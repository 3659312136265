import { DEBUG } from 'config/env';
import React, { createContext, useCallback, useEffect, useState } from 'react';

type ActionPropsType = Record<string, any>;

export type DocumentDetailActionsType =
  | 'CREATE_REVISION'
  | 'DOWNLOAD_ORIGINAL'
  | 'DOWNLOAD_WITH_ANNOTATIONS'
  | 'DOWNLOAD_SIGNED'
  | 'SHARE_DOWNLOAD'
  | 'SHARE_APP_USER_DOWNLOAD'
  | 'ONLINE_EDIT'
  | 'ANNOTATION_EDIT'
  | 'CREATE_ASSIGNMENTS'
  | 'RESERVE_DOCUMENT'
  | 'FAVORITE'
  | 'IS_MODEL'
  | 'DOCUMENT_SIGN_ONLINE'
  | 'DOCUMENT_SIGN_LOCAL'
  | 'CREATE_DIRECTORY_LINK'
  | 'ADD_NEW_COMMENT'
  | 'DISCARD'
  | 'ADD_BINDING'
  | 'ADD_REFERENCE';

type Action<T> = {
  type: T;
  props?: ActionPropsType;
  unregisterCallback?: () => void;
  execute: (props: ActionPropsType) => void;
  isDisabled: () => boolean;
};

type ValueType<T> = {
  registerAction: (type: T, execute: () => void, isDisabled?: () => boolean | string) => void;
  unregisterAction: (type: T) => void;
  callAction: (type: T, props?: ActionPropsType) => void;
  isRegistered: (type: T) => boolean;
  isDisabled: (type: T) => boolean;
};

export const DocumentActionsContext = createContext<ValueType<any>>(null);

export function useRegisterAction<T>(
  type: T,
  execute: () => void,
  isDisabled?: () => boolean | string,
  hide?: boolean
) {
  const { registerAction, unregisterAction } = useModalContext<T>();

  useEffect(() => {
    if (!hide) registerAction(type, execute, isDisabled);

    return () => {
      if (!hide) unregisterAction(type);
    };
  }, [isDisabled, type, execute, registerAction, unregisterAction, hide]);
}

export function useModalContext<T>() {
  const context = React.useContext<ValueType<T>>(DocumentActionsContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
}

export function DocumentActionsContextProvider<T extends string>({ children }: { children: React.ReactNode }) {
  const [actions, setActions] = useState<Partial<Record<T, Action<T>>>>({});

  const callAction = useCallback(
    (type: T, props: ActionPropsType = {}) => {
      const action = actions[type];
      DEBUG && console.log('call action', type, action);
      if (!action) {
        DEBUG && console.warn(`Action ${type} not registered`);
        return;
      }
      action.execute(props);
    },
    [actions]
  );

  const isRegistered = useCallback(
    (type: T) => {
      const registered = !!actions[type];
      if (!registered) {
        DEBUG && console.warn(`Action ${type} not registered`);
      }
      return registered;
    },
    [actions]
  );

  const isDisabled = useCallback(
    (type: T) => {
      const action = actions[type];
      if (!action) {
        DEBUG && console.warn(`Action ${type} not registered`);
      }
      return action.isDisabled?.();
    },
    [actions]
  );

  const registerAction = useCallback((type: T, execute: () => void, isDisabled?: () => boolean | string) => {
    const action = actions[type];
    if (action) {
      DEBUG && console.warn(`Action ${type} already registered, unregistering...`);
    }
    DEBUG && console.info('registered action', type);
    setActions((actions) => ({
      ...actions,
      [type]: {
        type,
        isDisabled,
        execute,
      },
    }));
  }, []);

  const unregisterAction = useCallback((type: T) => {
    const removedAction = actions[type];
    if (!removedAction) {
      setActions((actions) => {
        const { [type]: removed, ...rest } = actions;
        DEBUG && console.log('removed action', removed.type, removed);
        return rest as Partial<Record<T, Action<T>>>;
      });
    }
  }, []);

  return (
    <DocumentActionsContext.Provider value={{ registerAction, unregisterAction, callAction, isRegistered, isDisabled }}>
      {children}
    </DocumentActionsContext.Provider>
  );
}
