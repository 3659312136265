import { Button, Modal, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import { ApiResponse } from 'api/await-to';
import { ResultFileFormatEnum } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import { DATE_FORMAT_FOR_FILE_EXPORT } from 'config/constants';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment-business-days';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';
import { EXTENSIONS_MAP, ExportEntityTypeEnum, MIME_TYPE_MAP } from './CommonExportUtils';

type Props = {
  onSubmit: () => void;
  onClose: () => void;
  visible: boolean;
  handleExport: (exportFileType: ResultFileFormatEnum) => Promise<ApiResponse<string>>;
  exportEntityType: ExportEntityTypeEnum;
};

export const CommonExportFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  visible,
  handleExport,
  exportEntityType,
}) => {
  const intl = useIntl();
  const [exportFileType, setExportFileType] = useState<ResultFileFormatEnum>(ResultFileFormatEnum.excel);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useSameCallback(async () => {
    setLoading(true);

    const [err, resp] = await handleExport(exportFileType);

    if (err) {
      setLoading(false);
      messageError(err, intl);
      return err;
    }

    const exportFileName = `${exportEntityType}_export_${moment(Date.now()).format(DATE_FORMAT_FOR_FILE_EXPORT)}.${
      EXTENSIONS_MAP[exportFileType]
    }`;

    if (!!resp.data) {
      const blob = new Blob([resp.data], { type: MIME_TYPE_MAP[exportFileType] });
      downloadBlob(blob, exportFileName);
    }

    onSubmit && (await onSubmit());
    setLoading(false);
    return null;
  });

  const handleChangeRadio = (e: RadioChangeEvent) => {
    setExportFileType(e.target.value);
  };

  const footer = (
    <div>
      <Button onClick={onClose}>
        <Fmt id="general.close" />
      </Button>

      <Button type="primary" loading={loading} onClick={handleSubmit} disabled={loading}>
        <Fmt id="general.ok" />
      </Button>
    </div>
  );

  return (
    <Modal
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      title={<Fmt id={`CommonExportFormModal.title.${exportEntityType}`} />}
      okText={<Fmt id="general.export" />}
      cancelText={<Fmt id="general.close" />}
      maskClosable={false}
      confirmLoading={loading}
      footer={footer}
    >
      <Margin bottom>
        <Typography.Text strong>
          <Fmt id={'CommonExportFormModal.title.selectFormat'} />
        </Typography.Text>
      </Margin>
      <Radio.Group onChange={handleChangeRadio} value={exportFileType}>
        <Space direction="vertical">
          <Radio value={ResultFileFormatEnum.excel}>*.xlsx</Radio>
          <Radio value={ResultFileFormatEnum.Word}>*.docx</Radio>
          <Radio value={ResultFileFormatEnum.pdf}>*.pdf</Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default CommonExportFormModal;
