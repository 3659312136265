import { to } from 'api';
import {
  AcceptBackgroundDocumentDto,
  AddBackgroundDocumentDto,
  AssignBackgroundDocumentDto,
  BackgroundDocumentAddRequestDto,
  BackgroundDocumentPatchRequestDto,
  BackgroundDocumentRemoveRequestDto,
  ConfirmBackgroundDocumentDto,
  DelegateWorkflowNodeDto,
  RefuseBackgroundDocumentDto,
  WorkflowCommentProcedurePatchDto,
  WorkflowDefaultDirectoryPatchDto,
  WorkflowDocumentDetailListDto,
  WorkflowDto,
  WorkflowInitParallelNode,
  WorkflowInstantiationDto,
  WorkflowListDto,
  WorkflowNodeAddDocumentDto,
  WorkflowNodeAddRevisionDto,
  WorkflowNodeAssignDocumentDto,
  WorkflowNodeRemoveDocumentDto,
  WorkflowNodeUnlockConfirmationDto,
  WorkflowNodeVoteDto,
  WorkflowPatchDto,
  WorkFlowTemplateCheckRequestDto,
  WorkFlowTemplateCheckResponseDto,
  WorkflowTemplateCreateDto,
  WorkflowTemplateDto,
  WorkflowTemplateDuplicateDto,
  WorkFlowTemplateImportRequestDto,
  WorkflowTemplateListDto,
  WorkflowTemplatePatchDto,
} from 'api/completeApiInterfaces';
import { baseProjectApi } from 'api/project/baseProjectApi';
import { CancelToken } from 'axios';

export const workflowApi = {
  // workflow templates
  getWorkflowTemplatesList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<WorkflowTemplateListDto[]>(`/workflowtemplate`, { cancelToken })
    ),
  getWorkflowTemplate: (workflowTemplateId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<WorkflowTemplateDto>(`/workflowtemplate/${workflowTemplateId}`, { cancelToken })
    ),
  createTemplate: (template: WorkflowTemplateCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowTemplateDto>(`/workflowtemplate`, template, { cancelToken })
    ),
  updateTemplate: (template: WorkflowTemplatePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<WorkflowTemplateDto>(`/workflowtemplate`, template, { cancelToken })
    ),
  deleteTemplate: (templateId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/workflowtemplate/${templateId}`, { cancelToken })
    ),
  checkImportedTemplate: (importedTemplates: WorkFlowTemplateCheckRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkFlowTemplateCheckResponseDto>(`/workflowtemplate/import/check`, importedTemplates, {
        cancelToken,
      })
    ),

  importTemplate: (checkedImportedTemplates: WorkFlowTemplateImportRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowTemplateDto>(`/workflowtemplate/import`, checkedImportedTemplates, {
        cancelToken,
      })
    ),
  duplicateTemplate: (copiedTemplate: WorkflowTemplateDuplicateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowTemplateDto>(`/workflowtemplate/copy`, copiedTemplate, {
        cancelToken,
      })
    ),

  // workflow instances
  getWorkflowList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<WorkflowListDto[]>(`/workflow`, { cancelToken })
    ),
  getWorkflowListForDocuments: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<WorkflowDocumentDetailListDto>(`/workflow/document/${documentId}/detail`, { cancelToken })
    ),
  getWorkflow: (workflowId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<WorkflowDto>(`/workflow/${workflowId}`, { cancelToken })
    ),
  instantiate: (workflow: WorkflowInstantiationDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowDto>(`/workflow/instantiate`, workflow, { cancelToken })
    ),
  updateWorkflow: (workflowId: Guid, data: WorkflowPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<WorkflowDto>(`/workflow/${workflowId}`, data, { cancelToken })
    ),
  close: (workflowId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowDto>(`/workflow/${workflowId}/close`, { cancelToken })
    ),
  addDefaultDirectory: (workflowId: Guid, data: WorkflowDefaultDirectoryPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<WorkflowDto>(`/workflow/${workflowId}/defaultDirectory`, data, { cancelToken })
    ),
  updateDefaultDirectory: (
    workflowId: Guid,
    defaultDirectoryId: Guid,
    data: WorkflowDefaultDirectoryPatchDto,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.patch<WorkflowDto>(`/workflow/${workflowId}/defaultDirectory/${defaultDirectoryId}`, data, {
        cancelToken,
      })
    ),
  deleteDefaultDirectory: (workflowId: Guid, defaultDirectoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/workflow/${workflowId}/defaultDirectory/${defaultDirectoryId}`, {
        cancelToken,
      })
    ),
  commentProcedure: (workflowId: Guid, data: WorkflowCommentProcedurePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<WorkflowDto>(`/workflow/${workflowId}/commentprocedure`, data, { cancelToken })
    ),

  // background documents
  backgroundDocument: {
    addRequest: (request: BackgroundDocumentAddRequestDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/addrequest`, request, { cancelToken })
      ),
    addDocument: (document: AddBackgroundDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/adddocument`, document, { cancelToken })
      ),
    assign: (document: AssignBackgroundDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/assign`, document, { cancelToken })
      ),
    confirm: (document: ConfirmBackgroundDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/confirm`, document, { cancelToken })
      ),
    removeRequest: (request: BackgroundDocumentRemoveRequestDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/removerequest`, request, { cancelToken })
      ),
    accept: (request: AcceptBackgroundDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/accept`, request, { cancelToken })
      ),
    refuse: (request: RefuseBackgroundDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/backgrounddocument/refuse`, request, { cancelToken })
      ),
    changeRequest: (request: BackgroundDocumentPatchRequestDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.patch<WorkflowDto>(`/workflow/backgrounddocument/changerequest`, request, { cancelToken })
      ),
  },

  // nodes
  workflowNode: {
    vote: (vote: WorkflowNodeVoteDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/vote`, vote, { cancelToken })
      ),
    delegate: (delegate: DelegateWorkflowNodeDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/delegate`, delegate, { cancelToken })
      ),
    confirmlock: (data: WorkflowNodeUnlockConfirmationDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/confirmlock`, data, {
          cancelToken,
        })
      ),
    initParallel: (data: WorkflowInitParallelNode, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/initparallel`, data, {
          cancelToken,
        })
      ),
    addRevision: (data: WorkflowNodeAddRevisionDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/addrevision`, data, {
          cancelToken,
        })
      ),
    addDocument: (data: WorkflowNodeAddDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/adddocument`, data, { cancelToken })
      ),
    assignDocument: (data: WorkflowNodeAssignDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/assigndocument`, data, { cancelToken })
      ),
    removeDocument: (data: WorkflowNodeRemoveDocumentDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<WorkflowDto>(`/workflow/workflownode/removedocument`, data, { cancelToken })
      ),
  },
};
