import { to } from 'api/await-to';
import {
  AuditLogDMSRequestDto,
  AuditLogDto,
  AuditLogEntityRequestDto,
  AuditLogListResponseDto,
  AuditLogResponseDto,
  AuditLogSessionListRequestDto,
  AuditLogSessionResponseDto,
  AuditLogStavbaRequestDto,
  AuditLogUserActivityRequestDto,
  AuditLogUserActivityResponseDto,
  AuditLogUsersMonthlyRequestDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const auditLogApi = {
  getAuditLogDetail: (auditLogId: number, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<AuditLogResponseDto>(`/auditlog/${auditLogId}/auditlogdetail`, { cancelToken })
    ),
  listAuditLogEntity: (data: AuditLogEntityRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AuditLogListResponseDto>(`/auditlog`, data, { cancelToken })
    ),
  listAuditLog: (data: AuditLogEntityRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AuditLogListResponseDto>(`/auditlog/auditlogadmin`, data, { cancelToken })
    ),
  listAuditLogStavba: (data: AuditLogStavbaRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AuditLogListResponseDto>(`/auditlog/auditlogstavba`, data, { cancelToken })
    ),
  listAuditLogSession: (data: AuditLogSessionListRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AuditLogSessionResponseDto>(`/auditlog/useractivity/session`, data, { cancelToken })
    ),
  listAuditLogUserActivity: (data: AuditLogUserActivityRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AuditLogUserActivityResponseDto>(`/auditlog/useractivity/detail`, data, {
        cancelToken,
      })
    ),
  getAuditLogDmsExport: (data: AuditLogDMSRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/auditlog/dms/metadata`, data, {
        cancelToken,
      })
    ),
  getUserReportExport: (data: AuditLogUsersMonthlyRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/auditlog/users/report`, data, {
        cancelToken,
        responseType: 'blob',
      })
    ),
  getLastSuccessTransferToControlDatabase: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<AuditLogDto>(`/auditlog/auditlogstavba/lasttransfertokd`, { cancelToken })
    ),
};
