import { InputRef } from 'antd';
import { api } from 'api';
import { masterApi } from 'api/completeApi';
import {
  OrganizationAdminReportDto,
  ProjectCreateDto,
  ProjectCreateFromTempDto,
  ProjectDto,
} from 'api/completeApiInterfaces';
import { useApiData, useIntl, useIsMounted } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ProjectCreateForm, { ProjectCreateFormData, ProjectSource } from './ProjectCreateForm';

type Props = FormModalProps<ProjectDto> & {
  organizations: OrganizationAdminReportDto[];
  setInfoModalText?: React.Dispatch<React.SetStateAction<JSX.Element>>;
  currentUserName?: string;
};

const ProjectCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizations, setInfoModalText, currentUserName, ...restProps } = props;
  const intl = useIntl();
  const lang: LanguageEnum = useMemo(() => intl.locale, [intl]);
  const isMounted = useIsMounted();

  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => api.master.projects.getTimeZones(lang, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [lang]);

  const handleSubmit: FormSubmitHandler<ProjectCreateFormData> = useCallback(
    async (values) => {
      let isUserInProject = false;
      if (values.type === ProjectSource.NewProject) {
        const data: ProjectCreateDto = {
          name: values.name,
          description: values.description,
          language: values.language,
          timeZoneId: values.timeZoneId,
          organizationId: values.organizationId,
          orgLabels: values.labels,
          users: Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
            mail: mail,
            language: attributes.defaultLanguage,
            addAsAdmin: attributes.isAdmin,
          })),
          storageArea: values.storageArea,
        };

        const [err, res] = await masterApi.projects.post(data);
        if (err) return err;

        if (!isMounted.current) return null;
        await onSubmit(res.data);
        isUserInProject = data.users.some((user) => user.mail === currentUserName);
      } else {
        const data: ProjectCreateFromTempDto = {
          name: values.name,
          description: values.description,
          projectTemplateId: values.templateId,
          organizationId: values.organizationId,
          orgLabels: values.labels,
          expandUsers:
            Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
              mail: mail,
              language: attributes.defaultLanguage,
              addAsAdmin: attributes.isAdmin,
            })) || [],
        };

        const [err, res] = await masterApi.projects.createfromtemplate.post(data);
        if (err) return err;

        if (!isMounted.current) return null;
        await onSubmit(res.data);
      }
      setInfoModalText &&
        setInfoModalText(
          isUserInProject ? (
            <Fmt id="ProjectCreateFormModal.infoText.youWillSeeProject" />
          ) : (
            <Fmt id="ProjectCreateFormModal.infoText.common" />
          )
        );

      return null;
    },
    [currentUserName, onSubmit, setInfoModalText]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="general.createNewProject"
      submitTextId="ProjectCreateFormModal.button.create"
      submitTextLoadingId={null}
      width={600}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <ProjectCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          defaults={{ language: LanguageEnum[intl.locale] }}
          timeZones={timeZones}
          organizations={organizations}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectCreateFormModal;
