import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';

export function useAggregateEvent<T, U extends unknown[]>(
  onDebounceFunc: (values: T[]) => void,
  wait: number,
  aggregateFunc: (...params: U) => T,
  onEveryCall?: (data: T) => void,
  maximumWaitTime?: number
) {
  const debouncedValues = React.useRef([]);

  const debouncedFunction = useMemo(() => debounce(() => {
    const values = [...debouncedValues.current];
    onDebounceFunc?.(values);
    debouncedValues.current = [];
  }, wait, { maxWait: maximumWaitTime })
    ,
    [wait, maximumWaitTime, onDebounceFunc]
  );

  return useCallback(
    (...params: U) => {
      const data = aggregateFunc(...params);
      debouncedValues.current = [...debouncedValues.current, data];
      debouncedFunction();
      onEveryCall?.(data);
    },
    [debouncedFunction, onEveryCall, aggregateFunc]
  );
}
