import { CancelToken } from 'axios';

type ParallelProcessConfig = {
  onProgress: (done: number) => void;
  cancelToken: CancelToken;
};

export const processPromises = async <T>(
  promises: (() => Promise<T>)[],
  maxAtATime: number,
  config?: ParallelProcessConfig
): Promise<T[]> => {
  if (!promises?.length) {
    return Promise.resolve([]);
  }

  const { onProgress, cancelToken } = config || {};

  let nextIndex: number = 0; // next promise to process
  let doneCount: number = 0; // number of finished promises
  let cancelled: boolean = false;

  cancelToken?.promise.then(() => {
    cancelled = true;
  });

  const processNextPromise = async (resultsSoFar: T[]): Promise<T[]> => {
    // continue working?
    if (nextIndex >= promises.length || cancelled) {
      return Promise.resolve(resultsSoFar);
    }

    // find next promise to process
    const promiseCb = promises[nextIndex];
    nextIndex++;

    // execute the next promise
    const nextResult = await promiseCb();
    if (cancelled) {
      return Promise.resolve(resultsSoFar);
    }
    doneCount++;
    onProgress && onProgress(doneCount);

    // move on to a new promise
    return processNextPromise([...resultsSoFar, nextResult]);
  };

  // start up to maxAtATime "threads" at a time
  const threads: Promise<T[]>[] = [];
  for (let i = 0; i < maxAtATime; i++) {
    threads.push(processNextPromise([]));
  }

  // process and return results
  try {
    const threadResults = await Promise.all(threads);
    return threadResults.flat();
  } catch (error) {
    cancelled = true; // cancel other threads
    throw error;
  }
};
