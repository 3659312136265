import { InboxOutlined } from '@ant-design/icons';
import { default as classnames, default as classNames } from 'classnames';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './DropArea.module.less';

type Props = {
  multiple: boolean;
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  inputRef: RefObject<HTMLInputElement>;
  title: ReactNode;
  disabled?: boolean;
  tooltip?: string;
};

export const DropArea: FunctionComponent<Props> = ({
  multiple,
  onChangeInput,
  onDrop,
  disabled,
  inputRef,
  title,
  tooltip,
  children,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const enterLevels = useRef<number>(0);

  useEffect(() => {
    const dragEnterListener = (event: DragEvent) => {
      const items = Object.values(event.dataTransfer.items);
      if (!!items?.filter((item: DataTransferItem) => item.kind === 'file')?.length) {
        setIsDragActive(true);
        enterLevels.current++;
      }
    };
    const dragLeaveListener = () => {
      enterLevels.current = enterLevels.current < 1 ? 0 : enterLevels.current - 1;
      if (enterLevels.current === 0) setIsDragActive(false);
    };
    const dragDropListener = () => {
      enterLevels.current = 0;
      setIsDragActive(false);
    };

    const element = wrapperRef.current;
    element.addEventListener('dragleave', dragLeaveListener, false);
    element.addEventListener('drop', dragDropListener, false);
    element.addEventListener('dragenter', dragEnterListener, false);

    return () => {
      element.removeEventListener('dragleave', dragLeaveListener);
      element.removeEventListener('drop', dragDropListener);
      element.removeEventListener('dragenter', dragEnterListener);
    };
  }, []);

  const inputId = useMemo(() => {
    return uuid();
  }, []);

  return (
    <div className={classnames(styles.wrapper)} ref={wrapperRef}>
      <label
        className={classNames(styles.dropArea, !isDragActive && styles.hidden, disabled && styles.disabled)}
        htmlFor={inputId}
        ref={labelRef}
      >
        <input
          className={styles.input}
          tabIndex={-1}
          autoComplete="off"
          type="file"
          multiple={multiple}
          onChange={onChangeInput}
          onDrop={onDrop}
          id={inputId}
          ref={inputRef}
        />
        <div className={styles.hint}>
          <div className="ant-upload-drag-container">
            <p className="ant-upload-text">
              <InboxOutlined /> {title}
            </p>
            {disabled ? (
              <p className="ant-upload-hint">
                <Fmt id="DropArea.disabled" />
              </p>
            ) : (
              <p className="ant-upload-hint">{tooltip || <Fmt id="DropArea.hint" />}</p>
            )}
          </div>
        </div>
      </label>
      {children}
    </div>
  );
};
