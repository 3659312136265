import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { api } from 'api';
import Footer from 'components/Footer';
import { ProjectIcon, ReportIcon } from 'components/Icons/HubEntitiesIcons';
import MainModuleHeader from 'components/MainModule/MainModule.Header';
import SideMenu, { MenuItemType } from 'components/SideMenu/SideMenu';
import { SideMenuKey } from 'components/SideMenuActivator';
import { useApiData, useCurrentProjectUser, useIntl, useStoreSelector } from 'hooks';
import AdminPage from 'pages/Admin';
import AspeHubSettingsPage from 'pages/AspeHubSettingsPage';
import { MainDashboard } from 'pages/MainDashboard/MainDashboard';
import MessageCenterPage from 'pages/MessageCenterPage';
import OrganizationsSettingPage from 'pages/OrganizationsSettingPage';
import ProjectsListPage from 'pages/ProjectsListPage';
import ReportDetailPage from 'pages/ReportDetailPage/ReportDetailPage';
import { reportCategoriesMap } from 'pages/ReportsListPage/ReportsList';
import ReportsListPage from 'pages/ReportsListPage/ReportsListPage';
import React, { FunctionComponent, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { Dispatch } from 'store';
import styles from './MainModule.module.less';

type Props = RouteComponentProps<ProjectRouteParams>;

const MainModuleComponent: FunctionComponent<Props> = ({ match, location, history }) => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { sideMenuActiveKey, sideMenuCollapsed } = useStoreSelector((state) => state.appConfig);
  const currentProjectUser = useCurrentProjectUser();
  const currentAppUser = useStoreSelector((state) => state.currentAppUser);
  const [organizationsAdminReport] = useApiData((ct) => api.master.organization.getOrganizationsAdminReport(ct), {
    autoload: true,
  });

  const menuItemsConfig: MenuItemType[] = useMemo(() => {
    const validReportPermissions = Object.values(reportCategoriesMap).flatMap((categories) => categories);
    const canVieweReports =
      currentAppUser.data.organizationUsers?.some(
        (orgUser) =>
          !!orgUser.extendedPermissions.some(
            (permission) =>
              validReportPermissions.some((validPermission) => validPermission === permission.permissionType) &&
              !!orgUser.organization.esticonFirmIds?.length
          )
      ) || false;
    return [
      {
        key: SideMenuKey.DASHBOARD,
        label: intl.formatMessage({ id: 'menu.side.dashboard' }),
        icon: <AppstoreOutlined />,
        path: '/dashboard',
      },
      {
        key: SideMenuKey.PROJECTS,
        label: intl.formatMessage({ id: 'menu.side.projects' }),
        icon: <ProjectIcon />,
        path: '/projects',
      },
      canVieweReports
        ? {
            key: SideMenuKey.REPORTING,
            label: intl.formatMessage({ id: 'menu.side.reporting' }),
            icon: <ReportIcon />,
            path: '/reports',
          }
        : undefined,
    ].filter(Boolean);
  }, [intl, currentAppUser]);

  const bottomMenuItemsConfig: MenuItemType[] = useMemo(() => {
    const showOrganizationSetting = organizationsAdminReport?.organizations.some((org) => org.isOrganizationAdmin);
    const showAspeHubSetting = organizationsAdminReport?.isAppAdmin;
    return [
      showAspeHubSetting
        ? {
            key: SideMenuKey.ASPEHUB_SETTING,
            label: intl.formatMessage({ id: 'menu.side.AspeHubSetting' }),
            icon: <SettingOutlined />,
            path: '/aspeHubSetting',
          }
        : undefined,
      showOrganizationSetting
        ? {
            key: SideMenuKey.ORGANIZATIONS_SETTING,
            label: intl.formatMessage({ id: 'menu.side.organizationsSetting' }),
            icon: <SettingOutlined />,
            path: '/organizationsSetting',
          }
        : undefined,
    ].filter(Boolean);
  }, [organizationsAdminReport, intl]);

  return (
    <Layout className={styles.mainContent}>
      <MainModuleHeader match={match} location={location} history={history} />
      <Layout hasSider>
        <SideMenu
          url={match.url}
          sideMenuCollapsed={sideMenuCollapsed}
          sideMenuActiveItem={sideMenuActiveKey}
          setSideMenuCollapsed={dispatch.appConfig.setSideMenuCollapsed}
          bottomMenuItemsConfig={bottomMenuItemsConfig}
          menuItemsConfig={menuItemsConfig}
        />
        <Layout>
          <Layout.Content className={styles.layoutContent}>
            <div className={styles.content}>
              <Switch>
                <Route path="/dashboard" component={MainDashboard} />
                <Route path="/projects" component={ProjectsListPage} />
                <Route path="/reports" component={ReportsListPage} exact />
                <Route path="/reports/:organizationId/:report" component={ReportDetailPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/messagecenter" component={MessageCenterPage} />
                <Route
                  path={`/aspeHubSetting`}
                  render={(routeProps) => (
                    <AspeHubSettingsPage currentProjectUser={currentProjectUser} {...routeProps} />
                  )}
                />
                <Route
                  path={`/organizationsSetting`}
                  render={(routeProps) => <OrganizationsSettingPage {...routeProps} />}
                />
                <Redirect to="/dashboard" />
              </Switch>
            </div>
          </Layout.Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

export const MainModule = React.memo(MainModuleComponent);
