import {
  EsticonDocumentDto,
  EstiConPrenosHub,
  EstiConRozPolozkaDto,
  EstiConRozPolozkaHistorieCefDto,
  EstiConRozStrukturaTypUzlu,
} from 'api/completeApiInterfaces';
import { Decimal } from 'pages/BuildingBudgetPage/EsticonContainer';

export enum BuildingItemQualificationEnum {
  isQualified = 'isQualified',
  notQualified = 'notQualified',
}

export interface IBudgetGridData {
  id: Guid;
  key: Guid;
  type: EstiConRozStrukturaTypUzlu;
  children?: IBudgetGridData[];
  persistentChildren: IBudgetGridData[];
  sign: string; // Kód - string na každém uzlu
  name: string; // Název  - string na každém uzlu
  variant?: string; // Varianta  - string na položce
  orderNumber?: number; // PČ  - pořadové číslo int na položce
  amount?: Decimal; // Množství celkem - na položce, posčítáno z množství rozpisů SoD a investorských ZBV
  displayUnit?: string; // MJ - string na položce
  unitSellingPrice?: Decimal; // JOC - uvedeno na položkách
  unitCostPrice?: Decimal; // JNC - uvedeno na položkách
  sellingPrice?: Decimal; // Cena - celková cena vysčítána z podřízené úrovně - až dole na rozpisech se počítá round(množství * JOC)
  costPrice?: Decimal; // Cena (NC) - celková cena vysčítána z podřízené úrovně - až dole na rozpisech se počítá round(množství * JNC)
  sodAmount?: Decimal; // Množství SoD - množství položky z SoD rozpisu
  sodCostPrice?: Decimal; // Cena SoD - posčítané nákladové ceny SoD
  sodSellingPrice?: Decimal; // Cena SoD - posčítané ceny SoD
  zbvAmount?: Decimal; // Množství ZBV - množství položky posčítané z rozpisů investorských ZBV
  zbvCostPrice?: Decimal; // Cena ZBV - posčítané nákladové ceny ZBV
  zbvSellingPrice?: Decimal; // Cena ZBV - posčítané ceny ZBV
  spentSellingPrice?: Decimal; // Čerpáno - posčítáno, round(čerpané množství rozpisů * JOC)
  spentPercent?: number; // Čerpáno [%] - vypočítáno Čerpáno z celkové ceny
  remainingSellingPrice?: Decimal; // Zbývá - vypočítáno Celková cena - Čerpáno
  originType?: string; // Založeno - příznak položky zda byla založena v Sod nebo ZVB
  priceSystem?: string; // Cenová soustava - soustava ze které byla položka přebrána
  elementIds?: string[];
  polozka?: EstiConRozPolozkaDto;
  document?: EsticonDocumentDto;
  drawnAmount?: Decimal; //čerpané množství
  remainingAmount?: Decimal; //zbývající množství = sodAmount + zbvAmount - čerpané množství
  parent?: IBudgetGridData;
  cefActivity?: number;
  historieCef?: EstiConRozPolozkaHistorieCefDto[];
  qualification?: BuildingItemQualificationEnum; //způsobilost
  prenosHub: EstiConPrenosHub;
  valorization: boolean;
}

export interface IBudgetFlatData extends Omit<IBudgetGridData, 'children'> {
  parentId: Guid;
}

export interface BudgetModelOptionsType {
  showDrawingColors: boolean;
  showHighlighted: boolean;
  showCheckboxes: boolean;
}
