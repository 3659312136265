import { InputRef } from 'antd';
import { ProjectTemplateCategoryNodeDto, ProjectTemplateCategoryTreeDto } from 'api/completeApiInterfaces';
import DocumentCategoryTreeNodeCreateForm, {
  DocumentCategoryTreeNodeCreateFormData,
} from 'components/forms/DocumentCategoryTreeNodeCreateForm/DocumentCategoryTreeNodeCreateForm';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';

type Props = FormModalProps<ProjectTemplateCategoryNodeDto> & {
  selectedCategoryTree: ProjectTemplateCategoryTreeDto;
  selectedCategoryNodeId: Guid;
  editedCategoryNode?: ProjectTemplateCategoryNodeDto;
  validateUniqueName: (name: string) => boolean;
};

const ProjectTemplateCategoryNodeFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    selectedCategoryTree,
    selectedCategoryNodeId,
    editedCategoryNode,
    validateUniqueName,
    ...restProps
  } = props;
  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  const handleSubmit: FormSubmitHandler<DocumentCategoryTreeNodeCreateFormData> = async (values) => {
    onSubmit({
      id: editedCategoryNode?.id || uuid(),
      name: values.name,
      description: values.description,
      templateCategoryTreeId: editedCategoryNode?.templateCategoryTreeId || selectedCategoryTree.id,
      parentId: editedCategoryNode?.parentId || selectedCategoryNodeId,
    });
    return null;
  };

  const isCategoryNodeEditing = !!editedCategoryNode;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isCategoryNodeEditing
          ? 'ProjectTemplateCategoryNodeFormModal.edit.title'
          : 'ProjectTemplateCategoryNodeFormModal.add.title'
      }
      submitTextId={isCategoryNodeEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DocumentCategoryTreeNodeCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          defaults={editedCategoryNode}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectTemplateCategoryNodeFormModal;
