import { Button, Popconfirm } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import { useIntl } from 'hooks';
import React, { FunctionComponent, ReactNode } from 'react';
import { DisabledWithReason } from 'utils/types';

type Props = {
  onConfirm: () => void;
  disabled?: DisabledWithReason;
  loading?: boolean;
  tooltip?: ReactNode;
  confirmTooltip?: ReactNode;
  type?: 'link' | 'default' | 'primary' | 'dashed';
  shape?: 'circle' | 'default';
  size?: SizeType;
  children?: ReactNode;
};

const DeleteButtonConfirmComponent: FunctionComponent<Props> = ({
  onConfirm,
  disabled,
  type = 'link',
  shape = 'default',
  size = 'middle',
  loading,
  tooltip,
  confirmTooltip,
  children,
}) => {
  const intl = useIntl();
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <CommonHubTooltip
        title={typeof disabled === 'string' ? disabled : tooltip || intl.formatHTMLMessage({ id: 'general.delete' })}
        placement="topLeft"
      >
        <Popconfirm
          disabled={!!disabled}
          placement="right"
          title={confirmTooltip || intl.formatMessage({ id: 'Popconfirm.deleteItem' })}
          okText={intl.formatMessage({ id: 'general.yes' })}
          cancelText={intl.formatMessage({ id: 'general.no' })}
          onConfirm={onConfirm}
        >
          <Button
            size={size}
            shape={shape}
            icon={<DeleteIcon />}
            type={type}
            danger
            loading={loading}
            disabled={!!disabled}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          >
            {children ? <> {children}</> : null}
          </Button>
        </Popconfirm>
      </CommonHubTooltip>
    </div>
  );
};

export const DeleteButtonConfirm = React.memo(DeleteButtonConfirmComponent);
