import { DownloadOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { DownloadUrl } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { AnnotationIcon } from 'components/Icons/HubEntitiesIcons';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { downloadFile, messageError } from 'utils';

type Props = ButtonProps &
  InjectedIntlProps & {
    getUrl: () => Promise<[ApiError, AxiosResponse<DownloadUrl>]>;
    onDownloadWithAnnotations?: () => void;
    tooltip?: boolean;
    tooltipPlacement?: TooltipPlacement;
  };

const DownloadFileButton: FunctionComponent<Props> = ({
  intl,
  getUrl,
  onDownloadWithAnnotations,
  tooltip,
  tooltipPlacement,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const err = await downloadFile(getUrl);
    if (err) {
      messageError(err, intl);
    }
    setLoading(false);
  };

  const downloadMenu = useCallback(
    () => (
      <Menu>
        <Menu.Item key="downloadOriginal" onClick={handleClick} icon={<DownloadOutlined />}>
          <Fmt id="general.download" />
        </Menu.Item>
        <Menu.Item key="downloadWithAnnotations" onClick={onDownloadWithAnnotations} icon={<AnnotationIcon />}>
          <Fmt id="CommentProcedureDocumentTab.downloadWithAnnotations.tooltip" />
        </Menu.Item>
      </Menu>
    ),
    [onDownloadWithAnnotations]
  );

  const button = !!onDownloadWithAnnotations ? (
    <Dropdown overlay={downloadMenu} trigger={['click']}>
      <Button shape="circle" icon={<DownloadOutlined />} size="small" />
    </Dropdown>
  ) : (
    <Button
      type="default"
      shape="circle"
      icon={<DownloadOutlined />}
      loading={loading}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClick();
      }}
      {...restProps}
    />
  );

  if (tooltip)
    return (
      <CommonHubTooltip placement={tooltipPlacement} title={intl.formatMessage({ id: 'DownloadFileButton.tooltip' })}>
        {button}
      </CommonHubTooltip>
    );

  return button;
};

export default injectIntl(DownloadFileButton);
