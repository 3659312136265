import { ExtendedPermissionEnum, ProjectUserProfileDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { compact } from 'lodash';

export type TransitionMap<T extends string | number | symbol> = Partial<Record<T, T[]>>;

export const NEW_DOCUMENT_STATES = [
  WorkflowStateEnum.workinprogress,
  WorkflowStateEnum.shared,
  WorkflowStateEnum.published,
];

export const TO_CHANGE_DOCUMENT_STATES = [
  WorkflowStateEnum.rejected,
  WorkflowStateEnum.shared,
  WorkflowStateEnum.published,
];

export const MANUAL_STATE_TRANSITION: TransitionMap<WorkflowStateEnum> = {
  [WorkflowStateEnum.workinprogress]: [
    WorkflowStateEnum.workinprogress,
    WorkflowStateEnum.shared,
    WorkflowStateEnum.published,
    WorkflowStateEnum.rejected,
  ],
  [WorkflowStateEnum.shared]: [WorkflowStateEnum.shared, WorkflowStateEnum.published, WorkflowStateEnum.rejected],
  [WorkflowStateEnum.published]: [WorkflowStateEnum.published, WorkflowStateEnum.archived],
};

export const ALL_DOCUMENTS_STATES = Object.values(WorkflowStateEnum) as WorkflowStateEnum[];

export const getAvailableStates = (state: WorkflowStateEnum, map: TransitionMap<WorkflowStateEnum>) => {
  const states = map[state];
  return states ? states : [];
};

const MAP_UNAPPROVED_DOC_STATUSES: Partial<Record<ExtendedPermissionEnum, WorkflowStateEnum>> = {
  [ExtendedPermissionEnum.forbidWorkInProgressState]: WorkflowStateEnum.workinprogress,
  [ExtendedPermissionEnum.forbidSharedState]: WorkflowStateEnum.shared,
  [ExtendedPermissionEnum.forbidRejectedState]: WorkflowStateEnum.rejected,
  [ExtendedPermissionEnum.forbidPublishedState]: WorkflowStateEnum.published,
  [ExtendedPermissionEnum.forbidarchivedState]: WorkflowStateEnum.archived,
};

export const createRevisionOrDocumentAvailableStates = (currentUser: ProjectUserProfileDto) => {
  const unapprovedDocStatuses = compact(
    currentUser.extendedPermissions.map((permission) => MAP_UNAPPROVED_DOC_STATUSES[permission])
  );
  return NEW_DOCUMENT_STATES.filter((state) => !unapprovedDocStatuses.includes(state));
};

export const permittedStates = (currentUser: ProjectUserProfileDto) => {
  const unapprovedDocStatuses = compact(
    currentUser.extendedPermissions.map((permission) => MAP_UNAPPROVED_DOC_STATUSES[permission])
  );
  return ALL_DOCUMENTS_STATES.filter((state) => !unapprovedDocStatuses.includes(state));
};
