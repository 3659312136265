import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { compareByName } from 'utils/comparators';

export const rolesListSelector = (state: RootState) => state.roles.data;

export const rolesMapSelector = createSelector(
  [rolesListSelector],
  (roles) => roles && keyBy(roles, (role) => role.id)
);

export const rolesOrderedListSelector = createSelector(
  [rolesListSelector],
  (roles) => roles && [...roles].sort(compareByName)
);
