import { Select } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import DisplayName from 'components/DisplayName';
import Label from 'components/Label/Label';
import { useCurrentAppUser } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  organizationLabelsListSelector,
  organizationLabelsMapSelector,
} from 'store/selectors/organizationLabelsSelectors';
import { ignoreRef, smartFilter } from 'utils';
import styles from './LabelsInput.module.less';

type Props = Omit<SelectProps<Guid[]>, 'filterOption' | 'loading'> & { organizationId: Guid };

const OrganizationLabelsInputComponent: FunctionComponent<Props> = ({
  defaultValue,
  className,
  organizationId,
  onChange,
  ...restProps
}) => {
  const organizationIdRef = useRef<Guid>();
  const currentAppUser = useCurrentAppUser();

  useDirtyStoreReloadCallback(
    (store) => store.organizationLabels,
    (dispatch) =>
      dispatch.organizationLabels.loadData({
        reload: true,
        data: currentAppUser?.organizationUsers.map((orgUser) => orgUser.organization.id) || [],
      })
  );
  const orgLabelsMap = useSelector(organizationLabelsMapSelector);
  const orgLabelsList = useSelector(organizationLabelsListSelector);

  const filteredOrgLabelsList = useMemo(
    () => orgLabelsList?.filter((label) => label.organizationId === organizationId),
    [orgLabelsList, organizationId]
  );

  useEffect(() => {
    if (!!organizationIdRef.current && organizationIdRef.current !== organizationId) {
      onChange(defaultValue, { label: undefined });
    }
    organizationIdRef.current = organizationId;
  }, [onChange, organizationId]);

  const handleFilter = useCallback(
    (inputValue: string, option: DefaultOptionType): boolean => {
      const label = orgLabelsMap?.[option.key as Guid];
      return label && smartFilter(label.name, inputValue) && label.organizationId === organizationId;
    },
    [orgLabelsMap, organizationId]
  );

  const sortedValue: Guid[] = useMemo(
    () =>
      (defaultValue &&
        filteredOrgLabelsList
          ?.filter((label) => label.organizationId === organizationId)
          .reduce((accu, label) => {
            if (defaultValue.indexOf(label.id) !== -1) accu.push(label.id);
            return accu;
          }, [])) ||
      [],
    [defaultValue, filteredOrgLabelsList, organizationId]
  );

  return (
    <Select
      mode="multiple"
      showArrow
      placeholder={<Fmt id="LabelsInput.placeholder" />}
      filterOption={handleFilter}
      className={classNames(styles.select, className)}
      loading={orgLabelsMap === null}
      defaultValue={sortedValue}
      onChange={onChange}
      {...restProps}
    >
      {filteredOrgLabelsList?.map((label) => (
        <Select.Option key={label.id}>
          <Label color={label.color}>
            <DisplayName>{label.name}</DisplayName>
          </Label>
        </Select.Option>
      ))}
    </Select>
  );
};

export const OrganizationLabelsInput = ignoreRef(React.memo(OrganizationLabelsInputComponent));
