import { Button } from 'antd';
import { detect } from 'detect-browser';
import { Fmt } from 'locale';
import React from 'react';
import { fulfillVersion } from 'utils/unsupportedBrowserCheck';
import styles from './UnsupportedBrowserCheck.module.less';

interface Props {}
interface State {
  isEnabled: boolean;
  isCompatible: boolean;
}

export const detectIsCompatible = (): boolean => {
  const browser = detect();

  if (browser.name === 'edge') {
    return fulfillVersion(browser.version, 15);
  } else if (browser.name === 'edge-ios') {
    return fulfillVersion(browser.version, 76);
  } else {
    return true;
  }
};

class UnsupportedBrowserCheck extends React.Component<Props, State> {
  state: State = {
    isEnabled: false,
    isCompatible: false,
  };

  handlerButtonContinue = () => {
    this.setState({ isEnabled: true });
  };

  componentDidMount() {
    this.setState({ isCompatible: detectIsCompatible() });
  }

  render() {
    const { children } = this.props;
    const { isEnabled, isCompatible } = this.state;

    return isCompatible || isEnabled ? (
      children
    ) : (
      <div className={styles.buttonContinue}>
        <Fmt id="UnsupportedBrowser.error" />
        <Button type={'primary'} onClick={this.handlerButtonContinue}>
          <Fmt id="UnsupportedBrowser.continueAnyway" />
        </Button>
      </div>
    );
  }
}
export default UnsupportedBrowserCheck;
