import Button, { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { SignDocumentIcon } from 'components/Icons/HubActionsIcons';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';

type Props = ButtonProps &
  InjectedIntlProps & {
    tooltipPlacement?: TooltipPlacement;
    disabled: boolean;
  };

const SignFileButton: FunctionComponent<Props> = ({ intl, onClick, disabled, tooltipPlacement, size }) => {
  return (
    <CommonHubTooltip placement={tooltipPlacement} title={intl.formatMessage({ id: 'CertificateSignature.title' })}>
      <Button type="default" shape="circle" onClick={onClick} disabled={disabled} size={size}>
        <SignDocumentIcon />
      </Button>
    </CommonHubTooltip>
  );
};

export default injectIntl(SignFileButton);
