import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CheckOutlined } from '@ant-design/icons';
import { SwitchSize } from 'antd/lib/switch';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralSettingsItemsGroup from 'components/GeneralSettingsItemsGroup/GeneralSettingsItemsGroup';
import { Margin } from 'components/Margin/Margin';
import { SwitchLabeled } from 'components/SwitchLabeled/SwitchLabeled';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { ReactNode, useMemo } from 'react';
import { ArrayFlagsOperation } from 'utils/arrayHelpers';
import { ToggleNode, ToggleNodeEnum } from './GroupedToggles';
import { ToggleSwitchNode } from './ToggleSwitchNode';

type Props<T> = {
  label: IntlMessageId;
  children: ToggleNode<T>[];
  value: Set<T>;
  onChange: (values: T[], operation: ArrayFlagsOperation) => void;
  disabled?: boolean;
  switchSize?: SwitchSize;
  icon?: ReactNode;
};

export const renderToggleNodes = <T,>(
  nodes: ToggleNode<T>[],
  value: Set<T>,
  onChange: (values: T[], operation: ArrayFlagsOperation) => void,
  disabled: boolean
) => (
  <>
    {nodes.map((node, index) => {
      const commonProps = {
        label: node.label,
        onChange,
        disabled,
        icon: node.icon,
      };
      if (node.type === ToggleNodeEnum.ToggleGroup) {
        return <ToggleGroupNode key={index} {...commonProps} children={node.children} value={value} />;
      } else {
        return (
          <GeneralSettingsItem
            title={<Fmt id={node.label} />}
            key={index}
            input={
              <ToggleSwitchNode
                disabled={disabled}
                checked={value.has(node.value)}
                onChange={onChange}
                value={node.value}
              />
            }
            isBold={false}
          />
        );
      }
    })}
  </>
);

export const ToggleGroupNode = <T,>(props: Props<T>): JSX.Element => {
  const { label, children, value, onChange, disabled, icon } = props;

  const [allChildrenValue, allChecked, someChecked] = useMemo(() => {
    const allValuesMut: T[] = [];
    let allChecked = true;
    let someChecked = false;

    const processNode = (node: ToggleNode<T>) => {
      if (node.type === ToggleNodeEnum.ToggleGroup) {
        node.children.forEach(processNode);
      } else {
        allValuesMut.push(node.value);
        allChecked = allChecked && value.has(node.value);
        someChecked = someChecked || value.has(node.value);
      }
    };
    children.forEach(processNode);

    return [allValuesMut, allChecked, someChecked];
  }, [children, value]);

  return (
    <Margin top>
      <GeneralSettingsItem
        title={<Fmt id={label} />}
        disabled={disabled}
        icon={icon}
        input={
          <SwitchLabeled
            labelOnLeft
            checked={allChecked}
            label={<Fmt id={allChecked ? 'general.on' : someChecked ? 'general.onOff' : 'general.off'} />}
            onChange={(checked) =>
              onChange(allChildrenValue, checked ? ArrayFlagsOperation.Set : ArrayFlagsOperation.Clear)
            }
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<LegacyIcon type={someChecked ? 'check' : 'cross'} />}
          />
        }
      >
        {children.length > 1 && (
          <GeneralSettingsItemsGroup>
            {renderToggleNodes(children, value, onChange, disabled)}
          </GeneralSettingsItemsGroup>
        )}
      </GeneralSettingsItem>
    </Margin>
  );
};
