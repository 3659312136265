import { masterApi } from "api/completeApi";
import { EstiConFirmRowDto, EstiProjectDto } from "api/completeApiInterfaces";
import { useEffect, useState } from "react";
import { useApiData } from "./useApiData";

export const useEsticonFirmsWithProjects = (organizationId: Guid, esticonFirmIds?: Guid[]) => {
    const [esticonFirms, setEsticonFirms] = useState<Record<Guid, EstiConFirmRowDto>>({});
    const [esticonProjects, setEsticonProjects] = useState<Record<Guid, EstiProjectDto[]>>({});

    const [
        esticonFirmsWithProjects,
        firmsWithProjectsError,
        firmsWithProjectsLoading,
        loadFirmsWithProjects
    ] = useApiData((ct) => masterApi.projects.reports.firmlist.post({ organizationId, esticonFirmIds }, ct), { autoload: false });

    useEffect(() => {
        if (organizationId) {
            loadFirmsWithProjects();
        }
    }, [organizationId, esticonFirmIds]);

    useEffect(() => {
        if (!esticonFirmsWithProjects) return;

        setEsticonFirms(esticonFirmsWithProjects.esticonFirms.reduce(
            (esticonFirms, firm) => ({ ...esticonFirms, [firm.firm.id]: firm.firm }), {} as Record<Guid, EstiConFirmRowDto>
        ));
        setEsticonProjects(esticonFirmsWithProjects.esticonFirms.reduce(
            (esticonProjects, firm) => ({ ...esticonProjects, [firm.firm.id]: firm.projects }), {} as Record<Guid, EstiProjectDto[]>
        ));
    }, [esticonFirmsWithProjects])

    return { esticonFirms, esticonProjects, firmsWithProjectsError, firmsWithProjectsLoading } as const;
};

