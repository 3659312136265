import { api, createCancelToken } from 'api';
import { DirectoryContentDto } from 'api/completeApiInterfaces';
import { baseProjectApi } from 'api/project/baseProjectApi';
import Axios from 'axios';
import { API_BASE_URL } from 'config/env';
import { delay, processApiError } from 'utils';
import { checkArray, checkBoolean, checkGuid, checkNumber, checkObject } from 'utils/checkFormat';
import { jsonDecode } from 'utils/paramEncode';
import { TestData } from './Tests';

interface TestData2 {
  projectId: Guid;
  directories: Guid[];
  repetitionCount: number;
  parallelTaskCount: number;
  sleepTime: number;
  waitToEnd: boolean;
}

const Test2 = async (testData: TestData) => {
  const [err, data] = jsonDecode<TestData2>(
    testData.data.dataTemplate,
    checkObject({
      projectId: checkGuid,
      directories: checkArray(checkGuid),
      repetitionCount: checkNumber,
      parallelTaskCount: checkNumber,
      sleepTime: checkNumber,
      waitToEnd: checkBoolean,
    })
  );
  if (err) {
    const path = err.exception instanceof Error ? [] : err.exception.path;
    testData.onDataAdd(`Parsing data error (${err.intlId}): ${[...path, err.exception.message].join(' > ')}`);
    return;
  }

  baseProjectApi.defaults.baseURL = `${API_BASE_URL}/projects/${data.projectId}`;
  testData.onDataAdd('start');
  const tasks: Promise<void>[] = [];
  data.directories.forEach(async (dirId) => {
    const docs = await callDir(dirId, testData.onDataAdd);
    docs.forEach(async (doc) => {
      for (let i = 0; i < data.parallelTaskCount; i++) {
        tasks.push(
          callDocuments(doc.id, i.toString(), data.repetitionCount, data.sleepTime, !data.waitToEnd, testData.onDataAdd)
        );
      }
    });
  });
  while (tasks.length < data.parallelTaskCount) await delay(100);
  for (let i = 0; i < tasks.length; i++) await tasks[i];
  testData.onDataAdd('end');
};

const callDir = async (directoryId: Guid, onDataAdd: (row: string) => void): Promise<DirectoryContentDto[]> => {
  const ct = createCancelToken();
  onDataAdd(`Get contents for directory: ${directoryId} started`);
  const [err, resp] = await api.project.directories.getDirectoryContent(directoryId, ct.token);
  if (err) {
    const error = processApiError(err);
    onDataAdd(
      `Get content for directory: ${directoryId} finished with error (${error.referenceErrorCode}:${error.message})`
    );
    return [];
  }
  onDataAdd(`Get contents for directory: ${directoryId} finished`);
  return resp.data;
};

const callDocuments = async (
  documentId: Guid,
  id: string,
  count: number,
  sleep: number,
  cancel: boolean,
  onDataAdd: (row: string) => void
) => {
  let max_time = 0;
  let min_time = 10000;
  let time = 0;
  onDataAdd(`Get documents by Id: ${documentId} (thread: ${id}) started`);
  for (let i = 0; i < count; i++) {
    const startTime = Date.now();
    const ct = createCancelToken();
    const promise = api.project.documents.getDocumentById(documentId, ct.token);
    if (cancel) {
      await delay(sleep);
      ct.cancel('Test2: testing cancellation of axios request');
    }
    const [err] = await promise;
    const endTime = Date.now();
    if (err && !Axios.isCancel(err)) {
      const error = processApiError(err);
      onDataAdd(
        `Get documents by Id: ${documentId} (thread: ${id}, cyclus: ${i.toString()}) finished with error (${
          error.referenceErrorCode
        }:${error.message})`
      );
    }
    await delay(sleep);
    const diff = endTime - startTime;
    time += diff;
    if (diff > max_time) max_time = diff;
    if (diff < min_time) min_time = diff;
  }

  onDataAdd(
    `Get documents by Id: ${documentId} (thread: ${id}) finished. Time: ${time.toString()}ms, min: ${min_time.toString()}ms, avg: ${(
      time / count
    ).toString()}ms, max: ${max_time.toString()}ms`
  );
};

export default Test2;
