import { api } from 'api';
import { AssignmentCreateDto, AssignmentDto } from 'api/completeApiInterfaces';
import { useActiveProject } from 'hooks';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import { AssignmentAddFormConfiguration, AssignmentForm, AssignmentFormData } from './AssignmentForm';
import { ProjectSelect } from './ProjectSelect';

type Props = FormModalProps<AssignmentFormData, AssignmentDto> & InjectedIntlProps & AssignmentAddFormConfiguration;

const formDataToDto = (data: AssignmentFormData): AssignmentCreateDto => {
  const { assignmentDocuments, ...rest } = data;
  return {
    ...rest,
    assignmentDocumentIds: assignmentDocuments?.map((document) => document.id) || undefined,
  };
};

export const AssignmentFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  intl,
  visible,
  initialDocuments,
  ...restProps
}) => {
  const isInProject = useActiveProject() !== null;

  const handleSubmit = useSameCallback(async (values: AssignmentFormData) => {
    if (!values) {
      return null;
    }

    const [err, resp] = await api.project.assignments.createAssignment(formDataToDto(values));
    if (err) {
      return err;
    }

    onSubmit && (await onSubmit(values, resp.data));
    return null;
  });

  return (
    <>
      <ProjectSelect open={visible && !isInProject} onClose={onClose} />
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId="AssignmentFormModal.title"
        width="700px"
        open={visible && isInProject}
        {...restProps}
      >
        {({ intl, formRef }) => (
          <AssignmentForm intl={intl} wrappedComponentRef={formRef} initialDocuments={initialDocuments} />
        )}
      </FormModalWrapper>
    </>
  );
};
