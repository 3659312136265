import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import { RoleDto } from 'api/completeApiInterfaces';
import classnames from 'classnames';
import { RoleInfo } from 'components/RoleInfo/RoleInfo';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { rolesMapSelector } from 'store/selectors';
import { labelSelectFilter } from 'utils/formHelpers';
import styles from './RoleSelect.module.less';

type Props = SelectProps<Guid> & {
  preferredRoles?: Set<Guid>;
  preferredLabel?: ReactNode;
  othersLabel?: ReactNode;
  hiddenRoles?: (role: RoleDto) => boolean;
  selectDefault?: boolean;
  placeholder?: string;
};

const selectOption = (role: RoleDto) => (
  <Select.Option key={role.id} value={role.id} label={`${role.name}\n${role.user?.username || ''}`}>
    <RoleInfo role={role} />
  </Select.Option>
);

export const RoleSelect = React.forwardRef<RefSelectProps, Props>(
  (
    {
      preferredRoles,
      preferredLabel,
      othersLabel,
      hiddenRoles,
      className,
      filterOption,
      selectDefault,
      placeholder,
      ...selectProps
    },
    ref
  ) => {
    const showSearch = !('showSearch' in selectProps) || selectProps.showSearch;

    const roles = useSelectorDispatch(rolesMapSelector, (dispatch) => dispatch.roles.loadData({ reload: false }));
    useDirtyStoreReload(
      (store) => store.roles,
      (dispatch) => dispatch.roles
    );

    const visibleRoles = useMemo(
      () => (roles ? Object.values(roles).filter((e) => !(hiddenRoles && hiddenRoles(e))) : []),
      [roles, hiddenRoles]
    );
    const preferredVisibleRoles = useMemo(() => visibleRoles.filter((role) => preferredRoles?.has(role.id)), [
      preferredRoles,
      visibleRoles,
    ]);

    const options = useMemo(() => {
      if (!preferredVisibleRoles?.length) {
        return visibleRoles.map(selectOption);
      }
      return [
        // fragment doesn't work here, must use array
        <Select.OptGroup key={0} label={preferredLabel || <Fmt id="RoleSelect.preferredRoles" />}>
          {preferredVisibleRoles.map(selectOption)}
        </Select.OptGroup>,
        <Select.OptGroup key={1} label={othersLabel || <Fmt id="RoleSelect.otherRoles" />}>
          {visibleRoles.filter((role) => !preferredRoles.has(role.id)).map(selectOption)}
        </Select.OptGroup>,
      ];
    }, [visibleRoles, preferredRoles, preferredVisibleRoles, preferredLabel, othersLabel]);

    useEffect(() => {
      if (selectDefault && !!visibleRoles?.length) {
        if (!!preferredVisibleRoles?.length) {
          selectProps.onChange?.(preferredVisibleRoles[0]?.id, undefined);
        } else {
          selectProps.onChange?.(visibleRoles[0]?.id, undefined);
        }
      }
    }, [selectDefault, visibleRoles, preferredVisibleRoles]);

    return options.length ? (
      <Select
        // include "defaultActiveFirstOption" before "selectProps", so that it can be overridden
        defaultActiveFirstOption={false}
        {...selectProps}
        showSearch={showSearch}
        ref={ref}
        filterOption={filterOption || labelSelectFilter}
        className={classnames(styles.roleSelect, className)}
        placeholder={placeholder}
      >
        {options}
      </Select>
    ) : (
      <Fmt id="RoleSelect.emptyError" />
    );
  }
);
