import { ProjectTemplateCategoryNodeDto, ProjectTemplateCategoryTreeDto } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import StackPanel from 'components/StackPanel';
import { useBoolean } from 'hooks';
import { useMasterDetailView } from 'hooks/useMasterDetailView';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import CategoryList from 'pages/ProjectSettingsPage/Categories/CategoryListPanel/CategoryList';
import CategoryTree from 'pages/ProjectSettingsPage/Categories/CategoryTreePanel/CategoryTree';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, Key, useCallback, useEffect, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { filterTree } from 'store/selectors/genericSelectors';
import { strCompareCI } from 'utils';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplateCategoryFormModal from './ProjectTemplateCategoryFormModal';
import ProjectTemplateCategoryNodeFormModal from './ProjectTemplateCategoryNodeFormModal';

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
};

const ProjectTemplateCategoriesTab: FunctionComponent<Props> = ({ intl, projectTemplate, dispatchProjectTemplate }) => {
  const [isCategoryModalVisible, showCategoryTreeModal, hideCategoryTreeModal] = useBoolean(false);
  const [isCategoryNodeModalVisible, showCategoryNodeModal, hideCategoryNodeModal] = useBoolean(false);
  const [search, setSearch] = useState<string>('');
  const [searchNode, setSearchNode] = useState<string>('');
  const [selectedCategoryTreeId, setSelectedCategoryTreeId] = useState<Guid>();
  const [editedCategoryTreeId, setEditedCategoryTreeId] = useState<Guid>();
  const [selectedCategoryNodeId, setSelectedCategoryNodeId] = useState<Guid>();
  const [editedCategoryNodeId, setEditedCategoryNodeId] = useState<Guid>();
  const [expandedNodeKeys, setExpandedNodeKeys] = useState<Record<Guid, Key[]>>({});

  useEffect(() => {
    if (!!selectedCategoryTreeId) {
      setSelectedCategoryNodeId(
        projectTemplate.projectTemplateCategoryNodes.find(
          (node) => node.templateCategoryTreeId === selectedCategoryTreeId && !node.parentId
        )?.id
      );
    }
  }, [selectedCategoryTreeId]);

  const selectedCategoryTree = useMemo(
    () => projectTemplate.projectTemplateCategoryTrees.find((tree) => tree.id === selectedCategoryTreeId),
    [selectedCategoryTreeId, projectTemplate.projectTemplateCategoryTrees]
  );

  const editedCategoryTree = useMemo(
    () => projectTemplate.projectTemplateCategoryTrees.find((tree) => tree.id === editedCategoryTreeId),
    [editedCategoryTreeId, projectTemplate.projectTemplateCategoryTrees]
  );

  const categoryNodesTree = useMemo(() => {
    const categoryNodes = projectTemplate.projectTemplateCategoryNodes
      .filter((node) => node.templateCategoryTreeId === selectedCategoryTreeId)
      .reduce(
        (dictionary, node) => ({ ...dictionary, [node.id]: { ...node, parentId: node.parentId } }),
        {} as Dictionary<ProjectTemplateCategoryNodeDto & { parentId: Guid }>
      );

    return filterTree(categoryNodes, searchNode)[0];
  }, [projectTemplate.projectTemplateCategoryNodes, selectedCategoryTreeId, searchNode]);

  const editedCategoryNode = useMemo(
    () => projectTemplate.projectTemplateCategoryNodes.find((node) => node.id === editedCategoryNodeId),
    [editedCategoryNodeId, projectTemplate.projectTemplateCategoryNodes]
  );

  const handleNodeSelect = useCallback((keys: Guid[]) => {
    if (keys.length === 1) setSelectedCategoryNodeId(keys[0]);
  }, []);

  const handleCategoryTreeRemove = useCallback(
    (categoryTreeId: Guid) => {
      dispatchProjectTemplate({
        type: 'deleteCategoryTree',
        categoryTreeId: categoryTreeId,
      });
    },
    [projectTemplate.projectTemplateCategoryTrees, dispatchProjectTemplate]
  );

  const handleCategoryNodeRemove = useCallback(
    (categoryNodeId: Guid) => {
      dispatchProjectTemplate({
        type: 'deleteCategoryNode',
        categoryNodeId: categoryNodeId,
      });
      const erasedNode = projectTemplate.projectTemplateCategoryNodes.find((node) => node.id === categoryNodeId);
      setSelectedCategoryNodeId(erasedNode?.parentId);
    },
    [projectTemplate.projectTemplateCategoryNodes, dispatchProjectTemplate]
  );

  const handleCategoryTreeSubmit = useCallback(
    (tree: ProjectTemplateCategoryTreeDto) => {
      if (!!projectTemplate.projectTemplateCategoryTrees.some((categoryTree) => categoryTree.id === tree.id)) {
        dispatchProjectTemplate({
          type: 'updateCategoryTree',
          categoryTree: tree,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addCategoryTree',
          categoryTree: tree,
        });
      }
      setEditedCategoryTreeId(undefined);
      hideCategoryTreeModal();
    },
    [projectTemplate.projectTemplateCategoryTrees, dispatchProjectTemplate]
  );

  const handleCategoryNodeSubmit = useCallback(
    (node: ProjectTemplateCategoryNodeDto) => {
      if (!!projectTemplate.projectTemplateCategoryNodes.some((categoryNode) => categoryNode.id === node.id)) {
        dispatchProjectTemplate({
          type: 'updateCategoryNode',
          categoryNode: node,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addCategoryNode',
          categoryNode: node,
        });
      }
      setEditedCategoryNodeId(undefined);
      hideCategoryNodeModal();
    },
    [projectTemplate.projectTemplateCategoryNodes, dispatchProjectTemplate]
  );

  const showCategoryTreeEdit = useCallback((categoryId: Guid) => {
    setEditedCategoryTreeId(categoryId);
    showCategoryTreeModal();
  }, []);

  const showCategoryNodeEdit = useCallback((categoryNodeId: Guid) => {
    setEditedCategoryNodeId(categoryNodeId);
    showCategoryNodeModal();
  }, []);

  const showAddCategoryTreeModal = useCallback(() => {
    setEditedCategoryTreeId(undefined);
    showCategoryTreeModal();
  }, []);

  const showAddCategoryNodeModal = useCallback(() => {
    setEditedCategoryNodeId(undefined);
    showCategoryNodeModal();
  }, []);

  const handleOnExpand = useCallback(
    (keys) => {
      setExpandedNodeKeys({ ...expandedNodeKeys, [selectedCategoryNodeId]: keys });
    },
    [expandedNodeKeys, selectedCategoryNodeId]
  );

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !projectTemplate.projectTemplateCategoryTrees.some(
        (tree) =>
          strCompareCI(tree.name, name) === 0 && (!!selectedCategoryTreeId ? tree.id !== selectedCategoryTreeId : true)
      ),
    [selectedCategoryTreeId, projectTemplate.projectTemplateCategoryTrees]
  );

  const validateUniqueNodeName = useCallback(
    (name: string): boolean =>
      !projectTemplate.projectTemplateCategoryNodes
        .filter((node) => node.parentId === selectedCategoryNodeId)
        .some(
          (node) =>
            strCompareCI(node.name, name) === 0 && (!!editedCategoryNodeId ? node.id !== editedCategoryNodeId : true)
        ),
    [selectedCategoryNodeId, editedCategoryNodeId, projectTemplate.projectTemplateCategoryNodes]
  );

  const categoryDetailPanel = useMemo(
    () =>
      !!selectedCategoryTree ? (
        <Panel hideToolbar addButtonText={<Fmt id="general.addUsers" />}>
          <GeneralSettingsContainer>
            <GeneralSettingsContainer title={<Fmt id="general.general" />}>
              <GeneralSettingsItem title={<Fmt id="general.name" />} description={selectedCategoryTree?.name} />
              <GeneralSettingsItem
                title={<Fmt id="general.description" />}
                description={selectedCategoryTree?.description}
              />
            </GeneralSettingsContainer>
            <StackPanel vertical>
              <GeneralSettingsContainer title={<Fmt id="general.categoriesNodes" />}>
                <Panel
                  onSearch={setSearchNode}
                  addButtonOnClick={showAddCategoryNodeModal}
                  addButtonText={<Fmt id="Panel.addCategoryNode.tooltip" />}
                  noMargin
                  panelWidth={null}
                >
                  <CategoryTree
                    key={`${selectedCategoryTree.id}`}
                    treeData={categoryNodesTree}
                    onSelect={handleNodeSelect}
                    selectedKeys={[selectedCategoryNodeId]}
                    expandedKeys={expandedNodeKeys[selectedCategoryNodeId]}
                    onExpand={handleOnExpand}
                    defaultExpandAll
                    onEdit={showCategoryNodeEdit}
                    onDelete={handleCategoryNodeRemove}
                  />
                </Panel>
              </GeneralSettingsContainer>
            </StackPanel>
          </GeneralSettingsContainer>
        </Panel>
      ) : (
        undefined
      ),
    [
      selectedCategoryTree,
      showCategoryNodeModal,
      categoryNodesTree,
      handleNodeSelect,
      selectedCategoryNodeId,
      expandedNodeKeys,
      handleOnExpand,
      showCategoryNodeEdit,
      handleCategoryNodeRemove,
    ]
  );

  const handleClearSearch = () => setSearch(undefined);
  const hideCategoryTreeDetail = () => setSelectedCategoryTreeId(undefined);

  const { tableWrapRef, title, children } = useMasterDetailView(
    <Panel
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showAddCategoryTreeModal}
      addButtonText={<Fmt id="Panel.addCategory.tooltip" />}
    >
      <CategoryList
        data={projectTemplate.projectTemplateCategoryTrees}
        search={search}
        selectedId={selectedCategoryTreeId}
        onSelect={setSelectedCategoryTreeId}
        onEdit={showCategoryTreeEdit}
        onDelete={handleCategoryTreeRemove}
        onClearSearch={handleClearSearch}
      />
    </Panel>,
    categoryDetailPanel,
    intl.formatMessage({ id: 'general.categories' }),
    !!selectedCategoryTree && selectedCategoryTree.name,
    hideCategoryTreeDetail
  );

  return (
    <>
      <StackPanel vertical divRef={tableWrapRef}>
        <PageContent title={title}>{children}</PageContent>

        {isCategoryModalVisible && (
          <ProjectTemplateCategoryFormModal
            onSubmit={handleCategoryTreeSubmit}
            onClose={hideCategoryTreeModal}
            visible={isCategoryModalVisible}
            editedCategoryTree={editedCategoryTree}
            validateUniqueName={validateUniqueName}
          />
        )}
        {isCategoryNodeModalVisible && (
          <ProjectTemplateCategoryNodeFormModal
            onSubmit={handleCategoryNodeSubmit}
            onClose={hideCategoryNodeModal}
            visible={isCategoryNodeModalVisible}
            selectedCategoryTree={selectedCategoryTree}
            selectedCategoryNodeId={selectedCategoryNodeId}
            editedCategoryNode={editedCategoryNode}
            validateUniqueName={validateUniqueNodeName}
          />
        )}
      </StackPanel>
    </>
  );
};

export default ProjectTemplateCategoriesTab;
