/**
 * Based on @ninja-labs/verify-pdf library
 */

export type PDFSignatureMetadata = {
  reason?: string;
  contactInfo?: string;
  location?: string;
  name?: string;
};

export type PDFSignatureInfo = {
  meta: PDFSignatureMetadata;
  signatureString: string;
};

const getMetaRegexMatch = (keyName: string) => (str: string) => {
  const regex = new RegExp(`/${keyName}\\s*\\(([\\w.\\s@,]*)`, 'g');
  const matches = [...str.matchAll(regex)];
  const meta = matches.length ? matches[matches.length - 1][1] : null;

  return meta;
};

const getSignatureMeta = (signedData: Buffer): PDFSignatureMetadata => {
  const str = signedData.toString();
  return {
    reason: getMetaRegexMatch('Reason')(str),
    contactInfo: getMetaRegexMatch('ContactInfo')(str),
    location: getMetaRegexMatch('Location')(str),
    name: getMetaRegexMatch('Name')(str),
  };
};

export const getSignatures = (pdfBuffer: Buffer) => {
  const byteRangeStrings = pdfBuffer
    .toString()
    .match(/\/ByteRange\s*\[{1}\s*(?:(?:\d*|\/\*{10})\s+){3}(?:\d+|\/\*{10}){1}\s*\]{1}/g);
  if (!byteRangeStrings) {
    return [];
  }

  const strByteRanges = byteRangeStrings.map((brs) => brs.match(/[^[\s]*(?:\d|\/\*{10})/g));
  const byteRanges = strByteRanges.map((n) => n.map(Number));
  const lastIndex = byteRanges.length - 1;
  const endOfByteRange = byteRanges[lastIndex][2] + byteRanges[lastIndex][3];

  if (pdfBuffer.length > endOfByteRange) {
    return [];
  }

  return byteRanges.map(
    (byteRange): PDFSignatureInfo => ({
      meta: getSignatureMeta(
        Buffer.concat([
          pdfBuffer.slice(byteRange[0], byteRange[0] + byteRange[1]),
          pdfBuffer.slice(byteRange[2], byteRange[2] + byteRange[3]),
        ])
      ),
      signatureString: pdfBuffer.slice(byteRange[0] + byteRange[1] + 1, byteRange[2]).toString('latin1'),
    })
  );
};
