import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './StyledRow.module.less';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  active?: boolean;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  invalid?: boolean;
};

const StyledRow: FunctionComponent<Props> = ({
  wrapperClassName,
  wrapperStyle,
  active,
  className,
  children,
  invalid,
  ...restProps
}) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClassName)} style={wrapperStyle}>
      <div
        className={classNames(
          styles.row,
          { [styles.active]: active },
          { [styles.invalidTemplate]: invalid },
          className
        )}
        {...restProps}
      >
        {children}
      </div>
    </div>
  );
};

export default StyledRow;
