import { api, createCancelToken } from 'api';
import { projectApi } from 'api/completeApi';
import { BlobDerivateTypeEnum, RevisionDto, RevisionLockPurposeEnum } from 'api/completeApiInterfaces';
import { mapUploadProcessDataToSecondaryDocumentAdd } from 'api/project/upload/uploadHelpers';
import { UploadData, UploadFile, UploadFileType } from 'api/project/upload/uploadManager';
import { CancelToken } from 'axios';
import { DocumentReservationSigningLock } from 'components/DocumentReservationLock/DocumentReservationSigningLock';
import { useIntl, useIsMounted } from 'hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';
import { fileNameChangeExtension } from 'utils/fileNameChangeExtenison';
import { v4 as uuid } from 'uuid';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler, parseErrorMessage } from '../FormModalWrapper';
import RevisionSignedDocumentFileCreateForm, {
  RevisionSignedDocumentFileCreateFormData,
} from './RevisionSignedDocumentFileCreateForm';

type Props = FormModalProps<RevisionDto> & {
  titleId?: IntlMessageId;
  documentId: Guid;
  revisionId: Guid;
  workflowNodeId?: Guid;
  signedSecondaryFileId?: Guid;
  signedFileName?: string;
};

const getDownloadUrl = async (signedSecondaryFileId: Guid, documentId: Guid, revisionId: Guid, ct: CancelToken) => {
  if (signedSecondaryFileId) {
    return await projectApi.documents.id.revisions.id.download.id.get(
      documentId,
      revisionId,
      signedSecondaryFileId,
      undefined,
      undefined,
      undefined,
      ct
    );
  }
  return await api.project.documents.getDocumentRevisionDerivateDownloadUrlById(
    documentId,
    revisionId,
    BlobDerivateTypeEnum.Pdf,
    undefined,
    ct
  );
};

const RevisionSignedDocumentCreateFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    onClose,
    revisionId,
    documentId,
    workflowNodeId,
    signedSecondaryFileId,
    signedFileName,
    visible,
    ...restProps
  } = props;
  const [signedFileBlob, setSignedFileBlob] = useState<Blob>();
  const [signedFileDownloading, setSignedFileDownloading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const intl = useIntl();
  const [isSignedFileAnalyzed, setSignedFileAnalyzed] = useState<boolean>(false);

  const { lastUploadManager, error, loading, submitTextId, uploadProgress, resetAll, startUpload } = useFileUpload();

  useEffect(() => {
    const downloadSignedFile = async (ct: CancelToken) => {
      setSignedFileDownloading(true);
      const [err, downloadData] = await getDownloadUrl(signedSecondaryFileId, documentId, revisionId, ct);

      if (err) {
        messageError(err, intl);
        return;
      }

      const pdfBuffer = await fetch(downloadData.data.url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      }).then(async (response) => Buffer.from(await response.arrayBuffer()));

      if (isMounted.current) {
        const signedBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
        setSignedFileBlob(signedBlob);
        setSignedFileDownloading(false);
      }
    };
    const cancelToken = createCancelToken();
    if (visible && signedSecondaryFileId) {
      void downloadSignedFile(cancelToken.token);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [visible, signedSecondaryFileId, documentId, revisionId]);

  const signedFileNameWithPdfExtension = fileNameChangeExtension(signedFileName, 'pdf');

  const handleSignedFiledDownload = useCallback(() => {
    downloadBlob(signedFileBlob, signedFileNameWithPdfExtension);
  }, [signedFileBlob, signedFileNameWithPdfExtension]);

  const handleUploadFinish = useCallback(
    async (revision: RevisionDto): Promise<void> => {
      await onSubmit(revision);
      resetAll();
    },
    [onSubmit, resetAll]
  );

  const handleSubmit: FormSubmitHandler<RevisionSignedDocumentFileCreateFormData> = useCallback(
    async (values) => {
      const signedDocument = values.signedDocument;

      const revisionUploadData: UploadData<RevisionDto> = {
        createSaveRequest: (data) =>
          api.project.documents.addSecondaryDocumentsRevision(
            documentId,
            revisionId,
            mapUploadProcessDataToSecondaryDocumentAdd(data, workflowNodeId),
            data.ctSource.token
          ),
        onFinish: handleUploadFinish,
      };

      const uploadFiles: UploadFile[] = [
        {
          id: uuid(),
          fileName: signedDocument.name,
          blob: signedDocument,
          fileType: UploadFileType.signedDocument,
        },
      ];

      startUpload(revisionUploadData, uploadFiles);

      return null;
    },
    [startUpload, handleUploadFinish, documentId, revisionId]
  );

  const handleClose = useCallback(() => {
    resetAll();
    onClose();
  }, [onClose, resetAll]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      progress={uploadProgress}
      onClose={handleClose}
      errorMessage={parseErrorMessage(error)}
      showProgress={lastUploadManager.current !== null}
      submitTextId={submitTextId}
      onSubmitDisabled={!isSignedFileAnalyzed}
      forceLoading={loading}
      visible={visible}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DocumentReservationSigningLock
          lockPurpose={RevisionLockPurposeEnum.sign}
          documentId={documentId}
          revisionId={revisionId}
          isLockRequested={visible}
        >
          <RevisionSignedDocumentFileCreateForm
            intl={intl}
            wrappedComponentRef={formRef}
            signedDocumentLoading={signedFileDownloading}
            signedDocumentBlob={signedFileBlob}
            handleSignedFiledDownload={handleSignedFiledDownload}
            setSignedFileAnalyzed={setSignedFileAnalyzed}
          />
        </DocumentReservationSigningLock>
      )}
    </FormModalWrapper>
  );
};

export default RevisionSignedDocumentCreateFormModal;
