import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  isOn: boolean;
  saving: boolean;
  onClick: () => void;
  showDescription?: boolean;
  disabled?: boolean;
  onLabel: ReactNode;
  offLabel: ReactNode;
  offIcon: ReactNode;
  onIcon: ReactNode;
};

export const OnOffButton: FunctionComponent<Props> = ({
  isOn,
  saving,
  onClick,
  showDescription = false,
  disabled,
  onLabel,
  offLabel,
  offIcon,
  onIcon,
}) => {
  const description: ReactNode = isOn ? onLabel : offLabel;
  const button = (
    <Button type="link" onClick={onClick} disabled={disabled}>
      {saving ? <LoadingOutlined /> : isOn ? onIcon : offIcon}

      {showDescription && description}
    </Button>
  );

  return showDescription ? (
    <>{button}</>
  ) : (
    <CommonHubTooltip placement="topRight" title={saving ? <Fmt id="general.loading" /> : description}>
      {button}
    </CommonHubTooltip>
  );
};
