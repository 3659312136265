import { LabelLinkType } from 'components/LabelDisplay/LabelDisplay';
import { useStoreSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { labelsMapSelector } from 'store/selectors/labelsSelectors';
import LabelsStackDrawer from './LabelsStackDrawer';

type OwnProps = {
  labels: Guid[];
  linkType?: LabelLinkType;
  className?: string;
};

type Props = OwnProps;

const LabelsStack: FunctionComponent<Props> = (props) => {
  const labelsMap = useStoreSelector(labelsMapSelector);

  return <LabelsStackDrawer labelsMap={labelsMap} {...props} />;
};

export default LabelsStack;
