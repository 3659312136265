import classNames from 'classnames';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import StyledRow from 'components/StyledRow';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import styles from './StyledSelectableRow.module.less';

type Props = {
  id: Guid;
  selected?: boolean;
  disableSelect?: boolean;
  onClick?: (id: Guid) => void;
  onClickCheckbox?: (id: Guid) => void;
  style?: React.CSSProperties;
  wrapperClassName?: string;
  checkboxTooltip?: ReactNode;
  invalid?: boolean;
  className?: string;
  hideSelect?: boolean;
};

const StyledSelectableRow: FunctionComponent<Props> = ({
  id,
  children,
  selected,
  disableSelect,
  onClickCheckbox,
  onClick,
  style,
  checkboxTooltip,
  wrapperClassName,
  className,
  hideSelect,
  invalid,
}) => {
  const handleClickCheckbox = useCallback(() => {
    if (!disableSelect) {
      onClickCheckbox && onClickCheckbox(id);
    }
  }, [id, disableSelect, onClickCheckbox]);

  const handleClick = useCallback(() => {
    if (!disableSelect) {
      onClick && onClick(id);
    }
  }, [id, disableSelect, onClick]);

  return (
    <StyledRow
      wrapperClassName={wrapperClassName}
      wrapperStyle={style}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick();
        return false;
      }}
      className={classNames(
        styles.row,
        selected && styles.selected,
        !!onClick && !disableSelect && styles.selectable,
        className
      )}
      invalid={invalid}
    >
      {!hideSelect && (
        <DocumentCheckbox
          disabled={disableSelect}
          tooltip={checkboxTooltip}
          className={styles.checkbox}
          onClickCheckbox={handleClickCheckbox}
          selected={selected}
        />
      )}
      <div className={styles.main}>{children}</div>
    </StyledRow>
  );
};

export default StyledSelectableRow;
