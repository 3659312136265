import { Button, message, Modal } from 'antd';
import { projectApi } from 'api/completeApi';
import { AssignmentAssociatesPatchDto, AssignmentDto, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { UserProfile } from 'components/UserDisplay/UserDisplay';
import { groupSelectUserFooter, UserTransfer } from 'components/UserTransfer/UserTransfer';
import { useBoolean, useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { uniq } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { messageError, processApiError } from 'utils';
import { textComparer } from 'utils/comparators';

type Props = {
  canEdit: boolean;
  assignment: AssignmentDto;
  updateAssignment: (assignment: AssignmentDto | null) => void;
};

export const AssignmentAssociatesField: FunctionComponent<Props> = ({ canEdit, assignment, updateAssignment }) => {
  const intl = useIntl();

  const [modalVisible, showModal, hideModal] = useBoolean();
  const [selectedUsers, setSelectedUsers] = useState<Guid[]>([]);
  const [saving, setSaving] = useState(false);
  const [removingAssociateId, setRemovingAssociateId] = useState<Guid>(null);

  const handleShow = () => {
    setSelectedUsers([]);
    showModal();
  };

  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const allUsers = useSelector(projectUsersListSelector);
  const availableUsers = useMemo(
    () =>
      allUsers?.filter(
        (user) =>
          !assignment.associates.some((associate) => associate.id === user.id) &&
          user.status === ProjectUserProfileStatusEnum.active
      ) || [],
    [allUsers, assignment.associates]
  );

  const groups = useStoreSelector(groupOrderedListSelector);

  const handleSaveAssociate = async (associateIds: Guid[]) => {
    const data: AssignmentAssociatesPatchDto = {
      associateIds,
    };

    setSaving(true);
    const [err, resp] = await projectApi.assignments.id.updateassociates.post(assignment.id, data);
    setSaving(false);
    setRemovingAssociateId(null);

    if (err) {
      const error = processApiError(err);
      if (error.referenceErrorCode === ServiceErrorEnum.AssignmentForbiddenError) {
        message.warn(intl.formatMessage({ id: 'AssignmentSolversField.lostAccessWarning' }));
        updateAssignment(null);
      } else {
        messageError(error, intl);
      }
    } else {
      setSelectedUsers([]);
      hideModal();
      updateAssignment(resp.data);
    }
  };

  const canRemoveAssociate = useCallback(
    (associateId: Guid) =>
      assignment.createdBy.id === assignment.taskGiver?.id || associateId !== assignment.createdBy.id,
    [assignment]
  );

  const handleRemoveAssociate = (userId: Guid) => {
    setRemovingAssociateId(userId);
    void handleSaveAssociate(assignment.associates.map((associate) => associate.id).filter((id) => id !== userId));
  };

  const handleAddAssociate = () => {
    void handleSaveAssociate(uniq([...assignment.associates.map((associate) => associate.id), ...selectedUsers]));
  };

  const createFooter = useMemo(() => groupSelectUserFooter(groups, availableUsers), [groups, availableUsers]);

  return (
    <GeneralSettingsContainer
      title={
        <FlowLayout growFirst>
          <Fmt id="AssignmentForm.associateIds" />
          {canEdit && (
            <Button type="primary" icon={<AddIcon />} onClick={handleShow}>
              <Fmt id="AssignmentSolversField.addAssociate" />
            </Button>
          )}
        </FlowLayout>
      }
    >
      {assignment.associates.sort(textComparer.map((associate) => associate.username)).map((associate) => (
        <GeneralSettingsItem
          key={associate.id}
          title={<UserProfile user={associate} />}
          additionalActions={
            <>
              {canEdit && (
                <DeleteButtonConfirm
                  tooltip={
                    !canRemoveAssociate(associate.id)
                      ? intl.formatMessage({ id: 'AssignmentSolversField.cannotRemoveAssociate' })
                      : intl.formatMessage({ id: 'AssignmentSolversField.removeAssociate' })
                  }
                  onConfirm={() => handleRemoveAssociate(associate.id)}
                  disabled={
                    assignment.createdBy.id !== assignment.taskGiver?.id && associate.id === assignment.createdBy.id
                  }
                  loading={associate.id === removingAssociateId}
                />
              )}
            </>
          }
        />
      ))}

      <Modal
        open={modalVisible}
        onCancel={hideModal}
        onOk={handleAddAssociate}
        title={<Fmt id="AssignmentSolversField.addAssociate" />}
        confirmLoading={saving}
        width={700}
      >
        <UserTransfer
          users={availableUsers}
          value={selectedUsers}
          onChange={setSelectedUsers}
          createFooter={createFooter}
        />
      </Modal>
    </GeneralSettingsContainer>
  );
};
