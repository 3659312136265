import { TooltipPlacement } from 'antd/lib/tooltip';
import { ProjectUserProfileListDto, RoleDto } from 'api/completeApiInterfaces';
import React, { FunctionComponent } from 'react';
import { UserAvatarSize } from '../UserIcon/UserIcon';
import { ModularMultiUserAvatar } from 'components/avatars/MultiUserAvatar/ModularMultiUserAvatar';
import { UserAvatarWithOwner } from 'components/avatars/MultiUserAvatar/UserAvatarWithOwner';

type Props = {
  title?: string;
  size?: UserAvatarSize;
  popoverPlacement?: TooltipPlacement;
  revisionUser?: ProjectUserProfileListDto;
  documentUser?: ProjectUserProfileListDto;
  documentOwner?: RoleDto;
};

const MultiUserAvatarComponent: FunctionComponent<Props> = ({
  title,
  size = UserAvatarSize.Medium,
  popoverPlacement,
  revisionUser,
  documentUser,
  documentOwner,
}) => {
  const avatar = <UserAvatarWithOwner user={revisionUser || documentUser} size={size} documentOwner={documentOwner} />;
  return (
    <ModularMultiUserAvatar
      avatar={avatar}
      documentUser={documentUser}
      revisionUser={revisionUser}
      documentOwner={documentOwner}
      popoverPlacement={popoverPlacement}
      title={title}
      // TODO: nefunguje to správně, zobrazuje pouze jednoho uživatele, asi tam nechodí data, není tam ani titulek
    />
  );
};

export const MultiUserAvatar = React.memo(MultiUserAvatarComponent);
