import { Typography } from 'antd';
import { api } from 'api';
import {
  DocumentBulkActionSetResultDto,
  DocumentBulkStateCheckResultDto,
  DocumentBulkStateSetDto,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  IMultipleDocumentErrorData,
  MultipleDocumentActionErrorDataItem,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { messageError, processApiError } from 'utils';
import BulkEditActionResultModal from '../BulkActionResultReportModal/BulkEditActionResultModal';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import BulkEditDocumentsStateForm, { BulkEditDocumentsStateFormData } from './BulkEditDocumentsStateForm';

type Props = InjectedIntlProps &
  FormModalProps<void> & {
    documentsIds: Guid[];
    documentLinksIds?: Guid[];
    availableDocumentsStates: DocumentBulkStateCheckResultDto;
  };

const BulkEditDocumentsStateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, documentsIds, documentLinksIds, availableDocumentsStates, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [ignoredEntities, setIgnoreEntities] = useState<MultipleDocumentActionErrorDataItem[]>([]);
  const [resultModalVisible, showSuccessResultModal, hideSuccessResultModal] = useBoolean(false);
  const [result, setResult] = useState<DocumentBulkActionSetResultDto>(undefined);
  const intl = useIntl();
  const handleSubmit: FormSubmitHandler<BulkEditDocumentsStateFormData> = async (values) => {
    if (!availableDocumentsStates.availableStates.length) {
      handleClose();
      return null;
    }
    const data: DocumentBulkStateSetDto = {
      state: values.documentsState,
      documentsIds: documentsIds,
      documentLinksIds: documentLinksIds || [],
      ignoredDocumentIds: ignoredEntities.filter((item) => item.isDocument).map((item) => item.objectId) || [],
      ignoredDocumentLinksIds: ignoredEntities.filter((item) => !item.isDocument).map((item) => item.objectId) || [],
    };

    const [err, res] = await api.project.documents.bulkEditDocumentsStatesPatch(data);
    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleStateChangeObstacleError) {
          const errData = error.errorData as IMultipleDocumentErrorData;
          setErrors(errData.documentsErrors.map(transformError));
          setIgnoreEntities((prevState) => prevState.concat(errData.documentsErrors));
          return null;
        }
        messageError(error, intl);
        onClose();
      });
      return null;
    }
    setResult(res.data);
    onSubmit();
    showSuccessResultModal();
    handleClose();
    return null;
  };

  const handleClose = () => {
    onClose();
    setErrors([]);
    setIgnoreEntities([]);
  };

  const handleCloseActionResultModal = () => {
    hideSuccessResultModal();
    setResult(undefined);
  };

  const submitTextId = useMemo(() => {
    return !availableDocumentsStates?.availableStates.length
      ? 'general.ok'
      : errors.length > 0
      ? 'BulkEditDocumentsStateForm.executeChangesToOthers'
      : 'BulkEditDocumentsStateForm.executeChanges';
  }, [errors, availableDocumentsStates]);

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onCancel={handleClose}
        onClose={handleClose}
        titleId="BulkEditDocumentsStateForm.title"
        visible={true}
        confirmClose={false}
        {...restProps}
        submitTextId={submitTextId}
      >
        {({ intl, formRef }) => (
          <>
            <Typography.Paragraph strong>
              <Fmt id="BulkEditDocumentsStateForm.documentsCount" values={{ count: documentsIds.length }} />
            </Typography.Paragraph>
            <BulkEditDocumentsStateForm
              wrappedComponentRef={formRef}
              intl={intl}
              availableDocumentsStates={availableDocumentsStates}
              errors={errors}
            />
          </>
        )}
      </FormModalWrapper>
      <BulkEditActionResultModal
        visible={resultModalVisible}
        onClose={handleCloseActionResultModal}
        result={result}
        title={<Fmt id={'BulkEditDocumentsStateForm.successModal.title'} />}
        showReportLink
      />
    </>
  );
};

export default injectIntl(BulkEditDocumentsStateFormModal);
