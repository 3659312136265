import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { compareByName } from 'utils/comparators';

export const groupListSelector = (state: RootState) => state.groups.data;

export const groupMapSelector = createSelector(
  [groupListSelector],
  (groups) => groups && keyBy(groups, (group) => group.id)
);

export const groupOrderedListSelector = createSelector(
  [groupListSelector],
  (groups) => groups && [...groups].sort(compareByName)
);
