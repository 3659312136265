import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';
import React, { FunctionComponent } from 'react';

const EmptySimple: FunctionComponent<EmptyProps> = ({ children, ...props }) => (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props}>
    {children}
  </Empty>
);

export default EmptySimple;
