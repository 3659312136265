import React, { FunctionComponent, useEffect } from 'react';

import { useLocalStorage, useLocation } from 'react-use';

type Props = {};

const LAST_VISITED_LOCATION_STORAGE_KEY = 'lastVisitedLocationKey';
const ALLOWED_REDIRECT_PATHS = ['/'];
const IGNORED_REDIRECT_PATH_PREFIXES = [
  '/out',
  '/authorize',
  '/invitation-accept',
  '/oidc/callback/popup',
  '/oidc/callback/redirect',
  '/shared/',
];

const LastLocationStartupRedirect: FunctionComponent<Props> = () => {
  const location = useLocation();
  const [lastVisitedLocation, setCurrentLocation] = useLocalStorage<string>(LAST_VISITED_LOCATION_STORAGE_KEY);

  useEffect(() => {
    if (
      !!lastVisitedLocation &&
      ALLOWED_REDIRECT_PATHS.some((allowedLocation) => allowedLocation === location.pathname)
    ) {
      window.location.replace(lastVisitedLocation);
    }
  }, []);

  useEffect(() => {
    if (
      IGNORED_REDIRECT_PATH_PREFIXES.some((ignoredLocation) => location.pathname.startsWith(ignoredLocation)) ||
      location.pathname === '/'
    ) {
      return;
    }
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return <></>;
};

export default LastLocationStartupRedirect;
