import { AudioOutlined, SoundOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import classNames from 'classnames';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './Viewer.module.less';

const supportedTypesMap: Dictionary<string> = {
  'audio/mpeg': 'audio/mpeg',
  'audio/mpa': 'audio/mpeg',
  'audio/mpa-robust': 'audio/mpeg',
  'application/ogg': 'audio/ogg',
  'audio/ogg': 'audio/ogg',
  'audio/wav': 'audio/wav',
  'audio/vnd.wave': 'audio/wav',
  'audio/wave': 'audio/wav',
  'audio/x-wav': 'audio/wav',
  'audio/mp4': 'audio/mp4',
  'audio/vorbis': 'audio/vorbis',
};

const AUDIO_PLAYER_WIDTH = '60%';
const AUDIO_PLAYER_HEIGHT = '50px';

export const AudioViewer: FileViewerComponent = ({ style, className, url, contentType, onLoad, viewerVisible }) => {
  const sourceType = supportedTypesMap[contentType.toLowerCase()];
  const [playState, setPlayingState] = useState<boolean>(false);

  const isPlaying = useMemo(() => playState && viewerVisible, [playState, viewerVisible]);

  useEffect(() => onLoad && onLoad(), []);

  if (!sourceType) return <Alert type="error" message={<Fmt id="FileViewer.UnsupportedMediaType" />} />;

  return (
    <div style={style} className={classNames(styles.viewer, className)}>
      <ReactPlayer
        controls={true}
        url={url}
        light={false}
        width={AUDIO_PLAYER_WIDTH}
        height={AUDIO_PLAYER_HEIGHT}
        playing={isPlaying}
        onPause={() => setPlayingState(false)}
        onPlay={() => setPlayingState(true)}
        fallback={<SoundOutlined />}
      />
    </div>
  );
};

export const AudioViewerData: FileViewerProperties = {
  supportedTypes: Object.keys(supportedTypesMap),
  icon: <AudioOutlined />,
  component: 'AudioViewer',
  titleTranslationId: 'AudioViewer.title',
};
