import { api, createCancelToken } from 'api';
import { baseProjectApi } from 'api/project/baseProjectApi';
import Axios from 'axios';
import { API_BASE_URL } from 'config/env';
import { delay, processApiError } from 'utils';
import { checkArray, checkBoolean, checkGuid, checkNumber, checkObject } from 'utils/checkFormat';
import { jsonDecode } from 'utils/paramEncode';
import { TestData } from './Tests';

interface TestData1 {
  projectId: Guid;
  directories: Guid[];
  repetitionCount: number;
  parallelTaskCount: number;
  sleepTime: number;
  waitToEnd: boolean;
}

const Test1 = async (testData: TestData) => {
  const [err, data] = jsonDecode<TestData1>(
    testData.data.dataTemplate,
    checkObject({
      projectId: checkGuid,
      directories: checkArray(checkGuid),
      repetitionCount: checkNumber,
      parallelTaskCount: checkNumber,
      sleepTime: checkNumber,
      waitToEnd: checkBoolean,
    })
  );
  if (err) {
    const path = err.exception instanceof Error ? [] : err.exception.path;
    testData.onDataAdd(`Parsing data error (${err.intlId}): ${[...path, err.exception.message].join(' > ')}`);
    return;
  }

  baseProjectApi.defaults.baseURL = `${API_BASE_URL}/projects/${data.projectId}`;
  testData.onDataAdd('start');
  const tasks: Promise<void>[] = [];
  data.directories.forEach((dirId) => {
    for (let i = 0; i < data.parallelTaskCount; i++) {
      tasks.push(
        callDir(dirId, i.toString(), data.repetitionCount, data.sleepTime, !data.waitToEnd, testData.onDataAdd)
      );
    }
  });
  for (let i = 0; i < tasks.length; i++) await tasks[i];
  testData.onDataAdd('end');
};

const callDir = async (
  directoryId: Guid,
  id: string,
  count: number,
  sleep: number,
  cancel: boolean,
  onDataAdd: (row: string) => void
) => {
  let max_time = 0;
  let min_time = 10000;
  let time = 0;
  onDataAdd(`Get contents for directory: ${directoryId} (thread: ${id}) started`);
  for (let i = 0; i < count; i++) {
    const startTime = Date.now();
    const ct = createCancelToken();
    const promise = api.project.directories.getDirectoryContent(directoryId, ct.token);
    if (cancel) {
      await delay(sleep);
      ct.cancel('Test1: testing cancellation of axios request');
    }
    const [err] = await promise;
    const endTime = Date.now();
    if (err && !Axios.isCancel(err)) {
      const error = processApiError(err);
      onDataAdd(
        `Get content for directory: ${directoryId} (thread: ${id}, cyclus: ${i.toString()}) finished with error (${
          error.referenceErrorCode
        }:${error.message})`
      );
    }
    await delay(sleep);
    const diff = endTime - startTime;
    time += diff;
    if (diff > max_time) max_time = diff;
    if (diff < min_time) min_time = diff;
  }

  onDataAdd(
    `Get contents for directory: ${directoryId} (thread: ${id}) finished. Time: ${time.toString()}ms, min: ${min_time.toString()}ms, avg: ${(
      time / count
    ).toString()}ms, max: ${max_time.toString()}ms`
  );
};

export default Test1;
