import { ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './UserTooltipDetail.module.less';
import { UsersItem } from 'components/UsersItem/UsersItem';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';

export type Props = {
  user?: ProjectUserProfileListDto;
  title?: ReactNode;
  children?: ReactNode;
};

const UserTooltipDetailComponent: FunctionComponent<Props> = ({ user, title, children }) => {
  return (
    <div>
      {title && <div className={styles.title}>{title}</div>}
      <SettingsBox>
        {user && <UsersItem user={user} emailLink />}
        {children}
      </SettingsBox>
    </div>
  );
};

export const UserTooltipDetail = React.memo(UserTooltipDetailComponent);
