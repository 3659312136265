import { Core } from '@pdftron/webviewer';
import { usePdfTron } from 'components/PdfDiff/usePdfTron';
import SpinBoxCenter from 'components/SpinBoxCenter/SpinBoxCenter';
import { useIntl, useSameCallback } from 'hooks';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './PdfSignature.module.less';

const BANKID_SIGNING_AREA_MIN_WIDTH = 112;
const BANKID_SIGNING_AREA_MIN_HEIGHT = 37;
const BANKID_SIGNING_AREA_MAX_WIDTH = 198;
const BANKID_SIGNING_AREA_MAX_HEIGHT = 50;
const SIGNATURE_SAFE_BORDER = 27; // in points. PDF 1 point ~ 2,54/72 cm

export type PDFSignatureInfo = {
  pageNumber: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  pageHeight: number;
  pageWidth: number;
  pageRotation: number;
  createDate?: Date;
};

type Props = {
  licenseKey: string;
  documentUrl: string;
  onSignatureChanged: (signDocumentInfo: PDFSignatureInfo) => void;
};

const clampValue = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const getPageRotationDegrees = (pageRotation: Core.PageRotation): number => {
  switch (pageRotation) {
    case 0:
      return 0;
    case 1:
      return 90;
    case 2:
      return 180;
    case 3:
      return 270;
  }
  return 0;
};

const setAnnotationProperties = async (
  annotatedDocument: Core.PDFNet.PDFDoc,
  annotation: Core.Annotations.Annotation,
  signatureFieldRef: React.MutableRefObject<Core.Annotations.Annotation>,
  onSignatureChanged: (signDocumentInfo: PDFSignatureInfo) => void,
  documentViewer: Core.DocumentViewer
) => {
  const docInfo = await annotatedDocument.getDocInfo();
  const createDateInfo = await docInfo.getCreationDate();
  let createDate = new Date();
  if (createDateInfo.year > 0) {
    createDate.setUTCFullYear(createDateInfo.year, createDateInfo.month - 1, createDateInfo.day);
    createDate.setUTCHours(createDateInfo.hour, createDateInfo.minute, createDateInfo.second);
  } else {
    createDate = undefined;
  }

  signatureFieldRef.current = annotation;

  const completePageRotation = documentViewer.getCompleteRotation(signatureFieldRef.current.PageNumber);
  const pageRotationDegrees = getPageRotationDegrees(completePageRotation);
  const isRotated = pageRotationDegrees === 90 || pageRotationDegrees === 270;

  const clampedWidth = clampValue(annotation.Width, BANKID_SIGNING_AREA_MIN_WIDTH, BANKID_SIGNING_AREA_MAX_WIDTH);
  const clampedHeight = clampValue(
    annotation.Height,
    BANKID_SIGNING_AREA_MIN_HEIGHT,
    Math.min(BANKID_SIGNING_AREA_MAX_HEIGHT, Math.floor(clampedWidth / 3))
  );

  if (isRotated) {
    annotation.setHeight(clampedWidth);
    annotation.setWidth(clampedHeight);
  } else {
    annotation.setWidth(clampedWidth);
    annotation.setHeight(clampedHeight);
  }

  const signatureRect = signatureFieldRef.current.getRect();

  const pageWidth = documentViewer.getPageWidth(signatureFieldRef.current.PageNumber);
  const pageHeight = documentViewer.getPageHeight(signatureFieldRef.current.PageNumber);

  const xOffset =
    signatureRect.x1 < SIGNATURE_SAFE_BORDER
      ? SIGNATURE_SAFE_BORDER - signatureRect.x1
      : signatureRect.x2 > pageWidth - SIGNATURE_SAFE_BORDER
      ? pageWidth - signatureRect.x2 - SIGNATURE_SAFE_BORDER
      : 0;
  const yOffset =
    signatureRect.y1 < SIGNATURE_SAFE_BORDER
      ? SIGNATURE_SAFE_BORDER - signatureRect.y1
      : signatureRect.y2 > pageHeight - SIGNATURE_SAFE_BORDER
      ? pageHeight - signatureRect.y2 - SIGNATURE_SAFE_BORDER
      : 0;

  signatureRect.x1 += xOffset;
  signatureRect.x2 += xOffset;
  signatureRect.y1 += yOffset;
  signatureRect.y2 += yOffset;

  signatureFieldRef.current.setRect(signatureRect);

  onSignatureChanged({
    pageNumber: signatureFieldRef.current.PageNumber - 1,
    x1: signatureRect.x1,
    x2: signatureRect.x2,
    y1: signatureRect.y1,
    y2: signatureRect.y2,
    pageHeight: pageHeight,
    pageWidth: pageWidth,
    pageRotation: pageRotationDegrees,
    createDate,
  });
};

export const PdfSignature: FunctionComponent<Props> = ({ licenseKey, documentUrl, onSignatureChanged }) => {
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  const signatureFieldRef = useRef<Core.Annotations.Annotation>();

  const setDocument = useSameCallback(async () => {
    const instance = webViewerInstanceRef.current;

    if (instance) {
      setLoading(true);
      const { documentViewer, PDFNet, annotationManager } = instance.Core;

      const annotatedDocument = await PDFNet.PDFDoc.createFromURL(documentUrl);
      instance.UI.loadDocument(annotatedDocument);

      documentViewer.addEventListener('documentLoaded', () => {
        instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);
        instance.UI.setToolbarGroup('toolbarGroup-FillAndSign');
        instance.UI.setHeaderItems(function(header) {
          header.getHeader('toolbarGroup-FillAndSign').push({
            type: 'toolGroupButton',
            toolGroup: 'rectangleTools',
            dataElement: 'shapeToolGroupButton',
            title: 'annotation.signature',
            img: 'icon-tool-signature',
            cursor: 'crosshair',
          });
        });
        instance.UI.disableElements([
          'toolbarGroup-Annotate',
          'toolbarGroup-Edit',
          'toolbarGroup-Insert',
          'toolbarGroup-Shapes',
          'toolbarGroup-Measure',
          'toolbarGroup-Forms',
          'dateFreeTextToolButton',
          'rubberStampToolGroupButton',
          'dotStampToolButton',
          'checkStampToolButton',
          'crossStampToolButton',
          'toolsOverlay',
          'undoButton',
          'redoButton',
          'eraserToolButton',
          'freeTextToolGroupButton',
          'signatureToolGroupButton',
        ]);

        const tool = documentViewer.getTool(instance.Core.Tools.ToolNames.RECTANGLE);
        tool.setStyles((originalStyles: any) => {
          return {
            ...originalStyles,
            Opacity: 0.8,
            FillColor: new instance.Core.Annotations.Color(44, 79, 112, 0.2),
            StrokeColor: new instance.Core.Annotations.Color(22, 39, 64),
          };
        });

        tool.addEventListener('annotationAdded', async (annotation: Core.Annotations.Annotation) => {
          if (!!signatureFieldRef.current) {
            annotationManager.deleteAnnotations([signatureFieldRef.current]);
          }

          await setAnnotationProperties(
            annotatedDocument,
            annotation,
            signatureFieldRef,
            onSignatureChanged,
            documentViewer
          );

          annotation.disableRotationControl();
          annotation.NoResize = false;

          annotationManager.redrawAnnotation(annotation);
        });

        annotationManager.addEventListener(
          'annotationChanged',
          async (annotations: Core.Annotations.Annotation[], action: string) => {
            if (!signatureFieldRef.current) return;
            if (action === 'delete') {
              signatureFieldRef.current = undefined;
              onSignatureChanged(undefined);
              return;
            }

            const annotation = annotations[0];

            await setAnnotationProperties(
              annotatedDocument,
              annotation,
              signatureFieldRef,
              onSignatureChanged,
              documentViewer
            );

            annotationManager.redrawAnnotation(annotation);
          }
        );

        documentViewer.setToolMode(tool);
        documentViewer.addEventListener('toolModeUpdated', (toolMode: { name: Core.Tools.ToolNames }) => {
          if (toolMode.name !== instance.Core.Tools.ToolNames.RECTANGLE) {
            documentViewer.setToolMode(tool);
            instance.UI.setToolMode(instance.Core.Tools.ToolNames.RECTANGLE);
          }
        });

        setLoading(false);
      });
    }
  });

  const [containerRef, webViewerInstanceRef] = usePdfTron(licenseKey, intl, setDocument);

  useEffect(() => {
    setDocument();
  }, [documentUrl]);

  return (
    <SpinBoxCenter spinning={loading}>
      <div ref={containerRef} className={styles.workspace} />
    </SpinBoxCenter>
  );
};
