import { Modal } from 'antd';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { FormattedRelative } from 'react-intl';

type Props = { onIdleAction: () => void; timeoutTime: number; warningTime: number };

const IdleTimerActivityCheckModal: FunctionComponent<Props> = ({ onIdleAction, timeoutTime, warningTime }) => {
  const [confirmationPromtOpen, showConfirmationPromt, hideConfirmationPromt] = useBoolean(false);
  const [remaining, setRemaining] = useState<number>(0);

  const intl = useIntl();

  const onActive = (event: Event, idleTimer: IIdleTimer) => {
    if (idleTimer.isPrompted()) {
      hideConfirmationPromt();
    }
  };

  const onPrompt = () => {
    showConfirmationPromt();
  };

  const onIdle = () => {
    hideConfirmationPromt();
    onIdleAction();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout: 1000 * timeoutTime,
    promptBeforeIdle: 1000 * warningTime,
    onActive,
    onPrompt,
    onIdle,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleActivityConfirm = () => {
    activate();
  };

  useEffect(() => {
    activate();
  }, []);

  return confirmationPromtOpen ? (
    <Modal
      open
      onOk={handleActivityConfirm}
      onCancel={handleActivityConfirm}
      cancelButtonProps={HIDE_BUTTON_PROPS}
      okText={intl.formatMessage({ id: 'IdleTimerActivityCheck.modal.confirm' })}
      title={<Fmt id="IdleTimerActivityCheck.modal.title" />}
    >
      <Fmt
        id="IdleTimerActivityCheck.modal.message"
        values={{
          time: (
            <FormattedRelative
              value={moment()
                .add(remaining, 'second')
                .toDate()}
            />
          ),
        }}
      />
    </Modal>
  ) : null;
};

export default IdleTimerActivityCheckModal;
