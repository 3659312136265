import { BarsOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import ComponentDidMountCallback from 'components/ComponentDidMountCallback';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styles from './AdminPage.module.less';
import AdminPageStressTests from './AdminPageStressTest/AdminPageStressTest';

const MENU_GENERAL = 'general';
const MENU_USERS = 'users';
const MENU_TESTS = 'tests';

type Props = RouteComponentProps;
const AdminPage: FunctionComponent<Props> = (props) => {
  const [menuSelectedKey, setMenuSelectedKey] = useState<string>(null);
  const { match } = props;

  const page = (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <Menu mode="inline" selectedKeys={[menuSelectedKey]}>
          <Menu.Item key={MENU_GENERAL} icon={<HomeOutlined />}>
            <Link to={`${match.url}`}>
              <Fmt id="DirectorySettingsForm.tabs.general" />
            </Link>
          </Menu.Item>
          <Menu.Item key={MENU_USERS} icon={<UserOutlined />}>
            <Link to={`${match.url}/users`}>
              <Fmt id="general.users" />
            </Link>
          </Menu.Item>
          <Menu.Item key={MENU_TESTS} icon={<BarsOutlined />}>
            <Link to={`${match.url}/tests`}>
              <Fmt id="general.tests" />
            </Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className={styles.mainContent}>
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            render={() => (
              <ComponentDidMountCallback key={MENU_GENERAL} callback={() => setMenuSelectedKey(MENU_GENERAL)}>
                General
              </ComponentDidMountCallback>
            )}
          />
          <Route
            path={`${match.path}/users`}
            exact
            render={() => (
              <ComponentDidMountCallback key={MENU_USERS} callback={() => setMenuSelectedKey(MENU_USERS)}>
                User
              </ComponentDidMountCallback>
            )}
          />
          <Route
            path={`${match.path}/tests`}
            exact
            render={() => (
              <ComponentDidMountCallback key={MENU_TESTS} callback={() => setMenuSelectedKey(MENU_TESTS)}>
                <AdminPageStressTests />
              </ComponentDidMountCallback>
            )}
          />
          <Route
            render={() => (
              <ComponentDidMountCallback key={'null'} callback={() => setMenuSelectedKey(null)}>
                {<div>404 Not Found</div>}
              </ComponentDidMountCallback>
            )}
          />
        </Switch>
      </div>
    </div>
  );
  /*
  if (!currentProjectUser.isAdmin)
    return (
      <Alert
        style={{ margin: 24, width: '100%' }}
        message={<Fmt id="general.error" />}
        description={<Fmt id="serviceError.AdminAccessRequiredError" />}
        type="error"
        showIcon
      />
    );*/

  return <SideMenuActivator menuItemKey={SideMenuKey.ADMIN}>{page}</SideMenuActivator>;
};

export default AdminPage;
