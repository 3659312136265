import { ExternalApplicationSettingDto, ProjectHubDto } from 'api/completeApiInterfaces';
import { ListItemProps } from '../ListItem';

export enum AvailableAppEnum {
  Esticon = 'esticon',
  ConstructionJournal = 'constructionJournal',
  ExternalUrl = 'externalUrl',
  TextPage = 'textPage',
  DocumentWindow = 'documentWindow',
}

export enum ExternalAppSourceTypeEnum {
  Project = 'project',
  ExternalSource = 'externalSource',
}

export type ExternAppDetailProps =
  | { type: ExternalAppSourceTypeEnum.Project; project: ProjectHubDto; appId?: Guid }
  | { type: ExternalAppSourceTypeEnum.ExternalSource; externalAppSetting: ExternalApplicationSettingDto };

export type ExternAppListItem =
  | {
      type: ExternalAppSourceTypeEnum.Project;
      appType: AvailableAppEnum.Esticon;
      appId?: Guid;
      project: ProjectHubDto;
    }
  | {
      type: ExternalAppSourceTypeEnum.ExternalSource;
      appType:
        | AvailableAppEnum.DocumentWindow
        | AvailableAppEnum.ExternalUrl
        | AvailableAppEnum.TextPage
        | AvailableAppEnum.ConstructionJournal;
      externalAppSetting: ExternalApplicationSettingDto;
    };

export type ExternAppListItemProps = ListItemProps & ExternAppDetailProps;

export type AddExternAppsProps = {
  open: boolean;
  onAppAdded: (appId?: Guid) => void;
  onCancel: () => void;
};
