import { to } from 'api/await-to';
import { LabelCreateDto, LabelDto, LabelPatchDto, LabelRemoveConflictStrategyEnum } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const labelsApi = {
  listLabels: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<LabelDto[]>(`/labels`, { cancelToken })
    ),
  getLabelById: (labelId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<LabelDto>(`/labels/${labelId}`, { cancelToken })
    ),
  createLabel: (data: LabelCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<LabelDto>(`/labels`, data, { cancelToken })
    ),
  patchLabel: (labelId: Guid, data: LabelPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<LabelDto>(`/labels/${labelId}`, data, { cancelToken })
    ),
  deleteLabelById: (labelId: Guid, removeStrategy: LabelRemoveConflictStrategyEnum, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/labels/${labelId}`, { cancelToken, params: { removeStrategy } })),
};
