import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './Toolbar.module.less';

export type ToolbarProps = {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  className?: string;
  block?: boolean;
  padding?: boolean;
  styled?: boolean;
};

const Toolbar: FunctionComponent<ToolbarProps> = ({
  children,
  leftContent,
  rightContent,
  className,
  styled = true,
  block,
  padding,
}) => {
  return (
    <div
      className={classNames(
        styles.toolbar,
        block && styles.block,
        padding && styles.padding,
        styled && styles.styled,
        className
      )}
    >
      <div className={styles.docsToolbarLeft}>{leftContent}</div>
      <div className={styles.center}>{children}</div>
      <div className={styles.docsToolbarRight}>{rightContent}</div>
    </div>
  );
};

export default Toolbar;
