import React, { CSSProperties, FunctionComponent } from 'react';
import classnames from 'classnames';
import styles from './EsticonIcon.module.less';

type Props = {
  className?: string;
  style?: CSSProperties;
  monochrome?: boolean;
};

export const EsticonIcon: FunctionComponent<Props> = ({ className, style, monochrome }) => (
  <svg
    version="1.1"
    viewBox="0 0 64 64"
    className={classnames(styles.esticonIcon, monochrome && styles.monochrome, className)}
    style={style}
  >
    <defs>
      <style>
        {
          '.esticon_logo_a{fill:#16253f;}.esticon_logo_b{fill:#07b3c1;}.esticon_logo_c{fill:#008496;}.esticon_logo_d{fill:#eb5e39;}'
        }
      </style>
    </defs>
    <polygon className="esticon_logo_b" points="24 52 52 52 52 62 24 62" />
    <polygon className="esticon_logo_d" points="35.9 27 48 27 48 37 35.9 37" />
    <polygon className="esticon_logo_c" points="23 27 36 27 36 37 23 37" />
    <polygon className="esticon_logo_a" points="23 2 36 2 36 14 23 14" />
    <polygon className="esticon_logo_b" points="36 2 52 2 52 14 36 14" />
    <polygon className="esticon_logo_a" points="10 2 24 2 24 62 10 62" />
  </svg>
);
