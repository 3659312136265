import { Button } from 'antd';
import { DocumentAnnotationDto } from 'api/completeApiInterfaces';
import { useActiveProject } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { BcfTopic, getBcfTopicId } from 'utils/bcf/types';

type Props = {
  documentAnnotation: DocumentAnnotationDto;
};

export const AssignmentModelLink: FunctionComponent<Props> = ({ documentAnnotation }) => {
  const activeProject = useActiveProject();

  const bcfTopic = useMemo(() => {
    return documentAnnotation?.annotationData as BcfTopic;
  }, [documentAnnotation]);

  return bcfTopic && !!activeProject ? (
    <Link to={`/projects/${activeProject.id}/model/${documentAnnotation.documentId}/topic/${getBcfTopicId(bcfTopic)}`}>
      <Button type="primary">
        <Fmt id="ModelDetailPage.goToModel" />
      </Button>
    </Link>
  ) : null;
};
