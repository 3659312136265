import { Typography } from 'antd';
import { projectApi } from 'api/completeApi';
import {
  DirectoryDto,
  DirectorySubscriberEnumsWatchDirectoryEnum,
  DirectorySubscriberSetDto,
} from 'api/completeApiInterfaces';
import { GroupedToggles, ToggleNode, ToggleNodeEnum, ToggleNodeSwitch } from 'components/GroupedToggles/GroupedToggles';
import { Margin } from 'components/Margin/Margin';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import StackPanel from 'components/StackPanel';
import { SwitchLabeled } from 'components/SwitchLabeled/SwitchLabeled';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { isEqual, sortBy } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { ArrayFlagsOperation, setArrayFlags } from 'utils/arrayHelpers';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';

type WatchEnumType = DirectorySubscriberEnumsWatchDirectoryEnum;
const WatchEnum = DirectorySubscriberEnumsWatchDirectoryEnum;

const createToggleSwitch = (value: WatchEnumType): ToggleNodeSwitch<WatchEnumType> => ({
  type: ToggleNodeEnum.ToggleSwitch,
  value,
  label: `WatchSwitch.buttonTitle.${value}`,
});

const WATCH_TREE: ToggleNode<WatchEnumType>[] = [
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DirectoryWatchSettings.category.directoryChange',
    children: [
      WatchEnum.nameChange,
      WatchEnum.descriptionChange,
      WatchEnum.categoryChange,
      WatchEnum.permissionChange,
      WatchEnum.directoryDiscard,
      WatchEnum.directoryMove,
    ].map(createToggleSwitch),
  },
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DirectoryWatchSettings.category.subfolders',
    children: [WatchEnum.addSubdirectory, WatchEnum.removeSubdirectory].map(createToggleSwitch),
  },
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DirectoryWatchSettings.category.documentsChange',
    children: [WatchEnum.addDocument, WatchEnum.revisionChange, WatchEnum.removeDocument].map(createToggleSwitch),
  },
];

type Props = {
  directory: DirectoryDto;
  reloadDirectory: () => Promise<unknown>;
};

export const DirectoryWatchSettings: FunctionComponent<Props> = ({ directory, reloadDirectory }) => {
  const intl = useIntl();

  const isRoot = !directory.parentId;

  const directoryWatches = useMemo(() => directory.currentWatch || [], [directory.currentWatch]);
  const [selectedWatches, setSelectedWatches] = useState(directoryWatches);

  const defaultInheritWatch = !isRoot && !directory.currentWatch;
  const [inheritWatch, setInheritWatch] = useState(defaultInheritWatch);

  const parentWatches = useMemo(() => directory.inheritedWatch || [], [directory.inheritedWatch]);
  const shownWatches = inheritWatch ? parentWatches : selectedWatches;
  const shownWatchesSet = useMemo(() => new Set(shownWatches), [shownWatches]);

  const updateSelectedWatches = useCallback(
    (values: WatchEnumType[], operation: ArrayFlagsOperation) =>
      setSelectedWatches((selected) => setArrayFlags(selected, values, operation)),
    []
  );

  const isDirty = useMemo(
    () =>
      inheritWatch !== defaultInheritWatch ||
      (!inheritWatch && !isEqual(sortBy(directoryWatches), sortBy(selectedWatches))),
    [inheritWatch, defaultInheritWatch, directoryWatches, selectedWatches]
  );

  const [saving, setSaving] = useState(false);

  const saveWatches = async () => {
    const data: DirectorySubscriberSetDto = {
      interuptSubfolderInheritence: false,
      watchTypes: inheritWatch ? null : selectedWatches,
    };

    setSaving(true);
    const [err] = await projectApi.directories.primary.id.watch.post(directory.id, data);

    if (err) {
      setSaving(false);
      messageError(err, intl);
    } else {
      // TODO: save received directory dto instead? that might be bad however
      await reloadDirectory();
      setSaving(false);
    }
  };

  return (
    <StackPanel vertical scrollable>
      <Margin left top right>
        <Typography.Title level={4}>
          <Fmt id="DirectoryWatchSettings.title" />
        </Typography.Title>
        <SaveOrCancelButton
          onSave={saveWatches}
          onCancel={() => setSelectedWatches(directoryWatches)}
          disabled={!isDirty}
          loading={saving}
        />

        <Margin top>
          <SwitchLabeled
            checked={inheritWatch}
            onChange={setInheritWatch}
            disabled={isRoot}
            label={<Fmt id="DirectoryWatchSettings.inheritSettings" />}
          />
        </Margin>
      </Margin>

      <Margin left top right bottom>
        <SettingsBox>
          <Typography.Title level={4}>
            {intl.formatMessage({ id: 'DirectoryWatchSettings.watchParamsTitle' })}
          </Typography.Title>

          <GroupedToggles
            nodes={WATCH_TREE}
            value={shownWatchesSet}
            disabled={saving || inheritWatch}
            onChange={updateSelectedWatches}
          />
        </SettingsBox>
      </Margin>
    </StackPanel>
  );
};
