import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Progress, Typography } from 'antd';
import { DocumentDto, RevisionDto } from 'api/completeApiInterfaces';
import { checkUploadAccessDeniedError, hasUploadStateError } from 'api/project/upload/uploadHelpers';
import { UploadFileState, UploadStatus } from 'api/project/upload/uploadManager';
import StackPanel from 'components/StackPanel';
import { SwitchLabeled } from 'components/SwitchLabeled/SwitchLabeled';
import { DEBUG } from 'config/env';
import { useIntl } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { Dictionary, size } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import styles from './UploadState.module.less';

export type DocumentUploadStateData = {
  name: string;
  ready: boolean;
  error: string;
  filesSizes: number[];
  state: UploadFileState[];
  document: DocumentDto;
  revision: RevisionDto;
};

const UPLOAD_STEP_PRECISSION_ORDER = 100; // How small change will update total progress in relation (1/value) %

export const getDocumentsTotalProgress = (allProgresData: Dictionary<DocumentUploadStateData>): number => {
  let uploaded: number = 0;
  let total: number = 0;

  for (const documentId in allProgresData) {
    const documentProgressData = allProgresData[documentId];
    if (documentProgressData.state.length === 0) {
      documentProgressData.filesSizes.forEach((s) => (total += s));
    } else {
      documentProgressData.state.forEach((s, i) => {
        uploaded += getFileProgress(s, documentProgressData.filesSizes[i]);
        total += documentProgressData.filesSizes[i];
      });
    }
  }
  return Math.round((UPLOAD_STEP_PRECISSION_ORDER * (100 * uploaded)) / total) / UPLOAD_STEP_PRECISSION_ORDER;
};

export const getDocumentProgress = (documentProgressData: DocumentUploadStateData): number => {
  let uploaded: number = 0;
  let total: number = 0;
  if (documentProgressData.state.length === 0) {
    documentProgressData.filesSizes.forEach((s) => (total += s));
  } else {
    documentProgressData.state.forEach((s, i) => {
      uploaded += getFileProgress(s, documentProgressData.filesSizes[i]);
      total += documentProgressData.filesSizes[i] === 0 ? 1 : documentProgressData.filesSizes[i];
    });
  }
  DEBUG && console.log('uploaded:' + uploaded + ', total:' + total);
  return Math.round((100 * uploaded) / total);
};

export const getFileProgress = (fileProgressData: UploadFileState, size: number): number => {
  if (fileProgressData.uploaded === fileProgressData.count) return size === 0 ? 1 : size;
  return (size * fileProgressData.uploaded) / fileProgressData.count;
};

export const getDocumentName = (documentProgressData: DocumentUploadStateData): string => {
  if (!!documentProgressData.document && documentProgressData.document.name !== documentProgressData.name) {
    return documentProgressData.document.name + ' (' + documentProgressData.name + ')';
  }
  return documentProgressData.name;
};

export const getstatusFileMessageId = (status: UploadStatus): IntlMessageId => {
  switch (status) {
    case UploadStatus.waitingToStart:
      return 'DownloadManager.UploadStatus.waitingToStart';
    case UploadStatus.initiating:
      return 'DownloadManager.UploadStatus.initiating';
    case UploadStatus.waitingToUpload:
    case UploadStatus.uploading:
      return 'DownloadManager.UploadStatus.uploading';
    case UploadStatus.interrupted:
      return 'DownloadManager.UploadStatus.interrupted';
    case UploadStatus.finished:
      return 'DownloadManager.UploadStatus.finished';
    case UploadStatus.error:
    case UploadStatus.initializeError:
      return 'DownloadManager.UploadStatus.error';
    case UploadStatus.suspended:
      return 'DownloadManager.UploadStatus.suspended';
  }
  return null;
};

export const geFirstFileError = (state: UploadFileState[], intl: InjectedIntl): string => {
  for (const st of state) {
    if (hasUploadStateError(st)) {
      const accessDeniedError = checkUploadAccessDeniedError(st.error, intl);
      if (!!accessDeniedError) {
        return accessDeniedError;
      }
      return st.error.message;
    }
  }
  return null;
};

export const getstatusDocumentMessageId = (state: UploadFileState[]): IntlMessageId => {
  let ret: IntlMessageId = 'DownloadManager.UploadStatus.waitingToStart';
  let isFinished = state.length > 0;
  for (const st of state) {
    switch (st.status) {
      case UploadStatus.initiating:
        ret = 'DownloadManager.UploadStatus.initiating';
        isFinished = false;
        break;
      case UploadStatus.uploading:
      case UploadStatus.waitingToUpload:
        return 'DownloadManager.UploadStatus.uploading';
      case UploadStatus.interrupted:
        return 'DownloadManager.UploadStatus.interrupted';
      case UploadStatus.waitingToStart:
        isFinished = false;
        break;
      case UploadStatus.error:
      case UploadStatus.initializeError:
        return 'DownloadManager.UploadStatus.error';
      case UploadStatus.suspended:
        return 'DownloadManager.UploadStatus.suspended';
    }
  }
  if (isFinished) return 'DownloadManager.UploadStatus.finished';
  return ret;
};

type Props = InjectedIntlProps & {
  documentUploadStateData: Dictionary<DocumentUploadStateData>;
  visible: boolean;
  onContinue: (documentId: Guid) => void;
  onCancel: (documentId: Guid) => void;
  onPause: (documentId: Guid) => void;
};

export const UploadState: FunctionComponent<Props> = ({
  documentUploadStateData,
  onContinue,
  onCancel,
  onPause,
  visible,
}) => {
  const [filterErrors, setFilterErrors] = useState<boolean>(false);
  const intl = useIntl();

  if (!visible) return null;

  const uploadStates = Object.entries(documentUploadStateData);
  const disabled = !uploadStates.some((state) => state[1].state.some((st) => hasUploadStateError(st)));
  const totalUploads = uploadStates.length;
  const finishedUploads = uploadStates.filter((stateData) =>
    stateData[1].state.some((state) => state.status === UploadStatus.finished)
  ).length;

  const documentStates = uploadStates.map((documentProgressData, i) => {
    const hasProgressError = documentProgressData[1].state.some((st) => hasUploadStateError(st));

    if (filterErrors && !hasProgressError) return <></>;

    return (
      <div
        key={i}
        id={documentProgressData[0]}
        className={size(documentUploadStateData) === i + 1 ? styles.itemBottom : styles.item}
      >
        <Typography.Text>{getDocumentName(documentProgressData[1])}</Typography.Text>
        <Typography.Text style={{ paddingLeft: '5rem' }}>
          <Fmt id={getstatusDocumentMessageId(documentProgressData[1].state)} />
        </Typography.Text>
        {hasProgressError && (
          <Typography.Text type="danger" style={{ paddingLeft: '1rem' }}>
            {'(' + geFirstFileError(documentProgressData[1].state, intl) + ')'}
          </Typography.Text>
        )}
        <Progress
          style={{ flex: 1, marginRight: 8, marginTop: 3 }}
          percent={getDocumentProgress(documentProgressData[1])}
          status={documentProgressData[1].ready ? 'success' : !documentProgressData[1].error ? 'active' : 'normal'}
        />
      </div>
    );
  });

  return (
    <>
      <StackPanel className={styles.uploadStats}>
        <div>
          {!disabled && (
            <SwitchLabeled
              checked={filterErrors}
              label={<Fmt id="UploadState.toolbar.showOnlyErrors" />}
              onChange={setFilterErrors}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          )}
        </div>
        <div>
          <Fmt id="general.completed" /> {finishedUploads}/{totalUploads}
        </div>
      </StackPanel>
      <StackPanel vertical scrollable className={styles.itemList}>
        {documentStates}
      </StackPanel>
    </>
  );
};
