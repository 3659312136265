import { Collapse, Typography } from 'antd';
import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import StackPanel from 'components/StackPanel';
import { useCurrentAppUser, useIntl } from 'hooks';
import { useInitialLocalStorage } from 'hooks/useDefaultLocalStorage';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './ReportListPage.module.less';

export enum ReportCatagoriesEnum {
  General = 'General',
}
export const reportCategoriesMap: Record<ReportCatagoriesEnum, OrgExtendedPermissionEnum[]> = {
  [ReportCatagoriesEnum.General]: [
    OrgExtendedPermissionEnum.financialOverviewByUnit,
    OrgExtendedPermissionEnum.projectsInRealisationOverview,
  ],
};

const LOCAL_STORAGE_REPORT_LIST_OPENNED_ORGANIZATIONS_KEY = 'ReportListOpennedOrganizations';

const ReportsList: FunctionComponent = () => {
  const currentAppUser = useCurrentAppUser();
  const history = useHistory();
  const intl = useIntl();
  const { url } = useRouteMatch();

  const [opennedKeys, initialOpennedKeys, saveOpennedKeys] = useInitialLocalStorage(
    LOCAL_STORAGE_REPORT_LIST_OPENNED_ORGANIZATIONS_KEY,
    currentAppUser.organizationUsers?.map((orgUser) => orgUser.organization.id)
  );

  const handleCollapseOnChange = useCallback((keys: string | string[]) => {
    if (typeof keys === 'string') {
      saveOpennedKeys([keys]);
    } else {
      saveOpennedKeys(keys);
    }
  }, []);

  //TODO: Add filter to show only organizations where user have permission and org have firmId
  const availableOrganizationsForReport = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.filter((orgUser) => !!orgUser.organization.esticonFirmIds?.length)
        .map((orgUser) => orgUser.organization) || [],
    [currentAppUser]
  );

  const renderReportCategory = useCallback(
    (organizationId: Guid, category: ReportCatagoriesEnum, reports: OrgExtendedPermissionEnum[]) => {
      const orgUserPermissions =
        currentAppUser.organizationUsers.find((user) => user.organization.id === organizationId)?.extendedPermissions ||
        [];

      return (
        <GeneralSettingsContainer title={intl.formatMessage({ id: `Reporting.reportCategory.name.${category}` })}>
          {reports.map(
            (report) =>
              orgUserPermissions.some((permission) => permission.permissionType === report) && (
                <GeneralSettingsItem
                  key={report}
                  title={<Fmt id={`Reporting.reportType.name.${report}`} />}
                  selectable
                  onClick={() => {
                    history.push(`${url}/${organizationId}/${report}`);
                  }}
                />
              )
          )}
        </GeneralSettingsContainer>
      );
    },
    []
  );

  return (
    <div className={styles.results}>
      <StackPanel vertical scrollable>
        <Collapse defaultActiveKey={initialOpennedKeys} onChange={handleCollapseOnChange}>
          {availableOrganizationsForReport.map((organization) => (
            <Collapse.Panel
              key={organization.id}
              header={
                <>
                  <Typography.Text strong>{organization.name}</Typography.Text>
                  <br />
                  <Typography.Text>{organization.description}</Typography.Text>
                </>
              }
            >
              {Object.entries(
                reportCategoriesMap
              ).map(([category, reports]: [ReportCatagoriesEnum, OrgExtendedPermissionEnum[]]) =>
                renderReportCategory(organization.id, category, reports)
              )}
            </Collapse.Panel>
          ))}
        </Collapse>
      </StackPanel>
    </div>
  );
};

export default React.memo(ReportsList);
