import { to } from 'api/await-to';
import { AppUserLangSetDto, AppUserProfileDto, AppUserReportItem } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseMasterApi } from '../baseMasterApi';

export const appUsersApi = {
  getCurrentUser: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AppUserProfileDto>(`/users/me`, { cancelToken })
    ),
  getUsersList: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AppUserProfileDto[]>(`/users`, { cancelToken })
    ),
  getUserReports: (userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AppUserReportItem[]>(`/users/${userId}/reports`, { cancelToken })
    ),
  setUserReports: (userId: Guid, reports: AppUserReportItem[], cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`/users/${userId}/reports`, reports, { cancelToken })),
  setAppUserLang: (language: AppUserLangSetDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<AppUserProfileDto>(`/users/setlang`, language, { cancelToken })
    ),
};
