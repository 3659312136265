import {
  CloseOutlined,
  ExportOutlined,
  FileAddOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Space, message } from 'antd';
import { api } from 'api';
import { ButtonsSelect, ButtonsSelectOption } from 'components/ButtonsSelect/ButtonsSelect';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar from 'components/Toolbar/Toolbar';
import { FiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToggleButton } from 'components/filters/render/FilterToggleButton/FilterToggleButton';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { useApiAction } from 'hooks/useApiAction';
import { Fmt, InjectedIntlProps } from 'locale';
import { SelectedItemsContext } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { processApiError } from 'utils';

export enum ProjectListViewTypeEnum {
  filter = 'filter',
  collapse = 'collapse',
}

type Props = InjectedIntlProps & {
  selectedFilesIds: Set<Guid>;
  onProjectUpload?: () => void;
  reloadProjects?: () => void;
  addProjectDisabled?: boolean;
  viewType: ProjectListViewTypeEnum;
  setViewType: React.Dispatch<React.SetStateAction<ProjectListViewTypeEnum>>;
  showViewTypeButton: boolean;
  exportProjectListDisabled: boolean;
  showExportModal: () => void;
};

const ProjectsListToolbar: FunctionComponent<Props> = ({
  selectedFilesIds,
  onProjectUpload,
  reloadProjects,
  addProjectDisabled,
  viewType,
  setViewType,
  showViewTypeButton,
  exportProjectListDisabled,
  showExportModal,
}) => {
  const intl = useIntl();

  const [setOnProjects, setOnLoading] = useApiAction(
    (ct) => {
      return api.master.projects.createFavoriteProject(Array.from(selectedFilesIds), ct);
    },
    {
      fetchCallback: () => {
        message.success(intl.formatMessage({ id: 'FavoriteButton.addSuccess' }));
        reloadProjects();
      },
      errorCallback: (err) => {
        const error = processApiError(err);
        message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      },
    }
  );

  const [setOffProjects, setOffLoading] = useApiAction(
    (ct) => {
      return api.master.projects.deleteFromFavoriteProjects(Array.from(selectedFilesIds), ct);
    },
    {
      fetchCallback: () => {
        message.success(intl.formatMessage({ id: 'FavoriteButton.removeSuccess' }));
        reloadProjects();
      },
      errorCallback: (err) => {
        const error = processApiError(err);
        message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      },
    }
  );

  const modesOptions: ButtonsSelectOption<ProjectListViewTypeEnum>[] = useMemo(() => {
    return [
      {
        label: <Fmt id="general.projects" />,
        tooltip: <Fmt id="general.projectList" />,
        key: ProjectListViewTypeEnum.filter,
      },
      {
        label: <Fmt id="general.organizations" />,
        tooltip: <Fmt id="general.groupedByOrganizations" />,
        key: ProjectListViewTypeEnum.collapse,
      },
    ];
  }, []);

  const { selectAll, deselectAll, isAllSelected, isAnyItemSelected, totalItemsCount } = useContext(
    SelectedItemsContext
  );

  const { setOrder, order, orderOptions, activeFiltersCount, isFilterActive, toggleFilters } = useContext(
    FiltersContext
  );

  const items: ToolbarItemProps[] = useMemo(
    () =>
      isAnyItemSelected
        ? [
            {
              key: 'addToFavorites',
              label: 'ProjectsListPage.addToFavorites',
              onClick: setOnProjects,
              disabled: addProjectDisabled,
              icon: setOnLoading ? <LoadingOutlined /> : <StarFilled />,
            },
            {
              key: 'removeFromFavorites',
              label: 'ProjectsListPage.removeFromFavorites',
              onClick: setOffProjects,
              disabled: addProjectDisabled,
              icon: setOffLoading ? <LoadingOutlined /> : <StarOutlined />,
            },
          ]
        : [
            {
              key: 'general.createNewProject',
              label: 'general.newProject',
              onClick: onProjectUpload,
              disabled: addProjectDisabled,
              icon: <FileAddOutlined />,
              tooltip: intl.formatMessage({ id: 'general.createNewProject' }),
            },
            {
              key: 'general.export',
              label: 'general.export',
              onClick: showExportModal,
              disabled: exportProjectListDisabled,
              icon: <ExportOutlined />,
              tooltip: intl.formatMessage({ id: 'general.export.tooltip' }),
            },
          ],
    [isAnyItemSelected, onProjectUpload, addProjectDisabled, setOffProjects, setOnProjects, setOnLoading, setOffLoading]
  );

  return (
    <>
      <Toolbar
        styled
        leftContent={
          <>
            <DocumentCheckbox
              onClickCheckbox={isAllSelected ? deselectAll : selectAll}
              selected={isAllSelected}
              semiSelected={isAnyItemSelected}
              tooltip={
                isAllSelected ? <Fmt id="DocumentsGridHeader.deselect" /> : <Fmt id="DocumentsGridHeader.select" />
              }
            />
            {isAnyItemSelected && (
              <CommonHubTooltip title={<Fmt id="general.docMenu.selected.tooltip" />}>
                <Button type="link" onClick={deselectAll} icon={<CloseOutlined />}>
                  {`${selectedFilesIds?.size} / ${totalItemsCount}`}
                </Button>
              </CommonHubTooltip>
            )}
          </>
        }
        rightContent={
          <FlowLayout>
            {showViewTypeButton && (
              <ButtonsSelect<ProjectListViewTypeEnum>
                onChange={setViewType}
                defaultSelectedKey={ProjectListViewTypeEnum.filter}
                options={modesOptions}
                selectedKey={viewType}
                size="small"
              />
            )}
            <Space.Compact>
              <OrderSelect setOrder={setOrder} order={order} orderOptions={orderOptions} showTooltip />
              <FilterToggleButton
                onClick={toggleFilters}
                activeFiltersCount={activeFiltersCount}
                isOn={isFilterActive}
                showTooltip
              />
            </Space.Compact>
          </FlowLayout>
        }
      >
        <ShrinkableToolbar items={items} />
      </Toolbar>
    </>
  );
};

export default injectIntl(ProjectsListToolbar);
