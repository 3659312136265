import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Select } from 'antd';
import { AccessLevelEnum, DirectoryContentDto } from 'api/completeApiInterfaces';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { useCurrentProjectUser } from 'hooks';
import { InjectedIntlProps } from 'locale';
import React, { useCallback, useEffect } from 'react';
import { requiredRule } from 'utils/formHelpers';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { DiscussionRelationOption } from '../DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';

export type DiscussionAssignAttachmentFormData = {
  selectedDocuments: DirectoryContentDto[];
  discussionRelation?: string | number;
};

type Props = FormComponentProps<DiscussionAssignAttachmentFormData> &
  InjectedIntlProps & {
    discussionRelationOptions?: DiscussionRelationOption<string | number>[];
  };

const DiscussionAssignAttachmentForm = React.forwardRef<unknown, Props>(
  ({ intl, form, discussionRelationOptions }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const currentUser = useCurrentProjectUser();

    const disabledDocuments = useCallback(
      (document: DirectoryContentDto, directory: ConnectedDirectory) => {
        const currentAccessLevel = directory.currentAccessLevel;
        if (currentAccessLevel !== AccessLevelEnum.admin && currentAccessLevel !== AccessLevelEnum.write) {
          return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.noWriteAccess' });
        }
        if (document.reservedBy && document.reservedBy.id !== currentUser.id) {
          return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notUnlocked' });
        }
        if (document.ownedBy && document.ownedBy.user?.id !== currentUser.id) {
          return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notOwner' });
        }
        return false;
      },
      [currentUser, intl]
    );

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'DiscussionAssignAttachmentForm.assignedDocuments' })}>
          {form.getFieldDecorator<DiscussionAssignAttachmentFormData>('selectedDocuments', {
            initialValue: [],
          })(
            <DocumentsSelectMultipleFormItem
              disabledDocuments={disabledDocuments}
              titleId="DiscussionAssignAttachmentForm.assignedDocuments.modalTitle"
            />
          )}
        </Form.Item>

        {discussionRelationOptions?.length && (
          <Form.Item label={intl.formatMessage({ id: 'AttachmentCreateFormModal.form.item.relation.label' })}>
            {form.getFieldDecorator<DiscussionAssignAttachmentFormData>('discussionRelation', {
              rules: [requiredRule('DocumentCreateForm.form.items.relation.rules.required', true)],
            })(
              <Select dropdownMatchSelectWidth={false}>
                {discussionRelationOptions.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.icon}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(DiscussionAssignAttachmentForm);
