import { InputRef } from 'antd';
import { api } from 'api';
import { ApiPromise } from 'api/await-to';
import { RevisionCreateDto, RevisionDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { injectDocumentIdsToCreateDto } from 'api/project/upload/uploadHelpers';
import { UploadData, UploadFile, UploadFileType, UploadProcessData } from 'api/project/upload/uploadManager';
import { NoApplicableNewDocumentStateAlert } from 'components/NoApplicableNewDocumentAlert/NoApplicableNewDocumentStateAlert';
import { AnnotationData } from 'components/PdfAnnotation/PdfAnnotation';
import { mapAnnotationDataToAnnotationSaveDto } from 'components/PdfAnnotation/PdfAnnotationModal';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import { useFocus } from 'hooks/useFocus';
import React, { useCallback, useEffect, useState } from 'react';
import { createRevisionOrDocumentAvailableStates } from 'utils/documentStateUtils';
import { modalConfirm } from 'utils/modalConfirm';
import uuid from 'uuid';
import { CreateDocumentUploadData } from '../DocumentCreateForm/DocumentCreateFormModal';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler, parseErrorMessage } from '../FormModalWrapper';
import RevisionCreateForm, { RevisionCreateFormData } from './RevisionCreateForm';

export type CreateRevisionUploadData<T> = (
  data: RevisionCreateFormData
) => {
  createSaveRequest: (data: UploadProcessData<T>) => ApiPromise<T>;
  temporary?: boolean;
};

export const revisionCreateFormDataToCreateDto = (data: RevisionCreateFormData): RevisionCreateDto => ({
  description: data.description,
  state: data.state,
  secondaryDocumentsAdd: null,
  uploadId: null,
  metaData: undefined,
  annotations: data.annotations,
});

export const createRevisionUploadData = (documentId: Guid): CreateDocumentUploadData<RevisionDto> => (data) => {
  const createDto = revisionCreateFormDataToCreateDto(data);
  return {
    createSaveRequest: (data) =>
      api.project.documents.createDocumentRevision(
        documentId,
        injectDocumentIdsToCreateDto(createDto, data),
        data.ctSource.token
      ),
  };
};

type Props<T> = FormModalProps<RevisionCreateFormData, T> & {
  initialPrimaryFile?: File;
  annotations?: AnnotationData[];
  currentRevisionFileName: string;
  availableStates?: WorkflowStateEnum[];
  createUploadData: CreateRevisionUploadData<T>;
  allowSignedDocumentAttachment?: boolean;
};

function getFileExtensions(currentRevisionFileName: string, previousName: string) {
  const oldExt = currentRevisionFileName?.split('.').pop();
  const newExt = previousName?.split('.').pop();
  return {
    areExtensionDifferent: newExt?.toLowerCase() !== oldExt?.toLowerCase(),
    newExt,
    oldExt,
  };
}

const RevisionCreateFormModal = <T,>(props: Props<T>): JSX.Element => {
  const {
    onSubmit,
    onClose,
    initialPrimaryFile,
    annotations,
    currentRevisionFileName,
    availableStates,
    createUploadData,
    visible,
    allowSignedDocumentAttachment = true,
    ...restProps
  } = props;
  const intl = useIntl();

  const { lastUploadManager, error, loading, submitTextId, uploadProgress, resetAll, startUpload } = useFileUpload();

  const [usefulNewDocumentStatuses, setUsefulNewDocumentStatuses] = useState<WorkflowStateEnum[]>();

  const currentUser = useCurrentProjectUser();

  useEffect(() => {
    setUsefulNewDocumentStatuses(createRevisionOrDocumentAvailableStates(currentUser));
  }, [currentUser]);

  const handleUploadFinish = useCallback(
    async (values: RevisionCreateFormData, result: T): Promise<void> => {
      await onSubmit(values, result);
      resetAll();
    },
    [onSubmit]
  );

  const handleSubmit: FormSubmitHandler<RevisionCreateFormData> = useCallback(
    async (values) => {
      const { areExtensionDifferent, newExt, oldExt } = getFileExtensions(
        currentRevisionFileName,
        values.primaryFile.name
      );

      if (
        areExtensionDifferent &&
        !(await modalConfirm({
          title: intl.formatMessage({ id: 'RevisionCreateFormModal.fileTypeReplacement' }),
          content: intl.formatMessage(
            { id: 'RevisionCreateFormModal.fileTypeReplacement.description' },
            { newExt, oldExt }
          ),
        }))
      ) {
        return;
      }

      const secondaryFiles = values.secondaryFiles ? values.secondaryFiles.fileList : [];
      const signedDocument = values.signedDocumentFile;

      const uploadData: UploadData<T> = {
        ...createUploadData({
          ...values,
          annotations: annotations && annotations.map(mapAnnotationDataToAnnotationSaveDto),
        }),
        onFinish: async (result) => {
          await handleUploadFinish(values, result);
        },
      };

      const uploadFiles: UploadFile[] = [
        {
          id: uuid(),
          fileName: values.primaryFile.name,
          blob: values.primaryFile,
          fileType: UploadFileType.primaryFile,
        },
        signedDocument && {
          id: uuid(),
          fileName: signedDocument.name,
          blob: signedDocument,
          fileType: UploadFileType.signedDocument,
        },
        ...secondaryFiles.map(
          (file): UploadFile => {
            const blob = file.originFileObj;
            return {
              id: uuid(),
              fileName: blob instanceof File ? blob.name : file.name,
              blob: file.originFileObj,
              fileType: UploadFileType.attachment,
            };
          }
        ),
      ].filter((file) => !!file);

      startUpload(uploadData, uploadFiles);

      return null;
    },
    [handleUploadFinish, createUploadData, startUpload, currentRevisionFileName, annotations, intl]
  );

  const handleClose = useCallback(() => {
    resetAll();
    onClose();
  }, [resetAll, onClose]);

  const { setInputRef } = useFocus<InputRef>(visible);

  const handleAlertClose = () => {
    setUsefulNewDocumentStatuses(undefined);
    onClose();
  };

  return (
    <>
      {visible && !!usefulNewDocumentStatuses && !usefulNewDocumentStatuses?.length && (
        <NoApplicableNewDocumentStateAlert
          usefulNewDocumentStatuses={usefulNewDocumentStatuses}
          handleAlertClose={handleAlertClose}
        />
      )}
      <FormModalWrapper
        onSubmit={handleSubmit}
        progress={uploadProgress}
        onClose={handleClose}
        errorMessage={parseErrorMessage(error)}
        showProgress={lastUploadManager.current !== null}
        submitTextId={submitTextId}
        forceLoading={loading}
        titleId="RevisionCreateFormModal.title"
        visible={visible && !!usefulNewDocumentStatuses?.length}
        {...restProps}
      >
        {({ intl, formRef }) => (
          <RevisionCreateForm
            intl={intl}
            wrappedComponentRef={formRef}
            initialPrimaryFile={initialPrimaryFile}
            availableStates={availableStates}
            allowSignedDocumentAttachment={allowSignedDocumentAttachment}
            setRef={setInputRef}
            usefulNewDocumentStatuses={usefulNewDocumentStatuses}
          />
        )}
      </FormModalWrapper>
    </>
  );
};

export default RevisionCreateFormModal;
