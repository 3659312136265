import { Modal } from 'antd';
import { InjectedIntl } from 'locale';

export const modalFormConfirmClose = (intl: InjectedIntl, onOk: () => void, onCancel?: () => void) =>
  Modal.confirm({
    title: intl.formatMessage({ id: 'modalFormConfirmClose.title' }),
    content: intl.formatMessage({ id: 'modalFormConfirmClose.content' }),
    onOk,
    onCancel,
  });
