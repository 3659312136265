import { userManager } from 'config';
import LoadingPage from 'pages/LoadingPage';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps & {
  userAuthenticated: boolean;
};

class SignInPage extends Component<Props> {
  componentDidMount() {
    this.signIn();
  }

  signIn = () => {
    const locationFrom =
      this.props.location && this.props.location.state && (this.props.location.state as { from: any }).from;
    const from = locationFrom ? locationFrom : { pathname: '/' };

    userManager
      .signinSilent()
      .then(() => {
        this.props.history.replace(from);
      })
      .catch(() =>
        userManager.signinRedirect({
          state: { redirectUrl: from },
        })
      );
  };

  render() {
    return <LoadingPage />;
  }
}

export default SignInPage;
