import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './SpinBoxCenter.module.less';

type Props = SpinProps & {
  flex?: boolean;
  overflowVisible?: boolean;
  flexDirection?: 'horizontal' | 'vertical';
};

const SpinBoxCenter: FunctionComponent<Props> = ({
  children,
  flex,
  overflowVisible,
  flexDirection = 'vertical',
  ...restProps
}) => (
  <Spin
    className={classNames(styles.spin, overflowVisible && styles.overflowVisible)}
    wrapperClassName={classNames(
      styles.spinWrapper,
      flex && styles.flex,
      flexDirection === 'vertical' && styles.vertical,
      overflowVisible && styles.overflowVisible
    )}
    {...restProps}
  >
    {children}
  </Spin>
);
export default SpinBoxCenter;
