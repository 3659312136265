import { Modal, Progress, Typography } from 'antd';
import { checkUploadAccessDeniedError } from 'api/project/upload/uploadHelpers';
import { UploadData, UploadFile, UploadFileType, UploadProcessData } from 'api/project/upload/uploadManager';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import StackPanel from 'components/StackPanel';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { DiscussionAttachmentUploadList } from './DiscussionAttachmentUploadList';

type Props = {
  visible: boolean;
  onClose: () => void;
  titleId?: IntlMessageId;
  stagedAttachments: StagedAttachment[];
  createUploadData: () => UploadData;
};

export const DiscussionAttachmentUploadModal: FunctionComponent<Props> = (props) => {
  const { visible, onClose, titleId, stagedAttachments, createUploadData } = props;

  const { error, loading, submitTextId, uploadProgress, resetAll, startUpload } = useFileUpload();
  const intl = useIntl();

  const handleSubmit = useCallback(() => {
    if (!stagedAttachments?.length) {
      return;
    }

    const { onFinish, ...restUploadData } = createUploadData();
    const attachmentUploadData = {
      ...restUploadData,
      onFinish: async (response: unknown, data: UploadProcessData) => {
        await onFinish(response, data);
        resetAll();
      },
    };

    const uploadFiles = stagedAttachments.map(
      (attachment): UploadFile => ({
        id: uuid(),
        fileName: attachment.file.name,
        blob: attachment.file,
        fileType: UploadFileType.attachment,
      })
    );

    startUpload(attachmentUploadData, uploadFiles);
  }, [createUploadData, startUpload, stagedAttachments]);

  useEffect(() => {
    if (visible) {
      handleSubmit();
    }
  }, [visible]);

  const formatedErrorMessage = useMemo(() => {
    if (!error) return undefined;
    const accessDeniedError = checkUploadAccessDeniedError(error, intl);
    if (!!accessDeniedError) return accessDeniedError;
    return error.message;
  }, [error, intl]);

  const handleClose = () => {
    resetAll();
    onClose();
  };

  return (
    <Modal
      open={visible}
      onOk={handleSubmit}
      onCancel={handleClose}
      title={<Fmt id={titleId || 'AttachmentCreateFormModal.title'} />}
      width={800}
      okText={<Fmt id={submitTextId} />}
      okButtonProps={{
        loading,
      }}
    >
      <StackPanel vertical>
        <DiscussionAttachmentUploadList stagedAttachments={stagedAttachments} />

        <FlowLayout>
          {!formatedErrorMessage ? (
            <Progress
              style={{ flex: 1, marginRight: 8, marginTop: 3 }}
              percent={uploadProgress}
              status={uploadProgress === 100 ? 'success' : loading ? 'active' : 'normal'}
            />
          ) : !!formatedErrorMessage ? (
            <Typography.Text type="danger" style={{ flex: 1, marginRight: 8, marginTop: 3, textAlign: 'left' }}>
              {formatedErrorMessage}
            </Typography.Text>
          ) : (
            <div style={{ flex: 1 }} />
          )}
        </FlowLayout>
      </StackPanel>
    </Modal>
  );
};
