import { InputRef } from 'antd';
import { api } from 'api';
import { DirectoryLinkPatchDto } from 'api/completeApiInterfaces';
import { useFocus } from 'hooks/useFocus';
import { trim } from 'lodash';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryLinkEditForm, { DirectoryLinkEditFormData } from './DirectoryLinkEditForm';

type Props = FormModalProps<void> & {
  directoryLinkId: Guid;
  name: string;
  validateUniqueName: (name: string) => boolean;
};

const DirectoryLinkEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, name, validateUniqueName, directoryLinkId, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DirectoryLinkEditFormData> = async (values) => {
    const data: DirectoryLinkPatchDto = {
      name: trim(values.name),
    };

    const [err] = await api.project.directories.patchDirectoryLink(directoryLinkId, data);
    if (err) return err;

    await onSubmit();
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId="DirectoryLinkEditFormModal.title" {...restProps}>
      {({ intl, formRef }) => (
        <DirectoryLinkEditForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          initialData={{ name }}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default DirectoryLinkEditFormModal;
