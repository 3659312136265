import { PersistentUiStoreModel, PersistentUiStoreModelState } from 'store/models/storeModelinterfaces';

const persistentUiStoreDefaults: PersistentUiStoreModelState = {
  openCreateAssignmentModal: false,
  setFilterToSearchByLabelId: null,
};

export const persistentUiStoreModel: PersistentUiStoreModel = {
  state: persistentUiStoreDefaults,
  reducers: {
    setOpenCreateAssignmentModal: (state, openCreateAssignmentModal) => ({ ...state, openCreateAssignmentModal }),
    setFilterToSearchByLabelId: (state, setFilterToSearchByLabelId) => ({ ...state, setFilterToSearchByLabelId }),
  },
};
