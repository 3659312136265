import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, Modal, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { api } from 'api';
import {
  ProjectUserProfileStatusEnum,
  ProjectUserSetAdminDto,
  ProjectUsersRequestDto,
  SortOrder,
  SuspendAllUsersErrorsDto,
} from 'api/completeApiInterfaces';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import { Margin } from 'components/Margin/Margin';
import { ReinviteButton } from 'components/ReinviteButton/ReinviteButton';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { createFrontendSingleTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { COMMON_SETTING_MODAL_WIDTH, HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useFrontendFilters, useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { textComparer } from 'utils/comparators';
import { UserToChangeHisProjectsSettingParams } from './OrganizationUsersListPanel';

type Props = {
  onClose: () => void;
  open: boolean;
  userToChangeHisProjectsSettingParams: UserToChangeHisProjectsSettingParams;
  organizationId: Guid;
};

type CurrentUserProjectsParams = {
  projectId: Guid;
  projectName: string;
  projectUserId: Guid;
  projectUserIsAdmin: boolean;
  projectUserStatus: ProjectUserProfileStatusEnum;
  projectUserIsConfirmed: boolean;
  projectUserIsLastAdmin: boolean;
};

const USERS_USEFUL_PROJECT_FILTER: FrontendFilter<CurrentUserProjectsParams>[] = [
  createFrontendSingleTextFilter('name', (project) => project.projectName, {
    label: <Fmt id="general.name" />,
  }),
];
const USERS_USEFUL_PROJECT_ORDER_OPTIONS: FrontendOrderOption<CurrentUserProjectsParams>[] = [
  {
    key: 'name',
    label: <Fmt id="general.name" />,
    compare: textComparer.map((project) => project.projectName),
    defaultOrder: SortOrder.asc,
  },
];

export const OrganizationUserSettingOnProjectsModal: FunctionComponent<Props> = ({
  open,
  userToChangeHisProjectsSettingParams,
  onClose,
  organizationId,
}) => {
  const [isReinvited, setIsReinvited] = useState(false);
  const [suspendAllUsersErrors, setSuspendAllUsersErrors] = useState<SuspendAllUsersErrorsDto[]>(undefined);
  const intl = useIntl();

  const { orgUserId, userName, language, lastAccessDate } = userToChangeHisProjectsSettingParams;

  const [userProjectsReport, userProjectsReportError, userProjectsReportLoading, loadUserProjectsReport] = useApiData(
    (ct) => api.master.organization.getOrgUserProjectsReport(orgUserId, ct),
    {
      autoload: true,
    }
  );

  useEffect(() => {
    loadUserProjectsReport();
  }, [orgUserId]);

  const isMounted = useIsMounted();

  const reinviteUser = useCallback(async () => {
    const [err] = await api.master.organization.reinvite({
      appUserId: userProjectsReport?.orgUser.appUserProfile.id,
      organizationtId: organizationId,
    });
    if (err) {
      messageError(err, intl);
    } else {
      if (isMounted.current) {
        setIsReinvited(true);
        loadUserProjectsReport();
      }
    }
  }, [intl, loadUserProjectsReport, userProjectsReport?.orgUser.appUserProfile.id]);

  const suspendOnAllProjects = useCallback(async () => {
    const [err, res] = await api.master.organization.suspendUserInAllOrgProjects(orgUserId);
    if (err) {
      messageError(err, intl);
    } else {
      if (res.data.errors.length) setSuspendAllUsersErrors(res.data.errors);
      loadUserProjectsReport();
    }
  }, [intl, loadUserProjectsReport, orgUserId]);

  const getSuspendErrorModalProjectName = useCallback(
    (err: SuspendAllUsersErrorsDto) => {
      return userProjectsReport?.projects.find((project) => project.project.id === err.projectId).project.name;
    },
    [userProjectsReport?.projects]
  );

  const suspendErrorsModalContent = useMemo(() => {
    if (!suspendAllUsersErrors?.length) return null;
    return (
      <>
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              <Fmt id={'OrganizationUserSettingOnProjectsModal.noSuspendReason.projectName'} />
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>
              <Fmt id={'OrganizationUserSettingOnProjectsModal.noSuspendReason.reason'} />
            </Typography.Text>
          </Col>
        </Row>
        {suspendAllUsersErrors?.map((err) => (
          <Row key={err.projectId}>
            <Col span={12}>
              <CommonHubEllipsisText title={getSuspendErrorModalProjectName(err)}>
                {getSuspendErrorModalProjectName(err)}
              </CommonHubEllipsisText>
            </Col>
            <Col span={12}>
              <Fmt id={`OrganizationUserSettingOnProjectsModal.noSuspendReason.${err.error}`} />
            </Col>
          </Row>
        ))}
      </>
    );
  }, [suspendAllUsersErrors, userProjectsReport]);

  const removeInvitedProjectUser = useCallback(
    async (userId: Guid, projectId: Guid) => {
      const [err] = await api.master.organization.deleteInvitedProjectUser(projectId, userId);
      if (err) {
        messageError(err, intl);
      } else {
        loadUserProjectsReport();
      }
    },

    [intl, loadUserProjectsReport]
  );

  const setIsAdmin = useCallback(
    async (isAdmin: boolean, userId: Guid, projectId: Guid) => {
      const data: ProjectUserSetAdminDto = { isAdmin };
      const [err] = await api.master.organization.setadmin(projectId, userId, data);
      if (err) {
        messageError(err, intl);
      } else {
        loadUserProjectsReport();
      }
    },
    [intl, loadUserProjectsReport]
  );

  const suspendUser = useCallback(
    async (userId: Guid, projectId: Guid) => {
      const [err] = await api.master.organization.suspendUser(projectId, userId);
      if (err) {
        messageError(err, intl);
      } else {
        loadUserProjectsReport();
      }
    },
    [intl, loadUserProjectsReport]
  );

  const activateUser = useCallback(
    async (userId: Guid, projectId: Guid) => {
      const [err] = await api.master.organization.activateUser(projectId, userId);
      if (err) {
        messageError(err, intl);
      } else {
        loadUserProjectsReport();
      }
    },
    [intl, loadUserProjectsReport]
  );

  const changeUserStatus = async (newStatus: ProjectUserProfileStatusEnum, userId: Guid, projectId: Guid) => {
    newStatus === ProjectUserProfileStatusEnum.suspended && (await suspendUser(userId, projectId));
    newStatus === ProjectUserProfileStatusEnum.active && (await activateUser(userId, projectId));
    loadUserProjectsReport();
  };

  const addUserToProject = useCallback(
    async (projectId: Guid) => {
      const data: ProjectUsersRequestDto = {
        users: [
          {
            mail: userName,
            addAsAdmin: false,
            language: language,
          },
        ],
        organizationId,
        projectId,
      };
      const [err] = await api.master.organization.addprojectusers(data);
      if (err) {
        messageError(err, intl);
      } else {
        loadUserProjectsReport();
      }
    },
    [intl, loadUserProjectsReport, organizationId, language, userName]
  );

  const userActiveProjectsResume: CurrentUserProjectsParams[] = useMemo(() => {
    if (!userProjectsReport || !orgUserId) return [];
    return userProjectsReport.projects
      .filter((project) => !!project.user)
      .map((project) => ({
        projectId: project.project.id,
        projectName: project.project.name,
        projectUserId: project.user?.id,
        projectUserIsAdmin: project.user?.isAdmin,
        projectUserStatus: project.user?.status,
        projectUserIsConfirmed: project.user?.isConfirmed,
        projectUserIsLastAdmin: project.isLastAdmin,
      }));
  }, [orgUserId, userProjectsReport]);

  const userNewProjectsResume: CurrentUserProjectsParams[] = useMemo(() => {
    if (!userProjectsReport || !orgUserId) return [];
    return userProjectsReport.projects
      .filter((project) => !project.user)
      .map((project) => ({
        projectId: project.project.id,
        projectName: project.project.name,
        projectUserId: undefined,
        projectUserIsAdmin: undefined,
        projectUserStatus: undefined,
        projectUserIsConfirmed: undefined,
        projectUserIsLastAdmin: undefined,
      }));
  }, [orgUserId, userProjectsReport]);

  const { orderedItems: orderedActiveItems, ...filterPropsActive } = useFrontendFilters(
    USERS_USEFUL_PROJECT_FILTER,
    USERS_USEFUL_PROJECT_ORDER_OPTIONS,
    userActiveProjectsResume
  );
  const { orderedItems: orderedNewItems, ...filterPropsNew } = useFrontendFilters(
    USERS_USEFUL_PROJECT_FILTER,
    USERS_USEFUL_PROJECT_ORDER_OPTIONS,
    userNewProjectsResume
  );

  const suspendAllEnabled = useMemo(() => {
    return !!userActiveProjectsResume.find(
      (project) =>
        project.projectUserStatus === ProjectUserProfileStatusEnum.active ||
        project.projectUserStatus === ProjectUserProfileStatusEnum.invited
    );
  }, [userActiveProjectsResume]);

  if (userProjectsReportError) return <ServiceErrorBox error={userProjectsReportError} />;

  return (
    <>
      <Modal
        key="userOnProjectsSetting"
        title={<Fmt id="OrganizationUserSettingOnProjectsModal.title" />}
        open={open}
        onOk={onClose}
        onCancel={onClose}
        okText={<Fmt id="general.close" />}
        cancelButtonProps={HIDE_BUTTON_PROPS}
        width={COMMON_SETTING_MODAL_WIDTH}
      >
        <Collapse>
          <Collapse.Panel
            header={intl.formatMessage({ id: 'OrganizationUserSettingOnProjectsModal.usersActiveProjects' })}
            key="userActiveProjects"
            collapsible={!orderedActiveItems?.length ? 'disabled' : undefined}
          >
            <ReinviteButton
              onReinvite={reinviteUser}
              disabled={!!lastAccessDate || isReinvited}
              tooltip={intl.formatMessage({ id: 'ReinviteButton.reinvite.tooltip' })}
            >
              {intl.formatMessage({ id: 'ReinviteButton.reinvite' })}
            </ReinviteButton>
            <DeleteButton shape="default" onDelete={suspendOnAllProjects} disabled={!suspendAllEnabled}>
              {intl.formatMessage({ id: 'OrganizationUserSettingOnProjectsModal.DeleteDeactivateButtonText' })}
            </DeleteButton>
            <FlowLayout growLast wrap>
              <OrderSelect
                order={filterPropsActive.order}
                setOrder={filterPropsActive.setOrder}
                orderOptions={filterPropsActive.orderOptions}
              />
              <FilterToolbar
                wrap
                filters={filterPropsActive.filters}
                setFilterValue={filterPropsActive.setFilterValue}
                itemCounts={filterPropsActive.itemCounts}
                clearFilters={filterPropsActive.clearFilters}
              />
            </FlowLayout>
            <Margin top>
              {!!orderedActiveItems?.length &&
                orderedActiveItems?.map((item) => (
                  <div key={item.projectId}>
                    <Row>
                      <Col span={10}>
                        <CommonHubEllipsisText title={item.projectName}>{item.projectName}</CommonHubEllipsisText>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          checked={item.projectUserIsAdmin}
                          onChange={(e: CheckboxChangeEvent) =>
                            setIsAdmin(e.target.checked, item.projectUserId, item.projectId)
                          }
                          disabled={!!item.projectUserIsLastAdmin}
                        >
                          <Fmt id={'general.administrator'} />
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        {(item.projectUserStatus === ProjectUserProfileStatusEnum.active ||
                          item.projectUserStatus === ProjectUserProfileStatusEnum.suspended) && (
                          <Radio.Group
                            size="small"
                            disabled={item.projectUserIsLastAdmin}
                            value={item.projectUserStatus}
                            onChange={(e: RadioChangeEvent) =>
                              changeUserStatus(e.target.value, item.projectUserId, item.projectId)
                            }
                          >
                            <Radio value={ProjectUserProfileStatusEnum.active}>
                              {intl.formatMessage({ id: 'ProjectUsersEditModal.active' })}
                            </Radio>
                            <Radio value={ProjectUserProfileStatusEnum.suspended}>
                              {intl.formatMessage({ id: 'ProjectUsersEditModal.deactive' })}
                            </Radio>
                          </Radio.Group>
                        )}
                        {item.projectUserStatus === ProjectUserProfileStatusEnum.invited &&
                          !item.projectUserIsLastAdmin && (
                            <DeleteButton
                              disabled={
                                item.projectUserIsLastAdmin
                                  ? intl.formatMessage({ id: 'DeleteButton.isLastAdmin' })
                                  : undefined
                              }
                              onDelete={() => removeInvitedProjectUser(item.projectUserId, item.projectId)}
                              type="default"
                              shape="default"
                            >
                              {intl.formatMessage({ id: 'general.delete' })}
                            </DeleteButton>
                          )}
                      </Col>
                    </Row>
                  </div>
                ))}
            </Margin>
          </Collapse.Panel>
          <Collapse.Panel
            header={intl.formatMessage({ id: 'OrganizationUserSettingOnProjectsModal.usersNewProjects' })}
            key="userNewProjects"
            collapsible={!orderedNewItems?.length ? 'disabled' : undefined}
          >
            <FlowLayout growLast wrap>
              <OrderSelect
                order={filterPropsNew.order}
                setOrder={filterPropsNew.setOrder}
                orderOptions={filterPropsNew.orderOptions}
              />
              <FilterToolbar
                wrap
                filters={filterPropsNew.filters}
                setFilterValue={filterPropsNew.setFilterValue}
                itemCounts={filterPropsNew.itemCounts}
                clearFilters={filterPropsNew.clearFilters}
              />
            </FlowLayout>
            {!!orderedNewItems?.length &&
              orderedNewItems?.map((item) => (
                <div key={item.projectId}>
                  <Row>
                    <Col span={16}>
                      <CommonHubEllipsisText title={item.projectName}>{item.projectName}</CommonHubEllipsisText>
                    </Col>
                    <Col span={8}>
                      <Button onClick={() => addUserToProject(item.projectId)} size="small" icon={<PlusOutlined />}>
                        {intl.formatMessage({ id: 'OrganizationUserSettingOnProjectsModal.AddButtonText' })}
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
          </Collapse.Panel>
        </Collapse>
      </Modal>
      <Modal
        key="suspendAllUsersErrors"
        open={!!suspendAllUsersErrors?.length}
        title={<Fmt id="OrganizationUserSettingOnProjectsModal.noSuspendReason.Title" />}
        onOk={() => setSuspendAllUsersErrors(undefined)}
        onCancel={() => setSuspendAllUsersErrors(undefined)}
        okText={<Fmt id="general.close" />}
        cancelButtonProps={HIDE_BUTTON_PROPS}
        width={600}
      >
        {suspendErrorsModalContent}
      </Modal>
    </>
  );
};
