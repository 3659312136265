import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './GeneralSettingsContainer.module.less';

export type ListItemProps = {
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  itemsLargeGap?: boolean;
};

type Props = ListItemProps;

const GeneralSettingsContainer: FunctionComponent<Props> = ({
  children,
  title,
  description,
  className,
  itemsLargeGap,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      {(!!title || !!description) && (
        <div className={classNames(styles.titleGroup)}>
          <div className={styles.title}>{title}</div>
          <div>{description}</div>
        </div>
      )}
      <div className={classNames(styles.items, itemsLargeGap && styles.itemsLargeGap)}>{children}</div>
    </div>
  );
};

export default React.memo(GeneralSettingsContainer);
