import { DirectoryContentDto, ServiceError } from 'api/completeApiInterfaces';
import ServiceErrorBox from 'components/ServiceErrorBox/ServiceErrorBox';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { DocumentsLoadingRunType } from 'hooks/useDocuments';
import React, { FunctionComponent } from 'react';

type OwnProps = {
  loadingTip?: string;
  documentsError?: ServiceError;
  documentsLoading: DocumentsLoadingRunType;
  documents: DirectoryContentDto[];
};

type Props = OwnProps;

export const DocumentsGate: FunctionComponent<Props> = ({
  children,
  documentsError,
  loadingTip,
  documents,
  documentsLoading = null,
}) => {
  const spinning = (documents === null && documentsError === null) || documentsLoading === 'manual'; // || directoryIdChanged;

  if (documentsError) {
    return <ServiceErrorBox error={documentsError} />;
  }

  return (
    <SpinBoxCenter spinning={spinning} delay={0} tip={loadingTip}>
      {children}
    </SpinBoxCenter>
  );
};

export default DocumentsGate;
