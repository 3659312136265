import { DownOutlined, HddOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, DropdownProps } from 'antd';
import { getPathPartsFromPath } from 'components/DocumentsHeader/DocumentsHeader';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { useActiveProject, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { directoryConnectedMapSelector, directoryRootSelector } from 'store/selectors';
import { getDefaultRootName } from 'utils/getRootName';
import { getPathsParts, getUrlPathInProject } from 'utils/urlPaths';

type Props = {
  path: string;
  directoryId: Guid;
} & Omit<DropdownProps, 'children' | 'menu' | 'trigger'>;

const PathDisplayMenu: FunctionComponent<Props> = ({ path, directoryId, ...restProps }) => {
  const history = useHistory();
  const projectId = useActiveProject()?.id;
  const directoryUrl = getUrlPathInProject(projectId, '/directories/');

  const rootNode = useSelectorDispatch(directoryRootSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );
  const directoryTree = useSelector(directoryConnectedMapSelector);
  const endDirectory = directoryTree[directoryId];

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  const pathItemsMenu = useMemo(
    () => ({
      items: getPathPartsFromPath(
        [getDefaultRootName(rootNode), ...getPathsParts(path)],
        endDirectory,
        directoryUrl
      ).map((item) => ({
        key: item.key,
        label: item.label,
        icon: item.icon === null ? undefined : item.icon || <RightOutlined />,
        onClick: () => {
          if (item.link) {
            history.push(item.link);
          }
        },
      })),
    }),
    [path, history, endDirectory]
  );

  return (
    <Dropdown menu={pathItemsMenu} trigger={DROPDOWN_TRIGGER_CLICK} {...restProps}>
      <Button type="link">
        <>
          <HddOutlined />
          <DownOutlined size={8} />
        </>
      </Button>
    </Dropdown>
  );
};

export default PathDisplayMenu;
