import { ServiceError } from 'api/completeApiInterfaces';
import EmptyStyled from 'components/Empty/EmptyStyled';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = InjectedIntlProps & {
  error?: ServiceError;
  loading?: boolean;
  loadingIntlId?: IntlMessageId;
  loadingAlternative?: string;
  empty?: boolean;
  emptyIntlId?: IntlMessageId;
  emptyAlternative?: ReactNode;
  flex?: boolean;
  delay?: number;
  overlay?: boolean;
  additionalErrorContent?: ReactNode;
  className?: string;
};

const ContentGateComponent: FunctionComponent<Props> = ({
  error,
  loading = false,
  loadingIntlId,
  loadingAlternative,
  empty,
  emptyIntlId,
  emptyAlternative,
  flex,
  children,
  delay,
  overlay,
  additionalErrorContent,
  intl,
  className,
}) => {
  if (error) {
    return (
      <>
        {additionalErrorContent}
        <ServiceErrorBox error={error} />
      </>
    );
  }

  const loadingTip =
    loadingAlternative !== undefined
      ? loadingAlternative
      : intl.formatMessage({ id: loadingIntlId || 'general.loading' });

  if (!overlay && loading) {
    return (
      <SpinBoxCenter tip={loadingTip} delay={delay} flex={flex}>
        <EmptyStyled />
      </SpinBoxCenter>
    );
  }

  const content = (
    <>{empty ? emptyAlternative || <EmptyStyled description={emptyIntlId && <Fmt id={emptyIntlId} />} /> : children}</>
  );

  if (overlay) {
    return (
      <SpinBoxCenter className={className} tip={loadingTip} delay={delay} spinning={loading} flex={flex}>
        {content}
      </SpinBoxCenter>
    );
  }

  return content;
};

export const ContentGate = memoizeWithIntl(ContentGateComponent);
