import { EstiConObjectState, EstiConPrehledStavebPohDto, EstiConProjectPhase } from 'api/completeApiInterfaces';
import Decimal from 'decimal.js';
import { Precisions } from 'hooks/useEsticon';
import { InjectedIntl } from 'locale';
import moment from 'moment';
import { addValues, sumValuesByChildren } from 'utils/buildingGridUtils';
import uuid from 'uuid';

export type ProjectsInRealisationOverviewReportData = EstiConPrehledStavebPohDto & {
  key?: string;
  id: Guid;
  children?: any[];
  lastUpdate: string;
  aktualniCena: number;
};

export type ProjectsInRealizationOverviewSummedPrices = Pick<
  ProjectsInRealisationOverviewReportData,
  | 'aktualniCena'
  | 'aktualniCenaI'
  | 'aktualniCenaO'
  | 'prostavenoAktRok'
  | 'oRok'
  | 'prostavenoAktRokO1'
  | 'prostavenoAktRokO2'
  | 'prostavenoAktRokO3'
  | 'prostavenoAktRokO4'
  | 'prostavenoAktRokO5'
  | 'prostavenoAktRokO6'
  | 'prostavenoAktRokO7'
  | 'prostavenoAktRokO8'
  | 'prostavenoAktRokO9'
  | 'prostavenoAktRokO10'
  | 'prostavenoAktRokO11'
  | 'prostavenoAktRokO12'
  | 'iRok'
  | 'prostavenoAktRokI1'
  | 'prostavenoAktRokI2'
  | 'prostavenoAktRokI3'
  | 'prostavenoAktRokI4'
  | 'prostavenoAktRokI5'
  | 'prostavenoAktRokI6'
  | 'prostavenoAktRokI7'
  | 'prostavenoAktRokI8'
  | 'prostavenoAktRokI9'
  | 'prostavenoAktRokI10'
  | 'prostavenoAktRokI11'
  | 'prostavenoAktRokI12'
  | 'prostavenoCelkem'
  | 'investiceRok'
  | 'investiceCelkem'
  | 'ostatniCelkem'
  | 'ostatniRok'
  | 'nezatrideno'
  | 'planAktRokI'
  | 'planAktRokI1'
  | 'planAktRokI2'
  | 'planAktRokI3'
  | 'planAktRokI4'
  | 'planAktRokI5'
  | 'planAktRokI6'
  | 'planAktRokI7'
  | 'planAktRokI8'
  | 'planAktRokI9'
  | 'planAktRokI10'
  | 'planAktRokI11'
  | 'planAktRokI12'
  | 'planAktRokO'
  | 'planAktRokO1'
  | 'planAktRokO2'
  | 'planAktRokO3'
  | 'planAktRokO4'
  | 'planAktRokO5'
  | 'planAktRokO6'
  | 'planAktRokO7'
  | 'planAktRokO8'
  | 'planAktRokO9'
  | 'planAktRokO10'
  | 'planAktRokO11'
  | 'planAktRokO12'
  | 'planAktRok1I'
  | 'planAktRok1O'
  | 'planAktRok2I'
  | 'planAktRok2O'
  | 'planAktRok3I'
  | 'planAktRok3O'
  | 'planAktRok4I'
  | 'planAktRok4O'
>;

const PRICE_COLUMNS: (keyof ProjectsInRealizationOverviewSummedPrices)[] = [
  'aktualniCena',
  'aktualniCenaI',
  'aktualniCenaO',
  'oRok',
  'prostavenoAktRokO1',
  'prostavenoAktRokO2',
  'prostavenoAktRokO3',
  'prostavenoAktRokO4',
  'prostavenoAktRokO5',
  'prostavenoAktRokO6',
  'prostavenoAktRokO7',
  'prostavenoAktRokO8',
  'prostavenoAktRokO9',
  'prostavenoAktRokO10',
  'prostavenoAktRokO11',
  'prostavenoAktRokO12',
  'iRok',
  'prostavenoAktRokI1',
  'prostavenoAktRokI2',
  'prostavenoAktRokI3',
  'prostavenoAktRokI4',
  'prostavenoAktRokI5',
  'prostavenoAktRokI6',
  'prostavenoAktRokI7',
  'prostavenoAktRokI8',
  'prostavenoAktRokI9',
  'prostavenoAktRokI10',
  'prostavenoAktRokI11',
  'prostavenoAktRokI12',
  'prostavenoAktRok',
  'prostavenoCelkem',
  'investiceRok',
  'investiceCelkem',
  'ostatniCelkem',
  'ostatniRok',
  'nezatrideno',
  'planAktRokI',
  'planAktRokI1',
  'planAktRokI2',
  'planAktRokI3',
  'planAktRokI4',
  'planAktRokI5',
  'planAktRokI6',
  'planAktRokI7',
  'planAktRokI8',
  'planAktRokI9',
  'planAktRokI10',
  'planAktRokI11',
  'planAktRokI12',
  'planAktRokO',
  'planAktRokO1',
  'planAktRokO2',
  'planAktRokO3',
  'planAktRokO4',
  'planAktRokO5',
  'planAktRokO6',
  'planAktRokO7',
  'planAktRokO8',
  'planAktRokO9',
  'planAktRokO10',
  'planAktRokO11',
  'planAktRokO12',
  'planAktRok1I',
  'planAktRok1O',
  'planAktRok2I',
  'planAktRok2O',
  'planAktRok3I',
  'planAktRok3O',
  'planAktRok4I',
  'planAktRok4O',
];

export const divideToThousandsWithRound = (value: number): number =>
  Decimal.div(value || 0, 1000)
    .round()
    .toNumber();

export const createProjectInRealizationRowData = (
  rows: EstiConPrehledStavebPohDto[],
  precisions: Precisions,
  mapNumericalValues: (value: number) => number
): ProjectsInRealisationOverviewReportData[] =>
  rows.map((unitRow) => {
    const currentPrice = addValues(
      unitRow.aktualniCenaI,
      addValues(unitRow.aktualniCenaO, unitRow.nezatrideno, precisions.price),
      precisions.price
    );
    return {
      ...unitRow,
      aktualniCena: mapNumericalValues(currentPrice),
      aktualniCenaI: mapNumericalValues(unitRow.aktualniCenaI),
      aktualniCenaO: mapNumericalValues(unitRow.aktualniCenaO),
      prostavenoAktRok: mapNumericalValues(unitRow.prostavenoAktRok),
      prostavenoCelkem: mapNumericalValues(unitRow.prostavenoCelkem),
      investiceCelkem: mapNumericalValues(unitRow.investiceCelkem),
      ostatniCelkem: mapNumericalValues(unitRow.ostatniCelkem),
      investiceRok: mapNumericalValues(unitRow.investiceRok),
      ostatniRok: mapNumericalValues(unitRow.ostatniRok),
      nezatrideno: mapNumericalValues(unitRow.nezatrideno),
      iRok: mapNumericalValues(unitRow.iRok),
      prostavenoAktRokI1: mapNumericalValues(unitRow.prostavenoAktRokI1),
      prostavenoAktRokI2: mapNumericalValues(unitRow.prostavenoAktRokI2),
      prostavenoAktRokI3: mapNumericalValues(unitRow.prostavenoAktRokI3),
      prostavenoAktRokI4: mapNumericalValues(unitRow.prostavenoAktRokI4),
      prostavenoAktRokI5: mapNumericalValues(unitRow.prostavenoAktRokI5),
      prostavenoAktRokI6: mapNumericalValues(unitRow.prostavenoAktRokI6),
      prostavenoAktRokI7: mapNumericalValues(unitRow.prostavenoAktRokI7),
      prostavenoAktRokI8: mapNumericalValues(unitRow.prostavenoAktRokI8),
      prostavenoAktRokI9: mapNumericalValues(unitRow.prostavenoAktRokI9),
      prostavenoAktRokI10: mapNumericalValues(unitRow.prostavenoAktRokI10),
      prostavenoAktRokI11: mapNumericalValues(unitRow.prostavenoAktRokI11),
      prostavenoAktRokI12: mapNumericalValues(unitRow.prostavenoAktRokI12),
      oRok: mapNumericalValues(unitRow.oRok),
      prostavenoAktRokO1: mapNumericalValues(unitRow.prostavenoAktRokO1),
      prostavenoAktRokO2: mapNumericalValues(unitRow.prostavenoAktRokO2),
      prostavenoAktRokO3: mapNumericalValues(unitRow.prostavenoAktRokO3),
      prostavenoAktRokO4: mapNumericalValues(unitRow.prostavenoAktRokO4),
      prostavenoAktRokO5: mapNumericalValues(unitRow.prostavenoAktRokO5),
      prostavenoAktRokO6: mapNumericalValues(unitRow.prostavenoAktRokO6),
      prostavenoAktRokO7: mapNumericalValues(unitRow.prostavenoAktRokO7),
      prostavenoAktRokO8: mapNumericalValues(unitRow.prostavenoAktRokO8),
      prostavenoAktRokO9: mapNumericalValues(unitRow.prostavenoAktRokO9),
      prostavenoAktRokO10: mapNumericalValues(unitRow.prostavenoAktRokO10),
      prostavenoAktRokO11: mapNumericalValues(unitRow.prostavenoAktRokO11),
      prostavenoAktRokO12: mapNumericalValues(unitRow.prostavenoAktRokO12),
      planAktRokI: mapNumericalValues(unitRow.planAktRokI),
      planAktRokI1: mapNumericalValues(unitRow.planAktRokI1),
      planAktRokI2: mapNumericalValues(unitRow.planAktRokI2),
      planAktRokI3: mapNumericalValues(unitRow.planAktRokI3),
      planAktRokI4: mapNumericalValues(unitRow.planAktRokI4),
      planAktRokI5: mapNumericalValues(unitRow.planAktRokI5),
      planAktRokI6: mapNumericalValues(unitRow.planAktRokI6),
      planAktRokI7: mapNumericalValues(unitRow.planAktRokI7),
      planAktRokI8: mapNumericalValues(unitRow.planAktRokI8),
      planAktRokI9: mapNumericalValues(unitRow.planAktRokI9),
      planAktRokI10: mapNumericalValues(unitRow.planAktRokI10),
      planAktRokI11: mapNumericalValues(unitRow.planAktRokI11),
      planAktRokI12: mapNumericalValues(unitRow.planAktRokI12),
      planAktRokO: mapNumericalValues(unitRow.planAktRokO),
      planAktRokO1: mapNumericalValues(unitRow.planAktRokO1),
      planAktRokO2: mapNumericalValues(unitRow.planAktRokO2),
      planAktRokO3: mapNumericalValues(unitRow.planAktRokO3),
      planAktRokO4: mapNumericalValues(unitRow.planAktRokO4),
      planAktRokO5: mapNumericalValues(unitRow.planAktRokO5),
      planAktRokO6: mapNumericalValues(unitRow.planAktRokO6),
      planAktRokO7: mapNumericalValues(unitRow.planAktRokO7),
      planAktRokO8: mapNumericalValues(unitRow.planAktRokO8),
      planAktRokO9: mapNumericalValues(unitRow.planAktRokO9),
      planAktRokO10: mapNumericalValues(unitRow.planAktRokO10),
      planAktRokO11: mapNumericalValues(unitRow.planAktRokO11),
      planAktRokO12: mapNumericalValues(unitRow.planAktRokO12),
      planAktRok1I: mapNumericalValues(unitRow.planAktRok1I),
      planAktRok2I: mapNumericalValues(unitRow.planAktRok2I),
      planAktRok3I: mapNumericalValues(unitRow.planAktRok3I),
      planAktRok4I: mapNumericalValues(unitRow.planAktRok4I),
      planAktRok1O: mapNumericalValues(unitRow.planAktRok1O),
      planAktRok2O: mapNumericalValues(unitRow.planAktRok2O),
      planAktRok3O: mapNumericalValues(unitRow.planAktRok3O),
      planAktRok4O: mapNumericalValues(unitRow.planAktRok4O),
      lastUpdate: !!unitRow.datumPosledniPoznamky
        ? `${unitRow.autorPosledniPoznamky ? unitRow.autorPosledniPoznamky : ''} (${moment(
            unitRow.datumPosledniPoznamky
          ).format('lll')})`
        : '',
    };
  });

export const createProjectsInRealisationOverviewReportData = (
  projectsOverviewData: EstiConPrehledStavebPohDto[],
  precisions: Precisions,
  intl: InjectedIntl
): ProjectsInRealisationOverviewReportData[] => {
  const projectRows = createProjectInRealizationRowData(projectsOverviewData, precisions, divideToThousandsWithRound);

  const totalSum = sumValuesByChildren<ProjectsInRealizationOverviewSummedPrices>(
    projectRows,
    precisions.price,
    PRICE_COLUMNS
  );

  const rootKey = uuid();
  return [
    {
      ...totalSum,
      znacka: undefined,
      id: rootKey,
      phase: EstiConProjectPhase.Priprava,
      children: [...projectRows],
      key: rootKey,
      lastUpdate: undefined,
      autorPosledniPoznamky: undefined,
      datumPosledniPoznamky: undefined,
      dodavatel: undefined,
      dokonceni: undefined,
      nazevAkce: intl.formatMessage({ id: 'general.total' }),
      poznamka: undefined,
      typ: undefined,
      utvar: undefined,
      idUtvaru: undefined,
      tds: undefined,
      zahajeni: undefined,
      state: EstiConObjectState.Clean,
      isFpRozepsanoVse: true,
    },
  ];
};
