import { RightOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ProjectListDto, SortOrder } from 'api/completeApiInterfaces';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import { ProjectIcon } from 'components/Icons/HubEntitiesIcons';
import { createFrontendSingleTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useFrontendFilters } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { textComparer } from 'utils/comparators';
import styles from './MessageCenterProjectsMenu.module.less';

const FILTERS: FrontendFilter<ProjectListDto>[] = [
  createFrontendSingleTextFilter('name', (project) => project.name, {
    label: <Fmt id="general.name" />,
  }),
];

const ORDER_OPTIONS: FrontendOrderOption<ProjectListDto>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.name" />,
    compare: textComparer.map((project) => project.name),
    defaultOrder: SortOrder.asc,
  },
];

type Props = {
  projectList: ProjectListDto[];
  selectedKeys: string[];
  setSelectedProject: (projectId: string) => void;
};

export const MessageCenterProjectsMenu: FunctionComponent<Props> = (props) => {
  const { projectList, selectedKeys, setSelectedProject } = props;

  const { orderedItems, ...filterProps } = useFrontendFilters(
    FILTERS,
    ORDER_OPTIONS,
    projectList,
    FiltersPersistentKey.MessageCenterSettings
  );

  return (
    <>
      <DocumentsGridHeaderStyled>
        <FlowLayout growLast wrap>
          <OrderSelect
            order={filterProps.order}
            setOrder={filterProps.setOrder}
            orderOptions={filterProps.orderOptions}
          />
          <FilterToolbar
            filters={filterProps.filters}
            setFilterValue={filterProps.setFilterValue}
            itemCounts={filterProps.itemCounts}
            clearFilters={filterProps.clearFilters}
          />
        </FlowLayout>
      </DocumentsGridHeaderStyled>
      <EmptyGate
        empty={!orderedItems?.length}
        clearSearch={filterProps.clearFilters}
        hasUnfilteredItems={filterProps.hasFilteredOutItems}
      >
        <Menu selectedKeys={selectedKeys}>
          {orderedItems?.map((project) => {
            return (
              <Menu.Item
                key={project.id}
                onClick={() => setSelectedProject(project.id)}
                icon={<ProjectIcon />}
                className={styles.menuLabel}
              >
                <CommonHubEllipsisText placement="topLeft" title={project.name} className={styles.projectName}>
                  {project.name}
                </CommonHubEllipsisText>
                <RightOutlined />
              </Menu.Item>
            );
          })}
        </Menu>
      </EmptyGate>
    </>
  );
};
