import { Typography } from 'antd';
import {
  AccessLevelEnum,
  CalendarTemplateDto,
  ExtendedPermissionCategoryEnum,
  ExtendedPermissionEnum,
  ExtendedPermissionTemplateDto,
  LanguageEnum,
  OrgUserDto,
  ProjectTemplateCategoryNodeDto,
  ProjectTemplateCategoryTreeDto,
  ProjectTemplateDirectoryDto,
  ProjectTemplateDto,
  ProjectTemplateGroupDto,
  ProjectTemplateLabelDto,
  ProjectTemplateRoleDto,
  ProjectTemplateUserDto,
} from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { FlexTabs } from 'components/tabs/FlexTabs/FlexTabs';
import { useIntl } from 'hooks';
import produce from 'immer';
import { Fmt } from 'locale';
import { uniq, unset } from 'lodash';
import {
  DEFAULT_ACTIVE_PERMISSIONS,
  PERMISSION_MAP,
} from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsDrawer';
import { Tab } from 'rc-tabs/lib/interface';
import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import { InjectedIntl } from 'react-intl';
import { ROOT_NAME } from 'utils/getRootName';
import uuid from 'uuid';
import styles from './ProjectsTemplates.module.less';
import ProjectTemplateCategoriesTab from './Tabs/Categories/ProjectTemplateCategoriesTab';
import ProjectTemplateDirectoriesTab from './Tabs/Directories/ProjectTemplateDirectoriesTab';
import ProjectTemplateGeneralTab from './Tabs/General/ProjectTemplateGeneralTab';
import ProjectTemplateGroupsTab, { MAX_PROJECT_TEMPLATE_GROUP_COUNT } from './Tabs/Groups/ProjectTemplateGroupsTab';
import ProjectTemplateLabelsTab from './Tabs/Labels/ProjectTemplateLabelsTab';
import ProjectTemplateRolesTab from './Tabs/Roles/ProjectTemplateRolesTab';
import ProjectTemplateUsersTab from './Tabs/Users/ProjectTemplateUsersTab';

type Props = {
  organizationId: Guid;
  organizationTimezoneId: string;
  projectTemplate?: ProjectTemplateDto;
  onTemplateChanged: (templateData: ProjectTemplateData) => void;
  organizationUsers: OrgUserDto[];
  organizationPermissionProfiles?: ExtendedPermissionTemplateDto[];
  reloadOrganizationUsers: () => void;
  onTemplateValidityChange?: (isValid: boolean) => void;
  organizationStorageAreas?: string[];
};

export type ProjectTemplateData = {
  name: string;
  description?: string;
  language: LanguageEnum;
  timeZoneId?: string;
  storageArea?: string;
  id?: Guid;
  projectTemplateUsers: ProjectTemplateUserDto[];
  projectTemplateRoles: ProjectTemplateRoleDto[];
  projectTemplateGroups: ProjectTemplateGroupDto[];
  projectTemplateLabels: ProjectTemplateLabelDto[];
  projectTemplateCategoryTrees: ProjectTemplateCategoryTreeDto[];
  projectTemplateCategoryNodes: ProjectTemplateCategoryNodeDto[];
  projectTemplateDirectories: ProjectTemplateDirectoryDto[];
  orgExtendedPermissionTempates: ExtendedPermissionTemplateDto[];
  userExtendedPermissionTempates: ExtendedPermissionTemplateDto[];
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
  calendar: CalendarTemplateDto;
};

const templateDtoToData = (
  template: ProjectTemplateDto,
  organizationPermissionProfiles: ExtendedPermissionTemplateDto[]
): ProjectTemplateData => ({
  name: template.name || '',
  projectTemplateUsers: template.projectTemplateUsers || [],
  projectTemplateGroups: template.projectTemplateGroups || [],
  ...template,
  orgExtendedPermissionTempates: template.orgExtendedPermissionTempates || organizationPermissionProfiles,
  language: template.language || LanguageEnum.cs,
});

const createNodeFromCategoryTree = (tree: ProjectTemplateCategoryTreeDto): ProjectTemplateCategoryNodeDto => ({
  id: uuid(),
  name: tree.name,
  description: tree.description,
  templateCategoryTreeId: tree.id,
  parentId: null,
});

type SetTemplateAction = {
  type: 'setTemplate';
  value: ProjectTemplateData;
};

type SetNameAction = {
  type: 'setName';
  name: string;
};

type SetDescriptionAction = {
  type: 'setDescription';
  description: string;
};

type SetStorageAreaAction = {
  type: 'SetStorageArea';
  storageArea: string;
};

type SetLanguageAction = {
  type: 'setLanguage';
  language: LanguageEnum;
};

type SetTimezoneAction = {
  type: 'setTimezone';
  timezoneId: string;
};

type AddUsersAction = {
  type: 'addUsers';
  users: ProjectTemplateUserDto[];
};

type RemoveUserAction = {
  type: 'removeUser';
  userId: Guid;
};

type SetUsersAction = {
  type: 'setUsers';
  users: ProjectTemplateUserDto[];
};

type AddRoleAction = {
  type: 'addRole';
  role: ProjectTemplateRoleDto;
};

type UpdateRoleAction = {
  type: 'updateRole';
  role: ProjectTemplateRoleDto;
};

type DeleteRoleAction = {
  type: 'deleteRole';
  roleId: Guid;
};

type AddLabelAction = {
  type: 'addLabel';
  label: ProjectTemplateLabelDto;
};

type UpdateLabelAction = {
  type: 'updateLabel';
  label: ProjectTemplateLabelDto;
};

type DeleteLabelAction = {
  type: 'deleteLabel';
  labelId: Guid;
};

type AddGroupAction = {
  type: 'addGroup';
  group: ProjectTemplateGroupDto;
};

type UpdateGroupAction = {
  type: 'updateGroup';
  group: ProjectTemplateGroupDto;
};

type DeleteGroupAction = {
  type: 'deleteGroup';
  groupId: Guid;
};

type AddCategoryTreeAction = {
  type: 'addCategoryTree';
  categoryTree: ProjectTemplateCategoryTreeDto;
};

type UpdateCategoryTreeAction = {
  type: 'updateCategoryTree';
  categoryTree: ProjectTemplateCategoryTreeDto;
};

type DeleteCategoryTreeAction = {
  type: 'deleteCategoryTree';
  categoryTreeId: Guid;
};

type AddCategoryNodeAction = {
  type: 'addCategoryNode';
  categoryNode: ProjectTemplateCategoryNodeDto;
};

type UpdateCategoryNodeAction = {
  type: 'updateCategoryNode';
  categoryNode: ProjectTemplateCategoryNodeDto;
};

type DeleteCategoryNodeAction = {
  type: 'deleteCategoryNode';
  categoryNodeId: Guid;
};

type AddDirectoryAction = {
  type: 'addDirectory';
  directory: ProjectTemplateDirectoryDto[];
};

type EditDirectoryAction = {
  type: 'updateDirectory';
  directory: ProjectTemplateDirectoryDto;
};

type DeleteDirectoryAction = {
  type: 'deleteDirectory';
  directoryIds: Guid[];
};

type AddAdminsToGroupsAction = {
  type: 'addAdmins';
  userIds: Guid[];
};

type SetUserPermissionPreset = {
  type: 'setUserPermissionTemplate';
  userId: Guid;
  presetId: Guid;
};

type CreateOrganizationPermissionTemplate = {
  type: 'createOrgPermissionTemplate';
  userId: Guid;
  template: ExtendedPermissionTemplateDto;
};

type SetExtendedPermissionAction = {
  type: 'setExtendedPermission';
  userId: Guid;
  permission: ExtendedPermissionEnum;
  checked: boolean;
  permissionCategory: ExtendedPermissionCategoryEnum;
};

type DeleteExtendedPermissionProfileAction = {
  type: 'deleteExtendedPermissionProfile';
  permissionProfileId: Guid;
};

type PrimarySubmitterCanEditProcessorTeams = {
  type: 'primarySubmitterCanEditProcessorTeams';
  primarySubmitterCanEditProcessorTeams: boolean;
};

type PrimaryProcessorCanEditSubmitterTeams = {
  type: 'primaryProcessorCanEditSubmitterTeams';
  primaryProcessorCanEditSubmitterTeams: boolean;
};

type UseWorkDays = {
  type: 'useWorkDays';
  useWorkDays: boolean;
};

type SetCalendar = {
  type: 'setCalendar';
  calendar: CalendarTemplateDto | null;
};

export type ProjectTemplateDataAction =
  | SetTemplateAction
  | SetNameAction
  | SetDescriptionAction
  | SetStorageAreaAction
  | SetLanguageAction
  | SetTimezoneAction
  | SetUsersAction
  | AddUsersAction
  | RemoveUserAction
  | AddRoleAction
  | UpdateRoleAction
  | DeleteRoleAction
  | AddLabelAction
  | UpdateLabelAction
  | DeleteLabelAction
  | AddGroupAction
  | UpdateGroupAction
  | DeleteGroupAction
  | AddCategoryTreeAction
  | UpdateCategoryTreeAction
  | DeleteCategoryTreeAction
  | AddCategoryNodeAction
  | UpdateCategoryNodeAction
  | DeleteCategoryNodeAction
  | AddDirectoryAction
  | EditDirectoryAction
  | DeleteDirectoryAction
  | SetExtendedPermissionAction
  | CreateOrganizationPermissionTemplate
  | DeleteExtendedPermissionProfileAction
  | SetUserPermissionPreset
  | AddAdminsToGroupsAction
  | PrimarySubmitterCanEditProcessorTeams
  | PrimaryProcessorCanEditSubmitterTeams
  | UseWorkDays
  | SetCalendar;

const assignmentDataReducer = (state: ProjectTemplateData, action: ProjectTemplateDataAction) => {
  switch (action.type) {
    case 'setTemplate':
      return action.value;
    case 'setName':
      return produce(state, (draft) => {
        draft.name = action.name;
      });
    case 'setDescription':
      return produce(state, (draft) => {
        draft.description = action.description;
      });
    case 'SetStorageArea':
      return produce(state, (draft) => {
        draft.storageArea = action.storageArea;
      });
    case 'setLanguage':
      return produce(state, (draft) => {
        draft.language = action.language;
      });
    case 'setTimezone':
      return produce(state, (draft) => {
        draft.timeZoneId = action.timezoneId;
      });
    case 'addUsers':
      return produce(state, (draft) => {
        draft.projectTemplateUsers = [...draft.projectTemplateUsers, ...action.users].sort((a, b) =>
          a.appUserOrganization.appUserProfile.username.localeCompare(b.appUserOrganization.appUserProfile.username)
        );
      });
    case 'removeUser':
      return produce(state, (draft) => {
        draft.projectTemplateUsers = [...draft.projectTemplateUsers.filter((user) => user.id !== action.userId)];
        draft.projectTemplateRoles = [
          ...draft.projectTemplateRoles.map(
            (role): ProjectTemplateRoleDto => ({
              ...role,
              templateUserId: role.templateUserId === action.userId ? undefined : role.templateUserId,
            })
          ),
        ];
        draft.projectTemplateGroups = [
          ...draft.projectTemplateGroups.map(
            (group): ProjectTemplateGroupDto => ({
              ...group,
              templateUserIds: group.templateUserIds.filter((groupUserId) => groupUserId !== action.userId),
            })
          ),
        ];
      });
    case 'setUsers':
      return produce(state, (draft) => {
        draft.projectTemplateUsers = action.users;
      });
    case 'addRole':
      return produce(state, (draft) => {
        draft.projectTemplateRoles = [...draft.projectTemplateRoles, action.role].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    case 'updateRole':
      return produce(state, (draft) => {
        draft.projectTemplateRoles = [
          ...draft.projectTemplateRoles.map(
            (templateRole): ProjectTemplateRoleDto => (templateRole.id === action.role.id ? action.role : templateRole)
          ),
        ].sort((a, b) => a.name.localeCompare(b.name));
      });
    case 'deleteRole':
      return produce(state, (draft) => {
        draft.projectTemplateRoles = [...draft.projectTemplateRoles.filter((role) => role.id !== action.roleId)];
      });
    case 'addLabel':
      return produce(state, (draft) => {
        draft.projectTemplateLabels = [...draft.projectTemplateLabels, action.label].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    case 'updateLabel':
      return produce(state, (draft) => {
        draft.projectTemplateLabels = [
          ...draft.projectTemplateLabels.map(
            (templateLabel): ProjectTemplateLabelDto =>
              templateLabel.id === action.label.id ? action.label : templateLabel
          ),
        ].sort((a, b) => a.name.localeCompare(b.name));
      });
    case 'deleteLabel':
      return produce(state, (draft) => {
        draft.projectTemplateLabels = [...draft.projectTemplateLabels.filter((label) => label.id !== action.labelId)];
      });
    case 'addGroup':
      return produce(state, (draft) => {
        draft.projectTemplateGroups = [...draft.projectTemplateGroups, action.group].sort((a, b) =>
          a.isAdminGroup !== b.isAdminGroup ? (a.isAdminGroup ? -1 : 1) : a.name.localeCompare(b.name)
        );
      });
    case 'updateGroup':
      return produce(state, (draft) => {
        draft.projectTemplateGroups = [
          ...draft.projectTemplateGroups.map((templateGroup) =>
            templateGroup.id === action.group.id ? action.group : templateGroup
          ),
        ].sort((a, b) =>
          a.isAdminGroup !== b.isAdminGroup ? (a.isAdminGroup ? -1 : 1) : a.name.localeCompare(b.name)
        );
      });
    case 'deleteGroup':
      return produce(state, (draft) => {
        draft.projectTemplateGroups = [...draft.projectTemplateGroups.filter((group) => group.id !== action.groupId)];
        draft.projectTemplateDirectories = [
          ...draft.projectTemplateDirectories.map(
            (directory): ProjectTemplateDirectoryDto => {
              const filteredGroups = directory.projectTemplateGroupPermMap;
              unset(filteredGroups, action.groupId);
              return {
                ...directory,
                projectTemplateGroupPermMap: filteredGroups,
              };
            }
          ),
        ];
      });
    case 'addCategoryTree':
      return produce(state, (draft) => {
        draft.projectTemplateCategoryTrees = [...draft.projectTemplateCategoryTrees, action.categoryTree].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        draft.projectTemplateCategoryNodes = [
          ...draft.projectTemplateCategoryNodes,
          createNodeFromCategoryTree(action.categoryTree),
        ].sort((a, b) => a.name.localeCompare(b.name));
      });
    case 'updateCategoryTree':
      return produce(state, (draft) => {
        draft.projectTemplateCategoryTrees = [
          ...draft.projectTemplateCategoryTrees.map(
            (categoryTree): ProjectTemplateCategoryTreeDto =>
              categoryTree.id === action.categoryTree.id ? action.categoryTree : categoryTree
          ),
        ].sort((a, b) => a.name.localeCompare(b.name));
        draft.projectTemplateCategoryNodes = [
          ...draft.projectTemplateCategoryNodes.map(
            (node): ProjectTemplateCategoryNodeDto =>
              node.templateCategoryTreeId === action.categoryTree.id && !node.parentId
                ? { ...node, name: action.categoryTree.name, description: action.categoryTree.description }
                : node
          ),
        ].sort((a, b) => a.name.localeCompare(b.name));
      });
    case 'deleteCategoryTree':
      return produce(state, (draft) => {
        draft.projectTemplateDirectories = [
          ...draft.projectTemplateDirectories.map((directory) => {
            return {
              ...directory,
              projectTemplateDirectoryCategoryNodeIds: directory.projectTemplateDirectoryCategoryNodeIds.filter(
                (nodeId) =>
                  !draft.projectTemplateCategoryNodes
                    .filter((node) => node.templateCategoryTreeId === action.categoryTreeId)
                    .some((node) => node.id === nodeId)
              ),
            };
          }),
        ];
        draft.projectTemplateCategoryTrees = [
          ...draft.projectTemplateCategoryTrees.filter((categoryTree) => categoryTree.id !== action.categoryTreeId),
        ];
        draft.projectTemplateCategoryNodes = [
          ...draft.projectTemplateCategoryNodes.filter((node) => node.templateCategoryTreeId !== action.categoryTreeId),
        ];
      });
    case 'addCategoryNode':
      return produce(state, (draft) => {
        draft.projectTemplateCategoryNodes = [...draft.projectTemplateCategoryNodes, action.categoryNode].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    case 'updateCategoryNode':
      return produce(state, (draft) => {
        draft.projectTemplateCategoryNodes = [
          ...draft.projectTemplateCategoryNodes.map(
            (categoryNode): ProjectTemplateCategoryNodeDto =>
              categoryNode.id === action.categoryNode.id ? action.categoryNode : categoryNode
          ),
        ].sort((a, b) => a.name.localeCompare(b.name));
      });
    case 'deleteCategoryNode':
      return produce(state, (draft) => {
        draft.projectTemplateCategoryNodes = [
          ...draft.projectTemplateCategoryNodes.filter((node) => node.id !== action.categoryNodeId),
        ];
        draft.projectTemplateDirectories = [
          ...draft.projectTemplateDirectories.map(
            (directory): ProjectTemplateDirectoryDto => {
              return {
                ...directory,
                projectTemplateDirectoryCategoryNodeIds: directory.projectTemplateDirectoryCategoryNodeIds.filter(
                  (nodeId) => nodeId !== action.categoryNodeId
                ),
              };
            }
          ),
        ];
      });
    case 'addDirectory':
      return produce(state, (draft) => {
        draft.projectTemplateDirectories = [...draft.projectTemplateDirectories, ...action.directory];
      });
    case 'updateDirectory':
      return produce(state, (draft) => {
        draft.projectTemplateDirectories = [
          ...draft.projectTemplateDirectories.map((templateDirectory) =>
            templateDirectory.id === action.directory.id ? action.directory : templateDirectory
          ),
        ];
      });
    case 'deleteDirectory':
      return produce(state, (draft) => {
        draft.projectTemplateDirectories = [
          ...draft.projectTemplateDirectories.filter(
            (directory) => !action.directoryIds.some((deleteId) => directory.id === deleteId)
          ),
        ];
      });
    case 'setExtendedPermission':
      return produce(state, (draft) => {
        const user = draft.projectTemplateUsers.find((user) => user.id === action.userId);
        if (!user) return;

        const hasOrgPermissionProfile =
          user.extendedPermissionId &&
          draft.orgExtendedPermissionTempates.some((template) => template.id === user.extendedPermissionId);
        if (hasOrgPermissionProfile) return;

        const hasUserPermissionProfile =
          user.extendedPermissionId &&
          draft.userExtendedPermissionTempates.some((template) => template.id === user.extendedPermissionId);
        if (!hasUserPermissionProfile) {
          const newTemplateId = uuid();
          const permissionToCategoryMap = Object.entries(PERMISSION_MAP).reduce(
            (invertedMap, [category, permissions]) => ({
              ...invertedMap,
              ...permissions.map((permission) => ({ [permission]: category })),
            }),
            {} as Record<ExtendedPermissionEnum, ExtendedPermissionCategoryEnum>
          );
          draft.userExtendedPermissionTempates = [
            ...draft.userExtendedPermissionTempates,
            {
              id: newTemplateId,
              extendedPermissions: {
                settings: [
                  ...DEFAULT_ACTIVE_PERMISSIONS.filter((permission) => permission !== action.permission).map(
                    (permission) => ({
                      permissionType: permission,
                      category: permissionToCategoryMap[permission] || action.permissionCategory,
                      permission: true,
                    })
                  ),
                  {
                    permissionType: action.permission,
                    category: action.permissionCategory,
                    permission: action.checked,
                  },
                ],
              },
            },
          ];
          draft.projectTemplateUsers = [
            ...draft.projectTemplateUsers.map(
              (projectUser): ProjectTemplateUserDto =>
                projectUser.id === action.userId ? { ...user, extendedPermissionId: newTemplateId } : projectUser
            ),
          ];
        } else {
          draft.userExtendedPermissionTempates = [
            ...draft.userExtendedPermissionTempates.map((template) => ({
              ...template,
              extendedPermissions:
                template.id === user.extendedPermissionId
                  ? {
                      settings: [
                        ...template.extendedPermissions.settings.filter(
                          (setting) => setting.permissionType !== action.permission
                        ),
                        {
                          permissionType: action.permission,
                          permission: action.checked,
                          category: action.permissionCategory,
                        },
                      ],
                    }
                  : template.extendedPermissions,
            })),
          ];
        }
      });
    case 'setUserPermissionTemplate':
      return produce(state, (draft) => {
        const user = draft.projectTemplateUsers.find((user) => user.id === action.userId);

        if (
          draft.userExtendedPermissionTempates.some((userPermission) => userPermission.id === user.extendedPermissionId)
        ) {
          draft.userExtendedPermissionTempates = [
            ...draft.userExtendedPermissionTempates.filter(
              (userPermission) => userPermission.id !== user.extendedPermissionId
            ),
          ];
        }

        draft.projectTemplateUsers = [
          ...draft.projectTemplateUsers.map(
            (projectUser): ProjectTemplateUserDto =>
              projectUser.id === action.userId ? { ...user, extendedPermissionId: action.presetId } : projectUser
          ),
        ];
      });
    case 'createOrgPermissionTemplate':
      return produce(state, (draft) => {
        const user = draft.projectTemplateUsers.find((user) => user.id === action.userId);
        if (
          draft.userExtendedPermissionTempates.some((userPermission) => userPermission.id === user.extendedPermissionId)
        ) {
          draft.userExtendedPermissionTempates = [
            ...draft.userExtendedPermissionTempates.filter(
              (userPermission) => userPermission.id !== user.extendedPermissionId
            ),
          ];
        }
        draft.orgExtendedPermissionTempates = [...draft.orgExtendedPermissionTempates, action.template];
        draft.projectTemplateUsers = [
          ...draft.projectTemplateUsers.map(
            (projectUser): ProjectTemplateUserDto =>
              projectUser.id === action.userId ? { ...user, extendedPermissionId: action.template.id } : projectUser
          ),
        ];
      });
    case 'deleteExtendedPermissionProfile':
      return produce(state, (draft) => {
        draft.userExtendedPermissionTempates = [
          ...draft.userExtendedPermissionTempates.filter((permission) => permission.id !== action.permissionProfileId),
        ];
      });
    case 'addAdmins':
      return produce(state, (draft) => {
        draft.projectTemplateGroups = [
          ...draft.projectTemplateGroups.map(
            (group): ProjectTemplateGroupDto => ({
              ...group,
              templateUserIds: group.isAdminGroup
                ? uniq([...group.templateUserIds, ...action.userIds])
                : group.templateUserIds,
            })
          ),
        ];
      });
    case 'primarySubmitterCanEditProcessorTeams':
      return produce(state, (draft) => {
        draft.primarySubmitterCanEditProcessorTeams = action.primarySubmitterCanEditProcessorTeams;
      });
    case 'primaryProcessorCanEditSubmitterTeams':
      return produce(state, (draft) => {
        draft.primaryProcessorCanEditSubmitterTeams = action.primaryProcessorCanEditSubmitterTeams;
      });
    case 'useWorkDays':
      return produce(state, (draft) => {
        draft.useWorkDays = action.useWorkDays;
      });
    case 'setCalendar':
      return produce(state, (draft) => {
        draft.calendar = action.calendar;
      });
  }
};

const defaultProjectTemplateData = (
  organizationTimezoneId: string,
  organizationPermissionProfiles: ExtendedPermissionTemplateDto[],
  intl: InjectedIntl,
  storageArea?: string
): ProjectTemplateData => {
  const adminGroupId = uuid();
  return {
    name: '',
    description: '',
    language: LanguageEnum.cs,
    timeZoneId: organizationTimezoneId,
    storageArea: storageArea,
    projectTemplateUsers: [],
    projectTemplateLabels: [],
    projectTemplateGroups: [
      {
        id: adminGroupId,
        name: intl.formatMessage({ id: 'general.administrator' }),
        isAdminGroup: true,
        templateUserIds: [],
      },
    ],
    projectTemplateRoles: [],
    projectTemplateCategoryTrees: [],
    projectTemplateCategoryNodes: [],
    projectTemplateDirectories: [
      {
        id: uuid(),
        name: ROOT_NAME,
        permissionInheritance: false,
        projectTemplateDirectoryCategoryNodeIds: [],
        projectTemplateGroupPermMap: { [adminGroupId]: AccessLevelEnum.admin },
      },
    ],
    userExtendedPermissionTempates: [],
    orgExtendedPermissionTempates: organizationPermissionProfiles || [],
    primarySubmitterCanEditProcessorTeams: false,
    primaryProcessorCanEditSubmitterTeams: false,
    useWorkDays: false,
    calendar: null,
  };
};

const ProjectTemplateBuilder: FunctionComponent<Props> = ({
  organizationId,
  organizationTimezoneId,
  projectTemplate,
  onTemplateChanged,
  organizationUsers,
  organizationPermissionProfiles,
  reloadOrganizationUsers,
  onTemplateValidityChange,
  organizationStorageAreas,
}) => {
  const intl = useIntl();

  const originalTemplate = useMemo(
    () =>
      (projectTemplate && templateDtoToData(projectTemplate, organizationPermissionProfiles)) ||
      defaultProjectTemplateData(
        organizationTimezoneId,
        organizationPermissionProfiles,
        intl,
        organizationStorageAreas[0] || ''
      ),
    [projectTemplate, organizationPermissionProfiles, organizationTimezoneId, organizationStorageAreas, intl]
  );
  const [editedTemplate, dispatchProjectTemplate] = useReducer(assignmentDataReducer, originalTemplate);

  useEffect(() => {
    onTemplateChanged && onTemplateChanged(editedTemplate);
  }, [editedTemplate, onTemplateChanged]);

  const hasInvalidGeneral = useMemo(
    () =>
      !editedTemplate.name ||
      !editedTemplate.language ||
      !editedTemplate.timeZoneId ||
      (!!organizationStorageAreas?.length && !editedTemplate.storageArea),
    [editedTemplate]
  );
  const hasInvalidUsers = useMemo(() => !editedTemplate.projectTemplateUsers.length, [editedTemplate]);
  const hasMissingAdminInGroup = useMemo(
    () => editedTemplate.projectTemplateGroups.some((group) => group.isAdminGroup && !group.templateUserIds.length),
    [editedTemplate]
  );
  const hasTooManyGroups = useMemo(
    () => editedTemplate.projectTemplateGroups.length > MAX_PROJECT_TEMPLATE_GROUP_COUNT,
    [editedTemplate]
  );

  useEffect(() => {
    onTemplateValidityChange &&
      onTemplateValidityChange(!hasInvalidGeneral && !hasInvalidUsers && !hasMissingAdminInGroup);
  }, [onTemplateValidityChange, hasInvalidUsers, hasMissingAdminInGroup, hasInvalidGeneral]);

  const tabs: Tab[] = [
    {
      key: 'general',
      label: (
        <>
          <Fmt id="general.general" />
          {hasInvalidGeneral ? (
            <CommonHubTooltip title={<Fmt id="ProjectTemplateBuilder.warning.tooltip.general" />}>
              <Typography.Text type="danger"> * </Typography.Text>
            </CommonHubTooltip>
          ) : null}
        </>
      ),
      children: (
        <ProjectTemplateGeneralTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          organizationId={organizationId}
          organizationStorageAreas={organizationStorageAreas}
          intl={intl}
        />
      ),
    },
    {
      key: 'users',
      label: (
        <>
          <Fmt id="general.users" />
          {hasInvalidUsers ? (
            <CommonHubTooltip title={<Fmt id="ProjectTemplateBuilder.warning.tooltip.users" />}>
              <Typography.Text type="danger"> * </Typography.Text>
            </CommonHubTooltip>
          ) : null}
        </>
      ),
      children: (
        <ProjectTemplateUsersTab
          projectTemplate={editedTemplate}
          organizationId={organizationId}
          dispatchProjectTemplate={dispatchProjectTemplate}
          organizationUsers={organizationUsers}
          reloadOrganizationUsers={reloadOrganizationUsers}
          intl={intl}
        />
      ),
    },
    {
      key: 'roles',
      label: (
        <>
          <Fmt id="general.roles" />
        </>
      ),
      disabled: !editedTemplate.projectTemplateUsers.length,
      children: (
        <ProjectTemplateRolesTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          intl={intl}
        />
      ),
    },
    {
      key: 'groups',
      label: (
        <>
          <Fmt id="general.groups" />
          {hasMissingAdminInGroup ? (
            <CommonHubTooltip title={<Fmt id="ProjectTemplateBuilder.warning.tooltip.groups.missingAdmin" />}>
              <Typography.Text type="danger"> * </Typography.Text>
            </CommonHubTooltip>
          ) : hasTooManyGroups ? (
            <CommonHubTooltip title={<Fmt id="ProjectTemplateBuilder.warning.tooltip.groups.tooMany" />}>
              <Typography.Text type="danger"> * </Typography.Text>
            </CommonHubTooltip>
          ) : null}
        </>
      ),
      disabled: !editedTemplate.projectTemplateUsers.length,
      children: (
        <ProjectTemplateGroupsTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          intl={intl}
        />
      ),
    },
    {
      key: 'labels',
      label: <Fmt id="general.labels" />,
      children: (
        <ProjectTemplateLabelsTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          intl={intl}
        />
      ),
    },
    {
      key: 'categories',
      label: <Fmt id="general.categories" />,
      children: (
        <ProjectTemplateCategoriesTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          intl={intl}
        />
      ),
    },
    {
      key: 'directories',
      label: <Fmt id="general.folders" />,
      children: (
        <ProjectTemplateDirectoriesTab
          projectTemplate={editedTemplate}
          dispatchProjectTemplate={dispatchProjectTemplate}
          intl={intl}
        />
      ),
    },
  ];

  return <FlexTabs defaultActiveKey={'general'} items={tabs} className={styles.tabContent} />;
};

export default ProjectTemplateBuilder;
