import { Alert } from 'antd';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { useActiveProject, useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { userHasPermission } from 'utils/userHasPermission';
import { EsticonDetail } from './Esticon/EsticonDetail/EsticonDetail';
import { ExternAppsList } from './ExternAppsList';
import StackPanel from 'components/StackPanel';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { AvailableAppEnum } from 'pages/ProjectSettingsPage/ExternApps/externAppsTypes';

type Props = RouteComponentProps<ProjectRouteParams>;

export const ExternApps: FunctionComponent<Props> = () => {
  const project = useActiveProject();
  const currentUser = useCurrentProjectUser();
  const { url } = useRouteMatch();
  const intl = useIntl();
  const history = useHistory();

  const noPermission =
    !currentUser.isAdmin && !userHasPermission(currentUser, ExtendedPermissionEnum.externalApplications);

  const onDelete = () => {
    if (history.location.pathname === url) return;
    history.replace(url);
  };

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.pageTitle' })}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical scrollable>
            {noPermission ? (
              <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
            ) : (
              <ExternAppsList project={project} selectedItemId={selectedItemId} onClick={onClick} onDelete={onDelete} />
            )}
          </StackPanel>
        )}
      />
      <Switch>
        <Route path={`${url}/${AvailableAppEnum.Esticon}`} component={EsticonDetail} />
      </Switch>
    </>
  );
};
