import { ExtendedPermissionEnum, ProjectUserProfileDto, RoleDto } from 'api/completeApiInterfaces';

export const userHasPermission = (
  user: ProjectUserProfileDto | undefined,
  permission: ExtendedPermissionEnum
): boolean => user?.extendedPermissions?.some((userPermission) => userPermission === permission);

export const userHasSomePermissions = (
  user: ProjectUserProfileDto | undefined,
  permissions: ExtendedPermissionEnum[]
): boolean => user?.extendedPermissions?.some((userPermission) => permissions.includes(userPermission));

export const isUserInSomeRole = (user: ProjectUserProfileDto | undefined, roles: RoleDto[]): boolean =>
  !!user && roles?.some((role) => role?.user?.id === user.id);
