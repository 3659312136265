import { Button, Col, Row } from 'antd';
import { default as classNames, default as classnames } from 'classnames';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import styles from './Panel.module.less';

const SEARCH_INPUT_STYLE = { width: 150 };

type Props = {
  panelWidth?: number | string;
  className?: string;
  panelStyle?: CSSProperties;
  contentClassName?: string;
  panelContentStyle?: CSSProperties;
  hideToolbar?: boolean;
  searchPlaceholder?: string;
  onSearch?: (value: string) => void;
  searchValue?: string;
  addButtonOnClick?: () => void;
  addButtonText?: ReactNode;
  addButtonDisabled?: boolean;
  toolbarTitle?: ReactNode;
  noMargin?: boolean;
  additionalItems?: ReactNode;
};

const Panel: FunctionComponent<Props> = ({
  className,
  panelStyle,
  contentClassName,
  panelContentStyle,
  hideToolbar,
  onSearch,
  children,
  panelWidth = 800,
  toolbarTitle,
  addButtonText,
  addButtonDisabled,
  addButtonOnClick,
  noMargin,
  searchValue,
  additionalItems,
}) => {
  return (
    <div className={classnames(styles.panel, className)} style={{ width: panelWidth, ...panelStyle }}>
      {!hideToolbar && (
        <div className={classNames(styles.toolbar, noMargin && styles.noMargin)}>
          <div className={styles.toolbarContent}>
            {addButtonOnClick && (
              <Button
                type="primary"
                icon={<AddIcon />}
                onClick={addButtonOnClick}
                disabled={addButtonDisabled}
                className={styles.toolbarButton}
              >
                {addButtonText}
              </Button>
            )}
            {additionalItems}
            {toolbarTitle}
          </div>
          <div className={styles.toolbarRight}>
            <Row gutter={8}>
              {onSearch && (
                <Col>
                  {searchValue !== undefined ? (
                    <FilterInput onSearch={onSearch} value={searchValue} style={SEARCH_INPUT_STYLE} />
                  ) : (
                    <FilterInput onSearch={onSearch} style={SEARCH_INPUT_STYLE} />
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
      <div
        className={classNames(styles.content, noMargin && styles.noMargin, contentClassName)}
        style={panelContentStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default Panel;
