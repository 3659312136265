import { StarOutlined } from '@ant-design/icons';
import { Divider, Select, Tree } from 'antd';
import { DirectoryDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { Margin } from 'components/Margin/Margin';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { directoryConnectedLinksSelector, directoryConnectedMapSelector } from 'store/selectors';
import { useRootName } from 'utils/getRootName';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import { directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import styles from './DirectoriesPrefferedSelect.module.less';
import { DirectoriesTreeProps } from './DirectoriesTreeSelect';

const MARGIN_TOP_BOT: React.CSSProperties = { margin: '5px 0' };

export type PreferredDirectory = {
  id: Guid;
  name: string;
  description?: string;
  directory: DirectoryDto;
};

type Props = Omit<DirectoriesTreeProps, 'onSelect' | 'onFocus'> & {
  preferredDirectories: PreferredDirectory[];
};

export const DirectoriesPreferredSelect: FunctionComponent<Props> = ({
  preferredDirectories,
  treeData,
  onChange,
  value,
  filterTreeNode,
  treeDefaultExpandedKeys,
  onDeselect,
  ...restProps
}) => {
  const directoryMap = useSelector(directoryConnectedMapSelector);
  const directoryLinkMap = useSelector(directoryConnectedLinksSelector);
  const rootName = useRootName();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const currentLabel = useMemo(() => {
    const directoryNode = directoryNodeHelpers.directoryNodeFromKey(value, directoryMap, directoryLinkMap);
    return directoryNode && directoryNodeHelpers.getDirectoryNodeName(directoryNode);
  }, [value, directoryMap, directoryLinkMap, rootName]);

  const valueWithLabel = useMemo(
    () => ({
      key: value,
      label: currentLabel,
    }),
    [value, currentLabel]
  );

  const directoriesTree = useMemo(
    () => (
      <Tree
        filterTreeNode={(node) =>
          !!search && (filterTreeNode === true ? true : filterTreeNode === false ? false : filterTreeNode(search, node))
        }
        onSelect={(selectedKeys: DirectoryNodeKey[]) => {
          selectedKeys?.length > 0 && onChange && onChange(selectedKeys[0], undefined, undefined);
          setOpen(false);
        }}
        selectedKeys={value ? [value] : undefined}
        defaultExpandedKeys={treeDefaultExpandedKeys}
        treeData={treeData}
        virtual
      />
    ),
    [onChange, value, filterTreeNode, treeDefaultExpandedKeys, search, treeData]
  );

  const preferredLines = useMemo(() => {
    return preferredDirectories?.map((preferred) => {
      const node = (
        <Margin left>
          <StarOutlined /> {preferred.name} - {preferred.directory.name}
        </Margin>
      );
      return (
        //TODO-KR: use a ListItem component or something
        <span
          key={preferred.id}
          className={classNames(
            'ant-select-dropdown-menu-item',
            value === preferred.directory.id && 'ant-select-dropdown-menu-item-active'
          )}
          onClick={() => {
            onChange(directoryNodeHelpers.directoryKey(preferred.directory.id), undefined, undefined);
            setOpen(false);
          }}
        >
          {node}
        </span>
      );
    });
  }, [preferredDirectories, value, onChange]);

  const inputRef = useRef<HTMLDivElement>();
  const dropdownRef = useRef<HTMLDivElement>();

  const closeCallback = useCallback((event: MouseEvent) => {
    if (inputRef.current?.contains(event.target as any)) {
      return; // clicked in the input field - do not close
    }
    if (dropdownRef.current?.contains(event.target as any)) {
      return; // clicked in the dropdown - do not close
    }
    setOpen(false); // clicked outside the dropdown - close
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeCallback);
    return () => document.removeEventListener('click', closeCallback);
  }, [closeCallback]);

  const onDropdownVisibleChange = useCallback((open: boolean) => open && setOpen(true), []);

  const dropdownRender = useCallback(
    () => (
      <div ref={dropdownRef} style={MARGIN_TOP_BOT}>
        {!!preferredLines && (
          <>
            {preferredLines}
            <Divider style={MARGIN_TOP_BOT} />
          </>
        )}
        {directoriesTree}
      </div>
    ),
    [preferredLines, directoriesTree]
  );

  return (
    <div ref={inputRef} className={styles.container}>
      <Select
        onSearch={setSearch}
        onDropdownVisibleChange={onDropdownVisibleChange}
        labelInValue
        dropdownRender={dropdownRender}
        getPopupContainer={() => inputRef.current}
        onDeselect={(value, optionType) => onDeselect(value.key, optionType)}
        {...restProps}
        defaultValue={undefined}
        // TODO: ant4: check restProps and use missing props
        open={open}
        value={valueWithLabel}
      />
    </div>
  );
};
