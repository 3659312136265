import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { message } from 'antd';
import { api } from 'api';
import { DirectoryDto, ServiceError } from 'api/completeApiInterfaces';
import CategoriesList from 'components/CategoriesList/CategoriesList';
import DocumentCategoryForm from 'components/forms/DocumentCategoryForm/DocumentCategoryForm';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import SettingsItem from 'components/SettingsItem/SettingsItem';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { categoryListSelector, categoryMapSelector } from 'store/selectors';
import { processApiError } from 'utils';

type DictionaryCategoriesFormData = {
  categories: Dictionary<Guid>;
};

const mapStateToProps = (state: RootState) => ({
  categoryMap: categoryMapSelector(state),
  categoryList: categoryListSelector(state),
  categoryError: state.categories.error,
  categoryLoading: state.categories.loading,
  categoryTrees: state.categoryTrees,
  hasDirtyCategories: state.categories.dirty,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCategories: dispatch.categories.loadData,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = InjectedIntlProps &
  FormComponentProps<DictionaryCategoriesFormData> &
  PropsFromDispatch &
  PropsFromState & {
    directory: DirectoryDto;
    initLoading: boolean;
    onCategoriesChange?: () => void;
    editDisabled?: boolean;
  };

type State = {
  editMode: boolean;
  saving: boolean;
  error: ServiceError;
};

class DirectorySettingsRequiredCategories extends Component<Props, State> {
  state: State = {
    editMode: false,
    saving: false,
    error: undefined,
  };

  async componentDidUpdate() {
    if (this.props.hasDirtyCategories) {
      await this.props.loadCategories({ reload: false, silent: true });
    }
  }

  render() {
    const { editMode, saving } = this.state;
    const { directory, categoryMap, initLoading, categoryTrees, categoryList, intl, editDisabled = false } = this.props;

    const categories = directory
      ? Object.keys(directory.requiredCategoryTrees)
          .map((categoryId) => categoryMap[categoryId])
          .filter((category) => !!category)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => ({
            defaultCategoryNodeId: undefined,
            categoryId: c.id,
            required: false,
          }))
      : [];

    return (
      <SettingsItem
        editingChildren={
          <DocumentCategoryForm
            categoryTrees={categoryTrees}
            categoryTreeNodes={directory.requiredCategoryTrees}
            autoSelectDefault
            categories={categories}
            categoryList={categoryList}
            categoryMap={categoryMap}
            form={this.props.form}
            layout="vertical"
            selectPlaceholder={intl.formatMessage({ id: 'DirectorySettingsForm.requiredCategories.placeholder' })}
          />
        }
        settingsIcon={<EditIcon />}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        onEdit={
          !editDisabled &&
          (() => {
            this.setState({
              editMode: true,
            });
          })
        }
        editMode={editMode}
        saving={saving || initLoading}
        errorMessage={null}
        headline={<Fmt id="DirectorySettingsForm.requiredCategories.label" />}
        editTooltip={<Fmt id="DirectorySettingsForm.requiredCategories.tooltip" />}
      >
        <CategoriesList categories={directory.requiredCategoryTrees} />
      </SettingsItem>
    );
  }

  handleSave = async () => {
    const { onCategoriesChange } = this.props;
    const { directory } = this.props;

    this.props.form.validateFields(async (err: any, values: DictionaryCategoriesFormData) => {
      if (err) return;

      if (values !== undefined) {
        this.setState({ saving: true });
        const [err, res] = await api.project.directories.patchDirectory(directory.id, {
          requiredCategoryTrees: values.categories || {},
        });
        if (err) {
          processApiError(err, (error) => {
            this.setState({ saving: false, error });
            message.error(error.message);
          });
          return;
        }
        onCategoriesChange && (await onCategoriesChange());
      }
      this.setState({ editMode: false, saving: false });
    });
  };

  handleCancel = () => this.setState({ editMode: false, error: null });
}

export default connect(
  mapStateToProps,
  legacyMapDispatchToProps(mapDispatchToProps)
)(injectIntl(Form.create<Props>()(DirectorySettingsRequiredCategories)));
