import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { textComparer } from 'utils/comparators';

const categoriesSelector = (state: RootState) => state.categories.data;

export const categoryMapSelector = createSelector(
  [categoriesSelector],
  (categories) => categories && keyBy(categories, 'id')
);

export const categoryListSelector = createSelector(
  [categoriesSelector],
  (categories) => categories && [...categories].sort(textComparer.map((category) => category.name))
);
