import { Col, Row, Typography } from 'antd';
import { Fmt } from 'locale';
import { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

export type ShareMessageData = {
  Language: string;
  ProjectId: Guid;
  ProjectDescription: string;
  SharedBy: string;
  SharedTo: string;
  FinishTime: Moment;
  ShareMessage: string;
  DirectoryLink: string;
  DirectoryName: string;
  DocumentLinks: string[];
  DocumentNames: Record<string, string>;
};

export const AppShareMessageViewer: MessageViewer = ({ messageData, projectName }) => {
  const data = messageData as ShareMessageData;
  const documentListKeys = Object.keys(data.DocumentNames);

  const header = (
    <>
      {documentListKeys.length > 0 ? (
        <Fmt id={'MessageCenterPage.message.detail.grid.shareFiles'} />
      ) : (
        <Fmt id={'MessageCenterPage.message.detail.grid.shareFolder'} />
      )}
    </>
  );

  const content = (
    <>
      <Typography.Text>
        {documentListKeys.length > 0 ? (
          <Col span={24}>
            <Fmt
              id="MessageCenterPage.message.detail.grid.shareMessageFiles"
              values={{
                user: data.SharedBy,
                documentCount: documentListKeys.length,
                projectName: projectName,
              }}
            />
          </Col>
        ) : (
          <Col span={24}>
            <Fmt
              id="MessageCenterPage.message.detail.grid.shareMessageFolder"
              values={{ user: data.SharedBy, projectName: projectName }}
            />
          </Col>
        )}
      </Typography.Text>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.receiver'} />
          </Col>
          <Col span={16}>{data.SharedTo}</Col>
        </Row>
        {data.ShareMessage && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.receiverMessage'} />
            </Col>
            <Col span={16}>{data.ShareMessage}</Col>
          </Row>
        )}
        {documentListKeys.length > 0 && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.documentNames'} />
            </Col>
            <Col span={16}>
              {documentListKeys.map((documentKey) => (
                <Row key={documentKey}>
                  <Col span={24}>
                    <Link to={parse(data.DocumentNames[documentKey] || '').pathname}>
                      <Typography.Link underline>{documentKey}</Typography.Link>
                    </Link>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        )}
        {data.DirectoryName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.directoryName'} />
            </Col>
            <Col span={16}>
              <Link to={parse(data.DirectoryLink || '').pathname}>
                <Typography.Link underline>{data.DirectoryName}</Typography.Link>
              </Link>
            </Col>
          </Row>
        )}
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
