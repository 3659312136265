import { ExportOutlined, GlobalOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { projectApi } from 'api/completeApi';
import { ExtendedPermissionEnum, ProjectSettingExternalAppSettingsEnum } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { ConstructionJournalIcon } from 'components/Icons/HubEntitiesIcons';
import { useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { userHasPermission } from 'utils/userHasPermission';
import { ExternAppListItemProps, ExternalAppSourceTypeEnum } from '../externAppsTypes';
import styles from './ApplicationUrl.module.less';
import ApplicationUrlSetForm from './ApplicationUrlSetForm';

type ApplicationUrlItemState = {
  title: React.ReactNode;
  icon: React.ReactNode;
};

export const ApplicationUrlListItem: FunctionComponent<ExternAppListItemProps & {
  type: ExternalAppSourceTypeEnum.ExternalSource;
}> = ({ externalAppSetting, onDelete: _onDelete, ...listItemProps }) => {
  const dispatch = useDispatch<Dispatch>();
  const [isEditExternalApplicationUrl, showEditExternalApplicationUrl, hideEditExternalApplicationUrl] = useBoolean();
  const currentUser = useCurrentProjectUser();
  const intl = useIntl();
  const [itemState, setItemState] = React.useState<ApplicationUrlItemState>({ icon: null, title: null });

  const handleDelete = useCallback(async () => {
    const [err] = await projectApi.projectsetting.externalapplication.id.delete(externalAppSetting?.id);
    if (err) {
      messageError(err, intl);
    }
    void dispatch.externalApplicationsSettings.loadData({ reload: true });
  }, [externalAppSetting, intl]);

  useEffect(() => {
    externalAppSetting.type === ProjectSettingExternalAppSettingsEnum.constructionJournal
      ? setItemState({
          title: <Fmt id={'ProjectSettingsPage.ExternApps.ConstructionJournal.listItemTitle'} />,
          icon: <ConstructionJournalIcon className={styles.icon} />,
        })
      : setItemState({
          title: (
            <Fmt
              id={'ProjectSettingsPage.ExternApps.ApplicationUrl.listItemTitle'}
              values={{ name: externalAppSetting.name }}
            />
          ),
          icon: <GlobalOutlined className={styles.icon} />,
        });
  }, [externalAppSetting]);

  const canEdit = currentUser.isAdmin || userHasPermission(currentUser, ExtendedPermissionEnum.externalApplications);
  const externalAppUrl = 'url' in externalAppSetting.data && externalAppSetting.data.url;
  const externalAppType =
    externalAppSetting.type === ProjectSettingExternalAppSettingsEnum.constructionJournal
      ? ProjectSettingExternalAppSettingsEnum.constructionJournal
      : ProjectSettingExternalAppSettingsEnum.pageUrl;

  return (
    externalAppUrl && (
      <>
        <GeneralSettingsItem
          selected={listItemProps.selected}
          icon={itemState.icon}
          title={itemState.title}
          description={<div>{externalAppUrl}</div>}
          additionalActions={
            <>
              {listItemProps.additionalActions}
              {canEdit && <EditButton onClick={showEditExternalApplicationUrl} />}
              {canEdit && <DeleteButtonConfirm onConfirm={handleDelete} />}
              {
                <a href={externalAppUrl} target="_blank" rel="noreferrer">
                  <Button type="link" icon={<ExportOutlined />} />
                </a>
              }
            </>
          }
        />
        <ApplicationUrlSetForm
          open={isEditExternalApplicationUrl}
          onAppAdded={hideEditExternalApplicationUrl}
          onCancel={hideEditExternalApplicationUrl}
          externalAppType={externalAppType}
          externalAppSetting={externalAppSetting}
        />
      </>
    )
  );
};
