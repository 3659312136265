import React from 'react';

export const FileModelOutlined = () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    stroke="currentColor"
    aria-hidden
    data-icon="file"
    focusable="false"
    version="1.1"
    viewBox="64 64 896 896"
  >
    <g transform="translate(-1011.6 163.77)">
      <g strokeWidth=".40721">
        <path d="m1524 264h0.1132c1.775 0.0126 3.5781 0.3502 5.3259 1.0486 0 0 139.81 55.853 167.94 67.1 0.2965 0.1185 0.8792 0.37952 0.8792 0.37952 2.0833 0.97894 3.8379 2.3977 5.1976 4.1002l0.067 0.0843c1.5906 2.0165 2.6351 4.4317 3.002 7.0069l0.017 0.11972c0.099 0.73176 0.1299 1.4794 0.1304 2.2213 0.022 36.17 0 214.8 0 214.8 0 5.4444-3.0175 10.44-7.836 12.975l-167.98 88.348c-2.1823 1.1686-4.3946 1.7429-6.6343 1.751-2.3301 0.0122-4.6898-0.58643-7.0781-1.751l-167.98-88.348c-4.8185-2.5341-7.8359-7.5301-7.8359-12.975v-214.8c-0.013-0.74683 0.031-1.4888 0.1303-2.2213l0.017-0.11972c0.3669-2.5748 1.4114-4.99 3.0019-7.0069l0.067-0.0843c1.3593-1.7026 3.1144-3.1213 5.1977-4.1002 0 0 0.6824-0.30215 0.8791-0.37952l167.94-67.1c1.7477-0.69837 3.5508-1.036 5.3259-1.0486zm-153.36 288 138.69 72.94-0.069-191.18-138.62-64.922zm168.09-118.24-0.069 191.18 138.69-72.94v-183.16zm-145.91-86.909 131.17 61.436 131.17-61.436-131.17-52.409z" />
        <path d="m1524 284h0.1132c1.775 0.0126 3.5781 0.3502 5.3259 1.0486 0 0 139.81 55.853 167.94 67.1 0.2965 0.1185 0.8792 0.37952 0.8792 0.37952 2.0833 0.97894 3.8379 2.3977 5.1976 4.1002l0.067 0.0843c1.5906 2.0165 2.6351 4.4317 3.002 7.0069l0.017 0.11972c0.099 0.73176 0.1299 1.4794 0.1304 2.2213 0.022 36.17 0 214.8 0 214.8 0 5.4444-3.0175 10.44-7.836 12.975l-167.98 88.348c-2.1823 1.1686-4.3946 1.7429-6.6343 1.751-2.3301 0.0122-4.6898-0.58643-7.0781-1.751l-167.98-88.348c-4.8185-2.5341-7.8359-7.5301-7.8359-12.975v-214.8c-0.013-0.74683 0.031-1.4888 0.1303-2.2213l0.017-0.11972c0.3669-2.5748 1.4114-4.99 3.0019-7.0069l0.067-0.0843c1.3593-1.7026 3.1144-3.1213 5.1977-4.1002 0 0 0.6824-0.30215 0.8791-0.37952l167.94-67.1c1.7477-0.69837 3.5508-1.036 5.3259-1.0486zm-153.36 288 138.69 72.94-0.069-191.18-138.62-64.922zm168.09-118.24-0.069 191.18 138.69-72.94v-183.16zm-145.91-86.909 131.17 61.436 131.17-61.436-131.17-52.409z" />
      </g>
      <path d="m1683 358.16v221.16" fill="none" strokeWidth="48" />
      <path d="m1524.3 428.29v221.16" fill="none" strokeWidth="48" />
      <path d="m1365.4 357.06v221.16" fill="none" strokeWidth="48" />
    </g>
    <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494z" />
  </svg>
);
