import { Alert, Button, Modal, Typography } from 'antd';
import {
  AccessLevelEnum,
  DirectoryListDto,
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
} from 'api/completeApiInterfaces';
import DirectorySettingsPermissions from 'components/DirectorySettingsPermissions/DirectorySettingsPermissions';
import PathDisplay from 'components/PathDisplay';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { ListItemProps } from 'pages/ProjectSettingsPage/ListItem';
import queryString from 'query-string';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import styles from './GrantingAccessModal.module.less';

export const ACCESS_REQUEST = 'access-request';

type Props = ListItemProps &
  InjectedIntlProps & {
    usersMap: Record<Guid, ProjectUserProfileListDto>;
    usersList: ProjectUserProfileListDto[];
    selectedDirectory: DirectoryListDto;
    currentUser: ProjectUserProfileDto;
    action: string;
    projectId: Guid;
    visible?: boolean;
    onClose?: () => void;
  };

const GrantingAccessModal: FunctionComponent<Props> = ({
  selectedDirectory,
  usersMap,
  usersList,
  projectId,
  visible = true,
  onClose,
}) => {
  const queryParams = queryString.parseUrl(window.location.search);
  const requestingUserId = queryParams.query['userId'] as string;
  const requestingPermission = queryParams.query['accesslevel'] as string;

  const requestingUser = requestingUserId && usersMap ? usersMap[requestingUserId.toString()] : undefined;
  const username = requestingUser ? requestingUser.username : '';
  const permission = requestingPermission && (
    <Fmt id={`AccessLevel.tooltips.${requestingPermission.toLowerCase()}` as IntlMessageId} />
  );

  if (requestingUser === undefined || usersMap === undefined) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={
        <Button type="primary" onClick={onClose}>
          <Fmt id="general.close" />
        </Button>
      }
      title={<Fmt id="GrantAccessModal.title" />}
      closable={false}
    >
      {selectedDirectory.currentAccessLevel !== AccessLevelEnum.admin ? (
        <>
          <Alert
            style={{ flex: 1, margin: '0 0 auto' }}
            message={<Fmt id={'GrantAccessModal.unauthorizedError.message'} />}
            type="error"
            showIcon
            banner
          />
        </>
      ) : (
        <div>
          <div className={styles.section}>
            <div className={styles.infoItem}>
              <Typography.Title level={4}>
                <Fmt id="GrantAccessModal.folderLabel" />
              </Typography.Title>
              <PathDisplay path={selectedDirectory.path} alignLeft className={styles.path} />
            </div>

            <div className={styles.userInfoSection}>
              <div className={styles.infoItem}>
                <Fmt id="GrantAccessModal.userLabel" /> <strong className={styles.infoValue}>{username}</strong>
              </div>
              <div className={styles.infoItem}>
                <Fmt id="GrantAccessModal.requiredPermissionLabel" />{' '}
                <strong className={styles.infoValue}>{permission}</strong>
              </div>
            </div>

            {selectedDirectory && (
              <DirectorySettingsPermissions
                directory={selectedDirectory}
                projectId={projectId}
                usersList={usersList}
                usersMap={usersMap}
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default injectIntl(GrantingAccessModal);
