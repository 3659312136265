import { uniq } from 'lodash';

export enum ArrayFlagsOperation {
  Set,
  Clear,
}

export const setArrayFlags = <T>(original: T[], change: T[], opration: ArrayFlagsOperation) => {
  switch (opration) {
    case ArrayFlagsOperation.Set:
      return uniq([...original, ...change]);
    case ArrayFlagsOperation.Clear: {
      const asSet = new Set(change);
      return original.filter((item) => !asSet.has(item));
    }
  }
};
