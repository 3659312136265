import { Typography } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DisplayName from 'components/DisplayName';
import { ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import PathDisplay from 'components/PathDisplay';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { directoryConnectedLinksSelector, directoryConnectedMapSelector, directoryRootSelector } from 'store/selectors';
import { ignoreRef } from 'utils';
import { searchTreeBySearchValue } from 'utils/searchTreeBySearchValue';
import { DirectoryNodeDirectoryKey, DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import {
  ConnectedDirectory,
  ConnectedDirectoryLink,
  DirectoryNode,
  DirectoryNodeType,
  directoryNodeHelpers,
} from 'utils/typeMappings/directories/directoryTypes';
import { DirectoriesPreferredSelect, PreferredDirectory } from './DirectoriesPreferredSelect';
import { DirectoriesSimpleSelect } from './DirectoriesSimpleSelect';

export type DirectoriesTreeProps = TreeSelectProps<DirectoryNodeKey>;

type Props = Omit<DirectoriesTreeProps, 'value' | 'onChange'> & {
  preferredDirectories?: PreferredDirectory[];
  isItemDisabled?: (item: ConnectedDirectory) => boolean;
  value: Guid;
  onChange: (value: Guid) => void;
  defaultValue?: DirectoryNodeKey;
  defaultExpandDepth?: number;
};

const linkName = (link: ConnectedDirectoryLink) => {
  return (
    <DisplayName>
      <ShortcutIcon /> {link.linkName}
    </DisplayName>
  );
};

const getDirectoryNodePath = (node: DirectoryNode) => {
  switch (node.type) {
    case DirectoryNodeType.Directory:
      return node.directory.path;
    case DirectoryNodeType.DirectoryLink: {
      return node.directoryLink.parentDirectory.path;
    }
  }
};

const getDirectoryKeysInDepth = (directory: ConnectedDirectory, depth: number): DirectoryNodeDirectoryKey[] => {
  if (depth <= 0) {
    return [];
  }
  const result = [directoryNodeHelpers.directoryKey(directory.id)];
  directory.children.forEach((child) => {
    if (child.type === DirectoryNodeType.Directory) {
      result.push(...getDirectoryKeysInDepth(child.directory, depth - 1));
    }
  });
  return result;
};

const mapTreeNode = (node: DirectoryNode, isItemDisabled: (item: ConnectedDirectory) => boolean): DefaultOptionType => {
  const key = directoryNodeHelpers.directoryNodeKey(node);
  const targetDirectory = directoryNodeHelpers.getTargetDirectory(node);
  const name: string = directoryNodeHelpers.getDirectoryNodeName(node);
  return {
    key,
    value: key,
    name: name,
    disabled: !targetDirectory || (isItemDisabled && isItemDisabled(targetDirectory)),
    path: <PathDisplay path={targetDirectory?.path} alignLeft />,
    title:
      node.type === DirectoryNodeType.DirectoryLink ? (
        linkName(node.directoryLink)
      ) : (
        <DisplayName>{node.directory.name}</DisplayName>
      ),
    children:
      node.type === DirectoryNodeType.Directory && !!targetDirectory.children.length
        ? targetDirectory.children.map((child) => mapTreeNode(child, isItemDisabled))
        : [],
  };
};

const DirectoriesTreeSelectComponent: FunctionComponent<Props> = ({
  preferredDirectories,
  isItemDisabled,
  filterTreeNode,
  treeDefaultExpandedKeys,
  value,
  onChange,
  defaultValue,
  defaultExpandDepth = 1,
  allowClear,
  ...restProps
}) => {
  const directoryRoot = useSelectorDispatch(directoryRootSelector, (dispatch) => {
    dispatch.directoriesWithLinks.loadData({ reload: false });
  });

  useDirtyStoreReload(
    (store) => store.directoriesWithLinks,
    (dispatch) => dispatch.directoriesWithLinks
  );

  const directoryMap = useSelector(directoryConnectedMapSelector);
  const directoryLinkMap = useSelector(directoryConnectedLinksSelector);

  const expandSomeKeys = useMemo(
    () =>
      treeDefaultExpandedKeys
        ? treeDefaultExpandedKeys
        : directoryRoot
        ? getDirectoryKeysInDepth(directoryRoot, defaultExpandDepth)
        : [],
    [treeDefaultExpandedKeys, directoryRoot, defaultExpandDepth]
  );

  const treeData: DefaultOptionType[] = useMemo(
    () => (directoryRoot ? [mapTreeNode(directoryNodeHelpers.directoryNode(directoryRoot), isItemDisabled)] : []),
    [directoryRoot, isItemDisabled]
  );

  const selectedKey: DirectoryNodeKey = useMemo(() => {
    return value || defaultValue ? directoryNodeHelpers.directoryKey(value || defaultValue) : undefined;
  }, [defaultValue, value]);

  const handleSelectKey = (selectedKey: DirectoryNodeKey) => {
    const selectedNode = directoryNodeHelpers.directoryNodeFromKey(selectedKey, directoryMap, directoryLinkMap);
    const targetDirectoryId = selectedNode && directoryNodeHelpers.getTargetDirectory(selectedNode).id;
    !!selectedKey
      ? targetDirectoryId && targetDirectoryId !== value && onChange(targetDirectoryId)
      : allowClear && onChange(undefined);
  };

  const commonProps = {
    treeDefaultExpandedKeys: expandSomeKeys,
    treeData,
    showSearch: true,
    filterTreeNode: filterTreeNode || searchTreeBySearchValue,
    value: selectedKey,
    onChange: handleSelectKey,
    allowClear,
    placeholder: <Fmt id="general.search" />,
    treeDefaultExpandAll: true,
    treeNodeLabelProp: 'name',
    ...restProps,
  };

  const selectedPath = useMemo(() => {
    const directory = directoryNodeHelpers.directoryNodeFromKey(selectedKey, directoryMap, directoryLinkMap);
    return directory && <PathDisplay path={getDirectoryNodePath(directory)} alignLeft />;
  }, [selectedKey, directoryMap, directoryLinkMap, directoryRoot]);

  return (
    <ContentGate loading={directoryRoot === null}>
      <>
        {!!preferredDirectories?.length ? (
          <DirectoriesPreferredSelect preferredDirectories={preferredDirectories} {...commonProps} />
        ) : (
          <DirectoriesSimpleSelect {...commonProps} />
        )}
        <div>
          <Typography.Text type="secondary">
            <Fmt id="general.path" />:
          </Typography.Text>
        </div>
        {selectedPath}
      </>
    </ContentGate>
  );
};

export const DirectoriesTreeSelect = React.memo(DirectoriesTreeSelectComponent);

export const DirectoriesTreeSelectFormItem = ignoreRef(DirectoriesTreeSelect) as FunctionComponent<
  Omit<Props, 'value' | 'onChange'>
>;
