import { Col, Row, Typography } from 'antd';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment, { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

enum DocumentNotificationTypeEnum {
  releaseReservationAlert = 0,
  reservationReleased = 1,
}

type DocumentNotificationInfo = {
  DocumentNotificationType: DocumentNotificationTypeEnum;
  DocumentName: string;
  DocumentPath: string;
  DocumentLink: string;
  ExpiredTime: Moment;
};

export type DocumentMessageData = {
  Language: string;
  ProjectDescription: string;
  FinishTime: IsoDateTime;
  DocumentNotificationInfo: DocumentNotificationInfo;
};

export const DocumentMessageViewer: MessageViewer = ({ category, messageData, projectName, organizationName }) => {
  const data = messageData as DocumentMessageData;
  const intl = useIntl();
  const header = <Fmt id={'MessageCenterPage.message.detail.document.title'} />;
  const expireDate = moment(data.DocumentNotificationInfo.ExpiredTime)
    .locale(intl.locale)
    .format('LLL');

  const content = (
    <>
      <Typography.Text>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>

      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.document'} values={{ count: 1 }} />
          </Col>
          <Col span={12}>
            <Link to={parse(data.DocumentNotificationInfo.DocumentLink || '').pathname}>
              <Typography.Link underline>{data.DocumentNotificationInfo.DocumentName}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.documentPath'} />
          </Col>
          <Col span={12}>{data.DocumentNotificationInfo.DocumentPath}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.expirationReservation'} />
          </Col>
          <Col span={12}>{expireDate}</Col>
        </Row>
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
