import { createCancelToken } from 'api';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import Axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { processApiError } from 'utils';

type UseApiActionConfig<T> = {
  fetchCallback?: (data: T) => void;
  errorCallback?: (err: ApiError) => void;
  errorCallbackOnCancel?: boolean;
  autoload?: boolean;
};

export const useApiAction = <T>(
  fetchFn: (ct?: CancelToken) => Promise<[ApiError, AxiosResponse<T>]>,
  config?: UseApiActionConfig<T>
) => {
  const { fetchCallback, errorCallback, errorCallbackOnCancel, autoload } = config || {};

  const [error, setError] = useState<ServiceError>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctSourceRef = useRef<CancelTokenSource>();

  const callAction = () => {
    ctSourceRef.current?.cancel('useApiAction: another fetch call was made');
    ctSourceRef.current = createCancelToken();

    setLoading(true);
    fetchFn(ctSourceRef.current.token).then(([err, resp]) => {
      setLoading(false);

      if (Axios.isCancel(err)) {
        if (errorCallbackOnCancel) {
          errorCallback && errorCallback(err);
        }
        return;
      }

      if (err) {
        processApiError(err, setError);
        errorCallback && errorCallback(err);
      } else {
        setError(null);
        fetchCallback && fetchCallback(resp.data);
      }
    });
  };

  const manualCancel = useCallback((message: string) => {
    ctSourceRef.current?.cancel(message);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (autoload) {
      callAction();
    }
    return () => ctSourceRef.current?.cancel('useApiAction: unmounting');
  }, []);

  return [callAction, loading, error, manualCancel] as const;
};
