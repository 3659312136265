import axios from 'axios';
import { API_BASE_URL } from 'config/env';

const baseHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  // 'X-My-Header': '123',
};

export const baseProjectApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    ...baseHeaders,
  },
  // timeout: 10000,
});
