import { HddOutlined } from '@ant-design/icons';
import DisplayName from 'components/DisplayName';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import * as lodash from 'lodash';
import React, { FunctionComponent } from 'react';
import { directoryRootSelector } from 'store/selectors';
import { getDefaultRootName } from 'utils/getRootName';
import styles from './PathDisplay.module.less';
import { PathPartsDisplay } from './PathPartsDisplay';

type PathDisplayProps = {
  path: string;
  className?: string;
  inline?: boolean;
  alignLeft?: boolean;
};

export const pathToParts = (rootName: string, path: string) => {
  const pathParts = lodash
    .trim(path, '/')
    .split('/')
    .filter((part) => part && part.length > 0);

  const root = (
    <span className={styles.part}>
      <>
        <HddOutlined className={styles.root} />
        <DisplayName>{rootName}</DisplayName>
      </>
    </span>
  );

  const partsLink = pathParts.map((part: string) => {
    return (
      // key will be inserted elsewhere
      // eslint-disable-next-line react/jsx-key
      <span className={styles.part}>
        <DisplayName>{part}</DisplayName>
      </span>
    );
  });

  return [root, ...partsLink];
};

const PathDisplay: FunctionComponent<PathDisplayProps> = ({ path, ...restProps }) => {
  const rootNode = useSelectorDispatch(directoryRootSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  const defaultRootNodeName = getDefaultRootName(rootNode);

  const parts = pathToParts(defaultRootNodeName, path);

  const tooltip = defaultRootNodeName + path;

  return <PathPartsDisplay parts={parts} tooltip={tooltip} {...restProps} />;
};

export default PathDisplay;
