import { EstiConDphSazbyDto, EstiConDphZemeDto, EstiConProjectDto } from 'api/completeApiInterfaces';
import Decimal from 'decimal.js';
import { Precisions } from 'hooks/useEsticon';
import { LanguageEnum } from 'locale/messages/interfaces';
import { useMemo } from 'react';
import { Comparator, numberComparer, stringAsNumberComparer } from 'utils/comparators';

export const prepareValue = (value: number, decimalPlaces: number) =>
  new Decimal(value ? value : 0).toDecimalPlaces(decimalPlaces).toNumber();

export const addValues = (a: number, b: number, decimalPlaces: number) =>
  +Decimal.add(prepareValue(a, decimalPlaces), prepareValue(b, decimalPlaces)).toFixed(decimalPlaces);

export const subValues = (a: number, b: number) => +Decimal.sub(a, b);

export const calculatePercent = (total: number, partAmount: number, decimalPlaces: number): number => {
  if (!total) return 0;
  return +Decimal.mul(Decimal.div(100, total), partAmount).toFixed(decimalPlaces);
};

export const calculatePercentRate = (total: number, partAmount: number): number => {
  if (!total) return 0;
  return partAmount / total;
};

export const calculateCost = (amount: number, costPerUnit: number, precisions: Precisions): number => {
  return +Decimal.mul(prepareValue(amount, precisions.amount), prepareValue(costPerUnit, precisions.unitPrice)).toFixed(
    precisions.price
  );
};

export const roundValue = (value: number, decimalPlaces: number): number => {
  if (!value) return value;
  return new Decimal(value).toDecimalPlaces(decimalPlaces).toNumber();
};

export const formatNumberLocal = (
  value: number,
  precision: number,
  locale: LanguageEnum,
  maximumFractionDigits?: number
) =>
  value != null &&
  value.toLocaleString(locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: maximumFractionDigits !== undefined ? maximumFractionDigits : precision,
  });

export const formatPercentLocal = (value: number, precision: number, locale: LanguageEnum) =>
  value?.toLocaleString(locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: 'percent',
  });

export type BudgetTreeNode = { key: Guid; children: BudgetTreeNode[] };

export const getExpandedAll = (budget: BudgetTreeNode[]): Guid[] => {
  return (
    !!budget &&
    budget.reduce(
      (acc, root) => [
        ...acc,
        root.key,
        ...(root.children && root.children.length > 0 ? getExpandedAll(root.children) : []),
      ],
      []
    )
  );
};

export const generateEmptyValues = <T>(keys: (keyof T)[]): T => {
  return keys.reduce((acc, key: keyof T) => {
    return { ...acc, [key]: 0 };
  }, {} as T);
};

// TODO: move to better location
export type GridNumberFormats = Record<keyof Precisions, { type: 'fixedPoint'; precision: number }>;

export const getNumberFormatSettings: (precisions: Precisions) => GridNumberFormats = (precisions) => {
  if (!precisions) return undefined;
  return Object.keys(precisions).reduce((result, key: keyof Precisions) => {
    return { ...result, [key]: { type: 'fixedPoint', precision: precisions[key] } };
  }, {} as Record<keyof Precisions, { type: 'fixedPoint'; precision: number }>);
};

export const sumValues = <T extends Record<string, number>>(
  result: T,
  item: T,
  precision: number,
  keys: (keyof T)[]
): T => {
  return keys.reduce((acc, key: keyof T) => {
    return { ...acc, [key]: addValues(result[key] || 0, item[key] || 0, precision) };
  }, {} as T);
};

export const sumValuesByChildren = <T extends Record<string, number>>(
  children: T[] = [],
  precision: number,
  items: (keyof T)[]
): T => {
  const emptyValues: T = generateEmptyValues(items);
  return children
    ? children.reduce((result: T, child: T) => {
        return sumValues(result, child, precision, items);
      }, emptyValues)
    : emptyValues;
};

export function getVatRates(
  vatData: EstiConDphZemeDto[],
  idPeriod: Guid,
  idCountry: Guid
): EstiConDphSazbyDto | undefined {
  return vatData?.find((vatCountry) => vatCountry.id === idCountry)?.sazby.find((rate) => rate.id === idPeriod);
}

export function getRate(rates: EstiConDphSazbyDto | undefined, rate: number) {
  if (!rates) {
    return undefined;
  }
  switch (rate) {
    case 1:
      return rates.sazba1;
    case 2:
      return rates.sazba2;
    case 3:
      return rates.sazba3;
    default:
      return undefined;
  }
}

export const calculateVat = (price: number, precisions: Precisions, vatRate: number | undefined) => {
  if (price == null || !vatRate) {
    return undefined;
  }
  const totalVat = vatRate ? roundValue(price * vatRate, precisions.price) : 0;
  const totalPriceWithVat = addValues(price, totalVat, precisions.price);
  return {
    totalVat: totalVat,
    totalPriceWithVat: totalPriceWithVat,
    vatRate,
  };
};

export const useVatRate = (project: EstiConProjectDto, vatData: EstiConDphZemeDto[]) => {
  return useMemo(() => {
    return getRate(
      getVatRates(vatData, project.settings.idDphObdobi, project.settings.idDphZeme),
      project.settings.dphSazba
    );
  }, [project, vatData]);
};

export type SortBuildingItemType = {
  sign: string;
  variant?: string;
  orderNumber?: number;
};

export const buildingItemComparator: Comparator<SortBuildingItemType> = stringAsNumberComparer
  .map((building: SortBuildingItemType) => building.sign)
  .andThen(stringAsNumberComparer.map((building) => building.variant))
  .andThen(numberComparer.map((building) => building.orderNumber));
