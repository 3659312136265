import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './FlexTabs.module.less';

export const FlexTabs: FunctionComponent<TabsProps> = ({ children, className, ...restProps }) => {
  return (
    <Tabs className={classNames(styles.flexTabs, className)} {...restProps}>
      {children}
    </Tabs>
  );
};
