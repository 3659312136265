import { TooltipProps, Typography } from 'antd';
import { TOOLTIP_DELAY } from 'components/CommonHubTooltip/CommonHubTooltip';
import React, { FunctionComponent } from 'react';

const CommonHubEllipsisText: FunctionComponent<TooltipProps> = ({
  children,
  mouseEnterDelay = TOOLTIP_DELAY,
  placement = 'top',
  title,
  ...restProps
}) => {
  return (
    <Typography.Text ellipsis={{ tooltip: { mouseEnterDelay, placement, title: title || children, ...restProps } }}>
      {children}
    </Typography.Text>
  );
};

export default React.memo(CommonHubEllipsisText);
