import React, { RefObject, useContext, useEffect, useMemo, useRef } from 'react';
import { useMultiSelect } from 'hooks/useMultiSelect';
import { List } from 'react-virtualized';

type SelectedItemsContextType<T extends { id: Guid } = any> = {
  selectedItemsIds: Set<Guid>;
  selectAll: () => void;
  deselectAll: () => void;
  selectItem: (id: Guid, actualIndex: number, exclusively?: boolean) => void;
  isAnyItemSelected: boolean;
  isAllSelected: boolean;
  setConfig: (config: SourceConfig<T>) => void;
  totalItemsCount: number;
};

export const SelectedItemsContext = React.createContext<SelectedItemsContextType>(undefined);

type SelectedContextProviderProps<T> = {
  children: React.ReactNode;
};

type SourceConfig<T> = {
  items: T[];
  isSelectableFilter?: (item: T) => boolean;
  defaultSelectedItemsIds?: Set<Guid>;
  listRef: RefObject<List>;
};

export const SelectedItemsProvider = <T extends { id: Guid }>({ children }: SelectedContextProviderProps<T>) => {
  const [config, setConfig] = React.useState<SourceConfig<T> | null>({
    items: [],
    isSelectableFilter: undefined,
    defaultSelectedItemsIds: new Set(),
    listRef: null,
  });

  const [selectedItemsIds, deselectAll, isAllSelected, selectAll, selectItem, isAnyItemSelected] = useMultiSelect<T>(
    config?.listRef,
    config?.items,
    config?.isSelectableFilter,
    config?.defaultSelectedItemsIds
  );

  const value: SelectedItemsContextType<T> = useMemo(
    () => ({
      selectedItemsIds,
      selectAll,
      deselectAll,
      selectItem,
      isAnyItemSelected,
      isAllSelected,
      setConfig,
      totalItemsCount: config?.items.length,
    }),
    [
      selectedItemsIds,
      selectAll,
      deselectAll,
      selectItem,
      isAnyItemSelected,
      isAllSelected,
      setConfig,
      config?.items.length,
    ]
  );

  return <SelectedItemsContext.Provider value={value}>{children}</SelectedItemsContext.Provider>;
};

export const useSelectedItems = <T,>(items: T[]) => {
  const context = useContext(SelectedItemsContext);
  const listRef = useRef<List>();

  useEffect(() => {
    if (!items) return;
    context?.setConfig({
      listRef: listRef,
      items: items,
    });
  }, [items]);

  return [context, listRef] as const;
};
