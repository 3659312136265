import { hexToRgb } from 'utils';

/**
 * computes contrast color based on input
 * returns #000000 or #ffffff
 */

export const colorContrast = (hex: string) => {
  const rgb = hexToRgb(hex);
  // http://www.w3.org/TR/AERT#color-contrast
  const o = Math.round((rgb?.r * 299 + rgb?.g * 587 + rgb?.b * 114) / 1000);
  return o > 125 ? '#000000' : '#ffffff';
};
