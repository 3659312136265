import { ReactNode } from 'react';
import { ArrayFlagsOperation } from 'utils/arrayHelpers';
import { renderToggleNodes } from './ToggleGroupNode';
import { IntlMessageId } from 'locale/messages/cs';

export enum ToggleNodeEnum {
  ToggleSwitch,
  ToggleGroup,
}

export type ToggleNodeSwitch<T> = {
  type: ToggleNodeEnum.ToggleSwitch;
  label: IntlMessageId;
  value: T;
  icon?: ReactNode;
};

export type ToggleNodeGroup<T> = {
  type: ToggleNodeEnum.ToggleGroup;
  label: IntlMessageId;
  children: ToggleNode<T>[];
  icon?: ReactNode;
};

export type ToggleNode<T> = ToggleNodeSwitch<T> | ToggleNodeGroup<T>;

type Props<T> = {
  nodes: ToggleNode<T>[];
  value: Set<T>;
  onChange: (values: T[], operation: ArrayFlagsOperation) => void;
  disabled?: boolean;
};

export const GroupedToggles = <T,>(props: Props<T>): JSX.Element => {
  const { nodes, value, onChange, disabled } = props;
  return renderToggleNodes(nodes, value, onChange, disabled);
};
