import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { FiltersContextProvider } from 'components/filters/FiltersContextProvider';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { SelectedItemsProvider } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import ProjectsList, { PROJECT_LIST_ORDER_OPTIONS } from 'pages/ProjectsListPage/ProjectsList';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import styles from './ProjectsListPage.module.less';

type Props = RouteComponentProps;

const ProjectsListPageComponent: FunctionComponent<Props> = () => {
  return (
    <ErrorBoundary>
      <SideMenuActivator menuItemKey={SideMenuKey.PROJECTS}>
        <div className={styles.content}>
          <SelectedItemsProvider>
            <FiltersContextProvider
              persistentKey={FiltersPersistentKey.ProjectsAll}
              orderOptions={PROJECT_LIST_ORDER_OPTIONS}
            >
              <ProjectsList />
            </FiltersContextProvider>
          </SelectedItemsProvider>
        </div>
      </SideMenuActivator>
    </ErrorBoundary>
  );
};

export default React.memo(ProjectsListPageComponent);
