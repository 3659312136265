export const createScriptElement = (src: string, onLoad?: () => void, id?: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = onLoad;
  if (id) {
    script.id = id;
  }
  document.body.appendChild(script);
  return script;
};

export const onLoadScript = async (script: HTMLScriptElement): Promise<void> => {
  return new Promise((resolve) => {
    const onLoad = () => {
      script.removeEventListener('load', onLoad);
      resolve();
    };
    script.addEventListener('load', onLoad);
  });
};

export const loadScript = async (src: string, id?: string): Promise<void> => {
  return onLoadScript(createScriptElement(src, undefined, id));
};
