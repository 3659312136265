import { api } from 'api';
import { GroupListDto } from 'api/completeApiInterfaces';
import { useDeleteItems } from 'hooks';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import { smartFilter } from 'utils';
import List from 'components/List';
import { GroupUserCount } from './GroupUserCount';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { RightOutlined } from '@ant-design/icons';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';

type Props = InjectedIntlProps & {
  listData: GroupListDto[];
  search: string;
  selectedId: Guid;
  onSelect?: (categoryId: Guid) => void;
  onEdit?: (categoryId: Guid) => void;
  onDelete?: (categoryId: Guid) => void;

  onClearSearch?: () => void;
};

const GroupsList: FunctionComponent<Props> = ({
  intl,
  listData,
  search,
  selectedId,
  onSelect,
  onEdit,
  onDelete,
  onClearSearch,
}) => {
  const [deletingItems, handleDelete] = useDeleteItems<Guid>(
    intl,
    (id) => api.project.groups.deleteGroupById(id),
    onDelete
  );
  return (
    <GeneralSettingsContainer>
      <List<GroupListDto>
        data={listData}
        search={search}
        filterItem={(item) => smartFilter(item.name, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            selected={item.id === selectedId}
            selectable
            onClick={onSelect ? () => onSelect(item.id) : null}
            title={item.name}
            wrap
            loading={deletingItems.has(item.id)}
            additionalActions={
              <>
                <GroupUserCount userCount={item.userIds?.length} />
                {!item.isAdminGroup && <EditButton onClick={() => onEdit(item.id)} />}
                {!item.isAdminGroup && <DeleteButtonConfirm onConfirm={() => handleDelete(item.id)} />}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
    </GeneralSettingsContainer>
  );
};

export default injectIntl(GroupsList);
