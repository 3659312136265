import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Row, Spin } from 'antd';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { detect } from 'detect-browser';
import { Fmt } from 'locale';
import React, { useEffect, useState } from 'react';

export const PdfViewer: FileViewerComponent = ({ style, className, url, onLoad }) => {
  const [browserName, setBrowserName] = useState<string>(undefined);
  useEffect(() => {
    const browser = detect();
    setBrowserName(browser.name);
    onLoad && onLoad();
  }, []);

  if (browserName === undefined) {
    return <Spin />;
  }

  return browserName === 'safari' ? (
    <Row align="middle" justify="center" style={style}>
      <Button type="primary" href={url} target="_blank">
        <Fmt id="FileViewer.UnsupportedPDFBrowser" />
      </Button>
    </Row>
  ) : (
    <object data={url} style={style} className={className} type="application/pdf">
      <iframe style={style} src={url} frameBorder={0} />
    </object>
  );
};

export const PdfViewerData: FileViewerProperties = {
  supportedTypes: ['application/pdf'],
  icon: <FilePdfOutlined />,
  titleTranslationId: 'PdfViewer.title',
  component: 'PdfViewer',
};
