import classNames from 'classnames';
import React, { FunctionComponent, HTMLProps, Ref } from 'react';
import styles from './StackPanel.module.less';

export type StackPanelJustifyContentType = 'centerContent' | 'endContent' | 'splitContent';

type Props = HTMLProps<HTMLDivElement> & {
  vertical?: boolean;
  centerItems?: boolean;
  stretch?: boolean;
  padding?: boolean;
  scrollable?: boolean;
  autoHeight?: boolean;
  growLast?: boolean;
  showOverflow?: boolean;
  autoWidth?: boolean;
  divRef?: Ref<HTMLDivElement>;
  justifyContent?: StackPanelJustifyContentType;
};

const StackPanel: FunctionComponent<Props> = ({
  children,
  vertical,
  className,
  padding,
  centerItems,
  stretch,
  scrollable,
  autoHeight,
  growLast,
  showOverflow,
  autoWidth,
  divRef,

  justifyContent,
  ...rest
}) => (
  <div
    {...rest}
    ref={divRef}
    className={classNames(
      styles.container,
      centerItems && styles.center,
      vertical && styles.vertical,
      stretch && styles.stretch,
      padding && styles.paddingNormal,
      autoHeight && styles.autoHeight,
      scrollable && styles.scrollable,
      growLast && styles.growLast,
      showOverflow && styles.showOverflow,
      autoWidth && styles.autoWidth,
      justifyContent && styles[justifyContent],
      className
    )}
  >
    {children}
  </div>
);

export default StackPanel;
