import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { FilterDisplay } from 'components/filters/render/FilterDisplay/FilterDisplay';
import { DateRangeValue, InlineDateRangePicker } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import { Margin } from 'components/Margin/Margin';
import { DEBUG } from 'config/env';
import { useSameCallback } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import moment, { MomentInput } from 'moment';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckFormat, checkIsoDateTime, checkNullable, checkObject, checkString, valueOrProducer } from 'utils';
import styles from './DateFilter.module.less';

export type DateFilterValue = {
  type: string;
  from: IsoDateTime | null;
  to: IsoDateTime | null;
};

const IS_EMPTY = (value: DateFilterValue) => !value.from && !value.to;
const DEFAULT_VALUE: Omit<DateFilterValue, 'type'> = { from: null, to: null };
const CLEARED_VALUE: React.SetStateAction<DateFilterValue> = (value) => ({
  type: value.type,
  ...DEFAULT_VALUE,
});
const CHECK_FORMAT: CheckFormat<DateFilterValue> = checkObject({
  type: checkString,
  from: checkNullable(checkIsoDateTime),
  to: checkNullable(checkIsoDateTime),
});

type DateFilterType = {
  key: string;
  label: ReactNode;
  default?: boolean;
};

export type DateFrontendFilterType<T> = DateFilterType & {
  valueSelector: (item: T) => MomentInput;
};

export type DateBackendFilterType<T> = DateFilterType & {
  serialize: (accumulator: T, value: DateFilterValue) => T;
};

const getDefaultValue = (filterTypes: DateFilterType[]): DateFilterValue => ({
  type: filterTypes.find((type) => type.default)?.key || filterTypes[0]?.key,
  ...DEFAULT_VALUE,
});

type Props = InjectedIntlProps & {
  label?: ReactNode;
  title?: ReactNode;
  value: DateFilterValue;
  onChange: React.Dispatch<React.SetStateAction<DateFilterValue>>;
  filterTypes: DateFilterType[];
};

const DateFilterComponent: FunctionComponent<Props> = ({ intl, label, title, value, onChange, filterTypes }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const hideDropdown = useCallback(() => setDropdownVisible(false), []);

  // make sure that some filter type is selected (in case that filter types change)
  useEffect(() => {
    if (filterTypes.length && !filterTypes.some((type) => type.key === value.type)) {
      onChange((value) => ({ ...value, type: filterTypes[0]?.key }));
    }
  }, [filterTypes, value]);

  const handleTypeChange = useCallback(
    (event: RadioChangeEvent) => {
      const type = event.target.value;
      onChange((value) => ({ ...value, type }));
    },
    [onChange]
  );

  const selectedRange = useMemo<DateRangeValue>(
    () => [value.from && moment(value.from), value.to && moment(value.to)],
    [value]
  );

  const handleFilterChange = useSameCallback((filterData: React.SetStateAction<DateRangeValue>) => {
    const newValue = valueOrProducer(filterData, selectedRange);
    onChange({
      type: value.type,
      from: newValue?.[0]
        ?.startOf('day')
        .utc()
        .toISOString(),
      to: newValue?.[1]
        ?.endOf('day')
        .utc()
        .toISOString(),
    });
  });

  const selectedLabel = useMemo(() => {
    if (IS_EMPTY(value)) {
      return null;
    }

    const labelFrom = selectedRange[0] ? (
      selectedRange[0].locale(intl.locale).format('ll')
    ) : (
      <Fmt id="DateFilter.unlimited" />
    );
    const labelTo = selectedRange[1] ? (
      selectedRange[1].locale(intl.locale).format('ll')
    ) : (
      <Fmt id="DateFilter.unlimited" />
    );

    return (
      <>
        {labelFrom} - {labelTo}
      </>
    );
  }, [value, selectedRange, intl]);

  const typeOptions = useMemo(
    () =>
      filterTypes.length >= 2 && (
        <Radio.Group onChange={handleTypeChange} value={value.type} className={styles.typeSelect}>
          {filterTypes.map((filterType) => (
            <Radio key={filterType.key} value={filterType.key}>
              {filterType.label}
            </Radio>
          ))}
        </Radio.Group>
      ),
    [value, filterTypes]
  );

  const clearFilter = useCallback(() => onChange(CLEARED_VALUE), [onChange]);

  const usedLabel = label || (filterTypes.length === 1 ? filterTypes[0].label : <Fmt id="general.date" />);

  return (
    <FilterDisplay
      label={usedLabel}
      value={selectedLabel}
      isEmpty={IS_EMPTY(value)}
      clearFilter={clearFilter}
      dropdownVisible={dropdownVisible}
      setDropdownVisible={setDropdownVisible}
    >
      <Margin top right bottom left>
        {title && <div className={styles.title}>{title}</div>}
        {typeOptions}
        <InlineDateRangePicker value={selectedRange} onChange={handleFilterChange} allowClear onOk={hideDropdown} />
      </Margin>
    </FilterDisplay>
  );
};

export const DateFilter = memoizeWithIntl(DateFilterComponent);

type AdditionalProps = Omit<Props, 'intl' | 'value' | 'onChange' | 'filterTypes'>;

const createCommonDateFilter = (
  key: string,
  filterTypes: DateFilterType[],
  props?: AdditionalProps,
  defaultValue?: DateFilterValue
): CommonFilter<DateFilterValue> => ({
  key,
  render: (value, onChange) => <DateFilter {...props} value={value} onChange={onChange} filterTypes={filterTypes} />,
  isEmpty: IS_EMPTY,
  defaultValue: defaultValue || getDefaultValue(filterTypes),
  clearedValue: CLEARED_VALUE,
  checkFormat: CHECK_FORMAT,
});

export const createFrontendDateFilter = <T,>(
  key: string,
  filterTypes: DateFrontendFilterType<T>[],
  props?: AdditionalProps,
  defaultValue?: DateFilterValue
): FrontendFilter<T, DateFilterValue> => ({
  ...createCommonDateFilter(key, filterTypes, props, defaultValue),
  filter: (value) => {
    const fromMoment = value.from && moment(value.from).startOf('day');
    const toMoment = value.to && moment(value.to).endOf('day');
    const dateSelector = filterTypes.find((type) => type.key === value.type)?.valueSelector;
    if (!dateSelector) {
      DEBUG && console.error('Invalid key for filterType:', key, value.type, filterTypes);
      return (_item: T) => true;
    }
    return (item: T) => {
      const itemDate = dateSelector(item);
      if (!itemDate) return false;
      const itemMoment = moment(itemDate);
      if (fromMoment && itemMoment < fromMoment) return false;
      if (toMoment && itemMoment > toMoment) return false;
      return true;
    };
  },
});

export const createBackendDateFilter = <T,>(
  key: string,
  filterTypes: DateBackendFilterType<T>[],
  props?: AdditionalProps,
  defaultValue?: DateFilterValue
): BackendFilter<T, DateFilterValue> => ({
  ...createCommonDateFilter(key, filterTypes, props, defaultValue),
  serialize: (accu, value) => {
    const filterType = filterTypes.find((type) => type.key === value.type);
    if (filterType) {
      return filterType.serialize(accu, value);
    }
    DEBUG && console.error('Invalid key for filterType: ', key, value.type, filterTypes);
    return accu;
  },
});
