import { GroupListDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { Fmt, InjectedIntl } from 'locale';
import React, { FunctionComponent } from 'react';
import UserTab from './UserTab';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';

type Props = {
  url: string;
  intl: InjectedIntl;
  loadGroups: () => void;
  groupUsers: ProjectUserProfileListDto[];
  selectedGroup: GroupListDto;
  usersList: ProjectUserProfileListDto[];
};

const GroupDetailPanelComponent: FunctionComponent<Props> = ({
  selectedGroup,
  intl,
  loadGroups,
  url,
  groupUsers,
  usersList,
}) => {
  return (
    <MasterComponent
      url={url}
      title={selectedGroup?.name}
      children={() => (
        <StackPanel vertical scrollable>
          <GeneralSettingsContainer itemsLargeGap>
            <GeneralSettingsContainer>
              <GeneralSettingsItem title={<Fmt id="general.name" />} description={selectedGroup?.name} />
              <GeneralSettingsItem title={<Fmt id="general.description" />} description={selectedGroup?.description} />
            </GeneralSettingsContainer>

            <GeneralSettingsContainer
              title={
                <>
                  <Fmt id="general.users" /> ({selectedGroup?.userIds?.length})
                </>
              }
            >
              <UserTab
                isAdminGroup={selectedGroup?.isAdminGroup}
                usersList={usersList}
                groupUsers={groupUsers}
                groupId={selectedGroup?.id}
                onDelete={loadGroups}
                onUsersAdd={loadGroups}
              />
            </GeneralSettingsContainer>
          </GeneralSettingsContainer>
        </StackPanel>
      )}
    />
  );
};

export default React.memo(GroupDetailPanelComponent);
