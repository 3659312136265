import { message } from 'antd';
import { api } from 'api';
import { DirectoryListDto, DocumentLinkCreateDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import {
  IMultipleDocumentErrorData,
  transformError,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { processApiError } from 'utils';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentLinksCreateForm, { DocumentsLinksCreateFormData } from './DocumentLinksCreateForm';
import { useIntl } from 'hooks';

type Props = FormModalProps<number> & {
  disabled?: (node: ConnectedDirectory) => boolean;
  selectedDirectory: DirectoryListDto;
  destinationDirectory?: Guid;
  selectedDocumentIds: Guid[];
  selectedDocumentLinkIds: Guid[];
  defaultDocumentLinkName?: string;
};

export const DocumentLinksCreateFormModal: FunctionComponent<Props> = React.memo(
  ({
    onSubmit,
    onClose,
    selectedDirectory,
    selectedDocumentIds,
    selectedDocumentLinkIds,
    defaultDocumentLinkName,
    destinationDirectory,
    ...formModalProps
  }) => {
    const intl = useIntl();
    const [errors, setErrors] = useState<JSX.Element[]>([]);
    const [selectedOkDocuments, setSelectedOkDocuments] = useState<Guid[]>([]);
    const [selectedOkLinks, setSelectedOkLinks] = useState<Guid[]>([]);

    useEffect(() => {
      setSelectedOkDocuments(selectedDocumentIds || []);
      setSelectedOkLinks(selectedDocumentLinkIds || []);
      setErrors([]);
    }, [selectedDocumentIds, selectedDocumentLinkIds]);

    const handleSubmit: FormSubmitHandler<DocumentsLinksCreateFormData> = async (values) => {
      const documentLinkCreate: DocumentLinkCreateDto = {
        directoryId: values.destinationDirectory,
        documentIds: selectedOkDocuments,
        documentLinksIds: selectedOkLinks,
        name: values.documentLinkName,
      };
      const [err] = await api.project.documentLinks.addLinks(documentLinkCreate);
      if (err) {
        processApiError(err, (error) => {
          if (error != null && !!error.errorData) {
            switch (error.referenceErrorCode) {
              case ServiceErrorEnum.DocumentMultipleDiscardObstacleError:
                // eslint-disable-next-line no-case-declarations
                const errData = error.errorData as IMultipleDocumentErrorData;
                setSelectedOkDocuments(
                  documentLinkCreate.documentIds.filter(
                    (doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc)
                  )
                );
                setSelectedOkLinks(
                  documentLinkCreate.documentLinksIds.filter(
                    (doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc)
                  )
                );
                setErrors(errData.documentsErrors.map(transformError));
                return null;
            }
          }
          message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
        });
        return null;
      }
      await onSubmit(selectedOkDocuments.length + selectedOkLinks.length);
      return null;
    };

    const handleClose = () => {
      onClose();
    };

    return (
      <FormModalWrapper
        onSubmit={handleSubmit}
        onCancel={handleClose}
        onClose={handleClose}
        title={
          <Fmt
            id="DocumentLinksCreateFormModal.title"
            values={{ count: selectedDocumentIds?.length + selectedDocumentLinkIds?.length }}
          />
        }
        confirmClose={false}
        submitTextId={
          errors.length === 0
            ? 'DocumentLinksCreateFormModal.okButton'
            : 'DocumentLinksCreateFormModal.okButton.createNext'
        }
        onSubmitDisabled={!selectedOkDocuments?.length && !selectedOkLinks?.length}
        width={700}
        {...formModalProps}
      >
        {({ intl, formRef }) => (
          <DocumentLinksCreateForm
            selectedDirectory={selectedDirectory}
            defaultDocumentLinkName={defaultDocumentLinkName}
            selectedDocumentsCount={selectedOkDocuments.length + selectedOkLinks.length}
            destinationDirectory={destinationDirectory}
            wrappedComponentRef={formRef}
            intl={intl}
            errors={errors}
          />
        )}
      </FormModalWrapper>
    );
  }
);

export default DocumentLinksCreateFormModal;
