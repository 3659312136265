import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row, Select, Spin, TreeSelect } from 'antd';
import { DocumentCategoryNodeDto, DocumentCategoryTreeDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import DisplayName from 'components/DisplayName';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { CategoryTrees } from 'store/models/storeModelinterfaces';
import { smartFilter } from 'utils';
import { requiredRule } from 'utils/formHelpers';
import styles from './DocumentCategoryForm.module.less';

export type LayoutType = 'vertical' | 'horizontal' | 'inline';

export interface ICategoryListItemData {
  categoryId: Guid;
  required: boolean;
  defaultCategoryNodeId: Guid;
}

export type CategoryList = ICategoryListItemData[];

interface IProps {
  layout: LayoutType;
  categoryList: DocumentCategoryTreeDto[]; // list of all available categories
  categoryTreeNodes?: { [key: string]: string }; // object of document categories with their categoryTreeNode
  categories: CategoryList;
  categoryMap: Dictionary<DocumentCategoryTreeDto>;
  categoryTrees: CategoryTrees;
  form: any;
  autoSelectDefault?: boolean;
  selectPlaceholder?: string;
}

interface State {
  categories: CategoryList;
  selectCategoryVisible: boolean;
}

type Props = IProps & InjectedIntlProps;

class DocumentCategoryForm extends Component<Props, State> {
  constructor(props: Props, context: State) {
    super(props, context);

    this.state = {
      categories: [...props.categories],
      selectCategoryVisible: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.categories !== this.props.categories && this.state.categories.length === 0) {
      // TODO: required categories dont update when directory is changed and categories are no longer required.
      //  It is because we dont want to replace already set categories (by user) when changing directory.
      //  Solution: merge categories by its keys.

      this.setState({
        categories: this.props.categories,
      });
    }
  }

  getRestCategoryList = () => {
    const { categoryList } = this.props;
    const { categories } = this.state;
    if (!categoryList) {
      return [];
    }
    if (!categories) return categoryList;
    return categoryList.filter((category) => categories.findIndex((c) => c.categoryId === category.id) === -1);
  };

  handleAddCategory = (categoryId: Guid, required: boolean) => {
    this.setState((state) => ({
      selectCategoryVisible: false,
      categories: [{ categoryId, required, defaultCategoryNodeId: undefined }, ...state.categories],
    }));
  };

  handleRemoveCategory = (categoryId: Guid) => {
    this.setState((state) => {
      return { categories: state.categories ? state.categories.filter((c) => c.categoryId !== categoryId) : [] };
    });
  };

  handleShowCategorySelect = () => {
    this.setState({ selectCategoryVisible: true });
  };

  handleHideCategorySelect = () => {
    this.setState({ selectCategoryVisible: false });
  };

  renderCategorySelect = (restCategoryList: DocumentCategoryTreeDto[]) => (
    <Select
      className={styles.treeSelectInput}
      showSearch
      autoFocus
      defaultOpen
      defaultActiveFirstOption={false}
      filterOption={(input, option) => smartFilter(this.props.categoryMap[option.key].name, input)}
      onSelect={(categoryId: Guid) => this.handleAddCategory(categoryId as Guid, false)}
      onBlur={this.handleHideCategorySelect}
    >
      {restCategoryList.map((category) => (
        <Select.Option key={category.id}>{category.name}</Select.Option>
      ))}
    </Select>
  );

  renderCategoryTreeSelect = (categoryId: Guid) => {
    const { categoryTrees, intl, selectPlaceholder } = this.props;
    const tree = categoryTrees[categoryId] || null;

    if (!tree || !tree.map) return <Spin />;
    const list = Object.values(tree.map);

    const categoryState = this.state.categories
      ? this.state.categories.find((c) => c.categoryId === categoryId)
      : undefined;
    const required = categoryState ? categoryState.required : false;

    return (
      <TreeSelect
        className={styles.treeSelectInput}
        showSearch
        allowClear
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder={
          selectPlaceholder
            ? selectPlaceholder
            : intl.formatMessage({
                id: 'DocumentCreateForm.form.items.category.treeSelect.placeholder',
              })
        }
        treeDefaultExpandAll
        autoFocus={!required}
        treeNodeFilterProp={null}
        treeData={this.getCategoryTreeNode(list)}
        filterTreeNode={(input, node) => smartFilter(node.props.title, input)}
      />
    );
  };

  getCategoryTreeNode = (categoryTreeList: DocumentCategoryNodeDto[], parentId: Guid = null): DefaultOptionType[] => {
    return categoryTreeList
      .filter((n) => n.parentId === parentId)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((n) => {
        return {
          value: n.id,
          title: <DisplayName>{n.name}</DisplayName>,
          children: this.getCategoryTreeNode(categoryTreeList, n.id),
        };
      });
  };

  render() {
    const { form, categoryMap, categoryTreeNodes, autoSelectDefault } = this.props;
    const { categories, selectCategoryVisible } = this.state;
    const { getFieldDecorator } = form;
    const restCategoryList = this.getRestCategoryList();

    return (
      <Form.Item label={<Fmt id="DocumentCreateForm.section.categories" />}>
        <div className={styles.addButton}>
          {selectCategoryVisible ? (
            this.renderCategorySelect(restCategoryList)
          ) : restCategoryList.length === 0 ? (
            <Button block disabled>
              <Fmt id="DocumentCreateForm.form.items.category.add.noMore" />
            </Button>
          ) : (
            <Button block icon={<AddIcon />} onClick={this.handleShowCategorySelect}>
              <Fmt id="DocumentCreateForm.form.items.category.add" />
            </Button>
          )}
        </div>
        {categories &&
          categories.map(({ categoryId, required, defaultCategoryNodeId }) => {
            if (!categoryMap[categoryId]) {
              return null;
            }
            const selectedNodeId = !categoryTreeNodes
              ? autoSelectDefault
                ? defaultCategoryNodeId
                : null
              : categoryTreeNodes[categoryId]
              ? categoryTreeNodes[categoryId]
              : defaultCategoryNodeId;

            return (
              <Form.Item
                className={styles.itemRow}
                key={categoryId}
                label={<DisplayName>{categoryMap[categoryId] ? categoryMap[categoryId].name : ''}</DisplayName>}
              >
                <Row gutter={16} align="middle">
                  <Col style={{ flex: 1 }}>
                    {getFieldDecorator(`categories[${categoryId}]`, {
                      initialValue: selectedNodeId,
                      rules: [requiredRule('DocumentCreateForm.form.items.category.rules.required')],
                    })(this.renderCategoryTreeSelect(categoryId))}
                  </Col>
                  <Col style={{ width: 30 }}>
                    <DeleteButton disabled={required} onClick={() => this.handleRemoveCategory(categoryId)} />
                  </Col>
                </Row>
              </Form.Item>
            );
          })}
      </Form.Item>
    );
  }
}

export default injectIntl(DocumentCategoryForm);
