import { Button, Divider, Input, Modal, Typography } from 'antd';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import uuid from 'uuid';
import {
  ReportViewConfiguration,
  getDefaultProjectInRealizationOverviewNamedReports,
} from '../ProjectsInRealizationOverviewReport.reportPresets';
import styles from './ProjectsInRealizationNote.module.less';
import { ProjectsRealizationSavedViewItem } from './ProjectsRealizationSavedViewItem';

type Props = {
  onClose: () => void;
  onSave: (configurations: ReportViewConfiguration[], switchToViewId?: Guid) => Promise<void>;
  open: boolean;
  existingViewsConfigurations: ReportViewConfiguration[];
  currentViewConfiguration: ReportViewConfiguration;
};

const ProjectsInRealizationSavedViewsModal: FunctionComponent<Props> = (props) => {
  const { onClose, onSave, open, currentViewConfiguration, existingViewsConfigurations } = props;
  const [viewsLoading, setViewsLoading] = useState<boolean>(false);
  const intl = useIntl();
  const [newViewNameText, setNewNameText] = useState<string>();

  const defaultReportIds = useMemo(
    () => new Set<Guid>(getDefaultProjectInRealizationOverviewNamedReports(intl).map((report) => report.id)),
    []
  );

  const handleCreateView = useCallback(async () => {
    setViewsLoading(true);
    const newViewId = uuid();
    await onSave(
      [
        ...existingViewsConfigurations,
        {
          id: newViewId,
          name: newViewNameText,
          columns: currentViewConfiguration.columns,
          filters: currentViewConfiguration.filters,
        },
      ],
      newViewId
    );
    setNewNameText('');
    setViewsLoading(false);
  }, [existingViewsConfigurations, onSave, newViewNameText, currentViewConfiguration]);

  const handleRename = useCallback(
    async (viewId: Guid, newName: string) => {
      setViewsLoading(true);
      await onSave([
        ...existingViewsConfigurations.map((configuration) =>
          configuration.id === viewId ? { ...configuration, name: newName } : configuration
        ),
      ]);
      setViewsLoading(false);
    },
    [existingViewsConfigurations, onSave]
  );

  const handleDelete = useCallback(
    async (viewId: Guid) => {
      setViewsLoading(true);
      await onSave([...existingViewsConfigurations.filter((configuration) => configuration.id !== viewId)]);
      setViewsLoading(false);
    },
    [existingViewsConfigurations, onSave]
  );

  const handleClose = useCallback(() => {
    setNewNameText('');
    onClose();
  }, [onClose]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectsInRealisationSavedViewsModal.title' })}
      open={open}
      width={600}
      onCancel={handleClose}
      onOk={handleClose}
      cancelButtonProps={HIDE_BUTTON_PROPS}
    >
      <GeneralSettingsContainer>
        {!!existingViewsConfigurations &&
          existingViewsConfigurations.map((view) => (
            <ProjectsRealizationSavedViewItem
              key={view.id}
              view={view}
              canDelete={!defaultReportIds.has(view.id)}
              onRename={handleRename}
              onDelete={handleDelete}
            />
          ))}
      </GeneralSettingsContainer>
      <Divider />
      <Typography.Title level={5}>
        <Fmt id="ProjectsInRealisationSavedViewsModal.newView.title" />
      </Typography.Title>
      <FlowLayout growFirst>
        <Input value={newViewNameText} onChange={(e) => setNewNameText(e.target.value)} className={styles.newNote} />
        <Button onClick={handleCreateView} type="primary" loading={viewsLoading} disabled={!newViewNameText}>
          <Fmt id="general.create" />
        </Button>
      </FlowLayout>
    </Modal>
  );
};

export default ProjectsInRealizationSavedViewsModal;
