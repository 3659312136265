import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  ProjectTemplateGroupDto,
  ProjectTemplateUserDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { InjectedIntlProps } from 'locale';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type ProjectTemplateGroupFormData = {
  name: string;
  description: string;
  templaceUserIds: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = FormComponentProps<ProjectTemplateGroupFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    templateUsers: ProjectTemplateUserDto[];
    defaults?: ProjectTemplateGroupDto;
  };

const ProjectTemplateGroupFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, validateUniqueName, templateUsers, defaults }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const trasnferableUsers = useMemo(
      () =>
        templateUsers.map(
          (user): ProjectUserProfileListDto => ({
            ...user.appUserOrganization.appUserProfile,
            id: user.id,
            status: ProjectUserProfileStatusEnum.active,
          })
        ),
      [templateUsers]
    );

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {form.getFieldDecorator<ProjectTemplateGroupFormData>('name', {
            initialValue: defaults?.name,
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.projectTemplateGroupDto.name.maxLength),
              duplicateNameRuleCallback(validateUniqueName),
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'GroupForm.items.name.placeholder' })} autoFocus />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {form.getFieldDecorator<ProjectTemplateGroupFormData>('description', {
            initialValue: defaults?.description,
            rules: [
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              maxLengthRule(
                'general.maxDescriptionLength',
                apiConstraints.projectTemplateGroupDto.description.maxLength
              ),
            ],
          })(<Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.userIds.label' })}>
          {form.getFieldDecorator<ProjectTemplateGroupFormData>('templaceUserIds', {
            initialValue: defaults?.templateUserIds || [],
          })(<UserTransferFormItem users={trasnferableUsers} />)}
        </Form.Item>
      </Form>
    );
  }
);

export const ProjectTemplateGroupForm = React.memo(Form.create<Props>()(ProjectTemplateGroupFormComponent));
