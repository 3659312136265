import { Core } from '@pdftron/webviewer';
import { DocumentAnnotationDto } from 'api/completeApiInterfaces';
import { usePdfTron } from 'components/PdfDiff/usePdfTron';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { useIntl, useSameCallback } from 'hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './PdfAnnotation.module.less';
import { AnnotationModeEnum } from './PdfAnnotationModal';

export type AnnotationData = {
  storeData: AnnotationStoreData;
  content: string;
  note: string;
  contentId: Guid;
  position: AnnotationPositionData;
};

export type AnnotationPositionData = {
  page: number;
  x: number;
  y: number;
};

export type AnnotationStoreData = {
  data: string;
  type: string;
};

type AnnotationChangeActionType = 'add' | 'modify' | 'delete';

type Props = {
  licenseKey: string;
  documentUrl: string;
  initialPdfAnnotations: DocumentAnnotationDto[];
  onDocumentChanged: (documentBlob: Blob) => void;
  onAnnotationsChanged?: (annotations: AnnotationData[]) => void;
  onNewAnnotationsChanged?: (annotations: AnnotationData[]) => void;
  annotationMode: AnnotationModeEnum;
  canEditOwnAnnotations?: boolean;
  canEditOthersAnnotations?: boolean;
  canReplyToAnnotations?: boolean;
  highlightedAnnotationId?: Guid;
};

export const PdfAnnotation: FunctionComponent<Props> = ({
  licenseKey,
  documentUrl,
  initialPdfAnnotations,
  onDocumentChanged,
  onAnnotationsChanged,
  onNewAnnotationsChanged,
  annotationMode = AnnotationModeEnum.documentAnnotation,
  canEditOwnAnnotations = true,
  canEditOthersAnnotations = false,
  canReplyToAnnotations = true,
  highlightedAnnotationId,
}) => {
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  const [newAnnotations, setNewAnnotations] = useState<Core.Annotations.Annotation[]>([]);

  const parseAnnotations = useCallback(
    async (
      annotationManager: Core.AnnotationManager,
      annotList: Core.Annotations.Annotation[],
      callback: (annotations: AnnotationData[]) => void
    ) => {
      callback &&
        callback(
          await Promise.all(
            annotList
              .filter((annotation) => !!annotation.Author && !annotation.isReply())
              .map(async (annotation) => {
                const relatedAnnotations = [
                  annotation,
                  ...annotList.filter(
                    (replyAnnotation) => replyAnnotation.isReply() && replyAnnotation.InReplyTo === annotation.Id
                  ),
                ];
                const xfdfString = await annotationManager.exportAnnotations({
                  annotList: relatedAnnotations,
                  widgets: false,
                  links: true,
                });

                return {
                  storeData: { data: xfdfString, type: annotation.Subject },
                  contentId: annotation.Id,
                  note: annotation.getContents(),
                  content: annotation.getCustomData('trn-annot-preview'),
                  position: { x: annotation.X, y: annotation.Y, page: annotation.PageNumber },
                };
              })
          )
        );
    },
    []
  );

  useEffect(() => {
    const instance = webViewerInstanceRef.current;
    if (instance && annotationMode !== AnnotationModeEnum.annotationViewer) {
      parseAnnotations(instance.Core.annotationManager, newAnnotations, onNewAnnotationsChanged);
    }
  }, [newAnnotations]);

  const handleAnnotationsChange = useSameCallback(
    (
      changedAnnotations: Core.Annotations.Annotation[],
      action: AnnotationChangeActionType,
      annotationManager: Core.AnnotationManager
    ) => {
      switch (action) {
        case 'add':
          setNewAnnotations([...newAnnotations, ...changedAnnotations]);
          break;
        case 'modify':
          setNewAnnotations([
            ...changedAnnotations.filter((changedAnnotation) =>
              newAnnotations.some((newAnnotation) => newAnnotation.Id === changedAnnotation.Id)
            ),
            ...newAnnotations.filter(
              (annotation) => !changedAnnotations.some((changedAnnotation) => changedAnnotation.Id === annotation.Id)
            ),
          ]);
          break;
        case 'delete':
          setNewAnnotations([
            ...newAnnotations.filter(
              (annotation) => !changedAnnotations.some((deletedAnnotation) => deletedAnnotation.Id === annotation.Id)
            ),
          ]);
          break;
      }

      const annotList = annotationManager.getAnnotationsList();
      if (annotList.length > 0 && annotationMode !== AnnotationModeEnum.annotationViewer) {
        parseAnnotations(annotationManager, annotList, onAnnotationsChanged);
      }
    }
  );

  const setDocument = useSameCallback(async () => {
    const instance = webViewerInstanceRef.current;
    // TODO: cancel previous loading
    if (instance) {
      setLoading(true);
      const { documentViewer, PDFNet, annotationManager } = instance.Core;

      const annotatedDocument = await PDFNet.PDFDoc.createFromURL(documentUrl);
      instance.UI.loadDocument(annotatedDocument);

      instance.UI.disableReplyForAnnotations((annotation) => {
        return !canReplyToAnnotations;
      });

      annotationManager.addEventListener(
        'annotationChanged',
        async (annotations: Core.Annotations.Annotation[], action: AnnotationChangeActionType, { imported }) => {
          if (imported) {
            return;
          }

          handleAnnotationsChange(annotations, action, annotationManager);

          annotationManager.exportAnnotations({ widgets: false, links: false }).then((xfdfString) => {
            const doc = documentViewer.getDocument();

            doc.getPDFDoc().then((pdfDoc) => {
              pdfDoc.mergeXFDFString(xfdfString);
              pdfDoc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_incremental).then((data) => {
                const arr = new Uint8Array(data);
                const blob = new Blob([arr], { type: 'application/pdf' });
                onDocumentChanged(blob);
              });
            });
          });
        }
      );

      documentViewer.addEventListener('annotationsLoaded', async () => {
        if (canEditOthersAnnotations) {
          annotationManager.promoteUserToAdmin();
        }

        if (initialPdfAnnotations?.length > 0) {
          const annotationsList = annotationManager
            .getAnnotationsList()
            .filter((existingAnnotation) => existingAnnotation.isInternal());
          annotationManager.deleteAnnotations(annotationsList, { force: true, imported: true });
        }

        if (initialPdfAnnotations?.length > 0) {
          for (const annotation of initialPdfAnnotations) {
            const annotationXdfdString = (annotation.annotationData as AnnotationStoreData).data;
            await annotationManager.importAnnotations(annotationXdfdString);
          }
        }

        const existingAnnotations = annotationManager.getAnnotationsList();
        existingAnnotations.forEach((annotation) => {
          annotation.Locked = !canEditOwnAnnotations && !canEditOthersAnnotations;
          annotation.LockedContents = !canReplyToAnnotations;
          annotation.ReadOnly = !canEditOwnAnnotations && !canEditOthersAnnotations;
        });

        const selectedAnnotation =
          !!highlightedAnnotationId &&
          existingAnnotations.find((annotation) => annotation.Id === highlightedAnnotationId);
        if (!!selectedAnnotation) {
          annotationManager.selectAnnotation(selectedAnnotation);
          annotationManager.jumpToAnnotation(selectedAnnotation, { isSmoothScroll: true });
        }
      });

      // wait until the document has been loaded
      documentViewer.addEventListener('documentLoaded', async () => {
        instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);
        instance.UI.openElements(['notesPanel']);
        instance.UI.disableElements(['downloadButton']);

        if (annotationMode === AnnotationModeEnum.commentProcedureAnnotation) {
          instance.UI.setToolbarGroup('toolbarGroup-Annotate');
          documentViewer.setToolMode(documentViewer.getTool('AnnotationCreateSticky'));
          instance.UI.disableElements([
            'toolbarGroup-View',
            'toolbarGroup-FillAndSign',
            'toolbarGroup-Edit',
            'toolbarGroup-EditText',
            'toolbarGroup-Insert',
            'toolbarGroup-Shapes',
            'toolbarGroup-Forms',
            'toolbarGroup-Measure',
            'highlightToolGroupButton',
            'underlineToolGroupButton',
            'strikeoutToolGroupButton',
            'squigglyToolGroupButton',
            'freeTextToolGroupButton',
            'shapeToolGroupButton',
            'freeHandToolGroupButton',
            'freeHandHighlightToolGroupButton',
          ]);
        } else if (annotationMode === AnnotationModeEnum.annotationViewer) {
          instance.UI.setToolbarGroup('toolbarGroup-View');
          instance.UI.disableElements([
            'toolbarGroup-FillAndSign',
            'toolbarGroup-Edit',
            'toolbarGroup-EditText',
            'toolbarGroup-Insert',
            'toolbarGroup-Shapes',
            'toolbarGroup-Forms',
            'toolbarGroup-Annotate',
            'toolbarGroup-Measure',
            // popup toolbar
            'textHighlightToolButton',
            'textUnderlineToolButton',
            'textSquigglyToolButton',
            'textStrikeoutToolButton',
            'linkButton',
            'stickyToolButton',
            'highlightToolButton',
            'freeHandToolButton',
            'freeHandHighlightToolButton',
            'freeTextToolButton',
          ]);
        } else {
          instance.UI.setToolbarGroup('toolbarGroup-Annotate');
          instance.UI.disableElements([
            'toolbarGroup-View',
            'toolbarGroup-FillAndSign',
            'toolbarGroup-Edit',
            'toolbarGroup-EditText',
            'toolbarGroup-Forms',
          ]);
        }

        const existingAnnotations = annotationManager.getAnnotationsList();
        existingAnnotations.forEach((annotation) => {
          annotation.Locked = !canEditOwnAnnotations && !canEditOthersAnnotations;
          annotation.LockedContents = !canReplyToAnnotations;
          annotation.ReadOnly = !canEditOwnAnnotations && !canEditOthersAnnotations;
          annotationManager.redrawAnnotation(annotation);
        });

        setLoading(false);
      });
    }
  });

  const [containerRef, webViewerInstanceRef] = usePdfTron(licenseKey, intl, setDocument);

  useEffect(() => {
    setDocument();
  }, [documentUrl]);

  return (
    <SpinBoxCenter spinning={loading}>
      <div ref={containerRef} className={styles.workspace} />
    </SpinBoxCenter>
  );
};
