import { ExtendedPermissionCategoryEnum, ExtendedPermissionEnum, WorkflowStateEnum } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralSettingsItemsGroup from 'components/GeneralSettingsItemsGroup/GeneralSettingsItemsGroup';
import RevisionNumberTag from 'components/RevisionNumberTag';
import SwitchCheck from 'components/SwitchCheck';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import styles from './PermissionsTab.module.less';

type Props = {
  userPermissionsSet: Set<ExtendedPermissionEnum>;
  savingItems?: Set<ExtendedPermissionEnum>;
  handlePermissionChange: (permission: ExtendedPermissionEnum, checked: boolean) => Promise<void>;
  disabled?: boolean;
};
const REPLACE_TEXT_TO_TAG_AND_LOGIC_REVERSE_MAP: Partial<Record<ExtendedPermissionEnum, React.ReactNode>> = {
  [ExtendedPermissionEnum.forbidPublishedState]: (
    <RevisionNumberTag state={WorkflowStateEnum.published} showTitle className={styles.revisionTag} />
  ),
  [ExtendedPermissionEnum.forbidRejectedState]: (
    <RevisionNumberTag state={WorkflowStateEnum.rejected} showTitle className={styles.revisionTag} />
  ),
  [ExtendedPermissionEnum.forbidSharedState]: (
    <RevisionNumberTag state={WorkflowStateEnum.shared} showTitle className={styles.revisionTag} />
  ),
  [ExtendedPermissionEnum.forbidWorkInProgressState]: (
    <RevisionNumberTag state={WorkflowStateEnum.workinprogress} showTitle className={styles.revisionTag} />
  ),
  [ExtendedPermissionEnum.forbidarchivedState]: (
    <RevisionNumberTag state={WorkflowStateEnum.archived} showTitle className={styles.revisionTag} />
  ),
};

export const PERMISSION_MAP: Record<ExtendedPermissionCategoryEnum, ExtendedPermissionEnum[]> = {
  projectManager: [
    ExtendedPermissionEnum.userEditing,
    ExtendedPermissionEnum.category,
    ExtendedPermissionEnum.labels,
    ExtendedPermissionEnum.externalApplications,
  ],
  document: [
    ExtendedPermissionEnum.forbidPublishedState,
    ExtendedPermissionEnum.forbidRejectedState,
    ExtendedPermissionEnum.forbidSharedState,
    ExtendedPermissionEnum.forbidWorkInProgressState,
    ExtendedPermissionEnum.forbidarchivedState,
  ],
  workflow: [ExtendedPermissionEnum.addWorkflow, ExtendedPermissionEnum.workflowPreview],
  aspeEsticon: [ExtendedPermissionEnum.stavba],
};

export const DEFAULT_ACTIVE_PERMISSIONS = [ExtendedPermissionEnum.stavba];

export const PERMISSIONS_BUILDING_MAP: ExtendedPermissionEnum[] = [
  ExtendedPermissionEnum.importZP,
  ExtendedPermissionEnum.importZBV,
  ExtendedPermissionEnum.importFaktura,
  ExtendedPermissionEnum.importHarmonogram,
  ExtendedPermissionEnum.prenosHub,
  ExtendedPermissionEnum.transferKD,
  ExtendedPermissionEnum.setApprovalDate,
  ExtendedPermissionEnum.returnForRework,
  ExtendedPermissionEnum.addPartner,
];

const PermissionsDrawerComponent: FunctionComponent<Props> = ({
  savingItems,
  userPermissionsSet,
  handlePermissionChange,
  disabled,
}) => {
  const isVisible = useCallback(
    (permissionCategory: ExtendedPermissionCategoryEnum, permission: ExtendedPermissionEnum) => {
      if (
        permissionCategory === ExtendedPermissionCategoryEnum.projectManager ||
        permissionCategory === ExtendedPermissionCategoryEnum.workflow ||
        permissionCategory === ExtendedPermissionCategoryEnum.document
      )
        return true;
      if (permission === ExtendedPermissionEnum.stavba) return true;
      return userPermissionsSet.has(ExtendedPermissionEnum.stavba);
    },
    [userPermissionsSet]
  );

  return (
    <GeneralSettingsContainer itemsLargeGap>
      {Object.keys(PERMISSION_MAP).map((permissionCategory: ExtendedPermissionCategoryEnum) => (
        <GeneralSettingsContainer
          title={<Fmt id={`UserDetailPanel.PermisionsTab.categoryType.${permissionCategory}`} />}
          key={permissionCategory}
        >
          {PERMISSION_MAP[permissionCategory].map((permission) => {
            const isRevertedLogicPermission = !!REPLACE_TEXT_TO_TAG_AND_LOGIC_REVERSE_MAP[permission];
            const permissionSetChecking = isRevertedLogicPermission
              ? !userPermissionsSet.has(permission)
              : userPermissionsSet.has(permission);
            return isVisible(permissionCategory, permission) ? (
              <GeneralSettingsItem
                className={styles.permissionItem}
                key={permission}
                title={
                  REPLACE_TEXT_TO_TAG_AND_LOGIC_REVERSE_MAP[permission] || (
                    <Fmt id={`UserDetailPanel.PermisionsTab.category.${permission}`} />
                  )
                }
                description={
                  permission === ExtendedPermissionEnum.stavba && (
                    <Fmt id={`UserDetailPanel.PermisionsTab.categoryDescription.${permission}`} />
                  )
                }
                input={
                  <SwitchCheck
                    className={styles.switch}
                    checked={permissionSetChecking}
                    loading={savingItems?.has(permission)}
                    onChange={(checked) =>
                      isRevertedLogicPermission
                        ? handlePermissionChange(permission, !checked)
                        : handlePermissionChange(permission, checked)
                    }
                    label={<Fmt id={permissionSetChecking ? 'general.on' : 'general.off'} />}
                    labelOnLeft
                    disabled={disabled}
                  />
                }
              >
                {permission === ExtendedPermissionEnum.stavba && userPermissionsSet.has(permission) && (
                  <GeneralSettingsItemsGroup>
                    {PERMISSIONS_BUILDING_MAP.map((item) => (
                      <GeneralSettingsItem
                        title={<Fmt id={`UserDetailPanel.PermisionsTab.category.${item}`} />}
                        key={item}
                        input={
                          <SwitchCheck
                            className={styles.switch}
                            checked={userPermissionsSet.has(item)}
                            loading={savingItems?.has(item)}
                            onChange={(checked) => handlePermissionChange(item, checked)}
                            label={<Fmt id={userPermissionsSet.has(item) ? 'general.on' : 'general.off'} />}
                            labelOnLeft
                            disabled={disabled}
                          />
                        }
                      />
                    ))}
                  </GeneralSettingsItemsGroup>
                )}
              </GeneralSettingsItem>
            ) : null;
          })}
        </GeneralSettingsContainer>
      ))}
    </GeneralSettingsContainer>
  );
};

export const PermissionsDrawer = React.memo(PermissionsDrawerComponent);
