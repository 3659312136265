import { FC, useCallback } from 'react';
import { DocumentDetailActionsType, useRegisterAction } from 'pages/DocumentDetailPage/context/DocumentActionsContext';
import { useDocumentDetailContext } from 'pages/DocumentDetailPage/context/DocumentDetailContext';
import { useIntl, useSameCallback } from 'hooks';
import { AxiosResponse, CancelToken } from 'axios';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import { api } from 'api';
import { message, Modal } from 'antd';
import { processApiError } from 'utils';
import { DocumentLinkRemoveStrategyEnum } from 'api/completeApiInterfaces';

export const useToggleIsModelActions = () => {
  const intl = useIntl();

  const handleClearIsModel = useSameCallback(
    async (id: Guid, cancelToken?: CancelToken): Promise<[ApiError, AxiosResponse<void>]> => {
      const [err, res] = await api.project.documents.removeModelDocuments(id, undefined, cancelToken);

      if (!err) {
        message.success(intl.formatMessage({ id: 'IsModelButton.message.success' }));
        return Promise.resolve([err, res]);
      }

      const error = processApiError(err);
      if (error !== null && error.referenceErrorCode === ServiceErrorEnum.ModelHasLinkError) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'IsModelButton.modal.confirm.title' }),
          content: intl.formatMessage({ id: 'IsModelButton.modal.confirm.content' }),
          onOk: async () => {
            const [err, res] = await api.project.documents.removeModelDocuments(
              id,
              DocumentLinkRemoveStrategyEnum.remove,
              cancelToken
            );

            if (!err) {
              message.success(intl.formatMessage({ id: 'IsModelButton.message.success' }));
            } else {
              message.error(intl.formatMessage({ id: 'IsModelButton.message.error' }));
            }
          },
          onCancel: () => {
            message.error(intl.formatMessage({ id: 'IsModelButton.message.error' }));
            Promise.resolve([err, res]);
          },
          width: 600,
        });
      }

      return Promise.resolve([err, res]);
    }
  );

  const handleSetIsModel = useSameCallback(async (id: Guid, cancelToken?: CancelToken) => {
    const response = await api.project.documents.addModelDocuments(id, cancelToken);
    if (!response[0]) {
      message.success(intl.formatMessage({ id: 'IsModelButton.message.addSuccess' }));
    } else {
      message.error(intl.formatMessage({ id: 'IsModelButton.message.addError' }));
    }
    return Promise.resolve(response);
  });

  return { handleClearIsModel, handleSetIsModel };
};

export const ToggleIsModelAction: FC = () => {
  const { document, setDocument } = useDocumentDetailContext();

  const { handleClearIsModel, handleSetIsModel } = useToggleIsModelActions();

  const handleToggleFavorite = useCallback(async () => {
    if (!document) {
      return;
    }
    if (document.isModel) {
      const [err, res] = await handleClearIsModel(document.id);
      if (!err) {
        setDocument({ ...document, isModel: false });
      }
    } else {
      await handleSetIsModel(document.id);
      setDocument({ ...document, isModel: true });
    }
  }, [document, handleSetIsModel, handleClearIsModel, setDocument]);

  useRegisterAction<DocumentDetailActionsType>('IS_MODEL', handleToggleFavorite);

  return null;
};
