import React, { FunctionComponent } from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import Styles from './LocaleMenu.module.less';
import { LanguageEnum } from 'api/completeApiInterfaces';

type LocaleMenuProps = {
  selectedLocale: LanguageEnum;
  onlyFlag?: boolean;
};

const FlagIconComponent = FlagIconFactory(React, { useCssModules: false });
const LOCALES: Record<LanguageEnum, { label: string; code: string }> = {
  [LanguageEnum.cs]: { label: 'Česky', code: 'cz' },
  [LanguageEnum.en]: { label: 'English', code: 'gb' },
  [LanguageEnum.de]: { label: 'Deutsch', code: 'de' },
  [LanguageEnum.sk]: { label: 'Slovensky', code: 'sk' },
};

export const FlagIcon: FunctionComponent<LocaleMenuProps> = ({ selectedLocale, onlyFlag }) => {
  const locale = LOCALES[selectedLocale];
  return (
    <>
      <FlagIconComponent code={locale.code} size={'lg'} className={Styles.flag} /> {!onlyFlag && locale.label}
    </>
  );
};
