import { to } from 'api/await-to';
import { CancelToken } from 'axios';
import * as Dtos from './completeApiInterfaces';
import { baseProjectApi } from './project/baseProjectApi';
import { baseMasterApi } from './master/baseMasterApi';

export const masterApi = {
  users: {
    /** Get list of users */
    get: (cancelToken?: CancelToken) =>
      to(
        baseMasterApi.get<Dtos.AppUserProfileDto[]>(`/users`, { cancelToken })
      ),
    me: {
      /** Get current user */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<Dtos.AppUserProfileDto>(`/users/me`, { cancelToken })
        ),
    },
    id: {
      reports: {
        /** Get user dashboard configuration */
        get: (userId: Guid, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.AppUserReportItem[]>(`/users/${userId}/reports`, { cancelToken })
          ),
        /** Set dashbord configuration */
        post: (userId: Guid, data: Dtos.AppUserReportItem[], cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.AppUserReportItem[]>(`/users/${userId}/reports`, data, { cancelToken })
          ),
      },
    },
    setlang: {
      /** Change language */
      post: (data: Dtos.AppUserLangSetDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<Dtos.AppUserProfileDto>(`/users/setlang`, data, { cancelToken })
        ),
    },
  },
  EsticonReports: {
    orq: {
      id: {
        firms: {
          id: {
            projects: {
              id: {
                projectnotes: {
                  get: (orgId: Guid, firmId: Guid, id: Guid, cancelToken?: CancelToken) =>
                    to(
                      baseMasterApi.get<Dtos.EstiConProjectNoteDto[]>(
                        `/EsticonReports/orq/${orgId}/firms/${firmId}/projects/${id}/projectnotes`,
                        { cancelToken }
                      )
                    ),
                  post: (
                    orgId: Guid,
                    firmId: Guid,
                    id: Guid,
                    data?: Dtos.EstiConProjectNoteDto[],
                    cancelToken?: CancelToken
                  ) =>
                    to(
                      baseMasterApi.post<Dtos.EstiConSaveResultDto>(
                        `/EsticonReports/orq/${orgId}/firms/${firmId}/projects/${id}/projectnotes`,
                        data,
                        { cancelToken }
                      )
                    ),
                },
              },
            },
            projectunits: {
              get: (orgId: Guid, firmId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.get<Dtos.EstiConUtvaryStructureDto[]>(
                    `/EsticonReports/orq/${orgId}/firms/${firmId}/projectunits`,
                    { cancelToken }
                  )
                ),
            },
            projectsinrealisationpoh: {
              post: (
                orgId: Guid,
                firmId: Guid,
                data?: Dtos.EstiConProjectsInRealisationReportPOHRequestDto,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseMasterApi.post<Dtos.EstiConProjectsInRealisationReportPOHResponseDto>(
                    `/EsticonReports/orq/${orgId}/firms/${firmId}/projectsinrealisationpoh`,
                    data,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
    },
  },
  projects: {
    /** Get all availaible projects. */
    get: (cancelToken?: CancelToken) =>
      to(
        baseMasterApi.get<Dtos.ProjectListResultDto>(`/projects`, { cancelToken })
      ),
    /** Creates a new project. */
    post: (data: Dtos.ProjectCreateDto, cancelToken?: CancelToken) =>
      to(
        baseMasterApi.post<Dtos.ProjectDto>(`/projects`, data, { cancelToken })
      ),
    msgcenter: {
      /** paged list of message center messages */
      post: (data: Dtos.MsgCenterListRequestDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<Dtos.MsgCenterResponseDto>(`/projects/msgcenter`, data, { cancelToken })
        ),
      id: {
        /** get message center message */
        get: (msgCenterMessageId: Guid, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.MsgCenterMessageDto>(`/projects/msgcenter/${msgCenterMessageId}`, { cancelToken })
          ),
        getsettings: {
          /** Get Message project Settings */
          get: (projectId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.MsgCenterProjectSettingsDto>(`/projects/msgcenter/${projectId}/getsettings`, {
                cancelToken,
              })
            ),
        },
      },
      getsettings: {
        /** Get Message default Settings */
        get: (cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.MsgCenterDefaultSettingsDto>(`/projects/msgcenter/getsettings`, { cancelToken })
          ),
      },
      getsummary: {
        /** Get Unresolved Messages statistic */
        get: (cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.MsgCenterSummaryDto>(`/projects/msgcenter/getsummary`, { cancelToken })
          ),
      },
      setsettings: {
        /** Set Message Settings */
        patch: (data: Dtos.MsgCenterProjectSettingsPatchDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<void>(`/projects/msgcenter/setsettings`, data, { cancelToken })
          ),
      },
      interval: {
        /** Start of paged list messages at the set interval.. The last page contains the specified message. */
        post: (data: Dtos.MsgCenterIntervalListRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.MsgCenterIntervalResponseDto>(`/projects/msgcenter/interval`, data, { cancelToken })
          ),
      },
      setstatus: {
        /** Set Message Status */
        post: (data: Dtos.MsgCenterSetStatusDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/msgcenter/setstatus`, data, { cancelToken })
          ),
      },
      setallasreaded: {
        /** Set all unreaded messages as readed */
        post: (cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/msgcenter/setallasreaded`, { cancelToken })
          ),
      },
      assignments: {
        /** paged list of message center assignments */
        post: (data: Dtos.MsgCenterAssignmentListRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.MsgCenterAssignmentResponseDto>(`/projects/msgcenter/assignments`, data, {
              cancelToken,
            })
          ),
      },
      assignmentsreport: {
        /** Get project report xlsx/docx/pdf */
        post: (data: Dtos.AssignmentListForExportRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/msgcenter/assignmentsreport`, data, { cancelToken })
          ),
      },
    },
    reports: {
      esticonprojectnotes: {
        /** Patch new esticon project notes */
        patch: (data: Dtos.EstiProjectNoteForHubPatchDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<Dtos.EstiProjectNoteForHubDto[]>(`/projects/reports/esticonprojectnotes`, data, {
              cancelToken,
            })
          ),
        /** Insert new esticon project notes */
        post: (data: Dtos.EstiProjectNoteForHubCreateDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.EstiProjectNoteForHubDto[]>(`/projects/reports/esticonprojectnotes`, data, {
              cancelToken,
            })
          ),
        id: {
          /** Get list of esticon project notes */
          get: (organizationId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.EstiProjectNoteForHubDto[]>(
                `/projects/reports/esticonprojectnotes/${organizationId}`,
                { cancelToken }
              )
            ),
        },
      },
      financialoverviewbyunit: {
        /** Reinvite AppUser */
        post: (data: Dtos.FinancialOverviewByUnitRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.FinancialOverviewByUnitDataDto>(`/projects/reports/financialoverviewbyunit`, data, {
              cancelToken,
            })
          ),
      },
      projectsinrealisationpoh: {
        report: {
          id: {
            id: {
              /** Get users month report xlsx or pdf */
              post: (
                organizationId: Guid,
                firmId: Guid,
                data: Dtos.ProjectsInRealisationReportExportRequestDto,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseMasterApi.post<void>(
                    `/projects/reports/projectsinrealisationpoh/report/${organizationId}/${firmId}`,
                    data,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
      generalsavedrecord: {
        get: {
          /** get GeneralSavedRecordDto */
          post: (data: Dtos.GeneralSavedRecordRequestDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<Dtos.GeneralSavedRecordDto>(`/projects/reports/generalsavedrecord/get`, data, {
                cancelToken,
              })
            ),
        },
        delete: {
          /** delete GeneralSavedRecordDto */
          post: (data: Dtos.GeneralSavedRecordDeleteRequestDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<void>(`/projects/reports/generalsavedrecord/delete`, data, { cancelToken })
            ),
        },
        set: {
          /** set GeneralSavedRecordDto */
          post: (data: Dtos.GeneralSavedRecordDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<void>(`/projects/reports/generalsavedrecord/set`, data, { cancelToken })
            ),
        },
      },
      firmlist: {
        /** Get Esticon firm list with their projects */
        post: (data: Dtos.EstiFirmsRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.EstiFirmsResponseDto>(`/projects/reports/firmlist`, data, { cancelToken })
          ),
      },
    },
    organization: {
      labels: {
        /** Get list of organization labels */
        post: (data: Guid[], cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.OrgLabelListDto[]>(`/projects/organization/labels`, data, { cancelToken })
          ),
        id: {
          /** Add new label */
          post: (orgId: Guid, data: Dtos.OrgLabelCreateDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<Dtos.OrgLabelDto>(`/projects/organization/labels/${orgId}`, data, { cancelToken })
            ),
          id: {
            /** Delete label */
            delete: (
              orgId: Guid,
              labelId: Guid,
              removeStrategy?: Dtos.LabelRemoveConflictStrategyEnum,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              !!removeStrategy && queryParams.append('removeStrategy', removeStrategy.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseMasterApi.delete<void>(`/projects/organization/labels/${orgId}/${labelId}${constructedQuery}`, {
                  cancelToken,
                })
              );
            },
            /** Get organization label by Id */
            get: (orgId: Guid, labelId: Guid, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.get<Dtos.OrgLabelDto>(`/projects/organization/labels/${orgId}/${labelId}`, {
                  cancelToken,
                })
              ),
            /** Patch label */
            patch: (orgId: Guid, labelId: Guid, data: Dtos.OrgLabelPatchDto, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.patch<Dtos.OrgLabelDto>(`/projects/organization/labels/${orgId}/${labelId}`, data, {
                  cancelToken,
                })
              ),
          },
        },
      },
    },
    favorite: {
      /** Get all favorite projects. */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<Dtos.FavoriteProjectListDto[]>(`/projects/favorite`, { cancelToken })
        ),
      /** Create favorite */
      post: (data: Dtos.FavoriteProjectsDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<Dtos.FavoriteProjectsResultsDto>(`/projects/favorite`, data, { cancelToken })
        ),
      deletelist: {
        /** Delete favorite */
        post: (data: Dtos.FavoriteProjectsDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.FavoriteProjectsResultsDto>(`/projects/favorite/deletelist`, data, { cancelToken })
          ),
      },
    },
    AppSettings: {
      /** Get application settings. */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<Dtos.AppSettingsDto>(`/projects/AppSettings`, { cancelToken })
        ),
    },
    licence: {
      /** Get Users Licence info */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<Dtos.ProjectUsersLicenceInfoDto>(`/projects/licence`, { cancelToken })
        ),
    },
    timezones: {
      param: {
        /** Get supported timezones */
        get: (lang: Dtos.LanguageEnum, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.ProjectTimezoneListDto>(`/projects/timezones/${lang}`, { cancelToken })
          ),
      },
    },
    storageareas: {
      /** Get supported storagearea */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<Dtos.ProjectStorageAreasDto>(`/projects/storageareas`, { cancelToken })
        ),
    },
    invitation: {
      id: {
        param: {
          param: {
            /** Get old invitation user information */
            get: (projectId: Guid, invitatitonCode: string, email: string, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.get<Dtos.ProjectUserInvitationDto>(
                  `/projects/invitation/${projectId}/${invitatitonCode}/${email}`,
                  { cancelToken }
                )
              ),
          },
        },
      },
    },
    createfromtemplate: {
      /** Creates a new project from template. */
      post: (data: Dtos.ProjectCreateFromTempDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<Dtos.ProjectDto>(`/projects/createfromtemplate`, data, { cancelToken })
        ),
    },
    aspehubconfiguration: {
      /** Get project data for esticon */
      post: (data: Dtos.EsticonLinkDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<Dtos.EsticonSettingsDto>(`/projects/aspehubconfiguration`, data, { cancelToken })
        ),
    },
    projectorder: {
      /** Set project order in list */
      post: (data: Dtos.ProjectsOrderDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<void>(`/projects/projectorder`, data, { cancelToken })
        ),
    },
    updateerpdata: {
      /** Load data from ERP system */
      post: (data: Dtos.UpdateErpDataDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<void>(`/projects/updateerpdata`, data, { cancelToken })
        ),
    },
    basereport: {
      /** Get project report xlsx/docx/pdf */
      post: (data: Dtos.ProjectListForExportRequestDto, cancelToken?: CancelToken) =>
        to(
          baseMasterApi.post<void>(`/projects/basereport`, data, { cancelToken })
        ),
    },
    admin: {
      orgusers: {
        id: {
          /** Delet organization user */
          delete: (orgUserId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.delete<void>(`/projects/admin/orgusers/${orgUserId}`, { cancelToken })
            ),
        },
      },
      deleteorganization: {
        id: {
          /** Delete Organization without project */
          delete: (organizationId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.delete<void>(`/projects/admin/deleteorganization/${organizationId}`, { cancelToken })
            ),
        },
      },
      projectusers: {
        id: {
          /** Get list of users in project */
          get: (projectId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.ProjectUserProfileListDto[]>(`/projects/admin/projectusers/${projectId}`, {
                cancelToken,
              })
            ),
          id: {
            /** Delete invited user */
            delete: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.delete<void>(`/projects/admin/projectusers/${projectId}/${userId}`, { cancelToken })
              ),
            suspend: {
              /** Suspend user */
              post: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.post<void>(`/projects/admin/projectusers/${projectId}/${userId}/suspend`, {
                    cancelToken,
                  })
                ),
            },
            activate: {
              /** Activate suspended or expired user profile */
              post: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.post<Dtos.ProjectUserActivationResponseDto>(
                    `/projects/admin/projectusers/${projectId}/${userId}/activate`,
                    { cancelToken }
                  )
                ),
            },
            setadmin: {
              /** Set or unset project user as admine */
              post: (projectId: Guid, userId: Guid, data: Dtos.ProjectUserSetAdminDto, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.post<void>(`/projects/admin/projectusers/${projectId}/${userId}/setadmin`, data, {
                    cancelToken,
                  })
                ),
            },
          },
        },
      },
      calendar: {
        /** Patch Calendar */
        patch: (data: Dtos.CalendarSetDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<void>(`/projects/admin/calendar`, data, { cancelToken })
          ),
        id: {
          project: {
            id: {
              /** Delete project calendar */
              delete: (organizationId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.delete<void>(`/projects/admin/calendar/${organizationId}/project/${projectId}`, {
                    cancelToken,
                  })
                ),
            },
          },
        },
        get: {
          /** Get Calendar */
          post: (data: Dtos.CalendarRequestDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<Dtos.CalendarResponseDto>(`/projects/admin/calendar/get`, data, { cancelToken })
            ),
        },
      },
      getappusers: {
        /** Get AppUser */
        get: (cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.AppUserDto[]>(`/projects/admin/getappusers`, { cancelToken })
          ),
      },
      getorgusers: {
        id: {
          /** Get Organization Users */
          get: (organizationId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.OrgUserDto[]>(`/projects/admin/getorgusers/${organizationId}`, { cancelToken })
            ),
          project: {
            id: {
              /** Get Organization Users that does not exist in project */
              get: (organizationId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.get<Dtos.OrgUserDto[]>(
                    `/projects/admin/getorgusers/${organizationId}/project/${projectId}`,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
      orgSettings: {
        id: {
          getpohreportsettings: {
            /** Get Organization settings for POH report */
            get: (organizationId: Guid, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.get<Dtos.OrganizationPOHReportSettingDto>(
                  `/projects/admin/orgSettings/${organizationId}/getpohreportsettings`,
                  { cancelToken }
                )
              ),
          },
          setpohreportsettings: {
            /** Set Organization settings for POH report */
            post: (organizationId: Guid, data: Dtos.OrganizationPOHReportSettingDto, cancelToken?: CancelToken) =>
              to(
                baseMasterApi.post<void>(`/projects/admin/orgSettings/${organizationId}/setpohreportsettings`, data, {
                  cancelToken,
                })
              ),
          },
        },
      },
      getorgadminreport: {
        /** Get Organization report */
        get: (cancelToken?: CancelToken) =>
          to(
            baseMasterApi.get<Dtos.OrganizationsAdminReportDto>(`/projects/admin/getorgadminreport`, { cancelToken })
          ),
      },
      getorguserprojectsreport: {
        id: {
          /** Get Organization User in projects report */
          get: (orgUserId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.OrganizationUserProjectsReportDto>(
                `/projects/admin/getorguserprojectsreport/${orgUserId}`,
                { cancelToken }
              )
            ),
        },
      },
      id: {
        auditlogdetail: {
          /** Get list of AuditLogs, that is related to auditLog with set Id */
          get: (auditLogId: number, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.AuditLogResponseDto>(`/projects/admin/${auditLogId}/auditlogdetail`, {
                cancelToken,
              })
            ),
        },
        extendedpermission: {
          /** Get organization extended permission by Id */
          get: (orgUserId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.OrgExtendedPermissionsDto>(`/projects/admin/${orgUserId}/extendedpermission`, {
                cancelToken,
              })
            ),
          /** Change organization extended permission */
          patch: (orgUserId: Guid, data: Dtos.OrgExtendedPermissionsPatchDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.patch<void>(`/projects/admin/${orgUserId}/extendedpermission`, data, { cancelToken })
            ),
        },
      },
      forge: {
        orgusage: {
          /** Forge usage by organization */
          get: (cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.ForgeUploadOrganizationReportDto>(`/projects/admin/forge/orgusage`, {
                cancelToken,
              })
            ),
        },
        report: {
          /** Get users month report xlsx */
          post: (data: Dtos.ForgeMonthlyReportRequestDto, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<void>(`/projects/admin/forge/report`, data, { cancelToken })
            ),
        },
      },
      patchorganization: {
        /** Patch Organization */
        patch: (data: Dtos.OrganizationPatchDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<void>(`/projects/admin/patchorganization`, data, { cancelToken })
          ),
      },
      setappusers: {
        /** Set AppUsers, if the user does not exist, it create him */
        post: (data: Dtos.AppUsersRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/admin/setappusers`, data, { cancelToken })
          ),
      },
      reinviteappuser: {
        /** Reinvite AppUser */
        post: (data: Dtos.ReinviteRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/admin/reinviteappuser`, data, { cancelToken })
          ),
      },
      setorgusers: {
        /** Set Organization users, if the user does not exist, it create him */
        post: (data: Dtos.OrgUsersRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/admin/setorgusers`, data, { cancelToken })
          ),
      },
      addprojectusers: {
        /** Set Project users */
        post: (data: Dtos.ProjectUsersRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/admin/addprojectusers`, data, { cancelToken })
          ),
      },
      addorganization: {
        /** Add organization */
        post: (data: Dtos.OrganizationCreateDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.OrganizationDto>(`/projects/admin/addorganization`, data, { cancelToken })
          ),
      },
      suspendallusers: {
        id: {
          /** Suspend Organization User in all projects */
          post: (orgUserId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.post<Dtos.SuspendAllUsersResponseDto>(`/projects/admin/suspendallusers/${orgUserId}`, {
                cancelToken,
              })
            ),
        },
      },
      auditloglist: {
        /** list auditLog events for set entity */
        post: (data: Dtos.AuditLogEntityRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.AuditLogListResponseDto>(`/projects/admin/auditloglist`, data, { cancelToken })
          ),
      },
      orgmove: {
        id: {
          project: {
            id: {
              /** Move project between organizations */
              post: (projectId: Guid, organizationId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseMasterApi.post<Dtos.OrgUserDto[]>(
                    `/projects/admin/orgmove/${organizationId}/project/${projectId}`,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
    },
    tempates: {
      extendedpermissiontemplate: {
        /** Patch named extended permission template. Only the permission entries present in the settings list are changed. */
        patch: (data: Dtos.ExtendedPermissionTemplatePatchDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<Dtos.ExtendedPermissionTemplateDto>(
              `/projects/tempates/extendedpermissiontemplate`,
              data,
              { cancelToken }
            )
          ),
        /** Create named extended permission template */
        post: (data: Dtos.ExtendedPermissionTemplateCreateDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.ExtendedPermissionTemplateDto>(
              `/projects/tempates/extendedpermissiontemplate`,
              data,
              { cancelToken }
            )
          ),
        id: {
          /** Delete Extended Permission Template */
          delete: (extendedPermissionTemplateId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.delete<void>(
                `/projects/tempates/extendedpermissiontemplate/${extendedPermissionTemplateId}`,
                { cancelToken }
              )
            ),
          /** Get named extended permission template list */
          get: (organizationId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.ExtendedPermissionTemplateDto[]>(
                `/projects/tempates/extendedpermissiontemplate/${organizationId}`,
                { cancelToken }
              )
            ),
        },
      },
      projecttemplate: {
        /** Patch project template */
        patch: (data: Dtos.ProjectTemplatePatchDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.patch<Dtos.ProjectTemplateDto>(`/projects/tempates/projecttemplate`, data, { cancelToken })
          ),
        /** Create project template */
        post: (data: Dtos.ProjectTemplateCreateDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.ProjectTemplateDto>(`/projects/tempates/projecttemplate`, data, { cancelToken })
          ),
        id: {
          /** Delete Project Template */
          delete: (projectTemplateId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.delete<void>(`/projects/tempates/projecttemplate/${projectTemplateId}`, { cancelToken })
            ),
          /** Get project template */
          get: (projectTemplateId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.ProjectTemplateDto>(`/projects/tempates/projecttemplate/${projectTemplateId}`, {
                cancelToken,
              })
            ),
        },
      },
      projecttemplatelist: {
        id: {
          /** Get projects template list */
          get: (organizationId: Guid, cancelToken?: CancelToken) =>
            to(
              baseMasterApi.get<Dtos.ProjectTemplateListDto[]>(
                `/projects/tempates/projecttemplatelist/${organizationId}`,
                { cancelToken }
              )
            ),
        },
      },
      templatefromproject: {
        /** Create project template structure (Not save to db) from existing project */
        post: (data: Dtos.TemplateFromProjectRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.ProjectTemplateDto>(`/projects/tempates/templatefromproject`, data, { cancelToken })
          ),
      },
    },
    upload2hub: {
      createestidoc: {
        /** Add new zjistovaky faktury a zbv as documents or revisions */
        post: (data: Dtos.EstiDocumentsCreateDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<void>(`/projects/upload2hub/createestidoc`, data, { cancelToken })
          ),
      },
      uploadrequest: {
        /** Add new esticon document as document or revision */
        post: (data: Dtos.EstiDocumentUploadRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.EstiDocumentUploadResponseDto>(`/projects/upload2hub/uploadrequest`, data, {
              cancelToken,
            })
          ),
      },
      returnforrework: {
        /** Discard esticon document */
        post: (data: Dtos.EstiDocumentReturnForRevorkRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.EstiDocumentReturnForRevorkResponseDto>(
              `/projects/upload2hub/returnforrework`,
              data,
              { cancelToken }
            )
          ),
      },
      erpdata: {
        /** Send karat data to esticon */
        post: (data: Dtos.EstiCategoriesAccountingFactsRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.EstiCategoriesAccountingFactsResponseDto>(`/projects/upload2hub/erpdata`, data, {
              cancelToken,
            })
          ),
      },
    },
    wopi: {
      authenticate: {
        /** Authenticate WOPI request */
        post: (data: Dtos.AuthenticateWopiRequestDto, cancelToken?: CancelToken) =>
          to(
            baseMasterApi.post<Dtos.AuthenticateWopiResponseDto>(`/projects/wopi/authenticate`, data, { cancelToken })
          ),
      },
    },
  },
  pdftron: {
    licensekey: {
      /** Get encrypted license key for PdfTron */
      get: (cancelToken?: CancelToken) =>
        to(
          baseMasterApi.get<string>(`/pdftron/licensekey`, { cancelToken })
        ),
    },
  },
};

export const projectApi = {
  /** Gets the specified project. */
  get: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<Dtos.ProjectHubDto>(``, { cancelToken })
    ),
  /** Patch Project */
  patch: (data: Dtos.ProjectPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<void>(``, data, { cancelToken })
    ),
  assignments: {
    id: {
      /** Get assignment detail */
      get: (assignmentId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.AssignmentDto>(`/assignments/${assignmentId}`, { cancelToken })
        ),
      discussion: {
        id: {
          /** Delete note from assignment discussion */
          delete: (assignmentId: Guid, assignmentNoteId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/assignments/${assignmentId}/discussion/${assignmentNoteId}`, {
                cancelToken,
              })
            ),
          edit: {
            /** Edit assignment discussion note */
            patch: (
              assignmentId: Guid,
              assignmentNoteId: Guid,
              data: Dtos.AssignmentNoteEditDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.patch<Dtos.AssignmentDto>(
                  `/assignments/${assignmentId}/discussion/${assignmentNoteId}/edit`,
                  data,
                  { cancelToken }
                )
              ),
          },
          restore: {
            /** Restore note from assignment discussion */
            post: (assignmentId: Guid, assignmentNoteId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.AssignmentDto>(
                  `/assignments/${assignmentId}/discussion/${assignmentNoteId}/restore`,
                  { cancelToken }
                )
              ),
          },
        },
        addnote: {
          /** Add note to discussion on assignment */
          post: (assignmentId: Guid, data: Dtos.AssignmentNoteCreateDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/discussion/addnote`, data, {
                cancelToken,
              })
            ),
        },
      },
      attachment: {
        id: {
          /** Discard attachment from assignment */
          delete: (assignmentId: Guid, assignmentAttachmentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<Dtos.AssignmentDto>(
                `/assignments/${assignmentId}/attachment/${assignmentAttachmentId}`,
                { cancelToken }
              )
            ),
          download: {
            /** Get download URL of assignment attachment's blob */
            get: (
              assignmentId: Guid,
              attachmentId: Guid,
              redirect?: boolean,
              expires?: number,
              inline?: boolean,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              !!redirect && queryParams.append('redirect', redirect.toString());
              !!expires && queryParams.append('expires', expires.toString());
              !!inline && queryParams.append('inline', inline.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseProjectApi.get<Dtos.DownloadUrl>(
                  `/assignments/${assignmentId}/attachment/${attachmentId}/download${constructedQuery}`,
                  { cancelToken }
                )
              );
            },
          },
          downloadDerivate: {
            /** Get download URL of assignment attachment's derivate blob */
            get: (
              assignmentId: Guid,
              attachmentId: Guid,
              type: Dtos.BlobDerivateTypeEnum,
              redirect?: boolean,
              expires?: number,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              queryParams.append('type', type.toString());
              !!redirect && queryParams.append('redirect', redirect.toString());
              !!expires && queryParams.append('expires', expires.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseProjectApi.get<Dtos.DownloadUrl>(
                  `/assignments/${assignmentId}/attachment/${attachmentId}/downloadDerivate${constructedQuery}`,
                  { cancelToken }
                )
              );
            },
          },
          restore: {
            /** Restore attachment from assignment */
            post: (assignmentId: Guid, assignmentAttachmentId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.AssignmentDto>(
                  `/assignments/${assignmentId}/attachment/${assignmentAttachmentId}/restore`,
                  { cancelToken }
                )
              ),
          },
        },
      },
      attachments: {
        /** Get assignment attachments */
        get: (assignmentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.AssignmentAttachmentDto[]>(`/assignments/${assignmentId}/attachments`, {
              cancelToken,
            })
          ),
        discarded: {
          /** Get discarded assignment attachments */
          get: (assignmentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.AssignmentAttachmentDto[]>(`/assignments/${assignmentId}/attachments/discarded`, {
                cancelToken,
              })
            ),
        },
      },
      updateassignment: {
        /** Update assignment */
        post: (assignmentId: Guid, data: Dtos.AssignmentPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/updateassignment`, data, {
              cancelToken,
            })
          ),
      },
      changeState: {
        /** Set assignment state */
        post: (assignmentId: Guid, data: Dtos.AssignmentSetStateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/changeState`, data, { cancelToken })
          ),
      },
      updatedocuments: {
        /** Set documents */
        post: (assignmentId: Guid, data: Dtos.AssignmentDocumentsPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/updatedocuments`, data, {
              cancelToken,
            })
          ),
      },
      updatesolvers: {
        /** Set solvers */
        post: (assignmentId: Guid, data: Dtos.AssignmentSolversPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/updatesolvers`, data, { cancelToken })
          ),
      },
      updateassociates: {
        /** Set associates */
        post: (assignmentId: Guid, data: Dtos.AssignmentAssociatesPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/updateassociates`, data, {
              cancelToken,
            })
          ),
      },
      addattachments: {
        /** Add multiple new attachments with optional link to note */
        post: (assignmentId: Guid, data: Dtos.AssignmentAttachmentCreateMultipleDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/addattachments`, data, {
              cancelToken,
            })
          ),
      },
      downloadattachments: {
        /** Download multiple attachments for assigment */
        post: (assignmentId: Guid, data: Dtos.AssignmentAttachmentsDownloadDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DownloadUrl>(`/assignments/${assignmentId}/downloadattachments`, data, {
              cancelToken,
            })
          ),
      },
      assignattachment: {
        /** Assign document revision as attachment */
        post: (assignmentId: Guid, data: Dtos.AssignmentAttachmentAssignListDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/${assignmentId}/assignattachment`, data, {
              cancelToken,
            })
          ),
      },
    },
    list: {
      id: {
        /** Get all assignment list with annotaitons linked to document revision */
        get: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.AssignmenListDto>(`/assignments/list/${documentId}`, { cancelToken })
          ),
      },
    },
    createassignment: {
      /** Create new user assignment */
      post: (data: Dtos.AssignmentCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.AssignmentDto>(`/assignments/createassignment`, data, { cancelToken })
        ),
    },
    savewithannotations: {
      /** Save assignments with linked annotations */
      post: (data: Dtos.AssignmentAnnotationListSaveDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.AssignmenListDto>(`/assignments/savewithannotations`, data, { cancelToken })
        ),
    },
  },
  auditlog: {
    /** list auditLog events for set entity */
    post: (data: Dtos.AuditLogEntityRequestDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.AuditLogListResponseDto>(`/auditlog`, data, { cancelToken })
      ),
    id: {
      auditlogdetail: {
        /** Get list of AuditLogs, that is related to auditLog with set Id */
        get: (auditLogId: number, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.AuditLogResponseDto>(`/auditlog/${auditLogId}/auditlogdetail`, { cancelToken })
          ),
      },
    },
    auditlogstavba: {
      /** list stavba auditLog events */
      post: (data: Dtos.AuditLogStavbaRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.AuditLogListResponseDto>(`/auditlog/auditlogstavba`, data, { cancelToken })
        ),
      lasttransfertokd: {
        /** last success transfer to KD */
        get: (cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.AuditLogDto>(`/auditlog/auditlogstavba/lasttransfertokd`, { cancelToken })
          ),
      },
    },
    auditlogadmin: {
      /** list admin auditLog events */
      post: (data: Dtos.AuditLogAdminRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.AuditLogListResponseDto>(`/auditlog/auditlogadmin`, data, { cancelToken })
        ),
    },
    useractivity: {
      session: {
        /** get user activity sessions */
        post: (data: Dtos.AuditLogSessionListRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AuditLogSessionResponseDto>(`/auditlog/useractivity/session`, data, {
              cancelToken,
            })
          ),
      },
      detail: {
        /** get user activity log */
        post: (data: Dtos.AuditLogUserActivityRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AuditLogUserActivityResponseDto>(`/auditlog/useractivity/detail`, data, {
              cancelToken,
            })
          ),
      },
    },
    dms: {
      metadata: {
        /** Get audit log dms export xlsx */
        post: (data: Dtos.AuditLogDMSRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<void>(`/auditlog/dms/metadata`, data, { cancelToken })
          ),
      },
    },
    users: {
      report: {
        /** Get users month report xlsx */
        post: (data: Dtos.AuditLogUsersMonthlyRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<void>(`/auditlog/users/report`, data, { cancelToken })
          ),
      },
    },
  },
  blobs: {
    /** Get blobs dictionary */
    post: (data: Dtos.BlobsRequestDto, cancelToken?: CancelToken) =>
        to(
        baseProjectApi.post<Dtos.BlobsResponseDto>(`/blobs`, data, { cancelToken })
        ),
    param: {
      derivates: {
        process: {
          /** Process OnDemand blob derivate */
          post: (token: string, type: Dtos.BlobDerivateTypeEnum, cancelToken?: CancelToken) => {
            const queryParams = new URLSearchParams();
            queryParams.append('type', type.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.post<Dtos.BlobDto>(`/blobs/${token}/derivates/process${constructedQuery}`, { cancelToken })
            );
          },
        },
      },
    },
  },
  comment: {
    /** Get list of comment procedures */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.CommentProcedureListDto[]>(`/comment`, { cancelToken })
      ),
    /** Add new Comment Procedure */
    post: (data: Dtos.CommentProcedureCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.CommentProcedureDto>(`/comment`, data, { cancelToken })
      ),
    id: {
      /** Get Comment Procedure */
      get: (commentProcedureId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}`, { cancelToken })
        ),
      /** Patch Comment Procedure */
      patch: (commentProcedureId: Guid, data: Dtos.CommentProcedurePatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}`, data, { cancelToken })
        ),
      team: {
        /** Add team to Comment Procedure */
        post: (commentProcedureId: Guid, data: Dtos.CommentProcedureTeamCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.CommentProcedureTeamDto>(`/comment/${commentProcedureId}/team`, data, {
              cancelToken,
            })
          ),
        id: {
          /** Delete team from comment procedure */
          delete: (commentProcedureId: Guid, commentProcedureTeamId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/comment/${commentProcedureId}/team/${commentProcedureTeamId}`, {
                cancelToken,
              })
            ),
          /** Patch Comment Procedure team */
          patch: (
            commentProcedureId: Guid,
            commentProcedureTeamId: Guid,
            data: Dtos.CommentProcedureTeamPatchDto,
            cancelToken?: CancelToken
          ) =>
            to(
              baseProjectApi.patch<Dtos.CommentProcedureTeamDto>(
                `/comment/${commentProcedureId}/team/${commentProcedureTeamId}`,
                data,
                { cancelToken }
              )
            ),
          user: {
            id: {
              /** Delete user from comment procedure */
              delete: (
                commentProcedureId: Guid,
                commentProcedureTeamId: Guid,
                commentProcedureUserId: Guid,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseProjectApi.delete<void>(
                    `/comment/${commentProcedureId}/team/${commentProcedureTeamId}/user/${commentProcedureUserId}`,
                    { cancelToken }
                  )
                ),
              /** Patch Comment Procedure user */
              patch: (
                commentProcedureId: Guid,
                commentProcedureTeamId: Guid,
                commentProcedureUserId: Guid,
                data: Dtos.CommentProcedureUserPatchDto,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseProjectApi.patch<Dtos.CommentProcedureUserDto>(
                    `/comment/${commentProcedureId}/team/${commentProcedureTeamId}/user/${commentProcedureUserId}`,
                    data,
                    { cancelToken }
                  )
                ),
            },
          },
          users: {
            /** Add user to Comment Procedure */
            post: (
              commentProcedureId: Guid,
              commentProcedureTeamId: Guid,
              data: Dtos.CommentProcedureUserCreateDto[],
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureUserDto[]>(
                  `/comment/${commentProcedureId}/team/${commentProcedureTeamId}/users`,
                  data,
                  { cancelToken }
                )
              ),
          },
        },
      },
      category: {
        /** Add category to Comment Procedure */
        post: (commentProcedureId: Guid, data: Dtos.CommentProcedureCategoryCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.CommentProcedureCategoryDto>(`/comment/${commentProcedureId}/category`, data, {
              cancelToken,
            })
          ),
        id: {
          /** Delete Category from Comment Procedure */
          delete: (commentProcedureId: Guid, commentProcedureCategoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/comment/${commentProcedureId}/category/${commentProcedureCategoryId}`, {
                cancelToken,
              })
            ),
          /** Patch Comment Procedure category */
          patch: (
            commentProcedureId: Guid,
            commentProcedureCategoryId: Guid,
            data: Dtos.CommentProcedureCategoryPatchDto,
            cancelToken?: CancelToken
          ) =>
            to(
              baseProjectApi.patch<Dtos.CommentProcedureCategoryDto>(
                `/comment/${commentProcedureId}/category/${commentProcedureCategoryId}`,
                data,
                { cancelToken }
              )
            ),
        },
        import: {
          id: {
            /** Import Category to Comment Procedure from another Comment Procedure */
            post: (commentProcedureId: Guid, commentProcedureSourceId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCategoryDto[]>(
                  `/comment/${commentProcedureId}/category/import/${commentProcedureSourceId}`,
                  { cancelToken }
                )
              ),
          },
        },
      },
      comments: {
        /** Bulk comment procedure comment update */
        patch: (commentProcedureId: Guid, data: Dtos.CommentProcedureCommentStatePatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/comments`, data, {
              cancelToken,
            })
          ),
        /** Add comment to Comment Procedure */
        post: (commentProcedureId: Guid, data: Dtos.CommentProcedureCommentCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.CommentProcedureCommentDto>(`/comment/${commentProcedureId}/comments`, data, {
              cancelToken,
            })
          ),
        id: {
          /** Patch Comment Procedure Comment */
          patch: (
            commentProcedureId: Guid,
            commentProcedureCommentId: Guid,
            data: Dtos.CommentProcedureCommentPatchDto,
            cancelToken?: CancelToken
          ) =>
            to(
              baseProjectApi.patch<Dtos.CommentProcedureCommentDto>(
                `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}`,
                data,
                { cancelToken }
              )
            ),
          document: {
            /** Add document to comment */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentDocumentCreateDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/document`,
                  data,
                  { cancelToken }
                )
              ),
            id: {
              /** Delete document from comment */
              delete: (
                commentProcedureId: Guid,
                commentProcedureCommentId: Guid,
                commentProcedureCommentDocumentId: Guid,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseProjectApi.delete<void>(
                    `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/document/${commentProcedureCommentDocumentId}`,
                    { cancelToken }
                  )
                ),
            },
          },
          note: {
            id: {
              /** Delete note from comment procedure comment */
              delete: (
                commentProcedureId: Guid,
                commentProcedureCommentId: Guid,
                commentProcedureCommentNoteId: Guid,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseProjectApi.delete<Dtos.CommentProcedureCommentDto>(
                    `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}`,
                    { cancelToken }
                  )
                ),
              edit: {
                /** Edit Comment Procedure comment note */
                patch: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  commentProcedureCommentNoteId: Guid,
                  data: Dtos.CommentProcedureCommentNoteEditDto,
                  cancelToken?: CancelToken
                ) =>
                  to(
                    baseProjectApi.patch<Dtos.CommentProcedureCommentDto>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}/edit`,
                      data,
                      { cancelToken }
                    )
                  ),
              },
              restore: {
                /** Restore note from comment procedure comment */
                post: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  commentProcedureCommentNoteId: Guid,
                  cancelToken?: CancelToken
                ) =>
                  to(
                    baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}/restore`,
                      { cancelToken }
                    )
                  ),
              },
            },
          },
          attachment: {
            id: {
              /** Discard attachment from comment procedure comment */
              delete: (
                commentProcedureId: Guid,
                commentProcedureCommentId: Guid,
                commentProcedureCommentAttachmentId: Guid,
                cancelToken?: CancelToken
              ) =>
                to(
                  baseProjectApi.delete<Dtos.CommentProcedureCommentDto>(
                    `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${commentProcedureCommentAttachmentId}`,
                    { cancelToken }
                  )
                ),
              download: {
                /** Get download URL of comment procedure attachment's blob */
                get: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  attachmentId: Guid,
                  redirect?: boolean,
                  expires?: number,
                  inline?: boolean,
                  cancelToken?: CancelToken
                ) => {
                  const queryParams = new URLSearchParams();
                  !!redirect && queryParams.append('redirect', redirect.toString());
                  !!expires && queryParams.append('expires', expires.toString());
                  !!inline && queryParams.append('inline', inline.toString());
                  const queryResult = queryParams.toString();
                  const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                  return to(
                    baseProjectApi.get<Dtos.DownloadUrl>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}/download${constructedQuery}`,
                      { cancelToken }
                    )
                  );
                },
              },
              downloadDerivate: {
                /** Get download URL of comment procedure attachment's derivate blob */
                get: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  attachmentId: Guid,
                  type: Dtos.BlobDerivateTypeEnum,
                  redirect?: boolean,
                  expires?: number,
                  cancelToken?: CancelToken
                ) => {
                  const queryParams = new URLSearchParams();
                  queryParams.append('type', type.toString());
                  !!redirect && queryParams.append('redirect', redirect.toString());
                  !!expires && queryParams.append('expires', expires.toString());
                  const queryResult = queryParams.toString();
                  const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                  return to(
                    baseProjectApi.get<Dtos.DownloadUrl>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}/downloadDerivate${constructedQuery}`,
                      { cancelToken }
                    )
                  );
                },
              },
              restore: {
                /** Restore attachment from comment procedure comment */
                post: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  commentProcedureCommentAttachmentId: Guid,
                  cancelToken?: CancelToken
                ) =>
                  to(
                    baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${commentProcedureCommentAttachmentId}/restore`,
                      { cancelToken }
                    )
                  ),
              },
            },
          },
          attachments: {
            /** Get Comment Procedure Comment Attachments */
            get: (commentProcedureId: Guid, commentProcedureCommentId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.CommentProcedureCommentAttachmentDto[]>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments`,
                  { cancelToken }
                )
              ),
            discarded: {
              /** Get Discarded Comment Procedure Comment Attachments */
              get: (commentProcedureId: Guid, commentProcedureCommentId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.CommentProcedureCommentAttachmentDto[]>(
                    `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments/discarded`,
                    { cancelToken }
                  )
                ),
            },
            id: {
              publish: {
                /** Publish Internal Comment Procedure Comment Attachments */
                post: (
                  commentProcedureId: Guid,
                  commentProcedureCommentId: Guid,
                  commentProcedureCommentAttachmentId: Guid,
                  cancelToken?: CancelToken
                ) =>
                  to(
                    baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                      `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments/${commentProcedureCommentAttachmentId}/publish`,
                      { cancelToken }
                    )
                  ),
              },
            },
          },
          setstate: {
            /** Set comment state for Comment Procedure */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentSetStateDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/setstate`,
                  data,
                  { cancelToken }
                )
              ),
          },
          addnote: {
            /** add note to comment in Comment Procedure */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentNoteCreateDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/addnote`,
                  data,
                  { cancelToken }
                )
              ),
          },
          addattachments: {
            /** Add multiple new attachments with optional link to comment or note (that is uploaded to storage) */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentAttachmentCreateMultipleDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/addattachments`,
                  data,
                  { cancelToken }
                )
              ),
          },
          downloadattachments: {
            /** Download multiple attachments for comment procedure comment */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentAttachmentsDownloadDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.DownloadUrl>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/downloadattachments`,
                  data,
                  { cancelToken }
                )
              ),
          },
          assignattachment: {
            /** Assign document revision as attachment */
            post: (
              commentProcedureId: Guid,
              commentProcedureCommentId: Guid,
              data: Dtos.CommentProcedureCommentAttachmentAssignListDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
                  `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/assignattachment`,
                  data,
                  { cancelToken }
                )
              ),
          },
        },
      },
      documents: {
        id: {
          /** Delete existing document from comment procedure */
          delete: (commentProcedureId: Guid, commentProcedureDocumentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<Dtos.CommentProcedureDto>(
                `/comment/${commentProcedureId}/documents/${commentProcedureDocumentId}`,
                { cancelToken }
              )
            ),
        },
        add: {
          /** Add new document (that is uploaded to storage) to set directory and assign it to comment procedure */
          post: (commentProcedureId: Guid, data: Dtos.CommentProcedureDocumentCreateDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/documents/add`, data, {
                cancelToken,
              })
            ),
        },
        assign: {
          /** Assign existing document to comment procedure */
          post: (commentProcedureId: Guid, data: Dtos.CommentProcedureDocumentAssignDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/documents/assign`, data, {
                cancelToken,
              })
            ),
        },
      },
      comment: {
        id: {
          /** Get Comment Procedure */
          get: (commentProcedureId: Guid, commentProcedureCommentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.CommentProcedureCommentDto>(
                `/comment/${commentProcedureId}/comment/${commentProcedureCommentId}`,
                { cancelToken }
              )
            ),
        },
      },
      role: {
        /** Patch Comment Procedure Role */
        patch: (commentProcedureId: Guid, data: Dtos.CommentProcedureRolePatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/role`, data, { cancelToken })
          ),
      },
      setdeadline: {
        /** Set new Comment procedure deadline date */
        patch: (commentProcedureId: Guid, data: Dtos.CommentProcedureChangeDeadlineDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/setdeadline`, data, {
              cancelToken,
            })
          ),
      },
      setState: {
        /** Set Comment Procedure State */
        post: (commentProcedureId: Guid, data: Dtos.CommentProcedureSetStateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/setState`, data, {
              cancelToken,
            })
          ),
      },
      close: {
        /** Close Comment Procedure */
        post: (commentProcedureId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.CommentProcedureDto>(`/comment/${commentProcedureId}/close`, { cancelToken })
          ),
      },
      export: {
        post: (commentProcedureId: Guid, data?: Dtos.CommentProcedureExportSettingsDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<void>(`/comment/${commentProcedureId}/export`, data, { cancelToken })
          ),
      },
    },
    document: {
      id: {
        detail: {
          /** Get list of comment procedures */
          get: (documentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.CommentProcedureDocumentDetailDto>(`/comment/document/${documentId}/detail`, {
                cancelToken,
              })
            ),
        },
      },
    },
  },
  directories: {
    primary: {
      /** Get list of directories in project */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DirectoryListDto[]>(`/directories/primary`, { cancelToken })
        ),
      /** Add new directory */
      post: (data: Dtos.DirectoryCreateDto, uniquifyName?: boolean, cancelToken?: CancelToken) => {
        const queryParams = new URLSearchParams();
        !!uniquifyName && queryParams.append('uniquifyName', uniquifyName.toString());
        const queryResult = queryParams.toString();
        const constructedQuery = !!queryResult ? `?${queryResult}` : '';
        return to(
          baseProjectApi.post<Dtos.DirectoryDto>(`/directories/primary${constructedQuery}`, data, { cancelToken })
        );
      },
      id: {
        /** Discard directory */
        delete: (directoryId: Guid, removeLinks?: boolean, removeReferences?: boolean, cancelToken?: CancelToken) => {
          const queryParams = new URLSearchParams();
          !!removeLinks && queryParams.append('removeLinks', removeLinks.toString());
          !!removeReferences && queryParams.append('removeReferences', removeReferences.toString());
          const queryResult = queryParams.toString();
          const constructedQuery = !!queryResult ? `?${queryResult}` : '';
          return to(
            baseProjectApi.delete<void>(`/directories/primary/${directoryId}${constructedQuery}`, { cancelToken })
          );
        },
        /** Get directory by Id */
        get: (directoryId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DirectoryDetailDto>(`/directories/primary/${directoryId}`, { cancelToken })
          ),
        /** Change directory */
        patch: (directoryId: Guid, data?: Dtos.DirectoryPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<void>(`/directories/primary/${directoryId}`, data, { cancelToken })
          ),
        overview: {
          /** Get directory overview by Id */
          get: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.DirectoryOverviewDto>(`/directories/primary/${directoryId}/overview`, {
                cancelToken,
              })
            ),
        },
        content: {
          /** Get directory by Id */
          get: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.DirectoryContentDto[]>(`/directories/primary/${directoryId}/content`, {
                cancelToken,
              })
            ),
        },
        contentex: {
          /** Get directory (by Id) content with link and subdirectory list */
          get: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.DirectoryContentsExDto>(`/directories/primary/${directoryId}/contentex`, {
                cancelToken,
              })
            ),
        },
        permissions: {
          /** Get all directory permissions */
          get: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.DirectoryGroupDto[]>(`/directories/primary/${directoryId}/permissions`, {
                cancelToken,
              })
            ),
          /** Bulk permissions settings */
          patch: (directoryId: Guid, data: Dtos.DirectoryPermissionPatchDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.patch<Dtos.DirectoryPermissionDto>(
                `/directories/primary/${directoryId}/permissions`,
                data,
                { cancelToken }
              )
            ),
        },
        restore: {
          /** Restore  directory */
          patch: (
            directoryId: Guid,
            strategy?: Dtos.DocumentsMultipleRestoreStrategyEnum,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            !!strategy && queryParams.append('strategy', strategy.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.patch<Dtos.DirectoryRestoreResultDto>(
                `/directories/primary/${directoryId}/restore${constructedQuery}`,
                { cancelToken }
              )
            );
          },
        },
        discarddocuments: {
          /** Discard documents */
          patch: (directoryId: Guid, data: Dtos.DocumentsDiscardDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.patch<void>(`/directories/primary/${directoryId}/discarddocuments`, data, { cancelToken })
            ),
        },
        move: {
          /** Move directory and return new list of directories in project */
          patch: (directoryId: Guid, data: Dtos.DirectoryMoveDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.patch<Dtos.DirectoryDto[]>(`/directories/primary/${directoryId}/move`, data, {
                cancelToken,
              })
            ),
        },
        download: {
          /** Get directory download url with subdirectories */
          post: (directoryId: Guid, data: Dtos.DirectoryDownloadDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.DownloadUrl>(`/directories/primary/${directoryId}/download`, data, {
                cancelToken,
              })
            ),
        },
        accessrequest: {
          /** Directory access request */
          post: (directoryId: Guid, newAccessLevel: Dtos.AccessLevelEnum, cancelToken?: CancelToken) => {
            const queryParams = new URLSearchParams();
            queryParams.append('newAccessLevel', newAccessLevel.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.post<void>(`/directories/primary/${directoryId}/accessrequest${constructedQuery}`, {
                cancelToken,
              })
            );
          },
        },
        watch: {
          /** Add watch to directory */
          post: (directoryId: Guid, data: Dtos.DirectorySubscriberSetDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.DirectoryDetailDto>(`/directories/primary/${directoryId}/watch`, data, {
                cancelToken,
              })
            ),
        },
        report: {
          /** Get directory changes export xlsx */
          post: (directoryId: Guid, data?: Dtos.DirectoryReportSettingsDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<void>(`/directories/primary/${directoryId}/report`, data, { cancelToken })
            ),
        },
      },
      favorite: {
        id: {
          /** Delete favorite */
          delete: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/directories/primary/favorite/${directoryId}`, { cancelToken })
            ),
          /** Create favorite */
          post: (directoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<void>(`/directories/primary/favorite/${directoryId}`, { cancelToken })
            ),
        },
      },
      listdirectoriesex: {
        /** Get list of directories and directoryLinks in project */
        get: (cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DirectoryListsExDto>(`/directories/primary/listdirectoriesex`, { cancelToken })
          ),
      },
      appdirectories: {
        /** Returns app data for directory and directory content synchronization. */
        post: (data: Dtos.AppDirectoriesRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AppDirectoriesDto>(`/directories/primary/appdirectories`, data, { cancelToken })
          ),
      },
      appconflictedstruct: {
        /** Returns data of requested app object for conflict evaluation. */
        post: (data: Dtos.AppConflictedStructRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.AppConflictedStructDto>(`/directories/primary/appconflictedstruct`, data, {
              cancelToken,
            })
          ),
      },
    },
    discarded: {
      /** Get list of discarded directories */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DiscardedDirectoryDto[]>(`/directories/discarded`, { cancelToken })
        ),
    },
    dms: {
      download: {
        /** Get directory download url with subdirectories */
        post: (data: Dtos.DMSDownloadDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DownloadUrl>(`/directories/dms/download`, data, { cancelToken })
          ),
      },
    },
    documentselection: {
      /** Get list of documents and links in directory and subdirectories for deep selection */
      post: (data: Dtos.DirectorySelectionDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DirectorySelectionResultDto>(`/directories/documentselection`, data, { cancelToken })
        ),
    },
  },
  directorylink: {
    id: {
      /** Remove link to directory */
      delete: (directoryLinkId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/directorylink/${directoryLinkId}`, { cancelToken })
        ),
      /** Get directory link */
      get: (directoryLinkId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DirectoryLinkDto>(`/directorylink/${directoryLinkId}`, { cancelToken })
        ),
      /** Change directory link */
      patch: (directoryLinkId: Guid, data: Dtos.DirectoryLinkPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.DirectoryLinkDto>(`/directorylink/${directoryLinkId}`, data, { cancelToken })
        ),
      move: {
        /** Move directory link */
        patch: (directoryLinkId: Guid, data: Dtos.DirectoryLinkMoveDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.DirectoryLinkDto>(`/directorylink/${directoryLinkId}/move`, data, { cancelToken })
          ),
      },
    },
    adddirectorylink: {
      /** Add link to directory */
      post: (data: Dtos.DirectoryLinkCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/directorylink/adddirectorylink`, data, { cancelToken })
        ),
    },
  },
  documentannotation: {
    /** Add document revision annotations */
    post: (data: Dtos.DocumentAnnotationsSaveDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.DocumentAnnotationsDto>(`/documentannotation`, data, { cancelToken })
      ),
    id: {
      /** Get all document annotations */
      get: (documentId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DocumentAnnotationsDto>(`/documentannotation/${documentId}`, { cancelToken })
        ),
      id: {
        /** Get document revision annotations */
        get: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DocumentAnnotationsDto>(`/documentannotation/${documentId}/${revisionId}`, {
              cancelToken,
            })
          ),
      },
      attachment: {
        download: {
          /** Get download URL of annotation attachment's blob */
          get: (
            annotationId: Guid,
            redirect?: boolean,
            expires?: number,
            inline?: boolean,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            !!redirect && queryParams.append('redirect', redirect.toString());
            !!expires && queryParams.append('expires', expires.toString());
            !!inline && queryParams.append('inline', inline.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.get<Dtos.DownloadUrl>(
                `/documentannotation/${annotationId}/attachment/download${constructedQuery}`,
                { cancelToken }
              )
            );
          },
        },
      },
    },
  },
  documents: {
    /** Create document */
    post: (data: Dtos.DocumentCreateDto, uniquifyName?: boolean, cancelToken?: CancelToken) => {
      const queryParams = new URLSearchParams();
      !!uniquifyName && queryParams.append('uniquifyName', uniquifyName.toString());
      const queryResult = queryParams.toString();
      const constructedQuery = !!queryResult ? `?${queryResult}` : '';
      return to(
        baseProjectApi.post<Dtos.DocumentDto>(`/documents${constructedQuery}`, data, { cancelToken })
      );
    },
    categorytrees: {
      /** Get list of Document category tree in project */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DocumentCategoryTreeListDto[]>(`/documents/categorytrees`, { cancelToken })
        ),
      /** Create new Document category tree. */
      post: (data: Dtos.DocumentCategoryTreeCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DocumentCategoryTreeDto>(`/documents/categorytrees`, data, { cancelToken })
        ),
      id: {
        /** Remove Document category tree. */
        delete: (
          documentCategoryTreeId: Guid,
          removeStrategy?: Dtos.DocumentCategoryTreeRemoveStrategyEnum,
          cancelToken?: CancelToken
        ) => {
          const queryParams = new URLSearchParams();
          !!removeStrategy && queryParams.append('removeStrategy', removeStrategy.toString());
          const queryResult = queryParams.toString();
          const constructedQuery = !!queryResult ? `?${queryResult}` : '';
          return to(
            baseProjectApi.delete<void>(`/documents/categorytrees/${documentCategoryTreeId}${constructedQuery}`, {
              cancelToken,
            })
          );
        },
        /** Get Document category tree by Id */
        get: (documentCategoryTreeId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DocumentCategoryTreeDto>(`/documents/categorytrees/${documentCategoryTreeId}`, {
              cancelToken,
            })
          ),
        /** Patch Document category tree. */
        patch: (documentCategoryTreeId: Guid, data: Dtos.DocumentCategoryTreePatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.DocumentCategoryTreeDto>(
              `/documents/categorytrees/${documentCategoryTreeId}`,
              data,
              { cancelToken }
            )
          ),
        nodes: {
          /** Get list of Document category node in specific tree. */
          get: (documentCategoryTreeId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.DocumentCategoryNodeListDto[]>(
                `/documents/categorytrees/${documentCategoryTreeId}/nodes`,
                { cancelToken }
              )
            ),
          /** Create new Document category tree node. */
          post: (documentCategoryTreeId: Guid, data: Dtos.DocumentCategoryNodeCreateDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.DocumentCategoryNodeDto>(
                `/documents/categorytrees/${documentCategoryTreeId}/nodes`,
                data,
                { cancelToken }
              )
            ),
          id: {
            /** Remove Document category tree node. */
            delete: (
              documentCategoryTreeId: Guid,
              documentCategoryNodeId: Guid,
              removeStrategy?: Dtos.DocumentCategoryNodeRemoveStrategyEnum,
              removeChildren?: boolean,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              !!removeStrategy && queryParams.append('removeStrategy', removeStrategy.toString());
              !!removeChildren && queryParams.append('removeChildren', removeChildren.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseProjectApi.delete<void>(
                  `/documents/categorytrees/${documentCategoryTreeId}/nodes/${documentCategoryNodeId}${constructedQuery}`,
                  { cancelToken }
                )
              );
            },
            /** Get Document category tree node by Id */
            get: (documentCategoryTreeId: Guid, documentCategoryNodeId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.DocumentCategoryNodeDto>(
                  `/documents/categorytrees/${documentCategoryTreeId}/nodes/${documentCategoryNodeId}`,
                  { cancelToken }
                )
              ),
            /** Patch Document category tree node. */
            patch: (
              documentCategoryTreeId: Guid,
              documentCategoryNodeId: Guid,
              data: Dtos.DocumentCategoryNodePatchDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.patch<Dtos.DocumentCategoryNodeDto>(
                  `/documents/categorytrees/${documentCategoryTreeId}/nodes/${documentCategoryNodeId}`,
                  data,
                  { cancelToken }
                )
              ),
          },
        },
      },
    },
    id: {
      /** Get document by Id */
      get: (documentId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DocumentDto>(`/documents/${documentId}`, { cancelToken })
        ),
      /** Patch document */
      patch: (documentId: Guid, data: Dtos.DocumentPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.DocumentDto>(`/documents/${documentId}`, data, { cancelToken })
        ),
      revisions: {
        /** List document revisions */
        get: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.RevisionDto[]>(`/documents/${documentId}/revisions`, { cancelToken })
          ),
        /** Create document revision */
        post: (documentId: Guid, data: Dtos.RevisionCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.RevisionDto>(`/documents/${documentId}/revisions`, data, { cancelToken })
          ),
        id: {
          /** Discard document revision */
          delete: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/documents/${documentId}/revisions/${revisionId}`, { cancelToken })
            ),
          /** Get document revision by Id */
          get: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.RevisionDto>(`/documents/${documentId}/revisions/${revisionId}`, { cancelToken })
            ),
          download: {
            /** Get download URL of revision's primary blob */
            get: (
              documentId: Guid,
              revisionId: Guid,
              redirect?: boolean,
              expires?: number,
              inline?: boolean,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              !!redirect && queryParams.append('redirect', redirect.toString());
              !!expires && queryParams.append('expires', expires.toString());
              !!inline && queryParams.append('inline', inline.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseProjectApi.get<Dtos.DownloadUrl>(
                  `/documents/${documentId}/revisions/${revisionId}/download${constructedQuery}`,
                  { cancelToken }
                )
              );
            },
            id: {
              /** Get download URL of secondary file in revision */
              get: (
                documentId: Guid,
                revisionId: Guid,
                secDocumentId: Guid,
                redirect?: boolean,
                expires?: number,
                inline?: boolean,
                cancelToken?: CancelToken
              ) => {
                const queryParams = new URLSearchParams();
                !!redirect && queryParams.append('redirect', redirect.toString());
                !!expires && queryParams.append('expires', expires.toString());
                !!inline && queryParams.append('inline', inline.toString());
                const queryResult = queryParams.toString();
                const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                return to(
                  baseProjectApi.get<Dtos.DownloadUrl>(
                    `/documents/${documentId}/revisions/${revisionId}/download/${secDocumentId}${constructedQuery}`,
                    { cancelToken }
                  )
                );
              },
              derivates: {
                /** Get download URL of secondary file derivate in revision */
                get: (
                  documentId: Guid,
                  revisionId: Guid,
                  secDocumentBlobId: Guid,
                  secDocumentId: string,
                  type: Dtos.BlobDerivateTypeEnum,
                  redirect?: boolean,
                  expires?: number,
                  cancelToken?: CancelToken
                ) => {
                  const queryParams = new URLSearchParams();
                  queryParams.append('type', type.toString());
                  !!redirect && queryParams.append('redirect', redirect.toString());
                  !!expires && queryParams.append('expires', expires.toString());
                  const queryResult = queryParams.toString();
                  const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                  return to(
                    baseProjectApi.get<Dtos.DownloadUrl>(
                      `/documents/${documentId}/revisions/${revisionId}/download/${secDocumentId}/derivates${constructedQuery}`,
                      { cancelToken }
                    )
                  );
                },
              },
            },
            derivates: {
              /** Get download primary blob derivate URL of revision's */
              get: (
                documentId: Guid,
                revisionId: Guid,
                type: Dtos.BlobDerivateTypeEnum,
                redirect?: boolean,
                expires?: number,
                cancelToken?: CancelToken
              ) => {
                const queryParams = new URLSearchParams();
                queryParams.append('type', type.toString());
                !!redirect && queryParams.append('redirect', redirect.toString());
                !!expires && queryParams.append('expires', expires.toString());
                const queryResult = queryParams.toString();
                const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                return to(
                  baseProjectApi.get<Dtos.DownloadUrl>(
                    `/documents/${documentId}/revisions/${revisionId}/download/derivates${constructedQuery}`,
                    { cancelToken }
                  )
                );
              },
            },
          },
          addsecondary: {
            /** Add secondary files to document */
            post: (
              documentId: Guid,
              revisionId: Guid,
              data: Dtos.SecondaryDocumentsAddDto,
              cancelToken?: CancelToken
            ) =>
              to(
                baseProjectApi.post<Dtos.RevisionDto>(
                  `/documents/${documentId}/revisions/${revisionId}/addsecondary`,
                  data,
                  { cancelToken }
                )
              ),
          },
          restore: {
            /** Restore document revision */
            post: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.RevisionDto>(`/documents/${documentId}/revisions/${revisionId}/restore`, {
                  cancelToken,
                })
              ),
          },
          downloadall: {
            /** Download document with all secondary files as zip */
            post: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.DownloadUrl>(`/documents/${documentId}/revisions/${revisionId}/downloadall`, {
                  cancelToken,
                })
              ),
          },
        },
        diff: {
          id: {
            id: {
              download: {
                derivates: {
                  /** Get download primary blob derivate URL of two revisions to display diff */
                  get: (
                    documentId: Guid,
                    firstRevisionId: Guid,
                    secondRevisionId: Guid,
                    type: Dtos.BlobDerivateTypeEnum,
                    expires?: number,
                    cancelToken?: CancelToken
                  ) => {
                    const queryParams = new URLSearchParams();
                    queryParams.append('type', type.toString());
                    !!expires && queryParams.append('expires', expires.toString());
                    const queryResult = queryParams.toString();
                    const constructedQuery = !!queryResult ? `?${queryResult}` : '';
                    return to(
                      baseProjectApi.get<Dtos.DocumentRevisionDiffDownloadDto>(
                        `/documents/${documentId}/revisions/diff/${firstRevisionId}/${secondRevisionId}/download/derivates${constructedQuery}`,
                        { cancelToken }
                      )
                    );
                  },
                },
              },
            },
          },
        },
      },
      labels: {
        id: {
          /** Delete label from document */
          delete: (documentId: Guid, labelId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/documents/${documentId}/labels/${labelId}`, { cancelToken })
            ),
          /** Add label to document */
          post: (documentId: Guid, labelId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<void>(`/documents/${documentId}/labels/${labelId}`, { cancelToken })
            ),
        },
      },
      categorytrees: {
        id: {
          nodes: {
            id: {
              /** Remove category tree node from document */
              delete: (documentId: Guid, categoryTreeId: Guid, categoryNodeId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.delete<void>(
                    `/documents/${documentId}/categorytrees/${categoryTreeId}/nodes/${categoryNodeId}`,
                    { cancelToken }
                  )
                ),
              /** Add category tree to document */
              post: (documentId: Guid, categoryTreeId: Guid, categoryNodeId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.post<void>(
                    `/documents/${documentId}/categorytrees/${categoryTreeId}/nodes/${categoryNodeId}`,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
      reserve: {
        /** Remove reservation */
        delete: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<void>(`/documents/${documentId}/reserve`, { cancelToken })
          ),
        /** Create reservation */
        post: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocumentReservationDto>(`/documents/${documentId}/reserve`, { cancelToken })
          ),
      },
      download: {
        /** Get download URL of documen's current revision primary blob */
        get: (documentId: Guid, redirect?: boolean, expires?: number, inline?: boolean, cancelToken?: CancelToken) => {
          const queryParams = new URLSearchParams();
          !!redirect && queryParams.append('redirect', redirect.toString());
          !!expires && queryParams.append('expires', expires.toString());
          !!inline && queryParams.append('inline', inline.toString());
          const queryResult = queryParams.toString();
          const constructedQuery = !!queryResult ? `?${queryResult}` : '';
          return to(
            baseProjectApi.get<Dtos.DownloadUrl>(`/documents/${documentId}/download${constructedQuery}`, {
              cancelToken,
            })
          );
        },
        derivates: {
          /** Get download URL of document's derivate primary blob of current revision */
          get: (
            documentId: Guid,
            type: Dtos.BlobDerivateTypeEnum,
            redirect?: boolean,
            expires?: number,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            queryParams.append('type', type.toString());
            !!redirect && queryParams.append('redirect', redirect.toString());
            !!expires && queryParams.append('expires', expires.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.get<Dtos.DownloadUrl>(`/documents/${documentId}/download/derivates${constructedQuery}`, {
                cancelToken,
              })
            );
          },
        },
        id: {
          /** Get download URL current revision secondary file */
          get: (
            documentId: Guid,
            secDocumentBlobId: Guid,
            secDocumentId: string,
            redirect?: boolean,
            expires?: number,
            inline?: boolean,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            !!redirect && queryParams.append('redirect', redirect.toString());
            !!expires && queryParams.append('expires', expires.toString());
            !!inline && queryParams.append('inline', inline.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.get<Dtos.DownloadUrl>(
                `/documents/${documentId}/download/${secDocumentId}${constructedQuery}`,
                { cancelToken }
              )
            );
          },
          derivates: {
            /** Get download derivate URL current revision secondary file */
            get: (
              documentId: Guid,
              secDocumentBlobId: Guid,
              secDocumentId: string,
              type: Dtos.BlobDerivateTypeEnum,
              redirect?: boolean,
              expires?: number,
              cancelToken?: CancelToken
            ) => {
              const queryParams = new URLSearchParams();
              queryParams.append('type', type.toString());
              !!redirect && queryParams.append('redirect', redirect.toString());
              !!expires && queryParams.append('expires', expires.toString());
              const queryResult = queryParams.toString();
              const constructedQuery = !!queryResult ? `?${queryResult}` : '';
              return to(
                baseProjectApi.get<Dtos.DownloadUrl>(
                  `/documents/${documentId}/download/${secDocumentId}/derivates${constructedQuery}`,
                  { cancelToken }
                )
              );
            },
          },
        },
      },
      doctodoclink: {
        /** Get list of document to document linked dokuments */
        get: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DocToDocLinksListDto>(`/documents/${documentId}/doctodoclink`, { cancelToken })
          ),
      },
      references: {
        /** Get references for set document */
        get: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DocumentReferencesDto>(`/documents/${documentId}/references`, { cancelToken })
          ),
      },
      setOwner: {
        /** Set Owner on document */
        post: (documentId: Guid, data: Dtos.DocumentOwnerSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocumentDto>(`/documents/${documentId}/setOwner`, data, { cancelToken })
          ),
      },
      setState: {
        /** Set State on document */
        post: (documentId: Guid, data: Dtos.DocumentStateSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocumentDto>(`/documents/${documentId}/setState`, data, { cancelToken })
          ),
      },
      watch: {
        /** Add watch to document */
        post: (documentId: Guid, data: Dtos.DocumentSubscriberSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocumentDto>(`/documents/${documentId}/watch`, data, { cancelToken })
          ),
      },
    },
    reservationlock: {
      /** Lock document reservation */
      post: (data: Dtos.DocumentReservationLockRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DocumentReservationLockResponseDto>(`/documents/reservationlock`, data, {
            cancelToken,
          })
        ),
      id: {
        /** Unlock document reservation lock */
        delete: (lockId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<void>(`/documents/reservationlock/${lockId}`, { cancelToken })
          ),
      },
    },
    revisionlock: {
      /** Lock revision for sign */
      post: (data: Dtos.RevisionLockRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.RevisionLockResponseDto>(`/documents/revisionlock`, data, { cancelToken })
        ),
      id: {
        /** Unlock revision for sign */
        delete: (lockId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<void>(`/documents/revisionlock/${lockId}`, { cancelToken })
          ),
      },
    },
    reference: {
      /** Create document to document reference matrix */
      post: (data: Dtos.DocumentReferenceCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/documents/reference`, data, { cancelToken })
        ),
      id: {
        delete: {
          /** Delete document to document reference */
          delete: (referenceId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/documents/reference/${referenceId}/delete`, { cancelToken })
            ),
        },
      },
    },
    discardeddocs: {
      /** Get list of discarded documents */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DiscardedDocumentDto[]>(`/documents/discardeddocs`, { cancelToken })
        ),
    },
    favorite: {
      /** Get list of favorite */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DirectoryContentDto[]>(`/documents/favorite`, { cancelToken })
        ),
      /** Create favorite */
      post: (data: Dtos.FavoriteDocumentsDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.FavoriteDocumentsResultsDto>(`/documents/favorite`, data, { cancelToken })
        ),
      deletelist: {
        /** Delete favorite */
        post: (data: Dtos.FavoriteDocumentsDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.FavoriteDocumentsResultsDto>(`/documents/favorite/deletelist`, data, {
              cancelToken,
            })
          ),
      },
    },
    esticon: {
      /** Get list of esticon documents */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.EsticonDocumentDto[]>(`/documents/esticon`, { cancelToken })
        ),
    },
    move: {
      /** Move document */
      patch: (data: Dtos.DocumentMoveDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.DocumentMoveResultDto>(`/documents/move`, data, { cancelToken })
        ),
    },
    restore: {
      /** restore documents */
      patch: (data: Dtos.DocumentsRestoreDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.DocumentsRestoreResultDto>(`/documents/restore`, data, { cancelToken })
        ),
    },
    bulk: {
      state: {
        /** bulk action change selected documents state */
        patch: (data: Dtos.DocumentBulkStateSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.DocumentBulkActionSetResultDto>(`/documents/bulk/state`, data, { cancelToken })
          ),
      },
      labels: {
        /** bulk action change selected documents labels */
        patch: (data: Dtos.DocumentBulkLabelsSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.DocumentBulkActionSetResultDto>(`/documents/bulk/labels`, data, { cancelToken })
          ),
      },
      watch: {
        /** bulk action change selected documents watch */
        patch: (data: Dtos.DocumentBulkSubscribersSetDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.DocumentBulkActionSetResultDto>(`/documents/bulk/watch`, data, { cancelToken })
          ),
      },
      statecheck: {
        /** check valid states for state changes */
        post: (data: Dtos.DocumentBulkStateCheckDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocumentBulkStateCheckResultDto>(`/documents/bulk/statecheck`, data, {
              cancelToken,
            })
          ),
      },
    },
    returnforrework: {
      /** Return to rework on esticon document */
      post: (data: Dtos.DocumentReturnForReworkDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/documents/returnforrework`, data, { cancelToken })
        ),
    },
    setapprovaldate: {
      /** Set Approval Date */
      post: (data: Dtos.DocumentSetApprovalDateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/documents/setapprovaldate`, data, { cancelToken })
        ),
    },
    download: {
      /** Download multiple document */
      post: (data: Dtos.DocumentMultipleDownloadDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DownloadUrl>(`/documents/download`, data, { cancelToken })
        ),
    },
    share: {
      /** Share documents (current revisions in sharing time) or revisions */
      post: (data: Dtos.ShareDownloadDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.ShareDownloadResultDto>(`/documents/share`, data, { cancelToken })
        ),
    },
    deepshare: {
      /** Share all documents (current revisions in sharing time) in directory and optionaly in all subdirectories */
      post: (data: Dtos.DeepShareDownloadDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.ShareDownloadResultDto>(`/documents/deepshare`, data, { cancelToken })
        ),
    },
    doctodoclink: {
      /** Create document to document link */
      post: (data: Dtos.DocToDocLinkCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DocToDocLinkCreateResultsDto>(`/documents/doctodoclink`, data, { cancelToken })
        ),
      deletelist: {
        /** Delete document to document link */
        post: (data: Dtos.DocToDocLinksDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.DocToDocLinkDeleteResultsDto>(`/documents/doctodoclink/deletelist`, data, {
              cancelToken,
            })
          ),
      },
    },
    doctodoclinks: {
      /** Get list of document to document linked dokuments */
      post: (data: Dtos.GetDocToDocLinksListsRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.GetDocToDocLinksListsResponseDto>(`/documents/doctodoclinks`, data, { cancelToken })
        ),
    },
    dms: {
      metadata: {
        /** Get directory dms export xlsx */
        post: (data: Dtos.DMSMetadataRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<void>(`/documents/dms/metadata`, data, { cancelToken })
          ),
      },
    },
    sign: {
      uploadinfo: {
        id: {
          /** Get sign info for upload pdf sign document */
          get: (signId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.SignDocumentInfoDto>(`/documents/sign/uploadinfo/${signId}`, { cancelToken })
            ),
        },
      },
      serveraddress: {
        id: {
          /** Get sign server address */
          get: (signId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<string>(`/documents/sign/serveraddress/${signId}`, { cancelToken })
            ),
        },
      },
      authorizationinfo: {
        id: {
          /** Get sign info for bank id autorization */
          get: (signId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.SignDocumentAppInfoDto>(`/documents/sign/authorizationinfo/${signId}`, {
                cancelToken,
              })
            ),
        },
      },
      downloadinfo: {
        id: {
          /** Get sign info for bank id download */
          get: (signId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.SignDocumentDownloadInfoDto>(`/documents/sign/downloadinfo/${signId}`, {
                cancelToken,
              })
            ),
        },
      },
      initiate: {
        /** Initiate pdf document signing */
        post: (data: Dtos.SignDocumentRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.SignDocumentResponseDto>(`/documents/sign/initiate`, data, { cancelToken })
          ),
      },
      finish: {
        id: {
          /** Set sign finishing */
          post: (signId: Guid, State: Dtos.BankIdSignStateEnum, UploadId?: Guid, cancelToken?: CancelToken) => {
            const queryParams = new URLSearchParams();
            queryParams.append('State', State.toString());
            !!UploadId && queryParams.append('UploadId', UploadId.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.post<void>(`/documents/sign/finish/${signId}${constructedQuery}`, { cancelToken })
            );
          },
        },
      },
    },
  },
  documentlink: {
    id: {
      /** Remove link to document */
      delete: (documentLinkId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/documentlink/${documentLinkId}`, { cancelToken })
        ),
      /** Change document link */
      patch: (documentLinkId: Guid, data: Dtos.DocumentLinkPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.DocumentLinkDto>(`/documentlink/${documentLinkId}`, data, { cancelToken })
        ),
    },
    documentslinks: {
      id: {
        /** Get documents document link */
        get: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DocumentLinkDto[]>(`/documentlink/documentslinks/${documentId}`, { cancelToken })
          ),
      },
    },
    addlinks: {
      /** Add link to document */
      post: (data: Dtos.DocumentLinkCreateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/documentlink/addlinks`, data, { cancelToken })
        ),
    },
  },
  document: {
    model: {
      /** Bulk remove documents from models. */
      delete: (
        data: Dtos.ModelDocumentsRemoveFromModelDto,
        strategy?: Dtos.DocumentLinkRemoveStrategyEnum,
        cancelToken?: CancelToken
      ) => {
        const queryParams = new URLSearchParams();
        !!strategy && queryParams.append('strategy', strategy.toString());
        const queryResult = queryParams.toString();
        const constructedQuery = !!queryResult ? `?${queryResult}` : '';
        return to(
          baseProjectApi.delete<void>(`/document/model${constructedQuery}`, { cancelToken, data: data })
        );
      },
      /** Get list of models */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.DirectoryContentDto[]>(`/document/model`, { cancelToken })
        ),
      /** Bulk add documents to models. */
      post: (data: Dtos.ModelDocumentsAddToModelDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/document/model`, data, { cancelToken })
        ),
      id: {
        /** Remove document from models. */
        delete: (documentId: Guid, strategy?: Dtos.DocumentLinkRemoveStrategyEnum, cancelToken?: CancelToken) => {
          const queryParams = new URLSearchParams();
          !!strategy && queryParams.append('strategy', strategy.toString());
          const queryResult = queryParams.toString();
          const constructedQuery = !!queryResult ? `?${queryResult}` : '';
          return to(
            baseProjectApi.delete<void>(`/document/model/${documentId}${constructedQuery}`, { cancelToken })
          );
        },
        /** Add Document to models. */
        patch: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<void>(`/document/model/${documentId}`, { cancelToken })
          ),
      },
      doctodoclink: {
        /** Create document to document model Link */
        post: (data: Dtos.ModelDocToDocLinkCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.ModelDocToDocLinkCreateResultsDto>(`/document/model/doctodoclink`, data, {
              cancelToken,
            })
          ),
      },
    },
  },
  Esticon: {
    firms: {
      /** All Esticon firms */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.EstiConFirmRowDto[]>(`/Esticon/firms`, { cancelToken })
        ),
      ciselniky: {
        adresypartneri: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConAdresyPartneriDto>(`/Esticon/firms/ciselniky/adresypartneri`, {
                cancelToken,
              })
            ),
          post: (data?: Dtos.EstiConPartnerDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConSaveResultDto>(`/Esticon/firms/ciselniky/adresypartneri`, data, {
                cancelToken,
              })
            ),
        },
        banky: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConBankaDto[]>(`/Esticon/firms/ciselniky/banky`, { cancelToken })
            ),
        },
        dph: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConDphZemeDto[]>(`/Esticon/firms/ciselniky/dph`, { cancelToken })
            ),
        },
      },
      export: {
        exportCerpani: {
          post: (data?: Dtos.EstiConExportCerpaniRequest, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConExportApiResult>(`/Esticon/firms/export/exportCerpani`, data, {
                cancelToken,
              })
            ),
        },
        exportZBV: {
          post: (data?: Dtos.EstiConExportZBVRequest, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConExportApiResult>(`/Esticon/firms/export/exportZBV`, data, { cancelToken })
            ),
        },
      },
      fakturace: {
        param: {
          get: (sentOnly: boolean, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConFakProjektDto>(`/Esticon/firms/fakturace/${sentOnly}`, { cancelToken })
            ),
        },
        fakt: {
          id: {
            detail: {
              get: (faktId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConFakDetailDto>(`/Esticon/firms/fakturace/fakt/${faktId}/detail`, {
                    cancelToken,
                  })
                ),
            },
            spoldetail: {
              get: (faktId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConFakSpolDetailDto>(
                    `/Esticon/firms/fakturace/fakt/${faktId}/spoldetail`,
                    { cancelToken }
                  )
                ),
            },
          },
        },
        id: {
          rozpis: {
            param: {
              get: (id: Guid, ico: string, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConFakDetailDto[]>(`/Esticon/firms/fakturace/${id}/rozpis/${ico}`, {
                    cancelToken,
                  })
                ),
            },
          },
        },
        podklady: {
          post: (data?: Dtos.EstiConFakTvorbaFakturParamsDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConFakDetailDto[]>(`/Esticon/firms/fakturace/podklady`, data, {
                cancelToken,
              })
            ),
        },
      },
      id: {
        /** Esticon firm detail by id */
        get: (id: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.EstiConFirmDetailDto>(`/Esticon/firms/${id}`, { cancelToken })
          ),
        linkableprojects: {
          /** Esticon projects available to link */
          get: (id: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConProjectRowDto[]>(`/Esticon/firms/${id}/linkableprojects`, { cancelToken })
            ),
        },
      },
      hgm: {
        get: (cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.EstiConHarmonogramProjektDto>(`/Esticon/firms/hgm`, { cancelToken })
          ),
        version: {
          get: (versionId?: Guid, cancelToken?: CancelToken) => {
            const queryParams = new URLSearchParams();
            !!versionId && queryParams.append('versionId', versionId.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.get<Dtos.EstiConHarmonogramProjektDto>(`/Esticon/firms/hgm/version${constructedQuery}`, {
                cancelToken,
              })
            );
          },
        },
        versions: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConHarmonogramVersionDto[]>(`/Esticon/firms/hgm/versions`, { cancelToken })
            ),
        },
        pdf: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<string>(`/Esticon/firms/hgm/pdf`, { cancelToken })
            ),
        },
      },
      import: {
        analyzeZP: {
          id: {
            post: (uploadId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeZPResult>(`/Esticon/firms/import/analyzeZP/${uploadId}`, {
                  cancelToken,
                })
              ),
          },
        },
        analyzeZPDoc: {
          id: {
            post: (documentId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeZPResult>(
                  `/Esticon/firms/import/analyzeZPDoc/${documentId}`,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeZBV: {
          id: {
            post: (uploadId: Guid, data: Dtos.EsticonAnalyzeZBVRequestDto, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeZBVResult>(
                  `/Esticon/firms/import/analyzeZBV/${uploadId}`,
                  data,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeZBVDoc: {
          id: {
            post: (documentId: Guid, data: Dtos.EsticonAnalyzeZBVRequestDto, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeZBVResult>(
                  `/Esticon/firms/import/analyzeZBVDoc/${documentId}`,
                  data,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeFaktury: {
          id: {
            post: (uploadId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeFakturyResult>(
                  `/Esticon/firms/import/analyzeFaktury/${uploadId}`,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeFakturyDoc: {
          id: {
            post: (documentId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeFakturyResult>(
                  `/Esticon/firms/import/analyzeFakturyDoc/${documentId}`,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeHarmonogram: {
          id: {
            post: (uploadId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeHarmonogramResult>(
                  `/Esticon/firms/import/analyzeHarmonogram/${uploadId}`,
                  { cancelToken }
                )
              ),
          },
        },
        analyzeHarmonogramDoc: {
          id: {
            post: (documentId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.EstiConImportAnalyzeHarmonogramResult>(
                  `/Esticon/firms/import/analyzeHarmonogramDoc/${documentId}`,
                  { cancelToken }
                )
              ),
          },
        },
        importZP: {
          post: (data?: Dtos.EstiConImportCerpaniRunDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConImportResult>(`/Esticon/firms/import/importZP`, data, { cancelToken })
            ),
        },
        importZBV: {
          post: (data?: Dtos.EstiConImportZBVRunDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConImportResult>(`/Esticon/firms/import/importZBV`, data, { cancelToken })
            ),
        },
        importFaktury: {
          post: (data?: Dtos.EstiConImportFakturyRunDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConImportResult>(`/Esticon/firms/import/importFaktury`, data, {
                cancelToken,
              })
            ),
        },
        importHarmonogram: {
          post: (data?: Dtos.EstiConImportHarmonogramRunDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.EstiConImportResult>(`/Esticon/firms/import/importHarmonogram`, data, {
                cancelToken,
              })
            ),
        },
      },
      projects: {
        get: (cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.EstiConProjectRowDto[]>(`/Esticon/firms/projects`, { cancelToken })
          ),
        project: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConProjectDto>(`/Esticon/firms/projects/project`, { cancelToken })
            ),
        },
        dashboard: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConProjectDashboardDto>(`/Esticon/firms/projects/dashboard`, { cancelToken })
            ),
        },
        transferconstructiontokd: {
          get: (cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConImportResult>(`/Esticon/firms/projects/transferconstructiontokd`, {
                cancelToken,
              })
            ),
        },
      },
      realizace: {
        param: {
          get: (sentOnly: boolean, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConZjistovaciProtokolyListProjectDto>(
                `/Esticon/firms/realizace/${sentOnly}`,
                { cancelToken }
              )
            ),
        },
        zp: {
          id: {
            get: (zpId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZjistovaciProtokolDto>(`/Esticon/firms/realizace/zp/${zpId}`, {
                  cancelToken,
                })
              ),
            cerpani: {
              get: (zpId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConCerpaniZjistovaciProtokolDto>(
                    `/Esticon/firms/realizace/zp/${zpId}/cerpani`,
                    { cancelToken }
                  )
                ),
            },
            sdruzeni: {
              get: (zpId: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConZjistovaciProtokolSdruzeniDetailDto>(
                    `/Esticon/firms/realizace/zp/${zpId}/sdruzeni`,
                    { cancelToken }
                  )
                ),
            },
          },
        },
      },
      rozpocet: {
        param: {
          get: (sentOnly: boolean, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConRozProjektDto>(`/Esticon/firms/rozpocet/${sentOnly}`, { cancelToken })
            ),
        },
        so: {
          id: {
            get: (id: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConRozSODto>(`/Esticon/firms/rozpocet/so/${id}`, { cancelToken })
              ),
            cerpani: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozSOCerpaniDto>(`/Esticon/firms/rozpocet/so/${id}/cerpani`, {
                    cancelToken,
                  })
                ),
            },
            rozpocet: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozSORozpocetDto>(`/Esticon/firms/rozpocet/so/${id}/rozpocet`, {
                    cancelToken,
                  })
                ),
            },
            extradata: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozSOExtraDataDto>(`/Esticon/firms/rozpocet/so/${id}/extradata`, {
                    cancelToken,
                  })
                ),
            },
          },
        },
        pr: {
          id: {
            specifikace: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozPolozkaSpecifikaceDto>(
                    `/Esticon/firms/rozpocet/pr/${id}/specifikace`,
                    { cancelToken }
                  )
                ),
            },
            realizace: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozPolozkaRealizaceDto[]>(
                    `/Esticon/firms/rozpocet/pr/${id}/realizace`,
                    { cancelToken }
                  )
                ),
            },
            vykazvymer: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConVykazVymerDto>(`/Esticon/firms/rozpocet/pr/${id}/vykazvymer`, {
                    cancelToken,
                  })
                ),
            },
            polozka: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozPolozkaDto>(`/Esticon/firms/rozpocet/pr/${id}/polozka`, {
                    cancelToken,
                  })
                ),
            },
            extradata: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozPolozkaExtraDataDto>(`/Esticon/firms/rozpocet/pr/${id}/extradata`, {
                    cancelToken,
                  })
                ),
            },
          },
        },
        sd: {
          id: {
            extradata: {
              get: (id: Guid, cancelToken?: CancelToken) =>
                to(
                  baseProjectApi.get<Dtos.EstiConRozPolozkaExtraDataDto>(`/Esticon/firms/rozpocet/sd/${id}/extradata`, {
                    cancelToken,
                  })
                ),
            },
          },
        },
      },
      zbv: {
        param: {
          get: (sentOnly: boolean, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.EstiConZBVProjectDto>(`/Esticon/firms/zbv/${sentOnly}`, { cancelToken })
            ),
        },
        smluvnidodatekprojekt: {
          get: (id?: Guid, cancelToken?: CancelToken) => {
            const queryParams = new URLSearchParams();
            !!id && queryParams.append('id', id.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.get<Dtos.EstiConZBVDodatekProjectDto>(
                `/Esticon/firms/zbv/smluvnidodatekprojekt${constructedQuery}`,
                { cancelToken }
              )
            );
          },
        },
        smluvnidodatky: {
          id: {
            get: (smluvniDodatekId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZBVSmluvniDodatekSimpleDto[]>(
                  `/Esticon/firms/zbv/smluvnidodatky/${smluvniDodatekId}`,
                  { cancelToken }
                )
              ),
          },
        },
        id: {
          detail: {
            get: (zbvId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZBVZmenovyListDetailDto>(`/Esticon/firms/zbv/${zbvId}/detail`, {
                  cancelToken,
                })
              ),
          },
          zlpolozky: {
            get: (zbvId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZBVPolozkyZmenovyListDto>(`/Esticon/firms/zbv/${zbvId}/zlpolozky`, {
                  cancelToken,
                })
              ),
          },
          zlcerpani: {
            get: (structureId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZBVZmenovyListSimpleDto[]>(
                  `/Esticon/firms/zbv/${structureId}/zlcerpani`,
                  { cancelToken }
                )
              ),
          },
        },
        smluvnidodatek: {
          id: {
            get: (smdId: Guid, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.get<Dtos.EstiConZBVSmluvniDodatekDetailDto>(
                  `/Esticon/firms/zbv/smluvnidodatek/${smdId}`,
                  { cancelToken }
                )
              ),
          },
        },
      },
    },
  },
  forge: {
    token: {
      /** Get public access token for Autodesk Viewer */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.OAuthToken>(`/forge/token`, { cancelToken })
        ),
    },
  },
  groups: {
    /** Get list of groups in project */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.GroupListDto[]>(`/groups`, { cancelToken })
      ),
    /** Add new group */
    post: (data: Dtos.GroupCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.GroupDto>(`/groups`, data, { cancelToken })
      ),
    id: {
      /** Delete group */
      delete: (groupId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/groups/${groupId}`, { cancelToken })
        ),
      /** Get group by Id */
      get: (groupId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.GroupDto>(`/groups/${groupId}`, { cancelToken })
        ),
      /** Change group */
      patch: (groupId: Guid, data: Dtos.GroupPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.GroupDto>(`/groups/${groupId}`, data, { cancelToken })
        ),
      users: {
        /** Get list of users guid in group */
        get: (groupId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Guid[]>(`/groups/${groupId}/users`, { cancelToken })
          ),
      },
    },
    addUsers: {
      /** Add multiple users to group */
      post: (data: Dtos.GroupUserListAddDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.GroupDto>(`/groups/addUsers`, data, { cancelToken })
        ),
    },
  },
  labels: {
    /** Get list of labels in project */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.LabelListDto[]>(`/labels`, { cancelToken })
      ),
    /** Add new label */
    post: (data: Dtos.LabelCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.LabelDto>(`/labels`, data, { cancelToken })
      ),
    id: {
      /** Delete label */
      delete: (labelId: Guid, removeStrategy?: Dtos.LabelRemoveConflictStrategyEnum, cancelToken?: CancelToken) => {
        const queryParams = new URLSearchParams();
        !!removeStrategy && queryParams.append('removeStrategy', removeStrategy.toString());
        const queryResult = queryParams.toString();
        const constructedQuery = !!queryResult ? `?${queryResult}` : '';
        return to(
          baseProjectApi.delete<void>(`/labels/${labelId}${constructedQuery}`, { cancelToken })
        );
      },
      /** Get label by Id */
      get: (labelId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.LabelDto>(`/labels/${labelId}`, { cancelToken })
        ),
      /** Patch label */
      patch: (labelId: Guid, data: Dtos.LabelPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.LabelDto>(`/labels/${labelId}`, data, { cancelToken })
        ),
    },
  },
  link: {
    /** Create Esticon project link */
    post: (data: Dtos.EsticonLinkDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<void>(`/link`, data, { cancelToken })
      ),
    id: {
      /** Delete Esticon project link */
      delete: (esticonProjectId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/link/${esticonProjectId}`, { cancelToken })
        ),
    },
  },
  data: {
    /** Get project data */
    get: (period?: string, cancelToken?: CancelToken) => {
      const queryParams = new URLSearchParams();
      !!period && queryParams.append('period', period.toString());
      const queryResult = queryParams.toString();
      const constructedQuery = !!queryResult ? `?${queryResult}` : '';
      return to(
        baseProjectApi.get<Dtos.ProjectDataDto>(`/data${constructedQuery}`, { cancelToken })
      );
    },
  },
  notifyuserleave: {
    /** Notify user leaving project */
    post: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<void>(`/notifyuserleave`, { cancelToken })
      ),
  },
  repairederivates: {
    /** Repaire derivates from project document revision */
    post: (data: Dtos.RepaireDerivatesDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<void>(`/repairederivates`, data, { cancelToken })
      ),
  },
  repairederivate: {
    /** Repaire one derivate from project document revision */
    post: (data: Dtos.RepaireDerivateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<void>(`/repairederivate`, data, { cancelToken })
      ),
  },
  projectsetting: {
    externalapplication: {
      /** Set external application settings */
      patch: (data: Dtos.ExternalApplicationSettingSetDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.ExternalApplicationsProjectSettingsDto>(
            `/projectsetting/externalapplication`,
            data,
            { cancelToken }
          )
        ),
      id: {
        /** Delete external application setting */
        delete: (appId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<void>(`/projectsetting/externalapplication/${appId}`, { cancelToken })
          ),
      },
      textpage: {
        id: {
          /** Get external application text page */
          get: (pageId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.ExternalApplicationsProjectSettingsTextPageDto>(
                `/projectsetting/externalapplication/textpage/${pageId}`,
                { cancelToken }
              )
            ),
        },
      },
    },
    esticon: {
      /** Get Esticon settings */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.EstiProjectSettingsDto>(`/projectsetting/esticon`, { cancelToken })
        ),
      /** Set Esticon settings */
      patch: (data: Dtos.EstiProjectSettingsBaseDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.EstiProjectSettingsDto>(`/projectsetting/esticon`, data, { cancelToken })
        ),
    },
    externalapplications: {
      /** Get external app settings */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.ExternalApplicationsProjectSettingsDto>(`/projectsetting/externalapplications`, {
            cancelToken,
          })
        ),
    },
    commentprocedure: {
      /** Get Comment procedure general settings */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.CommentProcedureProjectSettingsDto>(`/projectsetting/commentprocedure`, {
            cancelToken,
          })
        ),
      /** Set Comment procedure general settings */
      patch: (data: Dtos.CommentProcedureProjectSettingsPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.CommentProcedureProjectSettingsDto>(`/projectsetting/commentprocedure`, data, {
            cancelToken,
          })
        ),
    },
    checkestipaths: {
      /** Check if esticon paths is set */
      post: (data: Dtos.CheckEstiPathsDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<boolean>(`/projectsetting/checkestipaths`, data, { cancelToken })
        ),
    },
  },
  users: {
    /** Get list of users in project */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.ProjectUserProfileListDto[]>(`/users`, { cancelToken })
      ),
    id: {
      /** Delete invited user */
      delete: (userId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/users/${userId}`, { cancelToken })
        ),
      /** Get user by Id */
      get: (userId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.ProjectUserProfileDto>(`/users/${userId}`, { cancelToken })
        ),
      groups: {
        id: {
          /** Remove user from group */
          delete: (userId: Guid, groupId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/users/${userId}/groups/${groupId}`, { cancelToken })
            ),
          /** Add user to group */
          post: (userId: Guid, groupId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<void>(`/users/${userId}/groups/${groupId}`, { cancelToken })
            ),
        },
      },
      extendedpermission: {
        /** Get extended permission by Id */
        get: (userId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.ExtendedPermissionsDto>(`/users/${userId}/extendedpermission`, { cancelToken })
          ),
        /** Change extended permission */
        patch: (userId: Guid, data: Dtos.ExtendedPermissionsPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<void>(`/users/${userId}/extendedpermission`, data, { cancelToken })
          ),
      },
      suspend: {
        /** Suspend user */
        post: (userId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<void>(`/users/${userId}/suspend`, { cancelToken })
          ),
      },
      activate: {
        /** Activate suspended or expired user profile */
        post: (userId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.ProjectUserActivationResponseDto>(`/users/${userId}/activate`, { cancelToken })
          ),
      },
    },
    me: {
      /** Get current projectuser */
      get: (cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.ProjectUserProfileDto>(`/users/me`, { cancelToken })
        ),
    },
    addprojectusers: {
      /** Add users to project */
      post: (data: Dtos.ProjectUsersRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/users/addprojectusers`, data, { cancelToken })
        ),
    },
    senddemandmsg: {
      /** Send user demand message */
      post: (data: Dtos.SendDemandMsgDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/users/senddemandmsg`, data, { cancelToken })
        ),
    },
  },
  roles: {
    /** Get list of roles in project */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.RoleDto[]>(`/roles`, { cancelToken })
      ),
    /** Add new role */
    post: (data: Dtos.RoleCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.RoleDto>(`/roles`, data, { cancelToken })
      ),
    id: {
      /** Delete role */
      delete: (roleId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/roles/${roleId}`, { cancelToken })
        ),
      /** Get role by Id */
      get: (roleId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.RoleDto>(`/roles/${roleId}`, { cancelToken })
        ),
      /** Update role */
      patch: (roleId: Guid, data: Dtos.RolePatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.RoleDto>(`/roles/${roleId}`, data, { cancelToken })
        ),
    },
  },
  search: {
    /** Performs search for documents within specified project. */
    post: (data: Dtos.SearchRequestDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.SearchResponseDto>(`/search`, data, { cancelToken })
      ),
  },
  reloadIndex: {
    /** Reload Elastic Search index for all files in project. */
    put: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.put<void>(`/reloadIndex`, { cancelToken })
      ),
  },
  share: {
    /** Process download from share */
    post: (data: Dtos.ProcessSharedDownloadDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.DownloadSharedUrl>(`/share`, data, { cancelToken })
      ),
    id: {
      /** Get share by Id */
      get: (shareId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.SharedDownloadDto>(`/share/${shareId}`, { cancelToken })
        ),
    },
    directory: {
      id: {
        /** Get directory accesss map */
        get: (directoryId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.DirectoryAccessInfoDto>(`/share/directory/${directoryId}`, { cancelToken })
          ),
      },
    },
    process: {
      /** Process appUser share notification */
      post: (data: Dtos.AppUserShareDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/share/process`, data, { cancelToken })
        ),
    },
  },
  uploads: {
    /** List all uploads */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.UploadDto[]>(`/uploads`, { cancelToken })
      ),
    /** Create new upload */
    post: (data: Dtos.UploadCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.UploadDto>(`/uploads`, data, { cancelToken })
      ),
    id: {
      /** Get upload by GUID */
      get: (uploadId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.UploadDto>(`/uploads/${uploadId}`, { cancelToken })
        ),
      parts: {
        /** Lists all upload's parts */
        get: (uploadId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.get<Dtos.UploadPartDto[]>(`/uploads/${uploadId}/parts`, { cancelToken })
          ),
        id: {
          init: {
            /** Initiate part upload */
            post: (uploadId: Guid, partId: Guid, data: Dtos.UploadPartInitRequestDto, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<Dtos.UploadPartInitResponseDto>(`/uploads/${uploadId}/parts/${partId}/init`, data, {
                  cancelToken,
                })
              ),
          },
          complete: {
            /** Complete part upload */
            post: (uploadId: Guid, partId: Guid, data: Dtos.UploadPartCompleteRequestDto, cancelToken?: CancelToken) =>
              to(
                baseProjectApi.post<void>(`/uploads/${uploadId}/parts/${partId}/complete`, data, { cancelToken })
              ),
          },
        },
      },
      complete: {
        /** Complete upload */
        post: (uploadId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.UploadDto>(`/uploads/${uploadId}/complete`, { cancelToken })
          ),
      },
    },
    checkmultiupload: {
      /** Check files and directories for subsequent multi upload */
      post: (data: Dtos.MultiUploadCheckRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.MultiUploadCheckResponseDto>(`/uploads/checkmultiupload`, data, { cancelToken })
        ),
    },
    preparemultiupload: {
      /** Make directories for subsequent files multi upload */
      post: (data: Dtos.MultiUploadPrepareDirectoriesRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.MultiUploadPrepareDirectoriesResponseDto>(`/uploads/preparemultiupload`, data, {
            cancelToken,
          })
        ),
    },
  },
  wopi: {
    startedit: {
      /** Start editing file by WOPI */
      post: (data: Dtos.StartWopiRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.StartWopiResponseDto>(`/wopi/startedit`, data, { cancelToken })
        ),
    },
    addrevision: {
      /** Set revision data to session variables */
      post: (data: Dtos.PrepareAddRevisionRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<void>(`/wopi/addrevision`, data, { cancelToken })
        ),
    },
    id: {
      refreshtoken: {
        /** Refresh Token request */
        put: (documentId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.put<Dtos.RefreshWopiResponseDto>(`/wopi/${documentId}/refreshtoken`, { cancelToken })
          ),
      },
    },
  },
  workflow: {
    /** Get list of Workflows */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.WorkflowListDto[]>(`/workflow`, { cancelToken })
      ),
    id: {
      /** Get Workflow */
      get: (workflowId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.get<Dtos.WorkflowDto>(`/workflow/${workflowId}`, { cancelToken })
        ),
      /** Patch Workflow */
      patch: (workflowId: Guid, data: Dtos.WorkflowPatchDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.patch<Dtos.WorkflowDto>(`/workflow/${workflowId}`, data, { cancelToken })
        ),
      defaultDirectory: {
        /** Add default directory */
        post: (workflowId: Guid, data: Dtos.WorkflowDefaultDirectoryPatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/${workflowId}/defaultDirectory`, data, { cancelToken })
          ),
        id: {
          /** Delete default directory */
          delete: (workflowId: Guid, defaultDirectoryId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.delete<void>(`/workflow/${workflowId}/defaultDirectory/${defaultDirectoryId}`, {
                cancelToken,
              })
            ),
          /** Update default directory */
          patch: (
            workflowId: Guid,
            defaultDirectoryId: Guid,
            data: Dtos.WorkflowDefaultDirectoryPatchDto,
            cancelToken?: CancelToken
          ) =>
            to(
              baseProjectApi.patch<Dtos.WorkflowDto>(
                `/workflow/${workflowId}/defaultDirectory/${defaultDirectoryId}`,
                data,
                { cancelToken }
              )
            ),
        },
      },
      commentprocedure: {
        /** Patch workflow comment procedures */
        patch: (workflowId: Guid, data: Dtos.WorkflowCommentProcedurePatchDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.WorkflowDto>(`/workflow/${workflowId}/commentprocedure`, data, { cancelToken })
          ),
      },
      close: {
        /** Close Workflow */
        post: (workflowId: Guid, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/${workflowId}/close`, { cancelToken })
          ),
      },
    },
    workflownode: {
      discussion: {
        /** Delete note from workflow node discussion */
        delete: (data: Dtos.WorkflowNodeNoteDiscardDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<Dtos.WorkflowDto>(`/workflow/workflownode/discussion`, { cancelToken, data: data })
          ),
        /** Edit workflow node discussion note */
        patch: (data: Dtos.WorkflowNodeNoteEditDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.WorkflowDto>(`/workflow/workflownode/discussion`, data, { cancelToken })
          ),
        /** Add note to discussion on workflow node */
        post: (data: Dtos.WorkflowNodeNoteCreateDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/discussion`, data, { cancelToken })
          ),
        restore: {
          /** Restore note from workflow node discussion */
          post: (data: Dtos.WorkflowNodeNoteRestoreDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/discussion/restore`, data, { cancelToken })
            ),
        },
      },
      attachment: {
        /** Discard attachment from workflow node */
        delete: (data: Dtos.WorkflowNodeAttachmentDiscardDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.delete<Dtos.WorkflowDto>(`/workflow/workflownode/attachment`, { cancelToken, data: data })
          ),
        discarded: {
          /** Get discarded workflowNode attachments */
          post: (data: Dtos.WorkflowNodeGetAttachmentsDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.WorkflowNodeAttachmentDto[]>(
                `/workflow/workflownode/attachment/discarded`,
                data,
                { cancelToken }
              )
            ),
        },
        addmultiple: {
          /** Add multiple new attachments with optional link to note */
          post: (data: Dtos.WorkflowNodeAttachmentCreateMultipleDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/attachment/addmultiple`, data, {
                cancelToken,
              })
            ),
        },
        downloadattachments: {
          /** Download multiple attachments for workflow node */
          post: (data: Dtos.WorkflowNodeAttachmentsDownloadDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.DownloadUrl>(`/workflow/workflownode/attachment/downloadattachments`, data, {
                cancelToken,
              })
            ),
        },
        assign: {
          /** Assign document revision as attachment */
          post: (data: Dtos.WorkflowNodeAttachmentAssignListDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/attachment/assign`, data, { cancelToken })
            ),
        },
        restore: {
          /** Restore attachment from workflow node */
          post: (data: Dtos.WorkflowNodeAttachmentRestoreDto, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/attachment/restore`, data, { cancelToken })
            ),
        },
        download: {
          /** Get download URL of workflow node attachment's blob */
          post: (
            data: Dtos.WorkflowNodeAttachmentDownloadDto,
            redirect?: boolean,
            expires?: number,
            inline?: boolean,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            !!redirect && queryParams.append('redirect', redirect.toString());
            !!expires && queryParams.append('expires', expires.toString());
            !!inline && queryParams.append('inline', inline.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.post<Dtos.DownloadUrl>(
                `/workflow/workflownode/attachment/download${constructedQuery}`,
                data,
                { cancelToken }
              )
            );
          },
        },
        downloadDerivate: {
          /** Get download URL of workflow node attachment's derivate blob */
          post: (
            data: Dtos.WorkflowNodeAttachmentDownloadDto,
            type: Dtos.BlobDerivateTypeEnum,
            redirect?: boolean,
            expires?: number,
            cancelToken?: CancelToken
          ) => {
            const queryParams = new URLSearchParams();
            queryParams.append('type', type.toString());
            !!redirect && queryParams.append('redirect', redirect.toString());
            !!expires && queryParams.append('expires', expires.toString());
            const queryResult = queryParams.toString();
            const constructedQuery = !!queryResult ? `?${queryResult}` : '';
            return to(
              baseProjectApi.post<Dtos.DownloadUrl>(
                `/workflow/workflownode/attachment/downloadDerivate${constructedQuery}`,
                data,
                { cancelToken }
              )
            );
          },
        },
      },
      confirmlock: {
        /** Confirm current user unlock (user can accept or refuse workflow node) */
        post: (data: Dtos.WorkflowNodeUnlockConfirmationDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/confirmlock`, data, { cancelToken })
          ),
      },
      delegate: {
        /** Delegate Workflow Node to other role */
        post: (data: Dtos.DelegateWorkflowNodeDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/delegate`, data, { cancelToken })
          ),
      },
      vote: {
        /** Vote workflow Node Port */
        post: (data: Dtos.WorkflowNodeVoteDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/vote`, data, { cancelToken })
          ),
      },
      addrevision: {
        /** Add revision to approval document over workflow Node */
        post: (data: Dtos.WorkflowNodeAddRevisionDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/addrevision`, data, { cancelToken })
          ),
      },
      adddocument: {
        /** Add document to approval document over workflow Node */
        post: (data: Dtos.WorkflowNodeAddDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/adddocument`, data, { cancelToken })
          ),
      },
      assigndocument: {
        /** Assign existing document to approval document over workflow Node */
        post: (data: Dtos.WorkflowNodeAssignDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/assigndocument`, data, { cancelToken })
          ),
      },
      removedocument: {
        /** Remove document from approval document over workflow Node */
        post: (data: Dtos.WorkflowNodeRemoveDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/removedocument`, data, { cancelToken })
          ),
      },
      initparallel: {
        /** Select subworkflows in parallel node */
        post: (data: Dtos.WorkflowInitParallelNode, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/workflownode/initparallel`, data, { cancelToken })
          ),
      },
    },
    document: {
      id: {
        detail: {
          /** Get list of workflows for document */
          get: (documentId: Guid, cancelToken?: CancelToken) =>
            to(
              baseProjectApi.get<Dtos.WorkflowDocumentDetailListDto>(`/workflow/document/${documentId}/detail`, {
                cancelToken,
              })
            ),
        },
      },
    },
    backgrounddocument: {
      changerequest: {
        /** Change background document request */
        patch: (data: Dtos.BackgroundDocumentPatchRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.patch<Dtos.WorkflowDto>(`/workflow/backgrounddocument/changerequest`, data, { cancelToken })
          ),
      },
      assign: {
        /** Assign existing document to background document */
        post: (data: Dtos.AssignBackgroundDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/assign`, data, { cancelToken })
          ),
      },
      confirm: {
        /** Confirm existing document to background document */
        post: (data: Dtos.ConfirmBackgroundDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/confirm`, data, { cancelToken })
          ),
      },
      accept: {
        /** Set background document as checked */
        post: (data: Dtos.AcceptBackgroundDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/accept`, data, { cancelToken })
          ),
      },
      refuse: {
        /** Refuse background document */
        post: (data: Dtos.RefuseBackgroundDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/refuse`, data, { cancelToken })
          ),
      },
      adddocument: {
        /** Add new document (that is uploaded to storage) to set directory and assign it to background document */
        post: (data: Dtos.AddBackgroundDocumentDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/adddocument`, data, { cancelToken })
          ),
      },
      addrequest: {
        /** Add background document request to workflow node */
        post: (data: Dtos.BackgroundDocumentAddRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/addrequest`, data, { cancelToken })
          ),
      },
      removerequest: {
        /** Remove background document request from workflow node */
        post: (data: Dtos.BackgroundDocumentRemoveRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/backgrounddocument/removerequest`, data, { cancelToken })
          ),
      },
    },
    instantiate: {
      /** Instatntiate workflow from template */
      post: (data: Dtos.WorkflowInstantiationDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.WorkflowDto>(`/workflow/instantiate`, data, { cancelToken })
        ),
    },
  },
  workflowtemplate: {
    /** Get list of Workflow templates */
    get: (cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<Dtos.WorkflowTemplateListDto[]>(`/workflowtemplate`, { cancelToken })
      ),
    /** Patch workflow template */
    patch: (data: Dtos.WorkflowTemplatePatchDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.patch<Dtos.WorkflowTemplateDto>(`/workflowtemplate`, data, { cancelToken })
      ),
    /** Add new workflow template */
    post: (data: Dtos.WorkflowTemplateCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.WorkflowTemplateDto>(`/workflowtemplate`, data, { cancelToken })
      ),
    id: {
      /** Remove workflow template */
      delete: (workflowTemplateId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`/workflowtemplate/${workflowTemplateId}`, { cancelToken })
        ),
      /** Get Workflow template */
      get: (workflowTemplateId: Guid, loadLastValid?: boolean, cancelToken?: CancelToken) => {
        const queryParams = new URLSearchParams();
        !!loadLastValid && queryParams.append('loadLastValid', loadLastValid.toString());
        const queryResult = queryParams.toString();
        const constructedQuery = !!queryResult ? `?${queryResult}` : '';
        return to(
          baseProjectApi.get<Dtos.WorkflowTemplateDto>(`/workflowtemplate/${workflowTemplateId}${constructedQuery}`, {
            cancelToken,
          })
        );
      },
    },
    copy: {
      /** Duplicate existing workflow template */
      post: (data: Dtos.WorkflowTemplateDuplicateDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.WorkflowTemplateDto>(`/workflowtemplate/copy`, data, { cancelToken })
        ),
    },
    import: {
      /** inport workflow templates */
      post: (data: Dtos.WorkFlowTemplateImportRequestDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.WorkflowTemplateDto>(`/workflowtemplate/import`, data, { cancelToken })
        ),
      check: {
        /** Check inported workflow templates */
        post: (data: Dtos.WorkFlowTemplateCheckRequestDto, cancelToken?: CancelToken) =>
          to(
            baseProjectApi.post<Dtos.WorkFlowTemplateCheckResponseDto>(`/workflowtemplate/import/check`, data, {
              cancelToken,
            })
          ),
      },
    },
  },
};
