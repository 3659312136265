import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import styles from './DocumentCheckbox.module.less';

type Props = {
  selected?: boolean;
  semiSelected?: boolean;
  onClickCheckbox: () => void;
  className?: string;
  tooltip?: string | ReactNode;
  disabled?: boolean;
};

export const DocumentCheckbox: FunctionComponent<Props> = ({
  selected,
  semiSelected,
  tooltip,
  onClickCheckbox,
  className,
  disabled,
}) => {
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!disabled) {
        onClickCheckbox && onClickCheckbox();
      }
    },
    [disabled, onClickCheckbox]
  );

  const content = (
    <div
      onClick={onClick}
      className={classNames(
        className,
        styles.checkbox,
        semiSelected && styles.semiSelected,
        selected && styles.selectedCheckbox,
        disabled && styles.disabled
      )}
    />
  );
  if (!!tooltip) return <CommonHubTooltip title={tooltip}>{content}</CommonHubTooltip>;
  return content;
};

export default DocumentCheckbox;