import { useBoolean } from 'hooks';
import { useProjectUrlPaths } from 'utils/urlPaths';
import { useCallback } from 'react';
import { Dispatch } from 'store';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';

export const useCreateLink = (dispatch: Dispatch, selectedDirectory: ConnectedDirectory) => {
  const [visible, show, onClose] = useBoolean(false);

  const { createDirectoryUrlPath } = useProjectUrlPaths();

  const onSubmit = useCallback(async () => {
    await dispatch.directoriesWithLinks.loadData({ reload: true });
    onClose();
  }, [onClose, createDirectoryUrlPath]);

  return { visible, show, onClose, onSubmit, selectedDirectory } as const;
};
