import { Select } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import DisplayName from 'components/DisplayName';
import Label from 'components/Label/Label';
import { useDispatchEffect } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { labelsMapSelector, labelsOrderedListSelector } from 'store/selectors/labelsSelectors';
import { ignoreRef, smartFilter } from 'utils';
import styles from './LabelsInput.module.less';

type Props = Omit<SelectProps<Guid[]>, 'filterOption' | 'loading'>;

const LabelsInputComponent: FunctionComponent<Props> = ({ defaultValue, className, ...restProps }) => {
  useDispatchEffect((dispatch) => dispatch.labels.loadData({ reload: true }), []);
  useDirtyStoreReload(
    (store) => store.labels,
    (dispatch) => dispatch.labels
  );
  const labelsMap = useSelector(labelsMapSelector);
  const labelsList = useSelector(labelsOrderedListSelector);

  const handleFilter = useCallback(
    (inputValue: string, option: DefaultOptionType): boolean => {
      const label = labelsMap?.[option.key as Guid];
      return label && smartFilter(label.name, inputValue);
    },
    [labelsMap]
  );

  const sortedValue: Guid[] =
    (defaultValue &&
      labelsList?.reduce((accu, label) => {
        if (defaultValue.indexOf(label.id) !== -1) accu.push(label.id);
        return accu;
      }, [])) ||
    [];

  return (
    <Select
      mode="multiple"
      showArrow
      allowClear
      placeholder={<Fmt id="LabelsInput.placeholder" />}
      filterOption={handleFilter}
      className={classNames(styles.select, className)}
      loading={labelsMap === null}
      defaultValue={sortedValue}
      {...restProps}
    >
      {labelsList?.map((label) => (
        <Select.Option key={label.id}>
          <Label color={label.color}>
            <DisplayName>{label.name}</DisplayName>
          </Label>
        </Select.Option>
      ))}
    </Select>
  );
};

export const LabelsInput = ignoreRef(React.memo(LabelsInputComponent));
