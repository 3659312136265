import { Radio } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import React, { ReactNode, ReactText, useCallback, useEffect, useState } from 'react';

export type ButtonsSelectOption<T> = {
  key: T;
  tooltip?: ReactNode;
  label: ReactNode;
  disabled?: boolean;
};

type Props<T> = {
  options: ButtonsSelectOption<T>[];
  defaultSelectedKey?: T;
  selectedKey?: T;
  onChange: (key: T) => void;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  disabled?: boolean;
};

export const ButtonsSelect = <T extends ReactText>({
  options,
  selectedKey: externalSelectedKey,
  defaultSelectedKey,
  onChange,
  className,
  size,
  disabled,
}: Props<T>) => {
  const [selectedKey, setSelectedKey] = useState(externalSelectedKey || defaultSelectedKey);

  useEffect(() => {
    setSelectedKey(externalSelectedKey);
  }, [externalSelectedKey]);

  const onClick = useCallback(
    (key: T) => {
      setSelectedKey(key);
      onChange(key);
    },
    [onChange]
  );

  return (
    <Radio.Group className={className} size={size} onChange={(e) => onClick(e.target.value)} value={selectedKey}>
      {options.map((option) => {
        return (
          <CommonHubTooltip title={option.tooltip || option.label} key={option.key}>
            <Radio.Button disabled={disabled || option.disabled} value={option.key}>
              {option.label}
            </Radio.Button>
          </CommonHubTooltip>
        );
      })}
    </Radio.Group>
  );
};
