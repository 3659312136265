import { PersonalReportWidgetConfiguration, SharedDashboardWidgetConfigurations } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useCallback, useState } from 'react';
import { SavedRecordTypeEnum, useSavedRecordsApi } from '../../hooks/useSavedRecordsApi';

/**
 * Manage shared report widget configurations
 */
export const useSharedReportWidgets = () => {
  const [organizationId, setOrganizationId] = useState<Guid>();

  const [
    sharedWidgets,
    sharedWidgetsError,
    sharedWidgetsLoading,
    sharedWidgetsSaving,
    saveSharedWidgets,
  ] = useSavedRecordsApi<SharedDashboardWidgetConfigurations>(
    organizationId,
    SavedRecordTypeEnum.SharedDashboardPanelConfigurations,
    undefined,
    0,
    organizationId
  );

  const loadSharedConfiguration = useCallback((organizationId: Guid) => {
    setOrganizationId(organizationId);
  }, []);

  const saveSharedConfiguration = useCallback(async (configurations: PersonalReportWidgetConfiguration[]) => {
    await saveSharedWidgets({
      configurations: configurations,
    })
  }, [saveSharedWidgets]);

  return {
    loadSharedConfiguration,
    sharedWidgetConfigurations: sharedWidgets?.data.configurations || [],
    sharedWidgetsError,
    sharedWidgetsLoading,
    sharedWidgetsSaving,
    saveSharedConfiguration
  };
};
