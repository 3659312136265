import { ForgeSearchLocationSettingsDto } from 'api/completeApiInterfaces';
import { ElementIdsType } from 'Forge/Forge';
// import Vector3 = THREE.Vector3;

export interface ColorRule {
  label: string;
  min: number | undefined;
  max: number | undefined;
  color: string; // number[]; // rgb values in range: 0-255, alpha in range: 0-1
}

export const COLORING_RULES: ColorRule[] = [
  { label: '0%', min: undefined, max: 0, color: 'rgba(170, 170, 170, 1)' },
  { label: '100%', min: 100, max: undefined, color: 'rgba(33, 255, 0, 1)' },
  { label: '(0-100)%', min: 0, max: 100, color: 'rgba(255, 140, 0, 1)' },
];

export const HOVER_COLOR_ARRAY = rgbaToVec4('rgba(69, 145, 255, 1)');

export function getColor(value: number, rules: ColorRule[]) {
  return rules.find(
    (rule) => (rule.min === undefined || rule.min <= value) && (rule.max === undefined || rule.max >= value)
  )?.color;
}

export function rgbaToVec4(color?: string): number[] {
  if (!color) return undefined;
  const parts = color
    .replace(/\s/g, '')
    .match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)
    .map((part) => +part);

  if (parts.length < 3) return undefined;
  const [r, g, b, a] = parts;
  return [...[r, g, b].map(mapChannel), a === undefined ? 1 : a];
}

export function arrayToVec4(color: number[] | undefined) {
  if (!color) return undefined;
  if (color.length !== 4) {
    throw new Error(`[${color}] is not a valid vec4`);
  }
  const [r, g, b, a] = color;
  return [...[r, g, b].map(mapChannel), a];
}

export function arrayToColor(color: number[] | undefined) {
  if (!color) return undefined;
  if (color.length !== 3 && color.length !== 4) {
    throw new Error(`[${color}] is not a valid rgb or rgba color`);
  }
  return `rgba(${color.join(',')})`;
}

function mapChannel(channel: number): number {
  return channel / 255;
}

type Property = Autodesk.Viewing.Property;

export type PropsBlacklistItem = Partial<Record<keyof Property, string>>;

const filterPropsByItem = (propsBlacklistItem: PropsBlacklistItem, prop: Property) =>
  !Object.keys(propsBlacklistItem).some((key: keyof Property) => {
    return prop[key] !== propsBlacklistItem[key];
  });

export const getFilterProps = (propsBlacklist: PropsBlacklistItem[]) => (prop: Property) =>
  !propsBlacklist.some((item) => filterPropsByItem(item, prop));

export function getElementProp(
  props: { properties: Autodesk.Viewing.Property[] },
  displayName: string,
  displayCategory?: string
): string | undefined {
  return props.properties
    .filter(
      (prop) => prop.displayName === displayName && (!displayCategory || prop.displayCategory === displayCategory)
    )
    .map((prop) => prop.displayValue + '')[0]; // HACK: + to convert to string
}

export function findElementProperty(properties: Autodesk.Viewing.Property[], paths: ForgeSearchLocationSettingsDto[]) {
  const mappedProps = paths.map((path) => getElementProp({ properties }, path.property, path.category));
  return mappedProps.find(Boolean);
}

export function findElementGlobalId(
  props: { properties: Autodesk.Viewing.Property[]; externalId?: string },
  locations: ForgeSearchLocationSettingsDto[]
) {
  return findElementProperty(props.properties, locations || []) || props.externalId;
}

export const getElementsFromDbIds = async (
  model: Autodesk.Viewing.Model,
  locations: ForgeSearchLocationSettingsDto[]
) => {
  const instanceTree = model?.getInstanceTree();

  if (!instanceTree) {
    throw new Error('getElementsFromDbIds: model or instance tree is null');
  }

  const nodeDbIds: number[] = [];
  instanceTree.enumNodeChildren(
    instanceTree.getRootId(),
    (nodeDbId) => {
      nodeDbIds.push(nodeDbId);
    },
    true
  );

  const result = nodeDbIds.flatMap(
    (nodeId) =>
      new Promise<ElementIdsType>((resolve, reject) => {
        model.getProperties(nodeId, async (props) => {
          const globalId = findElementGlobalId(props, locations);
          if (globalId) {
            resolve({ globalId, nodeId, model });
          } else {
            reject();
          }
        });
      })
  );

  return await Promise.allSettled(result);
};
