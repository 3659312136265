import { apiConstraints } from 'api/completeApiConstraints';
import { CommonEditable } from 'components/CommonEditableName/CommonEditable';
import React, { FunctionComponent } from 'react';
import { AssignmentData, AssignmentDataAction } from '../AssignmentDetail';

type Props = {
  canEdit: boolean;
  assignment: AssignmentData;
  dispatchAssignment: React.Dispatch<AssignmentDataAction>;
};

export const AssignmentNameField: FunctionComponent<Props> = ({ canEdit, assignment, dispatchAssignment }) => {
  return (
    <CommonEditable
      value={assignment.name}
      onSave={async (name) => {
        dispatchAssignment({ type: 'setName', name });
      }}
      disabled={!canEdit}
      maxLength={apiConstraints.assignmentPatchDto.name.maxLength}
      showErrorOnEmpty
    />
  );
};
