import { Col, Row } from 'antd';
import { MessageListDto, MsgStatusEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import { ListItem } from 'pages/ProjectSettingsPage/ListItem';
import React, { FunctionComponent, useCallback } from 'react';
import { MessageCategoryIcons, MessageStatus } from '../Constants';
import styles from './MessageListItem.module.less';

export enum MessageActive {
  Normal = 'Normal',
  Highlighted = 'Highlighted',
}

type Props = {
  message: MessageListDto;
  active: MessageActive;
  selectedItemsIds: Set<Guid>;
  onMessageSelect?: (id: Guid) => void;
  onCheckboxSelect?: () => void;
};

export const MessageListItem: FunctionComponent<Props> = (props) => {
  const { message, active, selectedItemsIds, onMessageSelect, onCheckboxSelect } = props;

  const intl = useIntl();

  const handleMessageClick = useCallback(() => {
    onMessageSelect && onMessageSelect(message.id);
  }, [message, onMessageSelect]);

  return (
    <ListItem onClick={handleMessageClick}>
      <StackPanel
        vertical
        className={classNames(styles.container, active === MessageActive.Highlighted && styles.active)}
      >
        <Row align={'middle'} justify={'space-between'}>
          <Col span={2}>
            <DocumentCheckbox onClickCheckbox={onCheckboxSelect} selected={selectedItemsIds.has(message.id)} />
          </Col>
          <Col span={22}>
            <div className={styles.headline}>
              <div className={classNames(styles.title, message.status === MsgStatusEnum.new && styles.highlight)}>
                {MessageCategoryIcons[message.categoryType]}
                <Fmt id={`MessageCenterPage.message.category.${message.category}`} />
              </div>
              {MessageStatus[message.status]}
            </div>
            <div className={styles.dateline}>
              <span>
                {message.finishDate && (
                  <>
                    <Fmt id={`MessageCenterPage.message.expireTime`} />{' '}
                    {moment(message.finishDate)
                      .locale(intl.locale)
                      .format('LLL')}
                  </>
                )}
              </span>
              <span className={styles.createDate}>
                {moment(message.createdDate)
                  .locale(intl.locale)
                  .format('LLL')}
              </span>
            </div>
          </Col>
        </Row>
      </StackPanel>
    </ListItem>
  );
};
