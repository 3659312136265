import { Modal } from 'antd';
import { api } from 'api';
import {
  DocumentCategoryTreeDto,
  DocumentCategoryTreeRemoveStrategyEnum,
  ServiceError,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { DocumentCategoryTreeCreateFormModal } from 'components/forms/DocumentCategoryTreeCreateForm';
import { useDeleteItems } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { messageError, processApiError, strCompareCI } from 'utils';
import CategoryList from './CategoryList';

type Props = InjectedIntlProps & {
  categoryMap: Dictionary<DocumentCategoryTreeDto>;
  categoryList: DocumentCategoryTreeDto[];
  categoryListLoading: boolean;
  categoryListError: ServiceError;
  selectedId: Guid;
  onSelect: (categoryId: Guid) => void;
  onAddOrEdit: (category: DocumentCategoryTreeDto) => void;
  onDelete: (categoryId: Guid) => void;
};

const CategoryListPanel: FunctionComponent<Props> = ({
  intl,
  categoryMap,
  categoryList,
  categoryListLoading,
  categoryListError,
  selectedId,
  onSelect,
  onAddOrEdit,
  onDelete,
}) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formModalId, setFormModalId] = useState<string>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const showModal = (id?: Guid) => {
    setFormModalId(id);
    setFormModalVisible(true);
  };
  const closeModal = () => {
    setFormModalVisible(undefined);
    setEditMode(false);
  };

  const handleSubmit = (data: DocumentCategoryTreeDto) => {
    onAddOrEdit(data);
    closeModal();
  };

  const handleEdit = (id: Guid) => {
    setEditMode(true);
    showModal(id);
  };

  const deleteConfirmCallback = useCallback(({ id }) => onDelete(id), [onDelete]);

  const [deletingItems, handleDelete] = useDeleteItems<{
    id: Guid;
    removeStrategy?: DocumentCategoryTreeRemoveStrategyEnum;
  }>(
    intl,
    async ({ id, removeStrategy }) => {
      const response = await api.project.categoryTrees.deleteCategoryTreeById(id, removeStrategy);
      const [err] = response;
      if (err) {
        processApiError(err, (e) => {
          if (e.referenceErrorCode === ServiceErrorEnum.DocumentCategoryTreeRemoveObstacleError) {
            Modal.confirm({
              title: intl.formatMessage({ id: `serviceError.DocumentCategoryTreeRemoveObstacleError` }),
              content: intl.formatMessage({ id: 'CategoryList.deleteConfirmModal.description' }),
              onOk() {
                handleDelete({ id, removeStrategy: DocumentCategoryTreeRemoveStrategyEnum.remove });
              },
              okText: intl.formatMessage({ id: 'CategoryList.deleteConfirmModal.delete' }),
              cancelText: intl.formatMessage({ id: 'forms.button.cancel' }),
            });
          } else {
            messageError(err, intl);
          }
        });
      }
      return response;
    },
    deleteConfirmCallback,
    false
  );

  const deleteCategoryItem = useCallback(
    (id: Guid) => {
      void handleDelete({ id });
    },
    [handleDelete]
  );

  const checkCategoryUniqueName = useCallback(
    (name: string) =>
      categoryList
        ? categoryList.findIndex((c) => strCompareCI(c.name, name) === 0 && (editMode ? c.id !== selectedId : true)) ===
          -1
        : true,
    [categoryList, selectedId, editMode]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const renderContent = () => {
    if (categoryListError) return <ServiceErrorBox error={categoryListError} />;
    if (categoryListLoading && !categoryList) return <SpinBox />;
    if (categoryList === null) return null;
    return (
      <CategoryList
        data={categoryList}
        search={search}
        selectedId={selectedId}
        deletingItems={deletingItems}
        onSelect={onSelect}
        onEdit={handleEdit}
        onDelete={deleteCategoryItem}
        onClearSearch={clearSearch}
      />
    );
  };

  return (
    <Panel
      panelWidth="auto"
      noMargin
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showModal}
      addButtonText={<Fmt id="Panel.addCategory.tooltip" />}
    >
      {renderContent()}
      <DocumentCategoryTreeCreateFormModal
        visible={formModalVisible}
        validateUniqueName={checkCategoryUniqueName}
        onSubmit={handleSubmit}
        onClose={closeModal}
        defaults={editMode ? categoryMap[formModalId] : null}
      />
    </Panel>
  );
};

export default injectIntl(CategoryListPanel);
