import { useCallback, useState } from 'react';

/**
 * Use state for value and a "visible" flag. Useful for modals where hiding the modal by clearing the displayed value
 * would clear the modal content while the close animation is playing, so a separate "visible" flag is needed.
 * @param initialValue Initial value
 * @param initialVisible Initial visible
 * @returns `[value, visible, setValue, hide]`
 */
export const useVisibleState = <T>(initialValue?: T | (() => T), initialVisible?: boolean | (() => boolean)) => {
  const [value, setValue] = useState<T>(initialValue);
  const [visible, setVisible] = useState<boolean>(initialVisible);

  const show = useCallback((value: React.SetStateAction<T>) => {
    setValue(value);
    setVisible(true);
  }, []);

  const hide = useCallback(() => setVisible(false), []);

  return [value, visible, show, hide] as const;
};
