import Icon from '@ant-design/icons';
import { Alert } from 'antd';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { FileXmlOutlined } from 'components/Icons';
import { Fmt } from 'locale';
import React, { useEffect, useState } from 'react';
import decode from 'unescape';
import styles from './XmlViewer.module.less';

export const XmlViewer: FileViewerComponent = ({ style, className, url, onLoad }) => {
  const [content, setContent] = useState<string>();

  useEffect(() => {
    if (url) {
      const controller = new AbortController();
      fetch(url, { signal: controller.signal })
        .then((response) => response.text())
        .then(decode)
        .then((text) => text.replaceAll('\t', '    '))
        .then(setContent)
        .then(onLoad);
      return () => controller.abort();
    }
    return undefined;
  }, [url]);

  return (
    <div style={style} className={className}>
      <ContentGate loading={!content}>
        {content && (
          <div className={styles.container}>
            <Alert message={<Fmt id="XmlViewer.copyWarning" />} banner />
            <pre className={styles.scrollable}>{content}</pre>
          </div>
        )}
      </ContentGate>
    </div>
  );
};

export const XmlViewerData: FileViewerProperties = {
  supportedTypes: ['application/xml'],
  icon: <Icon component={FileXmlOutlined} />,
  titleTranslationId: 'XmlViewer.title',
  component: 'XmlViewer',
};
