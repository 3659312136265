import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';

/**
 * There is a bug with `FormatterMessage` (`Fmt`) that it doesn't change language when in a memoized component.
 * However, it *does* change when there is a `Button` from `antd` in that component as well (or maybe other components as well).
 * So, if you use `React.memo`, check if there are any translations that are not changing instantly when the language changes,
 * and if there are, replace `React.memo` with `memoizeWithIntl`.
 *
 * Also, this "only" passes through re-rendering, if the parent component is memoized as well, then this *will not*
 * ensure that this memoized component will re-render if the parent doesn't re-render as well.
 */
export const memoizeWithIntl: <T>(component: FunctionComponent<T>) => FunctionComponent<Omit<T, 'intl'>> = (
  component
) => injectIntl(React.memo(component)) as any;
