import { Alert, Modal, Select, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { OptionProps } from 'antd/lib/select';
import { api } from 'api';
import { EsticonLinkDto, ProjectHubDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useActiveProject, useApiData, useIntl, useSameCallback, useSelectorDispatch } from 'hooks';
import { Fmt } from 'locale';
import { keyBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError, smartFilter } from 'utils';
import { AddExternAppsProps } from '../../externAppsTypes';
import styles from './EsticonCreate.module.less';

export const EsticonCreate: FunctionComponent<AddExternAppsProps> = ({ open, onAppAdded, onCancel }) => {
  const project = useActiveProject();
  const dispatch = useDispatch<Dispatch>();

  const [selectedProjectId, setSelectedProjectId] = useState<Guid>(undefined);
  const [selectedFirmId, setSelectedFirmId] = useState<Guid>(undefined);
  const [saving, setSaving] = useState<boolean>(false);

  const intl = useIntl();

  const esticonFirmsState = useSelectorDispatch(
    (state) => state.esticonFirms,
    (dispatch) => dispatch.esticonFirms.loadData({ reload: false })
  );
  const [esticonProjects, esticonProjectsError, esticonProjectsLoading, loadLinkableProjects] = useApiData((ct) => {
    return api.project.budget.getEsticonLinkableProjects(selectedFirmId, ct);
  });
  useEffect(() => selectedFirmId && loadLinkableProjects(), [selectedFirmId]);
  const availableProjects = useMemo(
    () =>
      esticonProjects?.filter((p) => p.id !== project.esticonProjectId).sort((a, b) => a.name.localeCompare(b.name)),
    [esticonProjects, project]
  );

  const projectsById = useMemo(() => keyBy(esticonProjects, 'id'), [esticonProjects]);
  const handleSearch = useCallback(
    (input: string, option: React.ReactElement<OptionProps>) =>
      smartFilter(projectsById[option.key].name, input) || smartFilter(option.key as string, input),
    [projectsById]
  );

  const firmsById = useMemo(() => keyBy(esticonFirmsState.data, 'id'), [esticonFirmsState]);
  const handleFirmSearch = useCallback(
    (input: string, option: React.ReactElement<OptionProps>) =>
      smartFilter(firmsById[option.key].nazev, input) || smartFilter(firmsById[option.key].popis, input),
    [firmsById]
  );

  const handleSubmit = useSameCallback(async () => {
    if (!!selectedFirmId && !!selectedProjectId && !saving) {
      const data: EsticonLinkDto = {
        esticonFirmId: selectedFirmId,
        esticonProjectId: selectedProjectId,
      };

      setSaving(true);
      const [err] = await api.master.projects.createEsticonProjectLink(project.id, data);
      setSaving(false);

      if (!err) {
        dispatch.activeProject.setActiveProject({
          ...project,
          esticonFirmId: selectedFirmId,
          esticonProjectId: selectedProjectId,
        });
        onAppAdded();
        return true;
      } else {
        messageError(err, intl);
        return false;
      }
    }
    return false;
  });

  const okButtonProps = useMemo<ButtonProps>(
    () => ({
      disabled: !selectedProjectId,
      loading: saving,
    }),
    [selectedProjectId, saving]
  );

  const cancelButtonProps = useMemo<ButtonProps>(
    () => ({
      loading: saving,
    }),
    [saving]
  );

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.addTitle' })}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <ContentGate error={esticonFirmsState.error}>
        <div className={styles.label}>
          <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.Database" />
        </div>
        <Select
          showSearch
          filterOption={handleFirmSearch}
          value={selectedFirmId}
          onChange={setSelectedFirmId}
          className={styles.selectProject}
          loading={esticonFirmsState.loading}
        >
          {esticonFirmsState.data?.map((esticonFirm) => (
            <Select.Option key={esticonFirm.id} value={esticonFirm.id}>
              <div className={styles.selectItem}>
                <Typography.Text ellipsis>{`${esticonFirm.nazev}`}</Typography.Text>
                <Typography.Text type="secondary">{esticonFirm.popis}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select>
        {esticonProjectsError && (
          <Alert
            type="error"
            message={
              esticonProjectsError.statusCode === 500
                ? intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError' })
                : esticonProjectsError.message
            }
          />
        )}
        {selectedFirmId && !esticonProjectsError && (
          <>
            <div className={styles.label}>
              <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.Project" />
            </div>
            <Select
              showSearch
              filterOption={handleSearch}
              value={selectedProjectId}
              onChange={setSelectedProjectId}
              className={styles.selectProject}
              loading={esticonProjectsLoading}
            >
              {availableProjects?.map((esticonProject) => (
                <Select.Option key={esticonProject.id} value={esticonProject.id}>
                  <div className={styles.selectItem}>
                    <CommonHubTooltip title={`${esticonProject.name} (${esticonProject.sign})`}>
                      <Typography.Text ellipsis>{`${esticonProject.name} (${esticonProject.sign})`}</Typography.Text>
                    </CommonHubTooltip>
                    <Typography.Text type="secondary">{esticonProject.id}</Typography.Text>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </ContentGate>
    </Modal>
  );
};

export const canLinkEsticonProject = (project: ProjectHubDto) => !project.esticonProjectId;
