import classNames from 'classnames';
import EmptyStyled from 'components/Empty/EmptyStyled';
import React, { Component, ReactNode, RefObject } from 'react';
import { AutoSizer, List, ListRowRenderer, Size } from 'react-virtualized';
import styles from './CommonDocumentsGrid.module.less';

export const DEFAULT_ROW_HEIGHT = 54;

export interface Pagination {
  pageSize: number;
  total: number;
  from: number;
  loadMore: () => void;
  loadingMore?: boolean;
}

type OwnProps<T> = {
  rowRenderer: ListRowRenderer;
  listRef: RefObject<List>;
  documents: T[];
  pagination?: Pagination;
  emptyDescription?: ReactNode;
  rowHeight?: number;
};

type Props<T> = OwnProps<T>;

class CommonDocumentsGrid<T> extends Component<Props<T>> {
  componentDidUpdate(prevProps: Readonly<Props<T>>): void {
    if (prevProps.documents !== this.props.documents) {
      this.props.listRef && this.props.listRef.current && this.props.listRef.current.forceUpdateGrid();
    }
  }

  renderList({ width, height }: Size) {
    const { documents, pagination } = this.props;
    const rowCount =
      pagination !== undefined && pagination.total > documents.length ? documents.length + 1 : documents.length;

    return (
      <List
        width={width}
        height={height}
        rowCount={rowCount}
        rowHeight={this.props.rowHeight || DEFAULT_ROW_HEIGHT}
        rowRenderer={this.props.rowRenderer}
        className={classNames(styles.list, 'scrollbar')}
        ref={this.props.listRef}
      />
    );
  }

  render() {
    const { documents, emptyDescription } = this.props;
    if (documents == null) return <div />;
    if (documents.length === 0) return emptyDescription || <EmptyStyled />;
    return (
      <div className={styles.wrapper}>
        <AutoSizer>{(props: Size) => this.renderList(props)}</AutoSizer>
      </div>
    );
  }
}

export default CommonDocumentsGrid;
