import { Layout } from 'antd';
import { ActiveProjectGate } from 'components/ActiveProjectGate/ActiveProjectGate';
import MainModuleHeader from 'components/MainModule/MainModule.Header';
import { ProjectModuleSideMenu } from 'components/ProjectModule/ProjectModuleSideMenu';
import ProjectUserGate from 'components/ProjectUserGate';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import SpinBoxCenter from 'components/SpinBoxCenter';
import WindowDisabler from 'components/WindowDisabler';
import { useStoreSelector } from 'hooks';
import React, { FunctionComponent, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { Dispatch } from 'store';
import Init from './ProjectModule.Init';
import styles from './ProjectModule.module.less';

const BuildingBudgetPage = React.lazy(() => import('pages/BuildingBudgetPage'));
const BuildingZbvPage = React.lazy(() => import('pages/BuildingZbvPage'));
const BuildingSchedulePage = React.lazy(() => import('pages/BuildingSchedulePage'));
const BuildingZbvBreakdownPage = React.lazy(() =>
  import('pages/BuildingZbvPage/BuildingZbvBreakdown/BuildingZbvBreakdownPage')
);
const BuildingInvoicingPage = React.lazy(() => import('pages/BuildingInvoicePage'));
const DocumentDetailPage = React.lazy(() => import('pages/DocumentDetailPage/DocumentDetailPageDocumentResolver'));
const FavoriteDocumentsPage = React.lazy(() => import('pages/FavoriteDocumentsPage'));
const AllDocumentsPage = React.lazy(() => import('pages/AllDocumentsPage'));
const BuildingDrawingPage = React.lazy(() => import('pages/BuildingDrawingPage'));
const MessageCenterPage = React.lazy(() => import('pages/MessageCenterPage'));
const ModelDetailPage = React.lazy(() => import('pages/ModelDetailPage/ModelDetailPage'));
const GroupedModelPage = React.lazy(() => import('pages/ModelDetailPage/GroupedModelPage'));
const CommentProcedureDetailPage = React.lazy(() =>
  import('pages/CommentProcedureDetailPage/CommentProcedureDetailPage')
);
const CommentProceduresPage = React.lazy(() => import('pages/CommentProceduresPage/CommentProceduresPage'));
const DiscardedDocumentsPage = React.lazy(() => import('pages/DiscardedDocumentsPage'));
const ProjectDashboardPage = React.lazy(() => import('pages/ProjectDashboardPage'));
const ProjectSearchPage = React.lazy(() => import('pages/ProjectSearchPage'));
const ModelPage = React.lazy(() => import('pages/ModelPage'));
const ProjectSettingsPage = React.lazy(() => import('pages/ProjectSettingsPage'));
const FiltersDocumentsPage = React.lazy(() => import('pages/FiltersDocumentsPage'));
const WorkflowListPage = React.lazy(() => import('pages/WorkflowListPage/WorkflowListPage'));
const WorkflowPage = React.lazy(() => import('pages/WorkflowDetailPage/WorkflowPage'));
const WorkflowTemplateListPage = React.lazy(() => import('pages/WorkflowTemplatePage'));
const WorkflowTemplatePage = React.lazy(() =>
  import('pages/WorkflowTemplatePage/WorkflowTemplateDetail/WorkflowTemplatePage')
);
const BuildingDrawingBreakdownPage = React.lazy(() => import('pages/BuildingDrawingPage/BuildingDrawingBreakdownPage'));
const ExternalApplicationPage = React.lazy(() => import('pages/ExternalApplicationPage/ExternalApplicationPage'));

type Props = RouteComponentProps<ProjectRouteParams>;

const ProjectModuleComponent: FunctionComponent<Props> = ({ match, location, history }) => {
  const dispatch = useDispatch<Dispatch>();
  const { sideMenuActiveKey, sideMenuCollapsed } = useStoreSelector((state) => state.appConfig);

  return (
    <ActiveProjectGate projectId={match.params.projectId} key={match.params.projectId}>
      <ProjectUserGate>
        <Layout className={styles.mainContent}>
          <MainModuleHeader match={match} location={location} history={history} showSearch />
          <Layout hasSider>
            <WindowDisabler />
            <Init />
            <ProjectModuleSideMenu
              match={match}
              location={location}
              history={history}
              sideMenuActiveItem={sideMenuActiveKey}
              sideMenuCollapsed={sideMenuCollapsed}
              setSideMenuCollapsed={dispatch.appConfig.setSideMenuCollapsed}
            />
            <Layout>
              <Layout.Content className={styles.layoutContent}>
                <Suspense fallback={<SpinBoxCenter flex className={styles.spinBox} />}>
                  <div className={styles.content}>
                    <Switch>
                      <Route path={`${match.path}`} component={ProjectDashboardPage} exact />
                      <Route path={`${match.path}/documents/filters`} component={FiltersDocumentsPage} exact />
                      <Route path={`${match.path}/documents/favorite`} component={FavoriteDocumentsPage} exact />
                      <Route path={`${match.path}/documents/discarded`} component={DiscardedDocumentsPage} exact />
                      <Route
                        path={`${match.path}/documents/:documentId/revisions/:revisionId`}
                        component={DocumentDetailPage}
                      />
                      <Route path={`${match.path}/documents/:documentId`} component={DocumentDetailPage} />
                      <Route
                        path={`${match.path}/directories/:directoryId/:action(access-request)`}
                        component={AllDocumentsPage}
                        exact
                      />
                      <Route path={`${match.path}/directories/:directoryId`} component={AllDocumentsPage} exact />
                      <Route path={`${match.path}/directoryLink/:directoryLinkId`} component={AllDocumentsPage} exact />
                      <Route path={`${match.path}/directories/`} component={AllDocumentsPage} />
                      <Route path={`${match.path}/model/`} component={ModelPage} exact />
                      <Route path={`${match.path}/groupedModel/:documentIds`} component={GroupedModelPage} exact />
                      <Route path={`${match.path}/model/:documentId`} component={ModelDetailPage} exact />
                      <Route
                        path={`${match.path}/model/:documentId/topic/:topicId`}
                        component={ModelDetailPage}
                        exact
                      />
                      <Route
                        path={`${match.path}/settings`}
                        render={(routeProps) => <ProjectSettingsPage {...routeProps} />}
                      />
                      <Route
                        exact
                        path={`${match.path}/construction/budget/focus/:focusId`}
                        component={BuildingBudgetPage}
                      />
                      <Route path={`${match.path}/construction/budget`} component={BuildingBudgetPage} />
                      <Route exact path={`${match.path}/construction/zbv/focus/:focusId`} component={BuildingZbvPage} />
                      <Route
                        path={`${match.path}/construction/zbv/:breakdownId`}
                        component={BuildingZbvBreakdownPage}
                      />
                      <Route path={`${match.path}/construction/zbv`} component={BuildingZbvPage} />
                      <Route path={`${match.path}/schedule`} component={BuildingSchedulePage} />

                      <Route
                        exact
                        path={`${match.path}/construction/invoicing/focus/:focusId`}
                        component={BuildingInvoicingPage}
                      />
                      <Route path={`${match.path}/construction/invoicing`} component={BuildingInvoicingPage} />
                      <Route
                        exact
                        path={`${match.path}/construction/drawing/focus/:focusId`}
                        component={BuildingDrawingPage}
                      />
                      <Route
                        path={`${match.path}/construction/drawing/:breakdownId`}
                        component={BuildingDrawingBreakdownPage}
                      />
                      <Route path={`${match.path}/construction/drawing`} component={BuildingDrawingPage} />
                      <Route path={`${match.path}/workflow/templates/create`} component={WorkflowTemplatePage} />
                      <Route path={`${match.path}/workflow/templates/:templateId`} component={WorkflowTemplatePage} />
                      <Route path={`${match.path}/workflow/templates`} component={WorkflowTemplateListPage} />
                      <Route path={`${match.path}/workflow/:workflowId/tasks/:nodeId`} component={WorkflowPage} exact />
                      <Route path={`${match.path}/workflow/:workflowId`} component={WorkflowPage} />
                      <Route path={`${match.path}/workflow/`} component={WorkflowListPage} />
                      <Route
                        path={`${match.path}/comment/:commentProcedureId`}
                        component={CommentProcedureDetailPage}
                      />
                      <Route path={`${match.path}/comment`} component={CommentProceduresPage} exact />
                      <Route path={`${match.path}/search`} component={ProjectSearchPage} />
                      <Route path={`${match.path}/message/:messageId`} component={MessageCenterPage} />
                      <Route path={`${match.path}/messagecenter`} component={MessageCenterPage} />
                      <Route
                        path={`${match.path}/externalapplication/:applicationId`}
                        component={ExternalApplicationPage}
                      />
                      <Route
                        render={() => (
                          <SideMenuActivator menuItemKey={SideMenuKey.EMPTY}>
                            {<div>404 Not Found</div>}
                          </SideMenuActivator>
                        )}
                      />
                    </Switch>
                  </div>
                </Suspense>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
      </ProjectUserGate>
    </ActiveProjectGate>
  );
};

export const ProjectModule = React.memo(ProjectModuleComponent);
