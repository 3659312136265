import { DEBUG } from 'config/env';

export function debugLog(...params: any[]) {
  if (DEBUG) {
    console.log('DEBUG ONLY LOG', ...params);
  }
}

export function debugError(...params: any[]) {
  if (DEBUG) {
    console.error('DEBUG ONLY ERROR', ...params);
  }
}
export function debugInfo(...params: any[]) {
  if (DEBUG) {
    console.info('DEBUG ONLY INFO', ...params);
  }
}

export function getLogWithPrefix(...prefixes: any[]) {
  return {
    debugLog: (...params: any[]) => debugLog(...prefixes, ...params),
    debugError: (...params: any[]) => debugError(...prefixes, ...params),
    debugInfo: (...params: any[]) => debugInfo(...prefixes, ...params),
  };
}
