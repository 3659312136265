import { api } from 'api';
import { BlobDerivateDto, BlobDerivateStatusEnum, BlobDerivateTypeEnum } from 'api/completeApiInterfaces';
import { derivatesContentTypeMap } from 'api/project/documents/documentsApiConstants';
import { FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { useApiData } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef } from 'react';
import { objectEntries } from 'utils/typedFunctions';
import FileViewerModal, { FileViewerModalProps, ViewerDescriptor, getComponentsByContentType } from './FileViewerModal';

const whitelistedDerivativesList: BlobDerivateTypeEnum[] = [
  BlobDerivateTypeEnum.Pdf,
  BlobDerivateTypeEnum.Forge,
  BlobDerivateTypeEnum.Xml,
  BlobDerivateTypeEnum.ProcessedXhtml,
];

const PREVIEW_DERIVATIVE_TYPE = BlobDerivateTypeEnum.ThumbnailLarge;

type Props = Omit<FileViewerModalProps, 'viewerItem' | 'isMetadata' | 'blobId' | 'viewers' | 'open' | 'visible'> & {
  uid: Guid;
  blobToken: string;
  getOriginalUrl: () => Promise<string>;
  itemsCount: number;
  itemIndex: number;
  visible: boolean;
};

function createDescriptor(
  uid: Guid,
  derivative: BlobDerivateDto,
  disabled: boolean,
  derivateType: BlobDerivateTypeEnum,
  refresh: () => void,
  component: FileViewerProperties,
  blobToken: string,
  title: ReactNode,
  isPreview?: boolean
): ViewerDescriptor {
  return {
    key: `${uid}-${derivative.url}`,
    disabled,
    title,
    derivateType,
    contentType: derivative.contentType,
    getUrl: async () => derivative.url,
    refresh,
    component: component,
    blobToken,
    derivative,
    isPreview,
  };
}

const imagePreview: FileViewerProperties = {
  component: 'PreviewImageViewer',
  icon: null,
  supportedTypes: [],
  titleTranslationId: undefined,
};

const DerivativesFileViewerModal: FunctionComponent<Props> = ({ blobToken, uid, getOriginalUrl, ...restProps }) => {
  const lastBlobDocumentId = useRef<Guid>(undefined);

  const [blobs, error, loading, loadBlob] = useApiData((ct) => {
    return api.project.blob.getBlobs({ tokens: { [uid]: blobToken } }, ct);
  });

  useEffect(() => {
    if (uid !== lastBlobDocumentId.current || !blobs?.blobs[uid]) {
      loadBlob();
      lastBlobDocumentId.current = uid;
    }
  }, [blobToken, uid]);

  const blob = useMemo(() => blobs?.blobs[uid], [blobs]);

  const viewers: ViewerDescriptor[] = useMemo<ViewerDescriptor[]>(() => {
    if (!blob) {
      return [];
    }

    const v: ViewerDescriptor[] = [];
    // first preview from large thumbnail derivative
    const derivative = blob.derivates[PREVIEW_DERIVATIVE_TYPE];
    if (derivative) {
      v.push(
        createDescriptor(
          uid,
          derivative,
          derivative.status !== BlobDerivateStatusEnum.Ok,
          PREVIEW_DERIVATIVE_TYPE,
          loadBlob,
          imagePreview,
          blobToken,
          <Fmt id="DerivativesFileViewerModal.firstPagePreview" />,
          true
        )
      );
    }

    const signatureDerivative = blob.derivates[BlobDerivateTypeEnum.Signature];
    if (signatureDerivative) {
      const components = getComponentsByContentType(signatureDerivative.contentType);
      v.push(
        createDescriptor(
          uid,
          signatureDerivative,
          signatureDerivative.status !== BlobDerivateStatusEnum.Ok,
          BlobDerivateTypeEnum.Signature,
          loadBlob,
          components.shift(),
          blobToken,
          <Fmt id="DerivativesFileViewerModal.signedDocument" />,
          true
        )
      );
    }

    // preview from other derivatives
    objectEntries(blob.derivates).forEach(([derivateType, derivative]) => {
      const type = derivateType as BlobDerivateTypeEnum; // TODO: type the Dto properly so this cast isn't needed
      if (whitelistedDerivativesList.indexOf(type) === -1) return;

      const contentType = derivatesContentTypeMap[type];
      const components = getComponentsByContentType(contentType);

      components.forEach((component) => {
        v.push(
          createDescriptor(
            uid,
            (type === BlobDerivateTypeEnum.Xml && blob.derivates[BlobDerivateTypeEnum.ContentPlainText]) || derivative, // TODO-KR fix this hack
            derivative.status !== BlobDerivateStatusEnum.Ok && type !== BlobDerivateTypeEnum.Forge,
            type as BlobDerivateTypeEnum,
            loadBlob,
            component,
            blobToken,
            component.titleTranslationId ? (
              <Fmt id={component.titleTranslationId} />
            ) : (
              <Fmt id="DerivativesFileViewerModal.preview" />
            )
          )
        );
      });
    });

    if (blob && blob.contentType && blob.contentType !== 'application/pdf') {
      const components = getComponentsByContentType(blob.contentType);

      if (components.length !== 0) {
        v.push({
          key: `${uid}-original`,
          disabled: false,
          title: (
            <>
              <Fmt id="DerivativesFileViewerModal.original" />
              {components[0].titleTranslationId && (
                <>
                  {' '}
                  (<Fmt id={components[0].titleTranslationId} />)
                </>
              )}
            </>
          ),
          derivateType: undefined,
          contentType: blob.contentType,
          getUrl: getOriginalUrl,
          component: components[0],
          blobToken,
          refresh: () => {},
          derivative: null,
        });
      }
    }

    return v;
  }, [blob, error]);

  return (
    <>
      {!!blob && (
        <FileViewerModal
          viewers={viewers}
          blobId={blob.blobId}
          isMetadata={blob.isMetadata}
          width="90vw"
          loading={loading}
          {...restProps}
        />
      )}
    </>
  );
};

export default DerivativesFileViewerModal;
