import { useInstanceStack } from 'hooks/useInstancesStack';
import { MouseEvent, useCallback, useEffect } from 'react';

export function useCloseOnEscape(
  triggerValue: boolean,
  onCancel: (event?: MouseEvent<HTMLElement> | KeyboardEvent) => void
) {
  const { isThisLastInstance } = useInstanceStack(triggerValue);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          isThisLastInstance() && onCancel && onCancel(undefined);
          break;
      }
    },
    [onCancel]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}
