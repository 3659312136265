import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ProjectUserProfileListDto, RoleDto } from 'api/completeApiInterfaces';
import { UserAvatarWithOwner } from 'components/avatars/MultiUserAvatar/UserAvatarWithOwner';
import React, { FunctionComponent, ReactNode } from 'react';
import { UserAvatarSize } from '../UserIcon/UserIcon';
import { UserTooltipDetail } from '../UserTooltipDetail/UserTooltipDetail';

export type UserAvatarProps = {
  user: ProjectUserProfileListDto | null;
  owner?: RoleDto;
  size?: UserAvatarSize;
  title?: ReactNode;
  popoverPlacement?: TooltipPlacement;
};

const UserAvatarComponent: FunctionComponent<UserAvatarProps> = ({
  user,
  size = UserAvatarSize.Medium,
  popoverPlacement = 'top',
  title,
  owner,
}) => {
  return (
    <Popover
      trigger="click"
      arrowPointAtCenter
      placement={popoverPlacement}
      title={title}
      content={<UserTooltipDetail user={user} />}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <UserAvatarWithOwner user={user} size={size} documentOwner={owner} clickable />
      </div>
    </Popover>
  );
};

export const UserAvatar = React.memo(UserAvatarComponent);
