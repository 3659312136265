import { SortAscendingOutlined } from '@ant-design/icons';
import { Button, Layout, MenuProps, Modal } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { api } from 'api';
import { FavoriteProjectListDto, ProjectOrderDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DashboardTile from 'components/DashboardTile/DashboardTile';
import { DragIdentifier } from 'components/DragTile/DragTile';
import EmptySimple from 'components/EmptySimple';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import ProjectsGrid from 'components/ProjectsGrid/ProjectsGrid';
import ReportsGrid from 'components/Reports/ReportsGrid/ReportsGrid';
import DashboardReportsContextProvider from 'components/Reports/contexts/ReportWidgetsContextProvider';
import DashboardReportTile from 'components/Reports/dashboard/DashboardReportTile';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { ProjectCreateFormModal } from 'components/forms/ProjectCreateForm';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useBoolean, useCurrentAppUser, useDispatchEffect, useSameCallback, useStoreSelector } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { messageError } from 'utils';
import { numberComparer, textComparer } from 'utils/comparators';
import styles from './MainDashboard.module.less';

const orderByPosition = numberComparer
  .map((project: FavoriteProjectListDto) => project.setPossition)
  .andThen(textComparer.map((project: FavoriteProjectListDto) => project.name));

type Props = InjectedIntlProps;

const MainDashboardComponent: FunctionComponent<Props> = ({ intl }) => {
  const [projectCreateModalVisible, showProjectCreateModal, hideProjectCreateModal] = useBoolean(false);
  const [infoModalText, setInfoModalText] = useState<JSX.Element>(null);

  const [organizationsAdminReport, organizationsAdminReportError, organizationsAdminReportLoading] = useApiData(
    (ct) => api.master.organization.getOrganizationsAdminReport(ct),
    {
      autoload: true,
    }
  );

  useDispatchEffect((dispatch) => dispatch.favoriteProjects.loadData({ reload: true }), []);
  const currentAppUser = useCurrentAppUser();

  const adminOrganizations = useMemo(
    () => organizationsAdminReport?.organizations.filter((org) => org.isOrganizationAdmin),
    [organizationsAdminReport]
  );

  const projectsState = useStoreSelector((state) => state.favoriteProjects);
  const [orderedList, setOrderedList] = useState<FavoriteProjectListDto[]>([]);
  useEffect(() => {
    if (projectsState.data) {
      setOrderedList(projectsState.data?.filter((project) => project.isFavorite).sort(orderByPosition));
    }
  }, [projectsState.data]);

  const setNewOrder = useSameCallback(async (newOrder: FavoriteProjectListDto[]) => {
    const reordered = newOrder.map((project, index): FavoriteProjectListDto => ({ ...project, setPossition: index }));

    setOrderedList(reordered);

    const updateData = newOrder.map((project, index): ProjectOrderDto => ({ id: project.id, order: index }));
    const [err] = await api.master.projects.setProjectOrder(updateData);
    if (err) {
      messageError(err, intl);
    }
  });

  const handleSortAlphabetically = useSameCallback(() => {
    const newOrder = (projectsState.data || []).sort(textComparer.map((project) => project.name));
    setNewOrder(newOrder);
  });

  const removeProject = useSameCallback(async (projectId: Guid) => {
    const newOrder = (orderedList || []).filter((project) => project.id !== projectId);
    setNewOrder(newOrder);

    const [err] = await api.master.projects.deleteFromFavoriteProjects([projectId]);
    if (err) {
      messageError(err, intl);
    }
  });

  const onMove = useSameCallback((from: DragIdentifier, to: DragIdentifier) => {
    const newOrder = orderedList ? [...orderedList] : [];
    newOrder.splice(to.itemId, 0, ...newOrder.splice(from.itemId, 1));
    setNewOrder(newOrder);
  });

  const currentUserName = useMemo(() => currentAppUser?.username, [currentAppUser]);

  const userOrganizations = useMemo(
    () => currentAppUser.organizationUsers?.map((userOrg) => userOrg.organization) || [],
    [currentAppUser]
  );

  const menu: MenuProps = useMemo(() => {
    const items: ItemType[] = [
      {
        key: 'createNewProject',
        label: intl.formatMessage({ id: 'general.createNewProject' }),
        icon: <AddIcon />,
        disabled: !currentAppUser.isAdmin || !adminOrganizations?.length,
        onClick: showProjectCreateModal,
      },
      {
        key: 'sortProjectsAZ',
        label: intl.formatMessage({ id: 'ProjectsListPage.sortProjectsAZ' }),
        icon: <SortAscendingOutlined />,
        onClick: handleSortAlphabetically,
      },
    ];
    return { items };
  }, [intl, currentAppUser, showProjectCreateModal, handleSortAlphabetically, adminOrganizations]);

  return (
    <ErrorBoundary>
      <SideMenuActivator menuItemKey={SideMenuKey.DASHBOARD}>
        <Layout className={styles.scrollableContent}>
          <DashboardTile title={<Fmt id="ProjectsListPage.projects" />} menu={menu}>
            <ContentGate
              error={projectsState.error}
              loading={projectsState.loading}
              empty={!orderedList?.length}
              emptyAlternative={
                <EmptySimple description={<Fmt id="MainDashboard.noSelectedProjects" />}>
                  <div className={styles.projectsButton}>
                    <Link to="/projects">
                      <Button type="primary" size="large">
                        <Fmt id="MainDashboard.viewAllProjects" />
                      </Button>
                    </Link>
                  </div>
                </EmptySimple>
              }
            >
              {orderedList && <ProjectsGrid projectList={orderedList} onMove={onMove} onRemove={removeProject} />}
            </ContentGate>
          </DashboardTile>
          <DashboardReportsContextProvider organizations={userOrganizations}>
            <DashboardReportTile classNames={styles.reportContainer}>
              <ReportsGrid />
            </DashboardReportTile>
          </DashboardReportsContextProvider>
        </Layout>
        <ProjectCreateFormModal
          visible={projectCreateModalVisible}
          onSubmit={hideProjectCreateModal}
          onClose={hideProjectCreateModal}
          organizations={adminOrganizations}
          setInfoModalText={setInfoModalText}
          currentUserName={currentUserName}
        />

        <Modal
          title={<Fmt id="general.newProject" />}
          open={!!infoModalText}
          onOk={() => setInfoModalText(null)}
          onCancel={() => setInfoModalText(null)}
          cancelButtonProps={HIDE_BUTTON_PROPS}
        >
          {infoModalText}
        </Modal>
      </SideMenuActivator>
    </ErrorBoundary>
  );
};

export const MainDashboard = memoizeWithIntl(MainDashboardComponent);
