import { UserOutlined } from '@ant-design/icons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';

type Props = {
  userCount: number;
};

const GroupUserCountComponent: FunctionComponent<Props> = ({ userCount }) => {
  const values = useMemo(() => ({ count: userCount }), [userCount]);

  return (
    <FlowLayout>
      <UserOutlined />
      <Fmt id={!!userCount ? 'GroupUserCount.userCount' : 'GroupUserCount.noUsers'} values={values} />
    </FlowLayout>
  );
};

export const GroupUserCount = React.memo(GroupUserCountComponent);
