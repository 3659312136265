import { InputRef } from 'antd';
import { AccessLevelEnum, ProjectTemplateDirectoryDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';
import { ProjectTemplateDirectoryForm, ProjectTemplateDirectoryFormData } from './ProjectTemplateDirectoryForm';

type Props = FormModalProps<ProjectTemplateDirectoryDto> & {
  editedDirectory?: ProjectTemplateDirectoryDto;
  validateUniqueName: (name: string) => boolean;
  adminGroupId: Guid;
  selectedParentId: Guid;
};

const ProjectTemplateDirectoryFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedDirectory, adminGroupId, selectedParentId, validateUniqueName, ...restProps } = props;

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  const handleSubmit: FormSubmitHandler<ProjectTemplateDirectoryFormData> = async (values) => {
    onSubmit({
      id: editedDirectory?.id || uuid(),
      name: values.name,
      description: values.description,
      permissionInheritance: editedDirectory?.permissionInheritance || true,
      projectTemplateDirectoryCategoryNodeIds: editedDirectory?.projectTemplateDirectoryCategoryNodeIds || [],
      projectTemplateGroupPermMap: editedDirectory?.projectTemplateGroupPermMap || {
        [adminGroupId]: AccessLevelEnum.admin,
      },
      parentId: !!editedDirectory ? editedDirectory.parentId : selectedParentId,
    });
    return null;
  };

  const isDirectoryEditing = !!editedDirectory;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isDirectoryEditing
          ? 'ProjectTemplateDirectoryFormModal.edit.title'
          : 'ProjectTemplateDirectoryFormModal.add.title'
      }
      submitTextId={isDirectoryEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <ProjectTemplateDirectoryForm
          intl={intl}
          wrappedComponentRef={formRef}
          defaults={editedDirectory}
          validateUniqueName={validateUniqueName}
          autofocusRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectTemplateDirectoryFormModal;
