import { Alert, message, Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ForgeSearchLocationSettingsDto } from 'api/completeApiInterfaces';
import SpinBox from 'components/SpinBox';
import { ForgeByAttributesFilter } from 'Forge/ForgeByAttributesFilter/ForgeByAttributesFilter';
import { GlobalOffsetType } from 'Forge/ForgeCamera';
import { FORGE_VIEWER_VERSION } from 'Forge/ForgeViewerOptions';
import { getMeasurementsExtensionOptions } from 'Forge/measurements/getMeasurementsExtensionOptions';
import { Fmt, InjectedIntlProps } from 'locale';
import { csMessages, IntlMessageId } from 'locale/messages/cs';
import { enMessages } from 'locale/messages/en';
import { isEqual } from 'lodash';
import React, { Component, MouseEvent, MutableRefObject } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { throttle } from 'utils';
import { getLogWithPrefix } from 'utils/debugLog';
import { implicitCast } from 'utils/implicitCast';
import { loadCss } from 'utils/loadCss';
import { loadScript } from 'utils/loadScript';
import styles from './Forge.module.less';
import { checkModelsConsistency } from './ForgeConsistencyWarning';
import {
  ForgeModelInfoContext,
  ForgeModelInfoContextValueType,
} from './ForgeModelInfoContext/ForgeModelInfoContextProvider';
import {
  COLORING_RULES,
  findElementGlobalId,
  findElementProperty,
  getColor,
  getElementsFromDbIds,
  getFilterProps,
  HOVER_COLOR_ARRAY,
  PropsBlacklistItem,
  rgbaToVec4,
} from './ForgeUtils';

const { debugError, debugInfo } = getLogWithPrefix('__FORGE__');

const AUTODESK_SETTINGS_PREFIX = 'Autodesk.Viewing.Private.GuiViewer3D.SavedSettings.';
const SETTING_SHOW_HOVER = 'ShowHover';
const LOAD_MODEL_MAX_TRIES = 3;
const PROPERTIES_BLACKLIST: PropsBlacklistItem[] = [{ displayName: 'GLOBALID' }];

function findElementName(
  props: { properties: Autodesk.Viewing.Property[]; name?: string },
  locations: ForgeSearchLocationSettingsDto[]
) {
  return findElementProperty(props.properties, locations || []) || props.name;
}

const getBubbleNodeName = (node: Autodesk.Viewing.BubbleNode): string => {
  if ('intermediateFile' in node.data) {
    // TODO: better name generation
    return (node.data as any).intermediateFile.replace(/output\/Resource\/Výkres\//, '');
  }
  return node.name();
};

export interface IToken {
  access_token: string;
  expires_in: number;
  token_type: 'Bearer';
}

export type NamedForgeModel = {
  urn: string;
  documentId?: Guid;
  documentName?: string;
  documentExtension?: string;
};

type LoadingModel = NamedForgeModel & {
  retries: number;
  cancelled: boolean;
};

export type LoadedModel = {
  availableBubbles2d: Autodesk.Viewing.BubbleNode[];
  availableBubbles3d: Autodesk.Viewing.BubbleNode[];
  model?: Autodesk.Viewing.Model;
};

export type ForgeElementParams = {
  globalId: string | undefined;
  model: Autodesk.Viewing.Model;
  elementName: string | undefined;
  properties: Autodesk.Viewing.Property[];
};

export type ElementIdsType = {
  globalId: string;
  nodeId: number;
  model: Autodesk.Viewing.Model;
};

type ElementsByGlobalIdMap = Record<string, ElementIdsType>;

export enum ForgeDisplayMode {
  Mode2d = 'Mode2d',
  Mode3d = 'Mode3d',
}

export type ForgeSelectedElementWithSpentPercent = { id: string; spentPercent: number };

export interface ForgeSelectHoverProps {
  selectedModels?: Guid[];
  onSelectModels?: (models: React.SetStateAction<Guid[]>) => void;
  selectedElements?: Guid[];
  isolatedElements?: ForgeSelectedElementWithSpentPercent[];
  onSelectElements?: (elements: React.SetStateAction<string[]>, elementData: ForgeElementParams[]) => void;
  hoveredModels?: Guid[];
  onHoverModels?: (models: React.SetStateAction<Guid[]>) => void;
  hoveredElements?: ForgeSelectedElementWithSpentPercent[];
  onHoverElements?: (elements: React.SetStateAction<string[]>, elementData: ForgeElementParams[]) => void;
  coloredElements?: ForgeSelectedElementWithSpentPercent[];
}

export interface ForgeProps extends ForgeSelectHoverProps {
  models: NamedForgeModel[];
  getForgeToken: () => IToken;
  onDocumentLoadFailure?: (viewerErrorCode: Autodesk.Viewing.ErrorCodes, finalTry: boolean) => void;
  onForgeLoad?: () => void;
  hiddenModels?: Set<Guid>; // document ids
  mode: ForgeDisplayMode;
  setMode: React.Dispatch<React.SetStateAction<ForgeDisplayMode>>;
  enableModeAutodetect: boolean;
  setEnableModeAutodetect: React.Dispatch<React.SetStateAction<boolean>>;
  bubbleIndex: number; // for multiple models (BubbleNodes) in a single document
  setBubbleIndex: React.Dispatch<React.SetStateAction<number>>;
  viewerRef?: MutableRefObject<Autodesk.Viewing.Viewer3D>;
  onGeometryLoaded?: () => void;
}

const mapStateToProps = (state: RootState) => ({
  appSettings: state.appSettings.data,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

interface Props extends ForgeProps, PropsFromState, InjectedIntlProps {}

interface State {
  loading: boolean;
  error?: boolean;
  lastProgressRatio: number;
  showNotFoundElementsError?: boolean;
  rerender: []; // oh no, this is bad, but we need to request rerender manually
}

class Forge extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    mode: ForgeDisplayMode.Mode3d,
  };

  static contextType = ForgeModelInfoContext;

  _isMounted = false;
  viewer: Autodesk.Viewing.GuiViewer3D = null;
  forgeContainerRef: React.RefObject<any>;
  notFoundElementsErrorsCount: number = 0;
  dimensions: { w: number; h: number };

  hoverHighlightEnabled = true;
  isUsingSharedCoordinates = false;

  // loading of models
  loadingQueue: LoadingModel[] = [];
  currentlyLoadedModel: LoadingModel = null;
  loadedModels: LoadedModel[] = [];

  selectedDbIds: number[] = undefined;

  // cache for efficiency
  elementsByGlobalId: ElementsByGlobalIdMap = {};
  lastHoveredModel: Guid; // do not re-fire the "on-hover" event

  /*
   * CONTRACT:
   * loadedModels has models that are loaded into Forge viewer, but might not have InstanceTree ready
   * currentlyLoadingModel will contain the currently loaded model until it is added to loadedModels
   * loadingQueue will contain models to be loaded, it can contain the same model as currentlyLoadingModel if it is cancelled
   */

  //#region Component lifecycle
  constructor(props: Props) {
    super(props);
    this.forgeContainerRef = React.createRef();
    this.dimensions = { w: 0, h: 0 };
    this.notFoundElementsErrorsCount = 0;
    this.state = {
      loading: true,
      lastProgressRatio: 0.0,
      showNotFoundElementsError: false,
      rerender: [],
    };

    this.handleModelsChange([], props.models);
    this.loadStoredCustomSettings();
  }

  getElementName = (data: Autodesk.Viewing.PropertyResult) => {
    return findElementName(data, this.props.appSettings.forgeSearchLocaltionsName);
  };

  loadStoredCustomSettings = () => {
    const showHoverExistingVal = window.localStorage[AUTODESK_SETTINGS_PREFIX + SETTING_SHOW_HOVER];
    if (showHoverExistingVal !== undefined) {
      this.hoverHighlightEnabled = JSON.parse(showHoverExistingVal);
    }
  };

  colorElementsByDrawing = () => {
    if (this._isMounted) {
      if (!this.props.coloredElements) {
        this.clearElementsColor();
      } else {
        this.setElementsColor(this.props.coloredElements, null);
      }
    }
  };

  geometryLoadedEvent = async (event: any) => {
    debugInfo('--> GEOMETRY_LOADED_EVENT');
    if (this._isMounted) {
      setTimeout(() => {
        this.colorElementsByDrawing();
      }, 1000);
      this.colorElementsByHover();
      if (this.allModelsLoaded()) {
        checkModelsConsistency(this.loadedModels, this.props.intl, this.isUsingSharedCoordinates);
      }
      this.props.onGeometryLoaded && this.props.onGeometryLoaded();
      const modelInfo = this.context as ForgeModelInfoContextValueType;
      modelInfo?.setGeometryLoaded(true);
    }
  };

  addOptionsToSettings = () => {
    debugInfo('--> TOOLBAR_CREATED_EVENT');
    const panel = this.viewer?.getSettingsPanel();
    panel?.addCheckbox(
      'appearance',
      implicitCast<IntlMessageId>('Forge.viewing.showHoverOption.name'),
      implicitCast<IntlMessageId>('Forge.viewing.showHoverOption.description'),
      this.hoverHighlightEnabled,
      (checked: boolean) => {
        this.hoverHighlightEnabled = checked;
        if (!checked) {
          // clear current models
          this.props.hoveredModels?.map((id) => this.setModelColor(this.getModelByDocumentId(id), null));
        } else {
          // show current models
          const hoverColor = new window.THREE.Vector4(...HOVER_COLOR_ARRAY); // cannot be created as a "global" const NOR a class variable
          this.props.hoveredModels?.map((id) => this.setModelColor(this.getModelByDocumentId(id), hoverColor));
        }
      },
      SETTING_SHOW_HOVER
    );
  };

  modelAdded = (event: any) => {
    debugInfo('--> MODEL_ADDED_EVENT');
  };

  colorElementsByHover = () => {
    const elementHoverColor = new window.THREE.Vector4(...HOVER_COLOR_ARRAY); // cannot be created as a "global" const NOR a class variable
    this.clearElementsColor();
    this.setElementsColor(this.props.coloredElements, null);

    const notFoundElementsCount = this.setElementsColor(this.props.hoveredElements, elementHoverColor);
    if (
      !!notFoundElementsCount &&
      this.props.hoveredElements &&
      this.props.hoveredElements.length > 0 &&
      this._isMounted
    ) {
      this.setState({ showNotFoundElementsError: true });
      this.notFoundElementsErrorsCount = this.notFoundElementsErrorsCount + 1;
      setTimeout(this.hideNotFoundElementsError, 2000);
    }
  };

  isolateElements = (prevProps: Props) => {
    const { isolatedElements } = this.props;
    if (this.allModelsLoaded() && prevProps.isolatedElements !== isolatedElements) {
      this.setIsolateElements(isolatedElements);
    }
  };

  selectElements = (prevProps: Props) => {
    const { selectedElements } = this.props;
    if (this.allModelsLoaded() && prevProps.selectedElements !== selectedElements) {
      this.setSelectedElements(selectedElements);
    }
  };

  initializeLanguage() {
    window.Autodesk?.Viewing.i18n.addResourceBundle('cs', 'allstrings', csMessages);
    window.Autodesk?.Viewing.i18n.addResourceBundle('en', 'allstrings', enMessages);
    void this.handleLanguageChange();
  }

  public async componentDidMount() {
    this._isMounted = true;
    if (!window.Autodesk) {
      void loadCss(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${FORGE_VIEWER_VERSION}/style.min.css`
      );
      await loadScript(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${FORGE_VIEWER_VERSION}/viewer3D.min.js`
      );
      await import('./extensions/CustomPropertiesExtension');
      await import('./extensions/VolumeSurfaceExtension');
    }

    if (!this.viewer) {
      this.launchViewer();
    }

    const modelInfo = this.context as ForgeModelInfoContextValueType;
    modelInfo?.setForgeElementPropertiesCallback(this.getPropertiesOfElements);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.models !== prevProps.models) {
      this.handleModelsChange(prevProps.models, this.props.models);
    }

    if (this.props.hiddenModels !== prevProps.hiddenModels) {
      this.handleVisibilityChange();
    }

    if (this.props.intl !== prevProps.intl) {
      void this.handleLanguageChange();
    }

    if (this.viewer && this.viewer.impl) {
      if (
        this.forgeContainerRef.current &&
        (this.forgeContainerRef.current.offsetHeight !== this.dimensions.h ||
          this.forgeContainerRef.current.offsetWidth !== this.dimensions.w)
      ) {
        this.dimensions = {
          h: this.forgeContainerRef.current.offsetHeight,
          w: this.forgeContainerRef.current.offsetWidth,
        };
        this.viewer.resize();
      }

      const modelHoverColor = new window.THREE.Vector4(...HOVER_COLOR_ARRAY); // cannot be created as a "global" const NOR a class variable
      if (this.allModelsLoaded() && prevProps.hoveredModels !== this.props.hoveredModels) {
        prevProps.hoveredModels?.forEach((documentId) =>
          this.setModelColor(this.getModelByDocumentId(documentId), null)
        );
        this.props.hoveredModels?.forEach((documentId) =>
          this.setModelColor(this.getModelByDocumentId(documentId), modelHoverColor)
        );
      }
      if (this.allModelsLoaded() && this.props.coloredElements !== prevProps.coloredElements) {
        this.colorElementsByDrawing();
      }
      if (this.allModelsLoaded() && prevProps.hoveredElements !== this.props.hoveredElements) {
        this.colorElementsByHover();
      }
      this.isolateElements(prevProps);
      this.selectElements(prevProps);
    }
  }

  hideNotFoundElementsError = () => {
    this.notFoundElementsErrorsCount = this.notFoundElementsErrorsCount - 1;
    if (this.notFoundElementsErrorsCount === 0 && this._isMounted) {
      this.setState({ showNotFoundElementsError: false });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.viewer) {
      this.viewer.tearDown();
      this.viewer.finish();
      this.viewer = null;
      window.Autodesk.Viewing.shutdown(); // TODO: what does this do?
    }
    if (this.currentlyLoadedModel) {
      this.currentlyLoadedModel.cancelled = true;
    }
    this.onMouseMove.cancel();
  }

  //#endregion

  //#region Helper functions
  allModelsLoaded = () => {
    return !!this.viewer?.impl && this.currentlyLoadedModel === null;
  };

  getModelByDocumentId = (id: Guid) =>
    this.loadedModels.find((model) => model.model?.getData().documentId === id)?.model;
  //#endregion

  //#region Props changed "effects"
  handleModelsChange = (oldModels: NamedForgeModel[], newModels: NamedForgeModel[]) => {
    // first, remove now unused models from the queue
    for (let i = this.loadingQueue.length - 1; i >= 0; i--) {
      if (!newModels.some((newModel) => newModel.documentId === this.loadingQueue[i].documentId)) {
        this.loadingQueue.splice(i, 1); // remove the element at i-th possition
      }
    }

    // second, cancel loading of the currently loaded model if it is no longer needed
    if (
      this.currentlyLoadedModel !== null &&
      !newModels.some((newModel) => newModel.documentId === this.currentlyLoadedModel.documentId)
    ) {
      this.currentlyLoadedModel.cancelled = true;
    }

    // third, unload now unused models
    for (let i = this.loadedModels.length - 1; i >= 0; i--) {
      if (!newModels.some((newModel) => newModel.documentId === this.loadedModels[i].model?.getData().documentId)) {
        this.clearElementsByGlobalId(this.loadedModels[i].model);
        (this.viewer.impl as any).unloadModel(this.loadedModels[i]?.model);
        this.loadedModels.splice(i, 1); // remove the element at i-th possition
      }
    }

    // finally, queue new models
    newModels
      .filter((newModel) => !oldModels.some((oldModel) => oldModel.documentId === newModel.documentId))
      .forEach((newModel) => this.loadingQueue.push({ ...newModel, retries: 0, cancelled: false }));

    // afterward, queue processing of new models if needed
    void this.loadDocumentsSequentially();
  };

  getPropertiesOfElements = async (globalIds: string[]) => {
    const results = await Promise.allSettled(
      globalIds.map((globalId) => {
        const element = this.elementsByGlobalId[globalId];
        return new Promise<ForgeElementParams>((resolve, reject) =>
          element.model.getProperties(
            element.nodeId,
            (data) =>
              resolve({
                globalId: findElementGlobalId(data, this.props.appSettings.forgeSearchLocaltionsId),
                model: element.model,
                elementName: findElementName(data, this.props.appSettings.forgeSearchLocaltionsName),
                properties: data.properties,
              }),
            reject
          )
        );
      })
    );

    return results.map((r) => r.status === 'fulfilled' && r.value).filter(Boolean);
  };

  handleVisibilityChange = () => {
    if (!this.viewer) {
      return;
    }

    const hiddenModels = this.props.hiddenModels || new Set([]);

    this.viewer
      .getVisibleModels()
      .filter((model) => hiddenModels.has(model.getData().documentId))
      .forEach((model) => this.viewer.hideModel(model));

    ((this.viewer as unknown) as { getHiddenModels: () => Autodesk.Viewing.Model[] })
      .getHiddenModels()
      .filter((model) => !hiddenModels.has(model.getData().documentId))
      .forEach((model) => this.viewer.showModel(model, true));
  };

  handleLanguageChange = async () => {
    const i18n = window.Autodesk?.Viewing.i18n;
    await i18n?.reloadResources([this.props.intl.locale]);
    await i18n?.changeLanguage(this.props.intl.locale);
    i18n?.localize();
  };
  //#endregion

  //#region Map fillers
  clearElementsByGlobalId = (model: Autodesk.Viewing.Model) => {
    Object.entries(this.elementsByGlobalId).forEach(([key, pair]) => {
      if (pair.model === model) {
        delete this.elementsByGlobalId[key];
      }
    });
  };

  fillElementsByGlobalId = async (model: Autodesk.Viewing.Model) => {
    try {
      const elements = await getElementsFromDbIds(model, this.props.appSettings.forgeSearchLocaltionsId);
      if (!this._isMounted && !!elements) return;

      const elementsByGlobalId: ElementsByGlobalIdMap = { ...this.elementsByGlobalId };
      elements.forEach((value) => {
        if (value.status === 'fulfilled') {
          const item = value.value;
          elementsByGlobalId[item.globalId] = item;
        }
      });

      this.elementsByGlobalId = elementsByGlobalId;
      const elementsByGlobalIdSet = new Set(Object.keys(elementsByGlobalId));

      const modelInfo = this.context as ForgeModelInfoContextValueType;
      modelInfo?.setExistingElementIds(elementsByGlobalIdSet);
      debugInfo('fillElementsByGlobalId', { elementsIds: elements, elementsByGlobalId });
    } catch (error) {
      debugError('fillElementsByGlobalId error', error);
    }
  };
  //#endregion

  //#region Mouse hover
  onMouseMove = throttle((screenPoint: { x: number; y: number }) => {
    if (!this.viewer) {
      return;
    }
    const n = this.normalize(screenPoint);
    const hitResult = this.getHitDbId(n.x, n.y);
    const documentId: Guid = hitResult?.model.getData().documentId;
    if (documentId !== this.lastHoveredModel) {
      this.props.onHoverModels && this.props.onHoverModels([documentId]);
    }
    this.lastHoveredModel = documentId;
  }, 100);

  handleMouseMove = (event: MouseEvent) => {
    if (!this.props.onHoverModels && !this.props.onHoverElements) {
      return; // don't waste time computing the ray hit
    }
    const screenPoint = {
      x: event.clientX,
      y: event.clientY,
    };
    this.onMouseMove(screenPoint);
  };

  // This is a built-in method getHitPoint, but the original returns
  // the hit point, so this modified version returns the dbId
  getHitDbId = (x: number, y: number) => {
    y = 1.0 - y;
    x = x * 2.0 - 1.0;
    y = y * 2.0 - 1.0;

    const vpVec = new window.THREE.Vector3(x, y, 1);

    return this.viewer.impl.hitTestViewport(vpVec, false);
  };

  // originally wrote by Philippe
  normalize = (screenPoint: { x: number; y: number }) => {
    const viewport = this.viewer.navigation.getScreenViewport();
    return {
      x: (screenPoint.x - viewport.left) / viewport.width,
      y: (screenPoint.y - viewport.top) / viewport.height,
    };
  };
  //#endregion

  onObjectTreeCreatedEvent = async (result: any) => {
    const models = this.viewer.getAllModels();
    debugInfo('--> OBJECT_TREE_CREATED_EVENT', result, models);

    for (const model of models) {
      await this.fillElementsByGlobalId(model);
    }
  };

  onAggregatedSelectionEvent = async (aggregatedSelection: {
    selections: { dbIdArray: number[]; fragIdsArray: string[]; model: Autodesk.Viewing.Model; nodeArray: number[] }[];
  }) => {
    debugInfo('--> AGGREGATE_SELECTION_CHANGED_EVENT');

    const { onSelectElements, selectedElements } = this.props;

    const newSelectedDbIds = aggregatedSelection.selections?.flatMap((selection) => selection.dbIdArray) || [];

    if (
      newSelectedDbIds.length === this.selectedDbIds?.length &&
      (newSelectedDbIds.length === 0 || isEqual(newSelectedDbIds, this.selectedDbIds))
    ) {
      return;
    }

    debugInfo('New selected DbIds', newSelectedDbIds, 'old DbIds', this.selectedDbIds);

    this.selectedDbIds = newSelectedDbIds;

    if (!onSelectElements) {
      debugError('NO onSelectElements Callback set');
      return;
    }

    const paramPromises = aggregatedSelection.selections.flatMap((selection) => {
      const model = selection.model;
      return selection.dbIdArray.map(
        (dbId) =>
          new Promise<ForgeElementParams>((resolve, reject) =>
            model.getProperties(
              dbId,
              (data) => {
                const globalId = findElementGlobalId(data, this.props.appSettings.forgeSearchLocaltionsId);
                if (!globalId) {
                  reject();
                }
                resolve({
                  globalId: globalId,
                  model: model,
                  elementName: findElementName(data, this.props.appSettings.forgeSearchLocaltionsName),
                  properties: data.properties,
                });
              },
              reject
            )
          )
      );
    });

    const results = await Promise.allSettled(paramPromises);
    const values = results.map((r) => r.status === 'fulfilled' && r.value);

    // Throw onSelectElements event only if values has some non-undefined values (existing some globalIds - only if model has IFC elements ids od externalId)
    const definedValues = values.filter(Boolean);
    if (values.length && definedValues.length === 0) return;

    if (definedValues?.length && selectedElements?.length === definedValues?.length) {
      if (!selectedElements?.some((element) => !definedValues.some((value) => element === value.globalId))) {
        return;
      }
    }

    const definedGlobalIds = definedValues.map((param) => param.globalId);

    debugInfo('found selected elements globalIds', definedGlobalIds);

    onSelectElements(definedGlobalIds, definedValues);
  };

  //#region Setting color
  setModelColor = (model: Autodesk.Viewing.Model, color: THREE.Vector4) => {
    if (!this.viewer || !model) {
      return;
    }

    this.viewer.clearThemingColors(model);

    const instanceTree = model?.getInstanceTree();
    if (!color || !instanceTree || !this.hoverHighlightEnabled) {
      return;
    }

    instanceTree.enumNodeChildren(
      instanceTree.getRootId(),
      (node) => {
        this.viewer.setThemingColor(node, color, model);
      },
      true
    );
  };

  setElementsColor = (elements: { id: string; spentPercent: number }[], color: THREE.Vector4): number | undefined => {
    if (!this.viewer || !elements?.length) {
      return undefined;
    }
    if (color !== null && !this.hoverHighlightEnabled) {
      return undefined;
    }
    let notFoundElementsCount = 0;
    elements.forEach((elementData) => {
      if (!elementData) return;
      const element = this.elementsByGlobalId[elementData.id];
      const spentColor = rgbaToVec4(getColor(elementData.spentPercent, COLORING_RULES));
      if (element) {
        element.model.setThemingColor(
          element.nodeId,
          color || (spentColor ? new window.THREE.Vector4(...spentColor) : null),
          true
        );
      } else if (color !== null) {
        notFoundElementsCount++;
      }
    });
    this.viewer.impl.invalidate(true);
    return notFoundElementsCount;
  };

  clearElementsColor = () => {
    if (!this.viewer) return;
    this.loadedModels.forEach((model) => this.viewer.clearThemingColors(model.model));
    this.viewer.impl.invalidate(true);
  };

  setIsolateElements = (elements: { id: string; spentPercent: number }[]): void => {
    if (!this.viewer || !elements) return;
    const nodesIds = elements.map((element) => this.elementsByGlobalId[element.id]?.nodeId).filter((i) => !!i);
    this.viewer.isolate(nodesIds);
  };

  setSelectedElements = (elements: Guid[]): void => {
    if (!this.viewer || !elements) return;
    const nodesIds = elements.map((id) => this.elementsByGlobalId[id]?.nodeId).filter(Boolean);
    debugInfo('setSelectedElements', { elements, nodesIds, globalElements: { ...this.elementsByGlobalId } });

    if (!nodesIds.length && elements.length !== nodesIds.length) {
      debugInfo('setSelectedElements: no elements was found, but some requested');
      return;
    }
    this.viewer.select(nodesIds);
    this.selectedDbIds = nodesIds;
  };
  //#endregion

  onLoadingModelsDone = () => {
    try {
      this.viewer.fitToView();
    } catch (ex) {
      debugError(ex);
    }
  };

  loadSingleModel = (loadingModel: LoadingModel) =>
    new Promise<void>((resolve, reject) => {
      Autodesk.Viewing.Document.load(
        `urn:${loadingModel.urn}`,
        (viewerDocument) => {
          viewerDocument.downloadAecModelData().then(() => {
            this.onDocumentLoadSuccess(viewerDocument, loadingModel.documentName).then((model) => {
              if (!this._isMounted) {
                reject();
                return;
              } else {
                this.setState({ lastProgressRatio: 0 });
                if (loadingModel.cancelled && model.model) {
                  (this.viewer?.impl as any)?.unloadModel(model.model);
                } else {
                  if (model.model) {
                    (model.model as any).myData.documentId = loadingModel.documentId;
                    (model.model as any).myData.documentName = loadingModel.documentName;
                  }
                  this.loadedModels.push(model);
                  this.setState({ rerender: [] });
                }
                resolve();
              }
            }, reject);
          }, reject);
        },
        (viewerErrorCode: Autodesk.Viewing.ErrorCodes) => {
          if (!this._isMounted) {
            reject();
            return;
          }
          loadingModel.retries++;
          this.onDocumentLoadFailure(viewerErrorCode, loadingModel);
          if (loadingModel.retries < LOAD_MODEL_MAX_TRIES) {
            this.loadingQueue.push(loadingModel);
          }
          resolve();
        }
      );
    });

  loadDocumentsSequentially = async () => {
    if (this.currentlyLoadedModel !== null || !this.viewer) {
      // already running or forge isn't loaded yet - don't run
      return;
    }

    while (this.loadingQueue.length > 0) {
      const [namedModel] = this.loadingQueue.splice(0, 1); // pop first element
      this.currentlyLoadedModel = namedModel;
      await this.loadSingleModel(namedModel);
      this.handleVisibilityChange();
    }

    this.currentlyLoadedModel = null;
    this.onLoadingModelsDone();
  };

  lastScaling: string | null = null;
  firstModelOffset: GlobalOffsetType = undefined;

  onDocumentLoadSuccess = (viewerDocument: Autodesk.Viewing.Document, modelName: string): Promise<LoadedModel> => {
    const availableBubbles2d = viewerDocument?.getRoot()?.search({ role: '2d', type: 'geometry' }) || [];
    const availableBubbles3d = viewerDocument?.getRoot()?.search({ role: '3d', type: 'geometry' }) || [];

    const bubbleNode = viewerDocument.getRoot().getDefaultGeometry();
    const aecModelData = bubbleNode.getAecModelData();

    let matrix4: THREE.Matrix4 = undefined;
    const tf = aecModelData && aecModelData.refPointTransformation;
    if (aecModelData && tf) {
      matrix4 = new THREE.Matrix4()
        .makeBasis(
          new THREE.Vector3(tf[0], tf[1], tf[2]),
          new THREE.Vector3(tf[3], tf[4], tf[5]),
          new THREE.Vector3(tf[6], tf[7], tf[8])
        )
        .setPosition(new THREE.Vector3(tf[9], tf[10], tf[11]));
      this.isUsingSharedCoordinates = true;
    }

    if (this.props.enableModeAutodetect) {
      if (availableBubbles3d.length > 0) {
        this.props.setEnableModeAutodetect(false); // confirm default choice of 3d
      } else if (availableBubbles2d.length > 0) {
        this.props.setMode(ForgeDisplayMode.Mode2d);
        this.props.setEnableModeAutodetect(false); // override mode to 2d
        return Promise.resolve({ availableBubbles2d, availableBubbles3d }); // force skip processing of model, forge will unmount anyway
      }
    }

    const availableModels = this.props.mode === ForgeDisplayMode.Mode2d ? availableBubbles2d : availableBubbles3d;

    if (!availableModels?.length) {
      void message.error(
        this.props.intl.formatMessage({ id: 'Forge.cannotViewInMode' }, { name: modelName, mode: this.props.mode })
      );
      return Promise.resolve({ availableBubbles2d, availableBubbles3d });
    }

    if (this.props.bubbleIndex >= availableModels.length) {
      this.props.setBubbleIndex(0);
      return Promise.resolve({ availableBubbles2d, availableBubbles3d }); // force skip processing of model, forge will unmount anyway
    }

    if (!this.viewer) {
      return Promise.resolve({ availableBubbles2d, availableBubbles3d });
    }

    return this.viewer
      .loadDocumentNode(viewerDocument, availableModels[this.props.bubbleIndex], {
        preserveView: false,
        keepCurrentModels: true,
        globalOffset: this.firstModelOffset,
        placementTransform: matrix4,
        applyScaling: this.lastScaling ? this.lastScaling : undefined,
        modelNameOverride: modelName,
        applyRefpoint: true,
      })
      .then((model) => {
        this.firstModelOffset = this.firstModelOffset || model.getData()?.globalOffset;
        debugInfo('Loaded model', {
          model,
          offset: model.getData()?.globalOffset,
          firstModelOffset: this.firstModelOffset,
          unitString: model.getUnitString(),
          displayUnit: model.getDisplayUnit(),
          lastScaling: this.lastScaling,
        });
        this.lastScaling = model.getUnitString() || model.getDisplayUnit();
        return {
          availableBubbles2d,
          availableBubbles3d,
          model,
        };
      });
  };

  onDocumentLoadFailure = (viewerErrorCode: Autodesk.Viewing.ErrorCodes, loadingModel: LoadingModel) => {
    const { onDocumentLoadFailure } = this.props;
    onDocumentLoadFailure && onDocumentLoadFailure(viewerErrorCode, loadingModel.retries === LOAD_MODEL_MAX_TRIES);
    if (loadingModel.retries === LOAD_MODEL_MAX_TRIES) {
      void message.error(
        this.props.intl.formatMessage({ id: 'Forge.loadModelError' }, { name: loadingModel.documentName }),
        10
      );
    }
  };
  //#endregion

  //#region Initialization
  initializeViewer = () => {
    if (this.viewer) {
      debugError('Failed to create a Viewer: Already created.');
      return;
    }
    const config = {
      extensions: ['CustomPropertiesExtension'],
      filterProps: getFilterProps(PROPERTIES_BLACKLIST),
    };
    const viewerContainer = this.forgeContainerRef.current;
    const Autodesk = window.Autodesk;
    this.viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainer, config);
    this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onAggregatedSelectionEvent);
    this.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreatedEvent);
    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.geometryLoadedEvent);
    this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.addOptionsToSettings);
    this.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.modelAdded);

    const modelInfo = this.context as ForgeModelInfoContextValueType;
    modelInfo?.setGeometryLoaded(false);

    if (this.props.viewerRef) {
      this.props.viewerRef.current = this.viewer;
    }

    this.viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT);
    const startedCode = this.viewer.start();
    if (!!this.viewer.prefs) this.viewer.prefs.displayUnitsPrecision = 3;

    this.initializeLanguage();

    this.configureMeasurementExtension();

    if (startedCode > 0) {
      debugError('Failed to create a Viewer: WebGL not supported.');
      return;
    }

    void this.loadDocumentsSequentially();
  };

  configureMeasurementExtension = () => {
    const options = getMeasurementsExtensionOptions(this.props.intl, this.viewer, this.getElementName);
    void this.viewer.loadExtension('VolumeSurfaceExtension', options);
  };

  launchViewer = () => {
    this.props.onForgeLoad && this.props.onForgeLoad();
    if (this._isMounted) {
      this.setState({ loading: false });
      const options = {
        env: 'AutodeskProduction',
        api: 'derivativeV2_EU',
        loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
        getAccessToken: this.handleTokenRequested,
        language: this.props.intl.locale,
      };
      const Autodesk = window.Autodesk;
      if (Autodesk !== undefined) {
        Autodesk.Viewing.Initializer(options, this.initializeViewer);
      }
    }
  };

  handleTokenRequested = (onAccessToken: (accessToken: string, expires: number) => void) => {
    if (onAccessToken) {
      const token = this.props.getForgeToken();
      if (token) onAccessToken(token.access_token, token.expires_in);
    }
  };
  //#endregion

  handleModeChange = (e: RadioChangeEvent) => {
    this.props.setEnableModeAutodetect(false);
    this.props.setMode(e.target.value);
    this.props.setBubbleIndex(0);
  };

  handleSetBubbleNodeId = (value: number) => {
    this.props.setBubbleIndex(value);
  };

  render() {
    const totalProgress = (this.loadedModels.length + this.state.lastProgressRatio) / this.props.models.length;

    const availableBubbles2d = this.loadedModels[0]?.availableBubbles2d;
    const availableBubbles3d = this.loadedModels[0]?.availableBubbles3d;

    const display3dButton = this.props.mode === ForgeDisplayMode.Mode3d || !!availableBubbles3d?.length;
    const display2dButton = this.props.mode === ForgeDisplayMode.Mode2d || !!availableBubbles2d?.length;

    const availableBubbles =
      this.loadedModels.length === 1 &&
      (this.props.mode === ForgeDisplayMode.Mode2d ? availableBubbles2d : availableBubbles3d);

    return (
      <>
        {this.state.error ? (
          <div className={styles.centeredContent}>
            <Alert className={styles.errorMessageWrap} type="error" message={<Fmt id="Forge.loadingError" />} />
          </div>
        ) : this.state.loading ? (
          <SpinBox fill spinning={this.state.loading}>
            <Fmt id="Forge.loading" />
          </SpinBox>
        ) : (
          <div className={styles.ForgeViewer}>
            <div ref={this.forgeContainerRef} onMouseMove={this.handleMouseMove} />
            <div className={styles.overlayContainer}>
              <ForgeByAttributesFilter
                viewer={this.viewer}
                key={this.currentlyLoadedModel?.documentId + this.viewer?.id}
              />
              <div>
                {display2dButton && display3dButton && (
                  <Radio.Group value={this.props.mode} onChange={this.handleModeChange}>
                    <Radio.Button value={ForgeDisplayMode.Mode2d}>2D</Radio.Button>
                    <Radio.Button value={ForgeDisplayMode.Mode3d}>3D</Radio.Button>
                  </Radio.Group>
                )}
              </div>
              {availableBubbles && (
                <Select
                  value={this.props.bubbleIndex}
                  onChange={this.handleSetBubbleNodeId}
                  className={styles.bubbleNodesSelect}
                >
                  {availableBubbles.map((availableBubble, index) => (
                    <Select.Option key={index} value={index}>
                      {getBubbleNodeName(availableBubble)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
            {this.props.models.length >= 2 && !!this.currentlyLoadedModel && (
              <div className={styles.loadbarOuter}>
                <div className={styles.loadbarMiddle}>
                  <div className={styles.loadbarInner} style={{ width: totalProgress * 100 + '%' }} />
                </div>
              </div>
            )}
            {this.state.showNotFoundElementsError && (
              <Alert
                message={<Fmt id="forge.warning.notFoundElements" />}
                showIcon
                type="warning"
                className={styles.notFoundElementsError}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(injectIntl(Forge));
