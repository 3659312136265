import { Table } from 'antd';
import { EntityTypesEnum } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import { breakTableValue } from './ParamsTable';

export interface DoubleParams {
  key: string;
  name: string;
  before: string;
  after: string;
}

type Props = InjectedIntlProps & {
  params: DoubleParams[];
  entityName: EntityTypesEnum;
};

const DoubleParamsTable: FunctionComponent<Props> = ({ params, entityName, intl }) => {
  const columns = [
    {
      title: intl.formatMessage({ id: `AuditLog.EntityTypeParam.${entityName}` }),
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => breakTableValue(value),
    },
    {
      title: intl.formatMessage({ id: 'AuditLog.ParamsTable.OriginValue' }),
      dataIndex: 'before',
      key: 'before',
      render: (value: string) => breakTableValue(value),
    },
    {
      title: intl.formatMessage({ id: 'AuditLog.ParamsTable.NewValue' }),
      dataIndex: 'after',
      key: 'after',
      render: (value: string) => breakTableValue(value),
    },
  ];
  return <Table size="small" pagination={false} dataSource={params} columns={columns} />;
};

export default injectIntl(DoubleParamsTable);
