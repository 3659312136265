import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './GeneralSettingsItemsGroup.module.less';

export type ListItemProps = {
  children?: ReactNode;
  className?: string;
};

type Props = ListItemProps;

const GeneralSettingsItemsGroup: FunctionComponent<Props> = ({ children, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.items)}>{children}</div>
    </div>
  );
};

export default React.memo(GeneralSettingsItemsGroup);
