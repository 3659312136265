import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { api } from 'api';
import { FavoriteDocumentsResultsDto } from 'api/completeApiInterfaces';
import { DownloadZipIcon, ShareIcon, ShowGroupedModelIcon } from 'components/Icons/HubActionsIcons';
import { ModelOnIcon } from 'components/Icons/HubEntitiesIcons';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import { useActiveProject, useFavoriteButton } from 'hooks';
import { compact } from 'lodash';
import ShowFavoriteSettingResponseModal from 'pages/AllDocumentsPage/ShowFavoriteSettingResponseModal';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paramEncode } from 'utils';

type Props = {
  favoriteDisabled?: boolean;
  downloadDisabled?: boolean;
  shareDownloadDisabled?: boolean;
  selectedFilesIds: Set<Guid>;
  onDocumentsDownload: () => void;
  onShareDownload?: () => void;
  onChangeFavorites?: () => void;
  handleRemoveFromModels?: () => void;
  onBulkAddToModels?: () => void;
  onBulkRemoveFromModels?: () => void;
};

const FileToolbar: FunctionComponent<Props> = ({
  downloadDisabled,
  shareDownloadDisabled,
  favoriteDisabled,
  onDocumentsDownload,
  onShareDownload,
  selectedFilesIds,
  onChangeFavorites,
  handleRemoveFromModels,
  onBulkAddToModels,
  onBulkRemoveFromModels,
}) => {
  const [areFavorite, setAreFavorite] = useState(false);
  const [favoriteResponse, setFavoriteResponse] = useState<FavoriteDocumentsResultsDto>(undefined);

  const handleOnChangeFavorites = useCallback(
    (isFavorite: boolean, response: FavoriteDocumentsResultsDto) => {
      setAreFavorite(!areFavorite);
      setFavoriteResponse(response);
      onChangeFavorites && onChangeFavorites();
    },
    [onChangeFavorites, areFavorite]
  );

  useEffect(() => {
    setAreFavorite(false);
  }, [selectedFilesIds]);

  const selectedFavoritesIds = useMemo(() => {
    return Array.from(selectedFilesIds);
  }, [selectedFilesIds]);

  const addFavoriteCallback = useCallback(() => {
    return api.project.documents.addFavoriteDocuments(selectedFavoritesIds);
  }, [selectedFavoritesIds]);

  const removeFavoriteCallback = useCallback(() => {
    return api.project.documents.removeFavoriteDocuments(selectedFavoritesIds);
  }, [selectedFavoritesIds]);

  const favoriteButton = useFavoriteButton(
    'favorite',
    selectedFavoritesIds[0],
    areFavorite,
    addFavoriteCallback,
    removeFavoriteCallback,
    handleOnChangeFavorites,
    undefined,
    undefined,
    favoriteDisabled
  );

  const history = useHistory();
  const project = useActiveProject();

  const onShowGroupedModel = useCallback(() => {
    history.push(`/projects/${project.id}/groupedModel/${paramEncode(Array.from(selectedFilesIds))}`);
  }, [history, project, selectedFilesIds]);

  const bulkModelsActions = useMemo((): ToolbarItemProps => {
    if (!onBulkAddToModels || !onBulkRemoveFromModels) return null;
    return {
      icon: <ModelOnIcon />,
      label: 'general.docMenu.bulkEdit.modelsSetting',
      key: 'modelSetting',
      disabled: !selectedFilesIds.size,
      subItems: [
        {
          icon: <PlusCircleOutlined />,
          label: 'general.docMenu.bulkEdit.modelsSetting.add',
          onClick: onBulkAddToModels,
          key: 'modelSettingAdd',
        },
        {
          icon: <MinusCircleOutlined />,
          label: 'general.docMenu.bulkEdit.modelsSetting.remove',
          onClick: onBulkRemoveFromModels,
          key: 'modelSettingRemove',
        },
      ],
    };
  }, [onBulkAddToModels, onBulkRemoveFromModels, selectedFilesIds.size]);

  const removeFromModels = useMemo((): ToolbarItemProps => {
    if (!handleRemoveFromModels) return null;
    return {
      key: 'removeFromModels',
      icon: <ModelOnIcon />,
      label: 'ModelPage.removeFromModels',
      onClick: handleRemoveFromModels,
    };
  }, [handleRemoveFromModels]);

  const items: ToolbarItemProps[] = useMemo(
    () =>
      compact([
        {
          key: 'download',
          icon: <DownloadZipIcon />,
          label: 'general.docMenu.downloadInZip',
          disabled: downloadDisabled,
          onClick: onDocumentsDownload,
        },
        favoriteButton,
        {
          key: 'share',
          icon: <ShareIcon />,
          label: 'general.docMenu.shareDownload',
          disabled: shareDownloadDisabled,
          onClick: onShareDownload,
        },
        {
          key: 'showGroupedModel',
          icon: <ShowGroupedModelIcon />,
          label: 'ModelPage.showGroupedModel',
          disabled: selectedFilesIds.size < 2,
          onClick: onShowGroupedModel,
        },
        bulkModelsActions,
        removeFromModels,
      ]),
    [
      downloadDisabled,
      onDocumentsDownload,
      favoriteButton,
      shareDownloadDisabled,
      onShareDownload,
      selectedFilesIds.size,
      onShowGroupedModel,
      bulkModelsActions,
      removeFromModels,
    ]
  );

  return (
    <>
      <ShrinkableToolbar items={items} />
      <ShowFavoriteSettingResponseModal favoriteResponse={favoriteResponse} setFavoriteResponse={setFavoriteResponse} />
    </>
  );
};

export default FileToolbar;
