import { Modal, Typography } from 'antd';
import { InjectedIntl, InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = InjectedIntlProps & {
  extension: string;
};

const ForgeCompareWarning: FunctionComponent<Props> = ({ intl, extension }) => {
  return (
    <div>
      <Typography.Title level={4}>
        {intl.formatMessage(
          {
            id: `Forge.warning.compare.extension`,
          },
          { extension }
        )}
      </Typography.Title>
      <Typography.Text>
        {intl.formatMessage({
          id: `Forge.warning.compare.turnOffProperties`,
        })}
      </Typography.Text>
    </div>
  );
};

export const displayCompareWarning = (extension: string, intl: InjectedIntl) => {
  Modal.warning({
    title: intl.formatMessage({ id: 'Forge.warning.compare.title' }),
    content: <ForgeCompareWarning extension={extension} intl={intl} />,
    width: 500,
  });
};
