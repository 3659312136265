import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import { ModelOnIcon } from 'components/Icons/HubEntitiesIcons';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './DocumentRow.Name.module.less';

export type DocumentRowProps = {
  name: string;
  nameLink?: string;
  hasOnClick?: boolean;
  activeProjectId: Guid;
  tooltip?: ReactNode;
  isModel?: boolean;
};

export const DocumentRowName: FunctionComponent<DocumentRowProps> = ({
  name,
  tooltip,
  nameLink,
  hasOnClick,
  activeProjectId,
  isModel,
}) => {
  return (
    <CommonHubTooltip title={tooltip || name} placement="right">
      {!!nameLink && !hasOnClick ? (
        <Link className={styles.name} to={nameLink.replace('{projectId}', activeProjectId)}>
          <DisplayName>
            {name} {isModel ? <ModelOnIcon /> : null}
          </DisplayName>
        </Link>
      ) : (
        <DisplayName className={styles.name}>
          {name} {isModel ? <ModelOnIcon /> : null}
        </DisplayName>
      )}
    </CommonHubTooltip>
  );
};

export default DocumentRowName;
