import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { useCurrentProjectUser, useDispatchEffect, useSameCallback } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { useEffect, useRef } from 'react';
import { InjectedIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';

const setUpMenuButtons = (instance: WebViewerInstance) => {
  const isFullscreen = instance.UI.isFullscreen();
  instance.UI.setHeaderItems((headers) => {
    const defaultHeader = headers.getHeader('default');
    defaultHeader.delete('fullscreenButton');
    defaultHeader.push({
      type: 'actionButton',
      img: isFullscreen ? 'icon-header-full-screen-exit' : 'icon-header-full-screen',
      onClick: () => {
        instance.UI.toggleFullScreen();
      },
      dataElement: 'fullscreenButton',
      title: isFullscreen ? 'action.exitFullscreen' : 'action.enterFullscreen',
    });
  });
};

export const usePdfTron = (licenseKey: string, intl: InjectedIntl, onLoaded?: () => void) => {
  const containerRef = useRef();
  const webViewerInstanceRef = useRef<WebViewerInstance>();
  const currentProjectUser = useCurrentProjectUser();
  const allUsers = useSelector(projectUsersListSelector);

  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const setUserDisplayMap = useSameCallback(() => {
    if (webViewerInstanceRef.current) {
      const { annotationManager } = webViewerInstanceRef.current.Core;
      annotationManager.setAnnotationDisplayAuthorMap((annotationUsername) => {
        const existingUser = allUsers?.find((user) => user.username === annotationUsername);
        return existingUser?.firstname || existingUser?.lastname
          ? [existingUser?.firstname, existingUser?.lastname].join(' ')
          : annotationUsername;
      });
    }
  });

  const setLanguage = useSameCallback(() => {
    if (webViewerInstanceRef.current) {
      webViewerInstanceRef.current.UI.setLanguage(intl.locale);
    }
  });
  useEffect(() => {
    setLanguage();
  }, [intl.locale]);

  useEffect(() => {
    void WebViewer(
      {
        fullAPI: true,
        path: '/lib/webviewer',
        annotationUser: currentProjectUser.username,
      },
      containerRef.current
    ).then(async (instance) => {
      webViewerInstanceRef.current = instance;
      await instance.Core.PDFNet.initialize(licenseKey);
      setLanguage();
      setUpMenuButtons(instance);
      instance.UI.addEventListener(instance.UI.Events.FULLSCREEN_MODE_TOGGLED, () => {
        setTimeout(() => setUpMenuButtons(instance), 500);
        setTimeout(() => setUpMenuButtons(instance), 1000);
      });
      setUserDisplayMap();
      onLoaded && onLoaded();
      return instance;
    });
  }, []);

  return [containerRef, webViewerInstanceRef] as const;
};
