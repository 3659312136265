import { Button, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { masterApi } from 'api/completeApi';
import { ProjectUserInvitationDto, ServiceError } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { Link } from 'components/Link/Link';
import { Margin } from 'components/Margin/Margin';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { Fmt, InjectedIntlProps } from 'locale';
import queryString from 'query-string';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { processApiError } from 'utils';
import { getProjectUrlPath } from 'utils/urlPaths';
import styles from './ProjectInvitationAcceptPage.module.less';

type Props = InjectedIntlProps & RouteComponentProps;

const ProjectInvitationAcceptPage: FunctionComponent<Props> = ({ intl, location }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ServiceError>();
  const [projectId, setProjectId] = useState<Guid>();
  const [invitation, setInvitationData] = useState<ProjectUserInvitationDto>();

  useEffect(() => {
    setLoading(true);
    const values = queryString.parse(location.search);

    if (
      !values.projectId ||
      typeof values.projectId !== 'string' ||
      !values.email ||
      typeof values.email !== 'string' ||
      !values.code ||
      typeof values.code !== 'string'
    ) {
      setError({
        apiVersion: null,
        requestId: null,
        statusCode: null,
        message: null,
        referenceErrorCode: ServiceErrorEnum.BadRequestError,
        details: null,
        validationErrors: null,
      });
      setLoading(false);
      return;
    }
    const projectId: string = values.projectId;
    const code: string = values.code;
    const email: string = values.email;

    setProjectId(projectId);

    masterApi.projects.invitation.id.param.param.get(projectId, code, email).then(
      (response) => {
        const [err, invitation] = response;
        if (err) {
          setError(processApiError(err));
          setLoading(false);
          return;
        }

        setInvitationData(invitation.data);
        setLoading(false);
      },
      () => {
        setError({
          apiVersion: null,
          requestId: null,
          statusCode: null,
          message: null,
          referenceErrorCode: ServiceErrorEnum.BadRequestError,
          details: null,
          validationErrors: null,
        });
        setLoading(false);
      }
    );
  }, [location]);

  const renderInvitation = useCallback(
    (invitation: ProjectUserInvitationDto) => (
      <StackPanel vertical justifyContent="centerContent" centerItems>
        <Typography.Text strong>
          <Fmt id="ProjectInvitationAcceptPage.invitationsDeprecated" />
        </Typography.Text>
        <Margin top bottom>
          <Typography.Text>
            {intl.formatMessage({ id: 'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitation' })}
          </Typography.Text>
        </Margin>
        <Typography.Text className={styles.projectName}>
          <Title level={2}>
            <strong>{invitation.projectName}</strong>
          </Title>
          {invitation.projectDescription && <br /> && <i>{invitation.projectDescription}</i>}
        </Typography.Text>
        <Margin top>
          <Link to={getProjectUrlPath(projectId)}>
            <Button type="primary">
              <Fmt id="ProjectInvitationAcceptPage.goToProject" />
            </Button>
          </Link>
        </Margin>
      </StackPanel>
    ),
    [projectId, intl]
  );

  const invitationNotFound = error?.referenceErrorCode === ServiceErrorEnum.ProjectUserInvitationNotFoundError;

  return (
    <div className={styles.wrap}>
      <div className={styles.box}>
        <Typography.Title className={styles.title} level={2}>
          {error ? (
            <Fmt id="ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationError" />
          ) : (
            <Fmt id="ProjectInvitationAcceptPage.acceptAllTermsAndConditions.mainInvitation" />
          )}
        </Typography.Title>
        {loading ? (
          <SpinBox />
        ) : error ? (
          invitationNotFound ? (
            <Typography.Text className={styles.info}>
              <Fmt id="ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationNotFound" />
            </Typography.Text>
          ) : (
            <ServiceErrorBox error={error} />
          )
        ) : (
          renderInvitation(invitation)
        )}
      </div>
    </div>
  );
};

export default injectIntl(ProjectInvitationAcceptPage);
