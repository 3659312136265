import { OnContextMenuPreparingType } from 'components/BudgetGrid/BudgetGrid';
import { dxTreeListNode } from 'devextreme/ui/tree_list';
import React from 'react';
import { InjectedIntl } from 'react-intl';

const getAllChildrenKeys = (currentNodeChilds: dxTreeListNode[]) => {
  let result: Guid[] = [];
  for (let i = 0; i < currentNodeChilds?.length; i++) {
    const item = { ...currentNodeChilds[i] };
    result = [...result, item.hasChildren ? item.key : null, ...getAllChildrenKeys(item.children)];
  }
  return result.filter((i) => i !== null) as Guid[];
};

export const addContextMenuItemsSetExpandKeys = (
  e: OnContextMenuPreparingType,
  setContextMenuExpandKeys: React.Dispatch<React.SetStateAction<Guid[]>>,
  expandedKeys: Guid[],
  intl: InjectedIntl
) => {
  if (e.target !== 'header' && !!e.row) {
    if (!e.items) e.items = [];
    if (e.row.node.hasChildren) {
      const rowChildrenKeys = getAllChildrenKeys(e.row.node.children);
      const anyKeysToExpand = rowChildrenKeys.concat(e.row.key).filter((key) => !expandedKeys.includes(key));
      if (!!anyKeysToExpand.length) {
        e.items.push({
          text: intl.formatMessage({ id: 'contextMenuExpandCollapseUtils.expand' }),
          icon: 'expand',
          onItemClick: () => {
            setContextMenuExpandKeys(rowChildrenKeys.concat(e.row.key));
          },
        });
      }
      if (e.row.isExpanded) {
        e.items.push({
          text: intl.formatMessage({ id: 'contextMenuExpandCollapseUtils.collapse' }),
          icon: 'collapse',
          onItemClick: () => {
            e.component.collapseRow(e.row.key);
          },
        });
      }
    }
  }
};
