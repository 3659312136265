import { Alert, ButtonProps, Input, Modal, message } from 'antd';
import { projectApi } from 'api/completeApi';
import { RevisionDto } from 'api/completeApiInterfaces';
import { mapUploadProcessDataToSecondaryDocumentAdd } from 'api/project/upload/uploadHelpers';
import { UploadData, UploadFile, UploadFileType } from 'api/project/upload/uploadManager';
import { Margin } from 'components/Margin/Margin';
import { SignatureDataWithPageData } from 'components/PdfSignature/PdfSignatureModal';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { useIntl, useIsMounted, useSameCallback } from 'hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'uuid';
import signingScript, { PythonErrorEnum, pythonErrorTranslations } from './localSigner.python';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  visible: boolean;
  signatureData: SignatureDataWithPageData;
  documentUrl: string;
  documentId: Guid;
  revisionId: Guid;
  workflowNodeId?: Guid;
  signedFileName: string;
};

const CERTIFICATE_FILE_TYPES = ['.pfx', '.p12'];

const CALLBACKS: any = {};

enum SigningStates {
  'initializing' = 'initializing',
  'signingDocument' = 'signingDocument',
  'uploadingDocument' = 'uploadingDocument',
}

const LocalSigningModal: FunctionComponent<Props> = (props) => {
  const {
    onClose,
    onSubmit,
    visible,
    signatureData,
    documentUrl,
    documentId,
    revisionId,
    workflowNodeId,
    signedFileName,
    ...restProps
  } = props;
  const intl = useIntl();
  const [pdfFile, setPdfFile] = useState<Blob>();
  const [certFile, setCertFile] = useState<File>();
  const [certPassword, setCertPassowrd] = useState<string>('');
  const [signing, setSigning] = useState<boolean>(false);
  const [signingError, setSigningError] = useState<IntlMessageId>();
  const pyodideWorkerRef = useRef<Worker>();
  const signingBgImageRef = useRef<Blob>();
  const signingFontRef = useRef<Blob>();
  const signingFontBoldRef = useRef<Blob>();
  const isMounted = useIsMounted();
  const [signingStatus, setSigningStatus] = useState<SigningStates>(SigningStates.initializing);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (passwordVisible) {
      timeout = setTimeout(() => {
        setPasswordVisible(false);
      }, 3000);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [passwordVisible]);

  const { lastUploadManager, error, loading, submitTextId, uploadProgress, resetAll, startUpload } = useFileUpload();

  const handleUploadFinish = useCallback(async (): Promise<void> => {
    await onSubmit();
    setSigning(false);
    setCertPassowrd('');
    setCertFile(undefined);
    resetAll();
  }, [onSubmit, resetAll]);

  const saveStampBlob = useSameCallback((blob: Blob) => {
    if (isMounted) signingBgImageRef.current = blob;
  });

  const saveFontBlob = useSameCallback((blob: Blob) => {
    if (isMounted) signingFontRef.current = blob;
  });

  const saveFontBoldBlob = useSameCallback((blob: Blob) => {
    if (isMounted) signingFontBoldRef.current = blob;
  });

  const saveSigningPdfBlob = useSameCallback((blob: Blob) => {
    if (isMounted) setPdfFile(blob);
  });

  useEffect(() => {
    if (!visible) {
      setCertPassowrd('');
      setCertFile(undefined);
      return () => {};
    }
    pyodideWorkerRef.current = new Worker(`${process.env.PUBLIC_URL}/lib/pyodide/pyodide.worker.js`);

    pyodideWorkerRef.current.onmessage = (event) => {
      const { id, ...data } = event.data;
      const onSuccess = CALLBACKS[id];
      delete CALLBACKS[id];
      onSuccess(data);
    };

    return () => {
      if (pyodideWorkerRef.current) {
        pyodideWorkerRef.current.terminate();
        pyodideWorkerRef.current = undefined;
      }
    };
  }, [visible]);

  useEffect(() => {
    void fetch(`${process.env.PUBLIC_URL}/lib/pyodide/resources/sign_stamp.png`).then(
      (response) => {
        if (response.ok) {
          void response.blob().then(saveStampBlob);
        } else {
          void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.stampLoadingFailed' }));
        }
      },
      () => {
        void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.stampLoadingFailed' }));
      }
    );

    void fetch(`${process.env.PUBLIC_URL}/lib/pyodide/resources/notoSans-Regular.ttf`).then(
      (response) => {
        if (response.ok) {
          void response.blob().then(saveFontBlob);
        } else {
          void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.fontLoadingFailed' }));
        }
      },
      () => {
        void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.fontLoadingFailed' }));
      }
    );

    void fetch(`${process.env.PUBLIC_URL}/lib/pyodide/resources/notoSans-SemiBold.ttf`).then(
      (response) => {
        if (response.ok) {
          void response.blob().then(saveFontBoldBlob);
        } else {
          void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.fontLoadingFailed' }));
        }
      },
      () => {
        void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.fontLoadingFailed' }));
      }
    );
  }, []);

  useEffect(() => {
    if (!documentUrl) return;
    void fetch(documentUrl)
      .then((response) => {
        void response.blob().then(saveSigningPdfBlob);
      })
      .catch(() => {
        void message.error(intl.formatMessage({ id: 'LocalSigningModal.error.fontLoadingFailed' }));
      });
  }, [documentUrl]);

  const signDocument = useMemo(() => {
    let id = 0; // identify a Promise
    return (context: any): Promise<{ results: Uint8Array | string; error: string }> => {
      // the id could be generated more carefully
      id = (id + 1) % Number.MAX_SAFE_INTEGER;
      return new Promise((onSuccess) => {
        CALLBACKS[id] = onSuccess;
        pyodideWorkerRef.current.postMessage({
          ...context,
          python: signingScript,
          id,
        });
      });
    };
  }, []);

  const handleSubmit = useSameCallback(async () => {
    setSigning(true);
    setSigningError(undefined);
    setSigningStatus(SigningStates.initializing);

    if (
      !pyodideWorkerRef.current ||
      !pdfFile ||
      !certFile ||
      !signingBgImageRef.current ||
      !signingFontRef.current ||
      !signingFontBoldRef.current
    ) {
      message.error(intl.formatMessage({ id: 'LocalSigningModal.error.missingResources' }));
      return;
    }

    const pdfBuffer = await pdfFile.arrayBuffer();
    const certBuffer = await certFile.arrayBuffer();
    const stampBuffer = await signingBgImageRef.current.arrayBuffer();
    const fontBuffer = await signingFontRef.current.arrayBuffer();
    const fontBoldBuffer = await signingFontBoldRef.current.arrayBuffer();

    const context = {
      pdfBuffer,
      certBuffer,
      certPassword,
      stampBuffer,
      fontBuffer,
      fontBoldBuffer,
      fieldName: signatureData.fieldName || `aspehub_sign_${uuid()}`,
      page: signatureData.pageNumber,
      startX: signatureData.fieldPositionX1,
      startY: signatureData.pageHeight - signatureData.fieldPositionY1,
      endX: signatureData.fieldPositionX2,
      endY: signatureData.pageHeight - signatureData.fieldPositionY2,
      rotation: signatureData.pageRotation,
      signLocation: 'CZ',
      signDate: moment()
        .locale(intl.locale)
        .format('L LTS Z'),
    };

    try {
      setSigningStatus(SigningStates.signingDocument);
      const { results: result, error } = await signDocument(context);

      if (!isMounted) return null;

      if (!!result) {
        const parsableErrors = Object.keys(pythonErrorTranslations);

        if (parsableErrors.some((errorName) => result === errorName)) {
          setSigningError(pythonErrorTranslations[result as PythonErrorEnum]);
          setSigning(false);
        } else {
          setSigningStatus(SigningStates.uploadingDocument);

          const revisionUploadData: UploadData<RevisionDto> = {
            createSaveRequest: (data) =>
              projectApi.documents.id.revisions.id.addsecondary.post(
                documentId,
                revisionId,
                mapUploadProcessDataToSecondaryDocumentAdd(data, workflowNodeId),
                data.ctSource.token
              ),
            onFinish: handleUploadFinish,
          };
          const signedFileNameWithPdfExtension = signedFileName.replace(/\.[^.]+$/, '.pdf');
          const uploadFiles: UploadFile[] = [
            {
              id: uuid(),
              fileName: signedFileNameWithPdfExtension,
              blob: new Blob([result as Uint8Array], { type: 'application/pdf' }),
              fileType: UploadFileType.signedDocument,
            },
          ];

          startUpload(revisionUploadData, uploadFiles, (error) => {
            setSigningError('LocalSignatureErrors.uploadProcessFailed');
            console.error('Signing: ', error.message);
            setSigning(false);
          });
        }
      } else if (error) {
        setSigningError('LocalSignatureErrors.singingProcessFailed');
        console.error('signingWorker error: ', error);
        setSigning(false);
      }
    } catch (e) {
      setSigningError('LocalSignatureErrors.singingProcessFailed');
      setSigning(false);
    }

    return null;
  });

  const handleClose = useCallback(() => {
    resetAll();
    onClose();
  }, [onClose, resetAll]);

  const okButtonProps: ButtonProps = {
    loading: signing || loading,
    disabled:
      !pdfFile ||
      !certFile ||
      !certPassword ||
      !pyodideWorkerRef.current ||
      !signingBgImageRef.current ||
      !signingFontRef.current,
    title: intl.formatMessage({ id: 'LocalSigningModal.button.sign' }),
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'LocalSigningModal.title' })}
      open={visible}
      width={600}
      {...restProps}
      onOk={handleSubmit}
      onCancel={handleClose}
      okButtonProps={okButtonProps}
    >
      {signingError && (
        <Margin bottom>
          <Alert type="error" message={<Fmt id={signingError} />} />
        </Margin>
      )}
      <SpinBoxCenter tip={<Fmt id={`LocalSigningModal.status.${signingStatus}`} />} flex spinning={signing}>
        <SingleFileInput
          inputText="LocalSigningModal.certificateFile"
          inputHint="SignedDocumentInput.collapse.hint"
          acceptedContentType={CERTIFICATE_FILE_TYPES}
          value={certFile}
          onChange={setCertFile}
        />
        <Margin top>
          <Fmt id="LocalSigningModal.input.passphrase" />
          <Input.Password
            value={certPassword}
            onChange={(e) => setCertPassowrd(e.target.value)}
            name="localSigningPassword"
            visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
          />
        </Margin>
      </SpinBoxCenter>
    </Modal>
  );
};

export default LocalSigningModal;
