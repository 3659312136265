import { message } from 'antd';
import { api } from 'api';
import {
  DirectoryDto,
  DocumentCreateDto,
  DocumentDto,
  DocumentRevisionStateEnum,
  MultiUploadCheckResponseDto,
  MultiUploadDirectoryDto,
  MultiUploadFileDto,
  RevisionCreateDto,
  RevisionDto,
} from 'api/completeApiInterfaces';
import {
  checkUploadAccessDeniedError,
  getCommonUploadError,
  hasBlockingUploadError,
  hasOnlyBlockingErrors,
  injectDocumentIdsToCreateDto,
} from 'api/project/upload/uploadHelpers';
import {
  UploadData,
  UploadFileState,
  UploadFileType,
  UploadManager,
  UploadProcess,
} from 'api/project/upload/uploadManager';
import { CancelToken } from 'axios';
import { NoApplicableNewDocumentStateAlert } from 'components/NoApplicableNewDocumentAlert/NoApplicableNewDocumentStateAlert';
import { FileItem, FileSystemTreeNode, FilesStructure } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { DocumentUploadStateData, UploadState, getDocumentsTotalProgress } from 'components/UploadState';
import DocumentCreateMultipleForm, {
  DocumentCreateMultipleFormData,
} from 'components/forms/DocumentCreateForm/DocumentCreateMultipleForm';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { useNewDocumentState } from 'hooks/useNewDocumentState';
import { IntlMessageId } from 'locale/messages/cs';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'react-use';
import { RootState } from 'store';
import { rolesOrderedListSelector } from 'store/selectors';
import { modalConfirm } from 'utils/modalConfirm';
import { v4 as uuid } from 'uuid';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import styles from './DocumentCreateForm.module.less';

const NOOP = () => {};

export enum DocumentCreateResultType {
  Document = 'Document',
  Revision = 'Revision',
  Directory = 'Directory',
}

type DocumentCreateDocumentResult = {
  type: DocumentCreateResultType.Document;
  document: DocumentDto;
};

type DocumentCreateRevisionResult = {
  type: DocumentCreateResultType.Revision;
  revision: RevisionDto;
  documentId: Guid;
};

type DocumentCreateDirectoryResult = {
  type: DocumentCreateResultType.Directory;
  directory: DirectoryDto;
};

export type DocumentCreateResult =
  | DocumentCreateDocumentResult
  | DocumentCreateRevisionResult
  | DocumentCreateDirectoryResult;

function hasSomeConflicts(data: MultiUploadCheckResponseDto): boolean {
  return (
    (!!data?.duplicateDirectories && !!Object.keys(data.duplicateDirectories)?.length) ||
    !!data?.notWritableDirectories?.length ||
    !!data?.invalidNameDirectories?.length ||
    !!data?.notWritableDocuments?.length ||
    !!data?.duplicateDocuments?.length ||
    !!data?.invalidNameDocuments?.length
  );
}

function filesToMultiUploadFileDto(files: FileItem[]): MultiUploadFileDto[] {
  return files.map((f) => ({ name: f.title, path: f.path }));
}

function mapToPaths(items: { path: string }[]): string[] {
  return items.map((item) => item.path);
}

function getDocumentCreateDto(
  file: FileItem,
  directoryId: Guid,
  values: DocumentCreateMultipleFormData
): DocumentCreateDto {
  return {
    name: file.title,
    description: values.description,
    ownedById: values.ownedById,
    directoryId,
    uploadId: null,
    labels: values.labels || [],
    secondaryDocumentsAdd: null,
    categoryTreeNodes: values.categories || {},
    state: values.state,
    metaData: undefined,
    isModel: values.isModel,
  };
}

function getFileDirectoryId(
  path: string,
  directories: Record<string, MultiUploadDirectoryDto>,
  directoryId: Guid
): Guid {
  return path === '' ? directoryId : directories[path]?.directory.id;
}

const mapStateToProps = (state: RootState) => ({
  roles: rolesOrderedListSelector(state),
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

type Props = FormModalProps<DocumentCreateResult[]> &
  PropsFromState & {
    startingDirectoryId?: Guid;
    droppedFiles?: FileSystemTreeNode[];
    defaultIsModel?: boolean;
    uploadDisabled?: boolean;
  };

const DocumentCreateMultipleFormModal: FunctionComponent<Props> = (props) => {
  const { visible, onSubmit, onClose, startingDirectoryId, droppedFiles, defaultIsModel } = props;

  const intl = useIntl();

  const [checkData, setCheckData] = useState<MultiUploadCheckResponseDto>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [interrupted, setInterrupted] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const resultRef = useRef<DocumentCreateResult[]>([]);

  const progressData = useRef<Dictionary<DocumentUploadStateData>>(null);
  const lastUploadManager = useRef<UploadManager>(null);
  const lastUploadIds = useRef<string[]>([]);

  const [selectedDirectoryId, setSelectedDirectoryId] = useState(startingDirectoryId);
  useEffect(() => {
    if (visible && startingDirectoryId) {
      setSelectedDirectoryId(startingDirectoryId);
    }
  }, [visible, startingDirectoryId]);

  const [structure, setStructure] = useState(null);

  const onValidationFileStructureChange = useCallback(async (value: FilesStructure) => {
    if (value) {
      setStructure(value);
    }
  }, []);

  const checkFetchFn = useCallback(
    (ct: CancelToken) =>
      api.project.upload.checkMultiUpload(
        {
          directories: mapToPaths(structure.folders),
          files: filesToMultiUploadFileDto([...structure.files, ...structure.revisions]),
          targetDirectoryId: selectedDirectoryId,
        },
        ct
      ),
    [structure, selectedDirectoryId]
  );

  const [checkResult, checkError, checkLoading, loadCheck, _setCheck, cancelCheck] = useApiData(checkFetchFn);

  useEffect(() => {
    if (!structure?.folders?.length && !structure?.files?.length && !structure?.revisions?.length) {
      setCheckData(null);
    }
  }, [structure]);

  useEffect(() => {
    setCheckData(checkResult);
  }, [checkResult]);

  useEffect(() => {
    if (checkError) {
      message.error(intl.formatMessage({ id: `serviceError.${checkError.referenceErrorCode}` }));
    }
  }, [intl, checkError]);

  useDebounce(
    () => {
      if (
        (!structure?.folders?.length && !structure?.revisions?.length && !structure?.files?.length) ||
        !selectedDirectoryId
      )
        return;
      loadCheck();
    },
    900,
    [structure, selectedDirectoryId]
  );

  useEffect(() => {
    cancelCheck('DocumentCreateMultipleFormModal: visible changed');
    setStructure(null);
    setCheckData(null);
  }, [cancelCheck, visible]);

  const evaluateUploadState = useCallback(() => {
    let isError: boolean = false;
    let isRunning: boolean = false;
    let error: string = '';
    for (const uploadId in progressData.current) {
      if (!!progressData.current[uploadId].error) {
        error = progressData.current[uploadId].error;
        isError = true;
      } else {
        if (!progressData.current[uploadId].ready) isRunning = true;
      }
    }
    if (!isRunning && isError) {
      setInterrupted(true);
      setError(error);
    }
    return !isRunning && !isError;
  }, []);

  const handleUploadError = useCallback(
    (state: UploadFileState[], uploadId: Guid) => {
      let errorMessage: string = 'Unknown Error';
      const error = getCommonUploadError(state);
      const accessDeniedError = checkUploadAccessDeniedError(error, intl);
      progressData.current[uploadId].error = errorMessage = accessDeniedError || error.message || errorMessage;
      evaluateUploadState();
    },
    [evaluateUploadState, intl]
  );

  const handleUploadProgress = useCallback((state: UploadFileState[], uploadId: Guid) => {
    progressData.current[uploadId].state = state;
    setUploadProgress(getDocumentsTotalProgress(progressData.current));
  }, []);

  const handleUploadFinish = async (document: DocumentDto, uploadId: Guid): Promise<void> => {
    progressData.current[uploadId].ready = true;
    progressData.current[uploadId].document = document;
    resultRef.current.push({
      type: DocumentCreateResultType.Document,
      document,
    });
    if (evaluateUploadState()) {
      await onSubmit(resultRef.current);
      resetAll();
    }
  };

  const handleRevisionUploadFinish = async (revision: RevisionDto, documentId: Guid, uploadId: Guid): Promise<void> => {
    progressData.current[uploadId].ready = true;
    progressData.current[uploadId].revision = revision;
    resultRef.current.push({
      type: DocumentCreateResultType.Revision,
      revision,
      documentId,
    });
    if (evaluateUploadState()) {
      await onSubmit(resultRef.current);
      resetAll();
    }
  };

  const resetAll = useCallback(() => {
    setUploadProgress(0);
    setInterrupted(false);
    setError(null);
    lastUploadManager.current = null;
    lastUploadIds.current = [];
    resultRef.current = [];
  }, []);

  const handleClose = useCallback(() => {
    if (lastUploadManager.current !== null) {
      lastUploadIds.current.forEach((uploadId) => {
        const progress = progressData.current[uploadId];
        if (!progress.ready) {
          lastUploadManager.current.cancelProcess(uploadId);
        }
      });
    }
    onSubmit(undefined);
    resetAll();
    onClose();
  }, [onSubmit, resetAll, onClose]);

  const confirmHandleClose = useCallback(async () => {
    return await modalConfirm({
      type: 'warning',
      title: intl.formatMessage({ id: 'DocumentCreateFormModal.cancelConfirm.title' }),
      okText: intl.formatMessage({ id: 'general.ok' }),
      cancelText: intl.formatMessage({ id: 'general.cancel' }),
      content: intl.formatMessage({ id: 'DocumentCreateFormModal.cancelConfirm.text' }),
      onOk: () => handleClose(),
      onCancel: () => {},
      visible: visible,
    });
  }, [handleClose, intl, visible]);

  const handleSubmit: FormSubmitHandler<DocumentCreateMultipleFormData> = useSameCallback(async (values) => {
    try {
      if (lastUploadManager.current !== null) {
        // continue is pressed
        if (interrupted) {
          lastUploadIds.current.forEach((uploadId) => {
            if (hasBlockingUploadError(progressData.current[uploadId].state)) return;
            if (!!progressData.current[uploadId].error) {
              progressData.current[uploadId].error = null;
              lastUploadManager.current.continueProcess(uploadId);
            } else {
              if (!progressData.current[uploadId].ready) {
                lastUploadManager.current.continueProcess(uploadId);
              }
            }
          });
          evaluateUploadState();
          setError(null);
          setInterrupted(false);
        }
        return null;
      }

      const [errCheck, resultCheck] = await api.project.upload.checkMultiUpload({
        directories: mapToPaths(values.files.folders),
        files: filesToMultiUploadFileDto([...values.files.files, ...values.files.revisions]),
        targetDirectoryId: selectedDirectoryId,
      });

      if (errCheck) {
        message.error(errCheck.message);
      }

      if (resultCheck && hasSomeConflicts(resultCheck.data)) {
        setCheckData(resultCheck.data);
        const revisionDocumentIds = values.files.revisions.flatMap((revision) => revision.documentId);

        if (
          !!resultCheck.data?.notWritableDirectories?.length ||
          !!resultCheck.data?.notWritableDocuments?.length ||
          !!resultCheck.data?.invalidNameDirectories?.length ||
          !!resultCheck.data?.invalidNameDocuments?.length ||
          !!resultCheck.data?.duplicateDocuments.some(
            (document) =>
              revisionDocumentIds.includes(document.document.id) &&
              document.document.addRevisionState !== DocumentRevisionStateEnum.Ok
          )
        ) {
          return Promise.reject();
        }
      } else {
        setCheckData(null);
      }

      let directories: Dictionary<MultiUploadDirectoryDto> = {};
      if (values.files.folders && values.files.folders.length > 0) {
        const [errPrepare, resultPrepare] = await api.project.upload.prepareMultiUpload({
          directories: mapToPaths(values.files.folders),
          targetDirectoryId: selectedDirectoryId,
          permissionInheritance: values.files.inheritPermissions,
        });

        if (errPrepare) {
          message.error(errPrepare.message);
          if (values.files.folders.length > 0) {
            return Promise.reject();
          }
        }

        directories = resultPrepare ? resultPrepare.data.directories : {};
      }

      resultRef.current = resultRef.current.filter(({ type }) => type !== DocumentCreateResultType.Directory);
      Object.values(directories)
        .filter(({ isCreated }) => isCreated)
        .forEach(({ directory }) => {
          resultRef.current.push({
            type: DocumentCreateResultType.Directory,
            directory,
          });
        });

      const files = values.files.files;
      const revisions = values.files.revisions;

      if (files.length === 0 && revisions.length === 0) {
        handleClose();
        return null;
      }

      lastUploadManager.current = new UploadManager();
      progressData.current = {};

      files.forEach((file) => {
        const uploadId = uuid();
        lastUploadIds.current.push(uploadId);
        const uploadFilesSize: number[] = [file.file.size];

        const fileDirectoryId = getFileDirectoryId(file.path, directories, selectedDirectoryId);

        // TODO: get directoryId from directories returned from the new API endpoint
        const documentCreateDto: DocumentCreateDto = getDocumentCreateDto(file, fileDirectoryId, values);
        const signedDocument = values?.signedDocument;

        const documentUploadData: UploadData<DocumentDto> = {
          createSaveRequest: (data) =>
            api.project.documents.createDocument(
              injectDocumentIdsToCreateDto(documentCreateDto, data),
              data.ctSource.token
            ),
          onFinish: (document) => handleUploadFinish(document, uploadId),
        };

        const uploadProcess: UploadProcess = {
          id: uploadId,
          uploadFiles: [],
          data: documentUploadData,
          onError: (state) => handleUploadError(state, uploadId),
          onProgress: (state) => handleUploadProgress(state, uploadId),
        };

        uploadProcess.uploadFiles.push({
          id: uploadId,
          fileName: file.title,
          blob: file.file,
          fileType: UploadFileType.primaryFile,
        });

        if (signedDocument) {
          uploadProcess.uploadFiles.push({
            id: uuid(),
            fileName: signedDocument.name,
            blob: signedDocument,
            fileType: UploadFileType.signedDocument,
          });
          uploadFilesSize.push(signedDocument.size);
        }

        const secondaryFiles = values?.secondaryFiles?.files || [];

        secondaryFiles.forEach((f) => {
          const blob = f.file;
          uploadProcess.uploadFiles.push({
            id: uuid(),
            fileName: blob instanceof File ? blob.name : f.title,
            blob: f.file,
            fileType: UploadFileType.attachment,
          });
          uploadFilesSize.push(blob.size);
        });

        lastUploadManager.current.startProcess(uploadProcess);
        progressData.current[uploadId] = {
          name: file.title,
          state: [],
          filesSizes: uploadFilesSize,
          ready: false,
          error: null,
          document: null,
          revision: null,
        };
      });

      revisions.forEach((revision) => {
        const uploadId = uuid();
        lastUploadIds.current.push(uploadId);
        const uploadFilesSize: number[] = [revision.file.size];

        const signedDocument = values?.signedDocument;

        const createDto: RevisionCreateDto = {
          metaData: undefined,
          description: values.revisionComment,
          state: values.revisionState,
          uploadId: null,
          secondaryDocumentsAdd: null,
        };

        const revisionUploadData: UploadData<RevisionDto> = {
          createSaveRequest: (data) =>
            api.project.documents.createDocumentRevision(
              revision.documentId,
              injectDocumentIdsToCreateDto(createDto, data),
              data.ctSource.token
            ),
          onFinish: (revisionResult) => handleRevisionUploadFinish(revisionResult, revision.documentId, uploadId),
        };

        const uploadProcess: UploadProcess = {
          id: uploadId,
          uploadFiles: [],
          data: revisionUploadData,
          onError: (state) => handleUploadError(state, uploadId),
          onProgress: (state) => handleUploadProgress(state, uploadId),
        };

        uploadProcess.uploadFiles.push({
          id: uploadId,
          fileName: revision.title,
          blob: revision.file,
          fileType: UploadFileType.primaryFile,
        });

        if (signedDocument) {
          uploadProcess.uploadFiles.push({
            id: uuid(),
            fileName: signedDocument.name,
            blob: signedDocument,
            fileType: UploadFileType.signedDocument,
          });
          uploadFilesSize.push(signedDocument.size);
        }

        const secondaryFiles = values?.secondaryFiles?.files || [];

        secondaryFiles.forEach((f) => {
          const blob = f.file;
          uploadProcess.uploadFiles.push({
            id: uuid(),
            fileName: blob instanceof File ? blob.name : f.title,
            blob: f.file,
            fileType: UploadFileType.attachment,
          });
          uploadFilesSize.push(blob.size);
        });

        lastUploadManager.current.startProcess(uploadProcess);
        progressData.current[uploadId] = {
          name: revision.title,
          state: [],
          filesSizes: uploadFilesSize,
          ready: false,
          error: null,
          document: null,
          revision: null,
        };
      });

      setUploadProgress(1);

      return null;
    } catch (e) {
      message.error(e);
      return Promise.reject();
    }
  });

  const loading = !interrupted && lastUploadManager.current !== null;
  const submitTextId: IntlMessageId =
    lastUploadManager.current !== null && interrupted ? 'DocumentCreateFormModal.continue' : 'forms.button.submit';
  const submitTextLoadingId: IntlMessageId = checkLoading ? 'DocumentCreateFormModal.checkingFiles' : undefined;
  const disabledWithBlockingErrors = hasOnlyBlockingErrors(progressData, interrupted);

  const { newDocumentUsefulStates, handleNoUsefulStatusesAlertClose } = useNewDocumentState(onClose);

  return (
    <>
      {visible && !newDocumentUsefulStates?.length && (
        <NoApplicableNewDocumentStateAlert
          usefulNewDocumentStatuses={newDocumentUsefulStates}
          handleAlertClose={handleNoUsefulStatusesAlertClose}
        />
      )}

      <FormModalWrapper
        visible={visible && !!newDocumentUsefulStates.length}
        progress={Math.round(uploadProgress)}
        showProgress={lastUploadManager.current !== null}
        errorMessage={error === null ? undefined : error}
        onSubmit={handleSubmit}
        submitTextId={submitTextId}
        submitTextLoadingId={submitTextLoadingId}
        onSubmitDisabled={
          props.uploadDisabled ||
          !!checkData?.notWritableDirectories.length ||
          !!checkData?.notWritableDocuments.length ||
          disabledWithBlockingErrors
        }
        onClose={!lastUploadManager.current ? handleClose : confirmHandleClose}
        forceLoading={loading || checkLoading}
        titleId="DocumentCreateMultipleFormModal.title"
        className={styles.documentCreateFormModal}
      >
        {({ intl, formRef }) => (
          <>
            <UploadState
              visible={!!lastUploadManager.current}
              intl={intl}
              documentUploadStateData={progressData.current}
              onCancel={NOOP}
              onContinue={NOOP}
              onPause={NOOP}
            />
            <DocumentCreateMultipleForm
              visible={!lastUploadManager.current}
              intl={intl}
              wrappedComponentRef={formRef}
              progressItemData={progressData.current}
              onDirectoryChange={setSelectedDirectoryId}
              startingDirectoryId={startingDirectoryId}
              disableTargetFolderInput
              checkData={checkData}
              checkLoading={checkLoading}
              onPrimaryFilesValidationChange={onValidationFileStructureChange}
              droppedFiles={droppedFiles}
              defaultIsModel={defaultIsModel}
              usefulNewDocumentStatuses={newDocumentUsefulStates}
            />
          </>
        )}
      </FormModalWrapper>
    </>
  );
};

export default connect(mapStateToProps)(DocumentCreateMultipleFormModal);
