import { Button, Col, Row, Select, TreeSelect, message } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { masterApi } from 'api/completeApi';
import { EstiConUtvaryStructureDto, OrganizationPOHReportUnitSettingDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import ColorPickerInput from 'components/ColorPickerInput';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import { useApiData, useCurrentAppUser } from 'hooks';
import { useEsticonFirmsWithProjects } from 'hooks/useEsticonFirmsWithProjects';
import { Fmt, InjectedIntl } from 'locale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ignoreRef } from 'utils';

type Props = {
  value?: OrganizationPOHReportUnitSettingDto[];
  intl: InjectedIntl;
  organizationId: Guid;
  onChange?: (value: OrganizationPOHReportUnitSettingDto[]) => void;
};

const mapUnitStructureToOptions = (
  unitStructure: EstiConUtvaryStructureDto,
  usedUnits: Set<Guid>
): DefaultOptionType | undefined => {
  return {
    value: unitStructure.id,
    label: unitStructure.sign,
    disabled: usedUnits.has(unitStructure.id),
    children: unitStructure.structure.map((structure) => mapUnitStructureToOptions(structure, usedUnits)),
  };
};

const flatmapUnitStructure = (unitStructure: EstiConUtvaryStructureDto[]): Record<Guid, string> =>
  unitStructure.reduce(
    (acc, structure) => ({
      ...acc,
      [structure.id]: structure.sign,
      ...(flatmapUnitStructure(structure.structure) || []),
    }),
    {}
  );

const ReportUnitColorAssignComponent: React.FunctionComponent<Props> = ({
  onChange,
  intl,
  organizationId,
  value = [],
}) => {
  const [selectedEsticonFirmId, setSelectedEsticonFirmId] = useState<Guid>();
  const [selectedUnitId, setSelectedUnitId] = useState<Guid>();
  const [selectedColor, setSelectedColor] = useState<string>();

  const currentAppUser = useCurrentAppUser();
  const { esticonFirms, firmsWithProjectsLoading } = useEsticonFirmsWithProjects(organizationId);
  const availableFirmOptions = useMemo(
    () =>
      Object.values(esticonFirms)
        ?.filter((firmRow) =>
          currentAppUser.organizationUsers?.some(
            (orgUser) => !!orgUser.organization.esticonFirmIds?.some((firmId) => firmRow.id === firmId)
          )
        )
        ?.map((firmRow): DefaultOptionType => ({ value: firmRow.id, label: firmRow.nazev })) || [],
    [esticonFirms, currentAppUser]
  );

  const [esticonUnits, esticonUnitsError, esticonUnitsLoading, loadEsticonUnits] = useApiData((ct) =>
    masterApi.EsticonReports.orq.id.firms.id.projectunits.get(organizationId, selectedEsticonFirmId, ct)
  );

  useEffect(() => {
    !!selectedEsticonFirmId && loadEsticonUnits();
  }, [selectedEsticonFirmId]);

  const usedUnits = useMemo(() => new Set<Guid>(value.map((unit) => unit.id)), [value]);

  const unitsOption = useMemo(() => esticonUnits?.map((unit) => mapUnitStructureToOptions(unit, usedUnits)) || [], [
    esticonUnits,
    usedUnits,
  ]);

  const unitsFlatMap = useMemo(
    (): Record<Guid, string> => (!!esticonUnits && flatmapUnitStructure(esticonUnits)) || {},
    [esticonUnits]
  );

  const addNewUnitSettings = useCallback(() => {
    const selectedUnit = unitsFlatMap[selectedUnitId];
    if (!selectedUnit) {
      void message.error(intl.formatMessage({ id: 'ReportUnitColorAssign.error.unitNotFound' }));
      return;
    }

    onChange && onChange([...value, { id: selectedUnitId, sign: selectedUnit, color: selectedColor }]);
    setSelectedUnitId(undefined);
  }, [intl, onChange, selectedColor, selectedUnitId, unitsFlatMap, value]);

  const updateUnitColor = useCallback(
    (unitId: Guid, color: string) => {
      const changingUnit = value.find((unit) => unit.id === unitId);
      if (!changingUnit) {
        void message.error(intl.formatMessage({ id: 'ReportUnitColorAssign.error.unitNotFound' }));
        return;
      }

      onChange && onChange([...value.filter((unit) => unit.id !== unitId), { ...changingUnit, color: color }]);
    },
    [value, onChange, intl]
  );

  const removeUnit = useCallback(
    (unitId: Guid) => {
      onChange && onChange(value.filter((unit) => unit.id !== unitId));
    },
    [value]
  );

  const sortedFilteredUnits = useMemo(
    () => value.filter((unit) => !!unitsFlatMap[unit.id]).sort((a, b) => a.sign.localeCompare(b.sign)),
    [unitsFlatMap, value]
  );

  return (
    <>
      <Margin bottom>
        <Row>
          <Col span={10}>
            <Fmt id="ReportUnitColorAssign.selectEsticonFirm" />
          </Col>
          <Col span={14}>
            <Select
              options={availableFirmOptions}
              size="middle"
              loading={firmsWithProjectsLoading}
              onChange={setSelectedEsticonFirmId}
            />
          </Col>
        </Row>
      </Margin>
      {!!selectedEsticonFirmId && (
        <ContentGate loading={esticonUnitsLoading}>
          {sortedFilteredUnits.map((unitSetting) => (
            <Row key={unitSetting.id}>
              <Col span={10}>{unitSetting.sign}:</Col>
              <Col span={12}>
                <ColorPickerInput
                  value={unitSetting.color}
                  onChange={(value) => updateUnitColor(unitSetting.id, value)}
                />
              </Col>
              <Col span={2}>
                <DeleteButton onClick={() => removeUnit(unitSetting.id)} />
              </Col>
            </Row>
          ))}
          <Margin top>
            <Fmt id="ReportUnitColorAssign.addNewUnit" />
            <Row>
              <Col span={10}>
                <TreeSelect
                  treeData={unitsOption}
                  onChange={setSelectedUnitId}
                  value={selectedUnitId}
                  treeDefaultExpandAll
                />
              </Col>
              <Col span={10}>
                <ColorPickerInput onChange={setSelectedColor} />
              </Col>
              <Col span={3}>
                <Button onClick={addNewUnitSettings} disabled={!selectedUnitId}>
                  <Fmt id="general.add" />
                </Button>
              </Col>
            </Row>
          </Margin>
        </ContentGate>
      )}
    </>
  );
};

export const ReportUnitColorAssign = ignoreRef(React.memo(ReportUnitColorAssignComponent));
