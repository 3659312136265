import { Button } from 'antd';
import { RoleDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { AddIcon, DeleteIcon } from 'components/Icons/HubActionsIcons';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { RoleInfo } from 'components/RoleInfo/RoleInfo';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import StackPanel from 'components/StackPanel';
import { useBoolean, useDispatchEffect } from 'hooks';
import { useClearableSearch } from 'hooks/useClearableSearch';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { uniq } from 'lodash';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { rolesMapSelector } from 'store/selectors';
import { ignoreRef, smartFilter } from 'utils';
import styles from './MultipleRolesListSelect.module.less';

export interface CommonMultipleRolesListSelectProps {
  removeManagerTranslationId?: IntlMessageId;
  addManagerTranslationId?: IntlMessageId;
  noAvailableRoleTranslationId?: IntlMessageId;
  required?: boolean;
}

type Props = CommonMultipleRolesListSelectProps & {
  value: Guid[];
  onChange: (value: Guid[]) => void;
  maxRoles?: number;
  disabled?: boolean;
};

const MultipleRolesListSelectComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  removeManagerTranslationId,
  addManagerTranslationId,
  noAvailableRoleTranslationId,
  disabled,
  maxRoles,
  required = true,
}) => {
  useDispatchEffect((dispatch) => dispatch.roles.loadData({ reload: false }), []);
  useDirtyStoreReload(
    (store) => store.roles,
    (dispatch) => dispatch.roles
  );
  const rolesMap = useSelector(rolesMapSelector);

  const [selectVisible, showSelect, hideSelect] = useBoolean(false);
  const inputRef = useRef<HTMLDivElement>();
  const { search, setSearch, clearSearch } = useClearableSearch();

  const getPopupContainer = useCallback(() => inputRef.current, []);

  const getRoleIsHidden = useCallback((role: RoleDto) => value.some((r) => r === role.id), [value]);

  const handleDelete = useCallback(
    (roleId: Guid) => {
      onChange(value.filter((role) => role !== roleId));
    },
    [onChange, value]
  );

  const onAddSubmit = useCallback(
    (roleId: Guid) => {
      if (!roleId) {
        hideSelect();
        return;
      }
      onChange(uniq([...value, roleId]));
      hideSelect();
    },
    [value]
  );

  const roles = useMemo(() => value.map((roleId) => rolesMap?.[roleId]).filter((role) => !!role) || [], [
    rolesMap,
    value,
  ]);

  const hasAvailableRoles = useMemo(() => !!rolesMap && value.length < Object.keys(rolesMap).length, [value, rolesMap]);
  const disableAdd = maxRoles !== undefined && value.length >= maxRoles;

  const newItemComponent = (
    <CommonHubTooltip
      title={
        !hasAvailableRoles && <Fmt id={noAvailableRoleTranslationId || 'MultipleRolesListSelect.noAvailableRole'} />
      }
    >
      <Button
        onClick={showSelect}
        icon={<AddIcon />}
        type="primary"
        disabled={!hasAvailableRoles || disabled || selectVisible || disableAdd}
        danger={!value.length && required}
      >
        <Fmt id={addManagerTranslationId || 'MultipleRolesListSelect.addManager'} />
      </Button>
    </CommonHubTooltip>
  );

  return (
    <Panel
      toolbarTitle={newItemComponent}
      noMargin
      onSearch={!!roles?.length && setSearch}
      searchValue={search}
      panelWidth="auto" //{null}
    >
      <div ref={inputRef}>
        {selectVisible && (
          <RoleSelect
            className={styles.roleSelect}
            defaultOpen
            autoFocus
            onChange={onAddSubmit}
            onBlur={hideSelect}
            hiddenRoles={getRoleIsHidden}
            getPopupContainer={getPopupContainer}
          />
        )}
        <List<RoleDto>
          data={roles}
          search={search}
          filterItem={(item: RoleDto) => smartFilter(item?.user?.username, search) || smartFilter(item.name, search)}
          renderItem={(role) => (
            <StackPanel centerItems key={role.id} className={styles.managerItem}>
              <RoleInfo role={role} />
              {!disabled && (
                <CommonHubTooltip
                  title={<Fmt id={removeManagerTranslationId || 'MultipleRolesListSelect.removeManager'} />}
                  placement={'left'}
                >
                  <Button icon={<DeleteIcon />} type="link" size="small" onClick={() => handleDelete(role.id)} />
                </CommonHubTooltip>
              )}
            </StackPanel>
          )}
          renderEmpty={(total, filtered) => (
            <ListEmpty
              filtered={filtered}
              total={total}
              noItemsMessageId={'RoleSelect.noRoleSet'}
              onClearSearch={clearSearch}
            />
          )}
        />
      </div>
    </Panel>
  );
};

export const MultipleRolesListSelect = React.memo(MultipleRolesListSelectComponent);

export const MultipleRolesListSelectFormItem: React.FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(
  MultipleRolesListSelect
);
