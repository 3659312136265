import { FilterFilled } from '@ant-design/icons';
import { Input, InputRef } from 'antd';
import { InputProps } from 'antd/lib/input';
import { InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';

type Props = InputProps &
  InjectedIntlProps & {
    onSearch: (value: string) => void;
    setRef?: (ref: InputRef) => void;
  };

const PREFIX = <FilterFilled />;

const FilterInputComponent: FunctionComponent<Props> = ({ intl, onChange, onSearch, setRef, placeholder, ...rest }) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      onSearch && onSearch(event.target.value);
    },
    [onChange, onSearch]
  );

  return (
    <Input
      ref={setRef}
      prefix={PREFIX}
      allowClear
      onChange={handleChange}
      placeholder={placeholder || intl.formatMessage({ id: 'general.filter' })}
      {...rest}
    />
  );
};

export const FilterInput = memoizeWithIntl(FilterInputComponent);
