import { Table } from 'antd';
import { EntityTypesEnum } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';

export function breakTableValue(value: string) {
  return <div style={{ wordBreak: 'break-word' }}>{value}</div>;
}

export interface Params {
  key: string;
  name: string;
  value: string;
}

type Props = InjectedIntlProps & {
  params: Params[];
  isEntity: boolean;
  entityName: EntityTypesEnum;
};

const ParamsTable: FunctionComponent<Props> = ({ params, isEntity, entityName, intl }) => {
  const columns = [
    {
      title: intl.formatMessage({
        id: isEntity ? `AuditLog.EntityTypeParam.${entityName}` : 'AuditLog.ParamsTable.EventName',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => breakTableValue(value),
    },
    {
      title: intl.formatMessage({ id: 'AuditLog.ParamsTable.Value' }),
      dataIndex: 'value',
      key: 'value',
      render: (value: string) => breakTableValue(value),
    },
  ];
  return <Table size="small" pagination={false} dataSource={params} columns={columns} />;
};

export default injectIntl(ParamsTable);
