import { SettingOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Select } from 'antd';
import { DROPDOWN_TRIGGER_CLICK, EMPTY_GUID } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { KPIConfiguration } from '../forms/KpiConfigurationForm/KpiConfigurationForm.utils';
import styles from './KpiSettingsToolbar.module.less';

type Props = {
  kpiConfigurations: KPIConfiguration[];
  selectedConfigurationId: Guid;
  onConfigSelect: (conigurationId: Guid) => void;
  onSettingsClick: (conigurationId: Guid) => void;
  onDeleteClick: (conigurationId: Guid) => void;
};

export const KpiSettingsToolbar: FunctionComponent<Props> = ({
  selectedConfigurationId,
  kpiConfigurations,
  onConfigSelect,
  onSettingsClick,
  onDeleteClick,
}) => {
  const intl = useIntl();
  const menuItems: MenuProps = useMemo(
    () => ({
      items: [
        {
          key: 'add',
          label: intl.formatMessage({ id: 'KpiSettingsToolbar.menu.add' }),
          onClick: () => {
            onSettingsClick(EMPTY_GUID);
          },
        },
        selectedConfigurationId !== EMPTY_GUID && {
          key: 'edit',
          label: intl.formatMessage({ id: 'KpiSettingsToolbar.menu.edit' }),
          onClick: () => {
            onSettingsClick(selectedConfigurationId);
          },
        },
        selectedConfigurationId !== EMPTY_GUID && {
          key: 'delete',
          label: intl.formatMessage({ id: 'KpiSettingsToolbar.menu.delete' }),
          onClick: () => {
            onDeleteClick(selectedConfigurationId);
          },
        },
      ].filter(Boolean),
    }),
    [intl, onDeleteClick, onSettingsClick, selectedConfigurationId]
  );

  return (
    <>
      <Select value={selectedConfigurationId} onChange={onConfigSelect} className={styles.kpiList}>
        <Select.Option key={EMPTY_GUID} value={EMPTY_GUID}>
          <Fmt id="general.default" />
        </Select.Option>
        {kpiConfigurations.map((config) => (
          <Select.Option key={config.id} value={config.id}>
            {config.title}
          </Select.Option>
        ))}
      </Select>
      <Dropdown menu={menuItems} trigger={DROPDOWN_TRIGGER_CLICK}>
        <Button icon={<SettingOutlined />} />
      </Dropdown>
    </>
  );
};
