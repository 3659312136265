import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import React, { FunctionComponent, ReactNode } from 'react';
import { DisabledWithReason } from 'utils/types';

type Props = {
  onDelete: () => void;
  loading?: boolean;
  disabled?: DisabledWithReason;
  tooltip?: ReactNode;
  shape?: 'circle' | 'default';
  type?: 'primary' | 'default' | 'dashed' | 'link' | 'text';
};

export const DeleteButton: FunctionComponent<Props> = ({
  onDelete,
  loading,
  disabled,
  tooltip,
  type = 'primary',
  shape = 'circle',
  children,
}) => {
  return (
    <CommonHubTooltip title={typeof disabled === 'string' ? disabled : tooltip} placement="leftTop">
      <Button
        type={type}
        shape={shape}
        icon={<DeleteIcon />}
        size="small"
        disabled={!!disabled}
        loading={loading}
        onClick={(event) => {
          event.stopPropagation();
          onDelete();
        }}
        danger
      >
        {children}
      </Button>
    </CommonHubTooltip>
  );
};
