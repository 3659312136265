import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './SpinBox.module.less';

type Props = SpinProps & {
  fill?: boolean;
  cover?: boolean;
};

const SpinBox: FunctionComponent<Props> = ({ fill, children, className, cover, spinning, ...restProps }) =>
  spinning ? (
    <div className={classNames(styles.spin, className, cover && styles.cover, fill && styles.fill)}>
      <Spin {...restProps} spinning={spinning} />
      {children && <div className={styles.children}>{children}</div>}
    </div>
  ) : null;

export default SpinBox;
