import React, { FunctionComponent, useEffect } from 'react';

type Props = {
  callback: () => void;
};

const ComponentDidMountCallback: FunctionComponent<Props> = ({ callback, children }) => {
  useEffect(() => callback(), []);

  return <>{children}</>;
};

export default ComponentDidMountCallback;
