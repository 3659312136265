import { BlockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AccessLevelEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DirectoryAccessLevel from 'components/DirectoryAccessLevel';
import DisplayName from 'components/DisplayName';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import { Fmt } from 'locale';
import React, { Component } from 'react';
import styles from './AccessLevelList.module.less';

export type AccessLevelListDataSource = {
  accessLevel: AccessLevelEnum; // after computing from parent
  selfAccessLevel: AccessLevelEnum | null; // set in this data source
  inherited: boolean;
  id: Guid;
  key: string;
  label: string;
  disabled?: boolean;
  highlighted?: boolean;
  edited: boolean;
  hidden?: boolean;
};

type Props = {
  dataSource: AccessLevelListDataSource[];
  onChange: (accessLevel: AccessLevelEnum, key: Guid) => void;
  onDelete: (key: string) => void;
  highlightStyle?: string;
};

type State = {
  dataSourceSorted: AccessLevelListDataSource[];
};

class AccessLevelList extends Component<Props, State> {
  state: State = {
    dataSourceSorted: [],
  };

  static defaultProps: any = {
    disabled: false,
    onChange: () => {},
    onDelete: () => {},
    displayEditMark: () => false,
  };

  compareItems = (itemA: AccessLevelListDataSource, itemB: AccessLevelListDataSource) =>
    itemA.label.localeCompare(itemB.label);

  componentDidMount(): void {
    this.updateDataSource();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.updateDataSource();
    }
  }

  updateDataSource = () => {
    if (!this.props.dataSource) return;
    const mappedAndSorted = this.props.dataSource
      // .filter((d) => d.accessLevel !== null)
      .sort(this.compareItems);
    this.setState({
      dataSourceSorted: mappedAndSorted,
    });
  };

  render() {
    const { onChange, onDelete, highlightStyle } = this.props;
    const { dataSourceSorted } = this.state;
    return (
      <div>
        {dataSourceSorted.map((item) => (
          <div key={item.key} className={styles.rowContainer}>
            <div
              className={classNames(
                styles.row,
                item.highlighted && styles.rowHighlighted,
                item.highlighted && highlightStyle
              )}
            >
              <div className={styles.label}>
                <CommonHubTooltip placement="left" title={<DisplayName>{item.label}</DisplayName>}>
                  <DisplayName>{item.label}</DisplayName>
                </CommonHubTooltip>
              </div>
              <div className={styles.labelButtonWrap}>
                <DirectoryAccessLevel
                  value={item.accessLevel}
                  disabled={item.disabled}
                  displayEditMark={item.edited}
                  onChange={(val) => {
                    onChange(val, item.id);
                  }}
                />
                <div className={styles.delete}>
                  {item.selfAccessLevel && (
                    <CommonHubTooltip title={<Fmt id="general.delete" />}>
                      <Button
                        disabled={item.disabled}
                        shape="circle"
                        size="small"
                        type="primary"
                        icon={<DeleteIcon />}
                        onClick={() => onDelete(item.id)}
                        danger
                      />
                    </CommonHubTooltip>
                  )}

                  {!item.selfAccessLevel && (
                    <CommonHubTooltip
                      title={
                        item.inherited ? <Fmt id="AccessLevel.inheritedPermission" /> : <Fmt id="AccessLevel.notSet" />
                      }
                    >
                      <BlockOutlined />
                    </CommonHubTooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default AccessLevelList;
