import { to } from 'api/await-to';
import { BlobDerivateTypeEnum, BlobsRequestDto, BlobsResponseDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export type OnDemandDerivateData = {
  attemptDate: string;
  attempts: number;
};

export const blobApi = {
  getBlobs: (tokenRequest: BlobsRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<BlobsResponseDto>(`/blobs`, tokenRequest, { cancelToken })
    ),
  processBlobDerivate: (blobToken: string, derivateType: BlobDerivateTypeEnum, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/blobs/${encodeURIComponent(blobToken)}/derivates/process`, null, {
        params: { type: derivateType },
        cancelToken,
      })
    ),
};
