import { api } from 'api';
import { AppUserShareDto } from 'api/completeApiInterfaces';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import AppUserShareForm, { AppUserShareFormData } from './AppUserShareForm';

type DocumentShareProps = {
  shareDocument: true;
  directoryId: Guid; // Directory ID is needed for access rights
  documentIds: Guid[];
  documentLinkIds: Guid[];
};

type DirectoryShareProps = {
  shareDocument: false;
  directoryId: Guid;
  documentIds?: never;
  documentLinkIds?: never;
};

type Props = FormModalProps<AppUserShareDto> & (DocumentShareProps | DirectoryShareProps);

const AppUserShareFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, documentIds, documentLinkIds, directoryId, shareDocument, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<AppUserShareFormData> = async (values) => {
    const uniqueUsers: Set<Guid> = new Set(Object.values(values.users).map((k) => k));
    const data: AppUserShareDto = {
      userIds: Array.from(uniqueUsers),
      message: values.message,
      documentIds,
      documentLinksIds: documentLinkIds,
      directoryId: directoryId,
    };

    const [err, res] = await api.project.sharedDownload.processAppUserShare(data);
    if (err) return err;

    await onSubmit(res.data);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={shareDocument ? 'AppUserShareFormModal.title.document' : 'AppUserShareFormModal.title.directory'}
      submitTextId="AppUserShareFormModal.send"
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <AppUserShareForm
          intl={intl}
          wrappedComponentRef={formRef}
          directoryId={props.directoryId}
          shareDocument={props.shareDocument}
        />
      )}
    </FormModalWrapper>
  );
};

export default AppUserShareFormModal;
