import {
  HistoryOutlined,
  KeyOutlined,
  ReloadOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { api } from 'api';
import { projectApi } from 'api/completeApi';
import {
  ProjectHubDto,
  ProjectUserProfileDto,
  ProjectUserProfileStatusEnum,
  ServiceError,
} from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import UserStatus from 'components/UserStatus/UserStatus';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { messageError, processApiError } from 'utils';
import NoDeletableUserOfferSuspendingModal from './NoDeletableUserOfferSuspendingModal';

type Props = {
  user: ProjectUserProfileDto;
  error?: ServiceError;
  currentUser: ProjectUserProfileDto;
  reloadUsers: () => void;
  currentProject: ProjectHubDto;
  url: string;
  selectedItemId?: string;
};

export const DELETE_INVITED_USER_ERRORS_TO_GIVE_SUSPEND_EVENTUALITY = [
  'UserIsInWorkflowError',
  'UserIsInCommentProcedureError',
  'UserIsInRoleError',
];

const GeneralTab: FunctionComponent<Props> = ({
  user,
  currentUser,
  error,
  reloadUsers,
  currentProject,
  url,
  selectedItemId,
}) => {
  const intl = useIntl();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSuspend, setLoadingSuspend] = useState(false);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [isReinvited, setIsReinvited] = useState(false);
  const [reasonToOfferSuspending, setReasonToOfferSuspending] = useState<React.ReactNode>(undefined);

  const reinviteProjectUser = useCallback(async () => {
    if (isReinvited) return;
    const [err] = await api.master.organization.reinvite({
      projectUserId: user?.id,
      projectId: currentProject?.id,
      organizationtId: currentProject?.organization.id,
    });
    if (err) {
      messageError(err, intl);
    } else {
      setIsReinvited(true);
      reloadUsers();
    }
  }, [currentProject, intl, isReinvited, reloadUsers, user]);

  const deleteInvited = useCallback(async () => {
    if (loadingDelete) return;
    setLoadingDelete(true);
    const [err] = await api.project.projectUser.deleteInvitedUser(user?.id);
    if (err) {
      const processedError = processApiError(err);
      const isConditionToOfferUserSuspending = DELETE_INVITED_USER_ERRORS_TO_GIVE_SUSPEND_EVENTUALITY.includes(
        processedError.referenceErrorCode
      );
      if (isConditionToOfferUserSuspending) {
        setReasonToOfferSuspending(<Fmt id={`serviceError.${processedError.referenceErrorCode}`} />);
        setLoadingDelete(false);
        return;
      }
      if (!isConditionToOfferUserSuspending) {
        messageError(err, intl);
      }
    } else {
      reloadUsers();
    }
    setLoadingDelete(false);
  }, [intl, loadingDelete, reloadUsers, user?.id]);

  const suspendUser = useCallback(async () => {
    if (loadingSuspend) return;
    setLoadingSuspend(true);
    const [err] = await projectApi.users.id.suspend.post(user?.id);
    if (err) {
      messageError(err, intl);
    } else {
      reloadUsers();
    }
    setLoadingSuspend(false);
  }, [currentProject, intl, loadingSuspend, reloadUsers, user]);

  const activateUser = useCallback(async () => {
    if (loadingActivate) return;
    setLoadingActivate(true);
    const [err] = await projectApi.users.id.activate.post(user?.id);
    if (err) {
      messageError(err, intl);
    } else {
      reloadUsers();
    }
    setLoadingActivate(false);
  }, [currentProject, intl, loadingActivate, reloadUsers, user]);

  const history = useHistory();

  if (!user) return null;
  if (error) return <ServiceErrorBox error={error} />;

  const canModifySelectedUser = currentUser.isAdmin || !user?.isAdmin;

  return (
    <>
      <StackPanel vertical scrollable>
        <GeneralSettingsContainer itemsLargeGap>
          <GeneralSettingsContainer>
            <GeneralSettingsItem title={intl.formatMessage({ id: 'general.userMail' })} description={user.username} />
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'general.userNames' })}
              description={<div>{`${user.firstname || ''} ${user.lastname || ''}`}</div>}
            />
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'general.state' })}
              description={<UserStatus status={user.status} />}
              input={
                <>
                  {user.id === currentUser.id && (
                    <Tag>
                      <Fmt id={'UserDetailPanel.GeneralTab.currentLoggedUser'} />
                    </Tag>
                  )}

                  <FlowLayout wrap>
                    {!user.isConfirmed && (
                      <Button
                        type="primary"
                        size="small"
                        icon={<ReloadOutlined />}
                        onClick={reinviteProjectUser}
                        disabled={isReinvited}
                      >
                        <Fmt id="UserDetailPanel.GeneralTab.reinviteButton" />
                      </Button>
                    )}
                    {user.status === ProjectUserProfileStatusEnum.invited && (
                      <Button
                        type="default"
                        size="small"
                        danger
                        icon={<DeleteIcon />}
                        onClick={deleteInvited}
                        loading={loadingDelete}
                      >
                        <Fmt id="UserDetailPanel.GeneralTab.deleteInvitedButton" />
                      </Button>
                    )}
                  </FlowLayout>

                  {user.status === ProjectUserProfileStatusEnum.active && currentUser.id != user.id && (
                    <Button
                      type="default"
                      size="small"
                      danger
                      icon={<DeleteIcon />}
                      onClick={suspendUser}
                      loading={loadingSuspend}
                      disabled={!canModifySelectedUser}
                    >
                      <Fmt id="UserDetailPanel.GeneralTab.suspendButton" />
                    </Button>
                  )}
                  {user.status === ProjectUserProfileStatusEnum.suspended && (
                    <Button
                      type="primary"
                      size="small"
                      icon={<ReloadOutlined />}
                      onClick={activateUser}
                      loading={loadingActivate}
                      disabled={!canModifySelectedUser}
                    >
                      <Fmt id="UserDetailPanel.GeneralTab.activateButton" />
                    </Button>
                  )}
                </>
              }
            />
            <GeneralSettingsItem title="ID" description={user.id} />
          </GeneralSettingsContainer>
          <GeneralSettingsContainer>
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'UserDetailPanel.RolesTab.rolesListTitle' })}
              icon={<SolutionOutlined />}
              selectable
              selected={selectedItemId === 'roles'}
              onClick={() => {
                history.push(`${url}/roles`);
              }}
            />
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'UserDetailPanel.GroupsTab' })}
              icon={<UsergroupAddOutlined />}
              selectable
              selected={selectedItemId === 'groups'}
              onClick={() => {
                history.push(`${url}/groups`);
              }}
            />
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'UserDetailPanel.PermisionsTab' })}
              icon={<KeyOutlined />}
              selectable
              selected={selectedItemId === 'permissions'}
              onClick={() => {
                history.push(`${url}/permissions`);
              }}
            />
            <GeneralSettingsItem
              title={intl.formatMessage({ id: 'UserDetailPanel.userActivitiesTab' })}
              icon={<HistoryOutlined />}
              selectable
              selected={selectedItemId === 'auditLog'}
              onClick={() => {
                history.push(`${url}/auditLog`);
              }}
            />
          </GeneralSettingsContainer>
        </GeneralSettingsContainer>
      </StackPanel>
      <NoDeletableUserOfferSuspendingModal
        reasonToOfferSuspending={reasonToOfferSuspending}
        onOk={suspendUser}
        onCancel={() => setReasonToOfferSuspending(undefined)}
      />
    </>
  );
};

export default GeneralTab;
