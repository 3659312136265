import { Button } from 'antd';
import { masterApi } from 'api/completeApi';
import classNames from 'classnames';
import { BudgetGrid, WithId } from 'components/BudgetGrid/BudgetGrid';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useKpiConfigurationContext } from 'components/Reports/contexts/KpiSettingsContextProvider';
import StackPanel from 'components/StackPanel/StackPanel';
import { Template } from 'devextreme-react';
import { dxToolbarItem } from 'devextreme/ui/toolbar';
import { dxTreeListColumn } from 'devextreme/ui/tree_list';
import { useApiData, useIntl } from 'hooks';
import { Precisions } from 'hooks/useEsticon';
import { useHeight } from 'hooks/useHeight';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNumberFormatSettings } from 'utils/buildingGridUtils';
import styles from '../../ReportDetailPage.module.less';
import {
  FinancialOverviewByUnitReportData,
  createFinancialOverviewByUnitData,
} from './FinancialOverviewByUnitReportUtils';

type Props = { esticonFirmId: Guid; organizationId: Guid; availableProjectIds: Set<Guid> };

type ColumnType = dxTreeListColumn & {
  dataField?: keyof FinancialOverviewByUnitReportData;
  columns?: dxTreeListColumn & { dataField?: keyof FinancialOverviewByUnitReportData };
};

const ReportPrecission: Precisions = {
  amount: 2,
  price: 2,
  percent: 2,
  unitPrice: 2,
  percentPrecise: 2,
};

const projectLinkRender = (cellInfo: { value?: string; row: { data: FinancialOverviewByUnitReportData } }) => {
  if (!!cellInfo.row.data.projectId)
    return (
      <Link to={`/projects/${cellInfo.row.data.projectId}`}>
        <Button type="link" size="small">
          <Fmt id="FinancialOverviewByUnitReport.projectLink.text" />
        </Button>
      </Link>
    );
  return cellInfo.value;
};

export const SPACE_FOR_SCROLLBAR_COLUMN: ColumnType & { dataField: any } = {
  width: '100%',
  minWidth: 10,
  allowHiding: false,
  allowReordering: false,
  allowSearch: false,
  allowSorting: false,
  showInColumnChooser: false,
  dataField: undefined, // this makes it work with "strongly" typed ColumnType
};

const FinancialOverviewByUnitReport: FunctionComponent<Props> = ({
  esticonFirmId,
  organizationId,
  availableProjectIds,
}) => {
  const intl = useIntl();
  const { tableWrapRef, wrapHeight } = useHeight();
  const [focusedKey, setFocusedKey] = useState<Guid>();
  const { evaluateKpi, kpiSettingsRender } = useKpiConfigurationContext();

  //selectedUnit
  const [reportData, reportError, reportLoading, loadReport] = useApiData(
    (ct) =>
      masterApi.projects.reports.financialoverviewbyunit.post(
        { esticonFirmId: esticonFirmId, organizationId: organizationId },
        ct
      ),
    { autoload: false }
  );
  useEffect(() => {
    if (!!esticonFirmId) {
      loadReport();
    }
  }, [esticonFirmId]);

  const handleFocusRowChange = (data: WithId) => setFocusedKey(data?.id);

  const financialOverviewByUnitData = useMemo(() => {
    if (!reportData) return null;

    return createFinancialOverviewByUnitData(reportData, ReportPrecission, availableProjectIds, intl);
  }, [reportData, intl]);

  const numberFormats = useMemo(() => getNumberFormatSettings(ReportPrecission), []);

  const columns = useMemo(
    (): ColumnType[] => [
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.sign' }),
        minWidth: 150,
        dataField: 'sign',
        fixed: true,
        allowHiding: false,
        allowReordering: false,
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.name' }),
        minWidth: 200,
        cssClass: styles.centered,
        dataField: 'name',
        fixed: true,
        allowHiding: false,
        allowReordering: false,
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.unit' }),
        minWidth: 100,
        cssClass: styles.centered,
        dataField: 'utvar',
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.state' }),
        minWidth: 100,
        cssClass: styles.centered,
        dataField: 'phaseName',
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.investor' }),
        minWidth: 200,
        cssClass: styles.centered,
        dataField: 'investor',
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.startDate' }),
        minWidth: 100,
        cssClass: styles.centered,
        dataField: 'begin',
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.endDate' }),
        minWidth: 100,
        cssClass: styles.centered,
        dataField: 'end',
        dataType: 'string',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.cost' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'cena',
        format: numberFormats.price,
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.invoicing' }),
        cssClass: styles.centered,
        columns: [
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.invoicedSoFar' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'fakturaceDosud',
            format: numberFormats.price,
          },
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.invoiceRemaining' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'fakturaceZbyva',
            format: numberFormats.price,
          },
        ],
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.costs' }),
        cssClass: styles.centered,
        columns: [
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.costSoFar' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'nakladyDosud',
            format: numberFormats.price,
          },
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.costRemaining' }),
            minWidth: 150,
            dataField: 'nakladyZbyva',
            format: numberFormats.price,
          },
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.costTotal' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'nakladyCelkem',
            format: numberFormats.price,
          },
        ],
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.profit' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'profit',
        format: numberFormats.price,
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.coefficient' }),
        minWidth: 150,
        cssClass: classNames(styles.centered, styles.kpiCell),
        dataField: 'coefficient',
        format: numberFormats.price,
        cellTemplate: 'kpiRender',
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.SoW' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'soD',
        format: numberFormats.price,
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.changeOrder' }),
        cssClass: styles.centered,
        columns: [
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.invoice' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'zbvFakturace',
            format: numberFormats.price,
          },
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.proposed' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'zbvNavrhova',
            format: numberFormats.price,
          },
          {
            caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.internal' }),
            minWidth: 150,
            cssClass: styles.centered,
            dataField: 'zbvInterni',
            format: numberFormats.price,
          },
        ],
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.ownPerformance' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'vlastniSubdodavky',
        format: numberFormats.price,
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.subcontracts' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'subdodavky',
        format: numberFormats.price,
      },
      {
        caption: intl.formatMessage({ id: 'FinancialOverviewByUnitReport.column.LinkToProject' }),
        minWidth: 150,
        cssClass: styles.centered,
        dataField: 'projectId',
        cellTemplate: 'projectLink',
      },
      SPACE_FOR_SCROLLBAR_COLUMN,
    ],
    [intl, numberFormats.price]
  );

  const handleToolbarPreparing = useCallback((e: { toolbarOptions: { items: Array<string | dxToolbarItem> } }) => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift({
      widget: 'dxButton',
      location: 'after',
      template: 'kpiSettings',
    });
  }, []);

  return (
    <StackPanel stretch className={styles.reportWrapper}>
      <ContentGate loading={reportLoading} error={reportError}>
        <BudgetGrid
          tableWrapRef={tableWrapRef}
          height={wrapHeight}
          data={financialOverviewByUnitData}
          columns={columns}
          focusedRowKey={focusedKey}
          onFocusedRowChanged={handleFocusRowChange}
          clearFocusOnSearchChange
          onToolbarPreparing={handleToolbarPreparing}
        >
          <Template name="projectLink" render={projectLinkRender} />
          <Template name="kpiSettings" render={kpiSettingsRender} />
          <Template name="kpiRender" render={evaluateKpi} />
        </BudgetGrid>
      </ContentGate>
    </StackPanel>
  );
};

export default React.memo(FinancialOverviewByUnitReport);
