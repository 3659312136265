import { FavoriteProjectListDto } from 'api/completeApiInterfaces';
import { DragIdentifier } from 'components/DragTile/DragTile';
import { DragableGrid } from 'components/DragableGrid/DragableGrid';
import ProjectCard from 'components/ProjectCard';
import React, { FunctionComponent } from 'react';

type Props = {
  projectList: FavoriteProjectListDto[];
  onMove: (from: DragIdentifier, to: DragIdentifier) => void;
  onRemove: (projectId: Guid) => void;
};

const ProjectsGrid: FunctionComponent<Props> = ({ projectList, onMove, onRemove }) => {
  return (
    <DragableGrid
      cardItems={projectList}
      onMove={onMove}
      drawCard={(drag, cardData) => <ProjectCard project={cardData} onRemove={onRemove} dragRef={drag} />}
    />
  );
};

export default ProjectsGrid;
