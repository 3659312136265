import { Button, Input, Modal } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EstiConProjectNoteDto,
  EstiProjectNoteForHubCreateDto,
  EstiProjectNoteForHubDto,
  EstiProjectNoteForHubItemDto,
  EstiProjectNoteForHubPatchDto,
  OrgExtendedPermissionValueEnum,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useCurrentAppUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { dateComparer } from 'utils/comparators';
import uuid from 'uuid';
import { ProjectsInRealisationOverviewReportData } from '../ProjectsInRealizationOverviewReportUtils';
import { ProjectNoteType, ProjectRealizationNote, ReportProjectNote } from './ProjectsInRealizationNote';
import styles from './ProjectsInRealizationNote.module.less';

type Props = {
  onClose: () => void;
  onSave: (notes: EstiProjectNoteForHubDto[]) => void;
  visible: boolean;
  orgUserReportPermission: OrgExtendedPermissionValueEnum;
  organizationId: Guid;
  esticonFirmId: Guid;
  selectedEsticonProject: ProjectsInRealisationOverviewReportData;
  hubProjectNotes: EstiProjectNoteForHubDto[];
};

const mapEsticonNotes = (note: EstiConProjectNoteDto): ReportProjectNote => ({
  type: ProjectNoteType.AspeEsticon,
  id: note.id,
  note: note.note,
  projectId: note.projectId,
  modifiedDate: note.datumZmeny,
  autor: note.autor,
});

const mapAspeHubNotes = (note: EstiProjectNoteForHubItemDto, projectId: Guid): ReportProjectNote => ({
  type: ProjectNoteType.AspeHub,
  id: note.id,
  note: note.note,
  projectId: projectId,
  modifiedDate: note.modifiedDate,
  autor: note.createdBy.username,
  orderId: note.orderId,
  createdDate: note.createdDate,
});

const ProjectsInRealizationNoteModal: FunctionComponent<Props> = (props) => {
  const {
    onClose,
    onSave,
    visible,
    orgUserReportPermission,
    esticonFirmId,
    selectedEsticonProject,
    organizationId,
    hubProjectNotes,
  } = props;
  const intl = useIntl();
  const [newNoteText, setNewNoteText] = useState<string>();
  const [newNoteLoading, setNewNoteLoading] = useState<boolean>(false);
  const currentAppUser = useCurrentAppUser();

  const [esticonNotes, notesError, notesLoading, loadNotes] = useApiData(
    (ct) =>
      masterApi.EsticonReports.orq.id.firms.id.projects.id.projectnotes.get(
        organizationId,
        esticonFirmId,
        selectedEsticonProject.id,
        ct
      ),
    { autoload: false }
  );
  useEffect(() => {
    if (!!esticonFirmId && selectedEsticonProject?.id) {
      loadNotes();
    }
  }, [esticonFirmId, selectedEsticonProject?.id]);

  const existingHubNote = useMemo(
    () => hubProjectNotes?.find((projectNote) => projectNote.esticonProjectId === selectedEsticonProject?.id),
    [hubProjectNotes, selectedEsticonProject?.id]
  );

  const saveNotes = useCallback(
    async (updatedNote: EstiProjectNoteForHubPatchDto) => {
      const [err, res] = await masterApi.projects.reports.esticonprojectnotes.patch(updatedNote);
      if (err) {
        messageError(err, intl);
        return false;
      } else {
        await onSave(res.data);
        setNewNoteText('');
        return true;
      }
    },
    [intl, onSave]
  );

  const handleSave = useCallback(
    async (noteId: Guid, message: string) => {
      if (existingHubNote) {
        const updatedNote: EstiProjectNoteForHubPatchDto = {
          id: existingHubNote.id,
          esticonFirmId: esticonFirmId,
          esticonProjectId: selectedEsticonProject.id,
          nazevAkce: selectedEsticonProject.nazevAkce,
          zahajeni: selectedEsticonProject.zahajeni,
          znacka: selectedEsticonProject.znacka,
          projectNoteItems: [
            ...existingHubNote.projectNoteItems.map((note) =>
              note.id === noteId ? { id: noteId, note: message } : note
            ),
          ],
        };
        return saveNotes(updatedNote);
      }
      return false;
    },
    [currentAppUser, esticonNotes, organizationId, saveNotes]
  );

  const handleCreate = useCallback(async () => {
    setNewNoteLoading(true);

    if (existingHubNote) {
      const updatedNote: EstiProjectNoteForHubPatchDto = {
        id: existingHubNote.id,
        esticonFirmId: esticonFirmId,
        esticonProjectId: selectedEsticonProject.id,
        nazevAkce: selectedEsticonProject.nazevAkce,
        zahajeni: selectedEsticonProject.zahajeni,
        znacka: selectedEsticonProject.znacka,
        projectNoteItems: [...existingHubNote.projectNoteItems, { id: uuid(), note: newNoteText }],
      };
      await saveNotes(updatedNote);
    } else {
      const newNote: EstiProjectNoteForHubCreateDto = {
        esticonFirmId: esticonFirmId,
        esticonProjectId: selectedEsticonProject.id,
        organizationId: organizationId,
        nazevAkce: selectedEsticonProject.nazevAkce,
        zahajeni: selectedEsticonProject.zahajeni,
        znacka: selectedEsticonProject.znacka,
        projectNoteItems: [{ id: uuid(), note: newNoteText }],
      };
      const [err, res] = await masterApi.projects.reports.esticonprojectnotes.post(newNote);
      if (err) {
        messageError(err, intl);
      } else {
        await onSave(res.data);
        setNewNoteText('');
      }
    }
    setNewNoteLoading(false);
  }, [esticonFirmId, existingHubNote, intl, newNoteText, onSave, organizationId, saveNotes, selectedEsticonProject]);

  const handleDelete = useCallback(
    async (noteId: Guid) => {
      if (existingHubNote) {
        const updatedNote: EstiProjectNoteForHubPatchDto = {
          id: existingHubNote.id,
          projectNoteItems: [...existingHubNote.projectNoteItems.filter((note) => note.id !== noteId)],
        };
        return await saveNotes(updatedNote);
      }
      return false;
    },
    [existingHubNote, saveNotes]
  );

  const mergedNotes = useMemo(
    () =>
      [
        ...(esticonNotes?.map(mapEsticonNotes) || []),
        ...(existingHubNote?.projectNoteItems.map((note) => mapAspeHubNotes(note, existingHubNote.esticonProjectId)) ||
          []),
      ]
        .sort(dateComparer.map((project) => project.modifiedDate || project.createdDate))
        .reverse(),

    [esticonNotes, existingHubNote]
  );

  const handleClose = useCallback(() => {
    setNewNoteText('');
    onClose();
  }, [onClose]);

  const canUserEditNotes = orgUserReportPermission === OrgExtendedPermissionValueEnum.write;

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectsInRealisationNoteModal.title' })}
      open={visible}
      width={600}
      onCancel={handleClose}
      onOk={handleClose}
      cancelButtonProps={HIDE_BUTTON_PROPS}
    >
      <ContentGate loading={notesLoading} error={notesError}>
        <StackPanel vertical scrollable className={styles.notePanel}>
          {!!esticonNotes &&
            mergedNotes.map((note) => (
              <ProjectRealizationNote
                key={note.id}
                note={note}
                onEdit={handleSave}
                onDelete={handleDelete}
                canUserEdit={canUserEditNotes}
              />
            ))}
        </StackPanel>
        {canUserEditNotes && (
          <StackPanel>
            <Input value={newNoteText} onChange={(e) => setNewNoteText(e.target.value)} className={styles.newNote} />
            <Button onClick={handleCreate} type="primary" loading={newNoteLoading} disabled={!newNoteText}>
              <Fmt id="general.create" />
            </Button>
          </StackPanel>
        )}
      </ContentGate>
    </Modal>
  );
};

export default ProjectsInRealizationNoteModal;
