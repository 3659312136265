import { Button } from 'antd';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import FileTypeIcon from 'components/FileTypeIcon';
import CommonDerivativesFileViewer, {
  CommonDerivedFileViewerItem,
} from 'components/FileViewer/CommonDerivativesFileViewer';
import { AddIcon, DeleteIcon } from 'components/Icons/HubActionsIcons';
import ImageBox from 'components/ImageBox';
import { useVisibleState } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { messageError } from 'utils';
import styles from './DiscussionInputAttachments.module.less';
import { DiscussionInputAttachmentFile, StagedAttachment } from './DiscussionInputAttachments.utils';

type Props = InjectedIntlProps & {
  attachments: DiscussionInputAttachmentFile[];
  onAttach?: () => void;
  onRemove?: (attachment: Guid) => void;
  onStagedRemove?: (attachment: StagedAttachment) => void;
  stagedAttachments?: Set<StagedAttachment>;
  showAttachOnEmpty?: boolean;
  disableNewLink?: boolean;
  disableNewLinkMessage?: IntlMessageId;
  getOriginalUrl?: (attachmentId: Guid) => ApiPromise<DownloadUrl>;
  allowExistingAttachments?: boolean;
  hideAddItemInput?: boolean;
  highlightedAttachments?: Guid[];
};

const PREVIEW_TRANSFORM: (
  item: DiscussionInputAttachmentFile,
  getOriginalUrl: (previewId: Guid) => Promise<string>
) => CommonDerivedFileViewerItem = (item, getOriginalUrl) => ({
  id: item.id,
  title: item.name,
  blobToken: item.blobToken,
  getOriginalUrl: () => getOriginalUrl(item.id),
});

export const DiscussionInputAttachments: FunctionComponent<Props> = ({
  intl,
  attachments,
  onAttach,
  onRemove,
  onStagedRemove,
  stagedAttachments,
  showAttachOnEmpty,
  disableNewLink,
  disableNewLinkMessage,
  getOriginalUrl,
  hideAddItemInput,
  allowExistingAttachments = true,
  highlightedAttachments,
}) => {
  const [previewId, previewVisible, setPreviewId, hidePreview] = useVisibleState<Guid>();

  const transformItems = useCallback(
    (item) => {
      const handleApiPromiseOriginalUrl = async (attachmentId: Guid) => {
        const [err, resp] = await getOriginalUrl(attachmentId);
        if (err) {
          messageError(err, intl);
          return undefined;
        }
        return resp.data.url;
      };

      return PREVIEW_TRANSFORM(item, handleApiPromiseOriginalUrl);
    },
    [getOriginalUrl]
  );

  const canAddAttachment = onAttach && allowExistingAttachments && (attachments.length > 0 || showAttachOnEmpty);

  const handleThumbnailClick = (e: React.MouseEvent, attachmentId: Guid) => {
    e.stopPropagation();
    setPreviewId(attachmentId);
  };

  const handleAttachmentRemoveClick = (e: React.MouseEvent, attachmentId: Guid) => {
    e.stopPropagation();
    onRemove && onRemove(attachmentId);
  };

  const handleStagedAttachmentRemoveClick = (e: React.MouseEvent, attachment: StagedAttachment) => {
    e.stopPropagation();
    onStagedRemove && onStagedRemove(attachment);
  };

  return (
    <div className={styles.viewer}>
      {attachments.map((attachment) => (
        <ImageBox
          key={attachment.id}
          className={classNames(
            styles.thumbnail,
            highlightedAttachments?.some((a) => a === attachment.id) && styles.warning
          )}
          loading={!attachment.thumbnailUrl === null}
          spinSize="small"
          onClick={(e) => handleThumbnailClick(e, attachment.id)}
        >
          {!!attachment.thumbnailUrl ? (
            <img src={attachment.thumbnailUrl} />
          ) : (
            <FileTypeIcon contentType={attachment.primaryFileContentType} />
          )}
          {onRemove && (
            <Button
              size="small"
              shape="circle"
              icon={<DeleteIcon />}
              onClick={(e) => handleAttachmentRemoveClick(e, attachment.id)}
            />
          )}
        </ImageBox>
      ))}
      {stagedAttachments &&
        Array.from(stagedAttachments).map((attachment) => (
          <ImageBox key={attachment.id} className={`${styles.thumbnail} ${styles.staged}`}>
            <CommonHubTooltip
              title={`${attachment.name} - ${intl.formatMessage({
                id: 'CommentProcedureLinkAttachment.staged.tooltip',
              })} `}
            >
              <div className={styles.stagedWrapper}>
                <img src={attachment.fileUrl} />
              </div>
            </CommonHubTooltip>
            {onStagedRemove && (
              <Button
                size="small"
                shape="circle"
                icon={<DeleteIcon />}
                onClick={(e) => handleStagedAttachmentRemoveClick(e, attachment)}
              />
            )}
          </ImageBox>
        ))}
      {canAddAttachment && !hideAddItemInput && (
        <Button icon={<AddIcon />} onClick={onAttach} disabled={disableNewLink}>
          <Fmt id="CommentProcedureLinkAttachment.tooltip.attach" />
        </Button>
      )}
      <CommonDerivativesFileViewer
        items={attachments}
        previewId={previewId}
        setPreviewDocumentId={setPreviewId}
        visible={previewVisible}
        onCancel={hidePreview}
        transform={transformItems}
      />
    </div>
  );
};

export default injectIntl(DiscussionInputAttachments);
