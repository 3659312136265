import { AuditLogDto } from 'api/completeApiInterfaces';
import React, { FunctionComponent } from 'react';
import styles from './AuditLogGrid.module.less';
import EntityData from './EntityData';
import EventData from './EventData';

type Props = {
  log: AuditLogDto;
};

export const AuditLogRowDisplay: FunctionComponent<Props> = ({ log }) => {
  return (
    <>
      {!!log.entityData && (Object.keys(log.entityData).length > 0 || !log.eventData) && (
        <div className={styles.table}>
          <EntityData log={log} />
        </div>
      )}
      {!!log.eventData && (
        <div className={styles.table}>
          <EventData log={log} />
        </div>
      )}
    </>
  );
};
