import { DownOutlined, InfoCircleOutlined, NumberOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Dropdown, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import styles from './InvalidItemsGroupResolver.module.less';
import { FlowLayout } from 'components/layouts/FlowLayout';

export type InvalidItemsGroupResolverProps = {
  items: ItemType[];
  label?: ReactNode;
  children?: ReactNode;
  type: InvalidItemsGroupResolverType;
  info?: ReactNode;
};

type InvalidItemsGroupResolverType = 'warning' | 'revision' | 'forbidden' | 'info';

const TYPE_TO_CLASS: Record<InvalidItemsGroupResolverType, string> = {
  warning: styles.accentWarning,
  info: styles.accentWarning,
  revision: styles.accentRevision,
  forbidden: styles.accentForbidden,
};

const TYPE_TO_ICON: Record<InvalidItemsGroupResolverType, ReactNode> = {
  warning: <WarningOutlined />,
  info: <InfoCircleOutlined />,
  revision: <NumberOutlined />,
  forbidden: <StopOutlined />,
};

const InvalidItemsGroupResolverComponent = ({ items, label, type, children, info }: InvalidItemsGroupResolverProps) => {
  const className = TYPE_TO_CLASS[type];
  const icon = TYPE_TO_ICON[type];

  return (
    <div>
      <FlowLayout wrap className={className}>
        {icon}
        {label}

        <Dropdown
          trigger={DROPDOWN_TRIGGER_CLICK}
          placement="bottomRight"
          menu={{
            items: items,
          }}
        >
          <Button size="small">
            <div className={styles.statistic}>
              {children}
              <DownOutlined />
            </div>
          </Button>
        </Dropdown>
      </FlowLayout>
      <Typography.Text italic className={styles.description}>
        {info}
      </Typography.Text>
    </div>
  );
};

export const InvalidItemsGroupResolver = React.memo(InvalidItemsGroupResolverComponent);
