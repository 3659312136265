import moment, { isMoment } from 'moment';
import 'moment/locale/cs';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import { ProcessedJsonData } from '../ProcessedJsonViewer';
import styles from './Processor.module.less';

export type MailProcessorData = {
  body: string;
  author: string;
  subject: string;
  from: string;
  to: string;
  cc?: string;
  date: string;
  attachments?: string[];
};

const base64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
};

export const MailProcessor = (data: MailProcessorData, intl: InjectedIntl): ProcessedJsonData => {
  const header = [
    {
      key: 0,
      title: intl.formatMessage({ id: 'EmailViewer.headers.subject' }),
      value: data.subject,
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'EmailViewer.headers.from' }),
      value: data.from,
    },
    {
      key: 2,
      title: intl.formatMessage({ id: 'EmailViewer.headers.to' }),
      value: data.to,
    },
    {
      key: 3,
      title: intl.formatMessage({ id: 'EmailViewer.headers.cc' }),
      value: data.cc,
    },
    {
      key: 4,
      title: intl.formatMessage({ id: 'EmailViewer.headers.date' }),
      value: isMoment(data.date)
        ? data.date
        : moment(data.date)
            .locale(intl.locale)
            .format('LLLL'),
    },
    {
      key: 5,
      title: intl.formatMessage({ id: 'EmailViewer.headers.attachments' }),
      value: data.attachments?.join(', '),
    },
  ];
  return {
    headers: header.filter((h) => !!h.value && h.value.length > 0),
    body: (
      <div className={styles.content}>
        <div
          className={styles.scrollable}
          dangerouslySetInnerHTML={{ __html: data.body && base64DecodeUnicode(data.body) }}
        ></div>
      </div>
    ),
  };
};
