import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import SplitPane, { Props as SplitPaneProps } from 'react-split-pane';
import styles from './VerticalSplitPane.module.less';

type Props = SplitPaneProps & {
  addMargin?: boolean;
  children: ReactNode[];
  scrollable1?: boolean;
  scrollable2?: boolean;
  hidePanel2?: boolean;
};

export const VerticalSplitPanel: FunctionComponent<Props> = ({
  addMargin,
  children,
  className,
  resizerClassName,
  scrollable1,
  scrollable2,
  hidePanel2,
  ...rest
}) => {
  if (!children?.map) {
    console.warn(`VerticalSplitPanel's children do not have a map method: `, children);
    return <>{children}</>;
  }

  return (
    <SplitPane
      split="vertical"
      className={classNames(
        styles.splitPane,
        scrollable1 && styles.scrollablePane1,
        scrollable2 && styles.scrollablePane2,
        hidePanel2 && styles.hidePanel2,
        className
      )}
      resizerClassName={classNames(styles.resizer, resizerClassName)}
      {...rest}
    >
      {children.map((node, index) => (
        <div
          key={index}
          className={classNames(
            styles.container,
            (index === 0 ? scrollable1 : scrollable2) && styles.scrollable,
            addMargin && styles.splitPaneMargin
          )}
        >
          {node}
        </div>
      ))}
    </SplitPane>
  );
};
