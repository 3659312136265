import React, { FunctionComponent } from 'react';
import { useFiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';

const FilterToolbarStyledComponent: FunctionComponent = ({}) => {
  const {
    hasFilteredOutItems,
    clearFilters,
    filters,
    setFilterValue,
    itemCounts,
    isFilterActive,
  } = useFiltersContext();

  if (!isFilterActive) return null;

  return (
    <DocumentsGridHeaderStyled>
      <FilterToolbar
        filters={filters}
        setFilterValue={setFilterValue}
        clearFilters={clearFilters}
        hasFilteredOutItems={hasFilteredOutItems}
        itemCounts={itemCounts}
      />
    </DocumentsGridHeaderStyled>
  );
};

export const FilterToolbarStyled = React.memo(FilterToolbarStyledComponent);
