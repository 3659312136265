import { useCallback, useEffect, useRef, useState } from 'react';

export function useHeight() {
  const tableWrapRef = useRef<HTMLDivElement>(null);
  const [wrapHeight, setWrapHeight] = useState(undefined);
  const [wrapWidth, setWrapWidth] = useState(undefined);

  const onResize = useCallback(() => {
    if (!tableWrapRef.current) return;
    const height = tableWrapRef.current.offsetHeight;
    const width = tableWrapRef.current.offsetWidth;
    setWrapHeight(Math.floor(height) - 1);
    setWrapWidth(Math.floor(width) - 1);
  }, []);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, tableWrapRef.current]);

  return {
    onResize,
    tableWrapRef,
    wrapHeight,
    wrapWidth,
  };
}
