import { FileDto } from 'api/completeApiInterfaces';
import { BcfTopicMarkup } from './topic/markup';
import { BcfViewpoint } from './viewpoint/viewpoint';

export type BcgSnapshot = {
  blob?: Blob;
  file?: FileDto;
};

export type BcfTopic = {
  markup: BcfTopicMarkup;
  viewpoint?: BcfViewpoint;
  snapshot?: Blob;
  file?: FileDto;
};

export type BcfTopicDto = {
  markup: BcfTopicMarkup;
  viewpoint?: BcfViewpoint;
};

export const getBcfTopicId = (topic: BcfTopic) => {
  return topic?.markup.detail.guid;
};
