import { DocumentCategoryTreeDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import React, { FunctionComponent } from 'react';
import { smartFilter } from 'utils';

type Props = {
  data: DocumentCategoryTreeDto[];
  search: string;
  selectedId: Guid;
  deletingItems?: Set<{ id: Guid }>;
  onSelect?: (categoryId: Guid) => void;
  onEdit?: (categoryId: Guid) => void;
  onDelete?: (categoryId: Guid) => void;
  onClearSearch?: () => void;
};

const CategoryList: FunctionComponent<Props> = ({
  data,
  search,
  selectedId,
  deletingItems,
  onSelect,
  onEdit,
  onDelete,
  onClearSearch,
}) => {
  return (
    <GeneralSettingsContainer>
      <List<DocumentCategoryTreeDto>
        data={data}
        search={search}
        filterItem={(item) => smartFilter(item.name, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            title={item.name}
            selected={item.id === selectedId}
            selectable
            onClick={onSelect ? () => onSelect(item.id) : null}
            loading={deletingItems?.has({ id: item.id })}
            additionalActions={
              <>
                {!!onEdit && <EditButton onClick={() => onEdit(item.id)} />}
                {!!onDelete && <DeleteButtonConfirm onConfirm={() => onDelete(item.id)} />}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
    </GeneralSettingsContainer>
  );
};

export default CategoryList;
