import { BellOutlined, BookOutlined } from '@ant-design/icons';
import { MsgCategoryEnum, MsgCategoryTypeEnum, MsgOrderTypeEnum, MsgStatusEnum } from 'api/completeApiInterfaces';
import { SubscribeIcon } from 'components/Icons/HubActionsIcons';
import {
  BulkActionIcon,
  CommentProcedureIcon,
  ProjectIcon,
  ShareEntityIcon,
  TaskIcon,
  WorkflowIcon,
} from 'components/Icons/HubEntitiesIcons';
import { Fmt } from 'locale';
import React, { ReactNode } from 'react';

export const MessageCategoryIcons: Record<MsgCategoryTypeEnum, ReactNode> = {
  workflow: <WorkflowIcon />,
  commentProcedure: <CommentProcedureIcon />,
  appFileShare: <ShareEntityIcon />,
  assignment: <TaskIcon />,
  bulkAction: <BulkActionIcon />,
  alert: undefined, // TODO
  subscriber: <SubscribeIcon />,
  accountNotification: <ProjectIcon />,
  document: <BookOutlined />,
  demandMsg: <BellOutlined />,
};

export const MessageStatus: Partial<Record<MsgStatusEnum, ReactNode>> = {
  new: <Fmt id={'MessageCenterPage.message.status.new'} />,
  read: <Fmt id={'MessageCenterPage.message.status.read'} />,
  ignored: <Fmt id={'MessageCenterPage.message.status.ignored'} />,
  solved: <Fmt id={'MessageCenterPage.message.status.solved'} />,
  finished: <Fmt id={'MessageCenterPage.message.status.finished'} />,
};

export const categoryMap: Partial<Record<MsgCategoryTypeEnum, MsgCategoryEnum[]>> = {
  accountNotification: [MsgCategoryEnum.projectAccountStateChange],
  appFileShare: [MsgCategoryEnum.appFileShare],
  commentProcedure: [
    MsgCategoryEnum.cpClosed,
    MsgCategoryEnum.cpCommentAdded,
    MsgCategoryEnum.cpCommentAttachmentAdded,
    MsgCategoryEnum.cpCommentAttachmentRemoved,
    MsgCategoryEnum.cpCommentDocumentAdded,
    MsgCategoryEnum.cpCommentDocumentRemoved,
    MsgCategoryEnum.cpCommentNoteAdded,
    MsgCategoryEnum.cpCommentNoteUserNotified,
    MsgCategoryEnum.cpCommentProcessorTeamChanged,
    MsgCategoryEnum.cpCommentStateChangded,
    MsgCategoryEnum.cpCommentSubmitterTeamChanged,
    MsgCategoryEnum.cpCommentTextChanged,
    MsgCategoryEnum.cpDocumentAdded,
    MsgCategoryEnum.cpDocumentRemoved,
    MsgCategoryEnum.cpStarted,
    MsgCategoryEnum.cpStateChanged,
    MsgCategoryEnum.cpUserAdded,
    MsgCategoryEnum.cpUserRemoved,
    MsgCategoryEnum.cpDeadlineWarning,
    MsgCategoryEnum.cpDeadlineExpired,
    MsgCategoryEnum.cpDeadlineChanged,
  ],
  workflow: [
    MsgCategoryEnum.wfNodeLimitExceeded,
    MsgCategoryEnum.wfApprovalDocumentsAccepted,
    MsgCategoryEnum.wfApprovalDocumentsRefused,
    MsgCategoryEnum.wfApprovalDocumentAdded,
    MsgCategoryEnum.wfApprovalDocumentRemoved,
    MsgCategoryEnum.wfApprovalDocumentsRevisionAdded,
    MsgCategoryEnum.wfBackgroundDocumentAccepted,
    MsgCategoryEnum.wfBackgroundDocumentAdded,
    MsgCategoryEnum.wfBackgroundDocumentLimitExceeded,
    MsgCategoryEnum.wfBackgroundDocumentLimitWillBeExceeded,
    MsgCategoryEnum.wfBackgroundDocumentRefused,
    MsgCategoryEnum.wfBackgroundDocumentRequestChanged,
    MsgCategoryEnum.wfBackgroundDocumentRequestRemoved,
    MsgCategoryEnum.wfBackgroundDocumentRequestStarted,
    MsgCategoryEnum.wfNodeFinished,
    MsgCategoryEnum.wfNodeLimitWillBeExceeded,
    MsgCategoryEnum.wfNodeStarted,
    MsgCategoryEnum.wfWorkflowClosed,
    MsgCategoryEnum.wfWorkflowFinished,
    MsgCategoryEnum.wfNotification,
    MsgCategoryEnum.wfWorkflowNodeAttachmentAdded,
    MsgCategoryEnum.wfWorkflowNodeAttachmentRemoved,
    MsgCategoryEnum.wfWorkflowNodeAttachmentRestored,
    MsgCategoryEnum.wfWorkflowNodeNoteAdded,
    MsgCategoryEnum.wfWorkflowNodeNoteUserNotified,
  ],
  assignment: [
    MsgCategoryEnum.assignmentDocumentsChanged,
    MsgCategoryEnum.assignmentStarted,
    MsgCategoryEnum.assignmentChanged,
    MsgCategoryEnum.assignmentStateChanged,
    MsgCategoryEnum.assignmentUserAdded,
    MsgCategoryEnum.assignmentUserRemoved,
    MsgCategoryEnum.assignmentUsersChanged,
    MsgCategoryEnum.assignmentNoteAdded,
    MsgCategoryEnum.assignmentNoteUserNotified,
    MsgCategoryEnum.assignmentDeadlineWarning,
    MsgCategoryEnum.assignmentDeadlineExpired,
  ],
  bulkAction: [MsgCategoryEnum.bulkActionReportNotification],
  subscriber: [MsgCategoryEnum.documentSubscriber, MsgCategoryEnum.directorySubscriber],
  document: [
    MsgCategoryEnum.docReleaseReservationAlert,
    MsgCategoryEnum.docReservationReleased,
    MsgCategoryEnum.alertSign,
  ],
};

export const NO_SETTABLE_CATEGORIES: MsgCategoryEnum[] = [
  MsgCategoryEnum.demWorkflowNodeResolveDemand,
  MsgCategoryEnum.demAssignmentResolveDemand,
];

export const MESSAGES_DEFAULT_ORDER = { key: MsgOrderTypeEnum.createTime, desc: false };
export const MARK_AS_READ_DELAY = 1000; // how long must message be displayed in ms before it's marked as read in ms
export const DEFAULT_PAGE_SIZE = 50;
