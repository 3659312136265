import { api } from 'api';
import { AssignmentDto, AssignmentSetStateDto, AssignmentStateEnum } from 'api/completeApiInterfaces';
import {
  AssignmentEditableState,
  ChangeAssignmentStateOption,
} from 'components/AssignmentState/AssignmentEditableState';
import { useIntl } from 'hooks';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { messageError } from 'utils';

type Props = {
  assignment: AssignmentDto;
  updateAssignment: (assignment: AssignmentDto | null) => void;
  isAuthor: boolean;
  isSolver: boolean;
};

export const AssignmentStateField: FunctionComponent<Props> = ({
  assignment,
  updateAssignment,
  isAuthor,
  isSolver,
}) => {
  const [saving, setSaving] = useState(false);

  const intl = useIntl();

  const targetStates = useMemo(() => {
    const statesMut: ChangeAssignmentStateOption[] = [];
    if (assignment.state === AssignmentStateEnum.new && isSolver) {
      statesMut.push({
        state: AssignmentStateEnum.inProgress,
        label: intl.formatMessage({ id: 'AssignmentStateField.startSolving' }),
      });
    }
    if (assignment.state === AssignmentStateEnum.inProgress && isSolver) {
      statesMut.push({
        state: AssignmentStateEnum.forApproval,
        label: intl.formatMessage({ id: 'AssignmentStateField.submitForApproval' }),
      });
    }
    if (assignment.state === AssignmentStateEnum.forApproval && isAuthor) {
      statesMut.push({
        state: AssignmentStateEnum.inProgress,
        label: intl.formatMessage({ id: 'AssignmentStateField.returnToSolver' }),
      });
      statesMut.push({
        state: AssignmentStateEnum.solved,
        label: intl.formatMessage({ id: 'AssignmentStateField.approveAndClose' }),
      });
    }
    if (assignment.state === AssignmentStateEnum.forApproval && isSolver && !isAuthor) {
      statesMut.push({
        state: AssignmentStateEnum.inProgress,
        label: intl.formatMessage({ id: 'AssignmentStateField.continueSolving' }),
      });
    }
    if (
      assignment.state !== AssignmentStateEnum.solved &&
      assignment.state !== AssignmentStateEnum.closed &&
      isAuthor
    ) {
      statesMut.push({
        state: AssignmentStateEnum.closed,
        label: intl.formatMessage({ id: 'AssignmentStateField.cancel' }),
      });
    }
    return statesMut;
  }, [assignment.state, isAuthor, isSolver, intl]);

  const handleSave = async (state: AssignmentStateEnum) => {
    const data: AssignmentSetStateDto = { newState: state };

    setSaving(true);
    const [err, resp] = await api.project.assignments.changeState(assignment.id, data);
    setSaving(false);

    if (err) {
      messageError(err, intl);
    } else {
      updateAssignment(resp.data);
    }
  };

  return (
    <AssignmentEditableState value={assignment.state} onChange={handleSave} options={targetStates} loading={saving} />
  );
};
