import { AuditLogSessionDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import { ListItem } from 'pages/ProjectSettingsPage/ListItem';
import React, { FunctionComponent } from 'react';
import styles from './SessionListItem.module.less';

type Props = {
  session: AuditLogSessionDto;
  handleSessionSelect: React.Dispatch<React.SetStateAction<string>>;
  showLogModal: () => void;
};

export const SessionListItem: FunctionComponent<Props> = (props) => {
  const { session, handleSessionSelect, showLogModal } = props;

  const intl = useIntl();

  const handleClick = () => {
    handleSessionSelect(session.sessionHash);
    showLogModal();
  };

  return (
    <ListItem onClick={handleClick}>
      <StackPanel vertical className={classNames(styles.container)}>
        <div className={styles.dateline}>
          <span>
            {session.sessionStart && (
              <>
                <Fmt id={`SessionListItem.sessionStart`} />
                {moment(session.sessionStart)
                  .locale(intl.locale)
                  .format('LLL')}
              </>
            )}
          </span>
          <span>
            {session.sessionEnd && (
              <>
                <Fmt id={`SessionListItem.sessionEnd`} />
                {moment(session.sessionEnd)
                  .locale(intl.locale)
                  .format('LLL')}
              </>
            )}
          </span>
        </div>
        <div>
          <Fmt id={`SessionListItem.IpAddress`} />
          {session.ipAddress}
        </div>
      </StackPanel>
    </ListItem>
  );
};
