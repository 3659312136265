import { Icon as LegacyIcon } from '@ant-design/compatible';
import { ProjectUserProfileListDto, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import classnames from 'classnames';
import { AuditLogOpenButton } from 'components/AuditLogsComponents/AuditLogOpenButton';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import UserStatus from 'components/UserStatus/UserStatus';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import styles from './UsersItem.module.less';

type Props = {
  user: ProjectUserProfileListDto;
  status?: ProjectUserProfileStatusEnum;
  onActivity?: (user: ProjectUserProfileListDto) => void;
  noPadding?: boolean;
  emailLink?: boolean;
};

export const UsersItem: FunctionComponent<Props> = ({ user, status, emailLink, onActivity, noPadding }) => {
  const { username } = user || {};

  const intl = useIntl();

  return (
    <div className={classnames(styles.wrapper, noPadding && styles.noPadding)}>
      <div className={styles.avatar}>
        <UserIcon size={UserAvatarSize.Large} user={user} />
      </div>
      <div className={styles.body}>
        <FlowLayout growFirst>
          <div className={styles.name} title={username}>
            {username}
          </div>
          {status && <UserStatus status={status} />}
        </FlowLayout>
        <span
          className={styles.usernameContainer}
          title={`${intl.formatMessage({ id: 'general.userName' })}: ${username}`}
        >
          <LegacyIcon type={'mail'} className={styles.emailIcon} />
          {emailLink ? (
            <a target="_blank" href={`mailto:${username}`} className={styles.username} rel="noreferrer">
              {username}
            </a>
          ) : (
            <span className={styles.username}>{username}</span>
          )}
        </span>
      </div>
      {onActivity && (
        <div className={styles.activity}>
          <AuditLogOpenButton onClick={() => onActivity(user)} />
        </div>
      )}
    </div>
  );
};
