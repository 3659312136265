import { api } from 'api';
import { WorkflowDto } from 'api/completeApiInterfaces';
import {
  availableRolesForWorkflow,
  isEmpty,
} from 'components/forms/WorkflowInstantiationForm/WorkflowInstantiationButton';
import { useActiveProject } from 'hooks/useActiveProject';
import { useApiData } from 'hooks/useApiData';
import { useBoolean } from 'hooks/useBoolean';
import { useIntl } from 'hooks/useIntl';
import { useSameCallback } from 'hooks/useSameCallback';
import { useCurrentProjectUser } from 'hooks/useUser';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const useWorkflowInstantiation = (
  disabled?: boolean,
  title?: string,
  redirect?: boolean,
  onSuccess?: (data: WorkflowDto) => void
) => {
  const intl = useIntl();
  const [modalFormVisible, modalFormShow, modalFormHide] = useBoolean(false);

  const activeProject = useActiveProject();

  // load workflow templates TODO-KR change to use store
  const [templates, templatesError] = useApiData(api.project.workflow.getWorkflowTemplatesList, {
    autoload: true,
  });

  // filter visible workflow templates
  const currentUser = useCurrentProjectUser();
  const availableTemplates = useMemo(
    () => templates?.filter((template) => availableRolesForWorkflow(template, currentUser).size > 0),
    [templates, currentUser]
  );

  // computed values
  const isDisabled = disabled || isEmpty(availableTemplates);
  const shownTitle =
    title ||
    templatesError?.message ||
    (isEmpty(currentUser?.roles) && intl.formatMessage({ id: 'WorkflowInstantiationButton.noRoles' })) ||
    (isEmpty(availableTemplates) ? intl.formatMessage({ id: 'WorkflowInstantiationButton.noTemplates' }) : undefined);

  const history = useHistory();

  // callbacks
  const handleSubmit = useSameCallback((data: WorkflowDto) => {
    onSuccess && onSuccess(data);
    redirect && history.push(`/projects/${activeProject.id}/workflow/${data.id}`);
    modalFormHide();
  });

  return useMemo(() => {
    return {
      modalFormShow,
      isDisabled,
      shownTitle,
      handleSubmit,
      modalFormVisible,
      modalFormHide,
      availableTemplates,
    };
  }, [modalFormShow, isDisabled, shownTitle, handleSubmit, modalFormVisible, modalFormHide, availableTemplates]);
};
