import { AutoComplete, Input, Typography } from 'antd';
import { EstiCategoryEnum, EsticonTemplateEnum } from 'api/completeApiInterfaces';
import {
  esticonDirectoryTemplateDescriptions,
  esticonDirectoryTemplateOverrides,
} from 'api/project/projectSetting/projectSettingApiConstants';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { useIntl, useSameCallback, useStoreSelector } from 'hooks';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './EsticonMaskInput.module.less';

const EXTRACT_TAG_REGEX = /<([^<>]+)>/g;

const findOverrideTranslation = (category: EstiCategoryEnum, template: EsticonTemplateEnum) => {
  if (
    esticonDirectoryTemplateOverrides[category] === undefined ||
    esticonDirectoryTemplateOverrides[category][template] === undefined
  )
    return null;

  return esticonDirectoryTemplateOverrides[category][template];
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  error: string;
  onError: (error: string) => void;
  category: EstiCategoryEnum;
  templates: EsticonTemplateEnum[];
};

const EsticonMaskInputComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  error,
  onError,
  templates,
  category,
}) => {
  const templateToTag = useStoreSelector((state) => state.appSettings.data.esticonTemplateTags);

  const autocompleteContainerRef = useRef();

  const validTagSet = useMemo(() => new Set(templates.map((template) => templateToTag[template])), [templateToTag]);
  const intl = useIntl();

  // display error based on value
  useEffect(() => {
    if (!value?.length) {
      onError(intl.formatMessage({ id: 'EsticonMaskInput.error.empty' }));
      return;
    }

    const invalidTag = [...value.matchAll(EXTRACT_TAG_REGEX)]
      .map((result) => result[1])
      .find((tag) => !validTagSet.has(tag));
    if (!!invalidTag) {
      onError(intl.formatMessage({ id: 'EsticonMaskInput.error.invalidTag' }, { tag: invalidTag.slice(0, 10) }));
      return;
    }

    // replace all valid tags with a "safe" character, like an underscore
    const replaced = templates.length
      ? value.replaceAll(new RegExp(templates.map((template) => `<${templateToTag[template]}>`).join('|'), 'g'), '_')
      : value;

    // if there are still tag marks at this point, show the "invalid tag format" error
    if (replaced.match(/.*[<>].*/)) {
      onError(intl.formatMessage({ id: 'EsticonMaskInput.error.invalidTagFormat' }));
      return;
    }

    // otherwise, show generic "wrong format" message
    if (!replaced.match(FILE_AND_FOLDER_NAME_REGEX)) {
      onError(intl.formatMessage({ id: 'EsticonMaskInput.error.invalidCharacters' }));
      return;
    }

    // no error
    onError(null);
  }, [value, intl]);

  // track cursor position
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const updateCursorPosition = useCallback((event: React.SyntheticEvent<HTMLDivElement, Event>) => {
    if (event.target instanceof HTMLInputElement) {
      setCursorPosition(event.target.selectionStart);
    }
  }, []);

  // precompute partial tag
  const [tagStartIndex, tagEndIndex, tagPrefix] = useMemo(() => {
    const beforeCursor = value.slice(0, cursorPosition);
    const tagStart = beforeCursor.lastIndexOf('<');
    const tagPrefix = tagStart >= 0 ? beforeCursor.slice(tagStart + 1) : null;

    const afterCursor = value.slice(cursorPosition);
    const nextTagStart = afterCursor.indexOf('<');
    const nextTagEnd = afterCursor.indexOf('>');

    const tagEndOffset = nextTagStart < 0 ? nextTagEnd : nextTagEnd < nextTagStart ? nextTagEnd : -1;
    const tagEnd = tagEndOffset < 0 ? tagEndOffset : tagEndOffset + cursorPosition;

    return [tagStart, tagEnd, tagPrefix] as const;
  }, [value, cursorPosition]);

  // filter possible tags base on tagPrefix
  const activeSuggestions = useMemo(
    () =>
      tagPrefix !== null
        ? templates.filter((template) =>
            templateToTag[template].toLocaleLowerCase().startsWith(tagPrefix.toLocaleLowerCase())
          )
        : [],
    [tagPrefix, templates, templateToTag]
  );

  // replace current partial tag with selected tag
  const onSuggestionSelected = useSameCallback((template: EsticonTemplateEnum) => {
    if (tagStartIndex < 0) return;
    const textBefore = value.slice(0, tagStartIndex);
    const textAfter = value.slice(tagEndIndex > 0 ? tagEndIndex + 1 : cursorPosition);
    const nextValue = `${textBefore}<${templateToTag[template]}>${textAfter}`;
    onChange(nextValue);
  });

  const dataSource = activeSuggestions.map((template) => ({
    value: template,
    text: `<${templateToTag[template]}> - ${intl.formatMessage({
      id: findOverrideTranslation(category, template) || esticonDirectoryTemplateDescriptions[template],
    })}`,
  }));

  const getAutocompleteContainer = useCallback(() => autocompleteContainerRef.current, []);

  return (
    <div ref={autocompleteContainerRef} className={styles.inputWrapper}>
      <AutoComplete
        dataSource={dataSource}
        value={value}
        onChange={onChange}
        dropdownMatchSelectWidth={false}
        onSelect={onSuggestionSelected}
        getPopupContainer={getAutocompleteContainer}
      >
        <Input
          onKeyUp={updateCursorPosition}
          onClick={updateCursorPosition}
          className={error && styles.inputErrorBorder}
        />
      </AutoComplete>
      <div className={styles.errorWrapper}>
        <Typography.Text type="danger" className={styles.errorMessage}>
          {error}
        </Typography.Text>
      </div>
    </div>
  );
};

export const EsticonMaskInput = React.memo(EsticonMaskInputComponent);
