import SparkMD5 from 'spark-md5';

type ProgressEvent = (percent: number, currentChunk: number, chunks: number) => void;

export const fileMd5 = (blob: Blob, onProgress: ProgressEvent = () => {}) => {
  return new Promise<string>((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    // const blobSlice = Blob.prototype.slice; // || Blob.prototype.mozSlice || Blob.prototype.webkitSlice;
    const chunkSize = 67108864; // Read in chunks of 64MB
    const chunks = Math.ceil(blob.size / chunkSize);
    let currentChunk = 0;

    fileReader.onload = function(e) {
      const target = <FileReader>e.target;
      spark.append(target.result as ArrayBuffer); // Append array
      currentChunk++;
      onProgress((currentChunk / chunks) * 100, currentChunk, chunks);

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = spark.end();
        resolve(hash);
      }
    };

    fileReader.onerror = function(error) {
      console.warn('oops, something went wrong.');
      reject(error);
    };

    const loadNext = () => {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= blob.size ? blob.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blob.slice(start, end));
    };

    loadNext();
  });
};
