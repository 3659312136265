import { Divider, List, Modal } from 'antd';
import { DocumentBulkActionSetResultDto } from 'api/completeApiInterfaces';
import { Link } from 'components/Link/Link';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useActiveProject, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

enum BulkStateSetResultEnum {
  updatedDocuments = 'updatedDocuments',
  updatedLinks = 'updatedLinks',
  unchangedDocuments = 'unchangedDocuments',
  unchangedLinks = 'unchangedLinks',
}

type Props = {
  result: DocumentBulkActionSetResultDto;
  visible: boolean;
  onClose: () => void;
  title: ReactNode;
  showReportLink: boolean;
};

const BulkEditActionResultModal: FunctionComponent<Props> = (props) => {
  const { visible, result, title, onClose, showReportLink } = props;
  const intl = useIntl();
  const { id: activeProjectId } = useActiveProject();

  const valueDescription = useCallback(
    (valueKey: BulkStateSetResultEnum) => {
      return intl.formatMessage({ id: `BulkEditActionResultModal.content.${valueKey}` });
    },
    [intl]
  );

  const successModalData = useMemo(() => {
    const successResultArray: [string, number][] = !!result ? Object.entries(result) : [];
    return successResultArray
      .map((record) => ({ key: record[0] as BulkStateSetResultEnum, value: record[1] }))
      .map((item) => <div key={item.key}>{`${valueDescription(item.key)}: ${item.value}`}</div>);
  }, [result, valueDescription]);

  return (
    <Modal
      key="successModal"
      title={title}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={HIDE_BUTTON_PROPS}
      width={700}
    >
      <List
        size="small"
        dataSource={successModalData}
        renderItem={(item) => <List.Item key={item.key}>{item}</List.Item>}
      />
      {showReportLink && (
        <>
          <Divider />
          <Link to={`/projects/${activeProjectId}/messagecenter/messages`}>
            <Fmt id={'BulkEditActionResultModal.moveTo'} />
          </Link>
        </>
      )}
    </Modal>
  );
};

export default injectIntl(BulkEditActionResultModal);
