import { HddOutlined } from '@ant-design/icons';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { getDefaultRootName } from 'utils/getRootName';
import { Space } from 'antd';

export interface PathSelectorProps {
  link: string;
  isLast?: boolean;
  rootNode: { name?: string; id: Guid };
}

const PathRoot: FunctionComponent<PathSelectorProps> = ({ link, isLast, rootNode }) => {
  const rootName = getDefaultRootName(rootNode);

  return (
    <Link to={link + rootNode.id} title={'/' + rootName}>
      <Space>
        <HddOutlined />
        {isLast ? (
          <strong>
            <DisplayName>{rootName}</DisplayName>
          </strong>
        ) : (
          <DisplayName>{rootName}</DisplayName>
        )}
      </Space>
    </Link>
  );
};

export default React.memo(PathRoot);
