import { RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './PathDisplay.module.less';

type Props = {
  parts: ReactNode[];
  className?: string;
  inline?: boolean;
  alignLeft?: boolean;
  tooltip?: ReactNode;
};

export const PathPartsDisplay: FunctionComponent<Props> = ({ className, inline, alignLeft, parts, tooltip }) => {
  const partsLink = parts.map((part: ReactNode, i: number) => {
    return (
      <span className={styles.part} key={i}>
        {i > 0 && <RightOutlined className={styles.arrow} />}
        <DisplayName>{part}</DisplayName>
      </span>
    );
  });

  return (
    <CommonHubTooltip
      className={classNames(styles.container, inline && styles.inline, alignLeft && styles.containerLeft, className)}
      title={tooltip}
    >
      {partsLink}
    </CommonHubTooltip>
  );
};
