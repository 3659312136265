import classNames from 'classnames';
import DocumentLinkButton from 'components/DocumentLinkButton';
import DocumentThumbnail from 'components/DocumentThumbnail';
import StyledSelectableRow from 'components/StyledSelectableRow';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import { DisabledWithReason } from 'utils/types';
import styles from './CommonDocumentRow.module.less';

export enum DocumentRowType {
  Document = 'Document',
  Link = 'Link',
  UnaccessibleLink = 'UnaccessibleLink',
  DeletedDocumentLink = 'DeletedDocumentLink',
}
export type CommonDocumentRowProps = {
  id: Guid;
  thumbnailUrl?: string;
  primaryFileContentType?: string;
  onThumbnailClick: (id: Guid) => void;
  style?: React.CSSProperties;
  onClick?: () => void;
  onClickCheckbox?: () => void;
  selected?: boolean;
  hideSelect?: boolean;
  disableSelect?: DisabledWithReason;
  documentRowType?: DocumentRowType;
  onDocumentLinkClick?: (documentLinkId: Guid) => void;
  documentLinkTooltip?: string;
  children: React.ReactNode;
};

const CommonDocumentRow: FunctionComponent<CommonDocumentRowProps> = ({
  id,
  thumbnailUrl,
  onThumbnailClick,
  primaryFileContentType,
  style,
  hideSelect,
  selected,
  onClick,
  onClickCheckbox,
  disableSelect,
  documentRowType = DocumentRowType.Document,
  onDocumentLinkClick,
  documentLinkTooltip,
  children,
}) => {
  const intl = useIntl();
  const isSelectable = !disableSelect;
  const isDocumentLink =
    documentRowType === DocumentRowType.Link ||
    documentRowType === DocumentRowType.DeletedDocumentLink ||
    documentRowType === DocumentRowType.UnaccessibleLink;
  const hasDocumentAccess = documentRowType === DocumentRowType.Document || documentRowType === DocumentRowType.Link;

  return (
    <StyledSelectableRow
      id={id}
      onClick={onClick}
      onClickCheckbox={onClickCheckbox}
      disableSelect={!isSelectable}
      hideSelect={hideSelect}
      selected={selected}
      style={style}
      className={classNames(styles.row)}
      wrapperClassName={classNames(styles.wrapper)}
      checkboxTooltip={disableSelect === true || disableSelect === false ? undefined : disableSelect}
    >
      <DocumentThumbnail
        thumbnailUrl={thumbnailUrl}
        primaryFileContentType={primaryFileContentType}
        onThumbnailClick={onThumbnailClick}
        hasAccess={hasDocumentAccess}
        id={id}
      />
      {isDocumentLink && (
        <DocumentLinkButton
          id={id}
          onLinkClick={onDocumentLinkClick}
          className={classNames(styles.linkIcon, hasDocumentAccess && styles.activeLink)}
          tooltip={intl.formatMessage({ id: 'DocumentLinkRenameFormModal.title' })}
        />
      )}
      {children}
    </StyledSelectableRow>
  );
};

export default React.memo(CommonDocumentRow);
