import { invert } from 'lodash';

// Must be manually synchronized with backend
const KEY_CHAR_MAP = {
  '0': 'd',
  '1': 'z',
  '2': 'H',
  '3': 'l',
  '4': '/',
  '5': 'k',
  '6': 'E',
  '7': 'p',
  '8': 'u',
  '9': 'N',
  '+': '4',
  '/': '0',
  A: 'D',
  B: 'h',
  C: 'T',
  D: 'c',
  E: 'm',
  F: '7',
  G: 'X',
  H: '9',
  I: 'A',
  J: 'r',
  K: 'q',
  L: 'U',
  M: 'x',
  N: 'B',
  O: 'O',
  P: 'Z',
  Q: 'y',
  R: 'R',
  S: 'g',
  T: 'M',
  U: 'I',
  V: 'G',
  W: 'b',
  X: 'w',
  Y: 'f',
  Z: 's',
  a: 'F',
  b: 'o',
  c: '2',
  d: 'Q',
  e: '3',
  f: 'K',
  g: '8',
  h: 'e',
  i: 'Y',
  j: '6',
  k: 'W',
  l: 'L',
  m: 'n',
  n: 'i',
  o: 'V',
  p: 't',
  q: 'a',
  r: 'j',
  s: '5',
  t: 'P',
  u: '1',
  v: '+',
  w: 'C',
  x: 'J',
  y: 'S',
  z: 'v',
  '=': '=',
};

export const decryptLicenceKey = (licenceKey: string): string => {
  const reversedMap = invert(KEY_CHAR_MAP);
  let decrypted = '';
  for (let i = 0; i < licenceKey.length; i++) {
    decrypted += reversedMap[licenceKey.slice(i, i + 1)];
  }
  return window.atob(decrypted);
};
