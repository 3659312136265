import classNames from 'classnames';
import { DragHook, DragIdentifier, DragTile } from 'components/DragTile/DragTile';
import React, { ReactNode } from 'react';
import styles from './DragableGrid.module.less';

type Props<T extends { id: Guid }> = {
  cardItems: T[];
  onMove: (from: DragIdentifier, to: DragIdentifier) => void;
  drawCard: (drag: DragHook, cardData: T) => ReactNode;
  className?: string;
  children?: ReactNode;
};

export function DragableGrid<T extends { id: Guid }>({ cardItems, onMove, drawCard, className, children }: Props<T>) {
  return (
    <div className={styles.container}>
      {cardItems?.map((cardData, index) => (
        <div className={classNames(styles.card, className)} key={cardData.id}>
          <DragTile onMove={onMove} itemId={index}>
            {(drag) => drawCard(drag, cardData)}
          </DragTile>
        </div>
      ))}
      {children}
    </div>
  );
}
