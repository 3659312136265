import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { ExtendedPermissionEnum, ExtendedPermissionTemplateDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import { PermissionsDrawerFormItem } from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsDrawerFormItem';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type UserPermissionProfileFormData = {
  name: string;
  description: string;
  activePermissions: ExtendedPermissionEnum[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = FormComponentProps<UserPermissionProfileFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    defaults?: ExtendedPermissionTemplateDto;
  };

const UserPermissionProfileFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, validateUniqueName, defaults }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const defaultExtendedPermissions = useMemo(
      () =>
        defaults?.extendedPermissions.settings
          .filter((setting) => setting.permission)
          .map((setting) => setting.permissionType) || [ExtendedPermissionEnum.stavba],
      [defaults]
    );

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {form.getFieldDecorator<UserPermissionProfileFormData>('name', {
            initialValue: defaults?.name,
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.projectTemplateDto.name.maxLength),
              duplicateNameRuleCallback(validateUniqueName),
            ],
          })(<Input autoFocus />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {form.getFieldDecorator<UserPermissionProfileFormData>('description', {
            initialValue: defaults?.description,
            rules: [
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              maxLengthRule('general.maxDescriptionLength', apiConstraints.projectTemplateDto.description.maxLength),
            ],
          })(<Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.userPermissionProfile.label' })}>
          {form.getFieldDecorator<UserPermissionProfileFormData>('activePermissions', {
            initialValue: defaultExtendedPermissions,
          })(<PermissionsDrawerFormItem />)}
        </Form.Item>
      </Form>
    );
  }
);

export const UserPermissionProfileForm = React.memo(Form.create<Props>()(UserPermissionProfileFormComponent));
