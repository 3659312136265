import { DependencyList, useCallback, useRef } from 'react';

const EMPTY_DEPS_ARRAY: DependencyList = [];

/**
 * Returns a memoized **mutable** callback, that will always have the same reference,
 * but it's behaviour will update with each render.
 * @param callback Function to be called when the result of this hook is called.
 * @returns An identical function, that will call the `callback` argument from the last render.
 */
export const useSameCallback = <P extends unknown[], Q extends void | Promise<unknown>>(
  callback: (...params: P) => Q
) => {
  const innerCallback = useRef(callback);
  innerCallback.current = callback;

  return useCallback((...params: P) => innerCallback.current(...params), EMPTY_DEPS_ARRAY);
};
