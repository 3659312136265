import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './StateIcon.module.less';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';

type Props = {
  title: ReactNode;
  className: string;
  titleClassName?: string;
  tooltipPlacement?: TooltipPlacement;
  onClick?: () => void;
  trigger?: TooltipProps['trigger'];
};

export const StateIcon: FunctionComponent<Props> = ({
  title,
  className,
  titleClassName,
  children,
  tooltipPlacement = 'leftTop',
  onClick,
  trigger,
}) => {
  const content = (
    <span className={classNames(styles.badge, className)} onClick={onClick}>
      {children}
    </span>
  );

  if (!title) return content;

  return (
    <NoClickTooltip
      title={title}
      placement={tooltipPlacement}
      overlayClassName={titleClassName}
      trigger={trigger}
      mouseEnterDelay={1}
    >
      {content}
    </NoClickTooltip>
  );
};
