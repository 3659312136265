import { ProjectsInRealizationPropertyType } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { dxTreeListColumn } from 'devextreme/ui/tree_list';
import { InjectedIntl } from 'react-intl';
import { GridNumberFormats } from 'utils/buildingGridUtils';
import styles from './ProjectsInRealizationOverviewReport.module.less';
import {
  ProjectsInRealisationOverviewReportData
} from './ProjectsInRealizationOverviewReportUtils';

const getMonthDefinitions = (intl: InjectedIntl): { caption: string, dataFieldSuffix: string }[] => [
  { caption: intl.formatMessage({ id: 'general.month.january' }), dataFieldSuffix: '1' },
  { caption: intl.formatMessage({ id: 'general.month.february' }), dataFieldSuffix: '2' },
  { caption: intl.formatMessage({ id: 'general.month.march' }), dataFieldSuffix: '3' },
  { caption: intl.formatMessage({ id: 'general.month.april' }), dataFieldSuffix: '4' },
  { caption: intl.formatMessage({ id: 'general.month.may' }), dataFieldSuffix: '5' },
  { caption: intl.formatMessage({ id: 'general.month.june' }), dataFieldSuffix: '6' },
  { caption: intl.formatMessage({ id: 'general.month.july' }), dataFieldSuffix: '7' },
  { caption: intl.formatMessage({ id: 'general.month.august' }), dataFieldSuffix: '8' },
  { caption: intl.formatMessage({ id: 'general.month.september' }), dataFieldSuffix: '9' },
  { caption: intl.formatMessage({ id: 'general.month.october' }), dataFieldSuffix: '10' },
  { caption: intl.formatMessage({ id: 'general.month.november' }), dataFieldSuffix: '11' },
  { caption: intl.formatMessage({ id: 'general.month.december' }), dataFieldSuffix: '12' },
]

const createPerMonthColumns = (dataFieldTotal: keyof ProjectsInRealisationOverviewReportData, dataFieldMonthBase: string, parentKey: ProjectsInRealizationPropertyType, intl: InjectedIntl, numberFormats: GridNumberFormats, cellTemplate?: string): ProjectInRealizationOverviewChildColumnType[] =>
  [{
    caption: intl.formatMessage({ id: "general.total" }),
    cssClass: classNames(styles.centered, cellTemplate && styles.noMargin),
    dataField: dataFieldTotal,
    format: numberFormats.price,
    minWidth: 80,
    width: 180,
    cellTemplate: cellTemplate,
    validationValueSource: dataFieldTotal
  },
  ...getMonthDefinitions(intl).map((month): ProjectInRealizationOverviewChildColumnType => ({
    parentColumnKey: parentKey,
    caption: month.caption,
    cssClass: classNames(styles.centered, cellTemplate && styles.noMargin),
    dataField: `${dataFieldMonthBase}${month.dataFieldSuffix}` as keyof ProjectsInRealisationOverviewReportData,
    format: numberFormats.price,
    visible: false,
    minWidth: 60,
    width: 80,
    allowReordering: false,
    allowHiding: false,
    cellTemplate: cellTemplate,
    validationValueSource: dataFieldTotal
  }))
  ];

export type ProjectInRealizationOverviewChildColumnType = dxTreeListColumn & { dataField?: keyof ProjectsInRealisationOverviewReportData, parentColumnKey?: ProjectsInRealizationPropertyType, validationValueSource?: keyof ProjectsInRealisationOverviewReportData };

export type ProjectsInRealisationOverviewColumnType = Omit<dxTreeListColumn, 'columns'> & {
  columnKey?: ProjectsInRealizationPropertyType;
  dataField?: keyof ProjectsInRealisationOverviewReportData;
  columns?: ProjectInRealizationOverviewChildColumnType[];
};

export const SPACE_FOR_SCROLLBAR_COLUMN: ProjectsInRealisationOverviewColumnType & { dataField: any } = {
  width: '100%',
  minWidth: 10,
  allowHiding: false,
  allowReordering: false,
  allowSearch: false,
  allowSorting: false,
  showInColumnChooser: false,
  dataField: undefined, // this makes it work with "strongly" typed ColumnType
};

export const getRealizationOverviewReportColumns = (reportYear: number, intl: InjectedIntl, numberFormats: GridNumberFormats): ProjectsInRealisationOverviewColumnType[] => [
  {
    columnKey: ProjectsInRealizationPropertyType.Type,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.io' }),
    minWidth: 80,
    cssClass: styles.centered,
    dataField: 'typ',
    fixed: true,
    allowHiding: false,
    allowReordering: false,
    dataType: 'string',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Unit,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.unit' }),
    minWidth: 60,
    cssClass: classNames(styles.centered, styles.noMargin),
    dataField: 'utvar',
    fixed: true,
    allowHiding: false,
    allowReordering: false,
    dataType: 'string',
    cellTemplate: 'realizationUnit',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Subsidies,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.subsidies' }),
    minWidth: 80,
    cssClass: styles.centered,
    dataField: 'uzivatelskeZatrideniProjektuZnacka',
    dataType: 'string',
    fixed: true,
    allowHiding: false,
    allowReordering: false,
    cellTemplate: 'subsidiesWithTooltip'
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Name,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.projectName' }),
    minWidth: 220,
    cssClass: styles.centered,
    fixed: true,
    allowHiding: false,
    allowReordering: false,
    dataField: 'nazevAkce',
    dataType: 'string',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Sign,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.projectSign' }),
    minWidth: 120,
    cssClass: styles.centered,
    dataField: 'znacka',
    dataType: 'string',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Supervisor,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.supervisor' }),
    minWidth: 130,
    cssClass: styles.centered,
    dataField: 'tds',
    dataType: 'string',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Supplier,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.supplier' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'dodavatel',
    dataType: 'string',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.State,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.state' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'soD',
    dataType: 'date',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.StartDate,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.startDate' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'zahajeni',
    dataType: 'date',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.EndDate,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.endDate' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'dokonceni',
    dataType: 'date',
    sortOrder: 'asc',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.TotalCost,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.cost' }),
    minWidth: 100,
    cssClass: classNames(styles.centered, styles.noMargin),
    dataField: 'aktualniCena',
    dataType: 'number',
    format: numberFormats.price,
    cellTemplate: 'validation'
  },
  {
    columnKey: ProjectsInRealizationPropertyType.TotalCostI,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.costI' }),
    minWidth: 130,
    cssClass: classNames(styles.centered, styles.noMargin),
    dataField: 'aktualniCenaI',
    dataType: 'number',
    format: numberFormats.price,
    cellTemplate: 'validation'
  },
  {
    columnKey: ProjectsInRealizationPropertyType.TotalCostO,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.costO' }),
    minWidth: 130,
    cssClass: classNames(styles.centered, styles.noMargin),
    dataField: 'aktualniCenaO',
    dataType: 'number',
    format: numberFormats.price,
    cellTemplate: 'validation'
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotal,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.performedWorksTotal' }),
    minWidth: 150,
    cssClass: styles.centered,
    dataField: 'prostavenoCelkem',
    dataType: 'number',
    format: numberFormats.price,
    visible: false,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalI,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.performedWorksTotalI' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'investiceCelkem',
    dataType: 'number',
    format: numberFormats.price,
    visible: false,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalO,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.performedWorksTotalO' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'ostatniCelkem',
    dataType: 'number',
    format: numberFormats.price,
    visible: false,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkYear,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.performedWorksYear' },
      { year: reportYear }
    ),
    cssClass: styles.centered,
    minWidth: 150,
    dataField: 'prostavenoAktRok',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearI,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.performedWorksYearI' }, { year: reportYear }),
    cssClass: classNames(styles.centered, styles.foldableMonths),
    headerCellTemplate: 'foldableMonthsParent',
    dataField: 'iRok',
    dataType: 'number',
    columns: createPerMonthColumns('iRok', 'prostavenoAktRokI', ProjectsInRealizationPropertyType.PerformedWorkYearI, intl, numberFormats)
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearICurrent,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.planI' }, { year: reportYear }),
    cssClass: classNames(styles.centered, styles.foldableMonths),
    headerCellTemplate: 'foldableMonthsParent',
    dataField: 'planAktRokI',
    dataType: 'number',
    columns: createPerMonthColumns('planAktRokI', 'planAktRokI', ProjectsInRealizationPropertyType.PlanYearICurrent, intl, numberFormats, 'planValidation'),
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearO,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.performedWorksYearO' }, { year: reportYear }),
    cssClass: classNames(styles.centered, styles.foldableMonths),
    headerCellTemplate: 'foldableMonthsParent',
    dataField: 'oRok',
    dataType: 'number',
    columns: createPerMonthColumns('oRok', 'prostavenoAktRokO', ProjectsInRealizationPropertyType.PerformedWorkYearO, intl, numberFormats)
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearOCurrent,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.planO' }, { year: reportYear }),
    cssClass: classNames(styles.centered, styles.foldableMonths),
    headerCellTemplate: 'foldableMonthsParent',
    dataField: 'planAktRokO',
    dataType: 'number',
    columns: createPerMonthColumns('planAktRokO', 'planAktRokO', ProjectsInRealizationPropertyType.PlanYearOCurrent, intl, numberFormats, 'planValidation'),
  },
  {
    columnKey: ProjectsInRealizationPropertyType.UnassignedYear,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.unassignedYear' },
      { date: reportYear }
    ),
    minWidth: 100,
    cssClass: classNames(styles.centered, styles.noMargin),
    dataField: 'nezatrideno',
    dataType: 'number',
    format: numberFormats.price,
    visible: false,
    cellTemplate: 'validation'
  },
  {
    columnKey: ProjectsInRealizationPropertyType.Note,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.note' }),
    minWidth: 250,
    cssClass: styles.centered,
    dataField: 'poznamka',
    dataType: 'string',
    cellTemplate: 'note',
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearI1,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planI' },
      { year: reportYear + 1 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok1I',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearO1,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planO' },
      { year: reportYear + 1 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok1O',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearI2,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planI' },
      { year: reportYear + 2 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok2I',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearO2,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planO' },
      { year: reportYear + 2 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok2O',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearI3,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planI' },
      { year: reportYear + 3 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok3I',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearO3,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planO' },
      { year: reportYear + 3 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok3O',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearI4,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planI' },
      { year: reportYear + 4 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok4I',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.PlanYearO4,
    caption: intl.formatMessage(
      { id: 'ProjectsInRealizationOverviewReport.column.planO' },
      { year: reportYear + 4 }
    ),
    minWidth: 110,
    cssClass: styles.centered,
    dataField: 'planAktRok4O',
    dataType: 'number',
    format: numberFormats.price,
  },
  {
    columnKey: ProjectsInRealizationPropertyType.NoteAuthor,
    caption: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.column.updateDate' }),
    minWidth: 100,
    cssClass: styles.centered,
    dataField: 'lastUpdate',
    dataType: 'string',
    visible: false,
  },
  SPACE_FOR_SCROLLBAR_COLUMN,
];


