import { api } from 'api';
import { ApiPromise } from 'api/await-to';
import { UploadCreateDto, UploadDto, UploadPartDto, UploadPartInitResponseDto } from 'api/completeApiInterfaces';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { MAX_UPLOAD_PART_SIZE } from 'config/constants';
import { DEBUG } from 'config/env';
import { Dictionary, omit } from 'lodash';
import moment from 'moment';
import { fileMd5, fileSha256, processApiError } from 'utils';



const MIN_CPUMAX_RUNNING_THREADS: number = 3;
const MAX_CPUMAX_RUNNING_THREADS: number = 10;

function setCPUMaxRunningThreads(): number  {
    const numProcessors: number | undefined = navigator.hardwareConcurrency;
    if (numProcessors === undefined) {
        console.error('Information about the number of processor cores is not available.');
        return MIN_CPUMAX_RUNNING_THREADS;
    }
    const CPUMAX_RUNNING_THREADS: number = Math.min(Math.max(Math.floor(0.75 * numProcessors), MIN_CPUMAX_RUNNING_THREADS), MAX_CPUMAX_RUNNING_THREADS);
    return CPUMAX_RUNNING_THREADS;
}

const CPUMAX_RUNNING_THREADS: number = setCPUMaxRunningThreads();
const MAX_INITIALIZATION_THREADS: number = Math.max(Math.floor(0.75 * CPUMAX_RUNNING_THREADS), MIN_CPUMAX_RUNNING_THREADS);
// console.log('CPUMAX_RUNNING_THREADS:', CPUMAX_RUNNING_THREADS);
// console.log('MAX_INITIALIZATION_THREADS:', MAX_INITIALIZATION_THREADS);


const INTERRUPT_MESSAGE: string = 'interrupted';
export const COMPLETED_ERRORS: ServiceErrorEnum[] = [
  ServiceErrorEnum.UploadAlreadyCompletedError,
  ServiceErrorEnum.UploadPartAlreadyCompletedError,
  ServiceErrorEnum.UploadAlreadyReferencedError,
];

export enum UploadStatus {
  waitingToStart,
  initiating,
  waitingToUpload,
  uploading,
  interrupted,
  finished,
  error,
  initializeError,
  suspended,
}

export enum UploadFileType {
  primaryFile = 'primaryFile',
  signedDocument = 'signedDocument',
  attachment = 'attachment',
}

export interface UploadData<T = unknown> {
  createSaveRequest?: (data: UploadProcessData<T>) => ApiPromise<T>;
  onFinish: (response: T, data: UploadProcessData<T>) => void | Promise<void>;
  temporary?: boolean;
}

export interface UploadProcess<T extends UploadData = UploadData> {
  id: string;
  uploadFiles: UploadFile[];
  data: T;
  onError: (state: UploadFileState[]) => void;
  onProgress: (state: UploadFileState[]) => void;
}

export interface UploadFile {
  id: string;
  blob: Blob;
  fileName: string;
  fileType: UploadFileType;
}

export interface UploadFileState {
  id: string;
  status: UploadStatus;
  error: ApiError;
  uploadId: string;
  fileName: string;
  uploaded: number;
  count: number;
  fileType: UploadFileType;
}

interface PartUploadResult {
  uploadId: string;
  partId: string;
  etag?: string;
}

export interface UploadProcessData<T = unknown> {
  id: string;
  started: boolean;
  data: UploadData<T>;
  uploadFiles: Promise<UploadFileData>[];
  uploadFilesState: UploadFileState[];
  status: UploadStatus;
  onError: (state: UploadFileState[]) => void;
  onProgress: (state: UploadFileState[]) => void;
  currentFileIdx: number;
  currentPartIdx: number;
  ctSource: CancelTokenSource;
  xhr: XMLHttpRequest;
}

export interface UploadFileData {
  id: string;
  name: string;
  parts: UploadPart[];
  upload: UploadDto;
}

interface UploadPart {
  id: string;
  blob: Blob;
  uploaded: boolean;
  hash: string;
  data: UploadPartDto;
}


// after the change is interrupted waiting
let tasksChangeCounter: number = 0;

const addTasksChange = () => {
  tasksChangeCounter++;
};

// in next cycle re-plan tasks
let planNewTask = false; // TODO: investigate - příznak, který řiká, že se má přeplánovat

async function processRequest<T>(
  data: UploadProcessData,
  fileId: number,
  promise: Promise<[ApiError, AxiosResponse<T>]>,
  errorState: UploadStatus = UploadStatus.initializeError
): Promise<[boolean, T]> {
  if (!promise) return [true, null];
  const [apiError, result] = await promise;
  let completed = true;
  if (apiError) {
    if (apiError.message !== INTERRUPT_MESSAGE) {
      const serviceError = processApiError(apiError);
      if (!COMPLETED_ERRORS.includes(serviceError.referenceErrorCode)) {
        data.uploadFilesState[fileId].error = apiError;
        data.uploadFilesState[fileId].status = errorState;
        data.onError(data.uploadFilesState);
        completed = false;
      }
    }
  }
  if (!!result) return [completed, result.data];
  return [completed, null];
}

const initializeFile = async (data: UploadProcessData, fileIdx: number, file: UploadFile): Promise<UploadFileData> => {
  const uploadReq: UploadCreateDto = {
    fileName: file.fileName,
    fileSize: file.blob.size,
    clientFileId: file.id,
    partSize: MAX_UPLOAD_PART_SIZE,
    temporary: data.data.temporary,
  };
  const uploadFileData: UploadFileData = {
    id: file.id,
    name: file.fileName,
    parts: [],
    upload: null,
  };

  let createUploadResponse: UploadDto = null;
  let listUploadPartsResponse: UploadPartDto[] = null;
  let completed: boolean;
  let errorCounter: number = 0;
  while (true) {
    if (!data) return null;
    if (!(await wait(data))) {
      continue;
    }

    if (data.uploadFilesState[fileIdx].status === UploadStatus.initializeError) {
      // Lazy error settings for upload nex file after timeout
      errorCounter++;
      if (errorCounter > 3) {
        errorCounter = 0;
        data.status = UploadStatus.error;
        planNewTask = true;
        DEBUG && console.log('Task(' + fileIdx + ') initialize error:' + moment(Date.now()).format('h:m:s.ms'));
      }
    }
    if (!createUploadResponse) {
      [completed, createUploadResponse] = await processRequest(
        data,
        fileIdx,
        api.project.upload.createUpload(uploadReq, data.ctSource.token)
      );
      if (!completed) {
        continue;
      }
    }
    uploadFileData.upload = createUploadResponse;
    if (!listUploadPartsResponse) {
      [completed, listUploadPartsResponse] = await processRequest<UploadPartDto[]>(
        data,
        fileIdx,
        api.project.upload.listUploadParts(uploadFileData.upload.id, data.ctSource.token)
      );
      if (!completed) {
        continue;
      }
    }
    const parts = listUploadPartsResponse;
    parts.sort((a, b) => a.startBytes - b.startBytes);
    try {
      for (let i = 0; i < parts.length; i++) {
        const part: UploadPart = {
          id: '',
          blob: file.blob.slice(parts[i].startBytes, parts[i].endBytes),
          uploaded: false,
          hash: '',
          data: parts[i],
        };

        switch (uploadFileData.upload.hashAlgo) {
          case 'MD5':
            part.hash = await fileMd5(part.blob);
            break;
          case 'SHA256':
          case 'SHA-256' as 'SHA256':
            part.hash = await fileSha256(part.blob);
            break;
          default:
            throw new Error('Unknown hash algo');
        }
        uploadFileData.parts.push(part);

        createUploadResponse = null;
        listUploadPartsResponse = null;
      }
    } catch (ex) {
      listUploadPartsResponse = null;
      data.uploadFilesState[fileIdx].error = ex as ApiError;
      data.uploadFilesState[fileIdx].status = UploadStatus.initializeError;
      data.onError(data.uploadFilesState);
      continue;
    }
    errorCounter = 0;
    data.uploadFilesState[fileIdx].count = parts.length;
    break;
  }
  data.uploadFilesState[fileIdx].status = UploadStatus.waitingToUpload;
  addTasksChange();
  return uploadFileData;
};

const waitToTimeoutOrChange = async (startCounter: number, timeout: number, steps = 20): Promise<void> => {
  for (let i: number = 0; i < steps; i++) {
    const promise = new Promise((resolve) => setTimeout(resolve, timeout / steps));
    await promise;
    if (tasksChangeCounter !== startCounter) {
      break;
    }
  }
};

const wait = async (data: UploadProcessData): Promise<boolean> => {
  if (
    data.status === UploadStatus.error ||
    data.status === UploadStatus.interrupted ||
    data.status === UploadStatus.suspended ||
    data.status === UploadStatus.waitingToStart
  ) {
    if (data.status === UploadStatus.interrupted || data.status === UploadStatus.error) {
      await waitToTimeoutOrChange(tasksChangeCounter, 10000);
    } else {
      await waitToTimeoutOrChange(tasksChangeCounter, 1000);
    }
    return false;
  }
  return true;
};

const planTask = (data: Dictionary<UploadProcessData>) => {
  let startedCount: number = 0;
  let initializedCount: number = 0;
  const waiting: Guid[] = [];
  Object.keys(data).forEach((dataId) => {
    switch (data[dataId].status) {
      case UploadStatus.waitingToStart:
        waiting.push(dataId);
        break;
      case UploadStatus.suspended:
        waiting.unshift(dataId);
        break;
      case UploadStatus.initiating:
        initializedCount++;
        break;
      case UploadStatus.finished:
      case UploadStatus.initializeError:
      case UploadStatus.uploading:
      case UploadStatus.waitingToUpload:
        startedCount++;
        break;
    }
  });

  for (
    let i = 0;
    i < waiting.length && i < MAX_INITIALIZATION_THREADS - initializedCount && i < CPUMAX_RUNNING_THREADS - startedCount;
    i++
  ) {
    data[waiting[i]].status = UploadStatus.initiating;
    addTasksChange();
  }
};

// test error and initialize state in process
const propagateProcessState = (data: UploadProcessData) => {
  let initialized = true;
  let error = false;

  for (let i = 0; i < data.uploadFilesState.length; i++) {
    const status = data.uploadFilesState[i].status;
    if (status === UploadStatus.initiating) {
      initialized = false;
    } else if (status === UploadStatus.error || status === UploadStatus.initializeError) {
      error = true;
    }
  }
  if (error) {
    data.status = UploadStatus.error;
    planNewTask = true;
  } else if (initialized && data.status !== UploadStatus.uploading) {
    data.status = UploadStatus.uploading;
    planNewTask = true;
  }
};

const resetErrors = (data: UploadProcessData) => {
  data.ctSource = axios.CancelToken.source();
  if (data.started) data.status = UploadStatus.suspended;
  else data.status = UploadStatus.waitingToStart;
  let fileId = data.currentFileIdx;
  if (fileId === data.uploadFiles.length) fileId -= 1;
  if (data.uploadFilesState[fileId].status === UploadStatus.error) {
    data.uploadFilesState[fileId].status = UploadStatus.uploading;
    data.uploadFilesState[fileId].error = null;
  }
  data.uploadFilesState.forEach((file) => {
    if (file.status === UploadStatus.initializeError) {
      file.status = UploadStatus.initiating;
      file.error = null;
    }
  });
};

const processCompletedUpload = async <T>(data: UploadProcessData<T>) => {
  const [processFinished, result] = await processRequest<T>(
    data,
    data.uploadFiles.length - 1,
    data.data.createSaveRequest?.(data),
    UploadStatus.error
  );
  if (processFinished) await data.data.onFinish(result, data);
  return processFinished;
};

const uploadPart = async (
  uploadId: string,
  partId: string,
  url: string,
  blob: Blob,
  partEtagRequired: boolean,
  headers: { [key: string]: string },
  onProgress: (percent: number) => void = () => { },
  ct: CancelToken = null
) =>
  new Promise<PartUploadResult>((resolve, reject: (error: ApiError) => void) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        const etag = partEtagRequired ? this.getResponseHeader('Etag') : null;
        resolve({
          uploadId,
          partId,
          etag,
        } as PartUploadResult);
      } else {
        reject({
          name: 'Uploading part failed',
          message: this.statusText,
          code: this.status.toString(),
          request: this,
          config: null,
          isAxiosError: false,
          response: this.response,
          stack: null,
          toJSON: () => ({ message: this.statusText, code: this.status.toString(), name: 'Uploading part failed' }),
        });
      }
    };

    xhr.onerror = function () {
      reject({
        name: 'Uploading part error',
        message: this.statusText,
        code: this.status.toString(),
        request: this,
        config: null,
        isAxiosError: false,
        response: this.response,
        stack: null,
        toJSON: () => ({ message: this.statusText, code: this.status.toString(), name: 'Uploading part error' }),
      });
    };

    xhr.upload.onprogress = (e) => {
      if (!!ct) {
        ct.promise.then(() => {
          xhr.abort();
          reject({
            name: 'aborted',
            message: ct.reason.message,
            config: null,
            isAxiosError: false,
            toJSON: () => ({ message: ct.reason.message, name: 'Upload aborted' }),
          });
        });
      }
      if (e.lengthComputable) {
        onProgress(e.loaded / e.total);
      }
    };

    xhr.open('PUT', url, true);

    xhr.setRequestHeader('Accept', '*/*');
    xhr.setRequestHeader('Content-Type', '');

    for (const header in omit(headers, ['host', 'content-length'])) {
      xhr.setRequestHeader(header, headers[header]);
    }

    xhr.send(blob);
  });

const finishUploadProcess = async (
  data: UploadProcessData
): Promise<{ processFinished: boolean; uploadFinished: boolean }> => {
  let uploadFinished: boolean = false;
  let processFinished: boolean = false;

  if (
    data.currentFileIdx === 0 &&
    data.uploadFilesState[data.currentFileIdx]?.status === UploadStatus.waitingToUpload
  ) {
    data.uploadFilesState[data.currentFileIdx].status = UploadStatus.uploading;
  }

  if (data.currentFileIdx === data.uploadFiles.length) {
    uploadFinished = true;
    processFinished = await processCompletedUpload(data);
  }
  return { processFinished, uploadFinished };
};

// increase parts and files counters and update state
const increaseUploadStatus = (data: UploadProcessData, partsCount: number) => {
  data.currentPartIdx++;
  data.uploadFilesState[data.currentFileIdx].uploaded = data.currentPartIdx;
  data.onProgress(data.uploadFilesState);

  if (data.currentPartIdx === partsCount) {
    data.uploadFilesState[data.currentFileIdx].status = UploadStatus.finished;
    data.currentPartIdx = 0;
    data.currentFileIdx++;
  } else {
    data.uploadFilesState[data.currentFileIdx].status = UploadStatus.uploading;
  }
};

const stop = (data: UploadProcessData, waitForComplete: boolean) => {
  data.status = UploadStatus.interrupted;
  if (!waitForComplete) {
    data.ctSource.cancel(INTERRUPT_MESSAGE);
  }
};

export class UploadManager {
  private uploadData: Dictionary<UploadProcessData> = {};

  public startProcess = (process: UploadProcess) => {
    if (!!this.uploadData[process.id]) throw 'Process with the id already exists!';
    DEBUG && console.log('Task(' + process.id + ') start:' + moment(Date.now()).format('h:m:s.ms'));
    this.initialize(process);
    planTask(this.uploadData);
  };

  public pauseProcess = (id: string, waitForComplete: boolean = true) => {
    if (!(id in this.uploadData)) throw 'Process with set id does not exist!';
    DEBUG && console.log('Task(' + id + ') pause:' + moment(Date.now()).format('h:m:s.ms'));
    stop(this.uploadData[id], waitForComplete);
    planTask(this.uploadData);
  };

  public continueProcess = (id: string) => {
    if (!(id in this.uploadData)) throw 'Process with set id does not exist!';
    DEBUG && console.log('Task(' + id + ') continue:' + moment(Date.now()).format('h:m:s.ms'));
    resetErrors(this.uploadData[id]);
    planTask(this.uploadData);
  };

  public cancelProcess = (id: string) => {
    if (!(id in this.uploadData)) throw 'Process with set id does not exist!';
    DEBUG && console.log('Task(' + id + ') cancel:' + moment(Date.now()).format('h:m:s.ms'));
    stop(this.uploadData[id], true);
    delete this.uploadData[id];
    planTask(this.uploadData);
  };

  public processExists = (id: string) => {
    return id && id in this.uploadData;
  };

  private initialize = async (process: UploadProcess) => {
    this.uploadData[process.id] = {
      id: process.id,
      started: false,
      data: process.data,
      uploadFiles: [],
      uploadFilesState: [],
      status: UploadStatus.waitingToStart,
      onError: process.onError,
      onProgress: process.onProgress,
      currentFileIdx: 0,
      currentPartIdx: 0,
      ctSource: axios.CancelToken.source(),
      xhr: null,
    };

    while (!(await wait(this.uploadData[process.id]))) {
      if (!this.uploadData[process.id]) return;
      if (planNewTask) {
        planNewTask = false;
        planTask(this.uploadData);
      }
    }
    DEBUG && console.log('Task(' + process.id + ') planned:' + moment(Date.now()).format('h:m:s.ms'));

    this.uploadData[process.id].started = true;
    this.uploadData[process.id].onProgress(this.uploadData[process.id].uploadFilesState);
    this.run(process.id);

    process.uploadFiles.forEach((file, i) => {
      this.uploadData[process.id].uploadFilesState[i] = {
        id: file.id,
        status: UploadStatus.initiating,
        error: null,
        uploadId: null,
        fileName: null,
        uploaded: 0,
        count: 1,
        fileType: file.fileType || UploadFileType.primaryFile,
      };
      this.uploadData[process.id].uploadFiles[i] = initializeFile(this.uploadData[process.id], i, file);
    });
  };

  private run = async (id: string) => {
    const data = this.uploadData[id];
    let initUploadPartResponse: UploadPartInitResponseDto = null;
    let partUploadResult: PartUploadResult = null;
    let partUploadComplete: boolean = false;
    let completed: boolean = false;

    while (true) {
      if (!this.uploadData[id]) return;
      if (planNewTask) {
        planNewTask = false;
        planTask(this.uploadData);
      }
      if (!(await wait(data))) continue;
      // test status
      propagateProcessState(data);
      if (data.status === UploadStatus.error) {
        DEBUG && console.log('Task(' + id + ') error:' + moment(Date.now()).format('h:m:s.ms'));
        continue;
      }

      const { uploadFinished, processFinished } = await finishUploadProcess(data);

      if (processFinished) {
        delete this.uploadData[id];
        DEBUG && console.log('Task(' + id + ') end:' + moment(Date.now()).format('h:m:s.ms'));
        planNewTask = true;
        addTasksChange();
        return;
      }
      if (uploadFinished) continue;
      // wait for file initialization
      const currentFile = await data.uploadFiles[data.currentFileIdx];
      const currentPart = currentFile.parts[data.currentPartIdx];

      if (data.currentPartIdx === 0) {
        propagateProcessState(data);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore the status can be changed while awaiting
        if (data.status === UploadStatus.error) {
          DEBUG && console.log('Task(' + id + ') after await error:' + moment(Date.now()).format('h:m:s.ms'));
          continue;
        }
        data.onProgress(data.uploadFilesState);
      }

      // init part upload on server
      if (!initUploadPartResponse) {
        [completed, initUploadPartResponse] = await processRequest<UploadPartInitResponseDto>(
          data,
          data.currentFileIdx,
          api.project.upload.initUploadPart(
            currentFile.upload.id,
            currentPart.data.id,
            { hash: currentPart.hash },
            data.ctSource.token
          ),
          UploadStatus.error
        );
        if (!completed) continue;
      }
      // upload part to storage
      if (!partUploadResult) {
        try {
          partUploadResult = await uploadPart(
            currentFile.upload.id,
            currentPart.data.id,
            initUploadPartResponse.uri,
            currentPart.blob,
            currentFile.upload.partEtagRequired,
            initUploadPartResponse.headers,
            (percent: number) => {
              data.uploadFilesState[data.currentFileIdx].uploaded = data.currentPartIdx + percent;
              data.onProgress(data.uploadFilesState);
            },
            data.ctSource.token
          );
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError.message !== INTERRUPT_MESSAGE) {
            data.uploadFilesState[data.currentFileIdx].error = apiError;
            data.uploadFilesState[data.currentFileIdx].status = UploadStatus.error;
            data.onError(data.uploadFilesState);
          }
          continue;
        }
      }

      // complete part upload on server
      if (!partUploadComplete) {
        [completed] = await processRequest<void>(
          data,
          data.currentFileIdx,
          api.project.upload.completeUploadPart(
            currentFile.upload.id,
            currentPart.data.id,
            { eTag: partUploadResult.etag },
            data.ctSource.token
          ),
          UploadStatus.error
        );
        if (!completed) {
          continue;
        } else {
          partUploadComplete = true;
        }
      }

      // complete upload whole file on server
      if (data.currentPartIdx + 1 === currentFile.parts.length) {
        [completed] = await processRequest<UploadDto>(
          data,
          data.currentFileIdx,
          api.project.upload.completeUpload(currentFile.upload.id, data.ctSource.token),
          UploadStatus.error
        );
        if (!completed) continue;
        data.uploadFilesState[data.currentFileIdx].uploadId = currentFile.upload.id;
        data.uploadFilesState[data.currentFileIdx].fileName = currentFile.name;
      }

      increaseUploadStatus(data, currentFile.parts.length);
      initUploadPartResponse = null;
      partUploadResult = null;
      partUploadComplete = false;
    }
  };
}
