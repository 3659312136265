import { ContentGate } from 'components/ContentGate/ContentGate';
import { useStoreSelector } from 'hooks';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

const AppSettingsGate: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    // first calls to backend must be finished before any other call can be executed to prevent concurency database update on BE
    dispatch.currentAppUser.loadData({ reload: false }).then(() => dispatch.appSettings.loadData({ reload: false }));
  }, []);

  const currentAppUserState = useStoreSelector((state) => state.currentAppUser);
  const appSettingsState = useStoreSelector((state) => state.appSettings);

  useEffect(() => {
    currentAppUserState.data && dispatch.appConfig.setLocale(currentAppUserState.data.language as LanguageEnum);
  }, [currentAppUserState]);

  const ready = !!currentAppUserState.data && !!appSettingsState.data;

  return (
    <ContentGate
      error={currentAppUserState.error || appSettingsState.error}
      loading={currentAppUserState.loading || appSettingsState.loading}
      loadingAlternative="" //Cannot display loading text because translation language is not set up yet
      overlay
    >
      {ready && children}
    </ContentGate>
  );
};

export default AppSettingsGate;
