import { StopOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import FileTypeIcon from 'components/FileTypeIcon';
import ImageBox from 'components/ImageBox';
import React, { FunctionComponent, useCallback } from 'react';
import styles from './DocumentThumbnail.module.less';

export type DocumentThumbnailProps = {
  onThumbnailClick?: (id: Guid) => void;
  thumbnailUrl?: string;
  id: Guid;
  primaryFileContentType?: string;
  hasAccess?: boolean;
};

const DocumentThumbnail: FunctionComponent<DocumentThumbnailProps> = ({
  id,
  onThumbnailClick,
  thumbnailUrl,
  primaryFileContentType,
  hasAccess,
}) => {
  const handleThumbnailClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onThumbnailClick && onThumbnailClick(id);
    },
    [onThumbnailClick, id]
  );

  return hasAccess === false ? (
    <ImageBox className={classNames(styles.thumbnail, onThumbnailClick && styles.clickable)}>
      <StopOutlined />
    </ImageBox>
  ) : (
    <ImageBox
      className={styles.thumbnail}
      loading={!thumbnailUrl === null}
      spinSize="small"
      onClick={handleThumbnailClick}
    >
      {!!thumbnailUrl ? <img src={thumbnailUrl} /> : <FileTypeIcon contentType={primaryFileContentType} />}
    </ImageBox>
  );
};

export default React.memo(DocumentThumbnail);
