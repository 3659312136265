import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';

type Props = {
  selectedCount: number;
  onClick?: () => void;
  className?: string;
  buttonLabelTranslationId?: IntlMessageId;
};

export const ItemUnselectButton: FunctionComponent<Props> = ({
  selectedCount,
  onClick,
  className,
  buttonLabelTranslationId,
}) => {
  return (
    <CommonHubTooltip title={<Fmt id="general.docMenu.selected.tooltip" />}>
      <Button type="link" onClick={onClick} disabled={!selectedCount} className={className}>
        <CloseOutlined />
        <Fmt id={buttonLabelTranslationId || 'general.docMenu.selectedFiles'} values={{ count: selectedCount }} />
      </Button>
    </CommonHubTooltip>
  );
};
