import { api } from 'api';
import {
  AssignmentAttachmentCreateDto,
  AssignmentAttachmentCreateMultipleDto,
  AssignmentDto,
} from 'api/completeApiInterfaces';
import { injectUploadIdToAttachments } from 'api/project/upload/uploadHelpers';
import { UploadData } from 'api/project/upload/uploadManager';
import { DiscussionChat } from 'components/DiscussionChat/DiscussionChat';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { DiscussionNoteData } from 'components/DiscussionChat/DiscussionNote/DiscussionNote';
import { DiscussionAttachmentUploadModal } from 'components/forms/DiscussionAttachmentUploadModal/DiscussionAttachmentUploadModal';
import { useIntl, useSameCallback, useVisibleState } from 'hooks';
import { uniqBy } from 'lodash';
import React, { FunctionComponent, useMemo, useState } from 'react';
import colors from 'styles/colors';
import { messageError } from 'utils';
import { dateComparer } from 'utils/comparators';

type Props = {
  canEdit: boolean;
  assignment: AssignmentDto;
  updateAssignment: (assignment: AssignmentDto | null) => void;
};

type AttachmentUploadData = {
  attachments: StagedAttachment[];
  noteId: Guid;
};

const AUTHOR_COLOR = colors['primary-color-dark'];
const SOLVER_COLOR = colors['alternative-color'];

export const AssignmentDiscussionField: FunctionComponent<Props> = ({ canEdit, assignment, updateAssignment }) => {
  const [uploadData, uploadVisible, setUploadData, hideUpload] = useVisibleState<AttachmentUploadData>();

  const [addSaving, setAddSaving] = useState(false);

  const intl = useIntl();

  const availableUsers = useMemo(
    () =>
      uniqBy(
        [...assignment.solvers, assignment.createdBy, ...assignment.associates, assignment.taskGiver].filter(Boolean),
        (user) => user.id
      ),
    [assignment]
  );

  const discussionNotes = useMemo(
    () =>
      assignment.assignmentNotes.map(
        (note): DiscussionNoteData => {
          const { assignmentNoteAttachments, ...rest } = note;
          const isAuthor =
            assignment.createdBy.id === note.createdBy.id ||
            assignment.associates.some((associate) => associate.id === note.createdBy.id) ||
            assignment.taskGiver?.id === note.createdBy.id;
          const isSolver = assignment.solvers.some((solver) => solver.id === note.createdBy.id);
          return {
            ...rest,
            attachments: assignmentNoteAttachments || [],
            authorColors: [isAuthor && AUTHOR_COLOR, isSolver && SOLVER_COLOR],
          };
        }
      ),
    [assignment]
  );

  const addNote = useSameCallback(
    async (
      value: string,
      linkedAttachments: Guid[],
      stagedAttachments: StagedAttachment[],
      userNotifications?: Guid[]
    ) => {
      setAddSaving(true);
      const [err, resp] = await api.project.assignments.discussion.addNote(assignment.id, {
        message: value,
        linkedAttachments,
        userNotifications,
      });
      setAddSaving(false);

      if (!err) {
        updateAssignment(resp.data);

        if (stagedAttachments.length > 0) {
          const noteId = [...resp.data.assignmentNotes].sort(dateComparer.map((note) => note.createdDate))[
            resp.data.assignmentNotes.length - 1
          ].id;
          setUploadData({
            attachments: stagedAttachments,
            noteId,
          });
        }
      } else {
        messageError(err, intl);
      }
    }
  );

  const createUploadData = (): UploadData<AssignmentDto> => {
    const attachments = uploadData.attachments.map(
      (attachment): AssignmentAttachmentCreateDto => ({
        name: attachment.name,
        uploadId: null,
      })
    );
    const createDto: AssignmentAttachmentCreateMultipleDto = {
      attachments,
      assignmentNoteId: uploadData.noteId,
    };
    const onFinish = async (response: AssignmentDto) => {
      updateAssignment(response);
      hideUpload();
    };
    return {
      createSaveRequest: (data) =>
        api.project.assignments.addAttachments(
          assignment.id,
          injectUploadIdToAttachments(createDto, data),
          data.ctSource.token
        ),
      onFinish,
    };
  };

  const getOriginalUrl = async (attachmentId: Guid) => {
    return await api.project.assignments.attachment.download(assignment.id, attachmentId);
  };

  const editNote = useSameCallback(
    async (noteId: Guid, noteMessage: string, linkedAttachments: Guid[], stagedAttachments: StagedAttachment[]) => {
      const [err, resp] = await api.project.assignments.discussion.editNote(assignment.id, noteId, {
        title: '',
        message: noteMessage,
        linkedAttachments: linkedAttachments,
      });
      if (err) {
        messageError(err, intl);
        return false;
      }
      updateAssignment(resp.data);
      if (stagedAttachments.length > 0) {
        setUploadData({
          attachments: stagedAttachments,
          noteId,
        });
      }
      return true;
    }
  );

  const deleteNote = useSameCallback(async (noteId: Guid) => {
    const [err] = await api.project.assignments.discussion.removeNote(assignment.id, noteId);
    if (err) {
      messageError(err, intl);
      return false;
    }
    updateAssignment(null);
    return true;
  });

  const restoreNote = useSameCallback(async (noteId: Guid) => {
    const [err, resp] = await api.project.assignments.discussion.restoreNote(assignment.id, noteId);
    if (err) {
      messageError(err, intl);
      return false;
    }
    updateAssignment(resp.data);
    return true;
  });

  return (
    <>
      <DiscussionChat
        availableUsers={availableUsers}
        discussionNotes={discussionNotes}
        disabled={!canEdit}
        onSend={addNote}
        onEdit={editNote}
        onDelete={deleteNote}
        onRestore={restoreNote}
        addNoteLoading={addSaving}
        availableAttachments={assignment.assignmentAttachments}
        getOriginalUrl={getOriginalUrl}
      />
      <DiscussionAttachmentUploadModal
        visible={uploadVisible}
        onClose={hideUpload}
        stagedAttachments={uploadData?.attachments}
        createUploadData={createUploadData}
      />
    </>
  );
};
