import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, InputRef, Row, Typography } from 'antd';
import { DeleteButton } from 'components/ActionButtons';
import MultipleInputModal from 'components/MultipleInputModal';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary, has } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { includesDiacriticsRule, requiredRule } from 'utils/formHelpers';
import uuid from 'uuid';

const mapStateToProps = (state: RootState) => ({
  appSettings: state.appSettings.data,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

export type ShareDownloadFormData = {
  emails: Dictionary<Guid>;
  message: string;
  includeSubdirectories?: boolean;
};

type MailData = {
  email: string;
  id: Guid;
};

type OwnProps = {
  showIncludeSubdirectories?: boolean;
};

type Props = PropsFromState &
  FormComponentProps<ShareDownloadFormData> &
  InjectedIntlProps &
  OwnProps & { setRef: (ref: InputRef) => void };

type State = {
  isMultiMail: boolean;
  emails: MailData[];
  validationVersion: number;
};

class ShareDownloadForm extends Component<Props, State> {
  validatedEmails: Dictionary<string> = {};

  constructor(props: Props, context: State) {
    super(props, context);
    this.state = {
      isMultiMail: false,
      emails: [{ id: uuid.v4(), email: '' }],
      validationVersion: 0,
    };
  }

  handleEmailAdd = () => {
    this.setState((s) => ({ emails: [...s.emails, { id: uuid.v4(), email: '' }] }));
  };

  handleMultiMailAdd = () => {
    this.setState({ isMultiMail: true });
  };

  handleEmailDelete = (uuid: Guid) => {
    if (this.state.emails.length < 2) return;
    if (has(this.validatedEmails, `emails[${uuid}]`)) delete this.validatedEmails[`emails[${uuid}]`];
    this.setState(
      (s) => ({ emails: s.emails.filter((mail) => mail.id !== uuid) }),
      () => this.validateAllEmails()
    );
  };

  handleEmailsAdd = (values: string[]) => {
    const emails = values.reduce<MailData[]>((acc, email) => {
      return [...acc, { id: uuid.v4(), email: email }];
    }, []);
    const { getFieldValue } = this.props.form;
    this.setState(
      (s) => {
        const originalEmails = s.emails
          .map((e) => ({ ...e, email: getFieldValue('emails[' + e.id + ']') }))
          .filter((email) => email.email !== '');
        return { emails: [...originalEmails, ...emails], isMultiMail: false };
      },
      () => this.validateAllEmails()
    );
  };

  handleMultiEmailCancel = () => {
    this.setState({ isMultiMail: false });
  };

  itemsConverter = (value: string): string[] => {
    const matches: RegExpMatchArray = value.match(
      /([\w\-!#$%&'*+/=?^_`{|}~](\.?[\w\-!#$%&'*+/=?^_`{|}~])*@([\w\-!#$%&'*+/=?^_`{|}~]+\.)+[^\d\W]{2,})/gi
    );
    if (!matches || !matches.length) return [];
    const uniqueEmails: Set<string> = new Set(
      matches.filter((match) => !!match).map((match) => match.toString().toLowerCase())
    );
    return Array.from(uniqueEmails);
  };

  validateAllEmails = () => {
    const { validateFields } = this.props.form;
    const validate: string[] = [];
    this.state.emails.forEach((e) => validate.push(`emails[${e.id}]`));
    validateFields(validate, { force: true });
  };

  getEmailIdx = (field: string): number => {
    return this.state.emails.indexOf(this.state.emails.find((e) => field.includes(e.id)));
  };

  emailDuplicateValidator = (rule: any, value: any, callback: any) => {
    const { intl } = this.props;
    const tValue: string = value.toLowerCase().trim();
    const ret: string[] = [];
    const { getFieldValue } = this.props.form;
    const originalEmails = this.state.emails.map((e) => ({ ...e, email: getFieldValue('emails[' + e.id + ']') }));
    if (
      originalEmails.some(
        (e) => e.email === tValue && this.getEmailIdx(rule.field) > this.getEmailIdx(`emails[${e.id}]`)
      )
    ) {
      ret.push(intl.formatMessage({ id: 'forms.items.rules.duplicateEmail' }));
    }
    this.validatedEmails[rule.field] = tValue;
    callback(ret);
  };

  getEmailEdits = (): ReactNode[] => {
    const { intl, form } = this.props;
    const { emails } = this.state;
    const { getFieldDecorator } = form;
    return emails.map((mail, i) => {
      return (
        <Form.Item
          key={mail.id}
          label={i > 0 ? undefined : intl.formatMessage({ id: 'ShareDownloadForm.form.emails' })}
          style={{ marginBottom: '4px' }}
        >
          <Row gutter={24}>
            <Col span={emails.length > 1 ? 22 : 24}>
              {getFieldDecorator(`emails[${mail.id}]`, {
                initialValue: mail.email,
                validateFirst: true,
                validateTrigger: '',
                rules: [
                  includesDiacriticsRule('forms.items.rules.email.noDiacritics'),
                  requiredRule('forms.items.rules.required'),
                  { type: 'email', message: intl.formatMessage({ id: 'forms.items.rules.email' }) },
                  {
                    validator: this.emailDuplicateValidator,
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'forms.items.email.placeholder' })}
                  onChange={() => {
                    this.setState(
                      (s) => ({ validationVersion: s.validationVersion + 1 }),
                      () => this.validateAllEmails()
                    );
                  }}
                  autoFocus
                  ref={this.props.setRef}
                />
              )}
            </Col>
            <Col span={2}>{emails.length > 1 && <DeleteButton onClick={() => this.handleEmailDelete(mail.id)} />}</Col>
          </Row>
        </Form.Item>
      );
    });
  };

  render() {
    const { intl, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="vertical">
        {this.props.showIncludeSubdirectories && (
          <Form.Item label={intl.formatMessage({ id: 'SharedDownload.form.items.includeSubdirectories.title' })}>
            {getFieldDecorator('includeSubdirectories', {
              initialValue: true,
              valuePropName: 'checked',
            })(
              <Checkbox>
                <Fmt id="SharedDownload.form.items.includeSubdirectories.label" />
              </Checkbox>
            )}
          </Form.Item>
        )}
        {this.getEmailEdits()}
        <Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button onClick={this.handleMultiMailAdd} style={{ width: '100%' }}>
                <UsergroupAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addMultiMail" />
              </Button>
            </Col>
            {!this.state.isMultiMail && (
              <Col span={12}>
                <Button onClick={this.handleEmailAdd} style={{ width: '100%' }}>
                  <UserAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addEmail" />
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'SharedDownload.form.items.message.label' })}>
          {getFieldDecorator('message', {
            initialValue: '',
          })(<Input.TextArea rows={3} autoSize={{ minRows: 3 }} />)}
        </Form.Item>
        <MultipleInputModal
          visible={this.state.isMultiMail}
          onSubmit={this.handleEmailsAdd}
          onClose={this.handleMultiEmailCancel}
          title={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.label' })}
          itemsFinder={this.itemsConverter}
          submitText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.addEmails' })}
          cancelText={intl.formatMessage({ id: 'forms.button.cancel' })}
          placeholder={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.placeholder' })}
          errorText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.error' })}
          inputText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.inputText' })}
          resultText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.resultText' })}
        />
        <Typography.Text type="danger" strong>
          <Fmt
            id="SharedDownload.page.expiredInterval"
            values={{ interval: Math.round(this.props.appSettings.sharedDownloadExpiredDate / 3600 / 24) }}
          />
        </Typography.Text>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(Form.create<Props>()(ShareDownloadForm));
