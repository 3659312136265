import React, { FunctionComponent } from 'react';
import styles from './SettingsBox.module.less';
import classnames from 'classnames';

type Props = {
  className?: string;
};

export const SettingsBox: FunctionComponent<Props> = ({ children, className }) => {
  return <div className={classnames(styles.container, className)}>{children}</div>;
};
