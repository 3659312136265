import { Button, Modal, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { api } from 'api';
import { ForgeMonthlyReportRequestDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DateRangeValue, InlineDateRangePicker } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import { Margin } from 'components/Margin/Margin';
import { DATE_FORMAT_FOR_FILE_EXPORT } from 'config/constants';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import moment, { Moment } from 'moment-business-days';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';

type Props = {
  onSubmit: () => void;
  onClose: () => void;
  visible: boolean;
  organizationId: Guid;
};

const formatDataToDto = (
  organizationId: Guid,
  [dateFrom, dateTo]: DateRangeValue,
  intl: InjectedIntl
): ForgeMonthlyReportRequestDto => {
  return {
    organizationId: organizationId,
    monthFrom: +dateFrom?.format('M') || undefined,
    yearFrom: +dateFrom?.format('Y') || undefined,
    monthTo: +dateTo?.format('M') || undefined,
    yearTo: +dateTo?.format('Y') || undefined,
    language: intl.locale,
  };
};

const DEFAULT_DATE_RANGE_VALUE: DateRangeValue = [moment(Date.now()).subtract(1, 'months'), moment(Date.now())];
const FIRST_DATE_OF_MEANINGFUL_DATA: Moment = moment('2023-01-01');

export const ModelProcessingExportFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  visible,
  organizationId,
}) => {
  const [dateRangePickerValue, setDateRangePickerValue] = useState<DateRangeValue>(DEFAULT_DATE_RANGE_VALUE);
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useSameCallback(async () => {
    setLoading(true);

    const [err, resp] = await api.master.organization.getForgeMonthReport(
      formatDataToDto(organizationId, dateRangePickerValue, intl)
    );
    if (err) {
      setLoading(false);
      messageError(err, intl);
      return err;
    }

    const exportFileName = `model_processing_report_${moment(Date.now()).format(DATE_FORMAT_FOR_FILE_EXPORT)}.xlsx`;

    if (!!resp.data) {
      const blob = new Blob([resp.data], { type: 'application/ms-excel' });
      downloadBlob(blob, exportFileName);
    }

    onSubmit && (await onSubmit());
    setLoading(false);
    return null;
  });

  const disabledDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    return current < FIRST_DATE_OF_MEANINGFUL_DATA.startOf('year');
  };

  const isNotRelevantPeriod = !dateRangePickerValue || dateRangePickerValue.filter((value) => !!value).length < 2;
  const footer = (
    <div>
      <Button onClick={onClose}>
        <Fmt id="general.close" />
      </Button>
      <CommonHubTooltip
        title={isNotRelevantPeriod ? intl.formatMessage({ id: 'UserActivity.report.modal.disableOk.tooltipText' }) : ''}
      >
        <Button type="primary" loading={loading} onClick={handleSubmit} disabled={isNotRelevantPeriod}>
          <Fmt id="AllDocumentsPage.directory.report.modalOkText" />
        </Button>
      </CommonHubTooltip>
    </div>
  );

  return (
    <Modal
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      title={<Fmt id="ModelProcessingExportFormModal.title" />}
      width={800}
      maskClosable={false}
      confirmLoading={loading}
      footer={footer}
    >
      <Margin bottom>
        <Typography.Text strong>
          <Fmt id={'ModelProcessingExportFormModal.periodSelection'} />
        </Typography.Text>
      </Margin>
      <InlineDateRangePicker
        defaultValue={DEFAULT_DATE_RANGE_VALUE}
        onChange={setDateRangePickerValue}
        isTimeGranulationMonth
        disabledDate={disabledDate}
      />
    </Modal>
  );
};

export default ModelProcessingExportFormModal;
