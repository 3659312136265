import { CaretRightOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './RoleUsageError.module.less';

export interface RoleInProcedureError {
  roleId: Guid;
  roleName: string;
  rolesObstacle: RolesObstaclesEnum;
  procedureId: Guid;
  procedureOrderId: number;
  procedureName: string;
}

export enum RolesObstaclesEnum {
  roleInWorkflow = 'roleInWorkflow',
  roleInWorkflowTemplate = 'roleInWorkflowTemplate',
  roleInCommentProcedure = 'roleInCommentProcedure',
}

const MINIMUM_ITEMS_VISIBLE = 5;

type Props = InjectedIntlProps & {
  roleErrors: RoleInProcedureError[];
  minimumItemsVisible?: number;
};

export const RoleUsageError: FunctionComponent<Props> = ({
  roleErrors,
  intl,
  minimumItemsVisible = MINIMUM_ITEMS_VISIBLE,
}) => {
  const visibleErrors = useMemo(() => roleErrors.slice(0, minimumItemsVisible), [roleErrors]);
  const moreItemsCount = roleErrors.length - visibleErrors.length;

  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <div className={styles.title}>
        {intl.formatMessage({ id: 'UserDetailPanel.RolesTab.ConfirmRemove.content' }, { count: roleErrors.length })}
      </div>
      <div className={styles.container}>
        {(showAll ? roleErrors : visibleErrors).map((doc) => (
          <div key={doc.procedureId}>
            {intl.formatMessage({
              id: `UserDetailPanel.RolesTab.ConfirmRemove.${doc.rolesObstacle}`,
            })}{' '}
            <strong>{doc.procedureName}</strong> {doc.procedureOrderId ? `(ID ${doc.procedureOrderId})` : ''}
          </div>
        ))}
        {moreItemsCount > 0 && (
          <Button type="link" onClick={() => setShowAll(!showAll)}>
            {!showAll ? (
              <>
                <CaretRightOutlined />
                {` ${intl.formatMessage({ id: 'general.showMore' })} (${moreItemsCount})`}
              </>
            ) : (
              <>
                <CaretUpOutlined /> {intl.formatMessage({ id: 'general.showLess' })}
              </>
            )}
          </Button>
        )}
      </div>
    </>
  );
};
