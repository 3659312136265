import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import { DisplayNameProps } from 'components/DisplayName/DisplayName';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './DocumentRow.module.less';

type Props = DisplayNameProps & {
  documentName: string;
  tooltip?: ReactNode;
};

export const DocumentName: FunctionComponent<Props> = ({ documentName, tooltip, className, ref, ...restProps }) => {
  return (
    <CommonHubTooltip title={tooltip || documentName}>
      <DisplayName className={classNames(styles.documentName, className)} {...restProps}>
        {documentName}
      </DisplayName>
    </CommonHubTooltip>
  );
};
