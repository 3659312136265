import { message, Modal, Typography } from 'antd';
import { FavoriteDocumentsResultsDto } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale/Fmt';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
const { Text } = Typography;

type Props = {
  favoriteResponse: FavoriteDocumentsResultsDto;
  setFavoriteResponse: React.Dispatch<React.SetStateAction<FavoriteDocumentsResultsDto>>;
};

const ShowFavoriteSettingResponseModal: FunctionComponent<Props> = ({ favoriteResponse, setFavoriteResponse }) => {
  const intl = useIntl();

  const favoriteChangesModalContent = useMemo(() => {
    return (
      <>
        {!!favoriteResponse?.documentErrors?.length && (
          <div key="docErrors">
            <Margin bottom top>
              <Text strong>
                <Fmt id="ShowFavoriteSettingResponseModal.documents" />
              </Text>
            </Margin>
            {favoriteResponse?.documentErrors.map((error) => (
              <div key={error.documentId}>
                <Fmt
                  id={`AllDocumentsPageFileToolbar.favoriteChanged.documentError.${error.documentsObstacles}`}
                  values={{ fileName: error.documentName }}
                />
              </div>
            ))}
          </div>
        )}
        {!!favoriteResponse?.documentLinkErrors?.length && (
          <div key="linkErrors">
            <Margin bottom top>
              <Text strong>
                <Fmt id="ShowFavoriteSettingResponseModal.links" />
              </Text>
            </Margin>
            {favoriteResponse?.documentLinkErrors.map((error) => (
              <div key={error.linkId}>
                <Fmt
                  id={`AllDocumentsPageFileToolbar.favoriteChanged.linkError.${error.linksObstacles}`}
                  values={{ linkName: error.linkName }}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  }, [favoriteResponse]);

  useEffect(() => {
    !!favoriteResponse &&
      !favoriteResponse?.documentErrors?.length &&
      !favoriteResponse?.documentLinkErrors?.length &&
      !!favoriteResponse?.documentsIds.length &&
      message.success(intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.success' }));
  }, [favoriteResponse]);
  return (
    <Modal
      title={
        !!favoriteResponse?.documentsIds.length
          ? intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.successParcial' })
          : intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.failure' })
      }
      visible={!!favoriteResponse?.documentErrors?.length || !!favoriteResponse?.documentLinkErrors?.length}
      cancelButtonProps={HIDE_BUTTON_PROPS}
      onOk={() => setFavoriteResponse(undefined)}
      onCancel={() => setFavoriteResponse(undefined)}
      width={800}
    >
      {favoriteChangesModalContent}
    </Modal>
  );
};
export default ShowFavoriteSettingResponseModal;
