import { Typography } from 'antd';
import { ProjectTemplateGroupDto, ProjectTemplateUserDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons/EditButton';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import StackPanel from 'components/StackPanel';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { useBoolean } from 'hooks';
import { useMasterDetailView } from 'hooks/useMasterDetailView';
import { Fmt } from 'locale';
import { GroupUserCount } from 'pages/ProjectSettingsPage/Groups/GroupsListPanel/GroupUserCount';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { smartFilter, strCompareCI } from 'utils';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplateGroupFormModal from './ProjectTemplateGroupFormModal';

export const MAX_PROJECT_TEMPLATE_GROUP_COUNT = 64;

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
};

const ProjectTemplateGroupsTab: FunctionComponent<Props> = ({ intl, projectTemplate, dispatchProjectTemplate }) => {
  const [selectedGroupId, setSelectedGroupId] = useState<Guid>();
  const [editedGroupId, setEditedGroupId] = useState<Guid>();
  const [search, setSearch] = useState<string>();
  const [searchUsers, setSearchUsers] = useState<string>();

  const [isGroupModalVisible, showGroupModal, hideGroupModal] = useBoolean(false);

  const selectedGroup = useMemo(
    () => projectTemplate.projectTemplateGroups.find((group) => group.id === selectedGroupId),
    [selectedGroupId, projectTemplate.projectTemplateGroups]
  );

  const editedGroup = useMemo(() => projectTemplate.projectTemplateGroups.find((group) => group.id === editedGroupId), [
    editedGroupId,
    projectTemplate.projectTemplateGroups,
  ]);

  const handleGroupRemove = useCallback(
    (groupId: Guid) => {
      if (projectTemplate.projectTemplateGroups.some((group) => group.id === groupId && group.isAdminGroup)) return;

      dispatchProjectTemplate({
        type: 'deleteGroup',
        groupId: groupId,
      });
    },
    [projectTemplate.projectTemplateGroups, dispatchProjectTemplate]
  );

  const handleGroupSubmit = useCallback(
    (group: ProjectTemplateGroupDto) => {
      if (!!editedGroupId) {
        dispatchProjectTemplate({
          type: 'updateGroup',
          group: group,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addGroup',
          group: group,
        });
      }
      setEditedGroupId(undefined);
      hideGroupModal();
    },
    [editedGroupId, dispatchProjectTemplate]
  );

  const handleGroupUserRemove = useCallback(
    (userId: Guid) => {
      if (!selectedGroup) return;
      dispatchProjectTemplate({
        type: 'updateGroup',
        group: { ...selectedGroup, templateUserIds: selectedGroup.templateUserIds.filter((id) => id !== userId) },
      });
    },
    [selectedGroup]
  );

  const showGroupEdit = useCallback((groupId: Guid) => {
    setEditedGroupId(groupId);
    showGroupModal();
  }, []);

  const showAddGroupModal = useCallback(() => {
    setSelectedGroupId(undefined);
    showGroupModal();
  }, []);

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !projectTemplate.projectTemplateGroups.some(
        (group) => strCompareCI(group.name, name) === 0 && (!!editedGroupId ? group.id !== editedGroupId : true)
      ),
    [editedGroupId, projectTemplate.projectTemplateGroups]
  );

  const userDetailPanel = useMemo(
    () =>
      !!selectedGroup ? (
        <Panel
          onSearch={setSearchUsers}
          searchValue={searchUsers}
          addButtonText={<Fmt id="general.addUsers" />}
          addButtonDisabled={projectTemplate.projectTemplateGroups.length > MAX_PROJECT_TEMPLATE_GROUP_COUNT}
        >
          <GeneralSettingsContainer>
            <List<ProjectTemplateUserDto>
              data={projectTemplate.projectTemplateUsers.filter((user) =>
                selectedGroup.templateUserIds.some((userId) => user.id === userId)
              )}
              search={searchUsers}
              filterItem={(item) =>
                (item && smartFilter(item?.appUserOrganization.appUserProfile.username, searchUsers)) ||
                smartFilter(item?.appUserOrganization.appUserProfile.firstname, searchUsers) ||
                smartFilter(item?.appUserOrganization.appUserProfile.lastname, searchUsers)
              }
              renderItem={(item) => (
                <GeneralSettingsItem
                  icon={<UserIcon size={UserAvatarSize.Large} user={item?.appUserOrganization.appUserProfile} />}
                  key={item.id}
                  title={item.appUserOrganization.appUserProfile.username}
                  wrap
                  description={
                    (item.appUserOrganization.appUserProfile.firstname ||
                      item.appUserOrganization.appUserProfile.lastname) && (
                      <>
                        {item.appUserOrganization.appUserProfile.firstname && (
                          <Typography.Text>{`${item.appUserOrganization.appUserProfile.firstname}`}</Typography.Text>
                        )}{' '}
                        {item.appUserOrganization.appUserProfile.lastname && (
                          <Typography.Text>{`${item.appUserOrganization.appUserProfile.lastname}`}</Typography.Text>
                        )}
                      </>
                    )
                  }
                  additionalActions={
                    <>
                      <DeleteButton onDelete={() => handleGroupUserRemove(item.id)} type="link" />
                    </>
                  }
                />
              )}
              renderEmpty={(total, filtered) => (
                <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearUserSearch} />
              )}
            />
          </GeneralSettingsContainer>
        </Panel>
      ) : (
        undefined
      ),
    [handleGroupUserRemove, projectTemplate.projectTemplateUsers, search, selectedGroup]
  );

  const handleClearSearch = () => setSearch(undefined);
  const handleClearUserSearch = () => setSearchUsers(undefined);
  const hideGroupDetail = () => setSelectedGroupId(undefined);

  const { tableWrapRef, title, children } = useMasterDetailView(
    <Panel
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showAddGroupModal}
      addButtonText={<Fmt id="Panel.addGroup.tooltip" />}
    >
      <GeneralSettingsContainer>
        <List<ProjectTemplateGroupDto>
          data={projectTemplate.projectTemplateGroups}
          search={search}
          filterItem={(item) => item && smartFilter(item?.name, search)}
          renderItem={(item) => (
            <GeneralSettingsItem
              key={item.id}
              title={item.name}
              description={item.description}
              selectable
              selected={item.id === selectedGroupId}
              onClick={() => setSelectedGroupId(item.id)}
              additionalActions={
                <>
                  <GroupUserCount userCount={item.templateUserIds?.length} />
                  <EditButton onClick={() => showGroupEdit(item.id)} />
                  {!item.isAdminGroup && <DeleteButton onDelete={() => handleGroupRemove(item.id)} type="link" />}
                </>
              }
            />
          )}
          renderEmpty={(total, filtered) => (
            <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearSearch} />
          )}
        />
      </GeneralSettingsContainer>
    </Panel>,
    userDetailPanel,
    intl.formatMessage({ id: 'general.groups' }),
    !!selectedGroup && selectedGroup.name,
    hideGroupDetail
  );

  const onCloseModal = useCallback(() => {
    hideGroupModal();
    setEditedGroupId(undefined);
  }, [hideGroupModal]);

  return (
    <>
      <StackPanel vertical divRef={tableWrapRef}>
        <PageContent title={title}>{children}</PageContent>
        {isGroupModalVisible && (
          <ProjectTemplateGroupFormModal
            onSubmit={handleGroupSubmit}
            onClose={onCloseModal}
            templateUsers={projectTemplate.projectTemplateUsers}
            visible={isGroupModalVisible}
            editedGroup={editedGroup}
            validateUniqueName={validateUniqueName}
          />
        )}
      </StackPanel>
    </>
  );
};

export default ProjectTemplateGroupsTab;
