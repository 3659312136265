import { Typography } from 'antd';
import { ProjectUserProfileListDto, RevisionDto } from 'api/completeApiInterfaces';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { UserAvatarSize } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';

export type ApprovalDocumentActivity = {
  id: Guid;
  actionDate: IsoDateTime;
  user: ProjectUserProfileListDto;
  documentRevision: RevisionDto;
  type: 'addedDocument' | 'addedRevision' | 'signed';
  workflowNodeId?: Guid;
};

export type Props = {
  documentActivity: ApprovalDocumentActivity;
  additionalData?: (documentActivity: ApprovalDocumentActivity) => ReactNode;
};

const WorkflowApprovalDocumentActivityListComponent: FunctionComponent<Props> = ({
  documentActivity,
  additionalData,
}: Props) => {
  return (
    <FlowLayout growFirst>
      <Typography.Text strong ellipsis>
        <Fmt id={`WorkflowApprovalDocumentActivityList.activityType.${documentActivity.type}`} />
      </Typography.Text>
      {documentActivity.user && <UserAvatar user={documentActivity.user} size={UserAvatarSize.Small} />}
      <RevisionNumberTag
        state={documentActivity.documentRevision.revisionState}
        no={documentActivity.documentRevision.number}
        size="small"
      />
      {additionalData && additionalData(documentActivity)}
    </FlowLayout>
  );
};

export const WorkflowApprovalDocumentActivityRow = React.memo(WorkflowApprovalDocumentActivityListComponent);
