import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './EmptyStyled.module.less';

const EmptyStyled: FunctionComponent<EmptyProps> = ({ children, className, ...props }) => (
  <Empty className={classNames(styles.empty, className)} {...props}>
    {children}
  </Empty>
);

export default EmptyStyled;
