import { FilterOutlined, SyncOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Switch } from 'antd';
import { ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel/StackPanel';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment-business-days';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ignoreRef } from 'utils';
import { CalendarExcludedDayFormData } from '../CalendarExcludedDayForm/CalendarExcludedDayForm';
import { CalendarExcludedDayFormModal } from '../CalendarExcludedDayForm/CalendarExcludedDayFormModal';
import { CalendarExcludedDayImportFormData } from '../CalendarExcludedDayImportForm/CalendarExcludedDayImportForm';
import { CalendarExcludedDayImportFormModal } from '../CalendarExcludedDayImportForm/CalendarExcludedDayImportFormModal';
import styles from './CalendarSettingsForm.module.less';
import {
  compareExcludedDays,
  excludedDaySorter,
  filterSelectedDay,
  getExcludeDayFormat,
  momentFromExcludedDay,
} from './CalendarSettingsForm.utils';

const { Panel } = Collapse;

type Props = {
  value: ExcludedDaySetDto[];
  onChange: (value: ExcludedDaySetDto[]) => void;
  showOnlyFuture: boolean;
  setShowOnlyFuture: React.Dispatch<React.SetStateAction<boolean>>;
};

const TOOLTIP_DELAY = 0.6;

const CalendarSettingsExcludedDaysItemComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  showOnlyFuture,
  setShowOnlyFuture,
}) => {
  const [editDayModalVisible, showEditDayModal, hideEditDayModal] = useBoolean(false);
  const [importModalVisible, showImportModal, hideImportModal] = useBoolean(false);
  const [selectedDay, setSelectedDay] = useState<ExcludedDaySetDto>();
  const [isRepeating, setIsRepeating] = useState<boolean>();
  const intl = useIntl();

  const showExcludedDayEdit = useCallback(
    (excludedDay: ExcludedDaySetDto) => {
      setSelectedDay(excludedDay);
      setIsRepeating(!excludedDay.year);
      showEditDayModal();
    },
    [showEditDayModal]
  );

  const showExcludedDayAdd = useCallback(() => {
    setSelectedDay(undefined);
    showEditDayModal();
  }, []);

  const showExcludedDaysImport = useCallback(() => {
    setSelectedDay(undefined);
    showImportModal();
  }, []);

  const handleExcludedDaySubmit = useCallback(
    (data: CalendarExcludedDayFormData, excludedDay: ExcludedDaySetDto) => {
      if (!!selectedDay) {
        onChange([...filterSelectedDay(value, selectedDay), excludedDay].sort(excludedDaySorter));
      } else {
        onChange([...value, excludedDay].sort(excludedDaySorter));
      }

      setSelectedDay(undefined);
      hideEditDayModal();
    },
    [onChange, selectedDay, value]
  );

  const handleExcludedDayDelete = useCallback(
    (excludedDay: ExcludedDaySetDto) => {
      onChange([...filterSelectedDay(value, excludedDay)]);
    },
    [onChange, value]
  );

  const handleExcludedDayRepeatToggle = useCallback(
    (excludedDay: ExcludedDaySetDto) => {
      const toggledRepeat: ExcludedDaySetDto = {
        ...excludedDay,
        year: !!excludedDay.year ? undefined : moment().year(), // TODO: Save current year when toggling for user comfort, and restore from save instead setting curent year if it was saved
      };
      onChange([...filterSelectedDay(value, excludedDay), toggledRepeat].sort(excludedDaySorter));
    },
    [onChange, value]
  );

  const handleExcludedDaysImport = useCallback(
    (data: CalendarExcludedDayImportFormData, response?: ExcludedDaySetDto[]) => {
      onChange(
        [
          ...value,
          ...response.filter(
            (newHoliday) => !value.some((existingDay) => compareExcludedDays(existingDay, newHoliday))
          ),
        ].sort(excludedDaySorter)
      );
      hideImportModal();
    },
    [hideImportModal, onChange, value]
  );

  const renderExcludedDay = (excludedDay: ExcludedDaySetDto) => {
    return (
      <Row>
        <Col span={14}>
          <CommonHubEllipsisText title={excludedDay.name} mouseEnterDelay={TOOLTIP_DELAY}>
            {excludedDay.name}
          </CommonHubEllipsisText>
        </Col>
        <Col span={4}>{momentFromExcludedDay(excludedDay).format(getExcludeDayFormat(excludedDay.year))}</Col>
        <Col span={2}>
          <CommonHubTooltip
            title={
              !!excludedDay.year
                ? intl.formatMessage({ id: 'CalendarSettingsExcludedDaysItem.onlyOnce' })
                : intl.formatMessage({ id: 'CalendarSettingsExcludedDaysItem.repeating' })
            }
            mouseEnterDelay={TOOLTIP_DELAY}
          >
            <Button
              icon={!!excludedDay.year ? <TagOutlined /> : <SyncOutlined />}
              type="link"
              onClick={() => handleExcludedDayRepeatToggle(excludedDay)}
            />
          </CommonHubTooltip>
        </Col>
        <Col span={2}>
          <EditButton onClick={() => showExcludedDayEdit(excludedDay)} />
        </Col>
        <Col span={2}>
          <DeleteButton onClick={() => handleExcludedDayDelete(excludedDay)} />
        </Col>
      </Row>
    );
  };

  const displayedExcludedDays = useMemo(
    () =>
      showOnlyFuture
        ? value.filter((day) => !day.year || momentFromExcludedDay(day) > moment()).sort(excludedDaySorter)
        : value.sort(excludedDaySorter),
    [showOnlyFuture, value]
  );
  const handleExcludeDayAdd = useCallback((repeated: boolean) => {
    setIsRepeating(repeated);
    showExcludedDayAdd();
  }, []);

  return (
    <StackPanel vertical>
      <StackPanel vertical>
        <Collapse>
          <Panel header={intl.formatMessage({ id: 'CalendarSettingsExcludedDaysItem.onlyOnce' })} key="onlyOnce">
            <StackPanel justifyContent="endContent">
              <CommonHubTooltip title={intl.formatMessage({ id: 'CalendarSettingsForm.filterPast.tooltip' })}>
                <Switch
                  checkedChildren={<FilterOutlined />}
                  unCheckedChildren={<FilterOutlined />}
                  onChange={() => setShowOnlyFuture((value) => !value)}
                />
              </CommonHubTooltip>
            </StackPanel>
            <StackPanel vertical scrollable className={styles.holidayWrapper}>
              {displayedExcludedDays.filter((excludeDay) => !!excludeDay.year).map(renderExcludedDay)}
            </StackPanel>
            <FlowLayout>
              <Button onClick={() => handleExcludeDayAdd(false)} type="primary">
                <Fmt id="general.add" />
              </Button>
            </FlowLayout>
          </Panel>
          <Panel header={intl.formatMessage({ id: 'CalendarSettingsExcludedDaysItem.repeating' })} key="repeating">
            <StackPanel vertical scrollable className={styles.holidayWrapper}>
              {displayedExcludedDays.filter((excludeDay) => !excludeDay.year).map(renderExcludedDay)}
            </StackPanel>
            <FlowLayout>
              <Button onClick={() => handleExcludeDayAdd(true)} type="primary">
                <Fmt id="general.add" />
              </Button>
            </FlowLayout>
          </Panel>
        </Collapse>
      </StackPanel>
      <FlowLayout alignRight>
        <Margin top>
          <Button onClick={showExcludedDaysImport} type="primary">
            <Fmt id="CalendarSettingsExcludedDaysItem.import" />
          </Button>
        </Margin>
      </FlowLayout>
      <ErrorBoundary>
        <CalendarExcludedDayImportFormModal
          intl={intl}
          onSubmit={handleExcludedDaysImport}
          visible={importModalVisible}
          onClose={hideImportModal}
        />
      </ErrorBoundary>
      <CalendarExcludedDayFormModal
        intl={intl}
        onSubmit={handleExcludedDaySubmit}
        visible={editDayModalVisible}
        onClose={hideEditDayModal}
        initialExcludedDay={selectedDay}
        isRepeating={isRepeating}
      />
    </StackPanel>
  );
};

export const CalendarSettingsExcludedDaysItem: FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(
  React.memo(CalendarSettingsExcludedDaysItemComponent)
);
