export const createCssElement = (src: string, onLoad?: () => void): HTMLLinkElement => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = src;
  link.type = 'text/css';
  link.onload = onLoad;
  document.head.appendChild(link);
  return link;
};

export const loadCss = (src: string) => {
  return new Promise<void>((resolve) => {
    const onLoad = () => {
      resolve();
    };
    createCssElement(src, onLoad);
  });
};
