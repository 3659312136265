import { AccessLevelEnum } from 'api/completeApiInterfaces';
import AccessLevel from 'components/AccessLevel';
import { AccessLevelProps } from 'components/AccessLevel/AccessLevel';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';

type DirectoryAccessLevelProps = Omit<
  AccessLevelProps<AccessLevelEnum>,
  'validValues' | 'translations' | 'accessLevelMap' | 'accessLevelReverseMap'
>;

export const accessLevelMap: Record<AccessLevelEnum, number> = {
  none: 0,
  read: 1,
  write: 2,
  admin: 3,
};

export const accessLevelReverseMap: Record<number, AccessLevelEnum> = {
  0: AccessLevelEnum.none,
  1: AccessLevelEnum.read,
  2: AccessLevelEnum.write,
  3: AccessLevelEnum.admin,
};

const translations: Record<AccessLevelEnum, IntlMessageId> = {
  [AccessLevelEnum.none]: 'AccessLevel.tooltips.none',
  [AccessLevelEnum.read]: 'AccessLevel.tooltips.read',
  [AccessLevelEnum.write]: 'AccessLevel.tooltips.write',
  [AccessLevelEnum.admin]: 'AccessLevel.tooltips.admin',
};

const DirectoryAccessLevel: FunctionComponent<DirectoryAccessLevelProps> = ({ ...restProps }) => {
  return (
    <AccessLevel
      accessLevelMap={accessLevelMap}
      accessLevelReverseMap={accessLevelReverseMap}
      translations={translations}
      {...restProps}
    />
  );
};

export default React.memo(DirectoryAccessLevel);
