import { Button, Layout } from 'antd';
import HeaderInfoDropdown from 'components/HeaderInfoDropdown';
import { HeaderSearch } from 'components/HeaderSearch/HeaderSearch';
import HeaderUserDropdown from 'components/HeaderUserDropdown';
import { ProjectName } from 'components/ProjectName/ProjectName';
import { useActiveProject, useStoreSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { DevelopmentStatusText } from './DevelopmentStatusText';
import styles from './MainModule.Header.module.less';
import { MenuOutlined } from '@ant-design/icons';
import HeaderAppLogo from 'components/HeaderAppLogo';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { NotificationContextProvider } from 'components/HeaderNotificationDropdown/NotificationContextProvider';
import { HeaderSearchContextProvider } from 'components/HeaderSearch/HeaderSearchContextProvider';
import { HeaderSearchComponent } from 'components/HeaderSearch/HeaderSearchButton';
import classnames from 'classnames';

const mapStateToProps = (state: RootState) => ({
  locale: state.appConfig.locale,
  currentAppUser: state.currentAppUser.data,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({ setLocale: dispatch.appConfig.setLocale });

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = RouteComponentProps<ProjectRouteParams> &
  PropsFromState &
  PropsFromDispatch & {
    onProjectClick?: () => void;
    showSearch?: boolean;
  };

const Header: FunctionComponent<Props> = (props) => {
  const { locale, setLocale, currentAppUser, onProjectClick, showSearch } = props;
  const dispatch = useDispatch<Dispatch>();
  const { sideMenuCollapsed } = useStoreSelector((state) => state.appConfig);

  const activeProject = useActiveProject();

  return (
    <HeaderSearchContextProvider projectId={props.match.params.projectId}>
      <Layout.Header className={styles.header}>
        <FlowLayout gapSize="none" className={styles.mainBar}>
          <Button
            className={classnames(styles.hamburger)}
            size="middle"
            type="link"
            icon={<MenuOutlined />}
            onClick={() => {
              dispatch.appConfig.setSideMenuCollapsed(!sideMenuCollapsed);
            }}
          />
          {sideMenuCollapsed && (
            <Link to="/">
              <HeaderAppLogo small />
            </Link>
          )}
          <ProjectName project={activeProject} onProjectClick={onProjectClick} />
          <DevelopmentStatusText />
          <FlowLayout gapSize="none" alignRight>
            {showSearch && activeProject && <HeaderSearchComponent />}
            <HeaderInfoDropdown />
            {currentAppUser && (
              <NotificationContextProvider>
                <HeaderUserDropdown user={currentAppUser} selectedLocale={locale} onLocaleChange={setLocale} />
              </NotificationContextProvider>
            )}
          </FlowLayout>
        </FlowLayout>
        <HeaderSearch />
      </Layout.Header>
    </HeaderSearchContextProvider>
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(Header);
