import { ForgeElementParams } from 'Forge/Forge';
import React, { createContext, FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';

type ForgeElementPropertiesCallbackType = (elementIds: string[]) => Promise<ForgeElementParams[]>;

type Props = { children: React.ReactNode };

export type ForgeModelInfoContextValueType = {
  existingElementIds: Set<string>;
  geometryLoaded: boolean;
  setExistingElementIds: (elementIds: Set<Guid>) => void;
  setGeometryLoaded: (loadedState: boolean) => void;
  setForgeElementPropertiesCallback: (forgeElementPropertiesFunction: ForgeElementPropertiesCallbackType) => void;
  getForgeElementProperties: ForgeElementPropertiesCallbackType;
};

export const ForgeModelInfoContext = createContext<ForgeModelInfoContextValueType>(undefined);

export const useForgeModelInfoContext = (): ForgeModelInfoContextValueType => {
  const context = React.useContext(ForgeModelInfoContext);

  if (context === undefined) {
    throw new Error('useForgeModelInfoContext must be used within a ForgeModelInfoContextProvider');
  }

  return context;
};

const ForgeModelInfoContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [existingElementIds, setExistingElementIds] = useState<Set<Guid>>(new Set<Guid>());
  const [geometryLoaded, setGeometryLoaded] = useState(false);
  const forgeElementPropertiesCallbackRef = useRef<ForgeElementPropertiesCallbackType>(undefined);

  const getForgeElementProperties = useCallback(async (elementIds: string[]) => {
    if (!!forgeElementPropertiesCallbackRef.current) {
      return await forgeElementPropertiesCallbackRef.current(elementIds);
    }

    return [];
  }, []);

  const setForgeElementPropertiesCallback = useCallback(
    (forgeElementPropertiesFunction: ForgeElementPropertiesCallbackType) => {
      forgeElementPropertiesCallbackRef.current = forgeElementPropertiesFunction;
    },
    []
  );

  const contextValue = useMemo(() => {
    return {
      existingElementIds,
      geometryLoaded,
      setExistingElementIds,
      setGeometryLoaded,
      setForgeElementPropertiesCallback,
      getForgeElementProperties,
    };
  }, [
    existingElementIds,
    geometryLoaded,
    setExistingElementIds,
    setGeometryLoaded,
    setForgeElementPropertiesCallback,
    getForgeElementProperties,
  ]);

  return <ForgeModelInfoContext.Provider value={contextValue}>{children}</ForgeModelInfoContext.Provider>;
};

export default React.memo(ForgeModelInfoContextProvider);
