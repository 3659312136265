import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { FormattedRelative } from 'react-intl';

type Props = { date: string; tooltipTranslationKey?: IntlMessageId };

export const RelativeDateDisplay: FunctionComponent<Props> = ({ date, tooltipTranslationKey }) => {
  const intl = useIntl();
  if (date) {
    const formattedDate = moment(date)
      .locale(intl.locale)
      .format('lll');
    return (
      <CommonHubTooltip
        placement="top"
        title={
          tooltipTranslationKey ? <Fmt id={tooltipTranslationKey} values={{ date: formattedDate }} /> : formattedDate
        }
      >
        <div>
          <FormattedRelative value={date} />
        </div>
      </CommonHubTooltip>
    );
  }
  return null;
};
