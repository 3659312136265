import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DatePicker } from 'antd';
import { InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { useEffect } from 'react';
import { isDateInThePast } from '../AssignmentForm/AssignmentForm';

export type CalendarExcludedDayImportFormData = { endDate: moment.Moment };

type Props = FormComponentProps<CalendarExcludedDayImportFormData> & InjectedIntlProps;

const CalendarExcludedDayImportFormComponent = React.forwardRef<unknown, Props>(({ intl, form }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'CalendarExcludedDayImportForm.label.endDate' })}>
        {form.getFieldDecorator<CalendarExcludedDayImportFormData>('endDate', {
          initialValue: moment().add(1, 'year'),
        })(<DatePicker disabledDate={(current) => isDateInThePast(current)} allowClear={false} />)}
      </Form.Item>
    </Form>
  );
});

export const CalendarExcludedDayImportForm = Form.create<Props>()(CalendarExcludedDayImportFormComponent);
