import { FolderFilled, FolderOpenFilled, FolderOpenOutlined, FolderOutlined, HddOutlined } from '@ant-design/icons';
import { DropDownProps, Dropdown, MenuProps, Modal, Tree, message } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { ProjectTemplateDirectoryDto } from 'api/completeApiInterfaces';
import DroppedFilesErrorAlert from 'components/DroppedFilesErrorAlert/DroppedFilesErrorAlert';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { FileSystemTreeNode, FileUploadError } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { createFileTreeNode, onDropHandler } from 'components/PrimaryFileInput/CommonFilesInputUtils';
import { DropArea } from 'components/PrimaryFileInput/DropArea';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useBoolean, useSameCallback } from 'hooks';
import { useMasterDetailView } from 'hooks/useMasterDetailView';
import { useOnDropProvider } from 'hooks/useOnDropProvider';
import { Fmt, InjectedIntl } from 'locale';
import { uniq } from 'lodash';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import { AllowDrop } from 'rc-tree/lib/Tree';
import React, { ChangeEvent, FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { strCompareCI } from 'utils';
import { textComparer } from 'utils/comparators';
import { modalConfirm } from 'utils/modalConfirm';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import styles from './ProjectTemplateDirectoryDetail.module.less';
import ProjectTemplateDirectoryDetailPanel from './ProjectTemplateDirectoryDetailPanel';
import ProjectTemplateDirectoryFormModal from './ProjectTemplateDirectoryFormModal';

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
};

const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['contextMenu'];
const EXPAND_TREE_ACTION = 'doubleClick';
const DISABLE_TREE_STRUCTURE = false;
const HAS_WRITE_ACCESS = true;

export type TemplateDirectoryNode = ProjectTemplateDirectoryDto & { children: TemplateDirectoryNode[] };

const renderDirectoryTitle = (
  node: TemplateDirectoryNode,
  title: ReactNode,
  contextMenu: (node: TemplateDirectoryNode) => MenuProps
): ReactNode => {
  return (
    <Dropdown menu={contextMenu(node)} trigger={DROPDOWN_TRIGGER}>
      {title}
    </Dropdown>
  );
};

const getDirectoryTreeFlat = (node: TemplateDirectoryNode): TemplateDirectoryNode[] => [
  node,
  ...node.children.flatMap(getDirectoryTreeFlat),
];

const getFlatDroppedDirectories = (parentId: Guid, dirs: FileSystemTreeNode[]): ProjectTemplateDirectoryDto[] => {
  const result: ProjectTemplateDirectoryDto[] = [];
  dirs.forEach((dir) => {
    if (dir.type === 'folder') {
      result.push({
        id: dir.key,
        name: dir.title,
        parentId,
        permissionInheritance: true,
        projectTemplateGroupPermMap: {},
        projectTemplateDirectoryCategoryNodeIds: [],
      });
      if (!!dir.children) {
        result.push(...getFlatDroppedDirectories(dir.key, dir.children as FileSystemTreeNode[]));
      }
    }
  });
  return result;
};

const getFlatDroppedDirectoriesErrors = (dirs: FileSystemTreeNode[]): FileUploadError[] => {
  const errors: FileUploadError[] = [];
  dirs.forEach((dir) => {
    if (dir.type === 'folder') {
      errors.push(...dir.errors);
      if (!!dir.children) {
        errors.push(...getFlatDroppedDirectoriesErrors(dir.children as FileSystemTreeNode[]));
      }
    }
  });
  return errors;
};

const getActualParentDirectoryId = (
  projectTemplateDirectories: ProjectTemplateDirectoryDto[],
  selectedDirectories?: ProjectTemplateDirectoryDto[]
) => {
  return !!selectedDirectories[0]?.id
    ? selectedDirectories[0].id
    : projectTemplateDirectories.find((dir) => !dir.parentId).id;
};

const ProjectTemplateDirectoriesTab: FunctionComponent<Props> = ({
  intl,
  projectTemplate,
  dispatchProjectTemplate,
}) => {
  const [editedDirectoryId, setEditedDirectoryId] = useState<Guid>();
  const [expandedKeys, setExpandedKeys] = useState<Guid[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Guid[]>([]);
  const [droppedFilesErrors, setDroppedFilesErrors] = useState<FileUploadError[]>([]);

  const [isDirectoryModalVisible, showDirectoryModal, hideDirectoryModal] = useBoolean(false);

  const [onDropFiles, droppedFiles, onCloseFileUpload, fileUploadVisible, onShowFileUpload] = useOnDropProvider(
    HAS_WRITE_ACCESS
  );

  const selectedDirectories = useMemo(
    () =>
      projectTemplate.projectTemplateDirectories.filter((directory) =>
        selectedKeys.some((key) => directory.id === key)
      ),
    [selectedKeys, projectTemplate.projectTemplateDirectories]
  );

  const editedDirectory = useMemo(
    () => projectTemplate.projectTemplateDirectories.find((directory) => directory.id === editedDirectoryId),
    [editedDirectoryId, projectTemplate.projectTemplateDirectories]
  );

  const adminGroupId = useMemo(() => projectTemplate.projectTemplateGroups.find((group) => group.isAdminGroup).id, [
    projectTemplate.projectTemplateGroups,
  ]);

  const directoryMap = useMemo(() => {
    const directoriesById: Record<Guid, TemplateDirectoryNode> = {};
    projectTemplate.projectTemplateDirectories.forEach((directory) => {
      directoriesById[directory.id] = { ...directory, children: [] };
    });

    Object.values(directoriesById).forEach((directory) => {
      if (!!directory.parentId && !!directoriesById[directory.parentId]) {
        directoriesById[directory.parentId].children.push(directory);
      }
    });

    return directoriesById;
  }, [projectTemplate.projectTemplateDirectories]);

  const directoryTreeRoot = useMemo(
    () => !!directoryMap && Object.values(directoryMap).find((directory) => !directory.parentId),
    [directoryMap]
  );

  const selectedParentId = useMemo(
    () => (!!selectedDirectories.length ? selectedDirectories[0].id : directoryTreeRoot.id),
    [selectedDirectories, directoryTreeRoot.id]
  );

  const handleDirectoryDelete = useCallback(
    async (directoryId: Guid) => {
      if (!directoryMap) return;

      if (
        !!directoryMap[directoryId].children.length &&
        !(await modalConfirm({
          title: intl.formatMessage({ id: 'ProjectTemplateDirectoriesTab.delete.confirmNotEmpty' }),
        }))
      ) {
        return;
      }

      dispatchProjectTemplate({
        type: 'deleteDirectory',
        directoryIds: getDirectoryTreeFlat(directoryMap[directoryId]).map((directory) => directory.id),
      });
      setSelectedKeys([]);
    },
    [directoryMap, intl, dispatchProjectTemplate]
  );

  const handleDirectoryChange = useCallback(
    (directory: ProjectTemplateDirectoryDto) => {
      const isExistingDirectory = projectTemplate.projectTemplateDirectories.some(
        (templateDirectory) => templateDirectory.id === directory.id
      );
      if (!!isExistingDirectory) {
        dispatchProjectTemplate({
          type: 'updateDirectory',
          directory: directory,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addDirectory',
          directory: [directory],
        });
        setExpandedKeys((keys) => uniq([...keys, directory.parentId]));
      }
    },
    [dispatchProjectTemplate, projectTemplate]
  );

  const handleDirectorySubmit = useCallback(
    (directory: ProjectTemplateDirectoryDto) => {
      handleDirectoryChange(directory);
      setEditedDirectoryId(undefined);
      hideDirectoryModal();
    },
    [hideDirectoryModal, handleDirectoryChange]
  );

  const showDirectoryCreate = useCallback(() => {
    setEditedDirectoryId(undefined);
    showDirectoryModal();
  }, []);

  const showDirectoryEdit = useCallback((directoryId: Guid) => {
    setEditedDirectoryId(directoryId);
    showDirectoryModal();
  }, []);

  const validateUniqueNameInsideDirectory = useCallback(
    (name: string): boolean =>
      !directoryMap[selectedParentId]?.children.some(
        (directory) =>
          strCompareCI(directory.name, name) === 0 && (!!editedDirectoryId ? directory.id !== editedDirectoryId : true)
      ),
    [directoryMap, selectedParentId, editedDirectoryId]
  );

  const onDrop = useCallback(
    (params) => {
      const sourceNodeId = params.dragNode?.key as Guid;
      const targetNodeId = params.node?.key as Guid;
      if (!sourceNodeId || !targetNodeId) return;

      const movingDirectory = directoryMap[sourceNodeId];
      const targetDirectory = directoryMap[targetNodeId];

      if (targetDirectory?.children.some((directory) => strCompareCI(directory.name, movingDirectory.name) === 0)) {
        void message.error(intl.formatMessage({ id: 'ProjectTemplateDirectoriesTab.move.duplicateNameError' }));
        return;
      }

      dispatchProjectTemplate({
        type: 'updateDirectory',
        directory: { ...movingDirectory, parentId: targetDirectory.id },
      });
    },
    [directoryMap, dispatchProjectTemplate, intl]
  );

  const onDragEnter = useCallback((info) => {
    setExpandedKeys(info.expandedKeys as Guid[]);
  }, []);

  const allowDrop: AllowDrop = (options) =>
    !(
      options.dragNode.key === options.dropNode.key || // target is the moved node
      options.dropNode.disabled || // target is disabled
      // target is parent of the moved node
      options.dropNode.children?.some((child) => child.key === options.dragNode.key)
    );

  const directoriesContextMenu = useCallback(
    (directory: TemplateDirectoryNode): MenuProps => ({
      items: [
        {
          label: <Fmt id="AllDocumentsPage.docMenu.createDirectory" />,
          key: 'createDirectory',
          onClick: () => showDirectoryCreate(),
        },
        { label: <Fmt id="general.edit" />, key: 'edit', onClick: () => showDirectoryEdit(directory.id) },
        {
          label: <Fmt id="general.delete" />,
          key: 'delete',
          onClick: () => handleDirectoryDelete(directory.id),
          disabled: !directory.parentId,
        },
      ],
    }),
    [handleDirectoryDelete, showDirectoryCreate, showDirectoryEdit]
  );

  const handleExpandTree = useCallback((expandedKeys: Guid[]) => {
    setExpandedKeys(expandedKeys);
  }, []);

  const handleTreeSelection = useCallback((selectedKeys: Guid[]) => {
    setSelectedKeys(selectedKeys);
  }, []);

  const getTreeNodeData = useCallback(
    (directory: TemplateDirectoryNode): DataNode => {
      const isExpanded = expandedKeys.indexOf(directory.id) !== -1 || directory.id === directoryTreeRoot.id;
      const filled = directory.children.length > 0;
      const icon =
        directory.parentId === null ? (
          <HddOutlined />
        ) : isExpanded ? (
          filled ? (
            <FolderOpenFilled />
          ) : (
            <FolderOpenOutlined />
          )
        ) : filled ? (
          <FolderFilled />
        ) : (
          <FolderOutlined />
        );

      const title = (
        <NoClickTooltip placement="right" title={directory.name} mouseEnterDelay={0.5}>
          <StackPanel>
            <span className={styles.directoryIcon}>{icon}</span> {directory.name}
          </StackPanel>
        </NoClickTooltip>
      );
      return {
        title: renderDirectoryTitle(directory, title, directoriesContextMenu),
        key: directory.id,
        children: [...directory.children]
          .sort(textComparer.map((child) => child.name))
          .map((child) => getTreeNodeData(child)),
      };
    },
    [directoriesContextMenu, directoryTreeRoot, expandedKeys]
  );

  const directoryTreeNodes = useMemo((): DataNode[] => {
    return [getTreeNodeData(directoryTreeRoot)];
  }, [getTreeNodeData, directoryTreeRoot]);

  const hideDirectoryDetail = () => setSelectedKeys([]);

  useEffect(() => {
    if (!!droppedFiles.length) {
      const actualParentDirectoryId = getActualParentDirectoryId(
        projectTemplate.projectTemplateDirectories,
        selectedDirectories
      );
      if (
        directoryMap[actualParentDirectoryId]?.children.some(
          (directory) => strCompareCI(directory.name, droppedFiles[0]?.title) === 0
        )
      ) {
        message.error(intl.formatMessage({ id: 'ProjectTemplateDirectoriesTab.move.duplicateNameError' }));
      } else {
        const droppedDirsToDispatchData = getFlatDroppedDirectories(actualParentDirectoryId, droppedFiles);
        if (!!droppedDirsToDispatchData.length) {
          dispatchProjectTemplate({
            type: 'addDirectory',
            directory: droppedDirsToDispatchData,
          });
        } else {
          message.warning(intl.formatMessage({ id: 'ProjectTemplateDirectoriesTab.noDirsWarning' }));
        }
      }
    }
  }, [droppedFiles]);

  const onDropDirStructure = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer) {
      const [result, rootErrors] = await onDropHandler(event, DISABLE_TREE_STRUCTURE);
      onDropFiles?.(result);
      onCloseFileUpload();
    }
  };

  useEffect(() => {
    const droppedFilesErrors = getFlatDroppedDirectoriesErrors(droppedFiles);
    !!droppedFilesErrors.length && setDroppedFilesErrors(droppedFilesErrors);
  }, [droppedFiles]);

  const onChangeInput = useSameCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.currentTarget?.files;
    if (files && files.length > 0) {
      const data = Object.values(files).map((f) => createFileTreeNode(f));
      onDropFiles?.(data);
    }
  });

  const { tableWrapRef, title, children } = useMasterDetailView(
    <Panel
      addButtonOnClick={showDirectoryModal}
      addButtonText={<Fmt id="ProjectTemplateDirectoryFormModal.add.title" />}
    >
      <StackPanel vertical scrollable>
        <Tree.DirectoryTree
          blockNode
          showIcon={false}
          draggable
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          allowDrop={allowDrop}
          expandAction={EXPAND_TREE_ACTION}
          onSelect={handleTreeSelection}
          onExpand={handleExpandTree}
          expandedKeys={expandedKeys}
          treeData={directoryTreeNodes}
          selectedKeys={selectedKeys}
        />
      </StackPanel>
    </Panel>,
    selectedDirectories?.length === 1 && (
      <ProjectTemplateDirectoryDetailPanel
        intl={intl}
        projectTemplate={projectTemplate}
        selectedDirectory={selectedDirectories[0]}
        directoryMap={directoryMap}
        onDirectoryChange={handleDirectoryChange}
      />
    ),
    intl.formatMessage({ id: 'general.folders' }),
    selectedDirectories.length === 1 && selectedDirectories[0].name,
    hideDirectoryDetail
  );

  return (
    <>
      <StackPanel vertical divRef={tableWrapRef}>
        <DropArea
          multiple={true}
          onChangeInput={onChangeInput}
          onDrop={onDropDirStructure}
          inputRef={undefined}
          title={intl.formatMessage({ id: 'ProjectTemplateDirectoriesTab.dropzoneText' })}
        >
          <PageContent title={title}>{children}</PageContent>
        </DropArea>
        {isDirectoryModalVisible && (
          <ProjectTemplateDirectoryFormModal
            onSubmit={handleDirectorySubmit}
            onClose={hideDirectoryModal}
            visible={isDirectoryModalVisible}
            editedDirectory={editedDirectory}
            validateUniqueName={validateUniqueNameInsideDirectory}
            adminGroupId={adminGroupId}
            selectedParentId={selectedParentId}
          />
        )}
      </StackPanel>

      {!!droppedFilesErrors.length && (
        <Modal
          open={!!droppedFilesErrors.length}
          onOk={() => setDroppedFilesErrors([])}
          onCancel={() => setDroppedFilesErrors([])}
          cancelButtonProps={HIDE_BUTTON_PROPS}
          title={<Fmt id="general.error" />}
          width={800}
        >
          <DroppedFilesErrorAlert
            droppedFilesErrors={droppedFilesErrors}
            treeLoadingErrorMessageId="ProjectTemplateDirectoriesTab.dropzone.treeLoading.error"
            treeLoadingErrorHelpMessageId="ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.help"
            treeLoadingErrorListTitleMessageId="ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.listTitle"
          />
        </Modal>
      )}
    </>
  );
};

export default ProjectTemplateDirectoriesTab;
