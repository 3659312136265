import { DragableGrid } from 'components/DragableGrid/DragableGrid';
import { useReportWidgetsContext } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import React, { FunctionComponent, useEffect } from 'react';
import ReportCard from '../ReportCard';

type Props = {};

const ReportsGrid: FunctionComponent<Props> = () => {
  const {
    orderedWidgets,
    setWidgetOrder,
    sharedWidgets,
    requestSharedWidgets,
    sharedWidgetsLoading,
  } = useReportWidgetsContext();

  useEffect(() => {
    if (orderedWidgets?.length > 0) {
      for (const widget of orderedWidgets) {
        if (widget.type === 'shared') {
          void requestSharedWidgets(widget.organizationId);
        }
      }
    }
  }, [orderedWidgets]);

  return (
    <DragableGrid
      cardItems={orderedWidgets}
      onMove={setWidgetOrder}
      drawCard={(drag, configuration) => (
        <ReportCard
          reportConfiguration={configuration}
          sharedWidgets={sharedWidgets}
          sharedWidgetsLoading={sharedWidgetsLoading}
          dragRef={drag}
        />
      )}
    />
  );
};

export default ReportsGrid;
