import { AssignmentStateEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { StateIcon } from 'components/RevisionNumberTag/StateIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './AssignmentState.module.less';

const ASSIGNMENT_STYLES: Record<AssignmentStateEnum, string> = {
  new: styles.statusNeW,
  inProgress: styles.statusInProgress,
  forApproval: styles.statusForApproval,
  solved: styles.statusSolved,
  closed: styles.statusClosed,
};

const UNKNOWN_STYLE = styles.statusUnknown;

type Props = {
  state?: AssignmentStateEnum;
  additionalContent?: ReactNode;
  tooltip?: ReactNode;
  onClick?: () => void;
  clickable?: boolean;
  className?: string;
};

export const AssignmentState: FunctionComponent<Props> = ({
  state,
  clickable,
  additionalContent,
  tooltip,
  onClick,
  className,
}) => {
  const title = !!state ? <Fmt id={`AssignmentStateEnum.${state}`} /> : <Fmt id="general.unknown" />;
  return (
    <StateIcon
      tooltipPlacement="top"
      className={classNames(
        styles.status,
        clickable && styles.clickable,
        !!state ? ASSIGNMENT_STYLES[state] : UNKNOWN_STYLE,
        className
      )}
      title={tooltip}
      onClick={onClick}
    >
      <FlowLayout>
        {title}
        {additionalContent}
      </FlowLayout>
    </StateIcon>
  );
};
