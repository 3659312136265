import { Button, Divider } from 'antd';
import { api } from 'api';
import { AuditLogDMSRequestDto, LanguageEnum, ServiceError } from 'api/completeApiInterfaces';
import Axios from 'axios';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Margin } from 'components/Margin/Margin';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { csMessages } from 'locale/messages/cs';
import { enMessages } from 'locale/messages/en';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { processApiError } from 'utils';
import AdministrationMetadataForm, { AdministrationMetadataFormData } from './AdministrationMetadataForm';
import { downloadBlob } from 'utils/downloadFile';

type Props = FormModalProps<undefined>;

export const AdministrationMetadataFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, ...restProps } = props;
  const intl = useIntl();

  const auditLogData = useMemo((): AuditLogDMSRequestDto => {
    const translationDb = intl.locale === LanguageEnum.en ? enMessages : csMessages;
    const auditLogLocalizations = (Object.keys(translationDb) as (keyof typeof translationDb)[])
      .filter((key) => key.startsWith('AuditLog.'))
      .reduce((obj, key) => {
        obj[key] = translationDb[key];
        return obj;
      }, {} as Dictionary<string>);

    return {
      auditLogTranslationsDto: { language: intl.locale, localization: auditLogLocalizations },
    };
  }, [intl]);

  const [auditLogFile, AuditLogError, auditLogLoading, loadAuditLogFile, setAuditLogFileData] = useApiData(
    (ct) => {
      return api.project.auditLog.getAuditLogDmsExport(auditLogData, ct);
    },
    { autoload: false }
  );

  const [metaDataFile, metaDataError, metaDataLoading, loadMetaDataFile, setMetaDataFileData] = useApiData(
    (ct) => {
      return api.project.documents.getDMSMetadata({ language: intl.locale });
    },
    { autoload: false }
  );

  const [dmsDownloadLink, setDmsDownloadLink] = useState<string>();
  const [dmsDownloadLinkError, setDmsDownloadLinkError] = useState<ServiceError>();
  const [isDocumentsPreparing, setIsDocumentPreparing] = useState<boolean>(false);
  const error = dmsDownloadLinkError || metaDataError || AuditLogError;
  const isAllDocumentsReady = !!auditLogFile && !!metaDataFile && dmsDownloadLink;

  const handleSubmit: FormSubmitHandler<AdministrationMetadataFormData> = async (values) => {
    if (isAllDocumentsReady) {
      handleClose();
      return null;
    }

    setIsDocumentPreparing(true);

    loadMetaDataFile();
    loadAuditLogFile();

    const [errDirDownload, resDirDownload] = await api.project.directories.getDirectoryDownloadWithSubdirectories({
      onlyCurrentRevisions: values.onlyCurrentRevisions,
    });

    if (!!errDirDownload && !Axios.isCancel(errDirDownload)) {
      processApiError(errDirDownload, (error) => {
        setDmsDownloadLinkError(error);
      });
    }
    if (!!resDirDownload) {
      const { url } = resDirDownload.data;
      setDmsDownloadLink(url);
    }

    return null;
  };

  const handleClose = useCallback(() => {
    setAuditLogFileData(undefined);
    setMetaDataFileData(undefined);
    setIsDocumentPreparing(false);
    onClose && onClose();
  }, []);

  const handleAuditLogDownload = useCallback(() => {
    if (!!auditLogFile) {
      const blob = new Blob([auditLogFile], { type: 'text/plain' });
      downloadBlob(blob, `_activity.log`);
    }
  }, [auditLogFile]);

  const handleMetaDataDownload = useCallback(() => {
    if (!!metaDataFile) {
      const blob = new Blob([metaDataFile], { type: 'application/ms-excel' });
      downloadBlob(blob, `_metadata.xlsx`);
    }
  }, [metaDataFile]);

  const handleDMSDownload = useCallback(() => {
    if (!!dmsDownloadLink) {
      window.open(dmsDownloadLink, '_blank');
    }
  }, [dmsDownloadLink]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      onCancel={handleClose}
      titleId="Administration.downloadDMSMetaDataModal.modalTitle"
      confirmClose={false}
      onSubmitDisabled={isDocumentsPreparing && !isAllDocumentsReady}
      submitTextId={
        isDocumentsPreparing
          ? isAllDocumentsReady
            ? 'general.ok'
            : 'Administration.downloadDMSMetaDataModal.submit.processing'
          : 'Administration.downloadDMSMetaDataModal.submit.prepareDocuments'
      }
      {...restProps}
    >
      {({ intl, formRef }) => (
        <>
          <AdministrationMetadataForm wrappedComponentRef={formRef} intl={intl} disabled={isDocumentsPreparing} />
          {isDocumentsPreparing && (
            <>
              <Divider />
              {!!error ? (
                <ServiceErrorBox error={error} />
              ) : (
                <StackPanel vertical>
                  <Margin bottom>
                    <FlowLayout growFirst>
                      <Fmt id="Administration.downloadDMSMetaDataModal.file.metadata" />
                      <Button
                        type="primary"
                        onClick={handleMetaDataDownload}
                        disabled={metaDataLoading || !metaDataFile}
                      >
                        <Fmt id="general.download" />
                      </Button>
                    </FlowLayout>
                  </Margin>
                  <Margin bottom>
                    <FlowLayout growFirst>
                      <Fmt id="Administration.downloadDMSMetaDataModal.file.activity" />
                      <Button
                        type="primary"
                        onClick={handleAuditLogDownload}
                        disabled={auditLogLoading || !auditLogFile}
                      >
                        <Fmt id="general.download" />
                      </Button>
                    </FlowLayout>
                  </Margin>
                  <FlowLayout growFirst>
                    <Fmt id="Administration.downloadDMSMetaDataModal.file.dms" />
                    <Button type="primary" onClick={handleDMSDownload} disabled={!dmsDownloadLink}>
                      <Fmt id="general.download" />
                    </Button>
                  </FlowLayout>
                </StackPanel>
              )}
            </>
          )}
        </>
      )}
    </FormModalWrapper>
  );
};

export default AdministrationMetadataFormModal;
