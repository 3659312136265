import { Comparator } from './comparators';

export const selectMaxValue = <T>(values: T[] | null, comparator: Comparator<T>) => {
  if (!values?.length) {
    return undefined;
  }
  const copy = [...values];
  const sorted = copy.sort(comparator);
  return sorted[sorted.length - 1];
};

export const selectMinValue = <T>(values: T[] | null, comparator: Comparator<T>) => {
  if (!values?.length) {
    return undefined;
  }
  const copy = [...values];
  const sorted = copy.sort(comparator);
  return sorted[0];
};
