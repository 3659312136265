import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './HeaderAppLogo.module.less';

type Props = {
  small?: boolean;
  style?: CSSProperties;
  className?: string;
};

const HeaderAppLogo: FunctionComponent<Props> = ({ small = false, className, style }) => {
  const title = 'AspeHub';

  return small ? (
    <span className={classNames(styles.logo, className)} title={title} style={style}>
      <span className={styles.icon}>
        <img alt={title} src={process.env.PUBLIC_URL + '/images/logo.svg'} />
      </span>
    </span>
  ) : (
    <span className={classNames(styles.logo, className)} style={style}>
      <span className={styles.icon}>
        <img alt={title} src={process.env.PUBLIC_URL + '/images/logo.svg'} />
      </span>
      <span className={styles.name}>
        Aspe<b>Hub</b>
      </span>
    </span>
  );
};
export default HeaderAppLogo;
