import { Empty } from 'antd';
import { AppUserDto, OrganizationAdminReportDto, ServiceError } from 'api/completeApiInterfaces';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { OrganizationAddFormModal } from 'components/forms/OrganizationAddFormModal';
import { DEFAULT_SETTINGS_PANEL_WIDTH } from 'config/constants';
import { useBoolean } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { textComparer } from 'utils/comparators';
import { OrganizationUsersEditModal } from './OrganizationUsersEditModal';
import OrganizationList from './OrganizationsList';

type Props = InjectedIntlProps & {
  organizations: OrganizationAdminReportDto[];
  appUsers: AppUserDto[];
  loading: boolean;
  error: ServiceError;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  onRemove: (appUserUserName: string) => void;
  loadOrganizationsAdminReport: () => void;
  userCanAddOrganization: boolean;
  configStorageAreas?: string[];
};

const OrganizationsListPanel: FunctionComponent<Props> = ({
  organizations,
  appUsers,
  loading,
  error,
  selectedId,
  onSelect,
  onRemove,
  loadOrganizationsAdminReport,
  userCanAddOrganization,
  configStorageAreas,
}) => {
  const [search, setSearch] = useState<string>('');

  const [addOrganizationsModalVisible, showAddOrganizationsModal, hideAddOrganizationsModal] = useBoolean(false);
  const [organizationToEdit, setOrganizationToEdit] = useState<OrganizationAdminReportDto>(null);
  const [organizationToEditUsers, setOrganizationToEditUsers] = useState<OrganizationAdminReportDto>(null);
  const [
    organizationUsersEditModalVisible,
    showOrganizationUsersEditModal,
    hideOrganizationUsersEditModal,
  ] = useBoolean(false);

  const handleSubmitOrgAddModal = () => {
    hideAddOrganizationsModal();
    setOrganizationToEdit(null);
    loadOrganizationsAdminReport();
  };

  const handleCloseOrgAddModal = () => {
    hideAddOrganizationsModal();
    setOrganizationToEdit(null);
  };

  const editOrganization = (organizationId: Guid) => {
    setOrganizationToEdit(organizations?.find((organization) => organization.id === organizationId) || null);
    showAddOrganizationsModal();
  };

  const editOrganizationUsers = (organizationId: Guid) => {
    setOrganizationToEditUsers(organizations?.find((organization) => organization.id === organizationId) || null);
    showOrganizationUsersEditModal();
  };

  const handleCloseOrganizationEditUsers = () => {
    hideOrganizationUsersEditModal();
    setOrganizationToEditUsers(null);
    loadOrganizationsAdminReport();
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const sortedOrganizations = useMemo(() => organizations.sort(textComparer.map((org) => org.name)), [organizations]);

  const renderContent = () => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading) return <SpinBox />;
    if (!organizations.length) return <Empty />;
    return (
      <StackPanel vertical scrollable autoWidth>
        <OrganizationList
          data={sortedOrganizations}
          search={search}
          onClearSearch={clearSearch}
          onRemove={onRemove}
          onEdit={editOrganization}
          onSelect={onSelect}
          selectedId={selectedId}
          editOrganizationUsers={!onSelect ? editOrganizationUsers : null}
        />
      </StackPanel>
    );
  };

  return (
    <Panel
      noMargin
      panelWidth={DEFAULT_SETTINGS_PANEL_WIDTH}
      addButtonOnClick={showAddOrganizationsModal}
      addButtonText={<Fmt id="general.addOrganization" />}
      onSearch={setSearch}
      searchValue={search}
      addButtonDisabled={!userCanAddOrganization}
    >
      {renderContent()}

      <OrganizationAddFormModal
        onSubmit={handleSubmitOrgAddModal}
        onClose={handleCloseOrgAddModal}
        visible={addOrganizationsModalVisible}
        organizationToEdit={organizationToEdit}
        appUsers={appUsers}
        organizations={organizations}
        configStorageAreas={configStorageAreas}
        userCanAddOrganization={userCanAddOrganization}
      />
      {organizationUsersEditModalVisible && (
        <OrganizationUsersEditModal
          onClose={handleCloseOrganizationEditUsers}
          open={organizationUsersEditModalVisible}
          organization={organizationToEditUsers}
          loadOrganizationsAdminReport={loadOrganizationsAdminReport}
        />
      )}
    </Panel>
  );
};

export default OrganizationsListPanel;
