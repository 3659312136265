import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import {
  createSingleSelectFilterFunction,
  IOption,
  SelectFilter,
  SelectFilterValue,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { iconsAndColorsMap } from 'components/RevisionNumberTag/RevisionNumberTag';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { checkObject } from 'utils';
import { ALL_DOCUMENTS_STATES } from 'utils/documentStateUtils';

type Props = InjectedIntlProps & {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
};

const StateFilterComponent: FunctionComponent<Props> = ({ intl, value, onChange }) => {
  const statesOptions = useMemo<IOption<WorkflowStateEnum>[]>(
    () =>
      ALL_DOCUMENTS_STATES.map((state: WorkflowStateEnum) => ({
        id: state,
        label: <RevisionNumberTag state={state} showTitle />,
        title: intl.formatMessage({ id: iconsAndColorsMap[state] ? iconsAndColorsMap[state].title : undefined }),
      })),
    [intl]
  );

  return (
    <SelectFilter
      label={<Fmt id="general.state" />}
      value={value}
      onChange={onChange}
      options={statesOptions}
      showSearchMinItems={ALL_DOCUMENTS_STATES.length + 1} // Do not show search
    />
  );
};

export const StateFilter = memoizeWithIntl(StateFilterComponent);

const createCommonStateFilter = (key: string): CommonFilter<SelectFilterValue<WorkflowStateEnum>> => ({
  key,
  render: (value, setValue) => <StateFilter value={value} onChange={setValue} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendStateFilter = <T,>(
  key: string,
  valueExtractor: (item: T) => WorkflowStateEnum
): FrontendFilter<T, SelectFilterValue<WorkflowStateEnum>> => ({
  ...createCommonStateFilter(key),
  filter: createSingleSelectFilterFunction(valueExtractor),
});

export const createBackendStateFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: SelectFilterValue<WorkflowStateEnum>) => T
): BackendFilter<T, SelectFilterValue<WorkflowStateEnum>> => ({
  ...createCommonStateFilter(key),
  serialize,
});
