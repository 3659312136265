import { EstiCategoryEnum, EsticonTemplateEnum } from 'api/completeApiInterfaces';
import { IntlMessageId } from 'locale/messages/cs';

export const esticonDirectoryTemplateDescriptions: Record<EsticonTemplateEnum, IntlMessageId> = {
  [EsticonTemplateEnum.ShortYear]: 'EsticonMaskInput.templateDescription.ShortYear',
  [EsticonTemplateEnum.Year]: 'EsticonMaskInput.templateDescription.Year',
  [EsticonTemplateEnum.ShortMonth]: 'EsticonMaskInput.templateDescription.ShortMonth',
  [EsticonTemplateEnum.Month]: 'EsticonMaskInput.templateDescription.Month',
  [EsticonTemplateEnum.Name]: 'EsticonMaskInput.templateDescription.Name',
  [EsticonTemplateEnum.Number]: 'EsticonMaskInput.templateDescription.Number',
  [EsticonTemplateEnum.Code]: 'EsticonMaskInput.templateDescription.Code',
  [EsticonTemplateEnum.ObjectNumber]: 'EsticonMaskInput.templateDescription.ObjectNumber',
  [EsticonTemplateEnum.ObjectPath]: 'EsticonMaskInput.templateDescription.ObjectPath',
};

export const esticonDirectoryTemplateOverrides: Partial<Record<
  EstiCategoryEnum,
  Partial<Record<EsticonTemplateEnum, IntlMessageId>>
>> = {
  [EstiCategoryEnum.Cerpani]: { [EsticonTemplateEnum.Code]: 'EsticonMaskInput.templateDescription.Cerpani.Code' },
  [EstiCategoryEnum.Harmonogram]: {
    [EsticonTemplateEnum.Code]: 'EsticonMaskInput.templateDescription.Harmonogram.Code',
  },
};
