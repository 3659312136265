import { useDispatchEffect, useIntl } from 'hooks';
import { useActiveProjectNameToHelmet } from 'hooks/useActiveProjectNameToHelmet';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { SideMenuKey } from './SideMenuKey';

type Props = {
  menuItemKey: SideMenuKey | Guid;
};

const SideMenuActivator: FunctionComponent<Props> = ({ menuItemKey, children }) => {
  const currentProjectNameToHelmet = useActiveProjectNameToHelmet();

  useDispatchEffect((dispatch) => dispatch.appConfig.setSideMenuActiveKey(menuItemKey), [menuItemKey]);
  const intl = useIntl();
  const translationKey: IntlMessageId | undefined = !!menuItemKey
    ? (('menu.side.' + menuItemKey) as IntlMessageId)
    : undefined;
  return (
    <>
      {!!translationKey && <Helmet title={intl.formatMessage({ id: translationKey }) + currentProjectNameToHelmet} />}
      {children}
    </>
  );
};

export default SideMenuActivator;
