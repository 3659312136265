import { CodeSandboxOutlined } from '@ant-design/icons';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { ForgeGroupedViewer, ForgeViewableModel } from 'components/ForgeGroupedViewer/ForgeGroupedViewer';
import ForgeModelInfoContextProvider from 'Forge/ForgeModelInfoContext/ForgeModelInfoContextProvider';
import React, { useEffect, useMemo } from 'react';

export const ForgeViewer: FileViewerComponent = ({ blobToken, derivate, className, style, onLoad, refresh }) => {
  useEffect(() => {
    onLoad && onLoad();
  }, []);

  const models = useMemo<ForgeViewableModel[]>(
    () => [
      {
        blobToken,
        forgeDerivate: derivate,
      },
    ],
    [blobToken, derivate]
  );
  return (
    <ForgeModelInfoContextProvider>
      <ForgeGroupedViewer className={className} style={style} models={models} refresh={refresh} />
    </ForgeModelInfoContextProvider>
  );
};

export const ForgeViewerData: FileViewerProperties = {
  icon: <CodeSandboxOutlined />,
  supportedTypes: ['application/forge-viewer'],
  titleTranslationId: 'ForgeViewer.title',
  component: 'ForgeViewer',
};
