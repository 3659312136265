import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { compareByName } from 'utils/comparators';

export const labelsListSelector = (state: RootState) => state.labels.data;

export const labelsMapSelector = createSelector(
  [labelsListSelector],
  (labels) => labels && keyBy(labels, (label) => label.id)
);

export const labelsOrderedListSelector = createSelector(
  [labelsListSelector],
  (labels) => labels && [...labels].sort(compareByName)
);
