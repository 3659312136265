import { InputRef } from 'antd';
import { api } from 'api';
import { LabelCreateDto, LabelDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import LabelsListItemForm, { LabelCreateFormData } from 'components/forms/LabelForm/LabelsListItemForm';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';

type Props = FormModalProps<LabelDto> & {
  validateUniqueName: (name: string) => boolean;
  defaults?: LabelDto;
};

export const LabelFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, validateUniqueName, defaults, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<LabelCreateFormData> = async (values) => {
    const data: LabelCreateDto = {
      name: values.name,
      description: values.description,
      color: values.color,
    };

    let err: ApiError;
    let resp: AxiosResponse<LabelDto>;

    if (defaults) {
      // update
      [err, resp] = await api.project.labels.patchLabel(defaults.id, data);
    } else {
      // create
      [err, resp] = await api.project.labels.createLabel(data);
    }

    if (err) return err;

    await onSubmit(resp.data);
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={defaults ? defaults.name : null}
      titleId="LabelFormModal.title.create"
      {...restProps}
    >
      {({ intl, formRef }) => (
        <LabelsListItemForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          defaults={defaults}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default LabelFormModal;
