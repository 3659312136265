import { LinkOutlined } from '@ant-design/icons';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

const FilenameErrorMessageTooltip: FunctionComponent = () => {
  return (
    <>
      <div>
        <strong>
          <Fmt id="FilenameErrorMessage.invalidCharactersTitle" />:
        </strong>
        <br />/ : * ? " {'<'} {'>'} |
      </div>
      <br />
      <div>
        <strong>
          <Fmt id="FilenameErrorMessage.invalidNamesTitle" />:
        </strong>
        <br />
        CON, PRN, AUX, NUL,
        <br />
        COM1, COM2, COM3, COM4, COM5, COM6, COM7, COM8, COM9,
        <br />
        LPT1, LPT2, LPT3, LPT4, LPT5, LPT6, LPT7, LPT8, LPT9
        <br />
      </div>
      <br />
      <a href="https://docs.microsoft.com/en-us/windows/desktop/fileio/naming-a-file" target="_blank" rel="noreferrer">
        <LinkOutlined /> <Fmt id="FilenameErrorMessage.showMoreInfo" />
      </a>
    </>
  );
};

export default FilenameErrorMessageTooltip;
