import { RangePickerProps } from 'antd/es/date-picker';
import { RangePickerWithHolidays } from 'components/CalendarWithHolidays/RangePickerWithHolidays';
import { useIntl } from 'hooks';
import { InjectedIntl } from 'locale';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import styles from './InlineDateRangePicker.module.less';

const ALLOW_EMPTY_BOTH: [boolean, boolean] = [true, true];

export type DateRangeValue = [Moment | null, Moment | null];

export function formatDateRange(dateRangeValue: DateRangeValue, intl: InjectedIntl) {
  const fromValueString = moment.isMoment(dateRangeValue[0])
    ? moment(dateRangeValue[0]).format('l')
    : intl.formatMessage({ id: 'general.unlimited' });
  const toValueString = moment.isMoment(dateRangeValue[1])
    ? moment(dateRangeValue[1]).format('l')
    : intl.formatMessage({ id: 'general.unlimited' });
  return (
    intl.formatMessage({ id: 'general.from' }) +
    fromValueString +
    ' ~ ' +
    intl.formatMessage({ id: 'general.to' }) +
    toValueString
  );
}

type Props = RangePickerProps & { isTimeGranulationMonth?: boolean };

const InlineDateRangePickerComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  isTimeGranulationMonth,
  ...restProps
}) => {
  const datePickerContainerRef = useRef();
  const intl = useIntl();

  const pickerFormat = useMemo(() => {
    if (!isTimeGranulationMonth) return 'L';
    return 'MMMM YYYY';
  }, [isTimeGranulationMonth, intl]);

  const getCalendarContainer = useCallback(() => datePickerContainerRef.current, []);

  return (
    <div ref={datePickerContainerRef} className={styles.rangeInput}>
      <RangePickerWithHolidays
        allowEmpty={ALLOW_EMPTY_BOTH}
        autoFocus
        key={intl.locale}
        getPopupContainer={getCalendarContainer}
        format={pickerFormat}
        value={value}
        onChange={onChange}
        picker={isTimeGranulationMonth ? 'month' : undefined}
        {...restProps}
      />
    </div>
  );
};

export const InlineDateRangePicker = React.memo(InlineDateRangePickerComponent);
