import { to } from 'api/await-to';
import * as Dtos from 'api/completeApiInterfaces';
import {
  BlobDerivateTypeEnum,
  CommentProcedureCategoryCreateDto,
  CommentProcedureCategoryDto,
  CommentProcedureCategoryPatchDto,
  CommentProcedureCommentAttachmentAssignDto,
  CommentProcedureCommentAttachmentCreateMultipleDto,
  CommentProcedureCommentAttachmentDto,
  CommentProcedureCommentCreateDto,
  CommentProcedureCommentDocumentCreateDto,
  CommentProcedureCommentDto,
  CommentProcedureCommentNoteCreateDto,
  CommentProcedureCommentNoteEditDto,
  CommentProcedureCommentPatchDto,
  CommentProcedureCommentStateEnum,
  CommentProcedureCommentStatePatchDto,
  CommentProcedureCreateDto,
  CommentProcedureDocumentAssignDto,
  CommentProcedureDocumentCreateDto,
  CommentProcedureDocumentDetailDto,
  CommentProcedureDto,
  CommentProcedureExportSettingsDto,
  CommentProcedureListDto,
  CommentProcedurePatchDto,
  CommentProcedureRolePatchDto,
  CommentProcedureSetStateDto,
  CommentProcedureTeamCreateDto,
  CommentProcedureTeamDto,
  CommentProcedureTeamPatchDto,
  CommentProcedureUserCreateDto,
  CommentProcedureUserDto,
  CommentProcedureUserPatchDto,
  DownloadUrl,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const commentProceduresApi = {
  getCommentProceduresById: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<CommentProcedureDto>(`/comment/${id}`, { cancelToken })
    ),
  getCommentProceduresList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<CommentProcedureListDto[]>(`/comment`, { cancelToken })
    ),
  createCommentProcedure: (data: CommentProcedureCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<CommentProcedureDto>(`/comment`, data, { cancelToken })
    ),
  getCommentProcedureCommentDetail: (procedureId: Guid, commentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<CommentProcedureCommentDto>(`/comment/${procedureId}/comment/${commentId}`, { cancelToken })
    ),
  setCommentProcedureState: (procedureId: Guid, data: CommentProcedureSetStateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<CommentProcedureDto>(`/comment/${procedureId}/setState`, data, { cancelToken })
    ),
  editCommentProcedure: (procedureId: Guid, data: CommentProcedurePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<CommentProcedureDto>(`/comment/${procedureId}`, data, { cancelToken })
    ),
  close: (procedureId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<CommentProcedureDto>(`/comment/${procedureId}/close`, null, { cancelToken })
    ),
  export: (procedureId: Guid, settings: CommentProcedureExportSettingsDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/comment/${procedureId}/export`, settings, { cancelToken, responseType: 'blob' })
    ),
  patchRole: (commentProcedureId: Guid, data: CommentProcedureRolePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<CommentProcedureDto>(`/comment/${commentProcedureId}/role`, data, { cancelToken })
    ),
  patchStates: (commentProcedureId: Guid, data: CommentProcedureCommentStatePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<CommentProcedureDto>(`/comment/${commentProcedureId}/comments`, data, {
        cancelToken,
      })
    ),
  deleteCommentProcedureCommentNote: (
    commentProcedureId: Guid,
    commentProcedureCommentId: Guid,
    commentProcedureCommentNoteId: Guid,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.delete<CommentProcedureCommentDto>(
        `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}`,
        {
          cancelToken,
        }
      )
    ),
  editCommentProcedureCommentNote: (
    commentProcedureId: Guid,
    commentProcedureCommentId: Guid,
    commentProcedureCommentNoteId: Guid,
    data: CommentProcedureCommentNoteEditDto,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.patch<CommentProcedureCommentDto>(
        `comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}/edit`,
        data,
        { cancelToken }
      )
    ),
  restoreCommentProcedureCommentNote: (
    commentProcedureId: Guid,
    commentProcedureCommentId: Guid,
    commentProcedureCommentNoteId: Guid,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.post<CommentProcedureCommentDto>(
        `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/note/${commentProcedureCommentNoteId}/restore`,
        null,
        {
          cancelToken,
        }
      )
    ),
  getCommentProcedureAttachments: (
    commentProcedureId: Guid,
    commentProcedureCommentId: Guid,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<CommentProcedureCommentAttachmentDto[]>(
        `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments`,
        {
          cancelToken,
        }
      )
    ),
  getCommentProcedureDiscardedAttachments: (
    commentProcedureId: Guid,
    commentProcedureCommentId: Guid,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<CommentProcedureCommentAttachmentDto[]>(
        `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments/discarded`,
        {
          cancelToken,
        }
      )
    ),
  getCommentProceduresForDocument: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<CommentProcedureDocumentDetailDto>(`/comment/document/${documentId}/detail`, { cancelToken })
    ),
  comments: {
    create: (commentProcedureId: Guid, data: CommentProcedureCommentCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(`/comment/${commentProcedureId}/comments`, data, {
          cancelToken,
        })
      ),
    patchComment: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      data: CommentProcedureCommentPatchDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.patch<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}`,
          data,
          {
            cancelToken,
          }
        )
      ),
    setState: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      state: CommentProcedureCommentStateEnum,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/setstate`,
          { state },
          {
            cancelToken,
          }
        )
      ),
    addNote: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      note: CommentProcedureCommentNoteCreateDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/addnote`,
          note,
          {
            cancelToken,
          }
        )
      ),
    addDocument: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      data: CommentProcedureCommentDocumentCreateDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/document`,
          data,
          {
            cancelToken,
          }
        )
      ),
    removeDocument: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      commentProcedureCommentDocumentId: Guid,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.delete<void>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/document/${commentProcedureCommentDocumentId}`,
          {
            cancelToken,
          }
        )
      ),
    addAttachments: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      data: CommentProcedureCommentAttachmentCreateMultipleDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/addattachments`,
          data,
          {
            cancelToken,
          }
        )
      ),
    discardAttachment: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      attachmentId: Guid,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.delete(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}`,
          {
            cancelToken,
          }
        )
      ),
    assignAttachment: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      data: CommentProcedureCommentAttachmentAssignDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<CommentProcedureCommentDto>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/assignattachment`,
          data,
          {
            cancelToken,
          }
        )
      ),
    attachment: {
      download: (
        commentProcedureId: Guid,
        commentProcedureCommentId: Guid,
        attachmentId: Guid,
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.get<DownloadUrl>(
            `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}/download`,
            {
              cancelToken,
            }
          )
        ),
      downloadDerivate: (
        commentProcedureId: Guid,
        commentProcedureCommentId: Guid,
        attachmentId: Guid,
        type: BlobDerivateTypeEnum,
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.get<DownloadUrl>(
            `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}/downloadDerivate?type=${type}`,
            {
              cancelToken,
            }
          )
        ),
      restore: (
        commentProcedureId: Guid,
        commentProcedureCommentId: Guid,
        attachmentId: Guid,
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.post<CommentProcedureCommentDto>(
            `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachment/${attachmentId}/restore`,
            {
              cancelToken,
            }
          )
        ),
      publish: (
        commentProcedureId: Guid,
        commentProcedureCommentId: Guid,
        attachmentId: Guid,
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.post<Dtos.CommentProcedureCommentDto>(
            `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/attachments/${attachmentId}/publish`,
            { cancelToken }
          )
        ),
    },
  },
  team: {
    create: (commentProcedureId: Guid, team: CommentProcedureTeamCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureTeamDto>(`/comment/${commentProcedureId}/team`, team, {
          cancelToken,
        })
      ),
    edit: (commentProcedureId: Guid, teamId: Guid, team: CommentProcedureTeamPatchDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.patch<CommentProcedureTeamDto>(`/comment/${commentProcedureId}/team/${teamId}`, team, {
          cancelToken,
        })
      ),
    delete: (commentProcedureId: Guid, teamId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.delete<void>(`/comment/${commentProcedureId}/team/${teamId}`, {
          cancelToken,
        })
      ),
    user: {
      create: (
        commentProcedureId: Guid,
        teamId: Guid,
        users: CommentProcedureUserCreateDto[],
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.post<CommentProcedureUserDto[]>(`/comment/${commentProcedureId}/team/${teamId}/users`, users, {
            cancelToken,
          })
        ),
      edit: (
        commentProcedureId: Guid,
        teamId: Guid,
        teamUserId: Guid,
        user: CommentProcedureUserPatchDto,
        cancelToken?: CancelToken
      ) =>
        to(
          baseProjectApi.patch<CommentProcedureUserDto>(
            `comment/${commentProcedureId}/team/${teamId}/user/${teamUserId}`,
            user,
            { cancelToken }
          )
        ),
      delete: (commentProcedureId: Guid, teamId: Guid, teamUserId: Guid, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.delete<void>(`comment/${commentProcedureId}/team/${teamId}/user/${teamUserId}`, {
            cancelToken,
          })
        ),
    },
  },
  category: {
    create: (commentProcedureId: Guid, category: CommentProcedureCategoryCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureCategoryDto>(`/comment/${commentProcedureId}/category`, category, {
          cancelToken,
        })
      ),
    edit: (
      commentProcedureId: Guid,
      categoryId: Guid,
      category: CommentProcedureCategoryPatchDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.patch<CommentProcedureCategoryDto>(
          `/comment/${commentProcedureId}/category/${categoryId}`,
          category,
          {
            cancelToken,
          }
        )
      ),
    delete: (commentProcedureId: Guid, categoryId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.delete<void>(`/comment/${commentProcedureId}/category/${categoryId}`, {
          cancelToken,
        })
      ),
    import: (commentProcedureId: Guid, sourceCommentProcedureId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureCategoryDto[]>(
          `/comment/${commentProcedureId}/category/import/${sourceCommentProcedureId}`,
          {
            cancelToken,
          }
        )
      ),
  },
  documents: {
    assign: (commentProcedureId: Guid, data: CommentProcedureDocumentAssignDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureDto>(`/comment/${commentProcedureId}/documents/assign`, data, {
          cancelToken,
        })
      ),
    add: (commentProcedureId: Guid, data: CommentProcedureDocumentCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<CommentProcedureDto>(`/comment/${commentProcedureId}/documents/add`, data, { cancelToken })
      ),
    delete: (commentProcedureId: Guid, procedureDocumentId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.delete<CommentProcedureDto>(`/comment/${commentProcedureId}/documents/${procedureDocumentId}`, {
          cancelToken,
        })
      ),
  },
  downloadattachments: {
    /** Download multiple attachments for comment procedure comment */
    post: (
      commentProcedureId: Guid,
      commentProcedureCommentId: Guid,
      data: Dtos.CommentProcedureCommentAttachmentsDownloadDto,
      cancelToken?: CancelToken
    ) =>
      to(
        baseProjectApi.post<Dtos.DownloadUrl>(
          `/comment/${commentProcedureId}/comments/${commentProcedureCommentId}/downloadattachments`,
          data,
          { cancelToken }
        )
      ),
  },
};
