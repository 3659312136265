import { easter } from 'date-easter';
import moment from 'moment';

export type ExcludeDayType = {
  name: string;
  day: number;
  month: number;
  year?: number;
  isRepeatable: boolean;
};

//zákon 245/2000 o státních svátcích, o ostatních svátcích, o významných dnech a o dnech pracovního klidu
//ve znění od 1.5.2022
const OFFICIAL_PUBLIC_HOLIDAYS: ExcludeDayType[] = [
  {
    name: 'Den obnovy samostatného českého státu / Nový rok',
    day: 1,
    month: 1,
    year: undefined,
    isRepeatable: true,
  },
  { name: 'Svátek práce', day: 1, month: 5, year: undefined, isRepeatable: true },
  { name: 'Den vítězství', day: 8, month: 5, year: undefined, isRepeatable: true },
  {
    name: 'Den slovanských věrozvěstů Cyrila a Metoděje',
    day: 5,
    month: 7,
    year: undefined,
    isRepeatable: true,
  },
  { name: 'Den upálení mistra Jana Husa', day: 6, month: 7, year: undefined, isRepeatable: true },
  { name: 'Den české státnosti', day: 28, month: 9, year: undefined, isRepeatable: true },
  {
    name: 'Den vzniku samostatného československého státu',
    day: 28,
    month: 10,
    year: undefined,
    isRepeatable: true,
  },
  {
    name: 'Den boje za svobodu a demokracii a Mezinárodní den studentstva',
    day: 17,
    month: 11,
    year: undefined,
    isRepeatable: true,
  },
  { name: 'Štědrý den', day: 24, month: 12, year: undefined, isRepeatable: true },
  { name: '1. svátek vánoční', day: 25, month: 12, year: undefined, isRepeatable: true },
  { name: '2. svátek vánoční', day: 26, month: 12, year: undefined, isRepeatable: true },
];

const currentDate = moment().startOf('day');
const currentYear = currentDate.year();

const getHolidays = (endDate: moment.Moment): ExcludeDayType[] => {
  const toAddHolidays: ExcludeDayType[] = [];

  OFFICIAL_PUBLIC_HOLIDAYS.forEach((officialHoliday) => {
    if (
      moment({ day: officialHoliday.day, month: officialHoliday.month - 1, year: currentYear }).isBetween(
        //-1, protoze mesice jsou 0-11
        currentDate,
        endDate
      ) ||
      moment({ day: officialHoliday.day, month: officialHoliday.month - 1, year: currentYear + 1 }).isBetween(
        currentDate,
        endDate
      )
    ) {
      toAddHolidays.push(officialHoliday);
    }
  });
  return toAddHolidays;
};

export const getEasterHolidays = (endDate: moment.Moment) => {
  const endYear = endDate.year();
  const toAddEasters: ExcludeDayType[] = [];
  for (let year = currentYear; year <= endYear; year++) {
    const easterResult = easter(year);
    const easterMonday = moment({ ...easterResult, month: easterResult.month - 1 }); //-1, protoze mesice jsou 0-11

    const goodFriday = moment({ ...easterResult, month: easterResult.month - 1 }).subtract(3, 'days');

    if (easterMonday.isBetween(currentDate, endDate)) {
      toAddEasters.push({
        name: 'Velikonoční pondělí',
        day: easterMonday.date(),
        month: easterMonday.month() + 1,
        year: easterMonday.year(),
        isRepeatable: false,
      });
    }

    if (goodFriday.isBetween(currentDate, endDate)) {
      toAddEasters.push({
        name: 'Velký pátek',
        day: goodFriday.date(),
        month: goodFriday.month() + 1,
        year: goodFriday.year(),
        isRepeatable: false,
      });
    }
  }
  return toAddEasters;
};

export const getOfficialHolidays = (endDate: moment.Moment) => {
  return [...getHolidays(endDate), ...getEasterHolidays(endDate)];
};
