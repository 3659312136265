import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import { OrganizationDto, ServiceError } from 'api/completeApiInterfaces';
import { DragIdentifier } from 'components/DragTile/DragTile';
import { useBoolean, useCurrentAppUser, useIntl, useItemsSet } from 'hooks';
import { RequestCacheApiCallback, useCachedApiRequests } from 'hooks/useCachedApiRequest';
import { SavedRecordTypeEnum, useSavedRecordsApi } from 'hooks/useSavedRecordsApi';
import React, { FunctionComponent, createContext, useCallback, useContext, useMemo, useState } from 'react';
import uuid from 'uuid';
import { ReportWidgetCreateFormData, ReportWidgetCreateFormModal } from '../forms/ReportWidgetCreateForm';
import ReportWidgetEditFormModal from '../forms/ReportWidgetCreateForm/ReportWidgetEditFormModal';
import SharedReportWidgetCreateFormModal from '../forms/ReportWidgetCreateForm/SharedReportWidgetCreateFormModal';
import SharedReportWidgetEditFormModal from '../forms/ReportWidgetCreateForm/SharedReportWidgetEditFormModal';

export type ReportWidgetConfiguration = {
  id: Guid;
  title: string;
  order: number;
} & (
  | {
      type: 'personal';
      data: ReportWidgetCreateFormData;
    }
  | {
      type: 'shared';
      organizationId: Guid;
      widgetId: Guid;
    }
);

export type PersonalReportWidgetConfiguration = ReportWidgetConfiguration & { type: 'personal' };
export type SharedReportWidgetConfiguration = ReportWidgetConfiguration & { type: 'shared' };

export type DashboardWidgetConfigurations = {
  configurations: ReportWidgetConfiguration[];
};

export type SharedDashboardWidgetConfigurations = {
  configurations: PersonalReportWidgetConfiguration[];
};

type ValueType = {
  orderedWidgets: ReportWidgetConfiguration[];
  sharedWidgets: Record<Guid, PersonalReportWidgetConfiguration[]>;
  dashboardWidgetsError: ServiceError;
  dashboardWidgetsLoading: boolean;
  setWidgetOrder: (from: DragIdentifier, to: DragIdentifier) => Promise<void>;
  saveDashboardWidget: (widgetConfiguration: ReportWidgetConfiguration) => Promise<void>;
  removeDashboardWidget: (widgetId: Guid) => Promise<void>;
  showReportAddModal: (defaults?: ReportWidgetCreateFormData) => void;
  showReportEditModal: (reportConfigurationId: Guid) => void;
  createReportWidgetCopy: (reportId: Guid) => Promise<void>;
  showSharedReportAddModal: () => void;
  requestCacheApiData: RequestCacheApiCallback;
  requestSharedWidgets: (organizationId: Guid) => Promise<void>;
  sharedWidgetsLoading: Set<Guid>;
  organizations: OrganizationDto[];
};

type Props = {
  organizations: OrganizationDto[];
};

export const ReportWidgetsContext = createContext<ValueType>(undefined);

export const useReportWidgetsContext = () => {
  const context = useContext(ReportWidgetsContext);
  if (context === undefined) {
    throw new Error('useReportWidgetsContext must be used within a ReportsWidgetsContextProvider');
  }
  return context;
};

const ReportWidgetsContextProvider: FunctionComponent<Props> = ({ children, organizations }) => {
  const [reportAddModalVisible, showReportAddModal, hideReportAddModal] = useBoolean(false);
  const [editedReportConfiguration, setEditedReportConfiguration] = useState<ReportWidgetConfiguration>();
  const [sharedReportAddModalVisible, showSharedReportAddModal, hideSharedReportAddModal] = useBoolean(false);
  const [sharedWidgets, setSharedWidgets] = useState<Record<Guid, PersonalReportWidgetConfiguration[]>>({});
  const [reportDefaults, setReportDefaults] = useState<ReportWidgetCreateFormData>();
  const [sharedWidgetsLoading, addSharedWidgetsOrgLoading, removeSharedWidgetsOrgLoading] = useItemsSet<Guid>();

  const currentAppUser = useCurrentAppUser();
  const intl = useIntl();

  const [
    dashboardWidgets,
    loadingError,
    dashboardWidgetsLoading,
    _dashboardWidgetsSaving,
    saveWidgets,
  ] = useSavedRecordsApi<DashboardWidgetConfigurations>(
    currentAppUser.id,
    SavedRecordTypeEnum.MasterDashboardPanelConfigurations
  );

  const saveDashboardWidget = useCallback(
    async (widgetConfiguration: ReportWidgetConfiguration) => {
      const isNewWidget = !dashboardWidgets?.data?.configurations.some(
        (config) => config.id === widgetConfiguration.id
      );
      const updatedConfiguratios = isNewWidget
        ? [...(dashboardWidgets?.data?.configurations || []), widgetConfiguration]
        : [
            ...(dashboardWidgets?.data?.configurations || []).map((config) =>
              config.id === widgetConfiguration.id ? widgetConfiguration : config
            ),
          ];

      await saveWidgets({
        configurations: updatedConfiguratios.map((configuration, index) => ({ ...configuration, order: index })),
      });
    },
    [dashboardWidgets, saveWidgets]
  );

  const removeDashboardWidget = useCallback(
    async (widgetId: Guid) => {
      await saveWidgets({
        configurations: (dashboardWidgets?.data?.configurations || []).filter((config) => config.id !== widgetId),
      });
    },
    [dashboardWidgets, saveWidgets]
  );

  const orderedWidgets = useMemo(
    () => [...(dashboardWidgets?.data.configurations || [])].sort((a, b) => a.order - b.order),
    [dashboardWidgets]
  );

  const dashboardWidgetsError = useMemo(
    () => (loadingError && loadingError?.statusCode !== 404 ? loadingError : undefined),
    [loadingError]
  );

  const setWidgetOrder = useCallback(
    async (from: DragIdentifier, to: DragIdentifier) => {
      const newConfigurationOrder = orderedWidgets ? [...orderedWidgets] : [];
      newConfigurationOrder.splice(to.itemId, 0, ...newConfigurationOrder.splice(from.itemId, 1));
      await saveWidgets({
        configurations: newConfigurationOrder.map((configuration, index) => ({ ...configuration, order: index })),
      });
    },
    [orderedWidgets, saveWidgets]
  );

  const handleReportWidgetCreate = useCallback(
    async (widgetConfiguration: ReportWidgetConfiguration) => {
      await saveDashboardWidget(widgetConfiguration);
      setReportDefaults(undefined);
      hideReportAddModal();
      void message.success(intl.formatMessage({ id: 'WidgetReportsContext.message.addWidget.success' }));
    },
    [intl, hideReportAddModal, saveDashboardWidget]
  );

  const handleSharedReportWidgetCreate = useCallback(
    async (widgetConfiguration: ReportWidgetConfiguration) => {
      await saveDashboardWidget(widgetConfiguration);
      setReportDefaults(undefined);
      hideSharedReportAddModal();
      void message.success(intl.formatMessage({ id: 'WidgetReportsContext.message.addWidget.success' }));
    },
    [intl, hideSharedReportAddModal, saveDashboardWidget]
  );

  const handleReportWidgetEdit = useCallback(
    async (widgetConfiguration: ReportWidgetConfiguration) => {
      await saveDashboardWidget(widgetConfiguration);
      setEditedReportConfiguration(undefined);
    },
    [saveDashboardWidget]
  );

  const showReportEditModal = useCallback(
    (configurationId: Guid) => {
      const widgetConfiguration = dashboardWidgets?.data.configurations.find(
        (configuration) => configuration.id === configurationId
      );
      if (widgetConfiguration) {
        setEditedReportConfiguration(widgetConfiguration);
      }
    },
    [intl, dashboardWidgets?.data.configurations]
  );

  const handleReportAddClose = () => {
    setReportDefaults(undefined);
    hideReportAddModal();
    hideSharedReportAddModal();
  };

  const onShowReportAddModal = useCallback((defaults?: ReportWidgetCreateFormData) => {
    setReportDefaults(defaults);
    showReportAddModal();
  }, []);

  const [requestCacheApiData] = useCachedApiRequests();

  const requestSharedWidgets = useCallback(
    async (organizationId: Guid) => {
      addSharedWidgetsOrgLoading(organizationId);
      const apiResponse = await requestCacheApiData(masterApi.projects.reports.generalsavedrecord.get.post, {
        id1: organizationId,
        id2: undefined,
        iden: SavedRecordTypeEnum.SharedDashboardPanelConfigurations,
        number: 0,
      });
      if (!apiResponse) return;
      const [err, res] = apiResponse;
      if (!err && res.data) {
        const widgetConfigurations = res.data?.data as SharedDashboardWidgetConfigurations;
        if ('configurations' in widgetConfigurations) {
          setSharedWidgets((existingSharedWidgets) => ({
            ...existingSharedWidgets,
            [organizationId]: widgetConfigurations.configurations,
          }));
        } else {
          console.error('Invalid saved shared widget structure');
        }
      }
      removeSharedWidgetsOrgLoading(organizationId);
    },
    [addSharedWidgetsOrgLoading, removeSharedWidgetsOrgLoading, requestCacheApiData]
  );

  const createReportWidgetCopy = useCallback(
    async (widgetId: Guid) => {
      const widgetIndex = orderedWidgets.findIndex((widget) => widget.id === widgetId);
      if (!widgetIndex && widgetIndex !== 0) {
        void message.error(intl.formatMessage({ id: 'WidgetReportsContext.message.cloneWidget.error.notFound' }));
        return;
      }

      const widgetConfiguration = orderedWidgets[widgetIndex];

      const newWidget =
        widgetConfiguration.type === 'shared'
          ? sharedWidgets[widgetConfiguration.organizationId].find(
              (widget) => widget.id === widgetConfiguration.widgetId
            )
          : orderedWidgets[widgetIndex];
      if (!newWidget) {
        void message.error(intl.formatMessage({ id: 'WidgetReportsContext.message.cloneWidget.error.notFound' }));
        return;
      }

      const newWidgets = [...orderedWidgets];
      const newReportName = `(${intl.formatMessage({
        id: 'WidgetReportsContext.message.cloneWidget.prefix',
      })}) ${widgetConfiguration.title || newWidget.title}`;
      newWidgets.splice(widgetIndex + 1, 0, {
        ...newWidget,
        id: uuid(),
        title: newReportName,
      });

      await saveWidgets({
        configurations: newWidgets.map((configuration, index) => ({ ...configuration, order: index })),
      });
    },
    [orderedWidgets, sharedWidgets, intl, saveWidgets]
  );

  const value: ValueType = useMemo(() => {
    return {
      orderedWidgets,
      sharedWidgets,
      dashboardWidgetsError,
      dashboardWidgetsLoading,
      organizations,
      setWidgetOrder,
      saveDashboardWidget,
      removeDashboardWidget,
      showReportAddModal: onShowReportAddModal,
      showReportEditModal,
      requestCacheApiData,
      createReportWidgetCopy,
      requestSharedWidgets,
      sharedWidgetsLoading,
      showSharedReportAddModal,
    };
  }, [
    orderedWidgets,
    sharedWidgets,
    dashboardWidgetsError,
    dashboardWidgetsLoading,
    organizations,
    setWidgetOrder,
    saveDashboardWidget,
    removeDashboardWidget,
    onShowReportAddModal,
    showReportEditModal,
    requestCacheApiData,
    createReportWidgetCopy,
    requestSharedWidgets,
    sharedWidgetsLoading,
    showSharedReportAddModal,
  ]);

  return (
    <ReportWidgetsContext.Provider value={value}>
      {children}
      <ReportWidgetCreateFormModal
        visible={reportAddModalVisible}
        onSubmit={handleReportWidgetCreate}
        onClose={handleReportAddClose}
        organizations={organizations}
        defaults={reportDefaults}
        requestCacheApiData={requestCacheApiData}
      />
      {editedReportConfiguration && editedReportConfiguration.type === 'personal' && (
        <ReportWidgetEditFormModal
          visible={!!editedReportConfiguration}
          onSubmit={handleReportWidgetEdit}
          onClose={() => setEditedReportConfiguration(undefined)}
          configuration={editedReportConfiguration}
          requestCacheApiData={requestCacheApiData}
        />
      )}
      {editedReportConfiguration && editedReportConfiguration.type === 'shared' && (
        <SharedReportWidgetEditFormModal
          visible={!!editedReportConfiguration}
          onSubmit={handleReportWidgetEdit}
          onClose={() => setEditedReportConfiguration(undefined)}
          configuration={editedReportConfiguration}
        />
      )}
      <SharedReportWidgetCreateFormModal
        visible={sharedReportAddModalVisible}
        onSubmit={handleSharedReportWidgetCreate}
        onClose={handleReportAddClose}
        organizations={organizations}
      />
    </ReportWidgetsContext.Provider>
  );
};

export default ReportWidgetsContextProvider;
