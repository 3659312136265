import { ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent } from 'react';

type Props = {
  user: ProjectUserProfileListDto;
};

export const UserProfile: FunctionComponent<Props> = ({ user }) => {
  return (
    <FlowLayout>
      <UserIcon user={user} />
      <span>{user.username}</span>
    </FlowLayout>
  );
};
