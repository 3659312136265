import BasePlot, { PlotConfig } from '@antv/g2plot/lib/base/plot';
import { ProjectReportTypeEnum } from 'api/completeApiInterfaces';
import moment from 'moment';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import { downloadBlob } from 'utils/downloadFile';
import { ExportStateProps, ReportButton, ValueFromReportState } from './commonTypes';

const DELAY_TO_PAINT_CANVAS_IN_BROWSER = 10; // ms, give browser some time to paint canvas. Otherwise it will rarely return blank image
const EXPORT_IMAGE_BORDER_SIZE = 10;
const BASE_SVG_TEXT_TITLE_FONT_SIZE = 30;
const BASE_SVG_TEXT_TITLE_LENGTH_TO_FIT = 90; // number of avarage character count to fit svg with with default size of 30 px font height

export enum ChartFormatTypeEnum {
  png = 'png',
  jpg = 'jpg',
  svg = 'svg',
  xlsx = 'xlsx',
  pdf = 'pdf',
}

type ExportReportName = { name?: string };

export const EXPORT_BUTTONS: ReportButton<ExportStateProps>[] = [
  /*{
    icon: 'file-image',
    nameId: 'ProjectDashboard.Reports.Export.jpg',
    onClick: { exportTo: ChartFormatTypeEnum.jpg } as ExportStateProps,
  },*/
  {
    icon: 'file-image',
    nameId: 'ProjectDashboard.Reports.Export.png',
    onClick: { exportTo: ChartFormatTypeEnum.png },
  },
  {
    icon: 'file-image',
    nameId: 'ProjectDashboard.Reports.Export.svg',
    onClick: { exportTo: ChartFormatTypeEnum.svg },
  },
  /*{
    icon: 'file-pdf',
    nameId: 'ProjectDashboard.Reports.Export.pdf',
    onClick: { exportTo: ChartFormatTypeEnum.pdf } as ExportStateProps,
  },
  {
    icon: 'file-excel',
    nameId: 'ProjectDashboard.Reports.Export.xlsx',
    onClick: { exportTo: ChartFormatTypeEnum.xlsx } as ExportStateProps,
  },*/
];

const fitTextOnCanvas = (
  text: string,
  context: CanvasRenderingContext2D,
  fontface: string,
  x: number,
  y: number,
  width: number,
  height: number
) => {
  let fontsize = BASE_SVG_TEXT_TITLE_FONT_SIZE;
  do {
    fontsize--;
    context.font = fontsize + 'px ' + fontface;
  } while (context.measureText(text).width > width);

  const textSize = context.measureText(text);
  const textHeight = textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent;
  context.fillText(text, x + (width / 2 - textSize.width / 2), y + (height / 2 + textHeight / 2));
};

export const EXPORT_TYPE_MAP: Partial<Record<ChartFormatTypeEnum, string>> = {
  [ChartFormatTypeEnum.jpg]: 'image/jpeg',
  [ChartFormatTypeEnum.png]: 'image/png',
  [ChartFormatTypeEnum.svg]: 'image/svg+xml;charset=utf-8',
};

export const exportToImage = (
  chartRef: React.MutableRefObject<BasePlot>,
  project: ExportReportName,
  reportName: string,
  reportFootnote: string,
  exportTo: ChartFormatTypeEnum,
  intl: InjectedIntl
) => {
  const plotCanvasDom = chartRef.current.getCanvas().get('el');
  const exportCanvas = document.createElement('canvas');
  const titleHeight = plotCanvasDom.height / 10;
  exportCanvas.width = plotCanvasDom.width;
  exportCanvas.height = plotCanvasDom.height + titleHeight + EXPORT_IMAGE_BORDER_SIZE;

  const exportContext = exportCanvas.getContext('2d');
  exportContext.fillStyle = '#FFFFFF';
  exportContext.fillRect(0, 0, exportCanvas.width, exportCanvas.height);
  exportContext.fillStyle = '#1c1c1c';
  fitTextOnCanvas(
    reportName,
    exportContext,
    'Helvetica',
    EXPORT_IMAGE_BORDER_SIZE,
    EXPORT_IMAGE_BORDER_SIZE,
    exportCanvas.width - EXPORT_IMAGE_BORDER_SIZE * 2,
    titleHeight - EXPORT_IMAGE_BORDER_SIZE * 2
  );
  exportContext.font = '12px Helvetica';
  exportContext.fillStyle = '#5a5a5a';
  exportContext.fillText(
    intl.formatMessage({ id: 'ProjectDashboard.Reports.ExportDate' }) +
      moment()
        .locale(intl.locale)
        .format('L'),
    EXPORT_IMAGE_BORDER_SIZE,
    exportCanvas.height - EXPORT_IMAGE_BORDER_SIZE
  );

  if (reportFootnote?.length) {
    exportContext.textAlign = 'right';
    exportContext.fillText(
      reportFootnote,
      exportCanvas.width - EXPORT_IMAGE_BORDER_SIZE,
      exportCanvas.height - EXPORT_IMAGE_BORDER_SIZE
    );
  }

  exportContext.drawImage(plotCanvasDom, 0, titleHeight);

  exportCanvas.toBlob(
    (blob) => {
      downloadBlob(
        blob,
        project.name?.length > 0 ? `${project.name} - ${reportName}.${exportTo}` : `${reportName}.${exportTo}`
      );
    },
    exportTo === ChartFormatTypeEnum.jpg ? 'image/jpeg' : 'image/png',
    0.9
  );
};

export const exportToSvg = (
  chartRef: React.MutableRefObject<BasePlot>,
  project: ExportReportName,
  reportName: string,
  reportFootnote: string,
  intl: InjectedIntl
) => {
  const canvasDom = chartRef.current.getCanvas().get('el');
  const clone: HTMLElement & SVGElement = canvasDom.cloneNode(true);
  const graphContainer = clone.getElementsByTagName('g');
  const svgWidth = parseInt(clone.getAttribute('width'));
  const svgHeight = parseInt(clone.getAttribute('height'));
  const titleAreaHeight = svgHeight / 10;
  const titleLengthOverflow = reportName.length / BASE_SVG_TEXT_TITLE_LENGTH_TO_FIT;
  clone.setAttribute('height', `${svgHeight + titleAreaHeight}`);
  clone.setAttribute(
    'style',
    `width: ${svgWidth}; height: ${svgHeight + titleAreaHeight};display: inline-block; vertical-align: middle;`
  );

  graphContainer[0].setAttribute('transform', `translate(0,${titleAreaHeight})`);

  const title = document.createElementNS('http://www.w3.org/2000/svg', 'text'); //Create a path in SVG's namespace
  title.style.fill = '#1c1c1c';
  title.style.fontSize = `${
    titleLengthOverflow >= 1 ? BASE_SVG_TEXT_TITLE_FONT_SIZE / titleLengthOverflow : BASE_SVG_TEXT_TITLE_FONT_SIZE
  }px`;
  title.style.fontFamily = 'Helvetica';
  title.append(reportName);
  clone.appendChild(title);

  title.setAttribute('x', `50%`);
  title.setAttribute('y', `${titleAreaHeight / 2}`);
  title.setAttribute('dominant-baseline', `middle`);
  title.setAttribute('text-anchor', `middle`);

  const date = document.createElementNS('http://www.w3.org/2000/svg', 'text'); //Create a path in SVG's namespace
  date.setAttribute('x', '10');
  date.setAttribute('y', `${svgHeight + titleAreaHeight}`);
  date.setAttribute('font-size', '12');
  date.style.fill = '#5a5a5a';
  date.style.fontFamily = 'Helvetica';
  date.append(
    intl.formatMessage({ id: 'ProjectDashboard.Reports.ExportDate' }) +
      moment()
        .locale(intl.locale)
        .format('L')
  );
  clone.appendChild(date);

  if (reportFootnote?.length) {
    const footnote = document.createElementNS('http://www.w3.org/2000/svg', 'text'); //Create a path in SVG's namespace
    footnote.setAttribute('x', `${svgWidth - 10}`);
    footnote.setAttribute('y', `${svgHeight + titleAreaHeight}`);
    footnote.setAttribute('font-size', '12');
    footnote.setAttribute('text-anchor', 'end');
    footnote.style.fill = '#5a5a5a';
    footnote.style.fontFamily = 'Helvetica';
    footnote.append(reportFootnote);
    clone.appendChild(footnote);
  }

  const svgDocType = document.implementation.createDocumentType(
    'svg',
    '-//W3C//DTD SVG 1.1//EN',
    'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'
  );
  const svgDoc = document.implementation.createDocument('http://www.w3.org/2000/svg', 'svg', svgDocType);
  svgDoc.replaceChild(clone, svgDoc.documentElement);
  const svgData = new XMLSerializer().serializeToString(svgDoc);
  const svgUrl = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svgData);

  const downloadLink = document.createElement('a');
  downloadLink.href = svgUrl;
  downloadLink.download = project.name?.length > 0 ? `${project.name} - ${reportName}.svg` : `${reportName}.svg`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportChartImage = (
  chartRef: React.MutableRefObject<BasePlot>,
  exportTo: ChartFormatTypeEnum,
  reportName: string,
  reportFootnote: string,
  intl: InjectedIntl,
  project: ExportReportName,
  onFinish?: () => void
) => {
  setTimeout(() => {
    switch (exportTo) {
      case ChartFormatTypeEnum.jpg:
      case ChartFormatTypeEnum.png:
        exportToImage(chartRef, project, reportName, reportFootnote, exportTo, intl);
        break;
      case ChartFormatTypeEnum.svg:
        exportToSvg(chartRef, project, reportName, reportFootnote, intl);
        break;
    }
    onFinish && onFinish();
  }, DELAY_TO_PAINT_CANVAS_IN_BROWSER);
};

export const exportProjectChartImage = (
  chartRef: React.MutableRefObject<BasePlot>,
  exportTo: ChartFormatTypeEnum,
  reportType: ProjectReportTypeEnum,
  reportFootnote: string,
  intl: InjectedIntl,
  project: ExportReportName,
  setState: (changer: ValueFromReportState<ExportStateProps, ExportStateProps>) => void,
  titleData?: { [key: string]: ReactIntl.MessageValue }
) => {
  if (chartRef.current && exportTo) {
    const reportName = intl.formatMessage(
      {
        id: `ProjectDashboard.Reports.title.${reportType}`,
      },
      titleData
    );

    exportChartImage(chartRef, exportTo, reportName, reportFootnote, intl, project, () =>
      setState({ exportTo: undefined } as ExportStateProps)
    );
  }
};

export const exportConfig: PlotConfig = {
  forceFit: false,
  width: 700,
  height: 360,
};
