import { Button } from 'antd';
import { ProjectStatusEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import { useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import ProjectStatusPage from 'pages/ProjectStatusPage';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';

type Props = {
  projectId: Guid;
};

export const ActiveProjectGate: FunctionComponent<Props> = ({ projectId, children }) => {
  const { activeProject, activeProjectError, activeProjectLoading } = useStoreSelector((state) => state.activeProject);
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();

  const loadActiveProject = () => dispatch.activeProject.selectProject(projectId);
  const loadProjectCalendar = () =>
    dispatch.activeCalendar.loadCalendar({
      organizationId: activeProject.organization?.id,
      projectId: activeProject.id,
    });

  useDirtyStoreReloadCallback(
    (store) => store.activeCalendar,
    (dispatch) =>
      dispatch.activeCalendar.loadCalendar({
        organizationId: activeProject?.organization?.id,
        projectId: activeProject?.id,
      })
  );

  const goToProjectListButton = (
    <Margin left top bottom>
      <Button
        type="primary"
        onClick={() => {
          const url = routeMatch.url;
          history.replace(url.slice(0, url.lastIndexOf('/')));
        }}
      >
        {intl.formatMessage({ id: 'ActiveProjectGate.backToProjectList.ButtonText' })}
      </Button>
    </Margin>
  );

  useEffect(() => {
    loadActiveProject();
    return () => dispatch.activeProject.selectProject(null);
  }, []);

  useEffect(() => {
    if (!!activeProject) loadProjectCalendar();
  }, [activeProject]);

  if (activeProject && activeProject.status !== ProjectStatusEnum.ready)
    return <ProjectStatusPage project={activeProject} reloadProject={loadActiveProject} />;

  return (
    <ContentGate
      error={activeProjectError}
      loading={activeProjectLoading}
      additionalErrorContent={goToProjectListButton}
    >
      {activeProject && children}
    </ContentGate>
  );
};
