import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import FileTypeIcon from 'components/FileTypeIcon';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import numeral from 'numeral';
import React, { Component } from 'react';
import Dropzone, { DropEvent } from 'react-dropzone';
import styles from './SingleFileInput.module.less';

type SingleFileInputProps = {
  value?: File;
  onChange?: (value: File) => void;
  inputText?: IntlMessageId;
  inputHint?: IntlMessageId;
  acceptedContentType?: string | string[];
  onInvalidFileDrop?: () => void;
};

type SingleFileInputState = { file: File };

class SingleFileInput extends Component<SingleFileInputProps, SingleFileInputState> {
  constructor(props: SingleFileInputProps) {
    super(props);
    this.state = { file: props.value };
  }

  triggerChange = (file: File) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(file);
    }
  };

  componentDidUpdate(prevProps: Readonly<SingleFileInputProps>) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.file) {
      this.setState({ file: this.props.value });
      this.triggerChange(this.props.value);
    }
  }

  onDrop = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
    const file = acceptedFiles.length ? acceptedFiles[0] : null;
    !file && this.props.onInvalidFileDrop && this.props.onInvalidFileDrop();
    this.setState({ file });
    this.triggerChange(file);
    event.stopPropagation();
  };

  removeFile = () => {
    this.setState({ file: null });
    this.triggerChange(null);
  };

  render() {
    const { file } = this.state;
    const { inputText, inputHint, acceptedContentType } = this.props;
    if (file) {
      return (
        <div className={styles.item}>
          <div className={styles.content}>
            <div className={styles.icon} title={file.type}>
              <FileTypeIcon contentType={file.type} />
            </div>
            <div className={styles.info}>
              <span className={styles.name} title={file.name}>
                {file.name}
              </span>
              <br />
              <span>{numeral(file.size).format('0.0 b')}</span>
            </div>
            <CloseOutlined onClick={this.removeFile} />
          </div>
        </div>
      );
    } else {
      return (
        <Dropzone onDrop={this.onDrop} multiple={false} accept={acceptedContentType}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames('ant-upload', 'ant-upload-drag', { 'ant-upload-drag-hover': isDragActive })}
            >
              <input {...getInputProps()} />
              <span className="ant-upload ant-upload-btn" role="button">
                <div className="ant-upload-drag-container">
                  <p className="ant-upload-text">
                    <InboxOutlined />
                    <Fmt id={inputText ? inputText : 'SingleFileInput.collapse.text'} />
                  </p>
                  <p className="ant-upload-hint">
                    <Fmt id={inputHint ? inputHint : 'SingleFileInput.collapse.hint'} />
                  </p>
                </div>
              </span>
            </div>
          )}
        </Dropzone>
      );
    }
  }
}

export default SingleFileInput;
