import { api } from 'api';
import { DirectoryCreateDto, DirectoryDto } from 'api/completeApiInterfaces';
import { trim } from 'lodash';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryCreateForm, { DirectoryCreateFormData } from './DirectoryCreateForm';
import { useFocus } from 'hooks/useFocus';
import { InputRef } from 'antd';

type Props = FormModalProps<DirectoryDto> & {
  parentDirectoryId: Guid;
  validateUniqueName: (name: string) => boolean;
};

const DirectoryCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, parentDirectoryId, validateUniqueName, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DirectoryCreateFormData> = async (values) => {
    const data: DirectoryCreateDto = {
      name: trim(values.name),
      parentId: parentDirectoryId,
      description: values.description,
      permissionInheritance: true,
      requiredCategoryTrees: {},
    };

    const [err, res] = await api.project.directories.createDirectory(data);
    if (err) return err;

    await onSubmit(res.data);
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId="DirectoryCreateFormModal.title" {...restProps}>
      {({ intl, formRef }) => (
        <DirectoryCreateForm
          intl={intl}
          setRef={setInputRef}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
        />
      )}
    </FormModalWrapper>
  );
};

export default DirectoryCreateFormModal;
