import { SettingOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  onDisplayDirectorySettings: (isVisible: boolean) => void;
  displayDirectorySettings?: boolean;
};

export const ToolbarSettingsButton: FunctionComponent<Props> = ({
  displayDirectorySettings,
  onDisplayDirectorySettings,
}) => {
  return (
    <>
      <CommonHubTooltip
        title={
          displayDirectorySettings ? <Fmt id="ToolbarSettingsButton.close" /> : <Fmt id="ToolbarSettingsButton.open" />
        }
        placement="topRight"
      >
        <Switch
          checkedChildren={<SettingOutlined />}
          unCheckedChildren={<SettingOutlined />}
          checked={displayDirectorySettings}
          onChange={(value) => onDisplayDirectorySettings(value)}
        />
      </CommonHubTooltip>
    </>
  );
};

export default ToolbarSettingsButton;
