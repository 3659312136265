import {
  createMultiSelectFilterFunction,
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import Label from 'components/Label';
import { useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useFilters } from 'hooks/useFilters';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { labelsOrderedListSelector } from 'store/selectors/labelsSelectors';
import { checkObject } from 'utils';

type Props = {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
};

const LabelsFilterComponent: FunctionComponent<Props> = ({ value, onChange }) => {
  const labelsList = useStoreSelector(labelsOrderedListSelector);
  useDispatchEffect((dispatch) => dispatch.labels.loadData({ reload: false }), []);
  useDirtyStoreReload(
    (store) => store.labels,
    (dispatch) => dispatch.labels
  );

  const intl = useIntl();
  const labelsOptions = useMemo(
    () =>
      (labelsList || []).map(
        (label): IOption<Guid> => ({
          id: label.id,
          title: label.name,
          label: <Label color={label.color}>{label.name}</Label>,
        })
      ),
    [labelsList]
  );

  return (
    labelsList?.length > 0 && (
      <SelectFilter
        label={<Fmt id="general.labels" />}
        options={labelsOptions}
        enableAndOrOperator
        value={value}
        onChange={onChange}
        searchItemsPlaceholder={intl.formatMessage({ id: 'filters.searchPlaceholder.labels' })}
      />
    )
  );
};

export const LabelsFilter = React.memo(LabelsFilterComponent);

const createCommonLabelsFilter = (key: string): CommonFilter<SelectFilterValue<Guid>> => ({
  key,
  render: (value, setValue) => <LabelsFilter value={value} onChange={setValue} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendLabelsFilter = <T,>(
  key: string,
  valuesSelectors: (item: T) => Guid[]
): FrontendFilter<T, SelectFilterValue<Guid>> => ({
  ...createCommonLabelsFilter(key),
  filter: createMultiSelectFilterFunction(valuesSelectors),
});

export const createBackendLabelsFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: SelectFilterValue<Guid>) => T
): BackendFilter<T, SelectFilterValue<Guid>> => ({
  ...createCommonLabelsFilter(key),
  serialize,
});

type FilterProps = Pick<ReturnType<typeof useFilters>, 'clearFilters' | 'setFilterValue'>;
export const useSetFiltersToSearchLabel = (filterProps: FilterProps, labelsKey: string) => {
  const setFilterToSearchByLabelId = useStoreSelector((state) => state.persistentUi.setFilterToSearchByLabelId);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (setFilterToSearchByLabelId) {
      filterProps.clearFilters();
      const labelsFilterValue: SelectFilterValue<Guid> = {
        values: [setFilterToSearchByLabelId],
        notSet: false,
        useAndOperator: false,
      };
      filterProps.setFilterValue(labelsKey, labelsFilterValue);
      dispatch.persistentUi.setFilterToSearchByLabelId(undefined);
    }
  }, [setFilterToSearchByLabelId]);
};
