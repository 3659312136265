import { api } from 'api';
import { AppUserDto, ServiceError } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import React, { FunctionComponent, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { messageError } from 'utils';
import AdminsListPanel from './AdminsListPanel';

type Props = InjectedIntlProps & {
  appUsers: AppUserDto[];
  loadOrganizationsAdminReport: () => void;
  organizationsAdminReportLoading: boolean;
  organizationsAdminReportError: ServiceError;
};

const Administrators: FunctionComponent<Props> = (props) => {
  const {
    appUsers,
    loadOrganizationsAdminReport,
    organizationsAdminReportLoading,
    organizationsAdminReportError,
  } = props;
  const intl = useIntl();

  const removeAppUserFromAdminList = useCallback(
    async (appUserUserId: Guid) => {
      const changedAppUser = appUsers.find((user) => user.id === appUserUserId);
      await api.master.organization.setappuser({
        users: [
          {
            language: changedAppUser.language,
            mail: changedAppUser.username,
            isAdmin: false,
          },
        ],
      });
      loadOrganizationsAdminReport();
    },
    [appUsers, loadOrganizationsAdminReport]
  );

  const reinviteAppUser = useCallback(async (appUserId: Guid) => {
    const [err] = await api.master.organization.reinvite({
      appUserId,
    });
    if (err) {
      messageError(err, intl);
    }
  }, []);

  return (
    <>
      <PageContent title={props.intl.formatMessage({ id: 'general.administrator.aspeHub' })}>
        <AdminsListPanel
          intl={props.intl}
          appUsers={appUsers || []}
          loading={organizationsAdminReportLoading}
          error={organizationsAdminReportError}
          onRemove={removeAppUserFromAdminList}
          loadOrganizationsAdminReport={loadOrganizationsAdminReport}
          onReinvite={reinviteAppUser}
        />
      </PageContent>
    </>
  );
};

export default injectIntl(Administrators);
