import React from 'react';

export const StampOutlined = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    width="1em"
    height="1em"
    stroke="currentColor"
    fill="currentColor"
    aria-hidden
    data-icon="stamp"
    focusable="false"
  >
    <path d="m512 64c-52.712 0-99.038 6.4797-135.8 32.532-36.76 26.052-56.206 71.854-56.206 126.7 0 59.446 28.095 105.48 51.134 148.95 18.803 35.481 34.753 69.831 40.217 111.11-3.7876 1.3668-7.5148 2.5357-11.449 4.5029-24.587 12.293-51.902 44.199-51.902 88.199v28h-183.99s-23.514-0.48941-48.1 11.804c-24.586 12.293-51.902 44.199-51.902 88.199v127.99h64.001v28s-0.4896 23.514 11.804 48.1c12.293 24.587 44.199 51.902 88.199 51.902h568c44 0 75.906-27.316 88.199-51.902 12.293-24.587 11.796-48.1 11.796-48.1v-28h64.001v-127.99c0-44-27.316-75.906-51.902-88.199-24.586-12.293-48.1-11.804-48.1-11.804h-184v-28c0-44-27.308-75.906-51.895-88.199-3.9384-1.9692-7.673-3.1427-11.464-4.5103 5.3791-38.996 20.763-67.985 39.493-99.98 23.114-39.483 51.873-85.087 51.873-148.89-4e-5 -59.684-18.266-107.64-55.224-135.77-36.958-28.131-84.067-34.658-136.78-34.658zm0 71.996c45.898 0 76.784 7.4776 93.174 19.953s26.826 31.735 26.826 78.477c0 42.628-18.185 71.81-42.01 112.51-19.668 33.597-41.962 74.821-49.23 129.07h-57.402c-7.0214-55.503-28.833-100.21-48.61-137.52-23.899-45.099-42.749-79.451-42.749-115.24 0-40.386 9.2488-56.202 25.837-67.958 16.588-11.756 48.266-19.282 94.164-19.282zm-64.001 412.01h128s8.4871 0.49354 15.901 4.2003c7.4135 3.7067 12.099 3.7992 12.099 23.799v28h-184v-28c0-20 4.6854-20.092 12.099-23.799 7.4135-3.7068 15.901-4.2003 15.901-4.2003zm-284 128h695.99s8.487 0.49357 15.901 4.2003c7.4136 3.7067 12.099 3.7998 12.099 23.799v55.999h-752v-55.999c0-19.999 4.6928-20.093 12.106-23.799s15.901-4.2003 15.901-4.2003zm36.002 155.99h624v28s-0.49356 8.4871-4.2003 15.901c-3.7067 7.4135-3.7993 12.099-23.799 12.099h-568c-20 0-20.092-4.6854-23.799-12.099-3.7068-7.4135-4.2003-15.901-4.2003-15.901z" />
  </svg>
);
