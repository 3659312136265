import EmptySimple from 'components/EmptySimple';
import React, { ReactNode, useMemo } from 'react';

type Props<T> = {
  data: T[];
  search?: string;
  renderItem: (item: T, index: number) => ReactNode;
  filterItem?: (item: T, search: string, index: number) => boolean;
  disableShowEmpty?: boolean;
  renderEmpty?: (total: number, filtered: number) => ReactNode;
};

function List<T>(props: Props<T>) {
  const { data, search, renderItem, filterItem, disableShowEmpty, renderEmpty } = props;

  const filtered = useMemo(
    () => data && (filterItem ? data.filter((item, index) => filterItem(item, search, index)) : data),
    [data, search, filterItem]
  );

  if (!filtered || filtered.length === 0) {
    return disableShowEmpty ? null : renderEmpty ? <>{renderEmpty(data.length, filtered.length)}</> : <EmptySimple />;
  }

  // TODO: possibly missing key
  return <>{filtered.map((item, index) => renderItem(item, index))}</>;
}

export default List;
