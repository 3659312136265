import { OrganizationDto } from 'api/completeApiInterfaces';
import { ReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import React, { FunctionComponent, useCallback } from 'react';
import uuid from 'uuid';

import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import SharedReportWidgetCreateForm, { SharedReportWidgetCreateFormData } from './SharedReportWidgetCreateForm';

type Props = FormModalProps<ReportWidgetConfiguration> & {
  organizations: OrganizationDto[];
  defaults?: SharedReportWidgetCreateFormData;
};

const SharedReportWidgetCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizations, defaults, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<SharedReportWidgetCreateFormData> = useCallback(
    async (values) => {
      const data: ReportWidgetConfiguration = {
        id: uuid(),
        title: values.name,
        order: 0,
        type: 'shared',
        organizationId: values.organizationId,
        widgetId: values.sharedReportId,
      };
      await onSubmit(data);
      return null;
    },
    [onSubmit]
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="ReportWidgetCreateFormModal.title"
      submitTextId="ReportWidgetCreateFormModal.addButton"
      submitTextLoadingId={null}
      width={600}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <SharedReportWidgetCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          organizations={organizations}
          defaults={defaults}
        />
      )}
    </FormModalWrapper>
  );
};

export default SharedReportWidgetCreateFormModal;
