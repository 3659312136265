import { Button, Checkbox, Modal, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { api } from 'api';
import { AuditLogUsersMonthlyRequestDto, LanguageEnum } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DateRangeValue, InlineDateRangePicker } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import { Margin } from 'components/Margin/Margin';
import { DATE_FORMAT_FOR_FILE_EXPORT } from 'config/constants';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import { csMessages } from 'locale/messages/cs';
import { enMessages } from 'locale/messages/en';
import { Dictionary } from 'lodash';
import moment from 'moment-business-days';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';

type Props = {
  onSubmit: () => void;
  onClose: () => void;
  visible: boolean;
};

const formDataToDto = (
  data: DateRangeValue,
  intl: InjectedIntl,
  exportAllProjectTime: boolean
): AuditLogUsersMonthlyRequestDto => {
  const translationDb = intl.locale === LanguageEnum.en ? enMessages : csMessages;
  const auditLogLocalizations = (Object.keys(translationDb) as (keyof typeof translationDb)[])
    .filter((key) => key.startsWith('AuditLog.'))
    .reduce((obj, key) => {
      obj[key] = translationDb[key];
      return obj;
    }, {} as Dictionary<string>);

  return {
    auditLogTranslationsDto: {
      language: intl.locale,
      localization: auditLogLocalizations,
    },
    startTime: exportAllProjectTime ? undefined : data[0]?.startOf('day').toISOString(),
    endTime: exportAllProjectTime ? undefined : data[1]?.endOf('day').toISOString(),
  };
};

const DEFAULT_DATE_RANGE_VALUE: DateRangeValue = [moment(Date.now()).subtract(1, 'months'), moment(Date.now())];

export const UserActivityReportExportFormModal: FunctionComponent<Props> = ({ onSubmit, onClose, visible }) => {
  const [dateRangePickerValue, setDateRangePickerValue] = useState<DateRangeValue>(DEFAULT_DATE_RANGE_VALUE);
  const [exportAllProjectTime, setExportAllProjectTime] = useState<boolean>(false);
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useSameCallback(async () => {
    setLoading(true);

    const [err, resp] = await api.project.auditLog.getUserReportExport(
      formDataToDto(dateRangePickerValue, intl, exportAllProjectTime)
    );
    if (err) {
      setLoading(false);
      messageError(err, intl);
      return err;
    }

    const exportFileName = `user_activity_report_${moment(Date.now()).format(DATE_FORMAT_FOR_FILE_EXPORT)}.xlsx`;

    if (!!resp.data) {
      const blob = new Blob([resp.data], { type: 'application/ms-excel' });
      downloadBlob(blob, exportFileName);
    }

    onSubmit && (await onSubmit());
    setLoading(false);
    return null;
  });

  const handleChangeChekbox = (e: CheckboxChangeEvent) => {
    setExportAllProjectTime(e.target.checked);
  };

  const footer = (
    <div>
      <Button onClick={onClose}>
        <Fmt id="general.close" />
      </Button>
      <CommonHubTooltip
        title={
          !dateRangePickerValue && !exportAllProjectTime
            ? intl.formatMessage({ id: 'UserActivity.report.modal.disableOk.tooltipText' })
            : ''
        }
      >
        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmit}
          disabled={!dateRangePickerValue && !exportAllProjectTime}
        >
          <Fmt id="AllDocumentsPage.directory.report.modalOkText" />
        </Button>
      </CommonHubTooltip>
    </div>
  );

  return (
    <Modal
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      title={<Fmt id="UserActivity.report.modal.title" />}
      okText={<Fmt id="AllDocumentsPage.directory.report.modalOkText" />}
      cancelText={<Fmt id="general.close" />}
      width={800}
      maskClosable={false}
      confirmLoading={loading}
      footer={footer}
    >
      <Margin bottom>
        <Typography.Text strong>
          <Fmt id={'UserActivity.report.modal.rangeLabel'} />
        </Typography.Text>
      </Margin>
      <InlineDateRangePicker
        defaultValue={DEFAULT_DATE_RANGE_VALUE}
        onChange={setDateRangePickerValue}
        disabled={exportAllProjectTime}
      />
      <Margin top>
        <Checkbox onChange={handleChangeChekbox}>
          <Fmt id={'UserActivity.report.modal.checkbox.exportAllProjectTimeReport'} />
        </Checkbox>
      </Margin>
    </Modal>
  );
};

export default UserActivityReportExportFormModal;
