import { Alert } from 'antd';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { rolesOrderedListSelector } from 'store/selectors';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector, projectUsersMapSelector } from 'store/selectors/projectUsersSelectors';
import { userHasPermission } from 'utils/userHasPermission';
import UserDetailPanel from './UserDetailPanel';
import UsersListPanel from './UsersListPanel';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentProjectUser.data,
  groupsList: groupOrderedListSelector(state),
  rolesList: rolesOrderedListSelector(state),
  groupsMapError: state.groups.error,
  rolesMapError: state.roles.error,
  groupsLoading: state.groups.loading,
  rolesLoading: state.roles.loading,
  projectUsersMap: projectUsersMapSelector(state),
  projectUsersMapError: state.projectUsers.error,
  projectUsersList: projectUsersListSelector(state),
  projectUsersLoading: state.projectUsers.loading,
  currentProject: state.activeProject.activeProject,
  currentProjectLoading: state.activeProject.activeProjectLoading,
  currentProjectError: state.activeProject.activeProjectError,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadGroups: dispatch.groups.loadData,
  loadRoles: dispatch.roles.loadData,
  loadProjectUsers: dispatch.projectUsers.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState & PropsFromDispatch & InjectedIntlProps;

const Users: FunctionComponent<Props> = ({
  loadGroups,
  loadRoles,
  loadProjectUsers,
  projectUsersList,
  currentUser,
  projectUsersMap,
  groupsLoading,
  rolesLoading,
  projectUsersLoading,
  groupsMapError,
  rolesMapError,
  projectUsersMapError,
  groupsList,
  rolesList,
  currentProject,
  currentProjectError,
  currentProjectLoading,
  intl,
}) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useDirtyStoreReload(
    (store) => store.roles,
    (dispatch) => dispatch.roles
  );

  useEffect(() => {
    loadGroups({ reload: true });
    loadRoles({ reload: true });
    loadProjectUsers({ reload: true });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const handleReload = useCallback(() => {
    loadGroups({ reload: true });
    loadProjectUsers({ reload: true });
  }, []);

  const handleReloadUsers = useCallback(async () => {
    await loadProjectUsers({ reload: true });
    history.push(url);
  }, [loadProjectUsers]);

  if (!currentUser.isAdmin && !userHasPermission(currentUser, ExtendedPermissionEnum.userEditing)) {
    return (
      <Margin top left right>
        <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
      </Margin>
    );
  }
  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'general.users' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <UsersListPanel
              intl={intl}
              usersList={projectUsersList}
              loading={groupsLoading || rolesLoading || projectUsersLoading || currentProjectLoading}
              error={groupsMapError || rolesMapError || projectUsersMapError || currentProjectError}
              selectedId={selectedItemId}
              onSelect={onSelect}
              currentProject={currentProject}
              groupsList={groupsList}
              handleReload={handleReload}
            />
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${path}/:userId`}
          render={(props) => (
            <ContentGate empty={!groupsList || !rolesList || !currentUser || !projectUsersMap}>
              <UserDetailPanel
                key={props.match.params.userId}
                reloadUsers={handleReloadUsers}
                intl={intl}
                groupsList={groupsList}
                rolesList={rolesList}
                currentUser={currentUser}
                projectUsersMap={projectUsersMap}
                currentProject={currentProject}
                {...props}
              />
            </ContentGate>
          )}
        />
      </Switch>
    </>
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(injectIntl(Users));
