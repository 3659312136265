import { createCancelToken } from 'api';
import { ApiPromise } from 'api/await-to';
import { ErrorType, Result } from 'api/errors';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import produce, { Draft } from 'immer';
import { processApiError } from 'utils';
import {
  ApiNewStoreModel,
  ApiNewStoreState,
  ApiStoreModel,
  ApiStoreState,
  RootState,
  SafeDispatch,
  StoreModelToDispatch,
  apiNewStoreDefaults,
  apiStoreDefaults,
} from './storeModelinterfaces';

type FetchDataFunc<T, U> = U extends undefined
  ? (cancelToken: CancelToken) => ApiPromise<T>
  : (cancelToken: CancelToken, data: U) => ApiPromise<T>;

export const createApiStoreModel = <T, U = undefined>(
  storeSelector: (store: RootState) => ApiStoreState<T>,
  dispatchSelector: (store: SafeDispatch) => StoreModelToDispatch<ApiStoreModel<T, U>>,
  fetchData: FetchDataFunc<T, U>
): ApiStoreModel<T, U> => {
  const cancelTokenRef = { current: undefined as CancelTokenSource };

  return {
    state: apiStoreDefaults,
    reducers: {
      setData: (state, data) =>
        produce(state, (draft) => {
          draft.data = data as Draft<T>;
          draft.error = null;
          draft.loading = false;
          draft.dirty = false;
        }),
      setError: (state, error) =>
        produce(state, (draft) => {
          draft.data = null;
          draft.error = error;
          draft.loading = false;
          draft.dirty = false;
        }),
      setLoading: (state, loading) =>
        produce(state, (draft) => {
          draft.loading = loading;
        }),
      setDefaults: () => apiStoreDefaults,
      setDirty: (state, dirty) =>
        produce(state, (draft) => {
          draft.dirty = dirty;
        }),
    },
    effects: (dispatch) => ({
      async loadData({ reload, silent, data }, rootState) {
        const storeState = storeSelector(rootState);
        const storeDispatch = dispatchSelector(dispatch);

        if (!reload && !storeState.dirty && (storeState.data !== null || storeState.loading)) return;

        cancelTokenRef.current?.cancel('apiStoreModel: new request was made');
        cancelTokenRef.current = createCancelToken();

        !silent && storeDispatch.setLoading(true);
        const [err, response] = await fetchData(cancelTokenRef.current.token, data);

        if (err) {
          if (!Axios.isCancel(err)) {
            processApiError(err, storeDispatch.setError);
          }
          return;
        }
        storeDispatch.setData(response.data);
      },

      clearData() {
        cancelTokenRef.current?.cancel('apiStoreModel: data was cleared');
        const storeDispatch = dispatchSelector(dispatch);
        storeDispatch.setDefaults();
      },

      updateData(updater, rootState) {
        const storeState = storeSelector(rootState);
        const storeDispatch = dispatchSelector(dispatch);

        if (storeState.data) {
          storeDispatch.setData(updater(storeState.data));
        }
      },
      markDirty() {
        const storeDispatch = dispatchSelector(dispatch);
        storeDispatch.setDirty(true);
      },
    }),
  };
};

type FetchNewDataFunc<T, U> = U extends undefined
  ? (cancelToken: CancelToken) => Promise<Result<T>>
  : (cancelToken: CancelToken, data: U) => Promise<Result<T>>;

export const createNewApiStoreModel = <T, U = undefined>(
  storeSelector: (store: RootState) => ApiNewStoreState<T>,
  dispatchSelector: (store: SafeDispatch) => StoreModelToDispatch<ApiNewStoreModel<T, U>>,
  fetchData: FetchNewDataFunc<T, U>
): ApiNewStoreModel<T, U> => {
  const cancelTokenRef = { current: undefined as CancelTokenSource };

  return {
    state: apiNewStoreDefaults,
    reducers: {
      setData: (state, data) =>
        produce(state, (draft) => {
          draft.data = data as Draft<T>;
          draft.error = null;
          draft.loading = false;
          draft.dirty = false;
        }),
      setError: (state, error) =>
        produce(state, (draft) => {
          draft.data = null;
          draft.error = error;
          draft.loading = false;
          draft.dirty = false;
        }),
      setLoading: (state, loading) =>
        produce(state, (draft) => {
          draft.loading = loading;
        }),
      setDefaults: () => apiNewStoreDefaults,
      setDirty: (state, dirty) =>
        produce(state, (draft) => {
          draft.dirty = dirty;
        }),
    },
    effects: (dispatch) => ({
      async loadData({ reload, silent, data }, rootState) {
        const storeState = storeSelector(rootState);
        const storeDispatch = dispatchSelector(dispatch);

        if (!reload && !storeState.dirty && (storeState.data !== null || storeState.loading)) return;

        cancelTokenRef.current?.cancel('apiStoreModel: new request was made');
        cancelTokenRef.current = createCancelToken();

        !silent && storeDispatch.setLoading(true);
        const [err, res] = await fetchData(cancelTokenRef.current.token, data);

        if (err) {
          if (!(err.type !== ErrorType.Cancelled)) {
            storeDispatch.setError(err);
          }
          return;
        }

        storeDispatch.setData(res);
      },

      clearData() {
        cancelTokenRef.current?.cancel('apiStoreModel: data was cleared');
        const storeDispatch = dispatchSelector(dispatch);
        storeDispatch.setDefaults();
      },

      updateData(updater, rootState) {
        const storeState = storeSelector(rootState);
        const storeDispatch = dispatchSelector(dispatch);

        if (storeState.data) {
          storeDispatch.setData(updater(storeState.data));
        }
      },

      markDirty() {
        const storeDispatch = dispatchSelector(dispatch);
        storeDispatch.setDirty(true);
      },
    }),
  };
};
