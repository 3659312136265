import { ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { textComparer } from 'utils/comparators';

const projectUsersDataSelector = (state: RootState) => state.projectUsers.data;

const projectUsersFilteredSelector = createSelector(
  [projectUsersDataSelector],
  (projectUsers) => projectUsers && projectUsers.filter((user) => user.status !== ProjectUserProfileStatusEnum.system)
);

export const projectUsersMapSelector = createSelector(
  [projectUsersFilteredSelector],
  (projectUsers) => projectUsers && keyBy(projectUsers, 'id')
);

export const projectUsersListSelector = createSelector(
  [projectUsersFilteredSelector],
  (projectUsers) => projectUsers && [...projectUsers].sort(textComparer.map((user) => user.username))
);
