import { to } from 'api/await-to';
import {
  AppUserDto,
  AppUsersRequestDto,
  ForgeMonthlyReportRequestDto,
  ForgeUploadOrganizationReportDto,
  OrganizationCreateDto,
  OrganizationDto,
  OrganizationPatchDto,
  OrganizationsAdminReportDto,
  OrganizationUserProjectsReportDto,
  OrgUserDto,
  OrgUsersRequestDto,
  ProjectsInRealisationReportExportRequestDto,
  ProjectUserActivationResponseDto,
  ProjectUserProfileListDto,
  ProjectUserSetAdminDto,
  ProjectUsersRequestDto,
  ReinviteRequestDto,
  SuspendAllUsersResponseDto
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseMasterApi } from '../baseMasterApi';

export const organizationsAdminApi = {
  getOrganizationsAdminReport: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<OrganizationsAdminReportDto>('/projects/admin/GetOrgAdminReport', {
        cancelToken,
      })
    ),
  deleteorganization: (organizationId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.delete<void>(`/projects/admin/deleteorganization/${organizationId}`, { cancelToken })
    ),
  getappusers: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AppUserDto[]>(`/projects/admin/getappusers`, { cancelToken })
    ),
  getorgusers: (organizationId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<OrgUserDto[]>(`/projects/admin/getorgusers/${organizationId}`, { cancelToken })
    ),
  getorgusersNonExistOnProject: (organizationId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<OrgUserDto[]>(`/projects/admin/getorgusers/${organizationId}/project/${projectId}`, {
        cancelToken,
      })
    ),
  project: (organizationId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<OrgUserDto[]>(`/projects/admin/getorgusers/${organizationId}/project/${projectId}`, {
        cancelToken,
      })
    ),

  patchorganization: (data: OrganizationPatchDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.patch<void>(`/projects/admin/patchorganization`, data, { cancelToken })
    ),
  setappuser: (data: AppUsersRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/setappusers`, data, { cancelToken })
    ),
  setorgusers: (data: OrgUsersRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/setorgusers`, data, { cancelToken })
    ),
  addprojectusers: (data: ProjectUsersRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/addprojectusers`, data, { cancelToken })
    ),
  getprojectusers: (projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectUserProfileListDto[]>(`/projects/admin/projectusers/${projectId}`, { cancelToken })
    ),
  addorganization: (data: OrganizationCreateDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<OrganizationDto>(`/projects/admin/addorganization`, data, { cancelToken })
    ),
  getForgeUsage: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ForgeUploadOrganizationReportDto>(`/projects/admin/forge/orgusage`, { cancelToken })
    ),
  suspendUser: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/projectusers/${projectId}/${userId}/suspend`, {
        cancelToken,
      })
    ),
  activateUser: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<ProjectUserActivationResponseDto>(
        `/projects/admin/projectusers/${projectId}/${userId}/activate`,
        {
          cancelToken,
        }
      )
    ),
  setadmin: (projectId: Guid, userId: Guid, data: ProjectUserSetAdminDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/projectusers/${projectId}/${userId}/setadmin`, data, {
        cancelToken,
      })
    ),
  reinvite: (data: ReinviteRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/admin/reinviteappuser`, data, {
        cancelToken,
      })
    ),
  deleteInvitedProjectUser: (projectId: Guid, userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.delete<void>(`/projects/admin/projectusers/${projectId}/${userId}`, { cancelToken })
    ),
  deleteOrganizationUser: (orgUserId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.delete<void>(`/projects/admin/orgusers/${orgUserId}`, { cancelToken })
    ),
  getOrgUserProjectsReport: (orgUserId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<OrganizationUserProjectsReportDto>(`/projects/admin/getorguserprojectsreport/${orgUserId}`, {
        cancelToken,
      })
    ),
  suspendUserInAllOrgProjects: (orgUserId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<SuspendAllUsersResponseDto>(`/projects/admin/suspendallusers/${orgUserId}`, {
        cancelToken,
      })
    ),
  getForgeMonthReport: (data: ForgeMonthlyReportRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<string>(`/projects/admin/forge/report`, data, {
        cancelToken,
        responseType: 'blob',
      })
    ),
  reports: {
    getProjectsInRealizationPOHReport: (organizationId: Guid,
      firmId: Guid,
      data: ProjectsInRealisationReportExportRequestDto, cancelToken?: CancelToken) =>
      to(
        baseMasterApi.post<string>(
          `/projects/reports/projectsinrealisationpoh/report/${organizationId}/${firmId}`,
          data,
          { cancelToken, responseType: 'blob', }
        )
      ),
  }
};
