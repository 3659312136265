import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useLostPermissionEffect = (effect: EffectCallback, permissionList: DependencyList, source: any = undefined) => {
    const lastSource = useRef(source);

    useEffect(() => {
        if (permissionList.some(dependency => !dependency) || lastSource.current !== source) {
            effect();
        }
        lastSource.current = source;
    }, [...permissionList, effect, source]);
};
