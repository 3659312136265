import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Select, Space, Switch, Typography } from 'antd';
import { ProjectListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { requiredRule, simpleSelectFilter } from 'utils/formHelpers';

export type ProjectTemplateSelectSourceProjectFormData = {
  projectId: Guid;
  tryUserPermissionPairig: boolean;
};

type Props = FormComponentProps<ProjectTemplateSelectSourceProjectFormData> &
  InjectedIntlProps & {
    projectsList: ProjectListDto[];
    organizationId: Guid;
  };

const ProjectTemplateSelectSourceProjectFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, projectsList, organizationId }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const handleSwitchValueChanged = (checked: boolean) => {
      const formValues = form.getFieldsValue;
      form.setFieldsValue({ ...formValues, tryUserPermissionPairig: checked });
    };

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.projectSelect' })}>
          {form.getFieldDecorator<ProjectTemplateSelectSourceProjectFormData>('projectId', {
            rules: [requiredRule('ProjectTemplateSelectSourceProjectModal.error.projectMissing')],
          })(
            <Select showSearch filterOption={simpleSelectFilter}>
              {projectsList
                ?.filter((project) => project.organization.id === organizationId)
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          {form.getFieldDecorator<ProjectTemplateSelectSourceProjectFormData>('tryUserPermissionPairig', {
            initialValue: true,
            valuePropName: 'checked',
          })(
            <Space>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                size="small"
                onChange={handleSwitchValueChanged}
                defaultChecked
              />
              <Typography.Text>
                {intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.form.tryPairing' })}
              </Typography.Text>
              <CommonHubTooltip
                title={intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.form.tryPairing.tooltip' })}
              >
                <InfoCircleOutlined />
              </CommonHubTooltip>
            </Space>
          )}
        </Form.Item>
      </Form>
    );
  }
);

export const ProjectTemplateSelectSourceProjectForm = React.memo(
  Form.create<Props>()(ProjectTemplateSelectSourceProjectFormComponent)
);
