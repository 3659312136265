import moment from 'moment';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import { useStoreSelector } from './useSelectorDispatch';

export const useReserveDocumentTooltip = (
  isReserved: boolean,
  intl: InjectedIntl,
  reservedBy: string,
  reservedDate: IsoDateTime,
  releaseReservedDate?: IsoDateTime
) => {
  const reservationDays = useStoreSelector((state) => state.appSettings.data).reservationTime;

  if (isReserved) {
    return (
      <>
        <div>
          {intl.formatMessage(
            { id: 'ReserveFileButton.tooltip.release' },
            {
              user: reservedBy,
              date: moment(reservedDate)
                .locale(intl.locale)
                .format('lll'),
            }
          )}
        </div>
        <div>
          {!!releaseReservedDate
            ? intl.formatMessage(
                { id: 'ReserveFileButton.tooltip.release.info' },
                {
                  releaseDate: moment(releaseReservedDate)
                    .locale(intl.locale)
                    .format('lll'),
                }
              )
            : intl.formatMessage({ id: 'ReserveFileButton.tooltip.release.noLimit' })}
        </div>
      </>
    );
  }
  return (
    <>
      <div>{intl.formatMessage({ id: 'ReserveFileButton.tooltip.reserve' })}</div>
      <div>
        {!!reservationDays
          ? intl.formatMessage(
              { id: 'ReserveFileButton.tooltip.reserve.withLimit' },
              {
                reservationDays: reservationDays,
              }
            )
          : intl.formatMessage({ id: 'ReserveFileButton.tooltip.reserve.noLimit' })}
      </div>
    </>
  );
};
