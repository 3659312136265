import { WindowsOutlined } from '@ant-design/icons';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import React, { useEffect } from 'react';

const OfficeOnlineFileURL = 'https://view.officeapps.live.com/op/view.aspx?src=';

const getIFrameUrl = (fileurl: string) => {
  const urlEnc = encodeURIComponent(fileurl);
  return OfficeOnlineFileURL + urlEnc;
};

export const OfficeOnlineViewer: FileViewerComponent = ({ style, className, url, onLoad }) => {
  useEffect(() => onLoad && onLoad(), []);

  return (
    <iframe
      id="OfficeOnlineViewerIFrame"
      style={style}
      className={className}
      src={getIFrameUrl(url)}
      allowFullScreen={true}
      frameBorder={0}
    />
  );
};

const supportedTypes = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const OfficeOnlineViewerData: FileViewerProperties = {
  supportedTypes,
  icon: <WindowsOutlined />,
  component: 'OfficeOnlineViewer',
  titleTranslationId: 'OfficeViewer.title',
};
