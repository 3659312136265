import { Checkbox, Col, Collapse, Row, Typography } from 'antd';
import {
  MsgCategoryEnum,
  MsgCategoryTypeEnum,
  MsgCenterDefaultSettingDto,
  MsgCenterDefaultSettingsDto,
  MsgCenterSettingPatchDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import { MinHeightSizer } from 'components/MinHeightSizer/MinHeightSizer';
import StackPanel from 'components/StackPanel';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import { NO_SETTABLE_CATEGORIES, categoryMap } from '../Constants';
import { SettingsReducerActions } from './MessageCenterSettings';
import styles from './MessageCenterSettings.module.less';

const { Panel } = Collapse;

type Props = {
  className?: string;
  currentSetting: MsgCenterDefaultSettingsDto;
  loading?: boolean;
  loadingError?: ServiceError;
  settingDispatch: (value: SettingsReducerActions) => void;
};

export const MessageDefaultSettingsDetail: FunctionComponent<Props> = (props) => {
  const { className, currentSetting, loading, loadingError, settingDispatch } = props;

  const handleSendEmailToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({ type: 'setDefaultSettingOption', categories, setting: 'sendEmail', value }),
    [settingDispatch]
  );

  const handleSendReportToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({ type: 'setDefaultSettingOption', categories, setting: 'sendReport', value }),
    [settingDispatch]
  );

  const handleIgnoreToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({ type: 'setDefaultSettingOption', categories, setting: 'ignore', value }),
    [settingDispatch]
  );

  const renderHeaderOption = useCallback(
    (categories: MsgCategoryEnum[]) => {
      const relevantSettings =
        currentSetting?.settings.filter((setting) => categories.includes(setting.category)) || [];

      const getCheckboxStatus = (property: keyof MsgCenterSettingPatchDto) => ({
        isChecked: relevantSettings.every((setting) => setting[property]),
        isIndeterminate:
          relevantSettings.some((setting) => setting[property]) &&
          relevantSettings.some((setting) => !setting[property]),
      });

      const sendEmailStatus = getCheckboxStatus('sendEmail');
      const sendReportStatus = getCheckboxStatus('sendReport');
      const ignoreStatus = getCheckboxStatus('ignore');

      return (
        <>
          <Col span={4}>
            <Checkbox
              checked={sendEmailStatus.isChecked}
              indeterminate={sendEmailStatus.isIndeterminate}
              onChange={(value) => handleSendEmailToggle(value.target.checked, categories)}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={sendReportStatus.isChecked}
              indeterminate={sendReportStatus.isIndeterminate}
              onChange={(value) => handleSendReportToggle(value.target.checked, categories)}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={ignoreStatus.isChecked}
              indeterminate={ignoreStatus.isIndeterminate}
              onChange={(value) => handleIgnoreToggle(value.target.checked, categories)}
            />
          </Col>
        </>
      );
    },
    [currentSetting, handleSendEmailToggle, handleSendReportToggle, handleIgnoreToggle]
  );

  const renderDefaultOption = useCallback(
    (defaultOption: MsgCenterDefaultSettingDto) => {
      return (
        <div className={styles.panelContent}>
          <Row key={defaultOption.category}>
            <Col span={10}>
              <Fmt id={`MessageCenterPage.message.category.${defaultOption.category}.filter`} />
            </Col>
            <Col span={4}>
              <Checkbox
                checked={defaultOption.sendEmail}
                onChange={(value) => handleSendEmailToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                checked={defaultOption.sendReport}
                onChange={(value) => handleSendReportToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                checked={defaultOption.ignore}
                onChange={(value) => handleIgnoreToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
          </Row>
        </div>
      );
    },
    [handleSendEmailToggle, handleSendReportToggle, handleIgnoreToggle]
  );

  const mappedSettings = useMemo(
    () =>
      currentSetting?.settings?.reduce((result, setting) => {
        result[setting.category] = renderDefaultOption(setting);

        return result;
      }, {} as Record<MsgCategoryEnum, ReactNode>) || ({} as Record<MsgCategoryEnum, ReactNode>),
    [currentSetting, renderDefaultOption]
  );

  const unmappedKeys = useMemo(() => {
    return (currentSetting?.settings?.map((setting) => setting.category) || [])
      .filter(
        (category) =>
          !Object.values(categoryMap).some((categoryGroup) => categoryGroup.includes(category)) &&
          !NO_SETTABLE_CATEGORIES.includes(category)
      )
      .sort();
  }, [currentSetting]);

  // render
  return (
    <ContentGate loading={loading} error={loadingError}>
      <StackPanel vertical className={className}>
        <Row>
          <Col span={10}></Col>
          <Col span={4}>
            <CommonHubTooltip title={<Fmt id={'MessageCenterPage.settings.option.header.email.tooltip'} />}>
              <span>
                <Fmt id={'MessageCenterPage.settings.option.header.email'} />
              </span>
            </CommonHubTooltip>
          </Col>
          <Col span={4}>
            <CommonHubTooltip title={<Fmt id={'MessageCenterPage.settings.option.header.report.tooltip'} />}>
              <span>
                <Fmt id={'MessageCenterPage.settings.option.header.report'} />
              </span>
            </CommonHubTooltip>
          </Col>
          <Col span={4}>
            <CommonHubTooltip title={<Fmt id={'MessageCenterPage.settings.option.header.mute.tooltip'} />}>
              <span>
                <Fmt id={'MessageCenterPage.settings.option.header.mute'} />
              </span>
            </CommonHubTooltip>
          </Col>
        </Row>
        <MinHeightSizer minHeight={200}>
          <StackPanel vertical scrollable>
            {Object.keys(categoryMap).map((categoryType: MsgCategoryTypeEnum) => (
              <Margin bottom key={categoryType}>
                <Collapse>
                  <Panel
                    key={categoryType}
                    header={
                      <Row>
                        <Col span={10}>
                          <Typography.Text>
                            <Fmt id={`MessageCenterPage.message.categoryType.${categoryType}`} />
                          </Typography.Text>
                        </Col>
                        {renderHeaderOption(categoryMap[categoryType])}
                      </Row>
                    }
                  >
                    {categoryMap[categoryType].map((category) => mappedSettings[category])}
                  </Panel>
                </Collapse>
              </Margin>
            ))}
            {unmappedKeys.length > 0 && (
              <Margin top>
                <Collapse>
                  <Panel
                    key={'unmappedKeys'}
                    header={
                      <Row>
                        <Col span={10}>
                          <Typography.Text>
                            <Fmt id={'MessageCenterPage.settings.unmappedCategory.title'} />
                          </Typography.Text>
                        </Col>
                        {renderHeaderOption(unmappedKeys)}
                      </Row>
                    }
                  >
                    {unmappedKeys.map((category) => mappedSettings[category])}
                  </Panel>
                </Collapse>
              </Margin>
            )}
          </StackPanel>
        </MinHeightSizer>
      </StackPanel>
    </ContentGate>
  );
};
