import { Button, InputRef, Modal, Tabs } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { api } from 'api';
import {
  DirectoryDetailDto,
  DirectoryListDto,
  DocumentCategoryTreeDto,
  ProjectUserProfileListDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DirectorySettingsPermissions from 'components/DirectorySettingsPermissions';
import { DirectoryWatchSettings } from 'components/DirectoryWatchSettings/DirectoryWatchSettings';
import FilenameErrorMessageTooltip from 'components/FilenameErrorMessage/FilenameErrorMessage.Tooltip';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { Margin } from 'components/Margin/Margin';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import TextInputSettingsItem from 'components/SettingsItem/TextInputSettingsItem';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import DirectorySettingsRequiredCategories from 'components/forms/DirectorySettingsForm/DirectorySettingsRequiredCategories';
import { FlexTabs } from 'components/tabs/FlexTabs/FlexTabs';
import { MAX_ITEM_DESCRIPTION_LENGTH, MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import numeral from 'numeral';
import { canEditDirectoryInfo } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import styles from './DirectorySettingsForm.module.less';

export enum DirectorySettingsFormTabsEnum {
  general = 'general',
  accessPermission = 'accessPermission',
  watchSettings = 'watchSettings',
}

export type DirectorySettingsFormProps = {
  directory: DirectoryListDto;
  usersList: ProjectUserProfileListDto[];
  usersMap: Dictionary<ProjectUserProfileListDto>;
  onSubmit: () => void;
  categoryMap: Dictionary<DocumentCategoryTreeDto>;
  defaultTab: DirectorySettingsFormTabsEnum;
  setDefaultTab: (tab: DirectorySettingsFormTabsEnum) => void;
  projectId: Guid;
};

type Props = DirectorySettingsFormProps & InjectedIntlProps;

type State = {
  directory: DirectoryDetailDto;
  directoryError: ServiceError;
  loading: boolean;
  errorModalVisible: boolean;
};

class DirectorySettingsForm extends Component<Props, State> {
  state: State = {
    directory: null,
    directoryError: null,
    loading: false,
    errorModalVisible: false,
  };

  inputRef = createRef<InputRef | TextAreaRef>();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.loadDirectoryDetail();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.directory !== undefined && this.props.directory !== prevProps.directory) {
      this.loadDirectoryDetail();
    }
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  // TODO: update in store as well?
  loadDirectoryDetail = async () => {
    const { directory } = this.props;
    if (!directory) return;
    const directoryId = directory.id;

    this.setLoading(true);
    const [errD, resultD] = await api.project.directories.getDirectoryById(directoryId);
    if (errD) {
      processApiError(errD, (e) => {
        this.setState({ directory: null, directoryError: e });
      });
    } else {
      this.setState({ directory: resultD.data, directoryError: null });
    }
    this.setLoading(false);
  };

  handleChangeTab = (tab: string) => this.props.setDefaultTab(tab as DirectorySettingsFormTabsEnum);

  handleCloseError = () => {
    this.setState({ errorModalVisible: false });
    this.inputRef.current && this.inputRef.current.focus();
  };

  getDocsLinksNumberValuesText(docNumber: number, linksNumber: number, subDirNumber: number) {
    return `${this.props.intl.formatMessage({
      id: 'general.documents',
    })}: ${docNumber}  ${this.props.intl.formatMessage({
      id: 'general.links',
    })}: ${linksNumber}   ${this.props.intl.formatMessage({ id: 'general.folders' })}: ${subDirNumber}`;
  }

  render() {
    const { intl, defaultTab, projectId, usersMap, usersList } = this.props;
    const { directory, directoryError, loading } = this.state;

    const editPermission = canEditDirectoryInfo(directory);
    return (
      <StackPanel vertical stretch className={styles.container}>
        <FlexTabs defaultActiveKey={defaultTab} onChange={this.handleChangeTab}>
          <Tabs.TabPane
            tab={intl.formatMessage({ id: 'DirectorySettingsForm.tabs.general' })}
            key={DirectorySettingsFormTabsEnum.general}
          >
            <SpinBoxCenter spinning={loading} delay={500} tip={intl.formatMessage({ id: 'general.loading' })}>
              {directoryError && <ServiceErrorBox error={directoryError} />}
              {directory && (
                <StackPanel vertical scrollable>
                  <Margin left top right bottom>
                    <GeneralSettingsContainer itemsLargeGap>
                      <GeneralSettingsContainer>
                        <SettingsBox>
                          <TextInputSettingsItem
                            itemId={directory.id}
                            value={directory.name}
                            onSave={this.onEditName}
                            headline={<Fmt id="forms.items.name.label" />}
                            inputRef={this.inputRef}
                            disableEdit={!editPermission}
                            loading={loading}
                          />
                          <Modal
                            open={this.state.errorModalVisible}
                            onCancel={this.handleCloseError}
                            footer={[
                              <Button type="primary" key="ok" onClick={this.handleCloseError}>
                                <Fmt id="general.close" />
                              </Button>,
                            ]}
                            title={<Fmt id="general.error" />}
                          >
                            <FilenameErrorMessageTooltip />
                          </Modal>
                        </SettingsBox>
                        <SettingsBox>
                          <TextInputSettingsItem
                            itemId={directory.id}
                            value={directory.description}
                            onSave={this.onEditDescription}
                            headline={<Fmt id="forms.items.description.label" />}
                            disableEdit={!editPermission}
                            multiline
                            loading={loading}
                          />
                        </SettingsBox>
                        <SettingsBox>
                          <DirectorySettingsRequiredCategories
                            directory={directory}
                            initLoading={loading}
                            onCategoriesChange={this.loadDirectoryDetail}
                            editDisabled={!editPermission}
                          />
                        </SettingsBox>
                        {!!directory.statistic && (
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={intl.formatMessage({
                                id: `DirectorySettingsForm.tabs.general.type.${directory.statistic.type}`,
                              })}
                              headline={<Fmt id="DirectorySettingsForm.tabs.general.type" />}
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                        )}
                      </GeneralSettingsContainer>
                      {!!directory.statistic && (
                        <GeneralSettingsContainer title={<Fmt id="DirectorySettingsForm.tabs.general.title" />}>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={numeral(directory.statistic.dirSize).format('0.0 b')}
                              headline={<Fmt id="DirectorySettingsForm.tabs.general.size" />}
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={numeral(directory.statistic.dirFullSize).format('0.0 b')}
                              headline={<Fmt id="DirectorySettingsForm.tabs.general.sizeIncludingAttachments" />}
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={numeral(directory.statistic.dirTreeSize).format('0.0 b')}
                              headline={<Fmt id="DirectorySettingsForm.tabs.general.sizeIncludingSubfolders" />}
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={numeral(directory.statistic.dirFullTreeSize).format('0.0 b')}
                              headline={
                                <Fmt id="DirectorySettingsForm.tabs.general.sizeIncludingSubfoldersAndAttachments" />
                              }
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={this.getDocsLinksNumberValuesText(
                                directory.statistic.dirDocCount,
                                directory.statistic.dirDocLinkCount,
                                directory.statistic.subDirCount
                              )}
                              headline={<Fmt id="DirectorySettingsForm.tabs.general.documentsCount" />}
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                          <SettingsBox>
                            <TextInputSettingsItem
                              itemId={directory.id}
                              value={this.getDocsLinksNumberValuesText(
                                directory.statistic.dirTreeDocCount,
                                directory.statistic.dirTreeDocLinkCount,
                                directory.statistic.subDirTreeCount
                              )}
                              headline={
                                <Fmt id="DirectorySettingsForm.tabs.general.documentsCountIncludingSubfolders" />
                              }
                              disableEdit
                              loading={loading}
                            />
                          </SettingsBox>
                        </GeneralSettingsContainer>
                      )}
                    </GeneralSettingsContainer>
                  </Margin>
                </StackPanel>
              )}
            </SpinBoxCenter>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={intl.formatMessage({ id: 'DirectorySettingsForm.tabs.accessPermission' })}
            key={DirectorySettingsFormTabsEnum.accessPermission}
          >
            <SpinBoxCenter spinning={loading} delay={0} tip={intl.formatMessage({ id: 'general.loading' })}>
              {directoryError && <ServiceErrorBox error={directoryError} />}
              {directory && (
                <DirectorySettingsPermissions
                  directory={directory}
                  projectId={projectId}
                  usersList={usersList}
                  usersMap={usersMap}
                />
              )}
            </SpinBoxCenter>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={intl.formatMessage({ id: 'DocumentDetailPageWatch.tabTitle' })}
            key={DirectorySettingsFormTabsEnum.watchSettings}
          >
            <ContentGate error={directoryError} loading={loading}>
              {directory && (
                <DirectoryWatchSettings
                  key={directory.id}
                  directory={directory}
                  reloadDirectory={this.loadDirectoryDetail}
                />
              )}
            </ContentGate>
          </Tabs.TabPane>
        </FlexTabs>
      </StackPanel>
    );
  }

  onEditName = async (name: string) => {
    const { directory } = this.state;
    if (directory.name === name) {
      return true;
    }
    if (name.length > MAX_ITEM_NAME_LENGTH) {
      return this.props.intl.formatMessage(
        {
          id: `general.maxNameLength`,
        },
        { max: MAX_ITEM_NAME_LENGTH, current: name.length }
      );
    }
    const [err] = await api.project.directories.patchDirectory(directory.id, { name });
    if (err) {
      this.setState({ errorModalVisible: true });
      return this.props.intl.formatMessage({ id: 'FilenameErrorMessage.message' });
    }
    this.props.onSubmit();
    return true;
  };

  onEditDescription = async (description: string) => {
    const { directory } = this.state;
    if (directory.description === description) {
      return true;
    }
    if (description.length > MAX_ITEM_DESCRIPTION_LENGTH) {
      return this.props.intl.formatMessage(
        {
          id: `general.maxDescriptionLength`,
        },
        { max: MAX_ITEM_DESCRIPTION_LENGTH, current: description.length }
      );
    }
    const [err] = await api.project.directories.patchDirectory(directory.id, { description });
    if (err)
      return this.props.intl.formatMessage({
        id: `serviceError.${processApiError(err).referenceErrorCode}`,
      });
    this.props.onSubmit();
    return true;
  };
}

export default injectIntl(DirectorySettingsForm);
