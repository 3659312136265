import React, { FunctionComponent, HTMLProps, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './HeaderContentLayout.module.less';

type Props = HTMLProps<HTMLDivElement> & {
  header: ReactNode;
  modals?: ReactNode;
};

export const HeaderContentLayout: FunctionComponent<Props> = React.memo<Props>(
  ({ children, modals, header, className, ...divProps }) => {
    return (
      <>
        <div className={classnames(styles.container, className)} {...divProps}>
          {header}
          {children}
        </div>
        {modals}
      </>
    );
  }
);
