import { ValidationRule } from '@ant-design/compatible/lib/form';
import { DefaultOptionType } from 'antd/lib/select';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React from 'react';
import { smartFilter } from 'utils/smartSearch';

export const simpleSelectFilter = (input: string, option: DefaultOptionType) =>
  smartFilter(option.props.children.toString(), input);

export const labelSelectFilter = (input: string, option: DefaultOptionType) =>
  smartFilter(option.props.label.toString(), input);

export const requiredRule: (intlId: IntlMessageId, includeWhitespace?: boolean) => ValidationRule = (
  intlId,
  includeWhitespace
) => {
  const config: ValidationRule = {
    required: true,
    message: React.createElement(Fmt, { id: intlId }),
  };
  if (includeWhitespace) {
    config.whitespace = includeWhitespace;
  }
  return config;
};

export const whitespaceRule: (intlId: IntlMessageId) => ValidationRule = (intlId) => ({
  whitespace: true,
  message: React.createElement(Fmt, { id: intlId }),
});

export const maxLengthRule: (intlId: IntlMessageId, length?: number) => ValidationRule = (
  intlId,
  length = MAX_ITEM_NAME_LENGTH
) => ({
  max: length,
  message: React.createElement(Fmt, { id: intlId, values: { max: length } }),
});

export const duplicateNameRule: (
  intlId: IntlMessageId,
  reservedNames: string[],
  ignoreCaseSensitivity?: boolean
) => ValidationRule = (intlId, reservedNames, ignoreCaseSensitivity) => ({
  validator: (rule, value, callback) => {
    if (
      reservedNames?.some(
        (item) =>
          item
            ?.trim()
            .localeCompare(value?.trim(), undefined, { sensitivity: ignoreCaseSensitivity ? 'accent' : 'variant' }) ===
          0
      )
    ) {
      callback(React.createElement(Fmt, { id: intlId }));
    } else {
      callback();
    }
  },
});

export const duplicateNameRuleCallback = (
  validateUniqueName: (name: string) => boolean, // true === ok,
  intlId: IntlMessageId = 'forms.items.name.rules.nameExists'
): ValidationRule => ({
  validator: (rule, value, callback) => {
    if (validateUniqueName && !validateUniqueName(value?.trim())) {
      callback(React.createElement(Fmt, { id: intlId }));
    } else {
      callback();
    }
  },
});

export const includesDiacriticsRule: (intlId: IntlMessageId) => ValidationRule = (intlId) => ({
  validator: (rule, value, callback) => {
    if (
      (typeof value === 'string' || value instanceof String) &&
      Array.from(value).length !== Array.from(value.normalize('NFD')).length
    ) {
      callback(React.createElement(Fmt, { id: intlId }));
    } else {
      callback();
    }
  },
});

export const justAllowedDocumentStatesRule: (
  intlId: IntlMessageId,
  allowedDocumentStates: WorkflowStateEnum[]
) => ValidationRule = (intlId, allowedDocumentStates) => ({
  validator: (rule, value, callback) => {
    const formValue = value as DocumentSelectDocumentState[];
    if (formValue?.some((item) => !!allowedDocumentStates.length && !allowedDocumentStates.includes(item.state))) {
      callback(React.createElement(Fmt, { id: intlId }));
    } else {
      callback();
    }
  },
});
