import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import Label from 'components/Label';
import { useActiveProject } from 'hooks';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { RedirectOption, redirectWithOption } from 'utils';
import styles from './LabelDisplay.module.less';

export type Label = {
  id: Guid;
  // TODO: remove ? after swagger interfaces get fixed
  name?: string;
  description?: string;
  color?: string;
};

export enum LabelLinkType {
  None,
  Document,
  CommentProcedure,
  Workflow,
  WorkflowTemplate,
}

type Props = {
  label: Label;
  linkType?: LabelLinkType;
  className?: string;
};

const linkTypeToUrl = (linkType: LabelLinkType, activeProjectId: Guid): string => {
  if (!linkType) {
    return null;
  }
  // TODO: replace with the "projectPaths" hook
  switch (linkType) {
    case LabelLinkType.Document:
      return `/projects/${activeProjectId}/documents/filters`;
    case LabelLinkType.CommentProcedure:
      return `/projects/${activeProjectId}/comment`;
    case LabelLinkType.Workflow:
      return `/projects/${activeProjectId}/workflow`;
    case LabelLinkType.WorkflowTemplate:
      return `/projects/${activeProjectId}/workflow/templates`;
  }
};

const LabelDisplayComponent: FunctionComponent<Props> = ({ label, linkType, className }) => {
  const activeProjectId = useActiveProject()?.id;
  const dispatch = useDispatch<Dispatch>();

  const isLink = !!linkType;

  const handleRedirectClick = useCallback(() => {
    dispatch.persistentUi.setFilterToSearchByLabelId(label.id);

    const url = linkTypeToUrl(linkType, activeProjectId);
    redirectWithOption(url, RedirectOption.Push);
  }, [dispatch, linkType, label.id, activeProjectId]);

  return (
    <CommonHubTooltip
      title={
        <>
          <div>{label.name}</div>
          <div>{label.description}</div>
        </>
      }
    >
      <Label
        color={label.color}
        className={classNames(isLink && styles.link, className)}
        onClick={isLink && handleRedirectClick}
      >
        <DisplayName text={label.name} />
      </Label>
    </CommonHubTooltip>
  );
};

export const LabelDisplay = React.memo(LabelDisplayComponent);
