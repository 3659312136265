import { produce } from 'immer';
import { FilterStateStoreModel, FilterStateStoreModelState } from './storeModelinterfaces';

const filterStateStoreDefaults: FilterStateStoreModelState = {
  toolbarFiltersSets: {},
};

export const filterStateStoreModel: FilterStateStoreModel = {
  state: filterStateStoreDefaults,
  reducers: {
    /** Deleting filters on leave/enter project **/
    // 'activeProject/setActiveProject': (state, project) => {
    //   if (project === null) return filterStateStoreDefaults;
    //   return state;
    // },
    setFilter: (state, { itemKey, filterItem }) => {
      return produce<FilterStateStoreModelState, FilterStateStoreModelState, void>(state, (draft) => {
        draft.toolbarFiltersSets[itemKey] = filterItem;
      });
    },
  },
};
