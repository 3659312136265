import { QuestionCircleOutlined } from '@ant-design/icons';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import FilenameErrorMessageTooltip from 'components/FilenameErrorMessage/FilenameErrorMessage.Tooltip';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

const FilenameErrorMessage: FunctionComponent = () => {
  return (
    <>
      <Fmt id="FilenameErrorMessage.message" />
      {' / : * ? " < > | '}
      <CommonHubTooltip
        placement="right"
        title={
          <p>
            <FilenameErrorMessageTooltip />
          </p>
        }
      >
        <QuestionCircleOutlined />
      </CommonHubTooltip>
    </>
  );
};

export default FilenameErrorMessage;
