import { ProjectUserProfileListDto, RoleDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { UserAvatarSize, UserIcon } from '../UserIcon/UserIcon';
import styles from './MultiUserAvatar.module.less';
import classnames from 'classnames';

type Props = {
  size?: UserAvatarSize;
  user?: ProjectUserProfileListDto;
  documentOwner?: RoleDto;
  clickable?: boolean;
};

const UserAvatarWithOwnerComponent: FunctionComponent<Props> = ({
  size = UserAvatarSize.Medium,
  user,
  documentOwner,
  clickable,
}) => {
  return (
    <div className={classnames(styles.avatarContainer, clickable && styles.clickable)}>
      <UserIcon user={user} size={size} />
      {documentOwner && <span className={classNames(styles.hasOwner, documentOwner?.user && styles.withUser)} />}
    </div>
  );
};

export const UserAvatarWithOwner = React.memo(UserAvatarWithOwnerComponent);
