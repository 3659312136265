import { CheckCircleOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useActiveProject } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';

export type HeaderNotificationDropdownProps = { totalNewMessages: number; totalActiveAssignments: number };

export const NotificationMenu: FunctionComponent<HeaderNotificationDropdownProps> = ({
  totalNewMessages,
  totalActiveAssignments,
}) => {
  const history = useHistory();
  const location = useLocation();
  const projectId = useActiveProject()?.id;

  const handleMessagesClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/messages`
    );
  }, [projectId]);

  const handleTasksClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/assignments`
    );
  }, [projectId]);

  const handleSettingsClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/settings`
    );
  }, [projectId]);

  return (
    <Menu>
      <Menu.ItemGroup title={<Fmt id="MessageCenterPage.title" />} />
      <Menu.Item key="messages" onClick={handleMessagesClick} icon={<MailOutlined />}>
        <Fmt id="MessageCenterPage.notification.messages" />
        {totalNewMessages > 0 && <>{` (${totalNewMessages})`}</>}
      </Menu.Item>

      <Menu.Item key="tasks" onClick={handleTasksClick} icon={<CheckCircleOutlined />}>
        <Fmt id="MessageCenterPage.notification.tasks" />
        {totalActiveAssignments > 0 && <>{` (${totalActiveAssignments})`}</>}
      </Menu.Item>

      <Menu.Item
        key="settings"
        onClick={handleSettingsClick}
        icon={<SettingOutlined />}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Fmt id="MessageCenterPage.notification.settings" />
      </Menu.Item>
    </Menu>
  );
};
