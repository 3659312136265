import { api, createCancelToken } from 'api';
import Axios, { CancelTokenSource } from 'axios';
import { produce } from 'immer';
import { processApiError } from 'utils';
import { ActiveProjectStoreModel, ActiveProjectStoreModelState, SafeDispatch } from './storeModelinterfaces';

const CANCEL_TOKEN_REF = { current: undefined as CancelTokenSource };

const activeProjectStoreDefaults: ActiveProjectStoreModelState = {
  activeProject: null,
  activeProjectError: null,
  activeProjectLoading: false,
};

const clearAllProjectData = (dispatch: SafeDispatch) => {
  dispatch.allDocumentsPage.clearData();
  dispatch.esticonFirms.clearData();
  dispatch.esticonProjects.clearData();
  dispatch.esticonVat.clearData();
  dispatch.esticonDetail.clearData();
  dispatch.externalApplicationsSettings.clearData();
  dispatch.directories.clearData();
  dispatch.directoriesWithLinks.clearData();
  dispatch.labels.clearData();
  dispatch.roles.clearData();
  dispatch.groups.clearData();
  dispatch.currentProjectUser.clearData();
  dispatch.categories.clearData();
  dispatch.categoryTrees.setDefaults();
  dispatch.projectUsers.clearData();
  dispatch.workflows.clearData();
  dispatch.activeCalendar.clearData();
};

export const activeProjectStoreModel: ActiveProjectStoreModel = {
  state: activeProjectStoreDefaults,
  reducers: {
    setActiveProject: (state, project) =>
      produce(state, (draft) => {
        draft.activeProject = project;
        draft.activeProjectError = null;
        draft.activeProjectLoading = false;
      }),
    setActiveProjectError: (state, error) =>
      produce(state, (draft) => {
        draft.activeProject = null;
        draft.activeProjectError = error;
        draft.activeProjectLoading = false;
      }),
    setActiveProjectLoading: (state, loading) =>
      produce(state, (draft) => {
        draft.activeProjectLoading = loading;
      }),
  },
  effects: (dispatch) => ({
    async selectProject(projectId, rootState) {
      if (projectId === null) {
        const abandonedProjectId = rootState.activeProject.activeProject?.id;
        CANCEL_TOKEN_REF.current?.cancel('activeProjectStore: leaving project');
        clearAllProjectData(dispatch);
        dispatch.activeProject.setActiveProject(null);
        if (abandonedProjectId) {
          const [error] = await api.master.projects.notifyUserLeave(abandonedProjectId);

          if (error) {
            processApiError(error, dispatch.activeProject.setActiveProjectError);
          }
        }
        return;
      }

      CANCEL_TOKEN_REF.current?.cancel('activeProjectStore: new request was made');
      CANCEL_TOKEN_REF.current = createCancelToken();

      dispatch.activeProject.setActiveProjectLoading(true);
      const [err, response] = await api.master.projects.getProjectById(projectId);

      if (err) {
        if (!Axios.isCancel(err)) {
          processApiError(err, dispatch.activeProject.setActiveProjectError);
        }
        return;
      }

      clearAllProjectData(dispatch);
      dispatch.activeProject.setActiveProject(response.data);
    },
    async reloadProjects(projectIds, rootState) {
      const currentProjectId = rootState.activeProject.activeProject?.id;
      if (!!currentProjectId && projectIds.includes(currentProjectId)) {
        const [err, response] = await api.master.projects.getProjectById(currentProjectId);

        if (err) {
          if (!Axios.isCancel(err)) {
            processApiError(err, dispatch.activeProject.setActiveProjectError);
          }
        } else {
          dispatch.activeProject.setActiveProject(response.data);
        }
      }
    },
  }),
};
