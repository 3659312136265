import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './SimpleOrderId.module.less';

type Props = {
  className?: string;
  orderId?: ReactNode;
};

export const SimpleOrderId: FunctionComponent<Props> = ({ orderId }) => {
  return orderId ? <span className={classNames(styles.orderId)}>{orderId}</span> : null;
};
