import { Modal } from 'antd';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  reasonToOfferSuspending: React.ReactNode;
  onOk: () => void;
  onCancel: () => void;
};

const NoDeletableUserOfferSuspendingModal: FunctionComponent<Props> = ({ reasonToOfferSuspending, onOk, onCancel }) => {
  return (
    <Modal
      title={reasonToOfferSuspending}
      open={!!reasonToOfferSuspending}
      onOk={onOk}
      okText={<Fmt id={'UserDetailPanel.GeneralTab.suspendButton'} />}
      onCancel={onCancel}
    >
      <Fmt id={'UserDetailPanel.GeneralTab.suspendingIsPossible'} />
    </Modal>
  );
};

export default NoDeletableUserOfferSuspendingModal;
