// prettier-ignore

export const csMessages = {
  'AccessLevel.label': 'Úroveň přístupu',
  'AccessLevel.inheritedPermission': 'Zděděné',
  'AccessLevel.notSet': 'Nenastaveno',
  'AccessLevel.tooltips.admin': 'Admin',
  'AccessLevel.tooltips.none': 'Žádný',
  'AccessLevel.tooltips.read': 'Čtení',
  'AccessLevel.tooltips.write': 'Zápis',
  'AccessLevel.tooltips.admin.label': 'Neomezený přístup (admin)',
  'AccessLevel.tooltips.none.label': 'Žádný přístup',
  'AccessLevel.tooltips.read.label': 'Pouze ke čtení',
  'AccessLevel.tooltips.write.label': 'Zápis a čtení',
  'AccessLevel.tooltips.admin.description':
    'Plný přístup, vytváření dokumentů a složek, včetně změny složkové struktury a úpravy přístupových práv.',
  'AccessLevel.tooltips.none.description': 'Bez jakéhokoliv přístupu.',
  'AccessLevel.tooltips.read.description': 'Pouze prohlížení a stahování dokumentů.',
  'AccessLevel.tooltips.write.description': 'Prohlížení, stahování a nahrávání dokumentů.',
  'AccessTokenExpiredModal.detail': 'Zkuste se znovu přihlásit nebo kontaktujte podporu AspeHub.',
  'AccessTokenExpiredModal.error':
    'Během požadavku na přihlášení došlo k chybě. Zkuste to prosím znovu nebo obnovte stránku.',
  'AccessTokenExpiredModal.title': 'Vaše relace vypršela. Prosím přihlaste se.',
  'ActiveProjectGate.backToProjectList.ButtonText': 'Vrátit se na seznam projektů',
  'ActiveTaskDetail.addRequest': 'Přidat požadavek',
  'ActiveTaskDetail.backgroundDocuments': 'Podkladové dokumenty',
  'ActiveTaskDetail.comment': 'Komentář',
  'ActiveTaskDetail.parallelInitialization.button': 'Zvolit spouštěné úkoly',
  'ActiveTaskDetail.parallelInitialization.subworkflows': 'Paralelní úkoly',
  'ActiveTaskDetail.parallelInitialization.title': 'Výběr spouštěných úkolů',
  'ActiveTaskDetail.parallelInitialization.validation.error': 'Počet spouštěných větví musí být nejméně {value}',
  'ActiveTaskDetail.parallelOverview.label': 'Stav paralelních větví',
  'ActiveTaskDetail.parallelOverview.subworkflowState.initialized': 'Inicializováno',
  'ActiveTaskDetail.parallelOverview.subworkflowState.wait': 'Čeká na inicializaci',
  'ActiveTaskDetail.parallelOverview.subworkflowState.out': 'Vynecháno',
  'ActiveTaskDetail.parallelOverview.subworkflowState.finished': 'Dokončeno',
  'ActiveTaskDetail.voteLockedWarning': 'Došlo ke změně v podkladových dokumentech.',
  'ActiveTaskDetail.voteLockedError':
    'Došlo ke změně v podkladových dokumentech. Workflow bylo aktualizováno, prohlédněte si prosím změny než budete znovu hlasovat.',
  'AdminListPanel.addFormTitle': 'Přidat administrátora',
  'Administration.downloadDMSMetaData.buttonTitle': 'Stáhnout DMS včetně logů a metadat',
  'Administration.downloadDMSMetaDataModal.modalTitle': 'Stáhnutí DMS včetně logů a metadat',
  'Administration.downloadDMSMetaDataModal.formRadio.actualRevisions': 'Aktuální revize',
  'Administration.downloadDMSMetaDataModal.formRadio.allRevisions': 'Všechny revize',
  'Administration.downloadDMSMetaDataModal.submit.prepareDocuments': 'Připravit dokumenty',
  'Administration.downloadDMSMetaDataModal.submit.processing': 'Zpracovává se',
  'Administration.downloadDMSMetaDataModal.file.metadata': 'Metadata',
  'Administration.downloadDMSMetaDataModal.file.activity': 'Aktivita',
  'Administration.downloadDMSMetaDataModal.file.dms': 'DMS',
  'AdminPageStressTest.testName.label': 'Název testu',
  'AdminPageStressTest.testData.label': 'Data',
  'AdminPageStressTest.Run.button': 'Testuj',
  'AdminPageStressTest.Result.label': 'Výsledek',
  'NodePortActionTypeEnum.accept.button': 'Schválit',
  'NodePortActionTypeEnum.accept.confirm': 'Schválit tuto úlohu',
  'NodePortActionTypeEnum.accept.vote': 'Schváleno',
  'NodePortActionTypeEnum.refuse.button': 'Odmítnout',
  'NodePortActionTypeEnum.refuse.confirm': 'Odmítnout tuto úlohu',
  'NodePortActionTypeEnum.refuse.vote': 'Odmítnuto',
  'NodePortActionTypeEnum.process.button': '{action}',
  'NodePortActionTypeEnum.process.confirm': 'Zvolit {action}',
  'NodePortActionTypeEnum.process.vote': '{action}',
  'NodePortActionTypeEnum.parallel.button': 'Paralelní zpracování',
  'NodePortActionTypeEnum.parallel.confirm': 'Paralelní zpracování',
  'NodePortActionTypeEnum.parallel.vote': 'Paralelní zpracování',
  'NodePortActionTypeEnum.continue': 'Pokračovat',
  'ActiveTaskDetail.taskDescription': 'Popis úlohy',
  'ActiveTaskDetail.taskDescription.edit': 'Upravit popis úlohy',
  'ActiveTaskDetail.resolver': 'Řešitel',
  'ActiveTaskDetail.resolvers': 'Řešitelé',
  'ActiveTaskDetail.Resolver.delegate': 'Delegovat',
  'ActiveTaskDetail.Resolver.delegate.title': 'Delegovat roli',
  'ActiveTaskDetail.Resolver.delegate.newResolver': 'Nový řešitel',
  'ActiveTaskDetail.Resolver.delegate.noResolver': 'Vyberte nového řešitele',
  'ActiveTaskDetail.Resolver.delegate.message': 'Zpráva pro nového řešitele',
  'ActiveTaskDetail.resolver.noVote': 'Řešitel nehlasoval',
  'ActiveTaskDetail.resolver.noVote.yet': 'Řešitel zatím nehlasoval',
  'ActiveTaskDetail.schema': 'Schéma',
  'ActiveTaskDetail.taskName': 'Název úlohy',
  'ActiveTaskDetail.timeoutAction.title': 'Akce po vypršení termínu',
  'ActiveTaskDetail.timeoutAction.wait': 'Žádná akce',
  'ActiveTaskDetail.timeoutAction.selectDefault': 'Použít konkrétní výstup',
  'ActiveTaskDetail.timeoutAction.output': 'Výstup',
  'ActiveTaskDetail.timeoutAction.closeWorkflow': 'Ukončit instanci workflow',
  'ActiveTaskDetail.timeoutAction.evalLateAsAccept': 'Nehlasoval ⇒ schvaluje',
  'ActiveTaskDetail.timeoutAction.evalLateAsRefuse': 'Nehlasoval ⇒ zamítá',
  'ActiveTaskDetail.timeoutAction.tooltip': 'Touto volbou lze stanovit, že řešitelé, kteří v termínu nehlasovali, předmětnou úlohu schválili/zamítli.',
  'ActiveTaskDetail.timeoutAction.accordingToVote': 'Podle hlasování',
  'ActiveTaskDetail.term': 'Termín',
  'ActiveTaskDetail.term.title': 'Termín: {date}',
  'ActiveTaskDetail.term.maxExpectedTime': '(maximální původní: {maxExpectedDate})',
  'ActiveTaskDetail.term.maxExpectedTime.notDetermined': 'Nestanoveno',
  'ActiveTaskDetail.workflowState.started': 'Aktivní',
  'ActiveTaskDetail.workflowState.finished': 'Dokončeno',
  'ActiveTaskDetail.workflowState.criticalError': 'Závažná chyba',
  'ActiveTaskDetail.workflowState.error': 'Chyba',
  'ActiveTaskDetail.workflowState.warning': 'Varování',
  'ActiveTaskDetail.workflowState.closed': 'Zrušeno',
  'ActiveTaskDetail.workflowNodeState': 'Stav',
  'ActiveTaskDetail.workflowNodeState.waiting': 'Čeká',
  'ActiveTaskDetail.workflowNodeState.current': 'Aktivní',
  'ActiveTaskDetail.workflowNodeState.out': 'Nepoužitý/Nedostupný',
  'ActiveTaskDetail.workflowNodeState.processed': 'Zpracováno',
  'ActiveTaskDetail.workflowNodeState.accepted': 'Přijato',
  'ActiveTaskDetail.workflowNodeState.refused': 'Odmítnuto',
  'ActiveTaskDetail.workflowNodeState.over': 'Vrácený',
  'ActiveTaskDetail.workflowNodeState.init': 'Inicializace',
  'ActiveTaskDetail.workflowNodeState.outOver': 'Nepoužitý, dříve zpracovaný',
  'ActiveTaskDetail.workflowNodeState.closed': 'Uzavřeno',
  'ActiveTaskDetail.workflowNodeState.closedIn': 'Uzavřeno při inicializaci',
  'ActiveTaskDetail.workflowNodeStateTitle': '{date}',
  'ActiveTaskDetail.notification.title': 'Data upozornění',
  'ActiveTaskDetail.notification.recipients': 'Adresáti',
  'ActiveTaskDetail.notification.subject': 'Předmět zprávy',
  'ActiveTaskDetail.notification.message': 'Text zprávy',
  'ActiveTaskDetail.notification.noData': 'Žádná data upozornění',
  'AddedRevisions.title': 'Změna schvalovaných dokumentů',
  'AddedRevisions.addDocuments.title': 'Přidat dokumenty',
  'AddedRevisions.buttons.selectDocuments': 'Vybrat z dostupných',
  'AddedRevisions.buttons.uploadDocument': 'Nahrát nový',
  'AddedRevisions.filter.signature': 'Podpis',
  'AddedRevisions.filter.comment': 'Komentář',
  'AddedRevisions.message.documentsAssigned': 'Dokumenty byly přidány do WF',
  'AddedRevisions.message.documentsAdded': 'Dokument byl nahrán a přidán do WF',
  'AddedRevisions.message.documentsAddedError': 'Dokumenty se nepodařilo přidat do WF.',
  'AdditionalInformation.constructionBillingData': 'Fakturační údaje stavby',
  'AdditionalInformation.contractorContract': 'Smlouva zhotovitel',
  'AdditionalInformation.CustomerContract': 'Smlouva objednatel',
  'AdditionalInformation.district': 'Okres',
  'AdditionalInformation.firm': 'Firma',
  'AdditionalInformation.forTheContractor': 'Za zhotovitele',
  'AdditionalInformation.forTheCustomer': 'Za objednatele',
  'AdditionalInformation.function': 'Funkce',
  'AdditionalInformation.functionDescription': 'Popis funkce',
  'AdditionalInformation.name': 'Jméno',
  'AdditionalInformation.placeOfConstruction': 'Místo stavby',
  'AdditionalInformation.placeOfInvoicing': 'Fakturační místo',
  'AdditionalInformation.wipItems': 'Položky čerpání',
  'AdditionalInformation.thirdPartySignatures': 'Třetí podpisy',
  'AdditionalInformation.where': 'Kde',
  'AdditionalInformation.wipReport': 'Zjišťovací protokol',
  'AllDocumentPage.deletedTargetDirectory.error': 'Složka <strong>{name}</strong> byla vyřazena.',
  'AllDocumentPage.deletedTargetDirectory.lastPath': 'Původní umístění: {path}',
  'AllDocumentsPage.forbiddenFilesWarning': 'Nelze stáhnout všechny dokumenty',
  'AllDocumentsPage.downloadOtherFiles': 'Stáhnout ostatní',
  'AllDocumentsPage.docMenu.annotate': 'Komentovat',
  'AllDocumentsPage.docMenu.closeSettings': 'Zavřít nastavení',
  'AllDocumentsPage.docMenu.createDirectory': 'Nová složka',
  'AllDocumentsPage.docMenu.createAssignment': 'Nový úkol',
  'AllDocumentsPage.docMenu.discard': 'Vyřadit',
  'AllDocumentsPage.docMenu.delete': 'Smazat',
  'AllDocumentsPage.docMenu.displaySubfolders': 'Zobrazení podsložek',
  'AllDocumentsPage.docMenu.documentEditor': 'Upravit dokument',
  'AllDocumentsPage.docMenu.editOnline': 'Upravit',
  'AllDocumentsPage.docMenu.expandAll': 'Rozbalit vše',
  'AllDocumentsPage.docMenu.collapseAll': 'Skrýt vše',
  'AllDocumentsPage.docMenu.openSettings': 'Nastavení',
  'AllDocumentsPage.docMenu.signDocument': 'Podepsat',
  'AllDocumentsPage.docMenu.tooltip': 'Možnosti složky',
  'AllDocumentsPage.docMenu.upload': 'Nahrát',
  'AllDocumentsPage.docMenu.uploadDocument': 'Nový dokument',
  'AllDocumentsPage.docMenu.uploadMultiple': 'Hromadné nahrání',
  'AllDocumentsPage.favoriteFilterActive': 'Zobrazeny pouze oblíbené složky.',
  'AllDocumentsPage.searchFilterActive': `Vyhledávání "{text}" v názvu složky.`,
  'AllDocumentsPage.docMenu.discard.disabled.byRights': 'Nemáte dostatečné oprávnění pro vyřazení složky',
  'AllDocumentsPage.docMenu.discard.disabled.byEsticon':
    'Složka nebo její podsložka je rezervována pro import dokumentů z AspeEsticon',
  'AllDocumentsPage.directory.report.button.tooltip': 'Soupis dokumentů',
  'AllDocumentsPage.directory.report.modalTitle': 'Soupis dokumentů',
  'AllDocumentsPage.directory.report.modalOkText': 'Exportovat',
  'AllDocumentsPage.directory.report.form.authorOrder': 'Dle autora revize v období',
  'AllDocumentsPage.directory.report.form.createTimeOrder': 'Dle datumu a času nahrání revize',
  'AllDocumentsPage.directory.report.form.documentNameOrder': 'Dle názvu dokumentu',
  'AllDocumentsPage.directory.report.form.documentPathOrder': 'Dle cesty k dokumentu',
  'AllDocumentsPage.directory.report.form.authorFilterTitle': 'Autor revize',
  'AllDocumentsPage.directory.report.form.revisionChangeDateTitle': 'Datum změny revize',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeSubdirectories': 'Zahrnout podsložky',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeAllRevisions': 'Zahrnout všechny revize',
  'AllDocumentsPage.directory.report.form.clearDatepickers': 'Vymazat',
  'AllDocumentsPage.downloadErrorsModal.documents': 'Dokumenty:',
  'AllDocumentsPageFileToolbar.favoriteChanged.success': 'Změna nastavení oblíbených proběhla úspěšně',
  'AllDocumentsPageFileToolbar.favoriteChanged.successParcial': 'U některých dokumentů nebyl nastaven stav oblíbený',
  'AllDocumentsPageFileToolbar.favoriteChanged.failure': 'U žádného z dokumentů nebyl nastaven stav oblíbený',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.NotFound': 'Soubor nenalezen',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.IsDiscarded': 'Soubor {fileName} byl odebrán',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.BadDirectory': 'Soubor {fileName} již není v adresáři',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.AccessDenied': 'Pro změnu atributu souboru {fileName} nemáte dostatečná oprávnění',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertySet': 'Soubor {fileName} byl již přidán do oblíbených',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertyUnset': 'Soubor {fileName} byl již odebrán z oblíbených',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound': 'Odkazovaný dokument nenalezen',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentIsDiscarded': 'Odkaz {linkName} vede na odebraný odkazovaný soubor',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.LinkAccessDenied': 'Nemáte dostatečná oprávnění k odkazu {linkName}',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentAccessDenied': 'Nemáte dostatečná oprávnění k dokumentu odkazovanému odkazem {linkName}',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertySet': 'Dokument odkazovaný odkazem {linkName} byl již přidán do oblíbených',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertyUnset': 'Dokument odkazovaný odkazem {linkName} byl již odebrán z oblíbených',
  'AllDocumentsPageDirectoryToolbar.directoryChoice': 'Složku',
  'AllDocumentsPageDirectoryToolbar.directoryLinkChoice': 'Odkaz na složku',
  'AllDocumentsPageDirectoryTree.NoMatchingDirs': 'Žádné odpovídající složky.',
  'ApprovalDocumentRevisionInfo.cannotChangeEsticonDocument': 'Dokument je importovaný z esticonu a nelze jej měnit.',
  'ApprovalDocumentRevisionInfo.noRevisionAdded': 'Revize nebyla změněna.',
  'ApprovalDocumentRevisionInfo.addRevision': 'Nahrát novou revizi',
  'ApprovalDocumentRevisionInfo.documentPreview': 'Náhled dokumentu',
  'ApprovalDocumentRevisionInfo.removeDocumentFromWf': 'Odebrat z WF',
  'AppUsersAddFormModal.title': 'Přidat administrátory AspeHub',
  'AppUsersFormItem.addCurrentUsers.aspeHub': 'Přidat z AspeHub',
  'AppUsersFormItem.addCurrentUsers.organization': 'Přidat z organizace',
  'AppUsersFormItem.addCurrentUsers.disable.aspeHub': 'Seznam dostupných uživatelů AspeHub je prázdný.',
  'AppUsersFormItem.addCurrentUsers.disable.organization': 'Všichni uživatelé organizace jsou již na projektu. Seznam dostupných uživatelů organizace je prázdný.',
  'AppUsersFormItem.changeCurrentUsers': 'Změnit seznam stávajících uživatelů',
  'AppUsersFormItem.removeCurrentUsers': 'Odstranit seznam stávajících uživatelů',
  'AppUsersFormItem.selectCurrentUsers': 'Vyber stávající uživatele',
  'AppUsersFormItem.addNewUsers': 'Přidat nové',
  'AppUsersFormItem.changeNewUsers': 'Změnit seznam nových uživatelů',
  'AppUsersFormItem.removeNewUsers': 'Odstranit seznam nových uživatelů',
  'AspeHubUsersListPanel.addModal.title': 'Přidat uživatele',
  'AppUserShareFormModal.form.selectGroup': 'Označit uživatele ze skupiny',
  'AppUserShareFormModal.form.users': 'Uživatelé pro sdílení',
  'AppUserShareFormModal.send': 'Odeslat',
  'AppUserShareFormModal.form.items.message.initialValue.document':
    'Zdravím! Rád bych Vám nasdílel přiložený dokument.',
  'AppUserShareFormModal.form.items.message.initialValue.folder': 'Zdravím! Rád bych Vám nasdílel přiloženou složku.',
  'AppUserShareFormModal.form.items.message.label': 'Zpráva pro příjemce',
  'AppUserShareFormModal.title.document': 'Sdílení dokumentu',
  'AppUserShareFormModal.title.directory': 'Sdílení složky',
  'AssignmentDocumentsField.documentIsAlreadySelected': 'Dokument je již vybraný',
  'AssignmentDocumentsField.unableToAddDocument.content':
    'Dokument byl úspěšně nahrán, ale nepodařilo se ho přiřadit k úkolu. ' +
    'Můžete ho zkusit přidat jako existující dokument.',
  'AssignmentDocumentsField.addExistingDocument': 'Přidat existující dokument',
  'AssignmentDocumentsField.uploadNewDocument': 'Nahrát nový dokument',
  'AssignmentDocumentsField.removeDocument': 'Odebrat dokument',
  'AssignmentDocumentsField.selectDocuments': 'Vybrat dokumenty',
  'CommonExportFormModal.title.assignments': 'Export seznamu úkolů',
  'CommonExportFormModal.title.projects': 'Export seznamu projektů',
  'CommonExportFormModal.title.selectFormat': 'Vyberte formát souboru',
  'CommonExportFormModal.columnName.name': 'Název',
  'CommonExportFormModal.columnName.project': 'Projekt',
  'CommonExportFormModal.columnName.type': 'Typ',
  'CommonExportFormModal.columnName.state': 'Stav',
  'CommonExportFormModal.columnName.taskGiver': 'Zadavatel',
  'CommonExportFormModal.columnName.representative': 'Zástupce zadavatele (seznam zástupců)',
  'CommonExportFormModal.columnName.createDate': 'Datum vytvoření',
  'CommonExportFormModal.columnName.deadline': 'Termín (požadovaný termín)',
  'CommonExportFormModal.columnName.resolvedDate': 'Datum splnění',
  'CommonExportFormModal.columnName.content': 'Obsah',
  'CommonExportFormModal.columnName.resolvers': 'Řešitelé (seznam řešitelů)',
  'CommonExportFormModal.columnName.projectName': 'Název projektu',
  'CommonExportFormModal.columnName.description': 'Popis',
  'CommonExportFormModal.columnName.organization': 'Organizace',
  'CommonExportFormModal.columnName.labels': 'Štítky',
  'AssignmentForm.error.currentUserRequiredInAssociates': 'Aktuální uživatel je povinný v zástupcích zadavatele, pokud je zadavatelem někdo jiný',
  'AssignmentForm.error.userDuplicateInAssociates': 'Stejný uživatel nemůže být současně jako zadavatel i zástupce zadavatele',
  'AssignmentForm.deadlineDate': 'Požadované datum splnění',
  'AssignmentForm.content': 'Obsah',
  'AssignmentForm.bcfDetail': 'BCF detail',
  'AssignmentForm.solverIds': 'Řešitelé',
  'AssignmentForm.assignmentDocuments': 'Dokumenty',
  'AssignmentForm.taskGiver': 'Zadavatel',
  'AssignmentForm.associateIds': 'Zástupci zadavatele',
  'AssignmentForm.name': 'Název úkolu',
  'AssignmentForm.name.required': 'Vyplňte prosím název úkolu',
  'AssignmentForm.type': 'Typ úkolu',
  'AssignmentFormModal.projectSelect': 'Vyberte prosím projekt, ve kterém chcete úkol vytvořit',
  'AssignmentFormModal.projectSelect.title': 'Výběr projektu',
  'AssignmentFormModal.result.success': 'Úkol úspěšně založen',
  'AssignmentFormModal.title': 'Vytvořit nový úkol',
  'AssignmentOverview.created': 'Vytvořeno',
  'AssignmentOverview.projectNotFound': 'Project nenalezen',
  'AssignmentProjectCheck.youMustSwitchProject':
    'Pro práci s tímto úkolem se musíte přepnout do projektu, ve kterém je úkol zadaný.',
  'AssignmentProjectCheck.switchProject': 'Přepnout do projektu',
  'AssignmentProjectCheck.targetProject': 'Cílový projekt',
  'AssignmentStateEnum.new': 'Nový',
  'AssignmentStateEnum.inProgress': 'Řeší se',
  'AssignmentStateEnum.forApproval': 'Ke schválení',
  'AssignmentStateEnum.solved': 'Dokončený',
  'AssignmentStateEnum.closed': 'Zrušený',
  'AssignmentStateField.startSolving': 'Začít zpracovávat',
  'AssignmentStateField.submitForApproval': 'Předat ke schválení',
  'AssignmentStateField.returnToSolver': 'Vrátit k přepracování',
  'AssignmentStateField.approveAndClose': 'Schválit a uzavřít',
  'AssignmentStateField.continueSolving': 'Pokračovat v řešení',
  'AssignmentStateField.cancel': 'Zrušit úkol',
  'AssignmentSolversField.addAssociate': 'Přidat zástupce',
  'AssignmentSolversField.addSolvers': 'Přidat řešitele',
  'AssignmentSolversField.removeAssociate': 'Odebrat zástupce',
  'AssignmentSolversField.removeSolver': 'Odebrat řešitele',
  'AssignmentSolversField.cannotRemoveAssociate': 'Tohoto zástupce zadavatele nelze odebrat',
  'AssignmentSolversField.cannotRemoveLastSolver': 'Nelze odebrat posledního řešitele',
  'AssignmentSolversField.cannotRemovePrimarySolver': 'Nelze odebrat primárního řešitele',
  'AssignmentSolversField.primarySolver': 'Primární řešitel',
  'AssignmentSolversField.lostAccessWarning': 'Odebrali jste se z řešitelů a proto již k úkolu nemáte přístup',
  'AssignmentSolversField.makePrimary': 'Nastavit primárního řešitele',
  'AssignmentSourceEnum.createdByUser': 'Vytvořeno uživatelem',
  'AssignmentSourceEnum.assignedToUser': 'Řešitel',
  'AssignmentSourceEnum.primarySolver': 'Primární řešitel',
  'AssignmentSourceEnum.associate': 'Zástupce řešitele',
  'AssignmentSourceEnum.taskGiver': 'Zadavatel',
  'AssignmentTypeEnum.userTask': 'Uživatelská úloha',
  'AssignmentTypeEnum.userDirectoryPermission': 'Nastavení oprávnění složek',
  'AssignmentTypeEnum.uploadDocuments': 'Nahrání dokumentů',
  'AssignmentTypeEnum.comment': 'Připomínka',
  'AssignmentTypeEnum.defectSolving': 'Řešení vad a nedodělků',
  'AssignmentTypeEnum.bcfTask': 'Úkol na modelu',
  'AttachmentCreateFormModal.title': 'Nová příloha',
  'AttachmentCreateFormModal.stagedUpload.title': 'Nahrávání příloh',
  'AttachmentCreateFormModal.form.item.relation.label': 'Viditelnost',
  'AuditLogBuildingModal.categories': 'Kategorie stavby',
  'AuditLog.assignment.label': `Aktivity na úkolu '{name}'`,
  'AuditLog.directory.label': `Aktivity na složce '{path}'`,
  'AuditLog.detail.title': 'Detail',
  'AuditLog.document.label': `Aktivity na dokumentu '{path}'`,
  'AuditLog.user.label': `Aktivity na uživateli '{username}'`,
  'AuditLog.wfTemplate.label': `Aktivity na workflow šabloně '{wfTemplateName}'`,
  'AuditLog.EntityData.Name': 'Název',
  'AuditLog.EntityData.Version': 'Verze',
  'AuditLog.EntityData.Description': 'Popis',
  'AuditLog.EntityData.DownloadCounter': 'Počet stažení',
  'AuditLog.EntityData.ElementId': 'ID elementu',
  'AuditLog.EntityData.AttributeName': 'Název atributu',
  'AuditLog.EntityData.true': 'ano',
  'AuditLog.EntityData.false': 'ne',
  'AuditLog.EntityData.unset': 'nenastaveno',
  'AuditLog.EntityData.Path': 'Cesta',
  'AuditLog.EntityData.PermissionInheritance': 'Zdědit oprávnění',
  'AuditLog.EntityData.Username': 'Název uživatele',
  'AuditLog.EntityData.Firstname': 'Jméno',
  'AuditLog.EntityData.Lastname': 'Příjmení',
  'AuditLog.EntityData.IsModel': 'Dokument je modelem',
  'AuditLog.EntityData.State': 'Stav dokumentu',
  'AuditLog.EntityData.InstanceName': 'Název workflow',
  'AuditLog.EntityData.InstanceDescription': 'Popis workflow',
  'AuditLog.EntityData.Content': 'Obsah komentáře',
  'AuditLog.EntityData.ReservedById': 'Rezervováno přes id',
  'AuditLog.EntityData.ReservedDate': 'Datum rezervace',
  'AuditLog.EntityType.assignment': 'Úkol',
  'AuditLog.EntityType.commentProcedure': 'Připomínkové řízení',
  'AuditLog.EntityType.directory': 'Složka',
  'AuditLog.EntityType.document': 'Dokument',
  'AuditLog.EntityType.label': 'Štítek',
  'AuditLog.EntityType.project': 'Projekt',
  'AuditLog.EntityType.projectUserProfile': 'Uživatel projektu',
  'AuditLog.EntityType.projectNote': 'Poznámka projektu',
  'AuditLog.EntityType.projectSetting': 'Nastavení projektu',
  'AuditLog.EntityType.group': 'Skupina',
  'AuditLog.EntityType.documentCategoryNode': 'Kategorie',
  'AuditLog.EntityType.documentCategoryTree': 'Kategorie',
  'AuditLog.EntityType.esticonToDocumentLink': 'Vazba na Esticon dokument',
  'AuditLog.EntityType.role': 'Role',
  'AuditLog.EntityType.head': 'Souhrnná',
  'AuditLog.EntityType.link': 'Odkaz dokumentu',
  'AuditLog.EntityType.shared': 'Sdílení dokumentu',
  'AuditLog.EntityType.linkedModelElement': 'Odkaz na model',
  'AuditLog.EntityType.workflow': 'Workflow',
  'AuditLog.EntityType.workflowTemplate': 'Šablona workflow',
  'AuditLog.EntityType.documentAnnotation': 'Poznámky v dokumentu',
  'AuditLog.EntityType.organization': 'Organizace',
  'AuditLog.EntityType.extendedPermissionTemplate': 'Šablona oprávnění',
  'AuditLog.EntityType.projectTemplate': 'Šablona projektu',
  'AuditLog.EntityType.orgLabel': 'Štítek organizace',
  'AuditLog.EntityTypeParam.assignment': 'Parametry úkolu',
  'AuditLog.EntityTypeParam.commentProcedure': 'Parametry připomínkového řízení',
  'AuditLog.EntityTypeParam.directory': 'Parametry složky',
  'AuditLog.EntityTypeParam.document': 'Parametry dokumentu',
  'AuditLog.EntityTypeParam.label': 'Parametry štítku',
  'AuditLog.EntityTypeParam.project': 'Parametry projektu',
  'AuditLog.EntityTypeParam.projectUserProfile': 'Parametry uživatele projektu',
  'AuditLog.EntityTypeParam.projectNote': 'Poznámka projektu',
  'AuditLog.EntityTypeParam.projectSetting': 'Nastavení projektu',
  'AuditLog.EntityTypeParam.group': 'Parametry skupiny',
  'AuditLog.EntityTypeParam.documentCategoryNode': 'Parametry uzlu kategorie',
  'AuditLog.EntityTypeParam.documentCategoryTree': 'Parametry kategorie',
  'AuditLog.EntityTypeParam.esticonToDocumentLink': 'Esticon dokument',
  'AuditLog.EntityTypeParam.role': 'Parametry role',
  'AuditLog.EntityTypeParam.head': 'Souhrnné parametry',
  'AuditLog.EntityTypeParam.link': 'Parametry odkazu dokumentu',
  'AuditLog.EntityTypeParam.shared': 'Parametry sdílení dokumentu',
  'AuditLog.EntityTypeParam.linkedModelElement': 'Parametry odkazu na model',
  'AuditLog.EntityTypeParam.workflow': 'Parametry workflow',
  'AuditLog.EntityTypeParam.workflowTemplate': 'Parametry šablony workflow',
  'AuditLog.EntityTypeParam.documentAnnotation': 'Komentáře v dokumentu',
  'AuditLog.EntityTypeParam.organization': 'Organizace',
  'AuditLog.EntityTypeParam.extendedPermissionTemplate': 'Šablona oprávnění',
  'AuditLog.EntityTypeParam.projectTemplate': 'Šablona projektu',
  'AuditLog.EntityTypeParam.orgLabel': 'Štítek organizace',
  'AuditLog.EventData.accessLevel': 'Úroveň přístupu',
  'AuditLog.EventData.addedExtendedPermissions': 'Přidaná rozšířená oprávnění',
  'AuditLog.EventData.addedLabels': 'Přidané štítky',
  'AuditLog.EventData.annotationContent': 'Obsah komentáře',
  'AuditLog.EventData.approvalDate': 'Datum schválení',
  'AuditLog.EventData.assignmentName': 'Název úkolu',
  'AuditLog.EventData.assignmentOrderId': 'ID úkolu',
  'AuditLog.EventData.assignmentState': 'Stav úkolu',
  'AuditLog.EventData.attachmentName': 'Název přílohy',
  'AuditLog.EventData.backgroundDocumentDescription': 'Popis požadavku',
  'AuditLog.EventData.backgroundDocumentName': 'Název požadavku',
  'AuditLog.EventData.bulkActionType': 'Typ hromadné změny',
  'AuditLog.EventData.category': 'Kategorie',
  'AuditLog.EventData.categoryDescription': 'Popis kategorie',
  'AuditLog.EventData.categoryName': 'Název kategorie',
  'AuditLog.EventData.commentProcedureName': 'Název připomínkového řízení',
  'AuditLog.EventData.commentProcedureRole': 'Role v připomínkovém řízení',
  'AuditLog.EventData.commentsCommentAddition': 'Reakce na připomínku',
  'AuditLog.EventData.commentsCommentCategory': 'Kategorie připomínky',
  'AuditLog.EventData.commentsCommentMsg': 'Text připomínky',
  'AuditLog.EventData.commentsCommentName': 'Název připomínky',
  'AuditLog.EventData.commentsCommentOrderId': 'Id připomínky',
  'AuditLog.EventData.commentsCommentProcessors': 'Tým zpracovatelů',
  'AuditLog.EventData.commentsCommentState': 'Stav připomínky',
  'AuditLog.EventData.commentsCommentStateOld': 'Předchozí stav připomínky',
  'AuditLog.EventData.commentsCommentSubmitters': 'Tým připomínkujících',
  'AuditLog.EventData.commentsName': 'Název připomínkového řízení',
  'AuditLog.EventData.commentsOrderId': 'Id připomínkového řízení',
  'AuditLog.EventData.commentsState': 'Stav připomínkového řízení',
  'AuditLog.EventData.content': 'Obsah',
  'AuditLog.EventData.dailyCount': 'Počet za den',
  'AuditLog.EventData.dailyLimitExceeded': 'Denní limit překročen',
  'AuditLog.EventData.deadlineDate': 'Termín',
  'AuditLog.EventData.deletedUser': 'Odebraný uživatel',
  'AuditLog.EventData.description': 'Popis',
  'AuditLog.EventData.directoryPath': 'Složka',
  'AuditLog.EventData.documentName': 'Název dokumentu',
  'AuditLog.EventData.documentState': 'Stav dokumentu',
  'AuditLog.EventData.draftTemplateDescription': 'Popis rozpracované šablony',
  'AuditLog.EventData.draftTemplateName': 'Název rozpracované šablony',
  'AuditLog.EventData.email': 'Email',
  'AuditLog.EventData.esticonId': 'Esticon ID',
  'AuditLog.EventData.externalShare': 'Sdílení mimo hub',
  'AuditLog.EventData.false': 'ne',
  'AuditLog.EventData.fileName': 'Název souboru',
  'AuditLog.EventData.fromDate': 'Od data',
  'AuditLog.EventData.groupName': 'Název skupiny',
  'AuditLog.EventData.userStatus': 'Stav uživatele',
  'AuditLog.EventData.oldUserStatus': 'Předchozí stav uživatele',
  'AuditLog.EventData.hourlyCount': 'Počet za hodinu',
  'AuditLog.EventData.hourlyLimitExceeded': 'Hodinový limit překročen',
  'AuditLog.EventData.includeAllRevisions': 'Zahrnuje všechny revize',
  'AuditLog.EventData.includeSubdirectories': 'Zahrnuje podadresáře',
  'AuditLog.EventData.invitationMessage': 'Pozvánková zpráva',
  'AuditLog.EventData.ipAddress': 'IP adresa uživatele',
  'AuditLog.EventData.isImport': 'Je import',
  'AuditLog.EventData.labelColor': 'Barva popisku',
  'AuditLog.EventData.labelName': 'Název popisku',
  'AuditLog.EventData.linkFrom': 'Vazba z dokumentu',
  'AuditLog.EventData.linkName': 'Název odkazu dokumentu',
  'AuditLog.EventData.linkTo': 'Vazba na dokument',
  'AuditLog.EventData.managerName': 'Správce dokumentu',
  'AuditLog.EventData.method': 'Metoda',
  'AuditLog.EventData.moveFrom': 'Přesunuto z',
  'AuditLog.EventData.moveTo': 'Přesunuto do',
  'AuditLog.EventData.name': 'Název',
  'AuditLog.EventData.newCategoryDescription': 'Nový popis kategorie',
  'AuditLog.EventData.newCategoryName': 'Nový název kategorie',
  'AuditLog.EventData.newDocument': 'Nový dokument',
  'AuditLog.EventData.newLabels': 'Nové štítky',
  'AuditLog.EventData.newNodeName': 'Nový název úlohy',
  'AuditLog.EventData.newRoleName': 'Nový název role',
  'AuditLog.EventData.newStateChangePermission': 'Nové oprávnění na změnu stavu připomínky',
  'AuditLog.EventData.newTeamName': 'Nový název týmu',
  'AuditLog.EventData.newUserPermission': 'Nové oprávnění uživatele',
  'AuditLog.EventData.nodeName': 'Název uzlu',
  'AuditLog.EventData.noteMessage': 'Komentář',
  'AuditLog.EventData.noteOldMessage': 'Starý komentář',
  'AuditLog.EventData.noteTitle': 'Nadpis poznámky',
  'AuditLog.EventData.old': 'Stará hodnota',
  'AuditLog.EventData.oldAssignmentState': 'Předchozí stav úkolu',
  'AuditLog.EventData.oldCategoryDescription': 'Předchozí popis kategorie',
  'AuditLog.EventData.oldCategoryName': 'Předchozí název kategorie',
  'AuditLog.EventData.oldCommentsCommentAddition': 'Předchozí reakce na připomínku',
  'AuditLog.EventData.oldCommentsCommentCategory': 'Předchozí kategorie připomínky',
  'AuditLog.EventData.oldCommentsCommentMsg': 'Previous text připomínky',
  'AuditLog.EventData.oldCommentsCommentName': 'Předchozí název připomínky',
  'AuditLog.EventData.oldCommentsCommentProcessors': 'Předchozí tým zpracovatelů',
  'AuditLog.EventData.oldCommentsCommentSubmitters': 'Předchozí tým připomínkujících',
  'AuditLog.EventData.oldCommentsState': 'Předchozí stav připomínkového řízení',
  'AuditLog.EventData.oldDocumentState': 'Předchozí stav dokumentu',
  'AuditLog.EventData.oldLinkName': 'Starý název odkazu',
  'AuditLog.EventData.oldNodeName': 'Starý název úlohy',
  'AuditLog.EventData.oldPrimarySolver': 'Předchozí primární řešitel',
  'AuditLog.EventData.oldRoleName': 'Stará název role',
  'AuditLog.EventData.oldStateChangePermission': 'Předchozí oprávnění na změnu stavu připomínky',
  'AuditLog.EventData.oldTeamName': 'Předchozí název týmu',
  'AuditLog.EventData.oldUserPermission': 'Předchozí oprávnění uživatele',
  'AuditLog.EventData.orderId': 'ID',
  'AuditLog.EventData.origNodeName': 'Původní jméno uzlu',
  'AuditLog.EventData.origRoleName': 'Původní název role',
  'AuditLog.EventData.path': 'Cesta',
  'AuditLog.EventData.primarySolver': 'Primární řešitel',
  'AuditLog.EventData.reason': 'Důvod',
  'AuditLog.EventData.receiver': 'Příjemce',
  'AuditLog.EventData.removedExtendedPermissions': 'Odebrána rozšířená oprávnění',
  'AuditLog.EventData.removedLabels': 'Odebrané štítky',
  'AuditLog.EventData.removedNodes': 'Odebrané úlohy',
  'AuditLog.EventData.requestAddress': 'Adresa požadavku',
  'AuditLog.EventData.requestedState': 'Požadovaný stav',
  'AuditLog.EventData.reservedById': 'Rezervováno přes id',
  'AuditLog.EventData.reservedByName': 'Rezervováno přes název',
  'AuditLog.EventData.reservedDate': 'Datum rezervace',
  'AuditLog.EventData.resultOk': 'Proběhlo korektně',
  'AuditLog.EventData.revisionDescription': 'Popis revize',
  'AuditLog.EventData.revisionNumber': 'Číslo revize',
  'AuditLog.EventData.secondRevisionNumber': 'Číslo revize k porovnání',
  'AuditLog.EventData.secondRevisionDescription': 'Popis revize k porovnání',
  'AuditLog.EventData.roleName': 'Název role',
  'AuditLog.EventData.secDocError': 'Chyba sekundárního dokumentu',
  'AuditLog.EventData.secondaryDocumentName': 'Název přílohy',
  'AuditLog.EventData.secundaryDocumentType': 'Typ sekundárního dokumentu',
  'AuditLog.EventData.state': 'Stav',
  'AuditLog.EventData.teamName': 'Název týmu',
  'AuditLog.EventData.teamRole': 'Role týmu',
  'AuditLog.EventData.templateDescription': 'Popis šablony',
  'AuditLog.EventData.templateName': 'Název šablony',
  'AuditLog.EventData.toDate': 'Do data',
  'AuditLog.EventData.true': 'ano',
  'AuditLog.EventData.unset': 'nenastaveno',
  'AuditLog.EventData.type': 'Typ',
  'AuditLog.EventData.userAgent': 'Uživatelský agent',
  'AuditLog.EventData.userId': 'Id uživatele',
  'AuditLog.EventData.username': 'Jméno uživatele',
  'AuditLog.EventData.userName': 'Jméno uživatele',
  'AuditLog.EventData.userPermission': 'Oprávnění uživatele',
  'AuditLog.EventData.version': 'Verze',
  'AuditLog.EventData.voteMessage': 'Komentář',
  'AuditLog.EventData.votePortName': 'Název akce hlasování',
  'AuditLog.EventData.workflowDescription': 'Popis workflow',
  'AuditLog.EventData.WorkflowName': 'Název workflow',
  'AuditLog.EventData.workflowName': 'Název workflow',
  'AuditLog.EventData.workflowNodeDescription': 'Popis úlohy',
  'AuditLog.EventData.workflowNodeName': 'Název úlohy',
  'AuditLog.EventData.message':'Zpráva',
  'AuditLog.EventData.notifiedUser':'Upozorněný uživatel',
  'AuditLog.EventData.workflowOrderId': 'Workflow id',
  'AuditLog.EventData.stateKD': 'Stav přenosu',
  'AuditLog.EventData.endTimeKD': 'Konec přenosu',
  'AuditLog.EventData.startTimeKD': 'Začátek přenosu',
  'AuditLog.EventData.versionName': 'Název',
  'AuditLog.EventData.versionSign': 'Značka',
  'AuditLog.EventData.refereerName': 'Název dokumentu',
  'AuditLog.EventData.referenceName': 'Název reference',
  'AuditLog.EventData.menuName': 'Název v menu',
  'AuditLog.EventData.applicationType': 'Typ aplikace',
  'AuditLog.EventName.approvalDocumentAssigned': 'Přiřazení schvalovaného dokumentu',
  'AuditLog.EventName.approvalDocumentAddedToWorkflow': 'Vytvoření a přidání schvalovaného dokumentu',
  'AuditLog.EventName.approvalDocumentRevisionAddedInWorkflow': 'Přidání revize schvalovaného dokumentu',
  'AuditLog.EventName.approvalDocumentRemovedFromWorkflow': 'Odstranění schvalovaného dokumentu z workflow',
  'AuditLog.EventName.associateUserAddedToAssignment': 'Zástupce zadavatele přidán do úkolu',
  'AuditLog.EventName.associateUserRemovedFromAssignment': 'Zástupce zadavatele odebrán z úkolu',
  'AuditLog.EventName.bulkAction': 'Hromadná akce',
  'AuditLog.EventName.bulkDocumentsChange': 'Hromadná změna dokumentů',
  'AuditLog.EventName.workflowAssignApprovalDocuments': 'Přiřazení schvalovaných dokumentů',
  'AuditLog.EventName.initialised': 'Inicializace',
  'AuditLog.EventName.patched': 'Změna',
  'AuditLog.EventName.created': 'Vytvoření',
  'AuditLog.EventName.currentRevisionChanged': 'Změna aktuální revize',
  'AuditLog.EventName.invitationCreated': 'Vytvoření pozvánky',
  'AuditLog.EventName.invitationAccepted': 'Přijetí pozvánky',
  'AuditLog.EventName.statusChanged': 'Změna stavu',
  'AuditLog.EventName.requiredCategoryTreeAdded': 'Přidání povinné kategorie',
  'AuditLog.EventName.requiredCategoryTreeRemoved': 'Smazání povinné kategorie',
  'AuditLog.EventName.requiredCategoryTreeChanged': 'Změna povinné kategorie',
  'AuditLog.EventName.groupAccessLevelHasBeenSet': 'Přidání přístupu skupině',
  'AuditLog.EventName.groupAccessLevelHasBeenUnset': 'Odebrání přístupu skupině',
  'AuditLog.EventName.groupUsersAdded': 'Uživatelé přidáni do skupiny',
  'AuditLog.EventName.userAccessLevelHasBeenSet': 'Přidání přístupu uživateli',
  'AuditLog.EventName.userAccessLevelHasBeenUnset': 'Odebrání přístupu uživateli',
  'AuditLog.EventName.permisssionInheritanceChanged': 'Změna dědění oprávnění',
  'AuditLog.EventName.move': 'Přesunutí',
  'AuditLog.EventName.labelAdded': 'Přidání štítku',
  'AuditLog.EventName.labelRemoved': 'Smazání štítku',
  'AuditLog.EventName.linkAdded': 'Přidání vazby na dokument',
  'AuditLog.EventName.revisionAdded': 'Přidání revize',
  'AuditLog.EventName.categoryTreeNodeAdded': 'Přidání uzlu kategorie',
  'AuditLog.EventName.categoryTreeNodeChanged': 'Změna uzlu kategorie',
  'AuditLog.EventName.categoryTreeNodeRemoved': 'Smazání uzlu kategorie',
  'AuditLog.EventName.suspended': 'Deaktivace',
  'AuditLog.EventName.activated': 'Aktivace',
  'AuditLog.EventName.discarded': 'Vyřazení',
  'AuditLog.EventName.requiredDefaultCategoryTreeNodechanged': 'Změna uzlu povinné kategorie',
  'AuditLog.EventName.moved': 'Přesunutí',
  'AuditLog.EventName.documentsDiscarded': 'Vyřazení dokumentů',
  'AuditLog.EventName.documentsRestored': 'Obnovení dokumentů',
  'AuditLog.EventName.documentsMoved': 'Přesunutí dokumentu',
  'AuditLog.EventName.directoriesDiscarded': 'Vyřazení složek',
  'AuditLog.EventName.directoriesRestored': 'Obnovení složek',
  'AuditLog.EventName.documentAddedForApproval': 'Přídání schvalovaného dokumentu do workflow',
  'AuditLog.EventName.restored': 'Obnovení',
  'AuditLog.EventName.used': 'Provedení',
  'AuditLog.EventName.documentShared': 'Dokument byl sdílen',
  'AuditLog.EventName.sharedDocumentDownloaded': 'Stažení sdíleného dokumentu',
  'AuditLog.EventName.shareSent': 'Sdílení odesláno',
  'AuditLog.EventName.documentDownloaded': 'Stažení dokumentu',
  'AuditLog.EventName.documentsDownloaded': 'Stažení dokumentů',
  'AuditLog.EventName.secondaryFileAdded': 'Soubor přílohy byl přidán',
  'AuditLog.EventName.documentAddedToModels': 'Dokument byl přidán k modelů',
  'AuditLog.EventName.documentRemovedFromModels': 'Dokument byl odebrán z modelů',
  'AuditLog.EventName.secondaryFileDownloaded': 'Soubor přílohy byl stažen',
  'AuditLog.EventName.linkRemoved': 'Vazba byla odebrána',
  'AuditLog.EventName.addedToGroup': 'Uživatel byl přidán do skupiny',
  'AuditLog.EventName.removedFromGroup': 'Uživatel byl odebrán ze skupiny',
  'AuditLog.EventName.usersInvited': 'Uživatel byl pozván',
  'AuditLog.EventName.workflowStateChanged': 'Stav dokumentu byl změněn',
  'AuditLog.EventName.reserved': 'Rezervace',
  'AuditLog.EventName.reservationReleased': 'Uvolnění rezervace',
  'AuditLog.EventName.ownerChanged': 'Správce dokumentu byl změněn',
  'AuditLog.EventName.userRoleAdded': 'Uživateli byla přiřazena role',
  'AuditLog.EventName.userRoleDeleted': 'Uživateli byla odebrána role',
  'AuditLog.EventName.roleAddedToWorkflow': 'Role byla přidána do workflow',
  'AuditLog.EventName.roleAddedToNode': 'Role byla přidána do úlohy',
  'AuditLog.EventName.roleRemovedFromNode': 'Role byla odebrána z úlohy',
  'AuditLog.EventName.roleAddedToBackgroundDocument': 'Role byla přidána k požadavku',
  'AuditLog.EventName.finished': 'Dokončeno',
  'AuditLog.EventName.backgroundDocumentAssigned': 'Dokument byl přiřazen k požadavku',
  'AuditLog.EventName.backgroundDocumentAccepted': 'Požadavek byl schválen',
  'AuditLog.EventName.backgroundDocumentRefused': 'Požadavek byl odmítnut',
  'AuditLog.EventName.backgroundDocumentRequestAdded': 'Byl přidán nový požadavek',
  'AuditLog.EventName.backgroundDocumentRequestChanged': 'Požadavek byl změněn',
  'AuditLog.EventName.backgroundDocumentRequestRemoved': 'Požadavek byl odebrán',
  'AuditLog.EventName.workflowNodeStarted': 'Začala nová úloha',
  'AuditLog.EventName.workflowNodeRoleDelegation': 'Řešitel delegoval svojí roli',
  'AuditLog.EventName.workflowNodeVote': 'Řešitel hlasoval u úlohy',
  'AuditLog.EventName.workflowNodeFinish': 'Úloha skončila',
  'AuditLog.EventName.documentAddedAsBackgroundDocument': 'Dokument byl nahrán k požadavku',
  'AuditLog.EventName.documentRemovedFromBackgroundDocument': 'Dokument byl odebrán z požadavku',
  'AuditLog.EventName.workflowVote': 'Hlasování u workflow',
  'AuditLog.EventName.roleRemovedFromBackgroundDocument': 'Role byla odebrána z požadavku',
  'AuditLog.EventName.documentRemovedFromComments': 'Dokument byl odebrán z připomínkového řízení',
  'AuditLog.EventName.documentAddedToComments': 'Dokument byl přidán do připomínkového řízení',
  'AuditLog.EventName.roleAddedToComments': 'Role byla přidaná do připomníkového řízení',
  'AuditLog.EventName.roleRemovedFromComments': 'Role byla odebrána z připomníkového řízení',
  'AuditLog.EventName.userAddedToComments': 'Uživatel byl přidán do připomníkového řízení',
  'AuditLog.EventName.userRemovedFromComments': 'Uživatel byl odebrán z připomníkového řízení',
  'AuditLog.EventName.userResendActivation': 'Znovu odeslání aktivačního emailu',
  'AuditLog.EventName.commentsUserPatched': 'Uživatel byl upraven v připomníkovém řízení',
  'AuditLog.EventName.teamAddedToComments': 'Tým byl přidán do připomníkového řízení',
  'AuditLog.EventName.teamRemovedFromComments': 'Tým byl odebrán z připomníkového řízení',
  'AuditLog.EventName.commentsTeamPatched': 'Tým byl upraven v připomníkovém řízení',
  'AuditLog.EventName.CommentsSetState': 'Stav změněn u připomníkového řízení',
  'AuditLog.EventName.commentsComentCreated': 'Vytvořena připomínka',
  'AuditLog.EventName.commentsComentPatched': 'Upravena připomínka',
  'AuditLog.EventName.commentsComentStateSet': 'Stav připomínky změněn',
  'AuditLog.EventName.attachmentAddedToCommentsComment': 'Příloha přidána k připomínce',
  'AuditLog.EventName.commentsComentNoteAdded': 'Komentář k připomínce přidán',
  'AuditLog.EventName.documentAddedToCommentsComment': 'Dokument přidán u připomínky',
  'AuditLog.EventName.documentRemovedFromCommentsComment': 'Dokument odebrán z připomínky',
  'AuditLog.EventName.commentsStateSet': 'Nastaven stav připomínkového řízení',
  'AuditLog.EventName.commentsComentNoteEdited': 'Upraven komentář připomínky',
  'AuditLog.EventName.commentsComentNoteDeleted': 'Smazán komentář připomínky',
  'AuditLog.EventName.commentsComentNoteRestored': 'Obnoven komentář připomínky',
  'AuditLog.EventName.attachmentDiscardedFromCommentsComment': 'Příloha vyřazena z komentáře',
  'AuditLog.EventName.attachmentRestoredInCommentsComment': 'Příloha obnovena v komentáři',
  'AuditLog.EventName.exported': 'Proběhl export',
  'AuditLog.EventName.extendedPermissionChanged': 'Uživatelské oprávnění změněno',
  'AuditLog.EventName.startEsticonUpload': 'Upload souboru pro Esticon zahájen',
  'AuditLog.EventName.startEsticonDocumentUpload': 'Upload dokumentu pro Esticon zahájen',
  'AuditLog.EventName.esticonDocumentUploaded': 'Dokument Esticonu nahrán',
  'AuditLog.EventName.esticonDocumentRejected': 'Dokument Esticonu odmítnut',
  'AuditLog.EventName.documentApprovalFinished': 'Schvalování dokumentu ukončeno',
  'AuditLog.EventName.closed': 'Ukončení',
  'AuditLog.EventName.documentRemovedFromCommentProcedure': 'Dokument uvolněn z připomínkového řízení',
  'AuditLog.EventName.documentAddedToCommentProcedure': 'Dokument přidán do připomínkového řízení',
  'AuditLog.EventName.documentAddedToAssignment': 'Dokument přidán do úkolu',
  'AuditLog.EventName.documentRemovedFromAssignment': 'Dokument odebrán z úkolu',
  'AuditLog.EventName.approvalDocumentsAccepted': 'Schvalované dokumenty byly schváleny',
  'AuditLog.EventName.approvalDocumentsRefused': 'Schvalované dokumenty byly odmítnuty',
  'AuditLog.EventName.CommentProcedureDocumentsAdded': 'Dokumenty byli přidány do připomínkového řízení',
  'AuditLog.EventName.commentProcedureRolesPatched': 'Role byly změněny v připomínkovém řízení',
  'AuditLog.EventName.usersAddedToComments': 'Uživatelé byli přidáni do připomínkového řízení',
  'AuditLog.EventName.commentProcedureCategoryAdded': 'Vytvořena kategorie',
  'AuditLog.EventName.commentProcedureCategoryPatched': 'Upravena kategorie',
  'AuditLog.EventName.commentProcedureCategoryDeleted': 'Smazána kategorie',
  'AuditLog.EventName.commentProcedureCategoriesImported': 'Importovány kategorie',
  'AuditLog.EventName.commentProcedureAdded': 'Přidáno připomínkové řízení',
  'AuditLog.EventName.commentProcedureRemoved': 'Odebráno připomínkové řízení',
  'AuditLog.EventName.commentProcedureDeadlineSet': 'Nastaven termín připomínkového řízení',
  'AuditLog.EventName.commentsTeamPermissionPatched': 'Oprávnění týmu bylo změněno',
  'AuditLog.EventName.documentDerivatesDownloaded': 'Zobrazeny náhledy dokumentu',
  'AuditLog.EventName.documentSecondaryDerivatesDownloaded': 'Zobrazeny náhledy sekundárního dokumentu',
  'AuditLog.EventName.revisionDiscarded': 'Revize dokumentu byla vyřazena',
  'AuditLog.EventName.revisionRestored': 'Revize dokumentu byla obnovena',
  'AuditLog.EventName.projectOpened': 'Otevření projektu',
  'AuditLog.EventName.projectClosed': 'Zavření projektu',
  'AuditLog.EventName.reportCreated': 'Vytvořen report',
  'AuditLog.EventName.workflowParallelInit': 'Paralelní workflow inicializováno',
  'AuditLog.EventName.workflowNodeNoteAdded': 'Přidání komentáře k diskuzi workflow úlohy',
  'AuditLog.EventName.workflowNodeNoteEdited': 'Změna komentáře u diskuze workflow úlohy',
  'AuditLog.EventName.workflowNodeNoteDeleted': 'Odebrání komentáře u diskuze workflow úlohy',
  'AuditLog.EventName.workflowNodeNoteRestored': 'Obnovení komentáře u diskuze workflow úlohy',
  'AuditLog.EventName.attachmentAddedToWorkflowNode': 'Přidání přílohy k diskuzi workflow úlohy',
  'AuditLog.EventName.attachmentDiscardedFromWorkflowNode': 'Odebrání přílohy diskuze workflow úlohy',
  'AuditLog.EventName.attachmentRestoredInWorkflowNode': 'Obnovení přílohy diskuze workflow úlohy',
  'AuditLog.EventName.mailLimitExceed': 'Překročen počet odeslaných emailů',
  'AuditLog.EventName.userAddedToAssignment': 'Uživatel byl přidán do úkolu',
  'AuditLog.EventName.userRemovedFromAssignment': 'Uživatel byl odebrán z úkolu',
  'AuditLog.EventName.primarySolverChanged': 'Změna primárního řešitele',
  'AuditLog.EventName.approvalDocumentAddRevision': 'Revize nahrána ke schvalovanému dokumentu',
  'AuditLog.EventName.assignmentNoteAdded': 'Přídání komentáře k diskuzi úkolu',
  'AuditLog.EventName.assignmentNoteEdited': 'Změna komentáře u diskuze úkolu',
  'AuditLog.EventName.assignmentNoteDeleted': 'Odebrání komentáře diskuze úkolu',
  'AuditLog.EventName.assignmentNoteRestored': 'Obnovení komentáře diskuze úkolu',
  'AuditLog.EventName.attachmentAddedToAssignment': 'Přidání přílohy k diskuzi úkolu',
  'AuditLog.EventName.attachmentDiscardedFromAssignment': 'Odebrání přílohy diskuze úkolu',
  'AuditLog.EventName.attachmentRestoredInAssignment': 'Obnovení přílohy diskuze úkolu',
  'AuditLog.EventName.directoryShared': 'Složka byla nasdílena',
  'AuditLog.EventName.documentLinksCreated': 'Vytvořeny odkazy na dokumenty',
  'AuditLog.EventName.documentLinkAdded': 'Odkaz na dokument přidán',
  'AuditLog.EventName.documentLinkRemoved': 'Odkaz na dokument odebrán',
  'AuditLog.EventName.documentLinkChanged': 'Odkaz na dokument upraven',
  'AuditLog.EventName.documentEsticonMultiupload': 'Hromadné nahrání z esticonu',
  'AuditLog.EventName.documentAnnotationsCreated': 'Vytvořeny komentáře v dokumentu',
  'AuditLog.EventName.documentAnnotationsChanged': 'Komentáře v dokumentu změněny',
  'AuditLog.EventName.documentAnnotationAdded': 'Přidán komentář do dokumentu',
  'AuditLog.EventName.documentAnnotationChanged': 'Komentář v dokumentu změněn',
  'AuditLog.EventName.annotationRemoved': 'Komentář odebrán z dokumentu',
  'AuditLog.EventName.documentAnnotationAddedToCommentsComment': 'Komentář dokumentu připojen k připomínce',
  'AuditLog.EventName.directoryLinkAdded': 'Odkaz na adresář přidán',
  'AuditLog.EventName.directoryLinkRemoved': 'Odkaz na adresář odebrán',
  'AuditLog.EventName.directoryLinkChanged': 'Odkaz na adresář upraven',
  'AuditLog.EventName.esticonDocumentReturnForRework': 'Esticon dokument vrácen k přepracování',
  'AuditLog.EventName.esticonDocumentPublished': 'Esticon dokument publikován',
  'AuditLog.EventName.directoryLinkMoved': 'Odkaz na adresář přesunut',
  'AuditLog.EventName.attachmentDownloaded': 'Příloha byla stažena',
  'AuditLog.EventName.attachmentsDownloaded': 'Přílohy byly staženy',
  'AuditLog.EventName.labelReplaced': 'Štítky aktualizovány',
  'AuditLog.EventName.unknown': 'Neznámá událost',
  'AuditLog.EventName.esticonDocumentSetApprovalDate': 'Nastaveno datum schválení dokumentu',
  'AuditLog.EventName.transferToKD': 'Přenos projektu do kontrolní databáze',
  'AuditLog.EventName.attachmentVisibilityChangedInCommentsComment': 'Změněna viditelnost přílohy',
  'AuditLog.EventName.docToDocLinksCreated': 'Vazby na dokumenty vytvořeny',
  'AuditLog.EventName.assignmentStateChanged': 'Změna stavu úkolu',
  'AuditLog.EventName.organizationDeleted': 'Organizace odstraněna',
  'AuditLog.EventName.addOrganizationUser': 'Přidán uživatel organizace',
  'AuditLog.EventName.patchOrganizationUser': 'Změněn uživatel organizace',
  'AuditLog.EventName.usersAddedToProject': 'Do projektu byli přidáni uživatelé',
  'AuditLog.EventName.projectUserSetAdmin': 'Uživateli na projektu byla změněna příslušnost k admin skupině',
  'AuditLog.EventName.projectUserChangeStatus': 'Uživateli na projektu byl změněn status',
  'AuditLog.EventName.addOrganizationProject': 'Do organizace byl přidán projekt',
  'AuditLog.EventName.harmonogramImported': 'Byl importován harmonogram projektu',
  'AuditLog.EventName.removeOrganizationUser': 'Byl odstraněn uživatel z organizace',
  'AuditLog.EventName.projectUserRemoveInvited': 'Byl odstraněn pozvaný uživatel z projektu',
  'AuditLog.EventName.organizationChanged': 'Organizace projektu byla změněna',
  'AuditLog.EventName.directoryDownloaded': 'Složka byla stažena',
  'AuditLog.EventName.bulkUpdateAnnotationsWithAssignment': 'Hromadná aktualizace připomínek s přílohou',
  'AuditLog.EventName.documentDownloadedForDiff': 'Dokument stažen k porovnání',
  'AuditLog.EventName.workflowStateChangedFail': 'Chyba změny stavu workflow',
  'AuditLog.EventName.approvalDocumentsShared': 'Schválené dokumenty jsou sdíleny',
  'AuditLog.EventName.approvalDocumentSignRevision': 'Revize schvalovaného dokumentu podepsána',
  'AuditLog.EventName.documentStateChangedFail': 'Nezdařený pokus o změnu stavu dokumentu',
  'AuditLog.EventName.merged': 'Sloučeno',
  'AuditLog.EventName.addItem': 'Položka byla přidána',
  'AuditLog.EventName.patchItem': 'Pložka byla upravena',
  'AuditLog.EventName.deleteItem': 'Položka byla smazána',
  'AuditLog.EventName.documentReferenceAdded': 'Přidána reference na dokument',
  'AuditLog.EventName.documentReferenceMatrixAdded': 'Přidány reference na dokument',
  'AuditLog.EventName.documentReferenceRemoved': 'Odebrdána reference na dokument',
  'AuditLog.EventName.userNotified': 'Uživatel upozorněn',
  'AuditLog.EventName.settingsDeleted': 'Nastavení smazáno',
  'AuditLog.ParamsTable.EventName': 'Parametry akce',
  'AuditLog.ParamsTable.Value': 'Hodnota',
  'AuditLog.ParamsTable.OriginValue': 'Původní hodnota',
  'AuditLog.ParamsTable.NewValue': 'Nová hodnota',
  'AuditLog.view': 'Zobrazit všechny související aktivity',
  'BcfPanel.notSaveAlert': 'BCF připomínky nejsou uloženy. Pokud budete pokračovat, změny budou ztraceny.',
  'BcfTopics': 'Úkoly',
  'BcfTopics.noTopics': 'Zatím nejsou vytvořeny žádné úkoly',
  'BcfAddNewTopic': 'Přidat nový úkol',
  'BcfUploadTopicsFile': 'Importovat úkoly z BCF souboru',
  'BcfDownloadTopicsFile': 'Exportovat úkoly do BCF souboru',
  'BcfForm.includeSnapshot': 'Náhled modelu (screenshot)',
  'BcfForm.includeViewpoint': 'Grafická data pohledu (např. pozice kamery)',
  'BcfForm.viewerIntegration': 'Přiložit grafické informace',
  'BcfTopicDetail.title': 'Detail úkolu',
  'BcfTopicDetail.focusView': 'Vycentrovat kameru',
  'BcfTopicDetail.automaticFocusView': 'Automatické vycentrování kamery',
  'BcfTopicDetail.goToAssignment': 'Přejít na úkol',
  'BcfTopicComments': 'Komentáře',
  'BcfTopicAddComment': 'Přidat komentář',
  'BcfTopicAddComment.placeholder': 'Napište komentář k poznámce ...',
  'BcfTopicStatus.open': 'Otevřeno',
  'BcfTopicStatus.inProgress': 'V řešení',
  'BcfTopicStatus.reopened': 'Znovu otevřeno',
  'BcfTopicStatus.resolved': 'Zapracováno',
  'BcfTopicStatus.closed': 'Uzavřeno',
  'BcfTopicTypes.task': 'Úkol',
  'BcfTopicTypes.issue': 'Problém',
  'BcfTopicTypes.request': 'Požadavek',
  'BcfTopicTypes.clash': 'Kolize',
  'BcfImport.success': 'Soubor BCF (verze: {value}) byl úspěšně importován.',
  'BcfImport.error': 'Soubor BCF se nepodařilo importovat.',
  'BcfSave.error': 'Změny úkolů se nepodařilo uložit na server.',
  'BcfSave.success': 'Změněné úkoly byly uloženy.',
  'BcfImportConflictModal.title': 'Řešení konfliktů',
  'BcfExportModal.title': 'Export poznámek',
  'BcfExportModal.summary': 'Počet vybraných poznámek: {count}',
  'BcfImportModal.title': 'Import poznámek k modelu',
  'BcfImportModal.nameTooltip': 'Importem se aktualizuje připomínka',
  'BcfImportModal.conflictTooltip': 'Importovaná položka nemá validní data a je potřeba v případě importu opravit',
  'BcfImportModal.resolveConflict': 'Vyřešit konflikt',
  'BooleanFilter.isModel':'Modely',
  'BooleanFilter.isModel.description':'Zobrazit dokumenty, které jsou modely',
  'BooleanFilter.isModel.yes':'Ano - pouze modely',
  'BooleanFilter.isModel.no':'Ne - pouze dokumenty, které nejsou modely',
  'BooleanFilter.isSigned':'Podepsáno',
  'BooleanFilter.isSigned.description':'Zobrazit dokumenty, které jsou podepsány',
  'BooleanFilter.isSigned.yes':'Ano - pouze podepsané',
  'BooleanFilter.isSigned.no':'Ne - pouze nepodepsané',
  'BooleanFilter.hasAnnotation':'Komentováno',
  'BooleanFilter.hasAnnotation.description':'Zobrazit dokumenty, které jsou komentované',
  'BooleanFilter.hasAnnotation.yes':'Ano - pouze komentované',
  'BooleanFilter.hasAnnotation.no':'Ne - pouze nekomentované',
  'BudgetItemDetail.price': 'Cena',
  'BudgetItemDetail.margins': 'Marže',
  'BudgetItemDetail.title': 'Detail položky rozpočtu',
  'BudgetItemDetail.menu.overview': 'Přehled',
  'BudgetItemDetail.menu.quantitySheet': 'Výkaz výměr',
  'BudgetItemDetail.menu.specification': 'Specifikace',
  'BudgetItemDetail.menu.realization': 'Realizace',
  'BudgetItemDetail.menu.cefActivities': 'Aktivity CEF',
  'BudgetItemDetail.tab.specification.description': 'Popis',
  'BudgetItemDetail.tab.specification.techSpecification': 'Technická specifikace',
  'BudgetItemDetail.tab.quantitySheet.title': 'Výkaz výměr',
  'BudgetItemDetailRealization.column.wipReportSign': 'Značka ZP',
  'BudgetItemDetailRealization.column.issuedDate': 'Datum vystavení',
  'BudgetItemDetailRealization.column.originName': 'SoD/ZBV',
  'BudgetItemDetailRealization.column.drawedAmount': 'Čerpáno',
  'BudgetItemDetailRealization.column.drawedCost': 'Čerpáno [{currency}]',
  'BudgetItemDetailRealization.column.totalAmount': 'Čerpáno celkem',
  'BudgetItemDetailRealization.column.totalCost': 'Čerpáno celkem [{currency}]',
  'BudgetItemDetailRealization.column.invoiceSign': 'Značka FA',
  'BudgetItemDetailStatement.column.code': 'Kód',
  'BudgetItemDetailStatement.column.description': 'Popis',
  'BudgetItemDetailStatement.column.expression': 'Výraz',
  'BudgetItemDetailStatement.column.value': 'Hodnota',
  'BudgetItemDetailStatement.column.include': 'Započítat',
  'BudgetItemDetailCefActivities.activity': 'Aktivita',
  'BudgetItemDetailCefActivities.notClassified': 'Nezařazeno',
  'BudgetItemDetailCefActivities.overviewOfChangesInCefActivities': 'Přehled změn aktivit CEF',
  'BudgetItemDetailCefActivities.rationaleForChangeInCefActivity': 'Zdůvodnění změny aktivity CEF',
  'BudgetItemDetailCefActivities.column.dateOfChange': 'Datum změny',
  'BudgetItemDetailCefActivities.column.validFrom': 'Platnost od',
  'BudgetItemDetailCefActivities.column.activityBeforeChange': 'Aktivita před změnou',
  'BudgetItemDetailCefActivities.column.activityAfterChange': 'Aktivita po změně',
  'BudgetItemDetailCefActivities.column.formulatedBy': 'Vypracoval',
  'BudgetDetail.amount': 'Množství',
  'BudgetDetail.structuralElementTitle': 'Detail zjišťovacího protokolu',
  'BudgetDetail.designElementTitle': 'Detail konstrukčního prvku',
  'BudgetDetail.buildingPartTitle': 'Detail stavebního dílu',
  'BudgetDetail.fixed.OC': 'Fixní OC',
  'BudgetDetail.JOC': 'JOC',
  'BudgetDetail.margins': 'Marže',
  'BudgetDetail.measureUnit': 'Měrná jednotka',
  'BudgetDetail.name': 'Název',
  'BudgetDetail.OC.total': 'OC Celkem',
  'BudgetDetail.serialNumber': 'PČ',
  'BudgetDetail.costSpent': 'Čerpáno',
  'BudgetDetail.JNC': 'JNC',
  'BudgetDetail.JNCTotal': 'NC Celkem',
  'BudgetDetail.totalMargins': 'Marže Celkem',
  'BudgetDetail.VAT': 'DPH',
  'BudgetDetail.withVAT': 's DPH',
  'BudgetDetail.notConnected': 'U projektu není připojen rozpočet.',
  'BudgetDetail.priceSystem': 'Cenová soustava',
  'BudgetDetail.otherProperties': 'Další vlastnosti',
  'BudgetDetail.itemIsValorized': 'Položka se valorizuje',
  'BudgetGrid.noSearchDataText': 'Zadanému textu neodpovídají žádné položky',
  'BudgetGrid.noDataText': 'Žádná data',
  'BudgetGrid.preparing': 'Připravuji ...',
  'BudgetObjectDetail.title': 'Detail objektu',
  'BudgetObjectDetail.costPrices': 'Nákladové ceny',
  'BudgetObjectDetail.contacts': 'Kontakty',
  'BudgetObjectDetail.ncTotal': 'Celková cena',
  'BudgetObjectDetail.begin': 'Zahájení',
  'BudgetObjectDetail.end': 'Dokončení',
  'BudgetObjectDetail.administrator': 'Správce / Investor',
  'BudgetObjectDetail.contractor': 'Dodavatel',
  'BudgetObjectDetail.designer': 'Projektant',
  'BudgetObjectDetail.salesPrices': 'Odbytové ceny',
  'BudgetProjectDetail.title': 'Detail projektu',
  'BudgetProjectDetail.realized': 'Čerpáno',
  'BudgetProjectDetail.remaining': 'Zbývá',
  'BudgetProjectDetail.costNow': 'Aktuální cena',
  'BudgetProjectDetail.costNowShort': 'Akt. cena',
  'BudgetProjectDetail.costChange': 'Cenový rozdíl',
  'BudgetProjectDetail.costChangeShort': 'Rozdíl',
  'Building.general.amount': 'Množství',
  'Building.general.name': 'Název',
  'Building.general.unit': 'Útvar',
  'Building.general.budgetType': 'Typ rozpočtu',
  'Building.general.budgetType.StavebniDily': 'Po stavebních dílech',
  'Building.general.budgetType.Elementy': 'Po elementech',
  'Building.general.stage': 'Stupeň projektu',
  'Building.general.stage.Zp': 'ZP',
  'Building.general.stage.Dur': 'DUR',
  'Building.general.stage.Dsp': 'DSP',
  'Building.general.stage.Dps': '(DPS) Dokumentace pro provádění stavby',
  'Building.general.price': 'Cena',
  'Building.general.sign': 'Značka',
  'Building.general.code': 'Kód',
  'Building.general.document': 'Dokument',
  'Building.general.existingAddress': 'Existující adresa',
  'Building.general.importedAddress': 'Importovaná adresa',
  'Building.general.establish': 'Založit',
  'Building.general.dontEstablish': 'Nezakládat',
  'Building.general.number': 'Číslo',
  'Building.general.spent': 'Čerpáno',
  'Building.general.billed': 'Čerpáno',
  'Building.general.spentPercent': 'Čerpáno [%]',
  'Building.general.variant': 'Varianta',
  'Building.general.valorization': 'Valorizační',
  'Building.general.notValorized': 'Bez valorizace',
  'Building.general.begin': 'Zahájení',
  'Building.general.end': 'Dokončení',
  'Building.general.scopeOfWork.abbreviation': 'SoD',
  'Building.general.changeOrder.abbreviation': 'ZBV',
  'Building.general.sod': 'Cena SoD',
  'Building.general.zbv': 'Cena ZBV',
  'Building.general.totalPrice': 'Celková cena',
  'Building.general.totalPriceWithVat': 'Celková cena s DPH',
  'Building.general.partner': 'Partner',
  'Building.general.phase': 'Fáze',
  'Building.general.phase.Priprava': 'Příprava',
  'Building.general.phase.Nabidka': 'Nabídka',
  'Building.general.phase.Realizace': 'Realizace',
  'Building.general.phase.Dokonceno': 'Dokončeno',
  'Building.general.phase.Neziskano': 'Nezískáno',
  'Building.general.short.displayUnit': 'MJ',
  'Building.general.short.orderNumber': 'PČ',
  'Building.general.short.unitPrice': 'JC',
  'Building.general.showBreakdown': 'Zobrazit rozpis',
  'Building.general.showDocument': 'Zobrazit dokument',
  'Building.general.returnToRework': 'Vrátit k přepracování',
  'Building.general.returnToReworkAssociated': 'Vrátit k přepracování včetně členských',
  'Building.general.displayMode.all': 'Zobrazit vše',
  'Building.general.displayMode.sent': 'Pouze odeslané',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokolSdruzeny': 'Typ dokumentu: Zjistovací protokol sdružený',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokol': 'Typ dokumentu: Zjistovací protokol',
  'BuildingApprovalDateForm.documentType.ZBV': 'Typ dokumentu: ZBV',
  'BuildingApprovalDateForm.title': 'Změna data schválení',
  'BuildingApprovalDateForm.formItemLabel': 'Datum schválení',
  'BuildingApprovalDateForm.submitText.enter': 'Nastavit datum schválení',
  'BuildingApprovalDateForm.submitText.remove': 'Bez data schválení',
  'BuildingApprovalDateForm.requiredRuleText': 'Zadejte prosím datum schválení',
  'Building.general.qualified': 'Způsobilá',
  'Building.general.notQualified': 'Nezpůsobilá',
  'Building.general.qualification.headerLabel': 'Způsobilost',
  'Building.general.sodQualification': 'Způsobilost SoD',
  'Building.general.zbvQualification': 'Způsobilost ZBV',
  'BuildingBudget.title': 'Rozpočet',
  'BuildingBudget.uploadProject': 'Nahrát',
  'BuildingBudget.searchPlaceholder': 'Vyhledávání...',
  'BuildingBudget.showColumns': 'Výběr sloupců',
  'BuildingBudget.accordingTo.KP': 'KP',
  'BuildingBudget.accordingTo.SD': 'SD',
  'BuildingBudget.accordingTo.KP.tooltip': 'Konstrukční prvky',
  'BuildingBudget.accordingTo.SD.tooltip': 'Stavební díly',
  'BuildingBudget.column.orderNumber': 'PČ',
  'BuildingBudget.column.sign': 'Kód',
  'BuildingBudget.column.variant': 'Varianta',
  'BuildingBudget.column.name': 'Název',
  'BuildingBudget.column.amount': 'Množství celkem',
  'BuildingBudget.column.displayUnit': 'MJ',
  'BuildingBudget.column.unitSellingPrice': 'JOC',
  'BuildingBudget.column.unitCostPrice': 'JNC',
  'BuildingBudget.column.sellingPrice': 'Cena',
  'BuildingBudget.column.costPrice': 'Cena (NC)',
  'BuildingBudget.column.sodAmount': 'Množství SoD',
  'BuildingBudget.column.drawnAmount': 'Čerpáno množství',
  'BuildingBudget.column.remainingAmount': 'Zbývá množství',
  'BuildingBudget.column.sodSellingPrice': 'Cena SoD',
  'BuildingBudget.column.zbvAmount': 'Množství ZBV',
  'BuildingBudget.column.zbvSellingPrice': 'Cena ZBV',
  'BuildingBudget.column.spentSellingPrice': 'Čerpáno',
  'BuildingBudget.column.spentPercent': 'Čerpáno [%]',
  'BuildingBudget.column.remainingSellingPrice': 'Zbývá',
  'BuildingBudget.column.originType': 'Založeno',
  'BuildingBudget.column.priceSystem': 'Cenová soustava',
  'BuildingBudget.column.valorized': 'Valorizovaná',
  'BuildingBudget.columnHeaderTooltip.unitSellingPrice': 'JOC [Kč]',
  'BuildingBudget.columnHeaderTooltip.unitCostPrice': 'JNC [Kč]',
  'BuildingBudget.columnHeaderTooltip.sellingPrice': 'Cena [Kč]',
  'BuildingBudget.columnHeaderTooltip.costPrice': 'Cena (NC) [Kč]',
  'BuildingBudget.columnHeaderTooltip.sodAmount': 'Množství SoD',
  'BuildingBudget.columnHeaderTooltip.sodSellingPrice': 'Cena SoD [Kč]',
  'BuildingBudget.columnHeaderTooltip.zbvAmount': 'Množství ZBV',
  'BuildingBudget.columnHeaderTooltip.zbvSellingPrice': 'Cena ZBV [Kč]',
  'BuildingBudget.columnHeaderTooltip.drawnAmount': 'Čerpáno množství',
  'BuildingBudget.columnHeaderTooltip.remainingAmount': 'Zbývá množství',
  'BuildingBudget.columnHeaderTooltip.cenaCelkem': 'Cena stavby včetně ZBV',
  'BuildingBudget.columnHeaderTooltip.fakturovano': 'Fakturováno v OC (zjišťovací protokoly)',
  'BuildingBudget.columnHeaderTooltip.cerpanoNakladyCelkem': 'Plánované náklady (NC) na provedené množství',
  'BuildingBudget.columnHeaderTooltip.actualIncome': 'Vystavené faktury (z účetnictví)',
  'BuildingBudget.columnHeaderTooltip.actualTotalCost': 'Přijaté faktury a ostatní náklady (z účetnictví)',
  'BuildingBudget.columnHeaderTooltip.netIncomeToDate': 'Hospodářský výsledek',
  'BuildingBudget.columnHeaderTooltip.zbyvaFakturovano': 'Zbývá dofakturovat (OC)',
  'BuildingBudget.columnHeaderTooltip.zbyvaNc': 'Náklady na zbývající množství z plánu (NC)',
  'BuildingBudget.columnHeaderTooltip.expectedNetIncome': 'Předpokládaný hospodářský výsledek stavby',
  'BuildingBudget.columnHeaderTooltip.coefficient': 'Koeficient předpokládaného hospodářského výsledku',
  'BuildingBudget.columnHeaderTooltip.margin': 'Marže v %',
  'BuildingBudget.columnHeaderTooltip.erpHeaderDrawing': 'Provedené práce - čerpání rozpočtu',
  'BuildingBudget.columnHeaderTooltip.erpHeaderActual': 'Skutečné výnosy a náklady v účetnicví',
  'BuildingBudget.columnHeaderTooltip.erpHeaderRemaining': 'Zbývající práce - čerpání rozpočtu',
  'BuildingBudget.columnHeaderTooltip.erpHeaderexpectedResult': 'Předpokládaný výsledek stavby',
  'BuildingBudget.noProjectContent': 'Rozpočet nelze zobrazit.',
  'BuildingBudget.transferToControlDb': 'Přenos projektu do kontrolní databáze',
  'BuildingBudget.transferToControlDb.lastSent': 'Naposledy odesláno: ',
  'BuildingBudget.transferToControlDb.transferAuthor': 'Autor přenosu: ',
  'BuildingBudget.transferToControlDb.transferNotYet': 'Přenos do kontrolní databáze doposud nebyl proveden',
  'BuildingBudget.transferToControlDb.button': 'Přenos do KD',
  'BuildingBudget.transferToControlDb.confirm.title': 'Přejete si odeslat projekt do kontrolní databáze?',
  'BuildingBudget.transferToControlDb.confirm.content': 'Do Kontrolní databáze budou přeneseny data z AspeEsticon, které nemusí souhlasit s daty odeslanými do AspeHub.',
  'BuildingBudget.transferToControlDb.confirm.okText': 'Přenést',
  'BuildingBudget.transferToControlDb.confirm.cancelText': 'Storno',
  'BuildingBudgetModel.colorsTooltip.title': 'Barevné schéma čerpání',
  'BuildingBudgetModel.colorsTooltip.drawed': 'Čerpáno',
  'BuildingBudgetModel.changeModels': 'Změnit vybrané modely',
  'BuildingBudgetModel.selectModelsTitle': 'Výběr modelů pro zobrazení',
  'BuildingBudgetModel.selectModelsButton': 'Otevřít modely',
  'BuildingBudgetModel.options.showDrawingColors': 'Zobrazit/skrýt obarvení elementů v modelu podle čerpání',
  'BuildingBudgetModel.options.showHighlighted': 'Zobrazit/skrýt zvýraznění vybraných elementů v modelu',
  'BuildingBudgetModel.options.showCheckboxes': 'Zobrazit/skrýt zaškrtávací políčka pro izolaci elementů v modelu',
  'BuildingBudgetModel.options': 'Možnosti zobrazení',
  'BuildingDocumentMenu.unsetApprovalDate': 'Odebrat datum schválení',
  'BuildingDrawing.newZPRevision': 'Nová revize ZP',
  'BuildingDrawing.addZP': 'Nahrát ZP',
  'BuildingDrawing.addZP.success': 'Data byla úspěšně nahrána do AspeEsticon. Nyní začíná proces přenosu do AspeHub.',
  'BuildingDrawing.addZP.disabled': 'Pro nahrání ZP musí být Esticon projekt ve fázi realizace.',
  'BuildingDrawing.downloadZP': 'Stáhnout ZP',
  'BuildingDrawing.downloadZP.success': 'Zjišťovací protokol byl úspěšně stažen',
  'BuildingDrawing.showBreakdown': 'Zobrazit rozpis',
  'BuildingDrawingDownloadZp.modal.title': 'Export zjišťovacích protokolů',
  'BuildingDrawingDownloadZp.modal.okText': 'Exportovat',
  'BuildingDrawingDownloadZp.input.default': 'Export ZP',
  'BuildingDrawingDownloadZp.input.fileName': 'Název souboru',
  'BuildingDrawingDownloadZp.radioChoice.all': 'Vše',
  'BuildingDrawingDownloadZp.radioChoice.actualMonth': 'Aktuální měsíc',
  'BuildingDrawingDownloadZp.radioChoice.ZPinPeriod': 'ZP v období',
  'BuildingDrawingDownloadZp.formatRadioChoice.exportFormat': 'Formát exportu',
  'BuildingDrawingDownloadZp.checkbox.title': 'Export společných ZP včetně ZP od členů',
  'BuildingDrawingDownloadZp.valuesSelectTitle': 'Výběr dat',
  'BuildingDrawingPartnersRegisterModal.title': 'Číselník adres v AspeEsticon',
  'BuildingDrawingPartnersRegisterModal.name': 'Název',
  'BuildingDrawingPartnersRegisterModal.CIN': 'IČ',
  'BuildingDrawingPartnersRegisterModal.street': 'Ulice',
  'BuildingDrawingPartnersRegisterModal.ZIP': 'PSČ',
  'BuildingDrawingPartnersRegisterModal.city': 'Město',
  'BuildingDrawingCreatePartnerForm.title': 'Nový partner',
  'BuildingDrawingCreatePartnerForm.name': 'Název',
  'BuildingDrawingCreatePartnerForm.branchName': 'Název střediska',
  'BuildingDrawingCreatePartnerForm.CIN': 'IČ',
  'BuildingDrawingCreatePartnerForm.VatDeclaration': 'Prohlášení o DPH',
  'BuildingDrawingCreatePartnerForm.note': 'Poznámka',
  'BuildingDrawingCreatePartnerForm.defaultAddress': 'Výchozí adresa',
  'BuildingDrawingCreatePartnerForm.branch': 'Středisko',
  'BuildingDrawingCreatePartnerForm.VAT': 'DIČ',
  'BuildingDrawingCreatePartnerForm.street': 'Ulice',
  'BuildingDrawingCreatePartnerForm.city': 'Město',
  'BuildingDrawingCreatePartnerForm.ZIP': 'PSČ',
  'BuildingDrawingCreatePartnerForm.personType': 'Osoba',
  'BuildingDrawingCreatePartnerForm.juridicalPerson': 'Právnická',
  'BuildingDrawingCreatePartnerForm.naturalPerson': 'Fyzická',
  'BuildingDrawingCreatePartnerForm.VatRegistered': 'Plátce DPH',
  'BuildingDrawingCreatePartnerForm.BusinessRegister': 'Zápis do obchodního rejstříku',
  'BuildingDrawingCreatePartnerForm.defaultAddress.branch': 'Výchozí adresa - středisko',
  'BuildingDrawingCreatePartnerForm.defaultAddress.default': 'Výchozí',
  'BuildingDrawingCreatePartnerForm.sign': 'Zkratka (značka)',
  'BuildingDrawingCreatePartnerForm.BIN': 'IČP',
  'BuildingDrawingCreatePartnerForm.partnerAddresses': 'Adresy partnera',
  'BuildingDrawingCreatePartnerForm.poBox': 'PO Box',
  'BuildingDrawingCreatePartnerForm.IBAN': 'IBAN',
  'BuildingDrawingCreatePartnerForm.bicSwift': 'BIC/SWIFT',
  'BuildingDrawingCreatePartnerForm.bankAccount': 'Bankovní účet',
  'BuildingDrawingCreatePartnerForm.bankAccountNumberList': 'Číselník bankovních účtů',
  'BuildingDrawingCreatePartnerForm.bankAccountNumber': 'Číslo účtu',
  'BuildingDrawingCreatePartnerForm.bankNumber': 'Banka',
  'BuildingDrawingCreatePartnerForm.bankName': 'Název',
  'BuildingDrawingCreatePartnerForm.contactPerson': 'Kontaktní osoba',
  'BuildingDrawingCreatePartnerForm.contactPersonNumberList': 'Číselník kontaktních osob',
  'BuildingDrawingCreatePartnerForm.contactName': 'Jméno',
  'BuildingDrawingCreatePartnerForm.contactSurname': 'Příjmení',
  'BuildingDrawingCreatePartnerForm.contactDegreeBefore': 'Titul před',
  'BuildingDrawingCreatePartnerForm.contactDegreeAfter': 'Titul za',
  'BuildingDrawingCreatePartnerForm.contactMobile': 'Mobil',
  'BuildingDrawingCreatePartnerForm.contactPhone': 'Telefon',
  'BuildingDrawingCreatePartnerForm.contactEmail': 'Email',
  'BuildingRealization.addZP': 'Nahrát ZP',
  'BuildingRealization.showBreakdown': 'Zobrazit rozpis',
  'BuildingRealization.SoD': 'SoD',
  'BuildingRealization.mode.all': 'Vše',
  'BuildingRealization.mode.realized': 'Pouze čerpané',
  'BuildingRealization.commonProtocolsMode.partial.label': 'Dílčí',
  'BuildingRealization.commonProtocolsMode.common.label': 'Spol.',
  'BuildingRealization.commonProtocolsMode.partial.tooltip': 'Dílčí zjišťovací protokoly',
  'BuildingRealization.commonProtocolsMode.common.tooltip': 'Společné zjišťovací protokoly',
  'BuildingDrawingBreakdown.title': 'Čerpání - Rozpis',
  'BuildingDrawingBreakdown.column.totalSpentAmount': 'Čerpáno celkem',
  'BuildingDrawingBreakdown.column.totalSpent': 'Čerpáno celkem',
  'BuildingDrawingBreakdown.column.totalSpentPercent': 'Čerpáno celkem [%]',
  'BuildingDrawingBreakdown.column.remainingAmount': 'Zbývá',
  'BuildingDrawingBreakdown.column.remainingSellingPrice': 'Zbývá',
  'BuildingDrawingBreakdown.column.done': 'Provedeno',
  'BuildingDrawingBreakdown.column.totalDone': 'Provedeno celkem',
  'BuildingDrawingPage.title': 'Čerpání',
  'BuildingDrawingPage.noProjectContent': 'Čerpání nelze zobrazit.',
  'BuildingDrawingPage.columns.code': 'Kód',
  'BuildingDrawingPage.columns.name': 'Název',
  'BuildingDrawingPage.columns.exposed': 'Vystavení',
  'BuildingDrawingPage.columns.approved': 'Schválení',
  'BuildingDrawingPage.columns.cost': 'Cena',
  'BuildingDrawingPage.columns.spentCost': 'Čerpáno',
  'BuildingDrawingPage.columns.spentPercent': 'Čerpáno [%]',
  'BuildingDrawingPage.columns.remainingCost': 'Zbývá',
  'BuildingDrawingPage.columns.sourceDocument': 'Zdrojový dokument',
  'BuildingDrawingPage.columns.originType': 'Typ množství',
  'BuildingDrawingPage.columns.contractor': 'Dodavatel',
  'BuildingDrawingPage.columns.investor': 'Investor',
  'BuildingDrawingPage.columns.wipType': 'Sdružení',
  'BuildingDrawingPage.columns.wipSpecies': 'Typ ZP',
  'BuildingDrawingPage.columns.wipSpecies.Klasicky': 'Klasický',
  'BuildingDrawingPage.columns.wipSpecies.Storno': 'Storno',
  'BuildingDrawingPage.columns.wipSpecies.Valorizacni': 'Valorizační',
  'BuildingDrawingPage.columns.valorizationPeriod': 'Období valorizace',
  'BuildingDrawingAnalyzeZpForm.documentId': 'Vyberte dokument k nahrání:',
  'BuildingDrawingAnalyzeZpForm.documentId.placeholder': 'Kliknutím zde vyberte dokument z DMS',
  'BuildingDrawingAnalyzeZpForm.file': 'Nebo nahrajte nový dokument:',
  'BuildingDrawingAnalyzeZpForm.justZbvGroups': 'Aktualizovat skupiny (import pouze skupin ZBV)',
  'buildingDrawingAnalyzeZpStep.chooseOneWarning': 'Buďto vyberte existující dokument nebo nahrajete nový soubor.',
  'buildingDrawingAnalyzeZpStep.title': 'Výběr souboru',
  'buildingDrawingAnalyzeZpStep.analyzeError': 'Nepodařilo se načíst data ze souboru. Zkontrolujte, že je ZP validní.',
  'buildingDrawingImportInvoiceStep.noImportableInvoice': 'Importovaný soubor neobsahuje žádnou importovatelnou fakturu',
  'buildingDrawingImportZpStep.importError': 'Nepodařilo se importovat zjišťovací protokol.',
  'buildingDrawingImportZpStep.title': 'Nastavení importu',
  'buildingDrawingImportZpStep.upload': 'Nahrát',
  'buildingDrawingImportZpStep.pairingNecessaryAlert':
    'Označeným dodavatelům je nutné před nahráním přiřadit existujícího člena sdružení',
  'buildingDrawingImportZpStep.someDestinationObjectIdNotSetError': 'Před nahráním musí mít všechny ZP zadané připojení k objektu. Vyberte prosím objekt z nabídky.',
  'buildingDrawingImportZpStep.noIsSelectedZps': 'Soubor neobsahuje žádné importovatelné ZP',
  'buildingDrawingImportZpStep.noProtocols': 'Nejsou vybrány žádné ZP',
  'buildingDrawingImportZpStep.cannotImportSomeProtocols': 'Některý ZP nelze importovat',
  'buildingDrawingImportZpStep.hasInvalidZpPartners': 'Zjišťovací protokoly obsahují nevalidní identifikátory partnerů nebo nevalidní adresy. Importovat lze pouze FA bez zahrnutí ZP.',
  'buildingDrawingImportZpStep.hasNoAssignedObjectOnSomeInvoice': 'U červeně označených faktur je potřeba napárovat nové objekty.',
  'BuildingDrawingImportZpList.zpAssociationMembers': 'Partneři ve sdružení',
  'BuildingDrawingImportZpList.tooltip.error': 'Zjišťovací protokol nelze nahrát',
  'BuildingDrawingImportZpList.tooltip.new': 'Nový zjišťovací protokol',
  'BuildingDrawingImportZpList.tooltip.updatable': 'Existující zjišťovací protokol',
  'BuildingDrawingImportZpList.tooltip.cannotCreate': 'Zjišťovací protokol nelze vytvořit',
  'BuildingDrawingImportZpList.zpList': 'Zjišťovací protokoly k importu',
  'BuildingDrawingImportZpForm.delegateDph': 'Importem přenést povinnost doplnit a přiznat DPH',
  'BuildingDrawingImportZpForm.includeWiPreports': 'Zahrnout zjišťovací protokoly',
  'BuildingDrawingImportZpForm.importExistingSettingsLabel': 'Jak řešit duplicitní ZP',
  'BuildingDrawingImportZpForm.replaceExistingZPs': 'Smazat existující čerpání a nahradit je z importu',
  'BuildingDrawingImportZpForm.ignoreExistingZPs': 'Neimportovat ZP s duplicitním číslem',
  'BuildingDrawingImportZpForm.partners': 'Partneři',
  'BuildingDrawingImportZpForm.associationMembers': 'Členové sdružení',
  'BuildingDrawingImportZpForm.partnersInvalidCIN': 'Partneři s nevalidním IČ',
  'BuildingDrawingImportZpForm.selectAddress': 'Zvolte adresu',
  'BuildingDrawingUploadZpFormWizard.title': 'Import Zjišťovacího Protokolu',
  'BuildingDrawingDownloadZp.column.sign': 'Značka',
  'BuildingDrawingDownloadZp.column.name': 'Název',
  'BuildingDrawingDownloadZp.column.exposed': 'Vystavení',
  'BuildingDrawingDownloadZp.column.realizedQuantityType': 'Typ čerpaného množství',
  'BuildingDrawingDownloadZp.column.VATTransferRegime': 'Režim přenosu DPH',
  'BuildingDrawingDownloadZp.column.investor': 'Investor',
  'BuildingDrawingDownloadZp.column.supplier': 'Dodavatel',
  'BuildingDrawingDownloadZp.columnChooser': 'Výběr sloupců',
  'BuildingDrawingDownloadZp.successMessage': 'Úspěšný export',
  'BuildingDrawingDownloadZp.maxLengthErrorMessage': 'Export se nezdařil. Text některých položek je příliš dlouhý.',
  'BuildingDrawingDownloadZp.errorMessage': 'Export se nezdařil',
  'BuildingSchedulePage.title': 'Harmonogram',
  'BuildingSchedulePage.noScheduleNotice': 'Harmonogram není k dispozici.',
  'BuildingSchedulePage.uploadHMG': 'Nahrát HMG',
  'BuildingScheduleUploadHmgFormWizard.title': 'Import harmonogramu',
  'BuildingScheduleImportHmgForm.hmgVersionPlaceholder': 'Vyber verzi',
  'BuildingScheduleImportHmgForm.importDataType.all': 'HMG a FP',
  'BuildingScheduleImportHmgForm.importDataType.HmgOnly': 'Pouze HMG',
  'BuildingScheduleImportHmgForm.importDataType.FpOnly': 'Pouze FP',
  'BuildingScheduleImportHmgForm.importDataStyle.actualization': 'Aktualizace',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew': 'Nová verze',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.sign': 'Značka',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.name': 'Název',
  'BuildingScheduleImportHmgForm.import.duplicitySign': 'Harmonogram se zadanou značkou již existuje',
  'BuildingZbvPage.title': 'ZBV',
  'BuildingZbvPage.noProjectContent': 'ZBV nelze zobrazit.',
  'BuildingZbvPage.columns.sign': 'Kód / značka',
  'BuildingZbvPage.columns.name': 'Název',
  'BuildingZbvPage.columns.exposed': 'Vystavení',
  'BuildingZbvPage.columns.approved': 'Schválení',
  'BuildingZbvPage.columns.totalSellingPrice': 'Cena celkem',
  'BuildingZbvPage.columns.totalCostPrice': 'Cena celkem (NC)',
  'BuildingZbvPage.columns.spentPrice': 'Čerpáno',
  'BuildingZbvPage.columns.sodSellingPrice': 'Cena SoD',
  'BuildingZbvPage.columns.sodCostPrice': 'Cena SoD (NC)',
  'BuildingZbvPage.columns.zbvSellingPrice': 'Cena ZBV',
  'BuildingZbvPage.columns.zbvCostPrice': 'Cena ZBV (NC)',
  'BuildingZbvPage.columns.zbvSpentPrice': 'Čerpáno ZBV',
  'BuildingZbvPage.columns.type': 'Typ',
  'BuildingZbvPage.columns.contractAddendum': 'Smluvní dodatek',
  'BuildingZbvPage.zbvType.Investorsky': 'Investorský',
  'BuildingZbvPage.zbvType.Navrh': 'Návrh',
  'BuildingZbvPage.zbvType.Interni': 'Interní',
  'BuildingZbvPage.zbvApprove.cannotApprove': 'Nemáte dostatečné oprávnění',
  'BuildingZbvPage.zbvApprove.noDocument': 'ZBV není odesláno do AspeHub',
  'BuildingZbvUploadInvoiceFormWizard.title': 'Import ZBV',
  'BuildingZbv.addZbv': 'Nahrát ZBV',
  'BuildingZbv.addZbv.success': 'Data byla úspěšně nahrána do AspeEsticon. Nyní začíná proces přenosu do AspeHub.',
  'BuildingZbv.addZbv.disabled': 'Pro nahrání ZBV musí být Esticon projekt ve fázi realizace.',
  'BuildingZbvImportGrid.exists': 'Existuje',
  'BuildingZbvImportGrid.approved': 'Schváleno',
  'BuildingZbvImportGrid.drawn': 'Čerpáno',
  'BuildingZbvImportGrid.sentToHub': 'Odesláno na AspeHub',
  'BuildingZbvImportGrid.existingAddress': 'Existující adresa',
  'BuildingZbvImportGrid.importedAddress': 'Importovaná adresa',
  'BuildingZbvImportGrid.name': 'Název',
  'BuildingZbvImportGrid.partner': 'Partner',
  'BuildingZbvImportGrid.sign': 'Kód',
  'BuildingZbvImportGrid.systemPairing': 'Spárováno',
  'BuildingZbvImportZbv.existingZbv': 'Zbv se stejným číslem už existuje',
  'BuildingZbvImportZbv.criticalValorizationDifference': 'Z důvodu rozdílné valorizace na projektu a importovaném ZBV nelze v importu pokračovat.',
  'BuildingZbvImportZbv.noZbvsInOnlyZbvGroupImport': 'Soubor neobsahuje skupiny ZBV.',
  'BuildingZbvImportZbv.ZbvGroups': 'Skupiny ZBV',
  'BuildingZbvSkupinyImportPairing.sourceZbvGroup': 'Skupina ZBV v importu',
  'BuildingZbvSkupinyImportPairing.destinationZbvGroup': 'Existující skupina ZBV',
  'BuildingZbvImportZbv.noAddPartnerPermissionAskAdmin': 'Pro založení nového partnera nebo jeho adresy se obraťte na správce projektu.',
  'BuildingZbvImportZbv.protocolWithoutContractor': 'V importovaném ZP chybí dodavatel.',
  'BuildingZbvImportZbvForm.notifyReplace':
    'V importu jsou ZBV, které v projektu již existují. Při pokračování budou přepsány daty z importovaného souboru.',
  'BuildingZbvImportZbvList.zbvList': 'Seznam ZBV pro import',
  'BuildingZbvImportZbvList.zbvPairing': 'Párování údajů',
  'BuildingZbvBreakdown.title': 'Změnové listy',
  'BuildingZbvBreakdown.columns.zbvAmount': 'Množství ZBV',
  'BuildingZbvBreakdown.columns.zbvSellingPrice': 'Cena ZBV',
  'BuildingZbvBreakdown.columns.zbvCostPrice': 'Cena ZBV (NC)',
  'BuildingZbvBreakdown.columns.sodAmount': 'Množství SoD',
  'BuildingZbvBreakdown.columns.sodSellingPrice': 'Cena SoD',
  'BuildingZbvBreakdown.columns.sodCostPrice': 'Cena SoD (NC)',
  'BuildingZbvBreakdown.columns.totalAmount': 'Množství celkem',
  'BuildingZbvBreakdown.columns.totalSellingPrice': 'Cena celkem',
  'BuildingZbvBreakdown.columns.totalCostPrice': 'Cena celkem (NC)',
  'BuildingZbvBreakdown.columns.groups': 'Skupiny',
  'BuildingZbvBreakdown.columns.category': 'Kategorie',
  'BuildingZbvBreakdownHighlight.groupsBreakdown': 'Rozpis skupin',
  'BuildingZbvBreakdownHighlightToggles.groups.hide': 'Skrýt rozpis skupin',
  'BuildingZbvBreakdownHighlightToggles.groups.show': 'Zobrazit rozpis skupin',
  'BuildingInvoicingPage.title': 'Fakturace',
  'BuildingInvoicePage.columns.sign': 'Kód',
  'BuildingInvoicePage.columns.order': 'Pořadí',
  'BuildingInvoicePage.columns.timePeriod': 'Období',
  'BuildingInvoicePage.columns.priceWithoutVat': 'Cena bez DPH',
  'BuildingInvoicePage.columns.vatPriceBasic': 'Základní sazba DPH',
  'BuildingInvoicePage.columns.vatPriceReduced1': 'První snížená sazba DPH',
  'BuildingInvoicePage.columns.vatPriceReduced2': 'Druhá snížená sazba DPH',
  'BuildingInvoicePage.columns.totalPrice': 'Cena celkem',
  'BuildingInvoicePage.columns.administrator': 'Správce / Investor',
  'BuildingInvoicePage.columns.contractor': 'Dodavatel',
  'BuildingInvoicePage.columns.importContractor': 'Dodavatel z importu',
  'BuildingInvoicePage.columns.buildingContractor': 'Dodavatel ze stavby',
  'BuildingInvoicePage.columns.VATTransferRegime': 'Režim přenosu DPH',
  'BuildingInvoicePage.VATTransferRegime.NeuplatnujeSe': 'Neuplatňuje se',
  'BuildingInvoicePage.VATTransferRegime.ZaplatiPrijemce': 'Zaplatí příjemce',
  'BuildingInvoicePage.VATTransferRegime.PrenesenoOdPoskytovatele': 'Přeneseno od poskytovatele',
  'BuildingInvoicePage.displayModes.partial.label': 'Dílčí',
  'BuildingInvoicePage.displayModes.partial.tooltip': 'Dílčí faktury',
  'BuildingInvoicePage.displayModes.common.label': 'Spol.',
  'BuildingInvoicePage.displayModes.common.tooltip': 'Společné faktury',
  'BuildingInvoicingUploadInvoiceFormWizard.title': 'Import faktury',
  'BuildingInvoicing.addInvoice': 'Nahrát fakturu',
  'BuildingInvoicing.addInvoice.success': 'Data byla úspěšně nahrána do AspeEsticon. Nyní začíná proces přenosu do AspeHub.',
  'BuildingInvoicing.addInvoice.disabled': 'Pro nahrání faktury musí být Esticon projekt ve fázi realizace.',
  'BuildingInvoicingImportInvoice.newInvoice': 'Nová faktura',
  'BuildingInvoicingImportInvoice.existingInvoice': 'Faktura se stejným číslem už existuje',
  'BuildingInvoicingImportInvoiceForm.delegateDph': 'Importem přenést povinnost doplnit a přiznat DPH',
  'BuildingInvoicingImportInvoiceForm.replaceExistingInvoices': 'Smazat existující faktury a nahradit je z importu',
  'BuildingInvoicingImportInvoiceList.invoiceList': 'Seznam faktur pro import',
  'BuildingInvoicingPairingGrid.associationMembers': 'Členové sdružení',
  'BuildingInvoicingPairingGrid.newObjects': 'Nastavení nového objektu',
  'BuildingInvoicingPairingGrid.CIN': 'IČ',
  'BuildingInvoicingPairingGrid.name': 'Název',
  'BuildingInvoicingPairingGrid.sign': 'Značka',
  'BuildingInvoicingPairingGrid.street': 'Ulice',
  'BuildingInvoicingPairingGrid.city': 'Město',
  'BuildingInvoicingPairingGrid.ZIP': 'PSČ',
  'BuildingInvoicingInvoiceItemsList.title': 'Položky faktury',
  'BuildingInvoicingInvoiceItemsList.object': 'Objekt',
  'BuildingInvoicingInvoiceItemsList.objectNew': 'Nový objekt',
  'BuildingInvoicingInvoiceItemsList.drawn': 'Čerpáno',
  'BuildingInvoicingInvoiceItemsList.valorizationPercent': 'Valorizace %',
  'BuildingInvoicingInvoiceItemsList.valorizationValue': 'Valorizace',
  'BuildingInvoicingInvoiceItemsList.discountPercent': 'Sleva %',
  'BuildingInvoicingInvoiceItemsList.discountValue': 'Sleva',
  'BulkEditActionResultModal.content.updatedDocuments': 'Počet změněných dokumentů',
  'BulkEditActionResultModal.content.updatedLinks': 'Počet změněných odkazů dokumentů',
  'BulkEditActionResultModal.content.unchangedDocuments': 'Počet dokumentů beze změn',
  'BulkEditActionResultModal.content.unchangedLinks': 'Počet odkazů dokumentů beze změn',
  'BulkEditActionResultModal.moveTo': 'Přejít na Centrum zpráv',
  'BulkEditDocumentsLabelsForm.documentsCount': 'Počet vybraných souborů',
  'BulkEditDocumentsLabelsForm.title': 'Změnit štítky dokumentů',
  'BulkEditDocumentsLabelsForm.labels': 'Štítky',
  'BulkEditDocumentsLabelsForm.labels.required': 'Vyberte prosím alespoň jeden štítek',
  'BulkEditDocumentsLabelsForm.mode': 'Metoda změny štítků',
  'BulkEditDocumentsLabelsForm.mode.required': 'Vyberte prosím metodu pro změnu štítků',
  'BulkEditDocumentsLabelsForm.mode.add': 'Přidat štítky',
  'BulkEditDocumentsLabelsForm.mode.replace': 'Nahradit štítky',
  'BulkEditDocumentsLabelsForm.mode.remove': 'Odebrat štítky',
  'BulkEditDocumentsLabelsForm.impossibleChanges': 'U následujících dokumentů a odkazů na dokumenty nelze změnit štítky',
  'BulkEditDocumentsLabelsForm.resultModal.title': 'Výsledek akce - hromadná změna štítků',
  'BulkEditDocumentsStateForm.title': 'Změnit stav dokumentů',
  'BulkEditDocumentsStateForm.documentsCount': 'Počet zvolených dokumentů: {count}',
  'BulkEditDocumentsStateForm.executeChanges': 'Provést změny',
  'BulkEditDocumentsStateForm.executeChangesToOthers': 'Provést změny u ostatních',
  'BulkEditDocumentsStateForm.newDocumentsState': 'Nový stav dokumentů',
  'BulkEditDocumentsStateForm.newDocumentsState.required': 'Nastavte prosím stav dokumentů',
  'BulkEditDocumentsStateForm.newDocumentsState.impossibleChanges': 'U následujících dokumentů a odkazů na dokumenty nelze stav změnit na požadovaný',
  'BulkEditDocumentsStateForm.newDocumentsState.noAvailableStates': 'Pro zvolenou množinu dokumentů a odkazů neexistuje stav, který by mohl být společný',
  'BulkEditDocumentsStateForm.successModal.title': 'Výsledek akce - hromadná změna stavů',
  'BulkEditDocumentsWatchForm.impossibleChanges': 'U následujících dokumentů a odkazů na dokumenty nelze nastavit sledování',
  'BulkEditDocumentsWatchForm.info': 'Nové nastavení přepíše stávající nastavení sledování.',
  'BulkEditDocumentsWatchForm.resultModal.title': 'Výsledek akce - hromadná nastavení sledování',
  'BulkEditDocumentsWatchForm.title': 'Změna sledování dokumentů',
  'BulkEditDocumentsWatchForm.watches': 'Sledování',
  'BulkRemoveFromModelsModal.button.Remove': 'Odebrat',
  'BulkRemoveFromModelsModal.button.RemoveAndDeleteLinks': 'Odebrat modely a smazat vazby',
  'BulkRemoveFromModelsModal.title': 'Odebrat dokumenty z modelů',
  'BulkRemoveFromModelsModal.RemoveModels': `{count, plural,
    =0 {Chcete opravdu odebrat 0 vybraných dokumentů z modelů?}
    one {Chcete opravdu odebrat 1 vybraný dokument z modelů?}
    few {Chcete opravdu odebrat # vybrané dokumenty z modelů?}
    other {Chcete opravdu odebrat # vybraných dokumentů z modelů?}
  }`,
  'BulkRemoveFromModelsModal.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přípravě odebrání modelu došlo k chybě. Žádný model nebyl odebrán.}
    few {Při přípravě vyřazování modelů došlo k chybám. Žádný model nebyl odebrán.}
    other {Při přípravě vyřazování modelů došlo k chybám. Žádný model nebyl odebrán.}
  }`,
  'BulkAddToModelsModal.button.Add': 'Přidat',
  'BulkAddToModelsModal.button.AddOthers': 'Přidat ostatní',
  'BulkAddToModelsModal.title': 'Přidat dokumenty do modelů',
  'BulkAddToModelsModal.AddModels': `{count, plural,
    =0 {Chcete opravdu přidat 0 vybraných do modelů?}
    one {Chcete opravdu přidat 1 vybraný do modelů?}
    few {Chcete opravdu přidat # vybrané do modelů?}
    other {Chcete opravdu přidat # vybraných do modelů?}
  }`,
  'BulkAddToModelsModal.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přidání modelu došlo k chybě. Žádný model nebyl přidán.}
    few {Při přidání modelů došlo k chybám. Žádný model nebyl přidán.}
    other {Při přidání modelů došlo k chybám. Žádný model nebyl přidán.}
  }`,
  'CalendarDaySelect.workingDays': 'Pracovní dny',
  'CalendarDaySelect.calendarDays': 'Kalendářní dny',
  'CalendarExcludedDayImportForm.modal.title': 'Import českých svátků do kalendáře',
  'CalendarExcludedDayImportForm.modal.importError': 'Při importu svátků nastala chyba. Import se nezdařil.',
  'CalendarExcludedDayImportForm.modal.importWarning': 'Zvolené časové období neobsahuje žádné české svátky.',
  'CalendarExcludedDayImportForm.label.endDate': 'Načíst svátky do data',
  'CalendarExcludedDayForm.title.add': 'Přidat svátek',
  'CalendarExcludedDayForm.title.edit': 'Upravit svátek',
  'CalendarExcludedDayForm.repeating.label': 'Opakovat svátek každý rok ve stejný den',
  'CalendarSettingsDeleteButton.confirmDelete.message': 'Po smazání projektového kalendáře bude použito nastavení organizace. Opravdu smazat projektový kalendář?',
  'CalendarSettingsExcludedDaysItem.onlyOnce': 'Pouze jednou',
  'CalendarSettingsExcludedDaysItem.repeating': 'Opakuje se',
  'CalendarSettingsExcludedDaysItem.import': 'Import českých svátků',
  'CalendarSettingsForm.workingDays': 'Pracovní dny',
  'CalendarSettingsForm.excludedDays': 'Svátky a volné dny',
  'CalendarSettingsForm.filterPast.tooltip': 'Zobrazit pouze budoucí svátky ',
  'CalendarSettingsFormModal.title.organization': 'Nastavení kalendáře organizace',
  'CalendarSettingsFormModal.title.project': 'Nastavení kalendáře projektu',
  'CalendarSettingsFormModal.title.template': 'Nastavení kalendáře šablony',
  'CategoryList.deleteConfirmModal.description': 'Opravdu smazat kategorii a odstranit ji ze všech složek a dokumentů?',
  'CategoryList.deleteConfirmModal.delete': 'Smazat',
  'CategoryFilterItem.category.description': 'Kategorie',
  'CategoryFilterItem.category.placeholder': 'Název kategorie ...',
  'CategoryFilterItem.node.desc': 'Uzel',
  'CategoryFilterItem.node.placeholder': 'Název uzlu ...',
  'CertificateSignature.title': 'Podepsat dokument',
  'CertificateSignature.signButtonWithBankID': 'Podepsat s BankID',
  'CertificateSignature.signButtonCertificate': 'Podepsat certifikátem',
  'CertificateSignature.derivateWarning':
    'Chystáte se podepsat vygenerovaný PDF dokument z originálu. Při konverzi mohlo dojít ke změně formátování. Před podpisem dokument zkontrolujte.',
  'CertificateSignature.secondaryFileNotSignable':
    'Dokument obsahuje přílohu s podepsaným dokumentem ve formátu, který není možné podepsat online.',
  'CertificateSignature.form.signingError': 'Nastala chyba při podepisování dokumentu.',
  'CertificateSignature.error.invalidContentType.text': 'Dokument {document} není podporovaný typ pro podpis.',
  'CertificateSignature.reason.key': 'Podpis AspeHub',
  'CertificateSignature.reason.value': 'Dokument schválen uživatelem AspeHub',
  'CertificateSignature.error.storage': 'Nastala chyba při odeslání požadavku na podpis dokumentu. {error}',
  'CertificateSignature.error.redirectEmpty':
    'Nastala chyba při podpisu dokumentu. Adresu pro přihlášení do BankID se nepodařilo načíst.',
  'CertificateSignature.verifyModal.title': 'Zpracování podepsaného dokumentu',
  'CertificateSignature.verifyModal.status.downloading': 'Probíhá stahování podepsaného dokumentu',
  'CertificateSignature.verifyModal.status.finished': 'Dokument úspěšně podepsán',
  'CertificateSignature.verifyModal.status.error': 'Chyba při ukládání',
  'CertificateSignature.verifyModal.button.hide': 'Skrýt',
  'CertificateSignature.error.GetSignInfoForUploadError': 'Chyba při načítání informací o dokumentu pro podepsání',
  'CertificateSignature.error.DownloadSignedDocumentFromStorageError': 'Chyba při stahování dokumentu pro podepsání',
  'CertificateSignature.error.ReadingSignedMetadataError': 'Chyba při čtení informací z dokumentu pro podpis',
  'CertificateSignature.error.InitialDocumentRequestError': 'Chyba při založení požadavku na podpis v BankID',
  'CertificateSignature.error.RosUrlsError': 'Chyba při získávání adresy pro nahrání dokumentu pro podpis',
  'CertificateSignature.error.SigningRequestExpiredError': 'Požadavku na podpis dokumentu vypršela platnost',
  'CertificateSignature.error.InitialBankIdRequestError': 'Požadavek na podpis dokumentu skončil chybou',
  'CertificateSignature.error.FileUploadBankIDError': 'Chyba při nahrávání dokumentu pro podpis do BankID',
  'CertificateSignature.error.GetSignInfoForDownloadError': 'Chyba při načítání dat pro stažení podepsaného dokumentu',
  'CertificateSignature.error.DownloadSignedDocError': 'Chyba při stažení podepsaného dokumentu z BankID',
  'CertificateSignature.error.UploadSignedDocError': 'Chyba při nahrávání podepsaného dokumentu do AspeHub',
  'CertificateSignature.error.CreateSignedDocError': 'Chyba při nastavení nahraného souboru jako podepsaného dokumentu',
  'CertificateSignature.error.ClientNotEligible': 'Uživatel bankovní identity nemá oprávnění podepisovat dokumenty',
  'CertificateSignature.error.AccessDenied': 'Uživatel nebyl ověřen bankovní identitou',
  'CertificateSignature.error.DocumentMissingCreateDate': 'Dokument neobsahuje povinný údaj datum vytvoření',
  'CertificateSignature.error.UnknownError': 'Nastala chyba při ověření uživatele přes bankovní identitu',
  'ColorPickerInput.refresh': 'Vygenerovat barvu automaticky',
  'DiscussionNote.closeEditing': 'Zrušení editace',
  'DiscussionNote.edit': 'Upravit',
  'DiscussionNote.delete': 'Odstranit',
  'DiscussionNote.addAttachments': 'Připojit přílohu',
  'DiscussionNote.deletedNote': 'Tento komentář byl odstraněn.',
  'DiscussionNote.restore': 'Zpět',
  'DiscussionNote.linkAttachments.title': 'Připojit přílohy',
  'DiscussionNote.linkAttachments.link': 'Připojit',
  'DiscussionNote.Notification.noticeWasSentTo': 'Upozorněni: ',
  'DiscussionNote.Notification.sendNoticeTo': 'Upozorní se: ',
  'DiscussionNote.Notification.tooltipToAddUserButton': 'Vybrat uživatele pro zaslání upozornění',
  'DiscussionNote.Notification.teamDropdownButton': 'Označit uživatele z týmu',
  'DiscussionNote.Notification.roleDropdownButton': 'Označit uživatele z role',
  'DiscussionNote.Notification.modalTitleUserSelecting': 'Výběr uživatelů pro zaslaní upozornění',
  'DiscussionNote.Notification.mainProcessor': 'Hlavní zpracovatelé',
  'DiscussionNote.Notification.mainSubmitter': 'Hlavní připomínkující',
  'CommentProcedure.general.processorTeams': 'Týmy zpracovatelů',
  'CommentProcedure.general.submitterTeams': 'Týmy připomínkujících',
  'CommentProcedure.general.category': 'Kategorie připomínky',
  'CommentProcedureAddCommentForm.annotationMessageSegment': 'Strana: {page}\nPřipomínka: {content}',
  'CommentProcedureAddCommentForm.title': 'Název připomínky',
  'CommentProcedureAddCommentForm.title.required': 'Prosím vyplňte název připomínky',
  'CommentProcedureAddCommentForm.message': 'Text připomínky',
  'CommentProcedureAddCommentForm.message.required': 'Prosím vyplňte text připomínky',
  'CommentProcedureAddCommentForm.commentProcedureCommentDocuments': 'Připomínkované dokumenty',
  'CommentProcedureAddCommentForm.notSet': 'Není nastaveno',
  'CommentProcedureAddCommentForm.commentProcedure': 'K připomínkovému řízení',
  'CommentProcedureAddCommentFormModal.title': 'Přidat novou připomínku',
  'CommentProcedureAddForm.commentedDocuments': 'Dokumenty k připomínkování',
  'CommentProcedureAddForm.commentedDocuments.required': 'Prosím vyberte dokumenty k připomínkování.',
  'CommentProcedureAddForm.commentedDocuments.modalTitle': 'Vybrat dokumenty k připomínkování',
  'CommentProcedureAddForm.name': 'Název řízení',
  'CommentProcedureAddForm.name.required': 'Prosím vyplňte název řízení.',
  'CommentProcedureAddForm.description': 'Popis řízení',
  'CommentProcedureAddForm.headOfSubmitters': 'Hlavní připomínkující',
  'CommentProcedureAddForm.headOfProcessors': 'Hlavní zpracovatelé',
  'CommentProcedureAddForm.role.required': 'Prosím vyberte roli.',
  'CommentProcedureAddFormModal.title': 'Vytvořit nové připomínkové řízení',
  'CommentProcedureCommentsAddAttachment.addAttachment': 'Přidat přílohu',
  'AttachmentHeader.addDocument': 'Vybrat z dostupných',
  'AttachmentHeader.addNewFile': 'Nahrát nový soubor',
  'CommentProcedureCommentsAddAttachment.discadToggleTooltip': 'Zobrazit vyřazené',
  'AttachmentList.discardAttachment.tooltip': 'Vyřadit přílohu',
  'AttachmentList.restoreAttachment.tooltip': 'Obnovit přílohu',
  'AttachmentList.insufficientPermissionTooltip': 'Nedostatečné oprávnění',
  'AttachmentList.discardAttachment.confirm': 'Opravdu chcete vyřadit tuto přílohu?',
  'AttachmentList.restoreAttachment.confirm': 'Opravdu chcete obnovit tuto přílohu?',
  'CommentProcedureDeadline.for.Commenting': 'Termín pro zadání připomínek',
  'CommentProcedureDeadline.for.InternalNegotiations': 'Termín pro interní projednání',
  'CommentProcedureDeadline.for.Negotiations': 'Termín pro projednávání připomínek',
  'CommentProcedureDeadline.for.Incorporation': 'Termín pro zapracování připomínek',
  'CommentProcedureDeadline.for.Approval': 'Termín pro schválení připomínek',
  'CommentProcedureDeadline.for.Approved': 'Termín pro schválení',
  'CommentProcedureDeadline.for.Closed': 'Termín for ukončení',
  'CommentProcedureDeadlineForm.reason.required': 'Prosím vyplňte důvod změny',
  'CommentProceduresPageToolbar.addCommentProcedureSuccess': 'Nové připomínkové řízení bylo úspěšně založeno.',
  'CommentProceduresPageToolbar.export': 'Export připomínkových řízení',
  'CommentProceduresPageToolbar.newComment': 'Nové připomínkové řízení',
  'CommentProcedureDetailPage.newComment': 'Nová připomínka',
  'CommentProcedureAddDocumentButton.addDocuments': 'Přidat dokumenty',
  'CommentProcedureAddDocumentButton.commentDocument.title': 'Přidat dokument k připomínce',
  'CommentProcedureAddDocumentButton.selectDocument': 'Vybrat z dostupných',
  'CommentProcedureAddDocumentButton.selectDocument.title': 'Přidat dokumenty k připomínkování',
  'CommentProcedureAddDocumentButton.uploadDocument': 'Nahrát nový dokument',
  'CommentProcedureCommentDocuments.removeDocument.tooltip': 'Odebrat dokument',
  'CommentProcedureCommentDocuments.removeDocument.confirm': 'Opravdu chcete odebrat dokument z připomínky?',
  'CommentProcedureCommentDocuments.downloadWithAnnotationDocument.error.notFound': 'Dokument ke stažení nenalezen',
  'CommentProcedureCommentDiscussion.tabs.common': 'Společná',
  'CommentProcedureCommentDiscussion.tabs.internalSubmitter': 'Interní připomínkující',
  'CommentProcedureCommentDiscussion.tabs.internalProcessor': 'Interní zpracovatelé',
  'CommentProcedureDetailPage.commentName': 'Název připomínky',
  'CommentProcedureDetailPage.export': 'Exportovat PŘ',
  'CommentProcedureDetailPage.changeStateMultiple': 'Změnit stav připomínek',
  'CommentProcedureDetailPage.multipleSelectTitle': 'Hromadný výběr připomínek',
  'CommentProcedureDetailPage.filterUserTitle': 'Autor připomínky',
  'CommentProcedureDetailPage.closeProcedure': 'Ukončit PŘ',
  'CommentProcedureDetailPage.closeProcedure.confirm': 'Opravdu chcete ukončit toto připomínkové řízení?',
  'CommentProcedureDetailPage.tabs.comments': 'Připomínky',
  'CommentProcedureDetailPage.tabs.documents': 'Dokumenty ({count})',
  'CommentProcedureDetailPage.tabs.users': 'Uživatelé',
  'CommentProcedureDocument.revisionDateLabel': 'Revize v PŘ: {date}',
  'CommentProcedureDocumentDownloadModal.comentStateFilter.text': 'Stav připomínek s komentáři pro stažení',
  'CommentProcedureDocumentDownloadModal.finishInBackground.text': 'Zavřít (dokončit na pozadí)',
  'CommentProcedureDocumentTab.annotate.tooltip': 'Komentovat',
  'CommentProcedureDocumentTab.compareRevisions.tooltip': 'Porovnat revize',
  'CommentProcedureDocumentTab.downloadWithAnnotations.tooltip': 'Stáhnout s komentáři',
  'CommentProcedureDocumentTab.remove.confirm': 'Opravdu chcete odebrat tento dokument z připomínkového řízení?',
  'CommentProcedureDocumentTab.remove.tooltip': 'Odebrat dokument z PŘ',
  'CommentProcedureDocumentTab.filter.inComment.label': 'Připomínkováno',
  'CommentProcedureDocumentTab.filter.inComment.tooltip': 'Zobrazit dokumenty, které jsou součástí některé připomínky.',
  'CommentProcedureDocumentTab.filter.inComment.yes': 'Ano - pouze připomínkované dokumenty',
  'CommentProcedureDocumentTab.filter.inComment.no': 'Ne - pouze nepřipomínkované dokumenty',
  'CommentProcedureDocumentsTab.revisionsModalTitle': 'Revize dokumentu na tomto připomínkovém řízení',
  'CommentProcedureErrors.noErrors': 'Připomínkové řízení neobsahuje žádné chyby',
  'CommentProcedureErrors.severity': 'Závažnost chyby',
  'CommentProcedureErrors.subject': 'Typ chyby',
  'CommentProcedureErrors.roleOrUserId': 'Ovlivněná role/uživatel',
  'CommentProcedureErrors.subjectName': 'Název objektu',
  'CommentProcedureErrors.commentProcedureRole': 'Typ týmu',
  'CommentProceduresListItem.createdDate': 'Vytvořeno: {date}',
  'CommentProceduresListItem.createdBy': 'Vytvořil uživatel',
  'CommentProceduresListItem.hasWorkflow': 'Připojena instance workflow',
  'CommentProcedurePhaseEnum.Commenting': 'Připomínkování',
  'CommentProcedurePhaseEnum.InternalNegotiations': 'Interní projednávání',
  'CommentProcedurePhaseEnum.Negotiations': 'Projednávání',
  'CommentProcedurePhaseEnum.Incorporation': 'Zapracování',
  'CommentProcedurePhaseEnum.Approval': 'Schvalování',
  'CommentProcedurePhaseEnum.Approved': 'Schváleno',
  'CommentProcedurePhaseEnum.Closed': 'Ukončeno',
  'CommentProcedureSetState.documentsWarning':
    'Některé dokumenty jsou ve stavech Archivováno či Zamítnuto a nelze je proto schválit. Přejete si schválit všechny ostatní dokumenty?',
  'CommentProcedureSetState.approveDocuments': 'Schválit dokumenty v rámci připomínkového řízení',
  'CommentProcedureSetState.dontApproveDocuments': 'Neschvalovat dokumenty v rámci připomínkového řízení',
  'CommentProcedureSetState.target.internalNegotiations.button': 'Předat k internímu projednání',
  'CommentProcedureSetState.target.internalNegotiations.confirm':
    'Opravdu chcete předat připomínkové řízení k internímu projednání?',
  'CommentProcedureSetState.target.negotiations.button': 'Odeslat reakce',
  'CommentProcedureSetState.target.negotiations.confirm':
    'Opravdu chcete zobrazit reakce na připomínky straně připomínkujících?',
  'CommentProcedureSetState.target.incorporation.button': 'Předat k zapracování',
  'CommentProcedureSetState.target.incorporation.confirm': 'Opravdu chcete předat připomínkové řízení k zapracování?',
  'CommentProcedureSetState.target.approval.button': 'Předat ke schválení',
  'CommentProcedureSetState.target.approval.confirm': 'Opravdu chcete předat připomínkové řízení ke schválení?',
  'CommentProcedureSetState.target.approved.button': 'Schválit',
  'CommentProcedureSetState.target.approved.confirm':
    'Opravdu chcete připomínkové řízení schválit? Potvrzením bude schváleno celé připomínkové řízení.',
  'CommentProcedureCommentsAttachments.button.publish.tooltip': 'Zveřejnit',
  'CommentProcedureCommentsAttachments.relationIcon.both.tooltip': 'Veřejný',
  'CommentProcedureCommentsAttachments.relationIcon.processor.tooltip': 'Interní pro zpracovatele',
  'CommentProcedureCommentsAttachments.relationIcon.submitter.tooltip': 'Interní pro připomínkující',
  'CommentProcedureCommentDiscussionRelationEnum.None': 'Žádný',
  'CommentProcedureCommentDiscussionRelationEnum.Processor': 'Zpracovatelé',
  'CommentProcedureCommentDiscussionRelationEnum.Submitter': 'Připomínkující',
  'CommentProcedureCommentDiscussionRelationEnum.Both': 'Veřejný',
  'CommentProcedureCommentStateEnum.Commenting': 'Zadáno',
  'CommentProcedureCommentStateEnum.Discarded': 'Vyřazeno',
  'CommentProcedureCommentStateEnum.InternallyDiscarded': 'Interně vyřazeno',
  'CommentProcedureCommentStateEnum.ForDiscussion': 'K diskuzi',
  'CommentProcedureCommentStateEnum.ForSolution': 'K řešení',
  'CommentProcedureCommentStateEnum.ForIncorporation': 'K zapracování',
  'CommentProcedureCommentStateEnum.Incorporated': 'Zapracováno',
  'CommentProcedureCommentStateEnum.Approved': 'Schváleno',
  'CommentProcedureBulkUpdateForm.state': 'Nový stav',
  'CommentProcedureBulkUpdateFormModal.title': 'Hromadná změna stavu připomínek',
  'CommentProcedureCreateTeamForm.name': 'Název týmu',
  'CommentProcedureCreateTeamForm.name.required': 'Prosím vyplňte název týmu',
  'CommentProcedureCreateTeamForm.commentProcedureRole': 'Typ týmu',
  'CommentProcedureCreateTeamForm.commentProcedureRole.required': 'Prosím vyplňte typ týmu',
  'CommentProcedureCreateTeamFormModal.title': 'Přidat nový tým',
  'CommentProcedureDetailPage.tabs.participants': 'Účastníci',
  'CommentProcedureEditTeamFormModal.title': 'Upravit tým',
  'CommentProcedureParticipants.tabName.responsiblePersons': 'Odpovědné osoby',
  'CommentProcedureParticipants.tabName.teams': 'Týmy',
  'CommentProcedureParticipants.tabName.users': 'Uživatelé',
  'CommentProcedureTeams.addTeam': 'Přidat tým',
  'CommentProcedureTeams.teamAdmin': 'Vedoucí',
  'CommentProcedureTeams.teamAdmins': 'Vedoucí týmu',
  'CommentProcedureTeams.teamMember': 'Člen',
  'CommentProcedureTeams.teamMembers': 'Členové týmu',
  'CommentProcedureTeams.moveToAdmins': 'Přesunout do vedoucích',
  'CommentProcedureTeams.moveToMembers': 'Přesunout do členů',
  'CommentProcedureTeams.lastTeamWarning.title': 'Odebíráte sami sebe',
  'CommentProcedureTeams.lastTeamWarning.message':
    'Odebráním se ztratíte přístup do tohoto připomínkového řízení. Opravdu se chcete odebrat?',
  'CommentProcedureTeams.memberAllowStatusChange': 'Členové mohou měnit stav připomínek',
  'CommentProcedureRoleEnum.Processor': 'Zpracovatel',
  'CommentProcedureRoleEnum.Processors': 'Zpracovatelé',
  'CommentProcedureRoleEnum.Submitter': 'Připomínkující',
  'CommentProcedureRoleEnum.Submitters': 'Připomínkující',
  'CommentProcedureCommentsDetails.teamNotSet': 'Nepřiřazen',
  'CommentProcedureCommentsDetails.tabs.discussion': 'Diskuze',
  'CommentProcedureCommentsDetails.tabs.annotations': `Komentáře {count, plural,
    =0 {}
    other {(#)}
  }`,
  'CommentProcedureCommentsDetails.tabs.documents': `Dokumenty {count, plural,
    =0 {}
    other {(#)}
  }`,
  'CommentProcedureCommentsDetails.notSaveAlert':
    'Připomínka není uložena. Pokud budete pokračovat, změny budou ztraceny.',
  'CommentProcedureCommentsNote.notSaveAlert': 'Komentář není uložený. Pokud budete pokračovat, změny budou ztraceny.',
  'CommentProcedureExportForm.author': 'Autor připomínky',
  'CommentProcedureExportForm.ButtonFilter': 'Přidat filtr',
  'CommentProcedureExportForm.Filter': 'Filtrovat',
  'CommentProcedureExportForm.sortA_Z': 'Seřadit od A do Z (Sestupně)',
  'CommentProcedureExportForm.sortBy': 'Řadit dle',
  'CommentProcedureExportForm.sortId': 'ID (chronologicky)',
  'CommentProcedureExportForm.state': 'Stav připomínky',
  'CommentProcedureExportItemFormModal.button': 'Exportovat',
  'CommentProcedureExportItemFormModal.title': 'Export připomínek',
  'CommentProcedureDetailPage.tabs.categories': 'Kategorie',
  'CommentProcedureDetailPage.tabs.errors': 'Chyby',
  'CommentProcedureCategories.tabName.categories': 'Kategorie',
  'CommentProcedureCreateCategoryForm.name': 'Název kategorie',
  'CommentProcedureCreateCategoryForm.name.required': 'Prosím vyplňte název kategorie',
  'CommentProcedureCreateCategoryForm.description': 'Popis kategorie',
  'CommentProcedureCreateCategoryFormModal.title': 'Přidat novou kategorii',
  'CommentProcedureEditCategoryFormModal.title': 'Upravit kategorii',
  'CommentProcedureCategories.addCategory': 'Přidat kategorii',
  'CommentProcedureCategories.import': 'Importovat kategorie',
  'CommentProcedureCategories.importSubmitButton': 'Importovat',
  'CommentProcedureImportCategoryForm.commentProcedure': 'Z připomínkového řízení',
  'CommentProcedureImportCategoryForm.commentProcedure.required': 'Prosím vyberte připomínkové řízení',
  'CommentProcedureImportCategoryFormModal.title': 'Import kategorií',
  'CommentProcedureLinkAttachment.tooltip.attach': 'Připojit přílohy',
  'CommentProcedureLinkAttachment.staged.tooltip': 'Připraveno pro nahrání',
  'CommentProcedureLinkAttachment.tooltip.disabled': 'Nelze připojit přílohu',
  'CommentProcedureLinkAttachment.tooltip.maxAttachmentsExceeded': 'Připojeno maximální množství příloh',
  'CommentProcedureLinkAttachment.tooltip.noAttachments': 'Nejsou k dispozici žádné přílohy',
  'CommentProcedureLinkAttachment.tooltip.noMoreAttachments': 'Nejsou k dispozici žádné další přílohy',
  'CommentProcedureRolesSettings.mainSubmitterCanEditProcessorTeams':
    'Hlavní připomínkující může nastavit tým zpracovatelů',
  'CommentProcedureRolesSettings.mainProcessorCanEditSubmitterTeams':
    'Hlavní zpracovatel může nastavit tým připomínkujících',
  'CommentProcedureRolesSettings.title': 'Připomínkové řízení a workflow',
  'CommentProcedureRolesSettings.useWorkDays': 'Nastavit výchozí pracovní dny místo kalendářních',
  'DiscussionAssignAttachmentForm.assignedDocuments': 'Připojované dokumenty do příloh',
  'DiscussionAssignAttachmentForm.assignedDocuments.modalTitle': 'Vybrat dokumenty pro přidání do příloh',
  'DiscussionLinkAttachment.warning.publishing': 'Dojde ke zveřejnění interních příloh',
  'DiscussionLinkAttachmentForm.title': 'Pojmenování přílohy',
  'DiscussionLinkAttachmentForm.name': 'Jméno souboru',
  'DiscussionLinkAttachmentForm.name.required': 'Prosím zadejte název souboru pro vkládaný obrázek',
  'DiscussionLinkAttachmentForm.disabled': 'Připojeno maximální množství příloh, nelze přiložit nový obrázek.',
  'CommentText.messageTitle': 'Text připomínky:',
  'CommentText.additionalMessageTitle': 'Reakce na připomínku:',
  'DiscussionInput.placeholder': 'Vložit komentář...',
  'DiscussionInput.send': 'Odeslat zprávu',
  'CompareRevisions.title': 'Porovnání revizí dokumentu',
  'CompareRevisions.firstRevision': 'První revize:',
  'CompareRevisions.secondRevision': 'Druhá revize:',
  'CompareRevisions.comparisonType': 'Typ porovnání:',
  'CompareRevisions.comparisonType.Text': 'Text',
  'CompareRevisions.comparisonType.Drawing': 'Výkres (PDF)',
  'CompareRevisions.comparisonType.ModelDiffTool': 'Model (DiffTool)',
  'DateFilter.unlimited': '(neomezeno)',
  'DateRangeForm.monthFrom': 'Měsíc od',
  'DateRangeForm.monthTo': 'Měsíc do',
  'DateRangeForm.title': 'Výběr data od/do',
  'DateRangeForm.yearFrom': 'Rok od',
  'DateRangeForm.yearTo': 'Rok do',
  'DateRangeForm.reset': 'Reset',
  'DateRangeForm.cancel': 'Zrušit',
  'DateRangeForm.confirm': 'Potvrdit',
  'DateRangeForm.defaultRange': 'Výchozí rozsah',
  'DateRangeForm.previousRange': 'Poslední nastavený rozsah',
  'DateSelect.n_workDays': `{workDays, plural,
    =0 {tento pracovní den}
    one {příští pracovní den}
    few {# pracovní dny}
    other {# pracovních dnů}
  }`,
  'DateSelect.n_weekDays': `{weekDays, plural,
    =0 {tento všední den}
    one {příští všední den}
    few {# všední dny}
    other {# všedních dnů}
  }`,
  'DateSelect.n_calendarDays': `{calendarDays, plural,
    =0 {dnes}
    one {zítra}
    few {# dny}
    other {# dnů}
  }`,
  'Deadline.changeDeadline': 'Změnit termín',
  'Deadline.changeReason': 'Důvod změny:',
  'Deadline.unlimited': 'Bez omezení',
  'DeepShareDownloadFormModal.title': 'Odkaz ke stažení složky',
  'DeepShareDownloadFormModal.success': 'Odkaz ke stažení složky byl úspěšně odeslán.',
  'DerivativesFileViewerModal.firstPagePreview': 'Titulní strana',
  'DerivativesFileViewerModal.preview': 'Náhled',
  'DerivativesFileViewerModal.original': 'Originál',
  'DerivativesFileViewerModal.signedDocument': 'Podepsaný dokument',
  'DerivativesFileViewerModal.discardedAttachment': 'Vyřazený',
  'DeleteButton.cannotDeleteYourself': 'Nelze odstranit sám sebe',
  'DeleteButton.orgHasProject': 'Nelze odstranit organizaci, ve které již je projekt',
  'DeleteButton.isLastAdmin': 'Nelze odstranit posledního administrátora',
  'DocumentsMoveFormModal.destinationDirectoryId.label': 'Do složky',
  'docMenu.restore': 'Obnovit',
  'DiscardDirectory.Modal.description': 'Opravdu chcete vyřadit vybranou složku včetně všech dokumentů a podsložek?',
  'DiscardDirectory.Modal.description.links':
    'Některé dokumenty obsahují vazby. Opravdu chcete vyřadit vybranou složku včetně všech dokumentů, vazeb a podsložek?',
  'DiscardDirectory.Modal.title': 'Vyřazení složky',
  'DiscardDirectoryLink.Modal.description': 'Opravdu chcete smazat odkaz na složku?',
  'DiscardDirectoryLink.Modal.title': 'Smazání odkazu na složku',
  'DiscardDirectoryLink.Modal.targetFolder': 'Odkazovaná složka: ',
  'DiscardedDocumentRow.discarded': 'Vyřazeno',
  'DiscardedDocumentRow.discardedBy': 'Vyřazeno uživatelem',
  'DiscardedDocumentRow.discardedTitle': 'Vyřazeno: {date}',
  'DiscardedDocumentsPage.loading': 'Načítám vyřazené dokumenty a složky...',
  'DiscardedDocumentRow.restore': 'Obnovit',
  'DiscardedFolderRow.discardedBy': 'Vyřazeno uživatelem',
  'DiscardedFolderRow.discardedTitle': 'Vyřazeno: {date}',
  'DiscardedFolderRow.restoreFolder': 'Obnovit složku',
  'DiscardedFolderRow.checkboxTooltip': 'Složky nelze vybírat hromadně',
  'DocumentCompleteList.downloadedZipName': 'dokumenty',
  'DocumentList.cannotSelectDiscarded': 'Nelze vybrat vyřazený dokument',
  'DocumentMultipleActionError.error.NotFound': 'Dokument <strong>{name}</strong> nenalezen',
  'DocumentMultipleActionError.error.IsDiscarded': 'Dokument <strong>{name}</strong> byl vyřazen',
  'DocumentMultipleActionError.error.BadDirectory':
    'Dokument <strong>{name}</strong> není ve zdrojové složce. Byl přesunut do: {path}.',
  'DocumentMultipleActionError.error.AccessDenied':
    'K dokumentu <strong>{name}</strong> nemáte dostatečné oprávnění.',
  'DocumentMultipleActionError.error.IsNotDiscarded': 'Dokument <strong>{name}</strong> nebyl vyřazen',
  'DocumentMultipleActionError.error.HasDocToDocLink': `{count, plural,
=0 {no}
one {Dokument <strong>{name}</strong> má přiřazenu 1 vazbu. Vyřazením dojde k jejímu smazání.}
few {Dokument <strong>{name}</strong> má přiřazeny # vazby. Vyřazením dojde k jejich smazání.}
other {Dokument <strong>{name}</strong> má přiřazeno # vazeb. Vyřazením dojde k jejich smazání.}
}`,
  'DocumentMultipleActionError.error.HasModelElementLink': `{count, plural,
  =0 {no}
  one {Model <strong>{name}</strong> má přiřazenu 1 vazbu na dokument. Odebráním dojde k jejímu smazání.}
  few {Model <strong>{name}</strong> má přiřazeny # vazby na dokumenty. Odebráním dojde k jejich smazání.}
  other {Model <strong>{name}</strong> má přiřazeno # vazeb na dokumenty. Odebráním dojde k jejich smazání.}
  }`,
  'DocumentMultipleActionError.error.InWorkflow': 'Dokument <strong>{name}</strong> je přiřazen v některém workflow.',
  'DocumentMultipleActionError.error.InCommentProcedure': 'Dokument <strong>{name}</strong> je v připomínkovém řízení.',
  'DocumentMultipleActionError.error.InActiveWorkflow': 'Dokument <strong>{name}</strong> je již v aktivním workflow.',
  'DocumentMultipleActionError.error.InActiveCommentProcedure':
    'Dokument <strong>{name}</strong> je již v aktivním připomínkovém řízení.',
  'DocumentMultipleActionError.error.InappropriateDocumentState':
    'Dokument <strong>{name}</strong> musí být ve stavu "Sdíleno".',
  'DocumentMultipleActionError.error.Reserved': 'Dokument <strong>{name}</strong> je rezervován.',
  'DocumentMultipleActionError.error.HasOwner': 'Dokument <strong>{name}</strong> má správce.',
  'DocumentMultipleActionError.error.EsticonDocument': 'Dokument <strong>{name}</strong> je importován z AspeEsticon.',
  'DocumentMultipleActionError.error.EsticonDocumentDilci':
    'Dokument <strong>{name}</strong> je dílčí součíst sdruženého importu z AspeEsticon.',
  'DocumentMultipleActionError.error.LinkNotFound': 'Odkaz na dokument <strong>{name}</strong> nebyl nalezen.',
  'DocumentMultipleActionError.error.LinkBadDirectory': 'Odkaz <strong>{name}</strong> odkazuje do chybné složky.',
  'DocumentMultipleActionError.error.LinkAccessDenied':
    'Pro zobrazení odkazovaného dokumentu <strong>{name}</strong> nemáte dostatečné oprávnění.',
  'DocumentMultipleActionError.error.LinkedDocumentAccessDenied':
    'Na odkazovaný dokument <strong>{name}</strong> nemáte dostatečné oprávnění.',
    'DocumentMultipleActionError.error.NotInModel':'Dokument <strong>{name}</strong> není v modelech, nelze odebrat.',
    'DocumentMultipleActionError.error.AlreadyInModel':'Dokument <strong>{name}</strong> již je v modelech.',
  'DocumentsGate.loading': 'Načítám dokumenty...',
  'DocumentRow.tooltip.lastRevision': 'Poslední revize: {date}',
  'DocumentRow.tooltip.originalDocument': '(Vznik dokumentu: {date})',
  'DocumentRow.tooltip.attachmentUpload': 'Datum nahrání: {date}',
  'DocumentRow.tooltip.revisionCreated': 'Datum vytvoření revize: {date}',
  'DocumentRow.tooltip.documentLinkCreated': 'Založení vazby: {date}',
  'DocumentsDiscardFormModal.button.Discard': 'Vyřadit',
  'DocumentsDiscardFormModal.button.DiscardNext': 'Vyřadit ostatní',
  'DocumentsDiscardFormModal.button.DiscardLinks': 'Smazat Vazby a Reference a vyřadit dokumenty',
  'DocumentsDiscardFormModal.button.DiscardLinksNext': 'Smazat Vazby a Reference a vyřadit ostatní dokumenty',
  'DocumentsDiscardForm.DiscardDocuments': `{count, plural,
    =0 {Chcete opravdu vyřadit 0 vybraných dokumentů?}
    one {Chcete opravdu vyřadit 1 vybraný dokument?}
    few {Chcete opravdu vyřadit # vybrané dokumenty?}
    other {Chcete opravdu vyřadit # vybraných dokumentů?}
  }`,
  'DocumentsDiscardForm.DiscardDocumentLinks': `{count, plural,
    =0 {Chcete opravdu vyřadit 0 vybraných odkazů na dokumenty?}
    one {Chcete opravdu vyřadit 1 vybraný odkaz na dokument?}
    few {Chcete opravdu vyřadit # vybrané odkazy na dokumenty?}
    other {Chcete opravdu vyřadit # vybraných odkazů na dokumenty?}
  }`,
  'DocumentsDiscardForm.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přípravě vyřazování dokumentů došlo k chybě. Žádný dokument nebyl vyřazen.}
    few {Při přípravě vyřazování dokumentů došlo k chybám. Žádný dokument nebyl vyřazen.}
    other {Při přípravě vyřazování dokumentů došlo k chybám. Žádný dokument nebyl vyřazen.}
  }`,
  'DocumentsDiscardFormModal.title': 'Vyřazení dokumentů',
  'DocumentsDownloadForm.downloadDocuments': `{count, plural,
    =0 {Stáhnout 0 vybraných dokumentů}
    one {Stáhnout 1 vybraný dokument}
    few {Stáhnout # vybrané dokumenty}
    other {Stáhnout # vybraných dokumentů}
  }`,
  'DocumentsDownloadForm.downloadDocumentLinks': `{count, plural,
    =0 {Stáhnout 0 vybraných odkazovaných dokumentů}
    one {Stáhnout 1 vybraný odkazovaný dokument}
    few {Stáhnout # vybrané odkazované dokumenty}
    other {Stáhnout # vybraných odkazovaných dokumentů}
  }`,
  'DocumentsDownloadForm.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přípravě archivu došlo k chybě. Archiv nebyl stažen.}
    few {Při přípravě archivu došlo k chybám. Archiv nebyl stažen.}
    other {Při přípravě archivu došlo k chybám. Archiv nebyl stažen.}
  }`,
  'DocumentsDownloadForm.form.items.name.rules.required': 'Prosím zadejte název archivu s dokumenty',
  'DocumentsDownloadForm.form.items.name.rules.valid': 'Prosím zadejte platný název souboru',
  'DocumentsDownloadForm.nameLabel': 'Jméno archivu',
  'DocumentsDownloadFormModal.button.download': 'Stáhnout',
  'DocumentsDownloadFormModal.button.downloadNext': 'Stáhnout ostatní',
  'DocumentsDownloadFormModal.title': 'Stáhnout dokumenty',
  'DocumentsGridHeader.select': 'Vybrat vše',
  'DocumentsGridHeader.deselect': 'Zrušit výběr',
  'DocumentLinkRow.missingDocument.error': 'Odkazovaný dokument nebyl nalezen',
  'DocumentLinkRow.missingDocument.restoreTooltip': 'Obnovit odkazovaný dokument',
  'DocumentLinkRow.missingDocument.contactAdminTooltip': 'Kontaktujte svého admina',
  'DocumentsMoveFormModal.button.move': 'Přesunout',
  'DocumentsMoveFormModal.button.moveAndRename': 'Přesunout a přejmenovat',
  'DocumentsMoveFormModal.button.moveNext': 'Přesunout ostatní',
  'DocumentsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Vše v pořádku}
    one {Následující dokument se stejným názvem již v cílové složce existuje}
    few {Následující dokumenty se stejnými názvy již v cílové složce existují}
    other {Následující dokumenty se stejnými názvy již v cílové složce existují}
  }`,
  'DocumentsMoveFormModal.errorsTitle.duplicateLinks': `{count, plural,
    =0 {Vše v pořádku}
    one {Následující odkaz na dokument se stejným názvem již v cílové složce existuje}
    few {Následující odkazy na dokumenty se stejnými názvy již v cílové složce existují}
    other {Následující odkazy na dokumenty se stejnými názvy již v cílové složce existují}
  }`,
  'DocumentsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přesunu došlo k chybě. Žádné dokumenty nebyly přesunuty.}
    few {Při přesunu došlo k chybám. Žádné dokumenty nebyly přesunuty.}
    other {Při přesunu došlo k chybám. Žádné dokumenty nebyly přesunuty.}
  }`,
  'DocumentsMoveFormModal.moveDocuments': `{count, plural,
    =0 {Přesunout 0 vybraných dokumentů}
    one {Přesunout 1 vybraný dokument}
    few {Přesunout # vybrané dokumenty}
    other {Přesunout # vybraných dokumentů}
  }`, 'DocumentsMoveFormModal.moveLinks': `{count, plural,
    =0 {Přesunout 0 vybraných odkazů na dokumenty}
    one {Přesunout 1 vybraný odkaz na dokument}
    few {Přesunout # vybrané odkazy na dokumenty}
    other {Přesunout # vybraných odkazů na dokumenty}
  }`,
  'DocumentsMoveFormModal.moveFromFolder': 'ze složky:',
  'DocumentsMoveRenameInfoModal.title': `{count, plural,
    =0 {Vše v pořádku}
    one {Následující dokument byl přejmenován}
    few {Následující dokumenty byly přejmenovány}
    other {Následující dokumenty byly přejmenovány}
  }`,
  'DocumentsMoveRenameInfoModal.item':
    'Dokument <strong>{origin}</strong> byl přejmenován na <strong>{destination}</strong>',
  'DocumentsMoveFormModal.title': 'Přesunout Dokumenty',
  'DocumentsRestoreFormModal.button.Restore': 'Obnovit',
  'DocumentsRestoreFormModal.button.RestoreNext': 'Obnovit ostatní',
  'DocumentsRestoreForm.RestoreDocuments': `{count, plural,
    =0 {Chcete opravdu obnovit 0 vybraných dokumentů?}
    one {Chcete opravdu obnovit 1 vybraný dokument?}
    few {Chcete opravdu obnovit # vybrané dokumenty?}
    other {Chcete opravdu obnovit # vybraných dokumentů?}
  }`,
  'DocumentsRestoreFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Vše v pořádku}
    one {Následující dokument nebo složka se stejným názvem již v cílové složce existuje}
    few {Následující dokumenty nebo složky se stejnými názvy již v cílové složce existují}
    other {Následující dokumenty nebo složky se stejnými názvy již v cílové složce existují}
  }`,
  'DocumentsRestoreForm.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přípravě obnovy dokumentů došlo k chybě. Žádný dokument nebyl vyřazen.}
    few {Při přípravě obnovy dokumentů došlo k chybám. Žádný dokument nebyl vyřazen.}
    other {Při přípravě obnovy dokumentů došlo k chybám. Žádný dokument nebyl vyřazen.}
  }`,
  'DocumentsRestoreRenameInfoModal.dupliciteDocumentError': 'Dokument: <strong>{document}</strong>',
  'DocumentsRestoreRenameInfoModal.dupliciteDirectoryError': 'Složka: <strong>{directory}</strong>',
  'DocumentsRestoreFormModal.title': 'Obnova dokumentů',
  'DocumentLinksCreateFormModal.title': `{count, plural,
    one {Vytvořit odkaz na dokument}
    other {Vytvořit odkaz na dokumenty}
  }`,
  'DocumentLinksCreateFormModal.okButton': 'Vytvořit odkazy',
  'DocumentLinksCreateFormModal.okButton.createNext': 'Vytvořit ostatní odkazy',
  'DocumentLinksCreateFormModal.result.success': `{count, plural,
    one {Odkaz na dokument vytvořen úspěšně.}
    other {Odkazy na dokumenty vytvořeny úspěšně.}
  }`,
  'DocumentLinksCreateForm.bindingsToDocuments': `{count, plural,
    =0 {Vytvořit odkaz na 0 vybraných dokumentů ze složky}
    one {Vytvořit odkaz na 1 vybraný dokument ze složky}
    few {Vytvořit odkaz na # vybrané dokumenty ze složky}
    other {Vytvořit odkaz na # vybraných dokumentů ze složky}
  }`,
  'DocumentLinksCreateForm.destinationDirectoryId.label': 'Do složky',
  'DocumentLinksCreateForm.documentLinkName.label': 'Název odkazu',
  'DocumentLinksCreateForm.errors': 'Chyby v souborech',
  'DocumentLinkRenameFormModal.title': 'Změna názvu odkazu',
  'DocumentLinkRenameForm.linkName': 'Název',
  'DocumentLinkRenameForm.createdDate': 'Datum vytvoření odkazu:',
  'DocumentLinkRenameForm.targetPath': 'Cesta k odkazovanému (cílovému) dokumentu:',
  'DocumentLinkRenameForm.linkLocation': 'Umístění odkazu:',
  'DocumentLinkRenameForm.linkAuthor': 'Autor odkazu:',
  'DirectoryCreateForm.form.items.name.label': 'Název',
  'DirectoryCreateForm.form.items.name.placeholder': 'Složka 1',
  'DirectoryCreateForm.form.items.name.rules.nameExists':
    'Složka s tímto názvem již existuje, zvolte prosím jiný název',
  'DirectoryCreateForm.form.items.name.rules.required': 'Prosím zadejte název složky',
  'DirectoryCreateFormModal.button.create': 'Vytvořit složku',
  'DirectoryCreateFormModal.button.create.saving': 'Vytváření složky...',
  'DirectoryCreateFormModal.title': 'Nová složka',
  'DirectoryCreateLinkFormModal.title': 'Vytvořit odkaz na složku',
  'DirectoryCreateLinkForm.source': 'Zdrojová složka',
  'DirectoryCreateLinkForm.label': 'Do složky',
  'DirectoryCreateLinkForm.placeholder': 'Vyberte cílovou složku',
  'DirectoryCreateLinkForm.linkName': 'Název odkazu',
  'DirectoryEditForm.form.items.description.label': 'Nový popis složky',
  'DirectoryEditForm.form.items.name.label': 'Nový název složky',
  'DirectoryEditForm.form.items.name.rules.nameExists': 'Složka s tímto názvem již existuje, zvolte prosím jiný název',
  'DirectoryEditForm.form.items.name.rules.required': 'Prosím zadejte název složky',
  'DirectoryEditFormModal.button.save': 'Přejmenovat složku',
  'DirectoryEditFormModal.button.save.saving': 'Ukládání změn...',
  'DirectoryEditFormModal.title': 'Editace složky',
  'DirectoryLinkEditForm.form.items.name.label': 'Nový název odkazu složky',
  'DirectoryLinkEditForm.form.items.name.rules.nameExists': 'Odkaz s tímto názvem již existuje, zvolte prosím jiný název',
  'DirectoryLinkEditForm.form.items.name.rules.required': 'Prosím zadejte název odkazu složky',
  'DirectoryLinkEditFormModal.button.save': 'Přejmenovat odkaz složky',
  'DirectoryLinkEditFormModal.button.save.saving': 'Ukládání změn...',
  'DirectoryLinkEditFormModal.title': 'Editace odkazu složky',
  'DirectoryForbiddenErrorBox.title': 'K této položce nemáte oprávnění k přístupu.',
  'DirectoryForbiddenErrorBox.description': 'Pro přístup k dokumentům této složky potřebujete oprávnění.',
  'DirectoryForbiddenErrorBox.request': 'Požádat o přístup ke složce',
  'DirectoryForbiddenErrorBox.requestFailed': 'Žádost se nepovedlo odeslat.',
  'DirectoryForbiddenErrorBox.requestSent': 'Žádost byla úspěšně odeslána.',
  'DirectoryForm.form.items.description.placeholder': 'Popis složky...',
  'DirectoryLinkMoveFormModal.title': 'Přesunout odkaz na složku',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.label': 'Přesunout do',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.placeholder': 'Vyberte cílovou složku',
  'DirectoryLinkMoveFormModal.preserveAccessRights': 'Zachovat nastavení přístupových práv',
  'DirectoryLinkMoveFormModal.rename': 'Přejmenovat',
  'DirectoryLinkMoveFormModal.selectedDirectory': 'Vybraná složka',
  'DirectoryLinkMoveFormModal.moveDirectoryLink': 'Přesunout odkaz na složku',
  'DirectoryLinkMoveFormModal.moveError': 'Při přesunu došlo k chybám. Odkaz na složku nebyl přesunut.',
  'DirectoryMoveFormModal.title': 'Přesunout složku',
  'DirectoryMoveFormModal.destinationDirectoryId.label': 'Přesunout do',
  'DirectoryMoveFormModal.destinationDirectoryId.placeholder': 'Vyberte cílovou složku',
  'DirectoryMoveFormModal.preserveAccessRights': 'Zachovat nastavení přístupových práv',
  'DirectoryMoveFormModal.rename': 'Přejmenovat',
  'DirectoryMoveFormModal.selectedDirectory': 'Vybraná složka',
  'DirectoryMoveFormModal.moveDirectory': 'Přesunout složku',
  'DirectoryMoveFormModal.moveError': 'Při přesunu došlo k chybám. Složka nebyla přesunuta.',
  'DirectoryRestoreForm.RestoreDirectory': 'Chcete opravdu obnovit vybranou složku?',
  'DirectoryRestoreForm.errorsTitle': `Složka nebyla obnovena. Složka nebo cesta s tímto názvem již existuje.`,
  'DirectoryRestoreFormModal.button.Restore': 'Obnovit',
  'DirectoryRestoreFormModal.button.RestoreAndRename': 'Obnovit a přejmenovat',
  'DirectoryRestoreFormModal.button.RestoreNext': 'Obnovit ostatní',
  'DirectoryRestoreFormModal.title': 'Obnova složky',
  'DirectorySettingsForm.noUserPermissions': 'Žádná uživatelská oprávnění.',
  'DirectorySettingsForm.goToGroupsSettings': 'Přejít do nastavení skupin',
  'DirectorySettingsForm.goToUsersSettings': 'Přejít do nastavení uživatelů',
  'DirectorySettingsForm.userAccessTab.searchButton.placeholder': 'Vyhledat uživatele podle jména nebo e-mailu',
  'DirectorySettingsForm.requiredCategories.label': 'Povinné kategorie',
  'DirectorySettingsForm.requiredCategories.placeholder': 'Výchozí umístění kategorie',
  'DirectorySettingsForm.requiredCategories.tooltip': 'Upravit povinné kategorie',
  'DirectorySettingsForm.tabs.accessPermission': 'Oprávnění',
  'DirectorySettingsForm.tabs.general': 'Obecné',
  'DirectorySettingsForm.tabs.general.type': 'Typ',
  'DirectorySettingsForm.tabs.general.type.folder': 'Složka',
  'DirectorySettingsForm.tabs.general.title': 'Informace o obsahu složky',
  'DirectorySettingsForm.tabs.general.size': 'Velikost složky',
  'DirectorySettingsForm.tabs.general.sizeIncludingAttachments': 'Velikost složky včetně příloh',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfolders': 'Velikost složky včetně podsložek',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfoldersAndAttachments': 'Velikost složky včetně podsložek a příloh',
  'DirectorySettingsForm.tabs.general.documentsCount': 'Počet dokumentů a složek',
  'DirectorySettingsForm.tabs.general.documentsCountIncludingSubfolders': 'Počet dokumentů a složek včetně podsložek',
  'DirectorySettingsForm.permissions.current': 'Vaše oprávnění pro složku',
  'DirectorySettingsForm.permissions.inheritLabel': 'Zdědit oprávnění',
  'DirectorySettingsForm.permissions.groups': 'Skupiny',
  'DirectorySettingsForm.permissions.notSaveAlert':
    'Nastavení není uloženo. Pokud opustíte stránku, změny budou ztraceny.',
  'DirectorySettingsForm.permissions.users': 'Uživatelé',
  'DirectorySettingsForm.permissions.save': 'Uložit',
  'DirectorySettingsForm.permissions.discardChanges': 'Zahodit změny',
  'DirectoryWatchSettings.title': 'Sledování složky',
  'DirectoryWatchSettings.watchParamsTitle': 'Co chcete sledovat?',
  'DirectoryWatchSettings.inheritSettings': 'Zdědit sledování',
  'DirectoryWatchSettings.category.directoryChange': 'Změna složky',
  'DirectoryWatchSettings.category.subfolders': 'Podsložky',
  'DirectoryWatchSettings.category.documentsChange': 'Změna dokumentů',
  'DirectoriesTree.itemsCount': 'Počet dokumentů',
  'DirectoriesTree.subItemsCount': 'Počet dokumentů (i v podsložkách)',
  'DirectoriesTree.linkedDirectoryPath': 'Cesta k odkazované složce:',
  'DirectoriesTree.linkedDirectoryNotFound': 'Odkazovaná složka nebyla nalezena',
  'DirectoriesTreeSelect.other': '(DMS struktura)',
  'DirectoriesTreeSelect.preferred': 'Vybrané složky',
  'DiscardDocumentsPageFilterToolbar.type': 'Typ',
  'DiscardDocumentsPageFilterToolbar.type.file': 'Dokument',
  'DiscardDocumentsPageFilterToolbar.type.folder': 'Složka',
  'DiscardDocumentsPageFilterToolbar.usersLabel': 'Uživatel',
  'DocumentAnnotationModal.buttons.updateAnnotations': 'Uložit komentáře',
  'DocumentAnnotationModal.buttons.createCommentFromAnnotations': 'Založit připomínku z komentářů',
  'DocumentAnnotationModal.error.emptyAnnotation': 'Chybí text komentáře',
  'DocumentAnnotationModal.error.outOfDateDocument': 'Dokument byl aktualizovaný jiným uživatelem',
  'DocumentAnnotationModal.message.success': 'Komentáře úspěšně uloženy',
  'DocumentAnnotationModal.notSavedAlert': 'Komentáře nejsou uloženy. Pokud budete pokračovat, změny budou ztraceny.',
  'DocumentCategoryTreeCreateFormModal.title': 'Nová kategorie',
  'DocumentCategoryTreeNodeCreateFormModal.title': 'Nová úroveň kategorie',
  'DocumentCategoryTreeNodeDeleteForm.documentsWithNode': 'Dotčené dokumenty',
  'DocumentCategoryTreeNodeDeleteForm.directoriesWithNode': 'Dotčené složky',
  'DocumentCategoryTreeNodeDeleteModal.ok': 'Odstranit',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.label': 'Odstranit také dceřiné uzly?',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.preserve': 'Zachovat a přesunout',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.remove': 'Odstranit',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.label': 'Jak přenastavit kategorii u dotčených dokumentů?',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceParent': 'Použít nadřazený uzel',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceRoot': 'Použít kořen',
  'DocumentCategoryTreeNodeDeleteModal.title': 'Odstranění uzlu kategorie',
  'DocumentCreateForm.loadingCategoryTip': 'Načítám povinné kategorie',
  'DocumentCreateForm.form.items.category.add': 'Přidat kategorii',
  'DocumentCreateForm.form.items.category.add.noMore': 'Žádné kategorie k přidání',
  'DocumentCreateForm.form.items.category.rules.required': 'Prosím vyberte umístění kategorie',
  'DocumentCreateForm.form.items.category.select.placeholder': 'Vyberte kategorii',
  'DocumentCreateForm.form.items.category.treeSelect.placeholder': 'Vyberte umístění kategorie',
  'DocumentCreateForm.form.items.destinationDirectory.label': 'Umístění (složka)',
  'DocumentCreateForm.form.items.destinationDirectory.placeholder': 'Vyberte cílovou složku',
  'DocumentCreateForm.form.items.destinationDirectory.rules.required': 'Prosím vyberte cílovou složku',
  'DocumentCreateForm.form.items.name.placeholder': 'Dokument 1.pdf',
  'DocumentCreateForm.form.items.name.rules.nameExists':
    'Dokument s tímto názvem již existuje, zvolte prosím jiný název',
  'DocumentCreateForm.form.items.name.rules.required': 'Prosím zadejte název dokumentu',
  'DocumentCreateForm.form.items.relation.rules.required': 'Zadejte, jestli se jedná o interní nebo veřejnou přílohu',
  'DocumentCreateForm.form.items.state.label': 'Stav',
  'DocumentCreateForm.form.items.state.rules.required': 'Prosím vyberte stav',
  'DocumentCreateForm.form.oldRevisionWarning':
    'Pozor! Editujete starší revizi dokumentu. ' +
    'Uložením vznikne nová revize, které nebude obsahovat změny provedené v novějších revizí.',
  'DocumentCreateForm.section.categories': 'Kategorie',
  'DocumentCreateFormModal.checkingFiles': 'Kontrola souborů...',
  'DocumentCreateFormModal.continue': 'Zkusit navázat',
  'DocumentCreateFormModal.cancelConfirm.title': 'Opravdu si přejete přerušit nahrávání?',
  'DocumentCreateFormModal.cancelConfirm.text': 'Právě nahrávaný dokument a dokumenty čekající na nahrání nebudou nahrány. Přejete si pokračovat?',
  'DocumentCreateFormModal.pause': 'Přerušit',
  'DocumentCreateFormModal.title': 'Nový dokument',
  'DocumentCreateMultipleForm.dropAreaTitle': 'Klikněte sem nebo přetáhněte soubory.',
  'DocumentCreateMultipleForm.inheritPermission.label': 'Zdědit práva složek z nadřazené složky.',
  'DocumentCreateMultipleForm.file.description': 'Popis',
  'DocumentCreateMultipleForm.file.description.placeholder': 'Vložte volitelný popis dokumentů',
  'DocumentCreateMultipleForm.file.manager': 'Správce',
  'DocumentCreateMultipleForm.file.label': 'Názvy dokumentů odpovídají jménům zadaných souborů',
  'DocumentCreateMultipleForm.file.rules.required': 'Prosím zvolte nahrávané soubory',
  'DocumentCreateMultipleForm.file.revisionComment': 'Komentář',
  'DocumentCreateMultipleForm.file.revisionComment.rules.required': 'Prosím zadejte komentář revize',
  'DocumentCreateMultipleForm.file.revisionComment.placeholder': 'Vložte komentář revize',
  'DocumentCreateMultipleForm.form.items.documentOptions.title': 'Parametry nových dokumentů',
  'DocumentCreateMultipleForm.form.items.destinationDirectory.label': 'Umístění (cílová složka)',
  'DocumentCreateMultipleForm.form.items.state.label': 'Stav',
  'DocumentCreateMultipleForm.form.items.revisionOptions.title': 'Parametry revize stávajících dokumentů',
  'DocumentCreateMultipleForm.form.isModelSwitch.text': 'Přidat k modelům',
  'DocumentCreateMultipleForm.primary.label': 'Primární soubory a složková struktura',
  'DocumentCreateMultipleForm.primary.text': 'Vyberte primární soubory',
  'DocumentCreateMultipleForm.secondary.label': 'Přílohy',
  'DocumentCreateMultipleForm.secondary.text': 'Vyberte přílohy',
  'DocumentCreateMultipleForm.secondary.cantAdd':
    'Nelze přidat podepsaný dokument ani přílohy hromadně ke všem primárním souborům, nebo ke složkám. Vložte pouze jeden primární soubor, pokud k němu chcete přidat přílohy.',
  'DocumentCreateMultipleForm.signedDocument.label': 'Podepsaný dokument',
  'DocumentCreateMultipleFormModal.title': 'Nahrát dokumenty a složky',
  'DocumentDetailPage.annotationEdit.error': 'Dokument nemá PDF derivát pro komentování',
  'DocumentDetailPage.createRevision': 'Nová revize',
  'DocumentDetailPage.createRevision.disabled': 'Novou revizi nelze přidat.',
  'DocumentDetailPage.createRevision.tooltip': 'Přidat novou revizi',
  'DocumentDetailPage.createRevision.tooltip.Ok': 'Přidat novou revizi.',
  'DocumentDetailPage.createRevision.tooltip.AccessDenied': 'Přístup odepřen.',
  'DocumentDetailPage.createRevision.tooltip.InWorkflow': 'Dokument je použit ve workflow.',
  'DocumentDetailPage.createRevision.tooltip.InCommentProcedure': 'Dokument je použit v připomínkovém řízení.',
  'DocumentDetailPage.createRevision.tooltip.Reserved': 'Dokument je rezervován.',
  'DocumentDetailPage.createRevision.tooltip.HasOwner': 'Dokument má vlastníka.',
  'DocumentDetailPage.createRevision.tooltip.EsticonDocument': 'Dokument má vazbu na AspeEsticon.',
  'DocumentDetailPage.directoryForbiddenMessage':
    'Požadovaný dokument se nachází ve složce {path}, ke které nemáte přístup.',
  'DocumentDetailPage.downloadPrimaryFile.tooltip': 'Stáhnout primární soubor',
  'DocumentDetailPageCategories.editMode.tooltip': 'Upravit kategorie',
  'DocumentDetailPage.favorite.saving': 'Ukládání oblíbených',
  'DocumentDetailPage.favorite.set': 'Přidat do oblíbených',
  'DocumentDetailPage.favorite.unset': 'Odebrat z oblíbených',
  'DocumentDetailPage.manager.error.documentInActiveWorkflow': 'Dokument je součástí workflow',
  'DocumentDetailPage.manager.error.documentInCommentProcedure': 'Dokument je součástí připomínkového řízení',
  'DocumentDetailPage.manager.error.documentIsReserved': 'Dokument je rezervován',
  'DocumentDetailPage.manager.error.documentHasOwner': 'Dokument má správce',
  'DocumentDetailPage.manager.error.documentHasEsticonLink': 'AspeEsticon dokumentu nelze přiřadit správce',
  'DocumentDetailPage.model.add': 'Přidat k modelům',
  'DocumentDetailPage.model.remove': 'Odebrat z modelů',
  'DocumentDetailPage.previewNotAvailable': 'Náhled není k dispozici.',
  'DocumentDetailPage.secondaryFile': 'Přílohy',
  'DocumentDetailPage.name.successMessage': 'Název byl úspěšně změněn',
  'DocumentDetailPageBindings.deleteSuccess': 'Vazba na dokument byla smazána',
  'DocumentDetailPageBindings.title': 'Vazba na tento dokument',
  'DocumentDetailPageBindings.bindedDocuments.modalTitle': 'Výběr dokumentů pro vytvoření vazeb',
  'DocumentDetailPageBindings.addBinding': 'Přidat vazby na dokumenty',
  'DocumentDetailPageBindings.addBinding.success': 'Vazby byly úspěšně přidány',
  'DocumentDetailPageBindings.addBinding.warning': `{count, plural,
    one {Vazba na element už existuje.}
    few {Vazby byly úspěšně přidány. # vazby na elementy už existují.}
    other {Vazby byly úspěšně přidány. # vazeb na elementy už existuje.}
  }`,
  'DocumentDetailPageBindings.removeBinding': 'Odebrat tuto vazbu',
  'DocumentDetailPageBindings.removeBinding.success': 'Vazba byla úspěšně odebrána',
  'DocumentDetailPageBindings.noBindingToSameDocument': 'Dokument nemůže odkazovat sám na sebe.',
  'DocumentDetailPageBindings.bindingDuplicated': 'Vazba na tento dokument již exituje.',
  'DocumentDetailPageBindings.noSufficientPermissions': 'Vazbu na vybraný dokument nelze vytvořit. Nemáte dostatečné oprávnění.',
  'DocumentDetailPageBindings.selectDocumentsModal.title': 'Vybrat dokumenty k přidání vazby',
  'DocumentDetailPageBindings.failureList.Modal.title': 'V následujících případech se nezdařilo nastavit vazbu na dokument',
  'DocumentDetailPageBindings.documentError.NotFound': 'Soubor {fileName} nebyl nalezen',
  'DocumentDetailPageBindings.documentError.IsDiscarded': 'Soubor {fileName} byl odebrán',
  'DocumentDetailPageBindings.documentError.BadDirectory': 'Soubor {fileName} již není v adresáři',
  'DocumentDetailPageBindings.documentError.AccessDenied': 'Pro nastavení vazby na soubor {fileName} nemáte dostatečná oprávnění',
  'DocumentDetailPageBindings.documentError.PropertySet': 'Vazba na soubor {fileName} již byla nastavena',
  'DocumentDetailPageBindings.documentError.PropertyUnset': 'Vazba na soubor {fileName} již byla odebrána',
  'DocumentDetailPageReferences.addReferences': 'Přidat reference na dokumenty',
  'DocumentDetailPageReferences.removeReference': 'Odebrat referenci',
  'DocumentDetailPageReferences.order.reference': 'Vlastní reference tohoto dokumentu',
  'DocumentDetailPageReferences.order.referrer': 'Dokument je referencí na jiný dokument',
  'DocumentDetailPageReferences.order.createdBy': 'Vytvořeno uživatelem',
  'DocumentDetailPageReferences.order.createdDate': 'Datum vytvoření',
  'DocumentDetailPageReferences.order.modifiedDate': 'Datum změny',
  'DocumentDetailPageReferences.addReferences.documentsSelect.title': 'Vybrat dokumenty k přidání reference',
  'DocumentDetailPageReferences.noSufficientPermissions': 'Nemáte dostatečné oprávnění',
  'DocumentDetailPageReferences.noReferenceToSameDocument': 'Dokument nemůže mít referenci sám na sebe.',
  'DocumentDetailPageReferences.referenceDuplicated': 'Reference na tento dokument již exituje.',
  'DocumentDetailReferencesProvider.create.successMessage': 'Reference na dokument úspěšně vytvořeny',
  'DocumentDetailReferencesProvider.delete.successMessage': 'Reference na dokument byla úspěšně odtraněna',
  'DocumentDetailPageLabels.editMode.tooltip': 'Upravit štítky',
  'DocumentDetailPageLinks.title': 'Odkazy na tento dokument',
  'DocumentDetailPageLinks.deleteSuccess': 'Odkaz na dokument úspěšně smazán',
  'DocumentDetailPageLinks.confirmDelete': 'Opravdu chcete smazat tento odkaz?',
  'DocumentDetailPageLinks.needsWriteToEdit': 'Pro editaci odkazu potřebujete mít oprávnění zapisovat ve složce s odkazem',
  'DocumentDetailPageLinks.needsWriteToDelete': 'Pro smazání odkazu potřebujete mít oprávnění zapisovat ve složce s odkazem',
  'DocumentDetailPageLinks.addLink': 'Přidat odkazy na dokumenty',
  'DocumentDetailPageManager.label': 'Nový správce dokumentu',
  'DocumentDetailPageManager.tooltip': 'Změnit správce dokumentu.',
  'DocumentDetailPageManager.tooltip.noPermission': 'Nemáte potřebná oprávnění nebo je dokument rezervován.',
  'DocumentDetailPageState.tooltip': 'Změnit stav dokumentu',
  'DocumentDetailPageState.label': 'Nový stav dokumentu',
  'DocumentDetailPageDescription.placeholder': 'Přidat Popis',
  'DocumentDetailPageWatch.tabTitle': 'Sledování',
  'DocumentDetailPageWatch.title': 'Sledování dokumentu',
  'DocumentDetailPageWatch.category.documentShare': 'Sdílení dokumentu',
  'DocumentDetailPageWatch.category.documentChange': 'Změna dokumentu',
  'DocumentDetailPageWatch.category.processInclusion': 'Zařazení do procesu',
  'DocumentDownloadWithAnnotationsModal.title': 'Stažení dokumentu s komentáři',
  'DocumentDownloadWithAnnotationsModal.progressMessage': 'Importují se komentáře do dokumentu',
  'DocumentOnlineEditor.createRevisionBusyReason': 'Připravují se data nové revize',
  'DocumentOnlineEditor.storingRevisionBusyReason': 'Ukládá se revize, počkejte prosím',
  'DocumentOnlineEditor.defaultModalTitle': 'Online editor',
  'DocumentOnlineEditor.button.saveAsRevision': 'Uložit revizi',
  'DocumentOnlineEditor.fileLimitError': 'Maximální podporovaná velikost souboru se nepodařila načíst.',
  'DocumentOnlineEditor.notSavedAlert': 'Dokument není uložen. Pokud budete pokračovat, změny budou ztraceny.',
  'DocumentOnlineEditor.oversizeWarning': 'Dokument překračuje maximální podporovanou velikost pro uložení. Pokud dokument nezmenšíte, nebude možné ho uložit.',
  'DocumentRequest.accept': 'Potvrdit',
  'DocumentRequest.accept.title': 'Potvrdit podkladový dokument',
  'DocumentRequest.accept.confirm': 'Opravdu chcete potvrdit tento dokument?',
  'DocumentRequest.confirmDocument': 'Potvrdit dokument',
  'DocumentRequest.confirm.title': 'Potvrdit předchozí dokument',
  'DocumentRequest.confirm.confirm': 'Vybrat stejný dokument (včetně revize) jako v předchozí instanci.',
  'DocumentRequest.refuse': 'Vrátit',
  'DocumentRequest.refuse.title': 'Vrátit dokument k přepracování',
  'DocumentRequest.refuse.reason': 'Komentář',
  'DocumentRequest.notPublished': 'Lze vybrat pouze publikované dokumenty',
  'DocumentRequest.assignDocument': 'Přiřadit dokument',
  'DocumentRequest.term': 'Termín: ',
  'DocumentRequest.term.title': 'Termín odevzdání: {date}',
  'DocumentRequest.missingUser': 'Chybí uživatel!',
  'DocumentRequest.suspendedUser': 'Deaktivovaný uživatel!',
  'DocumentRequest.noDocument': '(dokument není nahrán)',
  'DocumentRequest.newDocument': 'Nový dokument',
  'DocumentRequest.downloadDocument': 'Stáhnout dokument',
  'DocumentRequest.editRequest': 'Upravit požadavek',
  'DocumentRequest.removeRequest': 'Vyřadit požadavek',
  'DocumentRequest.removeRequest.confirm': 'Opravdu chcete vyřadit požadavek "{name}"?',
  'DocumentRequest.state': 'Stav',
  'DocumentRequest.state.ready': 'Ke kontrole',
  'DocumentRequest.state.ready.dateTooltip': 'Datum přiřazení: {date}',
  'DocumentRequest.state.missed': 'Nenahráno',
  'DocumentRequest.state.missed.dateTooltip': 'Datum vytvoření požadavku: {date}',
  'DocumentRequest.state.accepted': 'Schváleno',
  'DocumentRequest.state.accepted.dateTooltip': 'Datum schválení: {date}',
  'DocumentRequest.state.accepted.userTooltip': 'Schváleno uživatelem',
  'DocumentRequest.state.refused': 'Vráceno',
  'DocumentRequest.state.refused.dateTooltip': 'Datum vrácení: {date}',
  'DocumentRequest.state.refused.userTooltip': 'Vráceno uživatelem',
  'DocumentRequest.state.forConfirm': 'Pro potvrzení',
  'DocumentRequest.state.forConfirm.dateTooltip': 'Datum vytvoření požadavku',
  'DocumentReservationLock.error.oldRevision': 'Revize rezervovaného dokumentu je starší než aktuální revize dokumentu a není možné ji upravovat.',
  'DocumentRevisionsGrid.initialRevision': 'Počáteční revize',
  'DocumentRevisionsGrid.primaryFile': 'Primární soubor',
  'DocumentRevisionsGrid.revisionDiscar.modal.title': 'Opravdu chcete vyřadit revizi?',
  'DocumentRevisionsGrid.revisionDiscar.modal.description': 'Pozor: revizi může obnovit pouze administrátor projektu.',
  'DocumentRevisionsGrid.revisionDiscar.modal.okButton': 'Vyřadit',
  'DocumentRevisionsGrid.revisionDiscar.modal.cancelButton': 'Zrušit',
  'DocumentRevisionsGrid.revisionDiscarded': 'Revize byla vyřazena',
  'DocumentRevisionsGrid.revisionDiscarded.label': 'Vyřazeno',
  'DocumentRevisionsGrid.revisionDiscarded.success': 'Revize úspěšně vyřazena',
  'DocumentRevisionsGrid.downloadWithAnnotations.tooltip': 'Stáhnout s komentáři',
  'DocumentRevisionsGrid.downloadWithAttachments.error': 'Stažení souborů se nezdařilo',
  'DocumentRevisionsGrid.rowMenu.downloadWithAttachments': 'Stáhnout včetně příloh',
  'DocumentRevisionsGrid.rowMenu.addAttachments': 'Přidat přílohy',
  'DocumentRevisionsGrid.rowMenu.addSignedDocument': 'Přidat podepsaný dokument',
  'DocumentRevisionsGrid.rowMenu.discardRevision': 'Vyřadit',
  'DocumentRevisionsGrid.rowMenu.editDocumentRevision': 'Otevřít revizi v editoru',
  'DocumentRevisionsGrid.rowMenu.externalMetadata': 'Externí metadata',
  'DocumentRevisionsGrid.rowMenu.restoreDiscarded': 'Obnovení vyřazené revize',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.title': 'Obnovit vyřazenou revizi?',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.okButton': 'Obnovit',
  'DocumentRevisionsGrid.signedDocument': 'Podepsaný dokument',
  'DocumentRevisionsGrid.annotatedDocument': 'Dokument s komentáři',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.cancelButton': 'Zrušit',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.success': 'Revize byla úspěšně obnovena',
  'DocumentRevisionsGrid.secondaryFiles': 'Přílohy',
  'DocumentRevisionsGrid.metadata.aspeEsticon': 'AspeEsticon',
  'DocumentRevisionsGrid.metadata.openTree': 'OpenTree',
  'DocumentRevisionsGrid.metadata.user': 'Uživatel',
  'DocumentRevisionsGrid.metadata.firstName': 'Jméno',
  'DocumentRevisionsGrid.metadata.lastName': 'Příjmení',
  'DocumentRevisionsGrid.metadata.attributeName': 'Název atributu',
  'DocumentRevisionsGrid.metadata.attributeValue': 'Hodnota',
  'DocumentSelect.directoryError': 'Nelze vybrat dokumenty ze složky {path}: {error}',
  'DocumentSelect.selectedInThisDirectory': 'Vybráno {count} dokumentů v tomto adresáři.',
  'DocumentSelect.selectedInSubDirectories': '({count} dokumentů včetně podadresářů)',
  'DocumentSelect.disabledMessage.documentInThisWorkflow': 'Dokument už je vybraný v tomto workflow.',
  'DocumentSelect.disabledMessage.documentInWorkflow': 'Dokument je vybraný jako schvalovaný dokument ve workflow.',
  'DocumentSelect.disabledMessage.documentInThisProcedure': 'Dokument už je vybraný v tomto připomínkovém řízení.',
  'DocumentSelect.disabledMessage.documentInProcedure': 'Dokument je vybraný v aktivním připomínkovém řízení.',
  'DocumentSelect.disabledMessage.notUnlocked': 'Lze vybrat pouze odemčené dokumenty.',
  'DocumentSelect.disabledMessage.notOwner': 'Musíte být správcem dokumentu.',
  'DocumentSelect.disabledMessage.noWriteAccess': 'Musíte mít alespoň právo pro zápis.',
  'DocumentSelect.disabledMessage.notShared': 'Lze vybrat pouze sdílené dokumenty.',
  'DocumentSelect.disabledMessage.notAllowedDocumentState': 'Lze vybrat pouze dokumenty ve stavech definovaných šablonou workflow.',
  'DocumentSelect.disabledMessage.partialEsticonDocument': 'Dílčí dokument nelze schvalovat.',
  'DocumentSelect.multierror.someDocumentsTitle': 'Při zpracování některých dokumentů došlo k chybě, pokračovat?',
  'DocumentSelect.multierror.someDocumentsHaveError': '{count} z {total} vybraných dokumentů nelze zpracovat:',
  'DocumentSelect.multierror.continuePrompt': 'Chcete odebrat chybné dokumenty a provést akci znovu?',
  'DocumentSelect.multierror.allDocumentsTitle': 'Při zpracování každého dokumentu došlo k chybě',
  'DocumentSelect.multierror.allDocumentsHaveError': 'Žádný z vybraných dokumentů ({count}) nelze zpracovat',
  'DocumentSelect.uploadDocuments': 'Nahrát nové dokumenty',
  'DocumentSelectAddAll.progress': 'Zpracované složky',
  'DocumentSelectAddAll.selectAll': 'Vybrat vše včetně podsložek',
  'DocumentSelectAddAll.deselectAll': 'Odebrat vše včetně podsložek',
  'DocumentSelectInfo.actualSelected': 'Poslední akcí vybráno:',
  'DocumentSelectInfo.actualDeselected': 'Poslední akcí vyřazeno z výběru:',
  'DocumentSelectInfo.selectedTotal': 'Celkem vybráno:',
  'DocumentSelectFormItem.selectDocuments': 'Vybrat dokumenty',
  'DocumentSelectFormItem.selectDocument': 'Vybrat dokument',
  'DocumentSelectFormItem.changeDocuments': 'Změnit dokumenty',
  'DocumentSelectFormItem.changeDocument': 'Změnit dokument',
  'DownloadManager.UploadStatus.waitingToStart': 'Čekání na nahrání',
  'DownloadManager.UploadStatus.initiating': 'Příprava nahrávání',
  'DownloadManager.UploadStatus.uploading': 'Nahrávání',
  'DownloadManager.UploadStatus.finished': 'Nahrávání dokončeno',
  'DownloadManager.UploadStatus.interrupted': 'Nahrávání přerušeno',
  'DownloadManager.UploadStatus.error': 'Chyba',
  'DownloadManager.UploadStatus.suspended': 'Čekání na navázání nahrávání',
  'DownloadFileButton.tooltip': 'Stáhnout soubor',
  'DrawingActualOverview.title.toDate': 'k datu {date}',
  'DrawingActualOverview.char.column.constructionPrice': 'Cena stavby OC',
  'DrawingActualOverview.char.column.invoiced': 'Fakturováno',
  'DrawingActualOverview.char.column.totalCost': 'Náklady celkem',
  'DrawingActualOverview.char.column.income': 'Výnosy',
  'DrawingActualOverview.char.column.totalCostActual': 'Náklady celkem',
  'DrawingActualOverview.char.column.netIncomeDate': 'HV k datu',
  'DrawingActualOverview.char.column.invoicedOC': 'Fakturace (OC)',
  'DrawingActualOverview.char.column.remaining': 'Zbývá (NC)',
  'DrawingActualOverview.char.column.expectedNetIncome': 'Předpokládaný HV',
  'DrawingActualReportDetail.income.name': 'Zisk',
  'DrawingActualReportDetail.ownPerformance.name': 'Vlastní výkon',
  'DrawingActualReportDetail.uncategorized.name': 'Nepřiřazeno',
  'DrawingActualReportDetail.uncategorized.name.tooltip': 'Všechny záznamy z účetnictví, které nelze přiřadit k rozpočtu',
  'DrawingActualReportDetail.unclassified.name': 'Nezatříděno',
  'DrawingActualReportDetail.unclassified.name.tooltip': 'Nezatříděná činnost v rozpočtu i účetnictví',
  'DrawingActualReportDetail.unassignedWithCategory.name': 'Nespárováno s činností',
  'DrawingActualReportDetail.unassignedWithCategory.name.tooltip': 'Zatříděná činnost v rozpočtu, bez platného dodavatele v účetnictví',
  'DrawingActualReportDetail.unassignedWithoutCategory.name': 'Nespárováno bez činnosti',
  'DrawingActualReportDetail.unassignedWithoutCategory.name.tooltip': 'Nezatříděné činnosti v rozpočtu, bez platného dodavatele v účetnictví',
  'DrawingActualReportDetail.budgetIncome.name': 'Výnosy: rozpočet',
  'DrawingActualReportDetail.otherIncome.name': 'Ostatní',
  'DrawingActualReportDetail.headers.actual': 'Skutečnost',
  'DrawingActualReportDetail.headers.actualCostOfOwnWork': 'Náklady na vlastní výkon',
  'DrawingActualReportDetail.headers.actualTotalCost': 'Náklady celkem',
  'DrawingActualReportDetail.headers.category': 'Kategorie',
  'DrawingActualReportDetail.headers.changeOrder': 'ZBV',
  'DrawingActualReportDetail.headers.coeficient': 'Koeficient',
  'DrawingActualReportDetail.headers.contractuality': 'Zasmluvněnost (NC)',
  'DrawingActualReportDetail.headers.costOfOwnWork': 'Náklady na vlastní výkon',
  'DrawingActualReportDetail.headers.costOfSubcontracting': 'Náklady na subdodávky',
  'DrawingActualReportDetail.headers.drawing': 'Čerpání',
  'DrawingActualReportDetail.headers.drawingTotalCost': 'Náklady celkem',
  'DrawingActualReportDetail.headers.expectedNetIncome': 'Předpokládaný HV',
  'DrawingActualReportDetail.headers.expectedResult': 'Předpokládaný výsledek',
  'DrawingActualReportDetail.headers.netIncomeToDate': 'HV k datu',
  'DrawingActualReportDetail.headers.income': 'Výnosy',
  'DrawingActualReportDetail.headers.internalChangeOrder': 'ZBV interní',
  'DrawingActualReportDetail.headers.investorContract': 'Smlouva',
  'DrawingActualReportDetail.headers.invoiced': 'Fakturováno',
  'DrawingActualReportDetail.headers.invoicedSP': 'Fakturace (OC)',
  'DrawingActualReportDetail.headers.margin': 'Marže',
  'DrawingActualReportDetail.headers.ownWork': 'Vlastní výkon',
  'DrawingActualReportDetail.headers.priceCfW': 'Cena SoD',
  'DrawingActualReportDetail.headers.remaining': 'Zbývá',
  'DrawingActualReportDetail.headers.remainingCP': 'Zbývá (NC)',
  'DrawingActualReportDetail.headers.subcontracting': 'Subdodávky',
  'DrawingActualReportDetail.headers.supplierInvoices': 'Faktury dodavatelů',
  'DrawingActualReportDetail.headers.totalPrice': 'Cena stavby (OC)',
  'DrawingActualReportDetail.reportDisplayMode.simplified': 'Zjednodušený',
  'DrawingActualReportDetail.reportDisplayMode.full': 'Rozšířený',
  'DrawingActualReportDetail.reportError.noData': 'Data nejsou dostupná',
  'DropArea.hint': 'Přetáhněte soubory sem',
  'DropArea.disabled': 'Soubory nelze vložit',
  'DocumentDetailPage.dropFilesError':
    'Jako novou revizi lze nahrát pouze jeden soubor. Prosím vložte pouze jeden soubor.',
  'DocumentDetailPage.workflowList.responsible person': 'Zodpovědná osoba',
  'DocumentDetailPage.workflowList.approvalDocument': 'Schvalovaný dokument',
  'DocumentDetailPage.workflowList.backgroundDocument': 'Podkladový dokument',
  'DocumentDetailPage.workflowList.typeFilterLabel': 'Typ',
  'DocumentDetailPage.workflowList.typeApproval': 'Schvalovaný',
  'DocumentDetailPage.workflowList.typeBackground': 'Podkladový',
  'ERPDataImportButtonComponent.import.success': 'Data z připojeného projektu úspěšně importována',
  'EstiCategoryEnum.Stavba': 'Rozpočet',
  'EstiCategoryEnum.Cerpani': 'Čerpání',
  'EstiCategoryEnum.CerpaniSdruzeni': 'Sdružené čerpání',
  'EstiCategoryEnum.Fakturace': 'Fakturace',
  'EstiCategoryEnum.FakturaSdruzeni': 'Sdružená fakturace',
  'EstiCategoryEnum.ZBV': 'ZBV',
  'EstiCategoryEnum.Harmonogram': 'Harmonogram',
  'EsticonContainer.noProject.title': 'K projektu není připojen AspeEsticon projekt.',
  'EsticonDetail.settingsSaveSuccess': 'Nastavení úspěšně uloženo',
  'EsticonDetailDirectory.folders': 'Složky pro import z Esticonu',
  'EsticonDetailDirectory.targetPath.title': 'Cílová složka',
  'EsticonDetailDirectory.pathMask': 'Maska cesty',
  'EsticonDetailDirectory.fileMask': 'Maska názvu dokumentu',
  'EsticonDetailDirectory.addMaskDirectory': 'Přidat položku cesty',
  'EstiConImportValidationIssueType.Precerpani': 'Importem ZP dojde k přečerpání.',
  'EstiConImportValidationIssueType.MenepraceDoMinusu': 'Méněpráce, které způsobí překročení celkového množství položky.',
  'EstiConImportValidationIssueType.ChybiZbvProPolozky':
    'Importovaná data obsahují položku ZBV bez odpovídajícího ZBV na projektu.',
  'EstiConImportValidationIssueType.ChybiZpProCerpani':
    'Importovaná data obsahují položku bez odpovídajícího ZP na projektu.',
  'EstiConImportValidationIssueType.ChybiZbvProZp': 'Importovaná data obsahují ZP bez odpovídajícího ZBV na projektu.',
  'EstiConImportValidationIssueType.ChybiZbvProZpSdruzeny':
    'Importovaná data obsahují sdružený ZP bez odpovídajícího ZBV na projektu.',
  'EstiConImportValidationIssueType.ChybiFakturaProZp': 'Importovaná data obsahují ZP bez odpovídající FA.',
  'EstiConImportValidationIssueType.ChybiZpSdruzenyProZp':
    'Importovaná data obsahují ZP bez odpovídajícího sdruženého ZP.',
  'EstiConImportValidationIssueType.ChybiFakturaSdruzenaProFaktura':
    'Importovaná data obsahují FA bez odpovídající sdružené FA.',
  'EstiConImportValidationIssueType.ChybiFakturaProRozpisFaktury':
    'Importovaná data obsahují FA bez odpovídající zálohové FA.',
  'EstiConImportValidationIssueType.ChybiObjektProPolozkaFaktury':
    'Importovaná data obsahují FA bez odpovídajících objektů v projektu.',
  'EstiConImportValidationIssueType.ChybiPartnerZFakturyNaZP':
    'Faktura obsahuje partnera, který na zjišťovacím protokolu chybí, nebo se liší',
  'EstiConImportValidationIssueType.ChybiClenoveNaSdruzeneStavbe': 'Ve sdružené stavbě nejsou žádní členové sdružení',
  'EstiConImportValidationIssueType.ChybiSmlDodatekProZbv':
    'Importovaná data obsahují ZBV bez odpovídajícího smluvního dodatku.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaFakture':
    'Při importu dojde ke smazání ZP, který je použitý na faktuře.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaSpolZP':
    'Při importu dojde ke smazání ZP, který je použitý na společném ZP.',
  'EstiConImportValidationIssueType.MazaniFakturyPouziteNaSpolFakture':
    'Při importu dojde ke smazání FA, která je použitá na společné FA.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniCena':
    'Importovaná data obsahují zaokrouhlení ceny na jiný počet desetinných míst.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniJednotkovaCena':
    'Importovaná data obsahují zaokrouhlení jednotkové ceny na jiný počet desetinných míst.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniMnozstvi':
    'Importovaná data obsahují zaokrouhlení množství na jiný počet desetinných míst.',
  'EstiConImportValidationIssueType.ViceNezJednoCerpaniRozpisuStejnymZP':
    'Importovaná data obsahují násobné čerpání položky jedním ZP.',
  'EstiConImportValidationIssueType.RozdilneVypoctySDph':
    'Importovaná data obsahují jiné nastavení pozastávky nebo zádržného, než je na projektu.',
  'EstiConImportValidationIssueType.VyssiPocetAktivit': 'Importovaná data obsahují vyšší počet aktivit CEF.',
  'EstiConImportValidationIssueType.NizsiPocetAktivit': 'Importovaná data obsahují nižší počet aktivit CEF. ',
  'EstiConImportValidationIssueType.DuplicateInSource': 'Importovaná data obsahují duplicitní záznamy.',
  'EstiConImportValidationIssueType.DuplicateInDestination': 'Data v projektu obsahují duplicitní záznamy.',
  'EstiConImportValidationIssueType.ChybiVCili': 'Importovaná data obsahují záznamy, které nejsou v projektu.',
  'EstiConImportValidationIssueType.RozdilnaStruktura':
    'Importovaná data obsahují jinou objektovou strukturu, než je v cílovém projektu.',
  'EstiConImportValidationIssueType.DuplicitniPolozky': 'Projekt obsahuje duplicitní položky.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru': 'Importovaný soubor neobsahuje data správného objektu.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.WipReport': 'Importovaný soubor neobsahuje data ZP.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.ChangeOrder': 'Importovaný soubor neobsahuje data ZBV.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Invoice': 'Importovaný soubor neobsahuje data FA.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Schedule': 'Importovaný soubor neobsahuje data HMG.',
  'EstiConImportValidationIssueType.NevalidniData': 'Importovaný soubor obsahuje nevalidní data.',
  'EstiConImportValidationIssueType.KontrolaXC4': 'Importovaný soubor obsahuje nevalidní data formátu XC4.',
  'EstiConImportValidationIssueType.OdeslanoNaHub': 'Importovaný soubor je již odeslaný na AspeHub.',
  'EstiConImportValidationIssueType.NesparovaneStavebniDily': 'Importovaný soubor obsahuje nespárované stavební díly.',
  'EstiConImportValidationIssueType.NeznamaChyba': 'Došlo k neznámé chybě.',
  'EstiConImportValidationIssueType.ZbvNeniOdeslanoNaHub': 'ZBV není odesláno na AspeHub.',
  'EstiConImportValidationIssueType.ChybiDodavatelClenskeFaktury': 'Chybí dodavatel členské faktury.',
  'EstiConImportValidationIssueType.NelzeNaparovatDodavateleFaktury': 'Dodavatele faktury nelze napárovat.',
  'EstiConImportValidationIssueType.DuplicitniCsNazev': 'Duplicitní název cenové soustavy.',
  'EstiConImportValidationIssueType.DuplicitniCsVarianta': 'Duplicitní varianta cenové soustavy.',
  'EstiConImportValidationIssueType.KalkulaceDoNekalkulace': 'Data v importu obsahují kalkulace. Importem do nekalkulované stavby dojde ke smazání kalkulací.',
  'EstiConImportValidationIssueType.RozdilnaTvorbaStruktury': 'Stavba, do které data importujete, má jinou tvorbu struktury. Import zakázán.',
  'EstiConImportValidationIssueType.HmgRozdilnaStruktura': 'Rozdílná struktura harmonogramu.',
  'EstiConImportValidationIssueType.ExistingZpHasFaktura': 'K existujícímu zjišťovacímu protokolu je vystavená faktura.',
  'EstiConImportValidationIssueType.ZpIsApproved': 'Zjišťovací protokol je schválený.',
  'EstiConImportValidationIssueType.ExistingZbvHasZp': 'Existující ZBV už bylo čerpané.',
  'EstiConImportValidationIssueType.NeshodneValorizace': 'Rozdílné valorizace na projektu a importovaném ZBV',
  'EstiConImportValidationIssueType.NeshodnyTypValorizace': 'Rozdílný typ valorizace na projektu a importovaném ZBV',
  'EstiConImportValidationIssueType.NeshodneObdobiValorizace': 'Rozdílná období valorizace na projektu a importovaném ZBV',
  'EstiConImportValidationIssueType.ChybiSkupinyZbv': 'Chybí skupiny ZBV',
  'EstiConImportValidationIssueType.StavbaNepouzivaSkupinyZbv': 'Stavba nepoužívá skupiny ZBV',
  'EstiConImportValidationIssueType.NeshodnaMena': 'Databáze mají nastavenou jinou výchozí měnu',
  'EstiConImportValidationBlockingError.UnableToImport': 'Import nelze provést.',
  'EsticonMaskInput.error.empty': 'Maska nesmí být prázdná',
  'EsticonMaskInput.error.invalidCharacters': 'Neplatné znaky v názvu / : * ? " |',
  'EsticonMaskInput.error.invalidTagFormat': 'Chybně naformátovaný tag',
  'EsticonMaskInput.error.invalidTag': 'Špatný tag: {tag}',
  'EsticonMaskInput.templateDescription.ShortYear': 'Zkrácený rok (např. 21)',
  'EsticonMaskInput.templateDescription.Year': 'Úplný rok (např. 2021)',
  'EsticonMaskInput.templateDescription.ShortMonth': 'Zkrácený měsíc (např. 4)',
  'EsticonMaskInput.templateDescription.Month': 'Úplný měsíc (např. 04)',
  'EsticonMaskInput.templateDescription.Name': 'Název',
  'EsticonMaskInput.templateDescription.Number': 'Číslo',
  'EsticonMaskInput.templateDescription.Code': 'Značka',
  'EsticonMaskInput.templateDescription.ObjectNumber': 'Kód/Značka objektu',
  'EsticonMaskInput.templateDescription.ObjectPath': 'Objektová cesta',
  'EsticonMaskInput.templateDescription.Cerpani.Code': 'Číslo protokolu',
  'EsticonMaskInput.templateDescription.Harmonogram.Code': 'Revize/Značka harmonogramu',
  'EsticonContainer.noProject.goToSettings': 'Přejít do nastavení',
  'EstiConDataAnalyzeResult.Success': 'Úspěch',
  'EstiConDataAnalyzeResult.FileReadError': 'Nastala chyba při čtení souboru',
  'EstiConDataAnalyzeResult.IncorrectType': 'Přiložený soubor neodpovídá správnému formátu. Import není možný',
  'EstiConDataAnalyzeResult.InvalidData': 'Soubor obsahuje nevalidní data',
  'EstiConDataAnalyzeResult.PairingError': 'Nepodařilo se spárovat položky souboru',
  'EstiConDataAnalyzeResult.Informative': 'Přiložený soubor může obsahovat chyby',
  'EstiConDataAnalyzeResult.NoData': 'Soubor neobsahuje žádná data',
  'EsticonProjectTransferToControlDatabaseModal.title': 'Přenos projektu do kontrolní databáze',
  'EsticonProjectTransferToControlDatabaseModal.succes': 'Přenos projektu do kontrolní databáze proběhl úspěšně',
  'EsticonProjectTransferToControlDatabaseModal.error': 'Přenos projektu do kontrolní databáze se nezdařil',
  'EstiConSaveResult.Success': 'Úspěch',
  'EstiConSaveResult.DuplicateRecord': 'Záznam již existuje',
  'EstiConSaveResult.NoData': 'Žádná data',
  'EstiConSaveResult.DataNotSaveable': 'Data nelze uložit',
  'EstiConSaveResult.DataNotValid': 'Data nejsou validní',
  'EstiConSaveResult.DataReadonly': 'Data jsou pouze pro čtení',
  'EstiConSaveResult.SentToHub': 'Odesláno na AspeHub',
  'EstiConSaveResult.SqlError': 'Sql chyba databáze',
  'EstiConSaveResult.IOException': 'Chyba při čtení nebo zápisu dat',
  'EstiConSaveResult.UnspecifiedError': 'Neznámá chyba',
  'EstiConSaveResult.PartialSuccess': 'Částečný úspech',
  'EstiConSaveResult.GeneralError': 'Obecná chyba',
  'EsticonObjectLink.disabledByEsticonLink': 'Dokument nemá aktivní vazbu na objekt Esticonu',
  'esticonErrors.importedDirectoryNotSet':
    'Složka pro import dokumentů z Esticonu není nastavena. ' + 'Kontaktujte prosím administrátora projektu.',
  'ErrorBoundary.title': 'Ups! Něco se pokazilo 💥',
  'ExtensionFilter.placeholder': 'Přípona (docx, pdf, ...)',
  'ExtensionFilter.withoutExtension': 'Bez přípony',
  'FavoriteButton.saving': 'Ukládání ...',
  'FavoriteButton.set': 'Přidat do oblíbených',
  'FavoriteButton.addSuccess': 'Přidáno k oblíbeným.',
  'FavoriteButton.success': 'Vybrané dokumenty byly přidány k oblíbeným.',
  'FavoriteButton.removeSuccess': 'Odebráno z oblíbených.',
  'FavoriteButton.unset': 'Odebrat z oblíbených',
  'FavoriteDocumentsPage.entries': `{total, plural,
    =0 {Nenalezeny žádné oblíbené dokumenty.}
    one {Nalezen jeden oblíbený dokument.}
    few {Nalezeny # oblíbené dokumenty.}
    other {Nalezeno # oblíbených dokumentů.}
  }`,
  'FavoriteDocumentsPage.loading': 'Načítám oblíbené dokumenty...',
  'FavoriteSwitch.hideSelected': 'Zobrazit pouze oblíbené složky',
  'FavoriteSwitch.showSelected': 'Zobrazit všechny složky',
  'FileViewer.processing': 'Náhled dokumentu se momentálně zpracovává',
  'FileViewer.reloadDerivateButton': 'Načíst náhled znovu',
  'FileViewer.rerenderingDerivatesMessage': 'Přegenerování je v procesu nebo není možné',
  'FileViewer.rerenderingDerivatesTooltip': 'Přegenerovat náhled',
  'FileViewer.rerenderingDerivatesButton': 'Přegenerovat náhled',
  'FileViewer.rerenderingDerivateButton': 'Přegenerovat',
  'FileViewer.rerenderingProcessing': 'Zpracovává se ...',
  'FileViewer.NoPreviewAvailable': 'Náhled pro tento typ souboru není dostupný',
  'FileViewer.UnsupportedMediaType': 'Nepodporovaný typ souboru',
  'FileViewer.UnsupportedPDFBrowser': 'Prohlížeč nepodporuje vkládání PDF do stránky. Kliknutím zobrazíte PDF v novém okně.',
  'FinancialOverviewByUnitReport.noUnit.title': 'Pro zobrazení reportu vyberte útvar.',
  'FinancialOverviewByUnitReport.column.sign': 'Značka',
  'FinancialOverviewByUnitReport.column.name': 'Název',
  'FinancialOverviewByUnitReport.column.unit': 'Útvar',
  'FinancialOverviewByUnitReport.column.investor': 'Investor',
  'FinancialOverviewByUnitReport.column.state': 'Stav',
  'FinancialOverviewByUnitReport.column.startDate': 'Zahájení',
  'FinancialOverviewByUnitReport.column.endDate': 'Dokončení',
  'FinancialOverviewByUnitReport.column.cost': 'Cena',
  'FinancialOverviewByUnitReport.column.invoicing': 'Fakturace',
  'FinancialOverviewByUnitReport.column.invoicedSoFar': 'Dosud',
  'FinancialOverviewByUnitReport.column.invoiceRemaining': 'Zbývá',
  'FinancialOverviewByUnitReport.column.costs': 'Náklady',
  'FinancialOverviewByUnitReport.column.costSoFar': 'Dosud',
  'FinancialOverviewByUnitReport.column.costRemaining': 'Zbývá',
  'FinancialOverviewByUnitReport.column.costTotal': 'Celkem',
  'FinancialOverviewByUnitReport.column.profit': 'Zisk/ztráta',
  'FinancialOverviewByUnitReport.column.coefficient': 'K',
  'FinancialOverviewByUnitReport.column.SoW': 'SOD',
  'FinancialOverviewByUnitReport.column.changeOrder': 'ZBV',
  'FinancialOverviewByUnitReport.column.invoice': 'K fakturaci',
  'FinancialOverviewByUnitReport.column.proposed': 'Návrhové',
  'FinancialOverviewByUnitReport.column.internal': 'Interní',
  'FinancialOverviewByUnitReport.column.ownPerformance': 'Vlastní výkon',
  'FinancialOverviewByUnitReport.column.subcontracts': 'Subdodávky',
  'FinancialOverviewByUnitReport.column.LinkToProject': 'Odkaz na projekt',
  'FinancialOverviewByUnitReport.phase.Dokonceno': 'Dokončeno',
  'FinancialOverviewByUnitReport.phase.Nabidka': 'Nabídka',
  'FinancialOverviewByUnitReport.phase.Neziskano': 'Nezískáno',
  'FinancialOverviewByUnitReport.phase.Priprava': 'Příprava',
  'FinancialOverviewByUnitReport.phase.Realizace': 'Realizace',
  'FinancialOverviewByUnitReport.title': 'Výrobní plán',
  'FinancialOverviewByUnitReport.projectLink.text': 'Přejít na projekt',
  'ProjectsInRealizationFiltersFormItem.missingValues': 'Nejsou vyplněny všechny povinné údaje pro načtení reportu',
  'ProjectsInRealizationOverviewReport.reportSelection.columnConfiguration': 'Zobrazené hodnoty',
  'ProjectsInRealizationOverviewReport.reportSelection.title': 'Nastavení reportu',
  'ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod': 'Sledované období',
  'ProjectsInRealizationOverviewReport.reportSelection.unit': 'Útvar reportu',
  'ProjectsInRealizationOverviewReport.reportSelection.date': 'Dokončené stavby od',
  'ProjectsInRealizationOverviewReport.reportSelection.view': 'Pohled',
  'ProjectsInRealizationOverviewReport.reportSelection.required.forYear': 'Rok, za který se zobrazí data, je povinný',
  'ProjectsInRealizationOverviewReport.reportSelection.required.unit': 'Útvar reportu je povinný',
  'ProjectsInRealizationOverviewReport.reportSelection.required.date': 'Datum reportu je povinné',
  'ProjectsInRealizationOverviewReport.column.io': 'O + I',
  'ProjectsInRealizationOverviewReport.column.unit': 'Závod',
  'ProjectsInRealizationOverviewReport.column.supervisor': 'TDS',
  'ProjectsInRealizationOverviewReport.column.subsidies': 'Dotace',
  'ProjectsInRealizationOverviewReport.column.projectName': 'Název akce',
  'ProjectsInRealizationOverviewReport.column.projectSign': 'Č. akce',
  'ProjectsInRealizationOverviewReport.column.supplier': 'Dodavatel',
  'ProjectsInRealizationOverviewReport.column.state': 'SoD (datum)',
  'ProjectsInRealizationOverviewReport.column.startDate': 'Zahájení',
  'ProjectsInRealizationOverviewReport.column.endDate': 'Dokončení',
  'ProjectsInRealizationOverviewReport.column.cost': 'Cena',
  'ProjectsInRealizationOverviewReport.column.costI': 'Cena - I',
  'ProjectsInRealizationOverviewReport.column.costO': 'Cena - O',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotal': 'Prostavěnost celkem',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalI': 'Prostavěnost I - celkem',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalO': 'Prostavěnost O - celkem',
  'ProjectsInRealizationOverviewReport.column.performedWorksYear': 'Prostavěnost {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearI': 'Prostavěnost I - {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearO': 'Prostavěnost O - {year}',
  'ProjectsInRealizationOverviewReport.column.costTotal': 'Celkem',
  'ProjectsInRealizationOverviewReport.column.unassignedYear': 'Nezatříděno',
  'ProjectsInRealizationOverviewReport.column.note': 'Poznámka',
  'ProjectsInRealizationOverviewReport.column.planI': 'Plán {year} - I',
  'ProjectsInRealizationOverviewReport.column.planO': 'Plán {year} - O',
  'ProjectsInRealizationOverviewReport.column.updateDate': 'Poslední změna',
  'ProjectsInRealizationOverviewReport.filter.type.empty': 'Bez typu',
  'ProjectsInRealizationOverviewReport.filter.type.i': 'I',
  'ProjectsInRealizationOverviewReport.filter.type.o': 'O',
  'ProjectsInRealizationOverviewReport.filter.type.oi': 'O + I',
  'ProjectsInRealizationOverviewReport.filter.subsidies.empty': 'Bez dotace',
  'ProjectsInRealizationOverviewReport.notesSaved': 'Poznámky projektu uloženy',
  'ProjectsInRealizationOverviewReport.title': 'Stavby v realizaci {year} (v tis. Kč) k datu {date}',
  'ProjectsInRealizationOverviewReport.views.total': 'Celkový objem staveb pod SoD {year}',
  'ProjectsInRealizationOverviewReport.views.onlyI': 'Investice - objem staveb pod SoD {year}',
  'ProjectsInRealizationOverviewReport.views.onlyO': 'Opravy - objem staveb pod SoD {year}',
  'ProjectsInRealizationPairingNotesPreviewModal.title': 'Náhled uložených poznámek',
  'ProjectsInRealizationPairingNotesModal.title': 'Oprava nespárovaných poznámek',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.title': 'Sloučení existujících poznámek',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.description': 'Vybraný EstiCon projekt už má vytvořené poznámky. Při pokračování dojde ke sloučení poznámek.',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.continue': 'Pokračovat',
  'ProjectsInRealizationPairingNotesModal.labels.noteList': 'Poznámky',
  'ProjectsInRealizationPairingNotesModal.labels.projectName': 'Název projektu',
  'ProjectsInRealizationPairingNotesModal.labels.sign': 'Značka',
  'ProjectsInRealizationPairingNotesModal.labels.startDate': 'Datum zahájení',
  'ProjectsInRealizationPairingNotesModal.labels.pairTo': 'Napárovat na',
  'ProjectsInRealizationPairingNotes.selectProjectName': '{name} (Shoda {matchCount} %)',
  'ProjectsInRealizationReportConfigurationColumnForm.addColumn': 'Přidat nový sloupec',
  'ProjectsInRealizationReportConfigurationColumnForm.defaultColumnName': 'Nový sloupec',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.name': 'Název sloupce',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.value': 'Hodnota',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.unitOrder': 'Řád jednotek',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.constant': 'Konstanta',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.property': 'Z reportu',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.units': 'Jednotky',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.thousands': 'Tisíce',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.millions': 'Milióny',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.billions': 'Miliardy',
  'ProjectsInRealisationSavedViewsModal.title': 'Uložené pohledy',
  'ProjectsInRealisationSavedViewsModal.newView.title': 'Uložit aktuální pohled jako nový',
  'ProjectsInRealisationSavedViewsModal.button.rename.tooltip': 'Přejmenovat',
  'ProjectsInRealisationSavedViewsModal.delete.title': 'Smazat pohled',
  'ProjectsInRealisationSavedViewsModal.delete.content': 'Pohled bude nenávratně smazáný. Pokračovat?',
  'ProjectsInRealisationSavedViews.workingView.name': '*Neuloženo',
  'ProjectsInRealisationNoteModal.title': 'Poznámky projektu',
  'ProjectsInRealisationNoteModal.delete.title': 'Smazat poznámku',
  'ProjectsInRealisationNoteModal.delete.content': 'Poznámka bude nenávratně smazána. Pokračovat?',
  'ProjectsInRealisationNoteModal.error.save': 'Chyba při ukládání poznámek. Změny se nepodařilo uložit.',
  'ProjectsInRealisationNote.source.AspeHub': 'Poznámka AspeHub',
  'ProjectsInRealisationNote.source.AspeEsticon': 'Poznámka z AspeEsticon',
  'ProjectsInRealizationPohReportSettingsForm.defaultDate': 'Výchozí datum realizace reportu',
  'ProjectsInRealizationPohReportSettingsForm.completedRealizationColor': 'Podbarvení dokončených staveb',
  'ProjectsInRealizationPohReportSettingsForm.unitColors': 'Podbarvení útvarů',
  'ProjectsInRealizationPohReport.export.fileName': 'prehled_projektu_v_realizaci_{date}.{extension}',
  'ProjectsInRealizationPohReport.Export.pdf': 'Export do pdf',
  'ProjectsInRealizationPohReport.Export.xlsx': 'Export do excelu',
  'ReportUnitColorAssign.error.unitNotFound': 'Útvar nenalezen',
  'ReportUnitColorAssign.addNewUnit': 'Přidat útvar',
  'ReportUnitColorAssign.selectEsticonFirm': 'Výběr esticon databáze',
  'ReportUnitSettingsModal.title': 'Oprávnění na útvary v reportu',
  'Forge.cannotViewInMode': 'Model {name} nelze zobrazit v požadovaném {mode} módu',
  'Forge.loading': 'Načítám Autodesk® Forge Viewer...',
  'Forge.loadingError': 'Chyba při načítání Autodesk® Forge Viewer.',
  'Forge.loadModelError': 'Nastala chyba při načítání modelu {name}',
  'Forge.viewing.showHoverOption.name': 'Zvýraznit modely',
  'Forge.viewing.showHoverOption.description': 'Barevně zvýrazní modely při najetí myší.',
  'Forge.warning.compare.title': 'Upozornění porovnání',
  'Forge.warning.compare.extension': 'U {extension} modelů se nesprávně porovnávají atributy.',
  'Forge.warning.compare.turnOffProperties': 'Můžete vypnout porovnání atributů v nastavení porovnání.',
  'Forge.warning.multipleScales.title': 'Modely obsahují různá měřítka',
  'Forge.warning.multipleScales.message':
    'Vybrané modely nelze správně zobrazit, protože obsahují několik různých měřítek.',
  'Forge.warning.multipleScales.meter': 'Modely v metrech:',
  'Forge.warning.multipleScales.millimeter': 'Modely v milimetrech:',
  'Forge.warning.multipleScales.other': 'Modely v jiné jednotce:',
  'Forge.warning.longDistances.title': 'Modely jsou moc daleko od sebe',
  'Forge.warning.longDistances.message':
  'Vybrané modely jsou umístěné příliš daleko od sebe, nepůjde vidět všechny modely zároveň.',
  'Forge.warning.longDistances.group': 'Skupina {number}:',
  'Forge.warning.longDistances.sharedCoordinatesMissing': 'Modely nevyužívají sdílené souřadnice.',
  'forge.warning.notFoundElements': 'Některé elementy se v modelu nepodařilo nalézt.',
  'ForgeViewer.loadingTokenError': 'Chyba při načítání dat náhledu.',
  'ForgeViewer.processModel': 'Zpracovat model',
  'ForgeViewer.processError': 'Při zpracování došlo k chybě.',
  'ForgeViewer.cannotProcessModels': 'Model nelze momentálně zpracovat, zkuste to prosím znovu později.',
  'ForgeViewer.processModelInfo':
    'Zpracování modelu je náročná operace a může trvat i několik minut v závislosti na velikosti modelu.',

  'Forge.measurements.errors.noElementSelected': 'Vyberte prvek, pro který chcete měření provést.',
  'Forge.measurements.errors.notAvailable': 'Nepodařilo se změřit objem a povrch. Element může byt neuzavřený.',
  'Forge.measurements.button.tooltip': 'Změřit objem a povrch',
  'Forge.measurements.result.surface': 'Povrch:',
  'Forge.measurements.result.volume': 'Objem:',
  'Forge.measurements.result.title': 'Měření povrchu a objemu',
  'Forge.measurements.warnings.openVolume':
    'Některé z vybraných objektů nejsou uzavřené, objem nemusí být správně spočítán.',
  'ForgeByAttributesFilter.foundElements': `{count, plural,
    =0 {Nebyly nalezeny žádné elementy}
    one {Byl nalezen jeden element}
    few {Byly nalezeny # elementy}
    other {Bylo nalezeno # elementů}
  }`,
  'ForgeByAttributesFilter.filtersTitle': 'Vyhledávání elementů podle vlastností',
  'ForgeByAttributesFilter.parameterFilterLabel': 'Atribut',
  'ForgeByAttributesFilter.valueFilterLabel': 'Hodnota',
  'forms.button.cancel': 'Zrušit',
  'forms.button.submit': 'Uložit',
  'forms.button.submit.loading': 'Ukládání...',
  'forms.items.createdBy.label': 'Vytvořil',
  'forms.items.participants.label': 'Účastníci',
  'forms.items.description.label': 'Popis',
  'forms.items.esticon.label': 'Připojený AspeEsticon project',
  'forms.items.esticon.warning': 'Opravdu chcete nastavit tento projekt? Připojený projekt nelze později změnit!',
  'forms.items.esticon.warning.ok': 'Ano, nastavit',
  'forms.items.userId.label': 'Uživatel',
  'forms.items.userIds.label': 'Uživatelé',
  'forms.items.userId.rules.required': 'Prosím vyberte uživatele',
  'forms.items.roleId.label': 'Role',
  'forms.items.roleId.rules.required': 'Prosím vyberte roli',
  'forms.items.forceRemove.label': 'Odebrat uživatele',
  'forms.items.forceRemove.description': 'Potvrdit odebrání uživatele',
  'forms.items.email.label': 'E-mail',
  'forms.items.email.placeholder': 'jan.novak@email.cz',
  'forms.items.labels.label': 'Štítky',
  'forms.items.name.label': 'Název',
  'forms.items.name.rules.empty': 'Název nesmí být prázdný',
  'forms.items.name.rules.nameExists': 'Zadaný název je již použitý, zvolte prosím jiný název',
  'forms.items.name.rules.required': 'Prosím zadejte název',
  'forms.items.owner.label': 'Vlastník dokumentu',
  'forms.items.primaryFile.rules.required': 'Prosím vyberte primární soubor',
  'forms.items.rules.duplicateEmail': 'Emailová adresa je již použita',
  'forms.items.rules.email': 'Prosím zadejte validní e-mailovou adresu',
  'forms.items.rules.email.noDiacritics': 'Prosím zadejte validní e-mailovou adresu, bez diakritiky.',
  'forms.items.rules.required': 'Pole je povinné',
  'forms.items.rules.noEmpty': 'Pole nesmí být prázdné',
  'forms.items.rules.document.required': 'Prosím vyberte nějaké documenty',
  'forms.items.rules.duplicated': 'Hodnota musí být unikátní',
  'forms.items.status.label': 'Stav',
  'forms.items.userPermissionProfile.label': 'Profil oprávnění',
  'forms.items.addDocumentsToWokrflow.justAllowedDocuments': 'Lze zařadit jen soubory, jejichž stav odpovídá nastavení vybrané šablony.',
  'FormWizardWrapper.prev': '< Zpět',
  'FormWizardWrapper.next': 'Pokračovat >',
  'FilenameErrorMessage.cancelRename': 'Zrušit přejmenování',
  'FilenameErrorMessage.message': 'Neplatné znaky v názvu',
  'FilenameErrorMessage.ok': 'Zavřít',
  'FilenameErrorMessage.title': 'Neplatný název',
  'FilenameErrorMessage.invalidCharactersTitle': 'Název nemůže obsahovat znaky',
  'FilenameErrorMessage.invalidNamesTitle': 'Zakázané názvy',
  'FilenameErrorMessage.showMoreInfo': 'Více informací (v angličtině)',
  'fullscreen.maximize': 'Maximalizovat',
  'fullscreen.minimize': 'Minimalizovat',
  'general.administrator': 'Administrátor',
  'general.administrator.aspeHub': 'Administrátor AspeHub',
  'general.activity': 'Aktivita',
  'general.add': 'Přidat',
  'general.addUsers': 'Přidat uživatele',
  'general.addNewUsers': 'Přidat nové uživatele',
  'general.addOrganization': 'Přidat organizaci',
  'general.addProject': 'Přidat projekt',
  'general.editOrganization': 'Editovat organizaci',
  'general.addDescription': 'Přidat popis',
  'general.addName': 'Přidat název',
  'general.assignedTo': 'Přiřazeno řešiteli',
  'general.assignment': 'Úkol',
  'general.attachments': 'Přílohy',
  'general.attachmentsWithCount': 'Přílohy ({count})',
  'general.author': 'Autor',
  'general.back': 'Zpět',
  'general.budget': 'Rozpočet',
  'general.clearFilters': 'Zrušit filtry',
  'general.categories': 'Kategorie',
  'general.categoriesNodes': 'Uzly kategorie',
  'general.completed': 'Dokončeno',
  'general.date': 'Datum',
  'general.deadline': 'Termín',
  'general.tests': 'Stress testy',
  'general.charLength': `{count, plural,
    =0 {# znaků}
    one {1 znak}
    few {# znaky}
    other {# znaků}
  }`,
  'general.clearFilter': 'Zrušit filtr',
  'general.close': 'Zavřít',
  'general.color': 'Barva',
  'general.comments': 'Připomínky',
  'general.commentProcedure': 'Připomínkové řízení',
  'general.compare': 'Porovnat',
  'general.create': 'Vytvořit',
  'general.currencyLabel': '{label} [{currency}]',
  'general.currency.czk': 'Kč',
  'general.currency.eur': '€',
  'general.currency.usd': '$',
  'general.currencyFormat.czk': '{value} Kč',
  'general.currencyFormat.eur': '{value} €',
  'general.currencyFormat.usd': '{value} $',
  'general.custom': 'Vlastní',
  'general.createdBy': 'Vytvořil uživatel',
  'general.createdDate': 'Vytvořeno: {date}',
  'general.created': 'Vytvořeno',
  'general.escape': 'Esc',
  'general.deletedDate': 'Odstraněno: {date}',
  'general.modifiedDate': 'Upraveno: {date}',
  'general.dayNames.monday': 'Pondělí',
  'general.dayNames.tuesday': 'Úterý',
  'general.dayNames.wednesday': 'Středa',
  'general.dayNames.thursday': 'Čtvrtek',
  'general.dayNames.friday': 'Pátek',
  'general.dayNames.saturday': 'Sobota',
  'general.dayNames.sunday': 'Neděle',
  'general.days': 'Dnů',
  'general.daysPlural': `{count, plural,
    =0 {dnů}
    one {den}
    few {dny}
    other {dnů}
  }`,
  'general.default': 'Výchozí',
  'general.delete': 'Odstranit',
  'general.deleted': 'Odstraněno',
  'general.deletedItemSuccessfully': `{count, plural,
    =0 {0 položek odstraněno}
    one {Položka byla úspěšně odstraněna}
    few {# položka byly úspěšně odstraněny}
    other {# položek bylo úspěšně odstraněno}
  }`,
  'general.description': 'Popis',
  'general.discussion': 'Diskuze',
  'general.docMenu.showModel': 'Zobrazit Model',
  'general.docMenu.addModel': 'Nový Model',
  'general.docMenu.addToSelected': 'Přidat k vybraným',
  'general.docMenu.bulkEdit': 'Hromadné úpravy',
  'general.docMenu.bulkEdit.documentWatch': 'Nastavit sledování',
  'general.docMenu.bulkEdit.modelsSetting': 'Nastavení modelu',
  'general.docMenu.bulkEdit.modelsSetting.add': 'Přidat k modelům',
  'general.docMenu.bulkEdit.modelsSetting.remove': 'Odebrat z modelů',
  'general.docMenu.bulkEdit.changeState': 'Změnit stav',
  'general.docMenu.bulkEdit.setLabels': 'Nastavit štítky',
  'general.docMenu.downloadInZip': 'Stáhnout ZIP',
  'general.docMenu.downloadInZip.thisDirOnly': 'Pouze tuto složku',
  'general.docMenu.downloadInZip.deepWithFiles': 'Všechny podsložky a dokumenty',
  'general.docMenu.downloadInZip.deepWithoutFiles': 'Složkovou strukturu bez dokumentů',
  'general.docMenu.downloadInZip.downloadError': 'Stažení ZIP se nezdařilo',
  'general.docMenu.downloadInZip.directoryLinkWarning': 'Zahrnuté odkazy na složky nejsou součástí vytvářeného ZIP.',
  'general.docMenu.downloadOriginal': 'Stáhnout originál',
  'general.docMenu.downloadSignedDocument': 'Stáhnout dokument k podepsání',
  'general.docMenu.downloadWithAnnotations': 'Stáhnout s komentáři',
  'general.docMenu.selectedFiles': `{count, plural,
    =0 {Nic vybráno}
    one {1 položka}
    few {# položky}
    other {# položek}
  }`,
  'general.docMenu.selected.tooltip': 'Odznačit vše',
  'general.docMenu.share': 'Sdílet',
  'general.docMenu.shareAppUserDownload': 'Sdílet se členy projektu',
  'general.docMenu.shareDownload': 'Odeslat odkaz ke stažení',
  'general.docMenu.sign': 'Podepsat',
  'general.docMenu.signOnline': 'Podepsat dokument elektronicky',
  'general.docMenu.signLocally': 'Nahrát podepsaný dokument',
  'general.docMenu.startWorkflow': 'Spustit workflow',
  'general.docMenu.createDocumentLinks': 'Vytvořit odkaz',
  'general.dirMenu.createDirectoryLink': 'Vytvořit odkaz',
  'general.documents': 'Dokumenty',
  'general.documentsWithCount': 'Dokumenty ({count})',
  'general.download': 'Stáhnout',
  'general.downloadAll': 'Stáhnout vše',
  'general.drawing': 'Čerpání',
  'general.createNewProject': 'Vytvořit nový projekt',
  'general.editProject': 'Editovat projekt',
  'general.edit': 'Upravit',
  'general.error': 'Chyba',
  'general.failedAction': 'Akce se nezdařila',
  'general.export': 'Exportovat',
  'general.export.tooltip': 'Exportovat (dle aktuálního filtru)',
  'general.extension': 'Přípona',
  'general.ExternApps': 'Externí aplikace',
  'general.filter': 'Filtrovat',
  'general.turnFiltersOn': 'Zapnout filtry',
  'general.turnFiltersOff': 'Vypnout filtry',
  'general.folder': 'Složka',
  'general.folders': 'Složky',
  'general.general': 'Obecné',
  'general.general.setting': 'Obecné nastavení',
  'general.groups': 'Skupiny',
  'general.help': 'Nápověda',
  'general.goTo': 'Přejít do: ',
  'general.import': 'Importovat',
  'general.insufficientPermission': 'Nemáte dostatečné oprávnění pro přístup.',
  'general.invoicing': 'Fakturace',
  'general.links': 'Odkazy',
  'general.bindings': 'Vazby',
  'general.acknowledge': 'Beru na vědomí',
  'general.ok': 'OK',
  'general.on': 'Zapnuto',
  'general.off': 'Vypnuto',
  'general.onOff': 'Částečně zapnuto',
  'general.help.licence': 'Informace o licenci',
  'general.labels': 'Štítky',
  'general.loading': 'Načítání ...',
  'general.logout': 'Odhlásit',
  'general.maxDescriptionLength': 'Popis je moc dlouhý. Maximální délka popisu je {max} znaků.',
  'general.maxNameLength': 'Název je moc dlouhý. Maximální délka názvu je {max} znaků.',
  'general.maxLength': 'Zadaná hodnota je moc dlouhá. Maximální délka je {max} znaků.',
  'general.manager': 'Správce dokumentu',
  'general.modified': 'Upraveno',
  'general.modifiedBy': 'Upravil',
  'general.move': 'Přesunout',
  'general.month.january': 'Leden',
  'general.month.february': 'Únor',
  'general.month.march': 'Březen',
  'general.month.april': 'Duben',
  'general.month.may': 'Květen',
  'general.month.june': 'Červen',
  'general.month.july': 'Červenec',
  'general.month.august': 'Srpen',
  'general.month.september': 'Září',
  'general.month.october': 'Říjen',
  'general.month.november': 'Listopad',
  'general.month.december': 'Prosinec',
  'general.name': 'Název',
  'general.nameAndDescription': 'Název a popis',
  'general.newProject': 'Nový projekt',
  'general.defaultTimeZone': 'Výchozí časová zóna',
  'general.storagearea': 'Pobočka',
  'general.storageareas': 'Pobočky',
  'general.defaultLanguage': 'Výchozí jazyk',
  'general.no': 'Ne',
  'general.organizations': 'Organizace',
  'general.organizationLabels': 'Organizační štítky',
  'general.groupedByOrganizations': 'Sdruženo po organizacích',
  'general.permission': 'Oprávnění',
  'general.previous': 'Předchozí',
  'general.next': 'Další',
  'general.noMoreUsers': 'Žádní další uživatelé',
  'general.from': 'Od: ',
  'general.to': 'Do: ',
  'general.unknown': 'Neznámý',
  'general.unlimited': '(neomezeno)',
  'general.noUser': 'Žádný uživatel',
  'general.noDocuments': 'Ve vybrané složce nejsou dokumenty.',
  'general.noItems': 'Nejsou zde žádné položky.',
  'general.notEmptyItemError': 'Položka musí být vyplněna',
  'general.notSet': 'Není nastaveno',
  'general.nothingFound': 'Nic nebylo nalezeno.',
  'general.organization': 'Organizace',
  'general.path': 'Cesta',
  'general.PartialWiP': 'Dílčí ZP',
  'general.PartialInvoice': 'Dílčí FA',
  'general.preview': 'Náhled',
  'general.project': 'Projekt',
  'general.projectList': 'Seznam projektů',
  'general.projects': 'Projekty',
  'general.projectSettings': 'Nastavení projektu',
  'general.reinvite': 'Znovu pozvat',
  'general.revisions': 'Revize',
  'general.role': 'Role',
  'general.roles': 'Role',
  'general.roleName': 'Název role',
  'general.save': 'Uložit',
  'general.saveProfile': 'Uložit profil',
  'general.search': 'Hledat',
  'general.settings': 'Nastavení',
  'general.settings.profile': 'Nastavení profilu',
  'general.source': 'Zdroj',
  'general.sort': 'Seřadit',
  'general.sortedBy': 'Seřazeno dle',
  'general.sortBy': 'Seřadit dle',
  'general.sortDirection': 'Směr řazení',
  'general.sort.asc': 'Od A do Z (Vzestupně)',
  'general.sort.desc': 'Od Z do A (Sestupně)',
  'general.start': 'Spustit',
  'general.state': 'Stav',
  'general.state.archived': 'Archivováno',
  'general.state.published': 'Publikováno',
  'general.state.rejected': 'Zamítnuto',
  'general.state.shared': 'Sdíleno',
  'general.state.workInProgress': 'Rozpracováno',
  'general.title': 'Nadpis',
  'general.cancel': 'Zrušit',
  'general.cancelChanges': 'Zrušit změny',
  'general.change': 'Změnit',
  'general.showMore': 'Zobrazit více',
  'general.select': 'Vybrat',
  'general.selectAll': 'Vybrat vše',
  'general.showMoreOptions': 'více možností',
  'general.showLess': 'Zobrazit méně',
  'general.schedule': 'Harmonogram',
  'general.signIn': 'Přihlásit',
  'general.size': 'Velikost',
  'general.team': 'Tým',
  'general.category': 'Kategorie',
  'general.total': 'Celkem',
  'general.template': 'Šablona',
  'general.template.create': 'Vytvořit šablonu',
  'general.type': 'Typ',
  'general.users': 'Uživatelé',
  'general.userName': 'Uživatel',
  'general.userMail': 'E-mail uživatele',
  'general.firstName': 'Jméno',
  'general.lastName': 'Příjmení',
  'general.language': 'Jazyk',
  'general.userNames': 'Jméno a příjmení',
  'general.messages': 'Zprávy',
  'general.warning': 'Upozornění',
  'general.watch': 'Sledování',
  'general.workDaysCalendar': 'Kalendář pracovních dní',
  'general.view': 'Zobrazit',
  'general.yes': 'Ano',
  'general.needAdminAccess': 'Pro přístup k této položce potřebujete administrátorské oprávnění.',
  'general.send': 'Odeslat',
  'general.ZBV': 'ZBV',
  'general.workflow': 'Workflow',
  'general.document': 'Dokument',
  'general.link': 'Odkaz',
  'general.forge': 'Zpracování modelů',
  'general.textMaxLengthWarning': 'Dosáhli jste maximálního počtu znaků',
  'general.reference': 'Reference',
  'contextMenuExpandCollapseUtils.expand': 'Rozbalit',
  'contextMenuExpandCollapseUtils.collapse': 'Sbalit',
  'GrantAccessModal.addUser': 'Přidat oprávnění uživateli',
  'GrantAccessModal.folderLabel': 'Složka',
  'GrantAccessModal.message': 'Uživatel {user} požádal o udělení oprávnění.',
  'GrantAccessModal.noInGroup': 'Uživatel {user} není v žádné skupině.',
  'GrantAccessModal.requiredPermissionLabel': 'Požadované oprávnění',
  'GrantAccessModal.title': 'Udělení oprávnění přístupu ke složce',
  'GrantAccessModal.userLabel': 'Pro uživatele',
  'GrantAccessModal.usersLabel': 'Nastavit oprávnění uživateli',
  'GrantAccessModal.groupsDescription': 'Skupiny ve kterých je uživatel {user} jsou',
  'GrantAccessModal.groupsDescription.highlight': 'zvýrazněny',
  'GrantAccessModal.groupsLabel': 'Skupinová oprávnění ke složce',
  'GrantAccessModal.groupsMemberLabel': 'Skupiny uživatele {user}',
  'GrantAccessModal.unauthorizedError.message': 'Nemáte potřebné oprávnění pro nastavení přístupu.',
  'GroupForm.items.defaultDirectoryAccessLevel.label': 'Výchozí úroveň přístupového oprávnění ke složce',
  'GroupForm.items.name.placeholder': 'Divák - pouze čtení',
  'GroupFormModal.title.create': 'Nová skupina',
  'GroupUserCount.noUsers': 'Žádní uživatelé',
  'GroupUserCount.userCount': `{count} {count, plural,
    one {Uživatel}
    few {Uživatelé}
    other {Uživatelů}
  }`,
  'GroupUserAddFormModal.title': 'Výběr uživatelů pro přidání do skupiny',
  'HeaderInfoDropdown.Licence.title': 'Informace o licenci',
  'HeaderInfoDropdown.Licence.used': 'Využito',
  'HeaderInfoDropdown.Licence.remaining': 'Zbývající kapacita',
  'HeaderInfoDropdown.Licence.validTo': 'Platnost do',
  'HeaderInfoDropdown.Documentation.title': 'Dokumentace',
  'HeaderInfoDropdown.UserDocumentation.title': 'Uživatelská dokumentace',
  'HeaderInfoDropdown.TechnicalDocumentation.title': 'Technická dokumentace',
  'HeaderInfoDropdown.Version.title': 'Informace o verzi',
  'HeaderInfoDropdown.Version.number': 'Verze',
  'HeaderInfoDropdown.Version.NoData': 'Informace není k dispozici',
  'HeaderInfoDropdown.Version.builtDate': 'Datum sestavení',
  'HeaderInfoDropdown.ShortCuts.title': 'Klávesové zkratky',
  'HeaderInfoDropdown.ShortCuts.ESC': 'Zavřít náhled',
  'HeaderInfoDropdown.ShortCuts.next': 'Další dokument',
  'HeaderInfoDropdown.ShortCuts.DocumentPreview': 'Náhled dokumentu',
  'HeaderInfoDropdown.ShortCuts.DocumentsList': 'Seznam dokumentů',
  'HeaderInfoDropdown.ShortCuts.ModelWorking': 'Práce s modelem',
  'HeaderInfoDropdown.ShortCuts.MouseLeftClick': 'Kliknutí levým tlačítkem myši',
  'HeaderInfoDropdown.ShortCuts.previous': 'Předchozí dokument',
  'HeaderInfoDropdown.ShortCuts.ContinuousSelection': 'Souvislý výběr dokumentů',
  'HeaderInfoDropdown.ShortCuts.MultipleModelElementsSelect': 'Výběr více prvků modelu',
  'HeaderInfoDropdown.GDPR.title': 'GDPR',
  'HeaderInfoDropdown.GDPR.text':
    'V rámci aplikace AspeHub jsou zpracovávány osobní údaje uživatelů pro potřeby evidence a řízení projektů v aplikaci AspeHub v nezbytném rozsahu  (jméno, příjmení, email, telefon). Pro bližší informace včetně možnosti odvolání souhlasu se zpracováním osobních údajů je možné kontaktovat provozovatele aplikace.',
  'HeaderInfoDropdown.Contacts.title': 'Kontakty',
  'HeaderInfoDropdown.Contacts.webPageTitle': 'Web',
  'HeaderInfoDropdown.Contacts.technicalSupport': 'Technická podpora',
  'HeaderInfoDropdown.Contacts.phone': 'tel.:',
  'HeaderInfoDropdown.Contacts.mail': 'e-mail:',
  'HeaderLocaleDropdown.changeLanguage': 'Změnit jazyk',
  'HeaderSearch.placeholder': 'Hledat v projektu',
  'IdleTimerActivityCheck.modal.title': 'Odhlášení při nečinnosti',
  'IdleTimerActivityCheck.modal.message': 'Z důvodu neaktivity budete {time} odhlášeni. Přejete si pokračovat v práci?',
  'IdleTimerActivityCheck.modal.confirm': 'Zůstat přihlášen',
  'InsufficientAccessLevelError.message': 'Nemáte oprávnění pro čtení tohoto dokumentu.',
  'InsufficientAccessLevelError.description': 'Pro zobrazení požádejte správce složky o přístup.',
  'InviteReportModal.label': 'Nepodařilo se pozvat všechny uživatele',
  'InviteReportModal.alreadyExists.label': `{count, plural,
    =0 {no}
    one {Následující uživatel na projektu již existuje}
    other {Následující uživatelé na projektu již existují}
    }.`,
  'InviteReportModal.invited.label': `{count, plural,
    =0 {Nebyli pozváni žádní uživatelé}
    one {Byl pozván následující uživatel}
    other {Byli pozváni následující uživatelé}
    }.`,
  'InviteReportModal.uninvited.label': `{count, plural,
    =0 {no}
    one {Nemohl být pozván následující uživatel}
    other {Nemohli být pozváni následující uživatelé}
    }.`,
  'InviteReportModal.limit': `{count, plural,
    =0 {Není možné pozvat dalšího uživatele}
    one {Je možné pozvat jednoho uživatele}
    few {Je možné pozvat # uživatele}
    other {Je možné pozvat # uživatelů}
    }.`,
  'InvoiceDetail.title': 'Detail faktury',
  'InvoiceDetail.menu.additionalInformation': 'Doplňující údaje',
  'InvoiceDetail.menu.items': 'Položky',
  'InvoiceDetail.menu.overview': 'Přehled',
  'InvoiceDetailAdditionalInformation.forTheContractor': 'Za zhotovitele',
  'InvoiceDetailAdditionalInformation.forTheCustomer': 'Za objednatele',
  'InvoiceDetailAdditionalInformation.function': 'Funkce',
  'InvoiceDetailAdditionalInformation.invoiceManager': 'Správce faktury 7.7',
  'InvoiceDetailAdditionalInformation.where': 'Kde',
  'InvoiceItemsDetail.columns.basicVATPercent': 'Základ DPH {rate} %',
  'InvoiceItemsDetail.columns.object': 'Objekt',
  'InvoiceItemsDetail.columns.realizedPercent': 'Čerpáno {rate} %',
  'InvoiceItemsDetail.columns.retentionPercent': 'Zádržné [%]',
  'InvoiceItemsDetail.columns.retention': 'Zádržné',
  'InvoiceItemsDetail.columns.salePercent': 'Sleva [%]',
  'InvoiceItemsDetail.columns.sale': 'Sleva',
  'InvoiceItemsDetail.columns.suspensionPercent': 'Pozastávka [%]',
  'InvoiceItemsDetail.columns.suspension': 'Pozastávka',
  'InvoiceItemsDetail.columns.total': 'Celkem',
  'InvoiceItemsDetail.columns.valorizationPercent': 'Valorizace [%]',
  'InvoiceItemsDetail.columns.valorization': 'Valorizace',
  'InvoiceItemsDetail.columns.vat': 'DPH {rate} %',
  'InvoiceDetail.menu.paymentInformation': 'Platební údaje',
  'InvoiceDetailOverview.administrator': 'Správce/Investor',
  'InvoiceDetailOverview.billingPeriod': 'Fakturační období',
  'InvoiceDetailOverview.contacts': 'Kontakty',
  'InvoiceDetailOverview.contractor': 'Dodavatel',
  'InvoiceDetailOverview.invoiceNumber': 'Číslo faktury',
  'InvoiceDetailOverview.issue': 'Vystavení',
  'InvoiceDetailOverview.maturity': 'Splatnost',
  'InvoiceDetailOverview.payment': 'Uhrazení',
  'InvoiceDetailOverview.price': 'Cena',
  'InvoiceDetailOverview.serialNumber': 'PČ',
  'InvoiceDetailOverview.taxBase': 'Základ daně (21%)',
  'InvoiceDetailOverview.taxDocument': 'Daňový doklad',
  'InvoiceDetailOverview.taxableSupply': 'Zdanitelné plnění',
  'InvoiceDetailOverview.totalWithoutVat': 'Celkem bez DPH',
  'InvoiceDetailOverview.totalToBePaidIncludingVat': 'Celkem k úhradě vč. DPH',
  'InvoiceDetailOverview.invoiceType': 'Typ faktury',
  'InvoiceDetailOverview.invoiceType.Klasicka': 'Klasická',
  'InvoiceDetailOverview.invoiceType.Zalohova': 'Zálohová',
  'InvoiceDetailOverview.invoiceType.Dobropis': 'Dobropis',
  'InvoiceDetailPaymentInformation.bankAccount': 'Bankovní účet',
  'InvoiceDetailPaymentInformation.bankDetailsAdministrator': 'Bankovní spojení Správce/Investor',
  'InvoiceDetailPaymentInformation.bankDetailsContractor': 'Bankovní spojení Dodavatel',
  'InvoiceDetailPaymentInformation.bicSwift': 'BIC/SWIFT',
  'InvoiceDetailPaymentInformation.iban': 'IBAN',
  'InvoiceDetailAdditionalInformation.taxPeriod': 'Daňové období',
  'InvoiceDetailAdditionalInformation.VATTransferRegime': 'Režim přenosu DPH',
  'InvoiceDetailAdditionalInformation.contracts': 'Smlouvy',
  'InvoiceDetailAdditionalInformation.signature': 'Podpis faktury',
  'InvoiceDetailAdditionalInformation.contractDate': 'Datum smlouvy',
  'InvoiceDetailAdditionalInformation.contractContractor': 'Smlouva zhotovitel',
  'InvoiceDetailAdditionalInformation.contractCustomer': 'Smlouva objednatel',
  'IsModelButton.modal.confirm.title': 'Model obsahuje vazby na elementy',
  'IsModelButton.modal.confirm.content': 'Při odebrání dokumentu z modelů budou odebrány všechny vazby na elementy. Pokračovat?',
  'IsModelButton.message.success': 'Dokument byl odebrán z modelů',
  'IsModelButton.message.error': 'Dokument se nepodařilo odebrat z modelů',
  'IsModelButton.message.addSuccess': 'Dokument byl přidán k modelům',
  'IsModelButton.message.addError': 'Dokument se nepodařilo přidat k modelům',
  'CommonInvoiceDetail.title': 'Detail společné faktury',
  'CommonInvoiceDetail.menu.overview': 'Přehled',
  'CommonInvoiceDetail.menu.paymentInformation': 'Platební údaje',
  'CommonInvoiceDetail.menu.additionalInformation': 'Doplňující údaje',
  'CommonInvoiceDetail.menu.memberInvoices': 'Členské faktury',
  'CommonInvoiceItemsGrid.columns.sign': 'Kód',
  'CommonInvoiceItemsGrid.columns.contractor': 'Dodavatel',
  'CommonInvoiceItemsGrid.columns.order': 'Pořadí',
  'CommonInvoiceItemsGrid.columns.priceWithoutVat': 'Cena bez DPH',
  'CommonInvoiceItemsGrid.columns.vatPriceBasic': 'Základní sazba DPH',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced1': 'První snížená sazba DPH',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced2': 'Druhá snížená sazba DPH',
  'CommonInvoiceItemsGrid.columns.totalPrice': 'Cena celkem',
  'ItemNotFoundErrorBox.description': 'Položka byla asi odstraněna nebo je chybná adresa odkazu.',
  'ItemNotFoundErrorBox.title': 'Položka nebyla nalezena.',
  'ItemNotFoundErrorBox.toDocuments': 'Přejít na dokumenty',
  'ItemRowNameContainer.createdDate': 'Poslední změna {date}',
  'ItemRowNameContainer.user': 'Uživatel',
  'KpiConfigurationFormModal.title.create': 'Nová konfigurace KPI',
  'KpiConfigurationFormModal.title.edit': 'Upravit konfiguraci KPI',
  'KpiConfigurationForm.title': 'Název',
  'KpiConfigurationForm.title.required': 'Název je povinný',
  'KpiConfigurationForm.columnRules': 'Pravidla',
  'KpiConfigurationFormItem.column': 'Sloupec reportu',
  'KpiConfigurationFormItem.condition': 'Podmínka',
  'KpiConfigurationFormItem.referentialValue': 'Referenční hodnota',
  'KpiConfigurationFormItem.evaluation': 'Vyhodnocení',
  'KpiConfigurationFormItem.evaluation.ok': 'V pořádku',
  'KpiConfigurationFormItem.evaluation.critical': 'Kritické',
  'KpiConfigurationFormItem.addRule.title': 'Nové pravidlo',
  'KpiConfigurationFormItem.addRule.button': 'Přidat',
  'KpiConfigurationFormItem.addRule.disabled.tooltip': 'Všechny sloupce reportu už mají vytvořené pravidlo',
  'KpiSettingsToolbar.menu.add': 'Přidat novou konfiguraci KPI',
  'KpiSettingsToolbar.menu.edit': 'Upravit aktivní konfiguraci KPI',
  'KpiSettingsToolbar.menu.delete': 'Smazat aktivní konfiguraci KPI',
  'LabelFilterItem.Operators.AND': 'A',
  'LabelFilterItem.Operators.OR': 'NEBO',
  'LabelFormModal.title.create': 'Nový štítek',
  'LabelsForm.form.items.color.label': 'Barva',
  'LabelsForm.form.items.description.placeholder': 'Volitelný popis...',
  'LabelsForm.form.items.name.placeholder': 'Název štítku',
  'LabelsForm.preview': 'Náhled štítku',
  'LabelsList.deleteConfirmModal.delete': 'Smazat štítek',
  'LabelsList.deleteConfirmModal.description':
    'Opravdu chcete smazat štítek a odebrat jej ' +
    'z {documentCount} dokumentů, {commentProcedureCount} připomínkových řízení a {workflowCount} workflow?',
  'LabelsList.deleteConfirmModal.title': 'Štítek nebyl smazán, protože je přiřazen některým objektům.',
  'LabelsInput.placeholder': 'Vyberte štítky',
  'LablesEditableList.noLabels': 'Žádné štítky',
  'ListEmpty.noData': 'Seznam je prázdný.',
  'ListEmpty.noItemsFound': 'Žádné položky neodpovídají zadaným filtrům.',
  'ListEmpty.noDocumentsFound': 'Žádné dokumenty neodpovídají zadaným filtrům.',
  'LocalSigningModal.title': 'Podpis lokálním certifikátem',
  'LocalSigningModal.button.sign': 'Podepsat',
  'LocalSigningModal.input.passphrase': 'Heslo',
  'LocalSigningModal.certificateFile': 'Vyberte soubor s podpisem (pfx, p12)',
  'LocalSigningModal.error.stampLoadingFailed': 'Nepodařilo se načíst pozadí pro digitální podpis',
  'LocalSigningModal.error.fontLoadingFailed': 'Nepodařilo se načíst font pro digitální podpis',
  'LocalSigningModal.error.missingResources': 'Nejsou načtené podklady pro zahájení podpisu',
  'LocalSigningModal.status.initializing': 'Připravuje se prostředí pro podpis',
  'LocalSigningModal.status.signingDocument': 'Podepisování dokumentu',
  'LocalSigningModal.status.uploadingDocument': 'Nahrávání podepsaného dokumentu',
  'LocalSignatureErrors.singingProcessFailed': 'Nastala chyba při podepisování dokumentu. Dokument se nepodařilo podepsat.',
  'LocalSignatureErrors.uploadProcessFailed': 'Nastala chyba při ukládání podepsaného dokumentu. Dokument se nepodařilo podepsat.',
  'LocalSignatureErrors.invalidCertificateOrPassword': 'Chybný formát certifikátu nebo neplatné heslo.',
  'LocalSignatureErrors.certificateMissingHolderName': 'Certifikát neobsahuje jméno držitele pro zobrazení.',
  'LocalSignatureErrors.signerFailedToCreate': 'Nepodařilo se vytvořit formát podpisu pro PDF.',
  'LoginErrorPage.AccessDeniedMessage': 'Nejste oprávněni pro přístup k této aplikaci.',
  'LoginErrorPage.Button.ReturnToLogin': 'Zpět na přihlášení',
  'LoginErrorPage.ErrorTitle': 'Přístup odepřen',
  'MainDashboard.noSelectedProjects': 'Nemáte žádné vybrané projekty :-(',
  'MainDashboard.noSelectedReports': 'Nemáte žádné nastavené reporty',
  'MainDashboard.viewAllProjects': 'Zobrazit všechny projekty',
  'MainDashboard.viewAllReports': 'Zobrazit všechny reporty',
  'menu.side.comment.procedure': 'Připomínková řízení',
  'menu.side.AspeHubSetting': 'Nastavení AspeHub',
  'menu.side.hubAdministrators': 'Administrátoři',
  'menu.side.hubOrganizations': 'Organizace',
  'menu.side.hubUsers': 'Uživatelé',
  'menu.side.organizationsSetting': 'Nastavení organizací',
  'menu.side.dashboard': 'Dashboard',
  'menu.side.search': 'Vyhledávání v projektu',
  'menu.side.settings': 'Nastavení projektu',
  'menu.side.projects': 'Projekty',
  'menu.side.reporting': 'Reporty',
  'menu.side.documents': 'Dokumenty',
  'menu.side.documents.all': 'Vše',
  'menu.side.documents.category': 'Kategorie',
  'menu.side.documents.favorite': 'Oblíbené',
  'menu.side.documents.recent': 'Nedávné',
  'menu.side.documents.smart': 'SMART',
  'menu.side.documents.filters': 'Filtry',
  'menu.side.toggleMenu': 'Maximalizovat menu',
  'menu.side.minimizeMenu': 'Minimalizovat menu',
  'menu.side.model': 'Model',
  'menu.side.changeProject': 'Změnit projekt',
  'menu.side.workflow': 'Workflow',
  'menu.side.workflow.instances': 'Spuštené',
  'menu.side.workflow.templates': 'Šablony',
  'menu.side.budget': 'Rozpočet',
  'menu.side.construction': 'AspeEsticon',
  'menu.side.drawing': 'Čerpání',
  'menu.side.invoicing': 'Fakturace',
  'menu.side.zbv': 'ZBV',
  'menu.side.schedule': 'Harmonogram',
  'menu.side.financialPlan': 'Finanční plán',
  'menu.side.constructionJournal': 'Stavební deník',
  'menu.side.documents.discarded': 'Koš ',
  'menu.side.externalApplications': 'Externí aplikace',
  'MessageCenterAssignments.createAssignment': 'Nový úkol',
  'MessageCenterAssignments.createdDate': 'Datum vytvoření',
  'MessageCenterAssignments.deadlineDate': 'Požadovaný termín',
  'MessageCenterAssignments.resolvedDate': 'Datum splnění',
  'MessageCenterAssignments.source': 'Role',
  'MessageCenterAssignments.source.title': 'Moje role v úkolu',
  'MessageCenterPage.tabs.assignments': 'Úkoly',
  'MessageCenterPage.tabs.messages': 'Zprávy',
  'MessageCenterPage.tabs.report': 'Report',
  'MessageCenterPage.tabs.settings': 'Nastavení',
  'MessageCenterPage.title': 'Centrum zpráv',
  'MessageCenterPage.filters.category.label': 'Kategorie',
  'MessageCenterPage.filters.categoryType.label': 'Typ',
  'MessageCenterPage.filters.createDateRange.label': 'Vytvořeno',
  'MessageCenterPage.filters.finishDateRange.label': 'Ukončeno',
  'MessageCenterPage.filters.state.label': 'Stav',
  'MessageCenterPage.filters.project.label': 'Projekt',
  'MessageCenterPage.order.createTime.label': 'Čas vytvoření',
  'MessageCenterPage.order.finishTime.label': 'Čas ukončení',
  'MessageCenterPage.order.status.label': 'Stav',
  'MessageCenterPage.order.category.label': 'Kategorie',
  'MessageCenterPage.order.project.label': 'Projekt',
  'MessageCenterPage.message.categoryType.assignment': 'Úkol',
  'MessageCenterPage.message.categoryType.commentProcedure': 'Připomínkové řízení',
  'MessageCenterPage.message.categoryType.workflow': 'Workflow',
  'MessageCenterPage.message.categoryType.appFileShare': 'Sdílení',
  'MessageCenterPage.message.categoryType.alert': 'Výstraha',
  'MessageCenterPage.message.categoryType.bulkAction': 'Hromadná akce',
  'MessageCenterPage.message.categoryType.subscriber': 'Sledování',
  'MessageCenterPage.message.categoryType.accountNotification': 'Projekt',
  'MessageCenterPage.message.categoryType.document': 'Dokumenty',
  'MessageCenterPage.message.categoryType.document.filter': 'Dokumenty',
  'MessageCenterPage.message.categoryType.demandMsg': 'Uživatelské upozornění',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared.filter': 'Schválené dokumenty workflow jsou sdíleny',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared': 'Schválené dokumenty workflow jsou sdíleny',
  'MessageCenterPage.message.category.assignmentDeadlineExpired': 'Úkolu vypršel termín',
  'MessageCenterPage.message.category.assignmentDeadlineExpired.filter': 'Úkolu vypršel termín',
  'MessageCenterPage.message.category.assignmentDeadlineWarning': 'Termín v úkolu brzy vyprší.',
  'MessageCenterPage.message.category.assignmentDeadlineWarning.filter': 'Termín v úkolu brzy vyprší.',
  'MessageCenterPage.message.category.assignmentDocumentsChanged': 'Dokument v úkolu se změnil.',
  'MessageCenterPage.message.category.assignmentStarted': 'Byl vytvořen úkol.',
  'MessageCenterPage.message.category.assignmentChanged': 'Zadání úkolu bylo změněno',
  'MessageCenterPage.message.category.assignmentUsersChanged': 'Uživatelé úkolu byli změněni.',
  'MessageCenterPage.message.category.assignmentStateChanged': 'Změnil se stav úkolu.',
  'MessageCenterPage.message.category.assignmentUserAdded': 'Přidán uživatel k úkolu.',
  'MessageCenterPage.message.category.assignmentUserRemoved': 'Odebrán uživatel z úkolu.',
  'MessageCenterPage.message.category.assignmentNoteAdded': 'Byl přidán nový komentář k úkolu',
  'MessageCenterPage.message.category.assignmentNoteUserNotified': 'Upozornění na zmínku v komentářu úkolu',
  'MessageCenterPage.message.category.wfNodeStarted': 'Právě Vám byla přiřazena k řešení úloha ve workflow.',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded':
    'Termín pro vyřešení aktuální úlohy bude brzy překročen.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded': 'Termín pro vyřešení aktuální úlohy byl překročen.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded':
    'Byla nahrána revize u schvalovanému dokumentu.',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded': 'Byl nahrán nový schvalovaný dokument.',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved': 'Byl odebrán schvalovaný dokument.',
  'MessageCenterPage.message.category.wfWorkflowFinished': 'Schvalovací proces Workflow skončil.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded':
    'Termín pro vložení podkladového dokumentu byl překročen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded':
    'Termín pro vložení podkladového dokumentu bude brzy překročen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted':
    'Ve schvalovacím procesu Workflow máte aktivní požadavek na přiložení podkladového dokumentu.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded': 'Podkladový dokument byl přiložen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved':
    'Požadavek na přiložení podkladového dokumentu do schvalovacího procesu Workflow byl zrušen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged':
    'Požadavek na přiložení podkladového dokumentu do schvalovacího procesu Workflow byl změněn.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted': 'Podkladový dokument byl schválen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused': 'Podkladový dokument byl odmítnut.',
  'MessageCenterPage.message.category.wfNodeFinished': 'Úloha schvalovacího procesu Workflow byla ukončena.',
  'MessageCenterPage.message.category.wfWorkflowClosed': 'Schvalovací proces Workflow byl uzavřen.',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted': 'Schvalované dokumenty byly schváleny.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused': 'Schvalované dokumenty byly zamítnuty.',
  'MessageCenterPage.message.category.wfNotification': 'Zprávy z notifikačního uzlu.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded': 'Byl přidán komentář k úloze workflow.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified':
    'Uživatel v komentáři workflow úlohy byl upozorněn.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded': 'Byla přidána příloha k workflow úloze.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved': 'Byla vyřazena příloha z workflow úlohy.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored': 'Byla obnovena příloha ve workflow úloze.',
  'MessageCenterPage.message.category.cpCommentAdded': 'Připomínka byla přidána.',
  'MessageCenterPage.message.category.cpDocumentAdded': 'Byl přidán dokument k připomínce.',
  'MessageCenterPage.message.category.cpDocumentRemoved': 'Byl odebrán dokument od připomínky.',
  'MessageCenterPage.message.category.cpStateChanged': 'Stav připomínkového řízení byl změněn.',
  'MessageCenterPage.message.category.cpCommentStateChangded': 'Stav připomínky v připomínkovém řízení byl změněn',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged': 'Došlo ke změnám seznamu týmů připomínkujících na připomínce.',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged': 'Došlo ke změnám seznamu týmů zpracovatelů na připomínce.',
  'MessageCenterPage.message.category.cpCommentNoteAdded': 'Byl přidán komentář k připomínce.',
  'MessageCenterPage.message.category.cpCommentTextChanged': 'Byl změněn text připomínky.',
  'MessageCenterPage.message.category.cpUserAdded': 'Uživatel byl přidán do připomínkového řízení.',
  'MessageCenterPage.message.category.cpUserRemoved': 'Uživatel byl odebrán z připomínkového řízení.',
  'MessageCenterPage.message.category.cpCommentDocumentAdded': 'Byl přidán dokument k připomínce.',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved': 'Byl odebrán dokument od připomínky.',
  'MessageCenterPage.message.category.cpStarted': 'Připomínkové řízení začalo.',
  'MessageCenterPage.message.category.cpClosed': 'Připomínkové řízení bylo ukončeno.',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded': 'Byla přidána příloha k připomínce.',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved': 'Byla přidána odebrána z připomínky.',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified': 'Uživatel v komentáři připomínky byl upozorněn.',
  'MessageCenterPage.message.category.cpDeadlineWarning': 'Termín v připomínkovém řízení brzy vyprší.',
  'MessageCenterPage.message.category.cpDeadlineExpired': 'Termín v připomínkovém řízení vypršel.',
  'MessageCenterPage.message.category.cpDeadlineChanged': 'Termín v připomínkovém řízení byl změněn.',
  'MessageCenterPage.message.category.appFileShare': 'Byl nasdílen soubor/složka.',
  'MessageCenterPage.message.category.alertSign': 'Upozornění podepisování dokumentu.',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand': 'Uživatelské upozornění - workflow',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand.filter': 'Uživatelské upozornění - workflow',
  'MessageCenterPage.message.category.demAssignmentResolveDemand': 'Uživatelské upozornění - úkol',
  'MessageCenterPage.message.category.demAssignmentResolveDemand.filter': 'Uživatelské upozornění - úkol',
  'MessageCenterPage.message.category.assignmentStarted.filter': 'Vytvoření úkolu',
  'MessageCenterPage.message.category.wfNotification.filter': 'Zprávy z notifikačního uzlu',
  'MessageCenterPage.message.category.assignmentStateChanged.filter': 'Změna stavu úkolu',
  'MessageCenterPage.message.category.assignmentUserAdded.filter': 'Přidání uživatele do úkolu',
  'MessageCenterPage.message.category.assignmentUserRemoved.filter': 'Odebrání uživatele z úkolu',
  'MessageCenterPage.message.category.assignmentDocumentsChanged.filter': 'Změna dokumentu v úkolu',
  'MessageCenterPage.message.category.assignmentUsersChanged.filter': 'Změna v uživatelích úkolu',
  'MessageCenterPage.message.category.assignmentChanged.filter': 'Zadání úkolu upraveno',
  'MessageCenterPage.message.category.assignmentNoteAdded.filter': 'Komentář přidán k úkolu',
  'MessageCenterPage.message.category.assignmentNoteUserNotified.filter': 'Uživatel v komentáři úkolu upozorněn',
  'MessageCenterPage.message.category.wfNodeStarted.filter': 'Přiřazena úloha workflow',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded.filter': 'Termín v úloze workflow brzy vyprší.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded.filter': 'Workflow úloha po termínu',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded.filter':
    'Nahrání revize u schvalovaného dokumentu',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded.filter': 'Nahrání schvalovaného dokumentu',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved.filter': 'Odebrání schvalovaného dokumentu',
  'MessageCenterPage.message.category.wfWorkflowFinished.filter': 'Workflow je ukončeno',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded.filter':
    'Požadavek na dokument workflow vypršel',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded.filter':
    'Požadavek na dokument workflow brzy vyprší',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted.filter':
    'Požadavek na přiložení dokumentu ve workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded.filter': 'Podkladový dokument workflow přiložen',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved.filter':
    'Požadavek na dokument Workflow zrušen',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged.filter':
    'Požadavek na dokument Workflow změněn',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted.filter': 'Podkladový dokument workflow schválen',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused.filter': 'Podkladový dokument workflow odmítnut',
  'MessageCenterPage.message.category.wfNodeFinished.filter': 'Úloha workflow ukončena',
  'MessageCenterPage.message.category.wfWorkflowClosed.filter': 'Workflow bylo uzavřeno',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted.filter': 'Schvalované dokumenty workflow schváleny',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused.filter': 'Schvalované dokumenty workflow zamítnuty',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded.filter': 'Komentář přidán k úloze workflow',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified.filter':
    'Uživatel v komentáři workflow úlohy upozorněn',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded.filter': 'Příloha přidána k workflow úloze',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved.filter': 'Příloha vyřazena z workflow úlohy',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored.filter': 'Příloha obnovena ve workflow úloze',
  'MessageCenterPage.message.category.cpCommentAdded.filter': 'Připomínka byla přidána',
  'MessageCenterPage.message.category.cpDocumentAdded.filter': 'Dokument přidán k připomínce',
  'MessageCenterPage.message.category.cpDocumentRemoved.filter': 'Dokument odebrán z připomínky',
  'MessageCenterPage.message.category.cpStateChanged.filter': 'Stav připomínkového řízení změněn',
  'MessageCenterPage.message.category.cpCommentStateChangded.filter': 'Stav připomínky změněn',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged.filter': 'Změny seznamu týmů připomínkujících na připomínce',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged.filter': 'Změny seznamu týmů zpracovatelů na připomínce',
  'MessageCenterPage.message.category.cpCommentNoteAdded.filter': 'Komentář přidán k připomínce',
  'MessageCenterPage.message.category.cpCommentTextChanged.filter': 'Text připomínky změněn',
  'MessageCenterPage.message.category.cpUserAdded.filter': 'Uživatel přidán do připomínkového řízení',
  'MessageCenterPage.message.category.cpUserRemoved.filter': 'Uživatel odebrán z připomínkového řízení',
  'MessageCenterPage.message.category.cpCommentDocumentAdded.filter': 'Dokument přidán k připomínce',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved.filter': 'Dokument odebrán z připomínky',
  'MessageCenterPage.message.category.cpStarted.filter': 'Připomínkové řízení spuštěno',
  'MessageCenterPage.message.category.cpClosed.filter': 'Připomínkové řízení ukončeno',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded.filter': 'Příloha přidána k připomínce',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved.filter': 'Příloha odebrána z připomínky',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified.filter': 'Uživatel v komentáři připomínky upozorněn',
  'MessageCenterPage.message.category.cpDeadlineWarning.filter': 'Termín v připomínkovém řízení brzy vyprší.',
  'MessageCenterPage.message.category.cpDeadlineExpired.filter': 'Termín v připomínkovém řízení vypršel',
  'MessageCenterPage.message.category.cpDeadlineChanged.filter': 'Termín v připomínkovém řízení byl změněn',
  'MessageCenterPage.message.category.appFileShare.filter': 'Sdílení souboru/složky',
  'MessageCenterPage.message.category.alertSign.filter': 'Upozornění podepisování dokumentu',
  'MessageCenterPage.message.category.bulkActionReportStateChanged': 'Hromadná změna stavů dokumentů',
  'MessageCenterPage.message.category.bulkActionReportStateChanged.filter': 'Hromadná změna stavů dokumentů',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged': 'Hromadná změna štítků dokumentů',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged.filter': 'Hromadná změna štítků dokumentů',
  'MessageCenterPage.message.category.documentSubscriber': 'Změna u sledovaného dokumentu',
  'MessageCenterPage.message.category.documentSubscriber.filter': 'Změna u sledovaného dokumentu',
  'MessageCenterPage.message.category.directorySubscriber.filter': 'Změna u sledované složky',
  'MessageCenterPage.message.category.directorySubscriber': 'Změna u sledované složky',
  'MessageCenterPage.message.category.bulkActionReportNotification': 'Souhrnná zpráva hromadné změny',
  'MessageCenterPage.message.category.bulkActionReportNotification.filter': 'Souhrnná zpráva hromadné změny',
  'MessageCenterPage.message.category.projectAccountStateChange': 'Změna stavu účtu na projektu',
  'MessageCenterPage.message.category.projectAccountStateChange.filter': 'Pozvání do projektu, aktivace a deaktivace účtu',
  'MessageCenterPage.message.category.docReleaseReservationAlert': 'Termín rezervace brzy vyprší',
  'MessageCenterPage.message.category.docReservationReleased': 'Termín rezervace vypršel',
  'MessageCenterPage.message.category.docReleaseReservationAlert.filter': 'Termín rezervace brzy vyprší',
  'MessageCenterPage.message.category.docReservationReleased.filter': 'Termín rezervace vypršel',
  'MessageCenterPage.message.expireTime': 'Termín: ',
  'MessageCenterPage.message.detail.assignment.title': 'Upozornění úkolu',
  'MessageCenterPage.message.detail.bulkChanges.title': 'Upozornění hromadné změny dokumentů',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentStatesChanged': 'Změna stavů dokumentů',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsAdded': 'Přidání šítků na dokumentech',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsReplaced': 'Nahrazení štítků na dokumentech',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsRemoved': 'Odebrání šítků na dokumentech',
  'MessageCenterPage.message.detail.bulkChanges.newLabels': 'Nové štítky',
  'MessageCenterPage.message.detail.bulkChanges.newState': 'Nový stav dokumentů',
  'MessageCenterPage.message.detail.bulkChanges.changedDocuments': 'Změněné dokumenty',
  'MessageCenterPage.message.detail.bulkChanges.unchangedDocuments': 'Nezměněné dokumenty',
  'MessageCenterPage.message.detail.bulkChanges.ignoredDocuments': 'Ignorované dokumenty',
  'MessageCenterPage.message.detail.bulkChanges.originalState': 'Původní stav:',
  'MessageCenterPage.message.detail.commentProcedure.title': 'Upozornění připomínkového řízení',
  'MessageCenterPage.message.detail.document.title': 'Upozornění na událost související s dokumentem',
  'MessageCenterPage.message.detail.userNotification.title': 'Uživatelské upozornění',
  'MessageCenterPage.message.detail.userNotification.type.workflow': 'Zpráva z úlohy schvalovacího procesu workflow',
  'MessageCenterPage.message.detail.userNotification.type.task': 'Název úkolu',
  'MessageCenterPage.message.detail.subscriber.documentTitle': 'Upozornění sledovaného dokumentu',
  'MessageCenterPage.message.detail.subscriber.directoryTitle': 'Upozornění sledované složky',
  'MessageCenterPage.message.detail.workflow.title': 'Upozornění schvalovacího procesu Workflow',
  'MessageCenterPage.message.detail.projectAccount.title': 'Pozvání do projektu, aktivace a deaktivace účtu',
  'MessageCenterPage.message.detail.projectAccount.userAddedToOrganization': 'Uživatel {user} vás pozval do organizace {organization}.',
  'MessageCenterPage.message.detail.projectAccount.userAddedToProject': 'Uživatel {user} vás pozval do projektu {project}.',
  'MessageCenterPage.message.detail.projectAccount.userActivated': 'Uživatel {user} aktivoval váš účet na projektu {project}.',
  'MessageCenterPage.message.detail.projectAccount.userDeactivated': 'Uživatel {user} deaktivoval váš účet na projektu {project}.',
  'MessageCenterPage.message.detail.error.viewer': 'Tento typ zprávy není podporován',
  'MessageCenterPage.message.detail.grid.assignment': 'Úkol',
  'MessageCenterPage.message.detail.grid.assignmentContent': 'Obsah úkolu',
  'MessageCenterPage.message.detail.grid.author': 'Autor',
  'MessageCenterPage.message.detail.grid.completeTime': 'Datum dokončení',
  'MessageCenterPage.message.detail.grid.deadlineTime': 'Požadovaný termín splnění',
  'MessageCenterPage.message.detail.grid.document': `{count, plural,
    one {Dokument}
    other {Dokumenty}
  }`,
  'MessageCenterPage.message.detail.grid.organization': 'Organizace',
  'MessageCenterPage.message.detail.grid.project': 'Projekt',
  'MessageCenterPage.message.detail.grid.solvers': 'Řešitelé',
  'MessageCenterPage.message.detail.grid.workflow': 'Workflow',
  'MessageCenterPage.message.detail.grid.commentProcedure': 'Připomínkové řízeni',
  'MessageCenterPage.message.detail.grid.commentName': 'Komentář',
  'MessageCenterPage.message.detail.grid.team': 'Tým',
  'MessageCenterPage.message.detail.grid.user': 'Uživatel',
  'MessageCenterPage.message.detail.grid.task': 'Úloha',
  'MessageCenterPage.message.detail.grid.expireTime': 'Požadovaný termín',
  'MessageCenterPage.message.detail.grid.backgroundDocument': 'Podkladový dokument',
  'MessageCenterPage.message.detail.grid.message': 'Zpráva',
  'MessageCenterPage.message.detail.grid.subject': 'Předmět zprávy',
  'MessageCenterPage.message.detail.grid.startNodeMessages': 'Vyjádření z úlohy',
  'MessageCenterPage.message.detail.grid.oldState': 'Původní stav',
  'MessageCenterPage.message.detail.grid.newState': 'Nový stav',
  'MessageCenterPage.message.detail.grid.currentState': 'Aktuální stav',
  'MessageCenterPage.message.detail.grid.noteText': 'Text komentáře',
  'MessageCenterPage.message.detail.grid.shareFolder': 'Sdílena složka',
  'MessageCenterPage.message.detail.grid.shareFiles': 'Sdílené soubory',
  'MessageCenterPage.message.detail.grid.receiver': 'Příjemce',
  'MessageCenterPage.message.detail.grid.receiverMessage': 'Zpráva',
  'MessageCenterPage.message.detail.grid.removedDocument': `{count, plural,
    one {Odebraný dokument}
    other {Odebrané dokumenty}
  }`,
  'MessageCenterPage.message.detail.grid.directoryName': 'Sdílená složka',
  'MessageCenterPage.message.detail.grid.documentNames': 'Sdílené dokumenty',
  'MessageCenterPage.message.detail.grid.shareMessageFiles':
    'Uživatel {user} Vám nasdílel odkaz na dokumenty ({documentCount}) v rámci projektu {projectName}',
  'MessageCenterPage.message.detail.grid.shareMessageFolder':
    'Uživatel {user} Vám nasdílel odkaz na složku v rámci projektu {projectName}',
  'MessageCenterPage.message.detail.grid.documentPath': 'Cesta k dokumentu',
  'MessageCenterPage.message.detail.grid.expirationReservation': 'Čas vypršení maximální doby rezervace',
  'MessageCenterPage.message.detail.grid.taskDeadline': 'Termín úlohy',
  'MessageCenterPage.message.detail.grid.taskDeadline.task': 'Termín úkolu',
  'MessageCenterPage.message.detail.grid.useNotifiEntry': "Uživatel '{user}' vám odeslal následující zprávu:",
  'MessageCenterPage.message.status.new': 'Nepřečteno',
  'MessageCenterPage.message.status.read': 'Přečteno',
  'MessageCenterPage.message.status.solved': 'Vyřešeno',
  'MessageCenterPage.message.status.finished': 'Ukončeno',
  'MessageCenterPage.message.status.ignored': 'Ztlumeno',
  'MessageCenterPage.notification.messages': 'Zprávy',
  'MessageCenterPage.notification.tasks': 'Úkoly',
  'MessageCenterPage.notification.settings': 'Nastavení upozornění',
  'MessageCenterPage.settings.menu.default': 'Uživatelské výchozí nastavení',
  'MessageCenterPage.settings.menu.default.tooltip':
    'Toto nastavení je použito pro projekty, které nemají nastavené volby zasílání zpráv samostatně.',
  'MessageCenterPage.settings.menu.project': 'Nastavení pro projekt',
  'MessageCenterPage.settings.menu.project.tooltip':
    'Toto nastavení přizpůsobuje odesílání zpráv na vybraném projektu. Vybrané volby mají přednost před výchozím nastavením.',
  'MessageCenterPage.settings.option.header.email': 'Email',
  'MessageCenterPage.settings.option.header.report': 'Souhrnná zpráva',
  'MessageCenterPage.settings.option.header.mute': 'Ztlumit',
  'MessageCenterPage.settings.option.header.restore': 'Obnovit výchozí',
  'MessageCenterPage.settings.option.header.email.tooltip': 'Okamžité zaslání notifikace na email.',
  'MessageCenterPage.settings.option.header.report.tooltip':
    'Zaslání denní zprávy o všech notifikacích v jednom emailu.',
  'MessageCenterPage.settings.option.header.mute.tooltip': 'Vybrané notifikace automaticky označí jako přečtené.',
  'MessageCenterPage.settings.option.header.restore.tooltip': 'Použije uživatelské výchozí nastavení pro danou akci.',
  'MessageCenterPage.settings.unmappedCategory.title': 'Ostatní',
  'MessageCenterPage.settings.useDefaultSetting.button': 'Použít výchozí',
  'MessageCenterPage.settings.usingDefaultSetting.state': 'Používá výchozí',
  'MessageGrid.changestateButton.markAsRead':'Označit jako přečtené',
  'MessageGrid.changestateButton.markAsUnread':'Označit jako nepřečtené',
  'MessageGrid.changestateButton.markAllAsRead':'Označit vše jako přečtené',
  'modalFormConfirmClose.content': 'Je možné, že provedené změny nebudou uloženy.',
  'modalFormConfirmClose.title': 'Opravdu si přejete odejít?',
  'ModelDetailPageLinks.docToDocLinks': 'Vazby na model',
  'ModelDetailPageLinks.documentList.button.addSelection.tooltip': 'Vybrat elementy dokumentu',
  'ModelDetailPageLinks.bindings.elementsHeader': `{count, plural,
    one {Dokumenty vybraného elementu}
    other {Dokumenty # vybraných elementů}
  }`,
  'ModelDetailPageLinks.bindings.noBindingsOnElements': 'Vybrané elementy nemají žádné vazby',
  'ModelDetailPageLinks.bindings.return.tooltip': 'Zpět na seznam dokumentů',
  'ModelDetailPageLinks.bindings.missingElements': `{count, plural,
    =0 {Všechny elementy načteny}
    one {1 element nebyl nenalezen v aktuálním modelu}
    few {# elementy nebyly nenalezeny v aktuálním modelu}
    other {# elementů nebylo nenalezeno v aktuálním modelu}
  }`,
  'ModelDetailPage.goToModel': 'Přejít na model',
  'ModelDetailPage.linksClose': 'Skrýt vazby',
  'ModelDetailPage.linksOpen': 'Zobrazit vazby',
  'ModelDetailPage.urlParamParseFail': 'Nepodařil se načíst seznam modelů z URL parametru.',
  'ModelDetailPage.viewByForgeError.message': 'Model nelze zobrazit.',
  'ModelDetailPage.viewByForgeError.description': 'Tento dokument nelze zobrazit jako 3D model.',
  'ModelDetailPage.viewByForgeError.descriptionMultiple': 'Následující dokumenty nelze zobrazit jako 3D model:',
  'ModelDetailPage.viewByForgeError.pleaseWait':
    'Pokud jste model teprve teď nahráli, tak se nejspíš soubor ještě připravuje. Zkuste to prosím znovu později.',
  'ModelDetailPage.toolbar.addLinks': 'Přidat vazby',
  'ModelDetailPage.toolbar.createRevision': 'Nová revize',
  'ModelDetailPage.toolbar.linkElementToDocument': 'Provázat na dokument',
  'ModelDetailPage.toolbar.diff': 'Porovnat revize',
  'ModelDetailPage.toolbar.removeModel': 'Odebrat model',
  'ModelDetailPage.toolbar.removeModelDiscard': 'Odebrat model a vyřadit',
  'ModelDetailPage.toolbar.removeModelOnly': 'Pouze odebrat model',
  'ModelDetailPage.bindings.removeLink.tooltip': 'Odebrat vazbu modelu na dokument',
  'ModelDetailPage.removeSelectedBindings': 'Odebrat vazby na dokument',
  'ModelDetailPage.removeSelectedBindings.tooltip': 'Odebrat vybrané vazby na dokument',
  'ModelDetailPage.removeSelectedBindings.confirm': 'Opravdu si přejete odebrat vazby na dokument?',
  'ModelDetailPage.invalidElementName': 'Neplatný název elementu',
  'ModelPage.groupedModelPageTitle': 'Zobrazení {count} sdružených modelů',
  'ModelPage.loading': 'Načítám modely...',
  'ModelPage.newModelModalTitle': 'Nový model',
  'ModelPage.showModel': 'Zobrazit model',
  'ModelPage.showGroupedModel': 'Zobrazit sdružený model',
  'ModelPage.removeFromModels': 'Odebrat z modelů',
  'ModelPage.toolbar.upload': 'Nahrát',
  'ModelsMoveFormModal.destinationDirectoryId.label': 'Do složky',
  'ModelsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Vše v pořádku}
    one {Následující model se stejným názvem již v cílové složce existuje}
    few {Následující modely se stejnými názvy již v cílové složce existují}
    other {Následující modely se stejnými názvy již v cílové složce existují}
  }`,
  'ModelsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {Vše v pořádku}
    one {Při přesunu došlo k chybě. Žádné modely nebyly přesunuty.}
    few {Při přesunu došlo k chybám. Žádné modely nebyly přesunuty.}
    other {Při přesunu došlo k chybám. Žádné modely nebyly přesunuty.}
  }`,
  'ModelsMoveFormModal.moveModels': `{count, plural,
    =0 {Přesunout 0 vybraných modelů ze složky}
    one {Přesunout 1 vybraný model ze složky}
    few {Přesunout # vybrané modely ze složky}
    other {Přesunout # vybraných modelů ze složky}
  }`,
  'ModelProcessingExportFormModal.title': 'Export požadavků na zpracování modelů za zvolené období',
  'ModelProcessingExportFormModal.periodSelection': 'Volba období',
  'ModelProcessingExportFormModal.buttonTooltip': 'Export za období',
  'ModelBindingAddModal.form.documentSelect': 'Dokumenty pro vytvoření vazby',
  'ModelBindingAddModal.form.documentSelect.modalTitle': 'Výběr dokumentů pro vytvoření vazby',
  'ModelBindingAddModal.form.elements': 'Vybrané elementy modelu',
  'ModelBindingAddModal.form.title': 'Vytvoření vazeb na elementy',
  'ModelBindingAddModal.title': 'Provázat model na dokumenty',
  'ModelBindingAddModal.addBinding.success': 'Vazby na dokumenty úspěšně přidány',
  'ModelBindingAddModal.addBinding.noDocuments': 'Nebyly vybrány žádné dokumenty',
  'ModelBindingList.warning.missingElement': 'Element pro tuto vazbu nebyl v modelu nalezen',
  'ModelBindingList.documentList.header': 'Vazby na dokumenty',
  'ModelBindingRemoveModal.title': 'Odebrat vazby na dokument',
  'ModelBindingRemoveModal.submit': 'Odebrat',
  'ModelBindingRemoveModal.form.documentSelect': 'Výběr dokumentů pro odebrání',
  'MultipleRolesListSelect.addManager': 'Přidat roli',
  'MultipleRolesListSelect.removeManager': 'Odebrat roli',
  'MultipleRolesListSelect.noAvailableRole': 'Žádná role není k dispozici',
  'NoApplicableNewDocumentStateAlert.title': 'Nelze nahrát nový dokument',
  'NoApplicableNewDocumentStateAlert.description': 'Ve vašem uživatelském profilu na tomto projektu nemáte nastaven žádný použitelný povolený stav nahrávaného dokumentu. Kontaktujte, prosím, administrátora projektu.',
  'NodePortActionTypeEnum.accept': 'Schválit',
  'NodePortActionTypeEnum.accepted': 'Schváleno',
  'NodePortActionTypeEnum.refuse': 'Zamítnout',
  'NodePortActionTypeEnum.refused': 'Zamítnuto',
  'NodePortActionTypeEnum.process': 'Zpracovat',
  'NodePortActionTypeEnum.processed': 'Zpracováno',
  'NodeInPortCommonTitle': 'Vstup',
  'NotificationContext.updateMessage.messages': `{count, plural,
    one {# nová zpráva}
    few {# nové zprávy}
    other {# nových zpráv}
  }`,
  'NotificationContext.updateMessage.newAssignments': `{count, plural,
    one {# nový úkol}
    few {# nové úkoly}
    other {# nových úkolů}
  }`,
  'NotificationContext.updateMessage.updatedAssignments': `{count, plural,
    one {# úkol aktualizován}
    few {# úkoly aktualizovány}
    other {# úkolů aktualizováno}
  }`,
  'NotificationContext.updateMessage.notification': `{count, plural,
    one {{message}}
    other {{message} a {lastMessage}}
  }`,
  'notifications.downloadError.description': 'Soubor se nepodařilo stáhnout.',
  'notifications.error.message': 'Ups! Něco se pokazilo 💥',
  'notifications.reserveError.reserve.description': 'Dokument se nepodařilo rezervovat k úpravám.',
  'notifications.reserveError.release.description': 'Rezervaci dokumentu se nepodařilo zrušit.',
  'notifications.savingError.description': 'Během ukládání dat došlo k potížím, zkuste to prosím znovu.',
  'NoticeList.daysLeft': 'Zbývá dnů',
  'NoticeList.deadline': 'Termín: ',
  'NoticeList.project': 'Projekt: ',
  'OrgLabelsList.deleteConfirmModal.description': `{projectCount, plural,
    one {Opravdu chcete smazat štítek {label} a odebrat jej z {projectCount} projektu?}
    other {Opravdu chcete smazat štítek {label} a odebrat jej z {projectCount} projektů?}
  }`,
  'OrganizationSettings.title': 'Nastavení organizace',
  'OrganizationSettingsFormModal.title': 'Nastavení pro {settingsName}',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.name': 'Report stavby v realizaci',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.description': 'Nastavení podbarvení a výchozího data',
  'OrganizationUserPermissionProfiles.title': 'Profily oprávnění uživatelů',
  'OrganizationUserPermissionProfiles.modal.add.title': 'Přidat profil oprávnění',
  'OrganizationUserPermissionProfiles.modal.edit.title': 'Upravit profil oprávnění',
  'OrganizationUsersEditFormModal.title': 'Editovat uživatele v organizaci',
  'OrganizationUserEditForm.nameSource': 'Zdroj jména',
  'OrganizationUserEditForm.setByOrganization': 'Nastavit organizací',
  'OrganizationUserEditForm.takeByUserSetting': 'Převzít z nastavení uživatele',
  'OrganizationUserEditForm.firstNameLastName': 'Jméno a příjmení',
  'OrganizationUserEditForm.namesDisplayValue': 'Zobrazovaná hodnota',
  'OrganizationUserEditForm.namesNotSetValue': 'Hodnota jména a příjmení není nastavena',
  'Panel.addCategory.tooltip': 'Přidat kategorii',
  'Panel.addCategoryNode.tooltip': 'Přidat uzel',
  'Panel.addGroup.tooltip': 'Přidat skupinu',
  'Panel.addGroupUser.tooltip': 'Přidat uživatele',
  'Panel.AddLabel.tooltip': 'Přidat štítek',
  'Panel.AddRole.tooltip': 'Přidat roli',
  'Panel.addUser.tooltip': 'Přidat uživatele',
  'Panel.addUserToTeam.tooltip': 'Přidání uživatele do týmu',
  'RolePanel.Modal.CannotRemove.title': 'Roli nelze smazat',
  'Popconfirm.deleteItem': 'Opravdu chcete položku odstranit?',
  'PrimaryFileInput.collapse.hint': 'Klikněte nebo přetáhněte soubor sem',
  'PrimaryFileInput.collapse.text': 'Vyberte primární soubor',
  'CommonFilesInput.remove': 'Odebrat ze seznamu',
  'CommonFilesInput.hint': 'Klikněte nebo přetáhněte soubory a složky do této oblasti.',
  'CommonFilesInput.statistics.withErrors.tooltip': 'Složky a soubory s chybami:',
  'CommonFilesInput.statistics.duplicateDocuments.tooltip': 'Duplicitní soubory:',
  'CommonFilesInput.statistics.duplicateFolders.tooltip': 'Duplicitní složky:',
  'CommonFilesInput.statistics.newRevisions.tooltip': 'Nové revize:',
  'CommonFilesInput.statistics.invalidRevisions.tooltip': 'Revize nejde vytvořit:',
  'CommonFilesInput.button.remove': 'Odebrat vše ze seznamu',
  'CommonFilesInput.button.include': 'Zahrnout vše do výběru',
  'CommonFilesInput.button.exclude': 'Vyloučit vše z výběru',
  'CommonFilesInput.button.excludeInvalidNames': 'Vyloučit vše s chybným názvem',
  'CommonFilesInput.button.excludeWithoutAccess': 'Vyloučit vše bez oprávnění na zápis',
  'CommonFilesInput.button.removeInvalidNames': 'Odebrat vše s chybným názvem',
  'CommonFilesInput.button.removeWithoutAccess': 'Odebrat vše bez oprávnění na zápis',
  'CommonFilesInput.button.uploadAllAsNewDocuments': 'Nahrát vše jako nové dokumenty',
  'CommonFilesInput.button.uploadAllAsRevisions': 'Nahrát vše jako revize',
  'CommonFilesInput.button.uploadAsNewDocument': 'Nahrát jako nový dokument',
  'CommonFilesInput.button.uploadAsRevision': 'Nahrát jako revizi',
  'CommonFilesInput.duplicateFolders.buttonLabel': 'Odebrat duplicitní složky.',
  'CommonFilesInput.duplicateFolders.text': 'Některé složky již existují a budou automaticky sloučeny.',
  'CommonFilesInput.duplicateFolders.tooltip': 'Složka s tímto názvem již existuje.',
  'CommonFilesInput.duplicateDocuments.buttonLabel': 'Odebrat duplicitní soubory.',
  'CommonFilesInput.duplicateDocuments.text': 'Již existují dokumenty s názvy některých nahrávaných sobrourů. Soubory se nahrají jako nové dokumenty a budou automaticky přejmenovány.',
  'CommonFilesInput.duplicateDocuments.tooltip': 'Dokument s tímto názvem již existuje.',
  'CommonFilesInput.revision.tooltip': 'Dokument bude nahrán jako revize.',
  'CommonFilesInput.notWritableDocument.tooltip': 'Nemáte právo pro vytvoření tohoto souboru.',
  'CommonFilesInput.notWritableDirectory.tooltip': 'Nemáte právo pro vytvoření této složky.',
  'CommonFilesInput.invalidDirectoryName.tooltip': 'Složka má neplatný název.',
  'CommonFilesInput.invalidDocumentName.tooltip': 'Soubor má neplatný název.',
  'CommonFilesInput.notWritable.buttonLabel': 'Odebrat nezapsatelné soubory a složky.',
  'CommonFilesInput.notWritable.text':
    'Pro vytvoření některých složek a souborů nemáte potřebná oprávnění. Změňte oprávnění v jejich nadřazené složce, nebo je odeberte ze seznamu pro nahrávání.',
  'CommonFilesInput.invalidName.text':
    'Některé složky nebo soubory nemají platný název. Změňte jejich název a nahrajte je znovu nebo je odeberte ze seznamu pro nahrávání.',
  'CommonFilesInput.invalidName.buttonLabel': 'Odebrat složky a soubory s neplatným názvem.',
  'CommonFilesInput.blockedRevisions.text':
    'Některé revize není možné vytvořit. Změňte nahrávání na nový dokument nebo je odeberte ze seznamu.',
  'CommonFilesInput.blockedRevisions.buttonLabel': 'Odebrat soubory s blokovanou revizí',
  'CommonFilesInput.checkLoading.text': 'Kontrola souborů a složek.',
  'CommonFilesInput.treeLoading.text': 'Načítání souborů a složek.',
  'CommonFilesInput.treeLoading.error':
    'Složkovou strukturu se nepodařilo načíst celou. Protože cesta k souborům na vašem zařízení je příliš dlouhá, některé soubory nebo složky se nepodařilo načíst.',
  'CommonFilesInput.treeLoading.error.help':
    'Pro nahrání souborů a složek zkuste na vašem počítači přesunout soubory do jiné složky, přejmenovat složky a soubory s dlouhými názvy (použít kratší názvy), nebo zkuste nahrát soubory samostatně.',
  'CommonFilesInput.treeLoading.itemErrorInfo':
    'Obsah této složky se nepodařilo načít celý, protože cesta k souborům na vašem zařízení je příliš dlouhá. Některé soubory a složky chybí.',
  'CommonFilesInput.treeLoading.fileErrorInfo':
    'Soubor ({name}) se nepodařilo načíst, protože cesta na vašem zařízení je příliš dlouhá.',
  'CommonFilesInput.treeLoading.folderErrorInfo':
    'Obsah složky se nepodařilo načíst, protože cesta na vašem zařízení je příliš dlouhá.',
  'CommonFilesInput.treeLoading.error.listTitle': 'Chybějící soubory a složky',
  'CommonFilesInput.treeLoading.firefoxWarning':
    'Firefox vás neupozorní, pokud jsou cesty k souborům nebo složkám příliš dlouhé a některé chybí. Zkontrolujte prosím počty souborů a složek přidaných k nahrání.',
  'CommonFilesInput.treeLoading.pathTooLongWarning':
    'Složkovou strukturu se možná nepodařilo načíst celou. Některá cesta k souboru může být příliš dlouhá a soubor nebyl detekován. Zkontrolujte počet detekovaných a nahrávaných souborů, respektive složek a případně zkraťte jejich názvy.',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsRevisions': 'Nahrát duplicitní soubory jako revize',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsNew': 'Nahrát duplicitní soubory jako nové',
  'CommonFilesInput.treeItemMenu.includeDuplicateFolders': 'Zahrnout duplicitní složky',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFolders': 'Vyloučit duplicitní složky',
  'CommonFilesInput.treeItemMenu.includeDuplicateFiles': 'Zahrnout duplicitní soubory',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFiles': 'Vyloučit duplicitní soubory',
  'CommonFilesInput.treeItemMenu.excludeAllNotWritable': 'Vyloučit vše bez oprávnění na zápis',
  'CommonFilesInput.treeItemMenu.excludeAllInvalid': 'Vyloučit vše nevalidní',
  'SingleFileInput.collapse.hint': 'Klikněte nebo přetáhněte soubor sem',
  'SingleFileInput.collapse.text': 'Vyberte soubor',
  'paramEncode.error.urlParse': 'Nelze rozkódovat URL parametr.',
  'paramEncode.error.jsonParse': 'Nezdařil se převod na JSON objekt.',
  'paramEncode.error.checkFormat': 'Zpracovaná hodnota neodpovídá požadovanému formátu.',
  'OrganizationAddForm.title': 'Název',
  'OrganizationAddForm.title.placeholder': 'Název organizace...',
  'OrganizationAddForm.description.placeholder': 'Popis organizace...',
  'OrganizationAddForm.description': 'Popis',
  'OrganizationAddForm.language': 'Jazyk',
  'OrganizationAddForm.language.tooltip': 'Hlavní jazyk organizace. V jakém jazyce je očekávána většina dokumentů.',
  'OrganizationAddForm.timeZone': 'Časová zóna',
  'OrganizationAddForm.addUsersAlsoWithInvitation': 'Administrátor projektu smí přidat uživatele z AspeHub, i pozváním mailem',
  'OrganizationAddForm.required': 'Je nezbytné vybrat alespoň jednoho uživatele jako admina',
  'OrganizationList.organizationByUserTitle': 'Organizace, do kterých je zvolený uživatel zařazen',
  'OrganizationGeneralTab.title': 'Přidávání uživatelů do projektu e-mailovými pozvánkami (Administrátory projektu)',
  'OrganizationGeneralTab.description': 'Pokud je volba povolena, administrátoři projektu smí přidat i nového uživatele e-mailovou pozvánkou. Přidají tím nového uživatele do AspeHub. \nPokud je volba zakázána, do projektu lze přidat jen již existujícího uživatele v organizaci.',
  'OrganizationDetail.forgeText': 'Počet odeslaných požadavků: {count}',
  'OrganizationDetail.forgeTooltip': 'Počet odeslaných požadavků na zpracování modelů za organizaci',
  'OrganizationUsersEditModal.title': 'Změna nastavení uživatelů na organizaci',
  'OrganizationUserSettingOnProjectsModal.title': 'Změna nastavení uživatele na projektech',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.Title': 'Akce odstranit/deaktivovat se nezdařila',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.projectName': 'Název projektu',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.reason': 'Důvod nezdaru',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.NotFound': 'Uživatel nenalezen',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.IsLastAdmin': 'Uživatel je jediný admin na projektu',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.InvalidOperation': 'Neplatná operace',
  'OrganizationUserSettingOnProjectsModal.usersActiveProjects': 'Projekty přiřazené uživateli',
  'OrganizationUserSettingOnProjectsModal.usersNewProjects': 'Projekty, kde uživatel nefiguruje',
  'OrganizationUserSettingOnProjectsModal.DeleteDeactivateButtonText': 'Odstranit/Deaktivovat',
  'OrganizationUserSettingOnProjectsModal.AddButtonText': 'Přidat do projektu',
  'OrganizationUserSettingPermissionPanel.Title': 'Oprávnění',
  'OrganizationUserSettingPermissionPanel.permissionCategoryType.reports': 'Reporty',
  'OrganizationUserSettingPermissionPanel.permissionType.financialOverviewByUnit': 'Finanční přehled projektů AspeEsticon po útvarech',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverview': 'Přehled staveb v realizaci',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverviewAdmin': 'Správa poznámek přehledu staveb v realizaci',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationShareWidget': 'Správce sdílených widgetů',
  'ProjectCreateForm.form.items.description.placeholder': 'Popis projektu...',
  'ProjectCreateForm.form.items.language.cs': 'Čeština',
  'ProjectCreateForm.form.items.language.en': 'Angličtina',
  'ProjectCreateForm.form.items.language.label': 'Jazyk',
  'ProjectCreateForm.form.items.language.tooltip':
    'Hlavní jazyk projektu. V jakém jazyce je očekávána většina dokumentů.',
  'ProjectCreateForm.form.items.name.label': 'Název',
  'ProjectCreateForm.form.items.name.placeholder': 'D1 Praha - Brno',
  'Project.name.empty': 'Název projektu nesmí být prázdný',
  'ProjectCreateForm.form.items.name.rules.required': 'Prosím zadejte název projektu',
  'ProjectCreateForm.form.tabs.newProject': 'Ze zadání',
  'ProjectCreateForm.form.tabs.fromTemplate': 'Ze šablony',
  'ProjectCreateForm.form.template': 'Šablona',
  'ProjectCreateForm.form.timeZone': 'Časová zóna',
  'ProjectCreateForm.form.timeZone.rules.required': 'Prosím zadejte časovou zónu',
  'ProjectCreateForm.form.template.rules.required': 'Prosím vyberte šablonu projektu',
  'ProjectCreateFormModal.button.create': 'Vytvořit projekt',
  'ProjectCreateFormModal.infoText.common': 'Projekt se nyní vytváří.',
  'ProjectCreateFormModal.infoText.youWillSeeProject': 'Projekt se nyní vytváří. Po jeho vytvoření bude dostupný v modulu projekty.',
  'ProjectUserAddForm.addToGroups': 'Přidat uživatele do skupin',
  'Esticon.enums.phase.Priprava': 'Příprava',
  'Esticon.enums.phase.Nabidka': 'Nabídka',
  'Esticon.enums.phase.Realizace': 'Realizace',
  'Esticon.enums.phase.Dokonceno': 'Dokončeno',
  'Esticon.enums.phase.Neziskano': 'Nezískáno',
  'ProjectDashboard.Reports.notAvailableError': 'Report není dostupný',
  'ProjectDashboard.Reports.removeFromDashboard': 'Odebrat report',
  'ProjectDashboard.Reports.copyReportWidget': 'Vytvořit kopii',
  'ProjectDashboard.Reports.esticonForbidden': 'Nemáte oprávnění pro zobrazení dat stavby',
  'ProjectDashboard.Reports.title.documentOverview': 'Přehled dokumentů',
  'ProjectDashboard.Reports.title.financialPlan': 'Finanční plán/skutečnost',
  'ProjectDashboard.Reports.title.projectOverview': 'Přehled projektu',
  'ProjectDashboard.Reports.title.workflowTasks': 'Schvalovací procesy',
  'ProjectDashboard.Reports.title.withdrawOverview': 'Přehled čerpání',
  'ProjectDashboard.Reports.title.drawingActualOverview': 'Finanční přehled stavby',
  'ProjectDashboard.Reports.AddForm.report.label': 'Report',
  'ProjectDashboard.Reports.AddForm.report.rules.required': 'Prosím vyberte report, který chcete přidat',
  'ProjectDashboard.Reports.AddForm.submit': 'Přidat',
  'ProjectDashboard.Reports.AddForm.title': 'Přidat report',
  'ProjectDashboard.Reports.DocumentOverview.author': 'Moje',
  'ProjectDashboard.Reports.DocumentOverview.mineCreated': 'Mnou nahrané',
  'ProjectDashboard.Reports.DocumentOverview.admin': 'Správce',
  'ProjectDashboard.Reports.DocumentOverview.other': 'Ostatní',
  'ProjectDashboard.Reports.ExportDate': 'Platné ke dni: ',
  'ProjectDashboard.Reports.Export.jpg': 'Export do jpg',
  'ProjectDashboard.Reports.Export.png': 'Export do png',
  'ProjectDashboard.Reports.Export.svg': 'Export do svg',
  'ProjectDashboard.Reports.Export.pdf': 'Export do pdf',
  'ProjectDashboard.Reports.Export.xlsx': 'Export do excelu',
  'ProjectDashboard.Reports.FinancialPlan.invoiced': 'Fakturace',
  'ProjectDashboard.Reports.FinancialPlan.plan': 'Plán',
  'ProjectDashboard.Reports.FinancialPlan.remaining': 'Zbývá',
  'ProjectDashboard.Reports.FinancialPlan.outOfPlan': 'z plánu',
  'ProjectDashboard.Reports.FinancialPlan.outOfTotal': 'z celku',
  'ProjectDashboard.Reports.Menu.addReport': 'Přidat report',
  'ProjectDashboard.Reports.Menu.remove': 'Odebrat report',
  'ProjectDashboard.Reports.Menu.moveDown': 'Zvětšit',
  'ProjectDashboard.Reports.Menu.moveUp': 'Zmenšit',
  'ProjectDashboard.Reports.Menu.maximize': 'Maximalizovat',
  'ProjectDashboard.Reports.Menu.minimize': 'Minimalizovat',
  'ProjectDashboard.Reports.Menu.selectFromTo': 'Vybrat od - do',
  'ProjectDashboard.Reports.Menu.showDateModal': 'Vybrat datum od/do',
  'ProjectDashboard.Reports.Menu.showDetail': 'Zobrazit detail',
  'ProjectDashboard.Reports.timePeriod.Months': 'Měsíční zobrazení',
  'ProjectDashboard.Reports.timePeriod.Years': 'Roční zobrazení',
  'ProjectDashboard.Reports.ProjectOverview.numberOfDocuments': 'Počet dokumentů',
  'ProjectDashboard.Reports.ProjectOverview.numberOfUsers': 'Počet uživatelů',
  'ProjectDashboard.Reports.ProjectOverview.loggedInToday': 'Dnes přihlášeni',
  'ProjectDashboard.Reports.ProjectOverview.projectDate': 'Trvání projektu',
  'ProjectDashboard.Reports.ProjectOverview.projectPhase': 'Fáze projektu',
  'ProjectDashboard.Reports.ProjectOverview.recapitulation': 'Rekapitulace',
  'ProjectDashboard.Reports.ProjectOverview.ZBV': 'ZBV',
  'ProjectDashboard.Reports.ProjectOverview.ZBV.Tooltip': 'Změny během výstavby, celkem v {currency} bez DPH',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice': 'Aktuální cena',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice.Tooltip': 'Celková cena v {currency} bez DPH',
  'ProjectDashboard.Reports.ProjectOverview.ZP': 'Čerpáno',
  'ProjectDashboard.Reports.ProjectOverview.ZP.Tooltip': 'Zjišťovací protokoly, čerpáno celkem {currency} bez DPH',
  'ProjectDashboard.Reports.WorkflowTasks.finished': 'Dokončené',
  'ProjectDashboard.Reports.WorkflowTasks.active': 'Aktivní',
  'ProjectSearchPage.detailMode': 'Detailní zobrazení',
  'ProjectSearchPage.foundEntries': `{total, plural,
    =0 {nenalezeny žádné výsledky}
    one {nalezen jeden záznam}
    few {nalezeny # záznamy}
    other {nalezeno # záznamů}
  } za {took} ms, zobrazeno {showing}`,
  'ProjectsInRealisationOverviewReportPage.menu.editViews.tooltip': 'Upravit uložené pohledy',
  'ProjectsInRealisationOverviewReportPage.menu.override.tooltip': 'Přepsat aktuální pohled',
  'ProjectsInRealisationOverviewReportPage.menu.repairNotes.tooltip': 'Opravit nenapárované poznámky',
  'ProjectsInRealisationOverviewReportPage.menu.addWidget.tooltip': 'Vytvořit widget na dashboard ze zobrazeného reportu',
  'ProjectsInRealisationOverviewReportPage.menu.manageSharedWidgets.tooltip': 'Správa sdílených widgetů',
  'ProjectSearchPage.loadMore': 'Načíst dalších {count}',
  'ProjectTemplates.title': 'Šablony projektů',
  'ProjectTemplates.list.createProjectTemplate': 'Vytvořit šablonu',
  'ProjectTemplates.list.editProjectTemplate': 'Upravit šablonu',
  'ProjectTemplates.list.deleteTemplate.confirmation': 'Opravdu smazat šablonu?',
  'ProjectTemplates.createModal.confirm': 'Uložit šablonu',
  'ProjectTemplates.createModal.title': 'Nová šablona projektu',
  'ProjectTemplates.general.permissionTemplates.title': 'Šablony oprávnění',
  'ProjectTemplateBuilder.warning.tooltip.general': 'Povinné položky obecného nastavení v šabloně nejsou nastaveny.',
  'ProjectTemplateBuilder.warning.tooltip.groups.missingAdmin': 'Skupina administrátorů musí obsahovat alespoň jednoho uživatele.',
  'ProjectTemplateBuilder.warning.tooltip.groups.tooMany': 'Počet skupin překročil povolený limit.',
  'ProjectTemplateBuilder.warning.tooltip.users': 'V šabloně projektu nejsou zadáni žádní uživatelé.',
  'ProjectTemplateDirectoriesTab.delete.confirmNotEmpty': 'Vybraná složka není prázdná. Opravdu smazat složku i se všemi podsložkami?',
  'ProjectTemplateDirectoriesTab.move.duplicateNameError': 'V cílovém složce již existuje složka se stejným názvem.',
  'ProjectTemplateDirectoriesTab.noDirsWarning': 'Nebyla vybrána/vložena žádná složka',
  'ProjectTemplateDirectoriesTab.dropzoneText': 'Uložena bude pouze složková struktura',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error': 'Složkovou strukturu se nepodařilo načíst celou. Protože cesta ke složkám na vašem zařízení je příliš dlouhá, některé složky se nepodařilo načíst.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.help': 'Pro nahrání složek zkuste na vašem počítači  přejmenovat složky s dlouhými názvy (použít kratší názvy), nebo zkuste nahrát složky samostatně.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.listTitle': 'Chybějící složky',
  'ProjectTemplateCategoryFormModal.add.title': 'Nová kategorie',
  'ProjectTemplateCategoryFormModal.edit.title': 'Upravit kategorii',
  'ProjectTemplateCategoryNodeFormModal.add.title': 'Nový uzel',
  'ProjectTemplateCategoryNodeFormModal.edit.title': 'Upravit uzel',
  'ProjectTemplateDirectoryFormModal.add.title': 'Nová složka',
  'ProjectTemplateDirectoryFormModal.edit.title': 'Upravit složku',
  'ProjectTemplateDirectoryCategoryForm.category.required': 'Prosím vyberte kategorii',
  'ProjectTemplateDirectoryCategoryForm.categoryNode.required': 'Prosím vyberte uzel',
  'ProjectTemplateDirectoryCategoryForm.node': 'Uzel',
  'ProjectTemplateDirectoryCategoryFormModal.add.title': 'Přidat kategorii',
  'ProjectTemplateDirectoryCategoryFormModal.edit.title': 'Upravit kategorii',
  'ProjectTemplateDirectoryDetail.noCategories': 'Žádná kategorie',
  'ProjectTemplateDirectoryDetail.button.addCategory': 'Přidat kategorii',
  'ProjectTemplateGeneralTab.templateName.title': 'Název šablony',
  'ProjectTemplateGeneralTab.templateDescription.title': 'Popis šablony',
  'ProjectTemplateGroupFormModal.add.title': 'Nová skupina',
  'ProjectTemplateGroupFormModal.edit.title': 'Upravit skupinu',
  'ProjectTemplateLabelFormModal.add.title': 'Nový štítek',
  'ProjectTemplateLabelFormModal.edit.title': 'Upravit štítek',
  'ProjectTemplateRoleFormModal.add.title': 'Nová role',
  'ProjectTemplateRoleFormModal.edit.title': 'Upravit roli',
  'ProjectTemplatePermissionPresetFormModal.title': 'Nový profil',
  'ProjectTemplateRoleTab.warning.emptyRole': 'V roli není nastavený uživatel',
  'ReinviteButton.reinvite': 'Znovu pozvat',
  'ReinviteButton.reinvite.tooltip': 'Znovu zaslat aktivační mail',
  'ReinviteButton.reinvited': 'Již opětovně pozván',
  'Reporting.list.title': 'Reporty',
  'Reporting.reportCategory.name.General': 'Základní reporty',
  'Reporting.reportType.name.financialOverviewByUnit': 'Finanční přehled projektů AspeEsticon po útvarech',
  'Reporting.reportType.name.projectsInRealisationOverview': 'Přehled staveb v realizaci',
  'Reporting.reportType.name.projectsInRealisationOverviewAdmin': 'Admin poznámek v přehled staveb v realizac',
  'Reporting.reportType.name.projectsInRealisationShareWidget': 'Správce sdílených widgetů',
  'SearchPageHelp.searchHelp': 'Nápověda k vyhledávání',
  'SearchPageHelp.basicSearch': 'Vyhledávají se dokumenty obsahující alespoň jedno ze zadaných slov dle jejich kořenů.',
  'SearchPageHelp.wholeWords':
    'Pro vyhledání <strong>sousloví</strong> zadejte výraz do uvozovek.' +
    ' Příklad: <span class="primary">"konstrukční prvek"</span>.',
  'SearchPageHelp.mustHave':
    'Symbol plus (+) před výrazem znamená, že výraz <strong>musí</strong> být obsažen.' +
    ' Příklad: <span class="primary">+"konstrukční prvek"</span>.',
  'SearchPageHelp.mustNotHave':
    'Symbol mínus (-) před výrazem znamená, že výraz <strong>nesmí</strong> být obsažen.' +
    ' Příklad: <span class="primary">-konstrukční</span>.',
  'SearchPageInput.placeholder': 'Zadejte vyhledávací dotaz',
  'SearchPageSettings.searchSettings': 'Nastavení vyhledávání',
  'SearchPageSettings.displayConfig': 'Nastavení zobrazení',
  'SearchPageSettings.filterSettings': 'Nastavení filtrování',
  'SearchPageSettings.showDetail': 'Zobrazit detail',
  'SearchPageSettings.detailSize': 'Velikost náhledu',
  'SearchPageSettings.searchInName': 'Hledat v názvu dokumentu',
  'SearchPageSettings.searchInDescription': 'Hledat v popisu dokumentu',
  'SearchPageSettings.searchInRevisionNote': 'Hledat v komentáři revize',
  'SearchPageSettings.searchInContent': 'Hledat v obsahu dokumentu',
  'SearchPageSettings.searchInMetadata': 'Hledat v metadatech dokumentu',
  'SearchPageSettings.currentRevisionOnly': 'Zobrazit pouze aktuální revize',
  'SearchPageSettings.directoryId': 'Hledat ve složce:',
  'SearchPageSettings.includeSubdirectories': 'Včetně podsložek',
  'SearchResultRow.documentDescription': 'Popis dokumentu',
  'SearchResultRow.revisionDescription': 'Komentář revize',
  'SearchResultRow.metadataKey': 'Klíč metadat',
  'SearchResultRow.metadataValue': 'Hodnota metadat',
  'ProjectSettingsPageGeneral.esticonId.placeholder':
    'Není připojen žádný projekt. Pozor: připojený projekt nelze později změnit!',
  'ProjectSettingsPageGeneral.name': 'Název projektu',
  'ProjectSettingsPageGeneral.description': 'Popis projektu',
  'ProjectSettingsPageGeneral.nameError': 'Název je moc dlouhý. Maximální délka názvu je {max} znaků.',
  'ProjectSettingsPageGeneral.imports': 'Importy',
  'ProjectSettingsPageGeneral.importConnectedERPData': 'Import ERP dat z propojeného projektu',
  'ProjectSettingsPageGeneral.exports': 'Exporty',
  'ProjectSettingsPageGeneral.exportUsersAndProjectSetting': 'Export uživatelů a nastavení projektu',
  'ProjectSettingsPage.ExternApps.addApplication': 'Přidat aplikaci',
  'ProjectSettingsPage.ExternApps.pageTitle': 'Propojené externí aplikace',
  'ProjectSettingsPage.ExternApps.selectAppTitle': 'Vyberte aplikaci',
  'ProjectSettingsPage.ExternApps.Esticon.addButton': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.addTitle': 'Připojit projekt AspeEsticonu',
  'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError': 'Nepodařilo se spojit s databází. Kontaktujte správce AspeHub',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Database': 'Databáze',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Project': 'Projekt',
  'ProjectSettingsPage.ExternApps.Esticon.listItemTitle': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.loadError': 'Nastala chyba při načínání dat z AspeEsticonu',
  'ProjectSettingsPage.ExternApps.Esticon.projectMissing': 'Projekt AspeEsticonu nebyl nalezen',
  'ProjectSettingsPage.ExternApps.Esticon.projectNotSet': 'Projekt AspeEsticonu nebyl nastaven',
  'ProjectSettingsPage.ExternApps.Esticon.firm': 'Databáze firmy',
  'ProjectSettingsPage.ExternApps.Esticon.projectSign': 'Značka projektu',
  'ProjectSettingsPage.ExternApps.Esticon.projectName': 'Název projektu',
  'ProjectSettingsPage.ExternApps.Esticon.projectId': 'ID projektu',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp':
    'Začněte psát < pro zobrazení tagů. Tagy budou nahrazeny ' +
    'hodnotami z importovaného objektu v názvu dokumentu nebo složky.' +
    ' Měsíc a rok se přebírají z data vystavení.' +
    ' Maska objektové cesty bude aplikovaná na každou vytvářenou složku v cestě.',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp.invoices':
    'Začněte psát < pro zobrazení tagů. Tagy budou nahrazeny ' +
    'hodnotami z importovaného objektu v názvu dokumentu nebo složky.' +
    ' Měsíc a rok se přebírají z data fakturačního období.',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Stavba': 'Složka pro import rozpočtu',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Cerpani': 'Složka pro import zjišťovacích protokolů',
  'ProjectSettingsPage.ExternApps.Esticon.folder.CerpaniSdruzeni':
    'Složka pro import sdružených zjišťovacích protokolů',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Fakturace': 'Složka pro import faktur',
  'ProjectSettingsPage.ExternApps.Esticon.folder.FakturaSdruzeni': 'Složka pro import sdružených faktur',
  'ProjectSettingsPage.ExternApps.Esticon.folder.ZBV': 'Složka pro import ZBV',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Harmonogram': 'Složka pro import harmonogramu',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.addButton': 'Stavební deník',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.listItemTitle': 'Stavební deník',
  "ProjectSettingsPage.ExternApps.ConstructionJournal.title": 'Odkaz na stavební deník',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.addButton': 'Odkaz na externí aplikaci',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.listItemTitle': '{name} (Odkaz na externí aplikaci)',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.title': 'Nastavení odkazu externí aplikace',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder': 'Název v navigačním menu',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.placeholder': 'Adresa externí aplikace',
  'ProjectSettingsPage.ExternApps.DocumentView.addButton': 'Pdf dokument',
  'ProjectSettingsPage.ExternApps.DocumentView.listItemTitle': 'Pdf dokument',
  'ProjectSettingsPage.ExternApps.DocumentView.missingPdfDerivate': 'Dokument nemá vygenerovaný PDF derivát pro zobrazení',
  'ProjectSettingsPage.ExternApps.TextPage.addButton': 'Textová stránka',
  'ProjectSettingsPage.ExternApps.TextPage.listItemTitle': 'Textová stránka',
  'ProjectSettingsPage.ExternApps.TextPage.title.placeholder': 'Nadpis stránky',
  'ProjectSettingsPage.Groups.erasedGroupMessage': 'Skupina <strong>{name}</strong> byla smazána.',
  'ProjectStatusPage.projectCreating.projectCreating': 'Vytváření projektu',
  'ProjectStatusPage.projectCreating.projectIsReady': 'Projekt je připraven :-)',
  'ProjectStatusPage.projectCreating.projectError': 'Chyba :-(',
  'ProjectsListPage.addNewReport': 'Přidat nový report',
  'ProjectsListPage.addNewSharedReport': 'Přidat nový sdílený report',
  'ProjectsListPage.addToFavorites': 'Přidat k oblíbeným',
  'ProjectsListPage.createFirstProject': 'Vytvořte váš 1. projekt',
  'ProjectsListPage.createProject': 'Vytvořeno: {date}',
  'ProjectsListPage.motherOrganization': 'Projekt v organizaci: {organizationName}',
  'ProjectsListPage.removeProjects': 'Odebrat projekty',
  'ProjectsListPage.reorderProjects': 'Uspořádat',
  'ProjectsListPage.sortProjectsAZ': 'Seřadit abecedně',
  'ProjectsListPage.sortProjectsDate': 'Seřadit podle data',
  'ProjectsListPage.sortProjectsFav': 'Seřadit podle oblíb.',
  'ProjectsListPage.Filter.toolbar.organization': 'Organizace',
  'ProjectsListPage.projects': 'Oblíbené projekty',
  'ProjectsListPage.projectDrawingProcess': 'Průběh čerpání oblíbených projektů',
  'ProjectsListPage.removeFromFavorites': 'Odebrat z oblíbených',
  'ProjectsListPage.reports': 'Vybrané reporty',
  'ProjectsListPage.taskList': 'Moje úkoly',
  'ProjectsListPage.financialRecapitulation': 'Finanční rekapitulace',
  'ProjectsListPage.zbvGroups': 'ZBV - skupiny dle SGŘ 18/2017',
  'ProjectsListPage.list.empty': 'Nemáte žádné projekty :-(',
  'ProjectsListPage.list.empty.noAdmin': 'Nemáte oprávnění k žádnému projektu :-(',
  'ProjectsListPage.list.empty.noMatch': 'Žádné nalezené projekty :-(',
  'ProjectsListPage.tabs.all': 'Všechny projekty',
  'ProjectsListPage.tabs.favorite': 'Oblíbené',
  'ProjectsListPage.tabs.recent': 'Nedávné',
  'ProjectsListPage.Card.duration': 'Trvání projektu',
  'ProjectsListPage.Card.duration.unknown': 'Neznámé',
  'ProjectsListPage.Card.duration.unset': 'Neurčeno',
  'ProjectsListPage.Card.remove': 'Odebrat projekt z oblíbených',
  'ProjectsListPage.Card.phase': 'Fáze projektu',
  'ProjectsListPage.Card.status.unknown': 'Neznámá',
  'ProjectInvitationAcceptPage.goToProject': 'Přejít do projektu',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitation':
    'Touto pozvánkou máte zpřístupněný nový projekt: ',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.mainInvitation': 'Vítejte!',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationNotFound': 'Pozvánka do projektu nebyla nalezena.',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationError': 'Chyba při načítání pozvánky.',
  'ProjectInvitationAcceptPage.invitationsDeprecated':
    'Tento typ pozvánek už není podporovaný. Nově jsou uživatelé do projektů pozváni organizací, do které projekt patří. Pokud ještě nemáte aktivovaný účet, kotaktujte prosím správce organizace pro opětovné zaslání registrace.',
  'ProjectTemplateSelectSourceProject.title': 'Vytvořit šablonu z projektu',
  'ProjectTemplateSelectSourceProjectModal.projectSelect': 'Vyberte projekt',
  'ProjectTemplateSelectSourceProjectModal.error.projectMissing': 'Není vybraný projekt',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing': 'Spárovat uživatelská oprávnění',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing.tooltip': 'Spáruje oprávnění uživatelů s Profily oprávnění uživatelů na organizaci, pokud se budou nastavení shodovat.',
  'ProjectUserInviteFormData.form.addEmail': 'Přidat email',
  'ProjectUserInviteFormData.form.addMultiMail': 'Přidat hromadně',
  'ProjectUserInviteFormData.form.emails': 'Emaily uživatelů',
  'ProjectUserInviteFormData.form.items.groups.label': 'Přiřadit pozvané uživatele do skupin',
  'ProjectUserInviteFormData.form.items.message.initialValue':
    'Zdravím! Rád bych Vás přizval ke spolupráci na tomto projektu.',
  'ProjectUserInviteFormData.form.items.message.label': 'Text pozvánky',
  'ProjectUserInviteFormData.form.multipleInput.addEmails': 'Přidat emaily',
  'ProjectUserInviteFormData.form.multipleInput.error': 'Zadejte čárkou oddělený seznam emailových adres.',
  'ProjectUserInviteFormData.form.multipleInput.inputText': 'Zadej výchozí text obsahující emaily',
  'ProjectUserInviteFormData.form.multipleInput.label': 'Hromadné zadávání emailů',
  'ProjectUserInviteFormData.form.multipleInput.resultText': 'Nalezené emaily',
  'ProjectUserInviteFormData.form.multipleInput.placeholder': 'josef.novak@gmail.com, petr.spaleny@seznam.cz',
  'ProjectUserInviteFormModal.send': 'Odeslat',
  'ProjectUserInviteFormModal.title': 'Pozvat do projektu',
  'ProjectUserStatus.active': 'aktivní',
  'ProjectUserStatus.expired': 'platnost vypršela',
  'ProjectUserStatus.invited': 'pozván',
  'ProjectUserStatus.suspended': 'deaktivován',
  'ProjectUserStatus.system': 'SYSTEM',
  'ProjectUsersEditModal.title': 'Změna nastavení uživatelů na projektu',
  'ProjectUsersEditModal.active': 'Aktivován',
  'ProjectUsersEditModal.deactive': 'Deaktivován',
  'filters.searchPlaceholder.labels': 'Hledat štítky',
  'filters.searchPlaceholder.users': 'Hledat uživatele',
  'FiltersDocumentsPage.foundEntries': `{total, plural,
    =0 {nenalezeny žádné výsledky}
    one {nalezen jeden záznam}
    few {nalezeny # záznamy}
    other {nalezeno # záznamů}
  } za {took} ms`,
  'FiltersDocumentsPage.loading': 'Načítám filtrované dokumenty...',
  'ReportWidget.projectsInRealization.column.PlanYearICurrent': 'Plán I',
  'ReportWidget.projectsInRealization.column.PlanYearOCurrent': 'Plán O',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearI': 'Prostavěno I',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearO': 'Prostavěno 0',
  'ReportWidget.projectsInRealization.column.remainI': 'Zbývá I',
  'ReportWidget.projectsInRealization.column.remainO': 'Zbývá O',
  'ReportWidgetCreateFormModal.title': 'Přidat nový report',
  'ReportWidgetCreateFormModal.addButton': 'Přidat report',
  'ReportWidgetCreateFormModalForm.unit': 'Řád',
  'ReportWidgetCreateFormModalForm.unit.required': 'Řád je povinný',
  'ReportWidgetCreateFormModalForm.alignLabel': 'Zarovnání hodnoty',
  'ReportWidgetCreateFormModalForm.alignLabel.required': 'Zarovnání je povinné',
  'ReportWidgetCreateFormModalForm.organization.required': 'Organizace je povinný údaj',
  'ReportWidgetCreateFormModalForm.reportType': 'Typ reportu',
  'ReportWidgetCreateFormModalForm.reportType.required': 'Typ reportu je povinný údaj',
  'ReportWidgetCreateFormModalForm.filters': 'Filtr dat',
  'ReportWidgetCreateFormModalForm.columnConfigurations': 'Zobrazené sloupce',
  'ReportWidgetCreateFormModalForm.columnConfigurations.required': 'je povinný alespoň jeden sloupec',
  'ReportWidgetCreateFormModalForm.name.info': 'Pokud není vyplněno, bude použit název sdíleného reportu.',
  'ReportWidgetCreateFormModalForm.name.placeholder': 'Investice 2024',
  'ReportWidgetEditFormModal.title': 'Upravit report',
  'ReportWidgetDecimalUnit.units.label': '{currency}',
  'ReportWidgetDecimalUnit.thousands.label': 'v tis. {currency}',
  'ReportWidgetDecimalUnit.millions.label': 'v mil. {currency}',
  'ReportWidgetDecimalUnit.billions.label': 'v mld. {currency}',
  'ReportWidgetLabelAlignEnum.top.label': 'Nahoru',
  'ReportWidgetLabelAlignEnum.middle.label': 'Na střed',
  'ReportWidgetLabelAlignEnum.bottom.label': 'Dolu',
  'ReserveFileButton.label.set': 'Rezervovat k úpravám',
  'ReserveFileButton.label.unset': 'Ukončit rezervaci',
  'ReserveFileButton.tooltip.reserve': 'Rezervovat dokument k úpravám.',
  'ReserveFileButton.tooltip.reserve.withLimit': 'Bude rezervováno na {reservationDays} dní.',
  'ReserveFileButton.tooltip.reserve.noLimit': 'Rezervace nebude časově omezena.',
  'ReserveFileButton.tooltip.release': 'Ukončit rezervaci od uživatele {user} ze dne {date}.',
  'ReserveFileButton.tooltip.release.info': 'Rezervace bude automaticky ukončena {releaseDate}.',
  'ReserveFileButton.tooltip.release.noLimit': 'Rezervace není časově omezena.',
  'ReserveFileButton.tooltip.reservedByUser': 'Dokument je upravován uživatelem {user}',
  'ReserveFileButton.message.reserve': 'Úspěšně rezervováno.',
  'ReserveFileButton.message.release': 'Rezervace byla ukončena.',
  'RestoreInfoModal.categoryRemovedFrom.item':
    'U dokumentu <strong>{file}</strong> byly odstraněny některé neexistující kategorie',
  'RestoreInfoModal.labelRemovedFrom.item':
    'U dokumentu <strong>{file}</strong> byly odstraněny některé neexistující štítky',
  'RestoreInfoModal.newDirectoryPaths.item':
    'Složka (nebo cesta) <strong>{origin}</strong> byla přejmenována na <strong>{destination}</strong>',
  'RestoreInfoModal.newFileName.item':
    'Dokument <strong>{origin}</strong> byl přejmenován na <strong>{destination}</strong>',
  'RestoreInfoModal.newFilePath.item':
    'Složka Dokumentu <strong>{origin}</strong> byl přejmenován na <strong>{destination}</strong>',
  'RestoreInfoModal.requireCategoryRemovedFrom.item':
    'U složky <strong>{file}</strong> byly odstraněny některé neexistující povinné kategorie',
  'RestoreInfoModal.title':
    'Bylo obnoveno {directoryCount} složek, {fileCount} dokumentů a došlo k následujícím změnám:',
  'RevisionCreateForm.form.items.description.label': 'Komentář revize',
  'RevisionCreateForm.form.items.description.placeholder': 'Popis provedených změn...',
  'RevisionCreateForm.form.items.description.rules.required': 'Prosím zadejte komentář revize',
  'RevisionCreateFormModal.fileTypeReplacement': 'Soubor, který nahráváte má jinou příponu. Přejete si pokračovat?',
  'RevisionCreateFormModal.fileTypeReplacement.description':
    'Soubor s příponou "{oldExt}" bude novou revizí nahrazen souborem s příponou "{newExt}".',
  'RevisionCreateFormModal.title': 'Nová revize',
  'RevisionAttachmentsAddFormModal.title': 'Přidat přílohy',
  'RevisionsDiff.pdfMissing': 'Revize ({revision}) nemá PDF derivát, rozdíl nelze zobrazit.',
  'RevisionsDiff.pdfProcessing': 'Revize ({revision}): dokument se právě zpracovává, opakujte akci později.',
  'RevisionsDiff.firstPdfMissing': 'První revize nemá PDF derivát, rozdíl nelze zobrazit.',
  'RevisionsDiff.secondPdfMissing': 'Druhá revize nemá PDF derivát, rozdíl nelze zobrazit.',
  'RevisionSignedDocumentAddFormModal.analyzeRunning': 'Probíhá kontrola podpisů',
  'RevisionSignedDocumentAddFormModal.analyzeFinished': 'Kontrola dokončena',
  'RevisionSignedDocumentAddFormModal.downloadSignedDocumentMessage': 'Stáhnout dokument k podepsání',
  'RevisionSignedDocumentAddFormModal.invalidFileType': 'Tento typ souboru podepsaného dokumentu není podporován.',
  'RevisionSignedDocumentAddFormModal.title': 'Přidat podepsaný dokument',
  'RevisionSignedDocumentAddFormModal.replaceNotification':
    'Je povolen pouze jeden podepsaný dokument. Stávající dokument bude nahrazen.',
  'RevisionSignedDocumentAddFormModal.missingSignatureWarning':
    'Pozor, nahrávaný dokument neobsahuje některý z podpisů z předchozí verze dokumentu. Přejete si přesto dokument nahrát?',
  'RevisionSignedDocumentAddFormModal.unexpectedCountWraning':
    'Pozor, nahrávaný dokument obsahuje stejný nebo menší počet podpisů. Přejete si přesto dokument nahrát?',
  'RevisionSignDocumentFormModal.title': 'Podepsat dokument',
  'RevisionNumberTag.title': '{number}. revize',
  'RevisionStatus.tooltips.Ok.text': '',
  'RevisionStatus.tooltips.AccessDenied.text': 'Nemáte přístup k dokumentu',
  'RevisionStatus.tooltips.InWorkflow.text': 'Dokument je přiřazen v některém workflow.',
  'RevisionStatus.tooltips.InCommentProcedure.text': 'Dokument je v připomínkovém řízení.',
  'RevisionStatus.tooltips.Reserved.text': 'Dokument je rezervován.',
  'RevisionStatus.tooltips.HasOwner.text': 'Dokument má správce.',
  'RevisionStatus.tooltips.EsticonDocument.text': 'Dokument má vazbu na AspeEsticon.',
  'RevisionRow.withAnnotations': 'Náhled s komentáři',
  'RoleForm.items.name.placeholder': 'Divák - pouze čtení',
  'RoleForm.items.userId.suspended.placeholder': 'Uživatel nezadán nebo deaktivován, prosím, zadejte uživatele',
  'RoleFormModal.title.create': 'Nová role',
  'RoleAssignFormModal.title.create': 'Přiřadit roli',
  'RoleInfo.noRole': '(žádná role)',
  'RoleSelect.preferredRoles': 'Vybrané role',
  'RoleSelect.otherRoles': 'Ostatní',
  'RoleSelect.emptyError': 'Žádná role není k dispozici',
  'RoleSelect.noRoleSet': 'Žádná role není vybrána',
  'RelativeDateWithDeadlines.tooltips.start': 'Začátek:',
  'RelativeDateWithDeadlines.tooltips.deadline': 'Termín:',
  'RelativeDateWithDeadlines.tooltips.completion': 'Dokončení:',
  'RelativeDateWithDeadlines.tooltips.estimatedDates': 'Předpokládané termíny:',
  'RelativeDateWithDeadlines.tooltips.initialization': 'Spuštění paralelních větví:',
  'RelativeDateWithDeadlines.tooltips.parallelEnd': 'Konec paralleních větví:',
  'SearchSortTypeItem.lastChange': 'Poslední změna',
  'SearchSortTypeItem.modifiedBy': 'Uživatel, který provedl změnu',
  'SearchSortTypeItem.createdDate': 'Datum vytvoření',
  'SearchSortTypeItem.deadlineDate': 'Požadovaný termín',
  'SearchSortTypeItem.description': 'Řazení:',
  'SearchSortTypeItem.directoryPath': 'Umístění (cesta)',
  'SearchSortTypeItem.discaredDate': 'Datum vyřazení',
  'SearchSortTypeItem.documentCreated': 'Vytvoření dokumentu',
  'SearchSortTypeItem.documentModified': 'Změna dokumentu',
  'SearchSortTypeItem.extension': 'Přípona',
  'SearchSortTypeItem.isModel': 'Model',
  'SearchSortTypeItem.hasAnnotation': 'Připomínky',
  'SearchSortTypeItem.isSigned': 'Podpis',
  'SearchSortTypeItem.organization': 'Organizace',
  'SearchSortTypeItem.change': 'Datum změny',
  'SearchSortTypeItem.projectName': 'Název projektu',
  'SearchSortTypeItem.lastAccessDate': 'Datum poslední návštěvy',
  'SearchSortTypeItem.projectCreated': 'Datum vytvoření projektu',
  'SearchSortTypeItem.id': 'ID',
  'SearchSortTypeItem.name': 'Název',
  'SearchSortTypeItem.priority': 'Priorita',
  'SearchSortTypeItem.resolvedDate': 'Datum splnění',
  'SearchSortTypeItem.taskDeadline': 'Termín splnění aktivní úlohy',
  'SearchSortTypeItem.workflowRoles': 'Správce WF',
  'SearchSortTypeItem.orderId': 'ID',
  'SearchSortTypeItem.project': 'Projekt',
  'SearchSortTypeItem.state': 'Stav',
  'SearchSortTypeItem.path': 'Cesta',
  'SearchSortTypeItem.type': 'Typ',
  'SearchSortTypeItem.revisionCreated': 'Vytvoření revize',
  'SignedDocumentInput.collapse.header': 'Přidat podepsaný dokument',
  'SignedDocumentInput.collapse.text': 'Vyberte podepsaný dokument (PDF, Obrázek)',
  'SignedDocumentInput.collapse.hint': 'Klikněte nebo přetáhněte soubor sem',
  'SecondaryFilesInput.collapse.header': 'Přidat přílohu',
  'SecondaryFilesInput.collapse.text': 'Klikněte nebo přetáhněte soubory sem',
  'serviceError.AddProjectUserByMailForbiddenError': 'Nemáte oprávnění pozvat nové uživatele do projektu přes e-mail.',
  'serviceError.AdminAccessRequiredError': 'Tato operace vyžaduje práva administrátora.',
  'serviceError.ApplicationAdminAccessRequiredError': 'Tato operace vyžaduje práva administrátora aplikace AspeHub.',
  'serviceError.OrganizationAdminAccessRequiredError': 'Tato operace vyžaduje práva administrátora organizace.',
  'serviceError.ProjectAdminAccessRequiredError': 'Tato operace vyžaduje práva administrátora projektu.',
  'serviceError.ApplicationOrOrganizationAdminAccessRequiredError': 'Tato operace vyžaduje práva administrátora aplikace AspeHub nebo organizace.',
  'serviceError.AppUserNotFoundError': 'Aplikační profil uživatele nebyl nalezen.',
  'serviceError.AssignmentAttachmentIsUseError': 'Příloha je použita v komentáři.',
  'serviceError.BadRequestError': 'Došlo k odeslání nesprávného požadavku.',
  'serviceError.BlobTokenInvalidError': 'Přístupový token je neplatný.',
  'serviceError.CommentProcedureCommentAttachmentNotFoundError': 'Vybraná příloha nebyla nalezena.',
  'serviceError.CommentProcedureCommentAttachmentNotDiscardedBadRequestError': 'Vybraný dokument není vyřazený.',
  'serviceError.CommentProcedureCommentDocumentAlreadyAddedError': 'Dokument je v připomínce již přidaný.',
  'serviceError.CommentProcedureCommentDeleteAttachmentForbiddenError':
    'Vyřazení této přílohy v připomínce je zakázáno pro aktuálního uživatele.',
  'serviceError.CommentProcedureCommentRestoreAttachmentForbiddenError':
    'Obnovení této přílohy v připomínce je zakázáno pro aktuálního uživatele.',
  'serviceError.CommentProcedureForbiddenError':
    'Nejste přiřazeni na toto připomínkové řízení a nemáte potřebná oprávnění pro přístup.',
  'serviceError.CommentProcedureDuplicateNameError': 'Připomínkové řízení s tímto názvem již existuje.',
  'serviceError.CreateModelsError': 'Chyba při vytváření vazeb na model',
  'serviceError.DirectoryAlreadyExistsThereError': 'Složka nebyla přesunuta. Již je v tomto umístění.',
  'serviceError.DirectoryDuplicateNameError': 'Složka s tímto názvem již existuje. Prosím zvolte jiný název.',
  'serviceError.DirectoryMoveForbiddenError': 'Nemáte oprávnění k přesouvané složce, nebo jeho podsložkám.',
  'serviceError.DirectoryRecursiveMoveError': 'Složku nelze přesunout do jejich podsložek.',
  'serviceError.DestinationDirectoryForMoveForbiddenError':
    'Do vybrané složky nelze zapisovat. Nemáte potřebná oprávnění.',
  'serviceError.DirectoryNotFoundError': 'Složka nebyla nalezena.',
  'serviceError.DirectoryPermissionInheritanceOnRootNotPermitedError':
    'Není možné nastavit dědění práv na kořenové složce.',
  'serviceError.DocumentCategoryNodeRemoveObstacleError':
    'Uzel kategorie nebyl smazán, protože je přiřazen některým dokumentům nebo složkám.',
  'serviceError.DocumentCategoryTreeRemoveObstacleError':
    'Kategorie nebyla smazána, protože je přiřazena některým dokumentům nebo složkám.',
  'serviceError.DocumentCommentProcedureForbiddenActionError':
    'Zadanou akci není možné provést. Dokument je v připomínkovém řízení.',
  'serviceError.DocumentInWorkflowForbiddenActionError':
    'Dokument je spravován workflow, proto tuto akci nelze provést.',
  'serviceError.ReservedDocumentAccessError': 'Dokument je rezervován a nelze jej editovat.',
  'serviceError.DocumentReservationReleaseLockError': 'Dokument je upravován uživatelem {user}.',
  'serviceError.DocumentReservationLockNotFoundError': 'Zámek na dokumentu nebyl nalezen',
  'serviceError.DocumentIsReservedError': 'Dokument je již rezervován jiným uživatelem.',
  'serviceError.DocumentLinkNameNotPermitedBadRequestError': 'Nastavení jména linku pro hromadné akce není možné.',
  'serviceError.DocumentLinkNameRequiredBadRequestError': 'Jméno odkazu na dokument nesmí být prázdné.',
  'serviceError.DocumentLinkDuplicateNameError': 'Odkaz na dokument se zadaným názvem ve složce už existuje.',
  'serviceError.DirectoryLinkDuplicateNameError': 'Odkaz na složku se zadaným názvem ve složce už existuje.',
  'serviceError.DocumentManagerAccessError': 'Zadanou akci není možné provést. Dokument má správce.',
  'serviceError.DocumentStateForbiddenChangeError': 'Stav dokumentu nelze z aktuálního stavu změnit na tento stav.',
  'serviceError.DocumentNotFoundError': 'Dokument nebyl nalezen.',
  'serviceError.DocumentSigningIsProcessedError': 'Dokument už je v procesu podepisování.',
  'serviceError.EsticonDocumentsAlreadyLinkedError': 'AspeEsticon project má propojené dokumenty a nelze jej změnit.',
  'serviceError.EsticonProjectNotFoundError': 'AspeEsticon projekt připojený k tomuto projektu nebyl nalezen.',
  'serviceError.ForbiddenError': 'Nemáte dostatečné oprávnění.',
  'serviceError.GroupCountLimitExceededError': 'Překročen maximální počet skupin, nelze vytvořit novou skupinu.',
  'serviceError.GroupNotFoundError': 'Skupina nebyla nalezena.',
  'serviceError.InappropriateCommentStateForChangeError':
    'Připomínky mají chybný stav pro změnu stavu připomínkového řízení.',
  'serviceError.LabelDuplicateNameError': 'Štítek s tímto názvem již existuje.',
  'serviceError.LabelIsDeprecatedError': 'Štítek je zastaralý.',
  'serviceError.LabelIsUsedOnDocumentsError': 'Štítek je nastaven na některém dokumentu.',
  'serviceError.LabelIsUsedOnEntitiesError': 'Štítek je použitý u objektů aplikace.',
  'serviceError.LabelNotFoundError': 'Štítek nebyl nalezen.',
  'serviceError.ModelStateInvalidError': 'Některé nastavení není validní.',
  'serviceError.NetworkError': 'Chyba sítě.',
  'serviceError.NotFoundError': 'Požadovaný zdroj dat nebyl nenalezen.',
  'serviceError.ParentDirectoryNotFoundError': 'Nadřazená složka nebyla nalezena.',
  'serviceError.ProjectNotFoundError': 'Projekt nebyl nalezen.',
  'serviceError.ProjectUserInvitationAlreadyAcceptedError': 'Tato pozvánka byla již přijata.',
  'serviceError.ProjectUserInvitationInvalidAcceptCodeError': 'Nevalidní ověřovací kód pozvánky.',
  'serviceError.ProjectUserInvitationNotFoundError': 'Platnost Vaší pozvánky vypršela.',
  'serviceError.ProjectUserNotFoundError': 'Profil uživatele projektu nebyl nalezen.',
  'serviceError.RevisionIsLockedError': 'Revize dokumentu je zamčená pro podpis jiným uživatelem.',
  'serviceError.RevisionLockNotFoundError': 'Zámek revize nebyl nalezen.',
  'serviceError.RevisionNotFoundError': 'Požadovaný obsah již není platný.',
  'serviceError.RevisionNotDiscardableError': 'Vybranou revizi není možné vyřadit.',
  'serviceError.RevisionNotDiscardedBadRequestError': 'Revize není vyřazená.',
  'serviceError.RevisionRestoreForbiddenError': 'Nemáte dostatečné oprávnění pro obnovení vybrané revize.',
  'serviceError.ReturnToReworkEsticonDocumentResult.Ok': `AspeHub zjistil, že ke sdruženému dokumentu  {count, plural,
    one {není nahrán jeden dílčí}
    few {nejsou nahrány # dílčí}
    other {není nahráno # dílčích}`,
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasRelatedObject': 'ZP aktuálně má již  nahranou FA',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasParentObject': 'Tento dílčí ZP je součástí sdruženého ZP',
  'serviceError.ReturnToReworkEsticonDocumentResult.FaHasParentObject': 'Tato dílčí FA je součástí sdružené FA',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZBVHasZP': 'ZBV aktuálně již má nahraný ZP',
  'serviceError.ReturnToReworkEsticonDocumentResult.ObjectIsNotSent': 'Pokus vrátit dokument, který není aktuálně nahrán',
  'serviceError.ReturnToReworkEsticonDocumentResult.RelatedObjectIsNotSent': 'Pokus vrátit sdružený dokument, který nemá nahraný dílčí',
  'serviceError.ReturnToReworkEsticonDocumentResult.UnspecifiedError': 'Nespecifikovaná AspeEsticon chyba',
  'serviceError.ServerError': '500 (Chyba serveru)',
  'serviceError.SelfPermissionsCannotBeSetError': 'Uživatel nemůže nastavit vlastní oprávnění',
  'serviceError.UnauthorizedError': 'Nemáte potřebné oprávnění!',
  'serviceError.undefined': 'Neznámá chyba.',
  'serviceError.UploadAlreadyCompleteError': 'Nahrávání souboru je již dokončené.',
  'serviceError.UploadAlreadyReferencedError': 'Nahrávání souboru je již použité na jiném místě.',
  'serviceError.UploadExpiredError': 'Časové okno pro upload vypršelo.',
  'serviceError.UploadMissingCompletePartsError': 'Při nahrávání souboru chybí dokončené části.',
  'serviceError.UploadNotCompleteError': 'Nahrávání souboru nebylo dokončeno.',
  'serviceError.UploadNotFoundError': 'Nahrávací proces nebyl nalezen.',
  'serviceError.UploadPartNotFoundError': 'Část nahrávaného souboru nebyla nalezena.',
  'serviceError.UserProfileAlreadyExistsError': 'Tento uživatel již existuje.',
  'serviceError.WorkflowDuplicateNameError': 'Workflow s tímto názvem již existuje. Zadejte prosím jiný název.',
  'serviceError.WorkflowMultipleDocumentStateChangeError':
    'Workflow nesmí obsahovat více uzlů schválení a zamítnutí v jedné větvi.',
  'serviceError.WorkflowNodeWithoutRoleError':
    'Všechny uživatelské a rozhodovací úlohy musí mít nastavenou roli řešitele.',
  'serviceError.WorkflowNotReachableNodeError':
    'Všechny porty všech uzlů musí být připojeny a všechny uzly musí být dostupné.',
  'serviceError.WorkflowPortShouldHaveOneEdgeError': 'Všechny porty všech uzlů musí být připojeny.',
  'serviceError.WorkflowCircularDependencyError': 'Workflow nesmí obsahovat cyklické vazby.',
  'serviceError.WorkflowTemplateInsufficientAccessLevelError':
    'Nemáte potřebná oprávnění pro přístup k šabloně workflow.',
  'serviceError.CommentProcedureApproveError': 'Nepodařilo se schválit připomínkové řízení',
  'serviceError.DirectoryForbiddenError': 'Nemáte přístup k této složce',
  'serviceError.RoleInProcedureError': 'Role je součástí připomínkového řízení',
  'serviceError.UploadAlreadyCompletedError': 'Upload je již dokončený',
  'serviceError.UploadPartAlreadyCompletedError': 'Část uploadu je již dokončená',
  'serviceError.DocumentMultipleAddObstacleError': 'Chyba při přidávání dokumentů',
  'serviceError.DocumentMultipleMoveObstacleError': 'Chyba při přesouvání souborů',
  'serviceError.DocumentMultipleDiscardObstacleError': 'Chyba při vyřazování souborů',
  'serviceError.DocumentMultipleRestoreObstacleError': 'Chyba při obnovování souborů',
  'serviceError.DocumentMoveDuplicateError': 'Dokument s tímto názvem již existuje',
  'serviceError.DirectoryRestoreDuplicateError': 'Složka s tímto názvem již existuje',
  'serviceError.DocumentRestoreDuplicateError': 'Dokument s tímto názvem již existuje',
  'serviceError.ShareExpiredError': 'Časový limit pro stažení sdíleného obsahu vypršel',
  'serviceError.ShareInvalidPinError': 'Neplatný PIN',
  'serviceError.ShareInvalidFileCountError': 'Nelze nalézt validní soubor',
  'serviceError.DirectoryInsufficientAccessLevelError': 'Nemáte dostatečné oprávnění ke složce',
  'serviceError.GoneError': 'Server přestal odpovídat',
  'serviceError.WorkflowNodeHasCorruptedLockError': 'Workflow obsahuje nepotvrzené změny v podkladových dokumentech',
  'serviceError.LicenceExpirationError': 'Platnost licence vypršela',
  'serviceError.DocumentDeepDownloadForbiddenError': 'Nemáte přístup k některým dokumentům, které se snažíte stáhnout',
  'serviceError.ProjectForbiddenSuspendedError': 'Uživatelský účet byl deaktivován',
  'serviceError.ProjectUserLockError':
    'Nebylo možné provést operaci s uživatelským účtem, protože s daným účtem je prováděna jiná akce.',
  'serviceError.CommentProcedureTeamIsUseError': 'Tým je přiřazený k některé připomínce',
  'serviceError.EsticonUnlinkDocumentExistsError':
    'Projekt AspeEsticonu má propojené dokumenty a a není možné jej odebrat.',
  'serviceError.EsticonDocumentNotFoundError': 'Požadovaný dokument AspeEsticonu nebyl nalezen.',
  'serviceError.CommentProcedureDuplicateTeamError': 'Tým s tímto názvem již existuje. Zadejte jiný název týmu.',
  'serviceError.EmailLimitExceedError': 'Překročen počet odeslaných emailů',
  'serviceError.AddAccessLevelToAdminError': 'Nelze změnit přístupová práva skupiny administrátorů.',
  'serviceError.AdminOrExtendedPermissionRequiredError': 'Nedostatečné oprávnění pro tuto akci.',
  'serviceError.AnyDocumentNotFoundError': 'Žádný dokument nebyl nalezen.',
  'serviceError.AnnotationUsedInCommentProcedureCommentBadRequestError': 'Odebíraný komentář je přiřazen k připomínce připomínkového řízení.',
  'serviceError.ApprovaDocumentAlreadyInWorkflowError':
    'Dokument je již přiřazený jako schvalovaný dokument v tomto workflow.',
  'serviceError.ApprovaDocumentInsufficientRoleError': 'Schvalovaný dokument ná nedostatečnou roli.',
  'serviceError.AppUserForProjectNotFoundError': 'Nepodařilo se najít uživatele aplikace k uživateli projektu.',
  'serviceError.AppUserInvalidDashbordConfigurationError': 'Nevalidní nastavení dashboardu.',
  'serviceError.AuditLogNotFoundError': 'Aktivita nebyla nalezena.',
  'serviceError.AutodeskForgeServiceError': 'Chyba v Autodesk Forge službě.',
  'serviceError.BackgroundDocumentAlreadyAssignedError': 'Podkladový dokument je již přiřazený.',
  'serviceError.BackgroundDocumentAlreadyCheckedError': 'Podkladový dokument je již schválený nebo odmítnutý.',
  'serviceError.BackgroundDocumentInsufficientAccessLevelError':
    'Nedostatečné oprávnění pro práci s podkladovým dokumentem.',
  'serviceError.BackgroundDocumentNameAlreadExistsError': 'Podkladový dokument s tímto názvem již existuje.',
  'serviceError.BackgroundDocumentNotAssignedError': 'Podkladový dokument není přiřazený.',
  'serviceError.BackgroundDocumentNotFoundError': 'Podkladový dokument nebyl nalezen.',
  'serviceError.BackgroundTaskInProcessError': 'Stále probíhá zpracování derivátu.',
  'serviceError.BlobDerivateNotFoundError': 'Data derivátu nebyla nalezena.',
  'serviceError.BlobNotFoundError': 'Metadata derivátů nebyla nalezena.',
  'serviceError.ChekedFilePathNotInPathsError': 'Cesta souboru nebyla nalezena.',
  'serviceError.CommentChangeStateForbiddenError': 'Nemáte oprávnění pro změnu stavu připomínky.',
  'serviceError.CommentProcedureAddCommentForbiddenError': 'Nemáte oprávnění pro přidání připomínky.',
  'serviceError.CommentProcedureAlreadyHasWorkflowError': 'Toto připomínkovéřízení je již připojeno k workflow.',
  'serviceError.CommentProcedureAttachmentChangeForbiddenError': 'Nemáte oprávnění pro změnu přílohy.',
  'serviceError.CommentProcedureAttachmentIsUseError': 'Pro vyřazení přílohy ji nejprve smažte z textu připomínky.',
  'serviceError.CommentProcedureCategoryAprovedOrClosedError':
    'Nelze měnit kategorie uzavřeného připomínkového řízení.',
  'serviceError.CommentProcedureCategoryIsUseError': 'Kategorie připomínkového řízení je použita.',
  'serviceError.CommentProcedureCategoryNotFoundError': 'Kategorie připomínkového řízení nebyla nalezena.',
  'serviceError.CommentProcedureCommentNoteNotFoundError': 'Poznámka připomínky nebyla nalezena.',
  'serviceError.CommentProcedureCommentNotFoundError': 'Připomínka nebyla nalezena.',
  'serviceError.CommentProcedureDocumentAlreadyAddedError': 'Dokument je v připomínkovém řízení již přidaný.',
  'serviceError.CommentProcedureDocumentForbiddenError': 'Nedostatečné oprávnění pro dokument připomínkového řízení.',
  'serviceError.CommentProcedureDocumentInCommentError': 'Dokument je přiřazený v připomínce.',
  'serviceError.CommentProcedureDuplicateCategoryError': 'Kategorie připomínkového řízení s tímto názvem již existuje.',
  'serviceError.CommentProcedureDuplicateUserError': 'Uživatel je již přiřazený do tohoto připomínkového řízení.',
  'serviceError.CommentProcedureNoteChangeForbiddenError': 'Nemáte oprávnění pro změnu komentáře připomínky.',
  'serviceError.CommentProcedureNotFoundError': 'Připomínkové řízení nebylo nalezeno.',
  'serviceError.CommentProcedureNotifiedUserNotFoundError': 'Notifikovaný uživatel nebyl nalezen.',
  'serviceError.CommentProcedureProcessorForbiddenError': 'Musíte být zpracovatel v tomto připomínkovém řízení.',
  'serviceError.CommentProcedureRoleChangeForbiddenError': 'Nemáte právo pro změnu role v připomínkovém řízení.',
  'serviceError.CommentProcedureRoleProcessorEmptyError':
    'V připomínkovém řízení musí být alespoň jeden hlavní zpracovatel.',
  'serviceError.CommentProcedureRoleSubmitterEmptyError':
    'V připomínkovém řízení musí být alespoň jeden hlavní připomínkující.',
  'serviceError.CommentProcedureStateChangeForbiddenError': 'Nemáte oprávnění změnit stav připomínkového řízení.',
  'serviceError.CommentProcedureSubmitterForbiddenError': 'Musíte být připomínkující v tomto připomínkovém řízení.',
  'serviceError.CommentProcedureTeamNotFoundError': 'Tým připomínkového řízení nebyl nalezen.',
  'serviceError.CommentProcedureTeamPermissionForbiddenError': 'Nemáte oprávnění změnit tento tým.',
  'serviceError.CommentProcedureUserNotFoundError': 'Uživatel připomínkového řízení nebyl nalezen.',
  'serviceError.CommentProcedureProcessorNoteForbiddenError': 'Zpracovatelé mají zakázán přístup do této diskusní skupiny.',
  'serviceError.CommentProcedureSubmitterNoteForbiddenError': 'Připomínkující mají zakázán přístup do této diskusní skupiny.',
  'serviceError.DbConcurrencyError': 'Došlo k chybě při současném zpracovávání více požadavků. Zkuste to prosím znovu.',
  'serviceError.DestinationDirectoryForDocumentMoveForbiddenError':
    'Nemáte oprávnění v cílovém adresáři pro zápis souborů.',
  'serviceError.DirectoryAdminGroupAccessLevelCannotBeModifiedError':
    'Nelze měnit přístupová oprávnění administrátorské skupiny.',
  'serviceError.DirectoryEsticonImportDiscardForbiddenError':
    'Složka je (nebo obsahuje) adresář vybraný pro import dat z esticonu.',
  'serviceError.DirectoryIdRequiredError': 'Chybí povinný údaj id složky.',
  'serviceError.DirectoryInvalidNameError': 'Nevalidní název složky.',
  'serviceError.DirectoryIsAlreadyDiscardedError': 'Složka je již vyřazená.',
  'serviceError.DirectoryIsNotDiscardedError': 'Složka není vyřazená.',
  'serviceError.DirectoryIsNotEmptyError': 'Složka není prázdná.',
  'serviceError.DirectoryIsRootError': 'Kořenovou složku nelze smazat nebo přesunout.',
  'serviceError.DirectoryRootNotFoundError': 'Kořenová složka nebyla nalezena.',
  'serviceError.DirectoryTreeMaxDepthExceededError': 'Byla překročena maximální hloubka zanoření složky.',
  'serviceError.DirectoryUserAccessAlreadyExistsError': 'Uživatel již má nastavený přístup k této složce.',
  'serviceError.DiscoveryError': 'Došlo k chybě při zakládání WOPI spojení.',
  'serviceError.DocumentAlreadyInModelError': 'Dokument již je přiřazen do modelů.',
  'serviceError.DocumentAlreadyReservedError': 'Dokument již je rezervovaný.',
  'serviceError.DocumentCategoryNodeDuplicateNameError': 'Uzel kategorie s tímto názvem již existuje.',
  'serviceError.DocumentCategoryNodeNotFoundError': 'Uzel kategorie nebyl nalezen.',
  'serviceError.DocumentCategoryTreeDuplicateIdInListError': 'Strom kategorií obsahuje duplicitní id.',
  'serviceError.DocumentCategoryTreeDuplicateNameError': 'Strom kategorií obsahuje duplicitní id.',
  'serviceError.DocumentCategoryTreeMaxDepthExceededError':
    'Byla dosažena maximální hloubka zanoření stromu kategorií.',
  'serviceError.DocumentCategoryTreeNotFoundError': 'Strom kategorií nebyl nalezen.',
  'serviceError.DocumentCategoryTreeRootIsFixedError': 'Nelze posunout kořenový uzel kategorie.',
  'serviceError.DocumentCreatorAccessError': 'Dokument již má jiného správce.',
  'serviceError.DocumentDuplicateNameError': 'Dokument s tímto názvem ve složce již existuje.',
  'serviceError.DocumentDuplicateNamesError': 'Sekundární dokumenty s těmito názvy již existují.',
  'serviceError.DocumentIdRequiredError': 'Id dokumentu je požadovaný parametr.',
  'serviceError.DocumentInCommentProcedureForbiddenActionError':
    'Dokument je součástí připomínkového řízení a nelze měnit.',
  'serviceError.DocumentIsAlreadyDiscardedError': 'Dokument již je vyřazený.',
  'serviceError.DocumentIsNotDiscardedError': 'Dokument není vyřazený.',
  'serviceError.DocumentIsNotInModelError': 'Dokument není přiřazený do modelů.',
  'serviceError.DocumentIsUploadingError': 'Dokument se stále nahrává.',
  'serviceError.DocumentLinkedToEsticonError':
    'Dokument nelze vyřadit, protože je k němu je připojený obsah z Esticonu.',
  'serviceError.DocumentNotContainsRequiredCategoryTreeError': 'Dokument neobsahuje povinné kategorie.',
  'serviceError.DocumentNotReservedError': 'Dokument není rezervován.',
  'serviceError.DocumentRequiredCategoryTreeDeleteError': 'Kategorie je nastavená jako povinná ve složce dokumentu.',
  'serviceError.DocumentRemoveFromModelMultipleObstacleError': 'Dokument nelze vyřadit z modelů',
  'serviceError.DocumentReservationReleaseForbiddenError':
    'Pouze administrátor může zrušit rezervaci jiného uživatele.',
  'serviceError.DocumentWithManagerForbiddenActionError': 'Dokument má správce a nelze rezervovat.',
  'serviceError.EsticonAccessRequiredError': 'Tuto akci může provést pouze systémový Esticon účet.',
  'serviceError.EsticonDirectoryIsNotCreatedError': 'Složka pro import souborů z esticonu není nastavena.',
  'serviceError.EventLogInsufficientAccessLevelError': 'Nemáte dostatečné oprávnění pro zobrazení historie aktivity.',
  'serviceError.FavoriteDirectoryNotFoundError': 'Oblíbená složka nebyla nalezena.',
  'serviceError.FavoriteDocumentNotFoundError': 'Oblíbený dokument nebyl nalezen.',
  'serviceError.GroupDuplicateNameError': 'Jiná skupina s tímto názvem již existuje.',
  'serviceError.GroupIsSystemFixedError': 'Nelze měnit systémovou skupinu.',
  'serviceError.InappropriateCommentProcedureStateError': 'Akci nelze provést v tomto stavu připomínkového řízení.',
  'serviceError.InappropriateCommentStateError': 'Akci nelze provést: jedna z připomínek není ve správném stavu.',
  'serviceError.InappropriateDocumentStateError': 'Jeden z dokumentů není ve validním stavu.',
  'serviceError.InappropriateTaskStateError': 'Neočekávaná chyba: úloha na pozadí není ve validním stavu.',
  'serviceError.InappropriateTaskTypeError': 'Neplatný typ úlohy.',
  'serviceError.InappropriatMessageStatusError': 'Některé zprávy jsou již ve zvoleném stavu.',
  'serviceError.InvalidWopiSessionError': 'Autentizace wopi selhala: neplatná relace.',
  'serviceError.LinkNotFoundError': 'Vazba nabyla nalezena.',
  'serviceError.MessageBadUserError': 'Nemůžete měnit stav této zprávy.',
  'serviceError.MetaextractorTaskInProcessError': 'Nelze odebrat derivát: úkol meta extraktoru stále probíhá.',
  'serviceError.ModelHasLinkError': 'Odebrání modelu by způsobilo zrušení jeho vazeb.',
  'serviceError.ModelOperationInsufficientAccessLevelError':
    'Nemáte dostatečné oprávnění pracovat s tímto dokumentem jako s modelem.',
  'serviceError.NotUpdatableBackgroundDocumentStateError':
    'K tomuto požadavku momentálně nelze nahrát podkladový dokument.',
  'serviceError.NotUpdatableWorkflowNodeStateError': 'Uzel workflow nelze v tomto stavu měnit.',
  'serviceError.NotUpdatableWorkflowStateError': 'Workflow nelze v tomto stavu měnit.',
  'serviceError.ProjectActionForbiddenError': 'Tuto akci nelze provést.',
  'serviceError.ProjectDuplicateNameError': 'Projekt s tímto názvem již existuje.',
  'serviceError.ProjectForbiddenError': 'Nemáte oprávnění v tomto projektu.',
  'serviceError.ProjectNotReadyError': 'Projekt se stále vytváří.',
  'serviceError.ProjectSettingNotFoundError': 'Nastavení projektu nebylo nalezeno.',
  'serviceError.ProjectUserInvitationInvalidTermsAcceptCodeError': 'Neodpovídají ověřovací kód pozvánky uživalele.',
  'serviceError.ProjectUserStatusCannotBeChangedError': 'Nelze změnit stav uživatele.',
  'serviceError.ProjectUserSuspendedError': 'Uživatel je deaktivován.',
  'serviceError.ReferencedWorkflowNodeNotExistError': 'Nevalidní šablona WF: odkazovaný uzel neexistuje.',
  'serviceError.RestoreDirectoryForbiddenError': 'Pouze administrátor projektu může obnovit vyřazenou složku.',
  'serviceError.RestoreDocumentForbiddenError': 'Pouze administrátor projektu může obnovit vyřazený dokument.',
  'serviceError.RoleAlreadyUsedError': 'Role je již využitá v této úloze.',
  'serviceError.RoleAlreadyVotedError': 'Již jste hlasoval(a) za všechny svoje role v této úloze.',
  'serviceError.RoleDuplicateNameError': 'Jiná role s tímto názvem již existuje.',
  'serviceError.RoleInCommentProcedureError': 'Role je použitá v připomínkovém řízení.',
  'serviceError.RoleIsAlreadyUsedError': 'Role je již použita.',
  'serviceError.RoleNotFoundError': 'Role nebyla nalezena.',
  'serviceError.RoleRequireUserForbiddenError': 'Role musí mít přiřazeného uživatele.',
  'serviceError.SecondaryDocumentNotFoundError': 'Příloha nebyla nalezena.',
  'serviceError.ShareNotFoundError': 'Odkaz pro sdílení nebyl nalezen.',
  'serviceError.SourceDirectoryForDocumentMoveForbiddenError':
    'K přesunu dokumentů do jiné složky potřebujete oprávnění pro zápis.',
  'serviceError.DocumentMultipleStateChangeObstacleError': 'Některé stavy dokumentů ze seznamu dokumentů nelze změnit na požadovaný stav.',
  'serviceError.TmpBlobRequiredError': 'Na tuto akci je požadován dočasný soubor.',
  'serviceError.UploadAcccessDeniedError': 'Nemáte oprávnění zde nahrát soubor.',
  'serviceError.UploadedTaskNotFoundError': 'Úloha nahrávání nebyla nalezena.',
  'serviceError.UploadPartETagIsRequiredError': 'U nahrávání chybí Etag.',
  'serviceError.UserCantRemoveFromAdminGroupError': 'Nelze odebrat administrátorskou skupinu.',
  'serviceError.UserIsInRoleError': 'Uživatele nelze smazat: je přiřazený do rolí.',
  'serviceError.UserIsNotInvitedError': 'Uživatel nemá aktivní pozvánku.',
  'serviceError.UserIsNotTeamAdminError': 'Musíte být správce týmu.',
  'serviceError.UserIsSystemFixedError': 'Systémový uživatel nelze měnit.',
  'serviceError.UserNameNotVerifiedBadRequestError': 'Musíte být ověřen(a) pro podepsání dokumentů.',
  'serviceError.UserOrRoleCanBeSetError': 'Musíte vyplnit buďto zodpovědnou roli nebo uživatele (ale ne oboje).',
  'serviceError.WopiSessionNotFoundError': 'Wopi relace nebyla nalezena.',
  'serviceError.WorkflowApprovalRevisionNotFoundError': 'Schvalovaná revize nebyla nalezena',
  'serviceError.WorkflowAutomaticActionShouldHaveOneOutputPortError': 'Automatická úloha musí mít právě jeden výstup.',
  'serviceError.WorkflowDefaultDirectoryAlreadyUsedError':
    'Složka pro podkladové dokumenty je již využitá v tomto workflow.',
  'serviceError.WorkflowDefaultDirectoryAssignedRequestError':
    'Výchozí složka nelze odebrat, protože již obsahuje podkladové dokumenty.',
  'serviceError.WorkflowDefaultDirectoryDuplicateNameError': 'Výchozí složka s tímto názvem již existuje.',
  'serviceError.WorkflowDefaultDirectoryDuplicatePathError': 'Tato složka je již vybraná v jiné výchozí složce.',
  'serviceError.WorkflowDefaultDirectoryInsufficientAccessLevelError':
    'Pro výběr výchozí složky musíte mít právo zápisu v dané složce.',
  'serviceError.WorkflowDefaultDirectoryNotFoundError': 'Výchozí složka nebyla nalezena.',
  'serviceError.WorkflowHeadShouldHaveOneOutputPortError': 'Úloha Start by měla mít přesně jeden výstup.',
  'serviceError.WorkflowInsufficientAccessLevelError': 'Nemáte dostatečné oprávnění v tomto workflow.',
  'serviceError.WorkflowInvalidDocumentAliasError': 'Nevalidní alias dokumentů workflow.',
  'serviceError.WorkflowInvalidRoleError': 'Nevalidní role workflow.',
  'serviceError.WorkflowNodeDuplicateRoleError': 'Stejná role je vícekrát použita v jedné úloze.',
  'serviceError.WorkflowNodeInsufficientAccessLevelError': 'Nemáte oprávnění provádět změny v této úloze.',
  'serviceError.WorkflowNodeNotAcceptedBackgroundDocumentError': 'Všechny podkladové dokumenty musí být schválené.',
  'serviceError.WorkflowNodeNotFoundError': 'Úloha workflow nebyla nalezena.',
  'serviceError.WorkflowNodePortNotFoundError': 'Výstup úlohy workflow nebyl nalezen.',
  'serviceError.WorkflowNodeRoleNotFoundError': 'Role workflow nebyla nalezena.',
  'serviceError.WorkflowNotFoundError': 'Workflow nebylo nalezeno.',
  'serviceError.WorkflowTailMustHaveNoNoOutputPortError': 'Úloha Konec nesmí mít výstupy.',
  'serviceError.WorkflowTemplateDuplicateNameError': 'Jiná šablona s tímto názvem již existuje.',
  'serviceError.WorkflowTemplteNotFoundError': 'Šablona WF nebyla nalezena.',
  'serviceError.WorkflowTemplteShouldHaveOneHeadError': 'Šablona WF musí mít pouze 1 Start úlohu.',
  'serviceError.WorkflowUnassihnedAliasError': 'Musíte vyplnit výchozí složky pro nahrávání podkladových dokumentů.',
  'serviceError.WorkflowNodeAttachmentNotDiscardedBadRequestError': 'Příloha úlohy workflow není vyřazená.',
  'serviceError.WorkflowNodeAttachmentNotFoundError': 'Příloha úlohy workflow nebyla nalezena.',
  'serviceError.WorkflowNodeNoteChangeForbiddenError': 'Poznámka diskuze úlohy workflow nelze změnit.',
  'serviceError.WorkflowNodeNoteNotFoundError': 'Poznámka diskuze úlohy workflow nebyla nalezena.',
  'serviceError.WorkflowNodeNotifiedUserNotFoundError': 'Upozorněný uživatel nebyl v úloze workflow nalezený.',
  'serviceError.WorkflowApprovalDocumentAlreadyAssignedError': 'Dokument již byl přiřazen k jinému workflow.',
  'serviceError.WorkflowHasCriticalErrorError': 'Na workflow se vykytuje kritická chyba',
  'serviceError.AssignmentCloseBadRequestError': 'Úkol je uzavřený a nelze editovat.',
  'serviceError.AssignmentDuplicateNameBadRequestError': 'Jiný úkol s tímto názvem již v tomto projektu existuje.',
  'serviceError.AssignmentFailedSaveServerError': 'Úkol se nepodařilo uložit.',
  'serviceError.AssignmentForbiddenError': 'Nemáte oprávnění zobrazit tento úkol.',
  'serviceError.AssignmentNotFoundError': 'Úkol nebyl nalezen.',
  'serviceError.DocumentEsticonForbiddenActionError': 'Nelze aktualizovat dokument, protože je připojený k Esticonu.',
  'serviceError.EsticonDocumentIsPartialError': 'Nelze změnit stav u dílčího dokumentu Esticonu.',
  'serviceError.InappropriateStateChangeBadRequestError': 'Nelze změnit stav úkolu na tuto hodnotu.',
  'serviceError.InappropriateUserForChangeBadRequestError': 'Nemůžete změnit stav úkolu na tuto hodnotu.',
  'serviceError.InsufficientSubWorkflowCountError': 'Nedostateční počet paralelních větví.',
  'serviceError.ProjectUserNotActivatedError': 'Některý z uživatelů není aktivní.',
  'serviceError.SubWorkflowNotFoundError': 'Subworkflow nebylo nalezeno.',
  'serviceError.WorkflowApprovalDocumentNotFoundNotFoundError': 'Schvalovaný dokument nebyl nalezen.',
  'serviceError.InvaitedUserNameDifferentError': 'Pozvánka patří jinému uživateli.',
  'serviceError.AssignmentAttachmentChangeForbiddenError': 'Přílohu úkolu nelze změnit.',
  'serviceError.AssignmentAttachmentForbiddenError': 'Nemáte oprávnění zobrazit tento úkol.',
  'serviceError.AssignmentAttachmentNotFoundError': 'Příloha úkolu nebyla nalezena.',
  'serviceError.AssignmentDeleteAttachmentForbiddenError': 'Nemáte oprávnění smazat tuto přílohu úkolu.',
  'serviceError.AssignmentNoteChangeForbiddenError': 'Poznámku může editovat pouze její autor.',
  'serviceError.AssignmentNoteNotFoundError': 'Poznámka k úkolu nebyla nalezena.',
  'serviceError.AssignmentNotifiedUserNotFoundError': 'Uživatel pro upozornění není účastníkem na tomto úkolu.',
  'serviceError.AssignmentRestoreAttachmentForbiddenError': 'Nelze obnovit přílohu úkolu.',
  'serviceError.DirectoryCreateForbiddenError': 'Nemáte oprávnění zde vytvořit složku.',
  'serviceError.DocumentNotSignableBadRequestError': 'Tento typ dokumentu nelze podepsat.',
  'serviceError.DocumentSignBlobIsObsoleteError': 'Platnost podpisu dokumentu je zastaralá.',
  'serviceError.InappropriateSigningStateError': 'Chyba při podepisování dokumentu: neplatný stav.',
  'serviceError.InvalidReturnAddressError': 'Chyba při podepisování dokumentu: nevalidní návratová adresa.',
  'serviceError.SigningNotFoundError': 'Podpis nebyl nalezen.',
  'serviceError.OrganizationUserLastAdminError': 'Nelze smazat posledního administrátora',
  'serviceError.OrganizationUserInProjectError': 'Nelze smazat uživatele zařazeného do projektu',
  'serviceError.UserConfirmationResendRecentlyBadRequestError': 'E-mail pro potvrzení uživatele byl odeslán nedávno. Zkuste to znovu později.',
  'serviceError.UserAlreadyConfirmedBadRequestError': 'Uživatel již potvrdil aktivační e-mail.',
  'serviceError.OrganizationDuplicateNameError': 'Organizace s tímto názvem již v AspeHub existuje',
  'serviceError.OrganizationUserInProjectTemplateError': 'Uživatele nelze odebrat, protože se nachází v šabloně projektu',
  'serviceError.OrganizationUserNotFoundError': 'Uživatel nenalezen',
  'serviceError.OrganizationNotFoundError': 'Požadovaná organizace nebyla nalezena.',
  'serviceError.OrganizationIsSameError': 'Požadovaná organizace je stejná jako aktuální.',
  'serviceError.OrganizationForbiddenError': 'Organizace neobsahuje aktuálního uživatele s oprávněním správce.',
  'serviceError.OrganizationHasProjectError': 'Smazání organizace s existujícím projektem není povoleno.',
  'serviceError.OrganizationReportForbiddenError': 'Pro aktuálního uživatele nebylo nalezeno rozšířené oprávnění pro report organizace.',
  'serviceError.OrgLabelIsUsedOnEntitiesError': 'Štítek je použitý u objektů organizace.',
  'serviceError.ExtendedPermissionTemplateNotFoundError': 'Požadovaná šablona oprávnění nebyla nalezena.',
  'serviceError.ExtendedPermissionTemplateInProjectTemplateError': 'Šablona oprávnění je použita v šabloně projektu.',
  'serviceError.ExtendedPermissionTemplateDuplicateNameError': 'Šablona oprávnění s tímto názvem již existuje.',
  'serviceError.ProjectTemplateNotFoundError': 'Požadovaná šablona projektu nebyla nalezena.',
  'serviceError.ProjectTemplateAdminGroupNotFoundError': 'Šablona projektu smí obsahovat pouze jednu skupinu administrátorů.',
  'serviceError.ProjectTemplateUserNotFoundError': 'Požadovaný uživatel šablony projektu nebyl nalezen.',
  'serviceError.CategoryNodeParentNotFoundError': 'Některý nadřazený uzel kategorie nebyl nalezen.',
  'serviceError.DirectoryParentNotFoundError': 'Některý nadřazený adresář nebyl nalezen.',
  'serviceError.CategoryNodeTemplateNotFoundError': 'Požadovaná kategorie uzlu šablony nebyla nalezena.',
  'serviceError.GroupTemplateNotFoundError': 'Požadovaná skupina šablony nebyla nalezena.',
  'serviceError.UserIsInWorkflowError': 'Uživatele nelze smazat. Figuruje ve workflow.',
  'serviceError.UserIsInCommentProcedureError': 'Uživatele nelze smazat. Figuruje v připomínkovém řízení.',
  'serviceError.ComparedDocumentRevisionsAreIdenticalBadRequestError': 'Porovnávané revize dokumentu jsou identické.',
  'serviceError.KontrolaSkupinIssueError': 'Nejsou zaskupinovány všechny položky ZBV',
  'serviceError.DocumentStateOperationForbiddenError': 'Nemáte dostatečné oprávnění',
  'shareDownloadFormModal.errorsTitle.obstacle': 'Vytvoření sdíleného odkazu brání následující chyby:',
  'shareDownloadFormModal.errorsTitle.obstaclePartial': 'Vytvoření úplného sdíleného odkazu brání následující chyby:',
  'ShareDownloadFormModal.send': 'Odeslat',
  'ShareDownloadFormModal.sendNext': 'Odeslat ostatní',
  'ShareDownloadFormModal.title': 'Odkaz ke stažení souboru/ů',
  'ShareDownloadFormModal.success': 'Odkaz ke stažení souboru/ů byl úspěšně odeslán.',
  'ShareDownloadForm.form.emails': 'Emaily uživatelů',
  'SharedDownload.header.file': 'Stažení souboru z portálu AspeHub',
  'SharedDownload.header.archive': 'Stažení archivu z portálu AspeHub',
  'SharedDownload.form.items.includeSubdirectories.title': 'Zahrnout podadresáře',
  'SharedDownload.form.items.includeSubdirectories.label': 'Sdílet složku včetně všech podadresářů',
  'SharedDownload.form.items.message.label': 'Zpráva pro příjemce',
  'SharedDownload.page.button.download': 'Stáhnout',
  'SharedDownload.page.button.download.Others': 'Stáhnout ostatní',
  'SharedDownload.page.downloadError': 'Při stažení došlo k chybě:',
  'SharedDownload.page.error': 'Při načtení sdílení dokumentu došlo k chybě',
  'SharedDownload.page.error.download': 'Při stahování sdílených dokumentů došlo k chybě ({error})',
  'SharedDownload.page.error.obstacle': 'Následující dokumenty nelze stáhnout',
  'SharedDownload.page.expired': 'Platnost stažení vypršela v {date}',
  'SharedDownload.page.expiredInterval': 'Platnost odkazu je {interval} dnů',
  'SharedDownload.page.file': 'Soubor',
  'SharedDownload.page.label.Pin': 'PIN:',
  'SharedDownload.page.label.name': 'Jméno archivu:',
  'SharedDownload.page.pin.placeholder': 'Vložte PIN',
  'SharedDownload.page.ready': 'Požadavek na stažení úspěšně odeslán',
  'SharedDownload.page.revision': 'Revize',
  'SharedDownload.page.user': `Uživatel <strong>{user}</strong> vám zaslal {count, plural,
    one {následující soubor}
    few {následující # soubory}
    other {následujících # souborů}
  }:`,
  'SharedReportWidgetsModal.title': 'Správa sdílených widgetů',
  'SharedReportWidgetsModal.confirmDelete.title': 'Potvrdit smazání sdíleného widgetu',
  'SharedReportWidgetsModal.confirmDelete.description': 'Smazáním sdíleného widgetu bude tento widget znepřístupňěn uživatelům, kteří mají tento widget přidaný na dashboard. Pokračovat?',
  'ShowFavoriteSettingResponseModal.documents': 'Dokumenty',
  'ShowFavoriteSettingResponseModal.links': 'Linky',
  'SignalR.directory.newDocumentAdded': `{count, plural,
    =0 {Žádný dokument nebyl přidán}
    one {1 nový dokument přidán uživatelem {user}}
    few {# nové dokumenty byly přidány uživatelem {user}}
    other {# nových dokumentů bylo přidáno uživatelem {user}}
  }`,
  'SignalR.directory.onDocumentsRestored': `{count, plural,
    =0 {Žádný dokument nebyl obnoven.}
    one {1 dokument byl obnoven uživatelem {user}.}
    few {# dokumenty byly obnoveny uživatelem {user}.}
    other {# dokumentů bylo obnoveno uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentDiscarded': `{count, plural,
    =0 {Žádný dokument nebyl vyřazen.}
    one {1 dokument byl vyřazen uživatelem {user}.}
    few {# dokumenty byly vyřazeny uživatelem {user}.}
    other {# dokumentů bylo vyřazeno uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentChanged': `{count, plural,
    =0 {Žádný dokument nebyl změněn.}
    one {1 dokument byl změněn uživatelem {user}.}
    few {# dokumenty byly změněny uživatelem {user}.}
    other {# dokumentů bylo změněno uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentMovedTo': `{count, plural,
    =0 {Žádný dokument sem nebyl přesunut.}
    one {1 dokument byl sem přesunut uživatelem {user}.}
    few {# dokumenty sem byly přesunuty uživatelem {user}.}
    other {# dokumentů sem bylo přesunuto uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentMovedOut': `{count, plural,
    =0 {Žádný dokument nebyl přesunut z této složky.}
    one {1 dokument byl přesunut z této složky uživatelem {user}.}
    few {# dokumenty byly přesunuty z této složky uživatelem {user}.}
    other {# dokumentů bylo přesunuto z této složky uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentLinkAdded': `{count, plural,
    =0 {Žádný odkaz na dokument nebyl přidán.}
    one {1 odkaz na dokument byl přidán uživatelem {user}.}
    few {# odkazy na dokumenty byly přidány uživatelem {user}.}
    other {# odkazů na dokumenty bylo přidáno uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentLinkRemoved': `{count, plural,
    =0 {Žádný odkaz na dokument nebyl odebrán.}
    one {1 odkaz na dokument byl odebrán uživatelem {user}.}
    few {# odkazy na dokumenty byly odebrány uživatelem {user}.}
    other {# odkazů na dokumenty bylo odebráno uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentLinkMovedOut': `{count, plural,
    =0 {Žádný odkaz na dokument nebyl přesunut z této složky.}
    one {1 odkaz na dokument byl přesunut z této složky uživatelem {user}.}
    few {# odkazy na dokumenty byl přesunuty z této složky uživatelem {user}.}
    other {# odkazů na dokumenty bylo přesunuto z této složky uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentLinkMovedTo': `{count, plural,
    =0 {Žádný odkaz na dokument sem nebyl přesunut.}
    one {1 odkaz na dokument byl sem přesunut uživatelem {user}.}
    few {# odkazy na dokumenty byly sem přesunuty uživatelem {user}.}
    other {# odkazů na dokumenty bylo sem přesunuto uživatelem {user}.}
  }`,
  'SignalR.directory.onDocumentLinkNameChanged': `{count, plural,
    =0 {Žádný odkaz na dokument nebyl změněn.}
    one {1 odkaz na dokument byl změněn uživatelem {user}.}
    few {# odkazy na dokumenty byly změněny uživatelem {user}.}
    other {# odkazů na dokumenty bylo změněno uživatelem {user}.}
  }`,
  'SignalR.directory.directoryLocationChanged': 'Cesta k této složce byla změněna.',
  'SignalR.directory.DirectoryCurrentUserRightsChanged': 'Práva k této složce byla změněna.',
  'SignalR.document.commentProcedureChanged.message': 'Připomínkové řízení {name} bylo změněno. {reason}',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.added': 'Dokument přidán do připomínkového řízení.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removed': 'Dokument odebrán z připomínkového řízení.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.addedToComment': 'Dokument přidán ke připomínky.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removedFromComment': 'Dokument odebrán z připomínky.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.nameChanged': 'Název připomínkového řízení změněn.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentTitleChanged': 'Název připomínky změněn.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentStateChanged': 'Stav připomínky změněn.',
  'SignalR.document.documentDiscarded.message': 'Dokument byl vyřazen uživatelem {user}.',
  'SignalR.document.documentMoved.message': 'Dokument byl přesunut do složky {path} uživatelem {user}.',
  'SignalR.document.documentChanged.message': 'Dokument byl změněn uživatelem {user}. \n{reason}',
  'SignalR.document.documentChanged.messageWithoutUser': 'Dokument byl změněn. \n{reason}',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.rezervation': 'Dokument rezervován.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.owner': 'Změněn správce dokumentu.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.revision': 'Změna revize.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.icon': 'Změněn náhled.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.state': 'Změněn stav.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.labels': 'Změněny štítky.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.name': 'Změněn název.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isModel': 'Změna označení modelu.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isFavorite': 'Změněn stav oblíbený.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.category': 'Změněna kategorie.',
  'SignalR.document.linksChanged.message': 'Odkaz {name} byl změněn uživatelem {user}. {reason}',
  'SignalR.document.linksChanged.LinkActionEnum.added': 'Nový odkaz vytvořen.',
  'SignalR.document.linksChanged.LinkActionEnum.removed': 'Odkaz odebrán.',
  'SignalR.document.linksChanged.LinkActionEnum.rename': 'Odkaz přejmenován.',
  'SignalR.document.linksChanged.LinkActionEnum.moved': 'Odkaz přesunut.',
  'SignalR.document.bindingChanged.message': 'Vazba na dokument {name} byl změněn uživatelem {user}. {reason}',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.added': 'Vazba vytvořena.',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.removed': 'Vazba odebrána.',
  'SignalR.document.revisionChanged.message': 'Revize {name} byla změněna uživatelem {user}. {reason}',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.added': 'Přidána nová revize',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.discarded': 'Revize vyřazena',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.restored': 'Revize obnovena',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.signAdded': 'Přidán podepsaný dokument',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.secondaryAdded': 'Přidán sekundární dokument',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.annotationChanged': 'Přidána annotace',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.name': 'Změněno jméno revize',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.blobProcessed': 'Soubor revize zpracován',
  'SignalR.document.workflowChanged.message': 'Workflow {name} bylo změněno uživatelem {user}. {reason}',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApproval': 'Byl přidán schvalovaný dokument.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.removedApproval': 'Byl odebrán schvalovaný dokument.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApprovalRevision': 'Byla přidána revize schvalovaného dokumentu.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.backgroundChanged': 'Byl změněn podkladový dokument nebo jeoh stav.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.state': 'Došlo ke změně stavu.',
  'SignalR.esticonGrid.onObjectAdded': `{count, plural,
    =0 {Žádný objekt nebyl přidán.}
    one {1 objekt byl přidán uživatelem {user}.}
    few {# objekty byly přidány uživatelem {user}.}
    other {# objektů bylo přidáno uživatelem {user}.}
  }`,
  'SignalR.esticonGrid.onObjectDateChanged': `{count, plural,
    =0 {Nebylo změněno datum schválení žádného objektu.}
    one {pro 1 objekt se změnil datum schválení uživatelem {user}.}
    other {# objektům se změnilo datum schválení uživatelem {user}.}
  }`,
  'SignalR.esticonGrid.onObjectReturnForRework': `{count, plural,
    =0 {Žádný objekt nebyl vrácen k přepracování.}
    one {1 objekt byl vrácen k přepracování uživatelem {user}.}
    few {# objekty byly vráceny k přepracování uživatelem {user}.}
    other {# objektů bylo vráceno k přepracování uživatelem {user}.}
  }`,
  'SignalR.workflow.workflowChanged.message': 'Workflow bylo změněno uživatelem {user}.',
  'SignalR.workflow.workflowTemplateChanged.message': 'Šablona workflow byla změněna uživatelem {user}.',
  'ToolbarSettingsButton.close': 'Zavřít nastavení',
  'ToolbarSettingsButton.open': 'Nastavení složky',
  'UnsupportedBrowser.continueAnyway': 'Přesto pokračovat',
  'UnsupportedBrowser.error': 'Tato aplikace není touto verzí prohlížeče podporována.',
  'UploadNewDocument.tooltip': 'Nahrát nový dokument',
  'UploadState.toolbar.showOnlyErrors': 'Zobrazit pouze chyby nahrávání',
  'UploadState.error.uploadDeniedError': 'Nahrávání zamítnuto: {reason}',
  'UserActivity.report.modal.rangeLabel': 'Datum exportovaného období uživatelské aktivity',
  'UserActivity.report.modal.checkbox.exportAllProjectTimeReport': 'Export dat od začátku projektu',
  'UserActivity.report.modal.disableOk.tooltipText': 'Není zadáno relevantní období',
  'UserActivity.report.modal.title': 'Export uživatelské aktivity a nastavení projektu za vybrané období',
  'UserAvatar.MultiUserAvatar.documentUserAndRevisionUserTitle': 'Autor dokumentu i poslední revize',
  'UserAvatar.MultiUserAvatar.documentUserTitle': 'Autor dokumentu',
  'UserAvatar.MultiUserAvatar.revisionUserTitle': 'Autor poslední revize',
  'UserAvatar.revisionTitle': 'Autor revize',
  'UserDetailPanel.ConfirmSuspend.title': 'Deaktivace uživatele',
  'UserDetailPanel.ConfirmSuspend.option':
    'Deaktivovat uživatele zároveň ve všech ostatních projektech, kde není administrátorem',
  'UserDetailPanel.ConfirmSuspend.text': 'Opravdu chcete deaktivovat daného uživatele v rámci tohoto projektu?',
  'UserDetailPanel.ConfirmActivate.text': 'Opravdu chcete aktivovat daného uživatele?',
  'UserDetailPanel.GeneralTab': 'Nastavení uživatele',
  'UserDetailPanel.GeneralTab.currentUser': 'Aktuálně přihlášený uživatel',
  'UserDetailPanel.GeneralTab.reinviteButton': 'Znovu odeslat aktivační mail',
  'UserDetailPanel.GeneralTab.deleteInvitedButton': 'Smazat uživatele',
  'UserDetailPanel.GeneralTab.suspendButton': 'Deaktivovat uživatele',
  'UserDetailPanel.GeneralTab.suspendingIsPossible': 'Uživatele je možno deaktivovat',
  'UserDetailPanel.GeneralTab.activateButton': 'Aktivovat uživatele',
  'UserDetailPanel.GeneralTab.reinviteExceeded':
    'Byl překročen maximální počet odeslaných emailů pro emailovou adresu uživatele. Zkuste to prosím znovu později.',
  'UserDetailPanel.GeneralTab.reinviteError': 'Při opakovaném odesílání pozvánky došlo k chybě.',
  'UserDetailPanel.GeneralTab.deleteInvitedError': 'Při pokusu o smazání pozvánky došlo k chybě.',
  'UserDetailPanel.GeneralTab.deactivateUserError': 'Při pokusu o deaktivaci uživatele došlo k chybě.',
  'UserDetailPanel.GeneralTab.activateError': 'Při pokusu o aktivaci uživatele došlo k chybě.',
  'UserDetailPanel.GeneralTab.licenceError': 'Nemáte dostatek licencí k aktivaci dalšího uživatele.',
  'UserDetailPanel.GeneralTab.reinviteSuccess': 'Pozvánka byla úspěšně odeslána.',
  'UserDetailPanel.GeneralTab.deleteInvitedSuccess': 'Pozvánka byla úspěšně smazána.',
  'UserDetailPanel.GeneralTab.deactivateUserSuccess': 'Uživatel byl úspěšně deaktivován.',
  'UserDetailPanel.GeneralTab.activateSuccess': 'Uživatel byl úspěšně aktivován.',
  'UserDetailPanel.GeneralTab.currentLoggedUser': 'Aktuálně přihlášený uživatel',
  'UserDetailPanel.GroupsTab': 'Skupiny',
  'UserDetailPanel.GroupsTab.UserDisabledWarning': 'Uživatel je deaktivován. Není možné nastavit skupiny.',
  'UserDetailPanel.PermisionsTab': 'Oprávnění',
  'UserDetailPanel.PermisionsTab.categoryType.projectManager': 'Manažer projektu',
  'UserDetailPanel.PermisionsTab.categoryType.document': 'Dokumenty - povolené stavy',
  'UserDetailPanel.PermisionsTab.categoryType.workflow': 'Workflow',
  'UserDetailPanel.PermisionsTab.categoryType.aspeEsticon': 'AspeEsticon',
  'UserDetailPanel.PermisionsTab.category.addWorkflow': 'Editace šablon',
  'UserDetailPanel.PermisionsTab.category.category': 'Kategorie',
  'UserDetailPanel.PermisionsTab.category.externalApplications': 'Externí aplikace',
  'UserDetailPanel.PermisionsTab.category.groups': 'Skupiny',
  'UserDetailPanel.PermisionsTab.category.importHarmonogram': 'Import harmonogramu',
  'UserDetailPanel.PermisionsTab.category.returnForRework': 'Vrátit k přepracování',
  'UserDetailPanel.PermisionsTab.category.importFaktura': 'Import faktur',
  'UserDetailPanel.PermisionsTab.category.importZP': 'Import ZP',
  'UserDetailPanel.PermisionsTab.category.importZBV': 'Import ZBV',
  'UserDetailPanel.PermisionsTab.category.importWorkflow': 'Import/export workflow',
  'UserDetailPanel.PermisionsTab.category.labels': 'Štítky',
  'UserDetailPanel.PermisionsTab.category.roleEditing': 'Editace rolí',
  'UserDetailPanel.PermisionsTab.category.userEditing': 'Editace uživatelů',
  'UserDetailPanel.PermisionsTab.category.stavba': 'Modul AspeEsticon',
  'UserDetailPanel.PermisionsTab.categoryDescription.stavba': 'Povolení celého modulu stavby, tj. rozpočet, čepání a další.',
  'UserDetailPanel.PermisionsTab.category.prenosHub': 'Zobrazovat včetně neodeslaných na AspeHub',
  'UserDetailPanel.PermisionsTab.category.workflowPreview': 'Náhled workflow',
  'UserDetailPanel.PermisionsTab.category.setApprovalDate': 'Nastavení data schválení',
  'UserDetailPanel.PermisionsTab.category.transferKD': 'Přenos do kontrolní databáze',
  'UserDetailPanel.PermisionsTab.category.addPartner': 'Zakládání partnerů',
  'UserDetailPanel.PermisionsTab.category.forbidWorkInProgressState': 'forbidWorkInProgressState',
  'UserDetailPanel.PermisionsTab.category.forbidSharedState': 'forbidSharedState',
  'UserDetailPanel.PermisionsTab.category.forbidRejectedState': 'forbidRejectedState',
  'UserDetailPanel.PermisionsTab.category.forbidPublishedState': 'forbidPublishedState',
  'UserDetailPanel.PermisionsTab.category.forbidarchivedState': 'forbidarchivedState',
  'UserDetailPanel.userActivitiesTab': 'Aktivity uživatele - relace',
  'UserDetailPanel.RolesTab': 'Role',
  'UserDetailPanel.RolesTab.activity': 'Aktivita role {name}',
  'UserDetailPanel.RolesTab.AddRole': 'Přidat roli',
  'UserDetailPanel.RolesTab.AddRole.title': 'Nastavit uživatele do role',
  'UserDetailPanel.RolesTab.AddRole.description': 'Nahradí aktuálního uživatele z dané role.',
  'UserDetailPanel.RolesTab.rolesListTitle': 'Role uživatele',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInDocument': 'Dokument:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflow': 'WF:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflowTemplate': 'Šablona WF:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInCommentProcedure': 'Připomínkové řízení:',
  'UserDetailPanel.RolesTab.ConfirmRemove.title': 'Vybraná role je použitá v existujícím workflow',
  'UserDetailPanel.RolesTab.ConfirmRemove.content': `{count, plural,
    =0 {Role není použita}
    one {Role je použita na jedené položce}
    few {Role je použita na # položkách}
    other {Role je použita na # položkách}
    }.`,
  'UserDetailPanel.RolesTab.ConfirmRemove.question': 'Opravdu chcete odebrat uživatele dané roli?',
  'UserDetailPanel.RolesTab.ConfirmChange.title': 'Vybraná role už má uživatele',
  'UserDetailPanel.RolesTab.ConfirmChange.content': 'Vybraná role už má uživatele {user}, pokračovat?',
  'UserListPanel.addUserButton.disableTooltip': 'Všichni dostupní uživatelé organizace jsou již na projektu, zároveň nemáte oprávnění přizvat nové uživatele',
  'UserNotificationButton.resolver.notification.title': 'Upozornění řešitele',
  'UserNotificationButton.resolver.notification.wasNotified': 'Řešitel již byl upozorněn',
  'UserNotificationButton.resolver.notification.label': 'Zpráva pro řešitele',
  'UserNotificationButton.resolver.notification.WorkflowNodeResolveDemand': 'Dobrý den, zasílám upozornění na nevyřešenou úlohu WF.',
  'UserNotificationButton.resolver.notification.AssignmentResolveDemand': 'Dobrý den, zasílám upozornění na nevyřešený úkol.',
  'UserNotificationButton.resolver.notification.noText': 'Není zadána zpráva pro řešitele',
  'SessionTab.auditlogTitle': 'Aktivity uživatele',
  'SessionTab.OrderFilter.sessionStart': 'Začátek relace',
  'SessionTab.OrderFilter.sessionEnd': 'Konec relace',
  'SessionTab.OrderFilter.period': 'Období',
  'SessionListItem.sessionStart': 'Začátek: ',
  'SessionListItem.sessionEnd': 'Konec: ',
  'SessionListItem.IpAddress': 'IP adresa: ',
  'UsersFilter.documentUser': 'Autor dokumentu',
  'UsersFilter.discardedUser': 'Vyřazeno uživatelem',
  'UsersFilter.revisionUser': 'Autor revize',
  'UsersFilter.modifiedUser': 'Autor změny',
  'UsersFilter.ownerUser': 'Správce dokumentu',
  'UsersSelect.emptyError': 'Žádný uživatel není k dispozici',
  'UserTransfer.sourceTitle': 'Dostupní uživatelé',
  'UserTransfer.targetTitle': 'Vybraní uživatelé',
  'UserTransfer.searchPlaceholder': 'Hledat',
  'UserTransfer.itemUnit': 'uživatel',
  'UserTransfer.itemsUnit': 'uživatelů',
  'UserTransfer.selectAll': 'Vybrat všechny',
  'UserTransfer.selectInvert': 'Invertovat výběr',
  'ArchiveViewer.headers.size': 'Velikost',
  'ArchiveViewer.headers.type': 'Typ archivu',
  'EmailViewer.headers.subject': 'Předmět',
  'EmailViewer.headers.from': 'Odesílatel',
  'EmailViewer.headers.to': 'Komu',
  'EmailViewer.headers.cc': 'Kopie',
  'EmailViewer.headers.date': 'Datum',
  'EmailViewer.headers.attachments': 'Přílohy',
  'PdfViewer.title': 'PDF',
  'PdfSignatureAction.documentInWorkflowWarning.title': 'Dokument je ve workflow',
  'PdfSignatureAction.documentInWorkflowWarning.content': 'Tento dokument je vložen do spuštěného workflow a nemůže být podepsán mimo něj. Přejete si přejít do tohoto workflow?',
  'ProcessedJsonViewer.title': 'Obsah',
  'ProcessedJsonViewer.processor.notFound': 'Obsah souboru není podporován.',
  'ProcessedJsonViewer.processor.file.error': 'Chyba při načítání obsahu souboru',
  'ForgeViewer.title': 'Model',
  'ImageViewer.title': 'Obrázek',
  'OfficeViewer.title': 'Office',
  'VideoViewer.title': 'Video',
  'AudioViewer.title': 'Zvuk',
  'XmlViewer.title': 'Xml',
  'XmlViewer.copyWarning':
    'UPOZORNĚNÍ: textové položky xml byly rozkódovány (např. "poloměr < 5cm" namísto "poloměr &lt; 5cm"), zkopírování textu náhledu NEMUSÍ vyprodukovat validní xml.',
  'ZipViewer.title': 'Zip',
  'WatchSwitch.watch': 'Sledovat',
  'WatchSwitch.buttonTitle.nameChange': 'Změna názvu',
  'WatchSwitch.buttonTitle.descriptionChange': 'Změna popisu',
  'WatchSwitch.buttonTitle.categoryChange': 'Změna kategorií',
  'WatchSwitch.buttonTitle.permissionChange': 'Změna oprávnění',
  'WatchSwitch.buttonTitle.directoryDiscard': 'Vyřazení složky',
  'WatchSwitch.buttonTitle.directoryMove': 'Přesunutí složky',
  'WatchSwitch.buttonTitle.addSubdirectory': 'Přidání podsložky',
  'WatchSwitch.buttonTitle.removeSubdirectory': 'Odebrání podsložky',
  'WatchSwitch.buttonTitle.addDocument': 'Přidání dokumentu',
  'WatchSwitch.buttonTitle.removeDocument': 'Odebrání dokumentu',
  'WatchSwitch.buttonTitle.revisionChange': 'Změna revize',
  'WatchSwitch.buttonTitle.download': 'Zobrazení a stažení dokumentu',
  'WatchSwitch.buttonTitle.documentShare': 'Sdílení dokumentu se členy projektu',
  'WatchSwitch.buttonTitle.linkShare': 'Odeslání odkazu ke stažení',
  'WatchSwitch.buttonTitle.linkShareDownload': 'Stažení odeslaného odkazu',
  'WatchSwitch.buttonTitle.stateChange': 'Změna stavu',
  'WatchSwitch.buttonTitle.labelChange': 'Změna štítku',
  'WatchSwitch.buttonTitle.ownerChange': 'Změna vlastníka',
  'WatchSwitch.buttonTitle.documentMove': 'Přesunutí dokumentu',
  'WatchSwitch.buttonTitle.documentDiscard': 'Vyřazení dokumentu',
  'WatchSwitch.buttonTitle.documentSign': 'Podepsání dokumentu',
  'WatchSwitch.buttonTitle.annotationChange': 'Změna komentářů',
  'WatchSwitch.buttonTitle.attachmentChange': 'Změna příloh',
  'WatchSwitch.buttonTitle.cpChange': 'Změna v rámci připomínkovém řízení',
  'WatchSwitch.buttonTitle.wfChange': 'Změna v rámci workflow',
  'WatchSwitch.buttonTitle.assignmentChange': 'Změna v rámci úkolu',
  'WidgetReportsContext.message.addWidget.success': 'Widget byl úspěšně přidán na dashboard',
  'WidgetReportsContext.message.cloneWidget.prefix': 'Kopie',
  'WidgetReportsContext.message.cloneWidget.error.notFound': 'Konfigurace widgetu nebyla nalezena. Kopii nelze vytvořit.',
  'WipRecordDetail.additionalInformation': 'Doplňující údaje',
  'WipRecordDetail.administrator': 'Správce / Investor',
  'WipRecordDetail.approval': 'Schválení',
  'WipRecordDetail.destinationObjectId': 'Připojení k objektu',
  'WipRecordDetail.objectPath': 'Objekt',
  'WipRecordDetail.constructionParticipants': 'Účastníci výstavby',
  'WipRecordDetail.contractor': 'Dodavatel',
  'WipRecordDetail.issue': 'Vystavení',
  'WipRecordDetail.overview': 'Přehled',
  'WipRecordDetail.projectRealizationWithVAT': 'Čerpání s DPH',
  'WipRecordDetail.protocolNumber': 'Číslo protokolu',
  'WipRecordDetail.quantityCfW': 'Množství SoD',
  'WipRecordDetail.vatByExposed': 'DPH dle vystavení',
  'WipRecordDetail.realized': 'Čerpáno',
  'WipRecordDetail.realizedQuantityType': 'Typ čerpaného množství',
  'WipRecordDetail.realizedWithValorization': 'Čerpáno s valorizací',
  'WipRecordDetail.realizedWithValorizationAndVAT': 'Čerpáno s valorizací a DPH',
  'WipRecordDetail.retentionIncludingVAT': 'Zádržně včetně DPH',
  'WipRecordDetail.suspensionIncludingVAT': 'Pozastávka včetně DPH',
  'WipRecordDetail.taxPeriod': 'Daňové období',
  'WipRecordDetail.totalIncludingVAT': 'Celkem včetně DPH',
  'WipRecordDetail.valorization': 'Valorizace',
  'WipRecordDetail.VAT': 'DPH ({percent}%)',
  'WipRecordDetail.VATTransferRegime.title': 'Režim přenosu DPH',
  'WipRecordDetail.VATTransferRegime.option.notApplied': 'Neuplatňuje se',
  'WipRecordDetail.VATTransferRegime.option.receiverOfTheInvoiceWillPayVAT': 'DPH zaplatí příjemce faktury',
  'WipRecordDetail.VATTransferRegime.option.VATReverseChargedFromContractor':
    'DPH přeneseno od poskytovatele (dodavatele)',
  'Workflow.name.full': 'ID {id} - {name}',
  'Workflow.name.id': 'ID {id}',
  'Workflow.nodes.start': 'Začátek',
  'Workflow.nodes.stop': 'Konec',
  'Workflow.nodes.unknown': '??',
  'Workflow.nodes.ApprovalNode': 'Rozhodovací úloha',
  'Workflow.nodes.GenericNode': 'Uživatelská úloha',
  'Workflow.nodes.userTask': 'Uživatelská úloha',
  'Workflow.nodes.userAction': 'Uživatelská úloha',
  'Workflow.nodes.notification': 'Upozornění',
  'Workflow.nodes.accept': 'Schválení dokumentů',
  'Workflow.nodes.refuse': 'Zamítnutí dokumentů',
  'Workflow.nodes.parallel': 'Paralelní větvení',
  'Workflow.nodes.addRevision': 'Změna dokumentů',
  'Workflow.nodes.input': 'Vstup',
  'Workflow.nodes.parallel_END': 'Paralelní větvení - konec',
  'Workflow.nodes.changeDocState': 'Změna stavu dokumentu',
  'WorkflowDetail.ActiveTaskDetail.tabname': 'Úlohy',
  'WorkflowDetail.CommentProcedures.tabname': 'Připomínková řízení',
  'WorkflowDetail.DefaultFolders.tabname': 'Výchozí složky',
  'WorkflowDetail.DefaultFolders.buttonAdd.text': 'Přidat složku',
  'WorkflowDetail.DefaultFolders.invalidFolder': 'Nevalidní výchozí složka',
  'WorkflowDetail.DefaultFolders.name': 'Název',
  'WorkflowDetail.DefaultFolders.noDirectory': '(složka není nastavena)',
  'WorkflowDetail.DefaultFolders.description': 'Popis',
  'WorkflowAddRequestFormModal.title': 'Přidat požadavek na podkladový dokument',
  'WorkflowChangeRequestFormModal.title': 'Upravit požadavek',
  'WorkflowAddRequestForm.name': 'Název požadavku',
  'WorkflowAddRequestForm.name.required': 'Prosím vyplňte název požadavku',
  'WorkflowAddRequestForm.name.unique': 'Již existuje jiný požadavek s tímto názvem',
  'WorkflowAddRequestForm.description': 'Popis požadavku',
  'WorkflowAddRequestForm.insertionTerm': 'Požadovaný termín',
  'WorkflowAddRequestForm.insertionTerm.required': 'Prosím vyplňte požadovaný termín',
  'WorkflowAddRequestForm.insertionTerm.target': 'Termín úlohy',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId': 'Složka pro nahrání dokumentů',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.required': 'Prosím vyberte složku',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.placeholder': 'Složka pro nahrání souborů',
  'WorkflowAddRequestForm.responsibleRoleId': 'Odpovědná osoba (role)',
  'WorkflowAddRequestForm.responsibleRoleId.required': 'Prosím vyberte zodpovědnou roli',
  'WorkflowApprovalDocumentActivityList.activityType.addedDocument': 'Přidán dokument do workflow',
  'WorkflowApprovalDocumentActivityList.activityType.addedRevision': 'Nahrána nová revize dokumentu',
  'WorkflowApprovalDocumentActivityList.activityType.signed': 'Podepsána revize dokumentu',
  'WorkflowApprovalDocumentActivityModal.title': 'Změny dokumentu ve workflow',
  'WorkflowApprovalDocumentActivityHistoryButton.tooltip': 'Historie změn dokumentu ve workflow',
  'WorkflowCommentProcedures.removeProcedure.tooltip': 'Odebrat připomínkové řízení z workflow',
  'WorkflowCommentProcedures.removeProcedure.confirm': 'Opravdu odebrat připomínkové řízení?',
  'WorkflowCommentProcedures.removeProcedure.success': 'Připomínkové řízení úspěšně odebráno.',
  'WorkflowDetail.DocumentApprovals.tabname': 'Schvalované dokumenty ({count})',
  'WorkflowDetail.DocumentApprovals.revisionsModalTitle': 'Revize dokumentu na tomto WF',
  'WorkflowDetail.BackgroundDocuments.tabname': 'Podkladové dokumenty ({count})',
  'WorkflowDetail.WorkflowActivity.tabname': 'Aktivita',
  'WorkflowDetail.WorkflowErrors.tabname': 'Chyby',
  'WorkflowDetail.WorkflowErrors.noErrors': 'Workflow neobsahuje žádné chyby',
  'WorkflowDefaultDirectoryForm.modal.title': 'Výchozí složka',
  'WorkflowDefaultDirectoryForm.modal.title.create': 'Přidat výchozí složku',
  'WorkflowDefaultDirectoryForm.modal.title.update': 'Upravit výchozí složku',
  'WorkflowDefaultDirectoryForm.form.name': 'Název výchozí složky',
  'WorkflowDefaultDirectoryForm.form.name.required': 'Název výchozí složky je povinný',
  'WorkflowDefaultDirectoryForm.form.description': 'Popis výchozí složky',
  'WorkflowDefaultDirectoryForm.form.path': 'Cesta pro výchozí složku',
  'WorkflowDefaultDirectoryForm.form.path.required': 'Cesta pro výchozí musí být vyplněna',
  'WorkflowDocuments.revisionDateLabel': 'Revize workflow: {date}',
  'ActiveTaskDetail.WorkflowErrors.subjectName': 'Název objektu',
  'ActiveTaskDetail.WorkflowErrors.severity': 'Závažnost chyby',
  'ActiveTaskDetail.WorkflowErrors.roleId': 'Ovlivněná role',
  'ActiveTaskDetail.WorkflowErrors.subject': 'Typ chyby',
  'ErrorWorkflowSubjectEnum.backgroundDocumentRole':
    'Nevyplněný nebo deaktivovaný uživatel u zodpovědné role pro nahrání požadavku',
  'ErrorWorkflowSubjectEnum.backgroundDocumentTerm': 'Termín požadavku vypršel',
  'ErrorWorkflowSubjectEnum.nodeRole': 'Nevyplněný nebo deaktivovaný uživatel u role řešitele',
  'ErrorWorkflowSubjectEnum.nodeTerm': 'Termín úlohy vypršel',
  'ErrorWorkflowSubjectEnum.workflowRole': 'Nevyplněný nebo deaktivovaný uživatel u role správce workflow',
  'ErrorWorkflowSubjectEnum.backgroundDocumentDir': 'Nevalidní výchozí složka',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureRole': 'Nevyplněný nebo deaktivovaný uživatel v roli',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureUser': 'Uživatel je deaktivovaný',
  'ErrorSeverityEnum.warning': 'Varování',
  'ErrorSeverityEnum.error': 'Chyba',
  'ErrorSeverityEnum.critical': 'Kritická chyba',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentRole':
    'Zodpovědná role pro nahrání požadavku nemá přiřazeného uživatele',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentTerm': 'Termín požadavku vypršel',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeRole': 'Role řešitele nemá přiřazeného uživatele',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeTerm': 'Termín úlohy vypršel',
  'ActiveTaskDetail.WorkflowErrors.subject.workflowRole': 'Role správce workflow nemá přiřazeného uživatele',
  'ActiveTaskDetail.WorkflowErrors.subject.defaultDirectory': 'Nevalidní výchozí složka',
  'WorkflowDetail.WorkflowSchema.tabname': 'Schéma',
  'WorkflowInstantiationButton.noTemplates': 'Nejsou k dispozici žádné šablony pro založení workflow',
  'WorkflowInstantiationButton.noRoles': 'Pro spuštění musíte mít přiřazenou alespoň jednu roli v projektu.',
  'WorkflowInstantiationForm.name': 'Název',
  'WorkflowInstantiationForm.name.required': 'Prosím vyplňte název workflow',
  'WorkflowInstantiationForm.description': 'Popis',
  'WorkflowInstantiationForm.template': 'Šablona',
  'WorkflowInstantiationForm.template.required': 'Prosím vyberte šablonu workflow',
  'WorkflowInstantiationForm.approvalDocuments': 'Schvalované dokumenty',
  'WorkflowInstantiationForm.defaultDirectories': 'Výchozí složky',
  'WorkflowInstantiationForm.defaultDirectories.noTemplate': 'Nejdříve vyberte šablonu',
  'WorkflowInstantiationForm.defaultDirectories.placeholder': 'Vyberte výchozí složku',
  'WorkflowInstantiationForm.defaultDirectories.required': 'Prosím vyberte všechny výchozí složky',
  'WorkflowInstantiationForm.workflowRole': 'Správce workflow',
  'WorkflowInstantiationForm.workflowRole.required': 'Prosím vyplňte správce workflow',
  'WorkflowInstantiationFormModal.messagePlaceholder': 'Komentář pro řešitele první úlohy workflow...',
  'WorkflowInstantiationFormModal.messageLabel': 'Komentář k vytvoření workflow',
  'WorkflowInstantiationFormModal.title': 'Spustit nové workflow',
  'WorkflowInstantiationFormModal.WfIsStarting': 'Workflow se spouští ...',
  'WorkflowDetail.defaultDirectory.removeImpossible.message': 'Výchozí složku nelze smazat, je na ni založen požadavek',
  'WorkflowDetail.defaultBackgroundDirectory.name': 'Složka pro podkladové dokumenty',
  'WorkflowListItem.admin': 'Správce workflow',
  'WorkflowListItem.taskTerm': 'Termín aktivní úlohy: {date}',
  'WorkflowListPage.filter.assignedTo.options.waiting': 'Moje budoucí',
  'WorkflowListPage.filter.assignedTo.options.current': 'Moje k řešení',
  'WorkflowListPage.filter.assignedTo.options.out': 'Moje nedostupné',
  'WorkflowListPage.filter.assignedTo.options.processed': 'Moje hotové',
  'WorkflowListPage.filter.assignedTo.options.other': 'Ostatní',
  'WorkflowListPage.filter.assignedTo.label': 'Úlohy',
  'WorkflowRow.createdDate': 'Poslední změna: {date}',
  'WorkflowPage.closeWorkflow': 'Ukončit workflow',
  'WorkflowPage.closeWorkflow.confirm': 'Opravdu chcete ukončit toto workflow?',
  'WorkflowDetailPage.AddCommentProcedure.button': 'Přidat připomínkové řízení',
  'WorkflowDetailPage.AddCommentProcedure.add': 'Přidat existující',
  'WorkflowDetailPage.AddCommentProcedure.addTitle': 'Přidat připomínkové řízení',
  'WorkflowDetailPage.AddCommentProcedure.addSuccess': 'Připomínkové řízení úspěšně přidáno',
  'WorkflowDetailPage.AddCommentProcedure.new': 'Vytvořit nové',
  'WorkflowDetailPage.AddCommentProcedure.newTitle': 'Přidat nové připomínkové řízení k workflow',
  'WorkflowDetailPage.addedRevisions.signingState.all': 'Všechny dokumenty',
  'WorkflowDetailPage.addedRevisions.signingState.signed': 'Dokumenty s přílohou podepsaný dokument',
  'WorkflowDetailPage.addedRevisions.signingState.unsigned': 'Dokumenty bez přílohy podepsaný dokument',
  'WorkflowDetailPage.addedRevisions.commentedState.all': 'Všechny dokumenty',
  'WorkflowDetailPage.addedRevisions.commentedState.commented': 'Dokumenty s přílohou dokument s komentáři',
  'WorkflowDetailPage.addedRevisions.commentedState.clean': 'Dokumenty bez přílohy dokument s komentáři',
  'WorkflowPageToolbar.newTemplate': 'Nová šablona',
  'WorkflowPageToolbar.newWorkflow': 'Spustit workflow',
  'WorkflowPageToolbar.saveTemplate': 'Export do souboru',
  'WorkflowTemplate.solver': 'Řešitel',
  'WorkflowTemplate.solvers': 'Řešitelé',
  'WorkflowTemplate.parallelBranchingSetting': 'Nastavení spuštění',
  'WorkflowTemplate.parallelBranchingSetting.options.runAll': 'Spustit všechny větve automaticky',
  'WorkflowTemplate.parallelBranchingSetting.options.runManually': 'Vybrat větve manuálně při spuštění uzlu',
  'WorkflowTemplate.parallelBranchingSetting.solverRoles': 'Řešitelé, kteří mohou zvolit paralelní větve',
  'WorkflowTemplate.solverRoles': 'Role řešitelů',
  'WorkflowTemplate.confirmTitle': 'Opravdu si přejete odejít?',
  'WorkflowTemplate.confirmMessage': 'Je možné, že provedené změny nebudou uloženy.',
  'WorkflowTemplate.parallelBranch.output': 'Větev',
  'WorkflowTemplateList.invalid': 'Nevalidní',
  'WorkflowTemplatePage.editTemplate': 'Upravit schéma',
  'WorkflowTemplatePage.saveSuccess': 'WF bylo uloženo',
  'WorkflowTemplatePage.saveError': 'WF se nepodařilo uložit.',
  'WorkflowTemplatePage.saveError.savedAsDraft': 'Šablona je nevalidní, ukládá se jako draft.',
  'WorkflowTemplatePage.copyError.saveAsDraft': 'Kopírovaná šablona je nevalidní, kopie se ukládá jako draft.',
  'WorkflowTemplatePage.copySuccess': 'Kopie WF byla uložena',
  'WorkflowTemplatePage.loading': 'Načítám detail šablony...',
  'WorkflowTemplatePage.forbiddenError': 'Nemáte potřebné oprávnění pro přístup k šablonám WF.',
  'WorkflowTemplatePage.forReadOnly': 'Šablona je pouze pro čtení. Nemáte potřebná oprávnění.',
  'WorkflowTemplatePage.duplicityNameError': 'Zadaný název má již jiná šablona',
  'WorkflowTemplatePage.errorHeader.description': 'Popis chyby',
  'WorkflowTemplatePage.errorHeader.severity': 'Závažnost chyby',
  'WorkflowTemplatePage.errorHeader.source': 'Zdroj chyby',
  'WorkflowTemplatePage.errorHeader.link': 'Odkaz',
  'WorkflowTemplatePage.error.goToNode': 'Přejít na úlohu',
  'WorkflowTemplatePage.error.sourceInTemplate': 'Šablona',
  'WorkflowTemplatePage.errorNames.WorkflowNotReachableNodeError': 'Úloha není připojena',
  'WorkflowTemplatePage.errorNames.WorkflowMultipleDocumentStateChangeError': 'Dokument mění opakovaně stav',
  'WorkflowTemplatePage.errorNames.WorkflowCircularDependencyError': 'Nekonečná smyčka',
  'WorkflowTemplatePage.errorNames.ReferencedWorkflowNodeNotExistError': 'Úloha neexistuje',
  'WorkflowTemplatePage.errorNames.WorkflowPortShouldHaveOneEdgeError':
    'Každý výstup musí mít právě jedno spojení. Přidejte spojení pro každý prázdný port.',
  'WorkflowTemplatePage.errorNames.WorkflowTailMustHaveNoNoOutputPortError': 'Úloha konec nemůže mít výstup',
  'WorkflowTemplatePage.errorNames.WorkflowNodeWithoutRoleError':
    'Na úloze chybí role. Přidejte alespoň 1 roli řešitele.',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidDocumentAliasError': 'Chybný alias dokumentu',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRoleError': 'Chybná role',
  'WorkflowTemplatePage.errorNames.WorkflowNodeDuplicateRoleError': 'Duplicitní role',
  'WorkflowTemplatePage.errorNames.WorkflowHeadShouldHaveOneOutputPortError': 'Úloha start musí mít jeden výstup',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateDuplicateNameError': 'Duplicitní název workflow',
  'WorkflowTemplatePage.errorNames.UserOrRoleCanBeSetTempError': 'Úloha může mít jen uživatele nebo roli',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidTailNodeError': 'Chybné výstupy úlohy',
  'WorkflowTemplatePage.errorNames.NestedSubWorkflowError': 'Chyba v paralelní větvi',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRequiredVotersCount':
    'Je nastaven malý počet hlasujících. Nastavte položku "Musí hlasovat" alespoň na 1.',
  'WorkflowTemplatePage.errorNames.SubworkflowsCountError': 'Paralelní větvení musí mít alespoň jednu větev',
  'WorkflowTemplatePage.errorNames.SubworkflowRequestedVoteCountError':
    'Požadovaný počet spouštěných větví neodpovídá počtu vytvořených větví',
  'WorkflowTemplatePage.errorNames.NotifyNodeShouldHaveMessageError': 'Úloha oznámení by měla mít zprávu.',
  'WorkflowTemplatePage.errorNames.OnlyNotifyNodeCanHaveMessageError': 'Pouze úloha oznámení může mít zprávu.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeMessageDuplicateuserError': 'Duplicitní uživatel jako adresát zprávy.',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateShouldHaveOneHeadError':
    'Šablona workflow může mít pouze jeden start uzel.',
  'WorkflowTemplatePage.errorNames.DocumentRequestsWithSameNameError':
    'Úloha obsahuje požadavky na podkladový dokument se stejným názvem.',
  'WorkflowTemplatePage.errorNames.AddRevisionInBadPossitionError':
    'Nahrání revize může být pouze před schválením nebo odmítnutím dokumentů.',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidUserError': 'Vybraný uživatel nebyl nalezen.',
  'WorkflowTemplatePage.errorNames.MessageSubjectEmptyError': 'Předmět zprávy nesmí být prázdný.',
  'WorkflowTemplatePage.errorNames.MessageMessageEmptyError': 'Text zprávy je prázdný.',
  'WorkflowTemplatePage.errorNames.MessageNoRecipientsError': 'Nejsou vybrání žádní adresáti zprávy.',
  'WorkflowTemplatePage.errorNames.WorkflowBDRoleWithoutUserError': 'Role podkladového dokumentu neobsahuje uživatele.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeRoleWithoutUserError': 'Role na úloze neobsahuje uživatele.',
  'WorkflowTemplatePage.signalWarning.title': 'Šablona workflow byla změněna',
  'WorkflowTemplatePage.signalWarning': 'Šablona workflow byla změněna uživatelem {user}. Přejete si přenačíst aktuální šablonu workflow nebo pokračovat v práci a přepsat při uložení aktuální šablonu? Při přenačtení budou provedené změny ztraceny.',
  'WorkflowTemplatePage.signalWarning.sameUser': 'Změnili jste tuto šablonu. Přejete si přenačíst aktuální šablonu workflow nebo pokračovat v práci a přepsat při uložení aktuální šablonu? Při přenačtení budou provedené změny ztraceny.',
  'WorkflowTemplatePage.signalWarning.continue': 'Pokračovat se starou verzí',
  'WorkflowTemplatePage.signalWarning.reload': 'Načíst aktuální verzi',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedActionsError': 'Na úloze není povolena žádná akce',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyDestinationDocumentStateError': 'Není nastaven nový stav dokumentu',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedStatesError': 'Povolený stav přidávaného dokumentu není nastaven na úloze ani na šabloně',
  'WorkflowTemplatePage.errorNames.WorkflowEmptyAddDocumentPermittedStatesError': 'Povolený stav přidávaného dokumentu není na šabloně nastaven',
  'WorkflowTemplateNodeDetail.workflowSettings': 'Nastavení workflow',
  'WorkflowTemplateNodeDetail.workflowSolver': 'Správce workflow',
  'WorkflowTemplateNodeDetail.defaultDirectories': 'Výchozí složky',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses': 'Výběr stavů vstupních dokumentů',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.workInProgress': 'Rozpracováno',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.shared': 'Sdíleno',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.published': 'Publikováno',
  'WorkflowTemplateNodeDetail.inputLabels': 'Výběr štítků',
  'WorkflowTemplateNodeDetail.requestedVotes': 'Musí schválit',
  'WorkflowTemplateNodeDetail.requestedVotes.tooltip': 'Požadovaný počet schválení',
  'WorkflowTemplateNodeDetail.requestedVoters': 'Musí hlasovat',
  'WorkflowTemplateNodeDetail.requestedVoters.tooltip': 'Požadovaný počet hlasujících',
  'WorkflowTemplateNodeDetail.requestedParallelBranches': 'Počet spuštěných paralelních větví',
  'WorkflowTemplateNodeDetail.requestedParallelBranches.tooltip': 'Požadovaný minimální počet spuštěných větví',
  'WorkflowTemplateNodeDetail.name': 'Název úlohy',
  'WorkflowTemplateNodeDetail.node': 'Úloha',
  'WorkflowTemplateNodeDetail.description': 'Popis úlohy',
  'WorkflowTemplateNodeDetail.duration': 'Termín',
  'WorkflowTemplateNodeDetail.noNodeSelected': 'Žádný uzel není vybraný!',
  'WorkflowTemplateNodeDetail.timeoutAction.title': 'Akce po vypršení termínu',
  'WorkflowTemplateNodeDetail.timeoutAction.wait': 'Žádná akce',
  'WorkflowTemplateNodeDetail.timeoutAction.selectDefault': 'Použít konkrétní výstup',
  'WorkflowTemplateNodeDetail.timeoutAction.closeWorkflow': 'Ukončit instanci workflow',
  'WorkflowTemplateNodeDetail.backgroundDocument.title': 'Podkladové dokumenty',
  'WorkflowTemplateNodeDetail.backgroundDocument.addButton': 'Přidat požadavek',
  'WorkflowTemplateNodeDetail.backgroundDocument.defineDefaultFolderTooltip':
    'Pro možnost přidat požadavek na podkladový dokument definujte výchozí složku',
  'WorkflowTemplateNodeDetail.backgroundDocument.addBackgroundDocumentTooltip':
    'Přidat požadavek na podkladový dokument',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsibleRolePopover': 'Odpovědná osoba (role)',
  'WorkflowTemplateNodeDetail.backgroundDocument.roleWithoutUser': 'Role bez uživatele',
  'WorkflowTemplateNodeDetail.backgroundDocument.modalTitle': 'Přidat požadavek na podkladový dokument',
  'WorkflowTemplateNodeDetail.documentNewState': 'Nový stav',
  'WorkflowTemplateNodeDetail.notification.messageTitle': 'Předmět zprávy',
  'WorkflowTemplateNodeDetail.notification.messageTitle.placeholder': 'Upozornění schvalovacího procesu Workflow',
  'WorkflowTemplateNodeDetail.notification.messageBody': 'Text zprávy',
  'WorkflowTemplateNodeDetail.notification.recipients': 'Adresáti zprávy',
  'WorkflowTemplateNodeDetail.notification.recipients.description':
    'Není vybrána žádná role ani uživatel jako adresát.',
  'WorkflowTemplateNodeDetail.notification.recipients.roles': 'Role jako adresáti',
  'WorkflowTemplateNodeDetail.notification.recipients.users': 'Uživatelé jako adresáti',
  'WorkflowTemplateNodeDetail.notification.settingsTitle': 'Nastavení zprávy',
  'WorkflowTemplateNodeDetail.permittedActions.title': 'Povolené akce na úloze',
  'WorkflowTemplateNodeDetail.permittedActions.removeDocument': 'Odebrání dokumentu',
  'WorkflowTemplateNodeDetail.permittedActions.addDocument': 'Přidání dokumentu',
  'WorkflowTemplateNodeDetail.permittedActions.signDocument': 'Podepsání dokumentu',
  'WorkflowTemplateNodeDetail.permittedActions.addRevision': 'Přidání revize dokumentu',
  'WorkflowTemplateNodeDetail.permittedActions.stateFromNode': 'Jiné než výchozí nastavení stavů přidávaných dokumentů/revizí',
  'WorkflowTemplateNodeDetail.permittedActions.stateWorkInProgress': 'Rozpracováno',
  'WorkflowTemplateNodeDetail.permittedActions.stateShared': 'Sdíleno',
  'WorkflowTemplateNodeDetail.permittedActions.statePublish': 'Publikováno',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle': 'Název požadavku',
  'WorkflowTemplateAddBackgroundDocumentForm.requestDescription': 'Popis požadavku',
  'WorkflowTemplateNodeDetail.backgroundDocument.date': `{count, plural,
    =0 {Termín: # dní}
    one {Termín: # den}
    few {Termín: # dny}
    other {Termín: # dní}
  } `,
  'WorkflowTemplateAddBackgroundDocumentForm.folder': 'Složka pro nahrání dokumentů',
  'WorkflowTemplateAddBackgroundDocumentForm.responsiblePerson': 'Odpovědná osoba (role)',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate': 'Požadovaný termín (dny)',

  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.required': 'Prosím vyplňte název požadavku',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.duplicateName':
    'Tento název je již použitý u jiného požadavku',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate.required': 'Prosím vyplňte požadovaný termín',
  'WorkflowTemplateAddBackgroundDocumentForm.folder.required': 'Prosím vyberte složku pro nahrání dokument',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsiblePerson.required': 'Prosím vyberte zodpovědnou roli',
  'WorkflowTemplateNodeDetail.otherPortsName': 'Výstup',
  'WorkflowTemplateErrors.severity.Error': 'Chyba',
  'WorkflowTemplateErrors.severity.Warning': 'Varování',
  'WorkflowTemplateExportFormModal.title': 'Export šablon',
  'WorkflowTemplateListPageToolbar.export': 'Exportovat šablony',
  'WorkflowTemplateExportModal.submitButtonText': 'Exportovat šablony',
  'WorkflowTemplateExportButton.tooltip': 'Exportovat šablonu',
  'WorkflowTemplateExportForm.label': `{count, plural,
    one {Exportovat 1 vybranou šablonu do JSON souboru}
    few {Exportovat # vybrané šablony do JSON souboru}
    other {Exportovat # vybraných šablon do JSON souboru}
  }`,
  'WorkflowTemplateExportForm.nameLabel': 'Jméno souboru',
  'WorkflowTemplateExportForm.archiveNameRules': 'Prosím zadejte název souboru',
  'WorkflowTemplateCopyCreateFormModal.formTitle': 'Vytvoření kopie šablony',
  'WorkflowTemplateCopyCreateFormModal.submitButtonText': 'Vytvořit kopii',
  'WorkflowTemplateCopyCreateForm.copyInformation': 'Vytváříte kopii šablony: ',
  'WorkflowTemplateCopyCreateForm.copyName': 'Název kopie',
  'WorkflowTemplateCopyCreateForm.alreadyExistName': 'Zadaný název je již použitý, zvolte prosím jiný název',
  'WorkflowTemplateCopyCreateForm.description': 'Popis',
  'WorkflowTemplatePage.saveAsCopy.buttonText': 'Vytvořit kopii',
  WorkflowTemplateExportDefaultFilename: 'Šablony',
  'AllDocumentsPageFilterToolbar.WorkflowBackgroundDocumentFilterLabel': 'Název úlohy',
  'WorkflowTemplateCheckImportNamesAndRolesFormModal.title':
    'Nastavení názvů, rolí a výchozích složek importovaných šablon',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle': 'Název šablony',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.required': 'Vyplňte prosím název šablony',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.alreadyExist': 'Již existuje šablona tohoto názvu',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.dontDuplicate': 'Název šablony musí být jedinečný',
  'WorkflowTemplateCheckImportNamesAndRolesForm.roleAssignment': 'Přiřazení rolí',
  'WorkflowTemplateCheckImportNamesAndRolesForm.userAssignment': 'Přiřazení uživatelů',
  'WorkflowTemplateCheckImportNamesAndRolesForm.checkRecipients': 'Zkontrolujte adresáty na úloze Upozornění.',
  'WorkflowTemplateCheckImportNamesAndRolesForm.defaultDirectories': 'Výchozí složky',
  'WorkflowTemplateListPageToolbar.delete.confirm': 'Opravdu chcete tyto šablony smazat?',
  'WorkflowTemplateListPageToolbar.jsonFilesOnly': 'Lze vložit jen JSON soubor',
  'WorkflowTemplateListPageToolbar.checkImport.noValidFile': 'Nezdařila se validace importované šablony',
  'WorkflowTemplateListPageToolbar.checkImport.noEmptyFile': 'Importovaný soubor neobsahuje žádnou šablonu',
  'WorkflowTemplateListPageToolbar.checkImport.importFailed': 'Import se nezdařil',
  'WorkflowTemplateListPageToolbar.checkImport.importButtonText': 'Importovat šablonu',
  'WorkflowTemplateListPageToolbar.checkImport.modalLabel': 'Importovat šablony (*.json)',
  'WorkflowTemplate.DocumentStateSetting.placeholder': 'Vyberte nový stav dokumentu',
  'WorkflowSubWorkflowsSettings.title': 'Paralelní větve',
  'WorkflowSubWorkflowsSettings.addBranch': 'Přidat Větev',
  'WipRecordDetailDataProvider.commonZP.title': 'Detail společného ZP',
  'NodeOutputSettings.outputs': 'Výstupy',
  'NodeOutputSettings.addOutput': 'Přidat výstup',
  'ZBVDetail.Title': 'Detail změnového listu',
  'ZbvDetail.additionalInformation': 'Doplňující údaje',
  'ZbvDetail.overview': 'Přehled',
  'ZbvDetailAdditionalInformation.responsiblePersons': 'Zodpovědné osoby',
  'ZbvDetailAdditionalInformation.contractor': 'Dodavatel (stavbyvedoucí)',
  'ZbvDetailAdditionalInformation.employee.priceNegotiation': 'Zaměstnanec (cenové projednání)',
  'ZbvDetailAdditionalInformation.designer': `Projektant (autorský dozor)`,
  'ZbvDetailAdditionalInformation.employee.statementOnZBV': '  Zaměstnanec (vyjádření k ZBV)',
  'ZbvDetailAdditionalInformation.constructionSupervision': '  Stavební dozor',
  'ZbvDetailAdditionalInformation.employeeFunctions': 'Funkce zaměstnance (vyjádření)',
  'ZbvDetailAdditionalInformation.constructionManager': 'Správce stavby',
  'ZbvDetailAdditionalInformation.authorizedPerson': 'Oprávněná osoba',
  'ZbvDetailAdditionalInformation.supervision': 'Supervize',
  'ZbvDetailAdditionalInformation.justification': 'Zdůvodnění',
  'ZbvDetailAdditionalInformation.descriptionOfChanges': 'Popis změny',
  'ZbvDetailOverview.additionalInformation': 'Doplňující údaje',
  'ZbvDetailOverview.approval': 'Schválení',
  'ZbvDetailOverview.changeOrderClassification': 'Zařazení ZBV',
  'ZbvDetailOverview.chart.changedItems': 'Změněné položky',
  'ZbvDetailOverview.chart.newItems': 'Nové položky',
  'ZbvDetailOverview.code': 'Značka',
  'ZbvDetailOverview.contractualAmendment': 'Smluvní dodatek',
  'ZbvDetailOverview.valorization': 'Valorizační ZBV',
  'ZbvDetailOverview.graf.newItems': 'Nové položky',
  'ZbvDetailOverview.graf.changedItems': 'Změněné položky',
  'ZbvDetailOverview.grouping': 'Zařazení do skupin',
  'ZbvDetailOverview.issue': 'Vystavení',
  'ZbvDetailOverview.lessWork': 'Méněpráce',
  'ZbvDetailOverview.moreWork': 'Vícepráce',
  'ZbvDetailOverview.name': 'Název',
  'ZbvDetailOverview.price': 'Cena',
  'ZbvDetailOverview.pricesWithVAT': 'Ceny s DPH',
  'ZbvDetailOverview.overview': 'Přehled',
  'ZbvDetailOverview.recapitulation': 'Rekapitulace',
  'ZbvDetailOverview.taxBase': 'Základ daně',
  'ZbvDetailOverview.totalPrice': 'Cena celkem',
  'ZbvDetailOverview.type': 'Typ',
  'ZbvDetailOverview.unclassified': 'Nezařazené',
  'ZbvDetailOverview.PricesWithVAT': 'Ceny s DPH',
  'ZbvDetailOverview.rateDPH': '{percent} %',
};

export type IntlMessagesShape = typeof csMessages;

export type IntlMessageId = keyof IntlMessagesShape;

export const isIntlMessageId = (key: string): key is IntlMessageId => {
  return key in csMessages;
};
