import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { uniq } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ignoreRef } from 'utils';
import { DEFAULT_ACTIVE_PERMISSIONS, PermissionsDrawer } from './PermissionsDrawer';

type Props = {
  value?: ExtendedPermissionEnum[];
  onChange?: (permissions: ExtendedPermissionEnum[]) => void;
};

const PermissionsDrawerComponent: FunctionComponent<Props> = ({ value, onChange }) => {
  const handlePermissionChange = useCallback(
    async (permission: ExtendedPermissionEnum, checked: boolean) => {
      onChange &&
        onChange(!!checked ? uniq([...value, permission]) : value.filter((current) => current !== permission));
    },
    [onChange, value]
  );

  const selectedUserPermissionsSet = useMemo(() => new Set(!!value ? value : DEFAULT_ACTIVE_PERMISSIONS), [value]);

  return (
    <PermissionsDrawer
      userPermissionsSet={selectedUserPermissionsSet}
      handlePermissionChange={handlePermissionChange}
    />
  );
};

export const PermissionsDrawerFormItem = ignoreRef(PermissionsDrawerComponent);
