import { DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesFilteringTree } from 'components/DirectoriesTree/DirectoriesFilteringTree';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { directoryAncestryIds } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { directoryConnectedMapSelector } from 'store/selectors';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import {
  ConnectedDirectory,
  DirectoryNode,
  DirectoryNodeType,
  directoryNodeHelpers,
} from 'utils/typeMappings/directories/directoryTypes';
import { DocumentSelectDocumentState } from './DocumentSelect';

type ProcessedDirectories = Record<Guid, { directory: DirectoryListDto; self: number; total: number }>;

const contextMenu = () => <></>; // unused?

const countDirectories = (usedDirectories: Guid[]) => {
  return usedDirectories.reduce(
    (prev, curr) => ({ ...prev, [curr]: (prev[curr] || 0) + 1 }),
    {} as Record<Guid, number>
  );
};

const processDirectories = (
  counts: ProcessedDirectories,
  directoryTree: DirectoryNode,
  directoriesCount: Record<Guid, number>
) => {
  if (!directoryTree || directoryTree.type !== DirectoryNodeType.Directory) {
    return 0;
  }
  const directory = directoryTree.directory;

  const selfFiles = directoriesCount[directory.id] || 0;
  const totalFiles: number =
    selfFiles + directory.children.reduce((prev, curr) => prev + processDirectories(counts, curr, directoriesCount), 0);
  counts[directory.id] = { directory, self: selfFiles, total: totalFiles };
  return totalFiles;
};

type Props = {
  startDirectoryId?: Guid;
  selectedKeys: DirectoryNodeKey[];
  setSelectedKeys: React.Dispatch<React.SetStateAction<DirectoryNodeKey[]>>;
  directoryRoot: ConnectedDirectory;
  selectedDocuments: DocumentSelectDocumentState[];
  // multiple?: boolean; // not yet supported
};

export const DirectoryList: FunctionComponent<Props> = ({
  startDirectoryId,
  selectedKeys,
  setSelectedKeys,
  directoryRoot,
  selectedDocuments,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<DirectoryNodeKey[]>(() =>
    directoryRoot ? [directoryNodeHelpers.directoryKey(directoryRoot.id)] : []
  );

  // set expanded keys to starting directory
  const directoriesById = useSelectorDispatch(directoryConnectedMapSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  useEffect(() => {
    if (directoriesById !== null && startDirectoryId !== undefined) {
      const parrent = directoriesById[startDirectoryId]?.parent;
      setExpandedKeys(directoryAncestryIds(parrent).map(directoryNodeHelpers.directoryKey));
    }
  }, [directoriesById, startDirectoryId]);

  // calculate number of selected items in directories
  const processedDirectories = useMemo(() => {
    const counts: ProcessedDirectories = {};
    selectedDocuments &&
      processDirectories(
        counts,
        directoryNodeHelpers.directoryNode(directoryRoot),
        countDirectories(selectedDocuments.map((e) => e.directoryId))
      );
    return counts;
  }, [directoryRoot, selectedDocuments]);

  const tooltipRenderer = useCallback(
    (dir: ConnectedDirectory) => {
      if (processedDirectories?.[dir.id]?.total > 0) {
        return (
          <>
            <Fmt id="DocumentSelect.selectedInThisDirectory" values={{ count: processedDirectories[dir.id].self }} />
            <br />
            <Fmt id="DocumentSelect.selectedInSubDirectories" values={{ count: processedDirectories[dir.id].total }} />
          </>
        );
      } else {
        return null;
      }
    },
    [processedDirectories]
  );

  const nameRenderer = useCallback(
    (dir: DirectoryNode, name: ReactNode) => {
      if (dir.type === DirectoryNodeType.Directory && processedDirectories?.[dir.directory.id]?.total > 0) {
        const processedDir = processedDirectories[dir.directory.id];
        return (
          <>
            {name} {`(${processedDir.self}, ${processedDir.total})`}
          </>
        );
      } else {
        return name;
      }
    },
    [processedDirectories]
  );

  const [searchPhrase, setSearchPhrase] = useState('');
  const [showFavorites, setShowFavorites] = useState(false);

  return (
    <DirectoriesFilteringTree
      directoryRoot={directoryRoot}
      searchPhrase={searchPhrase}
      setSearchPhrase={setSearchPhrase}
      showFavorites={showFavorites}
      setShowFavorites={setShowFavorites}
      expandedKeys={expandedKeys}
      contextMenu={contextMenu}
      onExpand={setExpandedKeys}
      selectedKeys={selectedKeys}
      onSelect={setSelectedKeys}
      tooltipRenderer={tooltipRenderer}
      nameRenderer={nameRenderer}
    />
  );
};
