import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Alert, Button, Divider, Progress, Typography } from 'antd';
import { Margin } from 'components/Margin/Margin';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import StackPanel from 'components/StackPanel';
import { SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useCallback, useEffect, useState } from 'react';
import { getSignatures } from './RevisionSignedDocument.utils';

export type RevisionSignedDocumentFileCreateFormData = {
  signedDocument: File;
};

type Props = FormComponentProps<RevisionSignedDocumentFileCreateFormData> &
  InjectedIntlProps & {
    signedDocumentLoading: boolean;
    signedDocumentBlob?: Blob;
    handleSignedFiledDownload: () => void;
    setSignedFileAnalyzed: (isAnalyzed: boolean) => void;
  };

const RevisionSignedDocumentFileCreateForm = React.forwardRef<unknown, Props>(
  (
    { intl, form, signedDocumentLoading, signedDocumentBlob, handleSignedFiledDownload, setSignedFileAnalyzed },
    ref
  ) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const { getFieldDecorator } = form;

    const [analyzeProgress, setAnalyzeProgress] = useState<number>(undefined);
    const [showInvalidFileType, setShowInvalidFileType] = useState<boolean>();
    const [showMissingSignatureWarning, setMissingSignatureWarning] = useState<boolean>();
    const [showUnexpectedCountWarning, setUnexpectedCountWarning] = useState<boolean>();

    const compareSignatures = useCallback(
      async (signedFile: File) => {
        if (!signedDocumentBlob) {
          setAnalyzeProgress(100);
          setSignedFileAnalyzed(true);
          return;
        }
        setAnalyzeProgress(1);
        const fileBuffer = await signedFile.arrayBuffer();
        setAnalyzeProgress(50);
        const originalSignedFileBuffer = await signedDocumentBlob.arrayBuffer();
        const newSignatures = getSignatures(Buffer.from(fileBuffer));
        const existingSignatures = getSignatures(Buffer.from(originalSignedFileBuffer));

        setMissingSignatureWarning(
          existingSignatures.some(
            (signature) =>
              !newSignatures.find((newSignature) => newSignature.signatureString === signature.signatureString)
          )
        );
        setUnexpectedCountWarning(newSignatures.length <= existingSignatures.length);
        setAnalyzeProgress(100);
        setSignedFileAnalyzed(true);
      },
      [setSignedFileAnalyzed, signedDocumentBlob]
    );

    useEffect(() => {
      const signedDocument: File = form.getFieldValue('signedDocument');
      setSignedFileAnalyzed(false);
      if (signedDocument) {
        void compareSignatures(signedDocument);
      } else {
        setAnalyzeProgress(undefined);
        setMissingSignatureWarning(false);
        setUnexpectedCountWarning(false);
      }
    }, [compareSignatures, form.getFieldValue('signedDocument')]);

    const handleInvalidFileDrop = useCallback(() => {
      setShowInvalidFileType(true);
      setTimeout(() => setShowInvalidFileType(false), 3000);
    }, []);

    return (
      <Form layout="vertical">
        <StackPanel justifyContent="splitContent">
          <Typography.Paragraph>
            <Fmt id="RevisionSignedDocumentAddFormModal.downloadSignedDocumentMessage" />
          </Typography.Paragraph>
          <Button
            type="primary"
            loading={signedDocumentLoading}
            disabled={!signedDocumentBlob}
            onClick={handleSignedFiledDownload}
          >
            <Fmt id="general.download" />
          </Button>
        </StackPanel>
        <Divider />
        <Form.Item>
          {getFieldDecorator('signedDocument')(
            <SingleFileInput
              inputText="SignedDocumentInput.collapse.text"
              acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
              onInvalidFileDrop={handleInvalidFileDrop}
            />
          )}
        </Form.Item>
        {analyzeProgress && (
          <Margin bottom>
            <StackPanel vertical>
              {analyzeProgress === 100 ? (
                <Fmt id="RevisionSignedDocumentAddFormModal.analyzeFinished" />
              ) : (
                <Fmt id="RevisionSignedDocumentAddFormModal.analyzeRunning" />
              )}
              <Progress percent={analyzeProgress} />
            </StackPanel>
          </Margin>
        )}
        {showMissingSignatureWarning ? (
          <Margin bottom>
            <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.missingSignatureWarning" />} type="warning" />
          </Margin>
        ) : (
          showUnexpectedCountWarning && (
            <Margin bottom>
              <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.unexpectedCountWraning" />} type="warning" />
            </Margin>
          )
        )}
        {showInvalidFileType && (
          <Margin bottom>
            <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.invalidFileType" />} type="error" />
          </Margin>
        )}
        {!!signedDocumentBlob && (
          <Margin bottom>
            <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.replaceNotification" />} type="warning" />
          </Margin>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(RevisionSignedDocumentFileCreateForm);
