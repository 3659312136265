import { BASE_NAME, DEBUG, OIDC_CLIENT_ID, OIDC_PROVIDER } from 'config/env';
import Oidc, { InMemoryWebStorage, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

let baseURL = `${window.location.protocol}//${window.location.hostname}`;
if (window.location.port !== '') baseURL += `:${window.location.port}`;
baseURL += BASE_NAME;

if (DEBUG) {
  Oidc.Log.level = Oidc.Log.DEBUG;
  Oidc.Log.logger = console;
}

const config: UserManagerSettings = {
  client_id: OIDC_CLIENT_ID,
  redirect_uri: `${baseURL}/oidc/callback/redirect`,
  popup_redirect_uri: `${baseURL}/oidc/callback/popup`,
  response_type: 'id_token token',
  scope: 'openid profile email aspehub_api',
  authority: OIDC_PROVIDER,
  post_logout_redirect_uri: `${baseURL}/authorize`,
  silent_redirect_uri: `${baseURL}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
};

// create a user manager instance
export const userManager = new UserManager(config);
