import { userManager } from 'config';
import { User } from 'oidc-client';
import LoadingPage from 'pages/LoadingPage';
import LoginErrorPage from 'pages/LoginErrorPage';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

const ACCESS_DENIED_ERROR_TYPE = 'access_denied';

const OidcRedirectCallbackPage: FunctionComponent<Props> = ({ history }) => {
  const [displayError, setDisplayError] = useState<boolean>(false);

  // define a success callback which receives the signed in user & handles redirection
  // NOTE: this example uses react-router-redux,
  // but any other routing library should work the same
  const successCallback = (user: User) => {
    // the user object gets the browser's URL before
    // redirection was triggered passed into its state
    // when triggerAuthFlow is set to `true`
    history.replace(user.state.redirectUrl);
  };

  const errorCallback = (err: Error) => {
    console.error('OIDC Callback Error', err);

    if (err.message === ACCESS_DENIED_ERROR_TYPE) {
      setDisplayError(true);
    }
  };

  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then((user) => successCallback(user))
      .catch((error) => errorCallback(error));
  }, []);

  return !displayError ? <LoadingPage /> : <LoginErrorPage />;
};

export default OidcRedirectCallbackPage;
