import { message } from 'antd';
import { api } from 'api';
import { DirectoryReportSettingsDto } from 'api/completeApiInterfaces';
import Axios, { CancelToken } from 'axios';
import { useIntl } from 'hooks';
import { MutableRefObject, useCallback, useState } from 'react';
import { processApiError } from 'utils';

export const useDirectoryReportExport = (
  directory: { id: Guid; name: string },
  extension: string = 'xlsx',
  isMounted?: MutableRefObject<boolean>,
  ct?: CancelToken
) => {
  const [loading, setLoading] = useState(false);
  const [exportFormVisible, setExportFormVisible] = useState(false);
  const intl = useIntl();

  const onOpen = useCallback(async () => {
    if (!isMounted || isMounted.current) setExportFormVisible(true);
  }, []);

  const onCancel = useCallback(async () => {
    if (!isMounted || isMounted.current) setExportFormVisible(false);
  }, []);

  const exportDirectoryReport = useCallback(
    async (directoryId: Guid, settings: DirectoryReportSettingsDto, directoryName: string) => {
      const [err, res] = await api.project.directories.exportDirectoryReport(directoryId, settings, ct);

      if (err && !Axios.isCancel(err) && (!isMounted || isMounted.current)) {
        processApiError(err, (error) => {
          message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
        });
      }

      if (res) {
        const blob = new Blob([res.data], { type: 'application/ms-excel' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${directoryName}.${extension}`;
        link.href = url;
        link.click();
      }
    },
    [intl, extension]
  );

  const onSubmit = useCallback(
    async (settings: DirectoryReportSettingsDto) => {
      setLoading(true);
      await exportDirectoryReport(directory.id, settings, directory.name);

      if (!isMounted || isMounted.current) {
        setExportFormVisible(false);
        setLoading(false);
      }
    },
    [directory, exportDirectoryReport]
  );

  return [onOpen, onSubmit, onCancel, exportFormVisible, loading] as const;
};
