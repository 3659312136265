import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './LinkUnderscored.module.less';

type Props = LinkProps & {
  className?: string;
};

export const LinkUnderscored: FunctionComponent<Props> = ({ children, className, ...params }) => {
  return (
    <Link {...params} className={classNames(styles.link, className)}>
      {children}
    </Link>
  );
};
