import TextArea from 'antd/lib/input/TextArea';
import { apiConstraints } from 'api/completeApiConstraints';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import { AssignmentData, AssignmentDataAction } from '../AssignmentDetail';

type Props = {
  canEdit: boolean;
  assignment: AssignmentData;
  dispatchAssignment: React.Dispatch<AssignmentDataAction>;
};

export const AssignmentContentField: FunctionComponent<Props> = ({ canEdit, assignment, dispatchAssignment }) => {
  const intl = useIntl();

  return (
    <TextArea
      value={assignment.content}
      onChange={(event) => dispatchAssignment({ type: 'setContent', content: event.target.value })}
      readOnly={!canEdit}
      placeholder={intl.formatMessage({ id: 'general.description' })}
      maxLength={apiConstraints.assignmentPatchDto.content.maxLength}
      rows={4}
    />
  );
};
