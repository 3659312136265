import { produce } from 'immer';
import { uniq } from 'lodash';
import { AllDocumentsPageStoreModel, AllDocumentsPageStoreModelState } from '../storeModelinterfaces';

const defaults: AllDocumentsPageStoreModelState = {
  lastSelectedDirectoryKey: undefined,
  directoryTreeExpandedKeys: [],
  backupExpandedKeys: [],
  showFavorite: false,
  directoryTreeSearch: '',
  dirsSectionWidth: 300,
};

export const allDocumentsPageStoreModel: AllDocumentsPageStoreModel = {
  state: defaults,
  reducers: {
    clearData: (_state) => {
      return defaults;
    },
    setLastDirectoryNodeKey: (state, { directoryNodeKey }) =>
      produce(state, (draft) => {
        draft.lastSelectedDirectoryKey = directoryNodeKey;
      }),
    directoryTreeCollapse: (state, { keys }) =>
      produce(state, (draft) => {
        draft.directoryTreeExpandedKeys = state.directoryTreeExpandedKeys.filter((key) => {
          return !keys.includes(key);
        });
      }),
    directoryTreeExpand: (state, { keys }) =>
      produce(state, (draft) => {
        draft.directoryTreeExpandedKeys = uniq([...draft.directoryTreeExpandedKeys, ...keys]);
      }),
    directoryTreeExpandWithSearched: (state, { keys }) =>
      produce(state, (draft) => {
        draft.directoryTreeExpandedKeys = uniq([...draft.directoryTreeExpandedKeys, ...keys]);
        draft.backupExpandedKeys = uniq([...draft.backupExpandedKeys, ...keys]);
      }),
    setDirectoryTreeExpandedKeys: (state, { keys }) =>
      produce(state, (draft) => {
        draft.directoryTreeExpandedKeys = keys;
      }),
    setDirectoryTreeSearch: (state, { term }) =>
      produce(state, (draft) => {
        draft.directoryTreeSearch = term;
        if (state.directoryTreeSearch === '' && term !== '') {
          draft.backupExpandedKeys = [...draft.directoryTreeExpandedKeys];
        } else if (state.directoryTreeSearch !== '' && term === '') {
          draft.directoryTreeExpandedKeys = [...draft.backupExpandedKeys];
        }
      }),
    setDirsSectionWidth: (state, { width }) =>
      produce(state, (draft) => {
        draft.dirsSectionWidth = width;
      }),
    setShowFavorite: (state, favorite) =>
      produce(state, (draft) => {
        draft.showFavorite = favorite.isFavorite;
      }),
  },
};
