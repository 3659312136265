import { CaretRightOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { DEBUG, NODE_ENV } from 'config/env';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  error: ServiceError | null;
  className?: string;
  style?: React.CSSProperties;
  noFlex?: boolean;
  closable?: boolean;
};

const ServiceErrorBox: FunctionComponent<Props> = ({ error, noFlex, ...restProps }) => {
  let detail = null;
  if (NODE_ENV === 'development' || DEBUG) {
    detail = <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const [expanded, setExpanded] = useState(false);

  if (!error) {
    return null;
  }

  return (
    <Alert
      style={{ margin: '0 0 auto', flex: noFlex ? 'none' : 1 }}
      message={
        <div onClick={() => setExpanded((expanded) => !expanded)} style={!!detail ? { cursor: 'pointer' } : undefined}>
          <Fmt id={`general.error`} /> {detail && <CaretRightOutlined rotate={expanded ? 90 : 0} />}
        </div>
      }
      description={
        <>
          {error && (error.referenceErrorCode || !error.message) ? (
            <Fmt id={`serviceError.${error.referenceErrorCode}`} />
          ) : (
            error.message
          )}
          {expanded && detail && <div>{detail}</div>}
        </>
      }
      type="error"
      showIcon
      banner
      {...restProps}
    />
  );
};

export default React.memo(ServiceErrorBox);
