import classNames from 'classnames';
import SpinBoxCenter from 'components/SpinBoxCenter';
import React, { FunctionComponent } from 'react';
import styles from './ImageBox.module.less';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  loading?: boolean;
  spinSize?: 'small' | 'default' | 'large';
};

const ImageBox: FunctionComponent<Props> = ({ loading = false, spinSize, className, children, ...restProps }) => {
  return (
    <div className={classNames(styles.box, className)} {...restProps}>
      <SpinBoxCenter size={spinSize} spinning={loading} wrapperClassName={styles.spin}>
        {children ? children : <div />}
      </SpinBoxCenter>
    </div>
  );
};

export default ImageBox;
