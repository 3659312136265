import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import React, { FunctionComponent, ReactNode } from 'react';
import { DisabledWithReason } from 'utils/types';

type Props = {
  onReinvite: () => void;
  loading?: boolean;
  disabled?: DisabledWithReason;
  tooltip?: ReactNode;
  shape?: 'circle' | 'default';
  type?: 'primary' | 'default' | 'dashed' | 'link' | 'text';
};

export const ReinviteButton: FunctionComponent<Props> = ({
  onReinvite,
  loading,
  disabled,
  tooltip,
  type = 'primary',
  shape,
  children,
}) => {
  return (
    <CommonHubTooltip
      title={typeof disabled === 'string' ? disabled : tooltip}
      placement="leftTop"
      mouseEnterDelay={0.8}
    >
      <Button
        type={type}
        shape={shape}
        icon={<RedoOutlined />}
        size="small"
        disabled={!!disabled}
        loading={loading}
        onClick={onReinvite}
      >
        {children}
      </Button>
    </CommonHubTooltip>
  );
};
