export type XmlDateTime = string & {
  /** @deprecated This is only a type marker, the property will not exist on the string at runtime */
  _isXmlDateTime: true;
};

export const parseXmlBoolean = (value: string): boolean => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};
export const parseXmlBooleanArray = (value: [string]): boolean => {
  return parseXmlBoolean(value?.[0]);
};

export const parseXmlInteger = (value: string): number => {
  const parsed = parseInt(value);
  return isNaN(parsed) ? undefined : parsed;
};

export const parseXmlIntegerArray = (value: [string]): number => {
  return parseXmlInteger(value?.[0]);
};

export const parseXmlStringArray = (value: [string]): string => {
  return value?.[0];
};

export const parseXmlFloat = (value: string): number => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? undefined : parsed;
};
export const parseXmlFloatArray = (value: [string]): number => {
  return parseXmlFloat(value?.[0]);
};

export const parseXmlDateTime = (value: XmlDateTime): IsoDateTime => {
  return value;
};
export const parseXmlDateTimeArray = (value: [XmlDateTime]): IsoDateTime => {
  return parseXmlDateTime(value?.[0]);
};

export const encodeXmlBoolean = (value: boolean): string => {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return undefined;
};
export const encodeXmlBooleanArray = (value: boolean): [string] => {
  return value == undefined ? undefined : [encodeXmlBoolean(value)];
};

export const encodeXmlInteger = (value: number): string => {
  return value?.toString();
};
export const encodeXmlIntegerArray = (value: number): [string] => {
  // TODO: fix problem with unset property properly
  return value == undefined ? undefined : [encodeXmlInteger(value)];
};

export const encodeXmlFloat = (value: number): string => {
  return value?.toString(); //TODO: somthing is broken in the format
  //return value.toPrecision(10);
};
export const encodeXmlFloatArray = (value: number): [string] => {
  return value == undefined ? undefined : [encodeXmlFloat(value)];
};

export const encodeXmlDateTime = (value: IsoDateTime): XmlDateTime => {
  return value as XmlDateTime;
};
export const encodeXmlDateTimeArray = (value: IsoDateTime): [XmlDateTime] => {
  return value == undefined ? undefined : [encodeXmlDateTime(value)];
};

export const encodeXmlStringArray = (value: string): [string] => {
  return value ? [value] : ([] as any);
};

export function clearUndefinedProperties<T extends Record<string, unknown>>(obj: T): T {
  const clone = { ...obj };
  Object.keys(clone).forEach((key) => {
    if (clone[key] == undefined) {
      delete clone[key];
    }
  });
  return clone;
}

export const checkIsGuid = (value: string) => {
  const guidMatch = new RegExp('^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$');
  return guidMatch.test(value);
};
