import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';

export interface AdministrationMetadataFormData {
  onlyCurrentRevisions: boolean;
}

type Props = FormComponentProps<AdministrationMetadataFormData> & InjectedIntlProps & { disabled: boolean };

const AdministrationMetadataForm = React.forwardRef<unknown, Props>(({ form, disabled }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);
  return (
    <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Form.Item>
        {form.getFieldDecorator<AdministrationMetadataFormData>('onlyCurrentRevisions', {
          initialValue: true,
        })(
          <Radio.Group disabled={disabled}>
            <Radio style={{ display: 'block' }} value={true}>
              <Fmt id="Administration.downloadDMSMetaDataModal.formRadio.actualRevisions" />
            </Radio>
            <Radio style={{ display: 'block' }} value={false}>
              <Fmt id="Administration.downloadDMSMetaDataModal.formRadio.allRevisions" />
            </Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Form>
  );
});

export default Form.create<Props>()(AdministrationMetadataForm);
