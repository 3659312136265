import { Checkbox, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import DisplayName from 'components/DisplayName';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { FilterDisplay } from 'components/filters/render/FilterDisplay/FilterDisplay';
import SearchInput from 'components/SearchInput';
import { useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { sum, uniq } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  checkArray,
  checkBoolean,
  checkObject,
  checkString,
  deepCompareValues,
  ObjectChecker,
  smartFilter,
} from 'utils';
import styles from './SelectFilter.module.less';

export type OptionKey = string;

export type IOption<K extends OptionKey> = {
  id: K;
  title: string;
  label?: ReactNode;
};

export type SelectFilterValue<K extends OptionKey> = {
  values: K[];
  useAndOperator: boolean;
  notSet: boolean;
};

export const SELECT_IS_EMPTY = (value: SelectFilterValue<OptionKey>) => !value.values.length && !value.notSet;
export const SELECT_DEFAULT_VALUE = <K extends OptionKey>(values: K[]): SelectFilterValue<K> => ({
  values,
  useAndOperator: false,
  notSet: false,
});
export const SELECT_CLEARED_VALUE = <T extends SelectFilterValue<OptionKey>>(value: T): T => ({
  ...value,
  values: [],
  notSet: false,
});
export const SELECT_CHECK_FORMAT: ObjectChecker<SelectFilterValue<OptionKey>> = {
  values: checkArray(checkString),
  useAndOperator: checkBoolean,
  notSet: checkBoolean,
};

const DEFAULT_NOT_SET_LABEL = <Fmt id="general.notSet" />;

type Props<K extends OptionKey> = {
  label: ReactNode;
  title?: ReactNode;
  value: SelectFilterValue<K>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<K>>>;
  options: IOption<K>[];
  enableAndOrOperator?: boolean;
  allowNotSet?: boolean;
  notSetLabel?: ReactNode;
  inline?: boolean;
  selectAllOnClear?: boolean;
  renderSelectedLabel?: (selectedOptions: Set<K>) => ReactNode;
  additionalOptions?: ReactNode;
  showSearchMinItems?: number;
  searchItemsPlaceholder?: string;
};

export function SelectFilter<K extends OptionKey>({
  label,
  title,
  value,
  onChange,
  options,
  enableAndOrOperator,
  allowNotSet,
  notSetLabel = DEFAULT_NOT_SET_LABEL,
  inline,
  selectAllOnClear,
  renderSelectedLabel,
  additionalOptions,
  searchItemsPlaceholder,
  showSearchMinItems = 5,
}: Props<K>) {
  // state
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // clean value on props/state change
  useEffect(() => {
    if (dropdownVisible) {
      setSearchPhrase('');
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (!enableAndOrOperator) {
      onChange((value) => deepCompareValues(value, { ...value, useAndOperator: false }));
    }
  }, [enableAndOrOperator]);

  useEffect(() => {
    if (!allowNotSet) {
      onChange((value) => deepCompareValues(value, { ...value, notSet: false }));
    }
  }, [allowNotSet]);

  useEffect(() => {
    const presentKeys = new Set(options.map((option) => option.id));
    onChange((value) =>
      deepCompareValues(value, { ...value, values: value.values?.filter((key) => presentKeys.has(key)) || [] })
    );
  }, [options]);

  // set value callbacks
  const handleSelectOption = useCallback(
    (key: K, checked: boolean) => {
      // It is important to alway copy the previous value with ...value, because it may contain additional data
      if (checked) {
        onChange((value) => ({ ...value, values: uniq([...value.values, key]) }));
      } else {
        onChange((value) => ({ ...value, values: value.values.filter((itemId) => itemId !== key) }));
      }
    },
    [onChange]
  );

  const handleChangeUseAndOperator = useCallback(
    (event: RadioChangeEvent) => {
      const useAndOperator = event.target.value;
      onChange((value) => ({ ...value, useAndOperator, notSet: value.notSet && !useAndOperator }));
    },
    [onChange]
  );

  const handleChangeNotSet = useCallback(
    (event: CheckboxChangeEvent) => {
      const notSet = event.target.checked;
      onChange((value) => ({ ...value, notSet, useAndOperator: value.useAndOperator && !notSet }));
    },
    [onChange]
  );

  const clearFilter = useCallback(() => {
    if (selectAllOnClear) {
      onChange((value) => ({ ...value, values: options.map((option) => option.id) }));
    } else {
      onChange(SELECT_CLEARED_VALUE);
    }
  }, [onChange, selectAllOnClear, options]);

  // memoized values for render
  const selectedKeysSet = useMemo(() => new Set(value.values), [value]);

  const displayedOptions = useMemo<IOption<K>[]>(
    () => (searchPhrase ? options.filter((option) => smartFilter(option.title, searchPhrase)) : options),
    [options, searchPhrase]
  );

  const items = useMemo(
    () =>
      displayedOptions.map((option) => (
        <Checkbox
          key={option.id}
          checked={selectedKeysSet.has(option.id)}
          className={styles.filterOption}
          onChange={(event) => handleSelectOption(option.id, event.target.checked)}
        >
          {option.label || <DisplayName>{option.title}</DisplayName>}
        </Checkbox>
      )),
    [displayedOptions, selectedKeysSet, handleSelectOption]
  );

  const selectedAll = useMemo(
    () =>
      displayedOptions.length === selectedKeysSet.size &&
      displayedOptions.every((option) => selectedKeysSet.has(option.id)),
    [displayedOptions, selectedKeysSet]
  );

  const handleSelectAll = useSameCallback((event: CheckboxChangeEvent) => {
    const values = event.target.checked ? displayedOptions.map((option) => option.id) : [];
    onChange((value) => ({ ...value, values }));
  });

  const content = (
    <>
      <div className={styles.filterDropDownHeader}>
        {options && Object.keys(options).length >= showSearchMinItems && (
          <SearchInput value={searchPhrase} onSearch={setSearchPhrase} placeholder={searchItemsPlaceholder} />
        )}
        {title && <div className={styles.title}>{title}</div>}
        {enableAndOrOperator && (
          <Radio.Group
            onChange={handleChangeUseAndOperator}
            value={value.useAndOperator || false}
            className={styles.andOperator}
          >
            <Radio value={false}>
              <Fmt id="LabelFilterItem.Operators.OR" />
            </Radio>
            <Radio value={true}>
              <Fmt id="LabelFilterItem.Operators.AND" />
            </Radio>
          </Radio.Group>
        )}
        {additionalOptions}
      </div>
      {allowNotSet && (
        <div>
          <Checkbox checked={value.notSet} onChange={handleChangeNotSet} className={styles.selectCheckbox}>
            {notSetLabel}
          </Checkbox>
        </div>
      )}
      <div>
        <Checkbox
          indeterminate={selectedKeysSet.size > 0 && !selectedAll}
          checked={selectedAll}
          className={styles.selectCheckbox}
          onChange={handleSelectAll}
        >
          <Fmt id="general.selectAll" />
        </Checkbox>
      </div>
      <div className={styles.filterList}>{items}</div>
    </>
  );

  if (inline) {
    return content;
  }

  // This makes it incompatible with `createCommonSelectFilter`, so 'selectAllOnClear' is disabled in AdditionalProps
  const isEmpty = selectAllOnClear ? value.values.length === options.length : SELECT_IS_EMPTY(value);
  const optionCount = value.values.length + +value.notSet;

  return (
    <FilterDisplay
      label={label}
      value={!!optionCount ? optionCount : null}
      isEmpty={isEmpty}
      clearFilter={clearFilter}
      dropdownVisible={dropdownVisible}
      setDropdownVisible={setDropdownVisible}
    >
      {content}
    </FilterDisplay>
  );
}

// helper functions
export const createSingleSelectFilterFunction = <K extends OptionKey, T>(valueSelector: (item: T) => K) => (
  value: SelectFilterValue<K>
) => {
  const asSet = new Set(value.values);
  if (value.notSet) {
    return (item: T) => {
      const selected = valueSelector(item);
      // intentionally only 2 equals
      return selected == null || asSet.has(selected);
    };
  } else {
    return (item: T) => asSet.has(valueSelector(item));
  }
};

export const createMultiSelectFilterFunction = <K extends OptionKey, T>(valuesSelector: (item: T) => K[]) => (
  value: SelectFilterValue<K>
) => {
  const asSet = new Set(value.values);
  if (value.useAndOperator) {
    return (item: T) => sum(valuesSelector(item).map((value) => +asSet.has(value))) === asSet.size;
  } else if (value.notSet) {
    return (item: T) => {
      const selected = valuesSelector(item);
      return selected.length === 0 || selected.some((value) => asSet.has(value));
    };
  } else {
    return (item: T) => valuesSelector(item).some((value) => asSet.has(value));
  }
};

// filters
type AdditionalProps<K extends OptionKey> = Omit<Props<K>, 'value' | 'onChange' | 'selectAllOnClear'>;

type FrontendProps<K extends OptionKey> = Omit<AdditionalProps<K>, 'enableAndOrOperator'>;

const createCommonSelectFilter = <K extends OptionKey>(
  key: string,
  props: AdditionalProps<K>,
  defaultValue?: SelectFilterValue<K>
): CommonFilter<SelectFilterValue<K>> => ({
  key,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: defaultValue || SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
  render: (value, setValue) => <SelectFilter {...props} value={value} onChange={setValue} />,
});

export const createFrontendSingleSelectFilter = <K extends OptionKey, T>(
  key: string,
  props: FrontendProps<K>,
  valueSelector: (item: T) => K,
  defaultValue?: SelectFilterValue<K>
): FrontendFilter<T, SelectFilterValue<K>> => ({
  ...createCommonSelectFilter(key, props, defaultValue),
  filter: createSingleSelectFilterFunction(valueSelector),
});

export const createFrontendMultiSelectFilter = <K extends OptionKey, T>(
  key: string,
  props: FrontendProps<K>,
  valuesSelector: (item: T) => K[]
): FrontendFilter<T, SelectFilterValue<K>> => ({
  ...createCommonSelectFilter(key, { ...props, enableAndOrOperator: true }),
  filter: createMultiSelectFilterFunction(valuesSelector),
});

export const createBackendSelectFilter = <K extends OptionKey, T>(
  key: string,
  props: AdditionalProps<K>,
  serialize: (accumulator: T, value: SelectFilterValue<K>) => T,
  defaultValue?: SelectFilterValue<K>
): BackendFilter<T, SelectFilterValue<K>> => ({
  ...createCommonSelectFilter(key, props, defaultValue),
  serialize,
});
