import AccessTokenExpiredModal from 'components/AccessTokenExpiredModal';
import AppSettingsGate from 'components/AppSettingsGate';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
  userAuthenticated: boolean;
  accessTokenValid: boolean;
};

const AuthRoute: FunctionComponent<Props> = ({
  component: Component,
  userAuthenticated,
  accessTokenValid,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      userAuthenticated ? (
        <>
          {!accessTokenValid && <AccessTokenExpiredModal visible />}
          <AppSettingsGate>
            <Component {...props} />
          </AppSettingsGate>
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/authorize',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default AuthRoute;
