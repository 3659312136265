import { MeasurementsResult } from 'Forge/measurements/MeasurementsResult';
import { message } from 'antd';
import { InjectedIntl } from 'locale';
import React from 'react';

export const getMeasurementsExtensionOptions = (
  intl: InjectedIntl,
  viewer: Autodesk.Viewing.GuiViewer3D,
  getElementName: (data: Autodesk.Viewing.PropertyResult) => string | undefined
) => {
  return {
    onError: (error: { code: 'NO_ELEMENT_SELECTED'; message: string }) => {
      if (error.code === 'NO_ELEMENT_SELECTED') {
        void message.error(intl.formatMessage({ id: 'Forge.measurements.errors.noElementSelected' }));
      }
    },
    onClick: (
      elements: { surface: number; volume: number; dbId: number; hasOpenVolume: boolean }[],
      unitString: string,
      displayUnit: string
    ) => {
      if (elements.some((element) => element.hasOpenVolume)) {
        message.warning(intl.formatMessage({ id: 'Forge.measurements.warnings.openVolume' }));
      }
      elements.forEach(({ surface, volume, dbId }) => {
        if (!isNaN(surface) && !isNaN(volume)) {
          viewer.getProperties(dbId, (data) => {
            const name = getElementName(data);
            void message.info(
              <MeasurementsResult intl={intl} name={name} surface={surface} volume={volume} unitString={unitString} />
            );
          });
        } else {
          void message.error(intl.formatMessage({ id: 'Forge.measurements.errors.notAvailable' }));
        }
      });
    },
    button: {
      tooltip: intl.formatMessage({ id: 'Forge.measurements.button.tooltip' }),
      icon: 'adsk-icon-measure', // TODO: find a better icon
    },
  };
};
