import { RocketOutlined } from '@ant-design/icons';
import { Button, List, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Fmt } from 'locale';
import * as lodash from 'lodash';
import { Dictionary } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './AdminPageStressTest.module.less';
import { Test, TestData, Tests } from './Tests';

type Event = {
  msg: string;
  date: string;
};
const AUTOSIZE_OPTIONS = { minRows: 20 };

const AdminPageStressTest: FunctionComponent = () => {
  const tests = useRef<Dictionary<Test>>(lodash.fromPairs(Tests.map((t) => [t.name, t])));
  const saveResult = useRef<Event[]>([]);
  const [testing, setTesting] = useState<boolean>(false);
  const [currTestName, setCurrTestName] = useState<string>(Tests[0].name);
  const [currTestTemplate, setCurrTestTemplate] = useState<string>(Tests[0].dataTemplate);
  const [result, setResult] = useState<Event[]>([]);

  const setTest = useCallback((test: string) => {
    setCurrTestName(tests.current[test].name);
    setCurrTestTemplate(tests.current[test].dataTemplate);
    saveResult.current = [];
    setResult(saveResult.current);
  }, []);

  const handleDataChange = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      tests.current[currTestName].dataTemplate = value;
      setCurrTestTemplate(value);
    },
    [currTestName]
  );

  const handleRun = useCallback(async () => {
    setTesting(true);
    saveResult.current = [];
    setResult(saveResult.current);
    const data: TestData = { data: tests.current[currTestName], onDataAdd: addRow };
    await tests.current[currTestName].func(data);
    setTesting(false);
  }, [currTestName, tests.current[currTestName].dataTemplate]);

  const addRow = useCallback(
    (row: string) => {
      saveResult.current.push({ msg: row, date: moment(Date.now()).format('h:m:s.ms') });
    },
    [saveResult]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (result.length != saveResult.current.length) {
        setResult([...saveResult.current]);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.param}>
        <div className={styles.label}>
          <Fmt id="AdminPageStressTest.testName.label" />
        </div>
        <div className={styles.value}>
          <Select value={currTestName} onChange={setTest}>
            {Tests.map((item) => (
              <Select.Option value={item.name} key={item.name}>
                <div className={styles.item}>{item.name}</div>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.param}>
        <div className={styles.label}>
          <Fmt id="AdminPageStressTest.testData.label" />
        </div>
        <div className={styles.value}>
          <TextArea
            autoSize={AUTOSIZE_OPTIONS}
            disabled={testing}
            value={currTestTemplate}
            onChange={handleDataChange}
          />
        </div>
      </div>
      <div className={styles.param}>
        <div className={styles.value}>
          <Button type="primary" icon={<RocketOutlined />} disabled={testing} onClick={handleRun}>
            <Fmt id="AdminPageStressTest.Run.button" />
          </Button>
        </div>
      </div>
      <div className={styles.param}>
        <div className={styles.label}>
          <Fmt id="AdminPageStressTest.Result.label" />
        </div>
        <div className={styles.value}>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={result}
            renderItem={(item, i) => <List.Item key={i}> {item.date + ' ' + item.msg}</List.Item>}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPageStressTest;
