import { Col, Row, Typography } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer, messageDetailUrlLinkParse } from '../MessageDetail';
import styles from './MessageViewer.module.less';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

export type AssignmentMessageData = {
  ProjectId: Guid;
  ProjectDescription: string;
  Language: LanguageEnum;
  FinishTime?: IsoDateTime;
  AssignmentNotificationInfo: {
    /** @deprecated raw number from backend, do not use */
    AssignmentNotificationType: number;
    AssignmentName: string;
    AssignmentLink: string;
    AssignmentContent: string;
    AssignmentType: string;
    OldState: string;
    NewState: string;
    CompleteDate?: IsoDateTime;
    DeadlineDate?: IsoDateTime;
    RemovedDocuments: string[];
    RemovedDocumentLinks?: string[];
    Documents: string[];
    DocumentLinks?: string[];
    AssignmentAuthorName: string;
    Solvers: string[];
    RemovedSolvers: string[];
  };
};

export const AssignmentMessageViewer: MessageViewer = ({ category, messageData, projectName, organizationName }) => {
  const data = messageData as AssignmentMessageData;
  const notificationInfo = data.AssignmentNotificationInfo;
  const intl = useIntl();

  const assignmentDocumets = useMemo(
    () =>
      notificationInfo.Documents?.map((documentName, index) => {
        const documentLink = !!notificationInfo.DocumentLinks && notificationInfo.DocumentLinks[index];
        return !!documentLink ? <Link to={messageDetailUrlLinkParse(documentLink)}>{documentName}</Link> : documentName;
      }),
    [notificationInfo]
  );

  const assignmentRemovedDocumets = useMemo(
    () =>
      notificationInfo.RemovedDocuments?.map((documentName, index) => {
        const documentLink = !!notificationInfo.RemovedDocumentLinks && notificationInfo.RemovedDocumentLinks[index];
        return !!documentLink ? <Link to={messageDetailUrlLinkParse(documentLink)}>{documentName}</Link> : documentName;
      }),
    [notificationInfo]
  );

  const header = <Fmt id={'MessageCenterPage.message.detail.assignment.title'} />;

  const content = (
    <>
      <Typography.Text>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.assignment'} />
          </Col>
          <Col span={12}>
            <Link to={parse(notificationInfo.AssignmentLink || '').pathname}>
              <Typography.Link underline>{notificationInfo.AssignmentName}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.assignmentContent'} />
          </Col>
          <Col span={12} className={styles.description}>
            <CommonHubTooltip placement="topLeft" title={notificationInfo.AssignmentContent}>
              <DisplayName>{notificationInfo.AssignmentContent}</DisplayName>
            </CommonHubTooltip>
          </Col>
        </Row>
        {notificationInfo.CompleteDate && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.completeTime'} />
            </Col>
            <Col span={12}>
              {moment(notificationInfo.CompleteDate)
                .locale(intl.locale)
                .format('LLL')}
            </Col>
          </Row>
        )}
        {notificationInfo.DeadlineDate && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.deadlineTime'} />
            </Col>
            <Col span={12}>
              {moment(notificationInfo.DeadlineDate)
                .locale(intl.locale)
                .format('LLL')}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.author'} />
          </Col>
          <Col span={12}>{notificationInfo.AssignmentAuthorName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.solvers'} />
          </Col>
          <Col span={12}>
            {notificationInfo.Solvers.map((solver) => (
              <div key={solver}>{solver}</div>
            ))}
          </Col>
        </Row>
        {!!notificationInfo.OldState && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.oldState'} />
            </Col>
            <Col span={12}>{notificationInfo.OldState}</Col>
          </Row>
        )}
        {!!notificationInfo.NewState && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.currentState'} />
            </Col>
            <Col span={12}>{notificationInfo.NewState}</Col>
          </Row>
        )}
        {!!assignmentDocumets?.length && (
          <Row>
            <Col span={8}>
              <Fmt
                id={'MessageCenterPage.message.detail.grid.document'}
                values={{ count: assignmentDocumets.length }}
              />
            </Col>
            <Col span={12} className={styles.links}>
              {assignmentDocumets.map((document, index) => (
                <div key={index}>{document}</div>
              ))}
            </Col>
          </Row>
        )}
        {!!assignmentRemovedDocumets?.length && (
          <Row>
            <Col span={8}>
              <Fmt
                id={'MessageCenterPage.message.detail.grid.removedDocument'}
                values={{ count: assignmentRemovedDocumets.length }}
              />
            </Col>
            <Col span={12} className={styles.links}>
              {assignmentRemovedDocumets.map((removedDocument) => (
                <div key={removedDocument.toString()}>{removedDocument}</div>
              ))}
            </Col>
          </Row>
        )}
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
