import { Button } from 'antd';
import { masterApi } from 'api/completeApi';
import { ExtendedPermissionTemplatePatchDto, OrgUserDto, ProjectTemplatePatchDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import Modal from 'components/Modal/Modal';
import StackPanel from 'components/StackPanel/StackPanel';
import { useApiData, useCancelToken, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { messageError, processApiError } from 'utils';
import ProjectTemplateBuilder, { ProjectTemplateData } from './ProjectTemplateBuilder';
import styles from './ProjectsTemplates.module.less';

type Props = {
  visible: boolean;
  templateId: Guid;
  organizationId: Guid;
  organizationUsers: OrgUserDto[];
  organizationTimezoneId: string;
  reloadOrganizationUsers: () => void;
  onSubmit: () => void;
  onClose: () => void;
  organizationStorageAreas?: string[];
};

const templateDataToPatcheDto = (
  template: ProjectTemplateData,
  templateId: Guid,
  organizationId: Guid
): ProjectTemplatePatchDto => ({
  ...template,
  id: templateId,
  organizationId,
  primarySubmitterCanEditProcessorTeams: template.primarySubmitterCanEditProcessorTeams,
  primaryProcessorCanEditSubmitterTeams: template.primaryProcessorCanEditSubmitterTeams,
  useWorkDays: template.useWorkDays,
  calendar: template.calendar,
  userExtendedPermissionTempates: template.userExtendedPermissionTempates.map(
    (template): ExtendedPermissionTemplatePatchDto => ({
      ...template,
      organizationId,
      settings: template.extendedPermissions.settings,
    })
  ),
});

const ProjectTemplateEditModal: FunctionComponent<Props> = ({
  organizationId,
  visible,
  templateId,
  onSubmit,
  onClose,
  organizationUsers,
  organizationTimezoneId,
  reloadOrganizationUsers,
  organizationStorageAreas,
}) => {
  const [saving, setSaving] = useState<boolean>();
  const [templateData, setTemplateData] = useState<ProjectTemplateData>();
  const [isTemplateValid, setTemplateValidStatus] = useState<boolean>(false);
  const cancelToken = useCancelToken('EsticonDeleteLinkButton: unmounting', []);
  const intl = useIntl();

  const [projectTemplate, templatesError, templatesLoading, loadProjectTemplate] = useApiData(
    (ct) => masterApi.projects.tempates.projecttemplate.id.get(templateId, ct),
    { autoload: false }
  );

  useEffect(() => {
    if (templateId) loadProjectTemplate();
  }, [templateId]);

  const saveTemplate = useSameCallback(async () => {
    setSaving(true);
    const templatePatchDto = templateDataToPatcheDto(templateData, templateId, organizationId);

    const [err, result] = await masterApi.projects.tempates.projecttemplate.patch(templatePatchDto, cancelToken);
    if (!!err) {
      const apiError = processApiError(err);
      messageError(apiError, intl);
    } else {
      onSubmit && onSubmit();
    }

    setSaving(false);
  });

  const saveButton = useMemo(
    () => (
      <Button type="primary" onClick={saveTemplate} disabled={!templateData || !isTemplateValid} loading={saving}>
        <Fmt id="ProjectTemplates.createModal.confirm" />
      </Button>
    ),
    [templateData, saving, isTemplateValid]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={<Fmt id="ProjectTemplates.list.editProjectTemplate" />}
      additionalButtons={saveButton}
    >
      <ContentGate loading={templatesLoading} error={templatesError} empty={!projectTemplate}>
        <StackPanel vertical className={styles.modalWrap}>
          <ProjectTemplateBuilder
            projectTemplate={projectTemplate}
            organizationTimezoneId={organizationTimezoneId}
            organizationId={organizationId}
            onTemplateChanged={setTemplateData}
            organizationUsers={organizationUsers}
            reloadOrganizationUsers={reloadOrganizationUsers}
            onTemplateValidityChange={setTemplateValidStatus}
            organizationStorageAreas={organizationStorageAreas}
          />
        </StackPanel>
      </ContentGate>
    </Modal>
  );
};

export default ProjectTemplateEditModal;
