import { FileOutlined } from '@ant-design/icons';
import { ProjectUserProfileListDto, RevisionDto, WorkflowApprovalDocumentDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import List from 'components/List';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { ReactNode, useMemo } from 'react';
import { projectUsersMapSelector } from 'store/selectors/projectUsersSelectors';
import { dateComparer } from 'utils/comparators';
import styles from './WorkflowApprovalDocumentActivityList.module.less';
import { ApprovalDocumentActivity, WorkflowApprovalDocumentActivityRow } from './WorkflowApprovalDocumentActivityRow';

export type Props = {
  approvalDocument: WorkflowApprovalDocumentDto;
  workflowNodeId?: Guid;
  additionalData?: (documentActivity: ApprovalDocumentActivity) => ReactNode;
};

const createApprovalDocumentAddedEventDisplay = (
  approvalDocument: WorkflowApprovalDocumentDto
): ApprovalDocumentActivity => {
  return {
    id: approvalDocument.document.id,
    actionDate: approvalDocument.createdDate,
    user: approvalDocument.createdBy,
    documentRevision: approvalDocument.revision,
    type: 'addedDocument',
    workflowNodeId: approvalDocument.workflowNodeId,
  };
};

const createApprovalDocumentSignedEventsDisplay = (
  approvalDocument: WorkflowApprovalDocumentDto,
  userList: Record<Guid, ProjectUserProfileListDto>,
  workflowNodeId?: Guid
): ApprovalDocumentActivity[] => {
  const addedRevisions = approvalDocument.addedRevisions.reduce(
    (revisions, addedRevision) => ({ ...revisions, [addedRevision.revision.id]: addedRevision.revision }),
    {}
  );
  const revisionList: Record<Guid, RevisionDto> = approvalDocument.revision
    ? {
        [approvalDocument.revision.id]: approvalDocument.revision,
        ...addedRevisions,
      }
    : addedRevisions;

  return approvalDocument.addedSigns
    .filter((addedSign) => !workflowNodeId || addedSign.workflowNodeId === workflowNodeId)
    .map((addedSign) => {
      const signedFile = revisionList[addedSign.revisionId]?.secondaryFiles.find(
        (file) => file.id === addedSign.blobId
      );
      return {
        id: addedSign.secondaryBlobId,
        actionDate: signedFile?.createdDate || revisionList[addedSign.revisionId]?.createdDate,
        user: userList[signedFile.createdById],
        documentRevision: revisionList[addedSign.revisionId],
        type: 'signed',
        workflowNodeId: addedSign.workflowNodeId,
      };
    });
};

const createApprovalDocumentRevisionAddedEventsDisplay = (
  approvalDocument: WorkflowApprovalDocumentDto,
  workflowNodeId?: Guid
): ApprovalDocumentActivity[] => {
  return approvalDocument.addedRevisions
    .filter((addedRevision) => !workflowNodeId || addedRevision.workflowNodeId === workflowNodeId)
    .map((addedRevision) => ({
      id: addedRevision.revision.id,
      actionDate: addedRevision.revision.createdDate,
      user: addedRevision.revision.createdBy,
      documentRevision: addedRevision.revision,
      type: 'addedRevision',
      workflowNodeId: addedRevision.workflowNodeId,
    }));
};

export const mapApprovalDocumentActivitySorted = (
  approvalDocument: WorkflowApprovalDocumentDto,
  userList: Record<Guid, ProjectUserProfileListDto>,
  workflowNodeId?: Guid
): ApprovalDocumentActivity[] => {
  const approvalDocumentActions = [
    approvalDocument.workflowNodeId && createApprovalDocumentAddedEventDisplay(approvalDocument),
    ...createApprovalDocumentRevisionAddedEventsDisplay(approvalDocument, workflowNodeId),
    ...createApprovalDocumentSignedEventsDisplay(approvalDocument, userList, workflowNodeId),
  ].filter(Boolean);

  approvalDocumentActions.sort(dateComparer.map((item) => item.actionDate)).reverse();

  return approvalDocumentActions;
};

const WorkflowApprovalDocumentActivityListComponent = ({ approvalDocument, workflowNodeId, additionalData }: Props) => {
  const users = useSelectorDispatch(projectUsersMapSelector, (dispatch) =>
    dispatch.projectUsers.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const documentActionsInNode = useMemo(
    () => mapApprovalDocumentActivitySorted(approvalDocument, users, workflowNodeId),
    [approvalDocument, users, workflowNodeId]
  );

  return (
    <>
      <CommonHubTooltip title={approvalDocument.document.name}>
        <h3>
          <FlowLayout>
            <FileOutlined />
            <DisplayName text={approvalDocument.document.name} />
          </FlowLayout>
        </h3>
      </CommonHubTooltip>
      <SettingsBox>
        <List<ApprovalDocumentActivity>
          data={documentActionsInNode}
          renderItem={(documentActivity) => (
            <div className={styles.activityRow}>
              <WorkflowApprovalDocumentActivityRow
                key={documentActivity.id}
                documentActivity={documentActivity}
                additionalData={additionalData}
              />
            </div>
          )}
        />
      </SettingsBox>
    </>
  );
};

export const WorkflowApprovalDocumentActivityList = React.memo(WorkflowApprovalDocumentActivityListComponent);
