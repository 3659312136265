import '@ant-design/compatible/assets/index.css';
import { Empty } from 'antd';
import { masterApi } from 'api/completeApi';
import { EstiConProjectsInRealisationReportPOHResponseDto, ServiceError } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { FiltersFormItemComponent } from 'components/filters/components/FiltersFormItem/FiltersFormItem';
import { Serializable } from 'components/filters/filterTypes';
import { useIsMounted } from 'hooks';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { Fmt } from 'locale';
import moment from 'moment';
import { useProjectsInRealizationFilterConfiguration } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/useProjectsInRealizationFilter';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ignoreRef, processApiError } from 'utils';

type Props = {
  value: Record<string, Serializable>;
  onChange: (value: Record<string, Serializable>) => void;
  organizationId?: Guid;
  esticonUnit?: Guid;
  reportDate?: IsoDateTime;
  startYear?: number;
  requestCacheApiData: RequestCacheApiCallback;
};

const ProjectsInRealizationFiltersFormItemComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  organizationId,
  esticonUnit,
  reportDate,
  startYear,
  requestCacheApiData,
}) => {
  const [reportDataLoading, setReportLoading] = useState<boolean>();
  const isMounted = useIsMounted();
  const [reportError, setReportError] = useState<ServiceError>();
  const [reportData, setReportData] = useState<EstiConProjectsInRealisationReportPOHResponseDto>();

  useEffect(() => {
    if (organizationId && esticonUnit && startYear && reportDate) {
      setReportLoading(true);
      void requestCacheApiData(
        masterApi.EsticonReports.orq.id.firms.id.projectsinrealisationpoh.post,
        organizationId,
        esticonUnit,
        {
          utcFrom: moment(reportDate)
            .utc()
            .startOf('day')
            .toISOString(),
          processedYear: startYear,
        }
      ).then((response) => {
        if (!isMounted.current) return;
        setReportLoading(false);
        const [err, resp] = response;
        if (err) {
          const serviceError = processApiError(err);
          setReportError(serviceError);
        } else {
          setReportError(undefined);
          setReportData(resp.data);
        }
      });
    }
  }, [esticonUnit, organizationId, reportDate, requestCacheApiData, startYear]);

  const hasAllRequestValues = organizationId && esticonUnit && startYear && reportDate;

  const filters = useProjectsInRealizationFilterConfiguration(reportData?.rows);

  if (!hasAllRequestValues) {
    return <Empty description={<Fmt id="ProjectsInRealizationFiltersFormItem.missingValues" />}></Empty>;
  }

  return (
    <ContentGate error={reportError}>
      <SpinBoxCenter spinning={reportDataLoading} overflowVisible>
        <FiltersFormItemComponent value={value} onChange={onChange} filters={filters} reportData={reportData?.rows} />
      </SpinBoxCenter>
    </ContentGate>
  );
};

export const ProjectsInRealizationFiltersFormItem = ignoreRef(
  ProjectsInRealizationFiltersFormItemComponent
) as FunctionComponent<Omit<Props, 'value' | 'onChange'>>;
