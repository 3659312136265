import { FileOutlined, FolderOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';

type Props = {
  foldersCount?: number;
  filesCount?: number;
  selectedFoldersCount?: number;
  selectedFilesCount?: number;
  className?: string;
};

export const FolderItemsCounts: FunctionComponent<Props> = ({
  foldersCount,
  selectedFoldersCount,
  filesCount,
  selectedFilesCount,
  className,
}) => {
  return (
    <span className={className}>
      {foldersCount > 0 && (
        <>
          {selectedFoldersCount !== undefined && `${selectedFoldersCount}/`}
          {foldersCount} <FolderOutlined />
          {filesCount > 0 && ', '}
        </>
      )}
      {filesCount > 0 && (
        <>
          {selectedFilesCount !== undefined && `${selectedFilesCount}/`}
          {filesCount} <FileOutlined />
        </>
      )}
    </span>
  );
};
