import { DownOutlined, FileOutlined, FormOutlined, GlobalOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import {
  ExtendedPermissionEnum,
  ProjectHubDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import classnames from 'classnames';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { useCurrentProjectUser, useIntl, useSelectorDispatch, useVisibleState } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { userHasSomePermissions } from 'utils/userHasPermission';

import ApplicationUrlSetForm from './ApplicationUrl/ApplicationUrlSetForm';
import ConstructionJournalSetForm from './ApplicationUrl/ConstructionJournalSetForm';
import DocumentViewSetForm from './DocumentView/DocumentViewSetForm';
import { EsticonCreate } from './Esticon/EsticonCreate/EsticonCreate';
import { EsticonIcon } from './Esticon/EsticonListItem/EsticonIcon';
import styles from './ExternAppAdd.module.less';
import { AddExternAppsProps, AvailableAppEnum } from './externAppsTypes';
import TextPageSetForm from './TextPage/TextPageSetForm';

const DROPDOWN_TRIGGER_CLICK: DropDownProps['trigger'] = ['click'];

const MODAL_RENDERERS: Record<AvailableAppEnum, FunctionComponent<AddExternAppsProps>> = {
  [AvailableAppEnum.Esticon]: EsticonCreate,
  [AvailableAppEnum.ConstructionJournal]: ConstructionJournalSetForm,
  [AvailableAppEnum.ExternalUrl]: ApplicationUrlSetForm,
  [AvailableAppEnum.TextPage]: TextPageSetForm,
  [AvailableAppEnum.DocumentWindow]: DocumentViewSetForm,
};

type Props = {
  project: ProjectHubDto;
  onAppAdded: (appType: AvailableAppEnum, appId?: Guid) => void;
};

export const ExternAppAdd: FunctionComponent<Props> = ({ project, onAppAdded }) => {
  const [selectedKey, selectedKeyVisible, setSelectedKey, hideSelectedKey] = useVisibleState<AvailableAppEnum>(null);
  const intl = useIntl();
  const externalAppUrl = useSelectorDispatch(
    (state) => state.externalApplicationsSettings.data?.settings || [],
    (dispatch) => dispatch.externalApplicationsSettings.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const Component = MODAL_RENDERERS[selectedKey];
  const currentUser = useCurrentProjectUser();

  const handleAddApp = useCallback(
    (appId?: Guid) => {
      onAppAdded(selectedKey, appId);
      hideSelectedKey();
    },
    [onAppAdded, selectedKey]
  );

  const esticonDisabled = !!project.esticonProjectId || !currentUser.isAdmin;
  const hasConstructionJournal = !!externalAppUrl.some(
    (setting) => setting.type === ProjectSettingExternalAppSettingsEnum.constructionJournal
  );

  const overlayMenu = useMemo(
    (): MenuProps => ({
      items: [
        {
          key: 'esticon',
          label: intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.addButton' }),
          icon: <EsticonIcon className={classnames(styles.icon, esticonDisabled && styles.disabled)} />,
          disabled: esticonDisabled,
          onClick: () => setSelectedKey(AvailableAppEnum.Esticon),
        },
        {
          key: 'constructionJournal',
          label: intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.ConstructionJournal.addButton' }),
          icon: <ReadOutlined className={classnames(styles.icon, hasConstructionJournal && styles.disabled)} />,
          disabled: !!hasConstructionJournal,
          onClick: () => setSelectedKey(AvailableAppEnum.ConstructionJournal),
        },
        {
          key: 'applicationUrl',
          label: intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.ApplicationUrl.addButton' }),
          icon: <GlobalOutlined className={classnames(styles.icon)} />,
          onClick: () => setSelectedKey(AvailableAppEnum.ExternalUrl),
        },
        {
          key: 'documentWindow',
          label: intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.DocumentView.addButton' }),
          icon: <FileOutlined className={classnames(styles.icon)} />,
          onClick: () => setSelectedKey(AvailableAppEnum.DocumentWindow),
        },
        {
          key: 'textPage',
          label: intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.TextPage.addButton' }),
          icon: <FormOutlined className={classnames(styles.icon)} />,
          onClick: () => setSelectedKey(AvailableAppEnum.TextPage),
        },
      ],
    }),
    [esticonDisabled, hasConstructionJournal, intl, setSelectedKey]
  );

  return (
    <>
      <Dropdown
        trigger={DROPDOWN_TRIGGER_CLICK}
        menu={overlayMenu}
        disabled={
          !(currentUser.isAdmin || userHasSomePermissions(currentUser, [ExtendedPermissionEnum.externalApplications]))
        }
      >
        <Button type="primary" icon={<AddIcon />}>
          <Fmt id="ProjectSettingsPage.ExternApps.addApplication" />
          <DownOutlined />
        </Button>
      </Dropdown>
      {Component && <Component open={selectedKeyVisible} onAppAdded={handleAddApp} onCancel={hideSelectedKey} />}
    </>
  );
};
