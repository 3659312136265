import classNames from 'classnames';
import { LabelDisplay, LabelLinkType } from 'components/LabelDisplay/LabelDisplay';
import { Dictionary } from 'lodash';
import React, { useMemo } from 'react';
import { textComparer } from 'utils/comparators';
import styles from './LabelsStack.module.less';

type Props<T> = {
  labels: Guid[];
  linkType?: LabelLinkType;
  className?: string;
  labelsMap: Dictionary<T>;
};

const LabelsStackDrawer = <T extends { id: Guid; name?: string; description?: string; color?: string }>({
  labels,
  linkType,
  className,
  labelsMap,
}: Props<T>) => {
  const labelsData = useMemo(
    () =>
      (labelsMap &&
        labels
          ?.map((id) => labelsMap[id])
          .filter(Boolean)
          .sort(textComparer.map((label) => label.name))) ||
      [],
    [labelsMap, labels]
  );

  const allLabels = useMemo(
    () =>
      labelsData.map((label) => (
        <LabelDisplay key={label.id} label={label} linkType={linkType} className={styles.label} />
      )),
    [labelsData, linkType]
  );

  if (!allLabels?.length) return null;

  return (
    <span className={classNames(styles.labelsWrap, className)}>
      <span className={styles.labels}>{allLabels}</span>
    </span>
  );
};

export default LabelsStackDrawer;
