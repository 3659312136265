import { FilterOutlined } from '@ant-design/icons';
import { InputRef } from 'antd';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { FilterDisplay } from 'components/filters/render/FilterDisplay/FilterDisplay';
import { FilterLabel } from 'components/filters/render/FilterLabel/FilterLabel';
import { useFocus } from 'hooks/useFocus';
import { deburr } from 'lodash';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { CheckFormat, checkString, searchRgx, smartFilter } from 'utils';

type TextFilterValue = string;

const IS_EMPTY = (value: TextFilterValue) => !value.trim().length;
const DEFAULT_VALUE: TextFilterValue = '';
const CLEARED_VALUE: React.SetStateAction<TextFilterValue> = DEFAULT_VALUE;
const CHECK_FORMAT: CheckFormat<TextFilterValue> = checkString;

type Props = {
  defaultValue: string;
  onChangeDebounced: (value: string) => void;
  placeholder?: string;
  delay?: number;
  label?: ReactNode;
  predefinedOptions?: ReactNode;
};

const TextFilterComponent: FunctionComponent<Props> = ({
  defaultValue,
  onChangeDebounced,
  placeholder,
  label,
  delay = 400,
}) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue]);
  useDebounce(() => onChangeDebounced(value), delay, [value]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { setInputRef } = useFocus<InputRef>(dropdownVisible);

  return (
    <FilterDisplay
      label={label}
      value={!!value && <FilterLabel value={value} />}
      isEmpty={IS_EMPTY(value)}
      clearFilter={() => setValue('')}
      dropdownVisible={dropdownVisible}
      setDropdownVisible={setDropdownVisible}
    >
      <FilterInput
        value={value}
        onSearch={setValue}
        placeholder={placeholder}
        prefix={<FilterOutlined />}
        setRef={setInputRef}
      />
    </FilterDisplay>
  );
};

export const TextFilter = React.memo(TextFilterComponent);

type AdditionalProps = Omit<Props, 'defaultValue' | 'onChangeDebounced'>;

const createCommonTextFilter = (key: string, props?: AdditionalProps): CommonFilter<string> => ({
  key,
  render: (value, setValue) => <TextFilter {...props} defaultValue={value} onChangeDebounced={setValue} />,
  isEmpty: IS_EMPTY,
  defaultValue: DEFAULT_VALUE,
  clearedValue: CLEARED_VALUE,
  checkFormat: CHECK_FORMAT,
});

export const createFrontendSingleTextFilter = <T,>(
  key: string,
  valueSelector: (item: T) => string,
  props?: AdditionalProps
): FrontendFilter<T, TextFilterValue> => ({
  ...createCommonTextFilter(key, props),
  filter: (value) => {
    const regex = searchRgx(value);
    return (item: T) => deburr(valueSelector(item)).search(regex) !== -1;
  },
});

export const createFrontendMultiTextFilter = <T,>(
  key: string,
  valuesSelector: (item: T) => string[],
  props?: AdditionalProps
): FrontendFilter<T, TextFilterValue> => ({
  ...createCommonTextFilter(key, props),
  filter: (value) => {
    const regex = searchRgx(value);
    return (item: T) => valuesSelector(item).some((text) => deburr(text).search(regex) !== -1);
  },
});

export const createFrontendSingleTextMutlipleOptionsFilter = <T,>(
  key: string,
  valueSelector: (item: T) => string,
  separator: string,
  props?: AdditionalProps
): FrontendFilter<T, TextFilterValue> => ({
  ...createCommonTextFilter(key, props),
  filter: (value) => {
    const searchQueryList = value.split(separator).filter((searchQuery) => searchQuery.trim().length);
    return (item: T) =>
      !searchQueryList.length || searchQueryList.some((searchQuery) => smartFilter(valueSelector(item), searchQuery));
  },
});

export const createBackendTextFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: TextFilterValue) => T,
  props?: AdditionalProps
): BackendFilter<T, TextFilterValue> => ({
  ...createCommonTextFilter(key, props),
  serialize,
});
