import { ApiPromise, ApiResponse } from 'api/await-to';
import { CancelToken } from 'axios';
import { useRef, useState } from 'react';
import { useCancelToken } from './useCancelToken';
import { useIsMounted } from './useIsMounted';
import { useSameCallback } from './useSameCallback';

export type RequestCacheApiCallback = <T extends any[], R>(fetchMethod: (...args: [...T, CancelToken | undefined]) => ApiPromise<R>, ...args: [...T]) => Promise<ApiResponse<R>>

const generateRequestKey = <T extends any[], R>(fetchMethod: (...args: [...T, CancelToken | undefined]) => ApiPromise<R>, ...args: [...T]): string => {
  const serializedData = JSON.stringify(args);
  const fetchMethodString = fetchMethod.toString();
  return `${fetchMethodString}-${serializedData}`;
};

/**
 * Create queue for requests to the same endpoint and cache the result.
 * Subsequent calls to the same endpoint with same data returns cached response instead of new request.
 * Parallel request will only call api once and will be resolver together with first request
 * @returns a function to call api with parallel request waiting and result caching
 */
export const useCachedApiRequests = () => {
  const [cachedRequests, setChacedRequests] = useState<{ [key: string]: ApiResponse<any> }>({});
  const loading = useRef<{ [key: string]: boolean }>({});
  const queuedPromises = useRef<{ [key: string]: ((response: ApiResponse<any>) => void)[] }>({});

  const cancelToken = useCancelToken('Cached api unloading', []);
  const isMounted = useIsMounted();

  const requestCacheApiData: RequestCacheApiCallback = useSameCallback(async (fetchMethod, ...args) => {
    const requestKey = generateRequestKey(fetchMethod, ...args);

    if (cachedRequests[requestKey]) {
      return cachedRequests[requestKey];
    } else {
      if (!loading.current[requestKey]) {
        loading.current = ({ ...loading.current, [requestKey]: true });

        const fetchedData = await fetchMethod(...args, cancelToken);
        if (!isMounted.current) return null;
        setChacedRequests(prev => ({ ...prev, [requestKey]: fetchedData }));
        queuedPromises.current[requestKey]?.forEach(callback => callback(fetchedData));
        queuedPromises.current[requestKey] = [];

        loading.current = ({ ...loading.current, [requestKey]: false });
        return fetchedData;
      } else {
        return new Promise<ApiResponse<any>>(resolve => {
          queuedPromises.current[requestKey] = [
            ...(queuedPromises.current[requestKey] || []), (response) => resolve(response)
          ];
        });
      }
    }
  });

  return [requestCacheApiData] as const;
};
