import { CommonFilterWithValue, Serializable } from 'components/filters/filterTypes';
import { OrderOption, OrderValue } from 'components/filters/orderTypes';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent, ReactNode } from 'react';
import { FilterToolbar } from '../FilterToolbar/FilterToolbar';
import { OrderSelect } from '../OrderSelect/OrderSelect';
import styles from './OrderFilterToolbar.module.less';

type Props<T extends string> = {
  orderOptions: OrderOption<T>[];
  order: OrderValue<T> | null;
  setOrder: React.Dispatch<React.SetStateAction<OrderValue<T>>>;
  filters: CommonFilterWithValue[];
  setFilterValue: (key: string, value: React.SetStateAction<Serializable>) => void;
  itemCounts: ReactNode;
  alignRight?: boolean;
  clearFilters: () => void;
};

const OrderFilterToolbarComponent: FunctionComponent<Props<string>> = ({
  orderOptions,
  order,
  setOrder,
  ...filterProps
}) => {
  return (
    <FlowLayout growLast wrap gapSize="large" className={styles.toolbar}>
      <OrderSelect orderOptions={orderOptions} order={order} setOrder={setOrder} />
      <FilterToolbar {...filterProps} />
    </FlowLayout>
  );
};

export const OrderFilterToolbar: <T extends string>(props: Props<T>) => JSX.Element = React.memo(
  OrderFilterToolbarComponent
);
