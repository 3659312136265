import '@ant-design/compatible/assets/index.css';
import { Form, Input, Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { projectApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  ExternalApplicationSettingDto,
  ExternalApplicationSettingSetDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FULL_WIDTH_FORM_LABEL } from 'config/constants';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import uuid from 'uuid';
import { AddExternAppsProps } from '../externAppsTypes';
import styles from './TextPageSetForm.module.less';

export type TextPageSetFormProps = AddExternAppsProps & {
  externalAppSetting?: ExternalApplicationSettingDto & { data: { textPageId: Guid } };
};

const TextPageSetForm: FunctionComponent<TextPageSetFormProps> = ({
  open,
  onAppAdded,
  onCancel,
  externalAppSetting,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const [menuName, setMenuName] = useState<string>(externalAppSetting?.name);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageContent, setPageContent] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const [initialTextPage, textPageError, textPageLoading, loadTextPage] = useApiData(
    (ct) => projectApi.projectsetting.externalapplication.textpage.id.get(externalAppSetting?.data.textPageId, ct),
    { autoload: false }
  );

  useEffect(() => {
    if (initialTextPage) {
      setPageTitle(initialTextPage.title);
      setPageContent(initialTextPage.content);
    }
  }, [initialTextPage]);

  useEffect(() => {
    if (open && externalAppSetting?.data.textPageId) {
      loadTextPage();
    }
  }, [open, externalAppSetting?.data.textPageId]);

  const intl = useIntl();
  const handleSubmit = useSameCallback(async () => {
    if (!saving) {
      setSaving(true);
      const newSettings: ExternalApplicationSettingSetDto = externalAppSetting
        ? {
            ...externalAppSetting,
            name: menuName,
            data: { textPageId: externalAppSetting?.data.textPageId },
            textPage: {
              id: externalAppSetting?.data.textPageId,
              title: pageTitle,
              content: pageContent,
            },
          }
        : {
            id: uuid(),
            name: menuName,
            menuOrder: 0,
            type: ProjectSettingExternalAppSettingsEnum.textPage,
            data: { textPageId: undefined },
            textPage: {
              id: undefined,
              title: pageTitle,
              content: pageContent,
            },
          };
      const [err, res] = await projectApi.projectsetting.externalapplication.patch(newSettings);
      setSaving(false);

      if (!err) {
        dispatch.externalApplicationsSettings.setData(res.data);
        onAppAdded();
        return true;
      } else {
        messageError(err, intl);
        return false;
      }
    }
    return false;
  });

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const okButtonProps = useMemo<ButtonProps>(
    () => ({
      disabled: !pageTitle || !pageContent || !menuName,
      loading: saving,
    }),
    [pageTitle, pageContent, menuName, saving]
  );

  const cancelButtonProps = useMemo<ButtonProps>(
    () => ({
      loading: saving,
    }),
    [saving]
  );

  return (
    <Modal
      title={<Fmt id={'ProjectSettingsPage.ExternApps.TextPage.listItemTitle'} />}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      width={1000}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <Form>
        <Form.Item
          required
          label={intl.formatMessage({
            id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
          })}
          labelCol={FULL_WIDTH_FORM_LABEL}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
            })}
            onChange={(e) => {
              setMenuName(e.currentTarget.value);
            }}
            defaultValue={externalAppSetting?.name}
            value={menuName}
          />
        </Form.Item>
        <Form.Item
          required
          label={intl.formatMessage({
            id: 'ProjectSettingsPage.ExternApps.TextPage.title.placeholder',
          })}
          labelCol={FULL_WIDTH_FORM_LABEL}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'ProjectSettingsPage.ExternApps.TextPage.title.placeholder',
            })}
            onChange={(e) => {
              setPageTitle(e.currentTarget.value);
            }}
            maxLength={apiConstraints.externalApplicationsProjectSettingsTextPagePatchDto.title.maxLength}
            value={pageTitle}
          />
        </Form.Item>
        <Form.Item>
          <ContentGate loading={!initialTextPage && textPageLoading} error={textPageError}>
            <ReactQuill theme="snow" value={pageContent} onChange={setPageContent} className={styles.richTextEditor} />
          </ContentGate>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default TextPageSetForm;
