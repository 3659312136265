import { Alert, Button, Typography } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from './ItemNotFoundErrorBox.module.less';

type Props = {
  error: ServiceError;
  className?: string;
  style?: React.CSSProperties;
  projectId: string;
};

export const ItemNotFoundErrorBox: FunctionComponent<Props> = ({ error, projectId, ...restProps }) => {
  return (
    <Alert
      className={styles.alert}
      message={
        <Typography.Title level={4}>
          <Fmt id={`ItemNotFoundErrorBox.title`} />
        </Typography.Title>
      }
      description={
        <>
          <Fmt id={`ItemNotFoundErrorBox.description`} />
          <div className={styles.button}>
            <Link to={`/projects/${projectId}/directories`}>
              <Button type="primary">
                <Fmt id="ItemNotFoundErrorBox.toDocuments" />
              </Button>
            </Link>
          </div>
        </>
      }
      type="error"
      showIcon
      banner
      {...restProps}
    />
  );
};

export default ItemNotFoundErrorBox;
