import { history } from 'store/history';

/**
 * Specifies if a redirect should occur and whether it should replace location or push a new history location.
 */
export enum RedirectOption {
  /** Do not redirect */
  No = 'No',

  /** Replace current location with new location */
  Replace = 'Replace',

  /** Push new location into history */
  Push = 'Push',
}

export const redirectWithOption = (location: string | null, option: RedirectOption | null) => {
  if (!history || !location || !option) {
    return;
  }
  switch (option) {
    case RedirectOption.No:
      return;
    case RedirectOption.Push:
      history.push(location);
      return;
    case RedirectOption.Replace:
      history.replace(location);
      return;
  }
};
