import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  EstiConProjectRowDto,
  EstiProjectDto,
  EstiProjectNoteForHubDto,
  EstiProjectNoteForHubItemDto,
  EstiProjectNoteForHubPatchDto,
} from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { maxBy } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { smartFilter } from 'utils';
import styles from './ProjectsInRealizationPairingNotes.module.less';

type Props = {
  note: EstiProjectNoteForHubDto;
  availableEsticonProjects: EstiProjectDto[];
  onSave: (updatedNote: EstiProjectNoteForHubPatchDto) => Promise<void>;
  onNotesPreview: (notesList: EstiProjectNoteForHubItemDto[]) => void;
  className?: string;
};

type ProjectOptionType = DefaultOptionType & {
  sign: string;
  name: string;
};

const calculateProjectMathing = (project: EstiConProjectRowDto, note: EstiProjectNoteForHubDto): number => {
  let matchCount = 0;
  if (project.name === note.nazevAkce) {
    matchCount++;
  }
  if (project.begin === note.zahajeni) {
    matchCount++;
  }
  if (project.sign === note.znacka) {
    matchCount++;
  }

  return matchCount;
};

const noteFilter = (input: string, option: ProjectOptionType) => {
  return smartFilter(option.name.toString(), input) || smartFilter(option.sign.toString(), input);
};

export const ProjectsRealizationSavedViewItem: FunctionComponent<Props> = ({
  note,
  availableEsticonProjects,
  onSave,
  onNotesPreview,
  className,
}) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [selectedEsticonProjectId, setSelectedEsticonProjectId] = useState<Guid>();

  const handleEdit = useCallback(async () => {
    setLoading(true);
    await onSave({ ...note, esticonProjectId: selectedEsticonProjectId });
    setLoading(false);
  }, [note, selectedEsticonProjectId, onSave]);

  const projectsMathing = useMemo(
    () =>
      availableEsticonProjects
        .map((project) => ({ matchCount: calculateProjectMathing(project.estiProject, note), project }))
        .sort(
          (a, b) =>
            b.matchCount - a.matchCount ||
            a.project.estiProject.sign.localeCompare(b.project.estiProject.sign) ||
            a.project.estiProject.name.localeCompare(b.project.estiProject.name)
        ),
    [availableEsticonProjects, note]
  );

  useEffect(() => {
    if (!!selectedEsticonProjectId) return;
    const mostProbableEsticonId = maxBy(projectsMathing, (project) => project.matchCount)?.project.estiProject.id;
    setSelectedEsticonProjectId(mostProbableEsticonId);
  }, [projectsMathing]);

  const showNotesPreview = useCallback(() => {
    onNotesPreview(note.projectNoteItems);
  }, [note, onNotesPreview]);

  const availableReportProjectOptions = useMemo(
    () =>
      projectsMathing.map(
        (matchedProject): ProjectOptionType => ({
          value: matchedProject.project.estiProject.id,
          label: (
            <div className={styles.selectDescription}>
              <div>
                {matchedProject.project.estiProject.sign}
                {' - '}
                {intl.formatMessage(
                  { id: 'ProjectsInRealizationPairingNotes.selectProjectName' },
                  {
                    name: matchedProject.project.estiProject.name,
                    matchCount: Math.round((matchedProject.matchCount * 100) / 3),
                  }
                )}
              </div>
              <div>
                <Fmt id="ProjectsInRealizationPairingNotesModal.labels.startDate" />
                {': '}
                {moment(matchedProject.project.estiProject.begin)
                  .locale(intl.locale)
                  .format('lll')}
              </div>
            </div>
          ),
          name: matchedProject.project.estiProject.name,
          sign: matchedProject.project.estiProject.sign,
        })
      ),
    [projectsMathing, intl]
  );

  const isSaveDisabled = selectedEsticonProjectId === note.esticonProjectId;

  return (
    <Row className={classNames(styles.projectRow, className)}>
      <Col span={3}>{note.znacka}</Col>
      <Col span={8}>{note.nazevAkce}</Col>
      <Col span={3}>
        {moment(note.zahajeni)
          .locale(intl.locale)
          .format('lll')}
      </Col>
      <Col span={2}>
        <Button icon={<EditOutlined />} type="ghost" shape="circle" onClick={showNotesPreview} />
      </Col>
      <Col span={8} className={styles.projectWrapper}>
        <Select
          value={selectedEsticonProjectId}
          options={availableReportProjectOptions}
          onChange={setSelectedEsticonProjectId}
          className={styles.projectSelect}
          showSearch
          filterOption={noteFilter}
        />
        <Button onClick={handleEdit} disabled={isSaveDisabled} type="primary" loading={loading}>
          <Fmt id="general.save" />
        </Button>
      </Col>
    </Row>
  );
};
