import { to } from 'api/await-to';
import { DocumentLinkCreateDto, DocumentLinkDto, DocumentLinkPatchDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';
export const documentLinksApi = {
  addLinks: (data: DocumentLinkCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/documentlink/addlinks`, data, { cancelToken })
    ),
  updateLink: (documentLinkId: Guid, data: DocumentLinkPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentLinkDto>(`/documentlink/${documentLinkId}`, data, { cancelToken })
    ),
  deleteLink: (documentLinkId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/documentlink/${documentLinkId}`, { cancelToken })
    ),
  getDocumentsLinks: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocumentLinkDto[]>(`/documentlink/documentslinks/${documentId}`, { cancelToken })
    ),
};
