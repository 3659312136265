import { CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { RelativeDateDisplay } from 'components/RelativeDateDisplay/RelativeDateDisplay';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { EsticonIcon } from 'pages/ProjectSettingsPage/ExternApps/Esticon/EsticonListItem/EsticonIcon';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import styles from './ProjectsInRealizationNote.module.less';

export enum ProjectNoteType {
  AspeHub = 'AspeHub',
  AspeEsticon = 'AspeEsticon',
}

export type ReportProjectNote = {
  type: ProjectNoteType;
  id: Guid;
  projectId: Guid;
  note: string;
  autor?: string;
  createdDate?: IsoDateTime;
  modifiedDate?: IsoDateTime;
  orderId?: number;
};

type Props = {
  note: ReportProjectNote;
  onEdit?: (noteId: Guid, message: string) => Promise<boolean>;
  onDelete?: (noteId: Guid) => Promise<boolean>;
  canUserEdit: boolean;
  className?: string;
};

const AUTOSIZE_OPTIONS = { minRows: 1, maxRows: 6 };

export const ProjectRealizationNote: FunctionComponent<Props> = ({
  onEdit,
  onDelete,
  className,
  note,
  canUserEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState<string>(note.note);
  const intl = useIntl();

  const onStartEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      Modal.confirm({
        title: intl.formatMessage({ id: 'ProjectsInRealisationNoteModal.delete.title' }),
        content: intl.formatMessage({ id: 'ProjectsInRealisationNoteModal.delete.content' }),
        onOk: async () => {
          setLoading(true);
          await onDelete(note.id);
          setLoading(false);
        },
        width: 600,
      });
    }
  }, [intl, note.id, onDelete]);

  const handleEdit = useCallback(async () => {
    if (onEdit) {
      setLoading(true);
      const result = await onEdit(note.id, editedMessage);
      if (result) {
        setEditing(false);
      }
      setLoading(false);
    }
  }, [note, onEdit, editedMessage]);

  const handleClose = useCallback(() => {
    setEditing(false);
  }, []);

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.ctrlKey === true) {
      e.preventDefault();
      handleEdit();
    }
  };

  const onKeyDownEscape = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setEditing(false);
        setEditedMessage(note.note);
      }
    },
    [note]
  );

  const menu = useMemo(
    (): MenuProps => ({
      items: [
        {
          key: 'edit',
          label: <Fmt id="general.edit" />,
          onClick: onStartEdit,
          icon: <EditOutlined />,
        },
        {
          key: 'delete',
          label: <Fmt id="general.delete" />,
          onClick: handleDelete,
          icon: <DeleteOutlined />,
        },
      ],
    }),
    [onStartEdit, handleDelete]
  );

  return (
    <div className={classNames(styles.comment)}>
      <div className={styles.header}>
        <div className={styles.subInfo}>
          <StackPanel className={styles.headerGap}>
            {note.type === ProjectNoteType.AspeHub ? (
              <CommonHubTooltip title={<Fmt id="ProjectsInRealisationNote.source.AspeHub" />}>
                <img src={process.env.PUBLIC_URL + '/images/logo.svg'} className={styles.hubIcon} />
              </CommonHubTooltip>
            ) : (
              <CommonHubTooltip title={<Fmt id="ProjectsInRealisationNote.source.AspeEsticon" />}>
                <div>
                  <EsticonIcon />
                </div>
              </CommonHubTooltip>
            )}
            {!!note.orderId && <div className={styles.noteOrder}>{note.orderId}</div>}
            <RelativeDateDisplay
              date={note.modifiedDate || note.createdDate}
              tooltipTranslationKey={note.modifiedDate ? 'general.modifiedDate' : 'general.createdDate'}
            />
            {note.autor}
          </StackPanel>
        </div>
        <div className={styles.menuContainer}>
          {!editing ? (
            canUserEdit &&
            note.type === ProjectNoteType.AspeHub && (
              <Dropdown menu={menu} placement={'bottomRight'}>
                <Button type="link" size="small" icon={<EllipsisOutlined rotate={90} />} />
              </Dropdown>
            )
          ) : (
            <div className={styles.closeEdit}>
              <Tooltip title={<Fmt id="DiscussionNote.closeEditing" />}>
                <CloseOutlined onClick={handleClose} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {editing ? (
        <div className={styles.commentInput}>
          <TextArea
            autoSize={AUTOSIZE_OPTIONS}
            placeholder={intl.formatMessage({ id: 'DiscussionInput.placeholder' })}
            disabled={loading}
            value={editedMessage}
            onChange={(e) => setEditedMessage(e.target.value)}
            onPressEnter={onPressEnter}
            onKeyDown={onKeyDownEscape}
            autoFocus
            rows={10}
          />
          <Button onClick={handleEdit} type="primary" loading={loading}>
            <Fmt id="general.save" />
          </Button>
        </div>
      ) : (
        <>
          <div className={classNames(styles.message, className)}>{note.note}</div>
        </>
      )}
    </div>
  );

  return <></>;
};
