import { Form, Modal, Select, Tag } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { COMMON_SETTING_MODAL_WIDTH } from 'config/constants';
import { useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { projectsListSelector } from 'store/selectors';
import { RedirectOption, redirectWithOption, smartFilter } from 'utils';

type ProjectOption = {
  label: React.ReactNode;
  value: string;
  name: string;
  description: string;
  organizationName: string;
};

const filterProjectsOptions = (inputValue: string, option: ProjectOption) =>
  smartFilter(option?.name, inputValue) ||
  smartFilter(option?.description, inputValue) ||
  smartFilter(option?.organizationName, inputValue);

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ProjectSelect: FunctionComponent<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch<Dispatch>();
  const projects = useStoreSelector(projectsListSelector);

  useEffect(() => {
    dispatch.allProjects.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const handleClick = (projectId: Guid) => {
    dispatch.persistentUi.setOpenCreateAssignmentModal(true);

    const url = `/projects/${projectId}/messagecenter/assignments`;
    redirectWithOption(url, RedirectOption.Push);
  };

  const [selectedProjectId, setSelectedProjectId] = React.useState<string | undefined>(undefined);

  const onSubmit = useCallback(() => {
    handleClick(selectedProjectId);
  }, [selectedProjectId]);

  const projectsOptions: ProjectOption[] = projects?.map((project) => ({
    label: (
      <FlowLayout growFirst>
        <strong>{project.name}</strong>
        <Tag>{project.organization.name}</Tag>
      </FlowLayout>
    ),
    name: project.name,
    description: project.description,
    organizationName: project.organization.name,
    value: project.id,
  }));

  return (
    <Modal
      title={<Fmt id="AssignmentFormModal.projectSelect.title" />}
      open={open}
      onCancel={onClose}
      onOk={onSubmit}
      okButtonProps={{ disabled: !selectedProjectId }}
      width={COMMON_SETTING_MODAL_WIDTH}
    >
      <Form layout="vertical">
        <FormItem required label={<Fmt id="AssignmentFormModal.projectSelect" />}>
          <Select
            showSearch
            placeholder={<Fmt id="general.search" />}
            defaultActiveFirstOption={false}
            filterOption={filterProjectsOptions}
            notFoundContent={null}
            options={projectsOptions}
            value={selectedProjectId}
            autoFocus
            onSelect={(value) => setSelectedProjectId(value)}
            status={selectedProjectId ? undefined : 'error'}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};
