import { Alert, Typography } from 'antd';
import AccessPermissionRequest from 'components/AccessPermissionRequest';
import StackPanel from 'components/StackPanel';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';
import { injectIntl } from 'react-intl';
import styles from './DirectoryForbiddenError.module.less';

export type DirectoryForbiddenErrorData = {
  directoryId: Guid;
  directoryPath: string;
  documentId: Guid | null;
};

type Props = InjectedIntlProps & {
  selectedDirectoryId?: Guid;
  showRequestDialog?: boolean;
  additionalDescription?: ReactNode;
};

const DirectoryForbiddenError: FunctionComponent<Props> = ({
  selectedDirectoryId,
  showRequestDialog,
  intl,
  additionalDescription,
  ...restProps
}) => {
  return (
    <Alert
      className={styles.alert}
      message={
        <Typography.Title level={4}>
          <Fmt id="DirectoryForbiddenErrorBox.title" />
        </Typography.Title>
      }
      description={
        <StackPanel vertical>
          {additionalDescription}
          <Fmt id="DirectoryForbiddenErrorBox.description" />
          {showRequestDialog && selectedDirectoryId && (
            <AccessPermissionRequest selectedDirectoryId={selectedDirectoryId} />
          )}
        </StackPanel>
      }
      type="error"
      showIcon
      banner
      {...restProps}
    />
  );
};

export default injectIntl(DirectoryForbiddenError);
