import { useCallback, useState } from 'react';
import {
  ReportViewConfiguration,
  defaultProjectsInRealizationFoldedColumns,
} from './ProjectsInRealizationOverviewReport.reportPresets';

export enum ColumnChangeTypeEnum {
  Visibility = 'Visibility',
  Width = 'Width',
  Position = 'Position',
  ToggleFolding = 'ToggleFolding',
}

export const useConfigurableReportView = () => {
  const [currentViewConfiguration, setCurrentViewConfiguration] = useState<ReportViewConfiguration>();

  const handleColumnsChange = useCallback(
    (
      changeType: ColumnChangeTypeEnum,
      columnIndex: number | null,
      nestedIndex: number | null,
      oldValue?: boolean | number,
      newValue?: boolean | number
    ) => {
      // Timeout is required for DevExtreeme treelist to prevent bouncing back default previous value by modifiyng column property
      setTimeout(() => {
        switch (changeType) {
          case ColumnChangeTypeEnum.Visibility:
            setCurrentViewConfiguration((config) => ({
              ...config,
              columns: config?.columns.map((column, index) =>
                index === columnIndex
                  ? nestedIndex === null
                    ? { ...column, visible: newValue as boolean }
                    : {
                        ...column,
                        columns: (
                          column.columns || defaultProjectsInRealizationFoldedColumns
                        ).map((nestedColumn, nestedColumnIndex) =>
                          nestedColumnIndex === nestedIndex
                            ? { ...nestedColumn, visible: newValue as boolean }
                            : nestedColumn
                        ),
                      }
                  : column
              ),
            }));
            break;
          case ColumnChangeTypeEnum.Width:
            setCurrentViewConfiguration((config) => ({
              ...config,
              columns:
                config?.columns.map((column, index) =>
                  index === columnIndex
                    ? nestedIndex === null
                      ? { ...column, width: newValue as number }
                      : {
                          ...column,
                          columns: (
                            column.columns || defaultProjectsInRealizationFoldedColumns
                          ).map((nestedColumn, nestedColumnIndex) =>
                            nestedColumnIndex === nestedIndex
                              ? { ...nestedColumn, width: newValue as number }
                              : nestedColumn
                          ),
                        }
                    : column
                ) || [],
            }));
            break;
          case ColumnChangeTypeEnum.Position:
            if (nestedIndex === null) {
              setCurrentViewConfiguration((config) => {
                const reorderedArray = [...config.columns];
                const column = reorderedArray.splice(oldValue as number, 1)[0];
                reorderedArray.splice(newValue as number, 0, column);
                return { ...config, columns: reorderedArray };
              });
              break;
            }
            setCurrentViewConfiguration((config) => ({
              ...config,
              columns: config.columns.map((currentColumn, index) => {
                if (index !== columnIndex) return currentColumn;
                const reorderedArray = [...(currentColumn.columns || [])];
                const subcolumn = reorderedArray.splice(oldValue as number, 1)[0];
                reorderedArray.splice(newValue as number, 0, subcolumn);
                return { ...currentColumn, columns: reorderedArray };
              }),
            }));
            break;
          case ColumnChangeTypeEnum.ToggleFolding: {
            setCurrentViewConfiguration((config) => {
              return {
                ...config,
                columns:
                  config?.columns.map((column, index) =>
                    index === columnIndex
                      ? !nestedIndex
                        ? { ...column, folded: !column.folded }
                        : {
                            ...column,
                            columns: (
                              column.columns || defaultProjectsInRealizationFoldedColumns
                            ).map((nestedColumn, nestedColumnIndex) =>
                              nestedColumnIndex === nestedIndex
                                ? { ...nestedColumn, folded: !nestedColumn.folded }
                                : nestedColumn
                            ),
                          }
                      : column
                  ) || [],
              };
            });
            break;
          }
        }
      }, 1);
    },
    []
  );

  return [currentViewConfiguration, setCurrentViewConfiguration, handleColumnsChange] as const;
};
