import { InputRef } from 'antd';
import { useFocus } from 'hooks/useFocus';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import {
  DiscussionAttachmentCreateForm,
  DiscussionAttachmentCreateFormData,
  DiscussionRelationOption,
} from './DiscussionAttachmentCreateForm';

type Props = FormModalProps<DiscussionAttachmentCreateFormData> & {
  titleId?: IntlMessageId;
  initialFile?: File;
  discussionRelationOptions?: DiscussionRelationOption<string | number>[];
};

export const DiscussionAttachmentCreateFormModal: FunctionComponent<Props> = (props) => {
  const { visible, onSubmit, onClose, initialFile, titleId, discussionRelationOptions } = props;

  const handleSubmit = useCallback(
    async (values: DiscussionAttachmentCreateFormData) => {
      onSubmit(values);
      return null;
    },
    [onSubmit]
  );

  const { setInputRef } = useFocus<InputRef>(visible);

  return (
    <FormModalWrapper
      visible={visible}
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId={titleId || 'AttachmentCreateFormModal.title'}
      width={800}
    >
      {({ intl, formRef }) => (
        <DiscussionAttachmentCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          initialFile={initialFile}
          discussionRelationOptions={discussionRelationOptions}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};
