import { AccessLevelEnum, DocumentRevisionStateEnum, MultiUploadCheckResponseDto } from 'api/completeApiInterfaces';
import {
  FileItem,
  FileSystemTreeNode,
  FileTreeNode,
  FileUploadError,
  FolderItem,
  FolderTreeNode,
  RevisionFileItem,
  TreeCheckData,
  TreeNodeStatus,
  TreeStatistics,
} from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { EMPTY_GUID } from 'config/constants';
import React from 'react';
import { strCompareCI } from 'utils';
import { v4 as uuid } from 'uuid';

export const treeToStructure = (
  items: FileSystemTreeNode[],
  ignoreUnchecked: boolean = false
): [FileItem[], RevisionFileItem[], FolderItem[]] => {
  const files: FileItem[] = [];
  const revisions: RevisionFileItem[] = [];
  const folders: FolderItem[] = [];

  items.forEach((item) => {
    if (!ignoreUnchecked && !item.checked && !(item.type === 'folder' && item.halfChecked)) return;

    if (item.type === 'file') {
      if (item.isRevision) {
        revisions.push({
          title: item.title,
          file: item.file,
          path: item.path,
          key: item.key,
          documentId: item.revisionDocumentId,
        });
      } else {
        files.push({
          title: item.title,
          file: item.file,
          path: item.path,
          key: item.key,
        });
      }
    } else {
      folders.push({
        title: item.title,
        path: item.path,
        key: item.key,
        uploadErrors: item.errors,
      });
      const [childFiles, childRevisions, childFolders] = treeToStructure(item.children, ignoreUnchecked);
      files.push(...childFiles);
      revisions.push(...childRevisions);
      folders.push(...childFolders);
    }
  });
  return [files, revisions, folders];
};

export const calculateTreeStatistics = (items: FileSystemTreeNode[]): TreeStatistics => {
  const folderItems = items.filter((f) => f.type === 'folder') as FolderTreeNode[];
  const fileItems = items.filter((f) => f.type === 'file') as FileTreeNode[];
  const statistics = {
    totalDirectoryCount: folderItems.length,
    totalFileCount: fileItems.length,
    errorFiles: fileItems.filter(
      (f) =>
        f.checked &&
        (f.invalidName ||
          f.notWritable ||
          (!!f.accessRight && (f.accessRight === AccessLevelEnum.none || f.accessRight === AccessLevelEnum.read)))
    ).length,
    errorDirectories: folderItems.filter(
      (f) =>
        f.checked &&
        (f.invalidName ||
          f.notWritable ||
          (!!f.accessRight && (f.accessRight === AccessLevelEnum.none || f.accessRight === AccessLevelEnum.read)))
    ).length,
    blockedRevisions: fileItems.filter(
      (f) => f.checked && f.isRevision && !!f.revisionStatus && f.revisionStatus !== DocumentRevisionStateEnum.Ok
    ).length,
    duplicateDirectories: folderItems.filter((f) => f.checked && f.duplicate).length,
    duplicateFiles: fileItems.filter((f) => f.checked && f.duplicate && !f.isRevision).length,
  } as TreeStatistics;

  folderItems.forEach((node) => {
    const childStatistics = calculateTreeStatistics(node.children);
    statistics.totalDirectoryCount += childStatistics.totalDirectoryCount;
    statistics.totalFileCount += childStatistics.totalFileCount;
    statistics.errorFiles += childStatistics.errorFiles;
    statistics.errorDirectories += childStatistics.errorDirectories;
    statistics.duplicateFiles += childStatistics.duplicateFiles;
    statistics.duplicateDirectories += childStatistics.duplicateDirectories;
    statistics.blockedRevisions += childStatistics.blockedRevisions;
  });

  return statistics;
};

function shouldFilterOutFile(item: FileSystemEntry) {
  return item.name === 'desktop.ini';
}

export function isFile(item: FileSystemEntry): item is FileSystemFileEntry {
  return item.isFile;
}

export function isDirectory(item: FileSystemEntry): item is FileSystemDirectoryEntry {
  return item.isDirectory;
}

export const traverseFileTree = async (
  items: FileSystemEntry[],
  path: string = '',
  disableTreeStructure: boolean = false,
  level: number = 0
): Promise<[FileSystemTreeNode[], FileUploadError[]]> => {
  const result: FileSystemTreeNode[] = [];
  let errors: FileUploadError[] = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (isFile(item)) {
      if (shouldFilterOutFile(item)) {
        continue;
      }
      try {
        const file: File = await new Promise((resolve, reject) => {
          item.file(resolve, reject);
        });
        result.push(createFileTreeNode(file, path));
      } catch (e) {
        errors.push({
          code: 'pathTooLong',
          type: 'file',
          name: item.name,
          fullPath: item.fullPath,
          messageId: 'CommonFilesInput.treeLoading.fileErrorInfo',
          error: e,
        });
      }
    } else if (isDirectory(item)) {
      const entries: FileSystemEntry[] = [];
      try {
        const dirReader = item.createReader();
        let partialEntries: FileSystemEntry[] = [];
        do {
          partialEntries = await new Promise((resolve, reject) => {
            try {
              dirReader.readEntries(resolve, reject);
            } catch (e) {
              reject(e);
            }
          });
          entries.push(...partialEntries);
        } while (!!partialEntries && partialEntries.length !== 0);
      } catch (e) {
        errors.push({
          code: 'pathTooLong',
          type: 'folder',
          name: item.name,
          fullPath: item.fullPath,
          messageId: 'CommonFilesInput.treeLoading.folderErrorInfo',
          error: e,
        });
      }
      const currentPath = `${path}/${item.name}`;
      const [children, childErrors] = await traverseFileTree(entries, currentPath, disableTreeStructure, level + 1);
      if (disableTreeStructure) {
        result.push(...children);
        errors = [...errors, ...childErrors];
      } else {
        const foldersCount = children.filter((item) => item.type === 'folder').length;
        const filesCount = children.length - foldersCount;
        const folderErrors = [...errors, ...childErrors];
        const newNode = createFolderTreeNode(item, currentPath, children, filesCount, foldersCount, folderErrors);
        errors = [];
        result.push(newNode);
      }
    }
  }

  sortTree(result);

  return [result, errors];
};

export const removeFromTree = (tree: FileSystemTreeNode[], keys: Guid[]): FileSystemTreeNode[] => {
  return tree
    .filter((t) => {
      return !keys.some((k) => t.key === k);
    })
    .map((node) => {
      if (node.type === 'folder') {
        const children = removeFromTree(node.children, keys);
        const filesCount = children.filter((item) => item.type === 'file').length;
        const foldersCount = children.length - filesCount;

        return { ...node, children, filesCount, foldersCount };
      }
      return node;
    });
};

export const includeToTree = (tree: FileSystemTreeNode[], keys: Guid[]): FileSystemTreeNode[] => {
  return tree.map((node) => {
    const removed = keys.some((k) => k === node.key) ? false : node.removed;
    if (node.type === 'folder') {
      return { ...node, children: removeFromTree(node.children, keys), removed: removed };
    }
    return { ...node, removed };
  });
};

export const applyTreeCheckData = (
  tree: FileSystemTreeNode[],
  checkData: MultiUploadCheckResponseDto
): FileSystemTreeNode[] => {
  return tree.map((node) => {
    const duplicate = checkIsDuplicated(node, checkData);
    const notWritable = !checkIsWriteable(node, checkData);
    const invalidName = !checkIsValidName(node, checkData);
    const accessRight = getAccessRight(node, checkData);

    if (node.type === 'folder') {
      return {
        ...node,
        children: applyTreeCheckData(node.children, checkData),
        duplicate: duplicate,
        notWritable: notWritable,
        invalidName: invalidName,
        accessRight: accessRight,
      };
    }

    const [revisionStatus, revisionDocumentId] = getRevisionData(node, checkData);

    return {
      ...node,
      duplicate: duplicate,
      notWritable: notWritable,
      invalidName: invalidName,
      accessRight: accessRight,
      isRevision: node.isRevision && duplicate,
      revisionStatus: revisionStatus,
      revisionDocumentId: revisionDocumentId,
    };
  });
};

export const checkIsDuplicated = (item: FileSystemTreeNode, checkData?: MultiUploadCheckResponseDto) => {
  return (
    !!checkData &&
    (item.type === 'folder'
      ? !!checkData.duplicateDirectories[item.path]
      : checkData.duplicateDocuments.some(
          (d) => d.requestedFile.name === item.title && d.requestedFile.path === item.path
        ))
  );
};

export const checkIsWriteable = (item: FileSystemTreeNode, checkData?: MultiUploadCheckResponseDto) => {
  if (!checkData) return true;
  switch (item.type) {
    case 'file':
      return !checkData?.notWritableDocuments.some((p) => p.path === item.path && p.name === item.title);
    case 'folder':
      return !checkData?.notWritableDirectories.some((p) => p === item.path);
  }
  return true;
};

export const checkIsValidName = (item: FileSystemTreeNode, checkData?: MultiUploadCheckResponseDto) => {
  if (!checkData) return true;
  switch (item.type) {
    case 'file':
      return !checkData?.invalidNameDocuments.some((p) => p.path === item.path && p.name === item.title);
    case 'folder':
      return !checkData?.invalidNameDirectories.some((p) => p === item.title);
  }
};

export const getAccessRight = (item: FileSystemTreeNode, checkData?: MultiUploadCheckResponseDto) => {
  if (!checkData) return undefined;
  switch (item.type) {
    case 'folder':
      return checkData?.duplicateDirectories?.[item.path]?.currentAccessLevel;
  }
  return undefined;
};

export const getRevisionData = (
  item: FileSystemTreeNode,
  checkData?: MultiUploadCheckResponseDto
): [DocumentRevisionStateEnum, Guid] => {
  if (!checkData) return undefined;
  if (item.type == 'file') {
    const duplicateDocument = checkData?.duplicateDocuments?.find(
      (f) => f.requestedFile.path === item.path && f.requestedFile.name === item.title
    )?.document;
    return [duplicateDocument?.addRevisionState, duplicateDocument?.id];
  }
  return [undefined, EMPTY_GUID];
};

export const filterDuplicated = (
  tree: FileSystemTreeNode[],
  filterType: 'file' | 'folder' = 'folder'
): FileSystemTreeNode[] => {
  return tree
    .filter((node) => {
      return node.type !== filterType || !node.duplicate;
    })
    .map((node) => {
      return node.type === 'folder' ? { ...node, children: filterDuplicated(node.children, filterType) } : node;
    });
};

export const filterDuplicatedNewDocuments = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return tree
    .filter((node) => {
      return node.type === 'folder' || (node.type === 'file' && (!node.duplicate || node.isRevision));
    })
    .map((node) => {
      return node.type === 'folder' ? { ...node, children: filterDuplicatedNewDocuments(node.children) } : node;
    });
};

export const filterDuplicatedRevisions = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return tree
    .filter((node) => {
      return node.type === 'folder' || (node.type === 'file' && (!node.duplicate || !node.isRevision));
    })
    .map((node) => {
      return node.type === 'folder' ? { ...node, children: filterDuplicatedNewDocuments(node.children) } : node;
    });
};

export const filterBlockedRevisions = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return tree
    .filter((node) => {
      return (
        node.type === 'folder' ||
        (node.type === 'file' && (!node.isRevision || node.revisionStatus === DocumentRevisionStateEnum.Ok))
      );
    })
    .map((node) => {
      return node.type === 'folder' ? { ...node, children: filterBlockedRevisions(node.children) } : node;
    });
};

export const filterNotWritable = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return tree
    .filter((node) => !node.notWritable)
    .map((node) => (node.type === 'folder' ? { ...node, children: filterNotWritable(node.children) } : node));
};

export const filterInvalidName = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return tree
    .filter((node) => !node.invalidName)
    .map((node) => (node.type === 'folder' ? { ...node, children: filterInvalidName(node.children) } : node));
};

export const getNodesWithError = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.invalidName || item.notWritable);
};

export const getNodesWithInvalidName = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.invalidName);
};

export const getNodesWithNotWritable = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.notWritable);
};

export const getNodesWithDuplicate = (
  tree: FileSystemTreeNode[],
  filterType: 'file' | 'folder' = 'folder'
): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.type === filterType && item.duplicate);
};

export const getNodesWithNewDocuments = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.type === 'file' && item.duplicate && !item.isRevision);
};

export const getNodesWithRevision = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(tree, (item) => item.type === 'file' && item.duplicate && item.isRevision);
};

export const getNodesWithBlockedRevision = (tree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  return getNodesWithCallback(
    tree,
    (item) =>
      item.type === 'file' && item.duplicate && item.isRevision && item.revisionStatus !== DocumentRevisionStateEnum.Ok
  );
};

export const getNodesWithCallback = (
  tree: FileSystemTreeNode[],
  acceptingFn: (item: FileSystemTreeNode) => boolean
): FileSystemTreeNode[] => {
  const branches: FileSystemTreeNode[] = [];

  tree.forEach((item) => {
    if (acceptingFn(item)) {
      branches.push(item);
    }
    if (item.type === 'folder') {
      branches.push(...getNodesWithCallback(item.children, acceptingFn));
    }
  });
  return branches;
};

export const sortTree = (tree: (FileTreeNode | FolderTreeNode)[]) => {
  return tree.sort((a, b) => (a.type === b.type ? strCompareCI(a.title, b.title) : a.type === 'folder' ? -1 : 1));
};

export const mergeTrees = (originTree: FileSystemTreeNode[], addedTree: FileSystemTreeNode[]): FileSystemTreeNode[] => {
  const tree = [...originTree, ...addedTree];
  sortTree(tree);
  return tree.filter((node, index) => {
    if (index > 0) {
      return tree[index - 1].title !== node.title;
    }
    return true;
  });
};

export const replaceTreeBranches = (
  originTree: FileSystemTreeNode[],
  newBranches: FileSystemTreeNode[]
): FileSystemTreeNode[] => {
  return originTree.map((node) => {
    if (newBranches.some((branch) => branch.key === node.key)) {
      return newBranches.find((branch) => branch.key === node.key);
    }

    if (node.type === 'folder') {
      return { ...node, children: replaceTreeBranches(node.children, newBranches) };
    }
    return node;
  });
};

export const setRevisionStatus = (tree: FileSystemTreeNode[], revisionStatus: boolean): FileSystemTreeNode[] => {
  return tree.map((node) => {
    if (node.type === 'file' && node.duplicate) {
      node.isRevision = revisionStatus;
    }
    return node.type === 'folder' ? { ...node, children: setRevisionStatus(node.children, revisionStatus) } : node;
  });
};

export const getChecked = (tree: FileSystemTreeNode[]): Guid[] => {
  const checked: Guid[] = [];
  tree.forEach((node) => {
    node.checked && checked.push(node.key);
    if (node.type === 'folder') checked.push(...getChecked(node.children));
  });
  return checked;
};

export const getHalfChecked = (tree: FileSystemTreeNode[]): Guid[] => {
  const checked: Guid[] = [];
  tree.forEach((node) => {
    if (node.type === 'folder') {
      node.halfChecked && checked.push(node.key);
      checked.push(...getHalfChecked(node.children));
    }
  });
  return checked;
};

export const setNodesChecked = (
  tree: FileSystemTreeNode[],
  checkedItems: TreeCheckData,
  chainCheck: boolean = false
): FileSystemTreeNode[] => {
  return tree.map((node) => {
    const fullChecked = chainCheck || checkedItems.checked.includes(node.key);

    if (node.type === 'folder') {
      const children = setNodesChecked(node.children, checkedItems, fullChecked);

      return {
        ...node,
        checked: children.length > 0 ? !children.some((f) => !f.checked) : fullChecked,
        halfChecked:
          children.some((f) => f.type === 'folder' && f.halfChecked) ||
          (children.some((f) => f.checked) && children.some((f) => !f.checked)),
        children: children,
      };
    }
    return { ...node, checked: fullChecked };
  });
};

export const setNodesUnchecked = (tree: FileSystemTreeNode[], checkedItems: TreeCheckData): FileSystemTreeNode[] => {
  return tree.map((node) => {
    if (node.type === 'folder') {
      const children = setNodesUnchecked(node.children, checkedItems);

      return {
        ...node,
        checked: children.length > 0 ? !children.some((f) => !f.checked) : checkedItems.checked.includes(node.key),
        halfChecked:
          children.some((f) => f.type === 'folder' && f.halfChecked) ||
          (children.some((f) => f.checked) && children.some((f) => !f.checked)),
        children: children,
      };
    }
    return { ...node, checked: checkedItems.checked.includes(node.key) };
  });
};

export const getTreeNodeKeys = (tree: FileSystemTreeNode[]): Guid[] => {
  const nodeKeys: Guid[] = [];
  tree.forEach((node) => {
    nodeKeys.push(node.key);
    if (node.type === 'folder') nodeKeys.push(...getChecked(node.children));
  });
  return nodeKeys;
};

export const createFileTreeNode = (f: File, path: string = ''): FileTreeNode & TreeNodeStatus => ({
  file: f,
  removed: false,
  key: uuid(),
  title: f.name,
  path: path,
  type: 'file',
  isRevision: false,
  checked: true,
  duplicate: false,
  invalidName: false,
  notWritable: false,
  revisionDocumentId: EMPTY_GUID,
});

export const createFolderTreeNode = (
  directory: FileSystemDirectoryEntry,
  path: string = '',
  children: FileSystemTreeNode[],
  filesCount: number,
  foldersCount: number,
  errors: FileUploadError[]
): FolderTreeNode & TreeNodeStatus => ({
  key: uuid(),
  removed: false,
  type: 'folder',
  title: directory.name,
  filesCount,
  foldersCount,
  path,
  children,
  checked: true,
  halfChecked: false,
  duplicate: false,
  invalidName: false,
  notWritable: false,
  errors,
});

export const onDropHandler = async (
  event: React.DragEvent<HTMLElement>,
  disableTreeStructure: boolean
): Promise<[FileSystemTreeNode[], FileUploadError[]]> => {
  if (event.dataTransfer) {
    const items = Object.values(event.dataTransfer.items);
    return await traverseFileTree(
      items?.map((item: DataTransferItem) => item.webkitGetAsEntry())?.filter((item: any | null) => !!item),
      '',
      disableTreeStructure
    );
  }
  return undefined;
};
