import { to } from 'api/await-to';
import { RoleCreateDto, RoleDto, RolePatchDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const rolesApi = {
  listRoles: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<RoleDto[]>(`/roles`, { cancelToken })
    ),
  createRole: (data: RoleCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<RoleDto>('/roles', data, { cancelToken })
    ),
  getRoleById: (roleId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<RoleDto>(`/roles/${roleId}`, { cancelToken })
    ),
  updateRoleById: (roleId: Guid, role: RolePatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<RoleDto>(`/roles/${roleId}`, role, { cancelToken })
    ),
  deleteRoleById: (roleId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/roles/${roleId}`, { cancelToken })),
};
