import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Empty, Spin, Switch, Typography } from 'antd';
import { AccessLevelEnum } from 'api/completeApiInterfaces';
import AccessLevelList from 'components/AccessLevelList';
import { AccessLevelListDataSource } from 'components/AccessLevelList/AccessLevelList';
import { Margin } from 'components/Margin/Margin';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import styles from './DirectorySettingsPermissionsForm.module.less';

type UserProfile = { id: Guid; username?: string };

export type DirectorySettingsFormProps<T extends UserProfile> = {
  inheritPermissions: boolean;
  intl: InjectedIntl;
  searchUsersValue?: string;
  usersList?: T[];
  searchedUsers?: T[];
  userAccessForDisplay?: AccessLevelListDataSource[];
  showIndividualUserAccessSettings: boolean;
  groupsAccessForDisplay: AccessLevelListDataSource[];
  directoryId: Guid;
  handleSetPermissionsInheritance: (inheritPermissions: boolean) => void;
  handleAddUser?: (userId: Guid) => void;
  handleSetUser?: (accessLevel: AccessLevelEnum, userId: Guid) => void;
  handleUserSearch?: (value: string) => void;
  handleRemoveUser?: (userId: Guid) => void;
  handleChangeGroupAccess: (accessLevel: AccessLevelEnum, groupId: Guid) => void;
  handleRemoveGroup: (groupId: Guid) => void;
};

type Props<T extends UserProfile> = DirectorySettingsFormProps<T> & InjectedIntlProps;

const DirectorySettingsPermissionsDrawer: FunctionComponent<Props<UserProfile>> = ({
  inheritPermissions,
  intl,
  searchUsersValue,
  usersList,
  searchedUsers,
  userAccessForDisplay,
  groupsAccessForDisplay,
  showIndividualUserAccessSettings,
  directoryId,
  handleSetPermissionsInheritance,
  handleAddUser,
  handleSetUser,
  handleUserSearch,
  handleRemoveUser,
  handleChangeGroupAccess,
  handleRemoveGroup,
}) => {
  return (
    <>
      <div className={styles.inheritanceSwitch}>
        <Switch
          checked={inheritPermissions}
          disabled={false}
          loading={false}
          onChange={handleSetPermissionsInheritance}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <span style={{ marginLeft: 8 }}>
          {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.inheritLabel' })}
        </span>
      </div>
      {!!showIndividualUserAccessSettings && (
        <SettingsBox>
          <Typography.Title level={4}>
            {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.users' })}
          </Typography.Title>
          <AutoComplete
            value={searchUsersValue}
            style={{ width: '100%' }}
            onSelect={handleAddUser}
            onSearch={handleUserSearch}
            placeholder={intl.formatMessage({ id: 'DirectorySettingsForm.userAccessTab.searchButton.placeholder' })}
          >
            {usersList === null ? (
              <AutoComplete.Option key="loading" disabled={true} style={{ textAlign: 'center' }}>
                <Spin spinning={true} delay={500} />
              </AutoComplete.Option>
            ) : searchedUsers.length === 0 ? (
              <AutoComplete.Option key="empty" disabled={true} style={{ textAlign: 'center' }}>
                <Empty />
              </AutoComplete.Option>
            ) : (
              searchedUsers.map((user) => <AutoComplete.Option key={user.id}>{user.username}</AutoComplete.Option>)
            )}
          </AutoComplete>
          <Margin top />
          {userAccessForDisplay && (
            <div>
              {userAccessForDisplay.length > 0 ? (
                <AccessLevelList
                  directoryId={directoryId}
                  dataSource={userAccessForDisplay}
                  onChange={handleSetUser}
                  onDelete={handleRemoveUser}
                />
              ) : (
                <Typography.Text type="secondary">
                  {intl.formatMessage({ id: 'DirectorySettingsForm.noUserPermissions' })}
                </Typography.Text>
              )}
            </div>
          )}
        </SettingsBox>
      )}
      <SettingsBox>
        <Typography.Title level={4}>
          {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.groups' })}
        </Typography.Title>
        <AccessLevelList
          directoryId={directoryId}
          dataSource={groupsAccessForDisplay}
          onChange={handleChangeGroupAccess}
          onDelete={handleRemoveGroup}
        />
      </SettingsBox>
    </>
  );
};

export default DirectorySettingsPermissionsDrawer;
