import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

/**
 * Use browser local storage together with remembering the value of this storage on initial load
 */
export const useInitialLocalStorage = <T>(storageKey: string, initialValue?: T) => {
  const [currentStorageValue, setStorage] = useLocalStorage(storageKey, initialValue);

  const initialStorageValue = useMemo(() => currentStorageValue || initialValue, []);

  const setStorageValue = useCallback((updatedValue: T) => {
    setStorage(updatedValue);
  }, []);

  return [currentStorageValue, initialStorageValue, setStorageValue] as const;
};
