import { FilterWithValue, FiltersPersistentKey, FrontendFilter, Serializable } from 'components/filters/filterTypes';
import { FrontendOrderOption, OrderValue } from 'components/filters/orderTypes';
import { useFrontendFilters } from 'hooks';
import React, { createContext, useCallback, useMemo, useState } from 'react';

type Config<T> = {
  filtersOptions?: FrontendFilter<T>[];
  orderOptions?: FrontendOrderOption<T>[];
  persistentKey?: FiltersPersistentKey;
  items?: T[];
};

type Props<T> = Config<T> & {
  children: React.ReactNode;
};

type ValueType = {
  filteredSortedItems: any[];
  hasFilteredOutItems: boolean;
  clearFilters: () => void;
  setFilterValue: (key: string, value: React.SetStateAction<Serializable>) => void;
  setItems: (items: any[]) => void;
  setFiltersConfig: (config: Config<any>) => void;
  itemCounts: string;
  filters: FilterWithValue<Serializable, FrontendFilter<any, Serializable>>[];
  order: OrderValue<string>;
  setOrder: React.Dispatch<React.SetStateAction<OrderValue<string>>>;
  orderOptions: FrontendOrderOption<any>[];
  activeFiltersCount?: number;
  isFilterActive?: boolean;
  toggleFilters?: () => void;
};

export const FiltersContext = createContext<ValueType>(undefined);

export const useFiltersContext = () => {
  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersContextProvider');
  }
  return context;
};

export function FiltersContextProviderComponent<T>({
  children,
  filtersOptions = [],
  orderOptions: defaultOrderOptions = [],
  items = [],
  persistentKey,
}: Props<T>) {
  const [config, setConfig] = useState<Config<T>>({
    filtersOptions,
    orderOptions: defaultOrderOptions,
    persistentKey,
    items,
  });
  const setItems = useCallback((items: T[]) => {
    setConfig((prevConfig) => ({ ...prevConfig, items }));
  }, []);
  const updateConfig = useCallback((config: Config<T>) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...config }));
  }, []);

  const {
    orderedItems,
    clearFilters,
    filters,
    order,
    setFilterValue,
    setOrder,
    hasFilteredOutItems,
    itemCounts,
    isFilterActive,
    toggleFilters,
    orderOptions,
  } = useFrontendFilters<T>(config.filtersOptions, config.orderOptions, config.items, config.persistentKey);

  const activeFiltersCount = useMemo(() => filters.filter((filter) => !filter.isEmpty(filter.value)).length, [filters]);

  const values: ValueType = {
    clearFilters: clearFilters,
    filteredSortedItems: orderedItems,
    filters,
    setFilterValue,
    hasFilteredOutItems,
    setItems,
    setFiltersConfig: updateConfig,
    itemCounts,
    order,
    setOrder,
    orderOptions,
    activeFiltersCount,
    isFilterActive,
    toggleFilters,
  };

  return <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>;
}

export const FiltersContextProvider = React.memo(FiltersContextProviderComponent);
