import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { useCallback, useEffect, useState } from 'react';
import { createRevisionOrDocumentAvailableStates } from 'utils/documentStateUtils';
import { useCurrentProjectUser } from './useUser';

export const useNewDocumentState = (onClose: () => void) => {
  const [newDocumentUsefulStates, setNewDocumentUsefulStates] = useState<WorkflowStateEnum[]>([]);
  const currentUser = useCurrentProjectUser();

  useEffect(() => {
    setNewDocumentUsefulStates(createRevisionOrDocumentAvailableStates(currentUser));
  }, [currentUser]);

  const handleNoUsefulStatusesAlertClose = useCallback(() => {
    setNewDocumentUsefulStates([]);
    onClose();
  }, [onClose]);

  return { newDocumentUsefulStates, handleNoUsefulStatusesAlertClose } as const;
};
