import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import React, { FunctionComponent, useMemo } from 'react';
import { ProjectTemplateData } from '../../ProjectTemplateBuilder';
import ProjectTemplateDirectoryCategoryForm, {
  ProjectTemplateDirectoryCategoryFormData,
} from './ProjectTemplateDirectoryCategoryForm';

type Props = FormModalProps<Guid> & {
  editedCategoryNodeId?: Guid;
  usedTrees: Set<Guid>;
  projectTemplate: ProjectTemplateData;
};

const ProjectTemplateDirectoryCategoryFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedCategoryNodeId, projectTemplate, usedTrees, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ProjectTemplateDirectoryCategoryFormData> = async (values) => {
    onSubmit(values.categoryNodeId);
    return null;
  };

  const editedNode = useMemo(
    () =>
      editedCategoryNodeId
        ? projectTemplate.projectTemplateCategoryNodes.find((node) => node.id === editedCategoryNodeId)
        : undefined,
    [editedCategoryNodeId, projectTemplate.projectTemplateCategoryNodes]
  );

  const defaultCategoryData = useMemo((): ProjectTemplateDirectoryCategoryFormData => {
    if (!editedCategoryNodeId) return undefined;

    return { categoryTreeId: editedNode.templateCategoryTreeId, categoryNodeId: editedNode.id };
  }, [editedCategoryNodeId, editedNode]);

  const availablecategoryList = useMemo(
    () =>
      projectTemplate.projectTemplateCategoryTrees.filter(
        (tree) => !usedTrees.has(tree.id) || editedNode?.templateCategoryTreeId === tree.id
      ),
    [projectTemplate.projectTemplateCategoryTrees, usedTrees, editedNode]
  );

  const isCategoryEditing = !!editedCategoryNodeId;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isCategoryEditing ? 'ProjectTemplateCategoryFormModal.edit.title' : 'ProjectTemplateCategoryFormModal.add.title'
      }
      submitTextId={isCategoryEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <ProjectTemplateDirectoryCategoryForm
          intl={intl}
          wrappedComponentRef={formRef}
          defaults={defaultCategoryData}
          categoryList={availablecategoryList}
          categoryNodes={projectTemplate.projectTemplateCategoryNodes}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectTemplateDirectoryCategoryFormModal;
