import { message } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse, CancelToken } from 'axios';
import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { processApiError } from 'utils';

type Props = {
  itemId?: Guid;
  isOn: boolean;
  setOn?: (id: Guid, cancelToken?: CancelToken) => Promise<[ApiError, AxiosResponse<any>]>;
  setOff?: (id: Guid, cancelToken?: CancelToken) => Promise<[ApiError, AxiosResponse<any>]>;
  onChange?: (isFavorite: boolean) => void;
  onError?: (error: ServiceError) => void;
  render: (isOn: boolean, saving: boolean, onSave: () => void) => ReactNode;
  successMessage?: string;
};

const OnOffButtonWrap: FunctionComponent<Props> = ({
  itemId,
  isOn,
  setOn,
  setOff,
  onChange,
  onError,
  render,
  successMessage,
}) => {
  const [saving, setSaving] = useState<boolean>(false);

  const saveData = useCallback(
    async (isFavorite: boolean) => {
      if (saving) return;
      setSaving(true);
      const [err, res] = isFavorite ? setOn && (await setOn(itemId)) : setOff && (await setOff(itemId));
      if (err) {
        processApiError(err, (error) => {
          onError && onError(error);
        });
      } else {
        onChange && onChange(isFavorite);
        if (!!successMessage) {
          message.success(successMessage);
        }
      }
      setSaving(false);
    },
    [onChange, itemId, onError, setOn, setOff, saving]
  );

  return <>{render(isOn, saving, () => saveData(!isOn))}</>;
};

export default OnOffButtonWrap;
