import classNames from 'classnames';
import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styles from './LinkUnderscored.module.less';

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string;
};

export const LinkExternalUnderscored: FunctionComponent<Props> = ({ children, className, ...props }) => {
  return (
    <a {...props} className={classNames(styles.link, className)}>
      {children}
    </a>
  );
};
