import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import ModelProcessingExportFormModal from './ModelProcessingExportFormModal';

type Props = Pick<ButtonProps, 'type' | 'size' | 'icon'> & { organizationId: Guid };

const ModelProcessingExportButtonComponent: FunctionComponent<Props> = ({ organizationId, ...buttonProps }) => {
  const [isExportVisible, showExportModal, hideExportModal] = useBoolean(false);

  return (
    <>
      <NoClickTooltip title={<Fmt id={'ModelProcessingExportFormModal.buttonTooltip'} />}>
        <Button type="link" icon={<DownloadOutlined />} onClick={showExportModal} {...buttonProps} />
      </NoClickTooltip>
      <ModelProcessingExportFormModal
        visible={isExportVisible}
        onClose={hideExportModal}
        onSubmit={hideExportModal}
        organizationId={organizationId}
      />
    </>
  );
};

export const ModelProcessingExportButton = React.memo(ModelProcessingExportButtonComponent);
