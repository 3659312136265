export const ENV = process.env;
export const NODE_ENV = ENV.NODE_ENV || '';

export const DOCKERENV = (window as any)._env_;

const env: NodeJS.ProcessEnv = DOCKERENV || ENV;

export const BASE_NAME = (env.REACT_APP_BASE_NAME || '').replace(/\/+$/, '');

export const OIDC_PROVIDER = (env.REACT_APP_OIDC_PROVIDER || '').replace(/\/+$/, '');

export const OIDC_CLIENT_ID = env.REACT_APP_OIDC_CLIENT_ID || '';

export const API_BASE_URL = (env.REACT_APP_API_BASE_URL || '').replace(/\/+$/, '');

export const DEBUG: boolean = !!parseInt(env.REACT_APP_DEBUG || '0');

export const OFFICE_DISABLED: boolean = !!parseInt(env.REACT_APP_OFFICE_DISABLED || '0');

export const APP_INSIGHTS_INSTRUMENTATION_KEY = env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || '';

export const DEV_STATUS_TEXT = env.REACT_APP_DEV_STATUS_TEXT || '';

export const APP_LOGO_IMG = (env.REACT_APP_LOGO_IMG || '').replace(/\/+$/, '');
export const APP_LOGO_TITLE = (env.REACT_APP_LOGO_TITLE || '').replace(/\/+$/, '');
export const APP_LOGO_LINK = (env.REACT_APP_LOGO_LINK || '').replace(/\/+$/, '');
