import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { compareByName } from 'utils/comparators';

export const organizationLabelsListSelector = (state: RootState) => state.organizationLabels.data;

export const organizationLabelsMapSelector = createSelector(
  [organizationLabelsListSelector],
  (labels) => labels && keyBy(labels, (label) => label.id)
);

export const organizationLabelsOrderedListSelector = createSelector(
  [organizationLabelsListSelector],
  (labels) => labels && [...labels].sort(compareByName)
);
