import { Spin } from 'antd';
import classNames from 'classnames';
import { AuditLogButton, DeleteButton, EditButton } from 'components/ActionButtons';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './ListItem.module.less';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';

export type ListItemProps = {
  selected?: boolean;
  loading?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onAuditLog?: () => void;
  noConfirmDelete?: boolean;
  additionalActions?: ReactNode;
};

type Props = ListItemProps;

const ListItem: FunctionComponent<Props> = ({
  selected,
  loading,
  onClick,
  onEdit,
  onDelete,
  onAuditLog,
  children,
  noConfirmDelete,
  additionalActions,
}) => {
  return (
    <div className={classNames(styles.listItem, { [styles.selected]: selected })} onClick={onClick}>
      <Spin size="small" spinning={loading}>
        <div className={styles.listItemContent}>
          <div className={styles.listItemBody}>{children}</div>
          <div className={styles.listItemActions}>
            {additionalActions}
            {onAuditLog && <AuditLogButton onClick={onAuditLog} />}
            {onEdit && <EditButton onClick={onEdit} />}
            {!!onDelete &&
              (noConfirmDelete ? <DeleteButton onClick={onDelete} /> : <DeleteButtonConfirm onConfirm={onDelete} />)}
          </div>
        </div>
      </Spin>
    </div>
  );
};

ListItem.defaultProps = {
  selected: false,
  loading: false,
};

export { ListItem };
