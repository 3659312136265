import { Spin } from 'antd';
import React from 'react';
import styles from './LoadingPage.module.less';

const LoadingPage = () => (
  <div className={styles.screen}>
    <Spin size="large" />
  </div>
);

export default LoadingPage;
