import { Input, InputRef, message } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import SpinBox from 'components/SpinBox';
import { useIntl, useIsMounted } from 'hooks';
import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './CommonEditable.module.less';

export type RenderSubmitErrorType<T> = (error: T, resetValues: () => void, resetError: () => void) => ReactNode;

type Props<T> = {
  value: string;
  className?: string;
  disabled?: boolean;
  onSave?: (name: string) => Promise<void>;
  emptyPlaceholder?: string;
  showErrorOnEmpty?: boolean;
  emptyErrorMessage?: string;
  tooltipPlacement?: TooltipPlacement;
  flex?: boolean;
  isMultiline?: boolean;
  autofocus?: boolean;
  maxLength?: number;
  validateOnChange?: (value: string) => boolean | string;
  renderSubmitError?: RenderSubmitErrorType<T>;
  successMessage?: string;
  errorMessage?: string;
  enableSaveEmpty?: boolean;
};

export function CommonEditable<T>({
  value,
  disabled,
  className,
  showErrorOnEmpty,
  emptyErrorMessage,
  emptyPlaceholder,
  tooltipPlacement,
  onSave,
  flex,
  isMultiline,
  autofocus,
  maxLength,
  validateOnChange,
  renderSubmitError,
  successMessage,
  errorMessage,
  enableSaveEmpty,
}: Props<T>) {
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const isMounted = useIsMounted();
  const intl = useIntl();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const [hasError, setHasError] = useState(false);
  const [submitError, setSubmitError] = useState<T>(undefined);

  const innerInputRef = useRef<InputRef>(null);

  const focus = useCallback(() => {
    innerInputRef.current?.focus();
  }, []);

  const resetValues = useCallback(() => {
    setLocalValue(value);
    setSubmitError(undefined);
    setHasError(false);
    setLoading(false);
    focus();
  }, [value]);

  const resetError = useCallback(() => {
    setSubmitError(undefined);
  }, [value]);

  useLayoutEffect(() => {
    if (autofocus && !value) {
      innerInputRef.current?.focus();
    }
  }, []);

  const isEmptyValue = !localValue;

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value.trimStart();
      setSubmitError(undefined);
      if (validateOnChange) {
        if (validateOnChange(value)) {
          setHasError(false);
        } else {
          setHasError(true);
        }
      }
      setLocalValue(value);
    },
    [validateOnChange]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        resetValues();
      }
    },
    [resetValues]
  );

  const handleSave = useCallback(async () => {
    if (value !== localValue) {
      if (isEmptyValue && !enableSaveEmpty) {
        return;
      }
      setLoading(true);
      try {
        if (onSave) {
          await onSave(localValue);
          setSubmitError(undefined);
          successMessage && message.success(successMessage);
        }
      } catch (error) {
        if (!!error) {
          errorMessage && message.error(errorMessage);
          setSubmitError(error as SetStateAction<T>);
        }
      }
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [value, localValue, onSave]);

  const onBlur = useCallback(
    async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.scrollTop = 0;
      await handleSave();
    },
    [handleSave]
  );

  const error = (isEmptyValue && showErrorOnEmpty) || hasError || !!submitError;

  const commonInputProps = {
    disabled: loading,
    readOnly: disabled,
    value: localValue,
    onChange: handleInputChange,
    onKeyDown: handleKeyDown,
    onBlur,
    placeholder:
      (showErrorOnEmpty && (emptyErrorMessage || intl.formatMessage({ id: 'general.notEmptyItemError' }))) ||
      emptyPlaceholder,
    maxLength,
  };

  const inputComponent = isMultiline ? (
    <div className={styles.descriptionWrapper}>
      <Input.TextArea
        {...commonInputProps}
        rows={1}
        className={classNames(styles.textarea, error && styles.error, disabled && styles.disabled, className)}
      />
      <SpinBox cover spinning={loading} delay={0} />
    </div>
  ) : (
    <>
      <Input
        {...commonInputProps}
        onPressEnter={handleSave}
        ref={innerInputRef}
        className={classNames(styles.input, error && styles.error, disabled && styles.disabled, className)}
      />
      <SpinBox cover spinning={loading} delay={0} />
    </>
  );

  return (
    <div className={classNames(styles.container, flex && styles.flex)}>
      {!!value ? (
        <CommonHubTooltip
          title={<div>{value}</div>}
          placement={tooltipPlacement}
          overlayClassName={styles.formatedTooltip}
        >
          {inputComponent}
        </CommonHubTooltip>
      ) : (
        inputComponent
      )}
      {renderSubmitError && renderSubmitError(submitError, resetValues, resetError)}
    </div>
  );
}
