import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './CommonHeader.module.less';

type CommonHeaderProps = {
  details?: ReactNode;
  title?: ReactNode;
  icon?: ReactNode;
  lastStrong?: boolean;
  buttons?: ReactNode;
  alignTop?: boolean;
  padding?: undefined | 'left' | 'normal' | 'all';
  marginBottom?: boolean;
  minHeight?: boolean;
  className?: string;
};

const paddingClass = {
  left: styles.paddingLeft,
  normal: styles.paddingNormal,
  all: styles.paddingAll,
};

export const CommonHeader: FunctionComponent<CommonHeaderProps> = ({
  details,
  icon,
  title,
  buttons,
  alignTop,
  padding,
  marginBottom,
  minHeight,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.header,
        alignTop && styles.alignTop,
        padding && paddingClass[padding],
        marginBottom && styles.marginBottom,
        minHeight && styles.minHeight,
        className
      )}
    >
      {icon && <div className={styles.iconBox}>{icon}</div>}
      <div className={styles.main}>
        {title && <div className={styles.nameRow}>{title}</div>}
        {details}
      </div>
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
};
