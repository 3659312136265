import { Col, Row, Typography } from 'antd';
import {
  AuditLogDto,
  AuditLogUserProfileListDto,
  DirectorySubscriberEnumsWatchDirectoryEnum,
  DocumentSubscriberEnumsWatchDocumentEnum,
  EntityTypesEnum,
  EventNamesEnum,
  ProjectUserProfileDto,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { baseProjectApi } from 'api/project/baseProjectApi';
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import { AuditLogGrid } from 'components/AuditLogsComponents/AuditLogGrid';
import { AuditLogEntityData } from 'components/AuditLogsComponents/EntityData';
import { Margin } from 'components/Margin/Margin';
import PathDisplay from 'components/PathDisplay';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { API_BASE_URL } from 'config/env';
import { useActiveProject, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { messageError } from 'utils';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

type AuditLogData = {
  Id: number;
  EntityType: EntityTypesEnum;
  CreatedDate: IsoDateTime;
  EntityId: Guid;
  EventName: EventNamesEnum;
  CreatedById: Guid;
  EventData: Record<AuditLogEventData, string>;
  EntityData: AuditLogEntityData;
  ParentId?: number;
  ConnectionId?: number;
  WatchedId?: Guid;
  WatchedName: string;
  WatchedLink: string; // url?
  WatchType: DocumentSubscriberEnumsWatchDocumentEnum | DirectorySubscriberEnumsWatchDirectoryEnum;
};

export type SubscriberMessageData = {
  ProjectId: Guid;
  ProjectDescription: string;
  Language: LanguageEnum;
  IsDocSubscriber: boolean;
  AuditLogData: AuditLogData[];
};

const parseAuditLogData = (row: AuditLogData, projectUsers: AuditLogUserProfileListDto[]): AuditLogDto => {
  return {
    id: row.Id,
    connectionId: row.ConnectionId,
    createdBy: projectUsers?.find((user) => user.id === row.CreatedById) || undefined,
    createdDate: row.CreatedDate,
    entityId: row.EntityId,
    entityType: row.EntityType,
    eventName: row.EventName,
    entityData: row.EntityData,
    eventData: row.EventData,
    parentId: row.ParentId,
  };
};

const AuditLogRowViewer: FunctionComponent<{
  row: AuditLogData;
  projectUsers: AuditLogUserProfileListDto[];
}> = ({ row, projectUsers }) => {
  return (
    <Margin top>
      <FlowLayout growFirst>
        <Fmt id={`WatchSwitch.buttonTitle.${row.WatchType}`} />
        <a href={row.WatchedLink}>
          <PathDisplay
            path={row.WatchedName}
            // TODO: this will get the root directory name from the current project, which is incorrect
          />
        </a>
      </FlowLayout>
      <AuditLogGrid logs={[parseAuditLogData(row, projectUsers)]} />
    </Margin>
  );
};

export const SubsciberMessageViewer: MessageViewer = ({
  category,
  messageData,
  projectName,
  projectId,
  organizationName,
}) => {
  const data = messageData as SubscriberMessageData;
  const [projectUsers, setProjectUsers] = useState<AuditLogUserProfileListDto[]>(undefined);

  const intl = useIntl();
  const activeProject = useActiveProject();

  const dispatch = useDispatch<Dispatch>();

  const loadStoreProjectUsers = () => dispatch.projectUsers.loadData({ reload: false });

  useEffect(() => {
    loadStoreProjectUsers();
  }, [projectId]);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const storeProjectUsers = useStoreSelector(projectUsersListSelector);

  const storeProjectUsersMapped: AuditLogUserProfileListDto[] = useMemo(
    () =>
      storeProjectUsers?.map((user) => ({
        id: user.id,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        status: user.status,
        isAppUser: false,
      })),
    [storeProjectUsers]
  );

  const setMessageAuthorsInfo = useCallback(async () => {
    const messageProjectApi = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        ...baseProjectApi.defaults.headers,
      },
    });

    const [err, userData] = await to<AxiosResponse<ProjectUserProfileDto[]>, ApiError>(
      messageProjectApi.get<ProjectUserProfileDto[]>(`/projects/${projectId}/users`)
    );

    if (err) {
      messageError(err, intl);
      return;
    }
    setProjectUsers(
      userData.data.map((user) => ({
        id: user.id,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        status: user.status,
        isAppUser: false, //TODO, prozatimni reseni, aby to nepadalo, probrano s Pavlem, bude predelano s auditlogem pro cely HUB
      }))
    );
    return;
  }, [intl, projectId]);

  useEffect(() => {
    if (activeProject?.id === projectId) {
      setProjectUsers(storeProjectUsersMapped);
    } else {
      void setMessageAuthorsInfo();
    }
  }, [activeProject, projectId, setMessageAuthorsInfo, storeProjectUsersMapped]);

  const header = (
    <Fmt
      id={`MessageCenterPage.message.detail.subscriber.${data.IsDocSubscriber ? 'documentTitle' : 'directoryTitle'}`}
    />
  );

  const content = (
    <>
      <Typography.Text>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
      </MessageViewerDetailGrid>
      {data.AuditLogData.map((log, index) => (
        <AuditLogRowViewer
          key={index} // Audit log is stateless, and log.id can be 0 on multiple different logs
          row={log}
          projectUsers={projectUsers}
        />
      ))}
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
