import { EyeInvisibleOutlined, EyeOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Pagination, message } from 'antd';
import { api } from 'api';
import { MessageListDto, MsgStatusEnum } from 'api/completeApiInterfaces';
import DocumentCheckboxControl from 'components/DocumentCheckboxControl/DocumentCheckboxControl';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import List from 'components/List';
import StackPanel from 'components/StackPanel';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar from 'components/Toolbar/Toolbar';
import { CommonFilterWithValue, Serializable } from 'components/filters/filterTypes';
import { OrderOption, OrderValue } from 'components/filters/orderTypes';
import { OrderFilterToolbar } from 'components/filters/render/OrderFilterToolbar/OrderFilterToolbar';
import { useIntl } from 'hooks';
import { useMultiSelect } from 'hooks/useMultiSelect';
import { indexOf } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { List as VirtualList } from 'react-virtualized';
import { messageError } from 'utils';
import { MessageActive, MessageListItem } from '../messages/MessageListItem';
import { MessagesReducerActions } from '../tabs/MessageCenterMessages';
import { ReportsReducerActions } from '../tabs/MessageCenterReports';
import styles from './MessageGrid.module.less';

const PAGE_SIZE_OPTIONS = ['10', '25', '50', '100'];

export type ProjectMessageNames = {
  name: string;
  organizationName: string;
};

type Props = {
  filters?: CommonFilterWithValue[];
  setFilterValue?: (key: string, value: React.SetStateAction<Serializable>) => void;
  orderOptions?: OrderOption[];
  order?: OrderValue;
  setOrder?: React.Dispatch<React.SetStateAction<OrderValue>>;
  messageList: MessageListDto[];
  currentPageIndex?: number;
  totalMessages: number;
  pageSize?: number;
  messagesDispatch?: React.Dispatch<MessagesReducerActions | ReportsReducerActions>;
  clearFilters: () => void;
  onClick: (id: Guid) => void;
  selectedItemId?: Guid;
  setMessageState?: (selectedMessages: Guid[], status: MsgStatusEnum) => Promise<boolean>;
};

export const MessageGrid: FunctionComponent<Props> = (props) => {
  const {
    filters,
    setFilterValue,
    orderOptions,
    order,
    setOrder,
    messageList = [],
    currentPageIndex,
    totalMessages,
    pageSize,
    messagesDispatch,
    clearFilters,
    onClick,
    selectedItemId,
    setMessageState,
  } = props;

  const listRef = useRef<VirtualList>();
  const intl = useIntl();

  const handleMessageSelect = useCallback(
    (id: Guid) => {
      messagesDispatch && messagesDispatch({ type: 'selectMessage', messageId: id });
      onClick && onClick(id);
    },
    [messagesDispatch]
  );

  const setAllMessagesStateToRead = useCallback(async () => {
    const [error] = await api.master.messageCenter.setAllStatusesAsRead();

    if (error) {
      messageError(error, intl);
      return false;
    }

    return true;
  }, [intl]);

  const [
    selectedItemsIds,
    deselectItems,
    isAllSelected,
    selectAll,
    onSelectFile,
    isAnyItemSelected,
    totalCount,
  ] = useMultiSelect(listRef, messageList);

  const menuItems = useMemo((): ToolbarItemProps[] => {
    return isAnyItemSelected
      ? [
          {
            key: 'markAsRead',
            icon: <EyeOutlined />,
            label: 'MessageGrid.changestateButton.markAsRead',
            onClick: async () => {
              const isSuccessful = await setMessageState([...selectedItemsIds], MsgStatusEnum.read);

              if (isSuccessful) {
                messagesDispatch({
                  type: 'setMessagesState',
                  messages: [...selectedItemsIds],
                  state: MsgStatusEnum.read,
                });
              } else {
                void message.error(intl.formatMessage({ id: 'general.failedAction' }));
              }
            },
          },
          {
            key: 'markAsUnread',
            icon: <EyeInvisibleOutlined />,
            label: 'MessageGrid.changestateButton.markAsUnread',
            onClick: async () => {
              const isSuccessful = await setMessageState([...selectedItemsIds], MsgStatusEnum.new);
              if (isSuccessful) {
                messagesDispatch({
                  type: 'setMessagesState',
                  messages: [...selectedItemsIds],
                  state: MsgStatusEnum.new,
                });
              } else {
                void message.error(intl.formatMessage({ id: 'general.failedAction' }));
              }
            },
          },
          {
            key: 'markAllAsRead',
            icon: <FileDoneOutlined />,
            label: 'MessageGrid.changestateButton.markAllAsRead',
            onClick: async () => {
              const isSuccessful = await setAllMessagesStateToRead();
              if (isSuccessful) {
                messagesDispatch({
                  type: 'setMessagesState',
                  messages: messageList.map((msg) => msg.id),
                  state: MsgStatusEnum.read,
                });
              }
            },
          },
        ]
      : [
          {
            key: 'markAllAsRead',
            icon: <FileDoneOutlined />,
            label: 'MessageGrid.changestateButton.markAllAsRead',
            onClick: async () => {
              await setAllMessagesStateToRead();
              messagesDispatch({
                type: 'setMessagesState',
                messages: messageList.map((msg) => msg.id),
                state: MsgStatusEnum.read,
              });
            },
          },
        ];
  }, [isAnyItemSelected, messageList, messagesDispatch, selectedItemsIds, setAllMessagesStateToRead, setMessageState]);

  const handlePaginatorChange = useCallback(
    (currentPage: number, pageSize: number) => {
      messagesDispatch({ type: 'changePage', currentPageIndex: currentPage - 1, pageSize: pageSize });
    },
    [messagesDispatch]
  );

  const itemCounts = useMemo(() => {
    const firstItem = currentPageIndex * pageSize + 1;
    const lastItem = Math.min((currentPageIndex + 1) * pageSize, totalMessages);
    // TODO: add all messages count (without filters)?
    return `${firstItem}-${lastItem} / ${totalMessages}`;
  }, [currentPageIndex, pageSize, totalMessages]);

  return (
    <>
      <StackPanel stretch vertical className={styles.container}>
        <Toolbar
          leftContent={
            <>
              <DocumentCheckboxControl
                selectAll={selectAll}
                selectedFilesIds={selectedItemsIds}
                totalItemsCount={totalCount}
                isAnyItemSelected={isAnyItemSelected}
                deselectAll={deselectItems}
                allFilesSelected={isAllSelected}
              />
            </>
          }
        >
          <ShrinkableToolbar items={menuItems} />
        </Toolbar>
        {(filters || orderOptions) && (
          <DocumentsGridHeaderStyled>
            <OrderFilterToolbar
              filters={filters || []}
              setFilterValue={setFilterValue}
              orderOptions={orderOptions || []}
              order={order}
              setOrder={setOrder}
              itemCounts={itemCounts}
              alignRight
              clearFilters={clearFilters}
            />
          </DocumentsGridHeaderStyled>
        )}
        <StackPanel vertical padding scrollable>
          <List<MessageListDto>
            data={messageList}
            renderItem={(item) => {
              return (
                <MessageListItem
                  key={item.id}
                  message={item}
                  active={selectedItemId === item.id ? MessageActive.Highlighted : MessageActive.Normal}
                  onMessageSelect={handleMessageSelect}
                  selectedItemsIds={selectedItemsIds}
                  onCheckboxSelect={() => onSelectFile && onSelectFile(item.id, indexOf(messageList, item))}
                />
              );
            }}
          />
        </StackPanel>
        <Pagination
          onShowSizeChange={handlePaginatorChange}
          onChange={handlePaginatorChange}
          defaultCurrent={1}
          current={currentPageIndex + 1}
          total={totalMessages}
          pageSize={pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          className={styles.pagination}
        />
      </StackPanel>
    </>
  );
};
