import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import StackPanel from 'components/StackPanel';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  empty: boolean;
  hasUnfilteredItems?: boolean;
  clearSearch?: () => void;
  noItemsMessage?: ReactNode;
  noFilteredItemsMessage?: ReactNode;
  addNewItemButton?: ReactNode;
};

export const EmptyGate: FunctionComponent<Props> = ({
  empty,
  hasUnfilteredItems,
  clearSearch,
  noItemsMessage,
  noFilteredItemsMessage,
  children,
  addNewItemButton,
}) => {
  if (empty && hasUnfilteredItems) {
    return (
      <StackPanel vertical centerItems justifyContent="centerContent" padding>
        <Empty
          image={<FilterOutlined style={{ fontSize: '7em' }} />}
          description={noFilteredItemsMessage || <Fmt id="ListEmpty.noItemsFound" />}
        />
        {clearSearch && (
          <Button type="primary" ghost icon={<CloseOutlined />} onClick={clearSearch} style={{ marginTop: '1rem' }}>
            <Fmt id={'general.clearFilters'} />
          </Button>
        )}
      </StackPanel>
    );
  }

  if (empty) {
    return (
      <StackPanel vertical centerItems justifyContent="centerContent" padding>
        <Empty description={noItemsMessage || <Fmt id="general.noItems" />} />
        {addNewItemButton}
      </StackPanel>
    );
  }

  return <>{children}</>;
};
