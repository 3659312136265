import { to } from 'api/await-to';
import {
  AssignmentAttachmentCreateMultipleDto,
  AssignmentCreateDto,
  AssignmentDocumentsPatchDto,
  AssignmentDto,
  AssignmentNoteCreateDto,
  AssignmentNoteEditDto,
  AssignmentPatchDto,
  AssignmentSetStateDto,
  AssignmentSolversPatchDto,
  DownloadUrl,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const assignmentsApi = {
  createAssignment: (data: AssignmentCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/createassignment`, data, { cancelToken })
    ),
  updateAssignment: (assignmentId: Guid, data: AssignmentPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/updateassignment`, data, { cancelToken })
    ),
  updateSolvers: (assignmentId: Guid, data: AssignmentSolversPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/updatesolvers`, data, { cancelToken })
    ),
  updateDocuments: (assignmentId: Guid, data: AssignmentDocumentsPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/updatedocuments`, data, { cancelToken })
    ),
  changeState: (assignmentId: Guid, data: AssignmentSetStateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/changestate`, data, { cancelToken })
    ),
  addAttachments: (assignmentId: Guid, data: AssignmentAttachmentCreateMultipleDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/addattachments`, data, { cancelToken })
    ),
  attachment: {
    download: (assignmentId: Guid, attachmentId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.get<DownloadUrl>(`/assignments/${assignmentId}/attachment/${attachmentId}/download`, {
          cancelToken,
        })
      ),
  },
  discussion: {
    addNote: (assignmentId: Guid, data: AssignmentNoteCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<AssignmentDto>(`/assignments/${assignmentId}/discussion/addnote`, data, { cancelToken })
      ),
    editNote: (assignmentId: Guid, assignmentNodeId: Guid, data: AssignmentNoteEditDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.patch<AssignmentDto>(`assignments/${assignmentId}/discussion/${assignmentNodeId}/edit`, data, {
          cancelToken,
        })
      ),
    removeNote: (assignmentId: Guid, assignmentNoteId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.delete<void>(`assignments/${assignmentId}/discussion/${assignmentNoteId}`, {
          cancelToken,
        })
      ),
    restoreNote: (assignmentId: Guid, assignmentNoteId: Guid, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<AssignmentDto>(
          `assignments/${assignmentId}/discussion/${assignmentNoteId}/restore`,
          {},
          {
            cancelToken,
          }
        )
      ),
  },
};
