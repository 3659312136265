import { to } from 'api/await-to';
import { SignDocumentRequestDto, SignDocumentResponseDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';
export const documentSigningApi = {
  initiateSigning: (signData: SignDocumentRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<SignDocumentResponseDto>(`/documents/sign/initiate`, signData, { cancelToken })
    ),
  getSignServerAddress: (signId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<string>(`/documents/sign/serveraddress/${signId}`, { cancelToken })
    ),
};
