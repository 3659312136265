import { Alert, Button, message } from 'antd';
import { api } from 'api';
import {
  DocumentDto,
  RevisionLockPurposeEnum,
  SecondaryBlobTypeEnum,
  SignDocumentRequestDto,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { baseProjectApi } from 'api/project/baseProjectApi';
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useDocumentReservationSigningLock } from 'components/DocumentReservationLock/useDocumentReservationLock';
import { LocalSigningModal } from 'components/LocalSigningModal';
import Modal from 'components/Modal/Modal';
import { decryptLicenceKey } from 'components/PdfDiff/PdfDiff.utils';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useActiveProject, useApiData, useBoolean, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { processApiError } from 'utils';
import { modalFormConfirmClose } from 'utils/modalFormConfirmClose';
import { PDFSignatureInfo, PdfSignature } from './PdfSignature';
import styles from './PdfSignatureModal.module.less';

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  pdfTronLicenseKey?: string;
  workflowId?: Guid;
  workflowNodeId?: Guid;
  document: Pick<DocumentDto, 'id' | 'primaryFile' | 'secondaryFiles' | 'currentRevision'>;
};

export type SignatureDataWithPageData = SignDocumentRequestDto & {
  pageWidth: number;
  pageHeight: number;
  pageRotation: number;
};

export const SUPPORTED_SIGNABLE_CONTENT_TYPES = ['application/pdf'];

const prepareSigningRedirectUrl = (projectId: Guid, documentId: Guid, workflowId?: Guid): string => {
  const urlWithPath = new URL(`${window.location.href}`);

  if (!!workflowId) {
    return `${urlWithPath.origin}/projects/${projectId}/workflow/${workflowId}/tasks`;
  }
  return `${urlWithPath.origin}/projects/${projectId}/documents/${documentId}/revisions`;
};

const PdfSignatureModalComponent: FunctionComponent<Props> = ({
  visible,
  onClose,
  onSubmit,
  pdfTronLicenseKey,
  document,
  workflowId,
  workflowNodeId,
}) => {
  const [signatureData, setUpdatedSignature] = useState<SignatureDataWithPageData>();
  const intl = useIntl();
  const activeProject = useActiveProject();

  const documentName = useMemo(() => document?.primaryFile.name, [document]);
  const licenseKey = useMemo(() => pdfTronLicenseKey && decryptLicenceKey(pdfTronLicenseKey), [pdfTronLicenseKey]);
  const [signingGeneratedFileConsent, setSigningConsent] = useState<boolean>();
  const [signing, setSigning] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const [localSigningModalVisible, showLocalSigningModal, hideLocalSigningModal] = useBoolean(false);

  const secondarySignableFile = useMemo(
    () => document?.secondaryFiles.find((file) => file.blobType === SecondaryBlobTypeEnum.signed),
    [document?.secondaryFiles]
  );

  const primarySignableFile = useMemo(
    () =>
      document &&
      (SUPPORTED_SIGNABLE_CONTENT_TYPES.some((signableType) => document.primaryFile.contentType === signableType) ||
      document.primaryFile.hasPdfDerivate
        ? document.primaryFile
        : undefined),
    [document]
  );

  const isSecondaryFileSignable = useMemo(() => {
    return (
      !secondarySignableFile ||
      SUPPORTED_SIGNABLE_CONTENT_TYPES.some((signableType) => secondarySignableFile.contentType === signableType)
    );
  }, [secondarySignableFile]);

  const signingFile = secondarySignableFile || primarySignableFile;

  const [blobs, error, loading, loadBlob] = useApiData((ct) => {
    return api.project.blob.getBlobs({ tokens: { [signingFile?.id]: signingFile.blobToken } }, ct);
  });

  const blob = useMemo(() => signingFile && blobs?.blobs[signingFile.id], [blobs, signingFile]);

  useEffect(() => {
    if (!!signingFile && visible && isSecondaryFileSignable) {
      loadBlob();
      setUpdatedSignature(undefined);
    }
  }, [signingFile?.id, visible, isSecondaryFileSignable]);

  const requireConsent = useMemo(
    () =>
      !SUPPORTED_SIGNABLE_CONTENT_TYPES.some((signableType) => blob?.contentType === signableType) &&
      !!blob?.derivates.Pdf,
    [blob]
  );

  const [lockLoading, lockData, lockError] = useDocumentReservationSigningLock({
    lockPurpose: RevisionLockPurposeEnum.sign,
    documentId: document?.id,
    revisionId: document?.currentRevision.id,
    isLockRequested: visible,
  });

  const updateSignaturePosition = useCallback(
    (data?: PDFSignatureInfo) => {
      if (!document) {
        return;
      }

      if (!data) {
        setUpdatedSignature(undefined);
        return;
      }

      if (!data.createDate) {
        message.error(intl.formatMessage({ id: 'CertificateSignature.error.DocumentMissingCreateDate' }));
        return;
      }

      setUpdatedSignature({
        documentId: document.id,
        revisionId: document.currentRevision.id,
        fieldPositionX1: Math.floor(data.x1),
        fieldPositionX2: Math.floor(data.x2),
        fieldPositionY1: Math.floor(data.y1),
        fieldPositionY2: Math.floor(data.y2),
        pageNumber: data.pageNumber,
        pageHeight: data.pageHeight,
        pageWidth: data.pageWidth,
        returnAddress: prepareSigningRedirectUrl(activeProject.id, document.id, workflowId),
        pageRotation: data.pageRotation,
        signObjectKey: intl.formatMessage({ id: 'CertificateSignature.reason.key' }),
        signObjectValue: intl.formatMessage({ id: 'CertificateSignature.reason.value' }),
        signingDocumentBlobId: secondarySignableFile && blob.blobId,
        createDate: !!data.createDate && moment(data.createDate).format(),
        revisionLockId: lockData.lockId,
        workflowNodeId: workflowNodeId,
      });
    },
    [
      document,
      activeProject.id,
      intl,
      secondarySignableFile,
      blob?.blobId,
      lockData?.lockId,
      workflowId,
      workflowNodeId,
    ]
  );

  const handleBankIdSigning = useSameCallback(async () => {
    setSigning(true);
    const [err, res] = await api.project.documentSignings.initiateSigning(signatureData);
    if (err) {
      const error = processApiError(err);
      message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      setSigning(false);
      return null;
    }

    const bankIdSignServer = axios.create({
      baseURL: res.data.bankIdSignServerUrl,
      headers: {
        ...baseProjectApi.defaults.headers,
      },
    });

    const [errStorage, resStorage] = await to<AxiosResponse<string>, ApiError>(
      bankIdSignServer.post<string>(`/bankid/${activeProject.id}/sign/${res.data.id}/upload`)
    );

    if (errStorage) {
      message.error(
        intl.formatMessage(
          { id: 'CertificateSignature.error.storage' },
          {
            error: errStorage.response?.data
              ? intl.formatMessage({
                  id: `CertificateSignature.error.${errStorage.response.data}` as IntlMessageId,
                })
              : '',
          }
        )
      );
      setSigning(false);
      return null;
    }

    if (!resStorage.data) {
      message.error(intl.formatMessage({ id: 'CertificateSignature.error.redirectEmpty' }));
      setSigning(false);
      return;
    }

    window.location.href = resStorage.data;
  });

  const handleLocalSigning = useSameCallback(() => {
    setSigning(true);
    showLocalSigningModal();
  });

  const hideLocalSigning = useSameCallback(() => {
    setSigning(false);
    hideLocalSigningModal();
  });

  const handleLocalSigningSubmit = useSameCallback(() => {
    setSigning(false);
    hideLocalSigningModal();
    onSubmit();
  });

  const handleAnnotationClose = useSameCallback(() => {
    if (signatureData) {
      if (!isConfirmModalVisible) {
        modalFormConfirmClose(
          intl,
          () => {
            onClose();
            setIsConfirmModalVisible(false);
          },
          () => setIsConfirmModalVisible(false)
        );
      }
      setIsConfirmModalVisible(true);
    } else {
      onClose();
      setIsConfirmModalVisible(false);
    }
  });

  const saveButton = useMemo(
    () => (
      <>
        <Button type="primary" onClick={handleBankIdSigning} disabled={!signatureData} loading={signing}>
          <Fmt id="CertificateSignature.signButtonWithBankID" />
        </Button>
        <Button type="primary" onClick={handleLocalSigning} disabled={!signatureData} loading={signing}>
          <Fmt id="CertificateSignature.signButtonCertificate" />
        </Button>
      </>
    ),
    [signatureData, signing]
  );

  const documentUrl = blob?.derivates.Pdf?.url;

  return (
    <Modal
      visible={visible}
      onCancel={handleAnnotationClose}
      title={documentName ? documentName : <Fmt id="CertificateSignature.title" />}
      additionalButtons={saveButton}
    >
      {!isSecondaryFileSignable && (
        <Alert type="error" message={<Fmt id="CertificateSignature.secondaryFileNotSignable" />} />
      )}
      <ContentGate error={error || lockError} loading={!document || loading || lockLoading || !visible}>
        {requireConsent && !signingGeneratedFileConsent ? (
          <FlowLayout className={styles.consendArea}>
            <div className={styles.wrapper}>
              <Alert type="warning" message={<Fmt id="CertificateSignature.derivateWarning" />} />
              <Button type="primary" onClick={() => setSigningConsent(true)}>
                <Fmt id="general.acknowledge" />
              </Button>
            </div>
          </FlowLayout>
        ) : (
          documentUrl && (
            <PdfSignature
              licenseKey={licenseKey}
              documentUrl={documentUrl}
              onSignatureChanged={updateSignaturePosition}
            />
          )
        )}
      </ContentGate>
      <LocalSigningModal
        visible={localSigningModalVisible}
        onClose={hideLocalSigning}
        onSubmit={handleLocalSigningSubmit}
        signatureData={signatureData}
        documentUrl={documentUrl}
        documentId={document?.id}
        revisionId={document?.currentRevision.id}
        workflowNodeId={workflowNodeId}
        signedFileName={signingFile?.name}
      />
    </Modal>
  );
};

export const PdfSignatureModal = React.memo(PdfSignatureModalComponent);
