import { to as awaitTo } from 'await-to-js';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ApiError } from './errors';

export type ApiResponse<T> = [ApiError, undefined] | [null, AxiosResponse<T>];

export type ApiPromise<T> = Promise<ApiResponse<T>>;

export function to<T>(promise: AxiosPromise<T>): ApiPromise<T> {
  return awaitTo<AxiosResponse<T>, ApiError>(promise) as any;
}
