import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Typography } from 'antd';
import { throttle } from 'lodash';
import React, { FormEvent, FunctionComponent, useState } from 'react';

type Props = {
  itemsFinder: (value: string) => string[];
  onSubmit: (values: string[]) => void;
  onClose: () => void;
  placeholder: string;
  errorText: string;
  inputText: string;
  resultText: string;
  submitText: string;
  cancelText: string;
  title: string;
  visible: boolean;
};

const MultipleInputModal: FunctionComponent<Props> = ({
  itemsFinder,
  onSubmit,
  onClose,
  placeholder,
  errorText,
  inputText,
  resultText,
  submitText,
  cancelText,
  title,
  visible,
}) => {
  const [validateStatus, setValidateStatus] = useState<'' | 'error' | 'success' | 'warning' | 'validating'>('');
  const [items, setItems] = useState<string[]>([]);
  const [text, setText] = useState<string>('');

  const handleSubmit = () => {
    setText('');
    onSubmit(items);
    setItems([]);
  };

  const evaluateText = throttle((text: string) => {
    const texts = itemsFinder(text);
    let newStatus: '' | 'error' | 'success' | 'warning' | 'validating' = 'success';
    if (texts.length < 1) {
      newStatus = 'error';
    }
    setItems(texts);
    setValidateStatus(newStatus);
  }, 500);

  const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
    const text = e.currentTarget.value;
    setText(text);
    evaluateText(text);
  };

  const footer = (
    <div>
      <Button onClick={onClose}>{cancelText}</Button>
      <Button type="primary" onClick={handleSubmit} disabled={validateStatus !== 'success'}>
        {submitText}
      </Button>
    </div>
  );

  return (
    <Modal footer={footer} visible={visible} title={title} onCancel={onClose}>
      <Form layout="vertical">
        <Form.Item
          label={inputText}
          validateStatus={validateStatus}
          help={validateStatus === 'error' ? errorText : undefined}
        >
          <Input.TextArea placeholder={placeholder} onChange={handleChange} value={text} style={{ height: '150px' }} />
        </Form.Item>
        {items.length > 0 && (
          <Form.Item label={resultText}>
            {items.map((item) => (
              <div style={{ marginLeft: 8 }} key={item}>
                <Typography.Text>
                  <label>{item}</label>
                </Typography.Text>
              </div>
            ))}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default MultipleInputModal;
