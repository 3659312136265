import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Select, SelectProps } from 'antd';
import { AppUserDto, GroupListDto } from 'api/completeApiInterfaces';
import { useCurrentProjectUser } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';
import { AddUserEndpointType } from './ProjectUsersAddFormModal';

export type ProjectUserAddFormData = {
  usersToAddWithAttributes: Record<string, UserAttributes>;
  groups: Guid[];
};

type Props = FormComponentProps<ProjectUserAddFormData> &
  InjectedIntlProps & {
    toAddAppUsers: AppUserDto[];
    existUsersOnly: boolean;
    groupsList: GroupListDto[];
    requestEndpoint: AddUserEndpointType;
  };

const ProjectUserAddFormComponent = React.forwardRef<unknown, Props>(
  ({ form, intl, toAddAppUsers, groupsList, existUsersOnly, requestEndpoint }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const isCurrentUserProjectAdmin = !!useCurrentProjectUser()?.isAdmin;

    const options: SelectProps['options'] = useMemo(() => {
      if (isCurrentUserProjectAdmin) {
        return groupsList?.map((group) => ({
          label: group.name,
          value: group.id,
        }));
      } else {
        return groupsList
          ?.filter((group) => !group.isAdminGroup)
          .map((group) => ({
            label: group.name,
            value: group.id,
          }));
      }
    }, [groupsList, isCurrentUserProjectAdmin]);

    return (
      <Form layout="vertical">
        <Form.Item>
          {form.getFieldDecorator('usersToAddWithAttributes')(
            <AppUsersFormItem
              selectableAppUsers={toAddAppUsers}
              intl={intl}
              isProjectUsersAdding={requestEndpoint === 'project'}
              selectableUsersToAddSource={SelectableUsersSourceEnum.organization}
            />
          )}
        </Form.Item>
        {requestEndpoint === 'project' && (
          <Form.Item label={<Fmt id={'ProjectUserAddForm.addToGroups'} />}>
            {form.getFieldDecorator('groups', {})(<Select mode="multiple" allowClear options={options} />)}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(ProjectUserAddFormComponent);
