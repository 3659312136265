import { Alert } from 'antd';
import { DocumentCategoryNodeDto, DocumentCategoryTreeDto, ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { categoryListSelector, categoryMapSelector } from 'store/selectors';
import { userHasPermission } from 'utils/userHasPermission';
import CategoryListPanel from './CategoryListPanel';
import CategoryTreePanel from './CategoryTreePanel';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentProjectUser.data,
  categoryMap: categoryMapSelector(state),
  categoryList: categoryListSelector(state),
  categoryListError: state.categories.error,
  categoryListLoading: state.categories.loading,
  categoryTrees: state.categoryTrees,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCategoryList: dispatch.categories.loadData,
  loadCategoryTree: dispatch.categoryTrees.loadCategoryTree,
  setCategoryTreeNode: dispatch.categoryTrees.setCategoryTreeNode,
  removeCategoryTreeNode: dispatch.categoryTrees.removeCategoryTreeNode,
  categoryChangeNodeName: dispatch.categoryTrees.categoryChangeNodeName,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState & PropsFromDispatch & InjectedIntlProps & RouteComponentProps<ProjectRouteParams>;

const Categories: FunctionComponent<Props> = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const history = useHistory();

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  useEffect(() => {
    void props.loadCategoryList({ reload: true });
  }, []);

  const handleCategoryAdd = (category: DocumentCategoryTreeDto) => {
    const categoryId = category.id;
    props.categoryChangeNodeName({ name: category.name, categoryId: categoryId });
    void props.loadCategoryList({ reload: true });
    void props.loadCategoryTree({ reload: false, categoryId });
  };

  const handleCategoryDelete = useCallback(() => {
    void props.loadCategoryList({ reload: true });
    history.replace(url);
  }, [props, history, url]);

  const handleCategoryNodeAdd = (categoryId: Guid, node: DocumentCategoryNodeDto) => {
    props.setCategoryTreeNode({ categoryId, node });
  };

  const handleCategoryNodeDelete = (categoryId: Guid) => {
    void props.loadCategoryTree({ categoryId, reload: true });
  };

  const noPermission =
    !props.currentUser.isAdmin && !userHasPermission(props.currentUser, ExtendedPermissionEnum.category);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'general.categories' })}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical scrollable>
            {noPermission ? (
              <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
            ) : (
              <CategoryListPanel
                categoryMap={props.categoryMap}
                categoryList={props.categoryList}
                categoryListLoading={props.categoryListLoading}
                categoryListError={props.categoryListError}
                selectedId={selectedItemId}
                onSelect={onClick}
                onAddOrEdit={handleCategoryAdd}
                onDelete={handleCategoryDelete}
              />
            )}
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:categoryId`}
          render={(params) => (
            <CategoryTreePanel
              onAdd={handleCategoryNodeAdd}
              onDelete={handleCategoryNodeDelete}
              categoryTrees={props.categoryTrees}
              projectId={props.match.params.projectId}
              {...params}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(injectIntl(Categories));
