import { useActiveProject } from 'hooks';
import * as lodash from 'lodash';
import { useMemo } from 'react';
import { DirectoryNodeId, DirectoryNodeType } from './typeMappings/directories/directoryTypes';

export const getProjectUrlPath = (projectId: Guid) => `/projects/${projectId}`;

export const getUrlPathInProject = (projectId: Guid, path: string) => getProjectUrlPath(projectId) + path;

export const getDirectoryUrlPath = (projectId: Guid, directoryId: Guid) =>
  getUrlPathInProject(projectId, `/directories/${directoryId}`);

export const getDirectoryLinkUrlPath = (projectId: Guid, directoryLinkId: Guid) =>
  getUrlPathInProject(projectId, `/directoryLink/${directoryLinkId}`);

export const useProjectUrlPaths = () => {
  const activeProjectId = useActiveProject().id;

  return useMemo(() => {
    const createDirectoryUrlPath = (directoryId: Guid) => getDirectoryUrlPath(activeProjectId, directoryId);

    const createDirectoryLinkUrlPath = (directoryLinkId: Guid) =>
      getDirectoryLinkUrlPath(activeProjectId, directoryLinkId);

    const createDirectoryTreeUrlPath = (directoryTreeId: DirectoryNodeId) => {
      switch (directoryTreeId.type) {
        case DirectoryNodeType.Directory:
          return createDirectoryUrlPath(directoryTreeId.directoryId);
        case DirectoryNodeType.DirectoryLink:
          return createDirectoryLinkUrlPath(directoryTreeId.directoryLinkId);
      }
    };

    return {
      createDirectoryUrlPath,
      createDirectoryLinkUrlPath,
      createDirectoryTreeUrlPath,
    } as const;
  }, [activeProjectId]);
};

export const getPathsParts = (path: string) => {
  return lodash
    .trim(path, '/')
    .split('/')
    .filter((part) => part && part.length > 0);
};

export const combinePaths = (parts: string[], startWith = '/') => {
  return startWith + parts.join('/');
};
