import { to } from 'api/await-to';
import { SearchRequestDto, SearchResponseDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const searchApi = {
  search: (data: SearchRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<SearchResponseDto>(`/search`, data, { cancelToken })
    ),
};
