import { MainModule } from 'components/MainModule/MainModule';
import { ProjectModule } from 'components/ProjectModule/ProjectModule';
import { OidcPopupCallbackPage, OidcRedirectCallbackPage } from 'pages/OidcCallbackPage';
import ProjectInvitationAcceptPage from 'pages/ProjectInvitationAcceptPage/ProjectInvitationAcceptPage';
import SharedDownloadPage from 'pages/SharedDownloadPage';
import SignInPage from 'pages/SignInPage';
import SignOutPage from 'pages/SignOutPage';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import AuthRoute from 'routes/AuthRoute';
import { RootState } from 'store';
import styles from './Main.module.less';

type OwnProps = RouteComponentProps<{}> & {};

const mapStateToProps = (state: RootState) => ({
  userAuthenticated: state.appConfig.authenticated,
  oidc: state.oidc,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & PropsFromState;

class Main extends Component<Props, {}> {
  render() {
    const { userAuthenticated, oidc } = this.props;
    const accessTokenValid = !!(oidc && oidc.user);
    return (
      <div className={styles.pageContainer}>
        <DndProvider backend={HTML5Backend}>
          <Helmet defaultTitle="AspeHub" titleTemplate="%s | AspeHub">
            <meta charSet="utf-8" />
          </Helmet>
          <Switch>
            <Route
              path="/authorize"
              render={(props) => <SignInPage {...props} userAuthenticated={userAuthenticated} />}
              exact
            />
            <Route path="/invitation-accept" component={ProjectInvitationAcceptPage} exact />
            <Route path="/out" component={SignOutPage} exact />
            <Route path="/shared/:projectId/:shareId" component={SharedDownloadPage} exact />
            <Route path="/oidc/callback/popup" component={OidcPopupCallbackPage} exact />
            <Route path="/oidc/callback/redirect" component={OidcRedirectCallbackPage} exact />
            <AuthRoute
              path="/projects/:projectId"
              component={ProjectModule}
              userAuthenticated={userAuthenticated}
              accessTokenValid={accessTokenValid}
            />
            <AuthRoute
              path="/"
              component={MainModule}
              userAuthenticated={userAuthenticated}
              accessTokenValid={accessTokenValid}
            />
          </Switch>
        </DndProvider>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Main);
