import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { colorContrast } from 'utils';
import styles from './Label.module.less';

const Label: FunctionComponent<TagProps> = ({ children, color, className, ...props }) => {
  const textColor = colorContrast(color);
  return (
    <Tag
      color={color}
      className={classNames(className, styles.label, { [styles.dark]: textColor === '#000000' })}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Label;
