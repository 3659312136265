import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { useState } from 'react';
import { messageError, processApiError } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';
import { useItemsSet } from './useItemsSet';

export const useDeleteItems = <T = Guid, S = any>(
  intl: InjectedIntl,
  deleteFn: (id: T) => Promise<[ApiError, AxiosResponse<S>]>,
  deleteCb: (id: T, data: S) => void = undefined,
  showErrorMessage: boolean = true,
  confirmIntlId: IntlMessageId = undefined
) => {
  const [items, addItem, deleteItem] = useItemsSet<T>();
  const [error, setError] = useState<ServiceError>(null);

  const handleDelete = async (id: T) => {
    if (confirmIntlId && !(await modalConfirm({ title: intl.formatMessage({ id: confirmIntlId }) }))) {
      return false;
    }

    addItem(id);

    const result = (await deleteFn(id)) || [];
    const [err, response] = result;

    if (err) {
      processApiError(err, setError);
      showErrorMessage && messageError(err, intl);
    } else if (response) {
      deleteCb && deleteCb(id, response.data);
    }

    deleteItem(id);

    return !err;
  };

  return [items, handleDelete, error] as const;
};
