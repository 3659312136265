import { Modal } from 'antd';
import { ApiPromise } from 'api/await-to';
import { LabelRemoveConflictStrategyEnum } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { useCallback } from 'react';
import { messageError, processApiError } from 'utils';
import { useDeleteItems } from './useDeleteItems';
import { useIntl } from './useIntl';

export const useLabelListDeleteItems = <T extends { id: Guid, name?: string }[]>(
  labelList: T,
  onDelete: (id: Guid) => void,
  deleteEndpoint: (id: Guid, conflictStrategy: LabelRemoveConflictStrategyEnum) => ApiPromise<any>,
  formatMessage: (labelName: string, errorData: any) => string
) => {
  const intl = useIntl();
  const deleteConfirmCallback = useCallback(({ id }) => onDelete(id), [onDelete]);

  const [deletingItems, handleDelete] = useDeleteItems<{
    id: Guid;
    conflictStrategy?: LabelRemoveConflictStrategyEnum;
  }>(
    intl,
    async ({ id, conflictStrategy }) => {
      const item = labelList.find((i) => i.id === id);
      const name = item ? item.name : '';

      const response = await deleteEndpoint(id, conflictStrategy);
      const [err] = response;
      if (err) {
        processApiError(err, (error) => {
          if (error.referenceErrorCode === ServiceErrorEnum.OrgLabelIsUsedOnEntitiesError) {
            Modal.confirm({
              title: intl.formatMessage({ id: `LabelsList.deleteConfirmModal.title` }),
              content: formatMessage(name, error.errorData),
              onOk() {
                handleDelete({ id, conflictStrategy: LabelRemoveConflictStrategyEnum.remove });
              },
              okText: intl.formatMessage({ id: 'LabelsList.deleteConfirmModal.delete' }),
              cancelText: intl.formatMessage({ id: 'forms.button.cancel' }),
            });
          } else {
            messageError(error, intl);
          }
        });
      }
      return response;
    },
    deleteConfirmCallback,
    false
  );

  return [deletingItems, handleDelete] as const;
};
