import classNames from 'classnames';
import { DragHook } from 'components/DragTile/DragTile';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './MovableCard.module.less';

type Props = {
  title: ReactNode;
  menu?: ReactNode;
  className?: string;
  dragRef?: DragHook;
  linkTo?: string;
};

export const MovableCard: FunctionComponent<Props> = ({ title, menu, className, dragRef, children, linkTo }) => {
  const content = (
    <>
      <div className={classNames(styles.title, dragRef && styles.draggable)} ref={linkTo ? undefined : dragRef}>
        <div className={styles.titleText}>{title}</div>
        {menu && <div className={styles.menu}>{menu}</div>}
      </div>
      <div className={styles.content}>{children}</div>
    </>
  );

  return (
    <div className={classNames(styles.card, className)}>
      {linkTo ? (
        <Link to={linkTo} className={styles.link} innerRef={dragRef}>
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  );
};
