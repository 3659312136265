import { OrganizationAdminReportDto, OrgUserDto } from 'api/completeApiInterfaces';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import { ProjectCreateFormModal } from 'components/forms/ProjectCreateForm';
import ProjectEditFormModal from 'components/forms/ProjectEditForm/ProjectEditFormModal';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { projectsListSelector } from 'store/selectors';
import { textComparer } from 'utils/comparators';
import ProjectsList from './ProjectsList';
import { ProjectUsersPanel } from './ProjectUsersPanel';

type Props = InjectedIntlProps & {
  selectedOrganization: OrganizationAdminReportDto;
  organizationUsers: OrgUserDto[];
  reloadOrganizationUsers: () => void;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  onRemove?: (appUserUserName: string) => void;
  loadOrganizationsAdminReport?: () => void;
};

const ProjectsListPanel: FunctionComponent<Props> = ({
  selectedOrganization,
  loadOrganizationsAdminReport,
  organizationUsers,
  reloadOrganizationUsers,
}) => {
  const [search, setSearch] = useState<string>('');
  const [addProjectModalVisible, showAddProjectModal, hideAddProjectModal] = useBoolean(false);
  const [projectToEditId, setProjectToEditId] = useState<Guid>(undefined);
  const { url } = useRouteMatch();
  const intl = useIntl();

  const projectsFromReport = useMemo(
    () => selectedOrganization?.projects.sort(textComparer.map((project) => project.name)),
    [selectedOrganization]
  );

  const projectsList = useStoreSelector(projectsListSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );
  useEffect(() => {
    loadOrganizationsAdminReport();
  }, [projectsList]);

  const handleProjectCreateSubmit = () => {
    hideAddProjectModal();
    loadOrganizationsAdminReport();
  };

  const handleProjectCreateClose = () => {
    hideAddProjectModal();
  };

  const projectToEdit = useMemo(() => {
    return projectsFromReport.find((project) => project.id === projectToEditId);
  }, [projectToEditId]);

  const handleProjectEditSubmit = () => {
    setProjectToEditId(undefined);
    loadOrganizationsAdminReport();
  };

  const handleProjectEditClose = () => {
    setProjectToEditId(undefined);
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'general.projects' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              panelWidth="auto"
              addButtonOnClick={showAddProjectModal}
              addButtonText={<Fmt id="general.addProject" />}
              onSearch={setSearch}
              searchValue={search}
            >
              <EmptyGate empty={!projectsFromReport.length}>
                <ProjectsList
                  data={projectsFromReport}
                  search={search}
                  onClearSearch={clearSearch}
                  onEdit={setProjectToEditId}
                  onSelect={onSelect}
                  selectedId={selectedItemId}
                />
              </EmptyGate>
              <ProjectCreateFormModal
                visible={addProjectModalVisible}
                onSubmit={handleProjectCreateSubmit}
                onClose={handleProjectCreateClose}
                organizations={[selectedOrganization]}
              />
              {!!projectToEditId && (
                <ProjectEditFormModal
                  visible={!!projectToEditId}
                  onSubmit={handleProjectEditSubmit}
                  onClose={handleProjectEditClose}
                  projectToEdit={projectToEdit}
                  organizationId={selectedOrganization?.id}
                />
              )}
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:projectId`}
          render={() => (
            <ProjectUsersPanel
              selectedOrganization={selectedOrganization}
              organizationUsers={organizationUsers}
              reloadOrganizationUsers={reloadOrganizationUsers}
              projectList={projectsList}
              loadOrganizationsAdminReport={loadOrganizationsAdminReport}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default React.memo(ProjectsListPanel);
