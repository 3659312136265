import { Typography } from 'antd';
import { AssignmentMessageDto, ProjectListDto, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { AssignmentState } from 'components/AssignmentState/AssignmentState';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { ProjectIcon } from 'components/Icons/HubEntitiesIcons';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import StackPanel from 'components/StackPanel';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import { useIsAfterDeadline } from 'pages/MessageCenterPage/assignments/useIsAfterDeadline';
import React, { FunctionComponent, useMemo } from 'react';

type Props = {
  assignment: AssignmentMessageDto;
  selected: boolean;
  onClick: () => void;
  projects: Record<Guid, ProjectListDto>;
};

const AssignmentOverviewComponent: FunctionComponent<Props> = ({ assignment, selected, onClick, projects }) => {
  const intl = useIntl();
  const assignmentProject = projects?.[assignment.projectId];

  const isAfterDeadline = useIsAfterDeadline(assignment);

  const assignmentDate = useMemo(() => {
    const date = assignment.resolveDate || assignment.deadlineDate;

    return date
      ? moment(date)
          .locale(intl.locale)
          .format('ll')
      : '';
  }, [assignment.resolveDate, assignment.deadlineDate]);

  return (
    <GeneralSettingsItem
      selectable
      selected={selected}
      onClick={onClick}
      title={
        <>
          <Typography.Text ellipsis>
            #{assignment.orderId} {assignment.name}
          </Typography.Text>
        </>
      }
      description={
        <Typography.Text ellipsis>
          <ProjectIcon />{' '}
          {!!assignmentProject ? (
            `${assignmentProject.name} (${assignmentProject.organization.name})`
          ) : (
            <Fmt id="AssignmentOverview.projectNotFound" />
          )}
        </Typography.Text>
      }
      additionalActions={
        <>
          <StackPanel vertical autoWidth>
            <NoClickTooltip title={<Fmt id="AssignmentOverview.created" />}>
              <Typography.Text>
                {moment(assignment.createdDate)
                  .locale(intl.locale)
                  .format('ll')}
              </Typography.Text>
            </NoClickTooltip>
            <NoClickTooltip
              title={<Fmt id={assignment.resolveDate ? 'SearchSortTypeItem.resolvedDate' : 'general.deadline'} />}
            >
              <Typography.Text
                strong={isAfterDeadline}
                type={isAfterDeadline ? 'danger' : undefined}
                title={
                  isAfterDeadline
                    ? intl.formatMessage({ id: 'MessageCenterPage.message.category.assignmentDeadlineExpired.filter' })
                    : null
                }
              >
                {assignmentDate}
              </Typography.Text>
            </NoClickTooltip>
          </StackPanel>
          <AssignmentState state={assignment.state} />
          <UserAvatar
            title={<Fmt id="general.createdBy" />}
            user={{ ...(assignment.taskGiver || assignment.createdBy), status: ProjectUserProfileStatusEnum.active }}
          />
        </>
      }
    />
  );
};

export const AssignmentOverview = React.memo(AssignmentOverviewComponent);
