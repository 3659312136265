import { Column as ColumnChart, Donut as DonutChart } from '@ant-design/charts';
import { ColumnConfig, DonutConfig } from '@antv/g2plot';
import { Empty } from 'antd';
import { useCurrency, useTr } from 'hooks';
import { InjectedIntlProps } from 'locale';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { currencyLong, currencyShort, percentWhole } from 'utils/formatters';
import styles from './Charts.module.less';

export type ChartData = {
  cenaSOD: number;
  aktCena: number;
  realized: number;
};

type Props = InjectedIntlProps & ChartData;

const Charts = ({ cenaSOD, aktCena, realized, intl }: Props) => {
  const tr = useTr(intl);

  const currency = useCurrency();

  const charts = useMemo(() => {
    if (aktCena <= 0) {
      return <Empty className={styles.empty} description={tr('BudgetDetail.notConnected')} />;
    }

    const shortFormatter = currencyShort(intl.locale, currency);
    const longFormatter = currencyLong(intl.locale, currency);
    const percentFormatter = percentWhole(intl.locale);

    const percentage = !aktCena ? 1 : realized / aktCena;
    const donutData = [
      {
        item: tr('BudgetProjectDetail.realized'),
        value: realized,
        percent: percentage,
        color: '#80e040',
      },
      {
        item: tr('BudgetProjectDetail.remaining'),
        value: aktCena - realized,
        percent: 1 - percentage,
        color: '#ff6060',
      },
    ];

    const donutConfig: DonutConfig = {
      animation: false,
      forceFit: true,
      data: donutData,
      width: 80,
      height: 170,
      padding: [0, 0, 30, 0],
      angleField: 'value',
      colorField: 'color',
      color: donutData.map((d) => d.color),
      innerRadius: 0.5,
      tooltip: {
        showTitle: false,
        formatter: (item: string, value: number) => ({
          name: item,
          value: longFormatter(value) as any,
        }),
        fields: ['item', 'value'],
        position: 'left',
        domStyles: {
          // @ts-ignore
          'g2-tooltip-list-item': { display: 'flex', 'align-items': 'center' },
          'g2-tooltip-name': { whiteSpace: 'nowrap' },
        },
      },
      statistic: { visible: false },
      label: {
        visible: true,
        formatter: (value: number) => percentFormatter(value / aktCena),
        style: {
          fill: '#101010',
          fontSize: 12,
          fontWeight: 600,
        },
        field: 'percent',
      },
      legend: {
        offsetY: 10,
        position: 'bottom-center',
        text: {
          style: { fontSize: 12 },
          formatter: (color: string) => {
            const entry = donutData.find((e) => e.color === color);
            return `${entry.item}: ${longFormatter(entry.value)}`;
          },
        },
      },
    };

    const barData = [
      {
        name: tr('Building.general.sod'),
        shortName: tr('Building.general.sod'),
        value: cenaSOD,
        color: '#66a3ff',
      },
      {
        name: tr('BudgetProjectDetail.costNow'),
        shortName: tr('BudgetProjectDetail.costNowShort'),
        value: aktCena,
        color: '#80ff80',
      },
      {
        name: tr('BudgetProjectDetail.costChange'),
        shortName: tr('BudgetProjectDetail.costChangeShort'),
        value: aktCena - cenaSOD,
        color: '#feb0b1',
      },
    ];

    const barConfig: ColumnConfig = {
      animation: false,
      forceFit: true,
      data: barData,
      width: 80,
      height: 170,
      padding: [15, 5, 25, 5],
      xField: 'shortName',
      yField: 'value',
      colorField: 'color',
      color: barData.map((e) => e.color),
      legend: { visible: false },
      tooltip: {
        formatter: (name: string, value: number) => ({
          name: name,
          value: longFormatter(value) as any,
        }),
        showTitle: false,
        fields: ['name', 'value'],
      },
      xAxis: {
        label: {
          autoRotate: false,
          style: { fontSize: 12 },
        },
        title: { visible: false },
      },
      yAxis: {
        title: { visible: false },
      },
      label: {
        visible: true,
        formatter: (s) => shortFormatter(s as number),
        style: { fontSize: 11 },
      },
    };

    return (
      <>
        <div className={styles.chart}>
          <DonutChart key={intl.locale} {...donutConfig} />
        </div>
        <div className={styles.middle} />
        <div className={styles.chart}>
          <ColumnChart key={intl.locale} {...barConfig} />
        </div>
      </>
    );
  }, [cenaSOD, aktCena, realized, tr, intl]);

  return <div className={styles.charts}>{charts}</div>;
};

export default injectIntl(Charts);
