import { AppUserDto, ServiceError } from 'api/completeApiInterfaces';
import AppUsersAddFormModal from 'components/forms/AppUsersAddFormModal/AppUserAddFormModal';
import GeneralSettingsUsersList from 'components/GeneralSettingsUsersList/GeneralSettingsUsersList';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { useBoolean } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { ProjectUserProfileListExtended } from 'pages/OrganizationsSettingPage/OrganizationsDetailPanel';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { textComparer } from 'utils/comparators';

type Props = InjectedIntlProps & {
  appUsers: AppUserDto[];
  loading: boolean;
  error: ServiceError;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  onRemove: (appUserUserName: string) => void;
  onReinvite: (appUserUserId: Guid) => void;
  loadOrganizationsAdminReport: () => void;
};

const AdminsListPanel: FunctionComponent<Props> = ({
  appUsers,
  loading,
  error,
  selectedId,
  onSelect,
  onRemove,
  onReinvite,
  loadOrganizationsAdminReport,
}) => {
  const [search, setSearch] = useState<string>('');

  const [addAppUsersModalVisible, showAddAppUsersModal, hideAddAppUsersModal] = useBoolean(false);

  const remappedAppUserAdmins: ProjectUserProfileListExtended[] = useMemo(
    () =>
      appUsers
        ?.filter((user) => user.isAdmin)
        .map((admin) => ({
          ...admin,
          appUserId: admin.id,
          status: undefined,
          isConfirmed: !!admin.lastAccessDate,
        }))
        .sort(textComparer.map((admin) => admin.username)),
    [appUsers]
  );

  const noAdminAppUsers = useMemo(() => appUsers?.filter((user) => !user.isAdmin), [appUsers]);

  const handleSubmit = () => {
    loadOrganizationsAdminReport();
    hideAddAppUsersModal();
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const renderContent = () => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading && !remappedAppUserAdmins) return <SpinBox />;
    if (remappedAppUserAdmins === null) return null;
    return (
      <GeneralSettingsUsersList
        data={remappedAppUserAdmins}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onClearSearch={clearSearch}
        onRemove={onRemove}
        onReinvite={onReinvite}
      />
    );
  };

  return (
    <Panel
      noMargin
      addButtonOnClick={showAddAppUsersModal}
      addButtonText={<Fmt id="AdminListPanel.addFormTitle" />}
      onSearch={setSearch}
      searchValue={search}
    >
      {renderContent()}

      <AppUsersAddFormModal
        onSubmit={handleSubmit}
        onClose={hideAddAppUsersModal}
        visible={addAppUsersModalVisible}
        toAddAppUsers={noAdminAppUsers}
        titleId={'AppUsersAddFormModal.title'}
        isAdminsAdding
      />
    </Panel>
  );
};

export default AdminsListPanel;
