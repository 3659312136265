import { Col, Row, Typography } from 'antd';
import { Margin } from 'components/Margin/Margin';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

enum UserNotificationTypeEnum {
  WorkflowNodeResolveDemand = 0,
  AssignmentResolveDemand = 1,
}

const MAP_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID_TYPE_LABEL: Record<UserNotificationTypeEnum, IntlMessageId> = {
  [UserNotificationTypeEnum.WorkflowNodeResolveDemand]:
    'MessageCenterPage.message.detail.userNotification.type.workflow',
  [UserNotificationTypeEnum.AssignmentResolveDemand]: 'MessageCenterPage.message.detail.userNotification.type.task',
};

const MAP_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID_DEADLINE_LABEL: Record<UserNotificationTypeEnum, IntlMessageId> = {
  [UserNotificationTypeEnum.WorkflowNodeResolveDemand]: 'MessageCenterPage.message.detail.grid.taskDeadline',
  [UserNotificationTypeEnum.AssignmentResolveDemand]: 'MessageCenterPage.message.detail.grid.taskDeadline.task',
};

export type UserNotificationData = {
  DemandMsgObject: string;
  DemandMsgObjectLink: string;
  DemandMsgType: UserNotificationTypeEnum;
  FinishTime: IsoDateTime;
  Language: string;
  Message: string;
  ProjectDescription: string;
  Sender: string;
  SenderMail: string;
};

export const UserNotificationViewer: MessageViewer = ({ category, messageData, projectName, organizationName }) => {
  const data = messageData as UserNotificationData;
  const intl = useIntl();
  const header = <Fmt id={'MessageCenterPage.message.detail.userNotification.title'} />;
  const deadline = !!data.FinishTime
    ? moment(data.FinishTime)
        .locale(intl.locale)
        .format('LLL')
    : null;

  const content = (
    <>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={MAP_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID_TYPE_LABEL[data.DemandMsgType]} />
          </Col>
          <Col span={12}>
            <Link to={parse(data.DemandMsgObjectLink || '').pathname}>
              <Typography.Link underline>{data.DemandMsgObject}</Typography.Link>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Fmt id={MAP_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID_DEADLINE_LABEL[data.DemandMsgType]} />
          </Col>
          <Col span={12}>{deadline}</Col>
        </Row>
        <Margin top>
          <Row>
            <Col span={24}>
              <Fmt
                id={'MessageCenterPage.message.detail.grid.useNotifiEntry'}
                values={{ user: `${data.Sender} (${data.SenderMail})` }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Typography.Text>{data.Message}</Typography.Text>
            </Col>
          </Row>
        </Margin>
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
