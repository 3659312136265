import { AuditLogDto } from 'api/completeApiInterfaces';
import { InjectedIntl, InjectedIntlProps } from 'locale';
import { IntlMessageId, isIntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { objectKeys } from 'utils/typedFunctions';
import ParamsTable from './ParamsTable';

type Props = InjectedIntlProps & {
  log: AuditLogDto;
};

const getName = (key: AuditLogEventData, intl: InjectedIntl): string => {
  const localeKey: IntlMessageId = `AuditLog.EventData.${key}`;
  if (isIntlMessageId(localeKey)) {
    return intl.formatMessage({ id: localeKey });
  }
  return key;
};

const getValue = (value: any, intl: InjectedIntl): string => {
  if (value === 'unset') return intl.formatMessage({ id: 'AuditLog.EventData.unset' });
  if (typeof value === 'boolean')
    return value
      ? intl.formatMessage({ id: 'AuditLog.EventData.true' })
      : intl.formatMessage({ id: 'AuditLog.EventData.false' });
  return value;
};

const EventData: FunctionComponent<Props> = ({ log, intl }) => {
  const paramsTableData = useMemo(() => {
    const keys = objectKeys(log.eventData);
    return keys.map((key) => ({ key, name: getName(key, intl), value: getValue(log.eventData[key], intl) }));
  }, [log, intl]);

  if (!paramsTableData || paramsTableData.length === 0) return null;
  return <ParamsTable params={paramsTableData} isEntity={false} entityName={log.entityType} />;
};

export default injectIntl(EventData);
