import { ProjectsInRealizationPropertyType } from "api/completeApiInterfaces";
import { Serializable } from "components/filters/filterTypes";
import { dxTreeListColumn } from "devextreme/ui/tree_list";
import { InjectedIntl } from "locale";
import moment from "moment";

type SubColumn = Omit<ReportViewConfigurationColumn, 'columnKey'>;
export type ReportViewConfigurationColumn = ({ columnKey: ProjectsInRealizationPropertyType, columns?: SubColumn[], folded?: boolean } & Pick<dxTreeListColumn, 'visible' | 'width'>);
export type ReportViewConfiguration = {
  id: Guid;
  name: string;
  columns: ReportViewConfigurationColumn[];
  filters: Record<string, Serializable>;
};

export const defaultProjectsInRealizationFoldedColumns: SubColumn[] = [
  'total', 'jan', 'feb', 'mar', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
].map(column => ({
  folded: false,
  visible: true,
}))

export const getDefaultProjectInRealizationOverviewNamedReports = (intl: InjectedIntl): ReportViewConfiguration[] => [
  {
    id: '00000000-0000-0000-0000-000000000001',
    name: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.views.total' }, { year: moment().year() }),
    columns: [
      { columnKey: ProjectsInRealizationPropertyType.Type, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Unit, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Subsidies, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Name, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Sign, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supervisor, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supplier, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.State, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.StartDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.EndDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCost, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostI, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostO, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotal, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalI, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalO, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYear, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearI, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearICurrent, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearO, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearOCurrent, folded: true, visible: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.UnassignedYear, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.Note, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI1, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO1, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI2, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO2, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI3, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO3, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI4, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO4, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.NoteAuthor, visible: false },
    ],
    filters: {}
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    name: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.views.onlyI' }, { year: moment().year() }),
    columns: [
      { columnKey: ProjectsInRealizationPropertyType.Type, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Unit, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Subsidies, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Name, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Sign, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supervisor, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supplier, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.State, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.StartDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.EndDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCost, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostI, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostO, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotal, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalI, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalO, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYear, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearI, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearICurrent, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearO, visible: false, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearOCurrent, visible: false, folded: false, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.UnassignedYear, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.Note, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI1, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO1, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI2, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO2, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI3, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO3, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI4, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO4, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.NoteAuthor, visible: false },
    ],
    filters: {
      "projectType": {
        "notSet": false,
        "values": ["I", "O+I"],
        "useAndOperator": false
      }
    }
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    name: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.views.onlyO' }, { year: moment().year() }),
    columns: [
      { columnKey: ProjectsInRealizationPropertyType.Type, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Unit, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Subsidies, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Name, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Sign, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supervisor, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.Supplier, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.State, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.StartDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.EndDate, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCost, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostI, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.TotalCostO, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotal, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalI, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkTotalO, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYear, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearI, visible: false, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearICurrent, visible: false, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PerformedWorkYearO, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearOCurrent, visible: true, folded: true, columns: defaultProjectsInRealizationFoldedColumns },
      { columnKey: ProjectsInRealizationPropertyType.UnassignedYear, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.Note, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI1, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO1, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI2, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO2, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI3, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO3, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearI4, visible: false },
      { columnKey: ProjectsInRealizationPropertyType.PlanYearO4, visible: true },
      { columnKey: ProjectsInRealizationPropertyType.NoteAuthor, visible: false },
    ],
    filters: {
      "projectType": {
        "notSet": false,
        "values": ["O", "O+I"],
        "useAndOperator": false
      }
    }
  },
];