import { ControlOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Menu, Typography } from 'antd';
import { api } from 'api';
import {
  MsgCategoryEnum,
  MsgCenterDefaultSettingsDto,
  MsgCenterProjectSettingsDto,
  MsgCenterSettingPatchDto,
  ProjectListDto,
} from 'api/completeApiInterfaces';
import { CommonHeader } from 'components/CommonHeader/CommonHeader';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import StackPanel from 'components/StackPanel';
import { StyledBox } from 'components/StyledBox/StyledBox';
import { useApiData, useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import { MessageCenterProjectsMenu } from 'pages/MessageCenterPage/settings/MessageCenterProjectsMenu';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Dispatch as StoreDispatch } from 'store';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import { messageError } from 'utils';
import styles from './MessageCenterSettings.module.less';
import { MessageDefaultSettingsDetail } from './MessageDefaultSettingDetail';
import { MessageProjectSettingsDetail } from './MessageProjectSettingDetail';

type Props = { className?: string; projectList: ProjectListDto[] };
type MsgCenterDefaultSettingsWithDirty = MsgCenterDefaultSettingsDto & { isDirty: boolean };
type MsgCenterProjectSettingsWithDirty = MsgCenterProjectSettingsDto & { isDirty: boolean };
const DEFAULT_SETTINGS_KEY = 'defaults';
export type MessageSettingsOptionKeys = keyof MsgCenterSettingPatchDto;

export type SettingsState = {
  defaultSettings: MsgCenterDefaultSettingsDto;
  currentDefaultSettings: MsgCenterDefaultSettingsWithDirty;
  projectSettings: Record<Guid, MsgCenterProjectSettingsDto>;
  currentProjectSettings: Record<Guid, MsgCenterProjectSettingsWithDirty>;
};

export type SetDefaultSettings = {
  type: 'setDefaultSettings';
  settings: MsgCenterDefaultSettingsDto;
};

export type SetProjectSettings = {
  type: 'setProjectSettings';
  projectId: Guid;
  settings: MsgCenterProjectSettingsDto;
};

export type ResetSettingsChanges = {
  type: 'resetSettingsChanges';
};

export type ApplySettingsChanges = {
  type: 'applySettingsChanges';
};

export type SetDefaultSettingOption = {
  type: 'setDefaultSettingOption';
  categories: MsgCategoryEnum[];
  setting: MessageSettingsOptionKeys;
  value: boolean;
};

export type SetProjectSettingOption = {
  type: 'setProjectSettingOption';
  projectId: Guid;
  categories: MsgCategoryEnum[];
  setting: MessageSettingsOptionKeys;
  value: boolean;
};

export type ResetProjectSettingOption = {
  type: 'resetProjectSettingOption';
  projectId: Guid;
  categories: MsgCategoryEnum[];
};

export type SettingsReducerActions =
  | SetDefaultSettings
  | SetProjectSettings
  | ResetSettingsChanges
  | ApplySettingsChanges
  | SetDefaultSettingOption
  | SetProjectSettingOption
  | ResetProjectSettingOption;

const reducer = (prevState: SettingsState, action: SettingsReducerActions): SettingsState => {
  switch (action.type) {
    case 'setDefaultSettings':
      return {
        ...prevState,
        defaultSettings: action.settings,
        currentDefaultSettings: { ...action.settings, isDirty: false },
      };

    case 'setProjectSettings':
      return {
        ...prevState,
        projectSettings: { ...prevState.projectSettings, [action.projectId]: action.settings },
        currentProjectSettings: {
          ...prevState.currentProjectSettings,
          [action.projectId]: { ...action.settings, isDirty: false },
        },
      };

    case 'resetSettingsChanges':
      return {
        ...prevState,
        currentDefaultSettings: { ...prevState.defaultSettings, isDirty: false },
        currentProjectSettings:
          prevState.projectSettings &&
          Object.keys(prevState.projectSettings).reduce((acc, key) => {
            acc[key] = { ...prevState.projectSettings[key], isDirty: false };
            return acc;
          }, {} as Record<Guid, MsgCenterProjectSettingsWithDirty>),
      };

    case 'applySettingsChanges':
      return {
        ...prevState,
        defaultSettings: prevState.currentDefaultSettings,
        currentDefaultSettings: { ...prevState.currentDefaultSettings, isDirty: false },
        projectSettings: prevState.currentProjectSettings,
        currentProjectSettings:
          prevState.currentProjectSettings &&
          Object.keys(prevState.currentProjectSettings).reduce((acc, key) => {
            acc[key] = { ...prevState.currentProjectSettings[key], isDirty: false };
            return acc;
          }, {} as Record<Guid, MsgCenterProjectSettingsWithDirty>),
      };

    case 'setDefaultSettingOption':
      // eslint-disable-next-line no-case-declarations
      const currentDefaultSettings = prevState.currentDefaultSettings.settings.map((setting) => {
        if (!action.categories.includes(setting.category)) return setting;
        return { ...setting, [action.setting]: action.value };
      });

      return {
        ...prevState,
        currentDefaultSettings: {
          settings: currentDefaultSettings,
          isDirty: currentDefaultSettings.some((setting) => {
            const defaultSetting = prevState.defaultSettings.settings.find(
              (defaultSetting) => defaultSetting.category === setting.category
            );
            return (
              defaultSetting?.sendEmail !== setting.sendEmail ||
              defaultSetting?.sendReport !== setting.sendReport ||
              defaultSetting?.ignore !== setting.ignore
            );
          }),
        },
      };

    case 'setProjectSettingOption':
      // eslint-disable-next-line no-case-declarations
      const modifiedProjectSettings = prevState.currentProjectSettings[action.projectId].settings.map((setting) => {
        if (!action.categories.includes(setting.category)) return setting;
        return { ...setting, [action.setting]: action.value };
      });

      return {
        ...prevState,
        currentProjectSettings: {
          ...prevState.currentProjectSettings,
          [action.projectId]: {
            settings: modifiedProjectSettings,
            isDirty: modifiedProjectSettings.some((setting) => {
              const defaultSetting = prevState.projectSettings[action.projectId].settings.find(
                (defaultSetting) => defaultSetting.category === setting.category
              );
              return (
                defaultSetting?.sendEmail !== setting.sendEmail ||
                defaultSetting?.sendReport !== setting.sendReport ||
                defaultSetting?.ignore !== setting.ignore
              );
            }),
          },
        },
      };

    case 'resetProjectSettingOption':
      // eslint-disable-next-line no-case-declarations
      const restoredProjectSettings = prevState.currentProjectSettings[action.projectId].settings.map((setting) => {
        if (!action.categories.includes(setting.category)) return setting;
        return { ...setting, sendEmail: null, sendReport: null, ignore: null };
      });

      return {
        ...prevState,
        currentProjectSettings: {
          ...prevState.currentProjectSettings,
          [action.projectId]: {
            settings: restoredProjectSettings,
            isDirty: restoredProjectSettings.some((setting) => {
              const defaultSetting = prevState.currentProjectSettings[action.projectId].settings.find(
                (defaultSetting) => defaultSetting.category === setting.category
              );
              return (
                defaultSetting?.sendEmail !== setting.sendEmail ||
                defaultSetting?.sendReport !== setting.sendReport ||
                defaultSetting?.ignore !== setting.ignore
              );
            }),
          },
        },
      };
  }
};

const getDefaultSettings = (): SettingsState => {
  return {
    defaultSettings: undefined,
    currentDefaultSettings: undefined,
    projectSettings: undefined,
    currentProjectSettings: undefined,
  };
};

export const MessageCenterSettings: FunctionComponent<Props> = (props) => {
  const { projectList } = props;
  const [settings, settingsDispatch] = useReducer(reducer, getDefaultSettings());
  const [saving, setSaving] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const intl = useIntl();
  const dispatch = useDispatch<StoreDispatch>();
  const { url } = useRouteMatch();

  const [_globalSettings, globalSettingsError, globalSettingsLoading, loadGlobalSettings] = useApiData(
    (ct) => api.master.messageCenter.getDefaultSettings(ct),
    {
      fetchCallback: (data) => settingsDispatch({ type: 'setDefaultSettings', settings: data }),
    }
  );

  const isDirty = useMemo(() => {
    return (
      settings.currentDefaultSettings?.isDirty ||
      (settings.currentProjectSettings &&
        Object.keys(settings.currentProjectSettings).some(
          (projectKey) => settings.currentProjectSettings[projectKey].isDirty
        ))
    );
  }, [settings]);

  useEffect(() => {
    if (!settings.defaultSettings) loadGlobalSettings();
  }, [settings.defaultSettings]);

  const onResetChange = useCallback(() => {
    settingsDispatch({ type: 'resetSettingsChanges' });
  }, []);

  const onSaveHandler = useCallback(async () => {
    let isError: boolean = false;
    setSaving(true);
    if (settings.currentDefaultSettings?.isDirty) {
      const [defaultError] = await api.master.messageCenter.setMessageSettings({
        settings: settings.currentDefaultSettings.settings.reduce((patch, setting) => {
          patch[setting.category] = {
            sendEmail: setting.sendEmail,
            sendReport: setting.sendReport,
            ignore: setting.ignore,
          };

          return patch;
        }, {} as Record<MsgCategoryEnum, MsgCenterSettingPatchDto>),
        projectId: null,
      });
      if (defaultError) {
        messageError(defaultError, intl);
        isError = true;
      }
    }

    settings.currentProjectSettings &&
      Object.keys(settings.currentProjectSettings).forEach(async (projectKey) => {
        if (settings.currentProjectSettings[projectKey]) {
          const [projectError] = await api.master.messageCenter.setMessageSettings({
            settings: settings.currentProjectSettings[projectKey].settings.reduce((patch, setting) => {
              if (setting.sendEmail !== null || setting.sendReport !== null || setting.ignore !== null) {
                patch[setting.category] = {
                  sendEmail: setting.sendEmail ?? setting.sendEmailDefault,
                  sendReport: setting.sendReport ?? setting.sendReportDefault,
                  ignore: setting.ignore ?? setting.ignoreDefault,
                };
              } else {
                patch[setting.category] = null;
              }

              return patch;
            }, {} as Record<MsgCategoryEnum, MsgCenterSettingPatchDto>),
            projectId: projectKey,
          });
          if (projectError) {
            messageError(projectError, intl);
            isError = true;
          }
        }
      });

    if (!isMounted.current) return;

    setSaving(false);
    !isError && settingsDispatch({ type: 'applySettingsChanges' });
  }, [settings]);

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      showMask: false,
      message: intl.formatMessage({ id: 'CommentProcedureCommentsNote.notSaveAlert' }),
    }),
    [intl]
  );

  useEffect(() => {
    if (isDirty) {
      dispatch.disableWindow.startPreventing(disableWindowConfig);
    } else {
      dispatch.disableWindow.stopPreventing();
    }
  }, [isDirty, disableWindowConfig]);

  useEffect(() => {
    return () => dispatch.disableWindow.stopPreventing();
  }, []);

  // render
  return (
    <>
      <MasterComponent
        url={url}
        maxWidth={500}
        title={intl.formatMessage({ id: 'MessageCenterPage.tabs.settings' })}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical scrollable stretch>
            <GeneralSettingsContainer itemsLargeGap>
              <GeneralSettingsContainer title={<Fmt id={'MessageCenterPage.settings.menu.default'} />}>
                <Menu selectedKeys={[selectedItemId]} className={styles.container}>
                  <Menu.Item
                    key={DEFAULT_SETTINGS_KEY}
                    onClick={() => onClick(DEFAULT_SETTINGS_KEY)}
                    icon={<ControlOutlined />}
                    className={styles.menuLabel}
                  >
                    <CommonHubEllipsisText
                      placement="topLeft"
                      title={intl.formatMessage({ id: 'MessageCenterPage.settings.menu.default' })}
                      className={styles.projectName}
                    >
                      {intl.formatMessage({ id: 'MessageCenterPage.settings.menu.default' })}
                    </CommonHubEllipsisText>
                    <RightOutlined />
                  </Menu.Item>
                </Menu>
              </GeneralSettingsContainer>
              <GeneralSettingsContainer title={<Fmt id={'MessageCenterPage.settings.menu.project'} />}>
                <MessageCenterProjectsMenu
                  projectList={projectList}
                  selectedKeys={[selectedItemId]}
                  setSelectedProject={onClick}
                />
              </GeneralSettingsContainer>
            </GeneralSettingsContainer>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:projectId`}
          render={(props) => (
            <MasterComponent
              url={props.match.url}
              maxWidth={1200}
              title={
                props.match.params.projectId === DEFAULT_SETTINGS_KEY
                  ? intl.formatMessage({ id: 'MessageCenterPage.settings.menu.default' })
                  : projectList?.find((project) => project.id === props.match.params.projectId)?.name
              }
              children={() => (
                <StackPanel vertical className={styles.container}>
                  <StyledBox>
                    <CommonHeader
                      padding="all"
                      title={
                        props.match.params.projectId === DEFAULT_SETTINGS_KEY ? (
                          <Typography.Title level={4} className={styles.title}>
                            <Fmt id={'MessageCenterPage.settings.menu.default'} />
                            <CommonHubTooltip title={<Fmt id={'MessageCenterPage.settings.menu.default.tooltip'} />}>
                              <InfoCircleOutlined className={styles.infoIcon} />
                            </CommonHubTooltip>
                          </Typography.Title>
                        ) : (
                          <Typography.Title level={4} className={styles.title}>
                            <Fmt id={'MessageCenterPage.settings.menu.project'} />
                            <CommonHubTooltip title={<Fmt id={'MessageCenterPage.settings.menu.project.tooltip'} />}>
                              <InfoCircleOutlined className={styles.infoIcon} />
                            </CommonHubTooltip>
                          </Typography.Title>
                        )
                      }
                      buttons={
                        <SaveOrCancelButton
                          onSave={onSaveHandler}
                          onCancel={onResetChange}
                          disabled={!isDirty}
                          loading={saving}
                        />
                      }
                    />
                  </StyledBox>
                  <StackPanel vertical padding scrollable>
                    {props.match.params.projectId === DEFAULT_SETTINGS_KEY ? (
                      <MessageDefaultSettingsDetail
                        currentSetting={settings.currentDefaultSettings}
                        loading={globalSettingsLoading}
                        settingDispatch={settingsDispatch}
                        loadingError={globalSettingsError}
                        className={styles.settingsDetail}
                      />
                    ) : (
                      <MessageProjectSettingsDetail
                        projectList={projectList}
                        settings={settings}
                        settingDispatch={settingsDispatch}
                        projectId={props.match.params.projectId}
                        className={styles.settingsDetail}
                      />
                    )}
                  </StackPanel>
                </StackPanel>
              )}
            />
          )}
        />
      </Switch>
    </>
  );
};
