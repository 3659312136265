import { InputRef } from 'antd';
import { LabelDto, ProjectTemplateLabelDto, ProjectTemplateRoleDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import LabelsListItemForm, { LabelCreateFormData } from 'components/forms/LabelForm/LabelsListItemForm';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent, useMemo } from 'react';
import uuid from 'uuid';

type Props = FormModalProps<ProjectTemplateLabelDto> & {
  editedLabel?: ProjectTemplateRoleDto;
  validateUniqueName: (name: string) => boolean;
};

const ProjectTemplateLabelFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedLabel, validateUniqueName, ...restProps } = props;

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  const handleSubmit: FormSubmitHandler<LabelCreateFormData> = (values) => {
    onSubmit({
      id: editedLabel?.id || uuid(),
      name: values.name,
      description: values.description,
      color: values.color,
    });
    return null;
  };

  const isLabelEditing = !!editedLabel;
  const defaultLabel = useMemo((): LabelDto => ({ ...editedLabel, isDeprecated: false }), [editedLabel]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={isLabelEditing ? 'ProjectTemplateLabelFormModal.edit.title' : 'ProjectTemplateLabelFormModal.add.title'}
      submitTextId={isLabelEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <LabelsListItemForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          defaults={defaultLabel}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectTemplateLabelFormModal;
