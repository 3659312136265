import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import styles from './BackButton.module.less';
import { MasterDetailsViewContext } from 'components/MasterDetailsView/MasterDetailsView';

export const BackButton: FunctionComponent = () => {
  const masterDetailsViewContext = useContext(MasterDetailsViewContext);
  return (
    masterDetailsViewContext.showBackButton &&
    !masterDetailsViewContext.showFirstPart && (
      <Button
        type="primary"
        onClick={masterDetailsViewContext.onBackButtonClick}
        icon={<LeftOutlined />}
        className={styles.backButton}
      />
    )
  );
};
