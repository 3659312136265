import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useForgeModelInfoContext } from 'Forge/ForgeModelInfoContext/ForgeModelInfoContextProvider';
import { Fmt } from 'locale';
import { groupBy } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { smartFilter } from 'utils';
import { getLogWithPrefix } from 'utils/debugLog';

type Props = {
  viewer: Autodesk.Viewing.GuiViewer3D;
};

const USER_FUNCTION_REQUIRED_NAME = 'userFunction';

type ElementAttribute = { id: number; value: string };

type AttributeData = {
  name: string;
  category: string;
  i: number;
  displayName: string;
  dataType: number;
  dataTypeContext: string;
};

// TODO: to be used in the future
const getDataType = (dataType: number): string => {
  switch (dataType) {
    case 0:
      return 'Unknown';
    case 1:
      return 'Boolean';
    case 2:
      return 'Integer';
    case 3:
      return 'Double';
    // possibly other numeric types
    case 10:
      return 'BLOB';
    case 11:
      return 'DbKey';
    // possibly other special types
    case 20:
      return 'String';
    case 21:
      return 'Localized';
    case 22:
      return 'DateTime'; /* ISO 8601 date */
    case 23:
      return 'Geolocation'; /* LatLonHeight - ISO6709 Annex H string, e.g: "+27.5916+086.5640+8850/" for Mount Everest */
    case 24:
      return 'Position'; /* "x y z w" space separated string representing vector with 2,3 or 4 elements*/
    // possibly other string types
    default:
      return 'Unknown';
  }
};

const { debugInfo } = getLogWithPrefix('__FORGE__ATTRIBUTES_FILTER__');

export const ForgeByAttributesFilter: FC<Props> = ({ viewer }) => {
  const [properties, setProperties] = useState<AttributeData[]>([]);
  const { geometryLoaded } = useForgeModelInfoContext();

  useEffect(() => {
    debugInfo('Viewer changed', { viewer, model: viewer?.model, geometryLoaded });
    if (viewer && viewer.model) {
      // The Autodesk executeUserFunction method requires the function to have a name equal to 'userFunction'
      const userFunction = (pdb: any): AttributeData[] => {
        const attributes: AttributeData[] = [];

        pdb.enumAttributes((i: number, attrDef: any, attrRaw: any) => {
          attributes.push({
            name: attrDef.name,
            i,
            category: attrDef.category,
            displayName: attrDef.displayName,
            dataType: attrDef.dataType,
            dataTypeContext: attrDef.dataTypeContext,
          });
        });
        return attributes;
      };

      const db = viewer.model.getPropertyDb();

      const thePromise = db.executeUserFunction(userFunction);
      thePromise.then((retValue: AttributeData[]) => {
        setProperties(retValue);
      });
    }
  }, [geometryLoaded]);

  const [selectedPropertyKey, setSelectedPropertyKey] = useState<number | null>(null);
  const selectedProperty = useMemo(() => properties.find((property) => property.i === selectedPropertyKey), [
    properties,
    selectedPropertyKey,
  ]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [foundElements, setFoundElements] = useState<number[]>([]);

  const handleSearch = useCallback(() => {
    const userFunction = (pdb: any, selectedProperty: [number | null]) => {
      const selectedAttribute = selectedProperty[0];
      const elementAttributePairs: ElementAttribute[] = [];

      pdb.enumObjects(function(dbId: number) {
        pdb.enumObjectProperties(dbId, (attrId: number, valId: number) => {
          if (attrId === selectedAttribute || selectedAttribute == null) {
            let value: string = pdb.getAttrValue(attrId, valId);
            elementAttributePairs.push({ id: dbId, value });
            return selectedAttribute != null;
          }
          return false;
        });
      });

      return elementAttributePairs;
    };

    const db = viewer.model.getPropertyDb();
    const thePromise = db.executeUserFunction(userFunction, [selectedPropertyKey]);
    thePromise
      .then((result: ElementAttribute[]) => {
        if (!result) {
          return;
        }

        const foundElements = result.filter((item) => smartFilter(item.value, selectedValue));
        const uniqueElementsById = Array.from(new Set(foundElements.map((item) => item.id)));
        setFoundElements(uniqueElementsById);

        const ids: number[] = uniqueElementsById;
        viewer.select(ids);
        viewer.fitToView(ids);
        viewer.isolate(ids);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedPropertyKey, selectedValue, viewer]);

  const groupedOptions = groupBy(
    properties.filter((property) => !!property.displayName),
    (value) => value.category
  );

  const options = Object.entries(groupedOptions).map(([key, properties]) => ({
    label: properties[0].category,
    key,
    options: properties.map((property) => ({
      label: property.displayName + (property.dataTypeContext ? '[' + property.dataTypeContext + ']' : ''),
      value: property.i,
      title: property.name,
      category: property.category,
      dataType: property.dataType,
      key: property.i,
    })),
  }));

  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={<Fmt id="ForgeByAttributesFilter.filtersTitle" />} placement="topLeft">
        <Button htmlType="submit" loading={!properties} onClick={() => setOpen(true)} icon={<SearchOutlined />} />
      </Tooltip>
      <Modal
        title={<Fmt id="ForgeByAttributesFilter.filtersTitle" />}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form layout={'vertical'}>
          <Form.Item label={<Fmt id="ForgeByAttributesFilter.parameterFilterLabel" />}>
            <Select
              allowClear
              value={selectedPropertyKey}
              onSelect={setSelectedPropertyKey}
              options={options}
              optionFilterProp={'label'}
              showSearch
              onClear={() => setSelectedPropertyKey(null)}
            />
          </Form.Item>
          {/*selectedProperty?.dataType === 2 || selectedProperty?.dataType === 3 ? (
          TODO: to be implemented
            <>
              <Select
                defaultValue="eq"
                style={{ width: '90px' }}
                options={[
                  { label: '=', value: 'eq' },
                  { label: '>', value: 'gt' },
                  { label: '<', value: 'lt' },
                  { label: '>=', value: 'gte' },
                  { label: '<=', value: 'lte' },
                  { label: '!=', value: 'ne' },
                ]}
              />
              <InputNumber<number>
                value={selectedNumberFilter}
                decimalSeparator={'.'}
                onChange={(value) => setSelectedNumberFilter(value)}
              />
            </>
          ) : (

          )*/}
          <Form.Item label={<Fmt id="ForgeByAttributesFilter.valueFilterLabel" />}>
            <Input
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              suffix={
                selectedProperty && selectedProperty.dataTypeContext && '[' + selectedProperty.dataTypeContext + ']'
              }
            />
          </Form.Item>
          <FlowLayout>
            <Button type="primary" htmlType="submit" onClick={handleSearch} icon={<SearchOutlined />}>
              <Fmt id={'general.search'} />
            </Button>

            <Fmt id={'ForgeByAttributesFilter.foundElements'} values={{ count: foundElements.length }} />
          </FlowLayout>
        </Form>
      </Modal>
    </>
  );
};
