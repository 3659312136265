import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Dispatch } from 'store';
import {
  DisableWindowStoreModelState,
  DisableWindowStoreStartPayload,
  RootState,
} from 'store/models/storeModelinterfaces';
import styles from './WindowDisabler.module.less';

export const useWindowDisabler = (disabled: boolean, config: DisableWindowStoreStartPayload) => {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    if (disabled) {
      dispatch.disableWindow.startPreventing(config);
    } else {
      dispatch.disableWindow.stopPreventing();
    }
  }, [dispatch, disabled, config]);
};

const WindowDisabler: FunctionComponent = () => {
  const dispatch = useDispatch<Dispatch>();
  const disableWindow = useSelector<RootState, DisableWindowStoreModelState>((d) => d.disableWindow);
  const endDisable = dispatch.disableWindow.stopPreventing;

  const handleBeforeunload = useCallback(
    (ev: any) => {
      if (disableWindow.isActive) {
        ev.preventDefault();
        ev.returnValue = '';
        return disableWindow.message;
      }
      return undefined;
    },
    [disableWindow]
  );

  useEffect(() => {
    if (disableWindow.isActive) {
      endDisable();
    }
  }, [location.href]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [handleBeforeunload]);

  useEffect(() => {
    window.addEventListener('unload', endDisable);
    return () => {
      window.removeEventListener('unload', endDisable);
    };
  }, [endDisable]);

  return (
    <>
      {disableWindow.isActive && (
        <>
          <Prompt when={disableWindow.isActive} message={disableWindow.message} />
          {disableWindow.showMask && <div className={styles.mask} />}
        </>
      )}
    </>
  );
};

export default WindowDisabler;
