import { RevisionLockPurposeEnum, RevisionLockResponseDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useDocumentReservationSigningLock } from 'components/DocumentReservationLock/useDocumentReservationLock';
import React, { FunctionComponent } from 'react';

type Props = {
  lockPurpose: RevisionLockPurposeEnum;
  documentId: Guid;
  revisionId: Guid;
  onLockSuccess?: (lockResponse: RevisionLockResponseDto) => void;
  onLockFail?: () => void;
  isLockRequested: boolean;
};

export const DocumentReservationSigningLock: FunctionComponent<Props> = ({
  lockPurpose,
  documentId,
  revisionId,
  onLockSuccess,
  onLockFail,
  isLockRequested,
  children,
}) => {
  const [loading, lockData, lockError] = useDocumentReservationSigningLock({
    lockPurpose,
    documentId,
    revisionId,
    onLockSuccess,
    onLockFail,
    isLockRequested,
  });
  return (
    <ContentGate error={lockError} loading={loading && !lockData}>
      {children}
    </ContentGate>
  );
};
