import { message } from 'antd';
import { api } from 'api';
import { DirectoryLinkDto, DirectoryLinkMoveDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { memoizeWithIntl } from 'locale';
import { trim } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { match } from 'react-router';
import { DirectoryRouteParams } from 'routes';
import { processApiError } from 'utils';
import { ConnectedDirectory, ConnectedDirectoryLink } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryLinkMoveForm from './DirectoryLinkMoveForm';

type Props = InjectedIntlProps &
  FormModalProps<DirectoryLinkDto> & {
    disabled?: (node: ConnectedDirectory) => boolean;
    match: match<DirectoryRouteParams>;
    directoryLink: ConnectedDirectoryLink;
    defaultDestinationId?: Guid;
  };

const DirectoryLinkMoveFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, directoryLink, defaultDestinationId, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);

  const handleSubmit: FormSubmitHandler<DirectoryLinkMoveDto> = async (values) => {
    const data = { destination: values.destination, name: trim(values.name) };
    const [err, res] = await api.project.directories.moveDirectoryLink(directoryLink.linkId, data);
    if (err) {
      const error = processApiError(err);
      if (!!error?.errorData) {
        switch (error.referenceErrorCode) {
          case ServiceErrorEnum.DocumentMultipleMoveObstacleError:
            {
              const errData = error.errorData as IMultipleDocumentErrorData;
              setErrors(errData.documentsErrors.map(transformError));
            }
            return null;
        }
      }
      message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      return null;
    }
    await onSubmit(res.data);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="DirectoryLinkMoveFormModal.title"
      confirmClose={false}
      submitTextId="general.move"
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DirectoryLinkMoveForm
          selectedDirectory={directoryLink?.linkedDirectory}
          directoryLinkName={directoryLink?.linkName}
          defaultDestinationId={defaultDestinationId}
          wrappedComponentRef={formRef}
          intl={intl}
          errors={errors}
        />
      )}
    </FormModalWrapper>
  );
};

export default memoizeWithIntl(DirectoryLinkMoveFormModal);
