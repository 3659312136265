import { AccessLevelEnum, DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoryLink } from 'api/project/directories/directoriesApi';
import { DEBUG } from 'config/env';
import {
  DirectoryNodeDirectoryKey,
  DirectoryNodeDirectoryLinkKey,
  DirectoryNodeKey,
  DIRECTORY_NODE_DIRECTORY_LINK_PREFIX,
  DIRECTORY_NODE_DIRECTORY_PREFIX,
} from './directoryTreeIds';

// The "Variant type" enum used to differentiate directory node variant options

export enum DirectoryNodeType {
  Directory = 'Directory',
  DirectoryLink = 'DirectoryLink',
}

// The Directory node "itself" with full info

export type DirectoryNodeDirectory = {
  type: DirectoryNodeType.Directory;
  directory: ConnectedDirectory;
};

export type DirectoryNodeDirectoryLink = {
  type: DirectoryNodeType.DirectoryLink;
  directoryLink: ConnectedDirectoryLink;
};

export type DirectoryNode = DirectoryNodeDirectory | DirectoryNodeDirectoryLink;

// Directory node with id only, is extracted from the key

export type DirectoryNodeDirectoryId = {
  type: DirectoryNodeType.Directory;
  directoryId: Guid;
};

export type DirectoryNodeDirectoryLinkId = {
  type: DirectoryNodeType.DirectoryLink;
  directoryLinkId: Guid;
};

export type DirectoryNodeId = DirectoryNodeDirectoryId | DirectoryNodeDirectoryLinkId;

// Connected directory and directory link

export type ConnectedDirectoryLink = DirectoryLink & {
  parentDirectory: ConnectedDirectory;
  linkedDirectory: ConnectedDirectory;
};

export type ConnectedDirectory = DirectoryListDto & {
  parent: ConnectedDirectory | null;
  children: DirectoryNode[];
  accessLevelIncludingChildren: AccessLevelEnum;
};

export const directoryNodeHelpers = {
  directoryNode(directory: ConnectedDirectory): DirectoryNodeDirectory {
    return {
      type: DirectoryNodeType.Directory,
      directory,
    };
  },
  directoryLinkNode(directoryLink: ConnectedDirectoryLink): DirectoryNodeDirectoryLink {
    return {
      type: DirectoryNodeType.DirectoryLink,
      directoryLink,
    };
  },
  // TODO: this is quite inefficient: remove and filter in places manually?
  filterMapDirectory(content: DirectoryNode): ConnectedDirectory[] {
    return content.type === DirectoryNodeType.Directory ? [content.directory] : [];
  },
  filterMapDirectoryLink(content: DirectoryNode): ConnectedDirectoryLink[] {
    return content.type === DirectoryNodeType.DirectoryLink ? [content.directoryLink] : [];
  },
  directoryKey(directoryId: Guid): DirectoryNodeDirectoryKey {
    return `${DIRECTORY_NODE_DIRECTORY_PREFIX}${directoryId}`;
  },
  directoryLinkKey(directoryLinkId: Guid): DirectoryNodeDirectoryLinkKey {
    return `${DIRECTORY_NODE_DIRECTORY_LINK_PREFIX}${directoryLinkId}`;
  },
  directoryNodeKey(directoryContent: DirectoryNode): DirectoryNodeKey {
    switch (directoryContent.type) {
      case DirectoryNodeType.Directory:
        return directoryNodeHelpers.directoryKey(directoryContent.directory.id);
      case DirectoryNodeType.DirectoryLink:
        return directoryNodeHelpers.directoryLinkKey(directoryContent.directoryLink.linkId);
    }
  },
  directoryNodeKeyToId(key: DirectoryNodeKey): DirectoryNodeId {
    // TODO: this probably could be generalized and used for other varaint keys as well
    if (key.startsWith(DIRECTORY_NODE_DIRECTORY_PREFIX)) {
      return {
        type: DirectoryNodeType.Directory,
        directoryId: key.slice(DIRECTORY_NODE_DIRECTORY_PREFIX.length),
      };
    }
    if (key.startsWith(DIRECTORY_NODE_DIRECTORY_LINK_PREFIX)) {
      return {
        type: DirectoryNodeType.DirectoryLink,
        directoryLinkId: key.slice(DIRECTORY_NODE_DIRECTORY_LINK_PREFIX.length),
      };
    }

    const errorMessage = `Unknown directory tree key: ${key}`;
    if (DEBUG) {
      throw errorMessage;
    } else {
      console.error(errorMessage);
      return { type: undefined } as any;
    }
  },
  directoryNodeFromKey(
    key: DirectoryNodeKey,
    directories: Record<Guid, ConnectedDirectory>,
    directoryLinks: Record<Guid, ConnectedDirectoryLink>
  ) {
    return directoryNodeHelpers.directoryNodeFromId(
      key && directoryNodeHelpers.directoryNodeKeyToId(key),
      directories,
      directoryLinks
    );
  },
  directoryNodeFromId(
    nodeId: DirectoryNodeId,
    directories: Record<Guid, ConnectedDirectory>,
    directoryLinks: Record<Guid, ConnectedDirectoryLink>
  ) {
    if (!nodeId) {
      return undefined;
    }

    switch (nodeId.type) {
      case DirectoryNodeType.Directory: {
        const directory = directories?.[nodeId.directoryId];
        return directory && directoryNodeHelpers.directoryNode(directory);
      }
      case DirectoryNodeType.DirectoryLink: {
        const directoryLink = directoryLinks?.[nodeId.directoryLinkId];
        return directoryLink && directoryNodeHelpers.directoryLinkNode(directoryLink);
      }
    }
  },
  getTargetDirectory(directoryTree: DirectoryNode) {
    switch (directoryTree.type) {
      case DirectoryNodeType.Directory:
        return directoryTree.directory;
      case DirectoryNodeType.DirectoryLink:
        return directoryTree.directoryLink.linkedDirectory;
    }
  },
  getParentDirectory(directoryTree: DirectoryNode) {
    switch (directoryTree.type) {
      case DirectoryNodeType.Directory:
        return directoryTree.directory.parent;
      case DirectoryNodeType.DirectoryLink:
        return directoryTree.directoryLink.parentDirectory;
    }
  },
  getDirectoryNodeName(directoryTree: DirectoryNode) {
    switch (directoryTree.type) {
      case DirectoryNodeType.Directory:
        return directoryTree.directory.name;
      case DirectoryNodeType.DirectoryLink:
        return directoryTree.directoryLink.linkName;
    }
  },
};
