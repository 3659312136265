import React, { FunctionComponent } from 'react';
import styles from './MasterDetailContent.module.less';
import StackPanel from 'components/StackPanel';
import classnames from 'classnames';

export const MasterDetailContent: FunctionComponent = ({ children }) => {
  return (
    <StackPanel stretch className={classnames(styles.container)}>
      {children}
    </StackPanel>
  );
};
