import { useState, useEffect, useCallback } from 'react';

type KeyboardEventListener = (e: KeyboardEvent) => void;

export function useKeyPress(targetKey: string, onKeyPress?: () => void) {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }: KeyboardEvent) => {
    if (targetKey === key) {
      setKeyPressed(true);
    }
  };

  const upHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (targetKey === key) {
        if (onKeyPress) {
          onKeyPress();
        }
        setKeyPressed(false);
      }
    },
    [onKeyPress]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [upHandler]);

  return keyPressed;
}

export type KeyModifiers = 'altKey' | 'shiftKey' | 'ctrlKey' | 'metaKey';

export function useOnKeyUp(targetKey: string, onKeyUp: (e: KeyboardEvent) => void, modifiers?: KeyModifiers[]) {
  const handleKeyDown: KeyboardEventListener = useCallback(
    (e) => {
      if (e.key === targetKey) {
        if (!modifiers || modifiers.length === 0 || !modifiers.some((m) => !e[m])) {
          onKeyUp && onKeyUp(e);
        }
      }
    },
    [onKeyUp]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, [handleKeyDown]);
}
