import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import colors from 'styles/colors';

const projectUserProfileStatusEnumColorMap: Record<ProjectUserProfileStatusEnum, string> = {
  active: colors['lime-6'],
  expired: undefined,
  invited: colors['orange-6'],
  suspended: colors['red-6'],
  system: undefined,
};

type Props = TagProps & {
  status: ProjectUserProfileStatusEnum;
};

const UserStatus: FunctionComponent<Props> = ({ status, ...restProps }) => (
  <Tag color={projectUserProfileStatusEnumColorMap[status]} {...restProps}>
    <Fmt id={`ProjectUserStatus.${status}`} />
  </Tag>
);

export default UserStatus;
