import { Menu } from 'antd';
import { api } from 'api';
import { useIntl } from 'hooks';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Fmt } from 'locale';
import { FlagIcon } from 'components/HeaderLocaleDropdown/FlagIcon';

type LocaleMenuProps = {
  selectedLocale: LanguageEnum;
  onLocaleChange: (locale: LanguageEnum) => void;
};

const LOCALES = [LanguageEnum.cs, LanguageEnum.en];

export const LocaleMenu: FunctionComponent<LocaleMenuProps> = ({ selectedLocale, onLocaleChange }) => {
  const dispatch = useDispatch<Dispatch>();
  const intl = useIntl();

  const handleSetLocale = useCallback(
    async (param: MenuInfo) => {
      const key = param.key as LanguageEnum;
      onLocaleChange(key);
      const [err, resp] = await api.master.appUsers.setAppUserLang({ language: key });
      if (err) {
        messageError(err, intl);
      } else {
        dispatch.currentAppUser.setData(resp.data);
      }
    },
    [dispatch, intl, onLocaleChange]
  );

  return (
    <Menu selectedKeys={[selectedLocale]} onClick={handleSetLocale}>
      <Menu.ItemGroup title={<Fmt id="general.language" />} />
      {LOCALES.map((locale) => (
        <Menu.Item key={locale}>
          <FlagIcon selectedLocale={locale} />
        </Menu.Item>
      ))}
    </Menu>
  );
};
