import { TransferItem, TransferListProps } from 'antd/lib/transfer';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import React, { ReactNode } from 'react';
import { Comparator, dateComparer } from 'utils/comparators';
import styles from './DiscussionChat.module.less';
import { AttachmentWarning, DiscussionAttachment, DiscussionInput } from './DiscussionInput/DiscussionInput';
import { StagedAttachment } from './DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { DiscussionNote, DiscussionNoteData } from './DiscussionNote/DiscussionNote';

const NOTE_COMPARATOR: Comparator<DiscussionNoteData> = dateComparer.reverse().map((note) => note.createdDate);

type Props = {
  discussionNotes: DiscussionNoteData[];
  disabled?: boolean;
  addNoteLoading?: boolean;
  onSend?: (
    message: string,
    linkedAttachments: Guid[],
    stagedAttachments: StagedAttachment[],
    userNotifications?: Guid[]
  ) => void;
  onEdit?: (
    noteId: Guid,
    message: string,
    linkedAttachments: Guid[],
    stagedAttachments: StagedAttachment[]
  ) => Promise<boolean>;
  onDelete?: (noteId: Guid) => Promise<boolean>;
  onRestore?: (noteId: Guid) => Promise<boolean>;
  availableAttachments?: DiscussionAttachment[];
  availableUsers: ProjectUserProfileListDto[];
  createUserTransferFooter?: (
    value: Guid[],
    setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
  ) => (footerProps: TransferListProps<TransferItem>) => ReactNode;
  getOriginalUrl?: (attachmentId: Guid) => ApiPromise<DownloadUrl>;
  noteClassName?: string;
  padding?: boolean;
  validateLinkedAttachments?: (attachmentIds: Guid[]) => AttachmentWarning;
};

export const DiscussionChat: React.FunctionComponent<Props> = ({
  discussionNotes,
  disabled,
  addNoteLoading,
  onSend,
  onEdit,
  onDelete,
  onRestore,
  availableAttachments,
  availableUsers,
  createUserTransferFooter,
  getOriginalUrl,
  noteClassName,
  padding,
  validateLinkedAttachments,
}) => {
  const notesOrdered = [...(discussionNotes || [])].sort(NOTE_COMPARATOR);

  return (
    <StackPanel vertical scrollable padding={padding} className={styles.content}>
      <DiscussionInput
        onSend={onSend}
        disabled={disabled}
        clearOnSubmit
        availableAttachments={availableAttachments}
        loading={addNoteLoading}
        availableUsers={availableUsers}
        createUserTransferFooter={createUserTransferFooter}
        getOriginalUrl={getOriginalUrl}
        validateLinkedAttachments={validateLinkedAttachments}
      />
      <StackPanel vertical autoHeight>
        {notesOrdered.map((note) => (
          <DiscussionNote
            key={note.id}
            disabled={!note.canUserEdit}
            note={note}
            onEdit={onEdit}
            onDelete={onDelete}
            onRestore={onRestore}
            availableAttachments={availableAttachments}
            getOriginalUrl={getOriginalUrl}
            availableUsers={availableUsers}
            createUserTransferFooter={createUserTransferFooter}
            className={noteClassName}
            validateLinkedAttachments={validateLinkedAttachments}
          />
        ))}
      </StackPanel>
    </StackPanel>
  );
};
