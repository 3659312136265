import { api, createCancelToken } from 'api';
import { DirectoryContentDto, DocumentLinkDto, ServiceError } from 'api/completeApiInterfaces';
import axios, { CancelTokenSource } from 'axios';
import { useSignalRDirectory } from 'config/signalRConnection';
import { useIsMounted } from 'hooks/useIsMounted';
import { useSameCallback } from 'hooks/useSameCallback';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { processApiError } from 'utils';

export type DocumentsLoadingRunType = 'manual' | 'auto' | null;

export const useDocuments = (directoryId: Guid, projectId: Guid) => {
  const isMounted = useIsMounted();
  const [documents, setDocuments] = useState<DirectoryContentDto[]>();
  const [documentLinks, setDocumentLinks] = useState<DocumentLinkDto[]>();
  const [documentsLoading, setDocumentsLoading] = useState<DocumentsLoadingRunType>(null);
  const [documentsError, setDocumentsError] = useState<ServiceError>();
  const [directoryChanged, setDirectoryChanged] = useState<boolean>(false);
  const currentRequestCtsRef = useRef<CancelTokenSource>(null);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    return () => {
      currentRequestCtsRef.current?.cancel('Documents: unmounting');
    };
  }, []);

  useEffect(() => {
    if (directoryId) {
      loadDocuments(true);
    }
  }, [directoryId, projectId]);

  const loadDocuments = useSameCallback(async (directoryIdChanged: boolean = false, isAutoRefresh: boolean = false) => {
    if (!directoryId) return false;

    currentRequestCtsRef.current?.cancel('DocumentsGate: another request queued');
    currentRequestCtsRef.current = createCancelToken();

    if (isMounted.current) {
      if (directoryIdChanged) {
        setDocuments(null);
        setDocumentLinks(null);
        setDirectoryChanged(true);
      }
      setDocumentsLoading(isAutoRefresh ? 'auto' : 'manual');
    }

    const [err, res] = await api.project.directories.getDirectoryContentEx(
      directoryId,
      currentRequestCtsRef.current.token
    );
    if (err) {
      if (axios.isCancel(err)) {
        return true;
      }
      if (isMounted.current) {
        endChangingDirectory(directoryIdChanged);
      }
      processApiError(err, (documentsError) => {
        if (isMounted.current) {
          setDocumentsLoading(null);
          setDocumentsError(documentsError);
        }
      });
      return false;
    }
    if (isMounted.current) {
      setDocuments(res.data.documents);
      setDocumentLinks(res.data.documentLinks);
      setDocumentsLoading(null);
      setDocumentsError(null);
      endChangingDirectory(directoryIdChanged);
    }
    return true;
  });

  const onDocumentRefresh = useCallback(() => {
    loadDocuments(false, true);
  }, [loadDocuments]);

  const onDirectoryRefresh = useCallback(() => {
    dispatch.directories.loadData({ reload: true, silent: true });
  }, [loadDocuments]);

  useSignalRDirectory(onDocumentRefresh, onDirectoryRefresh);

  const endChangingDirectory = (directoryIdChanged: boolean) => {
    if ((directoryIdChanged || directoryChanged) && isMounted.current) {
      setDirectoryChanged(false);
    }
  };

  return [documents, documentLinks, documentsLoading, documentsError, loadDocuments] as const;
};
