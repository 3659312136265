import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DirectoryLinkCreateDto, DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { useEffect } from 'react';

import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './DirectoryCreateLinkForm.module.less';

type Props = FormComponentProps<DirectoryLinkCreateDto> &
  InjectedIntlProps & {
    selectedDirectory: DirectoryListDto;
    errors: JSX.Element[];
  };

const DirectoryCreateLinkForm = React.forwardRef<unknown, Props>(({ intl, form, selectedDirectory, errors }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);
  const { getFieldDecorator } = form;
  return (
    <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <div className={styles.movingDirectoryWrap}>
        <div className={styles.movingDirectoryTitle}>{<Fmt id="DirectoryCreateLinkForm.source" />}</div>
        <PathDisplay path={selectedDirectory.path} className={styles.movingDirectoryPath} />
      </div>
      <Form.Item className={styles.item} label={intl.formatMessage({ id: 'DirectoryCreateLinkForm.label' })}>
        {form.getFieldDecorator<DirectoryLinkCreateDto>('directoryId', {
          initialValue: undefined,
          rules: [requiredRule('forms.items.rules.noEmpty')],
        })(
          <DirectoriesTreeSelectFormItem
            isItemDisabled={(item) => !canWriteInDirectory(item)}
            autoFocus
            dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
            placeholder={intl.formatMessage({ id: 'DirectoryCreateLinkForm.placeholder' })}
          />
        )}
      </Form.Item>
      <Form.Item className={styles.item} label={<Fmt id="DirectoryCreateLinkForm.linkName" />}>
        {getFieldDecorator<DirectoryLinkCreateDto>('name', {
          initialValue: selectedDirectory.name,
          rules: [
            requiredRule('DirectoryEditForm.form.items.name.rules.required', true),
            maxLengthRule('general.maxNameLength'),
            {
              pattern: FILE_AND_FOLDER_NAME_REGEX,
              message: <FilenameErrorMessage />,
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="DocumentsMoveFormModal.errorsTitle.missed" />
    </Form>
  );
});

export default Form.create<Props>()(DirectoryCreateLinkForm);
