import { useSelector } from 'react-redux';
import { directoryRootSelector } from 'store/selectors';

export const ROOT_NAME = 'DMS';

export const getDefaultRootName = (rootNode: { name?: string }): string => {
  return rootNode ? rootNode.name : ROOT_NAME;
};

export const useRootName = () => {
  const rootNode = useSelector(directoryRootSelector);
  return getDefaultRootName(rootNode);
};
