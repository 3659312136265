import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import PathDisplay from 'components/PathDisplay';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { Component, ReactNode } from 'react';
import { ConnectedDirectoryLink } from 'utils/typeMappings/directories/directoryTypes';
import styles from './DirectoryLinkDiscard.module.less';

type Props = FormComponentProps &
  InjectedIntlProps & {
    title: ReactNode;
    directoryLink: ConnectedDirectoryLink;
  };

class DirectoryLinkDiscardForm extends Component<Props> {
  render() {
    const { title, directoryLink } = this.props;

    return (
      <Form>
        <Typography.Text>{title}</Typography.Text>
        {!!directoryLink.linkedDirectory && (
          <div className={styles.discardDirectoryLinkWrap}>
            <div className={styles.discardDirectoryLinkTitle}>
              <Fmt id="DiscardDirectoryLink.Modal.targetFolder" />
            </div>
            <PathDisplay path={directoryLink.linkedDirectory.path} className={styles.discardDirectoryLinkPath} />
          </div>
        )}
      </Form>
    );
  }
}

export default Form.create<Props>()(DirectoryLinkDiscardForm);
