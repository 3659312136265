import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  selectAll: () => void;
  isAnyItemSelected?: boolean;
  allFilesSelected?: boolean;
  deselectAll: () => void;
  selectedFilesIds: Set<Guid>;
  totalItemsCount: number;
};

export const DocumentCheckboxControl: FunctionComponent<Props> = ({
  selectAll,
  isAnyItemSelected,
  allFilesSelected,
  deselectAll,
  selectedFilesIds,
  totalItemsCount,
}) => {
  return (
    <>
      <DocumentCheckbox
        onClickCheckbox={allFilesSelected ? deselectAll : selectAll}
        semiSelected={isAnyItemSelected}
        selected={allFilesSelected}
        tooltip={allFilesSelected ? <Fmt id="DocumentsGridHeader.deselect" /> : <Fmt id="DocumentsGridHeader.select" />}
      />
      {isAnyItemSelected && (
        <CommonHubTooltip title={<Fmt id="general.docMenu.selected.tooltip" />}>
          <Button type="link" onClick={deselectAll} icon={<CloseOutlined />}>
            {`${selectedFilesIds?.size} / ${totalItemsCount}`}
          </Button>
        </CommonHubTooltip>
      )}
    </>
  );
};

export default DocumentCheckboxControl;
