import { Modal } from 'antd';
import { WorkflowApprovalDocumentDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';
import { WorkflowApprovalDocumentActivityList } from './WorkflowApprovalDocumentActivityList';
import { ApprovalDocumentActivity } from './WorkflowApprovalDocumentActivityRow';

type Props = {
  approvalDocument: WorkflowApprovalDocumentDto;
  open: boolean;
  onCancel: () => void;
  additionalData?: (documentActivity: ApprovalDocumentActivity) => ReactNode;
};

export const WorkflowApprovalDocumentActivityModal: FunctionComponent<Props> = React.memo(
  ({ approvalDocument, onCancel, open, additionalData }) => {
    return (
      <Modal
        width={800}
        open={open}
        title={<Fmt id={'WorkflowApprovalDocumentActivityModal.title'} />}
        onCancel={onCancel}
        onOk={onCancel}
        cancelButtonProps={HIDE_BUTTON_PROPS}
        okText={<Fmt id={'general.close'} />}
      >
        <StackPanel stretch vertical scrollable>
          <WorkflowApprovalDocumentActivityList approvalDocument={approvalDocument} additionalData={additionalData} />
        </StackPanel>
      </Modal>
    );
  }
);
