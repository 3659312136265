import * as H from 'history';
import { useStoreSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import IdleTimerActivityCheckModal from './IdleTimerActivityCheckModal';

type Props = { history: H.History };

const IdleTimerActivityCheck: FunctionComponent<Props> = ({ history }) => {
  const appSettings = useStoreSelector((state) => state.appSettings.data);

  const onIdleAction = () => history.replace('/out?force');

  return appSettings?.idleTimeLogoutInterval > 0 ? (
    <IdleTimerActivityCheckModal
      timeoutTime={appSettings?.idleTimeLogoutInterval}
      warningTime={appSettings?.idleTimeWarningInterval}
      onIdleAction={onIdleAction}
    />
  ) : (
    <></>
  );
};

export default IdleTimerActivityCheck;
