import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import React, { FunctionComponent, useCallback } from 'react';

export type DocumentThumbnailProps = {
  className: string;
  tooltip?: string;
  onLinkClick?: (id: Guid) => void;
  id: Guid;
};

const DocumentLinkButton: FunctionComponent<DocumentThumbnailProps> = ({ id, className, tooltip, onLinkClick }) => {
  const handleDocumentLinkClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onLinkClick && onLinkClick(id);
    },
    [onLinkClick, id]
  );

  return !!tooltip ? (
    <CommonHubTooltip title={tooltip}>
      <ShortcutIcon onClick={onLinkClick && handleDocumentLinkClick} className={className} />
    </CommonHubTooltip>
  ) : (
    <ShortcutIcon onClick={onLinkClick && handleDocumentLinkClick} className={className} />
  );
};

export default React.memo(DocumentLinkButton);
