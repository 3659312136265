import StackPanel from 'components/StackPanel';
import React, { CSSProperties, FunctionComponent } from 'react';
import { AutoSizer } from 'react-virtualized';

type Props = {
  minHeight: CSSProperties['minHeight'];
  scrollable?: boolean;
};

export const MinHeightSizer: FunctionComponent<Props> = ({ minHeight, scrollable, children }) => {
  return (
    <StackPanel vertical stretch style={{ minHeight }}>
      <AutoSizer>
        {(size) => (
          <StackPanel vertical scrollable={scrollable} style={size}>
            {children}
          </StackPanel>
        )}
      </AutoSizer>
    </StackPanel>
  );
};
