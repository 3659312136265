import Icon, {
  CalculatorOutlined,
  ContainerOutlined,
  DollarOutlined,
  ExceptionOutlined,
  FileAddOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  HistoryOutlined,
  LineChartOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  ProfileOutlined,
  ProjectOutlined,
  ReadOutlined,
  RetweetOutlined,
  ScheduleOutlined,
  ShareAltOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { CubeCircle } from './CubeCircle';
import { CubeOutlined } from './CubeOutlined';
import { DocumentLinkOutlined } from './DocumentLinkOutlined';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
};

//activity
export const ActivityIcon = HistoryOutlined;

//annotation
export const AnnotationIcon = MessageOutlined;

//bulkAction
export const BulkActionIcon = ProfileOutlined;

//commentProcedure
export const CommentProcedureIcon = ExceptionOutlined;

//construction
export const ConstructionIcon = FundProjectionScreenOutlined;
export const EstimateIcon = OrderedListOutlined;
export const ChangeOrderIcon = RetweetOutlined;
export const WorkInProgressIcon = CalculatorOutlined;
export const InvoicingIcon = DollarOutlined;
export const ScheduleIcon = ScheduleOutlined;
export const FinancialPlanIcon = LineChartOutlined;
export const ConstructionJournalIcon = ReadOutlined;

//model
export const ModelOffIcon: FunctionComponent<Props> = (props) => {
  return <Icon component={CubeOutlined} {...props} />;
};
export const ModelOnIcon: FunctionComponent<Props> = (props) => {
  return <Icon component={CubeCircle} {...props} />;
};

//project
export const ProjectIcon = ProjectOutlined;

//share
export const ShareEntityIcon = ShareAltOutlined;

// report
export const ReportIcon = FileTextOutlined;

//shortcut of file/directory
export const ShortcutIcon: FunctionComponent<Props> = (props) => {
  return <Icon component={DocumentLinkOutlined} {...props} />;
};

//task-assignment
export const TaskIcon = ContainerOutlined;

//workflow
export const WorkflowIcon = PartitionOutlined;
export const WorkflowTemplateIcon = SnippetsOutlined;
export const WorkflowInstantiationIcon = FileAddOutlined;
