import React, { FunctionComponent, useContext, useMemo } from 'react';
import styles from './MasterDetailHeader.module.less';
import { MasterDetailsViewContext } from 'components/MasterDetailsView/MasterDetailsView';
import { BackButton } from 'components/MasterDetailsView/BackButton/BackButton';
import { Breadcrumb } from 'antd';

export const MasterDetailHeader: FunctionComponent = ({ children }) => {
  const masterDetailsViewContext = useContext(MasterDetailsViewContext);

  const parts = useMemo(
    () =>
      Object.values(masterDetailsViewContext?.parts).map((part) => (
        <Breadcrumb.Item key={part.key}>{part.title}</Breadcrumb.Item>
      )),
    [masterDetailsViewContext?.parts]
  );

  return (
    <div className={styles.title}>
      <BackButton />
      {children}
      <Breadcrumb>{parts}</Breadcrumb>
    </div>
  );
};
