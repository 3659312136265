import {
  EstiConFinancialOverviewByUnitDto,
  EstiConObjectState,
  FinancialOverviewByUnitDataDto,
  FinancialOverviewByUnitExtendedDataDto,
} from 'api/completeApiInterfaces';
import Decimal from 'decimal.js';
import { Precisions } from 'hooks/useEsticon';
import { InjectedIntl } from 'locale';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { addValues, subValues, sumValuesByChildren } from 'utils/buildingGridUtils';
import uuid from 'uuid';

export type FinancialOverviewByUnitReportData = EstiConFinancialOverviewByUnitDto & {
  nakladyDosud: number;
  nakladyCelkem: number;
  profit: number;
  phaseName?: string;
  coefficient: string | number;
  projectId: Guid;
  key?: string;
  children?: FinancialOverviewByUnitReportData[];
};

type FinancialOverviewSummedPrices = Pick<
  FinancialOverviewByUnitReportData,
  | 'cena'
  | 'fakturaceDosud'
  | 'fakturaceZbyva'
  | 'nakladyDosud'
  | 'nakladyZbyva'
  | 'nakladyCelkem'
  | 'profit'
  | 'soD'
  | 'zbvFakturace'
  | 'zbvNavrhova'
  | 'zbvInterni'
  | 'vlastniSubdodavky'
  | 'subdodavky'
>;

const PRICE_COLUMNS: (keyof FinancialOverviewSummedPrices)[] = [
  'cena',
  'fakturaceDosud',
  'fakturaceZbyva',
  'nakladyDosud',
  'nakladyZbyva',
  'nakladyCelkem',
  'profit',
  'soD',
  'zbvFakturace',
  'zbvNavrhova',
  'zbvInterni',
  'vlastniSubdodavky',
  'subdodavky',
];

const calculateCoefficient = (totalCost: number, expectedCost: number): number | string => {
  return expectedCost !== 0 ? new Decimal(totalCost / expectedCost).toDecimalPlaces(2).toNumber() : '';
};

const createProjectRowData = (
  rows: EstiConFinancialOverviewByUnitDto[],
  externalData: Dictionary<FinancialOverviewByUnitExtendedDataDto>,
  precisions: Precisions,
  availableProjectIds: Set<Guid>,
  intl: InjectedIntl
): FinancialOverviewByUnitReportData[] =>
  rows.map((unitRow) => {
    const nakladyDosud = externalData[unitRow.evidencniCislo].erpNaklady;
    const nakladyCelkem = addValues(nakladyDosud, unitRow.nakladyZbyva, precisions.price);
    const projectId = externalData[unitRow.evidencniCislo].projectId;
    return {
      ...unitRow,
      key: unitRow.id,
      begin: unitRow.begin && moment(unitRow.begin).format('L'),
      end: unitRow.end && moment(unitRow.end).format('L'),
      phaseName: intl.formatMessage({ id: `FinancialOverviewByUnitReport.phase.${unitRow.phase}` }),
      nakladyDosud: nakladyDosud,
      nakladyCelkem: nakladyCelkem,
      profit: subValues(unitRow.cena, nakladyCelkem),
      coefficient: calculateCoefficient(unitRow.cena, nakladyCelkem),
      projectId: availableProjectIds.has(projectId) ? projectId : undefined,
    };
  });

export const createFinancialOverviewByUnitData = (
  financialOverviewData: FinancialOverviewByUnitDataDto,
  precisions: Precisions,
  availableProjectIds: Set<Guid>,
  intl: InjectedIntl
): FinancialOverviewByUnitReportData[] => {
  const projectRows = createProjectRowData(
    financialOverviewData.esticonResponse.rows,
    financialOverviewData.extendedDatas,
    precisions,
    availableProjectIds,
    intl
  );

  const totalSum = sumValuesByChildren<FinancialOverviewSummedPrices>(projectRows, precisions.price, PRICE_COLUMNS);

  const rootKey = uuid();
  return [
    {
      ...totalSum,
      state: EstiConObjectState.Clean,
      coefficient: calculateCoefficient(totalSum.cena, totalSum.nakladyCelkem),
      sign: intl.formatMessage({ id: 'general.total' }),
      id: rootKey,
      phase: undefined,
      projectId: undefined,
      children: [...projectRows],
      key: rootKey,
    },
  ];
};
