import { to } from 'api/await-to';
import {
  PrepareAddRevisionRequestDto,
  RefreshWopiResponseDto,
  StartWopiRequestDto,
  StartWopiResponseDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const wopiApi = {
  startEdit: (data: StartWopiRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<StartWopiResponseDto>(`/wopi/startedit`, data, { cancelToken })
    ),
  addRevision: (data: PrepareAddRevisionRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<StartWopiResponseDto>(`/wopi/addrevision`, data, { cancelToken })
    ),
  refreshToken: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.put<RefreshWopiResponseDto>(`/wopi/${documentId}/refreshToken`, { cancelToken })
    ),
};
