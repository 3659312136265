import { InputRef, message } from 'antd';
import { api } from 'api';
import { ShareDownloadDto, ShareDownloadResultDto, SharedSharedOutputTypeEnum } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import DocumentMultipleActionError, { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ShareDownloadForm, { ShareDownloadFormData } from './ShareDownloadForm';

type Props = InjectedIntlProps &
  FormModalProps<ShareDownloadResultDto> & {
    directoryId?: Guid;
    includeSubdirectories?: boolean;
    documentsIds: string[];
    revisionIds: string[];
    sharingType: SharedSharedOutputTypeEnum;
  };

const ShareDownloadFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, revisionIds, documentsIds, sharingType, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [okDocumentsIds, setOkDocumentsIds] = useState<string[]>(null);

  const handleSubmit: FormSubmitHandler<ShareDownloadFormData> = async (values) => {
    const toShareIds = !!okDocumentsIds ? okDocumentsIds : documentsIds;
    if (toShareIds.length > 0) {
      const uniqueEmails: Set<string> = new Set(Object.keys(values.emails).map((k) => values.emails[k]));
      const data: ShareDownloadDto = {
        emails: Array.from(uniqueEmails),
        shareMessage: values.message,
        shareDocumentRevisions: revisionIds,
        shareDocuments: toShareIds,
        sharedOutputType: sharingType,
        sendMails: true,
        requiredPin: true,
      };
      const [err, res] = await api.project.documents.shareDownload(data);
      if (err) {
        processApiError(err, (error) => {
          if (
            error != null &&
            !!error.errorData &&
            error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleMoveObstacleError
          ) {
            const errData = error.errorData as IMultipleDocumentErrorData;
            setErrors(errData.documentsErrors.map(transformError));
            setOkDocumentsIds(
              documentsIds.filter((doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc))
            );
            return null;
          }
          message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
          handleClose();
        });
        return null;
      }

      message.info(props.intl.formatMessage({ id: `ShareDownloadFormModal.success` }));

      onSubmit(res.data);
      handleClose();
      return null;
    }
    handleClose();
    return null;
  };
  const handleClose = () => {
    onClose();
    setErrors([]);
    setOkDocumentsIds(null);
  };

  const shareableEntitiesCount = useMemo(() => (!!okDocumentsIds ? okDocumentsIds.length : documentsIds?.length), [
    okDocumentsIds,
    documentsIds,
  ]);

  const submitTextId = useMemo(() => {
    if (!shareableEntitiesCount) return 'general.ok';
    return errors.length === 0 ? 'ShareDownloadFormModal.send' : 'ShareDownloadFormModal.sendNext';
  }, [shareableEntitiesCount, errors]);

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onCancel={handleClose}
      onClose={handleClose}
      confirmClose={false}
      titleId="ShareDownloadFormModal.title"
      submitTextId={submitTextId}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <>
          <ShareDownloadForm intl={intl} wrappedComponentRef={formRef} setRef={setInputRef} />
          <DocumentMultipleActionError
            errors={errors}
            titleId={
              !!shareableEntitiesCount
                ? 'shareDownloadFormModal.errorsTitle.obstaclePartial'
                : 'shareDownloadFormModal.errorsTitle.obstacle'
            }
          />
        </>
      )}
    </FormModalWrapper>
  );
};

export default injectIntl(ShareDownloadFormModal);
