import { Radio } from 'antd';
import DisplayName from 'components/DisplayName';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { FilterDisplay } from 'components/filters/render/FilterDisplay/FilterDisplay';
import SearchInput from 'components/SearchInput';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckFormat, checkNullable, checkString, smartFilter } from 'utils';
import { IOption, OptionKey } from '../SelectFilter/SelectFilter';
import styles from './RadioFilter.module.less';

export type RadioFilterValue<K extends OptionKey> = K | null;

const IS_EMPTY = (value: RadioFilterValue<OptionKey>) => !value;
const DEFAULT_VALUE: RadioFilterValue<OptionKey> = null;
const CLEARED_VALUE: React.SetStateAction<RadioFilterValue<OptionKey>> = DEFAULT_VALUE;
const CHECK_FORMAT: CheckFormat<OptionKey> = checkNullable(checkString);

type Props<K extends OptionKey> = {
  label: ReactNode;
  title?: ReactNode;
  value: RadioFilterValue<K>;
  onChange: React.Dispatch<React.SetStateAction<RadioFilterValue<K>>>;
  options: IOption<K>[];
  showSearchMinItems?: number;
};

const RadioFilterComponent = <K extends OptionKey>({
  label,
  title,
  value,
  onChange,
  options,
  showSearchMinItems = 5,
}: Props<K>) => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  const [dropdownVisible, setDropdownVisible] = useState(false);
  useEffect(() => {
    if (dropdownVisible) {
      setSearchPhrase('');
    }
  }, [dropdownVisible]);

  const clearFilter = useCallback(() => onChange(CLEARED_VALUE as React.SetStateAction<K>), [onChange]);

  const selectedLabel = useMemo(
    () =>
      value && (
        <DisplayName className={styles.firstSelected}>
          {options.find((option) => option.id === value)?.title}
        </DisplayName>
      ),
    [value, options]
  );

  const items = useMemo(
    () =>
      options
        .filter((option) => !searchPhrase || smartFilter(option.title, searchPhrase))
        .map((option) => (
          <Radio
            key={option.id}
            checked={value === option.id}
            className={styles.filterOption}
            onChange={() => onChange(option.id)}
          >
            <DisplayName>{option.label ? option.label : option.title}</DisplayName>
          </Radio>
        )),
    [options, value, searchPhrase, onChange]
  );

  return (
    <FilterDisplay
      label={label}
      value={selectedLabel}
      isEmpty={IS_EMPTY(value)}
      clearFilter={clearFilter}
      dropdownVisible={dropdownVisible}
      setDropdownVisible={setDropdownVisible}
    >
      <div className={styles.filterDropDownHeader}>
        {options.length > showSearchMinItems && (
          <SearchInput value={searchPhrase} onSearch={setSearchPhrase} className={styles.searchInput} />
        )}
        {title && <div className={styles.title}>{title}</div>}
      </div>
      <div className={styles.filterList}>{items}</div>
    </FilterDisplay>
  );
};

export const RadioFilter = React.memo(RadioFilterComponent);

// helper functions
export const createRadioFilterFunction = <K extends OptionKey, T>(valueSelector: (item: T) => K) => (
  value: RadioFilterValue<K>
) => (item: T) => valueSelector(item) === value;

// To be used by variants
export const createBaseRadioFilter = <K extends OptionKey>(
  key: string
): Omit<CommonFilter<RadioFilterValue<any>>, 'render'> => ({
  key,
  isEmpty: IS_EMPTY,
  defaultValue: DEFAULT_VALUE,
  clearedValue: CLEARED_VALUE,
  checkFormat: CHECK_FORMAT,
});

// filters
type AdditionalProps<K extends OptionKey> = Omit<Props<K>, 'value' | 'onChange'>;

const createCommonRadioFilter = <K extends OptionKey>(
  key: string,
  props: AdditionalProps<K>
): CommonFilter<RadioFilterValue<K>> => ({
  ...createBaseRadioFilter(key),
  render: (value, setValue) => <RadioFilter {...props} value={value} onChange={setValue} />,
});

export const createFrontendRadioFilter = <K extends OptionKey, T>(
  key: string,
  props: AdditionalProps<K>,
  valueSelector: (item: T) => K
): FrontendFilter<T, RadioFilterValue<K>> => ({
  ...createCommonRadioFilter(key, props),
  filter: createRadioFilterFunction(valueSelector),
});

export const createBackendRadioFilter = <K extends OptionKey, T>(
  key: string,
  props: AdditionalProps<K>,
  serialize: (accumulator: T, value: RadioFilterValue<K>) => T
): BackendFilter<T, RadioFilterValue<K>> => ({
  ...createCommonRadioFilter(key, props),
  serialize,
});
