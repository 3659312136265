import { Modal } from 'antd';
import { useActiveProject, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { projectsMapSelector } from 'store/selectors';
import { RedirectOption, redirectWithOption } from 'utils';
import styles from './AssignmentProjectCheck.module.less';

type Props = {
  projectId: Guid;
  assignmentId: Guid;
};

export const AssignmentProjectCheck: FunctionComponent<Props> = ({ projectId, assignmentId, children }) => {
  const activeProjectId = useActiveProject()?.id;

  const selectProject = () => {
    const url = `/projects/${projectId}/messagecenter/assignments/${assignmentId}`;
    redirectWithOption(url, RedirectOption.Replace);
  };

  const history = useHistory();
  const { url } = useRouteMatch();
  const onCancel = useCallback(() => {
    const oneBackUrl = url
      .split('/')
      .slice(0, -1)
      .join('/');
    history.replace(oneBackUrl);
  }, []);

  const allProjects = useStoreSelector(projectsMapSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  return (
    <>
      {children}
      <Modal
        title={<Fmt id="AssignmentProjectCheck.switchProject" />}
        wrapClassName={styles.backdrop}
        open={projectId && projectId !== activeProjectId}
        okText={<Fmt id="AssignmentProjectCheck.switchProject" />}
        onOk={selectProject}
        onCancel={onCancel}
      >
        <p>
          <Fmt id="AssignmentProjectCheck.youMustSwitchProject" />
        </p>
        <p>
          <Fmt id="AssignmentProjectCheck.targetProject" />
          {': '}
          <strong>{allProjects?.[projectId]?.name}</strong>
        </p>
      </Modal>
    </>
  );
};
