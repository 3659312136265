import { useCallback, useState } from 'react';

export const useImagePaste = (onImageCapture: (data: File) => void) => {
  const [clipBoardAttachment, setClipboardAttachment] = useState<File>(null);

  const onPasteHandler = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const dataItems = event.clipboardData.items;
    for (let i = 0; i < dataItems.length; i++) {
      if (dataItems[i].type.startsWith('image/')) {
        const imageFile = dataItems[i].getAsFile();
        setClipboardAttachment(imageFile);
        onImageCapture(imageFile);
      }
    }
  }, []);

  return [clipBoardAttachment, onPasteHandler] as const;
};
