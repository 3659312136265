import { Button, Col, Modal, ModalProps, Row } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import Tree, { DataNode } from 'antd/lib/tree';
import { masterApi } from 'api/completeApi';
import { EstiConUtvaryStructureDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DisplayName from 'components/DisplayName';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel';
import { useApiData } from 'hooks';
import { useEsticonFirmsWithProjects } from 'hooks/useEsticonFirmsWithProjects';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './ReportUnitSettingsModal.module.less';

type Props = Omit<ModalProps, 'onCancel' | 'onOk' | 'okButtonProps' | 'cancelButtonProps' | 'footer'> & {
  organizationId: Guid;
  onUnitSettingsDisplay: (unit: EstiConUtvaryStructureDto) => ReactNode;
  onClose: () => void;
};

const mapEsticonUnitsToData = (
  esticonUnits: EstiConUtvaryStructureDto[],
  onUnitSettingsDisplay: (unit: EstiConUtvaryStructureDto) => ReactNode
): DataNode[] =>
  esticonUnits
    ?.sort((a, b) => a.sign.localeCompare(b.sign))
    .map((unit) => ({
      key: unit.id,
      title: (
        <StackPanel>
          <DisplayName className={styles.permissionName}>
            {unit.sign} - {unit.name}
          </DisplayName>
          <div className={styles.permission}>{onUnitSettingsDisplay(unit)}</div>
        </StackPanel>
      ),
      children: mapEsticonUnitsToData(unit.structure, onUnitSettingsDisplay),
    })) || [];

const ReportUnitSettingsModal: FunctionComponent<Props> = (props) => {
  const { organizationId, onUnitSettingsDisplay, onClose, ...restProps } = props;

  const [selectedEsticonFirmId, setSelectedEsticonFirmId] = useState<Guid>();

  const { esticonFirms, firmsWithProjectsLoading } = useEsticonFirmsWithProjects(organizationId);
  const availableFirmOptions = useMemo(
    () =>
      Object.values(esticonFirms).map((firmRow): DefaultOptionType => ({ value: firmRow.id, label: firmRow.nazev })),
    [esticonFirms]
  );

  const [esticonUnits, esticonUnitsError, esticonUnitsLoading, loadEsticonUnits] = useApiData((ct) =>
    masterApi.EsticonReports.orq.id.firms.id.projectunits.get(organizationId, selectedEsticonFirmId, ct)
  );

  useEffect(() => {
    !!selectedEsticonFirmId && loadEsticonUnits();
  }, [selectedEsticonFirmId]);

  const unitTreeData = useMemo(() => mapEsticonUnitsToData(esticonUnits, onUnitSettingsDisplay), [
    esticonUnits,
    onUnitSettingsDisplay,
  ]);

  return (
    <Modal
      title={<Fmt id="ReportUnitSettingsModal.title" />}
      width={500}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          <Fmt id="general.close" />
        </Button>,
      ]}
      onCancel={onClose}
      {...restProps}
    >
      <Margin bottom>
        <Row>
          <Col span={10}>
            <Fmt id="ReportUnitColorAssign.selectEsticonFirm" />
          </Col>
          <Col span={14}>
            <Select
              options={availableFirmOptions}
              size="middle"
              loading={firmsWithProjectsLoading}
              onChange={setSelectedEsticonFirmId}
              className={styles.esticonDbSelect}
            />
          </Col>
        </Row>
      </Margin>
      {!!selectedEsticonFirmId && (
        <ContentGate loading={esticonUnitsLoading} error={esticonUnitsError}>
          {unitTreeData?.length && (
            <Tree
              treeData={unitTreeData}
              selectable={false}
              defaultExpandedKeys={[unitTreeData[0].key]}
              className={styles.permissionTree}
            />
          )}
        </ContentGate>
      )}
    </Modal>
  );
};

export default ReportUnitSettingsModal;
