import { to } from 'api/await-to';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export type OAuthToken = {
  accessToken: string;
  expires: Date;
};

export const forgeApi = {
  getToken: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<OAuthToken>('/forge/token', { cancelToken })
    ),
};
