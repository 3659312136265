import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ReportDetailProps } from '../../ReportDetail';
import ProjectsInRealisationOverviewReportPage from './ProjectsInRealizationOverviewReportPage';

const ProjectsInRealisationOverviewReportRouter: FunctionComponent<ReportDetailProps> = ({ ...reportProps }) => {
  return (
    <Switch>
      <Route
        path="/reports/:organizationId/:report/:firmId?/:date?/:year?/:widgetId?"
        render={(props) => <ProjectsInRealisationOverviewReportPage {...reportProps} {...props} />}
      />
    </Switch>
  );
};

export default ProjectsInRealisationOverviewReportRouter;
