import { EstiConProjectPhase } from 'api/completeApiInterfaces';
import { IntlMessageId } from 'locale/messages/cs';

export const estiConProjectPhaseTranslations: Record<EstiConProjectPhase, IntlMessageId> = {
  [EstiConProjectPhase.Priprava]: 'Esticon.enums.phase.Priprava',
  [EstiConProjectPhase.Nabidka]: 'Esticon.enums.phase.Nabidka',
  [EstiConProjectPhase.Realizace]: 'Esticon.enums.phase.Realizace',
  [EstiConProjectPhase.Dokonceno]: 'Esticon.enums.phase.Dokonceno',
  [EstiConProjectPhase.Neziskano]: 'Esticon.enums.phase.Neziskano',
};
