import {
  ExternalApplicationsProjectSettingsDto,
  ProjectHubDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { AvailableAppEnum, ExternAppListItem, ExternalAppSourceTypeEnum } from './externAppsTypes';

const externalApplicationTypeMap: Record<
  ProjectSettingExternalAppSettingsEnum,
  Extract<
    AvailableAppEnum,
    | AvailableAppEnum.ConstructionJournal
    | AvailableAppEnum.ExternalUrl
    | AvailableAppEnum.TextPage
    | AvailableAppEnum.DocumentWindow
  >
> = {
  [ProjectSettingExternalAppSettingsEnum.constructionJournal]: AvailableAppEnum.ConstructionJournal,
  [ProjectSettingExternalAppSettingsEnum.pageUrl]: AvailableAppEnum.ExternalUrl,
  [ProjectSettingExternalAppSettingsEnum.textPage]: AvailableAppEnum.TextPage,
  [ProjectSettingExternalAppSettingsEnum.document]: AvailableAppEnum.DocumentWindow,
};

export const loadExternalApplicationsListItems = async (
  _project: ProjectHubDto,
  externalApplications: ExternalApplicationsProjectSettingsDto,
  ct: CancelToken
): Promise<ExternAppListItem[]> => {
  if (!externalApplications?.settings?.length) {
    return [];
  }

  return externalApplications.settings.map((setting) => ({
    type: ExternalAppSourceTypeEnum.ExternalSource,
    appType: externalApplicationTypeMap[setting.type],
    externalAppSetting: setting,
  }));
};
