import { SortOrder } from 'api/completeApiInterfaces';
import {
  CommonFilter,
  FiltersPersistentKey,
  FilterWithValue,
  FrontendFilter,
  Serializable,
} from 'components/filters/filterTypes';
import { FrontendOrderOption, OrderOption, OrderValue } from 'components/filters/orderTypes';
import { useActiveProject } from 'hooks/useActiveProject';
import { useBoolean } from 'hooks/useBoolean';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterItem } from 'store/models/storeModelinterfaces';
import { Dispatch, RootState } from 'store/store';
import { valueOrProducer } from 'utils';

const ORDER_KEY = '_ORDER';

const getFilterDefaultValue = (filter: CommonFilter, storedFilters: FilterItem | null) => {
  if (!!storedFilters?.filters && filter.key in storedFilters.filters) {
    const storedValue = storedFilters.filters[filter.key];
    try {
      filter.checkFormat(storedValue);
      return storedValue;
    } catch (ex) {
      console.warn('Filter value did not match the required format.');
      console.warn('This should only happen when the filter format changes in a new version.');
      console.warn('If you see this message on every load, there is a bug in the checkFormat method.');
      console.warn('Filter key:', filter.key, ', storedValue:', storedValue, ', exception: ', ex);
    }
  }
  return filter.defaultValue;
};

export const clearAllFilters = (
  filters: CommonFilter[],
  setFilterValue: (key: string, value: React.SetStateAction<Serializable>) => void
) => {
  filters.forEach((filter) => setFilterValue(filter.key, filter.clearedValue));
};

export const useFilters = <F extends CommonFilter, O extends OrderOption>(
  filters: F[],
  orderOptions: O[],
  persistentKey?: FiltersPersistentKey,
  isGlobalFilter: boolean = false
) => {
  // load stored filters
  const dispatch = useDispatch<Dispatch>();
  const projectId = useActiveProject()?.id;
  const toolbarFiltersSets = useSelector((state: RootState) => {
    const filters =
      state.filterState.toolbarFiltersSets[getPersistentKey(persistentKey, projectId, isGlobalFilter)]?.filters;
    const order = state.filterState.toolbarFiltersSets[getPersistentKey(persistentKey) + ORDER_KEY]?.order;
    const result: FilterItem = { filters, order };
    return result;
  });

  // process filters
  const [filterValues, setFilterValues] = useState<Record<string, Serializable>>(() =>
    filters.reduce((accu, curr) => ({ ...accu, [curr.key]: getFilterDefaultValue(curr, toolbarFiltersSets) }), {})
  );

  const filtersWithValues = useMemo<FilterWithValue<Serializable, F>[]>(
    () =>
      filters.map((filter) => ({
        ...filter,
        value:
          filter.key in filterValues ? filterValues[filter.key] : getFilterDefaultValue(filter, toolbarFiltersSets),
      })),
    [filters, filterValues]
  );

  /* We got a really nasty situation here.
   *
   * Imagine someone adds a new filter to the `filters` array "dynamically" (during the hook's lifetime).
   * Then, `filterValues` will not have the default value for this filter, because that is computed only once.
   * But that can ba fixed with an useEffect, right? Wrong!
   *
   * useEffect will set the value asynchronously, while the rendering of the newly added filter will happen synchronously.
   * The `|| toolbarFiltersSets?.filters[filter.key] || filter.defaultValue` part in `filtersWithValues` will take care of
   * not feeding and undefined filter value to the render function, but what if the component calls `setValue` on the first render?
   * Even when called in an useEffect, it will process first, even before the hypothetical useEffect here that would fill that value.
   * So `setValue` could read an undefined filter value!
   *
   * And that's why we must take the existing value from `filtersWithValues` if it doesn't exist in the current value.
   */
  const setFilterValue = useCallback(
    (key: string, value: React.SetStateAction<Serializable>) =>
      setFilterValues((values) => {
        const existingValue =
          key in values ? values[key] : filtersWithValues.find((filter) => filter.key === key)?.value;
        const newValue = valueOrProducer(value, existingValue);
        // use deep equality to prevent unnecessary re-rendering
        // TODO: use deepCompareValues in filters and then use only reference equality between existingValue and newValue
        return isEqual(existingValue, newValue) ? values : { ...values, [key]: newValue };
      }),
    []
  );

  const clearFilters = useCallback(() => clearAllFilters(filters, setFilterValue), [filters, setFilterValue]);

  // process order
  const [order, setOrder] = useState<OrderValue>(() => {
    if (toolbarFiltersSets?.order && orderOptions.some((option) => option.key === toolbarFiltersSets.order.key)) {
      return toolbarFiltersSets.order;
    }

    const defaultOrder = orderOptions.find((option) => !!option.defaultOrder) || orderOptions[0];
    if (defaultOrder) {
      return { key: defaultOrder.key, direction: defaultOrder.defaultOrder };
    }

    return null;
  });

  const orderData = useMemo(
    () =>
      order && {
        ...order,
        ...(orderOptions.find((option) => option.key === order.key) || orderOptions[0]),
      },
    [order, orderOptions]
  );

  // save filters and order
  useEffect(() => {
    if (persistentKey) {
      const filters = filtersWithValues.reduce((accu, filter) => ({ ...accu, [filter.key]: filter.value }), {});
      dispatch.filterState.setFilter({
        itemKey: getPersistentKey(persistentKey, projectId, isGlobalFilter),
        filterItem: { filters, order: null },
      });
      dispatch.filterState.setFilter({
        itemKey: getPersistentKey(persistentKey) + ORDER_KEY,
        filterItem: { filters: null, order },
      });
    }
  }, [persistentKey, filterValues, order, projectId]);

  // return
  return { filtersWithValues, setFilterValue, order: orderData, setOrder, clearFilters } as const;
};

const getPersistentKey = (key: FiltersPersistentKey, projectId?: string, isGlobalFilter: boolean = false) =>
  projectId && !isGlobalFilter ? projectId + key : key;

export const useFrontendFilters = <T>(
  filters: FrontendFilter<T>[],
  orderOptions: FrontendOrderOption<T>[],
  allItems: T[] | null,
  persistentKey?: FiltersPersistentKey
) => {
  const [isFilterActive, activateFilters, deactivateFilters, setActiveState] = useBoolean(true);

  const toggleFilters = useCallback(() => {
    setActiveState((active) => !active);
  }, []);

  const { filtersWithValues, setFilterValue, order, setOrder, clearFilters } = useFilters(
    filters,
    orderOptions,
    persistentKey
  );

  const filteredItems = useMemo<T[]>(() => {
    const filterFunctions = filtersWithValues
      .filter((filter) => !filter.isEmpty(filter.value))
      .map((filter) => filter.filter(filter.value));
    return allItems?.filter((item: T) => filterFunctions.every((filter) => filter(item))) || [];
  }, [allItems, filtersWithValues]);

  const orderedItems = useMemo(
    () =>
      order
        ? [...filteredItems].sort(order.direction === SortOrder.asc ? order.compare : (a, b) => order.compare(b, a))
        : filteredItems,
    [filteredItems, order]
  );

  const itemCounts = `${filteredItems.length} / ${allItems?.length || 0}`;

  const hasFilteredOutItems: boolean = useMemo(
    () => orderedItems && allItems && orderedItems.length < allItems.length,
    [orderedItems, allItems]
  );

  return {
    orderedItems,
    filters: filtersWithValues,
    setFilterValue,
    itemCounts,
    order,
    setOrder,
    orderOptions,
    clearFilters,
    hasFilteredOutItems: hasFilteredOutItems,
    isFilterActive,
    toggleFilters,
  } as const;
};
