import { EstiCategoryEnum } from 'api/completeApiInterfaces';

export const ESTICON_CATEGORIES_ORDERED: EstiCategoryEnum[] = [
  EstiCategoryEnum.Stavba,
  EstiCategoryEnum.ZBV,
  EstiCategoryEnum.Cerpani,
  EstiCategoryEnum.CerpaniSdruzeni,
  EstiCategoryEnum.Fakturace,
  EstiCategoryEnum.FakturaSdruzeni,
  EstiCategoryEnum.Harmonogram,
];
