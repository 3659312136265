import { CloseOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styles from './FilterDisplay.module.less';
import classnames from 'classnames';

const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['click'];

type Props = {
  label: ReactNode;
  value: ReactNode | null;
  isEmpty: boolean;
  isOpen?: boolean;
  clearFilter: () => void;
  dropdownVisible?: boolean; // elevateable state
  setDropdownVisible?: (visible: boolean) => void; // elevateable state
  icon?: ReactNode; // TODO: refactor and use icons
};

export const FilterDisplay: FunctionComponent<Props> = ({
  label,
  value,
  icon,
  isEmpty,
  isOpen,
  clearFilter,
  dropdownVisible: dropdownVisibleProp,
  setDropdownVisible: setDropdownVisibleProp,
  children,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(dropdownVisibleProp);
  useEffect(() => {
    setDropdownVisible(dropdownVisibleProp);
  }, [dropdownVisibleProp]);

  const showDropdown = useCallback(
    () => (setDropdownVisibleProp ? setDropdownVisibleProp(true) : setDropdownVisible(true)),
    [setDropdownVisibleProp]
  );

  const overlay = (
    <div className={styles.filterDropdown}>
      {children}
      <div className={styles.filterDropdownFooter}>
        <Button type="link" disabled={isEmpty} className={styles.clearFilter} onClick={clearFilter}>
          <CloseOutlined />
          <Fmt id="general.clearFilter" />
        </Button>
      </div>
    </div>
  );

  // Hack to align InlineDateRangeModal correctly
  const [_ready, setReady] = useState(false);
  useEffect(() => {
    if (dropdownVisible) {
      setTimeout(() => setReady(true), 1);
    }
  }, [dropdownVisible]);

  // Always align popup relative to the component that displayed the popup
  const popupRef = useRef();
  const getPopupContainer = useCallback(() => popupRef.current, []);

  return (
    <div ref={popupRef}>
      <Dropdown
        open={dropdownVisible}
        onOpenChange={setDropdownVisibleProp || setDropdownVisible}
        trigger={DROPDOWN_TRIGGER}
        placement="bottomRight"
        overlay={overlay} // TODO: refactor
        getPopupContainer={getPopupContainer}
      >
        <Button
          type="default"
          onClick={showDropdown}
          icon={icon}
          className={classnames(styles.filterButton, !isEmpty && styles.isSet, isOpen && styles.isOpen)}
        >
          <Space>
            {label}
            {!!value && <div className={styles.tag}>{value}</div>}
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};
