import { Button, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { OrgExtendedPermissionValueEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DashboardTile from 'components/DashboardTile/DashboardTile';
import EmptySimple from 'components/EmptySimple';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { useReportWidgetsContext } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useCurrentAppUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { reportCategoriesMap } from 'pages/ReportsListPage/ReportsList';
import React, { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  classNames?: string;
};

const DashboardReportTile: FunctionComponent<Props> = ({ classNames, children }) => {
  const {
    orderedWidgets,
    dashboardWidgetsError,
    dashboardWidgetsLoading,
    showReportAddModal,
    showSharedReportAddModal,
    organizations,
  } = useReportWidgetsContext();
  const intl = useIntl();
  const currentAppUser = useCurrentAppUser();

  const showSelectedReports = useMemo(() => {
    const supportedReports = Object.values(reportCategoriesMap).flatMap((mapVal) => mapVal);
    const permissionArrays = currentAppUser.organizationUsers?.flatMap((user) => user.extendedPermissions) || [];
    return permissionArrays.some(
      (perm) =>
        supportedReports.includes(perm.permissionType) && perm.permission !== OrgExtendedPermissionValueEnum.none
    );
  }, [currentAppUser]);

  const reportMenu: MenuProps = useMemo(() => {
    const items: ItemType[] = [
      {
        key: 'addReport',
        label: intl.formatMessage({ id: 'ProjectsListPage.addNewReport' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: () => showReportAddModal(),
      },
      {
        key: 'addSharedReport',
        label: intl.formatMessage({ id: 'ProjectsListPage.addNewSharedReport' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: showSharedReportAddModal,
      },
    ];
    return { items };
  }, [intl, organizations?.length, showReportAddModal, showSharedReportAddModal]);

  if (!showSelectedReports) return null;

  return (
    <DashboardTile title={<Fmt id="ProjectsListPage.reports" />} menu={reportMenu} containerClass={classNames}>
      <ContentGate
        error={dashboardWidgetsError}
        loading={dashboardWidgetsLoading}
        empty={!orderedWidgets?.length}
        emptyAlternative={
          <EmptySimple description={<Fmt id="MainDashboard.noSelectedReports" />}>
            <Link to="/reports">
              <Button type="primary" size="large">
                <Fmt id="MainDashboard.viewAllReports" />
              </Button>
            </Link>
          </EmptySimple>
        }
      >
        {!!orderedWidgets && children}
      </ContentGate>
    </DashboardTile>
  );
};

export default DashboardReportTile;
