import { CalendarOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import { CalendarSetDto, LanguageEnum } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import GeneralSelectSettingsItem from 'components/GeneralSelectSettingsItem/GeneralSelectSettingsItem';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralTextAreaSettingsItem from 'components/GeneralTextAreaSettingsItem/GeneralTextAreaSettingsItem';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import { FlagIcon } from 'components/HeaderLocaleDropdown/FlagIcon';
import { SelectSettingsItemOption } from 'components/SettingsItem/SelectSettingsItem';
import StackPanel from 'components/StackPanel';
import SwitchCheck from 'components/SwitchCheck';
import { CalendarSettingsFormData } from 'components/forms/CalendarSettingsForm/CalendarSettingsForm';
import { formDataToDto } from 'components/forms/CalendarSettingsForm/CalendarSettingsFormModal';
import { useApiData } from 'hooks';
import { Fmt } from 'locale';
import { omit } from 'lodash';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { InjectedIntl } from 'react-intl';
import uuid from 'uuid';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import styles from '../../ProjectsTemplates.module.less';
import { ProjectTemplateCalendarSettingsFormButton } from './ProjectTemplateCalendarSettingsFormButton';

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
  organizationId: Guid;
  organizationStorageAreas?: string[];
};

const languageOptions: SelectSettingsItemOption[] = [
  { value: LanguageEnum.cs, text: <FlagIcon selectedLocale={LanguageEnum.cs} /> },
  { value: LanguageEnum.en, text: <FlagIcon selectedLocale={LanguageEnum.en} /> },
];

const ProjectTemplateGeneralTab: FunctionComponent<Props> = ({
  intl,
  projectTemplate,
  dispatchProjectTemplate,
  organizationId,
  organizationStorageAreas,
}) => {
  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => masterApi.projects.timezones.param.get(projectTemplate.language, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [projectTemplate.language]);

  const timezoneOptions = useMemo(() => {
    return timeZones?.timeZones.map((tz) => ({ value: tz.id, text: tz.name })) || [];
  }, [timeZones]);

  const storageAreasOptions = useMemo(() => {
    return organizationStorageAreas?.map((area) => ({ value: area, text: area })) || [];
  }, [organizationStorageAreas]);

  const handleNameChange = useCallback(async (value: string) => {
    dispatchProjectTemplate({ type: 'setName', name: value });
    return true;
  }, []);

  const handleDescriptionChange = useCallback(async (value: string) => {
    dispatchProjectTemplate({ type: 'setDescription', description: value });
    return true;
  }, []);

  const handleTimezoneChange = useCallback(
    async (timezoneId: Guid) => {
      dispatchProjectTemplate({
        type: 'setTimezone',
        timezoneId: timeZones?.timeZones.find((timezone) => timezone.id === timezoneId)?.id,
      });
      return true;
    },
    [dispatchProjectTemplate, timeZones]
  );
  const handleStorageAreaChange = useCallback(
    async (value: string) => {
      dispatchProjectTemplate({ type: 'SetStorageArea', storageArea: value });
      return true;
    },
    [dispatchProjectTemplate]
  );

  const handleLanguageChange = useCallback(async (language: LanguageEnum) => {
    dispatchProjectTemplate({ type: 'setLanguage', language: language });
    return true;
  }, []);

  const handlePrimarySubmitterCanEditProcessorTeams = useCallback(async (checked: boolean) => {
    dispatchProjectTemplate({
      type: 'primarySubmitterCanEditProcessorTeams',
      primarySubmitterCanEditProcessorTeams: checked,
    });
    return true;
  }, []);

  const handlePrimaryProcessorCanEditSubmitterTeams = useCallback(async (checked: boolean) => {
    dispatchProjectTemplate({
      type: 'primaryProcessorCanEditSubmitterTeams',
      primaryProcessorCanEditSubmitterTeams: checked,
    });
    return true;
  }, []);

  const handleUseWorkDays = useCallback(async (checked: boolean) => {
    dispatchProjectTemplate({
      type: 'useWorkDays',
      useWorkDays: checked,
    });
    return true;
  }, []);

  const handleCalendarSetting = useCallback(
    async (data: CalendarSettingsFormData) => {
      const calendar: CalendarSetDto = formDataToDto(data, organizationId);
      const templateCalendar = omit(calendar, ['organizationId', 'projectId']);
      dispatchProjectTemplate({
        type: 'setCalendar',
        calendar: {
          id: uuid(),
          ...templateCalendar,
          excludedDays: templateCalendar.excludedDays.map((day) => ({ id: uuid(), ...day })),
        },
      });
      return;
    },
    [dispatchProjectTemplate, organizationId]
  );

  const handleCalendarClear = useCallback(async () => {
    dispatchProjectTemplate({
      type: 'setCalendar',
      calendar: null,
    });
    return;
  }, [dispatchProjectTemplate]);

  return (
    <PageContent title={<Fmt id={'general.general.setting'} />}>
      <StackPanel vertical scrollable className={styles.masterPanel}>
        <GeneralSettingsContainer>
          <GeneralTextSettingsItem
            value={projectTemplate.name}
            onSave={handleNameChange}
            headline={
              <>
                <Fmt id="ProjectTemplateGeneralTab.templateName.title" />
                <Typography.Text type="danger"> * </Typography.Text>
              </>
            }
            maxLength={apiConstraints.projectTemplateCreateDto.name.maxLength}
          />
          <GeneralTextAreaSettingsItem
            value={projectTemplate.description}
            onSave={handleDescriptionChange}
            headline={<Fmt id="ProjectTemplateGeneralTab.templateDescription.title" />}
            maxLength={apiConstraints.projectTemplateCreateDto.description.maxLength}
          />
          {!!organizationStorageAreas?.length && (
            <GeneralSelectSettingsItem
              value={projectTemplate.storageArea}
              onSave={handleStorageAreaChange}
              headline={
                <>
                  <Fmt id="general.storagearea" />
                  {!!organizationStorageAreas?.length && <Typography.Text type="danger"> * </Typography.Text>}
                </>
              }
              options={storageAreasOptions}
              showSearch
            />
          )}
          <GeneralSelectSettingsItem
            value={projectTemplate.timeZoneId}
            onSave={handleTimezoneChange}
            headline={
              <>
                <Fmt id="ProjectCreateForm.form.timeZone" />
                <Typography.Text type="danger"> * </Typography.Text>
              </>
            }
            options={timezoneOptions}
            showSearch
          />
          <GeneralSelectSettingsItem
            value={projectTemplate.language}
            onSave={handleLanguageChange}
            headline={<Fmt id="general.language" />}
            options={languageOptions}
          />
          <GeneralSettingsItem
            icon={<CalendarOutlined />}
            title={intl.formatMessage({ id: 'general.workDaysCalendar' })}
            input={
              <>
                <ProjectTemplateCalendarSettingsFormButton
                  calendar={projectTemplate.calendar}
                  organizationId={organizationId}
                  buttonType="link"
                  onSuccess={handleCalendarSetting}
                />
                {!!projectTemplate.calendar && (
                  <DeleteButton onClick={handleCalendarClear} disabled={false} loading={false} />
                )}
              </>
            }
          />
        </GeneralSettingsContainer>
        <GeneralSettingsContainer title={<Fmt id="CommentProcedureRolesSettings.title" />}>
          <GeneralSettingsItem
            title={<Fmt id="CommentProcedureRolesSettings.mainProcessorCanEditSubmitterTeams" />}
            input={
              <SwitchCheck
                checked={projectTemplate.primaryProcessorCanEditSubmitterTeams}
                onChange={(checked) => handlePrimaryProcessorCanEditSubmitterTeams(checked)}
                label={true ? <Fmt id="general.on" /> : <Fmt id="general.off" />}
                labelOnLeft
              />
            }
          />
          <GeneralSettingsItem
            title={<Fmt id="CommentProcedureRolesSettings.mainSubmitterCanEditProcessorTeams" />}
            input={
              <SwitchCheck
                checked={projectTemplate.primarySubmitterCanEditProcessorTeams}
                onChange={(checked) => handlePrimarySubmitterCanEditProcessorTeams(checked)}
                label={true ? <Fmt id="general.on" /> : <Fmt id="general.off" />}
                labelOnLeft
              />
            }
          />
          <GeneralSettingsItem
            title={<Fmt id="CommentProcedureRolesSettings.useWorkDays" />}
            input={
              <SwitchCheck
                checked={projectTemplate.useWorkDays}
                onChange={(checked) => handleUseWorkDays(checked)}
                label={true ? <Fmt id="general.on" /> : <Fmt id="general.off" />}
                labelOnLeft
              />
            }
          />
        </GeneralSettingsContainer>
      </StackPanel>
    </PageContent>
  );
};

export default ProjectTemplateGeneralTab;
