import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, HTMLProps } from 'react';
import styles from './FlowLayout.module.less';

type GapSize = 'small' | 'normal' | 'mediumLarge' | 'large' | 'extraLarge' | 'none';

const gapSizeToClassName: Record<GapSize, typeof styles[keyof typeof styles]> = {
  none: styles.gapNone,
  small: styles.gapSmall,
  normal: styles.gapNormal,
  mediumLarge: styles.gapMediumLarge,
  large: styles.gapLarge,
  extraLarge: styles.gapExtraLarge,
};

type Props = {
  alignRight?: boolean;
  className?: string;
  style?: CSSProperties;
  gapSize?: GapSize;
  wrap?: boolean;
  growFirst?: boolean;
  growLast?: boolean;
  fullWidth?: boolean;
  divProps?: HTMLProps<HTMLDivElement>;
};

export const FlowLayout: FunctionComponent<Props> = ({
  alignRight,
  className,
  style,
  wrap,
  gapSize = 'normal',
  growFirst,
  growLast,
  children,
  fullWidth,
  divProps,
}) => {
  return (
    <div
      className={classNames(
        styles.flowLayout,
        alignRight ? styles.alignRight : styles.alignLeft,
        wrap && styles.wrap,
        gapSizeToClassName[gapSize],
        growFirst && styles.growFirst,
        growLast && styles.growLast,
        fullWidth && styles.fullWidth,
        className
      )}
      style={style}
      {...divProps}
    >
      {children}
    </div>
  );
};
