import { message } from 'antd';
import { api } from 'api';
import { DirectoryContentDto, DocumentDto, DocumentLinkDto } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentLinkRenameForm, { DocumentsLinkRenameFormData } from './DocumentLinkRenameForm';

export type DocumentLinkForEdit = Omit<DocumentLinkDto, 'linkedDocument'> & {
  linkedDocument: DirectoryContentDto | DocumentDto;
};

type Props = InjectedIntlProps &
  FormModalProps<number> & {
    focusedDocumentLink: DocumentLinkForEdit;
    showTargetPath?: boolean;
    showLinkLocation?: boolean;
  };

export enum SubmitButtonTextsEnum {
  save = 'general.save',
  ok = 'general.ok',
}

const DocumentLinkRenameFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, focusedDocumentLink, showTargetPath, showLinkLocation, ...restProps } = props;
  const [submitButtonText, setSubmitButtonText] = useState<SubmitButtonTextsEnum>(SubmitButtonTextsEnum.ok);

  const handleSubmit: FormSubmitHandler<DocumentsLinkRenameFormData> = async (values) => {
    const linkName = values.documentLinkName.trim();
    if (submitButtonText === SubmitButtonTextsEnum.ok) {
      onClose();
      return null;
    }
    const [err, res] = await api.project.documentLinks.updateLink(focusedDocumentLink.id, {
      name: linkName,
    });

    if (err) {
      processApiError(err, (error) => {
        message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      });
      return null;
    }
    await onSubmit(null);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onCancel={onClose}
      onClose={onClose}
      title={<Fmt id="DocumentLinkRenameFormModal.title" />}
      confirmClose={false}
      submitTextId={submitButtonText}
      cancelTextId={'general.close'}
      width={500}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DocumentLinkRenameForm
          focusedDocumentLink={focusedDocumentLink}
          setSubmitButtonText={setSubmitButtonText}
          showLinkLocation={showLinkLocation}
          showTargetPath={showTargetPath}
          wrappedComponentRef={formRef}
          intl={intl}
        />
      )}
    </FormModalWrapper>
  );
};

export default injectIntl(DocumentLinkRenameFormModal);
