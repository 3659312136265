import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import {
  ConnectableElement,
  DragElementWrapper,
  DragSourceMonitor,
  DragSourceOptions,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import styles from './InnerDragTile.module.less';

export type DragHook = DragElementWrapper<DragSourceOptions>;

export type DragIdentifier = {
  groupId: number;
  itemId: number;
};

type DragChild = (drag: DragHook) => ReactNode;

export type DragTileProps = {
  children: ReactNode | DragChild;
  typeName?: string;
  groupId?: number;
  itemId: number;
  onMove: (from: DragIdentifier, to: DragIdentifier) => void;
  disable?: boolean;
};

type Item = DragIdentifier & {
  type: string;
};

export const DragTile: FunctionComponent<DragTileProps> = (props) => {
  const typeName = props.typeName || 'drag';

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: typeName, groupId: props.groupId, itemId: props.itemId },
    canDrag: (monitor: DragSourceMonitor) => !props.disable,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isDropping }, drop] = useDrop({
    accept: typeName,
    canDrop: (item: Item, monitor: DropTargetMonitor) => !props.disable,
    drop: (target: Item) =>
      props.onMove(
        { groupId: target.groupId, itemId: target.itemId },
        { groupId: props.groupId, itemId: props.itemId }
      ),
    collect: (monitor: DropTargetMonitor) => ({
      isDropping: !!monitor.isOver() && monitor.canDrop(),
    }),
  });

  const hasCallback = useMemo(() => typeof props.children === 'function', [props.children]);

  const refHook = useCallback(
    (node: ConnectableElement) => {
      if (!hasCallback) {
        drag(node);
      }
      preview(node);
      drop(node);
    },
    [drag, preview, drop, hasCallback]
  );

  return (
    <div ref={refHook} className={classNames(styles.tile, isDropping && styles.drop, isDragging && styles.drag)}>
      {hasCallback ? (props.children as DragChild)(drag) : props.children}
    </div>
  );
};
