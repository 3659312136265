import { Tree } from 'antd';
import { TreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent } from 'react';
import { GenericTree } from 'store/selectors/genericSelectors';
import styles from './CategoryTree.module.less';

type TreeNode = {
  id: Guid;
  name: string;
  parentId: Guid;
};

type Props<T extends TreeNode> = Omit<TreeProps, 'treeData'> & {
  treeData: GenericTree<T>[];
  onEdit?: (nodeId: Guid) => void;
  onDelete?: (nodeId: Guid) => void;
};

export const ROOT_KEY = 'null';

const CategoryTree: FunctionComponent<Props<TreeNode>> = (props) => {
  const { treeData, onEdit, onDelete, className, ...restProps } = props;

  const selectedKey = props.selectedKeys && props.selectedKeys.length && props.selectedKeys[0];

  const renderTreeNodeTitle = (node: TreeNode) => {
    if (selectedKey !== node.id) return <DisplayName text={node.name} />;
    return (
      <div className={styles.categoryTreeNode}>
        <div className={styles.categoryTreeNodeTitle}>{<DisplayName text={node.name} />}</div>
        <div className={styles.categoryTreeNodeTitleActions}>
          {onEdit && !!node.parentId && <EditButton onClick={() => onEdit(node.id)} />}
          {onDelete && !!node.parentId && <DeleteButton onClick={() => onDelete(node.id)} />}
        </div>
      </div>
    );
  };

  const renderTreeNodes = (nodes: GenericTree<TreeNode>[]) => {
    return nodes.map((node) => {
      if (node.children.length) {
        // has more children
        return (
          <Tree.TreeNode key={node.id} title={renderTreeNodeTitle(node)}>
            {renderTreeNodes(node.children)}
          </Tree.TreeNode>
        );
      } else {
        // is leaf
        return <Tree.TreeNode key={node.id} title={renderTreeNodeTitle(node)} isLeaf />;
      }
    });
  };

  return (
    <Tree blockNode showLine className={classNames(styles.categoryTree, 'hide-file-icon', className)} {...restProps}>
      {renderTreeNodes(treeData)}
    </Tree>
  );
};

export default CategoryTree;
