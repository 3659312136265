import { EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { projectApi } from 'api/completeApi';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { useActiveProject, useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { userHasPermission } from 'utils/userHasPermission';
import { ExternAppListItemProps, ExternalAppSourceTypeEnum } from '../externAppsTypes';
import DocumentViewSetForm from './DocumentViewSetForm';

export const DocumentViewListItem: FunctionComponent<ExternAppListItemProps & {
  type: ExternalAppSourceTypeEnum.ExternalSource;
}> = ({ externalAppSetting, onDelete: _onDelete, ...listItemProps }) => {
  const dispatch = useDispatch<Dispatch>();
  const [isDocumentViewEditVisible, showDocumentViewEdit, hideDocumentViewEdit] = useBoolean();
  const currentUser = useCurrentProjectUser();
  const intl = useIntl();
  const activeProject = useActiveProject();

  const handleDelete = useCallback(async () => {
    const [err] = await projectApi.projectsetting.externalapplication.id.delete(externalAppSetting?.id);
    if (err) {
      messageError(err, intl);
    }
    void dispatch.externalApplicationsSettings.loadData({ reload: true });
  }, [externalAppSetting, intl]);

  const canEdit = currentUser.isAdmin || userHasPermission(currentUser, ExtendedPermissionEnum.externalApplications);
  const appSettingsWithDocument = {
    ...externalAppSetting,
    data: 'documentId' in externalAppSetting.data ? externalAppSetting.data : { documentId: undefined },
  };

  return (
    <>
      <GeneralSettingsItem
        selected={listItemProps.selected}
        icon={<FilePdfOutlined />}
        title={<Fmt id="ProjectSettingsPage.ExternApps.DocumentView.listItemTitle" />}
        description={<div>{externalAppSetting.name}</div>}
        additionalActions={
          <>
            {listItemProps.additionalActions}
            {canEdit && <EditButton onClick={showDocumentViewEdit} />}
            {canEdit && <DeleteButtonConfirm onConfirm={handleDelete} />}
            {
              <Link to={`/projects/${activeProject.id}/documents/${appSettingsWithDocument.data.documentId}`}>
                <Button type="link" icon={<EyeOutlined />} />
              </Link>
            }
          </>
        }
      />
      <DocumentViewSetForm
        open={isDocumentViewEditVisible}
        onAppAdded={hideDocumentViewEdit}
        onCancel={hideDocumentViewEdit}
        externalAppSetting={appSettingsWithDocument}
      />
    </>
  );
};
