import { api } from 'api';
import { OrgUserDto, OrgUsersRequestDto } from 'api/completeApiInterfaces';
import { useCurrentAppUser, useStoreSelector } from 'hooks';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import OrganizationUserEditForm, { OrgUserEditFormData } from './OrganizationUserEditForm';

type Props = FormModalProps<OrgUsersRequestDto> & { toEditUser: OrgUserDto };
const OrganizationUsersEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, toEditUser, ...restProps } = props;
  const [usefulFirstName, setUsefulFirstName] = useState('');
  const [usefulLastName, setUsefulLastName] = useState('');
  const currentUser = useCurrentAppUser();

  useEffect(() => {
    setUsefulFirstName(toEditUser?.firstname);
    setUsefulLastName(toEditUser?.lastname);
  }, [toEditUser]);

  const currentAppUserState = useStoreSelector((state) => state.currentAppUser);

  const isCurrentUserAspeHubAdmin = currentAppUserState.data.isAdmin;

  const isAdminSettingDisabled = useMemo(() => {
    return currentUser.username === toEditUser?.appUserProfile.username && !isCurrentUserAspeHubAdmin;
  }, [currentUser, toEditUser, isCurrentUserAspeHubAdmin]);

  const handleSubmit: FormSubmitHandler<OrgUserEditFormData> = async (values) => {
    const data: OrgUsersRequestDto = {
      users: [
        {
          language: values.language,
          mail: toEditUser.appUserProfile.username,
          isAdmin: values.isAdmin,
          namesFromOrganization: values.namesFromOrganization,
          firstname: values.namesFromOrganization ? usefulFirstName : toEditUser.firstname,
          lastname: values.namesFromOrganization ? usefulLastName : toEditUser.lastname,
        },
      ],
      organizationId: toEditUser.organizationId,
    };

    const [err] = await api.master.organization.setorgusers(data);
    if (err) return err;
    onSubmit(undefined);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'OrganizationUsersEditFormModal.title'}
      submitTextId="general.save"
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <OrganizationUserEditForm
          intl={intl}
          wrappedComponentRef={formRef}
          toEditUser={toEditUser}
          usefulFirstName={usefulFirstName}
          usefulLastName={usefulLastName}
          setUsefulFirstName={setUsefulFirstName}
          setUsefulLastName={setUsefulLastName}
          isAdminSettingDisabled={isAdminSettingDisabled}
        />
      )}
    </FormModalWrapper>
  );
};

export default OrganizationUsersEditFormModal;
