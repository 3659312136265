import Icon, {
  AppstoreAddOutlined,
  CheckCircleOutlined,
  CloudDownloadOutlined,
  CloudSyncOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  DisconnectOutlined,
  DownloadOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  FolderOutlined,
  LinkOutlined,
  LockFilled,
  MessageOutlined,
  PlusOutlined,
  ShareAltOutlined,
  StarFilled,
  StarOutlined,
  UndoOutlined,
  UnlockOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { ShortcutIcon } from './HubEntitiesIcons';
import { StampOutlined } from './StampOutlined';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
};

//common editing
export const EditIcon = EditOutlined;
export const DeleteIcon = DeleteOutlined;
export const AddIcon = PlusOutlined;
export const RestoreIcon = UndoOutlined;

//directory-directoryLink
export const DirectoryAddIcon = FolderAddOutlined;
export const DirectoryMoveIcon = ExportOutlined;
export const DirectoryEditIcon = EditOutlined;
export const DirectoryDiscardIcon = DeleteOutlined;

//directoryReport
export const GetDirectoryReportIcon = ExportOutlined;

//downloadZIP
export const DownloadZipIcon = CloudDownloadOutlined;
export const DownloadZipThisDirOnlyIcon = FileAddOutlined;
export const DownloadZipDeepWithFilesIcon = FolderAddOutlined;
export const DownloadZipDeepWithoutFilesIcon = FolderOutlined;

//favorites
export const AddToFavoriteIcon = StarOutlined;
export const RemoveFromFavoriteIcon = StarFilled;

//file-document-revision
export const FileAddIcon = FileAddOutlined;
export const FileDownloadIcon = CloudDownloadOutlined;
export const FileWithAnnotationDownloadIcon = CloudSyncOutlined;
export const AnnotateIcon = MessageOutlined;
export const NewTaskIcon = CheckCircleOutlined;
export const ReservateIcon = LockFilled;
export const CancelReservationIcon = UnlockOutlined;
export const AddBindingIcon = LinkOutlined;
export const RemoveBindingIcon = DisconnectOutlined;

//share
export const ShareIcon = ShareAltOutlined;
export const ShareDownloadIcon = ShareAltOutlined;
export const ShareAppUsersIcon = DeploymentUnitOutlined;

//showGroupedModel
export const ShowGroupedModelIcon = AppstoreAddOutlined;

//shortcut
export const CreateShortcutIcon = ShortcutIcon;

// sign document
export const SignDocumentIcon: FunctionComponent<Props> = (props) => {
  return <Icon component={StampOutlined} {...props} />;
};
export const AddSignedDocumentIcon = FileAddOutlined;

//subscribe
export const SubscribeIcon = EyeOutlined;

//upload-download
export const UploadIcon = UploadOutlined;
export const DownloadIcon = DownloadOutlined;
