import { useState } from 'react';
import { immutableSetAdd, immutableSetDelete } from 'utils';

export const useItemsSet = <T>(): [Set<T>, (data: T) => void, (data?: T) => void] => {
  const [items, setItems] = useState<Set<T>>(new Set());

  const addItem = (data: T) => setItems((s) => immutableSetAdd(s, data));

  const deleteItem = (data?: T) => setItems((s) => (data ? immutableSetDelete(s, data) : new Set<T>()));

  return [items, addItem, deleteItem];
};
