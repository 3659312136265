import { message } from 'antd';
import { api } from 'api';
import { DirectoryDto, DirectoryListDto, DocumentsDiscardDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  DocumentsMultipleActionObstaclesEnum,
  IMultipleDocumentErrorData,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentsDiscardForm from './DocumentsDiscardForm';

type Props = InjectedIntlProps &
  FormModalProps<void> & {
    selectedDocumentIds: Guid[];
    selectedDocumentLinkIds?: Guid[];
    selectedDirectory: DirectoryDto | DirectoryListDto;
  };

const DocumentsDiscardFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, selectedDocumentIds, selectedDocumentLinkIds, selectedDirectory, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [isInvalidFile, setIsInvalidFile] = useState<boolean>(false);
  const [hasLink, setHasLink] = useState<boolean>(false);
  const [selectedOkDocuments, setSelectedOkDocuments] = useState<Guid[]>(null);
  const [selectedOkLinks, setSelectedOkLinks] = useState<Guid[]>(null);

  const handleSubmit: FormSubmitHandler = async () => {
    const selected = !!selectedOkDocuments ? selectedOkDocuments : selectedDocumentIds;
    const selectedLinks = !!selectedOkLinks ? selectedOkLinks : selectedDocumentLinkIds || [];

    if (!!selected.length || !!selectedLinks.length) {
      const documentsDiscardDto: DocumentsDiscardDto = {
        documentsIds: selected,
        documentLinksIds: selectedDocumentLinkIds || [],
        removeDocToDocLinks: hasLink,
        removeReferences: hasLink,
      };
      const [err] = await api.project.directories.discardDocuments(selectedDirectory.id, documentsDiscardDto);
      if (err) {
        processApiError(err, (error) => {
          if (error != null && !!error.errorData) {
            if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleDiscardObstacleError) {
              const errData = error.errorData as IMultipleDocumentErrorData;
              setSelectedOkDocuments(
                selected.filter(
                  (doc) =>
                    !errData.documentsErrors.some(
                      (errDoc) =>
                        errDoc.objectId === doc &&
                        errDoc.documentsObstacles !== DocumentsMultipleActionObstaclesEnum.HasDocToDocLink
                    )
                )
              );
              setSelectedOkLinks(
                selectedLinks.filter(
                  (doc) =>
                    !errData.documentsErrors.some(
                      (errDoc) =>
                        errDoc.objectId === doc &&
                        errDoc.documentsObstacles !== DocumentsMultipleActionObstaclesEnum.HasDocToDocLink
                    )
                )
              );
              setErrors(errData.documentsErrors.map(transformError));

              errData.documentsErrors.forEach((error) => {
                if (error.documentsObstacles === DocumentsMultipleActionObstaclesEnum.HasDocToDocLink) setHasLink(true);
                else setIsInvalidFile(true);
              });
              return null;
            }
          }
          message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
        });
        return null;
      }
      onSubmit();
      resetDialog();
      return null;
    }
    resetDialog();
    onClose();
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setErrors([]);
    setSelectedOkDocuments(null);
    setSelectedOkLinks(null);
    setHasLink(false);
  };

  const submitTextId = useMemo(() => {
    if (selectedOkDocuments?.length + selectedOkLinks?.length === 0 && errors.length !== 0) return 'general.ok';
    return errors.length === 0
      ? 'DocumentsDiscardFormModal.button.Discard'
      : isInvalidFile
      ? hasLink
        ? 'DocumentsDiscardFormModal.button.DiscardLinksNext'
        : 'DocumentsDiscardFormModal.button.DiscardNext'
      : 'DocumentsDiscardFormModal.button.DiscardLinks';
  }, [selectedOkDocuments, selectedOkLinks, errors, isInvalidFile, hasLink]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onCancel={handleClose}
      onClose={handleClose}
      titleId="DocumentsDiscardFormModal.title"
      visible={true}
      confirmClose={false}
      {...restProps}
      submitTextId={submitTextId}
    >
      {({ intl, formRef }) => (
        <>
          <DocumentsDiscardForm
            title={
              <>
                <Fmt
                  id="DocumentsDiscardForm.DiscardDocuments"
                  values={{
                    count: !!selectedOkDocuments
                      ? selectedOkDocuments.length
                      : !!selectedDocumentIds
                      ? selectedDocumentIds.length
                      : 0,
                  }}
                />
                <br />
                <Fmt
                  id="DocumentsDiscardForm.DiscardDocumentLinks"
                  values={{
                    count: !!selectedOkLinks
                      ? selectedOkLinks.length
                      : !!selectedDocumentLinkIds
                      ? selectedDocumentLinkIds.length
                      : 0,
                  }}
                />
              </>
            }
            wrappedComponentRef={formRef}
            intl={intl}
            errors={errors}
            errorsTextId={'DocumentsDiscardForm.errorsTitle.missed'}
          />
        </>
      )}
    </FormModalWrapper>
  );
};

export default injectIntl(DocumentsDiscardFormModal);
