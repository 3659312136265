import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DerivativesFileViewerModal from './DerivativesFileViewerModal';

type Transformation<T extends {}> = (item: T, index?: number) => CommonDerivedFileViewerItem;

export type CommonDerivedFileViewerItem = {
  id: Guid;
  title: ReactNode;
  blobToken: string;
  secondaryName?: ReactNode;
  getOriginalUrl: () => Promise<string>;
};

type Props<T> = {
  items: T[];
  previewId: string;
  visible: boolean;
  onCancel: () => void;
  transform: Transformation<T>;
  // getOriginalUrl: () => Promise<string>;
  debounceTimeout?: number;
  previewSecFileId?: Guid;
  setPreviewDocumentId: (previewId: Guid) => void;
};

const CommonDerivativesFileViewer = <T extends {}>({
  items: originalItems,
  previewId,
  visible,
  onCancel,
  transform,
  setPreviewDocumentId,
  debounceTimeout = 400,
}: Props<T>) => {
  const [debouncedPreviewDocument, setDebouncedPreviewDocument] = useState<CommonDerivedFileViewerItem>(undefined);

  const lastDocumentId = useRef<Guid>(undefined);
  const useDebounce = useRef<boolean>(false);

  useEffect(() => {
    if (visible) {
      useDebounce.current = false;
    }
  }, [visible]);

  const previewDocumentId = previewId;

  const items = useMemo(() => {
    return originalItems ? originalItems.map(transform).filter((item) => !!item) : [];
  }, [originalItems]);

  const handleOnChangeIndexCallback = useCallback(
    (newIndex: number) => {
      setPreviewDocumentId?.(items[newIndex].id);
    },
    [previewDocumentId, items]
  );

  const previewDocumentIndex = useMemo(() => items.findIndex((d) => d.id === previewDocumentId), [
    previewDocumentId,
    items,
  ]);
  const previewDocument = useMemo(() => items[previewDocumentIndex], [previewDocumentIndex, items]);

  useEffect(() => {
    if (useDebounce.current) {
      const handler = setTimeout(() => setDebouncedPreviewDocument(previewDocument), debounceTimeout);
      return () => clearTimeout(handler);
    } else {
      useDebounce.current = true;
      setDebouncedPreviewDocument(previewDocument);
      return () => {};
    }
  }, [previewDocument, debounceTimeout]);

  useEffect(() => {
    if (debouncedPreviewDocument && debouncedPreviewDocument.id !== lastDocumentId.current) {
      lastDocumentId.current = debouncedPreviewDocument.id;
    }
  }, [debouncedPreviewDocument]);

  // has to render the modal only if it is visible (to destroy and recreate it with new data)
  // TODO: should be refactored to automatically rerender the modal when the data changes
  if (!previewDocument || !debouncedPreviewDocument?.blobToken || !visible) return null;

  return (
    <DerivativesFileViewerModal
      uid={debouncedPreviewDocument.id}
      visible
      onCancel={onCancel}
      title={previewDocument.title}
      blobToken={debouncedPreviewDocument.blobToken}
      hasPrev={items.length !== 1}
      hasNext={items.length !== 1}
      itemsCount={items.length}
      itemIndex={previewDocumentIndex}
      onSetIndex={handleOnChangeIndexCallback}
      getOriginalUrl={previewDocument.getOriginalUrl}
    />
  );
};

export default CommonDerivativesFileViewer;
