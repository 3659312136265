import { Tag } from 'antd';
import { formatNumberLocal } from 'utils/buildingGridUtils';
import React, { FC } from 'react';
import { InjectedIntl } from 'locale';
import styles from './MeasurementsResult.module.less';

type Props = {
  intl: InjectedIntl; // must be provided by parent component because it is not rendered in context
  name: string;
  surface: number;
  volume: number;
  unitString: string;
  precision?: number;
};

export const MeasurementsResult: FC<Props> = ({ intl, name, surface, volume, precision = 2, unitString }) => {
  const locale = intl.locale;

  return (
    <div className={styles.results}>
      {intl.formatMessage({ id: 'Forge.measurements.result.title' })}
      <div className={styles.title}>{name}</div>
      <div>
        {intl.formatMessage({ id: 'Forge.measurements.result.surface' })}{' '}
        <Tag className={styles.tag}>
          {formatNumberLocal(surface, precision, locale)} {unitString}
          <sup>2</sup>
        </Tag>
        <br />
        {intl.formatMessage({ id: 'Forge.measurements.result.volume' })}{' '}
        <Tag className={styles.tag}>
          {formatNumberLocal(volume, precision, locale)} {unitString}
          <sup>3</sup>
        </Tag>
      </div>
    </div>
  );
};
