import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  ExtendedPermissionPatchDto,
  ExtendedPermissionTemplateCreateDto,
  ExtendedPermissionTemplateDto,
} from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import React, { FunctionComponent, useState } from 'react';
import { processApiError } from 'utils';
import ProjectTemplatePermissionPresetForm, {
  ProjectTemplatePermissionPresetFormData,
} from './ProjectTemplatePermissionPresetForm';

type Props = FormModalProps<ExtendedPermissionTemplateDto> & {
  referencedTemplate: ExtendedPermissionTemplateDto;
  organizationId: Guid;
};

const ProjectTemplatePermissionPresetFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, referencedTemplate, organizationId, ...restProps } = props;
  const [isSaving, setStaving] = useState<boolean>();

  const handleSubmit: FormSubmitHandler<ProjectTemplatePermissionPresetFormData> = async (values) => {
    setStaving(true);

    const templateData: ExtendedPermissionTemplateCreateDto = {
      organizationId: organizationId,
      name: values.name,
      description: values.description,
      settings: referencedTemplate.extendedPermissions.settings.map(
        (setting): ExtendedPermissionPatchDto => ({
          permission: setting.permission,
          permissionType: setting.permissionType,
        })
      ),
    };
    const [err, res] = await masterApi.projects.tempates.extendedpermissiontemplate.post(templateData);
    if (err) {
      processApiError(err, (error) => {
        setStaving(false);
        message.error(error.message);
      });
      return;
    }

    onSubmit(res.data);
    setStaving(false);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'ProjectTemplatePermissionPresetFormModal.title'}
      submitTextId={'general.save'}
      forceLoading={isSaving}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => <ProjectTemplatePermissionPresetForm intl={intl} wrappedComponentRef={formRef} />}
    </FormModalWrapper>
  );
};

export default ProjectTemplatePermissionPresetFormModal;
