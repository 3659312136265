import { Alert } from 'antd';
import { api } from 'api';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { useApiData } from 'hooks';
import { Fmt } from 'locale';
import Organizations from 'pages/AspeHubSettingsPage/Organizations';
import React, { FunctionComponent, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';

type Props = RouteComponentProps<ProjectRouteParams>;

const OrganizationsSettingsPage: FunctionComponent<Props> = ({ match }) => {
  const [
    organizationsAdminReport,
    organizationsAdminReportError,
    organizationsAdminReportLoading,
    loadOrganizationsAdminReport,
  ] = useApiData((ct) => api.master.organization.getOrganizationsAdminReport(ct), {
    autoload: true,
  });

  const appUsers = useMemo(() => organizationsAdminReport?.appUsers, [organizationsAdminReport]);
  const organizations = useMemo(
    () => organizationsAdminReport?.organizations.filter((organization) => organization.isOrganizationAdmin),
    [organizationsAdminReport]
  );

  const userCanAddOrganization = useMemo(() => organizationsAdminReport?.isAppAdmin, [organizationsAdminReport]);

  const isAvailableSetOrganizations = useMemo(() => {
    return organizations?.some((organization) => organization.isOrganizationAdmin);
  }, [organizations]);

  const page = (
    <Organizations
      organizations={organizations}
      appUsers={appUsers}
      loadOrganizationsAdminReport={loadOrganizationsAdminReport}
      organizationsAdminReportLoading={organizationsAdminReportLoading}
      organizationsAdminReportError={organizationsAdminReportError}
      userCanAddOrganization={userCanAddOrganization}
    />
  );

  if (!isAvailableSetOrganizations && !organizationsAdminReportLoading)
    return (
      <Alert
        style={{ margin: 24, width: '100%' }}
        message={<Fmt id="general.error" />}
        description={<Fmt id="serviceError.OrganizationAdminAccessRequiredError" />}
        type="error"
        showIcon
      />
    );

  return <SideMenuActivator menuItemKey={SideMenuKey.ORGANIZATIONS_SETTING}>{page}</SideMenuActivator>;
};

export default OrganizationsSettingsPage;
