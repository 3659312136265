import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import BrandLogo from 'components/BrandLogo/BrandLogo';
import HeaderAppLogo from 'components/HeaderAppLogo';
import { SideMenuKey } from 'components/SideMenuActivator';
import { Fmt } from 'locale';
import * as lodash from 'lodash';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './SideMenu.module.less';

export const getPathsParts = (path: string) => {
  return lodash
    .trim(path, '/')
    .split('/')
    .filter((part) => part && part.length > 0);
};

export const combinePaths = (parts: string[], startWith = '/') => {
  return startWith + parts.join('/');
};

type Props = {
  sideMenuCollapsed: boolean;
  sideMenuActiveItem: SideMenuKey | Guid;
  setSideMenuCollapsed: (collapsed: boolean) => void;
  onOpenKeysChanged?: (opennedKeys: string[]) => void;
  menuItemsConfig: MenuItemType[];
  url: string;
  bottomMenuItemsConfig: MenuItemType[];
  defaultOpenKeys?: SideMenuKey[];
};

export type MenuItemType = {
  key: SideMenuKey;
  label: ReactNode;
  icon: ReactNode;
  children?: SubmenuItemType[];
  type?: string;
  path?: string;
  isSub?: boolean;
  disabled?: boolean;
  parentKey?: SideMenuKey;
  externalAppUrl?: string;
  disabledText?: JSX.Element;
  hidden?: boolean;
};

export type SubmenuItemType = Omit<MenuItemType, 'key'> & {
  key: SideMenuKey | Guid;
};

export const getItemByKey = (key: string, projectMenuTree: MenuItemType[]) => {
  return projectMenuTree.find((i) => i.key === key);
};

export const getSelectedItemMenuPath = (item: MenuItemType, projectMenuTree: MenuItemType[]): string[] => {
  if (!item) return [];
  if (item.parentKey) {
    const parentItem = getItemByKey(item.parentKey, projectMenuTree);
    return [...getSelectedItemMenuPath(parentItem, projectMenuTree), item.key];
  }
  return [item.key];
};

export function findMenuItemConfigByKey(
  key: string,
  menuItemsConfig: MenuItemType[] | SubmenuItemType[]
): MenuItemType | SubmenuItemType {
  for (const item of menuItemsConfig) {
    if (item.key === key) return item;
    if (item.children) {
      const child = findMenuItemConfigByKey(key, item.children);
      if (child) return child;
    }
  }
  return null;
}

const SideMenu: FunctionComponent<Props> = ({
  sideMenuCollapsed,
  sideMenuActiveItem,
  setSideMenuCollapsed,
  onOpenKeysChanged,
  url,
  menuItemsConfig,
  bottomMenuItemsConfig,
  defaultOpenKeys = [],
}) => {
  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);
  const history = useHistory();
  const [miniMenu, setMiniMenu] = useState(false);

  useEffect(() => {
    if (sideMenuActiveItem) {
      const selectedOpenKeys = getSelectedItemMenuPath(
        getItemByKey(sideMenuActiveItem, menuItemsConfig),
        menuItemsConfig
      );
      setOpenKeys((keys) => Array.from(new Set([...selectedOpenKeys, ...keys])));
    }
  }, [sideMenuActiveItem]);

  useEffect(() => {
    onOpenKeysChanged && onOpenKeysChanged(openKeys);
  }, [openKeys]);

  // fix a visual "bug" with "dangling" opened window on menu collapse
  useEffect(() => {
    if (sideMenuCollapsed) {
      setOpenKeys([]);
    }
  }, [sideMenuCollapsed]);

  const selectedKeys = useMemo(() => [sideMenuActiveItem], [sideMenuActiveItem]);

  const bottomMenuItems: MenuItemType[] = useMemo(() => {
    return [
      ...bottomMenuItemsConfig,
      {
        key: SideMenuKey.TOGGLE,
        icon: miniMenu ? <DoubleRightOutlined /> : <DoubleLeftOutlined />,
        label: miniMenu ? <Fmt id="menu.side.toggleMenu" /> : <Fmt id="menu.side.minimizeMenu" />,
        onClick: () => setMiniMenu(!miniMenu),
      } as MenuItemType,
    ];
  }, [url, miniMenu, bottomMenuItemsConfig]);

  return (
    <Layout.Sider
      width={200}
      className={styles.sider}
      trigger={null}
      breakpoint="lg"
      collapsedWidth={sideMenuCollapsed ? 0 : 80}
      collapsible
      collapsed={sideMenuCollapsed || miniMenu}
      onBreakpoint={(broken) => {
        setSideMenuCollapsed(broken);
      }}
      onCollapse={(collapsed, type) => {
        if (!collapsed && type === 'responsive') {
          setSideMenuCollapsed(false);
        }
        if (type === 'clickTrigger') {
          setSideMenuCollapsed(false);
        }
      }}
    >
      <Link to="/">
        <HeaderAppLogo small={miniMenu} className={styles.hubLogo} />
      </Link>
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        className={styles.menu}
        onOpenChange={setOpenKeys}
        items={menuItemsConfig}
        onClick={({ key }) => {
          const item = findMenuItemConfigByKey(key, menuItemsConfig);
          if (!item) return;

          if (item?.externalAppUrl) {
            window.open(item.externalAppUrl, '_blank');
            return;
          }
          if (item?.path != null) {
            const path = combinePaths([...getPathsParts(url), ...getPathsParts(item.path)]);
            history.push(path);
          }
        }}
      />

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[sideMenuActiveItem]}
        className={styles.menuBottom}
        items={bottomMenuItems}
        onClick={({ key }) => {
          const item = findMenuItemConfigByKey(key, bottomMenuItemsConfig);
          if (!item) return;

          if (item?.externalAppUrl) {
            window.open(item.externalAppUrl, '_blank');
          }
          if (item?.path != null) {
            const path = combinePaths([...getPathsParts(url), ...getPathsParts(item.path)]);
            history.push(path);
          }
        }}
      />

      <span className={styles.menuItemCompany}>
        <BrandLogo small={miniMenu} />
      </span>
    </Layout.Sider>
  );
};

export default React.memo(SideMenu);
