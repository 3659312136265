import { RedoOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { api } from 'api';
import { BlobDerivateDto, BlobDerivateTypeEnum } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import EmptySimple from 'components/EmptySimple';
import { FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import Modal from 'components/Modal/Modal';
import { getIndexInBounds, PreviousNextButtons } from 'components/PreviousNextButtons/PreviousNextButtons';
import { OFFICE_DISABLED } from 'config/env';
import { useActiveProject, useCurrentProjectUser } from 'hooks';
import { KeyModifiers, useOnKeyUp } from 'hooks/useKeyPress';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import FileViewer from './FileViewer';
import styles from './FileViewerModal.module.less';
import { AudioViewerData } from './viewers/AudioViewer';
import { ForgeViewerData } from './viewers/ForgeViewer';
import { ImageViewerData } from './viewers/ImageViewer';
import { OfficeOnlineViewerData } from './viewers/OfficeOnlineViewer';
import { PdfViewerData } from './viewers/PdfViewer';
import { ProcessedJsonViewerData } from './viewers/ProcessedJsonViewer';
import { VideoViewerData } from './viewers/VideoViewer';
import { XmlViewerData } from './viewers/XmlViewer';

const viewerComponentsList: FileViewerProperties[] = [
  ImageViewerData,
  PdfViewerData,
  AudioViewerData,
  VideoViewerData,
  ForgeViewerData,
  XmlViewerData,
  ProcessedJsonViewerData,
];
if (!OFFICE_DISABLED) viewerComponentsList.push(OfficeOnlineViewerData);

export function getComponentsByContentType(contentType: string): FileViewerProperties[] {
  if (!contentType) return [];
  return viewerComponentsList.filter((c) => c.supportedTypes.indexOf(contentType.toLowerCase()) !== -1);
}

export type ViewerDescriptor = {
  key: string;
  contentType: string;
  component: FileViewerProperties;
  derivateType: BlobDerivateTypeEnum;
  title: React.ReactNode;
  getUrl: () => Promise<string>;
  refresh: () => void;
  disabled?: boolean;
  blobToken: string;
  derivative: BlobDerivateDto;
  isPreview?: boolean;
};

const ARROW_KEYS_MODIFIERS: KeyModifiers[] = ['shiftKey'];

export type FileViewerModalProps = Omit<ModalProps, 'footer' | 'centered'> & {
  viewers: ViewerDescriptor[];
  hasPrev?: boolean;
  hasNext?: boolean;
  onSetIndex?: (newIndex: number) => void;
  loading?: boolean;
  itemsCount: number;
  itemIndex: number;
  blobId: Guid;
  isMetadata: boolean;
};

type FileViewerModalComponent = React.ComponentType<FileViewerModalProps & InjectedIntlProps> & {};

const FileViewerModal: FileViewerModalComponent = (props) => {
  const {
    intl,
    viewers,
    hasPrev,
    hasNext,
    onSetIndex,
    loading = false,
    itemsCount,
    itemIndex,
    blobId,
    isMetadata,
    ...modalProps
  } = props;
  const [activeKey, setActiveKey] = useState(undefined);

  const currentUser = useCurrentProjectUser();
  const activeProject = useActiveProject();

  useEffect(() => {
    viewers?.length && setActiveKey(viewers[0].key);
  }, [viewers]);

  useOnKeyUp('ArrowLeft', () => onSetIndex(getIndexInBounds(itemIndex - 1, itemsCount)), ARROW_KEYS_MODIFIERS);
  useOnKeyUp('ArrowRight', () => onSetIndex(getIndexInBounds(itemIndex + 1, itemsCount)), ARROW_KEYS_MODIFIERS);

  // TODO: cache viewers on tabs and reuse forgeViewer

  const handleClickGenerate = async () => {
    const [err] = await api.master.projects.repaireDerivates(blobId, activeProject.id);
    if (err) {
      message.info(intl.formatMessage({ id: 'FileViewer.rerenderingDerivatesMessage' }));
    } else {
    }
  };

  const handleClickGenerateOneDerivate = async (derivateType: string) => {
    const [err] = await api.master.projects.repaireDerivate(derivateType, blobId, activeProject.id);
    if (err) {
      message.info(intl.formatMessage({ id: 'FileViewer.rerenderingDerivatesMessage' }));
    } else {
    }
  };
  return (
    <Modal
      visible={modalProps.visible}
      onCancel={modalProps.onCancel}
      title={modalProps.title}
      tabs={
        <Space.Compact>
          {viewers?.map((viewer) => (
            <Button
              key={viewer.key}
              type={viewer.key === activeKey ? 'primary' : 'default'}
              onClick={() => {
                if (viewer.key !== activeKey) {
                  setActiveKey(viewer.key);
                }
              }}
            >
              {viewer.component.icon}
              {viewer.title}
            </Button>
          ))}
        </Space.Compact>
      }
      additionalButtons={
        <>
          {isMetadata && currentUser.isAdmin && (
            <CommonHubTooltip title={<Fmt id="FileViewer.rerenderingDerivatesTooltip" />}>
              <Button type="link" onClick={handleClickGenerate}>
                <RedoOutlined />
              </Button>
            </CommonHubTooltip>
          )}

          <PreviousNextButtons total={itemsCount} currentIndex={itemIndex} onSetIndex={onSetIndex} />
        </>
      }
    >
      <div className={styles.modalBody}>
        {viewers != null && viewers.length !== 0 ? (
          viewers.map(
            (viewer) =>
              viewer.key === activeKey && (
                <FileViewer
                  key={viewer.key}
                  viewer={viewer.component.component}
                  getUrl={viewer.getUrl}
                  refresh={viewer.refresh}
                  contentType={viewer.contentType}
                  blobToken={viewer.blobToken}
                  derivate={viewer.derivative}
                  viewerVisible={modalProps.visible}
                  loading={loading}
                  onClickGenerateOne={() => handleClickGenerateOneDerivate(viewer.derivateType)}
                />
              )
          )
        ) : (
          <>
            <EmptySimple description={<Fmt id="FileViewer.NoPreviewAvailable" />} />
            {!isMetadata && (
              <Button type="dashed" onClick={handleClickGenerate} className={styles.geneButton}>
                <Fmt id="FileViewer.rerenderingDerivatesButton" />
              </Button>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(FileViewerModal);
