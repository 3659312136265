import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CurrentRevisionDto, ProjectUserProfileListDto, RevisionDto, RoleDto } from 'api/completeApiInterfaces';
import {
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
  createMultiSelectFilterFunction,
  createSingleSelectFilterFunction,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { DEBUG } from 'config/env';
import { useIntl } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'store';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { checkObject, checkString } from 'utils';
import styles from './UsersFilter.module.less';

export type UsersFilterValue = SelectFilterValue<Guid> & {
  type: string;
};

export type UsersFilterType = {
  key: string;
  label: ReactNode;
  multiple: boolean;
  allowNotSet?: boolean;
  notSetLabel?: ReactNode;
};

export type UsersSingleFilterType<T> = UsersFilterType & {
  multiple: false;
  valueSelector: (item: T) => Guid;
};

export type UsersMultiFilterType<T> = UsersFilterType & {
  multiple: true;
  valuesSelector: (item: T) => Guid[];
};

export type UsersFrontendFilterType<T> = UsersSingleFilterType<T> | UsersMultiFilterType<T>;

export type UsersBackendFilterType<T> = UsersFilterType & {
  serialize: (accumulator: T, value: UsersFilterValue) => T;
};

type Props = {
  label?: ReactNode;
  title?: ReactNode;
  value: UsersFilterValue;
  onChange: React.Dispatch<React.SetStateAction<UsersFilterValue>>;
  filterTypes: UsersFilterType[];
  showSearchMinItems?: number;
  showWithNoUsers?: boolean;
  defaultUsersList?: ProjectUserProfileListDto[];
};

const UsersFilterComponent: FunctionComponent<Props> = ({
  label,
  title,
  value,
  onChange,
  filterTypes,
  showSearchMinItems = 3,
  showWithNoUsers,
  defaultUsersList,
}) => {
  const intl = useIntl();
  const usersListStore = useSelector(projectUsersListSelector);

  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    if (!defaultUsersList) {
      dispatch.projectUsers.loadData({ reload: false });
    }
  }, [!!defaultUsersList]);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const usersList = useMemo<ProjectUserProfileListDto[]>(() => defaultUsersList || usersListStore || [], [
    defaultUsersList,
    usersListStore,
  ]);

  const selectedFilterType = useMemo(() => filterTypes.find((type) => type.key === value.type) || filterTypes[0], [
    filterTypes,
    value,
  ]);

  // make sure that some filter type is selected (in case that filter types change)
  useEffect(() => {
    if (filterTypes.length && !selectedFilterType) {
      onChange((value) => ({ ...value, type: filterTypes[0]?.key }));
    }
  }, [filterTypes, selectedFilterType]);

  const usersOptions = useMemo<IOption<Guid>[]>(
    () =>
      usersList?.map((user) => ({
        id: user.id,
        title: user.username,
      })) || [],
    [usersList]
  );

  const handleTypeChange = useCallback(
    (event: RadioChangeEvent) => {
      const type = event.target.value;
      onChange((value) => ({ ...value, type }));
    },
    [onChange]
  );

  const additionalOptions = useMemo(
    () =>
      filterTypes.length > 1 && (
        <Radio.Group onChange={handleTypeChange} value={value.type} className={styles.typeSelect}>
          {filterTypes.map((type) => (
            <Radio value={type.key} key={type.key}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      ),
    [filterTypes, value, handleTypeChange]
  );

  const usedLabel = label || (filterTypes.length === 1 ? filterTypes[0].label : <Fmt id="general.users" />);

  return (
    ((usersList.length > 0 && filterTypes.length > 0) || showWithNoUsers) && (
      <SelectFilter
        label={usedLabel}
        title={title}
        value={value}
        onChange={onChange}
        options={usersOptions}
        enableAndOrOperator={selectedFilterType?.multiple}
        allowNotSet={selectedFilterType?.allowNotSet}
        notSetLabel={selectedFilterType?.notSetLabel}
        showSearchMinItems={showSearchMinItems}
        additionalOptions={additionalOptions}
        searchItemsPlaceholder={intl.formatMessage({ id: 'filters.searchPlaceholder.users' })}
      />
    )
  );
};

export const UsersFilter = React.memo(UsersFilterComponent);

export const USERS_FILTER_TYPE_CREATED_BY: UsersSingleFilterType<{ createdBy: ProjectUserProfileListDto }> = {
  key: 'createdBy',
  label: <Fmt id="UsersFilter.documentUser" />,
  multiple: false,
  valueSelector: (document) => document.createdBy?.id,
};

export const USERS_FILTER_TYPE_REVISION: UsersSingleFilterType<{ revision?: RevisionDto | CurrentRevisionDto }> = {
  key: 'revision',
  label: <Fmt id="UsersFilter.revisionUser" />,
  multiple: false,
  valueSelector: (document) => document.revision?.createdBy?.id,
};

export const USERS_FILTER_TYPE_OWNER: UsersSingleFilterType<{ ownedBy?: RoleDto }> = {
  key: 'owner',
  label: <Fmt id="UsersFilter.ownerUser" />,
  multiple: false,
  valueSelector: (document) => document.ownedBy?.user?.id,
};

export const USERS_FILTER_TYPE_MODIFIED_BY: UsersSingleFilterType<{ modifiedBy?: ProjectUserProfileListDto }> = {
  key: 'modifiedBy',
  label: <Fmt id="UsersFilter.modifiedUser" />,
  multiple: false,
  valueSelector: (item) => item.modifiedBy?.id,
};

type AdditionalProps = Omit<Props, 'value' | 'onChange' | 'filterTypes'>;

const createCommonUsersFilter = (
  key: string,
  filterTypes: UsersFilterType[],
  props?: AdditionalProps
): CommonFilter<UsersFilterValue> => ({
  key,
  render: (value, setValue) => <UsersFilter {...props} value={value} onChange={setValue} filterTypes={filterTypes} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: { ...SELECT_DEFAULT_VALUE([]), type: filterTypes[0]?.key },
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject({ ...SELECT_CHECK_FORMAT, type: checkString }),
});

export const createFrontendUsersFilter = <T,>(
  key: string,
  filterTypes: UsersFrontendFilterType<T>[],
  props?: AdditionalProps
): FrontendFilter<T, UsersFilterValue> => ({
  ...createCommonUsersFilter(key, filterTypes, props),
  filter: (value) => {
    const filterType = filterTypes.find((type) => type.key === value.type);
    if (filterType?.multiple) {
      return createMultiSelectFilterFunction(filterType.valuesSelector)(value);
    } else if (filterType && filterType.multiple == false) {
      // Intentionally only 2 equal signs
      return createSingleSelectFilterFunction(filterType.valueSelector)(value);
    }
    DEBUG && console.error('Invalid key for filterType:', key, value.type, filterTypes);
    return (_item: T) => true;
  },
});

export const createBackendUsersFilter = <T,>(
  key: string,
  filterTypes: UsersBackendFilterType<T>[],
  props?: AdditionalProps
): BackendFilter<T, UsersFilterValue> => ({
  ...createCommonUsersFilter(key, filterTypes, props),
  serialize: (accumulator, value) => {
    const filterType = filterTypes.find((type) => type.key === value.type);
    if (filterType) {
      return filterType.serialize(accumulator, value);
    }
    DEBUG && console.error('Invalid key for filterType: ', key, value.type, filterTypes);
    return accumulator;
  },
});
