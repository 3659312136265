import { api } from 'api';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectTimezoneDto } from 'api/completeApiInterfaces';
import DisplayName from 'components/DisplayName';
import GeneralSelectSettingsItem from 'components/GeneralSelectSettingsItem/GeneralSelectSettingsItem';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralTextAreaSettingsItem from 'components/GeneralTextAreaSettingsItem/GeneralTextAreaSettingsItem';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import Label from 'components/Label/Label';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { CalendarSettingsFormButton } from 'components/forms/CalendarSettingsForm/CalendarSettingsFormEditButton';
import { ERPDataImportButton } from 'components/forms/ERPDataImportButton/ERPDataImportButton';
import { UserActivityReportExportButton } from 'components/forms/UserActivityReportExportForm/UserActivityReportExportButton';
import { MAX_PROJECT_DESCRIPTION_LENGTH, MAX_PROJECT_NAME_LENGTH } from 'config/constants';
import { useActiveProject, useApiData, useCurrentProjectUser, useDispatchEffect } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';
import { organizationLabelsListSelector } from 'store/selectors/organizationLabelsSelectors';
import { processApiError } from 'utils';
import AdministrationMetadataExportButton from '../Administration/AdministrationMetadataExportButton';
import Panel from '../Panel';
import { CommentProcedureRolesSettings } from './CommentProcedureRolesSettings';

function checkLength(name: string, maxLength: number): boolean {
  if (name == null) return true;
  return name.length <= maxLength;
}

type Props = InjectedIntlProps;

const ProjectSettingsPageGeneral: FunctionComponent<Props> = (props) => {
  const project = useActiveProject();
  const dispatch = useDispatch<Dispatch>();
  const setActiveProject = dispatch.activeProject.setActiveProject;
  const currentUser = useCurrentProjectUser();

  const { intl } = props;
  const lang: LanguageEnum = useMemo(() => intl.locale, [intl]);

  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => api.master.projects.getTimeZones(lang, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [lang]);

  useDispatchEffect(
    (dispatch) =>
      project?.organization.id &&
      dispatch.organizationLabels.loadData({ reload: true, data: [project?.organization.id] }),
    [project?.organization.id]
  );
  useDirtyStoreReloadCallback(
    (store) => store.organizationLabels,
    (dispatch) => dispatch.organizationLabels.loadData({ reload: true, data: [project?.organization.id] })
  );
  const orgLabelsList = useSelector(organizationLabelsListSelector);

  const getTimeZoneObject = useCallback(
    (timeZoneId: string): ProjectTimezoneDto => {
      return timeZones?.timeZones.find((tz) => tz.id === timeZoneId);
    },
    [timeZones]
  );

  const selectOptions = useMemo(() => {
    return timeZones?.timeZones.map((tz) => ({ value: tz.id, text: tz.name })) || [];
  }, [timeZones]);

  const labelsSelectOptions = useMemo(() => {
    return (
      orgLabelsList?.map((label) => ({
        value: label.id,
        text: (
          <Label color={label.color}>
            <DisplayName>{label.name}</DisplayName>
          </Label>
        ),
      })) || []
    );
  }, [orgLabelsList]);

  const onEditName = useCallback(
    async (name: string): Promise<boolean | ReactNode> => {
      if (name != null && checkLength(name, MAX_PROJECT_NAME_LENGTH) && name.length > 0) {
        if (name !== project.name) {
          const id = project.id;
          const [err] = await api.master.projects.patchProject(id, {
            name,
          });
          if (!err) {
            setActiveProject({ ...project, name });
            return true;
          } else {
            return props.intl.formatMessage({
              id: `serviceError.${processApiError(err).referenceErrorCode}`,
            });
          }
        }
        return true;
      }
      return !name?.length
        ? props.intl.formatMessage({ id: 'Project.name.empty' })
        : props.intl.formatMessage({ id: 'general.maxNameLength' }, { max: MAX_PROJECT_NAME_LENGTH });
    },
    [setActiveProject, project, intl]
  );

  const onEditDescription = useCallback(
    async (description: string): Promise<boolean | ReactNode> => {
      if (!description && !project.description) return true;
      if (checkLength(description, MAX_PROJECT_DESCRIPTION_LENGTH)) {
        if (description !== project.description) {
          const id = project.id;
          const [err] = await api.master.projects.patchProject(id, {
            description,
          });
          if (!err) {
            setActiveProject({ ...project, description });
            return true;
          } else {
            return props.intl.formatMessage({
              id: `serviceError.${processApiError(err).referenceErrorCode}`,
            });
          }
        }
        return true;
      }
      return props.intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_PROJECT_DESCRIPTION_LENGTH });
    },
    [setActiveProject, project, intl]
  );

  const onEditTimezone = useCallback(
    async (timeZoneId: string): Promise<boolean | ReactNode> => {
      if (timeZoneId !== project.timeZone.id) {
        const id = project.id;
        const [err] = await api.master.projects.patchProject(id, {
          timeZoneId,
        });
        if (!err) {
          const newTimeZone = getTimeZoneObject(timeZoneId);
          setActiveProject({ ...project, timeZone: newTimeZone });
          return true;
        } else {
          return props.intl.formatMessage({
            id: `serviceError.${processApiError(err).referenceErrorCode}`,
          });
        }
      }
      return true;
    },
    [setActiveProject, project, getTimeZoneObject, intl]
  );

  const onEditLabels = useCallback(async (labels: Guid[]): Promise<boolean | ReactNode> => {
    const id = project.id;
    const [err] = await api.master.projects.patchProject(id, {
      orgLabels: labels,
    });
    if (!err) {
      setActiveProject({ ...project, labels: labels });
      return true;
    } else {
      return props.intl.formatMessage({
        id: `serviceError.${processApiError(err).referenceErrorCode}`,
      });
    }
  }, []);

  const { url } = useRouteMatch();

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'general.general' })}
      children={() => (
        <StackPanel vertical scrollable>
          <Panel hideToolbar panelWidth="auto" noMargin>
            <GeneralSettingsContainer itemsLargeGap>
              <GeneralSettingsContainer>
                <GeneralTextSettingsItem
                  value={project.name}
                  onSave={onEditName}
                  disableEdit={!currentUser.isAdmin}
                  headline={<Fmt id="ProjectSettingsPageGeneral.name" />}
                  maxLength={apiConstraints.projectPatchDto.name.maxLength}
                />
                <GeneralSelectSettingsItem
                  value={project.timeZone?.id}
                  onSave={onEditTimezone}
                  headline={<Fmt id="ProjectCreateForm.form.timeZone" />}
                  options={selectOptions}
                  disableEdit={!currentUser.isAdmin}
                  showSearch
                />
                <GeneralTextAreaSettingsItem
                  value={project.description}
                  disableEdit={!currentUser.isAdmin}
                  onSave={onEditDescription}
                  headline={<Fmt id="ProjectSettingsPageGeneral.description" />}
                  maxLength={apiConstraints.projectPatchDto.description.maxLength}
                />
                {!!project.storageArea && (
                  <GeneralTextAreaSettingsItem
                    value={project.storageArea}
                    disableEdit
                    headline={<Fmt id="general.storagearea" />}
                  />
                )}
                <GeneralSettingsItem
                  title={intl.formatMessage({ id: 'general.workDaysCalendar' })}
                  disabled={!currentUser.isAdmin}
                  input={
                    <CalendarSettingsFormButton
                      organizationId={project.organization.id}
                      projectId={project.id}
                      editDisabled={!currentUser.isAdmin}
                      buttonType="link"
                    />
                  }
                />
                <GeneralSelectSettingsItem
                  value={project.labels}
                  onSave={onEditLabels}
                  headline={<Fmt id="general.organizationLabels" />}
                  options={labelsSelectOptions}
                  disableEdit={!currentUser.isAdmin}
                  mode="multiple"
                  showSearch
                  showArrow
                />
              </GeneralSettingsContainer>
              <CommentProcedureRolesSettings />
              {currentUser.isAdmin && (
                <>
                  <GeneralSettingsContainer title={<Fmt id="ProjectSettingsPageGeneral.exports" />}>
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPageGeneral.exportUsersAndProjectSetting" />}
                      input={<UserActivityReportExportButton currentUser={currentUser} />}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="Administration.downloadDMSMetaData.buttonTitle" />}
                      input={<AdministrationMetadataExportButton currentUser={currentUser} />}
                    />
                  </GeneralSettingsContainer>
                  <GeneralSettingsContainer title={<Fmt id="ProjectSettingsPageGeneral.imports" />}>
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPageGeneral.importConnectedERPData" />}
                      input={<ERPDataImportButton projectId={project.id} intl={intl} />}
                    />
                  </GeneralSettingsContainer>
                </>
              )}
            </GeneralSettingsContainer>
          </Panel>
        </StackPanel>
      )}
    />
  );
};

export default injectIntl(ProjectSettingsPageGeneral);
