import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Button, Modal, Progress, Typography } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import Axios from 'axios';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { modalFormConfirmClose, processApiError } from 'utils';
import styles from './FormModalWrapper.module.less';

// TODO: refactor this function, move it to utils
export const parseErrorMessage = (error: ApiError | null): ReactNode => {
  if (!error) return null;
  const processed = processApiError(error);
  return <Fmt id={`serviceError.${processed.referenceErrorCode}`} />;
};

export const getFormValue = <T, F extends string & keyof T>(form: WrappedFormUtils<T>, field: F): T[F] =>
  form.getFieldValue(field);

export type FormSubmitHandler<T = any> = (values: T) => Promise<ApiError>;

type ChildrenProps = InjectedIntlProps & {
  formRef: RefObject<Form>;
  loading: boolean;
  setDirty?: (dirty: boolean) => void;
};

type Props = InjectedIntlProps &
  ModalProps & {
    onSubmit: FormSubmitHandler;
    onClose?: () => void;
    children(props: ChildrenProps): JSX.Element;
    title?: ReactNode | string;
    titleId?: IntlMessageId;
    submitTextId?: IntlMessageId;
    submitTextLoadingId?: IntlMessageId;
    cancelTextId?: IntlMessageId;
    confirmClose?: boolean;
    progress?: number;
    showProgress?: boolean;
    forceLoading?: boolean;
    errorMessage?: ReactNode;
    onSubmitDisabled?: boolean;
  };

const FormModalWrapperComponent: FunctionComponent<Props> = (props) => {
  const {
    intl,
    title,
    titleId,
    submitTextId,
    submitTextLoadingId,
    cancelTextId,
    progress,
    showProgress,
    errorMessage,
    forceLoading,
    onSubmit,
    onClose,
    confirmClose,
    children,
    onSubmitDisabled,
    ...modalProps
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ServiceError>(null);
  const [isDirty, setDirty] = useState<boolean>(false);

  const formRef = useRef<Form>(null);

  // cancel onSubmitPromise on unmount
  const unmountedRef = useRef(false);
  useEffect(() => () => (unmountedRef.current = true), []);

  function handleSubmit() {
    if (!formRef.current) return;
    const form = formRef.current.props.form;

    form.validateFields((err: any, values: any) => {
      if (err) return;

      setLoading(true);

      onSubmit(values)
        .then((apiError) => {
          if (unmountedRef.current) return;
          if (apiError) {
            if (!Axios.isCancel(apiError)) {
              processApiError(apiError, handleError);
            }
          } else {
            setError(null);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    });
  }

  function handleError(error: ServiceError) {
    setError(error);
    setLoading(false);
  }

  const inConfirmationRef = useRef<boolean>(false);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    if (inConfirmationRef.current) return;
    if (props.confirmClose !== undefined && !props.confirmClose) {
      handleClose();
      return;
    }

    if (!formRef.current) return;
    const form = formRef.current.props.form;

    if (form.isFieldsTouched() || isDirty) {
      inConfirmationRef.current = true;
      modalFormConfirmClose(
        intl,
        () => {
          inConfirmationRef.current = false;
          handleClose();
        },
        () => {
          inConfirmationRef.current = false;
        }
      );
    } else {
      handleClose();
    }
  }, [handleClose, props.confirmClose, isDirty]);

  const _title = (
    <div className={styles.elipsis}>{title ? title : titleId ? intl.formatMessage({ id: titleId }) : ''}</div>
  );
  const titleWithTooltip = (
    <CommonHubTooltip placement="topLeft" title={_title}>
      {_title}
    </CommonHubTooltip>
  );

  const footer = (
    <div style={{ display: 'flex' }}>
      {!errorMessage && ((loading && progress) || showProgress) ? (
        <Progress
          style={{ flex: 1, marginRight: 8, marginTop: 3 }}
          percent={progress}
          status={progress === 100 ? 'success' : loading ? 'active' : 'normal'}
        />
      ) : !!errorMessage ? (
        <Typography.Text type="danger" style={{ flex: 1, marginRight: 8, marginTop: 3, textAlign: 'left' }}>
          {errorMessage}
        </Typography.Text>
      ) : (
        <div style={{ flex: 1 }} />
      )}

      <Button onClick={handleCancel}>{intl.formatMessage({ id: cancelTextId })}</Button>
      <Button type="primary" loading={loading || forceLoading} onClick={handleSubmit} disabled={onSubmitDisabled}>
        {intl.formatMessage({
          id: (loading || forceLoading) && submitTextLoadingId ? submitTextLoadingId : submitTextId,
        })}
      </Button>
    </div>
  );

  useCloseOnEscape(modalProps.open || modalProps.visible, handleCancel);

  return (
    <Modal
      title={<DisplayName className={styles.main}>{titleWithTooltip}</DisplayName>}
      footer={footer}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      keyboard={false}
      {...modalProps}
    >
      {!!error && <ServiceErrorBox error={error} />}
      {props.children({ intl, formRef, loading, setDirty })}
    </Modal>
  );
};

FormModalWrapperComponent.defaultProps = {
  submitTextId: 'forms.button.submit',
  submitTextLoadingId: 'forms.button.submit.loading',
  cancelTextId: 'forms.button.cancel',
  confirmClose: true,
  onClose: () => {},
};

export const FormModalWrapper = injectIntl(FormModalWrapperComponent);
