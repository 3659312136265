import CommonHubTooltip, { TOOLTIP_DELAY } from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent } from 'react';
import styles from './DocumentRow.Description.module.less';

export type DocumentRowProps = {
  description: string;
  delay?: number;
};

const DocumentRowDescription: FunctionComponent<DocumentRowProps> = ({ description, delay = TOOLTIP_DELAY }) => {
  return description ? (
    <CommonHubTooltip
      overlayClassName={styles.formatedTooltip}
      placement="bottom"
      title={<DisplayName isWrap text={description} />}
      mouseEnterDelay={delay}
    >
      <DisplayName className={styles.description} text={description} />
    </CommonHubTooltip>
  ) : null;
};

export default React.memo(DocumentRowDescription);
