import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';

export const projectsListSelector = (state: RootState) => state.allProjects.data?.projects;

export const projectsMapSelector = createSelector([projectsListSelector], (projects) => {
  if (projects === null) return null;
  return keyBy(projects, (project) => project.id);
});
