import { SendOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { TOOLTIP_MOUSE_ENTER_DELAY } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import styles from './DiscussionSendButton.module.less';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  title?: string;
};

const DiscussionSendButtonComponent: FunctionComponent<Props> = ({ disabled, onClick, loading, title }) => {
  const intl = useIntl();

  const sendButton = (
    <Button
      type="primary"
      className={styles.button}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      title={title}
      icon={<SendOutlined />}
    />
  );

  if (disabled) {
    return sendButton;
  }

  return (
    <CommonHubTooltip
      placement="topRight"
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
      title={
        <FlowLayout>
          <div>{intl.formatMessage({ id: 'DiscussionInput.send' })}</div>
          <Tag>Ctrl</Tag>
          <div>+</div>
          <Tag>Enter</Tag>
        </FlowLayout>
      }
    >
      {sendButton}
    </CommonHubTooltip>
  );
};

export const DiscussionSendButton = React.memo(DiscussionSendButtonComponent);
