import { DocumentSubscriberEnumsWatchDocumentEnum } from 'api/completeApiInterfaces';
import { GroupedToggles, ToggleNode, ToggleNodeEnum, ToggleNodeSwitch } from 'components/GroupedToggles/GroupedToggles';
import { DownloadIcon, EditIcon, ShareIcon } from 'components/Icons/HubActionsIcons';
import { WorkflowIcon } from 'components/Icons/HubEntitiesIcons';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { useIntl } from 'hooks';
import { InjectedIntl } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ignoreRef, smartFilter } from 'utils';
import { ArrayFlagsOperation, setArrayFlags } from 'utils/arrayHelpers';

export type WatchEnumType = DocumentSubscriberEnumsWatchDocumentEnum;

type Props = {
  value?: DocumentSubscriberEnumsWatchDocumentEnum[];
  onChange?: (values: DocumentSubscriberEnumsWatchDocumentEnum[]) => void;
  disabled?: boolean;
  searchValue?: string;
  onClearSearch?: () => void;
};

const WatchEnum = DocumentSubscriberEnumsWatchDocumentEnum;

const createToggleSwitch = (value: WatchEnumType): ToggleNodeSwitch<WatchEnumType> => ({
  type: ToggleNodeEnum.ToggleSwitch,
  value,
  label: `WatchSwitch.buttonTitle.${value}`,
});

export const WATCH_TREE: ToggleNode<WatchEnumType>[] = [
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DocumentDetailPageWatch.category.documentShare',
    children: [WatchEnum.documentShare, WatchEnum.linkShare, WatchEnum.linkShareDownload].map(createToggleSwitch),
    icon: <ShareIcon />,
  },
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DocumentDetailPageWatch.category.documentChange',
    children: [
      WatchEnum.revisionChange,
      WatchEnum.nameChange,
      WatchEnum.descriptionChange,
      WatchEnum.stateChange,
      WatchEnum.labelChange,
      WatchEnum.categoryChange,
      WatchEnum.annotationChange,
      WatchEnum.attachmentChange,
      WatchEnum.ownerChange,
      WatchEnum.documentDiscard,
      WatchEnum.documentMove,
      WatchEnum.documentSign,
    ].map(createToggleSwitch),
    icon: <EditIcon />,
  },
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'DocumentDetailPageWatch.category.processInclusion',
    children: [WatchEnum.cpChange, WatchEnum.wfChange, WatchEnum.assignmentChange].map(createToggleSwitch),
    icon: <WorkflowIcon />,
  },
  {
    type: ToggleNodeEnum.ToggleGroup,
    label: 'WatchSwitch.buttonTitle.download',
    children: [WatchEnum.download].map(createToggleSwitch),
    icon: <DownloadIcon />,
  },
];

const filterTree = (
  tree: ToggleNode<WatchEnumType>[],
  searchValue: string,
  intl: InjectedIntl
): ToggleNode<WatchEnumType>[] => {
  if (!searchValue) {
    return tree;
  }

  const resultTree: ToggleNode<WatchEnumType>[] = [];
  tree.forEach((node) => {
    if (node.type === ToggleNodeEnum.ToggleSwitch) {
      if (smartFilter(intl.formatMessage({ id: node.label }), searchValue)) resultTree.push(node);
    } else {
      const filteredChildren = filterTree(node.children, searchValue, intl);
      if (filteredChildren.length > 0) {
        resultTree.push({
          ...node,
          children: filteredChildren,
        });
      } else if (smartFilter(intl.formatMessage({ id: node.label }), searchValue)) {
        resultTree.push(node);
      }
    }
  });

  return resultTree;
};

const DocumentWatchSettingsComponent: FunctionComponent<Props> = ({
  value,
  searchValue,
  onClearSearch,
  disabled,
  onChange,
}) => {
  const intl = useIntl();
  const [selectedWatches, setSelectedWatches] = useState(value);

  const updateSelectedWatches = useCallback(
    (values: WatchEnumType[], operation: ArrayFlagsOperation) =>
      setSelectedWatches((selected) => setArrayFlags(selected, values, operation)),
    []
  );

  useEffect(() => {
    onChange && onChange(selectedWatches);
  }, [selectedWatches]);

  const selectedWatchesSet = useMemo(() => new Set(selectedWatches), [selectedWatches]);

  const filteredTree = useMemo(() => {
    return filterTree(WATCH_TREE, searchValue, intl);
  }, [searchValue, intl]);

  if (filteredTree.length === 0) {
    return <ListEmpty filtered={filteredTree.length} total={WATCH_TREE.length} onClearSearch={onClearSearch} />;
  }

  return (
    <GroupedToggles
      nodes={filteredTree}
      value={selectedWatchesSet}
      disabled={disabled}
      onChange={updateSelectedWatches}
    />
  );
};

export const DocumentWatchSettings = ignoreRef(DocumentWatchSettingsComponent);
