import React, { FunctionComponent } from 'react';
import styles from './Logo.module.less';
import classnames from 'classnames';

type Props = {
  img?: string;
  width?: string;
  link?: string;
  className?: string;
  title?: string;
};

const Logo: FunctionComponent<Props> = ({ children, img, width, link, className, title }) => {
  const imageElement = img && <span className={styles.img} style={{ backgroundImage: `url(${img})`, width: width }} />;
  const childrenElement = children && <span className={styles.text}>{children}</span>;

  if (link) {
    return (
      <a href={link} title={title} className={classnames(styles.logo, className)} target="_blank">
        {imageElement}
        {childrenElement}
      </a>
    );
  }

  return (
    <span title={title} className={classnames(styles.logo, className)}>
      {imageElement}
      {childrenElement}
    </span>
  );
};

export default Logo;
