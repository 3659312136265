import { Action } from '@rematch/core';
import { AppUserProfileDto } from 'api/completeApiInterfaces';
import { SIGNAL_R_REDUX_STORE_CONNECTION } from 'config/signalRConnection';
import { Store } from 'store';
import { UnsafeDispatch } from 'store/models/storeModelinterfaces';

export const appUserProfileMiddleware = (store: Store) => (next: UnsafeDispatch) => async (action: Action) => {
  if (action.type === 'currentAppUser/setData') {
    const state = store.getState();
    const { data } = state.currentAppUser;
    if (data) {
      await SIGNAL_R_REDUX_STORE_CONNECTION.leaveGroup('user-' + data.id);
    }

    if (action.payload !== null) {
      const appUserId = (action.payload as AppUserProfileDto).id;
      await SIGNAL_R_REDUX_STORE_CONNECTION.joinGroup('user-' + appUserId);
    }
  }
  if (action.type === 'currentAppUser/setError' || action.type === 'currentAppUser/setDefaults') {
    const state = store.getState();
    const { data } = state.currentAppUser;
    if (data) {
      await SIGNAL_R_REDUX_STORE_CONNECTION.leaveGroup('user-' + data.id);
    }
  }
  return next(action);
};
