import { to } from 'api/await-to';
import {
  AppSettingsDto,
  EsticonLinkDto,
  FavoriteProjectsDto,
  ProjectCreateDto,
  ProjectDataDto,
  ProjectHubDto,
  ProjectListDto,
  ProjectListForExportRequestDto,
  ProjectOrderDto,
  ProjectPatchDto,
  ProjectTimezoneListDto,
  ProjectUsersLicenceInfoDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { LanguageEnum } from 'locale/messages/interfaces';
import { baseMasterApi } from '../baseMasterApi';

export const projectsApi = {
  deleteFromFavoriteProjects: (ids: Guid[], cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post('/projects/favorite/deletelist', {
        cancelToken,
        projectsIds: ids,
      })
    ),
  createFavoriteProject: (ids: Guid[], cancelToken?: CancelToken) =>
    to(baseMasterApi.post('/projects/favorite', { projectsIds: ids } as FavoriteProjectsDto, { cancelToken })),
  getProjects: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectListDto[]>('/projects', { cancelToken })
    ),
  getFavoriteProjects: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectListDto[]>('/projects/favorite', { cancelToken })
    ),
  createProject: (data: ProjectCreateDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<ProjectHubDto>('/projects', data, { cancelToken })
    ),
  getTimeZones: (lang: LanguageEnum, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectTimezoneListDto>(`/projects/timezones/${lang}`, { cancelToken })
    ),
  getProjectById: (projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectHubDto>(`/projects/${projectId}`, { cancelToken })
    ),
  patchProject: (projectId: Guid, data: ProjectPatchDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.patch<ProjectHubDto>(`/projects/${projectId}`, data, { cancelToken })
    ),
  setProjectOrder: (data: ProjectOrderDto[], cancelToken?: CancelToken) =>
    to(baseMasterApi.post('/projects/projectorder', { projectsOrder: data }, { cancelToken })),
  getProjectData: (projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectDataDto>(`projects/${projectId}/data`, { cancelToken })
    ),
  getAppSettings: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AppSettingsDto>(`/projects/AppSettings`, { cancelToken })
    ),
  createEsticonProjectLink: (projectId: Guid, data: EsticonLinkDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<void>(`/projects/${projectId}/link`, data, { cancelToken })
    ),
  deleteEsticonProjectLink: (projectId: Guid, esticonProjectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.delete<void>(`/projects/${projectId}/link/${esticonProjectId}`, { cancelToken })
    ),
  getUsersLicenceInfo: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<ProjectUsersLicenceInfoDto>(`projects/licence`, { cancelToken })
    ),
  repaireDerivates: (blobId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`projects/${projectId}/repairederivates`, { blobId }, { cancelToken })),
  repaireDerivate: (derivateType: string, blobId: Guid, projectId: Guid, cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`projects/${projectId}/repairederivate`, { derivateType, blobId }, { cancelToken })),
  notifyUserLeave: (projectId: Guid, cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`projects/${projectId}/notifyuserleave`, { cancelToken })),
  exportProjects: (data: ProjectListForExportRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<string>(`/projects/basereport`, data, { cancelToken, responseType: 'blob' })
    ),
};
