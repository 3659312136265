import { HomeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Menu } from 'antd';
import { api } from 'api';
import { ProjectUserProfileDto } from 'api/completeApiInterfaces';
import ComponentDidMountCallback from 'components/ComponentDidMountCallback';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { useApiData } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import Administrators from './Administrators/Administrators';
import styles from './AspeHubSettingsPage.module.less';
import AspeHubUsers from './AspeHubUsers';
import Organizations from './Organizations';

type Props = RouteComponentProps<ProjectRouteParams> & {
  currentProjectUser: ProjectUserProfileDto;
};

const AspeHubSettingsPage: FunctionComponent<Props> = ({ match }) => {
  const [menuSelectedKey, setMenuSelectedKey] = useState<SideMenuKey>(SideMenuKey.HUB_SETTING_MENU_ADMINISTRATORS);

  const [
    organizationsAdminReport,
    organizationsAdminReportError,
    organizationsAdminReportLoading,
    loadOrganizationsAdminReport,
  ] = useApiData((ct) => api.master.organization.getOrganizationsAdminReport(ct), {
    autoload: true,
  });

  const page = (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <Menu mode="inline" selectedKeys={[menuSelectedKey]}>
          <Menu.Item key={SideMenuKey.HUB_SETTING_MENU_ADMINISTRATORS} icon={<UserOutlined />}>
            <Link to={`${match.url}`}>
              <Fmt id="general.administrator" />
            </Link>
          </Menu.Item>
          <Menu.Item key={SideMenuKey.HUB_SETTING_MENU_ORGANIZATIONS} icon={<HomeOutlined />}>
            <Link to={`${match.url}/organizations`}>
              <Fmt id="general.organizations" />
            </Link>
          </Menu.Item>
          <Menu.Item key={SideMenuKey.HUB_SETTING_MENU_HUB_USERS} icon={<TeamOutlined />}>
            <Link to={`${match.url}/hubUsers`}>
              <Fmt id="general.users" />
            </Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className={styles.mainContent}>
        <Switch>
          <Route
            path={`${match.path}/organizations`}
            render={(p) => (
              <ComponentDidMountCallback
                key={SideMenuKey.HUB_SETTING_MENU_ORGANIZATIONS}
                callback={() => setMenuSelectedKey(SideMenuKey.HUB_SETTING_MENU_ORGANIZATIONS)}
              >
                <Organizations
                  organizations={organizationsAdminReport?.organizations}
                  appUsers={organizationsAdminReport?.appUsers}
                  loadOrganizationsAdminReport={loadOrganizationsAdminReport}
                  organizationsAdminReportLoading={organizationsAdminReportLoading}
                  organizationsAdminReportError={organizationsAdminReportError}
                  userCanAddOrganization
                  {...p}
                />
              </ComponentDidMountCallback>
            )}
          />

          <Route
            path={`${match.path}`}
            exact
            render={(p) => (
              <ComponentDidMountCallback
                key={SideMenuKey.HUB_SETTING_MENU_ADMINISTRATORS}
                callback={() => setMenuSelectedKey(SideMenuKey.HUB_SETTING_MENU_ADMINISTRATORS)}
              >
                <Administrators
                  appUsers={organizationsAdminReport?.appUsers}
                  loadOrganizationsAdminReport={loadOrganizationsAdminReport}
                  organizationsAdminReportLoading={organizationsAdminReportLoading}
                  organizationsAdminReportError={organizationsAdminReportError}
                  {...p}
                />
              </ComponentDidMountCallback>
            )}
          />
          <Route
            path={`${match.path}/hubUsers`}
            exact
            render={(p) => (
              <ComponentDidMountCallback
                key={SideMenuKey.HUB_SETTING_MENU_HUB_USERS}
                callback={() => setMenuSelectedKey(SideMenuKey.HUB_SETTING_MENU_HUB_USERS)}
              >
                <AspeHubUsers
                  organizationsAdminReport={organizationsAdminReport}
                  loadOrganizationsAdminReport={loadOrganizationsAdminReport}
                  organizationsAdminReportLoading={organizationsAdminReportLoading}
                  organizationsAdminReportError={organizationsAdminReportError}
                  {...p}
                />
              </ComponentDidMountCallback>
            )}
          />

          <Route
            render={() => (
              <ComponentDidMountCallback key="null" callback={() => setMenuSelectedKey(null)}>
                {/*<div>404 Not Found</div>*/}
              </ComponentDidMountCallback>
            )}
          />
        </Switch>
      </div>
    </div>
  );

  if (!organizationsAdminReport?.isAppAdmin && !organizationsAdminReportLoading)
    return (
      <Alert
        style={{ margin: 24, width: '100%' }}
        message={<Fmt id="general.error" />}
        description={<Fmt id="serviceError.ApplicationAdminAccessRequiredError" />}
        type="error"
        showIcon
      />
    );

  return <SideMenuActivator menuItemKey={menuSelectedKey}>{page}</SideMenuActivator>;
};

export default AspeHubSettingsPage;
