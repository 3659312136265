import { to } from 'api/await-to';
import {
  AppUserShareDto,
  DirectoryAccessInfoDto,
  DownloadSharedUrl,
  ProcessSharedDownloadDto,
  SharedDownloadDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const sharedDownloadApi = {
  getShare: (projectId: Guid, shareId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<SharedDownloadDto>(`/projects/${projectId}/share/${shareId}`, { cancelToken })
    ),
  processShareDownload: (projectId: Guid, data: ProcessSharedDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DownloadSharedUrl>(`/projects/${projectId}/share`, data, { cancelToken })
    ),
  getDirectoryAccessInfo: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryAccessInfoDto>(`/share/directory/${directoryId}`, { cancelToken })
    ),
  processAppUserShare: (data: AppUserShareDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<AppUserShareDto>(`/share/process`, data, { cancelToken })
    ),
};
