import { Col, Row, Table, Typography } from 'antd';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { Fmt } from 'locale';
import { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { MessageViewer, messageDetailUrlLinkParse } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

enum ChangeSuccessState {
  changed = 'changed',
  unchanged = 'unchanged',
  ignored = 'ignored',
}
enum EntityTypeEnum {
  document = 'document',
  link = 'link',
}
enum BulkActionReportType {
  documentStatesChanged = 'documentStatesChanged',
  documentLabelsAdded = 'documentLabelsAdded',
  documentLabelsReplaced = 'documentLabelsReplaced',
  documentLabelsRemoved = 'documentLabelsRemoved',
}

type BulkChangesReportItemData = {
  ChangeSuccessState: ChangeSuccessState;
  Name: string;
  OriginalValue: string;
  Path: string;
  NameLink?: string;
  PathLink?: string;
};

type BulkChangesReportInfo = {
  BulkActionReportType: BulkActionReportType;
  AffectedLabels: string[];
  NewState: WorkflowStateEnum;
  ReportItems: BulkChangesReportItemData[];
};

export type BulkChangesMessageData = {
  Language: string;
  ProjectId: Guid;
  ProjectDescription: string;
  FinishTime: Moment;
  ReportInfo: BulkChangesReportInfo;
};

export const BulkChangesMessageViewer: MessageViewer = ({ category, messageData, projectName, organizationName }) => {
  const data = messageData as BulkChangesMessageData;

  const columns = [
    {
      title: <Fmt id={'general.name'} />,
      dataIndex: 'Name',
      key: 'Name',
      ellipsis: true,
      render: (documentName: string, rowData: BulkChangesReportItemData) => {
        return !!rowData.NameLink ? (
          <Link to={messageDetailUrlLinkParse(rowData.NameLink)}>
            <Typography.Link underline>{documentName}</Typography.Link>
          </Link>
        ) : (
          documentName
        );
      },
    },
    {
      title: <Fmt id={'general.path'} />,
      dataIndex: 'Path',
      key: 'Path',
      ellipsis: true,
      render: (pathName: string, rowData: BulkChangesReportItemData) => {
        return !!rowData.PathLink ? (
          <Link to={messageDetailUrlLinkParse(rowData.PathLink)}>
            <Typography.Link underline>{pathName}</Typography.Link>
          </Link>
        ) : (
          pathName
        );
      },
    },
    {
      title: <Fmt id={'general.type'} />,
      dataIndex: 'ItemType',
      key: 'ItemType',
      render: (ItemType: EntityTypeEnum) => {
        return <Fmt id={`general.${ItemType}`} />;
      },
      ellipsis: true,
    },
    {
      title: <Fmt id={'MessageCenterPage.message.detail.bulkChanges.originalState'} />,
      dataIndex: 'OriginalValue',
      key: 'OriginalValue',
      render: (OriginalValue: WorkflowStateEnum) => {
        return <RevisionNumberTag state={OriginalValue} showTitle />;
      },
      ellipsis: true,
      width: 190,
    },
  ];

  const documentChangeStateReportItemsTableVersion = (successState: ChangeSuccessState) => {
    const dataSource = data.ReportInfo.ReportItems.filter(
      (reportItem) => reportItem.ChangeSuccessState === successState
    );
    return <Table dataSource={dataSource} columns={columns} pagination={false} />;
  };

  const header = <Fmt id={'MessageCenterPage.message.detail.bulkChanges.title'} />;

  const content = (
    <>
      <Typography.Text strong>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        {!!data.ReportInfo.BulkActionReportType && (
          <Row>
            <Col span={8}>
              <Fmt
                id={`MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.${data.ReportInfo.BulkActionReportType}`}
              />
            </Col>
          </Row>
        )}
        {!!data.ReportInfo.AffectedLabels && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.bulkChanges.newLabels'} />
            </Col>
            <Col span={12}>{data.ReportInfo.AffectedLabels.join(', ')}</Col>
          </Row>
        )}
        {!!data.ReportInfo.NewState && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.bulkChanges.newState'} />
            </Col>
            <Col span={6}>
              <RevisionNumberTag state={data.ReportInfo.NewState} showTitle />
            </Col>
          </Row>
        )}
        {data.ReportInfo.ReportItems.some(
          (reportItem) => reportItem.ChangeSuccessState === ChangeSuccessState.changed
        ) && (
          <Row>
            <Typography.Text strong underline>
              <Fmt id={'MessageCenterPage.message.detail.bulkChanges.changedDocuments'} />
            </Typography.Text>
            {documentChangeStateReportItemsTableVersion(ChangeSuccessState.changed)}
          </Row>
        )}
        {data.ReportInfo.ReportItems.some(
          (reportItem) => reportItem.ChangeSuccessState === ChangeSuccessState.unchanged
        ) && (
          <Row>
            <Typography.Text strong underline>
              <Fmt id={'MessageCenterPage.message.detail.bulkChanges.unchangedDocuments'} />
            </Typography.Text>
            {documentChangeStateReportItemsTableVersion(ChangeSuccessState.unchanged)}
          </Row>
        )}
        {data.ReportInfo.ReportItems.some(
          (reportItem) => reportItem.ChangeSuccessState === ChangeSuccessState.ignored
        ) && (
          <Row>
            <Typography.Text strong underline>
              <Fmt id={'MessageCenterPage.message.detail.bulkChanges.ignoredDocuments'} />
            </Typography.Text>
            {documentChangeStateReportItemsTableVersion(ChangeSuccessState.ignored)}
          </Row>
        )}
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
