import { api } from 'api';
import { processApiError } from 'utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentProjectUser, useIsMounted } from 'hooks';
import { RevisionDto, ServiceError } from 'api/completeApiInterfaces';

export const findCurrentRevision = (revisions: RevisionDto[]) => {
  return revisions.filter((revision) => !revision.discardedBy)[0];
};

export const useLoadDocumentRevisions = (documentId: Guid) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [revisions, setRevisions] = useState<RevisionDto[]>([]);
  const [error, setError] = useState<ServiceError | null>(null);
  const isMounted = useIsMounted();

  const currentUser = useCurrentProjectUser();

  const discardRevision = async (revisionId: Guid) => {
    const discardedRevision = revisions.find((revision) => revision.id === revisionId);
    if (!discardedRevision) {
      return;
    }
    setRevisions(
      [
        { ...discardedRevision, discardedBy: currentUser },
        ...revisions.filter((revision) => revision.id !== revisionId),
      ].sort((a, b) => b.number - a.number)
    );
  };

  const restoreRevision = async (revisionId: Guid) => {
    const restoredRevision = revisions.find((revision) => revision.id === revisionId);
    if (!restoredRevision) {
      return;
    }
    setRevisions(
      [{ ...restoredRevision, discardedBy: null }, ...revisions.filter((revision) => revision.id !== revisionId)].sort(
        (a, b) => b.number - a.number
      )
    );
  };

  const loadRevisions = useCallback(async () => {
    if (!documentId) {
      return Promise.reject();
    }
    setLoading(true);

    const [err, res] = await api.project.documents.listDocumentRevisions(documentId);

    if (!isMounted.current) {
      return Promise.reject();
    }

    if (err) {
      processApiError(err, (error) => {
        setError(error);
        setLoading(false);
        setRevisions(null);
      });
      return false;
    } else {
      const revs = res.data;
      revs.sort((a, b) => b.number - a.number);
      setError(null);
      setLoading(false);
      setRevisions(revs);
      return true;
    }
  }, [documentId]);

  const currentRevision = useMemo(() => revisions && findCurrentRevision(revisions), [revisions]);

  useEffect(() => {
    void loadRevisions();
  }, [loadRevisions]);

  return {
    revisions,
    loadRevisions,
    revisionsLoading: loading,
    revisionsError: error,
    setRevisions,
    currentRevision,
    discardRevision,
    restoreRevision,
  } as const;
};
