import { FileOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { InjectedIntl } from 'locale';
import { Dictionary } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import decode from 'unescape';
import { jsonDecode } from 'utils/paramEncode';
import { ArchiveProcessor, ArchiveProcessorData } from './JsonProcessors/ArchiveProcessor';
import { MailProcessor, MailProcessorData } from './JsonProcessors/MailProcessor';
import styles from './ProcessedJsonViewer.module.less';

type ProcessorTypes = MailProcessorData | ArchiveProcessorData;

type JsonTypeData = {
  type: string;
  subtype: string;
  contentType: string;
} & ProcessorTypes;

export type ProcessedJsonData = {
  headers?: { key: number; title: string; value: string }[];
  body: JSX.Element;
};

const JsonTypeProcessors: Dictionary<(data: ProcessorTypes, intl: InjectedIntl) => ProcessedJsonData> = {
  mail: MailProcessor,
  compress: ArchiveProcessor,
};

const headerColumns = [
  {
    key: 'title',
    dataIndex: 'title',
  },
  {
    key: 'value',
    dataIndex: 'value',
  },
];

export const ProcessedJsonViewer: FileViewerComponent = ({ style, className, url, onLoad }) => {
  const [content, setContent] = useState<string>();

  useEffect(() => {
    if (url) {
      const controller = new AbortController();
      fetch(url, { signal: controller.signal })
        .then((response) => response.text())
        .then(decode)
        .then((text) => text.replaceAll('\t', '    '))
        .then(setContent)
        .then(onLoad);
      return () => controller.abort();
    }
    return undefined;
  }, [url]);

  const intl = useIntl();

  const decodedContent = useMemo((): ProcessedJsonData => {
    const [err, jsonData] = jsonDecode<JsonTypeData>(content);
    if (!!err) {
      return {
        body: (
          <div className={styles.messageContainer}>
            {intl.formatMessage({ id: 'ProcessedJsonViewer.processor.file.error' })}
          </div>
        ),
      };
    }

    const processor = JsonTypeProcessors[jsonData.type];
    return !!processor
      ? processor(jsonData, intl)
      : {
          body: (
            <div className={styles.messageContainer}>
              {intl.formatMessage({ id: 'ProcessedJsonViewer.processor.notFound' })}
            </div>
          ),
        };
  }, [content, intl]);

  return (
    <div style={style} className={className}>
      <ContentGate loading={!decodedContent}>
        {decodedContent && (
          <StackPanel vertical>
            {!!decodedContent.headers && (
              <Table
                dataSource={decodedContent.headers}
                columns={headerColumns}
                pagination={false}
                showHeader={false}
                bordered={true}
                className={styles.headers}
              />
            )}
            {decodedContent.body}
          </StackPanel>
        )}
      </ContentGate>
    </div>
  );
};

export const ProcessedJsonViewerData: FileViewerProperties = {
  supportedTypes: ['application/json'],
  icon: <FileOutlined />,
  titleTranslationId: 'ProcessedJsonViewer.title',
  component: 'ProcessedJsonViewer',
};
