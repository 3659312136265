import React, { FunctionComponent, ReactNode } from 'react';
import { FlowLayout } from 'components/layouts/FlowLayout';

export const Section: FunctionComponent<{ title: ReactNode; rightContent?: ReactNode }> = ({
  title,
  rightContent,
  children,
}) => {
  return (
    <div>
      <FlowLayout growFirst wrap>
        <h3>{title}</h3>
        {rightContent}
      </FlowLayout>
      <div>{children}</div>
    </div>
  );
};
