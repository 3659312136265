enum ThrottleState {
  FREE,
  WAIT, // one callback executed, waiting for another?
  QUEUED, // another callback in queue
}

/**
 * Throttles the input function so it is called at most 1 every timeout milliseconds.
 * When called again within timeout milliseconds, the call will be queued and executed at the end of the interval.
 * @param callback The function to be called.
 * @param timeout Time interval between calls in milliseconds.
 * @returns <code>Function & { cancel: () => void; }</code> Throttled function with an option to cancel all pending executions.
 */
export const throttle = <T extends unknown[]>(callback: (...args: T) => void, timeout: number) => {
  let state: ThrottleState = ThrottleState.FREE;
  let lastArgs: T = undefined;
  let timeoutObj: NodeJS.Timeout;

  const timeoutCb = () => {
    if (state === ThrottleState.QUEUED) {
      callback(...lastArgs);
      state = ThrottleState.WAIT;
      timeoutObj = setTimeout(timeoutCb, timeout);
    } else {
      state = ThrottleState.FREE;
    }
  };

  const returnFunction = (...args: T) => {
    switch (state) {
      case ThrottleState.FREE:
        callback(...args);
        state = ThrottleState.WAIT;
        timeoutObj = setTimeout(timeoutCb, timeout);
        return;
      case ThrottleState.WAIT:
        lastArgs = args;
        state = ThrottleState.QUEUED;
        return;
      case ThrottleState.QUEUED:
        lastArgs = args;
        return;
    }
  };

  const cancelFunction = () => {
    clearTimeout(timeoutObj);
    state = ThrottleState.FREE;
  };

  return Object.assign(returnFunction, { cancel: cancelFunction });
};
