import { DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoryLink } from 'api/project/directories/directoriesApi';
import { accessLevelMap, accessLevelReverseMap } from 'components/DirectoryAccessLevel/DirectoryAccessLevel';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { textComparer } from 'utils/comparators';
import {
  ConnectedDirectory,
  ConnectedDirectoryLink,
  DirectoryNodeType,
  directoryNodeHelpers,
} from 'utils/typeMappings/directories/directoryTypes';

const sortChildrenRecursively = (dir: ConnectedDirectory) => {
  dir.children.sort(textComparer.map(directoryNodeHelpers.getDirectoryNodeName));
  dir.children.forEach((child) => {
    if (child?.type === DirectoryNodeType.Directory) {
      sortChildrenRecursively(child.directory);
    }
  });
};

const directoryListToLinkedTree = (dirs: DirectoryListDto[], dirLinks: DirectoryLink[] | null) => {
  const dirsById: Record<Guid, ConnectedDirectory> = {};
  const linksById: Record<Guid, ConnectedDirectoryLink> = dirLinks ? {} : undefined;
  let rootDir: ConnectedDirectory = undefined;

  if (dirs) {
    // first pass: save by id and collect children
    for (const dir of dirs) {
      const asLinkedTree: ConnectedDirectory = {
        ...dir,
        parent: undefined,
        children: [],
        accessLevelIncludingChildren: dir.currentAccessLevel,
      };
      dirsById[dir.id] = asLinkedTree;
      if (!dir.parentId) {
        rootDir = asLinkedTree;
      }
    }
    rootDir.parent = null;

    // second pass: link parents and children together
    for (const dirId in dirsById) {
      const dir = dirsById[dirId];
      dir.parent = dirsById[dir.parentId] || null;
      if (dir.parent) {
        dir.parent.children.push(directoryNodeHelpers.directoryNode(dir));
      }
    }

    // next, process directory links
    if (dirLinks) {
      for (const dirLink of dirLinks) {
        const linked: ConnectedDirectoryLink = {
          ...dirLink,
          linkedDirectory: dirsById[dirLink.linkedDirectoryId],
          parentDirectory: dirsById[dirLink.parentDirectoryId],
        };
        linked.parentDirectory?.children.push(directoryNodeHelpers.directoryLinkNode(linked));
        linksById[linked.linkId] = linked;
      }
    }

    // third pass: pre-compute access level including children
    const computePermissionsInDir = (dir: ConnectedDirectory) => {
      let commonPermission = accessLevelMap[dir.currentAccessLevel];
      for (const child of dir.children) {
        if (child.type !== DirectoryNodeType.Directory) continue;
        computePermissionsInDir(child.directory);
        const childPermission = accessLevelMap[child.directory.accessLevelIncludingChildren];
        if (childPermission < commonPermission) {
          commonPermission = childPermission;
        }
      }
      dir.accessLevelIncludingChildren = accessLevelReverseMap[commonPermission];
    };

    computePermissionsInDir(rootDir);

    sortChildrenRecursively(rootDir);
  }

  return { rootDir, dirsById, linksById };
};

const directoryListSelector = (state: RootState) => state.directories.data;
const directoriesWithLinksSelector = (state: RootState) => state.directoriesWithLinks.data;

const processedDirectoriesTreeSelector = createSelector(
  [directoryListSelector, directoriesWithLinksSelector],
  (dirs, dirLinks) => {
    // TODO: directories should be stored only in one place, so remove the old "directory list" endpoint or rework the store
    const directories = dirs || dirLinks?.directories;
    return directoryListToLinkedTree(directories, dirLinks?.directoryLinks);
  }
);

export const directoryConnectedMapSelector = createSelector(
  [processedDirectoriesTreeSelector],
  (processedTree) => processedTree.dirsById
);

export const directoryConnectedLinksSelector = createSelector(
  [processedDirectoriesTreeSelector],
  (processedTree) => processedTree.linksById
);

export const directoryRootSelector = createSelector(
  [processedDirectoriesTreeSelector],
  (processedTree) => processedTree.rootDir
);
