import { EstiCategoryEnum } from 'api/completeApiInterfaces';
import { API_BASE_URL } from 'config/env';
import { useCurrentProjectUser, useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useAggregateDirectoryMessage, useAggregateDocumentMessage } from 'hooks/useAggregateDocumentMessage';
import { useAggregateEsticonGridMessage } from 'hooks/useAggregateEsticonGridMessage';
import { IntlMessageId } from 'locale/messages/cs';
import { useEffect } from 'react';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import SignalR from 'utils/SignalR';

export const SIGNAL_R_DIRECTORY_CONNECTION = new SignalR(`${API_BASE_URL}/messagehub/DirectoryContentHub`);
export const SIGNAL_R_DOCUMENT_CONNECTION = new SignalR(`${API_BASE_URL}/messagehub/DocumentDetailHub`);
export const SIGNAL_R_ESTICON_CONNECTION = new SignalR(`${API_BASE_URL}/messagehub/EsticonHub`);
export const SIGNAL_R_REDUX_STORE_CONNECTION = new SignalR(`${API_BASE_URL}/messagehub/PersistedEntitiesHub`);
export const SIGNAL_R_WORKFLOW_CONNECTION = new SignalR(`${API_BASE_URL}/messagehub/WorkflowHub`);

export const useSignalRDirectory = (reloadDocuments: () => void, reloadDirectory: () => void) => {
  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
  }, []);

  const users = useStoreSelector(projectUsersListSelector);
  const currentProjectUser = useCurrentProjectUser();
  const intl = useIntl();

  const useCreateDocumentAggregator = (messageId: IntlMessageId) =>
    useAggregateDocumentMessage(reloadDocuments, messageId, intl, users, currentProjectUser);
  const useCreateDocumentLinkAggregator = (messageId: IntlMessageId) =>
    useAggregateDocumentMessage(reloadDocuments, messageId, intl, users, currentProjectUser);
  const useCreateDirectoryAggregator = (messageId: IntlMessageId) =>
    useAggregateDirectoryMessage(reloadDirectory, messageId, intl, currentProjectUser);

  const DocumentAdded = useCreateDocumentAggregator('SignalR.directory.newDocumentAdded');
  const DocumentRestored = useCreateDocumentAggregator('SignalR.directory.onDocumentsRestored');
  const DocumentDiscarded = useCreateDocumentAggregator('SignalR.directory.onDocumentDiscarded');
  const DocumentChanged = useCreateDocumentAggregator('SignalR.directory.onDocumentChanged');
  const DocumentMovedTo = useCreateDocumentAggregator('SignalR.directory.onDocumentMovedTo');
  const DocumentMovedOut = useCreateDocumentAggregator('SignalR.directory.onDocumentMovedOut');

  const DocumentLinkAdded = useCreateDocumentLinkAggregator('SignalR.directory.onDocumentLinkAdded');
  const DocumentLinkRemoved = useCreateDocumentLinkAggregator('SignalR.directory.onDocumentLinkRemoved');
  const DocumentLinkMovedOut = useCreateDocumentLinkAggregator('SignalR.directory.onDocumentLinkMovedOut');
  const DocumentLinkMovedTo = useCreateDocumentLinkAggregator('SignalR.directory.onDocumentLinkMovedTo');
  const DocumentLinkNameChanged = useCreateDocumentLinkAggregator('SignalR.directory.onDocumentLinkNameChanged');

  const DirectoryLocationChanged = useCreateDirectoryAggregator('SignalR.directory.directoryLocationChanged');
  const DirectoryCurrentUserRightsChanged = useCreateDirectoryAggregator(
    'SignalR.directory.DirectoryCurrentUserRightsChanged'
  );

  useEffect(() => {
    const events = Object.entries({
      DocumentAdded,
      DocumentMovedOut,
      DocumentMovedTo,
      DocumentChanged,
      DocumentDiscarded,
      DocumentRestored,
      DocumentLinkAdded,
      DocumentLinkRemoved,
      DocumentLinkMovedOut,
      DocumentLinkMovedTo,
      DocumentLinkNameChanged,
      DirectoryLocationChanged,
      DirectoryCurrentUserRightsChanged,
    });

    events.forEach(([key, value]) => {
      SIGNAL_R_DIRECTORY_CONNECTION.on(key, value);
    });

    return () => {
      events.forEach(([key, value]) => {
        SIGNAL_R_DIRECTORY_CONNECTION.off(key, value);
      });
    };
  }, [
    DocumentAdded,
    DocumentMovedOut,
    DocumentMovedTo,
    DocumentChanged,
    DocumentDiscarded,
    DocumentRestored,
    DocumentLinkAdded,
    DocumentLinkRemoved,
    DocumentLinkMovedOut,
    DocumentLinkMovedTo,
    DocumentLinkNameChanged,
    DirectoryLocationChanged,
    DirectoryCurrentUserRightsChanged,
  ]);
};

export const useSignalREsticonGrid = (
  reloadEsticonGrid: () => void,
  categories: EstiCategoryEnum[],
  projectId: Guid
) => {
  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
  }, []);

  const users = useStoreSelector(projectUsersListSelector);
  const currentProjectUser = useCurrentProjectUser();
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      await SIGNAL_R_ESTICON_CONNECTION.start();
      await SIGNAL_R_ESTICON_CONNECTION.joinGroup(projectId);
    })();

    return () => async () => {
      await SIGNAL_R_ESTICON_CONNECTION.leaveGroup(projectId);
      await SIGNAL_R_ESTICON_CONNECTION.stop();
    };
  }, [projectId]);

  const useCreateEsticonGridAggregator = (messageId: IntlMessageId) =>
    useAggregateEsticonGridMessage(reloadEsticonGrid, messageId, intl, users, currentProjectUser, categories);

  const ObjectAdded = useCreateEsticonGridAggregator('SignalR.esticonGrid.onObjectAdded');
  const ObjectDateChanged = useCreateEsticonGridAggregator('SignalR.esticonGrid.onObjectDateChanged');
  const ObjectReturnForRework = useCreateEsticonGridAggregator('SignalR.esticonGrid.onObjectReturnForRework');

  useEffect(() => {
    const events = Object.entries({
      ObjectAdded,
      ObjectDateChanged,
      ObjectReturnForRework,
    });

    events.forEach(([key, value]) => {
      SIGNAL_R_ESTICON_CONNECTION.on(key, value);
    });

    return () => {
      events.forEach(([key, value]) => {
        SIGNAL_R_ESTICON_CONNECTION.off(key, value);
      });
    };
  }, [ObjectAdded, ObjectDateChanged, ObjectReturnForRework]);
};
