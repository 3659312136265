import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { AppUserDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';

export type AppUserAddFormData = {
  usersToAddWithAttributes: Record<string, UserAttributes>;
};

type Props = FormComponentProps<AppUserAddFormData> &
  InjectedIntlProps & {
    toAddAppUsers: AppUserDto[];
    isAdminsAdding?: boolean;
  };

const OrganizationUserAddFormComponent = React.forwardRef<unknown, Props>(
  ({ form, intl, toAddAppUsers = [], isAdminsAdding }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    return (
      <>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item>
            {form.getFieldDecorator(
              'usersToAddWithAttributes',
              {}
            )(
              <AppUsersFormItem
                selectableAppUsers={toAddAppUsers}
                intl={intl}
                isAdminsAdding={isAdminsAdding}
                selectableUsersToAddSource={SelectableUsersSourceEnum.aspeHub}
              />
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
);

export default Form.create<Props>()(OrganizationUserAddFormComponent);
