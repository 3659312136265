import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';

export const modalConfirm = async ({ onOk, onCancel, ...restConfig }: ModalFuncProps): Promise<boolean> => {
  return new Promise((resolve) => {
    Modal.confirm({
      onOk: async () => {
        onOk && (await onOk());
        resolve(true);
      },
      onCancel: async () => {
        onCancel && (await onCancel());
        resolve(false);
      },
      ...restConfig,
    });
  });
};
