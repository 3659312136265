import { LabelDisplay, LabelLinkType } from 'components/LabelDisplay/LabelDisplay';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { labelsMapSelector } from 'store/selectors/labelsSelectors';
import { textComparer } from 'utils/comparators';

type OwnProps = {
  labels: Guid[];
  linkType?: LabelLinkType;
};

const mapStateToProps = (state: RootState) => ({
  labelsMap: labelsMapSelector(state),
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

type Props = PropsFromState & OwnProps;

const LabelsList: FunctionComponent<Props> = ({ labels, labelsMap, linkType }) => {
  useDirtyStoreReload(
    (store) => store.labels,
    (dispatch) => dispatch.labels
  );

  return (
    <>
      {labelsMap &&
        labels
          ?.map((id) => labelsMap[id])
          .filter(Boolean)
          .sort(textComparer.map((label) => label.name))
          .map((label) => <LabelDisplay key={label.id} label={label} linkType={linkType} />)}{' '}
    </>
  );
};

export default connect(mapStateToProps)(LabelsList);
