import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import Select from 'antd/lib/select';
import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { PersonalReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import StackPanel from 'components/StackPanel';
import { Serializable } from 'components/filters/filterTypes';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { Fmt, InjectedIntlProps } from 'locale';
import { isEqual } from 'lodash';
import { ProjectsInRealisationOverviewReportData } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import React, { useEffect, useState } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import ProjectsInRealizationConfigurationForm from './ReportWidgetConfigurations/ProjectsInRealizationConfigurationForm';
import { ProjectsInRealizationReportConfigurationColumnFormItem } from './ReportWidgetConfigurations/ProjectsInRealizationReportConfigurationColumnFormItem';
import {} from './ReportWidgetCreateForm';
import { ProjectsInRealizationFiltersFormItem } from './ReportWidgetFilterInputs/ProjectsInRealizationFilterFormItem';
import styles from './ReportWidgetForm.module.less';
import {
  ReportWidgetColumnConfiguration,
  ReportWidgetDecimalUnitEnum,
  ReportWidgetLabelAlignEnum,
  getDecimalUnitOptions,
  getLabelAlignOptions,
} from './ReportWidgetForm.utils';

export type ReportWidgetEditFormData = {
  name: string;
  viewDecimalUnit: ReportWidgetDecimalUnitEnum;
  filters: Record<string, Serializable>;
  labelAlign: ReportWidgetLabelAlignEnum;
} & {
  reportType: OrgExtendedPermissionEnum.projectsInRealisationOverview;
  esticonUnit: string;
  reportDate: string;
  view: Guid;
  startYear: number;
  columnConfigurations: ReportWidgetColumnConfiguration<ProjectsInRealisationOverviewReportData>[];
};

type Props = FormComponentProps<ReportWidgetEditFormData> &
  InjectedIntlProps & {
    configuration: PersonalReportWidgetConfiguration;
    setRef: (ref: InputRef) => void;
    requestCacheApiData: RequestCacheApiCallback;
  };

const ReportWidgetEditForm = React.forwardRef<unknown, Props>(
  ({ intl, form, configuration, requestCacheApiData, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;
    const [formValues, setFormValues] = useState<Record<string, any>>();

    useEffect(() => {
      const currentFormValues = form.getFieldsValue();
      if (isEqual(formValues, currentFormValues)) {
        return;
      }
      setFormValues(currentFormValues);
    }, [form]);

    const decimalUnitOptions = getDecimalUnitOptions(intl);
    const labelAlignOptions = getLabelAlignOptions(intl);

    return (
      <Form layout="vertical" className={styles.formWrapper}>
        <StackPanel vertical className={styles.generalConfig}>
          <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
            {getFieldDecorator<ReportWidgetEditFormData>('name', {
              rules: [
                requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
                maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
              ],
              initialValue: configuration.title,
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
                autoFocus
                ref={setRef}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.unit' })}>
            {form.getFieldDecorator<ReportWidgetEditFormData>('viewDecimalUnit', {
              initialValue: configuration.data.viewDecimalUnit,
              rules: [requiredRule('ReportWidgetCreateFormModalForm.unit.required', true)],
            })(<Select options={decimalUnitOptions} />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.alignLabel' })}>
            {form.getFieldDecorator<ReportWidgetEditFormData>('labelAlign', {
              initialValue: configuration.data.labelAlign,
              rules: [requiredRule('ReportWidgetCreateFormModalForm.alignLabel.required', true)],
            })(<Select options={labelAlignOptions} />)}
          </Form.Item>
          {configuration.data.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <ProjectsInRealizationConfigurationForm
              intl={intl}
              organizationId={configuration.data.organizationId}
              defaultValues={configuration.data}
              form={form}
            />
          )}
        </StackPanel>
        <StackPanel vertical>
          {configuration.data.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <Form.Item label={<Fmt id="ReportWidgetCreateFormModalForm.filters" />}>
                {form.getFieldDecorator<ReportWidgetEditFormData>('filters', {
                  initialValue: configuration.data.filters,
                })(
                  <ProjectsInRealizationFiltersFormItem
                    requestCacheApiData={requestCacheApiData}
                    organizationId={configuration.data.organizationId}
                    {...formValues}
                  />
                )}
              </Form.Item>
              <Form.Item label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.columnConfiguration" />}>
                {form.getFieldDecorator<ReportWidgetEditFormData>('columnConfigurations', {
                  rules: [requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.date', false)],
                  initialValue: configuration.data.columnConfigurations || [],
                })(<ProjectsInRealizationReportConfigurationColumnFormItem intl={intl} columnCountLimit={6} />)}
              </Form.Item>
            </>
          )}
        </StackPanel>
      </Form>
    );
  }
);

export default Form.create<Props>()(ReportWidgetEditForm);
