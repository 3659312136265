import { to } from 'api/await-to';
import {
  EstiConAdresyPartneriDto,
  EstiConBankaDto,
  EstiConCerpaniZjistovaciProtokolDto,
  EstiConDphZemeDto,
  EstiConExportApiResult,
  EstiConExportCerpaniRequest,
  EstiConFakDetailDto,
  EstiConFakProjektDto,
  EstiConFakSpolDetailDto,
  EstiConFirmDetailDto,
  EstiConFirmRowDto,
  EstiConHarmonogramVersionDto,
  EstiConImportAnalyzeFakturyResult,
  EstiConImportAnalyzeHarmonogramResult,
  EstiConImportAnalyzeZBVResult,
  EstiConImportAnalyzeZPResult,
  EstiConImportCerpaniRunDto,
  EstiConImportFakturyRunDto,
  EstiConImportHarmonogramRunDto,
  EstiConImportResult,
  EstiConImportZBVRunDto,
  EstiConPartnerDto,
  EstiConProjectDto,
  EstiConProjectRowDto,
  EstiConRozPolozkaRealizaceDto,
  EstiConRozPolozkaSpecifikaceDto,
  EstiConRozProjektDto,
  EstiConRozSODto,
  EstiConSaveResultDto,
  EstiConVykazVymerDto,
  EstiConZBVPolozkyZmenovyListDto,
  EstiConZBVProjectDto,
  EstiConZBVZmenovyListDetailDto,
  EstiConZjistovaciProtokolDto,
  EstiConZjistovaciProtokolSdruzeniDetailDto,
  EstiConZjistovaciProtokolyListProjectDto,
  EsticonAnalyzeZBVRequestDto,
} from 'api/completeApiInterfaces';
import { baseProjectApi } from 'api/project/baseProjectApi';
import { CancelToken } from 'axios';

export const esticonApi = {
  getAllPartnersAddresses: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConAdresyPartneriDto>(`/Esticon/firms/ciselniky/adresypartneri`, {
        cancelToken,
      })
    ),
  getEsticonFirms: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConFirmRowDto[]>(`/Esticon/firms`, {
        cancelToken,
      })
    ),
  getEsticonFirm: (esticonFirmId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConFirmDetailDto>(`/Esticon/firm/${esticonFirmId}`, {
        cancelToken,
      })
    ),
  getEsticonProjects: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConProjectRowDto[]>(`/Esticon/firms/projects`, {
        cancelToken,
      })
    ),
  getEsticonProject: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConProjectDto>(`/Esticon/firms/projects/project`, {
        cancelToken,
      })
    ),
  transferEsticonProjectToControlDatabase: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConImportResult>(`/Esticon/firms/projects/transferconstructiontokd`, {
        cancelToken,
      })
    ),
  getEsticonLinkableProjects: (firmId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConProjectDto[]>(`/Esticon/firms/${firmId}/linkableprojects`, {
        cancelToken,
      })
    ),

  getBudget: (sentOnly: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConRozProjektDto>(`/Esticon/firms/rozpocet/${sentOnly}`, {
        cancelToken,
      })
    ),

  getBudgetBuildingObjectDetail: (objectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConRozSODto>(`/Esticon/firms/rozpocet/SO/${objectId}`, {
        cancelToken,
      })
    ),

  getZp: (sentOnly: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZjistovaciProtokolyListProjectDto>(`/Esticon/firms/realizace/${sentOnly}`, {
        cancelToken,
      })
    ),

  getZpDetail: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZjistovaciProtokolDto>(`/Esticon/firms/realizace/zp/${id}`, {
        cancelToken,
      })
    ),

  getZpBreakdown: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConCerpaniZjistovaciProtokolDto>(`/Esticon/firms/realizace/zp/${id}/cerpani`, {
        cancelToken,
      })
    ),

  getCommonZpDetail: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZjistovaciProtokolSdruzeniDetailDto>(`/Esticon/firms/realizace/zp/${id}/sdruzeni`, {
        cancelToken,
      })
    ),

  getQuantitySheet: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConVykazVymerDto>(`/Esticon/firms/rozpocet/pr/${id}/vykazvymer`, {
        cancelToken,
      })
    ),

  getRealization: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConRozPolozkaRealizaceDto[]>(`Esticon/firms/rozpocet/pr/${id}/realizace`, { cancelToken })
    ),

  getZbv: (sentOnly: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZBVProjectDto>(`/Esticon/firms/zbv/${sentOnly}`, {
        cancelToken,
      })
    ),

  getZbvBreakdown: (zbvId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZBVPolozkyZmenovyListDto>(`/Esticon/firms/zbv/${zbvId}/zlpolozky`, {
        cancelToken,
      })
    ),

  getVat: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConDphZemeDto[]>(`/Esticon/firms/ciselniky/dph`, { cancelToken })
    ),

  getPartnersAddresses: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConAdresyPartneriDto>(`/Esticon/firms/ciselniky/adresypartneri`, { cancelToken })
    ),

  createNewPartner: (partnerData: EstiConPartnerDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConSaveResultDto>(`/Esticon/firms/ciselniky/adresypartneri`, partnerData, { cancelToken })
    ),

  getBanksRegister: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConBankaDto[]>(`/Esticon/firms/ciselniky/banky`, { cancelToken })
    ),

  getSpecification: (id: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConRozPolozkaSpecifikaceDto>(`/Esticon/firms/rozpocet/pr/${id}/specifikace`, {
        cancelToken,
      })
    ),

  uploadAnalyzeZp: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeZPResult>(`/Esticon/firms/import/analyzeZP/${uploadId}`, null, {
        cancelToken,
      })
    ),

  uploadAnalyzeZpDoc: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeZPResult>(`/Esticon/firms/import/analyzeZPDoc/${documentId}`, null, {
        cancelToken,
      })
    ),

  uploadImportZp: (data: EstiConImportCerpaniRunDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportResult>(`/Esticon/firms/import/importZP`, data, {
        cancelToken,
      })
    ),

  uploadAnalyzeZbv: (uploadId: Guid, data: EsticonAnalyzeZBVRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeZBVResult>(`/Esticon/firms/import/analyzeZBV/${uploadId}`, data, {
        cancelToken,
      })
    ),

  uploadAnalyzeZbvDoc: (documentId: Guid, data: EsticonAnalyzeZBVRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeZBVResult>(`/Esticon/firms/import/analyzeZBVDoc/${documentId}`, data, {
        cancelToken,
      })
    ),

  uploadImportZbv: (data: EstiConImportZBVRunDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportResult>(`/Esticon/firms/import/importZBV`, data, {
        cancelToken,
      })
    ),

  getInvoice: (sentOnly: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConFakProjektDto>(`/Esticon/firms/fakturace/${sentOnly}`, {
        cancelToken,
      })
    ),

  getInvoiceDetail: (invoiceId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConFakDetailDto>(`/Esticon/firms/fakturace/fakt/${invoiceId}/detail`, {
        cancelToken,
      })
    ),

  getCommonInvoiceDetail: (invoiceId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConFakSpolDetailDto>(`/Esticon/firms/fakturace/fakt/${invoiceId}/spoldetail`, {
        cancelToken,
      })
    ),

  uploadAnalyzeInvoice: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeFakturyResult>(`/Esticon/firms/import/analyzeFaktury/${uploadId}`, null, {
        cancelToken,
      })
    ),

  uploadAnalyzeInvoiceDoc: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeFakturyResult>(
        `/Esticon/firms/import/analyzeFakturyDoc/${documentId}`,
        null,
        {
          cancelToken,
        }
      )
    ),

  uploadImportInvoice: (data: EstiConImportFakturyRunDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportResult>(`/Esticon/firms/import/importFaktury`, data, {
        cancelToken,
      })
    ),

  getZbvDetail: (zbvId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConZBVZmenovyListDetailDto>(`/Esticon/firms/zbv/${zbvId}/detail`, {
        cancelToken,
      })
    ),
  getHgmPdf: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<string>(`/Esticon/firms/hgm/pdf`, {
        cancelToken,
      })
    ),
  getHgmVersions: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiConHarmonogramVersionDto[]>(`/Esticon/firms/hgm/versions`, {
        cancelToken,
      })
    ),
  uploadAnalyzeHarmonogram: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeHarmonogramResult>(
        `/Esticon/firms/import/analyzeHarmonogram/${uploadId}`,
        null,
        {
          cancelToken,
        }
      )
    ),

  uploadAnalyzeHarmonogramDoc: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportAnalyzeHarmonogramResult>(
        `/Esticon/firms/import/analyzeHarmonogramDoc/${documentId}`,
        null,
        {
          cancelToken,
        }
      )
    ),
  uploadImportHmg: (data: EstiConImportHarmonogramRunDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConImportResult>(`/Esticon/firms/import/importHarmonogram`, data, {
        cancelToken,
      })
    ),
  buildingDrawingDownload: (data: EstiConExportCerpaniRequest, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<EstiConExportApiResult>(`/Esticon/firms/export/exportCerpani`, data, { cancelToken })
    ),
};
