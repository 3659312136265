import { SelectedIdPairedToTargetId } from 'components/DocumentSelect/DocumentList';
import { useSameCallback } from 'hooks/useSameCallback';
import { flow } from 'lodash';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useKeyPress } from 'react-use';
import { List } from 'react-virtualized';

export type IndexSpanType = {
  start: number;
  end: number;
};

function processDocumentsState<T extends { id: Guid }>(
  isSelectableFilter: (item: T, index: number) => boolean,
  items: T[] = []
) {
  const filteredItems = isSelectableFilter ? items?.filter(isSelectableFilter) : items;
  return new Set(filteredItems?.map((item) => item.id));
}

function validateIndexOrder(start: number, end: number): IndexSpanType {
  if (start <= end) return { start, end };
  return { start: end, end: start };
}

function getDocumentsSpanIds<T extends { id: Guid }>(
  items: T[],
  boundary: IndexSpanType,
  isSelectableFilter?: (item: T) => boolean
): Guid[] {
  return items
    .filter(
      (item, index) =>
        index >= boundary.start && index <= boundary.end && (!isSelectableFilter || isSelectableFilter(item))
    )
    .map((item) => item.id);
}

function mergeSetWithArray<T>(oldSet: Set<T>, newItems: T[]) {
  const newSet = new Set(oldSet);
  newItems.forEach((item) => newSet.add(item));
  return newSet;
}

const getDeselectIds = (clickedId: Guid, selectedIdsPairedToTargetDocuments: SelectedIdPairedToTargetId[]) => {
  if (!selectedIdsPairedToTargetDocuments?.length) {
    return [clickedId];
  }
  const targetId = selectedIdsPairedToTargetDocuments.find((pairedIds) => clickedId === pairedIds.selectedId)
    .targetDocumentId;
  const idsToRemove = selectedIdsPairedToTargetDocuments
    .filter((pairedIds) => targetId === pairedIds.targetDocumentId)
    .map((pairedIds) => pairedIds.selectedId);
  return idsToRemove;
};

export const useMultiSelectInner = <T extends { id: Guid }>(
  listRef: RefObject<List>,
  allOrderedItems: T[],
  selectedItemIds: Set<Guid>,
  setSelectedItemIds: (selectedItems: Set<Guid>) => void,
  isSelectableFilter?: (item: T) => boolean,
  selectedIdsPairedToTargetDocuments?: SelectedIdPairedToTargetId[]
) => {
  const [lastSelectedIndex, setLastSelectedIndex] = useState(undefined);

  const selectableItemIds = useMemo(() => processDocumentsState(isSelectableFilter, allOrderedItems), [
    isSelectableFilter,
    allOrderedItems,
  ]);

  // Deselect unselectable items
  useEffect(() => {
    let newItems = undefined;
    for (const itemId of selectedItemIds || []) {
      if (!selectableItemIds.has(itemId)) {
        if (!newItems) {
          newItems = new Set(selectedItemIds);
        }
        newItems.delete(itemId);
      }
    }
    if (newItems) {
      setSelectedItemIds(newItems);
    }
  }, [selectedItemIds, selectableItemIds, setSelectedItemIds]);

  // Update grid on selected change
  useEffect(() => {
    listRef?.current?.forceUpdateGrid();
  }, [listRef?.current, selectedItemIds]);

  const selectedItemsCount = selectedItemIds ? selectedItemIds.size : 0;
  const isAnyItemSelected = !!selectedItemIds?.size;
  const isAllSelected = isAnyItemSelected && selectedItemsCount === selectableItemIds.size;

  const shiftKeyPressed = useKeyPress('Shift')[0];

  const deselectItems = useCallback(() => {
    setSelectedItemIds(new Set());
  }, [setSelectedItemIds]);

  const selectAll = useSameCallback(() => {
    if (isAllSelected) {
      deselectItems();
    } else {
      setSelectedItemIds(selectableItemIds);
    }
  });

  const onSelectItem = useSameCallback((id: Guid, actualIndex: number, exclusively: boolean = false) => {
    if (exclusively) {
      setSelectedItemIds(new Set([id]));
      return;
    }
    if (!shiftKeyPressed) {
      setLastSelectedIndex(actualIndex);
      if (selectedItemIds?.has(id)) {
        const idsToRemove = getDeselectIds(id, selectedIdsPairedToTargetDocuments);

        const newSet = new Set<Guid>(selectedItemIds);
        idsToRemove.forEach((id) => {
          newSet.delete(id);
        });

        setSelectedItemIds(newSet);
      } else {
        const newSet = new Set<Guid>(selectedItemIds);
        newSet.add(id);
        setSelectedItemIds(newSet);
      }
    } else {
      flow(
        () => validateIndexOrder(lastSelectedIndex, actualIndex),
        (boundary) => getDocumentsSpanIds(allOrderedItems, boundary, isSelectableFilter),
        (idsToSelect) => mergeSetWithArray(selectedItemIds, idsToSelect),
        setSelectedItemIds
      )();
    }
  });

  return [deselectItems, isAllSelected, selectAll, onSelectItem, isAnyItemSelected, allOrderedItems?.length] as const;
};

export const useMultiSelect = <T extends { id: Guid }>(
  listRef: RefObject<List>,
  allOrderedItems: T[],
  isSelectableFilter?: (item: T) => boolean,
  defaultSelectedItemsIds?: Set<Guid>
) => {
  const [selectedItemsIds, setSelectedItemsIds] = useState(defaultSelectedItemsIds || new Set<Guid>());

  const [deselectItems, isAllSelected, selectAll, onSelectItem, isAnyItemSelected, totalCount] = useMultiSelectInner(
    listRef,
    allOrderedItems,
    selectedItemsIds,
    setSelectedItemsIds,
    isSelectableFilter
  );

  return [
    selectedItemsIds,
    deselectItems,
    isAllSelected,
    selectAll,
    onSelectItem,
    isAnyItemSelected,
    totalCount,
  ] as const;
};
