import { Tag } from 'antd';
import { ProjectTemplateRoleDto, ProjectTemplateUserDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons/EditButton';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import StackPanel from 'components/StackPanel';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { smartFilter, strCompareCI } from 'utils';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplateRoleFormModal from './ProjectTemplateRoleFormModal';
import styles from './ProjectTemplateRolesTab.module.less';

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
};

const ProjectTemplateRolesTab: FunctionComponent<Props> = ({ intl, projectTemplate, dispatchProjectTemplate }) => {
  const [search, setSearch] = useState<string>();
  const [editedRoleId, setEditedRoleId] = useState<Guid>();
  const [isRoleModalVisible, showRoleModal, hideRoleModal] = useBoolean(false);

  const handleRoleRemove = useCallback(
    (roleId: Guid) => {
      dispatchProjectTemplate({
        type: 'deleteRole',
        roleId: roleId,
      });
    },
    [dispatchProjectTemplate]
  );

  const handleRoleSubmit = useCallback(
    (role: ProjectTemplateRoleDto) => {
      if (!!editedRoleId) {
        dispatchProjectTemplate({
          type: 'updateRole',
          role: role,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addRole',
          role: role,
        });
      }
      setEditedRoleId(undefined);
      hideRoleModal();
    },
    [editedRoleId, dispatchProjectTemplate]
  );

  const showRoleEdit = useCallback((roleId: Guid) => {
    setEditedRoleId(roleId);
    showRoleModal();
  }, []);

  const templateUserMap = useMemo(
    () =>
      projectTemplate.projectTemplateUsers?.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {} as Record<Guid, ProjectTemplateUserDto>
      ),
    [projectTemplate.projectTemplateUsers]
  );

  const handleClearSearch = () => setSearch(undefined);

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !projectTemplate.projectTemplateRoles.some(
        (role) => strCompareCI(role.name, name) === 0 && (!!editedRoleId ? role.id !== editedRoleId : true)
      ),
    [editedRoleId, projectTemplate.projectTemplateRoles]
  );

  const editedRole = useMemo(() => projectTemplate.projectTemplateRoles.find((role) => role.id === editedRoleId), [
    editedRoleId,
    projectTemplate.projectTemplateRoles,
  ]);

  const onCloseModal = useCallback(() => {
    setEditedRoleId(undefined);
    hideRoleModal();
  }, [hideRoleModal]);

  return (
    <StackPanel vertical>
      <PageContent title={<Fmt id={'UserDetailPanel.RolesTab.rolesListTitle'} />}>
        <Panel
          onSearch={setSearch}
          searchValue={search}
          addButtonOnClick={showRoleModal}
          addButtonText={<Fmt id="UserDetailPanel.RolesTab.AddRole" />}
        >
          <GeneralSettingsContainer>
            <List<ProjectTemplateRoleDto>
              data={projectTemplate.projectTemplateRoles}
              search={search}
              filterItem={(item) => item && smartFilter(item?.name, search)}
              renderItem={(item) => (
                <GeneralSettingsItem
                  key={item.id}
                  title={
                    <>
                      {item.name}
                      {!item.templateUserId && (
                        <Tag color="orange" className={styles.warning}>
                          <Fmt id="ProjectTemplateRoleTab.warning.emptyRole" />
                        </Tag>
                      )}
                    </>
                  }
                  description={templateUserMap[item.templateUserId]?.appUserOrganization.appUserProfile.username}
                  additionalActions={
                    <>
                      <DeleteButton onDelete={() => handleRoleRemove(item.id)} type="link" />
                      <EditButton onClick={() => showRoleEdit(item.id)} />
                    </>
                  }
                />
              )}
              renderEmpty={(total, filtered) => (
                <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearSearch} />
              )}
            />
          </GeneralSettingsContainer>
        </Panel>
        {isRoleModalVisible && (
          <ProjectTemplateRoleFormModal
            onSubmit={handleRoleSubmit}
            onClose={onCloseModal}
            templateUsers={projectTemplate.projectTemplateUsers}
            visible={isRoleModalVisible}
            editedRole={editedRole}
            validateUniqueName={validateUniqueName}
          />
        )}
      </PageContent>
    </StackPanel>
  );
};

export default ProjectTemplateRolesTab;
