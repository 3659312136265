import { RoleDto, ServiceError } from 'api/completeApiInterfaces';
import { RoleFormModal } from 'components/forms/RoleForm';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { strCompareCI } from 'utils';
import Panel from '../../Panel';
import RolesList from './RolesList';

type Props = InjectedIntlProps & {
  rolesMap: Record<Guid, RoleDto>;
  rolesList: RoleDto[];
  rolesListLoading: boolean;
  rolesListError: ServiceError;
  selectedId?: Guid;
  onSelect?: (roleId: Guid) => void;
  onAdd?: (roleId: RoleDto) => void;
  onDelete?: (roleId: Guid) => void;
};

const RolesListPanel: FunctionComponent<Props> = ({
  rolesMap,
  rolesList,
  rolesListLoading,
  rolesListError,
  selectedId,
  onSelect,
  onAdd,
  onDelete,
  intl,
}) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formModalId, setFormModalId] = useState<string>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const showModal = (id?: Guid) => {
    setFormModalId(id);
    setFormModalVisible(true);
  };

  const closeModal = () => {
    setFormModalVisible(false);
    setFormModalId(undefined);
    setEditMode(false);
  };

  const handleSubmit = (data: RoleDto) => {
    onAdd?.(data);
    closeModal();
  };

  const handleEdit = (id: Guid) => {
    setEditMode(true);
    showModal(id);
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const checkRoleUniqueName = useCallback(
    (name: string) =>
      rolesList
        ? rolesList.findIndex((r) => strCompareCI(r.name, name) === 0 && (editMode ? r.id !== formModalId : true)) ===
          -1
        : true,
    [rolesList, formModalId, editMode]
  );

  const renderContent = () => {
    if (rolesListError) return <ServiceErrorBox error={rolesListError} />;
    if (rolesListLoading && !rolesList) return <SpinBox />;
    if (rolesList === null) return null;

    return (
      <RolesList
        listData={rolesList}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onEdit={handleEdit}
        onDelete={onDelete}
        onClearSearch={clearSearch}
      />
    );
  };

  return (
    <Panel
      noMargin
      panelWidth="auto"
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showModal}
      addButtonText={<Fmt id="Panel.AddRole.tooltip" />}
    >
      {renderContent()}
      <RoleFormModal
        visible={formModalVisible}
        onSubmit={handleSubmit}
        onClose={closeModal}
        validateUniqueName={checkRoleUniqueName}
        defaults={editMode ? rolesMap[formModalId] : null}
        intl={intl}
      />
    </Panel>
  );
};

export default RolesListPanel;
