import { InputRef } from 'antd';
import { ProjectTemplateCategoryTreeDto } from 'api/completeApiInterfaces';
import DocumentCategoryTreeCreateForm, {
  DocumentCategoryTreeCreateFormData,
} from 'components/forms/DocumentCategoryTreeCreateForm/DocumentCategoryTreeCreateForm';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';

type Props = FormModalProps<ProjectTemplateCategoryTreeDto> & {
  editedCategoryTree?: ProjectTemplateCategoryTreeDto;
  validateUniqueName: (name: string) => boolean;
};

const ProjectTemplateCategoryFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedCategoryTree, validateUniqueName, ...restProps } = props;
  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  const handleSubmit: FormSubmitHandler<DocumentCategoryTreeCreateFormData> = async (values) => {
    onSubmit({
      id: editedCategoryTree?.id || uuid(),
      name: values.name,
      description: values.description,
    });
    return null;
  };

  const isCategoryTreeEditing = !!editedCategoryTree;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isCategoryTreeEditing
          ? 'ProjectTemplateCategoryFormModal.edit.title'
          : 'ProjectTemplateCategoryFormModal.add.title'
      }
      submitTextId={isCategoryTreeEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DocumentCategoryTreeCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          defaults={editedCategoryTree}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default ProjectTemplateCategoryFormModal;
