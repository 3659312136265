import { useCallback, useMemo } from 'react';

type CacheKey = boolean | number | string;

type Cache<C> = { value?: C; children: Record<string, Cache<C>> };

const readCache = <C>(cache: Cache<C>, path: CacheKey[]) => {
  return path.reduce((value, part) => value?.children[typeof part + part], cache)?.value;
};

const writeCache = <C>(cache: Cache<C>, path: CacheKey[], value: C) => {
  const targetObject = path.reduce((currentObject, part) => {
    const key = typeof part + part;
    if (!(key in currentObject.children)) {
      currentObject.children[key] = { children: {} };
    }
    return currentObject.children[key];
  }, cache);
  targetObject.value = value;
};

/**
 * Creates a callback that will cache the results of the `producer` function.
 * @param producer A function whose output will be cached. The first version of this function will always be called until `deps` change.
 * @param deps Dependency list. The cache is discarded upon change,
 *     but the old returned value (if saved elsewhere) will still use the old cache and the old callback.
 * @returns A memoized function whose output is cached, so it will return an identical object for the same input.
 *     The return value will change only on dependency change.
 */
export const useCache = <A extends CacheKey[], C>(producer: (...args: A) => C, deps: React.DependencyList) => {
  const cache = useMemo<Cache<C>>(() => ({ children: {} }), deps);

  return useCallback((...args: A) => {
    const cached = readCache(cache, args);
    if (cached) {
      return cached;
    } else {
      const newValue = producer(...args);
      writeCache(cache, args, newValue);
      return newValue;
    }
  }, deps);
};

/**
 * Simplified variant for `useCache` for callbacks.
 * @param callback Callback to be cached.
 * @returns A "cached callback", where passing arguments returns a (cached) function which, when called, calls `callback` with those arguments.
 */
export const useCachedCallback = <A extends CacheKey[], R>(callback: (...args: A) => R) =>
  useCache((...args: A) => () => callback(...args), [callback]);
