import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './DocumentsGridHeader.module.less';
import { StyledBox } from 'components/StyledBox/StyledBox';

type Props = {
  children: ReactNode;
  noPadding?: boolean;
  marginBottom?: boolean;
  className?: string;
};

const DocumentsGridHeaderStyledComponent: FunctionComponent<Props> = ({
  noPadding,
  children,
  className,
  marginBottom,
}) => {
  if (!children) return null;

  return (
    <StyledBox
      className={classNames(
        styles.documentsHeader,
        noPadding && styles.noPadding,
        marginBottom && styles.marginBottom,
        className
      )}
    >
      {children}
    </StyledBox>
  );
};

export const DocumentsGridHeaderStyled = React.memo(DocumentsGridHeaderStyledComponent);
