import { Col, Row } from 'antd';
import { Fmt } from 'locale';
import React, { Component, ErrorInfo } from 'react';

interface Props {}
interface State {
  error: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (!!this.state.error) {
      return (
        <Row justify="space-around">
          <Col>
            <h1>
              <Fmt id="notifications.error.message" />
            </h1>
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
