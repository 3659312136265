import { Radio, RadioGroupProps } from 'antd';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import styles from './CalendarDaySelect.module.less';

type Props = RadioGroupProps & {};

export const WorkingOrCalendarDaySelect: FunctionComponent<Props> = ({ ...radioGroupProps }) => {
  return (
    <Radio.Group {...radioGroupProps}>
      <Radio value={true} className={styles.radioOption}>
        <Fmt id="CalendarDaySelect.workingDays" />
      </Radio>
      <Radio value={false}>
        <Fmt id="CalendarDaySelect.calendarDays" />
      </Radio>
    </Radio.Group>
  );
};
