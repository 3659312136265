import React, { useCallback } from 'react';
import { ArrayFlagsOperation } from 'utils/arrayHelpers';
import { Fmt } from 'locale';
import SwitchCheck from 'components/SwitchCheck';

type Props<T> = {
  value: T;
  checked: boolean;
  onChange: (values: T[], operation: ArrayFlagsOperation) => void;
  disabled?: boolean;
};

export const ToggleSwitchNode = <T,>(props: Props<T>): JSX.Element => {
  const { value, checked, onChange, disabled } = props;

  const handleChange = useCallback(
    (checked) => onChange([value], checked ? ArrayFlagsOperation.Set : ArrayFlagsOperation.Clear),
    [onChange, value]
  );

  return (
    <SwitchCheck
      disabled={disabled}
      checked={checked}
      label={<Fmt id={checked ? 'general.on' : 'general.off'} />}
      labelOnLeft
      onChange={handleChange}
    />
  );
};
