import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Layout, message, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { api } from 'api';
import {
  ProcessSharedDownloadDto,
  ServiceError,
  SharedDownloadDocumentDto,
  SharedSharedOutputTypeEnum,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import Axios from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DocumentMultipleActionError, { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { createFrontendSingleTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FrontendFilter } from 'components/filters/filterTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import HeaderAppLogo from 'components/HeaderAppLogo';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useApiData, useCancelToken, useFrontendFilters, useSameCallback } from 'hooks';
import { clearAllFilters } from 'hooks/useFilters';
import { Fmt, FmtHTML, InjectedIntl, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FormEvent, FunctionComponent, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { SharedDownloadRouteParams } from 'routes';
import { processApiError } from 'utils';
import { commonPrefixAll } from 'utils/commonPrefix';
import { textComparer } from 'utils/comparators';
import styles from './SharedDownloadPage.module.less';

type Props = RouteComponentProps<SharedDownloadRouteParams> & InjectedIntlProps;

type DataType = SharedDownloadDocumentDto & {
  fullPath: string;
};

type ColumnType = ColumnProps<DataType> & { dataIndex: keyof DataType };

const HEADER_HEIGHT = 54;

const FILTERS: FrontendFilter<DataType>[] = [
  createFrontendSingleTextFilter('fullPath', (row) => row.fullPath, {
    label: <Fmt id="general.path" />,
  }),
];

const getDownloadName = (data: SharedDownloadDocumentDto[], intl: InjectedIntl) => {
  const defaultName = intl.formatMessage({ id: 'general.documents' }) + '.zip';
  if (!data?.length) return defaultName;
  if (data.length === 1) return data[0].documentName + '.zip';

  const commonPath = commonPrefixAll(data.map((row) => row.documentPath));
  const withoutFirstSlash = commonPath.replace(/^\//, '');
  if (!withoutFirstSlash.length) return defaultName;

  return withoutFirstSlash.replace('/', '-');
};

const SharedDownloadPage: FunctionComponent<Props> = (props) => {
  const { intl, match } = props;

  const [name, setName] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [fileError, setFileError] = useState<JSX.Element[]>([]);
  const [downloadError, setDownloadError] = useState<ServiceError>(null);
  const [share, error, loading] = useApiData(
    (ct) => api.project.sharedDownload.getShare(match.params.projectId, match.params.shareId, ct),
    {
      autoload: true,
      fetchCallback: (share) => setName(getDownloadName(share.data, intl)),
    }
  );

  const data = useMemo(
    () =>
      (share?.data || [])
        .map(
          (row): DataType => ({
            ...row,
            fullPath: row.documentPath + '/' + row.documentName,
          })
        )
        .sort(
          textComparer.map((row: DataType) => row.documentPath).andThen(textComparer.map((row) => row.documentName))
        ),
    [share]
  );

  const cancelToken = useCancelToken('ShareDownloadPage: unmounting', []);

  const [downloading, setDownloading] = useState(false);
  const downloadShare = useSameCallback(async () => {
    const data: ProcessSharedDownloadDto = {
      shareId: match.params.shareId,
      pin: pin,
      zipName: name,
      excludeInvalid: fileError.length > 0,
    };

    setDownloading(true);
    const [err, resp] = await api.project.sharedDownload.processShareDownload(
      match.params.projectId,
      data,
      cancelToken
    );
    if (Axios.isCancel(err)) {
      return;
    }
    setDownloading(false);

    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleMoveObstacleError) {
          const errData = error.errorData as IMultipleDocumentErrorData;
          setFileError(errData.documentsErrors.map(transformError));
          setDownloadError(null);
        } else {
          setFileError([]);
          setDownloadError(error);
        }
      });
    } else {
      const { url } = resp.data;
      window.open(url, '_blank');
      message.success(intl.formatMessage({ id: 'SharedDownload.page.ready' }));
    }
  });

  const columns = useMemo(
    (): ColumnType[] => [
      {
        title: intl.formatMessage({ id: 'general.path' }),
        dataIndex: 'documentPath',
      },
      {
        title: intl.formatMessage({ id: 'SharedDownload.page.file' }),
        dataIndex: 'documentName',
      },
      {
        title: intl.formatMessage({ id: 'SharedDownload.page.revision' }),
        dataIndex: 'revisionNumber',
      },
    ],
    [intl]
  );

  const handleChangePin = (e: FormEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setPin(text);
  };

  const handleChangeName = (e: FormEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setName(text);
  };

  const { orderedItems, ...filterProps } = useFrontendFilters(FILTERS, [], data);
  const clearFilters = () => clearAllFilters(filterProps.filters, filterProps.setFilterValue);

  return (
    <StackPanel vertical>
      <Layout.Header className={styles.header}>
        <HeaderAppLogo small={false} className={styles.logo} />
      </Layout.Header>
      <ContentGate error={error} loading={loading} delay={500}>
        <StackPanel vertical scrollable className={styles.content}>
          <h2>
            <Fmt
              id={
                share?.sharedType === SharedSharedOutputTypeEnum.zip
                  ? 'SharedDownload.header.archive'
                  : 'SharedDownload.header.file'
              }
            />
          </h2>

          <FmtHTML id="SharedDownload.page.user" values={{ user: share?.userName, count: share?.data?.length }} />
          <FilterToolbar {...filterProps} />

          <StackPanel vertical className={styles.wrapper}>
            <AutoSizer>
              {(size) => (
                <Table
                  pagination={false}
                  bordered
                  size="small"
                  rowKey="fullPath"
                  columns={columns}
                  dataSource={orderedItems}
                  style={size}
                  scroll={{ y: size.height - HEADER_HEIGHT }}
                  locale={{
                    emptyText: (
                      <ListEmpty filtered={orderedItems.length} total={data.length} onClearSearch={clearFilters} />
                    ),
                  }}
                  className={styles.table}
                />
              )}
            </AutoSizer>
          </StackPanel>

          <Divider />

          {share?.sharedType === SharedSharedOutputTypeEnum.zip && (
            <div className={styles.row}>
              <span className={styles.label}>
                <Fmt id="SharedDownload.page.label.name" />
              </span>
              <Input className={styles.name} maxLength={64} value={name} onChange={handleChangeName} />
            </div>
          )}
          <ServiceErrorBox error={downloadError} />
          {fileError.length > 0 && (
            <div className={styles.error}>
              <DocumentMultipleActionError errors={fileError} titleId={'SharedDownload.page.error.obstacle'} />
            </div>
          )}
          <div className={styles.row}>
            {share?.sharedType === SharedSharedOutputTypeEnum.zip && (
              <span className={styles.label}>
                <Fmt id="SharedDownload.page.label.Pin" />
              </span>
            )}
            <Input
              className={styles.pin}
              maxLength={12}
              value={pin}
              placeholder={intl.formatMessage({ id: 'SharedDownload.page.pin.placeholder' })}
              onChange={handleChangePin}
            />
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={downloading}
              onClick={downloadShare}
              className={styles.download}
            >
              {fileError.length > 0 ? (
                <Fmt id="SharedDownload.page.button.download.Others" />
              ) : (
                <Fmt id="SharedDownload.page.button.download" />
              )}
            </Button>
          </div>
        </StackPanel>
      </ContentGate>
    </StackPanel>
  );
};

export default memoizeWithIntl(SharedDownloadPage);
