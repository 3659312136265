import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import styles from './SettingsItem.module.less';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';

export interface ConfirmOptions {
  title: ReactNode;
  okText: ReactNode;
  cancelText: ReactNode;
}

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  InjectedIntlProps & {
    headline: ReactNode;
    editTooltip?: ReactNode;
    editingChildren?: ReactNode;
    onSave?: () => void;
    onCancel?: () => void;
    onEdit?: () => void;
    saving?: boolean;
    editMode?: boolean;
    errorMessage?: ReactNode;
    settingsIcon?: ReactNode;
    easyMode?: boolean;
    disableEdit?: boolean;
    disableEnter?: boolean;
    disableKeyboard?: boolean;
    hideEdit?: boolean;
    confirmOptions?: ConfirmOptions;
  };

const SettingsItem: FunctionComponent<Props> = ({
  intl,
  editingChildren,
  settingsIcon,
  onSave,
  editMode,
  onCancel,
  onEdit,
  saving,
  errorMessage,
  children,
  easyMode,
  headline,
  editTooltip,
  disableEdit,
  disableEnter,
  disableKeyboard,
  hideEdit,
  confirmOptions,
}) => {
  const containerRef = useRef(null);
  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        onCancel();
        break;
      case 'Enter':
        !disableEnter && onSave();
        break;
    }
  };

  useEffect(() => {
    if (!disableEdit && !hideEdit && editMode && !disableKeyboard && !confirmOptions) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [handleKeyDown, disableEdit, hideEdit, editMode, confirmOptions]);

  const editButton = onEdit ? (
    <Button type="link" onClick={onEdit} disabled={disableEdit}>
      {settingsIcon ? settingsIcon : <EditIcon onClick={onEdit} />}
    </Button>
  ) : null;

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.title}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.spacer} />
        {!hideEdit ? (
          saving ? (
            <Button type="link">
              <LoadingOutlined spin />
            </Button>
          ) : editMode && !easyMode ? (
            <>
              {confirmOptions ? (
                <Popconfirm
                  title={confirmOptions.title}
                  onConfirm={onSave}
                  okText={confirmOptions.okText}
                  cancelText={confirmOptions.cancelText}
                >
                  <Button type="link">
                    <CheckOutlined />
                  </Button>
                </Popconfirm>
              ) : (
                <Button type="link" onClick={onSave} title={intl.formatMessage({ id: 'forms.button.submit' })}>
                  <CheckOutlined />
                </Button>
              )}
              <Button type="link" onClick={onCancel} title={intl.formatMessage({ id: 'forms.button.cancel' })}>
                <CloseOutlined />
              </Button>
            </>
          ) : editTooltip ? (
            <CommonHubTooltip title={editTooltip}>
              {editButton}
            </CommonHubTooltip>
          ) : (
            editButton
          )
        ) : null}
      </div>
      <div className={styles.content}>
        {editMode ? editingChildren : children}
        {errorMessage && (
          <div>
            <Typography.Text type="danger" className={styles.errorMessage}>
              {errorMessage}
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(SettingsItem);
