import { CalendarDto, CalendarSetDto, ExcludedDayDto, ExcludedDaySetDto } from 'api/completeApiInterfaces';
import moment, { Moment } from 'moment';
import { CalendarDaysKeys } from './CalendarSettingsForm';

export const compareExcludedDays = (excludedDay: ExcludedDaySetDto, comparedDay: ExcludedDaySetDto) => {
  return (
    excludedDay.day === comparedDay.day &&
    excludedDay.month === comparedDay.month &&
    (excludedDay.year === comparedDay.year || !excludedDay.year || !comparedDay.year) &&
    excludedDay.name === comparedDay.name
  );
};

export const getExcludeDayFormat = (excludeDayYear?: number) => {
  return !!excludeDayYear ? 'L' : 'DD.MM.';
};

export const momentFromExcludedDay = (excludedDay: ExcludedDaySetDto | ExcludedDayDto) =>
  moment({
    year: excludedDay.year || moment().year(),
    month: excludedDay.month - 1,
    days: excludedDay.day,
  });

export const excludedDaySorter = (a: ExcludedDaySetDto, b: ExcludedDaySetDto) => {
  const currentYear = moment().year();
  return (a.year || currentYear) - (b.year || currentYear) || a.month - b.month || a.day - b.day;
};

export const filterSelectedDay = (excludedDays: ExcludedDaySetDto[], selectedDay: ExcludedDaySetDto) =>
  excludedDays.filter((excludedDay) => !compareExcludedDays(excludedDay, selectedDay));

export const getDefaultCalendar = (organizationId: Guid, projectId?: Guid): CalendarSetDto => ({
  mo: false,
  tu: false,
  we: false,
  th: false,
  fr: false,
  sa: false,
  su: false,
  organizationId: organizationId,
  projectId: projectId,
  excludedDays: [],
});

export const weekDayToPropNameMap: Record<number, CalendarDaysKeys> = {
  0: 'mo',
  1: 'tu',
  2: 'we',
  3: 'th',
  4: 'fr',
  5: 'sa',
  6: 'su',
};

export const isWorkingDay = (current: Moment, calendar: CalendarDto) =>
  !!calendar ? calendar[weekDayToPropNameMap[current.weekday()]] : true;

/// Get holiday for given date or undefined if no holiday exist for given day
export const getHoliday = (current: Moment, calendar: CalendarDto) =>
  calendar?.excludedDays?.find(
    (excludedDay) =>
      current.date() === excludedDay.day &&
      current.month() + 1 === excludedDay.month &&
      (!excludedDay.year || current.year() === excludedDay.year)
  );

export const getCalendarDaysFromWorkingDays = (startDate: Moment, workingDays: number, activeCalendar: CalendarDto) => {
  let daysAdded = 0;
  let workingDaysAdded = 0;
  let currentDay = startDate;
  while (workingDaysAdded < workingDays) {
    currentDay = currentDay.add(1, 'day');
    const isHoliday = !!getHoliday(currentDay, activeCalendar);
    if (isWorkingDay(currentDay, activeCalendar) && !isHoliday) {
      workingDaysAdded++;
    }
    daysAdded++;
  }
  return daysAdded;
};
