import { InputRef } from 'antd';
import { SideMenuKey } from 'components/SideMenuActivator';
import { useSameCallback, useStoreSelector } from 'hooks';
import React, { ChangeEvent, createContext, FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { RedirectOption, redirectWithOption } from 'utils';

type Props = {
  projectId: Guid;
};

type ValueType = {
  searchText: string;
  isOnSearchPage: boolean;
  inputRef: React.RefObject<InputRef>;
  focused: boolean;
  handleSearch: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  enterSearchMode: () => void;
  leaveSearchMode: () => void;
  toggleSearchMode: () => void;
};

export const HeaderSearchContext = createContext<ValueType>(undefined);

export const HeaderSearchContextProvider: FunctionComponent<Props> = ({ children, projectId }) => {
  const [searchText, setSearchedText] = useState('');
  const [focused, setFocused] = useState(false);

  const inputRef = useRef<InputRef>();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchedText(event.target.value);
  }, []);

  const enterSearchMode = useCallback(() => {
    setFocused(true);
    inputRef.current?.focus();
  }, []);

  const leaveSearchMode = useCallback(() => {
    setFocused(false);
    setSearchedText('');
  }, []);

  const toggleSearchMode = useCallback(() => {
    if (focused) {
      leaveSearchMode();
    } else {
      enterSearchMode();
    }
  }, [leaveSearchMode, enterSearchMode, focused]);

  const handleSearch = useSameCallback(() => {
    if (!searchText) {
      return;
    }
    leaveSearchMode();

    const url = `/projects/${projectId}/search?query=${encodeURIComponent(searchText)}`;
    redirectWithOption(url, RedirectOption.Push);
  });

  const activeMenuKey = useStoreSelector((state) => state.appConfig.sideMenuActiveKey);
  const isOnSearchPage = activeMenuKey === SideMenuKey.SEARCH;

  const values: ValueType = useMemo(
    () => ({
      isOnSearchPage,
      searchText,
      inputRef,
      focused,
      handleSearch,
      onChange,
      enterSearchMode,
      leaveSearchMode,
      toggleSearchMode,
    }),
    [
      isOnSearchPage,
      inputRef,
      searchText,
      focused,
      handleSearch,
      onChange,
      enterSearchMode,
      leaveSearchMode,
      toggleSearchMode,
    ]
  );

  return <HeaderSearchContext.Provider value={values}>{children}</HeaderSearchContext.Provider>;
};
