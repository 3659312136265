import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useHeight } from 'hooks/useHeight';
import React, { ReactNode, useMemo } from 'react';

export const useMasterDetailView = (
  masterContent: ReactNode,
  detailContent: ReactNode,
  masterName: ReactNode,
  detailName: ReactNode,
  onBack: () => void,
  threshold = 800
) => {
  const { onResize, tableWrapRef, wrapHeight, wrapWidth } = useHeight();

  const showOnlyDetail = !!detailContent && wrapWidth < threshold;

  const title = useMemo(
    () => (
      <FlowLayout>
        {!!showOnlyDetail && (
          <div>
            <Button type="primary" icon={<ArrowLeftOutlined />} onClick={onBack} />
          </div>
        )}
        {masterName}
        {!!detailContent && (
          <>
            <div>/</div>
            <CommonHubEllipsisText title={detailName}>{detailName}</CommonHubEllipsisText>
          </>
        )}
      </FlowLayout>
    ),
    [showOnlyDetail, onBack, masterName, detailName, !!detailContent]
  );

  const children = useMemo(() => {
    return (
      <>
        {!showOnlyDetail && masterContent}
        {detailContent}
      </>
    );
  }, [showOnlyDetail, masterContent, detailContent]);

  return { children, title, tableWrapRef } as const;
};
