import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode } from 'react';

export type ActionButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
};

type Props = ActionButtonProps & {
  icon: ReactNode;
  messageId: IntlMessageId;
};

const ActionButton: FunctionComponent<Props> = ({ disabled, messageId, onClick, icon, children }) => {
  const intl = useIntl();

  return (
    <CommonHubTooltip title={intl.formatHTMLMessage({ id: messageId })}>
      <Button
        onClick={(event) => {
          event.stopPropagation();
          onClick?.();
        }}
        icon={icon}
        type="link"
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        {children}
      </Button>
    </CommonHubTooltip>
  );
};

export default React.memo(ActionButton);
