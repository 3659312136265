import { IntlMessageId } from 'locale/messages/cs';
import { CheckFormat, CheckFormatError } from 'utils';

export const paramEncode = <T = unknown>(data: T) => encodeURIComponent(JSON.stringify(data));

export enum ParamDecodeErrorEnum {
  URL_PARSE_ERROR,
  JSON_PARSE_ERROR,
  CHECK_TYPE_ERROR,
}

export type ParamDecodeError =
  | {
      type: ParamDecodeErrorEnum.URL_PARSE_ERROR;
      intlId: IntlMessageId;
      argument: string;
      exception: Error;
    }
  | {
      type: ParamDecodeErrorEnum.JSON_PARSE_ERROR;
      intlId: IntlMessageId;
      argument: string;
      exception: Error;
    }
  | {
      type: ParamDecodeErrorEnum.CHECK_TYPE_ERROR;
      intlId: IntlMessageId;
      argument: any;
      exception: CheckFormatError;
    };

export const paramDecode = <T = unknown>(
  param: string,
  checkFormat?: CheckFormat<T>,
  alternative?: T
): [ParamDecodeError, T] => {
  let urlDecoded: string;
  try {
    urlDecoded = decodeURIComponent(param);
  } catch (e) {
    const error = e as Error;
    return [
      {
        type: ParamDecodeErrorEnum.URL_PARSE_ERROR,
        intlId: 'paramEncode.error.urlParse',
        argument: param,
        exception: error,
      },
      alternative,
    ];
  }

  return jsonDecode<T>(urlDecoded, checkFormat, alternative);
};

export const jsonDecode = <T = unknown>(
  jsonText: string,
  checkFormat?: CheckFormat<T>,
  alternative?: T
): [ParamDecodeError, T] => {
  let jsonDecoded: any;
  try {
    jsonDecoded = JSON.parse(jsonText);
  } catch (e) {
    const error = e as Error;
    return [
      {
        type: ParamDecodeErrorEnum.JSON_PARSE_ERROR,
        intlId: 'paramEncode.error.jsonParse',
        argument: jsonText,
        exception: error,
      },
      alternative,
    ];
  }

  if (checkFormat) {
    try {
      checkFormat(jsonDecoded);
    } catch (e) {
      const error = e as CheckFormatError;
      return [
        {
          type: ParamDecodeErrorEnum.CHECK_TYPE_ERROR,
          intlId: 'paramEncode.error.checkFormat',
          argument: jsonDecoded,
          exception: error,
        },
        alternative,
      ];
    }
  }

  return [null, jsonDecoded];
};
