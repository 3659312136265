import { BackendFilter, FrontendFilter } from 'components/filters/filterTypes';
import { Fmt, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { IOption } from '../../../SelectFilter/SelectFilter';
import { RadioFilter, RadioFilterValue, createBaseRadioFilter, createRadioFilterFunction } from '../../RadioFilter';

export enum BooleanOption {
  True = 'true',
  False = 'false',
}

type Props = InjectedIntlProps & {
  label: ReactNode;
  title?: ReactNode;
  value: BooleanOption;
  onChange: React.Dispatch<React.SetStateAction<BooleanOption>>;
  trueLabel?: ReactNode;
  trueTitle?: string;
  falseLabel?: ReactNode;
  falseTitle?: string;
};

type BooleanFilterType = {
  key: string;
  label: ReactNode;
  default?: boolean;
};

export type BooleanBackendFilterType<T> = BooleanFilterType & {
  serialize: (accumulator: T, value: RadioFilterValue<BooleanOption>) => T;
};

export const BooleanFilterComponent: FunctionComponent<Props> = ({
  intl,
  value,
  onChange,
  label,
  title,
  trueLabel,
  trueTitle,
  falseLabel,
  falseTitle,
}) => {
  const options = useMemo<IOption<BooleanOption>[]>(
    () => [
      {
        id: BooleanOption.True,
        title: trueTitle || intl.formatMessage({ id: 'general.yes' }),
        label: trueLabel || <Fmt id="general.yes" />,
      },
      {
        id: BooleanOption.False,
        title: falseTitle || intl.formatMessage({ id: 'general.no' }),
        label: falseLabel || <Fmt id="general.no" />,
      },
    ],
    [intl, trueTitle, trueLabel, falseTitle, falseLabel]
  );

  return <RadioFilter label={label} title={title} options={options} value={value} onChange={onChange} />;
};

export const BooleanFilter = memoizeWithIntl(BooleanFilterComponent);

type AdditionalProps = Omit<Props, 'value' | 'onChange' | 'intl'>;

export const createFrontendBooleanFilter = <T,>(
  key: string,
  props: AdditionalProps,
  valueSelector: (item: T) => BooleanOption
): FrontendFilter<T, RadioFilterValue<BooleanOption>> => ({
  ...createBaseRadioFilter(key),
  render: (value, setValue) => <BooleanFilter value={value} onChange={setValue} {...props} />,
  filter: createRadioFilterFunction(valueSelector),
});

export const createBackendBooleanFilter = <T,>(
  key: string,
  props: AdditionalProps,
  serialize: (accumulator: T, value: RadioFilterValue<BooleanOption>) => T
): BackendFilter<T, RadioFilterValue<BooleanOption>> => ({
  ...createBaseRadioFilter(key),
  render: (value, setValue) => <BooleanFilter value={value} onChange={setValue} {...props} />,
  serialize,
});
