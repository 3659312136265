import { FolderOutlined, HddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MasterDetailsViewContext } from 'components/MasterDetailsView/MasterDetailsView';
import { PathPart, ShrinkablePath } from 'components/ShrinkablePath/ShrinkablePath';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import * as lodash from 'lodash';
import React, { FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { directoryConnectedMapSelector, directoryRootSelector } from 'store/selectors';
import { getDefaultRootName } from 'utils/getRootName';
import styles from './DocumentsHeader.module.less';

type DocumentsHeaderProps = {
  directoryPath: string;
  directoryId: Guid;
  projectId: Guid;
  buttons?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const getPathParts = (path: string): string[] => {
  return lodash
    .trim(path, '/')
    .split('/')
    .filter((part) => part && part.length > 0);
};

type DirectoryWithParent = {
  id: string;
  name?: string;
  description?: string;
  parent?: DirectoryWithParent;
};

const getParentDirectory = (parentCount: number, currentDirectory: DirectoryWithParent) => {
  for (let i = 0; i < parentCount; i++) {
    currentDirectory = currentDirectory?.parent;
  }
  return currentDirectory;
};

export const getPathPartsFromPath = (
  pathParts: string[],
  currentDirectory: DirectoryWithParent,
  url: string = './',
  lastStrong: boolean = true
): PathPart[] =>
  pathParts.map((part: string, index: number) => {
    const goBacks = pathParts.length - 1;
    const dirPath = '/' + pathParts.slice(0, index + 1).join('/');
    const parentDirectory = getParentDirectory(goBacks - index, currentDirectory);

    const result: PathPart = {
      title: part,
      path: dirPath,
      link: parentDirectory?.id ? `${url}${parentDirectory?.id}` : undefined,
      description: parentDirectory?.description,
      label: part,
      key: part + index,
      strong: lastStrong && index === goBacks,
      icon: index === 0 ? <HddOutlined /> : undefined,
    };

    return result;
  });

export const DocumentsHeader: FunctionComponent<DocumentsHeaderProps> = ({
  directoryPath,
  directoryId,
  buttons,
  projectId,
}) => {
  const url = `/projects/${projectId}/directories/`;

  const rootNode = useSelectorDispatch(directoryRootSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  const directoryTree = useSelector(directoryConnectedMapSelector);
  const currentDirectory = directoryTree[directoryId];

  const parts = useMemo(() => {
    return getPathPartsFromPath([getDefaultRootName(rootNode), ...getPathParts(directoryPath)], currentDirectory, url);
  }, [directoryPath, rootNode, currentDirectory, url]);

  const masterDetailsViewContext = useContext(MasterDetailsViewContext);

  return (
    <div className={styles.titlePanel}>
      {masterDetailsViewContext?.showBackButton && (
        <Button
          type="link"
          size="small"
          onClick={() => {
            masterDetailsViewContext?.onBackButtonClick();
          }}
          icon={<FolderOutlined />}
        />
      )}
      <div className={styles.docsToolbarTitle}>
        {directoryPath && directoryId && <ShrinkablePath key={directoryId} parts={parts} rootNode={rootNode} />}
      </div>
      {buttons && <div>{buttons}</div>}
    </div>
  );
};

export default DocumentsHeader;
