import React, { Reducer, useCallback } from 'react';

/**
 * Converts setState to a dispatch using a reducer function
 * @param setState state setter from a setState hook
 * @param reducer reducer function that you would pass to the useDispatch hook
 * @returns a dispatch function, same as useDispatch would return
 */
export const useSetStateToDispatch = <S, T>(
  setState: React.Dispatch<React.SetStateAction<S>>,
  reducer: Reducer<S, T>
) => useCallback((action: T) => setState((state) => reducer(state, action)), [setState, reducer]);
