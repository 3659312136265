import { api } from 'api';
import { CommentProcedureProjectSettingsDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import SwitchCheck from 'components/SwitchCheck';
import { useApiData, useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { messageError } from 'utils';

type CommentProcedureProjectSettingsKeys = keyof CommentProcedureProjectSettingsDto;

export const CommentProcedureRolesSettings: FunctionComponent = () => {
  const intl = useIntl();
  const currentProjectUser = useCurrentProjectUser();

  const [saving, setSaving] = useState(false);

  const [data, error, loading, _reload, setData] = useApiData(api.project.projectSetting.getCommentProcedureSettings, {
    autoload: true,
  });

  const handleSave = async (values: CommentProcedureProjectSettingsDto) => {
    setSaving(true);
    const [err, resp] = await api.project.projectSetting.patchCommentProcedureSettings(values);
    setSaving(false);

    if (err) {
      messageError(err, intl);
      return;
    }

    setData(resp.data);
  };

  const renderOption = (key: CommentProcedureProjectSettingsKeys, label: ReactNode, disabled: boolean) => {
    const value = data?.[key];
    return (
      <GeneralSettingsItem
        title={label}
        disabled={disabled}
        input={
          <SwitchCheck
            disabled={disabled || value === undefined}
            checked={value}
            onChange={(value) => {
              handleSave({ ...data, [key]: value });
            }}
            label={value ? <Fmt id="general.on" /> : <Fmt id="general.off" />}
            labelOnLeft
          />
        }
      />
    );
  };

  const disabled = saving || !currentProjectUser.isAdmin;

  return (
    <ContentGate error={error} loading={loading}>
      <GeneralSettingsContainer title={<Fmt id="CommentProcedureRolesSettings.title" />}>
        {renderOption(
          'primaryProcessorCanEditSubmitterTeams',
          <Fmt id="CommentProcedureRolesSettings.mainProcessorCanEditSubmitterTeams" />,
          disabled
        )}
        {renderOption(
          'primarySubmitterCanEditProcessorTeams',
          <Fmt id="CommentProcedureRolesSettings.mainSubmitterCanEditProcessorTeams" />,
          disabled
        )}
        {renderOption('useWorkDays', <Fmt id="CommentProcedureRolesSettings.useWorkDays" />, disabled)}
      </GeneralSettingsContainer>
    </ContentGate>
  );
};
