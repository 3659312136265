import classnames from 'classnames';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import { MasterDetailContent } from 'components/MasterDetailsView/MasterDetailContent/MasterDetailContent';
import { MasterDetailHeader } from 'components/MasterDetailsView/MasterDetailHeader/MasterDetailHeader';
import StackPanel from 'components/StackPanel';
import { useActiveProjectNameToHelmet } from 'hooks/useActiveProjectNameToHelmet';
import { useHeight } from 'hooks/useHeight';
import React, { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { getPathsParts } from 'utils/urlPaths';
import styles from './MasterDetailsView.module.less';

type Props = { threshold?: number };

type PartType = {
  key: string;
  title: ReactNode;
  minWidth?: number;
  onBack?: () => void;
};

type ContextType = {
  parts: Record<string, PartType>;
  registerPart: (baseUrl: string, part: PartType) => void;
  getSelectedItemId: (url: string) => Guid;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  label?: ReactNode;
  title?: string;
  helmetText?: string;
  tableWrapRef?: React.RefObject<HTMLDivElement>;
  getIsPartVisible?: (url: string) => boolean;
  showFirstPart?: boolean;
  setShowFirstPart?: (show: boolean) => void;
};

export const MasterDetailsViewContext = React.createContext<ContextType>({
  parts: {},
  registerPart: () => {},
  getSelectedItemId: () => undefined,
});

const MIN_WIDTH = 500;

export const MasterDetailsViewContextProvider: FunctionComponent<Props> = ({ children }) => {
  const { tableWrapRef, wrapWidth } = useHeight();
  const location = useLocation();

  const helmetSuffix = useActiveProjectNameToHelmet();

  const [parts, setParts] = useState<Record<string, PartType>>({});
  const [showFirstPart, setShowFirstPart] = useState(false);

  const getIsPartVisible = useCallback(
    (url: string): boolean => {
      const partsValues = Object.values(parts);
      const partsLength = partsValues.length;
      const partIndex = partsValues.findIndex((p) => p.key === url);

      if (partIndex === -1) return false;

      if (showFirstPart) return !partIndex;

      const firstPartLength: number = !!showFirstPart ? partsValues[0].minWidth || MIN_WIDTH : 0;

      const isLast = partsLength === partIndex + 1;

      const threshold = partsValues.reduceRight((acc, p, index) => {
        if (index < partIndex) return acc;
        return acc + (p.minWidth || MIN_WIDTH);
      }, 0);

      return threshold < wrapWidth - firstPartLength || (isLast && !showFirstPart);
    },
    [parts, wrapWidth, showFirstPart]
  );

  const visibleParts = useMemo(() => {
    const partsValues = Object.values(parts);
    return partsValues.filter((p) => getIsPartVisible(p.key));
  }, [parts, getIsPartVisible]);

  const registerPart = useCallback((baseUrl: string, part: PartType) => {
    setParts((parts) => {
      const newParts = { ...parts };
      newParts[baseUrl] = part;
      if (part == null) {
        delete newParts[baseUrl];
      }
      return newParts;
    });
  }, []);

  const getSelectedItemId = useCallback(
    (url: string) => {
      const locationPathname = location.pathname.replace(url, '');
      return getPathsParts(locationPathname)?.[0];
    },
    [location?.pathname]
  );

  const showBackButton = useMemo(() => {
    const partsValues = Object.values(parts);
    const visibleParts = partsValues.filter((p) => getIsPartVisible(p.key));
    return visibleParts.length < partsValues.length;
  }, [visibleParts]);

  const onBackButtonClick = useCallback(() => {
    const activeParts = Object.values(parts);
    const lastPart = activeParts[activeParts.length - 2];
    lastPart?.onBack();
    if (activeParts.length - 2 <= 0) {
      setShowFirstPart((showFirstPart) => true);
    }
  }, [parts]);

  const label = useMemo(
    () => (
      <>
        {Object.values(parts)
          .map((p) => p.title || null)
          .filter(Boolean)}
      </>
    ),
    [parts]
  );

  const title = Object.values(parts)
    .map((p) => p.title)
    .filter(Boolean)
    .join(' / ');

  const helmetText = title + helmetSuffix;

  const values = useMemo(
    () => ({
      parts,
      registerPart,
      getSelectedItemId,
      tableWrapRef,
      onBackButtonClick,
      title,
      helmetText,
      label,
      showBackButton,
      getIsPartVisible,
      showFirstPart,
      setShowFirstPart,
    }),
    [
      parts,
      registerPart,
      getSelectedItemId,
      tableWrapRef,
      onBackButtonClick,
      title,
      helmetText,
      label,
      showBackButton,
      getIsPartVisible,
      showFirstPart,
    ]
  );

  return <MasterDetailsViewContext.Provider value={values}>{children}</MasterDetailsViewContext.Provider>;
};

type MasterDetailsContainerProps = {
  className?: string;
  hideTitle?: boolean;
};

export const MasterDetailsContainer: FunctionComponent<MasterDetailsContainerProps> = ({
  children,
  className,
  hideTitle,
}) => {
  const masterDetailsViewContext = useContext(MasterDetailsViewContext);

  return (
    <StackPanel
      vertical
      divRef={masterDetailsViewContext.tableWrapRef}
      className={classnames(styles.wrapper, className)}
    >
      <Helmet title={masterDetailsViewContext.helmetText} />
      {!hideTitle && (
        <DocumentsGridHeaderStyled marginBottom>
          <MasterDetailHeader />
        </DocumentsGridHeaderStyled>
      )}
      <MasterDetailContent>{children}</MasterDetailContent>
    </StackPanel>
  );
};

type MasterComponentProps = {
  url: string;
  children: (onClick: (itemId: Guid) => void, selectedItemId: Guid) => ReactNode;
  title?: ReactNode;
  maxWidth?: number | null;
  minWidth?: number;
  noFlex?: boolean;
  className?: string;
};
const useMasterDetailsViewContext = (url: string, title: ReactNode | undefined, minWidth?: number) => {
  const history = useHistory();
  const masterDetailsViewContext = useContext(MasterDetailsViewContext);

  useEffect(() => {
    masterDetailsViewContext.registerPart(url, {
      key: url,
      title: title,
      minWidth: minWidth,
      onBack: () => {
        history.push(url);
      },
    });
    return () => {
      // unregister
      masterDetailsViewContext.registerPart(url, null);
    };
  }, [url, masterDetailsViewContext?.registerPart, title]);

  const selectedItemId = masterDetailsViewContext.getSelectedItemId(url);

  const onSelect = useCallback(
    (itemId) => {
      history.push(`${url}/${itemId}${history.location.search}`);
      masterDetailsViewContext.setShowFirstPart(false);
    },
    [url, history]
  );

  const isPartVisible = masterDetailsViewContext.getIsPartVisible(url);

  return [onSelect, selectedItemId, isPartVisible] as const;
};

const ITEM_MAX_WIDTH = 800;

const MasterComponentNoMemo: FunctionComponent<MasterComponentProps> = ({
  url,
  children,
  title,
  maxWidth = ITEM_MAX_WIDTH,
  minWidth,
  noFlex,
  className,
}) => {
  const [onSelect, selectedItemId, isPartVisible] = useMasterDetailsViewContext(url, title, minWidth);

  const style = useMemo(() => {
    return {
      maxWidth: maxWidth ? `${maxWidth}px` : undefined,
      minWidth: minWidth != null ? `${minWidth}px` : undefined,
    };
  }, [maxWidth, minWidth]);

  const childrenElement = useMemo(() => children?.(onSelect, selectedItemId), [children, onSelect, selectedItemId]);
  const masterDetailsViewContext = useContext(MasterDetailsViewContext);
  const showFirstPart = masterDetailsViewContext.showFirstPart;

  return (
    <div
      className={classnames(
        styles.masterItem,
        !isPartVisible && styles.hideItem,
        noFlex && styles.noFlex,
        showFirstPart && styles.showFirstPart,
        className
      )}
      style={style}
    >
      {childrenElement}
    </div>
  );
};

export const MasterComponent = React.memo(MasterComponentNoMemo);
