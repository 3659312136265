import { EstiConDphZemeDto, EstiConProjectDto, EstiConProjectStructureCreation } from 'api/completeApiInterfaces';
import { useSelectorDispatch } from 'hooks';
import { Currency, DEFAULT_CURRENCY } from 'hooks/useCurrency';
import { useMemo } from 'react';
import { getNumberFormatSettings, GridNumberFormats } from 'utils/buildingGridUtils';
import { useActiveProject } from './useActiveProject';

export type Precisions = {
  price: number;
  unitPrice: number;
  amount: number;
  percent: number;
  percentPrecise: number;
};

export const PERCENT_PRECISIONS = 2;
export const PERCENT_PRECISIONS_PRECISE = 4;

export interface EsticonAttributes {
  numberFormats: GridNumberFormats | undefined;
  precisions: Precisions;
  projectStructureType: EstiConProjectStructureCreation;
  currency: Currency;
  esticonProject: EstiConProjectDto;
  vatData: EstiConDphZemeDto[];
}

export function useEsticon() {
  const { esticonFirmId, esticonProjectId } = useActiveProject();

  const esticonDetailState = useSelectorDispatch(
    (store) => store.esticonDetail,
    (dispatch) => esticonProjectId && dispatch.esticonDetail.loadData({ reload: false, data: esticonProjectId }),
    [esticonProjectId]
  );

  const esticonVatState = useSelectorDispatch(
    (store) => store.esticonVat,
    (dispatch) => dispatch.esticonVat.loadData({ reload: false }),
    []
  );

  const esticon: EsticonAttributes = useMemo(() => {
    if (!esticonDetailState.data) return undefined;
    const settings = esticonDetailState.data.settings;
    const precisions = settings
      ? {
          price: settings.zaokrouhleniCena,
          unitPrice: settings.zaokrouhleniCenaJednotkova,
          amount: settings.zaokrouhleniMnozstvi,
          percent: PERCENT_PRECISIONS,
          percentPrecise: PERCENT_PRECISIONS_PRECISE,
        }
      : undefined;

    const numberFormats = getNumberFormatSettings(precisions);

    const result: EsticonAttributes = {
      numberFormats,
      precisions,
      projectStructureType: esticonDetailState.data.settings?.tvorbaStruktury,
      currency: DEFAULT_CURRENCY, // TODO
      vatData: esticonVatState.data,
      esticonProject: esticonDetailState.data,
    };
    return result;
  }, [esticonDetailState, esticonVatState, esticonProjectId]);

  const loading = esticonDetailState.loading || esticonVatState.loading;

  return useMemo(
    () => ({
      esticon,
      loading,
      hasProjectSet: !!esticonFirmId && !!esticonProjectId,
      esticonVatError: esticonVatState.error,
      esticonProjectError: esticonDetailState.error,
    }),
    [esticon, loading, esticonFirmId, esticonProjectId, esticonDetailState, esticonVatState]
  );
}
