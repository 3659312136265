import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classnames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import styles from './ShrinkableToolbar.module.less';

type Props = {
  items?: ToolbarItemProps[];
  selectable?: boolean;
  selectedKeys?: string[];

  right?: boolean;
};

export interface ToolbarSubItem {
  key: string;
  icon?: ReactNode;
  label: IntlMessageId;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: ReactNode;
  subItems?: ToolbarSubItem[];
}

export type ToolbarItemProps = ToolbarSubItem & {
  subItems?: ToolbarSubItem[];
};

export const mapMenuItems = (items: ToolbarItemProps[], intl: InjectedIntl): ItemType[] => {
  return items.map(
    (item) =>
      ({
        key: item.key,
        label: (
          <CommonHubTooltip title={item.tooltip || intl.formatMessage({ id: item.label })}>
            <span>
              {intl.formatMessage({ id: item.label })} {item.subItems && <DownOutlined className={styles.down} />}
            </span>
          </CommonHubTooltip>
        ),
        icon: (
          <CommonHubTooltip title={item.tooltip || intl.formatMessage({ id: item.label })}>
            {item.icon}
          </CommonHubTooltip>
        ),
        onClick: item.onClick,
        disabled: item.disabled,
        children: item.subItems ? mapMenuItems(item.subItems, intl) : undefined,
      } as ItemType)
  );
};

const ShrinkableToolbar: FunctionComponent<Props> = ({ items, selectable = false, selectedKeys, right }) => {
  const intl = useIntl();

  const menuItems = useMemo(() => mapMenuItems(items, intl), [items, intl]);

  return (
    <Menu
      mode="horizontal"
      items={menuItems}
      selectable={selectable}
      selectedKeys={selectedKeys}
      className={classnames(styles.menu, right && styles.right)}
      subMenuOpenDelay={0.3}
      triggerSubMenuAction="click"
      overflowedIndicator={<Button shape="circle" icon={<EllipsisOutlined rotate={90} />} />}
    />
  );
};

export default ShrinkableToolbar;
