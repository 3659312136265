import { api } from 'api';
import { EntityTypesEnum, ProjectUserProfileListDto, ServiceError } from 'api/completeApiInterfaces';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import List from 'components/List';
import ServiceErrorBox from 'components/ServiceErrorBox';
import UserStatus from 'components/UserStatus/UserStatus';
import { useBoolean, useCurrentProjectUser, useDeleteItems, useIsMounted, useVisibleState } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError, smartFilter } from 'utils';
import GroupUserAddFormModal from './GroupUserAddFormModal';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { AuditLogButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';

type Props = InjectedIntlProps & {
  usersList: ProjectUserProfileListDto[];
  groupUsers: ProjectUserProfileListDto[];
  groupId: Guid;
  isAdminGroup?: boolean;
  error?: ServiceError;
  onDelete?: (id: string) => void;
  onUsersAdd?: (userIds: Guid[]) => void;
};

const filterByName = (search: string) => (item: ProjectUserProfileListDto) =>
  smartFilter(item.username + '\n' + (item.firstname || '') + ' ' + (item.lastname || ''), search);

const UserTabComponent: FunctionComponent<Props> = ({
  intl,
  usersList,
  groupUsers,
  isAdminGroup,
  groupId,
  error,
  onDelete,
  onUsersAdd,
}) => {
  const [search, setSearch] = useState<string>();
  const [addGroupUsersVisible, showAddGroupUsers, hideAddGroupUsers] = useBoolean(false);
  const [auditLogUser, auditLogVisible, setAuditLogUser, hideAuditLog] = useVisibleState<ProjectUserProfileListDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const [deletingItems, handleDelete] = useDeleteItems<Guid>(
    intl,
    (id) => api.project.projectUser.removeUserGroup(id, groupId),
    onDelete
  );

  const sortedUsers = useMemo(() => groupUsers?.sort((a, b) => a.username.localeCompare(b.username)), [groupUsers]);

  const handleAddGroupUsers = useCallback(
    async (addedUserIds: Guid[]) => {
      setLoading(true);
      const [error] = await api.project.groups.addGroupUsers({ groupId: groupId, users: addedUserIds });

      if (!isMounted.current) {
        return;
      }

      if (error) {
        messageError(error, intl);
      }

      onUsersAdd && onUsersAdd(addedUserIds);
      setLoading(false);
      hideAddGroupUsers();
    },
    [groupId, intl]
  );

  const transferUsers = useMemo<ProjectUserProfileListDto[]>(() => {
    return usersList?.filter((user) => !groupUsers.some((groupUser) => user.id === groupUser.id));
  }, [groupUsers, usersList]);

  const currentUser = useCurrentProjectUser();

  if (error) return <ServiceErrorBox error={error} />;

  return (
    <Panel
      noMargin
      onSearch={setSearch}
      addButtonText={<Fmt id="Panel.addGroupUser.tooltip" />}
      addButtonOnClick={showAddGroupUsers}
      addButtonDisabled={isAdminGroup && !currentUser.isAdmin}
      panelWidth={null}
    >
      <GeneralSettingsContainer>
        <List<ProjectUserProfileListDto>
          data={sortedUsers}
          search={search}
          filterItem={filterByName(search)}
          renderItem={(item) => (
            <GeneralSettingsItem
              key={item.id}
              icon={<UserIcon user={item} size={UserAvatarSize.Large} />}
              input={<UserStatus status={item.status} />}
              title={<div style={{ wordBreak: 'break-all' }}>{item.username}</div>}
              additionalActions={
                <>
                  <AuditLogButton onClick={() => setAuditLogUser(item)} />
                  {handleDelete && (
                    <DeleteButtonConfirm
                      disabled={
                        !(!isAdminGroup || item.id !== currentUser.id) || (isAdminGroup && !currentUser.isAdmin)
                      }
                      loading={deletingItems.has(item.id)}
                      onConfirm={() => handleDelete(item.id)}
                    />
                  )}
                </>
              }
            />
          )}
        />
      </GeneralSettingsContainer>
      <AuditLogEntityModal
        visible={auditLogVisible}
        label={<Fmt id="AuditLog.user.label" values={{ username: auditLogUser?.username }} />}
        entityType={EntityTypesEnum.projectUserProfile}
        entityId={auditLogUser?.id}
        onOk={hideAuditLog}
        deps={[auditLogUser]}
      />
      <GroupUserAddFormModal
        visible={addGroupUsersVisible}
        onClose={hideAddGroupUsers}
        onSubmit={handleAddGroupUsers}
        users={transferUsers}
        loading={loading}
      />
    </Panel>
  );
};

export default memoizeWithIntl(UserTabComponent);
