import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CommonDocument, DocumentCompleteListFormItem } from 'components/DocumentCompleteList/DocumentCompleteList';
import React, { useEffect } from 'react';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl } from 'api/completeApiInterfaces';

export type DiscussionLinkAttachmentFormData = {
  linkedAttachments: Set<Guid>;
};

type Props = FormComponentProps<DiscussionLinkAttachmentFormData> & {
  availableAttachments: CommonDocument[];
  getOriginalUrl?: (id: Guid) => ApiPromise<DownloadUrl>;
};

const DiscussionLinkAttachmentFormComponent = React.forwardRef<unknown, Props>(
  ({ getOriginalUrl, form, availableAttachments }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    // render
    return (
      <Form layout="vertical">
        <Form.Item>
          {form.getFieldDecorator<DiscussionLinkAttachmentFormData>('linkedAttachments')(
            <DocumentCompleteListFormItem
              documents={availableAttachments}
              disableReserve
              selectItemOnClickEnabled
              hideToolbar
              hideFilters
              disableDownload
              // TODO: add some getDocumentUrl or refactor it whole
              getDocumentUrl={getOriginalUrl}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
);

export const DiscussionLinkAttachmentForm = Form.create<Props>()(DiscussionLinkAttachmentFormComponent);
