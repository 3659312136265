import { Collapse, Modal, Pagination } from 'antd';
import { api } from 'api';
import {
  AuditLogSessionDto,
  AuditLogSessionListRequestDto,
  AuditLogSessionOrderTypeEnum,
  SortOrder,
} from 'api/completeApiInterfaces';
import AuditLogDetailModal from 'components/AuditLogsComponents/AuditLogDetailModal';
import AuditLogRow from 'components/AuditLogsComponents/AuditLogRow';
import EntityData from 'components/AuditLogsComponents/EntityData';
import EventData from 'components/AuditLogsComponents/EventData';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { createBackendDateFilter, DateBackendFilterType } from 'components/filters/components/DateFilter/DateFilter';
import { BackendFilter, FiltersPersistentKey } from 'components/filters/filterTypes';
import { accumulateFilterValues } from 'components/filters/filterUtils';
import { OrderOption } from 'components/filters/orderTypes';
import { OrderFilterToolbar } from 'components/filters/render/OrderFilterToolbar/OrderFilterToolbar';
import List from 'components/List';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useFilters, useIntl, useVisibleState } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SessionListItem } from './SessionListItem';
import styles from './SessionTab.module.less';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { useRouteMatch } from 'react-router-dom';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';

type Props = {
  userId: Guid;
};

const PAGE_SIZE = 50;
const PAGE_SIZE_OPTIONS = ['10', '25', '50', '100'];
const DEFAULT_FROM_FILTER = new Date('1/1/2020').toISOString();
const DEFAULT_TO_FILTER = new Date().toISOString();

const startOfDay = (date: IsoDateTime | null) =>
  date
    ? moment(date)
        .startOf('day')
        .toISOString()
    : undefined;
const endOfDay = (date: IsoDateTime | null) =>
  date
    ? moment(date)
        .endOf('day')
        .toISOString()
    : undefined;

const SESSION_DATE_KEY = 'sessionStart';

const ORDER_OPTIONS: OrderOption<AuditLogSessionOrderTypeEnum>[] = [
  {
    key: AuditLogSessionOrderTypeEnum.sessionStart,
    label: <Fmt id="SessionTab.OrderFilter.sessionStart" />,
    defaultOrder: SortOrder.desc,
  },
  {
    key: AuditLogSessionOrderTypeEnum.sessionEnd,
    label: <Fmt id="SessionTab.OrderFilter.sessionEnd" />,
  },
];

const DATE_FILTER_TYPES: DateBackendFilterType<AuditLogSessionListRequestDto>[] = [
  {
    key: SESSION_DATE_KEY,
    label: <Fmt id="SessionTab.OrderFilter.period" />,
    serialize: (request, value) => ({
      ...request,
      from: startOfDay(value.from),
      to: endOfDay(value.to),
    }),
  },
];

const SessionsTab: FunctionComponent<Props> = ({ userId }) => {
  const [logModalVisible, showLogModal, hideLogModal] = useBoolean();
  const [auditLogId, auditLogVisible, setAuditLogId, hideAuditLog] = useVisibleState<number>();
  const [sessionHash, setSessionHash] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);

  const { url } = useRouteMatch();
  const intl = useIntl();

  const filters = useMemo<BackendFilter<AuditLogSessionListRequestDto>[]>(
    () => [
      createBackendDateFilter(
        'date',
        DATE_FILTER_TYPES,
        {},
        { type: SESSION_DATE_KEY, from: DEFAULT_FROM_FILTER, to: DEFAULT_TO_FILTER }
      ),
    ],
    []
  );

  const { filtersWithValues, order, setOrder, ...filterProps } = useFilters(
    filters,
    ORDER_OPTIONS,
    FiltersPersistentKey.UserActivitySessionLog
  );

  const requestSession = useMemo<AuditLogSessionListRequestDto>(
    () =>
      accumulateFilterValues(filtersWithValues, {
        from: DEFAULT_FROM_FILTER,
        to: DEFAULT_TO_FILTER,
        order: [{ property: order.key, direction: order.direction }],
        userId,
        pageSize,
        currentPage,
      }),
    [filtersWithValues, userId, order, currentPage, pageSize]
  );

  const [defaultSessions, sessionsError, sessionsLoading, loadSessions] = useApiData((ct) =>
    api.project.auditLog.listAuditLogSession(requestSession, ct)
  );

  const [defaultLogs, logsError, logsLoading, loadLogs] = useApiData((ct) =>
    api.project.auditLog.listAuditLogUserActivity({ userId, sessionHash }, ct)
  );

  useEffect(loadSessions, [userId, requestSession]);

  useEffect(() => {
    if (!!sessionHash) {
      loadLogs();
    }
  }, [sessionHash]);

  const auditLogs = defaultLogs?.auditLogs;
  const connections = defaultLogs?.connections;

  const getRelevantConnection = (connectionId: number) => {
    const { id: _id, ...rest } = connections.find((c) => c.id === connectionId);
    return rest;
  };

  const auditLogsWithConnections = useMemo(
    () =>
      auditLogs?.map((i) => ({
        id: i.id,
        entityType: i.entityType,
        createdDate: i.createdDate,
        entityId: i.entityId,
        eventName: i.eventName,
        createdBy: i.createdBy,
        eventData: {
          ...i.eventData,
          ...getRelevantConnection(i.connectionId),
        },
        entityData: i.entityData,
        parentId: i.parentId,
        connectionId: i.connectionId,
      })),
    [defaultLogs]
  );

  const itemCounts = useMemo(() => {
    const firstItem = (currentPage - 1) * pageSize + 1;
    const lastItem = Math.min(currentPage * pageSize, defaultSessions?.total);
    return `${firstItem}-${lastItem} / ${defaultSessions?.total}`;
  }, [currentPage, pageSize, defaultSessions]);

  const handlePaginationChange = (current: number, size: number) => {
    setCurrentPage(current);
    setPageSize(size);
  };

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'UserDetailPanel.userActivitiesTab' })}
      children={() => (
        <ContentGate error={sessionsError} loading={sessionsLoading} overlay>
          <StackPanel vertical scrollable>
            <DocumentsGridHeaderStyled>
              <OrderFilterToolbar
                filters={filtersWithValues || []}
                {...filterProps}
                itemCounts={itemCounts}
                alignRight
                orderOptions={ORDER_OPTIONS}
                order={order}
                setOrder={setOrder}
              />
            </DocumentsGridHeaderStyled>
            <StackPanel stretch vertical scrollable>
              <List<AuditLogSessionDto>
                data={defaultSessions?.sessions}
                renderItem={(item) => (
                  <SessionListItem
                    key={item.sessionHash}
                    session={item}
                    handleSessionSelect={setSessionHash}
                    showLogModal={showLogModal}
                  />
                )}
              />
            </StackPanel>
            <Pagination
              onShowSizeChange={handlePaginationChange}
              onChange={handlePaginationChange}
              defaultCurrent={1}
              current={currentPage}
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              total={defaultSessions?.total}
              disabled={!defaultSessions?.total}
              className={styles.pagination}
            />
            <Modal
              title={<Fmt id="SessionTab.auditlogTitle" />}
              open={logModalVisible}
              centered
              onCancel={hideLogModal}
              footer={null}
              width={1500}
            >
              <ContentGate loading={logsLoading} error={logsError} empty={!defaultLogs?.auditLogs.length}>
                <Collapse accordion>
                  {auditLogsWithConnections
                    ?.filter((log) => !!log.entityData || !!log.eventData)
                    .map((log) => (
                      <Collapse.Panel
                        key={log.id}
                        header={<AuditLogRow log={log} displayEntityType isAdmin onDetailClick={setAuditLogId} />}
                      >
                        {!!log.entityData && (Object.keys(log.entityData).length > 0 || !log.eventData) && (
                          <div className={styles.table}>
                            <EntityData log={log} />
                          </div>
                        )}
                        {!!log.eventData && (
                          <div className={styles.table}>
                            <EventData log={log} />
                          </div>
                        )}
                      </Collapse.Panel>
                    ))}
                </Collapse>
                <AuditLogDetailModal auditLogId={auditLogId} visible={auditLogVisible} onOk={hideAuditLog} />
              </ContentGate>
            </Modal>
          </StackPanel>
        </ContentGate>
      )}
    />
  );
};

export default SessionsTab;
