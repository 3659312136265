import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './Margin.module.less';

type Props = {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  inFlex?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const Margin: FunctionComponent<Props> = ({ top, right, bottom, left, className, style, inFlex, children }) => {
  return (
    <div
      className={classNames(
        top && styles.top,
        right && styles.right,
        bottom && styles.bottom,
        left && styles.left,
        inFlex && styles.inFlex,
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};
