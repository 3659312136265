import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  MasterComponent,
  MasterDetailsContainer,
  MasterDetailsViewContextProvider,
} from 'components/MasterDetailsView/MasterDetailsView';
import PageLevelDropzone from 'components/PageLevelDropzone';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import StackPanel from 'components/StackPanel';
import { HeaderContentLayout } from 'components/layouts/HeaderContentLayout/HeaderContentLayout';
import { useIntl } from 'hooks';
import ResizerWidth from 'pages/AllDocumentsPage/ResizerWidth';
import React, { FunctionComponent, ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styles from './AllDocumentsPage.module.less';

type Props = {
  directoryContent: ReactElement;
  directoryTree: ReactElement;
  documentsHeader: ReactElement;
  onDropFiles: (data: FileSystemTreeNode[]) => void;
  disableDropzone?: boolean;
};

const SIZE_CONSTRAINTS = { minWidth: 300, maxWidth: 700 };
const STEP_SIZES = [SIZE_CONSTRAINTS.minWidth, SIZE_CONSTRAINTS.maxWidth];

const AllDocumentsPageLayout: FunctionComponent<Props> = ({
  directoryContent,
  directoryTree,
  documentsHeader,
  onDropFiles,
  disableDropzone,
}) => {
  const { url } = useRouteMatch();
  const intl = useIntl();

  const [width, setWidth] = React.useState(300);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <MasterDetailsViewContextProvider>
      <SideMenuActivator menuItemKey={SideMenuKey.DOCUMENTS_ALL}>
        <PageLevelDropzone onDrop={onDropFiles} multiple disabled={disableDropzone}>
          <MasterDetailsContainer hideTitle>
            <MasterComponent
              url={url}
              title={intl.formatMessage({ id: 'menu.side.documents' })}
              minWidth={width}
              noFlex
              children={() => (
                <ErrorBoundary>
                  <ResizerWidth
                    handleResize={setWidth}
                    contentRef={ref}
                    sizesSteps={STEP_SIZES}
                    constrains={SIZE_CONSTRAINTS}
                  />
                  <StackPanel divRef={ref} vertical stretch scrollable className={styles.directoryTree}>
                    {directoryTree}
                  </StackPanel>
                </ErrorBoundary>
              )}
            />
            <MasterComponent
              url={url + '/content'}
              maxWidth={null}
              children={() => (
                <StackPanel vertical stretch scrollable>
                  <HeaderContentLayout header={documentsHeader}>
                    <ErrorBoundary>{directoryContent}</ErrorBoundary>
                  </HeaderContentLayout>
                </StackPanel>
              )}
            />
          </MasterDetailsContainer>
        </PageLevelDropzone>
      </SideMenuActivator>
    </MasterDetailsViewContextProvider>
  );
};

export default React.memo(AllDocumentsPageLayout);
