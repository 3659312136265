import Logo from 'components/Logo';
import { APP_LOGO_IMG, APP_LOGO_LINK, APP_LOGO_TITLE } from 'config/env';
import React, { FunctionComponent } from 'react';

type Props = {
  small?: boolean;
};

const BrandLogo: FunctionComponent<Props> = ({ small }) => {
  return (
    <Logo img={APP_LOGO_IMG} link={APP_LOGO_LINK} width={'32px'}>
      {!small && <span title={APP_LOGO_TITLE}>{APP_LOGO_TITLE}</span>}
    </Logo>
  );
};
export default BrandLogo;
