import { Alert } from 'antd';
import { api } from 'api';
import { GroupDto, ProjectUserProfileDto, ProjectUserProfileStatusEnum, ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import SwitchCheck from 'components/SwitchCheck';
import { useItemsSet } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';

type Props = InjectedIntlProps & {
  userId: Guid;
  userStatus: ProjectUserProfileStatusEnum;
  groups: GroupDto[];
  userGroupsSet: Set<Guid>;
  currentUser: ProjectUserProfileDto;
  error?: ServiceError;
  onChange?: (userId: Guid, groupId: Guid, checked: boolean) => void;
};

const GroupsTab: FunctionComponent<Props> = ({
  intl,
  userId,
  userStatus,
  groups,
  userGroupsSet,
  currentUser,
  error,
  onChange,
}) => {
  const { url } = useRouteMatch();
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<Guid>();

  useEffect(() => () => deleteSavingItem(), [userId]); // after change clear saving indicators

  const handleSave = async (userId: Guid, groupId: Guid, checked: boolean) => {
    addSavingItem(groupId);

    let err: ApiError;
    if (checked) {
      [err] = await api.project.projectUser.addUserGroup(userId, groupId);
    } else {
      [err] = await api.project.projectUser.removeUserGroup(userId, groupId);
    }

    if (err) {
      messageError(err, intl);
    } else {
      onChange && onChange(userId, groupId, checked);
    }

    deleteSavingItem(groupId);
  };

  const userDisabled = userStatus === ProjectUserProfileStatusEnum.suspended;

  if (error) return <ServiceErrorBox error={error} />;

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'UserDetailPanel.GroupsTab' })}
      children={() => (
        <StackPanel vertical scrollable>
          <GeneralSettingsContainer>
            {userDisabled && (
              <Alert
                type="warning"
                message={intl.formatMessage({ id: 'UserDetailPanel.GroupsTab.UserDisabledWarning' })}
              />
            )}
            {groups.map((group) => (
              <GeneralSettingsItem
                key={group.id}
                title={group.name}
                description={group.description}
                input={
                  <SwitchCheck
                    checked={userGroupsSet.has(group.id)}
                    loading={savingItems.has(group.id)}
                    disabled={
                      userDisabled || ((currentUser.id === userId || !currentUser.isAdmin) && group.isAdminGroup)
                    }
                    onChange={(checked) => handleSave(userId, group.id, checked)}
                    label={<Fmt id={userGroupsSet.has(group.id) ? 'general.on' : 'general.off'} />}
                    labelOnLeft
                  />
                }
              />
            ))}
          </GeneralSettingsContainer>
        </StackPanel>
      )}
    />
  );
};

export default GroupsTab;
