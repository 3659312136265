import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { Fmt } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import styles from './ReportListPage.module.less';
import ReportsList from './ReportsList';

type Props = RouteComponentProps;

const ReportsListComponent: FunctionComponent<Props> = () => {
  return (
    <ErrorBoundary>
      <SideMenuActivator menuItemKey={SideMenuKey.REPORTING}>
        <div className={styles.content}>
          <PageContent title={<Fmt id="Reporting.list.title" />}>
            <ReportsList />
          </PageContent>
        </div>
      </SideMenuActivator>
    </ErrorBoundary>
  );
};

export default React.memo(ReportsListComponent);
