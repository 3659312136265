import { DependencyList, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { ApiStoreEffects } from 'store/models/storeModelinterfaces';

// TODO: remove this hook and keep only useStoreSelector and useDispatchEffect ?
export const useSelectorDispatch = <T>(
  selector: (state: RootState) => T,
  dispatch: (dispatch: Dispatch) => void,
  deps?: DependencyList
) => {
  useDispatchEffect(dispatch, deps || []);
  return useSelector(selector);
};

export const useStoreSelector: <T>(selector: (state: RootState) => T) => T = useSelector;

export const useDispatchEffect = (dispatch: (dispatch: Dispatch) => void, deps: DependencyList) => {
  const dispatchObject = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch(dispatchObject);
  }, deps);
};

export const useDirtyStoreReload = <
  T extends { dirty: boolean },
  K extends Pick<ApiStoreEffects<T>, 'loadData'> & {
    loadData: (payload: { reload: boolean; silent?: boolean }) => Promise<void>;
  }
>(
  stateSelector: (state: RootState) => T,
  dispatchSelector: (dispatch: Dispatch) => K
) => {
  const storeModel = useSelector<RootState, T>(stateSelector);
  useDispatchEffect((dispatch) => dispatchSelector(dispatch).loadData({ reload: false, silent: true }), [
    storeModel.dirty,
  ]);
};

export const useDirtyStoreReloadCallback = <T extends { dirty: boolean }, K extends Promise<void>>(
  stateSelector: (state: RootState) => T,

  reloadCallback: (dispatch: Dispatch) => K
) => {
  const storeModel = useSelector<RootState, T>(stateSelector);

  useDispatchEffect((dispatch) => reloadCallback(dispatch), [storeModel.dirty]);
};
