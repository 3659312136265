import { useCallback, useEffect, useRef } from 'react';

export const useFocus = <T extends { focus: () => void }>(trigger: boolean) => {
  const inputRef = useRef<T>();
  const setInputRef: (ref: T) => void = useCallback((ref: T) => {
    if (ref) {
      inputRef.current = ref;
    }
  }, []);

  useEffect(() => {
    if (trigger) {
      const timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [trigger]);

  return { inputRef, setInputRef } as const;
};
