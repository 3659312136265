import { ApiError } from 'api/errors';
import { calculateUploadProgress, getCommonUploadError } from 'api/project/upload/uploadHelpers';
import {
  UploadData,
  UploadFile,
  UploadFileState,
  UploadManager,
  UploadProcess,
} from 'api/project/upload/uploadManager';
import { IntlMessageId } from 'locale/messages/cs';
import { useCallback, useRef, useState } from 'react';
import uuid from 'uuid';

export const useFileUpload = () => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const lastUploadManager = useRef<UploadManager>(null);
  const lastUploadId = useRef<string>(null);

  const [interrupted, setInterrupted] = useState<boolean>(false);
  const [error, setError] = useState<ApiError>(null);

  const handleUploadError = useCallback((state: UploadFileState[], onError?: (error: ApiError) => void) => {
    const error = getCommonUploadError(state);
    if (!!error) {
      setError(error);
      onError && onError(error);
    }
    setInterrupted(true);
    lastUploadManager.current?.cancelProcess(lastUploadId.current);
  }, []);

  const resetAll = useCallback(() => {
    if (lastUploadManager.current?.processExists(lastUploadId.current)) {
      lastUploadManager.current.cancelProcess(lastUploadId.current);
    }
    setUploadProgress(0);
    setInterrupted(false);
    setError(null);
    lastUploadManager.current = null;
    lastUploadId.current = null;
  }, []);

  const handleUploadProgress = useCallback((state: UploadFileState[]) => {
    setUploadProgress(calculateUploadProgress(state));
  }, []);

  const startUpload = useCallback(
    (uploadData: UploadData, uploadFiles: UploadFile[], onError?: (error: ApiError) => void) => {
      if (lastUploadManager.current !== null) {
        // continue is pressed
        if (interrupted) {
          setError(null);
          setInterrupted(false);
          lastUploadManager.current.continueProcess(lastUploadId.current);
        }
        return;
      }

      lastUploadId.current = uuid();

      const uploadProcess: UploadProcess = {
        id: lastUploadId.current,
        uploadFiles: uploadFiles,
        data: uploadData,
        onError: (state) => handleUploadError(state, onError),
        onProgress: handleUploadProgress,
      };

      lastUploadManager.current = new UploadManager();
      lastUploadManager.current.startProcess(uploadProcess);

      setUploadProgress(1);
    },
    []
  );

  const loading = !interrupted && lastUploadManager.current !== null;
  const submitTextId: IntlMessageId =
    lastUploadManager.current !== null && interrupted ? 'DocumentCreateFormModal.continue' : 'forms.button.submit';

  return {
    lastUploadManager,
    uploadProgress,
    error,
    loading,
    submitTextId,
    resetAll,
    startUpload,
  };
};
