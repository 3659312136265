import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError, serviceErrorEnumStatusCodeMap } from 'api/errors';

export const processApiError = (error: ApiError, setError?: (error: ServiceError) => void): ServiceError => {
  let serviceError: ServiceError;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log('error.response.data', error.response.data);
    // console.log('error.response.status', error.response.status);
    // console.log('error.response.headers', error.response.headers);
    const response = error.response;

    if (response.data && response.data.requestId !== undefined) {
      // API Error body is present and contains requestId property
      serviceError = response.data;
    } else {
      // Received empty response or other then expected
      serviceError = {
        apiVersion: null,
        requestId: null,
        statusCode: response.status,
        message: response.statusText,
        referenceErrorCode: serviceErrorEnumStatusCodeMap[response.status],
        errorData: response.data,
        validationErrors: null,
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // console.log('error.request', error.request);
    const request = error.request;

    serviceError = {
      apiVersion: null,
      requestId: null,
      statusCode: request.status,
      message: request.statusText,
      referenceErrorCode: serviceErrorEnumStatusCodeMap[request.status],
      errorData: request,
      validationErrors: null,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('error.message', error.message);

    serviceError = {
      apiVersion: null,
      requestId: null,
      statusCode: null,
      message: error.message,
      referenceErrorCode: undefined,
      errorData: error,
      validationErrors: null,
    };
  }

  setError && setError(serviceError);

  return serviceError;
};
