import { Rate } from 'antd';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { isNumber } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AccessLevel.module.less';

export type AccessLevelProps<T extends string> = {
  accessLevelMap: Record<T, number>;
  accessLevelReverseMap: Record<number, T>;
  translations: Record<T, IntlMessageId>;
  value?: T;
  onChange?: (value: T) => void;
  disabled?: boolean;
  displayTooltips?: boolean;
  displayEditMark?: boolean;
  coloured?: boolean;
};

const AccessLevel = <T extends string>({
  accessLevelMap,
  accessLevelReverseMap,
  translations,
  value,
  onChange,
  disabled,
  displayTooltips,
  displayEditMark,
  coloured,
}: AccessLevelProps<T>) => {
  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState<number>(accessLevelMap[value]);
  const [hover, setHover] = useState<number>(null);

  useEffect(() => {
    if (!!value) setCurrentValue(accessLevelMap[value]);
  }, [value, accessLevelMap]);

  const triggerChange = useCallback(
    (value: number) => {
      setCurrentValue(value);
      // Should provide an event to pass value to Form.
      onChange && onChange(accessLevelReverseMap[value]);
    },
    [onChange]
  );

  const translates = useMemo(
    () =>
      Object.entries(accessLevelMap)
        .filter(([_, enumValue]: [T, number]) => enumValue !== 0)
        .reduce((acc, [enumKey, _]: [T, number]) => [...acc, intl.formatMessage({ id: translations[enumKey] })], []),
    [translations, accessLevelMap, intl]
  );

  const displayedSize = Object.keys(accessLevelMap).length - 1;

  const permissionLabel = isNumber(hover) ? (
    translates[hover - 1]
  ) : currentValue !== 0 ? (
    translates[currentValue - 1]
  ) : (
    <Fmt id="AccessLevel.tooltips.none" />
  );

  return (
    <div className={styles.rateWrap}>
      <Rate
        count={displayedSize}
        character={
          <div className={styles.character}>
            <span className={styles.characterInner} />
          </div>
        }
        tooltips={displayTooltips ? translates : null}
        value={currentValue}
        disabled={disabled}
        onHoverChange={(v) => {
          if (hover !== v) {
            setHover(hover);
          }
        }}
        onChange={triggerChange}
        className={classNames(styles.rate, coloured && styles.coloured, disabled && styles.disabled)}
      />
      <CommonHubTooltip title={permissionLabel}>
        <div className={styles.rateLabel}>
          {permissionLabel}
          {displayEditMark && <span style={{ color: 'red' }}>*</span>}
        </div>
      </CommonHubTooltip>
    </div>
  );
};

export default React.memo(AccessLevel);
