import { EstiConPrehledStavebPohDto } from 'api/completeApiInterfaces';
import { DateFrontendFilterType, createFrontendDateFilter } from 'components/filters/components/DateFilter/DateFilter';
import { IOption, createFrontendSingleSelectFilter } from 'components/filters/components/SelectFilter/SelectFilter';
import { createFrontendSingleTextMutlipleOptionsFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FrontendFilter } from 'components/filters/filterTypes';
import { useFrontendFilters, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';

const SOD_DATE_FILTER_TYPES: DateFrontendFilterType<EstiConPrehledStavebPohDto>[] = [
  {
    key: 'sod',
    label: <Fmt id="SearchSortTypeItem.createdDate" />,
    valueSelector: (reportRow) => reportRow.soD,
  },
];

const START_DATE_FILTER_TYPES: DateFrontendFilterType<EstiConPrehledStavebPohDto>[] = [
  {
    key: 'startDate',
    label: <Fmt id="SearchSortTypeItem.createdDate" />,
    valueSelector: (reportRow) => reportRow.zahajeni,
  },
];

const END_DATE_FILTER_TYPES: DateFrontendFilterType<EstiConPrehledStavebPohDto>[] = [
  {
    key: 'endDate',
    label: <Fmt id="SearchSortTypeItem.createdDate" />,
    valueSelector: (reportRow) => reportRow.dokonceni,
  },
];

export const useProjectsInRealizationFilterConfiguration = (reportRowsWithNotes: EstiConPrehledStavebPohDto[]) => {
  const intl = useIntl();

  const typeFilterOptions = useMemo(
    (): IOption<string>[] => [
      {
        id: '',
        title: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.filter.type.empty' }),
      },
      {
        id: 'O',
        title: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.filter.type.o' }),
      },
      {
        id: 'I',
        title: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.filter.type.i' }),
      },
      {
        id: 'O+I',
        title: intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.filter.type.oi' }),
      },
    ],
    [intl]
  );

  const subsidiesFilterOptions = useMemo(
    () =>
      uniqBy(reportRowsWithNotes, (row) => row.uzivatelskeZatrideniProjektuZnacka)
        .map((row) => ({
          id: row.uzivatelskeZatrideniProjektuZnacka,
          title:
            row.uzivatelskeZatrideniProjektuZnacka ||
            intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.filter.subsidies.empty' }),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [intl, reportRowsWithNotes]
  );

  return useMemo<FrontendFilter<EstiConPrehledStavebPohDto>[]>(
    () => [
      createFrontendSingleSelectFilter(
        'projectType',
        {
          label: <Fmt id="ProjectsInRealizationOverviewReport.column.io" />,
          options: typeFilterOptions,
        },
        (reportRow) => reportRow.typ
      ),
      createFrontendSingleTextMutlipleOptionsFilter('unit', (reportRow) => reportRow.utvar, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.unit" />,
      }),
      createFrontendSingleTextMutlipleOptionsFilter(
        'subsidies',
        (reportRow) => reportRow.uzivatelskeZatrideniProjektuZnacka,
        ';',
        {
          label: <Fmt id="ProjectsInRealizationOverviewReport.column.subsidies" />,
        }
      ),
      createFrontendSingleTextMutlipleOptionsFilter('projectName', (reportRow) => reportRow.nazevAkce, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.projectName" />,
      }),
      createFrontendSingleTextMutlipleOptionsFilter('projectSign', (reportRow) => reportRow.znacka, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.projectSign" />,
      }),
      createFrontendSingleTextMutlipleOptionsFilter('supervisor', (reportRow) => reportRow.tds, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.supervisor" />,
      }),
      createFrontendSingleTextMutlipleOptionsFilter('supplier', (reportRow) => reportRow.dodavatel, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.supplier" />,
      }),
      createFrontendDateFilter('sodDate', SOD_DATE_FILTER_TYPES, {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.state" />,
      }),
      createFrontendDateFilter('startDate', START_DATE_FILTER_TYPES, {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.startDate" />,
      }),
      createFrontendDateFilter('endDate', END_DATE_FILTER_TYPES, {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.endDate" />,
      }),
      createFrontendSingleTextMutlipleOptionsFilter('note', (reportRow) => reportRow.poznamka, ';', {
        label: <Fmt id="ProjectsInRealizationOverviewReport.column.note" />,
      }),
    ],
    [typeFilterOptions, subsidiesFilterOptions]
  );
};

export const useProjectsInRealizationFilter = (reportRowsWithNotes: EstiConPrehledStavebPohDto[]) => {
  const filters = useProjectsInRealizationFilterConfiguration(reportRowsWithNotes);

  return useFrontendFilters(filters, [], reportRowsWithNotes);
};
