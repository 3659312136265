import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadLabels: dispatch.labels.loadData,
  loadCategories: dispatch.categories.loadData,
  loadCategoriesTrees: dispatch.categoryTrees.loadCategoriesTrees,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromDispatch & {
  children?: never;
};

const Init: FunctionComponent<Props> = (props) => {
  const { loadLabels, loadCategories, loadCategoriesTrees } = props;

  useEffect(() => {
    loadLabels({ reload: false });
    loadCategories({ reload: false }).then(() => loadCategoriesTrees({ reload: false }));
  }, []);

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  return null;
};

export default connect(null, legacyMapDispatchToProps(mapDispatchToProps))(Init);
