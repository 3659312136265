import { WarningOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { api } from 'api';
import { RoleDto, ServiceError, UserRemoveStrategyEnum } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { AddIcon, DeleteIcon } from 'components/Icons/HubActionsIcons';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { RoleAssignFormData, RoleAssignFormModal } from 'components/forms/RoleAssignForm';
import { RoleInProcedureError, RoleUsageError } from 'components/forms/RoleForm/RoleUsageError';
import { useItemsSet } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError, processApiError } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';

type Props = InjectedIntlProps & {
  userId: Guid;
  roles: RoleDto[];
  userRolesSet: Set<Guid>;
  error?: ServiceError;
  onChange?: (userId: Guid, role: RoleDto) => void;
};

const RolesTab: FunctionComponent<Props> = ({ intl, userId, roles, userRolesSet, error, onChange }) => {
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<Guid>();
  useEffect(() => () => deleteSavingItem(), [userId]); // after change clear saving indicators
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (data: RoleAssignFormData, role: RoleDto) => {
      onChange && onChange(userId, role);
      setFormModalVisible(false);
    },
    [onChange, userId, setFormModalVisible]
  );

  const handleRemove = useCallback(
    async (userId: Guid, roleId: Guid) => {
      addSavingItem(roleId);

      let [err, response] = await api.project.roles.updateRoleById(roleId, {
        name: null,
        removeUser: true,
        strategy: null,
      });
      const error = err && processApiError(err);

      if (error && error.referenceErrorCode === ServiceErrorEnum.RoleInProcedureError) {
        const data = error.errorData as { roleErrors: RoleInProcedureError[] };

        const approve = await modalConfirm({
          title: intl.formatMessage({ id: 'UserDetailPanel.RolesTab.ConfirmRemove.question' }),
          icon: <WarningOutlined />,
          content: <RoleUsageError roleErrors={data.roleErrors} intl={intl} />,
        });

        if (!approve) {
          deleteSavingItem(roleId);
          return;
        }

        [err, response] = await api.project.roles.updateRoleById(roleId, {
          name: null,
          removeUser: true,
          strategy: UserRemoveStrategyEnum.remove,
        });
      }

      if (err) {
        messageError(err, intl);
      } else {
        onChange && onChange(userId, response.data);
      }

      deleteSavingItem(roleId);
    },
    [intl, addSavingItem, deleteSavingItem, onChange]
  );

  const { url } = useRouteMatch();

  if (error) return <ServiceErrorBox error={error} />;

  return (
    <>
      <RoleAssignFormModal
        visible={formModalVisible}
        userId={userId}
        roles={roles}
        onSubmit={handleSubmit}
        onClose={() => setFormModalVisible(false)}
        userRolesSet={userRolesSet}
        intl={intl}
      />
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'UserDetailPanel.RolesTab.rolesListTitle' })}
        children={() => (
          <StackPanel vertical scrollable>
            <GeneralSettingsContainer itemsLargeGap>
              <GeneralSettingsItem
                title={<Fmt id="UserDetailPanel.RolesTab.AddRole.title" />}
                description={<Fmt id="UserDetailPanel.RolesTab.AddRole.description" />}
                input={
                  <Button icon={<AddIcon />} type="primary" onClick={() => setFormModalVisible(true)}>
                    <Fmt id="UserDetailPanel.RolesTab.AddRole" />
                  </Button>
                }
              />

              <GeneralSettingsContainer title={intl.formatMessage({ id: 'UserDetailPanel.RolesTab.rolesListTitle' })}>
                {roles?.map(
                  (role) =>
                    userRolesSet.has(role.id) && (
                      <GeneralSettingsItem
                        title={role.name}
                        description={role.description}
                        input={
                          <Button
                            type="link"
                            danger
                            onClick={() => handleRemove(userId, role.id)}
                            icon={<DeleteIcon />}
                            loading={savingItems.has(role.id)}
                          />
                        }
                      />
                    )
                )}
                {userRolesSet.size === 0 && (
                  <StackPanel vertical centerItems justifyContent="centerContent" padding>
                    <Empty description={<Fmt id="ListEmpty.noData" />} />

                    <Button icon={<AddIcon />} type="link" onClick={() => setFormModalVisible(true)}>
                      <Fmt id="UserDetailPanel.RolesTab.AddRole" />
                    </Button>
                  </StackPanel>
                )}
              </GeneralSettingsContainer>
            </GeneralSettingsContainer>
          </StackPanel>
        )}
      />
    </>
  );
};

export default RolesTab;
