import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { api } from 'api';
import {
  EntityTypesEnum,
  GroupListDto,
  ProjectHubDto,
  ProjectUserProfileListDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import GeneralSettingsUsersList from 'components/GeneralSettingsUsersList/GeneralSettingsUsersList';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import ProjectUsersAddFormModal from 'components/forms/ProjectUsersAddForm/ProjectUsersAddFormModal';
import { useApiData, useVisibleState } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { ProjectUserProfileListExtended } from 'pages/OrganizationsSettingPage/OrganizationsDetailPanel';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

type Props = InjectedIntlProps & {
  usersList: ProjectUserProfileListExtended[];
  loading: boolean;
  error: ServiceError;
  selectedId: Guid;
  onSelect?: (userId: Guid) => void;
  currentProject: ProjectHubDto;
  groupsList: GroupListDto[];
  handleReload: () => void;
};

const UsersListPanel: FunctionComponent<Props> = ({
  usersList,
  loading,
  error,
  selectedId,
  onSelect,
  currentProject,
  groupsList,
  handleReload,
}) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [auditLogUser, auditLogVisible, setAuditLogUser, hideAuditLog] = useVisibleState<ProjectUserProfileListDto>();

  const [
    usableOrganizationUsers,
    usableOrganizationUsersError,
    usableOrganizationUsersLoading,
    loadUsableOrganizationUsers,
  ] = useApiData(
    (ct) =>
      api.master.organization.getorgusersNonExistOnProject(currentProject.organization?.id, currentProject.id, ct),
    {
      autoload: true,
    }
  );

  useEffect(() => {
    loadUsableOrganizationUsers();
  }, [usersList]);

  const remappedUsers = useMemo(() => usableOrganizationUsers?.map((orgUser) => orgUser.appUserProfile), [
    usableOrganizationUsers,
  ]);

  const showModal = () => {
    setFormModalVisible(true);
  };
  const closeModal = () => setFormModalVisible(false);

  const handleSubmit = () => {
    loadUsableOrganizationUsers();
    handleReload();
    closeModal();
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const renderContent = () => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading && !usersList) return <SpinBox />;
    if (usersList === null) return null;
    return (
      <GeneralSettingsUsersList
        data={usersList}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onActivity={setAuditLogUser}
        onClearSearch={clearSearch}
      />
    );
  };

  return (
    <Panel
      noMargin
      panelWidth="auto"
      onSearch={setSearch}
      searchValue={search}
      toolbarTitle={
        <Space>
          <CommonHubTooltip
            title={
              !remappedUsers?.length && !currentProject.organization?.projectAdminCanInviteNewUser ? (
                <Fmt id={'UserListPanel.addUserButton.disableTooltip'} />
              ) : (
                undefined
              )
            }
          >
            <Button
              type="primary"
              onClick={showModal}
              icon={<PlusOutlined />}
              disabled={!remappedUsers?.length && !currentProject.organization?.projectAdminCanInviteNewUser}
            >
              <Fmt id="Panel.addUser.tooltip" />
            </Button>
          </CommonHubTooltip>
        </Space>
      }
    >
      {renderContent()}
      <ProjectUsersAddFormModal
        onSubmit={handleSubmit}
        onClose={closeModal}
        visible={formModalVisible}
        toAddAppUsers={remappedUsers}
        titleId={'AspeHubUsersListPanel.addModal.title'}
        projectId={currentProject.id}
        organizationId={currentProject.organization?.id}
        groupsList={groupsList}
        requestEndpoint="project"
      />
      <AuditLogEntityModal
        visible={auditLogVisible}
        label={<Fmt id="AuditLog.user.label" values={{ username: auditLogUser?.username }} />}
        entityType={EntityTypesEnum.projectUserProfile}
        entityId={auditLogUser?.id}
        onOk={hideAuditLog}
        deps={[auditLogUser]}
      />
    </Panel>
  );
};

export default UsersListPanel;
