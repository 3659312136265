import { Button, message } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { masterApi } from 'api/completeApi';
import { useSameCallback } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';

type Props = { projectId: Guid } & InjectedIntlProps & Pick<ButtonProps, 'type' | 'size' | 'icon'>;

const ERPDataImportButtonComponent: FunctionComponent<Props> = ({ projectId, intl, ...buttonProps }) => {
  const [isERPDataLoading, setERPDataLoading] = useState<boolean>(false);

  const handleERPSynchronization = useSameCallback(async () => {
    setERPDataLoading(true);
    const [err, res] = await masterApi.projects.updateerpdata.post({ projectIds: [projectId] });

    if (err) {
      messageError(err, intl);
    } else if (res) {
      message.success(intl.formatMessage({ id: 'ERPDataImportButtonComponent.import.success' }));
    }
    setERPDataLoading(false);
  });

  return (
    <>
      <Button onClick={handleERPSynchronization} type="primary" loading={isERPDataLoading} {...buttonProps}>
        <Fmt id="general.import" />
      </Button>
    </>
  );
};

export const ERPDataImportButton = React.memo(ERPDataImportButtonComponent);
