import { Button, Input, message, Select, Typography } from 'antd';
import { api } from 'api';
import { AccessLevelEnum } from 'api/completeApiInterfaces';
import { useApiData } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { messageError } from 'utils';
import styles from './AccessPermissionRequest.module.less';
import { Margin } from 'components/Margin/Margin';

const { Option } = Select;

type Props = InjectedIntlProps & {
  selectedDirectoryId: Guid;
  accessOptions?: AccessLevelEnum[];
  startAccessOption?: AccessLevelEnum;
};

const defaultAccessOptions = [AccessLevelEnum.none, AccessLevelEnum.read, AccessLevelEnum.write, AccessLevelEnum.admin];

export const AccessPermissionRequest: FunctionComponent<Props> = ({
  selectedDirectoryId,
  intl,
  accessOptions = defaultAccessOptions,
  startAccessOption = AccessLevelEnum.none,
}) => {
  const [sent, setSent] = useState<Record<Guid, boolean>>({});

  const accessOptionsFiltered = useMemo(() => {
    const result: AccessLevelEnum[] = [];
    let canAdd = false;
    accessOptions.forEach((item) => {
      if (canAdd) {
        result.push(item);
      }
      if (item === startAccessOption) {
        canAdd = true;
      }
    });
    return result;
  }, [startAccessOption, selectedDirectoryId]);

  const [level, setLevel] = useState<AccessLevelEnum>(
    accessOptionsFiltered.length > 0 ? accessOptionsFiltered[0] : undefined
  );

  useEffect(() => {
    if (accessOptionsFiltered.length > 0) {
      setLevel(accessOptionsFiltered[0]);
    }
  }, [selectedDirectoryId]);

  // TODO: useApiData is for fetching data, but it is used here to send POST requests ... change to useSameCallback
  const [_data, error, loading, onRequestAccess] = useApiData(
    (ct) => api.project.directories.requestAccess(selectedDirectoryId, level, ct),
    {
      fetchCallback: () => {
        message.success(intl.formatMessage({ id: 'DirectoryForbiddenErrorBox.requestSent' }));
        setSent({ ...sent, [selectedDirectoryId]: true });
      },
      errorCallback: (err) => messageError(err, intl),
    }
  );

  return (
    <div>
      {!sent[selectedDirectoryId] && (
        <Input.Group className={styles.group}>
          <div className={styles.button}>
            <Button type="primary" onClick={onRequestAccess} loading={loading}>
              <Fmt id="DirectoryForbiddenErrorBox.request" />
            </Button>
          </div>
          {accessOptionsFiltered.length > 0 && (
            <Select
              value={level}
              onChange={(value: AccessLevelEnum) => {
                setLevel(value);
              }}
            >
              {accessOptionsFiltered.map((item) => (
                <Option value={item} key={item}>
                  <Fmt id={`AccessLevel.tooltips.${item}`} />
                </Option>
              ))}
            </Select>
          )}
        </Input.Group>
      )}
      {sent[selectedDirectoryId] && !error && (
        <Typography.Text>
          <Fmt id="DirectoryForbiddenErrorBox.requestSent" />
        </Typography.Text>
      )}
      {error && (
        <Margin top>
          <Typography.Text type="danger">{<Fmt id="DirectoryForbiddenErrorBox.requestFailed" />}</Typography.Text>
        </Margin>
      )}
    </div>
  );
};

export default injectIntl(AccessPermissionRequest);
