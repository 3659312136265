import React from 'react';

export const DocumentLinkOutlined = () => (
  <svg
    viewBox="7 7 21 16"
    version="1.1"
    width="1em"
    height="1em"
    stroke="currentColor"
    fill="currentColor"
    aria-hidden
    data-icon="stamp"
    focusable="false"
  >
    <path d="M 17.00,5.95 C 17.00,5.95 25.08,12.52 25.08,12.52 25.08,12.52 17.00,18.50 17.00,18.50 17.00,18.50 17.00,15.78 17.00,14.11 10.19,14.02 9.85,20 12, 24 9,22.74 1.72,9.90 17.00,9.95 17.00,8.70 17.00,5.95 17.00,5.95 Z" />
  </svg>
);
