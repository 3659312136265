import { SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { api } from 'api';
import { ApiError } from 'api/errors';
import { CreateNewIcon } from 'components/AppIcons/CreateNewIcon';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import { Margin } from 'components/Margin/Margin';
import PathDisplay from 'components/PathDisplay';
import RevisionNumberTag from 'components/RevisionNumberTag';
import StackPanel from 'components/StackPanel';
import { DocumentCreateMultipleFormModal } from 'components/forms/DocumentCreateForm';
import {
  DocumentCreateResult,
  DocumentCreateResultType,
} from 'components/forms/DocumentCreateForm/DocumentCreateMultipleFormModal';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import { ignoreRef, messageError } from 'utils';
import { DocumentSelectDocumentState } from '../DocumentSelect';
import { DocumentSelectModal, DocumentSelectModalProps } from '../DocumentSelectModal';
import styles from './DocumentSelectMultipleFormItem.module.less';

type ProcessResult = [ApiError | null, DocumentSelectDocumentState | null];

const processDocumentCreateResults = (results: DocumentCreateResult[]): Promise<ProcessResult[]> => {
  const promises = results.map(
    async (result): Promise<ProcessResult> => {
      if (result.type === DocumentCreateResultType.Document) {
        return Promise.resolve([
          null,
          {
            id: result.document.id,
            name: result.document.name,
            directoryId: result.document.directoryId,
            directoryPath: result.document.directoryPath,
          },
        ]);
      }
      if (result.type === DocumentCreateResultType.Revision) {
        const [err, resp] = await api.project.documents.getDocumentById(result.documentId);
        if (err) {
          return [err, null];
        } else {
          return [
            null,
            {
              id: resp.data.id,
              directoryId: resp.data.directoryId,
              directoryPath: resp.data.directoryPath,
              name: resp.data.name,
            },
          ];
        }
      }
      return Promise.resolve([null, null]);
    }
  );
  return Promise.all(promises);
};

type Props = Omit<DocumentSelectModalProps, 'selectedDocuments' | 'setSelectedDocuments' | 'multiple'> & {
  value: DocumentSelectDocumentState[];
  onChange: (value: DocumentSelectDocumentState[]) => void;
  onSelectedDocumentsChange?: (value: DocumentSelectDocumentState[]) => void;
  selectButton?: ReactNode;
  titleId?: IntlMessageId;
  allowUploadNew?: boolean;
};

const DocumentsSelectMultipleFormItemComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  selectButton,
  titleId,
  allowUploadNew,
  onSelectedDocumentsChange,
  ...restProps
}) => {
  const [modalDocuments, setModalDocuments] = useState<DocumentSelectDocumentState[]>(value || []); // files selected in modal
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadVisible, showUpload, hideUpload] = useBoolean(false);
  const [uploadSaving, setUploadSaving] = useState(false);

  const intl = useIntl();

  const openModal = useSameCallback(() => {
    setModalDocuments(value || []);
    setModalVisible(true);
  });

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const confirmModal = useSameCallback(() => {
    onChange(modalDocuments);
    onSelectedDocumentsChange?.(modalDocuments);
    setModalVisible(false);
  });

  const hasValue = value?.length > 0;

  const buttonNode = (
    <Button
      onClick={openModal}
      loading={uploadSaving}
      type="primary"
      icon={hasValue ? <SwapOutlined /> : <CreateNewIcon />}
    >
      {selectButton ||
        (hasValue ? (
          <Fmt id="DocumentSelectFormItem.changeDocuments" />
        ) : (
          <Fmt id="DocumentSelectFormItem.selectDocuments" />
        ))}
    </Button>
  );

  const modalNode = (
    <DocumentSelectModal
      visible={modalVisible}
      onOk={confirmModal}
      onCancel={closeModal}
      title={<Fmt id={titleId || 'DocumentSelectFormItem.selectDocuments'} />}
      selectedDocuments={modalDocuments}
      setSelectedDocuments={setModalDocuments}
      multiple
      {...restProps}
    />
  );

  const onUploadSuccess = (uploadResult: DocumentCreateResult[]) => {
    if (!uploadResult) {
      hideUpload();
      return;
    }

    setUploadSaving(true);
    processDocumentCreateResults(uploadResult).then((results) => {
      setUploadSaving(false);

      const newValue = (value || []).filter((item) => !results.some((result) => result[1]?.id === item.id));
      results.forEach(([error, processed]) => {
        if (error) {
          messageError(error, intl);
        }
        if (processed) {
          newValue.push(processed);
        }
      });
      onChange(newValue);
      onSelectedDocumentsChange?.(newValue);
    });

    hideUpload();
  };

  const uploadButtonNode = useMemo(
    () => (
      <Button loading={uploadSaving} onClick={showUpload} size="small">
        <Fmt id="DocumentSelect.uploadDocuments" />
      </Button>
    ),
    [uploadSaving, showUpload]
  );

  const uploadModalNode = (
    <DocumentCreateMultipleFormModal visible={uploadVisible} onSubmit={onUploadSuccess} onClose={hideUpload} />
  );

  return (
    <>
      <FlowLayout>
        <Margin bottom>
          {buttonNode}
          {allowUploadNew && uploadButtonNode}
        </Margin>
      </FlowLayout>
      <StackPanel vertical scrollable className={styles.filesGrid}>
        <EmptyGate empty={value?.length === 0}>
          {value?.map((doc) => (
            <FlowLayout key={doc.id} growFirst className={styles.documentRow}>
              <PathDisplay alignLeft className={styles.pathDisplay} path={`${doc.directoryPath}/${doc.name}`} />
              <RevisionNumberTag state={doc.state} />
              <DeleteButton
                onDelete={() => {
                  const selected = value.filter((val) => val.id !== doc.id);
                  onChange(selected);
                  onSelectedDocumentsChange?.(selected);
                }}
              />
            </FlowLayout>
          ))}
        </EmptyGate>
      </StackPanel>
      {modalNode}
      {allowUploadNew && uploadModalNode}
    </>
  );
};

export const DocumentsSelectMultipleFormItem: FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(
  React.memo(DocumentsSelectMultipleFormItemComponent)
);
