import {
  DocumentReservationDto,
  DownloadUrl,
  EsticonObjectLinkDto,
  PrimaryFileDto,
  ProjectUserProfileListDto,
  RoleDto,
  WorkflowStateEnum,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import CommonDocumentRow, {
  CommonDocumentRowProps,
  DocumentRowType,
} from 'components/CommonDocumentRow/CommonDocumentRow';
import DocumentRowDate from 'components/DocumentRow/DocumentRow.Date';
import DocumentRowDescription from 'components/DocumentRow/DocumentRow.Description';
import DocumentRowName from 'components/DocumentRow/DocumentRow.Name';
import DocumentRowPath from 'components/DocumentRow/DocumentRow.Path';
import DownloadFileButton from 'components/DownloadFileButton';
import {
  EsticonObjectGridLink,
  checkEsticonObjectDisabled,
} from 'components/EsticonObjectGridLink/EsticonObjectGridLink';
import { LabelLinkType } from 'components/LabelDisplay/LabelDisplay';
import LabelsStack from 'components/LabelsStack';
import ReserveFileButton from 'components/ReserveFileButton';
import RevisionNumberTag from 'components/RevisionNumberTag';
import SignFileButton from 'components/SignFileButton';
import { MultiUserAvatar } from 'components/avatars/MultiUserAvatar/MultiUserAvatar';
import { UserAvatarSize } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useActiveProject } from 'hooks';
import { InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import styles from './DocumentRow.module.less';

type IconSizesType = 'small' | 'middle' | 'large';

const ICONS_SIZE: IconSizesType = 'small';
const AVATAR_SIZES_MAP: Record<IconSizesType, UserAvatarSize> = {
  small: UserAvatarSize.Medium,
  middle: UserAvatarSize.Large,
  large: UserAvatarSize.ExtraLarge,
};

export type DocumentRowProps = Omit<CommonDocumentRowProps, 'children'> & {
  getDownloadUrl?: () => Promise<[ApiError, AxiosResponse<DownloadUrl>]>;
  name: string;
  nameLink?: string;
  labels?: Guid[];
  createdDate?: string;
  createdBy?: ProjectUserProfileListDto;
  disableReserve?: boolean;
  revisionNo: number;
  revisionDate: string;
  revisionUser?: ProjectUserProfileListDto;
  path?: string;
  pathGuid?: string;
  pathLink?: string;
  reservedBy?: ProjectUserProfileListDto;
  reservedDate?: string;
  onReservationChange?: (reservation?: DocumentReservationDto) => void;
  reservationDisabled?: boolean;
  state: WorkflowStateEnum;
  additionalButtons?: ReactNode;
  disableDownload?: boolean;
  size?: number;
  esticonObjectLink?: EsticonObjectLinkDto;
  revisionDateLabel?: IntlMessageId;
  onSignatureClick?: (documentId: Guid) => void;
  signatureDisabled?: boolean;
  description?: string;
  documentOwner?: RoleDto;
  onDownloadWithAnnotations?: () => void;
  documentLinkCreateDate?: string;
  releaseReservedDate?: IsoDateTime;
  primaryFile?: PrimaryFileDto;
  isModel?: boolean;
};

const DocumentRow: FunctionComponent<InjectedIntlProps & DocumentRowProps> = ({
  id,
  getDownloadUrl,
  style,
  thumbnailUrl,
  name,
  nameLink,
  labels,
  createdDate,
  createdBy,
  disableReserve,
  revisionNo,
  revisionDate,
  revisionUser,
  primaryFileContentType,
  path,
  pathGuid,
  pathLink,
  onClick,
  reservedBy,
  reservedDate,
  reservationDisabled,
  onReservationChange,
  state,
  additionalButtons,
  disableDownload,
  revisionDateLabel,
  onSignatureClick,
  signatureDisabled,
  intl,
  size,
  esticonObjectLink,
  description,
  documentOwner,
  documentRowType = DocumentRowType.Document,
  onDownloadWithAnnotations,
  documentLinkCreateDate,
  releaseReservedDate,
  isModel,
  ...restProps
}) => {
  const getTooltipDate = (date: string) => {
    return moment(date)
      .locale(intl.locale)
      .format('lll');
  };

  const attachmentTooltip = intl.formatMessage(
    { id: 'DocumentRow.tooltip.attachmentUpload' },
    {
      date: getTooltipDate(createdDate),
    }
  );
  const revisionTooltip = intl.formatMessage(
    { id: revisionDateLabel || 'DocumentRow.tooltip.lastRevision' },
    {
      date: getTooltipDate(revisionDate),
    }
  );
  const documentTooltip = intl.formatMessage(
    {
      id: 'DocumentRow.tooltip.originalDocument',
    },
    {
      date: getTooltipDate(createdDate),
    }
  );
  const documentLinkTooltip = intl.formatMessage(
    {
      id: 'DocumentRow.tooltip.documentLinkCreated',
    },
    {
      date: getTooltipDate(documentLinkCreateDate),
    }
  );

  const handleReservationChange = useCallback(
    (reservation?: DocumentReservationDto) => {
      onReservationChange && onReservationChange(reservation);
    },
    [onReservationChange]
  );

  const activeProject = useActiveProject();

  const handleSignatureButtonClick = useCallback(() => {
    onSignatureClick && onSignatureClick(id);
  }, [onSignatureClick, id]);

  const esticonLinkObjectDisabled = checkEsticonObjectDisabled(state);
  const isNew = moment().diff(revisionDate, 'hours', true) < 1;

  return (
    <CommonDocumentRow
      id={id}
      style={style}
      thumbnailUrl={thumbnailUrl}
      primaryFileContentType={primaryFileContentType}
      documentRowType={documentRowType}
      onClick={onClick}
      {...restProps}
    >
      <div className={styles.main}>
        <div className={styles.nameLabels}>
          <DocumentRowName
            name={name}
            nameLink={nameLink}
            activeProjectId={activeProject?.id}
            tooltip={restProps.documentLinkTooltip || name}
            hasOnClick={!!onClick}
            isModel={isModel}
          />
          {labels && labels.length > 0 && <LabelsStack labels={labels} linkType={LabelLinkType.Document} />}
        </div>
        {(!path || !pathGuid) && <DocumentRowDescription description={description} />}
        <DocumentRowPath
          path={path}
          pathGuid={pathGuid}
          activeProjectId={activeProject?.id}
          hasOnClick={!!onClick}
          pathLink={pathLink}
        />
      </div>
      <FlowLayout alignRight>
        {createdDate && (
          <DocumentRowDate
            date={documentLinkCreateDate || revisionDate || createdDate}
            tooltip={!!revisionDate ? revisionTooltip : attachmentTooltip}
            documentTooltip={!!revisionDate && documentTooltip}
            documentLinkTooltip={!!documentLinkCreateDate && documentLinkTooltip}
            size={size}
          />
        )}
        <RevisionNumberTag no={revisionNo} state={state} date={revisionDate} trigger="click" />
        {esticonObjectLink && (
          <EsticonObjectGridLink
            esticonObjectId={esticonObjectLink.esticonObjectId}
            objectType={esticonObjectLink.category}
            disabled={
              esticonLinkObjectDisabled && intl.formatMessage({ id: 'EsticonObjectLink.disabledByEsticonLink' })
            }
            isPartial={esticonObjectLink.isDilci}
            tooltipPlacement="leftTop"
          />
        )}

        {additionalButtons}
        {createdBy && (
          <MultiUserAvatar
            title={name}
            popoverPlacement="leftTop"
            revisionUser={revisionUser}
            documentUser={createdBy}
            documentOwner={documentOwner}
            size={AVATAR_SIZES_MAP[ICONS_SIZE]}
          />
        )}
        {onSignatureClick && (
          <SignFileButton
            disabled={signatureDisabled}
            size={ICONS_SIZE}
            onClick={handleSignatureButtonClick}
            tooltipPlacement="leftTop"
          />
        )}
        {!disableReserve && (
          <ReserveFileButton
            documentId={id}
            reservedBy={reservedBy}
            reservedDate={reservedDate}
            disabled={reservationDisabled}
            onChanged={handleReservationChange}
            size={ICONS_SIZE}
            tooltipPlacement="leftTop"
            releaseReservedDate={releaseReservedDate}
          />
        )}
        {!disableDownload && !!getDownloadUrl && (
          <DownloadFileButton
            size={ICONS_SIZE}
            getUrl={getDownloadUrl}
            onDownloadWithAnnotations={onDownloadWithAnnotations}
            tooltip
            tooltipPlacement="leftTop"
          />
        )}
      </FlowLayout>
    </CommonDocumentRow>
  );
};

export default memoizeWithIntl(DocumentRow);
