import { message, Typography } from 'antd';
import { api } from 'api';
import { EstiCategoryEnum, EstiProjectSettingsBaseDto, EstiProjectSettingsDto } from 'api/completeApiInterfaces';
import Axios from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import StackPanel from 'components/StackPanel';
import { useActiveProject, useCancelToken, useIntl, useSameCallback, useSelectorDispatch } from 'hooks';
import { useApiData } from 'hooks/useApiData';
import produce from 'immer';
import { Fmt } from 'locale';
import { isEqual, mapValues } from 'lodash';
import React, { FunctionComponent, Reducer, useCallback, useMemo, useReducer, useState } from 'react';
import { messageError } from 'utils';
import { ExternAppDetailProps } from '../../externAppsTypes';
import { ESTICON_CATEGORIES_ORDERED } from './esticonCategoriesOrdered';
import styles from './EsticonDetail.module.less';
import { EsticonDetailDirectory } from './EsticonDetailDirectory';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { useRouteMatch } from 'react-router-dom';

export type EsticonSettingsWithErrors = EstiProjectSettingsBaseDto & {
  esticonDirectoryErrors: Record<EstiCategoryEnum, string[]>;
  esticonFileErrors: Record<EstiCategoryEnum, string>;
};

const esticonSettingsToCurrentSettings = (settings: EstiProjectSettingsDto): EsticonSettingsWithErrors => ({
  esticonDirectories: mapValues(settings.esticonDirectories, (directory) => directory?.id),
  esticonDirectoryMasks: settings.esticonDirectoryMasks,
  esticonFileMasks: settings.esticonFileMasks,
  // TODO: need to send object keys correctly
  esticonDirectoryErrors: mapValues(settings.esticonDirectoryMasks, (masks) =>
    new Array(masks.length).fill(null)
  ) as any,
  esticonFileErrors: mapValues(settings.esticonFileMasks, (_mask) => null) as any,
});

type SetDataAction = {
  type: 'setData';
  data: EstiProjectSettingsDto;
};

type SetDirectoryAction = {
  type: 'setDirectory';
  directory: EstiCategoryEnum;
  directoryId: Guid;
};

type SetPathMaskAction = {
  type: 'setPathMask';
  directory: EstiCategoryEnum;
  pathIndex: number;
  mask: string;
};

type SetPathErrorAction = {
  type: 'setPathError';
  directory: EstiCategoryEnum;
  pathIndex: number;
  error: string;
};

type AddPathMaskAction = {
  type: 'addPathMask';
  directory: EstiCategoryEnum;
};

type RemovePathMaskAction = {
  type: 'removePathMask';
  directory: EstiCategoryEnum;
  pathIndex: number;
};

type SetFileMaskAction = {
  type: 'setFileMask';
  directory: EstiCategoryEnum;
  mask: string;
};

type SetFileErrorAction = {
  type: 'setFileError';
  directory: EstiCategoryEnum;
  error: string;
};

export type EsticonSettingsAction =
  | SetDataAction
  | SetDirectoryAction
  | SetPathMaskAction
  | SetPathErrorAction
  | AddPathMaskAction
  | RemovePathMaskAction
  | SetFileMaskAction
  | SetFileErrorAction;

const esticonSettingsReducer: Reducer<EsticonSettingsWithErrors, EsticonSettingsAction> = (prevState, action) => {
  switch (action.type) {
    case 'setData':
      return esticonSettingsToCurrentSettings(action.data);
    case 'setDirectory':
      return produce(prevState, (draft) => {
        draft.esticonDirectories[action.directory] = action.directoryId;
      });
    case 'setPathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory][action.pathIndex] = action.mask;
      });
    case 'setPathError':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryErrors[action.directory][action.pathIndex] = action.error;
      });
    case 'addPathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory].push('');
        draft.esticonDirectoryErrors[action.directory].push(null);
      });
    case 'removePathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory].splice(action.pathIndex, 1);
        draft.esticonDirectoryErrors[action.directory].splice(action.pathIndex, 1);
      });
    case 'setFileMask':
      return produce(prevState, (draft) => {
        draft.esticonFileMasks[action.directory] = action.mask;
      });
    case 'setFileError':
      return produce(prevState, (draft) => {
        draft.esticonFileErrors[action.directory] = action.error;
      });
  }
};

export const EsticonDetail: FunctionComponent<ExternAppDetailProps> = () => {
  const project = useActiveProject();
  const { url } = useRouteMatch();

  const esticonProjectsState = useSelectorDispatch(
    (store) => store.esticonProjects,
    (dispatch) => dispatch.esticonProjects.loadData({ reload: false })
  );

  const esticonFirmsState = useSelectorDispatch(
    (state) => state.esticonFirms,
    (dispatch) => dispatch.esticonFirms.loadData({ reload: false })
  );

  const esticonFirm = useMemo(() => esticonFirmsState.data?.find((firm) => firm.id === project?.esticonFirmId), [
    esticonFirmsState,
    project,
  ]);

  const esticonProject = useMemo(
    () => esticonProjectsState.data?.find((esticon) => esticon.id === project?.esticonProjectId),
    [esticonProjectsState, project?.esticonProjectId]
  );

  const [currentSettings, dispatchCurrentSettings] = useReducer(esticonSettingsReducer, null);

  const [
    esticonSettings,
    esticonSettingsError,
    esticonSettingsLoading,
    _reloadEsticonSettings,
    setEsticonSettings,
  ] = useApiData(api.project.projectSetting.getEsticonSettings, {
    autoload: true,
    fetchCallback: (data) => dispatchCurrentSettings({ type: 'setData', data }),
  });

  const intl = useIntl();

  const isDirty = useMemo(
    () => esticonSettings && !isEqual(esticonSettingsToCurrentSettings(esticonSettings), currentSettings),
    [esticonSettings, currentSettings]
  );

  const [saving, setSaving] = useState<boolean>(false);

  const cleanupToken = useCancelToken('EsticonDetail: unmounting', []);

  const handleSave = useSameCallback(async () => {
    if (saving) return;

    setSaving(true);
    const [err, resp] = await api.project.projectSetting.saveEsticonSettings(currentSettings, cleanupToken);
    if (Axios.isCancel(err)) return;
    setSaving(false);

    if (err) {
      messageError(err, intl);
    } else {
      message.success(intl.formatMessage({ id: 'EsticonDetail.settingsSaveSuccess' }));
      setEsticonSettings(resp.data);
    }
  });

  const handleReset = useSameCallback(
    () => esticonSettings && dispatchCurrentSettings({ type: 'setData', data: esticonSettings })
  );

  const hasAnyError = useMemo(
    () =>
      currentSettings &&
      (Object.values(currentSettings.esticonDirectoryErrors).some((errors) => errors.some((error) => !!error)) ||
        Object.values(currentSettings.esticonFileErrors).some((error) => !!error)),
    [currentSettings]
  );

  const isToDisplay = useCallback(
    (directory: EstiCategoryEnum) => {
      if (
        directory === EstiCategoryEnum.Stavba ||
        (!esticonProject?.sdruzeni && directory === EstiCategoryEnum.CerpaniSdruzeni) ||
        (!esticonProject?.sdruzeni && directory === EstiCategoryEnum.FakturaSdruzeni)
      )
        return false;
      return true;
    },
    [esticonProject]
  );

  return (
    <MasterComponent
      url={url}
      maxWidth={1000}
      title={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.listItemTitle' })}
      children={() => (
        <ContentGate
          error={esticonFirmsState.error || esticonProjectsState.error || esticonSettingsError}
          loading={esticonFirmsState.loading || esticonProjectsState.loading || esticonSettingsLoading}
          empty={!esticonProject || !esticonFirm}
        >
          <StackPanel vertical stretch className={styles.mainPanel}>
            <FlowLayout alignRight>
              <SaveOrCancelButton
                onSave={handleSave}
                onCancel={handleReset}
                disabled={!isDirty}
                saveDisabled={hasAnyError}
                loading={saving}
              />
            </FlowLayout>
            <StackPanel vertical stretch scrollable className={styles.mainPanel}>
              <GeneralSettingsContainer itemsLargeGap>
                {esticonProject ? (
                  <GeneralSettingsContainer>
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectName" />}
                      description={esticonProject?.name}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.firm" />}
                      description={esticonFirm?.nazev}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectSign" />}
                      description={esticonProject?.sign}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectId" />}
                      description={esticonProject?.id}
                    />
                  </GeneralSettingsContainer>
                ) : (
                  <Typography.Text>
                    {!!esticonProjectsState.data ? (
                      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectMissing" />
                    ) : (
                      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectNotSet" />
                    )}
                  </Typography.Text>
                )}
                {currentSettings &&
                  ESTICON_CATEGORIES_ORDERED.filter((directory) => isToDisplay(directory)).map((directory) => (
                    <EsticonDetailDirectory
                      key={directory}
                      directory={directory}
                      currentSettings={currentSettings}
                      dispatchCurrentSettings={dispatchCurrentSettings}
                    />
                  ))}
              </GeneralSettingsContainer>
            </StackPanel>
          </StackPanel>
        </ContentGate>
      )}
    />
  );
};
