import { InputRef } from 'antd';
import { api } from 'api';
import {
  AppUserDto,
  OrganizationAdminReportDto,
  OrganizationCreateDto,
  OrganizationDto,
  OrganizationPatchDto,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import { useApiData, useIntl } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import OrganizationAddForm, { OrganizationAddFormData } from './OrganizationAddForm';

type Props = FormModalProps<OrganizationCreateDto> & {
  organizationToEdit?: OrganizationAdminReportDto;
  appUsers?: AppUserDto[];
  organizations: OrganizationAdminReportDto[];
  userCanAddOrganization: boolean;
  configStorageAreas?: string[];
};

const OrganizationAddFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    organizationToEdit,
    appUsers,
    organizations,
    userCanAddOrganization,
    configStorageAreas,
    ...restProps
  } = props;
  const intl = useIntl();
  const lang: LanguageEnum = useMemo(() => intl.locale, [intl]);

  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => api.master.projects.getTimeZones(lang, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [lang]);

  const handleSubmit: FormSubmitHandler<OrganizationAddFormData> = useCallback(
    async (values) => {
      const data: OrganizationCreateDto & OrganizationPatchDto = {
        id: organizationToEdit?.id,
        name: values.name,
        description: values.description,
        defaultLanguage: values.defaultLanguage,
        defaultTimeZoneId: values.defaultTimeZoneId,
        projectAdminCanInviteNewUser: values.projectAdminCanInviteNewUser,
        users: !!organizationToEdit
          ? null
          : Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
              mail: mail,
              language: attributes.defaultLanguage,
              isAdmin: attributes.isAdmin,
              namesFromOrganization: false,
              firstname: undefined,
              lastname: undefined,
            })),
        defaultStorageArea: values.allowableStorageAreas,
      };

      let err: ApiError;
      let res: AxiosResponse<OrganizationDto> = undefined;

      if (!!organizationToEdit) {
        [err] = await api.master.organization.patchorganization(data);
      } else {
        [err, res] = await api.master.organization.addorganization(data);
      }
      if (err) return err;
      onSubmit(res?.data);
      return null;
    },
    [organizationToEdit, onSubmit]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={!!organizationToEdit ? 'general.editOrganization' : 'general.addOrganization'}
      submitTextId="general.save"
      submitTextLoadingId={null}
      width={800}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <OrganizationAddForm
          intl={intl}
          wrappedComponentRef={formRef}
          timeZones={timeZones}
          currentOrganization={organizationToEdit}
          appUsers={appUsers}
          organizations={organizations}
          setRef={setInputRef}
          configStorageAreas={configStorageAreas}
          userCanAddOrganization={userCanAddOrganization}
        />
      )}
    </FormModalWrapper>
  );
};

export default OrganizationAddFormModal;
