import { ExportOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { ProjectUserProfileDto } from 'api/completeApiInterfaces';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import UserActivityReportExportFormModal from './UserActivityReportExportFormModal';

type Props = { currentUser: ProjectUserProfileDto } & Pick<ButtonProps, 'type' | 'size' | 'icon'>;

const UserActivityReportExportButtonComponent: FunctionComponent<Props> = ({ currentUser, ...buttonProps }) => {
  const [isExportVisible, showExportModal, hideExportModal] = useBoolean(false);

  if (!currentUser.isAdmin) {
    return <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />;
  }

  return (
    <>
      <Button type="primary" size="middle" icon={<ExportOutlined />} onClick={showExportModal} {...buttonProps}>
        <Fmt id="general.export" />
      </Button>
      <UserActivityReportExportFormModal
        visible={isExportVisible}
        onClose={hideExportModal}
        onSubmit={hideExportModal}
      />
    </>
  );
};

export const UserActivityReportExportButton = React.memo(UserActivityReportExportButtonComponent);
