import { DownOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  onSave: () => void;
  onCancel: () => void;
  disabled?: boolean;
  saveDisabled?: boolean;
  loading?: boolean;
};

export const SaveOrCancelButton: FunctionComponent<Props> = ({ onSave, onCancel, disabled, saveDisabled, loading }) => {
  const menu = (
    <Menu>
      <Menu.Item key="cancel" onClick={onCancel}>
        <Fmt id="general.cancelChanges" />
      </Menu.Item>
    </Menu>
  );

  return (
    <Space.Compact>
      <Button
        icon={<SaveOutlined />}
        type="primary"
        disabled={disabled || saveDisabled}
        onClick={onSave}
        loading={loading}
      >
        <Fmt id="general.save" />
      </Button>
      <Dropdown overlay={menu} disabled={disabled || loading} placement="bottomRight">
        <Button type="primary" icon={<DownOutlined />} />
      </Dropdown>
    </Space.Compact>
  );
};
