import { useCallback, useState } from 'react';

/**
 * useState for boolean with prepared callbacks for setting, reseting and toggling the value
 * @param initialValue initial value for `useState`
 * @returns `[value, set, clear, toggle, setValue]`
 *  - `value` - current value of the state
 *  - `set` - sets the value to `true`
 *  - `clear` - clears the value to `false`
 *  - `toggle` - toggles the value from `true` to `false` or vice versa
 *  - `setValue` - regular setter from `useState`
 */
export const useBoolean = (initialValue?: boolean | (() => boolean)) => {
  const [value, setValue] = useState<boolean>(initialValue);

  const set = useCallback(() => setValue(true), []);

  const clear = useCallback(() => setValue(false), []);

  return [value, set, clear, setValue] as const;
};
