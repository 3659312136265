import { List, Modal, Typography } from 'antd';
import {
  IMultipleDocumentErrorData,
  transformErrorIntl,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { InjectedIntl } from 'locale';
import React from 'react';
import styles from './DocumentSelect.module.less';

export const documentSelectHandleMultiError = async (
  originalIds: Guid[],
  errorData: IMultipleDocumentErrorData,
  intl: InjectedIntl
) => {
  return new Promise<Guid[]>((resolve) => {
    const errors = errorData.documentsErrors.map((err) => transformErrorIntl(err, intl));
    const errorList = (
      <List
        className={styles.errorList}
        size="small"
        bordered
        dataSource={errors}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
    if (errors.length < originalIds.length) {
      const content = (
        <>
          <p className={styles.errorLabel}>
            <Typography.Text type="danger" strong>
              {intl.formatMessage(
                { id: 'DocumentSelect.multierror.someDocumentsHaveError' },
                { count: errors.length, total: originalIds.length }
              )}
            </Typography.Text>
          </p>
          {errorList}
          <p className={styles.errorLabel}>
            <Typography.Text strong>
              {intl.formatMessage({ id: 'DocumentSelect.multierror.continuePrompt' })}
            </Typography.Text>
          </p>
        </>
      );
      Modal.confirm({
        title: intl.formatMessage({ id: 'DocumentSelect.multierror.someDocumentsTitle' }),
        content,
        onOk: () => {
          const removedIds = new Set(errorData.documentsErrors.map((doc) => doc.objectId));
          resolve(originalIds.filter((id) => !removedIds.has(id)));
        },
        onCancel: () => resolve(null),
        width: 600,
      });
    } else {
      const content = (
        <>
          <p className={styles.errorLabel}>
            <Typography.Text type="danger" strong>
              {intl.formatMessage({ id: 'DocumentSelect.multierror.allDocumentsHaveError' }, { count: errors.length })}
            </Typography.Text>
          </p>
          {errorList}
        </>
      );

      Modal.error({
        title: intl.formatMessage({ id: 'DocumentSelect.multierror.allDocumentsTitle' }),
        content,
        onOk: () => resolve(null),
        onCancel: () => resolve(null),
        width: 600,
      });
    }
  });
};
