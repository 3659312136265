export const FORGE_VIEWER_VERSION = '7.93.1';
export type AutodeskViewOptions = {
  mimeType: string;
  sourceFileWarning: boolean;
};
export const DEFAULT_AUTODESK_VIEW_OPTIONS: AutodeskViewOptions = {
  mimeType: 'application/vnd.autodesk.cad',
  sourceFileWarning: false,
};
export const getAutodeskViewOptions = (extension: string): AutodeskViewOptions | undefined => {
  switch (extension?.toLowerCase()) {
    case 'rvt':
    case 'rfa':
    case 'rte':
    case 'rft':
      return { mimeType: 'application/vnd.autodesk.revit', sourceFileWarning: false };
    case 'ipt':
    case 'iam':
    case 'idw': // This is related to DWG?
      return { mimeType: 'application/vnd.autodesk.inventor.assembly', sourceFileWarning: false };
    case 'nwd':
      return { mimeType: 'application/vnd.autodesk.navisworks', sourceFileWarning: false };
    case 'dng':
    case 'dwf':
    case 'ifc':
    case 'sat':
    case 'skp':
      return { mimeType: 'application/vnd.autodesk.cad', sourceFileWarning: true };
    case 'dxf':
      return { mimeType: 'application/vnd.autodesk.dxf', sourceFileWarning: false };
    case 'dwg':
      // TODO: dwg needs to be compared as a revit file?
      // return 'application/vnd.autodesk.autocad.dwg';
      return { mimeType: 'application/vnd.autodesk.revit', sourceFileWarning: true };
    default:
      return undefined;
  }
};
