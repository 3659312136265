import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt } from 'locale';
import randomColor from 'randomcolor';
import React, { Component, Fragment } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import styles from './ColorPickerInput.module.less';

type ColorPickerInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  size?: 'small' | 'default';
};

type ColorPickerInputState = { color: string; displayColorPicker: boolean };

class ColorPickerInput extends Component<ColorPickerInputProps, ColorPickerInputState> {
  constructor(props: ColorPickerInputProps) {
    super(props);

    this.state = {
      color: props.value,
      displayColorPicker: false,
    };
  }

  componentDidMount() {
    // generate random color when not set
    if (!this.props.value) this.handleGenerateColor();
  }

  private triggerChange = (color: string) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(color);
    }
  };

  handleChangeComplete = (color: ColorResult) => {
    this.setState({ color: color.hex });
    this.triggerChange(color.hex);
  };

  handleTogglePicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClosePicker = () => {
    this.setState({ displayColorPicker: false });
  };

  handleGenerateColor = () => {
    const color = randomColor() as string;
    this.setState({ color });
    this.triggerChange(color);
  };

  render() {
    return (
      <Fragment>
        <div className={classNames(styles.swatch, styles[this.props.size || 'default'])}>
          <div
            className={styles.color}
            style={{ backgroundColor: this.state.color }}
            onClick={this.handleTogglePicker}
          />
          <CommonHubTooltip placement="bottomLeft" title={<Fmt id="ColorPickerInput.refresh" />}>
            <SyncOutlined className={styles.refresh} onClick={this.handleGenerateColor} />
          </CommonHubTooltip>
        </div>
        {this.state.displayColorPicker && (
          <div className={styles.popover}>
            <div className={styles.cover} onClick={this.handleClosePicker} />
            <TwitterPicker color={this.state.color} onChangeComplete={this.handleChangeComplete} />
          </div>
        )}
      </Fragment>
    );
  }
}

export default ColorPickerInput;
