import { api } from 'api';
import { CommentProcedureSetStateStrategyEnum, MsgCenterSetStatusDto, MsgStatusEnum } from 'api/completeApiInterfaces';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import { MasterDetailHeader } from 'components/MasterDetailsView/MasterDetailHeader/MasterDetailHeader';
import {
  MasterComponent,
  MasterDetailsContainer,
  MasterDetailsViewContextProvider,
} from 'components/MasterDetailsView/MasterDetailsView';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import StackPanel from 'components/StackPanel';
import { useActiveProject, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import MessageCenterMenu from 'pages/MessageCenterPage/MessageCenterMenu';
import queryString from 'query-string';
import React, { FunctionComponent, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { MessageCenterRouteParams } from 'routes';
import { projectsListSelector } from 'store/selectors';
import { messageError } from 'utils';
import { MessageCenterAssignments } from './assignments/MessageCenterAssignments';
import { MessageCenterSettings } from './settings/MessageCenterSettings';
import { MessageCenterMessages } from './tabs/MessageCenterMessages';
import { MessageCenterReports } from './tabs/MessageCenterReports';

type Props = RouteComponentProps<MessageCenterRouteParams>;

const MessageCenterPage: FunctionComponent<Props> = ({ match }) => {
  const queryParams = queryString.parseUrl(window.location.search);

  const intl = useIntl();
  const projectId = useActiveProject()?.id;
  const messageId = match.params.messageId;
  const url = match.url;

  const createFromDate = queryParams.query['from'] as string;
  const createToDate = queryParams.query['to'] as string;

  const projectList = useSelectorDispatch(projectsListSelector, (dispatch) =>
    dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const setMessageState = useCallback(
    async (selectedMessages: Guid[], status: MsgStatusEnum) => {
      const statusDto: MsgCenterSetStatusDto = {
        messages: selectedMessages,
        status: status,
        strategy: CommentProcedureSetStateStrategyEnum.ignoreBadStateDocuments,
      };
      const [error] = await api.master.messageCenter.setStatus(statusDto);

      if (error) {
        messageError(error, intl);
        return false;
      }

      return true;
    },
    [intl]
  );

  return (
    <MasterDetailsViewContextProvider>
      <SideMenuActivator menuItemKey={SideMenuKey.EMPTY}>
        <StackPanel vertical stretch scrollable>
          <DocumentsGridHeaderStyled marginBottom>
            <MasterDetailHeader />
          </DocumentsGridHeaderStyled>
          <StackPanel vertical stretch>
            <MasterDetailsContainer hideTitle>
              <MasterComponent
                noFlex
                minWidth={200}
                maxWidth={200}
                url={url}
                title={intl.formatMessage({ id: 'MessageCenterPage.title' })}
                children={(onSelect, selectedItemId) => (
                  <MessageCenterMenu onSelect={onSelect} url={match.url} selectedKeys={[selectedItemId]} />
                )}
              />
              <Switch>
                <Route path={`${url}/assignments`} component={MessageCenterAssignments} />
                <Route
                  path={`${url}/messages`}
                  render={() => (
                    <MessageCenterMessages
                      defaultProjectId={projectId}
                      projectList={projectList}
                      setMessageState={setMessageState}
                    />
                  )}
                />
                <Route
                  path={`${url}/report`}
                  render={() => (
                    <MessageCenterReports
                      defaultProjectId={projectId}
                      defaultFromFilter={createFromDate}
                      defaultToFilter={createToDate}
                      projectList={projectList}
                      messageId={messageId}
                      setMessageState={setMessageState}
                    />
                  )}
                />
                <Route path={`${url}/settings`} render={() => <MessageCenterSettings projectList={projectList} />} />
              </Switch>
            </MasterDetailsContainer>
          </StackPanel>
        </StackPanel>
      </SideMenuActivator>
    </MasterDetailsViewContextProvider>
  );
};

export default MessageCenterPage;
