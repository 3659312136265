import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import { GroupPatchDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem, groupSelectUserFooter } from 'components/UserTransfer/UserTransfer';
import { MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { useDispatchEffect, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { InjectedIntlProps } from 'locale';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { useEffect, useMemo } from 'react';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type GroupFormData = {
  name: string;
  description: string;
  userIds: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = FormComponentProps<GroupFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    defaults?: GroupPatchDto;
    setRef: (ref: InputRef) => void;
  };

const GroupFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, validateUniqueName, defaults, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    useDispatchEffect((dispatch) => {
      dispatch.projectUsers.loadData({ reload: false });
      dispatch.groups.loadData({ reload: false });
    }, []);

    useDirtyStoreReload(
      (store) => store.groups,
      (dispatch) => dispatch.groups
    );

    useDirtyStoreReload(
      (store) => store.projectUsers,
      (dispatch) => dispatch.projectUsers
    );

    const users = useStoreSelector(projectUsersListSelector);
    const groups = useStoreSelector(groupOrderedListSelector);

    const createFooter = useMemo(() => groupSelectUserFooter(groups, users), [groups, users]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {form.getFieldDecorator<GroupFormData>('name', {
            initialValue: defaults?.name,
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              duplicateNameRuleCallback(validateUniqueName),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'GroupForm.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {form.getFieldDecorator<GroupFormData>('description', {
            initialValue: defaults?.description,
            rules: [
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              {
                max: MAX_ITEM_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_ITEM_DESCRIPTION_LENGTH }
                ),
              },
            ],
          })(<Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.userIds.label' })}>
          {form.getFieldDecorator<GroupFormData>('userIds', {
            initialValue: defaults?.userIds || [],
          })(<UserTransferFormItem users={users} createFooter={createFooter} />)}
        </Form.Item>
      </Form>
    );
  }
);

export const GroupForm = React.memo(Form.create<Props>()(GroupFormComponent));
