import { Checkbox, Slider, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DirectoriesTreeSelect } from 'components/DirectoriesTreeSelect';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel';
import { useCache } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { KeyOfType } from 'utils/types';
import styles from './SearchPageSettings.module.less';

export type SearchSettings = {
  settingsVisible: boolean;

  searchInName: boolean;
  searchInDescription: boolean;
  searchInRevisionNote: boolean;
  searchInContent: boolean;
  searchInMetadata: boolean;

  currentRevisionOnly: boolean;

  directoryId: Guid | null;
  includeSubdirectories: boolean;

  showDetail: boolean;
  detailSize: number;
};

export const DEFAULT_SEARCH_SETTINGS: SearchSettings = {
  settingsVisible: false,

  searchInName: true,
  searchInDescription: true,
  searchInRevisionNote: true,
  searchInContent: true,
  searchInMetadata: true,

  currentRevisionOnly: true,

  directoryId: null,
  includeSubdirectories: true,

  showDetail: true,
  detailSize: 150,
};

type Props = {
  settings: SearchSettings;
  setSettings: React.Dispatch<React.SetStateAction<SearchSettings>>;
};

const SearchPageSettingsComponent: FunctionComponent<Props> = ({ settings, setSettings }) => {
  const checkboxValueSetter = useCache(
    (key: KeyOfType<SearchSettings, boolean>) => (event: CheckboxChangeEvent) => {
      const checked = event.target.checked;
      setSettings((value) => ({ ...value, [key]: checked }));
    },
    [setSettings]
  );

  const handleDirectoryIdChange = useCallback(
    (directoryId: Guid) => setSettings((value) => ({ ...value, directoryId })),
    [setSettings]
  );

  const handleDetailSizeChange = useCallback(
    (detailSize: number) => {
      setSettings((value) => ({ ...value, detailSize: detailSize as number }));
    },
    [setSettings]
  );

  if (!settings.settingsVisible) {
    return null;
  }

  return (
    <StackPanel vertical padding scrollable className={styles.settings}>
      <Typography.Title level={4}>
        <Fmt id="SearchPageSettings.filterSettings" />
      </Typography.Title>

      <Checkbox checked={settings.searchInName} onChange={checkboxValueSetter('searchInName')}>
        <Fmt id="SearchPageSettings.searchInName" />
      </Checkbox>
      <Checkbox checked={settings.searchInDescription} onChange={checkboxValueSetter('searchInDescription')}>
        <Fmt id="SearchPageSettings.searchInDescription" />
      </Checkbox>
      <Checkbox checked={settings.searchInRevisionNote} onChange={checkboxValueSetter('searchInRevisionNote')}>
        <Fmt id="SearchPageSettings.searchInRevisionNote" />
      </Checkbox>
      <Checkbox checked={settings.searchInContent} onChange={checkboxValueSetter('searchInContent')}>
        <Fmt id="SearchPageSettings.searchInContent" />
      </Checkbox>
      <Checkbox checked={settings.searchInMetadata} onChange={checkboxValueSetter('searchInMetadata')}>
        <Fmt id="SearchPageSettings.searchInMetadata" />
      </Checkbox>

      <Margin top>
        <Checkbox checked={settings.currentRevisionOnly} onChange={checkboxValueSetter('currentRevisionOnly')}>
          <Fmt id="SearchPageSettings.currentRevisionOnly" />
        </Checkbox>
      </Margin>

      <Margin top bottom>
        <Fmt id="SearchPageSettings.directoryId" />
        <DirectoriesTreeSelect
          value={settings.directoryId}
          onChange={handleDirectoryIdChange}
          allowClear
          className={styles.directorySelect}
        />
        <Checkbox checked={settings.includeSubdirectories} onChange={checkboxValueSetter('includeSubdirectories')}>
          <Fmt id="SearchPageSettings.includeSubdirectories" />
        </Checkbox>
      </Margin>

      <Typography.Title level={4}>
        <Fmt id="SearchPageSettings.displayConfig" />
      </Typography.Title>

      <Checkbox checked={settings.showDetail} onChange={checkboxValueSetter('showDetail')}>
        <Fmt id="SearchPageSettings.showDetail" />
      </Checkbox>
      <FlowLayout>
        <Fmt id="SearchPageSettings.detailSize" />
        <Slider
          min={100}
          max={500}
          value={settings.detailSize}
          onChange={handleDetailSizeChange}
          tooltipVisible={false}
          className={styles.sizeSlider}
        />
      </FlowLayout>
    </StackPanel>
  );
};

export const SearchPageSettings = React.memo(SearchPageSettingsComponent);
