import { CloseOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { ProjectHubDto, ProjectStatusEnum } from 'api/completeApiInterfaces';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import styles from './ProjectStatusPage.module.less';

type Props = InjectedIntlProps & {
  project: ProjectHubDto;
  reloadProject: () => void;
};

class ProjectStatusPage extends Component<Props> {
  private interval: NodeJS.Timeout;

  componentDidMount() {
    this.interval = setInterval(() => this.props.reloadProject(), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { project } = this.props;

    return (
      <div className={styles.page}>
        <div className={styles.box}>{this.getStatus(project.status)}</div>
      </div>
    );
  }

  getStatus(status: ProjectStatusEnum) {
    switch (status) {
      case ProjectStatusEnum.creating:
        return (
          <Fragment>
            <LoadingOutlined className={styles.loading} spin />
            <div className={styles.status}>
              <Fmt id="ProjectStatusPage.projectCreating.projectCreating" /> <b>{this.props.project.name}</b>...
            </div>
          </Fragment>
        );
      case ProjectStatusEnum.ready:
        return (
          <Fragment>
            <CheckOutlined className={styles.check} />
            <div className={styles.status}>
              <Fmt id="ProjectStatusPage.projectCreating.projectIsReady" />
            </div>
          </Fragment>
        );
      case ProjectStatusEnum.error:
        return (
          <Fragment>
            <CloseOutlined className={styles.close} />
            <div className={styles.status}>
              <Fmt id="ProjectStatusPage.projectCreating.projectError" />
            </div>
          </Fragment>
        );
      default:
        return 'Unknown???';
    }
  }
}

export default injectIntl(ProjectStatusPage);
