import { api, createCancelToken } from 'api';
import { projectApi } from 'api/completeApi';
import {
  DocumentLockPurposeEnum,
  DocumentReservationLockResponseDto,
  RevisionLockPurposeEnum,
  RevisionLockResponseDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import { baseProjectApi } from 'api/project/baseProjectApi';
import axios, { CancelToken } from 'axios';
import { useIntl, useIsMounted, useSameCallback } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { messageError, processApiError } from 'utils';

const LOCK_REFRESH_INTERVAl = 300; // 5 minutes

type ReservationProps = {
  lockDocument: (
    setLoading: (state: boolean) => void,
    setLockError: (error: ServiceError) => void,
    cancelToken: CancelToken
  ) => Promise<void>;
  unlockDocument: () => Promise<void>;
  documentId: Guid;
  isLockRequested: boolean;
  lockId?: Guid;
  isMounted: React.MutableRefObject<boolean>;
  unlockUrl: string;
};

type Props = {
  lockPurpose: DocumentLockPurposeEnum;
  documentId: Guid;
  revisionId: Guid;
  isLockRequested: boolean;
  onLockSuccess?: (lockResponse: DocumentReservationLockResponseDto) => void;
  onLockFail?: () => void;
  onLockedDocumentChanged?: (documentChanged: boolean, revisionChanged: boolean, annotationsChanged: boolean) => void;
};

type SigningProps = {
  lockPurpose: RevisionLockPurposeEnum;
  documentId: Guid;
  revisionId: Guid;
  isLockRequested: boolean;
  onLockSuccess?: (lockResponse: RevisionLockResponseDto) => void;
  onLockFail?: () => void;
};

export const useDocumentReservationLock = ({
  lockPurpose,
  documentId,
  revisionId,
  onLockSuccess,
  onLockFail,
  onLockedDocumentChanged,
  isLockRequested,
}: Props) => {
  const [lockData, setLockData] = useState<DocumentReservationLockResponseDto>();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const lockDocument = useSameCallback(
    async (
      setLoading: (state: boolean) => void,
      setLockError: (error: ServiceError) => void,
      cancelToken: CancelToken
    ) => {
      setLoading(true);
      const [err, res] = await api.project.documents.reservationLock(
        {
          documentId: documentId,
          purpose: lockPurpose,
          lockId: lockData?.lockId || null,
        },
        cancelToken
      );
      if (!isMounted.current) return;
      setLoading(false);

      if (err) {
        if (axios.isCancel(err)) {
          return;
        }
        onLockFail && onLockFail();
        setLockData(null);
        processApiError(err, setLockError);
      } else {
        const data = res.data;

        if (data.revisionId != revisionId) {
          processApiError(
            { message: intl.formatMessage({ id: 'DocumentReservationLock.error.oldRevision' }) } as ApiError,
            setLockError
          );
          onLockFail && onLockFail();
          return;
        }

        if (!!lockData) {
          const documentChanged = lockData.lastDocumentUpdate !== data.lastDocumentUpdate;
          const revisionChanged = lockData.lastRevisionUpdate !== data.lastRevisionUpdate;
          const annotationsChanged = lockData.lastAnotationUpdate !== data.lastAnotationUpdate;
          if (documentChanged || revisionChanged || annotationsChanged) {
            onLockedDocumentChanged && onLockedDocumentChanged(documentChanged, revisionChanged, annotationsChanged);
          }
        }

        if (data.reNew || !lockData) {
          onLockSuccess && onLockSuccess(data);
        }
        setLockData(data);
        setLockError(undefined);
        return;
      }
    }
  );

  const unlockDocument = useSameCallback(async () => {
    if (!lockData?.lockId) return;

    const [err] = await api.project.documents.reservationUnlock(lockData.lockId);

    if (err) {
      const errorData = processApiError(err);
      if (errorData.referenceErrorCode !== ServiceErrorEnum.DocumentReservationLockNotFoundError) {
        messageError(err, intl);
      }
    }
    if (isMounted) {
      setLockData(null);
    }
  });

  const reservationUnlockUrl = useMemo(
    () => `${baseProjectApi.defaults.baseURL}/documents/reservationlock/${lockData?.lockId}`,
    [lockData?.lockId]
  );

  const [loading, lockError] = useReservationLock({
    lockDocument,
    unlockDocument,
    documentId,
    isLockRequested,
    isMounted,
    unlockUrl: reservationUnlockUrl,
  });

  return [loading, lockData, lockError] as const;
};

export const useDocumentReservationSigningLock = ({
  lockPurpose,
  documentId,
  revisionId,
  onLockSuccess,
  onLockFail,
  isLockRequested,
}: SigningProps) => {
  const [lockData, setLockData] = useState<RevisionLockResponseDto>();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const lockDocument = useSameCallback(
    async (
      setLoading: (state: boolean) => void,
      setLockError: (error: ServiceError) => void,
      cancelToken: CancelToken
    ) => {
      setLoading(true);
      const [err, res] = await projectApi.documents.revisionlock.post(
        {
          revisionId: revisionId,
          purpose: lockPurpose,
          lockId: lockData?.lockId || null,
        },
        cancelToken
      );
      if (!isMounted.current) return;
      setLoading(false);

      if (err) {
        if (axios.isCancel(err)) {
          return;
        }
        onLockFail && onLockFail();
        setLockData(null);
        processApiError(err, setLockError);
      } else {
        const data = res.data;

        if (data.revisionId != revisionId) {
          processApiError(
            { message: intl.formatMessage({ id: 'DocumentReservationLock.error.oldRevision' }) } as ApiError,
            setLockError
          );
          onLockFail && onLockFail();
          return;
        }

        if (data.reNew || !lockData) {
          onLockSuccess && onLockSuccess(data);
        }
        setLockData(data);
        setLockError(undefined);
        return;
      }
    }
  );

  const unlockDocument = useSameCallback(async () => {
    if (!lockData?.lockId) return;

    const [err] = await projectApi.documents.revisionlock.id.delete(lockData.lockId);

    if (err) {
      const errorData = processApiError(err);
      if (errorData.referenceErrorCode !== ServiceErrorEnum.RevisionLockNotFoundError) {
        messageError(err, intl);
      }
    }
    if (isMounted) {
      setLockData(null);
    }
  });

  const reservationUnlockUrl = useMemo(
    () => `${baseProjectApi.defaults.baseURL}/documents/revisionlock/${lockData?.lockId}`,
    [lockData?.lockId]
  );

  const [loading, lockError] = useReservationLock({
    lockDocument,
    unlockDocument,
    documentId,
    isLockRequested,
    isMounted,
    unlockUrl: reservationUnlockUrl,
  });

  return [loading, lockData, lockError] as const;
};

const useReservationLock = ({
  lockDocument,
  unlockDocument,
  documentId,
  isLockRequested,
  lockId,
  isMounted,
  unlockUrl,
}: ReservationProps) => {
  const [lockError, setLockError] = useState<ServiceError>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cancelToken = createCancelToken();
    let refreshTimer: NodeJS.Timeout;

    const refreshLock = () => {
      lockDocument(setLoading, setLockError, cancelToken.token).then(() => {
        if (isLockRequested) {
          refreshTimer = setTimeout(refreshLock, LOCK_REFRESH_INTERVAl * 1000);
        }
      });
    };

    if (isLockRequested && !!documentId) {
      lockDocument(setLoading, setLockError, cancelToken.token).then(() => {
        refreshTimer = setTimeout(refreshLock, LOCK_REFRESH_INTERVAl * 1000);
      });
    }

    return () => {
      clearInterval(refreshTimer);
      cancelToken.cancel('Lock for reserved document is no longer required');
      unlockDocument();
    };
  }, [isLockRequested, documentId]);

  useEffect(() => {
    if (!!lockId) {
      const requestData = {
        fetchUrl: unlockUrl,
        defaultHeaders: baseProjectApi.defaults.headers,
        authorizationHeader: baseProjectApi.defaults.headers.common?.Authorization,
      };

      const handleUnloadReservationUnlock = async () => {
        if (document.visibilityState === 'hidden' && !isMounted.current) {
          await fetch(requestData.fetchUrl, {
            method: 'DELETE',
            headers: {
              ...requestData.defaultHeaders,
              Authorization: requestData.authorizationHeader,
              Connection: 'keep-alive',
            },
          });
        }
      };

      document.addEventListener('visibilitychange', handleUnloadReservationUnlock);
      return () => {
        window.removeEventListener('visibilitychange', handleUnloadReservationUnlock);
      };
    }

    return () => {};
  }, [lockId]);

  return [loading, lockError] as const;
};
