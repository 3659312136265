import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { ReactNode } from 'react';
import { IOption } from './SelectFilter';

export const createTranslatedOptions = <T extends string>(
  intl: InjectedIntl,
  options: T[],
  translateKey: (option: T) => IntlMessageId,
  translateLabel?: (option: T) => ReactNode
): IOption<T>[] =>
  options.map((option) => ({
    id: option,
    title: intl.formatMessage({ id: translateKey(option) }),
    label: translateLabel && translateLabel(option),
  }));
