import { FormOutlined } from '@ant-design/icons';
import { projectApi } from 'api/completeApi';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { useActiveProject, useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { userHasPermission } from 'utils/userHasPermission';
import { ExternAppListItemProps, ExternalAppSourceTypeEnum } from '../externAppsTypes';
import TextPageSetForm from './TextPageSetForm';

export const TextPageListItem: FunctionComponent<ExternAppListItemProps & {
  type: ExternalAppSourceTypeEnum.ExternalSource;
}> = ({ externalAppSetting, onDelete: _onDelete, ...listItemProps }) => {
  const dispatch = useDispatch<Dispatch>();
  const [isDocumentViewEditVisible, showDocumentViewEdit, hideDocumentViewEdit] = useBoolean();
  const currentUser = useCurrentProjectUser();
  const intl = useIntl();
  const activeProject = useActiveProject();

  const handleDelete = useCallback(async () => {
    const [err] = await projectApi.projectsetting.externalapplication.id.delete(externalAppSetting?.id);
    if (err) {
      messageError(err, intl);
    }
    void dispatch.externalApplicationsSettings.loadData({ reload: true });
  }, [externalAppSetting, intl]);

  const canEdit = currentUser.isAdmin || userHasPermission(currentUser, ExtendedPermissionEnum.externalApplications);
  const appSettingsWithDocument = {
    ...externalAppSetting,
    data: 'textPageId' in externalAppSetting.data ? externalAppSetting.data : { textPageId: undefined },
  };

  return (
    <>
      <GeneralSettingsItem
        selected={listItemProps.selected}
        icon={<FormOutlined />}
        title={<Fmt id="ProjectSettingsPage.ExternApps.TextPage.listItemTitle" />}
        description={<div>{externalAppSetting.name}</div>}
        additionalActions={
          <>
            {listItemProps.additionalActions}
            {canEdit && <EditButton onClick={showDocumentViewEdit} />}
            {canEdit && <DeleteButtonConfirm onConfirm={handleDelete} />}
          </>
        }
      />
      <TextPageSetForm
        open={isDocumentViewEditVisible}
        onAppAdded={hideDocumentViewEdit}
        onCancel={hideDocumentViewEdit}
        externalAppSetting={appSettingsWithDocument}
      />
    </>
  );
};
