import { TooltipProps } from 'antd/lib/tooltip';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import React, { FunctionComponent, useCallback } from 'react';

const CLICKED_FROM_CONTENT = Symbol('NoClickTooltip: clicked from content');

type EventType = React.MouseEvent & {
  [CLICKED_FROM_CONTENT]?: boolean;
};

const innerOnClick = (event: EventType) => {
  event[CLICKED_FROM_CONTENT] = true;
};

const outerOnClick = (event: EventType) => {
  if (event[CLICKED_FROM_CONTENT]) {
    delete event[CLICKED_FROM_CONTENT]; // clear the flag
    return;
  }
  event.preventDefault();
  event.stopPropagation();
};

type Props = TooltipProps;

export const NoClickTooltip: FunctionComponent<Props> = ({ children, trigger, ...restProps }) => {
  const onClick = useCallback(
    (event: EventType) => {
      if (trigger === 'click') {
        event.preventDefault();
        event.stopPropagation();
      }
      innerOnClick(event);
    },
    [trigger]
  );

  return (
    <span onClick={outerOnClick}>
      <CommonHubTooltip {...restProps} trigger={trigger}>
        <span onClick={onClick}>{children}</span>
      </CommonHubTooltip>
    </span>
  );
};
