import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { api } from 'api';
import { OrgLabelListDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import OnOffButtonWrap from 'components/FavoriteButton';
import OrganizationLabelsStack from 'components/LabelsStack/OrganizationLabelsStack';
import { LinkUnderscored } from 'components/LinkUnderscored/LinkUnderscored';
import { OnOffButton } from 'components/OnOffButton/OnOffButton';
import StyledSelectableRow from 'components/StyledSelectableRow';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedRelative, injectIntl } from 'react-intl';
import styles from './ProjectListPageRow.module.less';

const OnOffButtonRender = (isFavorite: boolean, saving: boolean, onClick: () => void) => (
  <OnOffButton
    saving={saving}
    onClick={onClick}
    isOn={isFavorite}
    onIcon={<StarFilled />}
    offIcon={<StarOutlined />}
    offLabel={<Fmt id="ProjectsListPage.addToFavorites" />}
    onLabel={<Fmt id="ProjectsListPage.removeFromFavorites" />}
  />
);

export type ProjectListPageRowProps = {
  style?: React.CSSProperties;
  name: string;
  organizationName: string;
  description?: string;
  onClick?: () => void;
  onClickCheckbox?: () => void;
  selected?: boolean;
  disableSelect?: boolean;
  createdDate: string;
  isFavorite: boolean;
  id: Guid;
  onChangeFavorites: () => void;
  handleOrganizationTagClick?: (organizationId: Guid) => void;
  organizationId?: Guid;
  organizationLabels: Dictionary<OrgLabelListDto>;
  projectLabels: Guid[];
} & InjectedIntlProps;

const ProjectListPageRow: FunctionComponent<ProjectListPageRowProps> = ({
  style,
  name,
  organizationName,
  description,
  onClick,
  onClickCheckbox,
  selected,
  disableSelect,
  createdDate,
  isFavorite,
  id,
  onChangeFavorites,
  intl,
  handleOrganizationTagClick,
  organizationId,
  projectLabels,
}) => {
  const createFavoriteProjects = useCallback((id: Guid) => {
    return api.master.projects.createFavoriteProject([id]);
  }, []);

  const removeFromFavoriteProjects = useCallback((id: Guid) => {
    return api.master.projects.deleteFromFavoriteProjects([id]);
  }, []);

  return (
    <StyledSelectableRow
      style={style}
      wrapperClassName={styles.wrapper}
      className={classNames(styles.row)}
      selected={selected}
      id={id}
      onClickCheckbox={onClickCheckbox}
      disableSelect={disableSelect}
      onClick={onClick}
    >
      <div className={styles.main}>
        <div className={styles.nameRow}>
          <CommonHubTooltip title={name} placement="right">
            {onClick ? (
              <span className={styles.nameLabels}>
                <DisplayName className={styles.name}>{name}</DisplayName>
              </span>
            ) : (
              <LinkUnderscored to={`/projects/${id}`} className={styles.nameLabels}>
                <DisplayName className={styles.name}>{name}</DisplayName>
              </LinkUnderscored>
            )}
          </CommonHubTooltip>
        </div>
        {!!description && (
          <div className={styles.description}>
            <CommonHubEllipsisText placement="bottom" title={description}>
              <DisplayName removeNewLines text={description} />
            </CommonHubEllipsisText>
          </div>
        )}
      </div>
      <OrganizationLabelsStack labels={projectLabels} className={styles.labels} />
      <CommonHubTooltip title={intl.formatMessage({ id: 'ProjectsListPage.motherOrganization' }, { organizationName })}>
        <Tag
          className={styles.tag}
          onClick={() => {
            handleOrganizationTagClick && handleOrganizationTagClick(organizationId);
          }}
        >
          {organizationName}
        </Tag>
      </CommonHubTooltip>
      {!onClick && (
        <CommonHubTooltip
          title={`${intl.formatMessage(
            { id: 'ProjectsListPage.createProject' },
            {
              date: moment(createdDate)
                .locale(intl.locale)
                .format('lll'),
            }
          )}`}
        >
          <span className={styles.date}>
            <FormattedRelative value={createdDate} />
          </span>
        </CommonHubTooltip>
      )}
      {onChangeFavorites && (
        <OnOffButtonWrap
          itemId={id}
          isOn={isFavorite}
          setOn={createFavoriteProjects}
          setOff={removeFromFavoriteProjects}
          onChange={onChangeFavorites}
          render={OnOffButtonRender}
        />
      )}
    </StyledSelectableRow>
  );
};
export default injectIntl(ProjectListPageRow);
