import { Switch } from 'antd';
import { SwitchChangeEventHandler, SwitchProps } from 'antd/lib/switch';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { ignoreRef } from 'utils';
import styles from './SwitchLabeled.module.less';

export interface SwitchLabeledProps extends SwitchProps {
  label?: ReactNode;
  labelOnLeft?: boolean;
}

const SwitchLabeledComponent: FunctionComponent<SwitchLabeledProps> = ({
  label,
  labelOnLeft,
  className,
  ...switchProps
}) => {
  const { onChange, onClick, ...rest } = switchProps;

  // TODO: a weird bug when these callbacks get called twice
  // when the "checkedChildren" and "uncheckedChildren" are different
  const handleChange = useCallback<SwitchChangeEventHandler>(
    (checked, event) => {
      onChange && setTimeout(() => onChange(checked, event), 1);
    },
    [onChange]
  );
  const handleClick = useCallback<SwitchChangeEventHandler>(
    (checked, event) => {
      onClick && setTimeout(() => onClick(checked, event), 1);
    },
    [onClick]
  );

  return (
    <div className={styles.wrapper}>
      <label
        className={classNames(
          styles.container,
          labelOnLeft && styles.labelOnLeft,
          rest.disabled && styles.disabled,
          className
        )}
      >
        <Switch onChange={handleChange} onClick={handleClick} {...rest} />
        <div className={styles.label}>{label}</div>
      </label>
    </div>
  );
};

export const SwitchLabeled = ignoreRef(SwitchLabeledComponent);
