import { esticonApi } from 'api/project/esticon/esticonApi';
import { appUsersApi } from './master/appUsers/appUsersApi';
import { baseMasterApi } from './master/baseMasterApi';
import { messageCenterApi } from './master/messageCenter/messageCenterApi';
import { organizationsAdminApi } from './master/organizationsAdmin/organizationsAdminApi';
import { pdfTronApi } from './master/pdfTron/pdfTronApi';
import { projectsApi } from './master/projects/projectsApi';
import { assignmentsApi } from './project/assignments/assignmentsApi';
import { auditLogApi } from './project/auditLog/auditLogApi';
import { baseProjectApi } from './project/baseProjectApi';
import { blobApi } from './project/blob/blobApi';
import { categoryTreesApi } from './project/categoryTrees/categoryTreesApi';
import { commentProceduresApi } from './project/commentProcedures/commentProceduresApi';
import { directoriesApi } from './project/directories/directoriesApi';
import { documentAnnotationsApi } from './project/documentAnnotations/documentAnnotationsApi';
import { documentLinksApi } from './project/documentLinks/documentLinksApi';
import { documentSigningApi } from './project/documentSignings/documentSigningsApi';
import { documentsApi } from './project/documents/documentsApi';
import { forgeApi } from './project/forge/forgeApi';
import { groupsApi } from './project/groups/groupsApi';
import { labelsApi } from './project/labels/labelsApi';
import { projectSettingApi } from './project/projectSetting/projectSettingApi';
import { projectUserApi } from './project/projectUser/projectUserApi';
import { rolesApi } from './project/roles/rolesApi';
import { searchApi } from './project/search/searchApi';
import { sharedDownloadApi } from './project/sharedDownload/sharedDownloadApi';
import { uploadApi } from './project/upload/uploadApi';
import { wopiApi } from './project/wopi/wopiApi';
import { workflowApi } from './project/workflow/workflowApi';

export const api = {
  master: {
    base: baseMasterApi,
    appUsers: appUsersApi,
    projects: projectsApi,
    messageCenter: messageCenterApi,
    pdfTron: pdfTronApi,
    organization: organizationsAdminApi,
  },
  project: {
    assignments: assignmentsApi,
    auditLog: auditLogApi,
    base: baseProjectApi,
    blob: blobApi,
    budget: esticonApi,
    forge: forgeApi,
    search: searchApi,
    directories: directoriesApi,
    projectUser: projectUserApi,
    projectSetting: projectSettingApi,
    groups: groupsApi,
    roles: rolesApi,
    labels: labelsApi,
    upload: uploadApi,
    documents: documentsApi,
    documentSignings: documentSigningApi,
    documentLinks: documentLinksApi,
    documentAnnotations: documentAnnotationsApi,
    categoryTrees: categoryTreesApi,
    sharedDownload: sharedDownloadApi,
    workflow: workflowApi,
    wopi: wopiApi,
    commentProcedures: commentProceduresApi,
  },
};

export { to } from './await-to';
export { createCancelToken } from './createCancelToken';
