import { useBoolean } from 'hooks';
import { DirectoryDto } from 'api/completeApiInterfaces';
import { RedirectOption, redirectWithOption, strCompareCI } from 'utils';
import { useProjectUrlPaths } from 'utils/urlPaths';
import { useCallback } from 'react';
import { Dispatch } from 'store';
import { ConnectedDirectory, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';

export const directoryContainsSubdirWithName = (directory: ConnectedDirectory, name: string) =>
  !!directory &&
  directory.children.flatMap(directoryNodeHelpers.filterMapDirectory).some((dir) => strCompareCI(dir.name, name) === 0);

export const useCreateDirectory = (dispatch: Dispatch, selectedDirectory: ConnectedDirectory) => {
  const [visible, show, onClose] = useBoolean(false);

  const { createDirectoryUrlPath } = useProjectUrlPaths();

  const validateUniqueName = useCallback(
    (name: string) => {
      return !directoryContainsSubdirWithName(selectedDirectory, name);
    },
    [selectedDirectory]
  );

  const onSubmit = useCallback(
    async (dir: DirectoryDto) => {
      await dispatch.directories.loadData({ reload: true });
      onClose();
      redirectWithOption(createDirectoryUrlPath(dir.id), RedirectOption.Push);
    },
    [onClose, createDirectoryUrlPath]
  );

  return { visible, show, onClose, onSubmit, parentDirectoryId: selectedDirectory?.id, validateUniqueName } as const;
};
