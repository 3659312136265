import { List, Modal, ModalProps } from 'antd';
import { EstiProjectNoteForHubItemDto } from 'api/completeApiInterfaces';
import { MinHeightSizer } from 'components/MinHeightSizer/MinHeightSizer';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';

type Props = Omit<ModalProps, 'title' | 'cancelButtonProps' | 'okText' | 'onOk'> & {
  notesList: EstiProjectNoteForHubItemDto[];
};

const ProjectsInRealizationPairingNotesPreviewModal: FunctionComponent<Props> = (props) => {
  const { notesList, onCancel, ...modalProps } = props;
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectsInRealizationPairingNotesPreviewModal.title' })}
      cancelButtonProps={HIDE_BUTTON_PROPS}
      okText={intl.formatMessage({ id: 'general.close' })}
      onOk={onCancel}
      onCancel={onCancel}
      {...modalProps}
    >
      <MinHeightSizer minHeight={350} scrollable>
        <List
          size="small"
          dataSource={notesList?.map((note) => note)}
          renderItem={(item) => (
            <List.Item>
              [{item.createdBy.username}] {item.note}
            </List.Item>
          )}
        />
      </MinHeightSizer>
    </Modal>
  );
};

export default React.memo(ProjectsInRealizationPairingNotesPreviewModal);
