import { InjectedIntl, InjectedIntlProps } from 'locale';
import React, { createContext, FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';

export const IntlContext = createContext<InjectedIntl>(undefined);

const IntlContextProvider: FunctionComponent<InjectedIntlProps> = ({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
};

export default injectIntl(IntlContextProvider);
