import { ContentGate } from 'components/ContentGate/ContentGate';
import { DirectoriesFilteringTree } from 'components/DirectoriesTree/DirectoriesFilteringTree';
import { MasterDetailsViewContext } from 'components/MasterDetailsView/MasterDetailsView';
import { useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'store';
import { directoryRootSelector } from 'store/selectors';
import { RedirectOption, redirectWithOption } from 'utils';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import { DirectoryNode, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import { useProjectUrlPaths } from 'utils/urlPaths';

type Props = {
  contextMenu: (node: DirectoryNode) => ReactElement;
  onMoveDirectory: (selectedTreeKey: DirectoryNodeKey, destinationTreeKey: DirectoryNodeKey) => void;
  projectId: Guid;
  selectedTreeKey: DirectoryNodeKey;
  toolbar?: ReactElement;
};

const AllDocumentsPageDirectoryTree: FunctionComponent<Props> = (props) => {
  const { onMoveDirectory, contextMenu, selectedTreeKey, toolbar } = props;

  const dispatch = useDispatch<Dispatch>();
  const { createDirectoryTreeUrlPath } = useProjectUrlPaths();

  const rootDirectory = useSelector(directoryRootSelector);
  const expandedKeys = useStoreSelector((state) => state.allDocumentsPage.directoryTreeExpandedKeys);
  const directoryTreeError = useStoreSelector((state) => state.directories.error);
  const directoryTreeLoading = useStoreSelector((state) => state.directories.loading);
  const showFavorite = useStoreSelector((state) => state.allDocumentsPage.showFavorite);
  const directoryTreeSearch = useStoreSelector((state) => state.allDocumentsPage.directoryTreeSearch);

  const selectedDirectoryKeys = useMemo(() => [selectedTreeKey], [selectedTreeKey]);

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  // automatically open the root node
  useEffect(() => {
    if (rootDirectory) {
      dispatch.allDocumentsPage.directoryTreeExpand({
        keys: [directoryNodeHelpers.directoryKey(rootDirectory.id)],
      });
    }
  }, [dispatch, rootDirectory]);

  const masterDetailsViewContext = useContext(MasterDetailsViewContext);

  // save selected directory tree in url
  const onSelectDirectory = useCallback(
    (keys: DirectoryNodeKey[]) => {
      if (keys.length && keys[0] !== selectedTreeKey) {
        const asId = directoryNodeHelpers.directoryNodeKeyToId(keys[0]);
        redirectWithOption(createDirectoryTreeUrlPath(asId), RedirectOption.Push);
        dispatch.allDocumentsPage.directoryTreeExpand({ keys });
      }
      masterDetailsViewContext?.setShowFirstPart(false);
    },
    [selectedTreeKey, createDirectoryTreeUrlPath]
  );

  const onDirExpand = useCallback(
    (keys: DirectoryNodeKey[]) => {
      dispatch.allDocumentsPage.setDirectoryTreeExpandedKeys({ keys });
    },
    [dispatch]
  );

  const setSearchPhrase = useCallback((term: string) => dispatch.allDocumentsPage.setDirectoryTreeSearch({ term }), [
    dispatch,
  ]);

  const setShowFavorites = useCallback(
    (isFavorite: boolean) => dispatch.allDocumentsPage.setShowFavorite({ isFavorite }),
    [dispatch]
  );

  return (
    <ContentGate error={directoryTreeError} loading={!rootDirectory || directoryTreeLoading} overlay>
      {toolbar}
      {rootDirectory && (
        <DirectoriesFilteringTree
          searchPhrase={directoryTreeSearch}
          setSearchPhrase={setSearchPhrase}
          showFavorites={showFavorite}
          setShowFavorites={setShowFavorites}
          onMoveDirectory={onMoveDirectory}
          directoryRoot={rootDirectory}
          expandedKeys={expandedKeys}
          onExpand={onDirExpand}
          selectedKeys={selectedDirectoryKeys}
          onSelect={onSelectDirectory}
          contextMenu={contextMenu}
        />
      )}
    </ContentGate>
  );
};

export default AllDocumentsPageDirectoryTree;
