import { useCallback } from 'react';
import { directoryConnectedMapSelector } from 'store/selectors';
import { useSelectorDispatch } from './useSelectorDispatch';

export const useIsDirectoryIdValid = () => {
  const directoriesTree = useSelectorDispatch(directoryConnectedMapSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );
  return useCallback((directoryId: Guid) => !!directoriesTree?.[directoryId], [directoriesTree]);
};
