import { RightOutlined } from '@ant-design/icons';
import DisplayName from 'components/DisplayName';
import PathDisplay from 'components/PathDisplay';
import PathRoot from 'components/PathRoot/PathRoot';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import * as lodash from 'lodash';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { directoryConnectedMapSelector, directoryRootSelector } from 'store/selectors';
import { getDefaultRootName } from 'utils/getRootName';
import styles from './PathSelector.module.less';

export interface PathSelectorProps {
  path: string;
  id: Guid;
  link: string;
  simple?: boolean;
  lastStrong?: boolean;
}

const getPathParts = (path: string): string[] => {
  return lodash
    .trim(path, '/')
    .split('/')
    .filter((part) => part && part.length > 0);
};

const PathSelector: FunctionComponent<PathSelectorProps> = ({ path, id, link, simple, lastStrong }) => {
  const pathParts = getPathParts(path);

  const rootNode = useSelectorDispatch(directoryRootSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  const directoryTree = useSelector(directoryConnectedMapSelector);

  const getParentDirectoryId = (parentCount: number) => {
    let currentDirectory = directoryTree?.[id];
    for (let i = 0; i < parentCount; i++) {
      currentDirectory = currentDirectory?.parent;
    }
    return currentDirectory?.id;
  };

  if (!link) {
    return <PathDisplay path={path} alignLeft />;
  }

  if (simple || !rootNode) {
    const title = '/' + (rootNode ? rootNode.name : '') + path;
    return (
      <Link to={link + id} title={title} className={styles.simpleLink}>
        <PathDisplay path={path} />
      </Link>
    );
  }

  const rootName = getDefaultRootName(rootNode);

  const root = <PathRoot rootNode={rootNode} link={link} isLast={lastStrong && !!pathParts.length} />;

  const goBacks = pathParts.length - 1;

  return (
    <div className={styles.container}>
      <div className={styles.pathWithoutLast}>
        {root}
        {pathParts.map((part: string, index: number) => {
          const linkTitleGoBack = '/' + rootName + '/' + pathParts.slice(0, index + 1).join('/');
          const linkTitle = '/' + rootName + '/' + pathParts.join('/');
          const parentId = getParentDirectoryId(goBacks - index);
          const linkHref = parentId && link + parentId;
          return index < goBacks ? (
            <span key={index} className={styles.part}>
              <RightOutlined className={styles.arrow} />
              <Link className={styles.partLink} to={linkHref} title={linkTitleGoBack}>
                <DisplayName>{part}</DisplayName>
              </Link>
            </span>
          ) : (
            <span key={index} className={styles.pathLastPart}>
              {<RightOutlined className={styles.arrow} />}
              <Link to={link + id} title={linkTitle}>
                {lastStrong ? (
                  <DisplayName>
                    <strong>{part}</strong>
                  </DisplayName>
                ) : (
                  <DisplayName>{part}</DisplayName>
                )}
              </Link>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default PathSelector;
