export enum SideMenuKey {
  EMPTY = '',

  DASHBOARD = 'dashboard',
  PROJECTS = 'projects',
  REPORTING = 'reporting',

  DOCUMENTS = 'documents',
  DOCUMENTS_ALL = 'documents.all',
  DOCUMENTS_FAVORITE = 'documents.favorite',
  DOCUMENTS_FILTERS = 'documents.filters',
  DOCUMENTS_DISCARDED = 'documents.discarded',

  WORKFLOW = 'workflow.instances',
  WORKFLOW_GROUP = 'workflow',
  WORKFLOW_TEMPLATES = 'workflow.templates',

  COMMENT_PROCEDURES = 'comment.procedure',

  MODEL = 'model',

  CONSTRUCTION = 'construction',
  CONSTRUCTION_BUDGET = 'construction.budget',
  CONSTRUCTION_DRAWING = 'construction.drawing',
  CONSTRUCTION_INVOICING = 'construction.invoicing',
  CONSTRUCTION_ZBV = 'construction.zbv',
  CONSTRUCTION_SCHEDULE = 'construction.schedule',
  CONSTRUCTION_FINANCIAL_PLAN = 'construction.financialPlan',

  EXTERNAL_APPLICATION = 'construction.externalApplication',
  CONSTRUCTION_JOURNAL = 'construction.journal',

  SETTINGS = 'settings',
  SEARCH = 'search',
  TOGGLE = 'toggleMenu',

  ADMIN = 'admin',

  HUB_SETTING_MENU_ADMINISTRATORS = 'hubAdministrators',
  HUB_SETTING_MENU_ORGANIZATIONS = 'hubOrganizations',
  HUB_SETTING_MENU_HUB_USERS = 'hubUsers',
  ASPEHUB_SETTING = 'aspehub.setting',
  ORGANIZATIONS_SETTING = 'organizations.setting',
}
