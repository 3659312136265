import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Empty, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DirectoryLinkCreateDto, DocumentBulkStateCheckResultDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';

import { requiredRule } from 'utils/formHelpers';
import styles from './BulkEditDocumentsStateForm.module.less';

const { Option } = Select;

export interface BulkEditDocumentsStateFormData {
  documentsState: WorkflowStateEnum;
}

type Props = FormComponentProps<DirectoryLinkCreateDto> &
  InjectedIntlProps & {
    availableDocumentsStates: DocumentBulkStateCheckResultDto;
    errors: JSX.Element[];
  };

const BulkEditDocumentsStateForm = React.forwardRef<unknown, Props>(
  ({ intl, form, availableDocumentsStates, errors }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;

    const availableStatesSelectOptions = useMemo(() => {
      if (!availableDocumentsStates?.availableStates.length) return null;
      return availableDocumentsStates?.availableStates.map((state) => {
        return (
          <Option key={state}>
            <div className={styles.stateOption}>
              <RevisionNumberTag state={state} showTitle />
            </div>
          </Option>
        );
      });
    }, [availableDocumentsStates]);

    return (
      <Form layout="vertical">
        {!!availableDocumentsStates?.availableStates.length && (
          <>
            <Form.Item label={intl.formatMessage({ id: 'BulkEditDocumentsStateForm.newDocumentsState' })}>
              {getFieldDecorator<BulkEditDocumentsStateFormData>('documentsState', {
                initialValue: availableDocumentsStates.availableStates[0],
                rules: [requiredRule('BulkEditDocumentsStateForm.newDocumentsState.required')],
              })(<Select>{availableStatesSelectOptions}</Select>)}
            </Form.Item>
            <DocumentMultipleActionError
              errors={errors}
              titleId="BulkEditDocumentsStateForm.newDocumentsState.impossibleChanges"
            />
          </>
        )}
        {!availableDocumentsStates?.availableStates.length && (
          <Empty
            description={
              <span>
                <Fmt id="BulkEditDocumentsStateForm.newDocumentsState.noAvailableStates" />
              </span>
            }
          ></Empty>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(BulkEditDocumentsStateForm);
