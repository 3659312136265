import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Checkbox } from 'antd';
import { CalendarDto, ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { ReactNode, useEffect, useState } from 'react';
import { CalendarSettingsExcludedDaysItem } from './CalendarSettingsExcludedDaysItem';
import styles from './CalendarSettingsForm.module.less';

export type CalendarDaysKeys = keyof Pick<CalendarDto, 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su'>;

export type CalendarSettingsFormData = {
  workingDays: CalendarDaysKeys[];
  excludedDays: ExcludedDaySetDto[];
};

export type CalendarSettingsFormConfiguration = {
  initialCalendar?: CalendarDto;
};

type Props = FormComponentProps<CalendarSettingsFormData> & InjectedIntlProps & CalendarSettingsFormConfiguration;

const workingDaysOptions: { label: ReactNode; value: CalendarDaysKeys }[] = [
  { label: <Fmt id={'general.dayNames.monday'} />, value: 'mo' },
  { label: <Fmt id={'general.dayNames.tuesday'} />, value: 'tu' },
  { label: <Fmt id={'general.dayNames.wednesday'} />, value: 'we' },
  { label: <Fmt id={'general.dayNames.thursday'} />, value: 'th' },
  { label: <Fmt id={'general.dayNames.friday'} />, value: 'fr' },
  { label: <Fmt id={'general.dayNames.saturday'} />, value: 'sa' },
  { label: <Fmt id={'general.dayNames.sunday'} />, value: 'su' },
];

const mapSelectedCalendarDays = (calendar: CalendarDto) =>
  Object.entries(calendar)
    .filter((value) => value[1] === true)
    .map((value) => value[0]);

const CalendarSettingsFormComponent = React.forwardRef<unknown, Props>(({ intl, form, initialCalendar }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  const [showOnlyFuture, setShowOnlyFuture] = useState<boolean>(false);

  // render
  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'CalendarSettingsForm.workingDays' })}>
        {form.getFieldDecorator<CalendarSettingsFormData>('workingDays', {
          initialValue: mapSelectedCalendarDays(initialCalendar),
        })(<Checkbox.Group options={workingDaysOptions} />)}
      </Form.Item>
      <Form.Item
        label={
          <FlowLayout className={styles.filterWrapper}>
            {intl.formatMessage({ id: 'CalendarSettingsForm.excludedDays' })}
          </FlowLayout>
        }
      >
        {form.getFieldDecorator<CalendarSettingsFormData>('excludedDays', {
          initialValue: initialCalendar.excludedDays,
        })(<CalendarSettingsExcludedDaysItem showOnlyFuture={showOnlyFuture} setShowOnlyFuture={setShowOnlyFuture} />)}
      </Form.Item>
    </Form>
  );
});

export const CalendarSettingsForm = Form.create<Props>()(CalendarSettingsFormComponent);
