import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { api } from 'api';
import { DirectoryContentDto, DirectoryListDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DirectoryForbiddenError from 'components/DirectoryForbiddenErrorBox/DirectoryForbiddenError';
import {
  CommonDocument,
  DOCUMENT_FILTERS,
  DOCUMENT_ORDER_OPTIONS,
} from 'components/DocumentCompleteList/DocumentCompleteList';
import {
  CommonLinkedDocument,
  mapDocumentLinkDtoToCommonLinkedDocument,
} from 'components/DocumentLinkRow/DocumentLinkRow';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { useActiveProject, useApiData, useCurrentProjectUser, useFrontendFilters, useIntl } from 'hooks';
import { useMultiSelectInner } from 'hooks/useMultiSelect';
import { Fmt } from 'locale';
import AllDocumentsPageDocumentsGrid from 'pages/AllDocumentsPage/AllDocumentsPageDocumentsGrid';
import React, { FunctionComponent, RefObject, useCallback, useEffect, useMemo } from 'react';
import { List } from 'react-virtualized';
import { DisabledWithReason } from 'utils/types';
import { DocumentSelectDocumentState } from './DocumentSelect';

export type SelectedDocumentsChange = {
  addedDocuments: DocumentSelectDocumentState[];
  removedDocumentIds: Guid[];
};

export type SelectedIdPairedToTargetId = { selectedId: Guid; targetDocumentId: Guid };

const isDocumentLink = (document: DirectoryContentDto): document is CommonLinkedDocument =>
  !!(document as CommonLinkedDocument).linkedDocument;

const getTargetDocument = (document: DirectoryContentDto): DirectoryContentDto =>
  isDocumentLink(document) ? document.linkedDocument : document;

const getSelectedIdsPairedToTargetIds = (
  selectedIds: Set<Guid>,
  selectableDocuments: (CommonDocument & DirectoryContentDto)[]
) => {
  const pairedSelectedAndTarget: SelectedIdPairedToTargetId[] = [];

  selectedIds?.forEach((selectedId) => {
    const selectableDocument = selectableDocuments.find((item) => item.id === selectedId);
    const targetId = isDocumentLink(selectableDocument) ? selectableDocument.linkedDocument.id : selectableDocument.id;
    pairedSelectedAndTarget.push({
      selectedId: selectedId,
      targetDocumentId: targetId,
    });
  });
  return pairedSelectedAndTarget;
};

type Props = {
  directory: DirectoryListDto;
  selectedDocuments: DocumentSelectDocumentState[];
  changeSelectedDocuments: (documents: SelectedDocumentsChange) => void;
  disabledDocuments?: (file: DirectoryContentDto) => DisabledWithReason;
  listRef: RefObject<List>;
  multiple?: boolean;
  filtersKey?: FiltersPersistentKey;
};

const DocumentListComponent: FunctionComponent<Props> = ({
  directory,
  selectedDocuments,
  changeSelectedDocuments,
  disabledDocuments,
  listRef,
  multiple,
  filtersKey,
}) => {
  const [documentAndLinks, documentsError, documentsLoading, loadDocuments] = useApiData((ct) =>
    api.project.directories.getDirectoryContentEx(directory.id, ct)
  );
  useEffect(() => {
    if (directory) {
      loadDocuments();
    }
  }, [directory.id]);

  const { documents, documentLinks } = documentAndLinks || {};

  const activeProject = useActiveProject();
  const currentUser = useCurrentProjectUser();
  const intl = useIntl();

  const commonDocuments = useMemo(
    () =>
      documents
        ?.map((document): CommonDocument & DirectoryContentDto => ({ ...document, revision: document.currentRevision }))
        .concat(documentLinks?.map(mapDocumentLinkDtoToCommonLinkedDocument) || []),
    [documents, documentLinks]
  );

  const disabledCommonDocuments = useCallback(
    (commonDocument: DirectoryContentDto): DisabledWithReason => {
      if ((commonDocument as CommonLinkedDocument).linkedDiscardedDocument) {
        return intl.formatMessage({ id: 'DocumentList.cannotSelectDiscarded' });
      }
      return disabledDocuments ? disabledDocuments(getTargetDocument(commonDocument)) : false;
    },
    [disabledDocuments, intl]
  );

  const selectableDocuments = useMemo(() => {
    return commonDocuments?.filter((doc) => !disabledCommonDocuments(doc)) || [];
  }, [commonDocuments, disabledCommonDocuments]);

  const { orderedItems, ...filterProps } = useFrontendFilters<CommonDocument & DirectoryContentDto>(
    DOCUMENT_FILTERS,
    DOCUMENT_ORDER_OPTIONS,
    commonDocuments,
    filtersKey
  );

  const selectedItemsIds = useMemo(
    () =>
      new Set(
        selectedDocuments
          .flatMap((doc) => {
            // include the document if it is in this directory
            const ids = doc.directoryId === directory.id ? [doc.id] : [];
            // and all links in this directory that point to this document
            return ids.concat(
              (documentLinks || [])
                .filter((link) => link.directory.id === directory.id && link.linkedDocument?.id === doc.id)
                .map((link) => link.id)
            );
          })
          // TODO: the last filter is a hack to prevent useMultiSelect from removing documents
          // that cannot be selected, which happens when changing directories
          .filter((docId) => selectableDocuments.some((doc) => doc.id === docId))
      ),
    [directory, selectedDocuments, selectableDocuments, documentLinks]
  );

  const handleSelectedChanged = useCallback(
    (selectedIds: Set<Guid>) => {
      const selectedDocuments = (commonDocuments || []).filter((doc) => selectedIds.has(doc.id)).map(getTargetDocument);
      const unselectedDocuments = (commonDocuments || [])
        .filter((doc) => !selectedIds.has(doc.id))
        .map(getTargetDocument);

      changeSelectedDocuments({
        addedDocuments: selectedDocuments,
        removedDocumentIds: unselectedDocuments.map((doc) => doc.id),
      });
    },
    [commonDocuments, changeSelectedDocuments]
  );

  const isSelectable = useCallback((doc: CommonDocument & DirectoryContentDto) => !disabledCommonDocuments(doc), [
    disabledCommonDocuments,
  ]);

  const selectedIdsPairedToTargetDocuments = useMemo(
    () => getSelectedIdsPairedToTargetIds(selectedItemsIds, selectableDocuments),
    [selectedItemsIds, selectableDocuments]
  );

  const [deselectFiles, isAllSelected, selectAll, onSelectItem] = useMultiSelectInner(
    listRef,
    orderedItems,
    selectedItemsIds,
    handleSelectedChanged,
    isSelectable,
    selectedIdsPairedToTargetDocuments
  );

  if (documentsError?.referenceErrorCode === ServiceErrorEnum.DirectoryForbiddenError) {
    return <DirectoryForbiddenError />;
  }

  // TODO: Fix multiselecting with shift key pressed and fix unselecting documents when switch directory

  return (
    <ContentGate loading={documentsLoading || documents === null} error={documentsError}>
      <DocumentsGridHeader
        allFilesSelected={isAllSelected}
        selectAllDocuments={selectAll}
        disableSelect={!multiple}
        order={
          <>
            {!!selectedItemsIds.size && !!deselectFiles && (
              <CommonHubTooltip title={<Fmt id="general.docMenu.selected.tooltip" />}>
                <Button type="link" onClick={deselectFiles}>
                  <CloseOutlined />
                  <Fmt id="general.docMenu.selectedFiles" values={{ count: selectedItemsIds?.size }} />
                </Button>
              </CommonHubTooltip>
            )}
            <OrderSelect {...filterProps} />
          </>
        }
        filters={<FilterToolbar {...filterProps} />}
      />
      <AllDocumentsPageDocumentsGrid
        documents={orderedItems}
        selectedFilesIds={selectedItemsIds}
        listRef={listRef}
        directory={directory}
        disableReserve
        projectId={activeProject?.id}
        currentUser={currentUser}
        onSelectFile={onSelectItem}
        disabledDocuments={disabledCommonDocuments}
        selectItemOnClickEnabled
        clearFilters={filterProps.clearFilters}
        hasFilteredOutItems={filterProps.hasFilteredOutItems}
      />
    </ContentGate>
  );
};

export const DocumentList = React.memo(DocumentListComponent);
