const hashHex = (hashArray: number[]): string => hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join('');

export const fileSha256 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const target = <FileReader>e.target;
      if (target.readyState === FileReader.DONE) {
        if (target.error) {
          reject(target.error);
        } else {
          crypto.subtle.digest('SHA-256', target.result as ArrayBuffer).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            resolve(hashHex(hashArray));
          });
        }
      }
    };
    reader.readAsArrayBuffer(blob);
  });
};
