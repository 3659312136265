/**
 * Performs an implicit (soft) cast on a value to a type.
 *
 * This has an advantage over explicit (hard) cast using 'as' in certain situations, consider for example the following code:
 *
 * ```
 * type MyType = {
 *   requiredProperty: string;
 *   optionalProperty?: number;
 * }
 *
 * // no problem, even though there is a typo in 'opitionalProperty'
 * const a = {requiredProperty: 'text', opitionalProperty: 42} as MyType;
 *
 * // error: 'opitionalProperty' does not exists on type 'MyType'
 * const b = implicitCast<MyType>({requiredProperty: 'text', opitionalProperty: 42});
 * ```
 *
 *
 * @param value value to be implicitly casted as another type
 */
export const implicitCast: <T>(value: T) => T = (value) => value;
