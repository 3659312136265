import { Alert, Modal } from 'antd';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';

type Props = { usefulNewDocumentStatuses: WorkflowStateEnum[]; handleAlertClose: () => void };

export const NoApplicableNewDocumentStateAlert: FunctionComponent<Props> = ({
  usefulNewDocumentStatuses,
  handleAlertClose,
}) => {
  const intl = useIntl();

  return (
    <Modal open={!usefulNewDocumentStatuses.length} footer={null} onCancel={handleAlertClose}>
      <Margin top>
        <Alert
          message={intl.formatMessage({
            id: `NoApplicableNewDocumentStateAlert.title`,
          })}
          showIcon
          description={intl.formatMessage({
            id: `NoApplicableNewDocumentStateAlert.description`,
          })}
          type="error"
        />
      </Margin>
    </Modal>
  );
};
