import {
  createMultiSelectFilterFunction,
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import Label from 'components/Label';
import { useCurrentAppUser, useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { organizationLabelsOrderedListSelector } from 'store/selectors/organizationLabelsSelectors';
import { checkObject } from 'utils';

type Props = {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
};

const OrganizationLabelsFilterComponent: FunctionComponent<Props> = ({ value, onChange }) => {
  const currentAppUser = useCurrentAppUser();
  const organizationLabelsList = useStoreSelector(organizationLabelsOrderedListSelector);
  useDispatchEffect(
    (dispatch) =>
      dispatch.organizationLabels.loadData({
        reload: false,
        data: currentAppUser?.organizationUsers?.map((orgUser) => orgUser.organization.id) || [],
      }),
    [currentAppUser?.organizationUsers]
  );
  useDirtyStoreReloadCallback(
    (store) => store.organizationLabels,
    (dispatch) =>
      dispatch.organizationLabels.loadData({
        reload: true,
        data: currentAppUser?.organizationUsers?.map((orgUser) => orgUser.organization.id) || [],
      })
  );

  const intl = useIntl();
  const labelsOptions = useMemo(
    () =>
      (organizationLabelsList || []).map(
        (label): IOption<Guid> => ({
          id: label.id,
          title: label.name,
          label: <Label color={label.color}>{label.name}</Label>,
        })
      ),
    [organizationLabelsList]
  );

  return (
    organizationLabelsList?.length > 0 && (
      <SelectFilter
        label={<Fmt id="general.labels" />}
        options={labelsOptions}
        enableAndOrOperator
        value={value}
        onChange={onChange}
        searchItemsPlaceholder={intl.formatMessage({ id: 'filters.searchPlaceholder.labels' })}
      />
    )
  );
};

export const OrganizationLabelsFilter = React.memo(OrganizationLabelsFilterComponent);

const createCommonOrganizationLabelsFilter = (key: string): CommonFilter<SelectFilterValue<Guid>> => ({
  key,
  render: (value, setValue) => <OrganizationLabelsFilter value={value} onChange={setValue} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendOrganizationLabelsFilter = <T,>(
  key: string,
  valuesSelectors: (item: T) => Guid[]
): FrontendFilter<T, SelectFilterValue<Guid>> => ({
  ...createCommonOrganizationLabelsFilter(key),
  filter: createMultiSelectFilterFunction(valuesSelectors),
});

export const createBackendOrganizationLabelsFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: SelectFilterValue<Guid>) => T
): BackendFilter<T, SelectFilterValue<Guid>> => ({
  ...createCommonOrganizationLabelsFilter(key),
  serialize,
});
