import { init } from '@rematch/core';
import { UserState } from 'redux-oidc';
import { middlewares } from './middlewares';
import { models } from './models';
import {
  RootState as StoreRootState,
  SafeDispatch,
  Store as ReduxStore,
  StoreModelsUnsafe,
} from './models/storeModelinterfaces';
import { plugins } from './plugins';
import { reducers } from './reducers';

export const createStore = (initialState: Partial<RootState> | undefined): Store =>
  init<StoreModelsUnsafe>({
    models: models as StoreModelsUnsafe,
    plugins,
    redux: {
      initialState,
      middlewares,
      reducers,
    },
  });

export const store: Store = createStore(undefined);

export type UserProfile = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  sub: string;
  website: string;
  name_verified: string;
};

export type RootState = StoreRootState & {
  oidc: UserState & {
    user?: UserProfile;
  };
};

export type Store = ReduxStore;
export type Dispatch = SafeDispatch;
