import Test1 from './Test1';
import Test2 from './Test2';
import Test3 from './Test3';

export interface Test {
  name: string;
  dataTemplate: string;
  func: (data: TestData) => void;
}

export interface TestData {
  data: Test;
  onDataAdd: (row: string) => void;
}

export const Tests: Test[] = [
  {
    name: 'Volej výpis souborů v adresářích',
    dataTemplate:
      '{\n' +
      '  "projectId":  "3fa85f64-5717-4562-b3fc-2c963f66afa6",\n' +
      '  "directories": [\n' +
      '    "3fa85f64-5717-4562-b3fc-2c963f66afa6"\n' +
      '  ],\n' +
      '  "repetitionCount": 100,\n' +
      '  "parallelTaskCount": 10,\n' +
      '  "sleepTime": 10,\n' +
      '  "waitToEnd": true\n' +
      '}\n',
    func: Test1,
  },
  {
    name: 'Volej detaily souborů v adresářích',
    dataTemplate:
      '{\n' +
      '  "projectId":  "3fa85f64-5717-4562-b3fc-2c963f66afa6",\n' +
      '  "directories": [\n' +
      '    "3fa85f64-5717-4562-b3fc-2c963f66afa6"\n' +
      '  ],\n' +
      '  "repetitionCount": 100,\n' +
      '  "parallelTaskCount": 2,\n' +
      '  "sleepTime": 10,\n' +
      '  "waitToEnd": true\n' +
      '}\n',
    func: Test2,
  },
  {
    name: 'Volej seznam adresářů',
    dataTemplate:
      '{\n' +
      '  "projectId":  "3fa85f64-5717-4562-b3fc-2c963f66afa6",\n' +
      '  "repetitionCount": 100,\n' +
      '  "parallelTaskCount": 10,\n' +
      '  "sleepTime": 10,\n' +
      '  "waitToEnd": true\n' +
      '}\n',
    func: Test3,
  },
];
