import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { FunctionComponent } from 'react';
import styles from './UserIcon.module.less';

export type UserAvatarType = {
  firstname?: string | null;
  lastname?: string | null;
  username?: string | null;
};

const getDisplayText = (user: UserAvatarType) => {
  // First, try to read first and last name directly from users
  if (user.firstname?.length > 0 && user.lastname?.length > 0) {
    return user.firstname[0].toLocaleUpperCase() + user.lastname[0].toLocaleUpperCase();
  }

  // then, try to pull first and last name from email. Example: (T)om.(S)mith@email.com -> TS
  const initials = user.username?.match(/^(\w)[^.@]*\.(\w)/i);
  if (initials) {
    return initials[1].toLocaleUpperCase() + initials[2].toLocaleUpperCase();
  }

  // finally, return first 2 letters from email
  return user.username?.slice(0, 2).toLocaleUpperCase();
};

export enum UserAvatarSize {
  /** 16px */
  Small = 'Small',

  /** 24px */
  Medium = 'Medium',

  /** 32px */
  Large = 'Large',

  /** 64px */
  ExtraLarge = 'ExtraLarge',
}

const SIZE_TO_CLASSNAME: Record<UserAvatarSize, string> = {
  [UserAvatarSize.Small]: styles.sizeSmall,
  [UserAvatarSize.Medium]: styles.sizeMedium,
  [UserAvatarSize.Large]: styles.sizeLarge,
  [UserAvatarSize.ExtraLarge]: styles.extraLarge,
};

type Props = {
  user: UserAvatarType | null;
  size?: UserAvatarSize;
};

const UserIconComponent: FunctionComponent<Props> = ({ user, size = UserAvatarSize.Medium }) => {
  if (!user) {
    return <Avatar icon={<UserOutlined />} className={SIZE_TO_CLASSNAME[size]} />;
  }

  return <Avatar className={SIZE_TO_CLASSNAME[size]}>{getDisplayText(user)}</Avatar>;
};

export const UserIcon = React.memo(UserIconComponent);
