import { message } from 'antd';
import {
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { useAggregateEvent } from 'hooks/useAggregateEvent';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { groupBy } from 'lodash';
import { useCallback } from 'react';

type SignalDocumentDto = { projectId: Guid; userId: Guid; documentId: Guid; documentName: string };
type SignalFolderDto = { projectId: Guid; userId: Guid };

const MAP_DOCUMENT_SIGNAL_PARAMS_TO_OBJECT = (
  projectId: Guid,
  userId: Guid,
  documentId: Guid,
  documentName: string
): SignalDocumentDto => ({
  projectId,
  userId,
  documentId,
  documentName,
});

const MAP_DOCUMENT_LINK_SIGNAL_PARAMS_TO_OBJECT = (
  projectId: Guid,
  userId: Guid,
  documentLinkId: Guid,
  documentLinkName: string
): SignalDocumentDto => ({
  projectId,
  userId,
  documentId: documentLinkId,
  documentName: documentLinkName,
});

export function useAggregateDirectoryMessage(
  action: () => void,
  messageId: IntlMessageId,
  intl: InjectedIntl,
  currentProjectUser: ProjectUserProfileDto,
  wait: number = 300
) {
  const onDebounceFunc = useCallback(
    (values: SignalFolderDto[]) => {
      if (values.some((value) => value.userId !== currentProjectUser.id)) {
        message.info(
          intl.formatMessage({
            id: messageId,
          })
        );
      }
      action();
    },
    [action, messageId, intl]
  );
  return useAggregateEvent(onDebounceFunc, wait, (projectId: Guid, userId: Guid) => ({ projectId, userId }));
}

export function useAggregateDocumentMessage(
  action: () => void,
  messageId: IntlMessageId,
  intl: InjectedIntl,
  users: ProjectUserProfileListDto[],
  currentProjectUser: ProjectUserProfileDto,
  wait: number = 300
) {
  const onDebounceFunc = useCallback(
    (values: SignalDocumentDto[]) => {
      showGroupedMessagesByUser(values, users, currentProjectUser, messageId, intl);
      action();
    },
    [action, users, intl]
  );
  return useAggregateEvent(onDebounceFunc, wait, MAP_DOCUMENT_SIGNAL_PARAMS_TO_OBJECT);
}

export function useAggregateDocumentLinkMessage(
  action: () => void,
  messageId: IntlMessageId,
  intl: InjectedIntl,
  users: ProjectUserProfileListDto[],
  currentProjectUser: ProjectUserProfileDto,
  wait: number = 300
) {
  const onDebounceFunc = useCallback(
    (values: SignalDocumentDto[]) => {
      showGroupedMessagesByUser(values, users, currentProjectUser, messageId, intl);
      action();
    },
    [action, users, intl]
  );
  return useAggregateEvent(onDebounceFunc, wait, MAP_DOCUMENT_LINK_SIGNAL_PARAMS_TO_OBJECT);
}

export function toGroups<T, K extends keyof any>(
  values: T[],
  groupFunc: (value: T) => K,
  forEachGroup: (values: T[], key: K) => void
) {
  const groups = groupBy(values, groupFunc);
  Object.entries(groups).forEach(([key, group]) => forEachGroup(group, key as K));
  return groups as Record<K, T[]>;
}

function showGroupedMessagesByUser(
  values: SignalDocumentDto[],
  users: ProjectUserProfileListDto[],
  currentProjectUser: ProjectUserProfileListDto,
  messageID: IntlMessageId,
  intl: InjectedIntl
) {
  toGroups(
    values,
    (value) => value.userId,
    (values, key) => {
      const user = users?.find((u) => u.id === key);
      if (!user || user.status === ProjectUserProfileStatusEnum.system || user.id === currentProjectUser.id) return;
      message.info(
        intl.formatMessage(
          {
            id: messageID,
          },
          { user: user?.username, count: values.length }
        )
      );
    }
  );
}
