import { TreeSelect } from 'antd';
import React, { CSSProperties, FunctionComponent, useCallback, useRef } from 'react';
import { DirectoriesTreeProps } from './DirectoriesTreeSelect';

const TREE_STYLE: CSSProperties = { width: '100%' };

const DROPDOWN_STYLE: CSSProperties = { overflow: 'auto', maxWidth: 300 };

export const DirectoriesSimpleSelect: FunctionComponent<DirectoriesTreeProps> = ({ ...restProps }) => {
  const inputRef = useRef<HTMLDivElement>();

  const getPopupContainer = useCallback(() => inputRef.current, []);

  return (
    <div ref={inputRef}>
      <TreeSelect
        treeNodeLabelProp="path"
        style={TREE_STYLE}
        dropdownStyle={DROPDOWN_STYLE}
        listHeight={400}
        virtual
        getPopupContainer={getPopupContainer}
        {...restProps}
      />
    </div>
  );
};
