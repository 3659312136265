import '@ant-design/compatible/assets/index.css';
import { DefaultOptionType } from 'antd/lib/select';
import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { Precisions } from 'hooks/useEsticon';
import { InjectedIntl } from 'locale';

export const supportedReportWidgets = [OrgExtendedPermissionEnum.projectsInRealisationOverview];

export enum ReportWidgetDecimalUnitEnum {
  Units = 'units',
  Thousands = 'thousands',
  Millions = 'millions',
  Billions = 'billions',
}

export const ReportPrecision: Precisions = {
  amount: 2,
  price: 0,
  percent: 2,
  unitPrice: 0,
  percentPrecise: 2,
};

export enum ReportWidgetLabelAlignEnum {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum ReportWidgetColumnConfigurationType {
  Constant = 'constant',
  Sum = 'sum',
}

export type ReportWidgetColumnConfiguration<T> = (
  | { type: ReportWidgetColumnConfigurationType.Constant; constant: string }
  | { type: ReportWidgetColumnConfigurationType.Sum; property: keyof T }
) & {
  id: Guid;
  name: string;
  color: string;
};

export const getDecimalUnitOptions = (intl: InjectedIntl): DefaultOptionType[] => [
  {
    value: ReportWidgetDecimalUnitEnum.Units,
    label: intl.formatMessage({ id: 'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.units' }),
  },
  {
    value: ReportWidgetDecimalUnitEnum.Thousands,
    label: intl.formatMessage({
      id: 'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.thousands',
    }),
  },
  {
    value: ReportWidgetDecimalUnitEnum.Millions,
    label: intl.formatMessage({
      id: 'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.millions',
    }),
  },
  {
    value: ReportWidgetDecimalUnitEnum.Billions,
    label: intl.formatMessage({
      id: 'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.billions',
    }),
  },
];

export const getLabelAlignOptions = (intl: InjectedIntl): DefaultOptionType[] => [
  {
    value: ReportWidgetLabelAlignEnum.Top,
    label: intl.formatMessage({ id: 'ReportWidgetLabelAlignEnum.top.label' }),
  },
  {
    value: ReportWidgetLabelAlignEnum.Middle,
    label: intl.formatMessage({
      id: 'ReportWidgetLabelAlignEnum.middle.label',
    }),
  },
  {
    value: ReportWidgetLabelAlignEnum.Bottom,
    label: intl.formatMessage({
      id: 'ReportWidgetLabelAlignEnum.bottom.label',
    }),
  },
];
