import { DownloadUrl } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';

export const downloadFile = async (
  getUrl: () => Promise<[ApiError, AxiosResponse<DownloadUrl>]>
): Promise<ApiError> => {
  const [err, res] = await getUrl();
  if (err) {
    return err;
  } else {
    window.open(res.data.url, '_blank');
    return null;
  }
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
};