import { Action } from '@rematch/core';
import { ProjectHubDto } from 'api/completeApiInterfaces';
import { baseProjectApi } from 'api/project/baseProjectApi';
import { API_BASE_URL } from 'config/env';
import { SIGNAL_R_REDUX_STORE_CONNECTION } from 'config/signalRConnection';
import { Store } from 'store';
import { UnsafeDispatch } from 'store/models/storeModelinterfaces';

export const projectIdAxiosMiddleware = (store: Store) => (next: UnsafeDispatch) => async (action: Action) => {
  if (action.type === 'activeProject/setActiveProject') {
    if (action.payload !== null) {
      const project = action.payload as ProjectHubDto;
      baseProjectApi.defaults.baseURL = `${API_BASE_URL}/projects/${project.id}`;
      await SIGNAL_R_REDUX_STORE_CONNECTION.joinGroup('project-' + project.id);
      await SIGNAL_R_REDUX_STORE_CONNECTION.joinGroup('organization-' + project.organization.id);
    } else {
      const state = store.getState();
      const { activeProject } = state.activeProject;
      if (activeProject) {
        await SIGNAL_R_REDUX_STORE_CONNECTION.leaveGroup('project-' + activeProject.id);
        await SIGNAL_R_REDUX_STORE_CONNECTION.leaveGroup('organization-' + activeProject.organization.id);
      }
    }
  }
  return next(action);
};
