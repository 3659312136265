import { to } from 'api/await-to';
import { CancelToken } from 'axios';
import { baseMasterApi } from '../baseMasterApi';

export const pdfTronApi = {
  getLicenceKey: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<string>(`/pdftron/licensekey`, { cancelToken })
    ),
};
