import { ProjectHubDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent } from 'react';
import styles from './ProjectName.module.less';

type Props = { onProjectClick?: () => void; project?: ProjectHubDto };

export const ProjectName: FunctionComponent<Props> = ({ project, onProjectClick }) => {
  return (
    <>
      {project && (
        <CommonHubTooltip
          trigger={['click']}
          overlayClassName={styles.tooltip}
          title={
            <>
              <DisplayName isWrap className={styles.projectNameTitle}>
                {project.name}
              </DisplayName>
              {<br />}
              {project.description && (
                <DisplayName isWrap className={styles.projectDescription}>
                  {project.description}
                </DisplayName>
              )}
            </>
          }
        >
          <div className={styles.projectName} onClick={onProjectClick}>
            <DisplayName text={project.name} />
          </div>
        </CommonHubTooltip>
      )}
    </>
  );
};
