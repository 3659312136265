import { InputRef, message } from 'antd';
import { api } from 'api';
import { DeepShareDownloadDto, ShareDownloadResultDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import DocumentMultipleActionError, { transformError } from 'components/DocumentMultipleActionError';
import {
  IMultipleDocumentErrorData,
  MultipleDocumentActionErrorDataItem,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useFocus } from 'hooks/useFocus';
import { InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { messageError, processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ShareDownloadForm, { ShareDownloadFormData } from './ShareDownloadForm';

type Props = InjectedIntlProps &
  FormModalProps<ShareDownloadResultDto> & {
    directoryId: Guid;
  };

const DeepShareDownloadFormModalComponent: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, directoryId, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [ignoreEntities, setIgnoreEntities] = useState<MultipleDocumentActionErrorDataItem[]>([]);

  const handleSubmit: FormSubmitHandler<ShareDownloadFormData> = async (values) => {
    const uniqueEmails: Set<string> = new Set(Object.keys(values.emails).map((k) => values.emails[k]));
    const data: DeepShareDownloadDto = {
      emails: Array.from(uniqueEmails),
      shareMessage: values.message,
      directoryId: directoryId,
      ignoredDocumentsIds: ignoreEntities.filter((item) => item.isDocument).map((item) => item.objectId),
      ignoredDocumentLinksIds: ignoreEntities.filter((item) => !item.isDocument).map((item) => item.objectId),
      includeSubdirectories: values.includeSubdirectories,
      sendMails: true,
      requiredPin: true,
    };

    const [err, res] = await api.project.documents.deepShareDownload(data);
    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleMoveObstacleError) {
          const errData = error.errorData as IMultipleDocumentErrorData;
          setErrors(errData.documentsErrors.map(transformError));
          setIgnoreEntities((prevState) => prevState.concat(errData.documentsErrors));
          return null;
        }
        messageError(error, props.intl);
        onClose();
      });
      return null;
    }

    message.info(props.intl.formatMessage({ id: 'DeepShareDownloadFormModal.success' }));

    onSubmit(res.data);
    handleClose();
    return null;
  };

  const handleClose = () => {
    onClose();
    setErrors([]);
    setIgnoreEntities([]);
  };

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onCancel={handleClose}
      onClose={handleClose}
      confirmClose={false}
      titleId="DeepShareDownloadFormModal.title"
      submitTextId={errors.length > 0 ? 'ShareDownloadFormModal.sendNext' : 'ShareDownloadFormModal.send'}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <>
          <ShareDownloadForm intl={intl} wrappedComponentRef={formRef} showIncludeSubdirectories setRef={setInputRef} />
          {errors.length > 0 && (
            <DocumentMultipleActionError errors={errors} titleId="shareDownloadFormModal.errorsTitle.obstaclePartial" />
          )}
        </>
      )}
    </FormModalWrapper>
  );
};

export const DeepShareDownloadFormModal = memoizeWithIntl(DeepShareDownloadFormModalComponent);
