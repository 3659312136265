import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import styles from './WrapButton.module.less';
import { ButtonProps } from 'antd/lib/button';

type Props = ButtonProps & {};

export const WrapButton: FunctionComponent<Props> = ({ className, ...buttonProps }) => {
  return <Button className={classnames(styles.wrap, className)} {...buttonProps} />;
};
