import { api, createCancelToken } from 'api';
import { produce } from 'immer';
import { keyBy } from 'lodash';
import { processApiError } from 'utils';
import { CategoryTreesStoreModel, CategoryTreesStoreModelState } from './storeModelinterfaces';

// for leaving project only
const CANCEL_TOKEN_REF = { current: createCancelToken() };

const defaults: CategoryTreesStoreModelState = {};

export const categoryTreesStoreModel: CategoryTreesStoreModel = {
  state: defaults,
  reducers: {
    setCategoryTree: (state, { categoryId, map, loading, error }) =>
      produce(state, (draft) => {
        if (!draft[categoryId]) {
          draft[categoryId] = {
            map: map !== undefined ? map : null,
            loading: loading !== undefined ? loading : false,
            error: error !== undefined ? error : null,
          };
        } else {
          if (map !== undefined) {
            draft[categoryId].map = map;
          }
          if (loading !== undefined) {
            draft[categoryId].loading = loading;
          }
          if (error !== undefined) {
            draft[categoryId].error = error;
          }
        }
      }),
    setCategoryTreeNode: (state, { categoryId, node }) =>
      produce(state, (draft) => {
        if (!draft[categoryId]) {
          draft[categoryId] = {
            map: { [node.id]: node },
            loading: false,
            error: null,
          };
        } else {
          draft[categoryId].map[node.id] = node;
        }
      }),
    removeCategoryTreeNode: (state, { categoryId, nodeId }) =>
      produce(state, (draft) => {
        if (draft && draft[categoryId] && draft[categoryId].map) {
          delete draft[categoryId].map[nodeId];
        }
      }),
    categoryChangeNodeName: (state, { name, categoryId }) =>
      produce(state, (draft) => {
        if (draft[categoryId]) {
          const node = Object.values(draft[categoryId].map).find((n) => n.parentId === null);
          if (node) node.name = name;
        }
      }),
    setDefaults: () => defaults,
  },
  effects: (dispatch) => ({
    async loadCategoryTree({ categoryId, reload }, rootState) {
      if (!reload && rootState.categoryTrees[categoryId] !== undefined) return;

      dispatch.categoryTrees.setCategoryTree({ categoryId, loading: true });

      const [err, response] = await api.project.categoryTrees.listCategoryNodes(
        categoryId,
        CANCEL_TOKEN_REF.current.token
      );
      if (err) {
        processApiError(err, (error) =>
          dispatch.categoryTrees.setCategoryTree({ categoryId, map: null, error, loading: false })
        );
        return;
      }

      const map = keyBy(response.data, 'id');
      dispatch.categoryTrees.setCategoryTree({ categoryId, map, error: null, loading: false });
    },

    async loadCategoriesTrees({ reload }, rootState) {
      rootState.categories.data?.forEach((category) => {
        dispatch.categoryTrees.loadCategoryTree({ reload: reload, categoryId: category.id });
      });
    },

    clearData() {
      CANCEL_TOKEN_REF.current.cancel('categoryTreesModel: clearing data / leaving current project');
    },
  }),
};
