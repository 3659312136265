import { processApiError } from 'utils';
import { useEffect, useState } from 'react';
import { useIsMounted } from 'hooks';
import { DocumentLinkDto, ServiceError } from 'api/completeApiInterfaces';
import { projectApi } from 'api/completeApi';

export const useLoadLinks = (documentId: Guid) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [links, setLinks] = useState<DocumentLinkDto[] | null>([]);
  const [error, setError] = useState<ServiceError | null>(null);

  const isMounted = useIsMounted();

  const loadLinks = async () => {
    setLoading(true);
    const [err, res] = await projectApi.documentlink.documentslinks.id.get(documentId);
    if (!isMounted.current) {
      return Promise.reject();
    }

    if (err) {
      processApiError(err, (error) => {
        setError(error);
        setLoading(false);
      });
      return false;
    } else {
      setLinks(res.data);
      setLoading(false);
      setError(null);
      return true;
    }
  };

  useEffect(() => {
    void loadLinks();
  }, []);

  return { links, loadLinks, setLinks, linksLoading: loading, linksError: error } as const;
};
