import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useBoolean, useIntl, useSameCallback } from 'hooks';
import { useDirtyStoreReloadCallback, useStoreSelector } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { CalendarSettingsDeleteButton } from './CalendarSettingsDeleteButton';
import { CalendarSettingsFormData } from './CalendarSettingsForm';
import { CalendarSettingsFormModal } from './CalendarSettingsFormModal';

type Props = {
  editDisabled?: boolean;
  title?: string;
  onSuccess?: (data: CalendarSettingsFormData, response: void) => void;
  buttonType?: ButtonType;
  organizationId: Guid;
  projectId?: Guid;
};

export const CalendarSettingsFormButton: FunctionComponent<Props> = ({
  onSuccess,
  children,
  organizationId,
  projectId,
  editDisabled = false,
  buttonType = 'link',
}) => {
  const [modalFormVisible, modalFormShow, modalFormHide] = useBoolean(false);
  const dispatch = useDispatch<Dispatch>();

  const intl = useIntl();

  const { activeCalendar, activeCalendarLoading } = useStoreSelector((state) => state.activeCalendar);

  const reloadCalendar = useCallback(() => {
    dispatch.activeCalendar.loadCalendar({ organizationId: organizationId, projectId: projectId });
  }, [organizationId, projectId]);

  useDirtyStoreReloadCallback(
    (store) => store.activeCalendar,
    (dispatch) =>
      dispatch.activeCalendar.loadCalendar({
        organizationId,
        projectId,
      })
  );

  const handleSubmit = useSameCallback((data: CalendarSettingsFormData, response: void) => {
    onSuccess && onSuccess(data, response);
    reloadCalendar();
    modalFormHide();
  });

  const currentCalendar = activeCalendar?.projectCalendar
    ? activeCalendar?.projectCalendar
    : activeCalendar?.orgCalendar;

  return (
    <>
      <Button
        type={buttonType}
        icon={<EditOutlined />}
        disabled={editDisabled || !currentCalendar}
        onClick={modalFormShow}
        loading={activeCalendarLoading}
      >
        {children}
      </Button>
      {!editDisabled && !!activeCalendar?.projectCalendar && (
        <CalendarSettingsDeleteButton
          organizationId={organizationId}
          projectId={projectId}
          onSuccess={reloadCalendar}
        />
      )}
      {currentCalendar && modalFormVisible && (
        <CalendarSettingsFormModal
          onSubmit={handleSubmit}
          visible={modalFormVisible}
          onClose={modalFormHide}
          initialCalendar={currentCalendar}
          intl={intl}
          organizationId={organizationId}
          projectId={projectId}
          editDisabled={editDisabled}
        />
      )}
    </>
  );
};
