import { Dictionary, pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { smartFilter } from 'utils';
import { KeyOfType } from 'utils/types';

export type TreeNode = {
  id: Guid;
  parentId: Guid;
  name: string;
};

export type GenericTree<T extends TreeNode> = T & {
  children: GenericTree<T>[];
};

export const createParentMap = <T extends TreeNode>(list: T[]) => {
  const parentMap: Dictionary<T[]> = {};

  list.forEach((node) => {
    if (parentMap[node.parentId] === undefined) parentMap[node.parentId] = [];
    parentMap[node.parentId].push(node);
  });

  return parentMap;
};

export const mapToTree = <T extends TreeNode>(
  parentMap: Dictionary<T[]>,
  parentId: string = null
): GenericTree<T>[] => {
  return (parentMap[parentId] || [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((node) => {
      const children = mapToTree(parentMap, node.id);
      return { ...node, children };
    });
};

export const addParent = <T extends TreeNode>(map: Dictionary<T>, parentId: Guid, parents: Dictionary<T>): void => {
  if (parentId === null) return;
  const parent = map[parentId];
  if (parents[parent.id]) return;
  parents[parent.id] = parent;
  if (parent.parentId !== null) {
    addParent(map, parent.parentId, parents);
  }
};

export const filterTree = <T extends TreeNode>(map: Dictionary<T>, search: string): [GenericTree<T>[], Guid[]] => {
  if (map === null) return [null, null];

  if (search === '') {
    const parentMap = createParentMap(Object.values(map));
    const tree = mapToTree(parentMap);
    return [tree, Object.keys(map)];
  }

  const match = pickBy(map, (node) => smartFilter(node.name, search));
  const matchParents: Dictionary<T> = {};

  for (const id in match) {
    const node = match[id];
    if (node.parentId !== null) {
      addParent(map, node.parentId, matchParents);
    }
  }

  const allMatch: Dictionary<T> = { ...match, ...matchParents };

  const parentMap = createParentMap(Object.values(allMatch));
  const tree = mapToTree(parentMap);

  return [tree, Object.keys(allMatch)];
};

export const mapToListSelector = <T, S = RootState>(mapSelector: (state: S) => Dictionary<T>) =>
  createSelector<S, Dictionary<T>, T[]>([mapSelector], (map) => {
    if (map === null) return null;
    return Object.values(map);
  });

export const mapToSortedListSelector = <T, S = RootState>(
  mapSelector: (state: S) => Dictionary<T>,
  key: KeyOfType<T, string>,
  order: 'asc' | 'desc' = 'asc'
) =>
  createSelector<S, Dictionary<T>, T[]>([mapSelector], (map) => {
    if (map === null) return null;
    return Object.values(map).sort(
      (a, b) =>
        // a[key] is not recognised as string, nedd to cast
        (order === 'desc' ? -1 : 1) * ((a[key] as unknown) as string).localeCompare((b[key] as unknown) as string)
    );
  });
