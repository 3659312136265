import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, InputRef } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { FilterDisplay } from 'components/filters/render/FilterDisplay/FilterDisplay';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { checkBoolean, CheckFormat, checkObject, checkString, smartFilter } from 'utils';
import styles from './ExtensionFilter.module.less';
import { useIntl } from 'hooks';
import { FilterLabel } from 'components/filters/render/FilterLabel/FilterLabel';
import { useFocus } from 'hooks/useFocus';

type ExtensionFilterValue = {
  extension: string;
  withoutExtension: boolean;
};

const IS_EMPTY = (value: ExtensionFilterValue) => !value.extension?.trim().length && !value.withoutExtension;
const DEFAULT_VALUE: ExtensionFilterValue = { extension: '', withoutExtension: false };
const CLEARED_VALUE: React.SetStateAction<ExtensionFilterValue> = DEFAULT_VALUE;
const CHECK_FORMAT: CheckFormat<ExtensionFilterValue> = checkObject({
  extension: checkString,
  withoutExtension: checkBoolean,
});

type Props = {
  defaultValue: ExtensionFilterValue | null;
  onChangeDebounced: (value: ExtensionFilterValue) => void;
  placeholder?: string;
  delay?: number;
};

const ExtensionFilterComponent: FunctionComponent<Props> = ({
  defaultValue,
  onChangeDebounced,
  placeholder,
  delay = 400,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue]);
  useDebounce(() => onChangeDebounced(value), delay, [value]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { setInputRef } = useFocus<InputRef>(dropdownVisible);

  const extensionLabel = useMemo(
    () =>
      value?.extension?.trim().length ? (
        <FilterLabel value={value.extension} />
      ) : value?.withoutExtension ? (
        intl.formatMessage({ id: 'ExtensionFilter.withoutExtension' })
      ) : null,
    [value, intl]
  );

  const onExtensionChange = useCallback((extension: string) => setValue({ extension, withoutExtension: false }), []);

  const onWithoutExtensionChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const value: ExtensionFilterValue = { extension: '', withoutExtension: event.target.checked };
      setValue(value);
      onChangeDebounced(value); // update parent immediately (don't wait on debounce)
    },
    [onChangeDebounced]
  );

  const clearFilter = useCallback(() => {
    setValue(CLEARED_VALUE);
    onChangeDebounced(CLEARED_VALUE); // update parent immediately (don't wait on debounce)
  }, [onChangeDebounced]);

  return (
    <FilterDisplay
      label={<Fmt id="general.extension" />}
      value={extensionLabel}
      isEmpty={IS_EMPTY(value)}
      clearFilter={clearFilter}
      dropdownVisible={dropdownVisible}
      setDropdownVisible={setDropdownVisible}
    >
      <FilterInput
        value={value?.extension}
        onSearch={onExtensionChange}
        placeholder={intl.formatMessage({ id: 'ExtensionFilter.placeholder' })}
        prefix={<SearchOutlined />}
        setRef={setInputRef}
      />
      <Checkbox
        checked={value?.withoutExtension}
        className={styles.extensionOption}
        onChange={onWithoutExtensionChange}
      >
        <Fmt id="ExtensionFilter.withoutExtension" />
      </Checkbox>
    </FilterDisplay>
  );
};

export const ExtensionFilter = React.memo(ExtensionFilterComponent);

type AdditionalProps = Omit<Props, 'defaultValue' | 'onChangeDebounced'>;

const filterWithoutExtension = <T,>(valueSelector: (item: T) => string) => (item: T) => !valueSelector(item)?.length;

/** function to search multiple extensions separated by comma (e.g. "jpg, png") and return a filter function **/
const filterWithMultipleExtensions = <T,>(extensions: string, valueSelector: (item: T) => string) => {
  const extensionsList = extensions.split(',').filter((extension) => extension.trim().length);
  return (item: T) =>
    !extensionsList.length || extensionsList.some((extension) => smartFilter(valueSelector(item), extension));
};

export const createCommonExtensionFilter = (
  key: string,
  props?: AdditionalProps
): CommonFilter<ExtensionFilterValue> => ({
  key,
  render: (value, setValue) => <ExtensionFilter {...props} defaultValue={value} onChangeDebounced={setValue} />,
  isEmpty: IS_EMPTY,
  defaultValue: DEFAULT_VALUE,
  clearedValue: CLEARED_VALUE,
  checkFormat: CHECK_FORMAT,
});

export const createFrontendExtensionFilter = <T,>(
  key: string,
  valueSelector: (item: T) => string,
  props?: AdditionalProps
): FrontendFilter<T, ExtensionFilterValue> => ({
  ...createCommonExtensionFilter(key, props),
  filter: (value: ExtensionFilterValue) =>
    value.withoutExtension
      ? filterWithoutExtension(valueSelector)
      : filterWithMultipleExtensions(value.extension, valueSelector),
});

export const createBackendExtensionFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: ExtensionFilterValue) => T,
  props?: AdditionalProps
): BackendFilter<T, ExtensionFilterValue> => ({
  ...createCommonExtensionFilter(key, props),
  serialize,
});
