import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { AssignmentStateEnum } from 'api/completeApiInterfaces';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { AssignmentState } from './AssignmentState';

export type ChangeAssignmentStateOption = {
  state: AssignmentStateEnum;
  label: ReactNode;
};

type Props = {
  value: AssignmentStateEnum;
  onChange: (value: AssignmentStateEnum) => void;
  options: ChangeAssignmentStateOption[];
  loading?: boolean;
  disabled?: boolean;
};

export const AssignmentEditableState: FunctionComponent<Props> = ({ value, onChange, options, loading, disabled }) => {
  if (!options?.length || !onChange) {
    return <AssignmentState state={value} />;
  }

  const menu = useMemo(
    () => ({
      items: options
        .filter((option) => option.state !== value)
        .map((option) => ({
          key: option.state,
          label: <AssignmentState state={option.state} tooltip={option.label} />,
          onClick: () => onChange(option.state),
        })),
    }),
    [value, options, onChange]
  );

  return (
    <Dropdown menu={menu} trigger={loading ? [] : ['click']} placement={'bottomRight'} disabled={disabled}>
      <span>
        <AssignmentState clickable state={value} additionalContent={loading ? <LoadingOutlined /> : <DownOutlined />} />
      </span>
    </Dropdown>
  );
};
