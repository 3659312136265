import { DisableWindowStoreModel, DisableWindowStoreModelState } from 'store/models/storeModelinterfaces';

const disableWindowStoreDefaults: DisableWindowStoreModelState = {
  isActive: false,
  showMask: false,
  message: null,
};

export const disableWindowStoreModel: DisableWindowStoreModel = {
  state: disableWindowStoreDefaults,
  reducers: {
    stopPreventing: (state) => ({ ...state, isActive: false }),
    startPreventing: (_state, { showMask, message }) => ({
      isActive: true,
      showMask: showMask,
      message: message,
    }),
  },
};
