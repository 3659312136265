import { AssignmentMessageDto } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, ReactNode } from 'react';
import { projectsMapSelector } from 'store/selectors';
import { AssignmentOverview } from './AssignmentOverview';

type Props = {
  assignments: AssignmentMessageDto[];
  loadMore: ReactNode;
  selectedAssignmentId: Guid;
  selectAssignment: (assignmentId: Guid) => void;
};

const AssignmentListComponent: FunctionComponent<Props> = ({
  assignments,
  loadMore,
  selectedAssignmentId,
  selectAssignment,
}) => {
  const projectsMap = useStoreSelector(projectsMapSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  return (
    <GeneralSettingsContainer>
      {assignments.map((assignment) => (
        <AssignmentOverview
          key={assignment.id}
          assignment={assignment}
          selected={assignment.assignmentId === selectedAssignmentId}
          onClick={() => selectAssignment(assignment.assignmentId)}
          projects={projectsMap}
        />
      ))}
      <FlowLayout>{loadMore}</FlowLayout>
    </GeneralSettingsContainer>
  );
};

export const AssignmentList = React.memo(AssignmentListComponent);
