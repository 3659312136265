import { FileViewerComponent } from 'components/FileViewer/FileViewerInterfaces';
import { AudioViewer } from 'components/FileViewer/viewers/AudioViewer';
import { ForgeViewer } from 'components/FileViewer/viewers/ForgeViewer';
import { ImageViewer } from 'components/FileViewer/viewers/ImageViewer';
import { OfficeOnlineViewer } from 'components/FileViewer/viewers/OfficeOnlineViewer';
import { PdfViewer } from 'components/FileViewer/viewers/PdfViewer';
import { PreviewImageViewer } from 'components/FileViewer/viewers/PreviewImageViewer';
import { VideoViewer } from 'components/FileViewer/viewers/VideoViewer';
import { XmlViewer } from 'components/FileViewer/viewers/XmlViewer';
import { ProcessedJsonViewer } from './viewers/ProcessedJsonViewer';

export const viewerComponentsMap: Record<string, FileViewerComponent> = {
  AudioViewer: AudioViewer,
  ForgeViewer: ForgeViewer,
  ImageViewer: ImageViewer,
  OfficeOnlineViewer: OfficeOnlineViewer,
  PdfViewer: PdfViewer,
  PreviewImageViewer: PreviewImageViewer,
  VideoViewer: VideoViewer,
  XmlViewer: XmlViewer,
  ProcessedJsonViewer: ProcessedJsonViewer,
};
