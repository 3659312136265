import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { DirectoryDownloadDto, DirectoryListDto } from 'api/completeApiInterfaces';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import {
  DirectoryAddIcon,
  DirectoryDiscardIcon,
  DirectoryEditIcon,
  DirectoryMoveIcon,
  DownloadZipDeepWithFilesIcon,
  DownloadZipDeepWithoutFilesIcon,
  DownloadZipIcon,
  DownloadZipThisDirOnlyIcon,
  GetDirectoryReportIcon,
  ShareAppUsersIcon,
  ShareDownloadIcon,
  ShareIcon,
  UploadIcon,
} from 'components/Icons/HubActionsIcons';
import { ActivityIcon, ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar from 'components/Toolbar/Toolbar';
import { FiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToggleButton } from 'components/filters/render/FilterToggleButton/FilterToggleButton';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { TOOLTIP_MOUSE_ENTER_DELAY } from 'config/constants';
import { useFavoriteDirectoryButton } from 'hooks/useOnOffButton';
import { Fmt, InjectedIntlProps } from 'locale';
import { SelectedItemsContext } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import React, { FunctionComponent, memo, useContext, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { checkDirectoryWriteAccess } from 'utils';
import { DisabledWithReason } from 'utils/types';

type Props = InjectedIntlProps & {
  className?: string;
  selectedDirectory: DirectoryListDto;
  onMultipleUpload: () => void;
  onCreateDirectory: () => void;
  onMoveDirectory: (moveDirectorySourceId?: Guid, moveDirectoryDestinationId?: Guid) => void;
  onDirectoryEdit: () => void;
  onDirectoryDiscard: (selectedDirectory: DirectoryListDto) => void;
  onDownloadZip: (dirId: Guid, directoryData: DirectoryDownloadDto) => void;
  onFavoriteChange: (dirId: Guid, isFavorite: boolean) => void;
  onShareAppUser: () => void;
  onShareDownload: () => void;
  onCreateDirectoryLink: () => void;
  onEditDirectoryLink: () => void;
  onDiscardDirectoryLink: () => void;
  onMoveDirectoryLink: () => void;
  isSelectedDirectory: boolean;
  downloadZipLoading?: boolean;
  createDisabled?: boolean;
  moveDisabled?: boolean;
  modifyDisabled?: boolean;
  discardDisabled?: DisabledWithReason;
  onOpenExport: () => void;
  setAuditLogDirectory: (value: React.SetStateAction<DirectoryListDto>) => void;
};

const DirectoryToolbar: FunctionComponent<Props> = ({
  className,
  selectedDirectory,
  onMultipleUpload,
  onCreateDirectory,
  onMoveDirectory,
  onDirectoryEdit,
  onDirectoryDiscard,
  onDownloadZip,
  onFavoriteChange,
  onShareAppUser,
  onShareDownload,
  onCreateDirectoryLink,
  onEditDirectoryLink,
  onDiscardDirectoryLink,
  onMoveDirectoryLink,
  isSelectedDirectory,
  downloadZipLoading,
  createDisabled,
  moveDisabled,
  modifyDisabled,
  discardDisabled,
  onOpenExport,
  setAuditLogDirectory,
}) => {
  const moveButton = useMemo((): ToolbarItemProps => {
    return {
      disabled: moveDisabled,
      icon: <DirectoryMoveIcon />,
      label: 'general.move',
      onClick: isSelectedDirectory ? () => onMoveDirectory(selectedDirectory?.id) : undefined,
      key: 'moveDirectoryCommon',
      subItems: isSelectedDirectory
        ? undefined
        : [
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryChoice',
              onClick: () => onMoveDirectory(selectedDirectory?.id),
              key: 'moveDirectory',
            },
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryLinkChoice',
              onClick: onMoveDirectoryLink,
              key: 'moveDirectoryLink',
            },
          ],
    };
  }, [isSelectedDirectory, moveDisabled, onMoveDirectory, onMoveDirectoryLink, selectedDirectory?.id]);

  const editButton = useMemo((): ToolbarItemProps => {
    return {
      disabled: modifyDisabled,
      icon: <DirectoryEditIcon />,
      label: 'general.edit',
      onClick: isSelectedDirectory ? onDirectoryEdit : undefined,
      key: 'editDirectoryCommon',
      subItems: isSelectedDirectory
        ? undefined
        : [
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryChoice',
              onClick: onDirectoryEdit,
              key: 'editDirectory',
            },
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryLinkChoice',
              onClick: onEditDirectoryLink,
              key: 'editDirectoryLink',
            },
          ],
    };
  }, [isSelectedDirectory, modifyDisabled, onDirectoryEdit, onEditDirectoryLink]);

  const discardButton = useMemo((): ToolbarItemProps => {
    return {
      disabled: !!discardDisabled,
      icon: <DirectoryDiscardIcon />,
      label: 'AllDocumentsPage.docMenu.discard',
      onClick: isSelectedDirectory ? () => onDirectoryDiscard(selectedDirectory) : undefined,
      key: 'discardDirectoryCommon',
      tooltip: typeof discardDisabled === 'string' ? discardDisabled : undefined,
      subItems: isSelectedDirectory
        ? undefined
        : [
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryChoice',
              onClick: () => onDirectoryDiscard(selectedDirectory),
              key: 'discardDirectory',
            },
            {
              label: 'AllDocumentsPageDirectoryToolbar.directoryLinkChoice',
              onClick: onDiscardDirectoryLink,
              key: 'discardDirectoryLink',
            },
          ],
    };
  }, [onDirectoryDiscard, onDiscardDirectoryLink, discardDisabled, isSelectedDirectory, selectedDirectory]);

  const favoriteButton = useFavoriteDirectoryButton(
    'favorite',
    selectedDirectory?.id,
    selectedDirectory?.isFavorite,
    onFavoriteChange
  );

  const items = useMemo(
    (): ToolbarItemProps[] => [
      {
        disabled: !checkDirectoryWriteAccess(selectedDirectory?.currentAccessLevel),
        icon: <UploadIcon />,
        label: 'AllDocumentsPage.docMenu.upload',
        onClick: onMultipleUpload,
        key: 'upload',
      },
      {
        icon: <ShareIcon />,
        label: 'general.docMenu.share',
        key: 'shareDropdown',
        subItems: [
          {
            icon: <ShareDownloadIcon />,
            label: 'general.docMenu.shareDownload',
            onClick: onShareDownload,
            key: 'shareDownload',
          },
          {
            icon: <ShareAppUsersIcon />,
            label: 'general.docMenu.shareAppUserDownload',
            onClick: onShareAppUser,
            key: 'shareAppUser',
          },
        ],
      },
      {
        disabled: downloadZipLoading,
        icon: downloadZipLoading ? <LoadingOutlined /> : <DownloadZipIcon />,
        label: 'general.docMenu.downloadInZip',
        key: 'downloadDropdown',
        subItems: [
          {
            icon: <DownloadZipThisDirOnlyIcon />,
            label: 'general.docMenu.downloadInZip.thisDirOnly',
            onClick: () =>
              onDownloadZip(selectedDirectory?.id, {
                name: selectedDirectory?.name,
                withFiles: true,
                withSubfolders: false,
                ignoreDirectoryLink: false,
              }),
            key: 'thisDirOnly',
          },
          {
            icon: <DownloadZipDeepWithFilesIcon />,
            label: 'general.docMenu.downloadInZip.deepWithFiles',
            onClick: () =>
              onDownloadZip(selectedDirectory?.id, {
                name: selectedDirectory?.name,
                withFiles: true,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              }),
            key: 'deepWithFiles',
          },
          {
            icon: <DownloadZipDeepWithoutFilesIcon />,
            label: 'general.docMenu.downloadInZip.deepWithoutFiles',
            onClick: () =>
              onDownloadZip(selectedDirectory?.id, {
                name: selectedDirectory?.name,
                withFiles: false,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              }),
            key: 'deepWithoutFiles',
          },
        ],
      },
      {
        disabled: createDisabled,
        icon: <DirectoryAddIcon />,
        label: 'AllDocumentsPage.docMenu.createDirectory',
        onClick: onCreateDirectory,
        key: 'createDirectory',
      },
      moveButton,
      editButton,
      discardButton,
      {
        icon: <ShortcutIcon />,
        label: 'general.dirMenu.createDirectoryLink',
        onClick: onCreateDirectoryLink,
        key: 'createDirectoryLink',
      },
      favoriteButton,
      {
        icon: <GetDirectoryReportIcon />,
        label: 'AllDocumentsPage.directory.report.button.tooltip',
        onClick: onOpenExport,
        key: 'export',
      },
      {
        icon: <ActivityIcon />,
        label: 'general.activity',
        onClick: () => setAuditLogDirectory(selectedDirectory),
        key: 'auditLog',
      },
    ],
    [
      favoriteButton,
      selectedDirectory,
      onMultipleUpload,
      createDisabled,
      onCreateDirectory,
      moveButton,
      editButton,
      discardButton,
      downloadZipLoading,
      onShareDownload,
      onShareAppUser,
      onCreateDirectoryLink,
      onDownloadZip,
      onOpenExport,
      setAuditLogDirectory,
    ]
  );

  const { selectAll, isAllSelected } = useContext(SelectedItemsContext);
  const { setOrder, order, orderOptions, activeFiltersCount, isFilterActive, toggleFilters } = useContext(
    FiltersContext
  );

  return (
    <Toolbar
      className={className}
      leftContent={
        <DocumentCheckbox
          onClickCheckbox={selectAll}
          selected={isAllSelected}
          tooltip={isAllSelected ? <Fmt id="DocumentsGridHeader.deselect" /> : <Fmt id="DocumentsGridHeader.select" />}
        />
      }
      rightContent={
        <Space.Compact>
          <OrderSelect setOrder={setOrder} order={order} orderOptions={orderOptions} showTooltip />
          <FilterToggleButton
            onClick={toggleFilters}
            activeFiltersCount={activeFiltersCount}
            isOn={isFilterActive}
            tooltipDelay={TOOLTIP_MOUSE_ENTER_DELAY}
            showTooltip
          />
        </Space.Compact>
      }
    >
      <ShrinkableToolbar items={items} />
    </Toolbar>
  );
};

export default injectIntl(memo(DirectoryToolbar));
