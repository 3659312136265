import { SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ProjectUserProfileListDto, RoleDto } from 'api/completeApiInterfaces';
import { RoleInfo } from 'components/RoleInfo/RoleInfo';
import StackPanel from 'components/StackPanel';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';
import { UserTooltipDetail } from '../UserTooltipDetail/UserTooltipDetail';
import styles from './MultiUserAvatar.module.less';

type Props = {
  title?: string;
  popoverPlacement?: TooltipPlacement;
  revisionUser?: ProjectUserProfileListDto;
  documentUser?: ProjectUserProfileListDto;
  documentOwner?: RoleDto;
  avatar: ReactNode;
};

const ModularMultiUserAvatarComponent: FunctionComponent<Props> = ({
  title,
  popoverPlacement,
  revisionUser,
  documentUser,
  documentOwner,
  avatar,
}) => {
  const content = (
    <StackPanel vertical className={styles.popoverContent}>
      {revisionUser && (
        <UserTooltipDetail
          title={
            revisionUser.username === documentUser?.username ? (
              <>
                <UserOutlined /> <Fmt id="UserAvatar.MultiUserAvatar.documentUserAndRevisionUserTitle" />
              </>
            ) : (
              <>
                <UserOutlined /> <Fmt id="UserAvatar.MultiUserAvatar.revisionUserTitle" />
              </>
            )
          }
          user={revisionUser}
        />
      )}
      {documentUser && revisionUser?.username !== documentUser.username && (
        <UserTooltipDetail
          title={
            <>
              <UserOutlined /> <Fmt id="UserAvatar.MultiUserAvatar.documentUserTitle" />
            </>
          }
          user={documentUser}
        />
      )}

      {documentOwner && (
        <UserTooltipDetail
          title={
            <>
              <SolutionOutlined /> <Fmt id="general.manager" />
            </>
          }
        >
          <RoleInfo role={documentOwner} emailLink />
        </UserTooltipDetail>
      )}
    </StackPanel>
  );

  return (
    <Popover placement={popoverPlacement} content={content} title={title}>
      <div>{avatar}</div>
    </Popover>
  );
};

export const ModularMultiUserAvatar = React.memo(ModularMultiUserAvatarComponent);
