import { api } from 'api';
import { processApiError } from 'utils';
import { ServiceErrorEnum } from 'api/errors';
import { DirectoryForbiddenErrorData } from 'components/DirectoryForbiddenErrorBox/DirectoryForbiddenError';
import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from 'hooks';
import { DirectoryDto, ServiceError } from 'api/completeApiInterfaces';

export const useLoadDirectory = (directoryId: Guid | undefined) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [directory, setDirectory] = useState<DirectoryDto | null>(null);
  const [error, setError] = useState<ServiceError | null>(null);

  const isMounted = useIsMounted();

  const loadDirectory = useCallback(async () => {
    if (!directoryId) {
      return Promise.reject();
    }
    const [err, res] = await api.project.directories.getDirectoryById(directoryId);
    if (!isMounted.current) {
      return Promise.reject();
    }

    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DirectoryInsufficientAccessLevelError) {
          const errorData = error.errorData as DirectoryForbiddenErrorData;
          const { directoryId } = errorData || {};
          if (!!directoryId) {
            setDirectory(undefined);
          }
        }
        setDirectory(undefined);
        setLoading(false);
        setError(error);
      });
      return Promise.reject(err);
    } else {
      setDirectory(res.data);
      setLoading(false);
      setError(null);
      return Promise.resolve(res.data);
    }
  }, [directoryId]);

  useEffect(() => {
    void loadDirectory();
  }, [directoryId]);

  return { directory, loadDirectory, setDirectory, directoryLoading: loading, directoryError: error } as const;
};
