import { Button } from 'antd';
import { userManager } from 'config';
import { Fmt } from 'locale';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

class SignOutPage extends Component<Props> {
  componentDidMount() {
    if (this.isForceLogout()) {
      userManager.signoutRedirect();
    }
  }

  render() {
    if (this.isForceLogout()) return <h2>Signing out...</h2>;
    return (
      <div>
        <h2>You have been signed out...</h2>
        <Link to="/authorize">
          <Button>
            <Fmt id="general.signIn" />
          </Button>
        </Link>
      </div>
    );
  }

  isForceLogout = () => this.props.location.search === '?force';
}

export default SignOutPage;
