import { useIsMounted } from 'hooks/useIsMounted';
import { useCallback, useEffect, useRef } from 'react';
import uuid from 'uuid';

const modalInstanceVisibleStack: string[] = [];

function getLastItem<T>(stack: T[]): T {
  if (stack.length === 0) return undefined;
  return stack[stack.length - 1];
}

function removeItem<T>(array: T[], item: T): void {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function useInstanceStack(triggerValue: boolean) {
  const instanceCounterRef = useRef<string>(uuid());
  const isMounted = useIsMounted();

  const addThisInstance = useCallback(() => {
    if (isMounted.current) modalInstanceVisibleStack.push(instanceCounterRef.current);
  }, []);

  const removeThisInstance = useCallback(() => {
    if (isMounted.current) removeItem(modalInstanceVisibleStack, instanceCounterRef.current);
  }, []);

  const isThisLastInstance = useCallback(() => {
    return getLastItem(modalInstanceVisibleStack) === instanceCounterRef.current;
  }, []);

  useEffect(() => {
    if (triggerValue) {
      addThisInstance();
      return () => {
        removeThisInstance();
      };
    }
    return () => {};
  }, [triggerValue]);

  useEffect(() => {
    return () => {
      removeItem(modalInstanceVisibleStack, instanceCounterRef.current);
    };
  }, []);

  return { isThisLastInstance } as const;
}
