import { message } from 'antd';
import { api } from 'api';
import { DirectoryListDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { memoizeWithIntl } from 'locale';
import { trim } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { match } from 'react-router';
import { DirectoryRouteParams } from 'routes';
import { processApiError } from 'utils';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryMoveForm, { DirectoryMoveFormData } from './DirectoryMoveForm';

type Props = InjectedIntlProps &
  FormModalProps<DirectoryListDto[]> & {
    disabled?: (node: ConnectedDirectory) => boolean;
    match: match<DirectoryRouteParams>;
    selectedDirectory: DirectoryListDto;
    destinationDirectory?: Guid;
  };

const DirectoryMoveFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, selectedDirectory, ...restProps } = props;

  const [errors, setErrors] = useState<JSX.Element[]>([]);

  const handleSubmit: FormSubmitHandler<DirectoryMoveFormData> = async (values) => {
    const [err, res] = await api.project.directories.moveDirectory(selectedDirectory.id, {
      ...values,
      name: trim(values.name),
    });
    if (err) {
      const error = processApiError(err);
      if (!!error?.errorData) {
        switch (error.referenceErrorCode) {
          case ServiceErrorEnum.DocumentMultipleMoveObstacleError:
            // eslint-disable-next-line no-case-declarations
            const errData = error.errorData as IMultipleDocumentErrorData;
            setErrors(errData.documentsErrors.map(transformError));
            return null;
        }
      }
      message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      return null;
    }
    await onSubmit(res.data);
    return null;
  };

  const { destinationDirectory } = props;
  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="DirectoryMoveFormModal.title"
      confirmClose={false}
      width={800}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DirectoryMoveForm
          selectedDirectory={selectedDirectory}
          destinationDirectory={destinationDirectory}
          wrappedComponentRef={formRef}
          intl={intl}
          errors={errors}
        />
      )}
    </FormModalWrapper>
  );
};

export default memoizeWithIntl(DirectoryMoveFormModal);
