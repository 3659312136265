import { ProjectUserProfileDto } from 'api/completeApiInterfaces';
import React, { FunctionComponent, useMemo } from 'react';
import { userHasPermission } from 'utils/userHasPermission';
import { useIntl } from 'hooks';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { PageSubmenu, routeType } from 'components/PageSubmenu/PageSubmenu';

type Props = {
  currentProjectUser: ProjectUserProfileDto;
  selectedItemId?: string;
  items: routeType[];
  onSelect?: (key: string) => void;
};

export const ProjectSettingsPageMenu: FunctionComponent<Props> = ({
  currentProjectUser,
  selectedItemId,
  items,
  onSelect,
}) => {
  const intl = useIntl();

  const menuItems: ItemType[] = useMemo(() => {
    return items
      .filter((item) => {
        return !item.userAccess || userHasPermission(currentProjectUser, item.userAccess) || currentProjectUser.isAdmin;
      })
      .map((item) => {
        return {
          key: item.key,
          icon: item.icon,
          label: intl.formatMessage({ id: item.title }),
          onClick: () => {
            onSelect && onSelect(item.key);
          },
        };
      });
  }, [currentProjectUser, intl, onSelect, items]);

  const selectedKeys: string[] = useMemo(() => {
    return selectedItemId ? [selectedItemId] : [];
  }, [selectedItemId]);

  return <PageSubmenu items={menuItems} selectedKeys={selectedKeys} />;
};

export default ProjectSettingsPageMenu;
