import { createCancelToken } from 'api';
import {
  EntityTypesEnum,
  ExternalApplicationsProjectSettingsDto,
  ProjectHubDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import Axios, { CancelToken } from 'axios';
import { AuditLogButton } from 'components/ActionButtons';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import List from 'components/List';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { ExternAppAdd } from 'pages/ProjectSettingsPage/ExternApps/ExternAppAdd';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { messageError, processApiError } from 'utils';
import Panel from '../Panel';
import { ApplicationUrlListItem } from './ApplicationUrl/ApplicationUrlListItem';
import { DocumentViewListItem } from './DocumentView/DocumentViewListItem';
import { EsticonListItem, loadEsticonListItems } from './Esticon/EsticonListItem/EsticonListItem';
import { TextPageListItem } from './TextPage/TextPageListItem';
import {
  AvailableAppEnum,
  ExternAppListItem,
  ExternAppListItemProps,
  ExternalAppSourceTypeEnum,
} from './externAppsTypes';
import { loadExternalApplicationsListItems } from './loadExternalApplicationsListItems';

const LIST_LOADERS: ((
  project: ProjectHubDto,
  externalApplications: ExternalApplicationsProjectSettingsDto,
  ct: CancelToken
) => Promise<ExternAppListItem[]>)[] = [loadEsticonListItems, loadExternalApplicationsListItems];

const LIST_RENDERERS: Record<AvailableAppEnum, React.FunctionComponent<ExternAppListItemProps>> = {
  [AvailableAppEnum.Esticon]: EsticonListItem,
  [AvailableAppEnum.ConstructionJournal]: ApplicationUrlListItem,
  [AvailableAppEnum.ExternalUrl]: ApplicationUrlListItem,
  [AvailableAppEnum.TextPage]: TextPageListItem,
  [AvailableAppEnum.DocumentWindow]: DocumentViewListItem,
};

type Props = {
  project: ProjectHubDto;
  selectedItemId: string;
  onClick: (id: string) => void;
  onDelete: () => void;
};

export const ExternAppsList: FunctionComponent<Props> = ({ project, selectedItemId, onClick, onDelete }) => {
  const [appList, setAppList] = useState<ExternAppListItem[]>([]);
  const [appListLoading, setAppListLoading] = useState<boolean>(true);
  const [appListError, setAppListError] = useState<ServiceError>(null);
  const [auditLogVisible, showAuditLog, hideAuditLog] = useBoolean(false);
  const externalAppSettings = useSelectorDispatch(
    (state) => state.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const intl = useIntl();

  useEffect(() => {
    if (externalAppSettings.error) {
      messageError(externalAppSettings.error, intl);
    }
  }, [externalAppSettings]);

  useEffect(() => {
    const ctSource = createCancelToken();
    setAppListLoading(true);
    Promise.all(LIST_LOADERS.map((loader) => loader(project, externalAppSettings.data, ctSource.token)))
      .then((results) => {
        setAppList(results.flat());
        setAppListLoading(false);
      })
      .catch((err) => {
        if (!Axios.isCancel(err)) {
          setAppListError(processApiError(err));
        }
      });
    return () => ctSource.cancel('ExternAppsList: project changed or unmounting');
  }, [project, externalAppSettings?.data]);

  const renderItem = useCallback(
    (item: ExternAppListItem) => {
      const Component = LIST_RENDERERS[item.appType];
      return (
        <Component
          key={`${item.appType}_${
            item.type === ExternalAppSourceTypeEnum.Project ? item.appId : item.externalAppSetting.id
          }`}
          selected={item.appType === selectedItemId && selectedItemId != null}
          onClick={() => onClick(item.appType)}
          onDelete={() => onDelete()}
          {...item}
        />
      );
    },
    [project, selectedItemId, onDelete, onClick]
  );

  const handleSetSelectedApp = useCallback(
    (appType: AvailableAppEnum) => {
      if (appType === AvailableAppEnum.Esticon) {
        onClick(AvailableAppEnum.Esticon);
      }
    },
    [onClick]
  );

  const toolbarTitle = (
    <FlowLayout>
      <ExternAppAdd project={project} onAppAdded={handleSetSelectedApp} />
      {externalAppSettings.data?.id && <AuditLogButton onClick={showAuditLog} />}
    </FlowLayout>
  );

  return (
    <Panel noMargin panelWidth="auto" toolbarTitle={toolbarTitle}>
      <ContentGate error={appListError} loading={appListLoading || externalAppSettings.loading}>
        <GeneralSettingsContainer>
          <List<ExternAppListItem> data={appList} renderItem={renderItem} />
        </GeneralSettingsContainer>
      </ContentGate>
      <AuditLogEntityModal
        visible={auditLogVisible}
        label={<Fmt id="general.activity" />}
        entityType={EntityTypesEnum.projectSetting}
        entityId={externalAppSettings.data?.id}
        onOk={hideAuditLog}
        deps={[externalAppSettings.data]}
      />
    </Panel>
  );
};
