import Title from 'antd/lib/typography/Title';
import React, { FunctionComponent } from 'react';
import styles from './MessageViewer.module.less';

export type Props = {
  header: JSX.Element;
  content: JSX.Element;
};

const MessageViewerStyled: FunctionComponent<Props> = ({ header, content }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headline}>
          <Title level={2}>{header}</Title>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>{content}</div>
      </div>
    </>
  );
};

export default MessageViewerStyled;
