import { Button, Col, List, Modal, ModalProps, Row } from 'antd';
import { OrganizationDto } from 'api/completeApiInterfaces';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MinHeightSizer } from 'components/MinHeightSizer/MinHeightSizer';
import {
  PersonalReportWidgetConfiguration,
  useReportWidgetsContext,
} from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useSharedReportWidgets } from 'components/Reports/useSharedReportWidgets';

import { CopyOutlined } from '@ant-design/icons';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import uuid from 'uuid';
import { ReportWidgetCreateFormData, ReportWidgetCreateFormModal } from '../ReportWidgetCreateForm';
import ReportWidgetEditFormModal from '../ReportWidgetCreateForm/ReportWidgetEditFormModal';
import styles from './SharedReportWidgetModal.module.less';

type Props = Omit<ModalProps, 'title' | 'cancelButtonProps' | 'okText' | 'onOk'> & {
  organizationId: Guid;
  availableOrganizations: OrganizationDto[];
  defaults: ReportWidgetCreateFormData;
};

const SharedReportWidgetsModal: FunctionComponent<Props> = (props) => {
  const { onCancel, organizationId, availableOrganizations, defaults, ...modalProps } = props;
  const intl = useIntl();
  const [sharedWidgetCreateModalVisible, showSharedWidgetCreate, hideSharedWidgetCreate] = useBoolean();
  const [sharedWidgetEditModalVisible, showSharedWidgetEdit, hideSharedWidgetEdit] = useBoolean();
  const [selectedConfiguration, setSelectedConfiguration] = useState<PersonalReportWidgetConfiguration>();

  const { requestCacheApiData } = useReportWidgetsContext();

  const {
    sharedWidgetConfigurations,
    saveSharedConfiguration,
    sharedWidgetsLoading,
    loadSharedConfiguration,
  } = useSharedReportWidgets();

  useEffect(() => {
    loadSharedConfiguration(organizationId);
  }, [organizationId]);

  const hideSharedConfigurationEdit = useCallback(() => {
    hideSharedWidgetEdit();
    setSelectedConfiguration(undefined);
  }, [hideSharedWidgetEdit]);

  const handleSharedWidgetCreate = useCallback(
    async (newWidgetData: PersonalReportWidgetConfiguration) => {
      await saveSharedConfiguration([...sharedWidgetConfigurations, newWidgetData]);
      hideSharedWidgetCreate();
    },
    [hideSharedWidgetCreate, saveSharedConfiguration, sharedWidgetConfigurations]
  );

  const handleSharedWidgetEdit = useCallback(
    async (editedWidgetData: PersonalReportWidgetConfiguration) => {
      await saveSharedConfiguration([
        ...sharedWidgetConfigurations.map((config) => (config.id === editedWidgetData.id ? editedWidgetData : config)),
      ]);
      hideSharedConfigurationEdit();
    },
    [hideSharedConfigurationEdit, saveSharedConfiguration, sharedWidgetConfigurations]
  );

  const handleConfigurationDelete = useCallback(
    (sharedWidgetId: Guid) => {
      Modal.confirm({
        title: intl.formatMessage({ id: `SharedReportWidgetsModal.confirmDelete.title` }),
        content: intl.formatMessage({ id: 'SharedReportWidgetsModal.confirmDelete.description' }),
        onOk: async () => {
          await saveSharedConfiguration([
            ...sharedWidgetConfigurations.filter((config) => config.id !== sharedWidgetId),
          ]);
        },
        okText: intl.formatMessage({ id: 'general.delete' }),
        cancelText: intl.formatMessage({ id: 'general.cancel' }),
      });
    },
    [intl, saveSharedConfiguration, sharedWidgetConfigurations]
  );

  const setConfigurationEdit = useCallback(
    (sharedWidgetId: Guid) => {
      const editedWidgetConfiguration = sharedWidgetConfigurations.find(
        (configuration) => configuration.id === sharedWidgetId
      );
      setSelectedConfiguration(editedWidgetConfiguration);
      showSharedWidgetEdit();
    },
    [sharedWidgetConfigurations, showSharedWidgetEdit]
  );

  const copyConfiguration = useCallback(
    async (sharedWidgetId: Guid) => {
      const widgetIndex = sharedWidgetConfigurations.findIndex((configuration) => configuration.id === sharedWidgetId);
      if (!widgetIndex && widgetIndex !== 0) {
        return;
      }
      const widgetConfiguration = sharedWidgetConfigurations[widgetIndex];

      const newSharedWidgets = [...sharedWidgetConfigurations];
      const newReportName = `(${intl.formatMessage({
        id: 'WidgetReportsContext.message.cloneWidget.prefix',
      })}) ${widgetConfiguration.title}`;
      newSharedWidgets.splice(widgetIndex + 1, 0, {
        ...widgetConfiguration,
        id: uuid(),
        title: newReportName,
      });

      await saveSharedConfiguration([...newSharedWidgets.map((config, index) => ({ ...config, order: index }))]);
    },
    [sharedWidgetConfigurations, saveSharedConfiguration]
  );

  return (
    <Modal
      title={intl.formatMessage({ id: 'SharedReportWidgetsModal.title' })}
      cancelButtonProps={HIDE_BUTTON_PROPS}
      okText={intl.formatMessage({ id: 'general.close' })}
      onOk={onCancel}
      onCancel={onCancel}
      {...modalProps}
    >
      <MinHeightSizer minHeight={350} scrollable>
        <ContentGate loading={sharedWidgetsLoading} empty={!sharedWidgetConfigurations}>
          <List
            size="small"
            dataSource={sharedWidgetConfigurations}
            renderItem={(item) => (
              <List.Item>
                <Row gutter={2} className={styles.sharedWidgetRow}>
                  <Col span={12}>{item.title}</Col>
                  <Col span={8}>
                    <Fmt id={`Reporting.reportType.name.${item.data.reportType}`} />
                  </Col>
                  <Col span={3}>
                    <StackPanel className={styles.iconPanel} showOverflow>
                      <Button icon={<CopyOutlined />} onClick={() => copyConfiguration(item.id)} type="link" />
                      <EditButton onClick={() => setConfigurationEdit(item.id)} />
                      <DeleteButton onClick={() => handleConfigurationDelete(item.id)} />
                    </StackPanel>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </ContentGate>
      </MinHeightSizer>
      <Button onClick={showSharedWidgetCreate}>
        <Fmt id="general.add" />
      </Button>
      {sharedWidgetCreateModalVisible && (
        <ReportWidgetCreateFormModal
          visible={sharedWidgetCreateModalVisible}
          onSubmit={handleSharedWidgetCreate}
          onClose={hideSharedWidgetCreate}
          organizations={availableOrganizations}
          requestCacheApiData={requestCacheApiData}
          defaults={defaults}
        />
      )}
      {sharedWidgetEditModalVisible && (
        <ReportWidgetEditFormModal
          visible={sharedWidgetEditModalVisible}
          onSubmit={handleSharedWidgetEdit}
          onClose={hideSharedConfigurationEdit}
          configuration={selectedConfiguration}
          requestCacheApiData={requestCacheApiData}
        />
      )}
    </Modal>
  );
};

export default React.memo(SharedReportWidgetsModal);
