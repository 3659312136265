import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DirectoryReportOrderTypeEnum, DirectoryReportSettingsDto, SortOrder } from 'api/completeApiInterfaces';
import { DateRangeValue, InlineDateRangePicker } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import { Margin } from 'components/Margin/Margin';
import { SelectFilterValue } from 'components/filters/components/SelectFilter/SelectFilter';
import {
  UsersFilter,
  UsersFilterType,
} from 'components/filters/components/SelectFilter/variants/UsersFilter/UsersFilter';
import { OrderOption, OrderValue } from 'components/filters/orderTypes';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { useSameCallback } from 'hooks';
import produce from 'immer';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { valueOrProducer } from 'utils';
import styles from './AllDocumentsPage.module.less';

const ORDER_OPTIONS: OrderOption<DirectoryReportOrderTypeEnum>[] = [
  {
    key: DirectoryReportOrderTypeEnum.author,
    label: <Fmt id={'AllDocumentsPage.directory.report.form.authorOrder'} />,
    defaultOrder: SortOrder.asc,
  },
  {
    key: DirectoryReportOrderTypeEnum.createTime,
    label: <Fmt id={'AllDocumentsPage.directory.report.form.createTimeOrder'} />,
  },
  {
    key: DirectoryReportOrderTypeEnum.documentName,
    label: <Fmt id={'AllDocumentsPage.directory.report.form.documentNameOrder'} />,
  },
  {
    key: DirectoryReportOrderTypeEnum.documentPath,
    label: <Fmt id={'AllDocumentsPage.directory.report.form.documentPathOrder'} />,
  },
];

const USERS_TYPE_AUTHOR = 'author';
const USERS_FILTER_TYPES: UsersFilterType[] = [
  {
    key: USERS_TYPE_AUTHOR,
    label: <Fmt id="AllDocumentsPage.directory.report.form.authorFilterTitle" />,
    multiple: false,
  },
];

type Props = InjectedIntlProps & {
  onChange: (settings: DirectoryReportSettingsDto) => void;
};

const getDefaultSettings = (): DirectoryReportSettingsDto => {
  return {
    authorsFilter: undefined,
    inline: undefined,
    from: undefined,
    to: undefined,
    orderBy: DirectoryReportOrderTypeEnum.author,
    orderDescending: false,
    includeAllRevisions: false,
    includeSubdirectories: true,
  };
};

interface SetAuthorsFilter {
  type: 'SetAuthorsFilter';
  payload: React.SetStateAction<SelectFilterValue<Guid>>;
}

interface SetFromToCreatedTime {
  type: 'SetFromToCreatedTime';
  payload: DateRangeValue;
}

interface SetOrder {
  type: 'SetOrder';
  payload: React.SetStateAction<OrderValue<DirectoryReportOrderTypeEnum>>;
}

interface SetIncludeAllRevisions {
  type: 'SetIncludeAllRevisions';
  payload: CheckboxChangeEvent;
}

interface SetIncludeSubdirectories {
  type: 'SetIncludeSubdirectories';
  payload: CheckboxChangeEvent;
}
export type SettingsActions =
  | SetAuthorsFilter
  | SetFromToCreatedTime
  | SetOrder
  | SetIncludeAllRevisions
  | SetIncludeSubdirectories;

export function reportStateReducer(state: DirectoryReportSettingsDto, action: SettingsActions) {
  switch (action.type) {
    case 'SetAuthorsFilter':
      return produce(state, (draft) => {
        const oldValue = { values: draft.authorsFilter || [] };
        const newValue = valueOrProducer(action.payload, oldValue);
        draft.authorsFilter = newValue.values;
      });
    case 'SetFromToCreatedTime':
      return produce(state, (draft) => {
        draft.from = action?.payload[0]?.startOf('day').toISOString() || undefined;
        draft.to = action?.payload[1]?.endOf('day').toISOString() || undefined;
      });
    case 'SetOrder':
      return produce(state, (draft) => {
        const oldValue = { key: draft.orderBy, direction: draft.orderDescending ? SortOrder.desc : SortOrder.asc };
        const newValue = valueOrProducer(action.payload, oldValue);
        draft.orderBy = newValue.key;
        draft.orderDescending = newValue.direction === SortOrder.desc;
      });
    case 'SetIncludeAllRevisions':
      return produce(state, (draft) => {
        draft.includeAllRevisions = action.payload.target.checked;
      });
    case 'SetIncludeSubdirectories':
      return produce(state, (draft) => {
        draft.includeSubdirectories = action.payload.target.checked;
      });
    default:
      throw new Error();
  }
}

const DirectoryReportExportFormComponent: FunctionComponent<Props> = ({ intl, onChange }) => {
  const [settingsState, dispatchSettings] = useReducer(reportStateReducer, getDefaultSettings());
  const [dateRangePickerValue, setDateRangePickerValue] = useState<DateRangeValue>([null, null]);

  useEffect(() => {
    onChange && onChange(settingsState);
  }, [settingsState]);

  useEffect(() => {
    dispatchSettings({ type: 'SetFromToCreatedTime', payload: dateRangePickerValue });
  }, [dateRangePickerValue]);

  const handlePickerValueChange = useSameCallback((value?: DateRangeValue) => {
    const correctValue = value || [null, null];
    setDateRangePickerValue(correctValue);
  });

  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col span={12}>
          <Margin bottom>
            <div className={styles.label}>
              <Fmt id={'AllDocumentsPage.directory.report.form.revisionChangeDateTitle'} />
            </div>
            <InlineDateRangePicker onChange={handlePickerValueChange} />
          </Margin>
          <Margin top bottom>
            <div className={styles.label}>
              <Fmt id="CommentProcedureExportForm.Filter" />
            </div>
            <UsersFilter
              label={<Fmt id="AllDocumentsPage.directory.report.form.authorFilterTitle" />}
              value={{
                type: USERS_TYPE_AUTHOR,
                values: settingsState.authorsFilter || [],
                useAndOperator: false,
                notSet: false,
              }}
              onChange={(payload) => dispatchSettings({ type: 'SetAuthorsFilter', payload })}
              filterTypes={USERS_FILTER_TYPES}
            />
          </Margin>
          <Checkbox
            checked={settingsState.includeSubdirectories}
            onChange={(payload) => dispatchSettings({ type: 'SetIncludeSubdirectories', payload })}
          >
            <Fmt id={'AllDocumentsPage.directory.report.form.checkBoxIncludeSubdirectories'} />
          </Checkbox>
          <Checkbox
            checked={settingsState.includeAllRevisions}
            onChange={(payload) => dispatchSettings({ type: 'SetIncludeAllRevisions', payload })}
          >
            <Fmt id={'AllDocumentsPage.directory.report.form.checkBoxIncludeAllRevisions'} />
          </Checkbox>
        </Col>
        <Col span={12}>
          <div className={styles.label}>
            <Fmt id="CommentProcedureExportForm.sortBy" />
          </div>
          <OrderSelect
            orderOptions={ORDER_OPTIONS}
            order={{
              key: settingsState.orderBy,
              direction: settingsState.orderDescending ? SortOrder.desc : SortOrder.asc,
            }}
            setOrder={(payload) => dispatchSettings({ type: 'SetOrder', payload })}
          />
        </Col>
      </Row>
    </Form>
  );
};

export const DirectoryReportExportForm = memoizeWithIntl(DirectoryReportExportFormComponent);
