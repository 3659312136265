import { to } from 'api/await-to';
import {
  CheckEstiPathsDto,
  CommentProcedureProjectSettingsDto,
  CommentProcedureProjectSettingsPatchDto,
  EstiProjectSettingsBaseDto,
  EstiProjectSettingsDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const projectSettingApi = {
  getEsticonSettings: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EstiProjectSettingsDto>(`/projectsetting/esticon`, { cancelToken })
    ),
  saveEsticonSettings: (data: EstiProjectSettingsBaseDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<EstiProjectSettingsDto>(`/projectsetting/esticon`, data, { cancelToken })
    ),
  deleteConstructionJournalSettings: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/projectsetting/constructionjournal`, {
        cancelToken,
      })
    ),
  checkEstiPaths: (data: CheckEstiPathsDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<boolean>(`/projectsetting/checkestipaths`, data, { cancelToken })
    ),
  getCommentProcedureSettings: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<CommentProcedureProjectSettingsDto>(`/projectsetting/commentprocedure`, { cancelToken })
    ),
  patchCommentProcedureSettings: (data: CommentProcedureProjectSettingsPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<CommentProcedureProjectSettingsDto>(`/projectsetting/commentprocedure`, data, {
        cancelToken,
      })
    ),
};
