import { api } from 'api';
import { RoleDto, RolePatchDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { modalConfirm } from 'utils/modalConfirm';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import RoleAssignForm, { RoleAssignFormData } from './RoleAssignForm';

type Props = FormModalProps<RoleAssignFormData> &
  InjectedIntlProps & {
    userId: Guid;
    roles: RoleDto[];
    userRolesSet: Set<Guid>;
  };

const RoleAssignFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, roles, userId, userRolesSet, intl, ...restProps } = props;

  const handleSubmit = useCallback(
    async (values: RoleAssignFormData) => {
      const roleId: Guid = values.roleId as Guid;

      // check existing user
      const filtered = roles.filter((r) => r.id === roleId);
      if (filtered.length && filtered[0].user) {
        // user needs to confirm user change
        const confirm = await modalConfirm({
          title: intl.formatMessage({ id: 'UserDetailPanel.RolesTab.ConfirmChange.title' }),
          content: intl.formatMessage(
            { id: 'UserDetailPanel.RolesTab.ConfirmChange.content' },
            { user: filtered[0].user.username }
          ),
        });

        if (!confirm) {
          // user did not confirm
          return null;
        }
      }

      const data: RolePatchDto = {
        userId: userId,
        removeUser: false,
        strategy: null,
      };

      const [err, response] = await api.project.roles.updateRoleById(roleId, data);

      if (err) return err;
      await onSubmit(values, response.data);

      return null;
    },
    [roles, intl, onSubmit]
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId="RoleAssignFormModal.title.create"
      {...restProps}
    >
      {({ intl, formRef }) => <RoleAssignForm intl={intl} wrappedComponentRef={formRef} userRolesSet={userRolesSet} />}
    </FormModalWrapper>
  );
};

export default RoleAssignFormModal;
