import classNames from 'classnames';
import DisplayName from 'components/DisplayName';
import React, { FunctionComponent, ReactNode } from 'react';
import { ListItem, ListItemProps } from '../ListItem';
import styles from './BasicListItem.module.less';

type Props = ListItemProps & {
  name: string;
  description?: string;
  additional?: ReactNode;
  noConfirmDelete?: boolean;
};

const BasicListItem: FunctionComponent<Props> = ({ name, description, additional, ...restProps }) => (
  <ListItem {...restProps}>
    <div className={styles.wrapper}>
      <div className={classNames(styles.name)} title={name}>
        {<DisplayName text={name} />}
      </div>
      {description && (
        <div className={styles.description} title={description}>
          {description}
        </div>
      )}
      {additional}
    </div>
  </ListItem>
);

export { BasicListItem };
