import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, Select, Tag } from 'antd';
import { MultiUploadCheckResponseDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { PreferredDirectory } from 'components/DirectoriesTreeSelect/DirectoriesPreferredSelect';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { CommonFilesInput } from 'components/PrimaryFileInput/CommonFilesInput';
import { FileSystemTreeNode, FilesStructure } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import { Section } from 'components/Section/Section';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import { SwitchLabeled } from 'components/SwitchLabeled/SwitchLabeled';
import { DocumentUploadStateData } from 'components/UploadState';
import { MAX_ITEM_DESCRIPTION_LENGTH, SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import { DocumentCategoryFormFc } from '../DocumentCategoryForm/DocumentCategoryFormFc';
import { getFormValue } from '../FormModalWrapper';
import { useRequiredCategories } from './DocumentCreateForm';
import styles from './DocumentCreateForm.module.less';

const { Option } = Select;

export type DocumentCreateMultipleFormData = {
  destinationDirectory: Guid;
  description: string;
  labels: Guid[];
  files: FilesStructure;
  secondaryFiles: FilesStructure;
  signedDocument: File;
  categories: Dictionary<Guid>;
  state: WorkflowStateEnum;
  ownedById: Guid;
  revisionState: WorkflowStateEnum;
  revisionComment: string;
  isModel: boolean;
};

type Props = FormComponentProps<DocumentCreateMultipleFormData> &
  InjectedIntlProps & {
    progressItemData: Dictionary<DocumentUploadStateData>;
    visible: boolean;
    startingDirectoryId?: Guid;
    onDirectoryChange?: (directoryId: Guid) => void;
    disableTargetFolderInput?: boolean;
    preferredDirectories?: PreferredDirectory[];
    checkData?: MultiUploadCheckResponseDto;
    onPrimaryFilesValidationChange?: (value: FilesStructure) => void;
    checkLoading?: boolean;
    droppedFiles?: FileSystemTreeNode[];
    defaultIsModel: boolean;
    usefulNewDocumentStatuses: WorkflowStateEnum[];
    // initialPrimaryFiles?: FilesStructure; // TODO - use default values
  };

const DocumentCreateMultipleForm = React.forwardRef<unknown, Props>((props, ref) => {
  const {
    intl,
    form,
    visible,
    checkData,
    checkLoading,
    startingDirectoryId,
    onDirectoryChange,
    preferredDirectories,
    onPrimaryFilesValidationChange,
    droppedFiles,
    defaultIsModel,
    usefulNewDocumentStatuses,
  } = props;

  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  const files = getFormValue(form, 'files');

  const disableSecondaryFiles = files && files.files.length + files.revisions.length !== 1;
  const hasAnyFile = files && files.files.length + files.revisions.length > 0;
  const newDocumentsCount = files && files.files.length;
  const hasNewDocuments = !!newDocumentsCount;
  const revisionsCount = files && files.revisions.length;
  const hasRevisions = !!revisionsCount;

  const secondaryFiles = getFormValue(form, 'secondaryFiles');
  const hasAttachments = secondaryFiles && secondaryFiles.files.length > 0;
  const hasSignedDocument = getFormValue(form, 'signedDocument') != null;
  const canUploadMultipleFiles = !hasAttachments && !hasSignedDocument;

  const { getFieldDecorator } = form;

  const destinationDirectoryId = getFormValue(form, 'destinationDirectory');
  destinationDirectoryId && onDirectoryChange && onDirectoryChange(destinationDirectoryId);
  const requiredCategories = useRequiredCategories(destinationDirectoryId);

  if (!visible) return null;

  return (
    <Form layout="vertical">
      <div className={styles.wrapper}>
        <div className={styles.mainSection}>
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.destinationDirectory.label' })}
          >
            {getFieldDecorator<DocumentCreateMultipleFormData>('destinationDirectory', {
              initialValue: startingDirectoryId,
              rules: [requiredRule('DocumentCreateForm.form.items.destinationDirectory.rules.required')],
            })(
              <DirectoriesTreeSelectFormItem
                isItemDisabled={(item) => !canWriteInDirectory(item)}
                showSearch
                defaultExpandDepth={3}
                preferredDirectories={preferredDirectories}
                placeholder={intl.formatMessage({
                  id: 'DocumentCreateForm.form.items.destinationDirectory.placeholder',
                })}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.primary.label' })}>
            {getFieldDecorator<DocumentCreateMultipleFormData>('files', {
              rules: [
                requiredRule('DocumentCreateMultipleForm.file.rules.required'),
                {
                  validator(rule, value, callback, source, options) {
                    const errors: string[] = [];
                    if (
                      !!value &&
                      value.files.length === 0 &&
                      value.folders.length === 0 &&
                      value.revisions.length === 0
                    ) {
                      errors.push(intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.rules.required' }));
                    }
                    callback(errors);
                  },
                },
              ],
            })(
              <CommonFilesInput
                multiple={canUploadMultipleFiles}
                title={
                  canUploadMultipleFiles ? (
                    <Fmt id="DocumentCreateMultipleForm.primary.text" />
                  ) : (
                    <Fmt id="PrimaryFileInput.collapse.text" />
                  )
                }
                checkData={checkData}
                checkLoading={checkLoading}
                onValidationChange={onPrimaryFilesValidationChange}
                droppedFiles={droppedFiles}
              />
            )}
          </Form.Item>

          {!disableSecondaryFiles && (
            <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.signedDocument.label' })}>
              {getFieldDecorator('signedDocument')(
                <SingleFileInput
                  inputText="SignedDocumentInput.collapse.text"
                  inputHint="SignedDocumentInput.collapse.hint"
                  acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
                />
              )}
            </Form.Item>
          )}
          <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.secondary.label' })}>
            {disableSecondaryFiles ? (
              <Fmt id="DocumentCreateMultipleForm.secondary.cantAdd" />
            ) : (
              getFieldDecorator('secondaryFiles')(
                <CommonFilesInput
                  multiple
                  disableTreeStructure
                  title={<Fmt id="DocumentCreateMultipleForm.secondary.text" />}
                />
              )
            )}
          </Form.Item>
        </div>
        {hasAnyFile && (
          <div className={styles.sideSection}>
            {hasNewDocuments && (
              <div>
                <SettingsBox>
                  <Section
                    title={
                      <>
                        <Fmt id="DocumentCreateMultipleForm.form.items.documentOptions.title" />{' '}
                        <Tag>{newDocumentsCount}</Tag>
                      </>
                    }
                  >
                    <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.state.label' })}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('state', {
                        initialValue: usefulNewDocumentStatuses?.length === 1 ? usefulNewDocumentStatuses[0] : null,
                        rules: [requiredRule('DocumentCreateForm.form.items.state.rules.required')],
                      })(
                        <Select>
                          {usefulNewDocumentStatuses.map((state) => (
                            <Option key={state}>
                              <div className={styles.stateOption}>
                                <RevisionNumberTag state={state} showTitle />
                              </div>
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('isModel', {
                        valuePropName: 'checked',
                        initialValue: defaultIsModel,
                      })(
                        <SwitchLabeled
                          label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.isModelSwitch.text' })}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.description' })}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('description', {
                        rules: [
                          {
                            whitespace: true,
                            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
                          },
                          {
                            max: MAX_ITEM_DESCRIPTION_LENGTH,
                            message: intl.formatMessage(
                              { id: 'general.maxDescriptionLength' },
                              { max: MAX_ITEM_DESCRIPTION_LENGTH }
                            ),
                          },
                        ],
                      })(
                        <Input.TextArea
                          placeholder={intl.formatMessage({
                            id: 'DocumentCreateMultipleForm.file.description.placeholder',
                          })}
                          rows={2}
                          autoSize={{ minRows: 2 }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'forms.items.labels.label' })}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('labels', { initialValue: [] })(
                        <LabelsInput />
                      )}
                    </Form.Item>
                    <Form.Item label={<Fmt id="DocumentCreateMultipleForm.file.manager" />}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('ownedById')(<RoleSelect allowClear />)}
                    </Form.Item>
                    <DocumentCategoryFormFc
                      layout="vertical"
                      form={form}
                      requiredCategories={requiredCategories}
                      autoSelectDefault={true}
                    />
                  </Section>
                </SettingsBox>
              </div>
            )}
            {hasRevisions && (
              <div>
                <SettingsBox>
                  <Section
                    title={
                      <>
                        <Fmt id="DocumentCreateMultipleForm.form.items.revisionOptions.title" />{' '}
                        <Tag>{revisionsCount}</Tag>
                      </>
                    }
                  >
                    <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.state.label' })}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('revisionState', {
                        initialValue: usefulNewDocumentStatuses?.length === 1 ? usefulNewDocumentStatuses[0] : null,
                        rules: [requiredRule('DocumentCreateForm.form.items.state.rules.required')],
                      })(
                        <Select>
                          {usefulNewDocumentStatuses.map((state) => (
                            <Option key={state}>
                              <div className={styles.stateOption}>
                                <RevisionNumberTag state={state} showTitle />
                              </div>
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.revisionComment' })}>
                      {getFieldDecorator<DocumentCreateMultipleFormData>('revisionComment', {
                        rules: [
                          requiredRule('DocumentCreateMultipleForm.file.revisionComment.rules.required', true),
                          maxLengthRule('general.maxDescriptionLength', MAX_ITEM_DESCRIPTION_LENGTH),
                        ],
                      })(
                        <Input.TextArea
                          placeholder={intl.formatMessage({
                            id: 'DocumentCreateMultipleForm.file.description.placeholder',
                          })}
                          rows={2}
                          autoSize={{ minRows: 2 }}
                        />
                      )}
                    </Form.Item>
                  </Section>
                </SettingsBox>
              </div>
            )}
          </div>
        )}
      </div>
    </Form>
  );
});

export default Form.create<Props>()(DocumentCreateMultipleForm);
