import { Menu } from 'antd';
import React, { FunctionComponent } from 'react';
import styles from './PageSubmenu.module.less';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import StackPanel from 'components/StackPanel';
import { IntlMessageId } from 'locale/messages/cs';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';

type Props = {
  selectedKeys?: string[];
  items: ItemType[];
};

export type routeType = {
  key: string;
  title: IntlMessageId;
  icon: React.ReactNode;
  path?: string;
  component: React.ComponentType<any>;
  userAccess?: ExtendedPermissionEnum;
};

const PageSubmenuComponent: FunctionComponent<Props> = ({ selectedKeys, items }) => {
  return (
    <StackPanel vertical scrollable>
      <Menu mode="inline" selectedKeys={selectedKeys} items={items} className={styles.menu} />
    </StackPanel>
  );
};

export const PageSubmenu = React.memo(PageSubmenuComponent);
