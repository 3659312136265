import { SecondaryDocumentCreateDto, SecondaryDocumentsAddDto } from 'api/completeApiInterfaces';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import { DocumentUploadStateData } from 'components/UploadState';
import produce from 'immer';
import { InjectedIntl } from 'locale';
import { Dictionary } from 'lodash';
import { processApiError } from 'utils';
import { UploadFileState, UploadFileType, UploadProcessData, UploadStatus } from './uploadManager';

export const injectUploadIdToAttachments = <T extends { attachments: { uploadId: Guid }[] }>(
  createDto: T,
  data: UploadProcessData
) => {
  return produce(createDto, (draft) => {
    data.uploadFilesState.forEach((state, index) => {
      draft.attachments[index].uploadId = state.uploadId;
    });
  });
};

export const getPrimaryFileUploadId = (data: UploadProcessData) =>
  data.uploadFilesState.find((file) => file.fileType === UploadFileType.primaryFile)?.uploadId;

export const mapUploadProcessDataToSecondaryDocumentAdd = (
  data: UploadProcessData,
  workflowNodeId?: Guid
): SecondaryDocumentsAddDto => {
  const { uploadFilesState } = data;

  const signedDocument = uploadFilesState.find((file) => file.fileType === UploadFileType.signedDocument);
  const attachments = uploadFilesState
    .filter((file) => file.fileType === UploadFileType.attachment)
    .map((file): SecondaryDocumentCreateDto => ({ name: file.fileName, uploadId: file.uploadId }));

  return {
    secondarySignedDocument: signedDocument && { name: signedDocument.fileName, uploadId: signedDocument.uploadId },
    secondaryAttachedDocuments: attachments,
    workflowNodeId: workflowNodeId,
  };
};

export const injectDocumentIdsToCreateDto = <
  T extends { uploadId: Guid; secondaryDocumentsAdd: SecondaryDocumentsAddDto }
>(
  documentCreateDto: T,
  data: UploadProcessData
) => {
  return produce(documentCreateDto, (draft) => {
    draft.uploadId = getPrimaryFileUploadId(data);
    draft.secondaryDocumentsAdd = mapUploadProcessDataToSecondaryDocumentAdd(data);
  });
};

export const getCommonUploadError = (state: UploadFileState[]): ApiError => {
  return state.find(
    (fileState) => fileState.status === UploadStatus.error || fileState.status === UploadStatus.initializeError
  )?.error;
};

export const calculateUploadProgress = (state: UploadFileState[]): number => {
  let uploaded: number = 0;
  let all: number = 0;
  state.forEach((s) => {
    uploaded += s.uploaded;
    all += s.count;
  });
  return Math.round((100 * uploaded) / all);
};

export const hasUploadStateError = (state: UploadFileState): boolean =>
  state.status === UploadStatus.error || state.status === UploadStatus.initializeError;

export const checkUploadAccessDeniedError = (error: ApiError, intl: InjectedIntl) => {
  const apiError = processApiError(error);

  if (apiError.referenceErrorCode === ServiceErrorEnum.UploadAcccessDeniedError) {
    const errorData = apiError.errorData as { data: { reason: string; uploadId: Guid } };
    return (
      intl.formatMessage({ id: 'UploadState.error.uploadDeniedError' }, { reason: errorData?.data.reason }) ||
      intl.formatMessage({ id: 'serviceError.UploadAcccessDeniedError' })
    );
  }
  if (apiError.referenceErrorCode === ServiceErrorEnum.DocumentStateOperationForbiddenError) {
    return intl.formatMessage({ id: 'serviceError.DocumentStateOperationForbiddenError' });
  }

  return undefined;
};

export const hasBlockingUploadError = (states: UploadFileState[]): boolean => {
  return states.some((state) => {
    const apiError = processApiError(state.error);
    return apiError.referenceErrorCode === ServiceErrorEnum.UploadAcccessDeniedError;
  });
};

export const hasOnlyBlockingErrors = (
  progressData: React.MutableRefObject<Dictionary<DocumentUploadStateData>>,
  interrupted: boolean
) => {
  const uploadsWithError = Object.entries(progressData.current || []).filter((upload) => !!upload[1].error);
  const blockingUploads = uploadsWithError.filter((upload) => hasBlockingUploadError(upload[1].state));
  return interrupted && blockingUploads.length > 0 && uploadsWithError.length === blockingUploads.length;
};
