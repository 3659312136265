import { masterApi } from 'api/completeApi';
import { OrganizationPOHReportSettingDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useApiData, useIntl, useIsMounted, useSameCallback } from 'hooks';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { ProjectsInRealizationPohReportSettingsForm } from './ProjectsInRealizationPohReportSettingsForm';

type Props = FormModalProps<OrganizationPOHReportSettingDto> & {
  organizationId: Guid;
};

const ProjectsInRealizationPohReportSettingsModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizationId, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const [reportSettings, settingsError, settingsLoading] = useApiData(
    (ct) => masterApi.projects.admin.orgSettings.id.getpohreportsettings.get(organizationId, ct),
    { autoload: true }
  );

  const handleSubmit: FormSubmitHandler<OrganizationPOHReportSettingDto> = useSameCallback(async (values) => {
    setSaving(true);

    const [err, res] = await masterApi.projects.admin.orgSettings.id.setpohreportsettings.post(organizationId, values);
    if (!isMounted) return null;
    if (err) {
      messageError(err, intl);
    } else {
      onSubmit(values);
    }

    setSaving(false);
    return null;
  });

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'OrganizationSettingsListPanel.report.projectsInRealizationPOH.name'}
      submitTextId={'general.save'}
      forceLoading={saving}
      width={500}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <ContentGate error={settingsError} loading={settingsLoading}>
          {!!reportSettings && (
            <ProjectsInRealizationPohReportSettingsForm
              intl={intl}
              wrappedComponentRef={formRef}
              defaults={reportSettings}
              organizationId={organizationId}
            />
          )}
        </ContentGate>
      )}
    </FormModalWrapper>
  );
};

export default ProjectsInRealizationPohReportSettingsModal;
