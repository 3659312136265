import { useIntl } from 'hooks';
import { SavedRecordTypeEnum, useSavedRecordsApi } from 'hooks/useSavedRecordsApi';
import { useMemo } from 'react';
import { getDefaultProjectInRealizationOverviewNamedReports } from './ProjectsInRealizationOverviewReport.reportPresets';
import { SavedReportViews } from './ProjectsInRealizationOverviewReportPage';

export const useProjectsInRealiationSavedViews = (organizationId: Guid, appUserId: Guid) => {
  const intl = useIntl();

  const [namedViews, namedViewsError, namedViewsLoading, namedViewsSaving, saveNamedViews] = useSavedRecordsApi<
    SavedReportViews
  >(organizationId, SavedRecordTypeEnum.ProjectsInRealizationSavedViews, appUserId);

  const existingNamedViews = useMemo(
    () =>
      !!namedViews?.data.views?.length
        ? namedViews?.data.views
        : getDefaultProjectInRealizationOverviewNamedReports(intl),
    [intl, namedViews]
  );

  return [existingNamedViews, namedViewsError, namedViewsLoading, namedViewsSaving, saveNamedViews] as const;
};
