import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { Component, ReactNode } from 'react';

type Props = FormComponentProps &
  InjectedIntlProps & {
    title: ReactNode;
    errors: JSX.Element[];
    errorsTextId: IntlMessageId;
  };

class DocumentsDiscardForm extends Component<Props> {
  render() {
    const { title, errors, errorsTextId } = this.props;

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div>{title}</div>
        {errors.length > 0 && <DocumentMultipleActionError errors={errors} titleId={errorsTextId} />}
      </Form>
    );
  }
}

export default Form.create<Props>()(DocumentsDiscardForm);
