import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { OrganizationPOHReportSettingDto } from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import ColorPickerInput from 'components/ColorPickerInput';
import { InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { useEffect } from 'react';
import { ReportUnitColorAssign } from './ReportUnitColorAssign';

type Props = FormComponentProps<OrganizationPOHReportSettingDto> &
  InjectedIntlProps & {
    defaults: OrganizationPOHReportSettingDto;
    organizationId: Guid;
  };

const ProjectsInRealizationPohReportSettingsFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, defaults, organizationId }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.defaultDate' })}>
          {form.getFieldDecorator<OrganizationPOHReportSettingDto>('defaultReportDate', {
            initialValue: moment(defaults.defaultReportDate) || undefined,
          })(<DatePickerWithHolidays allowClear />)}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.completedRealizationColor' })}
        >
          {form.getFieldDecorator<OrganizationPOHReportSettingDto>('completeRealizationColor', {
            initialValue: defaults.completeRealizationColor,
          })(<ColorPickerInput />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.unitColors' })}>
          {form.getFieldDecorator<OrganizationPOHReportSettingDto>('unitSettings', {
            initialValue: defaults.unitSettings,
          })(<ReportUnitColorAssign intl={intl} organizationId={organizationId} />)}
        </Form.Item>
      </Form>
    );
  }
);

export const ProjectsInRealizationPohReportSettingsForm = React.memo(
  Form.create<Props>()(ProjectsInRealizationPohReportSettingsFormComponent)
);
