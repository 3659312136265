import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import RevisionNumberTag from 'components/RevisionNumberTag';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './PrimaryFileViewerTitle.module.less';

type Props = {
  fileName: ReactNode;
  revisionNo?: number;
  state?: WorkflowStateEnum;
};

const PrimaryFileViewerTitle: FunctionComponent<Props> = ({ fileName, revisionNo, state }) => {
  return (
    <div className={styles.title}>
      <span>{fileName}</span>
      <RevisionNumberTag className={styles.tag} no={revisionNo} state={state} />
    </div>
  );
};

export default PrimaryFileViewerTitle;
