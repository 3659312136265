import { RocketOutlined } from '@ant-design/icons';
import Forge, { ForgeDisplayMode, ForgeSelectHoverProps, NamedForgeModel } from 'Forge/Forge';
import { ForgeCompareDiff } from 'Forge/ForgeCompareDiff';
import { ForgeLoader } from 'Forge/ForgeLoader';
import { Alert, Button, Typography } from 'antd';
import { api } from 'api';
import { BlobDerivateDto, BlobDerivateStatusEnum, BlobDerivateTypeEnum } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { OnDemandDerivateData } from 'api/project/blob/blobApi';
import classNames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SpinBox from 'components/SpinBox';
import { useApiData, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { processPromises } from 'utils';
import styles from './ForgeGroupedViewer.module.less';

type BubbleNode = Autodesk.Viewing.BubbleNode;

const MAX_PROCESSED_MODELS = 2;

export enum ForgeCompareMode {
  None,
  DiffToolExt,
}

export type ForgeDerivateData = {
  urn?: string;
  derivatives?: BubbleNode[];
};

function getTokenExpiration(expires: Date): number {
  if (!expires) return 0;
  const duration = moment.duration(moment().diff(expires));
  return duration.asSeconds();
}

function isTokenExpired(expires: Date) {
  return getTokenExpiration(expires) <= 0;
}

const canProcessOnDemandDerivate = (derivate: BlobDerivateDto & { data?: OnDemandDerivateData }): boolean => {
  return (
    !!derivate.data &&
    (derivate.data.attempts === 0 || moment.duration(moment().diff(derivate.data.attemptDate)).asHours() >= 2)
  );
};

export type ForgeProcessableDocument = { id: Guid; name?: string; ext?: string; primaryFile: { blobToken?: string } };

export type ForgeViewableModel = {
  documentId?: Guid;
  documentName?: string;
  documentExtension?: string;
  blobToken: string;
  forgeDerivate?: BlobDerivateDto & { data?: ForgeDerivateData | OnDemandDerivateData };
};

type Props = ForgeSelectHoverProps & {
  className?: string;
  style?: React.CSSProperties;
  models: ForgeViewableModel[];
  hiddenModels?: Set<Guid>;
  refresh?: () => void;
  compareMode?: ForgeCompareMode;
  viewerRef?: MutableRefObject<Autodesk.Viewing.Viewer3D>;
  onGeometryLoaded?: () => void;
};

export const ForgeGroupedViewer: FunctionComponent<Props> = ({
  className,
  style,
  models,
  refresh,
  hiddenModels,
  compareMode = ForgeCompareMode.None,
  ...forgeProps
}) => {
  const [processingLoading, setProcessingLoading] = useState<boolean>(false);
  const [processingError, setProcessingError] = useState<ApiError>();

  const [token, tokenError, tokenLoading, loadToken] = useApiData(api.project.forge.getToken);

  const [mode, setMode] = useState(ForgeDisplayMode.Mode3d);
  const [enableModeAutodetect, setEnableModeAutodetect] = useState(true);

  const [bubbleIndex, setBubbleIndex] = useState(0);

  const processModels = useSameCallback(async () => {
    if (processingLoading) {
      return;
    }

    setProcessingError(undefined);
    setProcessingLoading(true);

    const work = models
      .filter((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.OnDemand)
      .map((model) => () =>
        api.project.blob.processBlobDerivate(model.blobToken, BlobDerivateTypeEnum.Forge).then(([err]) => {
          if (err) setProcessingError(err);
          return err;
        })
      );

    const results = await processPromises(work, MAX_PROCESSED_MODELS);

    setProcessingLoading(false);
    results.some((err) => !err) && refresh && refresh();
  });

  const handleGetForgeToken = useCallback(
    () =>
      ({
        access_token: token.accessToken,
        expires_in: getTokenExpiration(token.expires),
        token_type: 'Bearer',
      } as const),
    [token]
  );

  // TODO: what a mess ... rework when derivates gate is completed
  // TODO: or better yet ... call the API method only when Forge requests it ...
  useEffect(() => {
    if (
      (!token || isTokenExpired(token.expires)) &&
      models.every((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.Ok)
    ) {
      loadToken();
    }
  }, [models]);

  const forgeModels = useMemo(
    () =>
      models
        .filter((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.Ok)
        .map(
          (model): NamedForgeModel => ({
            ...model,
            urn: (model.forgeDerivate.data as ForgeDerivateData).urn,
          })
        ),
    [models]
  );

  const allModelsReady = forgeModels.length === models.length;

  const unviewableModels = useMemo(
    () => models.filter((model) => !model.forgeDerivate || model.forgeDerivate.status === BlobDerivateStatusEnum.Error),
    [models]
  );

  const canProcessModels = useMemo(() => {
    const onDemandModels = models.filter((model) => model.forgeDerivate?.status === BlobDerivateStatusEnum.OnDemand);
    return (
      onDemandModels.length > 0 &&
      onDemandModels.every((model) =>
        canProcessOnDemandDerivate(model.forgeDerivate as BlobDerivateDto & { data?: OnDemandDerivateData })
      )
    );
  }, [models]);

  const pickForgeComponent = () => {
    switch (compareMode) {
      case ForgeCompareMode.None:
        return (
          <Forge
            key={`${mode}-${bubbleIndex}`} // big brain ... TODO: rework the Forge component so that it can properly handle changing modes
            getForgeToken={handleGetForgeToken}
            models={forgeModels}
            hiddenModels={hiddenModels}
            mode={mode}
            setMode={setMode}
            enableModeAutodetect={enableModeAutodetect}
            setEnableModeAutodetect={setEnableModeAutodetect}
            bubbleIndex={bubbleIndex}
            setBubbleIndex={setBubbleIndex}
            {...forgeProps}
          />
        );
      case ForgeCompareMode.DiffToolExt:
        return (
          forgeModels.length >= 2 && <ForgeCompareDiff getForgeToken={handleGetForgeToken} namedModels={forgeModels} />
        );
    }
  };

  if (token && allModelsReady) {
    return (
      <ErrorBoundary>
        <div style={style} className={classNames(styles.viewer, className)}>
          <ForgeLoader>{pickForgeComponent()}</ForgeLoader>
        </div>
      </ErrorBoundary>
    );
  }

  if (!!unviewableModels?.length) {
    const multipleModels = models?.length >= 2;
    const confirmedError = unviewableModels.some(
      (model) => model.forgeDerivate?.status === BlobDerivateStatusEnum.Error
    );
    const description = (
      <>
        <div>
          <Fmt
            id={
              multipleModels
                ? 'ModelDetailPage.viewByForgeError.descriptionMultiple'
                : 'ModelDetailPage.viewByForgeError.description'
            }
          />
        </div>
        {multipleModels &&
          unviewableModels.map((model) => (
            <div key={model.documentId}>
              <Typography.Text strong>{model.documentName}</Typography.Text>
            </div>
          ))}
        {!confirmedError && (
          <div>
            <Fmt id="ModelDetailPage.viewByForgeError.pleaseWait" />
          </div>
        )}
      </>
    );
    return (
      <Alert
        message={<Fmt id="ModelDetailPage.viewByForgeError.message" />}
        description={description}
        type="warning"
        showIcon
        banner
      />
    );
  }

  return (
    <div className={classNames(styles.viewer, className)}>
      {tokenError ? (
        <Alert type="error" message={<Fmt id="ForgeViewer.loadingTokenError" />} />
      ) : tokenLoading ? (
        <SpinBox fill spinning={true}>
          <Fmt id="Forge.loading" />
        </SpinBox>
      ) : (
        !allModelsReady && (
          <div className={styles.beforeViewContent}>
            <Button
              type="primary"
              size="large"
              icon={<RocketOutlined />}
              onClick={processModels}
              loading={processingLoading}
              disabled={!canProcessModels}
            >
              <Fmt id="ForgeViewer.processModel" />
            </Button>
            <div className={styles.infoBox}>
              <Alert message={<Fmt id="ForgeViewer.processModelInfo" />} type="info" showIcon />
            </div>
            {!canProcessModels && (
              <div className={styles.infoBox}>
                <Alert message={<Fmt id="ForgeViewer.cannotProcessModels" />} type="warning" showIcon />
              </div>
            )}
            {processingError && (
              <div className={styles.infoBox}>
                <Alert message={<Fmt id="ForgeViewer.processError" />} type="error" showIcon />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
