import { CloseOutlined } from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useCache } from 'hooks';
import { Fmt } from 'locale';
import React, { Fragment, FunctionComponent, ReactNode, useMemo } from 'react';
import { CommonFilterWithValue, Serializable } from '../../filterTypes';
import styles from './FilterToolbar.module.less';

type Props = {
  filters: CommonFilterWithValue[];
  setFilterValue: (key: string, value: React.SetStateAction<Serializable>) => void;
  itemCounts: ReactNode;
  /**
   * @deprecated Prop is not used, because it is now with wrap by default
   */
  wrap?: boolean;
  clearFilters: () => void;
  hasFilteredOutItems?: boolean;
  className?: string;
};

const FilterToolbarComponent: FunctionComponent<Props> = ({
  filters,
  setFilterValue,
  itemCounts,
  clearFilters,
  hasFilteredOutItems,
  className,
}) => {
  const setValueByKey = useCache(
    (key: string) => (data: React.SetStateAction<Serializable>) => setFilterValue(key, data),
    [setFilterValue]
  );

  const canClearFilter = useMemo(() => filters.some((filter) => !filter.isEmpty(filter.value)), [filters]);

  return (
    <div className={classNames(styles.filterToolbar, className)}>
      <Typography.Text strong>
        <Fmt id="menu.side.documents.filters" />:
      </Typography.Text>
      {filters.map((filter) => {
        return <Fragment key={filter.key}>{filter?.render?.(filter.value, setValueByKey(filter.key))}</Fragment>;
      })}
      {hasFilteredOutItems && <Tag color="error">{itemCounts}</Tag>}

      {canClearFilter && (
        <CommonHubTooltip title={<Fmt id="general.clearFilters" />} placement={'topLeft'}>
          <Button onClick={clearFilters} type="primary" shape="circle" icon={<CloseOutlined />} />
        </CommonHubTooltip>
      )}
    </div>
  );
};

export const FilterToolbar = React.memo(FilterToolbarComponent);
