import { DEFAULT_LOCALE } from 'config/constants';
import { produce } from 'immer';
import { DEFAULT_SEARCH_SETTINGS } from 'pages/ProjectSearchPage/SearchPageSettings/SearchPageSettings';
import { valueOrProducer } from 'utils';
import { AppConfigStoreModel, AppConfigStoreModelState } from './storeModelinterfaces';

const appConfigStoreDefaults: AppConfigStoreModelState = {
  locale: DEFAULT_LOCALE,
  sideMenuCollapsed: false,
  sideMenuActiveKey: null,
  serachSettings: DEFAULT_SEARCH_SETTINGS,
  authenticated: false,
};

export const appConfigStoreModel: AppConfigStoreModel = {
  state: appConfigStoreDefaults,
  reducers: {
    'redux-oidc/USER_FOUND': (state: AppConfigStoreModelState) =>
      produce(state, (draft) => {
        draft.authenticated = true;
      }),
    setLocale: (state, locale) =>
      produce(state, (draft) => {
        draft.locale = locale;
      }),
    setSideMenuCollapsed: (state, collapsed) =>
      produce(state, (draft) => {
        draft.sideMenuCollapsed = collapsed;
      }),
    setSideMenuActiveKey: (state, key) =>
      produce(state, (draft) => {
        draft.sideMenuActiveKey = key;
      }),
    setSearchSettings: (state, settings) =>
      produce(state, (draft) => {
        draft.serachSettings = valueOrProducer(settings, draft.serachSettings);
      }),
  },
};
