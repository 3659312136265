import { IntlMessageId } from 'locale/messages/cs';

export enum PythonErrorEnum {
  'InvalidCertificateOrPassword' = 'INVALID_CERTIFICATE_OR_PASSWORD',
  'FailedToCreateSigner' = 'FAILED_TO_CREATE_SIGNER',
  'NameMissingInCertificate' = 'CERTIFICATE_MISSING_HOLDER_NAME',
}
export const pythonErrorTranslations: Record<PythonErrorEnum, IntlMessageId> = {
  ['INVALID_CERTIFICATE_OR_PASSWORD']: 'LocalSignatureErrors.invalidCertificateOrPassword',
  ['FAILED_TO_CREATE_SIGNER']: 'LocalSignatureErrors.signerFailedToCreate',
  ['CERTIFICATE_MISSING_HOLDER_NAME']: 'LocalSignatureErrors.certificateMissingHolderName',
};

const signingScript = `
  import micropip
  await micropip.install('https://files.pythonhosted.org/packages/13/dc/5b46e610e091babc616d3bb0f9d10ebfff1bc5282b7966d5aa884d0dcf61/pyHanko-0.20.1-py3-none-any.whl')

  import textwrap
  import re
  import math
  from PIL import ImageFont, ImageDraw, Image, ImageOps
  from pyhanko import stamp
  from pyhanko.pdf_utils import images
  from pyhanko.pdf_utils.layout import SimpleBoxLayoutRule, Margins, AxisAlignment
  from pyhanko.sign import signers, timestamps
  from pyhanko.sign.fields import SigFieldSpec, append_signature_field, SigSeedSubFilter
  from pyhanko.pdf_utils.incremental_writer import IncrementalPdfFileWriter
  from io import BytesIO
  from js import pdfBuffer, certBuffer, certPassword, stampBuffer, fontBuffer, fontBoldBuffer, fieldName, page, startX, startY, endX, endY, signLocation, signDate, rotation

  signature_bg_upscaling_coefficient = 3 # antialiasing by oversizing. Used for zoom and printing
  background_image_opacity = 0.15

  def format_primary_name(primary_name):
    words = primary_name.split()
    middle_index = math.ceil(len(words) / 2)
    first_part = ' '.join(words[:middle_index])
    second_part = ' '.join(words[middle_index:])
    return f"{first_part}\\n{second_part}"

  def format_secondary_info(primary_name, email, date):
    if email:
      full_name = f"{primary_name} <{email}>"
    else:
      full_name = primary_name
    prefered_length = 24
    wrapped_text = "\\n".join(textwrap.wrap(full_name, prefered_length))
    wrapped_date = "\\n".join(textwrap.wrap(date, prefered_length))
    return f"{wrapped_text}\\n{wrapped_date}"

  def get_font_fitting_box_size(draw, font_path, width, height, text):
    fontsize = 2
    font = ImageFont.truetype(font_path, fontsize)
    fontbox = draw.textbbox((0,0), text, font)
    while fontbox[2] < width and fontbox[3] < height:
      # iterate until the text size is just larger than the criteria
      fontsize += 1
      font = ImageFont.truetype(font_path, fontsize)
      fontbox = draw.textbbox((0,0), text, font)
    # return to max fitting size
    fontsize -= 1
    return ImageFont.truetype(font_path, fontsize)

  # create signature image with text supporting non-latin characters
  def create_stamp_signature(stamp_bg_path, signature_save_path, font_path, bold_font_path, width, height, full_name, email, date):
    if rotation % 180 > 0:
      signature_width = height
      signature_height = width
    else:
      signature_width = width
      signature_height = height
    
    content_padding = 5
    fontsize = signature_height / 5
    fontsize_content = signature_height / 6

    bg_image = Image.open(stamp_bg_path, 'r')

    # resize BG image to fit signature canvas
    if bg_image.width > signature_width or bg_image.height > signature_height:
      if bg_image.height / signature_height > bg_image.width / signature_width:
        factor = signature_height / bg_image.height
      else:
        factor = signature_width / bg_image.width
      
      bg_image = bg_image.resize((int(bg_image.width * factor), int(bg_image.height * factor)))

    offset = ((signature_width - bg_image.width) // 2, (signature_height - bg_image.height) // 2)
    canvas = Image.new('RGBA', (signature_width, signature_height), (255, 255, 255, 255))
    canvas.paste(bg_image, offset, mask=bg_image)
    canvas = Image.blend(Image.new('RGBA', (signature_width, signature_height), (255, 255, 255, 255)), canvas, background_image_opacity)

    draw = ImageDraw.Draw(canvas)
    primary_name_text = format_primary_name(full_name)
    secondary_info_text = format_secondary_info(full_name, email, date)
    primary_font = get_font_fitting_box_size(draw, bold_font_path, signature_width / 2 - content_padding, signature_height - content_padding, primary_name_text)
    secondary_font = get_font_fitting_box_size(draw, font_path, signature_width / 2, signature_height, secondary_info_text)
    primary_text_size = draw.textbbox((0,0), primary_name_text, primary_font)
    draw.text((0, (signature_height - primary_text_size[3]) // 3), primary_name_text, font=primary_font, fill="#000")
    draw.text((signature_width / 2, 0), secondary_info_text, font=secondary_font, fill="#222")
    canvas = canvas.rotate(rotation, Image.NEAREST, expand = 1)

    canvas.save(signature_save_path)

  async def sign_pdf_document(bytePassphrase):
    # Load and decode signing certificate
    cms_signer = signers.SimpleSigner.load_pkcs12(
      pfx_file='/certificate.pfx',
      passphrase=bytePassphrase
    )

    if cms_signer is None:
      return "${PythonErrorEnum.InvalidCertificateOrPassword}"

    pattern = r'([^<]+)\\s*(?:<([^>]+)>)?'
    match = re.search(pattern, cms_signer.subject_name)
      
    if not match:
      return "${PythonErrorEnum.NameMissingInCertificate}"

    full_name = match.group(1).strip()
    email_address = match.group(2) if match.group(2) else None

    create_stamp_signature('/stamp.png', '/stamp_txt.png', '/font.ttf', '/fontBold.ttf', abs(endX - startX) * signature_bg_upscaling_coefficient, abs(endY - startY) * signature_bg_upscaling_coefficient, full_name, email_address, signDate)

    pdfBytes = bytes(pdfBuffer.to_py())
    doc = BytesIO(pdfBytes)
    w = IncrementalPdfFileWriter(doc, strict=False)
    sig_field = append_signature_field(w, SigFieldSpec(sig_field_name=fieldName, on_page=page, box=(startX, startY, endX, endY)))
    w.write_in_place()

    background_layout = SimpleBoxLayoutRule(x_align=AxisAlignment.ALIGN_MID,y_align=AxisAlignment.ALIGN_MID,margins=Margins(left=0, right=0, top=0, bottom=0))
    stampStyle = stamp.TextStampStyle(stamp_text='',background=images.PdfImage('/stamp_txt.png'),border_width=0,background_opacity=1,background_layout=background_layout)

    pdfSigner = signers.PdfSigner(
      signers.PdfSignatureMetadata(
        field_name=fieldName,
        subfilter=SigSeedSubFilter.PADES,
        location=signLocation,
        # To support long lasting signature, SSL certs are required to comunicate with https verifying services and time servers
      ),
      signer=cms_signer,
      stamp_style=stampStyle
    )

    if pdfSigner is None:
      return "${PythonErrorEnum.FailedToCreateSigner}"

    out = await pdfSigner.async_sign_pdf(w),

    # async psdsigner create new file buffer as tupple
    return out[0].getvalue()


  # Save file buffers as files to be openned from filesystem later
  with open("/certificate.pfx", "wb") as fh:
    fh.write(bytes(certBuffer.to_py()))

  with open("/stamp.png", "wb") as fh:
    fh.write(bytes(stampBuffer.to_py()))

  with open("/font.ttf", "wb") as fh:
    fh.write(bytes(fontBuffer.to_py()))

  with open("/fontBold.ttf", "wb") as fh:
    fh.write(bytes(fontBoldBuffer.to_py()))

  bytePassphrase = bytes(certPassword, 'utf-8');

  await sign_pdf_document(bytePassphrase)
`;

export default signingScript;
