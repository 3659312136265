import React, { FunctionComponent, useMemo } from 'react';
import styles from './DocumentRow.Path.module.less';
import PathSelector from 'components/PathSelector';
import PathDisplay from 'components/PathDisplay';

export type DocumentRowProps = {
  hasOnClick?: boolean;
  activeProjectId: Guid;
  path?: string;
  pathGuid?: string;
  pathLink?: string;
};

export const DocumentRowPath: FunctionComponent<DocumentRowProps> = ({
  path,
  pathGuid,
  pathLink,
  hasOnClick,
  activeProjectId,
}) => {
  const link = useMemo(
    () => (!hasOnClick && !!pathLink ? pathLink.replace('{projectId}', activeProjectId) : undefined),
    [pathLink, hasOnClick, activeProjectId]
  );

  if (!path) {
    return null;
  }

  if (!pathGuid || !link) {
    return (
      <div className={styles.path}>
        <PathDisplay path={path} alignLeft />
      </div>
    );
  }

  return (
    <div className={styles.path}>
      <PathSelector path={path} id={pathGuid} link={link} />
    </div>
  );
};

export default DocumentRowPath;
