import { Button } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { HeaderSearchContext } from 'components/HeaderSearch/HeaderSearchContextProvider';
import { SearchOutlined } from '@ant-design/icons';

export const HeaderSearchComponent: FunctionComponent = () => {
  const { isOnSearchPage, toggleSearchMode } = useContext(HeaderSearchContext);

  return !isOnSearchPage && <Button type="link" icon={<SearchOutlined key="Icon" />} onClick={toggleSearchMode} />;
};
