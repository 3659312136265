import { Typography } from 'antd';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import FileTypeIcon from 'components/FileTypeIcon';
import ImageBox from 'components/ImageBox';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent, useState } from 'react';
import styles from './DiscussionAttachmentUploadList.module.less';

type Props = {
  stagedAttachments: StagedAttachment[];
};

export const DiscussionAttachmentUploadList: FunctionComponent<Props> = ({ stagedAttachments }) => {
  const [showImage, setShowImage] = useState(true);

  const hideImage = () => {
    setShowImage(false);
  };

  return (
    <StackPanel vertical>
      {stagedAttachments?.map((attachment) => (
        <FlowLayout key={attachment.id}>
          <Margin right bottom>
            {showImage ? (
              <ImageBox className={styles.thumbnail}>
                <img src={attachment.fileUrl} alt={attachment.name} onError={hideImage} />
              </ImageBox>
            ) : (
              <div className={styles.icon}>
                <FileTypeIcon contentType={attachment.file.type} />
              </div>
            )}
          </Margin>
          <Typography.Text>{attachment.name}</Typography.Text>
        </FlowLayout>
      ))}
    </StackPanel>
  );
};
