import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { DeleteIcon, EditIcon } from 'components/Icons/HubActionsIcons';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { ReportViewConfiguration } from '../ProjectsInRealizationOverviewReport.reportPresets';
import styles from './ProjectsInRealizationNote.module.less';

type Props = {
  view: ReportViewConfiguration;
  canDelete: boolean;
  onRename?: (viewId: Guid, message: string) => Promise<void>;
  onDelete?: (viewId: Guid) => Promise<void>;
  className?: string;
};

export const ProjectsRealizationSavedViewItem: FunctionComponent<Props> = ({
  onRename,
  onDelete,
  className,
  view,
  canDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [nameEditing, setEditing] = useState(false);
  const [editedName, setEditedMessage] = useState<string>(view.name);
  const intl = useIntl();

  const onStartEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      Modal.confirm({
        title: intl.formatMessage({ id: 'ProjectsInRealisationSavedViewsModal.delete.title' }),
        content: intl.formatMessage({ id: 'ProjectsInRealisationSavedViewsModal.delete.content' }),
        onOk: async () => {
          setLoading(true);
          await onDelete(view.id);
          setLoading(false);
        },
        width: 600,
      });
    }
  }, [intl, view.id, onDelete]);

  const handleEdit = useCallback(async () => {
    if (onRename) {
      setLoading(true);
      await onRename(view.id, editedName);
      setLoading(false);
      setEditing(false);
    }
  }, [onRename, view.id, editedName]);

  const handleClose = useCallback(() => {
    setEditing(false);
  }, []);

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.ctrlKey === true) {
      e.preventDefault();
      handleEdit();
    }
  };

  const onKeyDownEscape = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setEditing(false);
        setEditedMessage(view.name);
      }
    },
    [view]
  );

  return (
    <GeneralSettingsItem
      title={
        nameEditing ? (
          <div className={styles.commentInput}>
            <Input
              placeholder={intl.formatMessage({ id: 'DiscussionInput.placeholder' })}
              disabled={loading}
              value={editedName}
              onChange={(e) => setEditedMessage(e.target.value)}
              onPressEnter={onPressEnter}
              onKeyDown={onKeyDownEscape}
              autoFocus
            />
            <Button onClick={handleEdit} type="primary" loading={loading}>
              <Fmt id="general.save" />
            </Button>
          </div>
        ) : (
          <>
            <div className={classNames(styles.message, className)}>{view.name}</div>
          </>
        )
      }
      additionalActions={
        <>
          {!nameEditing ? (
            <>
              <CommonHubTooltip title={<Fmt id="ProjectsInRealisationSavedViewsModal.button.rename.tooltip" />}>
                <Button type="link" icon={<EditIcon />} onClick={onStartEdit} />
              </CommonHubTooltip>
              <CommonHubTooltip title={<Fmt id="general.delete" />}>
                <Button type="link" icon={<DeleteIcon />} onClick={handleDelete} danger disabled={!canDelete} />
              </CommonHubTooltip>
            </>
          ) : (
            <>
              <Button type="link" icon={<CloseOutlined />} onClick={handleClose} />
            </>
          )}
        </>
      }
    />
  );
};
