import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import { TransferItem, TransferListProps } from 'antd/lib/transfer';
import { ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransfer } from 'components/UserTransfer/UserTransfer';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

type Props = {
  onSubmit: (notifiedUsersIds: string[]) => void;
  onClose: () => void;
  visible: boolean;
  availableUsers: ProjectUserProfileListDto[];
  selectedUserIds?: Guid[];
  createUserTransferFooter: (
    value: Guid[],
    setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
  ) => (footerProps: TransferListProps<TransferItem>) => ReactNode;
};

export const DiscussionNotifyUsersModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  visible,
  availableUsers,
  createUserTransferFooter,
  selectedUserIds: selectedUsers,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState<Guid[]>([]);

  const handleOnOk = () => {
    onSubmit(selectedUserIds);
  };

  const handleOnCancel = () => {
    onClose();
    setSelectedUserIds([]);
  };

  useEffect(() => {
    selectedUsers && setSelectedUserIds(selectedUsers);
  }, [selectedUsers]);

  return (
    <Modal
      title={<Fmt id="DiscussionNote.Notification.modalTitleUserSelecting" />}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={handleOnOk}
      width={700}
    >
      <Form layout="vertical">
        <Form.Item>
          <UserTransfer
            users={availableUsers}
            value={selectedUserIds}
            onChange={setSelectedUserIds}
            createFooter={createUserTransferFooter}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
