import { Button } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  ExtendedPermissionTemplateCreateDto,
  ExtendedPermissionTemplateDto,
  OrgUserDto,
  ProjectTemplateCreateDto,
  ProjectTemplateDto,
} from 'api/completeApiInterfaces';
import Modal from 'components/Modal/Modal';
import StackPanel from 'components/StackPanel/StackPanel';
import { useCancelToken, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { messageError, processApiError } from 'utils';
import ProjectTemplateBuilder, { ProjectTemplateData } from './ProjectTemplateBuilder';
import styles from './ProjectsTemplates.module.less';

type Props = {
  visible: boolean;
  organizationId: Guid;
  organizationTimezoneId: string;
  organizationUsers: OrgUserDto[];
  organizationPermissionProfiles: ExtendedPermissionTemplateDto[];
  projectTemplatePreset?: ProjectTemplateDto;
  reloadOrganizationUsers: () => void;
  onSubmit: () => void;
  onClose: () => void;
  organizationStorageAreas?: string[];
};

const templateDataToCreateDto = (template: ProjectTemplateData, organizationId: Guid): ProjectTemplateCreateDto => ({
  ...template,
  organizationId,
  primarySubmitterCanEditProcessorTeams: template.primarySubmitterCanEditProcessorTeams,
  primaryProcessorCanEditSubmitterTeams: template.primaryProcessorCanEditSubmitterTeams,
  useWorkDays: template.useWorkDays,
  calendar: template.calendar,
  userExtendedPermissionTempates: template.userExtendedPermissionTempates.map(
    (template): ExtendedPermissionTemplateCreateDto => ({
      ...template,
      organizationId,
      settings: template.extendedPermissions.settings,
    })
  ),
});

const ProjectTemplateAddModal: FunctionComponent<Props> = ({
  organizationId,
  visible,
  onSubmit,
  onClose,
  organizationUsers,
  organizationTimezoneId,
  organizationPermissionProfiles,
  projectTemplatePreset,
  reloadOrganizationUsers,
  organizationStorageAreas,
}) => {
  const [saving, setSaving] = useState<boolean>();
  const [templateData, setTemplateData] = useState<ProjectTemplateData>();
  const [isTemplateValid, setTemplateValidStatus] = useState<boolean>(false);
  const cancelToken = useCancelToken('EsticonDeleteLinkButton: unmounting', []);
  const intl = useIntl();

  const saveTemplate = useSameCallback(async () => {
    setSaving(true);
    const templateCreateDto = templateDataToCreateDto(templateData, organizationId);

    const [err, result] = await masterApi.projects.tempates.projecttemplate.post(templateCreateDto, cancelToken);
    if (!!err) {
      const apiError = processApiError(err);
      messageError(apiError, intl);
    } else {
      onSubmit && onSubmit();
    }

    setSaving(false);
  });

  const saveButton = useMemo(
    () => (
      <Button type="primary" onClick={saveTemplate} disabled={!templateData || !isTemplateValid} loading={saving}>
        <Fmt id="ProjectTemplates.createModal.confirm" />
      </Button>
    ),
    [saveTemplate, templateData, isTemplateValid, saving]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={<Fmt id="ProjectTemplates.list.createProjectTemplate" />}
      additionalButtons={saveButton}
    >
      <StackPanel vertical className={styles.modalWrap}>
        <ProjectTemplateBuilder
          organizationId={organizationId}
          organizationTimezoneId={organizationTimezoneId}
          onTemplateChanged={setTemplateData}
          organizationUsers={organizationUsers}
          organizationPermissionProfiles={organizationPermissionProfiles}
          projectTemplate={projectTemplatePreset}
          reloadOrganizationUsers={reloadOrganizationUsers}
          onTemplateValidityChange={setTemplateValidStatus}
          organizationStorageAreas={organizationStorageAreas}
        />
      </StackPanel>
    </Modal>
  );
};

export default ProjectTemplateAddModal;
