import { useIntl } from 'hooks/useIntl';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { useCallback, useMemo } from 'react';
import { currencyLong } from 'utils/formatters';

export enum Currency {
  czk = 'czk',
  eur = 'eur',
  usd = 'usd',
}

export const DEFAULT_CURRENCY: Currency = Currency.czk;

// TODO: The currency from Esticon is NOT YET supported
export const useCurrency = () => useMemo<Currency>(() => DEFAULT_CURRENCY, []);

export const useCurrencyFormatter = () => {
  const currency = useCurrency();
  const intl = useIntl();

  return useMemo(() => currencyLong(intl.locale, currency), [intl]);
};

export function formatCurrencyLabel(label: string, currencyKey: Currency, intl: InjectedIntl) {
  const currency = currencyKey ? intl.formatMessage({ id: `general.currency.${currencyKey}` }) : '?';
  return intl.formatMessage({ id: 'general.currencyLabel' }, { label, currency });
}

export const useCurrencyLabelFormat = (currency: Currency, intl: InjectedIntl) => {
  return useCallback(
    (labelKey: IntlMessageId) => formatCurrencyLabel(intl.formatMessage({ id: labelKey }), currency, intl),
    [currency, intl]
  );
};
