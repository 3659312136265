import { Modal } from 'antd';
import { DocumentMoveResultDto } from 'api/completeApiInterfaces';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import styles from './DocumentsMoveRenameInfo.module.less';

export const showDocumentsMoveRenameInfo = (data: DocumentMoveResultDto, intl: InjectedIntl) => {
  const messages = data.renamedDocuments.map<JSX.Element>((doc) => (
    <p
      dangerouslySetInnerHTML={{
        __html: intl.formatHTMLMessage(
          { id: 'DocumentsMoveRenameInfoModal.item' },
          { origin: doc.originName, destination: doc.newName }
        ),
      }}
    />
  ));
  Modal.info({
    title:
      intl.formatMessage({ id: 'DocumentsMoveRenameInfoModal.title' }, { count: data.renamedDocuments.length }) +
      intl.formatMessage({ id: 'DocumentsMoveRenameInfoModal.title' }, { count: data.renamedDocumentLinks.length }),
    content: <div className={styles.messageList}>{messages}</div>,
    onOk() {},
    width: 620,
  });
};
