import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_INSIGHTS_INSTRUMENTATION_KEY } from 'config/env';
import { history } from 'store';

const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY ,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
    enableAutoRouteTracking: true,
  },
});

export const configureApplicationInsights = (App: () => JSX.Element) => {
  if (!!APP_INSIGHTS_INSTRUMENTATION_KEY ) {
    appInsights.loadAppInsights();
    return withAITracking(reactPlugin, App);
  }

  return App;
};
