import { Tooltip, TooltipProps } from 'antd';
import React, { FunctionComponent } from 'react';

export const TOOLTIP_DELAY = 0.5;

const CommonHubTooltip: FunctionComponent<TooltipProps> = ({
  children,
  mouseEnterDelay = TOOLTIP_DELAY,
  placement = 'top',
  title,
  ...restProps
}) => {
  return title == null || title === '' ? (
    <>{children}</>
  ) : (
    <Tooltip placement={placement} mouseEnterDelay={mouseEnterDelay} title={<>{title}</>} {...restProps}>
      {children}
    </Tooltip>
  );
};

export default React.memo(CommonHubTooltip);
