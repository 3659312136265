import { List, Typography } from 'antd';
import { Fmt, InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styles from './DocumentMultipleActionError.module.less';

export interface MultipleDocumentActionErrorDataItem {
  objectId: Guid;
  directoryPath: string;
  objectName: string;
  documentsObstacles: DocumentsMultipleActionObstaclesEnum;
  occurrences: number;
  isDocument?: boolean;
}
export interface IMultipleDocumentErrorData {
  documentsErrors: MultipleDocumentActionErrorDataItem[];
}

export interface IMultipleDocumentLinkCreateErrorData {
  duplicateElementIds: Guid;
  errors: MultipleDocumentActionErrorDataItem[];
}

export interface IMultipleRestoreDuplicateData {
  documentsErrors: MultipleDocumentRestoreDuplicateDataItem[];
  directoryErrors: MultipleDirectoryRestoreDuplicateDataItem[];
}

export class MultipleDocumentRestoreDuplicateDataItem {
  id: Guid;
  name: string;
}

export class MultipleDirectoryRestoreDuplicateDataItem {
  id: Guid;
  name: string;
}

export enum DocumentsMultipleActionObstaclesEnum {
  NotFound = 'NotFound',
  IsDiscarded = 'IsDiscarded',
  BadDirectory = 'BadDirectory',
  AccessDenied = 'AccessDenied',
  IsNotDiscarded = 'IsNotDiscarded',
  HasDocToDocLink = 'HasDocToDocLink',
  InWorkflow = 'InWorkflow',
  InCommentProcedure = 'InCommentProcedure',
  InActiveWorkflow = 'InActiveWorkflow',
  InActiveCommentProcedure = 'InActiveCommentProcedure',
  InappropriateDocumentState = 'InappropriateDocumentState',
  Reserved = 'Reserved',
  HasOwner = 'HasOwner',
  EsticonDocument = 'EsticonDocument',
  EsticonDocumentDilci = 'EsticonDocumentDilci',
  LinkNotFound = 'LinkNotFound',
  LinkBadDirectory = 'LinkBadDirectory',
  LinkAccessDenied = 'LinkAccessDenied',
  LinkedDocumentAccessDenied = 'LinkedDocumentAccessDenied',
  HasModelElementLink = 'HasModelElementLink',
  NotInModel = 'NotInModel',
  AlreadyInModel = 'AlreadyInModel',
}

const getTransformObject = (obstacle: MultipleDocumentActionErrorDataItem): { id: IntlMessageId; values: any } => {
  switch (obstacle.documentsObstacles) {
    case DocumentsMultipleActionObstaclesEnum.IsDiscarded:
    case DocumentsMultipleActionObstaclesEnum.AccessDenied:
    case DocumentsMultipleActionObstaclesEnum.IsNotDiscarded:
    case DocumentsMultipleActionObstaclesEnum.InWorkflow:
    case DocumentsMultipleActionObstaclesEnum.InCommentProcedure:
    case DocumentsMultipleActionObstaclesEnum.InActiveWorkflow:
    case DocumentsMultipleActionObstaclesEnum.InActiveCommentProcedure:
    case DocumentsMultipleActionObstaclesEnum.InappropriateDocumentState:
    case DocumentsMultipleActionObstaclesEnum.Reserved:
    case DocumentsMultipleActionObstaclesEnum.HasOwner:
    case DocumentsMultipleActionObstaclesEnum.EsticonDocument:
    case DocumentsMultipleActionObstaclesEnum.EsticonDocumentDilci:
    case DocumentsMultipleActionObstaclesEnum.LinkNotFound:
    case DocumentsMultipleActionObstaclesEnum.LinkBadDirectory:
    case DocumentsMultipleActionObstaclesEnum.LinkAccessDenied:
    case DocumentsMultipleActionObstaclesEnum.LinkedDocumentAccessDenied:
      return {
        id: `DocumentMultipleActionError.error.${obstacle.documentsObstacles}`,
        values: { name: obstacle.objectName },
      };
    case DocumentsMultipleActionObstaclesEnum.BadDirectory:
      return {
        id: `DocumentMultipleActionError.error.${obstacle.documentsObstacles}`,
        values: { name: obstacle.objectName, path: obstacle.directoryPath },
      };
    case DocumentsMultipleActionObstaclesEnum.HasDocToDocLink:
    case DocumentsMultipleActionObstaclesEnum.HasModelElementLink:
      return {
        id: `DocumentMultipleActionError.error.${obstacle.documentsObstacles}`,
        values: { name: obstacle.objectName, count: obstacle.occurrences.toString() },
      };
    case DocumentsMultipleActionObstaclesEnum.NotInModel:
      return {
        id: `DocumentMultipleActionError.error.NotInModel`,
        values: { name: obstacle.objectName },
      };
    case DocumentsMultipleActionObstaclesEnum.AlreadyInModel:
      return {
        id: `DocumentMultipleActionError.error.AlreadyInModel`,
        values: { name: obstacle.objectName },
      };
  }
  return {
    id: 'DocumentMultipleActionError.error.NotFound',
    values: { name: obstacle.objectName },
  };
};

export const transformError = (obstacle: MultipleDocumentActionErrorDataItem): JSX.Element => {
  const errorObject = getTransformObject(obstacle);
  return <FormattedHTMLMessage tagName={'p'} {...errorObject} />;
};

export const transformErrorIntl = (obstacle: MultipleDocumentActionErrorDataItem, intl: InjectedIntl): JSX.Element => {
  const errorObject = getTransformObject(obstacle);
  return <p dangerouslySetInnerHTML={{ __html: intl.formatHTMLMessage({ id: errorObject.id }, errorObject.values) }} />;
};

type Props = {
  titleId: IntlMessageId;
  errors: JSX.Element[];
};

const DocumentMultipleActionError: FunctionComponent<Props> = ({ titleId, errors }) => {
  if (errors.length === 0) return null;
  return (
    <>
      <div className={styles.errorLabel}>
        <Typography.Text type="danger" strong>
          <Fmt id={titleId} values={{ count: errors.length }} />
        </Typography.Text>
      </div>
      <List
        className={styles.errorList}
        size="small"
        bordered
        dataSource={errors}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </>
  );
};

export default DocumentMultipleActionError;
