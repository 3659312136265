import { implicitCast } from './implicitCast';

export type CheckFormat<T> = (value: any) => asserts value is T;

export type CheckFormatError = {
  message: string; // simple message for logs / debugging
  path: string[]; // path in object tree
  malformedValue: any; // bad value that failed the validation
};

const crop = (value: any, maxLength = 50) => {
  const asString = String(value);
  return asString.length <= maxLength ? asString : asString.substring(0, maxLength - 3) + '...';
};

/**
 * Format checker that doesn't check anything and always succeeds.
 * @param value
 */
export const checkAny: CheckFormat<any> = (value): asserts value is any => undefined;

export const checkBoolean: CheckFormat<number> = (value): asserts value is boolean => {
  if (typeof value !== 'boolean') {
    throw implicitCast<CheckFormatError>({
      message: `Expected boolean, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
};

export const checkNumber: CheckFormat<number> = (value): asserts value is number => {
  if (typeof value !== 'number') {
    throw implicitCast<CheckFormatError>({
      message: `Expected number, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
};

export const checkString: CheckFormat<string> = (value): asserts value is string => {
  if (typeof value !== 'string') {
    throw implicitCast<CheckFormatError>({
      message: `Expected string, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
};

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const checkGuid: CheckFormat<Guid> = (value): asserts value is Guid => {
  if (typeof value !== 'string' || !guidRegex.test(value)) {
    throw implicitCast<CheckFormatError>({
      message: `Expected GUID, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
};

const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}:\d{2})$/;
export const checkIsoDateTime: CheckFormat<IsoDateTime> = (value): asserts value is IsoDateTime => {
  if (typeof value !== 'string' || !isoDateTimeRegex.test(value)) {
    throw implicitCast<CheckFormatError>({
      message: `Expected IsoDateTime, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
  if (!Date.parse(value)) {
    throw implicitCast<CheckFormatError>({
      message: `Date is invalid: ${crop(value)}.`,
      path: [],
      malformedValue: value,
    });
  }
};

export const checkEnum: <T extends string>(checkedEnum: Record<any, T> | T[]) => CheckFormat<T> = <T extends string>(
  checkedEnum: Record<any, T> | T[]
) => {
  const asSet = new Set(Object.values(checkedEnum));
  return (value: any): asserts value is T => {
    if (!asSet.has(value)) {
      throw implicitCast<CheckFormatError>({
        message: `Expected one of ${Object.values(checkedEnum).join(', ')}, found ${crop(value)} instead.`,
        path: [],
        malformedValue: value,
      });
    }
  };
};

// add this around a type to make it nullable
export const checkNullable: <T>(checkFormat: CheckFormat<T>) => CheckFormat<T> = <T>(checkFormat: CheckFormat<T>) => <
  T
>(
  value: any
): asserts value is T => {
  if (value == null) return;
  checkFormat(value);
};

export const checkArray: <T>(arrayFormat: CheckFormat<T>) => CheckFormat<T[]> = <T>(arrayFormat: CheckFormat<T>) => <T>(
  value: any
): asserts value is T[] => {
  if (!Array.isArray(value)) {
    throw implicitCast<CheckFormatError>({
      message: `Expected array, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
  let index: string;
  try {
    for (index in value) {
      arrayFormat(value[index]);
    }
  } catch (ex) {
    const checkEx = ex as CheckFormatError;
    throw { ...checkEx, path: [index, ...checkEx.path] };
  }
};

type AnyObject = Record<string, unknown>;

export type ObjectChecker<T extends AnyObject> = { [key in keyof T]: CheckFormat<T[key]> };
export const checkObject: <T extends AnyObject>(checkObject: ObjectChecker<T>) => CheckFormat<T> = <
  T extends AnyObject
>(
  checkObject: ObjectChecker<T>
) => <T extends AnyObject>(value: any): asserts value is T => {
  if (!value || typeof value !== 'object') {
    throw implicitCast<CheckFormatError>({
      message: `Expected an object, but got ${crop(value)} instead.`,
      path: [],
      malformedValue: value,
    });
  }
  let index: string;
  try {
    for (index in checkObject) {
      (checkObject as any)[index](value[index]);
    }
  } catch (ex) {
    const checkEx = ex as CheckFormatError;
    throw { ...checkEx, path: [index, ...checkEx.path] };
  }
};
