import { to } from 'api/await-to';
import { DocumentAnnotationsDto, DocumentAnnotationsSaveDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';
import * as Dtos from 'api/completeApiInterfaces';

export const documentAnnotationsApi = {
  saveDocumentAnnotations: (data: DocumentAnnotationsSaveDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentAnnotationsDto>(`/documentannotation`, data, { cancelToken })
    ),
  getDocumentAnnotations: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocumentAnnotationsDto>(`/documentannotation/${documentId}/${revisionId}`, { cancelToken })
    ),
  getDocumentAnnotationsAllRevisions: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<Dtos.DocumentAnnotationsDto>(`/documentannotation/${documentId}`, { cancelToken })
    ),
  getAttachmentDownloadUrl: (annotationId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<Dtos.DownloadUrl>(`/documentannotation/${annotationId}/attachment/download`, {
        cancelToken,
      })
    ),
};
