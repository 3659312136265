import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Typography } from 'antd';
import { DocumentBulkActionSetResultDto, DocumentSubscriberEnumsWatchDocumentEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { DocumentWatchSettings } from 'components/DocumentWatchSettings/DocumentWatchSettings';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';

export interface BulkEditDocumentsWatchFormData {
  watchTypes: DocumentSubscriberEnumsWatchDocumentEnum[];
}

type Props = FormComponentProps<DocumentBulkActionSetResultDto> &
  InjectedIntlProps & {
    errors: JSX.Element[];
  };

const BulkEditDocumentsWatchForm = React.forwardRef<unknown, Props>(({ intl, form, errors }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);
  const { getFieldDecorator } = form;

  return (
    <Form layout="vertical">
      <Form.Item
        label={
          <Typography.Text strong>
            <Fmt id="BulkEditDocumentsWatchForm.info" />
          </Typography.Text>
        }
      >
        {getFieldDecorator<BulkEditDocumentsWatchFormData>('watchTypes', {
          initialValue: [],
        })(<DocumentWatchSettings />)}
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="BulkEditDocumentsWatchForm.impossibleChanges" />
    </Form>
  );
});

export default Form.create<Props>()(BulkEditDocumentsWatchForm);
