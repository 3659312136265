import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './PageContent.module.less';

type Props = {
  title?: ReactNode;
  backButton?: ReactNode;
  hideTitle?: boolean;
  titleTooltip?: ReactNode;
};

const PageContent: FunctionComponent<Props> = ({ title, titleTooltip, children, backButton, hideTitle }) => {
  return (
    <>
      {!hideTitle && (
        <FlowLayout className={styles.title}>
          {backButton}
          {!!title && (
            <span className={styles.headline}>
              <CommonHubTooltip title={titleTooltip || title}>{title}</CommonHubTooltip>
            </span>
          )}
        </FlowLayout>
      )}
      <div className={styles.content}>{children}</div>
    </>
  );
};

export default PageContent;
