import { DocumentLockPurposeEnum, DocumentReservationLockResponseDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useDocumentReservationLock } from 'components/DocumentReservationLock/useDocumentReservationLock';
import React, { FunctionComponent } from 'react';

type Props = {
  lockPurpose: DocumentLockPurposeEnum;
  documentId: Guid;
  revisionId: Guid;
  onLockSuccess?: (lockResponse: DocumentReservationLockResponseDto) => void;
  onLockFail?: () => void;
  onLockedDocumentChanged?: (documentChanged: boolean, revisionChanged: boolean, annotationsChanged: boolean) => void;
  isLockRequested: boolean;
};

export const DocumentReservationLock: FunctionComponent<Props> = ({
  lockPurpose,
  documentId,
  revisionId,
  onLockSuccess,
  onLockFail,
  onLockedDocumentChanged,
  isLockRequested,
  children,
}) => {
  const [loading, lockData, lockError] = useDocumentReservationLock({
    lockPurpose,
    documentId,
    revisionId,
    onLockSuccess,
    onLockFail,
    onLockedDocumentChanged,
    isLockRequested,
  });
  return (
    <ContentGate error={lockError} loading={loading && !lockData}>
      {children}
    </ContentGate>
  );
};
