import { Input, InputRef } from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import DisplayName from 'components/DisplayName';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import SettingsItem from 'components/SettingsItem/SettingsItem';
import React, {
  FormEvent,
  FunctionComponent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './TextInputSettingsItem.module.less';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  multiline?: boolean;
  value: string;
  headline: ReactNode;
  editTooltip?: ReactNode;
  onSave?: (value: string) => Promise<boolean | ReactNode>;
  disableEdit?: boolean;
  easyMode?: boolean;
  inputRef?: RefObject<InputRef | TextAreaRef>;
  maxLength?: number;
  loading?: boolean;
  itemId?: Guid;
};

const TextInputSettingsItem: FunctionComponent<Props> = ({
  multiline,
  disableEdit,
  value,
  onSave,
  headline,
  editTooltip,
  easyMode,
  inputRef,
  maxLength,
  loading,
  itemId,
}) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<ReactNode>(null);
  const [liveValue, setLiveValue] = useState<string>(value);

  const newRef = useRef<InputRef | TextAreaRef>();
  const _ref = !!inputRef ? inputRef : newRef;

  useEffect(() => {
    setLiveValue(value);
  }, [value]);

  useEffect(() => {
    if (!!itemId) handleCancel();
  }, [itemId]);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setLiveValue(value);
    setError(null);
  }, [value]);

  const handleSave = useCallback(async () => {
    if (onSave) {
      setSaving(true);
      const result = await onSave(liveValue);
      if (result !== false && result !== true) {
        setError(result);
        _ref.current.focus();
      } else if (result) {
        setEditing(false);
        setError(null);
      }
      setSaving(false);
    }
  }, [onSave, liveValue, _ref]);

  const handleTextareaChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setLiveValue(e.currentTarget.value);
  };

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    setLiveValue(e.currentTarget.value);
  };

  const handleBlur = useCallback(() => easyMode && handleSave(), [easyMode, handleSave]);

  return (
    <SettingsItem
      disableEdit={loading}
      hideEdit={disableEdit}
      settingsIcon={<EditIcon />}
      editingChildren={
        multiline ? (
          <TextArea
            rows={4}
            className={styles.textarea}
            defaultValue={liveValue}
            onBlur={handleBlur}
            onChange={handleTextareaChange}
            disabled={disableEdit || saving || loading}
            autoFocus
            ref={_ref as RefObject<TextAreaRef>}
            maxLength={maxLength}
          />
        ) : (
          <Input
            onChange={handleInputChange}
            value={liveValue}
            disabled={disableEdit || saving || loading}
            onBlur={handleBlur}
            autoFocus
            ref={_ref as RefObject<InputRef>}
            maxLength={maxLength}
          />
        )
      }
      easyMode={easyMode}
      editMode={editing}
      onSave={handleSave}
      onEdit={handleEdit}
      onCancel={handleCancel}
      saving={saving}
      errorMessage={error}
      headline={headline}
      editTooltip={editTooltip}
      disableEnter={multiline}
    >
      <DisplayName isWrap text={liveValue} />
    </SettingsItem>
  );
};

export default TextInputSettingsItem;
