import { MonitorOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AuditLogDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { FormattedRelative } from 'react-intl';
import styles from './AuditLogRow.module.less';

type Props = InjectedIntlProps & {
  log: AuditLogDto;
  displayEntityType: boolean;
  isAdmin: boolean;
  onDetailClick: (auditLogId: number) => void;
};

const AuditLogRow: FunctionComponent<Props> = ({ log, displayEntityType, isAdmin, onDetailClick }) => {
  const intl = useIntl();
  return (
    <FlowLayout>
      {displayEntityType && <Fmt id={`AuditLog.EntityType.${log.entityType}`} />}
      <span className={styles.mainItem}>
        <Fmt id={`AuditLog.EventName.${log.eventName}`} />
      </span>
      <CommonHubTooltip
        title={moment(log.createdDate)
          .locale(intl.locale)
          .format('lll')}
      >
        {/* span is needful for the tooltip */}
        <span>
          <FormattedRelative value={log.createdDate} />
        </span>
      </CommonHubTooltip>
      {log.createdBy && (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <UserAvatar user={log.createdBy} popoverPlacement="leftTop" />
        </div>
      )}
      {isAdmin && !!log.id && (
        <CommonHubTooltip placement="left" title={<Fmt id="AuditLog.view" />}>
          <Button
            type="link"
            icon={<MonitorOutlined />}
            disabled={!isAdmin}
            onClick={(event) => {
              event.stopPropagation();
              onDetailClick(log.id);
            }}
          />
        </CommonHubTooltip>
      )}
    </FlowLayout>
  );
};

export default memoizeWithIntl(AuditLogRow);
