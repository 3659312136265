import React from 'react';

export const CubeCircle = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 1024 1024"
    version="1.1"
    stroke="currentColor"
    fill="currentColor"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <g>
      <path d="M512,0C794.58,0 1024,229.42 1024,512C1024,794.58 794.58,1024 512,1024C229.42,1024 0,794.58 0,512C0,229.42 229.42,0 512,0ZM193.845,331.345C193.585,316.817 202.202,302.96 216.488,297.252L498.643,184.52C507.441,181.005 516.809,181.211 525.002,184.387C525.12,184.428 525.239,184.472 525.357,184.52L807.335,297.181C809.454,298.015 811.511,299.056 813.472,300.304C823.862,306.911 830.155,318.369 830.155,330.682L830.155,691.855C830.155,705.225 822.745,717.494 810.912,723.717L528.757,872.112C517.939,877.802 505.531,877.43 495.431,872.206C495.366,872.176 495.304,872.144 495.243,872.112L213.088,723.717C201.255,717.494 193.845,705.225 193.845,691.855L193.845,331.345ZM547.872,485.783L547.978,780.652L758.155,670.114L758.155,387.296L547.872,485.783ZM265.845,670.114L475.978,780.63L475.872,485.663L265.845,387.296L265.845,670.114ZM320.317,333.302L512,423.078L703.683,333.302L512,256.717L320.317,333.302Z" />
    </g>
  </svg>
);
