import { createCancelToken } from 'api';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import Axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { processApiError } from 'utils';

type UseApiDataConfig<T> = {
  fetchCallback?: (data: T) => void;
  errorCallback?: (err: ApiError) => void;
  errorCallbackOnCancel?: boolean;
  autoload?: boolean;
};

/**
 * Reworked hook for fetching data from the backend API. Only 1 API call can be active at a time.
 * @param fetchFn Function to create the fetch request.
 * @param config Additional configuration for the hook:
 *  - `fetchCallback`: Callback on successfull data fetch.
 *  - `errorCallback`: Callback on fetching error. Doesn't trigger on cancel by default.
 *  - `errorCallbackOnCancel`: When set, call `errorCallback` even on cancel.
 *  - `autoload`: When set, automatically call the `fetchFn` on first call.
 * @returns Array with following values:
 *  - `data`: Last successfully fetched data, or `null` if no data has been fetched yet.
 *  - `error`: Error from the last call or null by default. Doesn't get set on cancel.
 *  - `loading`: True if an aPI call is currently ongoing.
 *  - `fetchData`: Function to load/reload the data. Automatically cancels the previous call and an `useEffect` will automatically clean up after this on unmount. This function is not memoized.
 *  - `setData`: From `setState`, to manually set / update the data (for example from a result of another API call).
 *  - `manualCancel`: To manually cancel the current request. This function is memoized and always the same.
 */
export const useApiData = <T>(
  fetchFn: (ct?: CancelToken) => Promise<[ApiError, AxiosResponse<T>]>,
  config?: UseApiDataConfig<T>
) => {
  const { fetchCallback, errorCallback, errorCallbackOnCancel, autoload } = config || {};

  const [data, setData] = useState<T>(null);
  const [error, setError] = useState<ServiceError>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctSourceRef = useRef<CancelTokenSource>();

  const fetchData = () => {
    ctSourceRef.current?.cancel('useApiData: another fetch call was made');
    ctSourceRef.current = createCancelToken();

    setLoading(true);
    fetchFn(ctSourceRef.current.token).then(([err, resp]) => {
      setLoading(false);

      if (Axios.isCancel(err)) {
        if (errorCallbackOnCancel) {
          errorCallback && errorCallback(err);
        }
        return;
      }

      if (err) {
        processApiError(err, setError);
        errorCallback && errorCallback(err);
      } else {
        setError(null);
        setData(resp.data);
        fetchCallback && fetchCallback(resp.data);
      }
    });
  };

  const manualCancel = useCallback((message: string) => {
    ctSourceRef.current?.cancel(message);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (autoload) {
      fetchData();
    }
    return () => ctSourceRef.current?.cancel('useApiData: unmounting');
  }, []);

  return [data, error, loading, fetchData, setData, manualCancel] as const;
};
