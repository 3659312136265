import { Modal, Spin, Typography } from 'antd';
import { api } from 'api';
import { WorkflowDto, WorkflowInstantiationDto } from 'api/completeApiInterfaces';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import { AxiosResponse } from 'axios';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { documentSelectHandleMultiError } from 'components/DocumentSelect/documentSelectHandleMultiError';
import { useSameCallback } from 'hooks/useSameCallback';
import { Fmt, InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent, useState } from 'react';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import WorkflowInstantiationForm, {
  WorkflowInstantiationFormConfiguration,
  WorkflowInstantiationFormData,
} from './WorkflowInstantiationForm';

const formDataToDto: (data: WorkflowInstantiationFormData) => WorkflowInstantiationDto = (data) => ({
  workflowTemplateId: data.templateId,
  instanceName: data.name,
  instanceDescription: data.description,
  labels: data.labels,
  workflowApprovalDocumentIds: data.approvalDocuments.map((doc) => doc.id),
  workflowDefaultDirectories: data.defaultDirectories
    ? Object.entries(data.defaultDirectories).map(([aliasId, directoryId]) => ({
        directoryId: directoryId,
        workflowTemplateDirectoryAliasId: aliasId,
      }))
    : [],
  workflowRole: data.workflowRole,
  message: data.message,
});

type Props = FormModalProps<WorkflowDto> & InjectedIntlProps & WorkflowInstantiationFormConfiguration;
const WorkflowInstantiationFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  intl,
  visible,
  ...formConfig
}) => {
  const [spinning, setSpining] = useState<boolean>(false);
  const handleSubmit = useSameCallback(async (values: WorkflowInstantiationFormData) => {
    if (!values) {
      return null;
    }
    setSpining(true);
    const data = formDataToDto(values);
    let [err, resp]: [ApiError, AxiosResponse<WorkflowDto>] = [undefined, undefined];
    while (true) {
      [err, resp] = await api.project.workflow.instantiate(data);

      if (err) {
        const error = processApiError(err);
        if (error !== null && !!error.errorData) {
          switch (error.referenceErrorCode) {
            case ServiceErrorEnum.DocumentMultipleAddObstacleError: {
              const remainingDocumentIds = await documentSelectHandleMultiError(
                data.workflowApprovalDocumentIds,
                error.errorData as IMultipleDocumentErrorData,
                intl
              );
              if (remainingDocumentIds === null) {
                setSpining(false);
                return null;
              } else {
                data.workflowApprovalDocumentIds = remainingDocumentIds;
              }
              break;
            }
            default: {
              setSpining(false);

              return err;
            }
          }
        } else {
          setSpining(false);

          return err;
        }
      } else {
        break;
      }
    }

    onSubmit && (await onSubmit(resp.data));
    setSpining(false);
    return null;
  });

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId="WorkflowInstantiationFormModal.title"
      visible={visible}
      width={800}
      submitTextId="general.start"
    >
      {({ intl, formRef, setDirty }) => (
        <>
          <WorkflowInstantiationForm intl={intl} wrappedComponentRef={formRef} {...formConfig} setDirty={setDirty} />

          <Modal open={spinning} footer={null}>
            <Typography.Text strong>
              <Fmt id="WorkflowInstantiationFormModal.WfIsStarting" />
            </Typography.Text>{' '}
            <Spin spinning={spinning} />
          </Modal>
        </>
      )}
    </FormModalWrapper>
  );
};

export default WorkflowInstantiationFormModal;
