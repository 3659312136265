import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Menu } from 'antd';
import { AppUserProfileDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { HeaderDropdown } from 'components/HeaderDropdown';
import { LocaleMenu } from 'components/HeaderLocaleDropdown/LocaleMenu';
import { NotificationContext } from 'components/HeaderNotificationDropdown/NotificationContextProvider';
import { NotificationMenu } from 'components/HeaderNotificationDropdown/NotificationMenu';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { OIDC_PROVIDER } from 'config/env';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { history } from 'store';
import styles from './HeaderUserDropdown.module.less';

export type HeaderUserDropdownProps = {
  user: AppUserProfileDto;
  className?: string;
  onLocaleChange: (locale: LanguageEnum) => void;
  selectedLocale: LanguageEnum;
};

const HeaderUserDropdown: FunctionComponent<HeaderUserDropdownProps> = ({
  user,
  className,
  onLocaleChange,
  selectedLocale,
}) => {
  const identitySettingsUrl = useMemo(() => {
    const url = new URL(OIDC_PROVIDER);
    url.searchParams.append('returnUrl', window.location.href.split('?')[0]);
    return url.toString();
  }, [window.location.href]);

  const notificationContext = useContext(NotificationContext);

  const totalActiveAssignments =
    notificationContext.totalNewAssignments +
    notificationContext.totalInProgressAssignments +
    notificationContext.totalForApprovalAssignments;

  const userMenu = (
    <div className={styles.wrapper}>
      <div className={styles.userInfo}>
        <div className={styles.userIcon}>
          <UserIcon size={UserAvatarSize.ExtraLarge} user={user} />
        </div>
        {(user.firstname || user.lastname) && (
          <div className={styles.username}>
            <strong>
              {user?.firstname || ''} {user?.lastname || ''}
            </strong>
          </div>
        )}
        <div className={styles.username}>{user.username}</div>
      </div>
      <Divider className={styles.divider} />
      <NotificationMenu
        totalNewMessages={notificationContext?.totalNewMessages}
        totalActiveAssignments={totalActiveAssignments}
      />
      <Divider className={styles.divider} />
      <LocaleMenu onLocaleChange={onLocaleChange} selectedLocale={selectedLocale} />
      <Divider className={styles.divider} />
      <Menu selectedKeys={[]}>
        <Menu.Item
          key="settings"
          onClick={() => (window.location.href = identitySettingsUrl)}
          icon={<SettingOutlined />}
        >
          <Fmt id="general.settings.profile" />
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => history.push('/out?force')} icon={<LogoutOutlined />}>
          <Fmt id="general.logout" />
        </Menu.Item>
      </Menu>
    </div>
  );

  const tooltipTitle = (
    <>
      <Fmt id="general.userName" />
      <div>{user.username}</div>
      <Fmt id="general.messages" />
      <div>{notificationContext?.totalNewMessages}</div>
    </>
  );

  return (
    <HeaderDropdown placement="bottomRight" overlay={userMenu} trigger={['click']}>
      <CommonHubTooltip placement="left" title={tooltipTitle}>
        <Badge size="small" count={notificationContext?.totalNewMessages} title="" className={styles.badge}>
          <Button type="link">
            <UserIcon user={user} size={UserAvatarSize.Medium} />
          </Button>
        </Badge>
      </CommonHubTooltip>
    </HeaderDropdown>
  );
};

export default HeaderUserDropdown;
