import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import styles from './AuthorRelationWrapper.module.less';

type Props = {
  appliedColors: (string | null)[] | null;
};

const AuthorRelationWrapper: FunctionComponent<Props> = ({ appliedColors, children }) => {
  const nonEmptyColors = useMemo(() => appliedColors?.filter((color) => !!color) || [], [appliedColors]);

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.innerWrap, nonEmptyColors.length === 0 && styles.noPadding)}>
        {children}
        <div className={styles.border}>
          {nonEmptyColors.reverse().map((color) => (
            <div key={color} style={{ backgroundColor: color }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthorRelationWrapper;
