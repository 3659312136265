import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl } from 'api/completeApiInterfaces';
import { UploadData } from 'api/project/upload/uploadManager';
import { CancelToken } from 'axios';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import AttachmentHeader, {
  AssignAttachmentListData,
} from 'components/DiscussionAttachments/AttachmentHeader/AttachmentHeader';
import { DiscussionAttachment } from 'components/DiscussionChat/DiscussionInput/DiscussionInput';
import { CommonDocument, DocumentCompleteList } from 'components/DocumentCompleteList/DocumentCompleteList';
import { DocumentsGridItemProps } from 'components/DocumentsGrid/DocumentsGrid';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import StackPanel from 'components/StackPanel';
import {
  DiscussionAttachmentCreateFormData,
  DiscussionRelationOption,
} from 'components/forms/DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';
import { useApiData, useDeleteItems, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { downloadFile, messageError } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';

export type EditableAttachment = CommonDocument & { canUserEdit: boolean };

type Props<T> = {
  attachments: DiscussionAttachment[];
  getDiscardedAttachments: (cancelToken: CancelToken) => ApiPromise<DiscussionAttachment[]>;
  onDiscardAttachment: (attachmentId: Guid) => ApiPromise<T>;
  onRestoreAttachment: (attachmentId: Guid) => ApiPromise<T>;
  onResponse: (data: T) => void;
  getDownloadUrl: (attachmentId: Guid) => ApiPromise<DownloadUrl>;
  assignAttachment: (data: AssignAttachmentListData) => ApiPromise<T>;
  createUploadData: (data: DiscussionAttachmentCreateFormData) => UploadData<unknown>;
  canAddAttachment: boolean;
  getDownloadSelected?: (ids: Guid[]) => ApiPromise<DownloadUrl>;
  additionalButtons?: (documentId: Guid) => ReactNode;
  discussionRelationOptions?: DiscussionRelationOption<string | number>[];
};

export const AttachmentList = <T,>({
  attachments,
  getDiscardedAttachments,
  onDiscardAttachment,
  onRestoreAttachment,
  onResponse,
  getDownloadUrl,
  assignAttachment,
  createUploadData,
  canAddAttachment,
  getDownloadSelected,
  additionalButtons,
  discussionRelationOptions,
}: Props<T>): JSX.Element => {
  const intl = useIntl();
  const isMounted = useIsMounted();

  const [showDiscarded, setShowDiscarded] = useState(false);

  const [
    discardedAttachments,
    discardedAttachmentsError,
    discardedAttachmentsLoading,
    loadDiscardedAttachments,
    setDiscardedAttachments,
  ] = useApiData(getDiscardedAttachments);

  const [deletingAttachments, deleteAttachment] = useDeleteItems(
    intl,
    onDiscardAttachment,
    (_id, data) => onResponse(data),
    true,
    'AttachmentList.discardAttachment.confirm'
  );

  useEffect(() => {
    showDiscarded && loadDiscardedAttachments && loadDiscardedAttachments();
  }, [showDiscarded]);

  const handleRestoreAttachment = useSameCallback(async (attachment: EditableAttachment) => {
    if (
      !(await modalConfirm({
        title: intl.formatMessage({ id: 'AttachmentList.restoreAttachment.confirm' }),
      }))
    ) {
      return;
    }

    const [err, resp] = await onRestoreAttachment(attachment.id);
    if (!isMounted.current) {
      return;
    }

    if (!!err) {
      messageError(err, intl);
      return;
    }

    onResponse(resp.data);
    setDiscardedAttachments(discardedAttachments.filter((d) => d.id !== attachment.id));
    return;
  });

  const additionalAttachmentButtons = useCallback(
    (attachment: EditableAttachment) => (
      <>
        {additionalButtons && additionalButtons(attachment.id)}
        {showDiscarded ? (
          <CommonHubTooltip
            title={
              attachment.canUserEdit ? (
                <Fmt id="AttachmentList.restoreAttachment.tooltip" />
              ) : (
                <Fmt id="AttachmentList.insufficientPermissionTooltip" />
              )
            }
            placement="leftTop"
          >
            <Button
              icon={<RedoOutlined />}
              size="small"
              type="default"
              shape="circle"
              disabled={!attachment.canUserEdit}
              onClick={() => handleRestoreAttachment(attachment)}
            />
          </CommonHubTooltip>
        ) : (
          <DeleteButton
            onDelete={() => deleteAttachment(attachment.id)}
            loading={deletingAttachments.has(attachment.id)}
            tooltip={<Fmt id="AttachmentList.discardAttachment.tooltip" />}
            disabled={
              !attachment.canUserEdit && intl.formatMessage({ id: 'AttachmentList.insufficientPermissionTooltip' })
            }
          />
        )}
      </>
    ),
    [showDiscarded, deletingAttachments, additionalButtons, deleteAttachment, handleRestoreAttachment]
  );

  const displayedAttachments = useMemo(
    () =>
      (showDiscarded ? discardedAttachments : attachments)?.map(
        (attachment): EditableAttachment => ({
          id: attachment.id,
          name: attachment.name,
          primaryFile: attachment.file,
          labels: [],
          createdBy: undefined,
          createdDate: attachment.createdDate,
          canUserEdit: attachment.canEdit,
          revision: undefined,
        })
      ),
    [showDiscarded, attachments, discardedAttachments, canAddAttachment]
  );

  const [selectedDocuments, setSelectedDocuments] = useState<Set<Guid>>(() => new Set());
  const downloadAttachmentsDisabled = !selectedDocuments.size || showDiscarded;

  const onDownloadAttachments = useCallback(
    () => !!getDownloadSelected && downloadFile(() => getDownloadSelected([...selectedDocuments])),
    [selectedDocuments, intl, getDownloadSelected]
  );

  const allFilesSelected = !!displayedAttachments?.length && displayedAttachments.length === selectedDocuments?.size;

  const selectAllDocuments = useCallback(() => {
    if (displayedAttachments.length !== selectedDocuments.size)
      setSelectedDocuments(new Set(displayedAttachments.map((a) => a.id)));
    else setSelectedDocuments(new Set([]));
  }, [displayedAttachments, selectedDocuments]);

  const unselectAllDocuments = useCallback(() => {
    setSelectedDocuments(new Set([]));
  }, []);

  return (
    <StackPanel vertical>
      <AttachmentHeader
        onShowDiscardedChange={setShowDiscarded}
        onDownloadAttachments={onDownloadAttachments}
        downloadAttachmentsDisabled={downloadAttachmentsDisabled}
        assignAttachment={assignAttachment}
        createUploadData={createUploadData}
        canAddAttachment={canAddAttachment && !showDiscarded}
        onResponse={onResponse}
        allFilesSelected={allFilesSelected}
        onSelectAllDocuments={selectAllDocuments}
        selectedItemsCount={selectedDocuments?.size}
        onUnselectAll={unselectAllDocuments}
        showDiscarded={showDiscarded}
        discussionRelationOptions={discussionRelationOptions}
      />
      <ContentGate
        loading={showDiscarded && discardedAttachmentsLoading}
        error={showDiscarded && discardedAttachmentsError}
      >
        <EmptyGate empty={!displayedAttachments?.length}>
          <DocumentCompleteList
            documents={displayedAttachments}
            hideToolbar
            hideFilters
            disableReserve
            flexGrow
            additionalDocumentButtons={additionalAttachmentButtons}
            getDocumentUrl={getDownloadUrl}
            onChange={setSelectedDocuments}
            value={selectedDocuments}
            disableDownload={showDiscarded}
            transform={transformAttachment}
          />
        </EmptyGate>
      </ContentGate>
    </StackPanel>
  );
};

export const transformAttachment = (document: CommonDocument): DocumentsGridItemProps => ({
  ...document,
  name: document.name,
  revisionNo: document.revision?.number,
  revisionDate: document.revision?.createdDate,
  revisionUser: document.revision?.createdBy,
  state: document.revision?.revisionState,
  primaryFileContentType: document.primaryFile?.contentType,
  thumbnailUrl: document.primaryFile?.thumbnail,
});
