import { isEqual } from 'lodash';

/**
 * Compares the two values using deep equality, and returns the old (first) value if they are equal,
 * or the new (second) value if they are not equal.
 *
 * Note: objects with the same property-value pairs are considered the same, even when the order is different.
 *
 * Useful for not triggering unnecessary re-renders with setState.
 *
 * @param oldValue Old value to compare.
 * @param newValue New value to compare.
 * @returns Old value if values are the same, or new value if they are different.
 */
export const deepCompareValues = <T>(oldValue: T, newValue: T) => (isEqual(oldValue, newValue) ? oldValue : newValue);
