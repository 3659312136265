import { OrgExtendedPermissionValueEnum } from 'api/completeApiInterfaces';
import AccessLevel, { AccessLevelProps } from 'components/AccessLevel/AccessLevel';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useMemo } from 'react';

type DirectoryAccessLevelProps = Omit<
  AccessLevelProps<OrgExtendedPermissionValueEnum>,
  'translations' | 'accessLevelMap' | 'accessLevelReverseMap'
> & {
  enabledOptions: Set<OrgExtendedPermissionValueEnum>;
};

export const orgReportAccessLevelMap: Record<OrgExtendedPermissionValueEnum, number> = {
  none: 0,
  read: 1,
  write: 2,
};

export const orgReportAccessLevelReverseMap: Record<number, OrgExtendedPermissionValueEnum> = {
  0: OrgExtendedPermissionValueEnum.none,
  1: OrgExtendedPermissionValueEnum.read,
  2: OrgExtendedPermissionValueEnum.write,
};

const translations: Record<OrgExtendedPermissionValueEnum, IntlMessageId> = {
  [OrgExtendedPermissionValueEnum.none]: 'AccessLevel.tooltips.none',
  [OrgExtendedPermissionValueEnum.read]: 'AccessLevel.tooltips.read',
  [OrgExtendedPermissionValueEnum.write]: 'AccessLevel.tooltips.write',
};

const OrgReportAccessLevel: FunctionComponent<DirectoryAccessLevelProps> = ({ enabledOptions, ...restProps }) => {
  const reportAccessLevelMap = useMemo(
    () =>
      Object.entries(orgReportAccessLevelMap)
        .filter(([acceessLevel, _]: [OrgExtendedPermissionValueEnum, number]) => enabledOptions.has(acceessLevel))
        .reduce(
          (acc, [accessLevel, value]) => ({ ...acc, [accessLevel]: value }),
          {} as Record<OrgExtendedPermissionValueEnum, number>
        ),
    [enabledOptions]
  );

  const reportAccessLevelReversalMap = useMemo(
    () =>
      Object.entries(orgReportAccessLevelReverseMap)
        .filter(([_, acceessLevel]) => enabledOptions.has(acceessLevel))
        .reduce(
          (acc, [value, acceessLevel]) => ({ ...acc, [value]: acceessLevel }),
          {} as Record<number, OrgExtendedPermissionValueEnum>
        ),
    [enabledOptions]
  );

  return (
    <AccessLevel
      translations={translations}
      accessLevelMap={reportAccessLevelMap}
      accessLevelReverseMap={reportAccessLevelReversalMap}
      {...restProps}
    />
  );
};

export default React.memo(OrgReportAccessLevel);
