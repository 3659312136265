import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { api } from 'api';
import {
  AppUserDto,
  OrganizationAdminReportDto,
  OrgUserDto,
  ProjectListDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
  ProjectUserSetAdminDto,
} from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import OrganizationUsersEditFormModal from 'components/forms/OrganizationUserEditForm/OrganizationUserEditFormModal';
import ProjectUsersAddFormModal from 'components/forms/ProjectUsersAddForm/ProjectUsersAddFormModal';
import GeneralSettingsUsersList from 'components/GeneralSettingsUsersList/GeneralSettingsUsersList';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { ReinviteButton } from 'components/ReinviteButton/ReinviteButton';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import { DELETE_INVITED_USER_ERRORS_TO_GIVE_SUSPEND_EVENTUALITY } from 'pages/ProjectSettingsPage/Users/UserDetailPanel/GeneralTab';
import NoDeletableUserOfferSuspendingModal from 'pages/ProjectSettingsPage/Users/UserDetailPanel/NoDeletableUserOfferSuspendingModal';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { messageError, processApiError } from 'utils';
import { textComparer } from 'utils/comparators';

type Props = {
  selectedOrganization: OrganizationAdminReportDto;
  organizationUsers: OrgUserDto[];
  reloadOrganizationUsers: () => void;
  projectList: ProjectListDto[];
  onSelect?: (userId: Guid) => void;
  onRemove?: (appUserUserName: string) => void;
  loadOrganizationsAdminReport: () => void;
};

type ProjectDetailPanelParams = {
  projectId: string;
};

const ProjectUsersPanelComponent: FunctionComponent<Props> = ({
  selectedOrganization,
  organizationUsers,
  reloadOrganizationUsers,
  loadOrganizationsAdminReport,
}) => {
  const [addUsersAddModalVisible, showUsersAddModal, hideUsersAddModal] = useBoolean(false);
  const [reinvitedUserIds, setReinvitedUserIds] = useState<Guid[]>([]);
  const [selectedOrgUserName, setSelectedOrgUserName] = useState<string>(undefined);
  const [search, setSearch] = useState<string>('');
  const [reasonToOfferSuspending, setReasonToOfferSuspending] = useState<React.ReactNode>(undefined);
  const [userIdToOfferSuspending, setUserIdToOfferSuspending] = useState<Guid>(undefined);

  const intl = useIntl();
  const { url } = useRouteMatch();
  const { projectId } = useParams<ProjectDetailPanelParams>();

  const projectName = selectedOrganization.projects.find((p) => p.id === projectId)?.name;

  const handleAddModalSubmit = () => {
    hideUsersAddModal();
    loadOrganizationsAdminReport();
    loadProjectUsers();
    loadUsableOrganizationUsers();
  };

  const [
    usableOrganizationUsers,
    usableOrganizationUsersError,
    usableOrganizationUsersLoading,
    loadUsableOrganizationUsers,
  ] = useApiData((ct) => api.master.organization.getorgusersNonExistOnProject(selectedOrganization.id, projectId, ct), {
    autoload: true,
  });

  const toAddAppUsers: AppUserDto[] = useMemo(() => {
    return usableOrganizationUsers?.map((orgUser) => ({
      id: orgUser.appUserProfile.id,
      username: orgUser.appUserProfile.username,
      isAdmin: orgUser.appUserProfile.isAdmin,
      language: orgUser.appUserProfile.language,
      profilePicture: orgUser.appUserProfile.profilePicture,
      firstname: orgUser.appUserProfile.firstname,
      lastname: orgUser.appUserProfile.lastname,
    }));
  }, [usableOrganizationUsers]);

  const [projectUsers, projectUsersError, projectUsersLoading, loadProjectUsers] = useApiData(
    (ct) => api.master.organization.getprojectusers(projectId, ct),
    {
      autoload: true,
    }
  );

  useEffect(() => {
    loadProjectUsers();
    loadUsableOrganizationUsers();
  }, [projectId]);

  const handleEditUser = () => {
    setSelectedOrgUserName(undefined);
    reloadOrganizationUsers();
  };

  const reinviteProjectUser = useCallback(
    async (projectUserId: Guid) => {
      const [err] = await api.master.organization.reinvite({
        projectUserId,
        projectId,
        organizationtId: selectedOrganization.id,
      });
      if (err) {
        messageError(err, intl);
      } else {
        setReinvitedUserIds((prevState) => [...prevState, projectUserId]);
      }
    },

    [projectId]
  );

  const removeInvitedProjectUser = useCallback(
    async (userId: Guid) => {
      const [err] = await api.master.organization.deleteInvitedProjectUser(projectId, userId);
      if (err) {
        const processedError = processApiError(err);
        const isConditionToOfferUserSuspending = DELETE_INVITED_USER_ERRORS_TO_GIVE_SUSPEND_EVENTUALITY.includes(
          processedError.referenceErrorCode
        );
        if (isConditionToOfferUserSuspending) {
          setReasonToOfferSuspending(<Fmt id={`serviceError.${processedError.referenceErrorCode}`} />);
          setUserIdToOfferSuspending(userId);
          return;
        }
        if (!isConditionToOfferUserSuspending) {
          messageError(err, intl);
        }
      } else {
        loadProjectUsers();
        loadUsableOrganizationUsers();
      }
    },

    [intl, loadProjectUsers, loadUsableOrganizationUsers, projectId]
  );

  const setIsAdmin = useCallback(
    async (isAdmin: boolean, userId: Guid) => {
      const data: ProjectUserSetAdminDto = { isAdmin };
      const [err] = await api.master.organization.setadmin(projectId, userId, data);
      if (err) {
        messageError(err, intl);
      } else {
        loadProjectUsers();
      }
    },
    [loadProjectUsers, projectId]
  );

  const handleCloseNoDeletableUserOfferSuspendingModal = () => {
    setReasonToOfferSuspending(undefined);
    setUserIdToOfferSuspending(undefined);
  };

  const suspendUser = useCallback(
    async (userId: Guid) => {
      const [err] = await api.master.organization.suspendUser(projectId, userId);
      if (err) {
        messageError(err, intl);
      } else {
        loadProjectUsers();
        handleCloseNoDeletableUserOfferSuspendingModal();
      }
    },
    [loadProjectUsers, projectId]
  );

  const activateUser = useCallback(
    async (userId: Guid) => {
      const [err] = await api.master.organization.activateUser(projectId, userId);
      if (err) {
        messageError(err, intl);
      } else {
        loadProjectUsers();
      }
    },
    [loadProjectUsers, projectId]
  );

  const changeUserStatus = (newStatus: ProjectUserProfileStatusEnum, userId: Guid) => {
    newStatus === ProjectUserProfileStatusEnum.suspended && suspendUser(userId);

    newStatus === ProjectUserProfileStatusEnum.active && activateUser(userId);
  };

  const isOnlyOneAdmin = useMemo(
    () =>
      projectUsers?.filter(
        (user) =>
          user.isAdmin &&
          (user.status === ProjectUserProfileStatusEnum.active || user.status === ProjectUserProfileStatusEnum.invited)
      ).length < 2,
    [projectUsers]
  );

  const sortedProjectUsers = useMemo(() => projectUsers?.sort(textComparer.map((user) => user.username)) || [], [
    projectUsers,
  ]);

  const editedUser = useMemo(
    () => organizationUsers?.find((user) => user.appUserProfile.username === selectedOrgUserName),
    [selectedOrgUserName, organizationUsers]
  );

  const getAdditionalUserActions = useCallback(
    (user: ProjectUserProfileListDto) => {
      return (
        <>
          <Checkbox
            checked={user.isAdmin}
            onChange={(e: CheckboxChangeEvent) => setIsAdmin(e.target.checked, user.id)}
            disabled={
              user.status === ProjectUserProfileStatusEnum.system ||
              user.status === ProjectUserProfileStatusEnum.suspended ||
              (user.isAdmin && isOnlyOneAdmin)
            }
          >
            <Fmt id={'general.administrator'} />
          </Checkbox>
          {user.status === ProjectUserProfileStatusEnum.active && (
            <Button onClick={() => changeUserStatus(ProjectUserProfileStatusEnum.suspended, user.id)} size="small">
              <Fmt id="UserDetailPanel.GeneralTab.suspendButton" />
            </Button>
          )}
          {user.status === ProjectUserProfileStatusEnum.suspended && (
            <Button onClick={() => changeUserStatus(ProjectUserProfileStatusEnum.active, user.id)} size="small">
              <Fmt id="UserDetailPanel.GeneralTab.activateButton" />
            </Button>
          )}

          {!user.isConfirmed && (
            <ReinviteButton
              disabled={
                reinvitedUserIds.includes(user.id) ? intl.formatMessage({ id: 'ReinviteButton.reinvited' }) : undefined
              }
              tooltip={intl.formatMessage({ id: 'ReinviteButton.reinvite.tooltip' })}
              onReinvite={() => reinviteProjectUser(user.id)}
              type="link"
            />
          )}
          <EditButton onClick={() => setSelectedOrgUserName(user.username)} />
          {user.status === ProjectUserProfileStatusEnum.invited && (
            <DeleteButton
              disabled={
                isOnlyOneAdmin && user.isAdmin ? intl.formatMessage({ id: 'DeleteButton.isLastAdmin' }) : undefined
              }
              onDelete={() => removeInvitedProjectUser(user.id)}
              type="link"
              shape="circle"
            ></DeleteButton>
          )}
        </>
      );
    },
    [
      changeUserStatus,
      intl,
      isOnlyOneAdmin,
      reinviteProjectUser,
      reinvitedUserIds,
      removeInvitedProjectUser,
      setIsAdmin,
    ]
  );

  return (
    <>
      <MasterComponent
        url={url}
        title={`${projectName} / ${intl.formatMessage({ id: 'general.users' })}`}
        children={() => (
          <StackPanel>
            <Panel
              noMargin
              panelWidth="auto"
              addButtonOnClick={showUsersAddModal}
              addButtonText={<Fmt id="Panel.addUser.tooltip" />}
              onSearch={setSearch}
              searchValue={search}
            >
              <ContentGate empty={!projectUsers?.length} loading={projectUsersLoading} error={projectUsersError}>
                <GeneralSettingsUsersList
                  data={sortedProjectUsers}
                  search={search}
                  showIsAdminTag={false}
                  additionalActions={getAdditionalUserActions}
                />
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
      {!!editedUser && (
        <OrganizationUsersEditFormModal
          visible={!!editedUser}
          onSubmit={handleEditUser}
          onClose={() => setSelectedOrgUserName(undefined)}
          toEditUser={editedUser}
        />
      )}
      {addUsersAddModalVisible && (
        <ProjectUsersAddFormModal
          onSubmit={handleAddModalSubmit}
          onClose={hideUsersAddModal}
          visible={addUsersAddModalVisible}
          toAddAppUsers={toAddAppUsers}
          titleId={'AspeHubUsersListPanel.addModal.title'}
          projectId={projectId}
          organizationId={selectedOrganization.id}
          requestEndpoint="organization"
        />
      )}
      <NoDeletableUserOfferSuspendingModal
        reasonToOfferSuspending={reasonToOfferSuspending}
        onOk={() => suspendUser(userIdToOfferSuspending)}
        onCancel={handleCloseNoDeletableUserOfferSuspendingModal}
      />
    </>
  );
};

export const ProjectUsersPanel = React.memo(ProjectUsersPanelComponent);
