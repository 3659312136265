import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DocumentBulkActionSetResultDto, DocumentLabelsSetModeEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';

import { requiredRule } from 'utils/formHelpers';

export interface BulkEditDocumentsLabelsFormData {
  documentsLabels: Guid[];
  setMode: DocumentLabelsSetModeEnum;
}

type Props = FormComponentProps<DocumentBulkActionSetResultDto> &
  InjectedIntlProps & {
    errors: JSX.Element[];
  };

const BulkEditDocumentsLabelsForm = React.forwardRef<unknown, Props>(({ intl, form, errors }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);
  const { getFieldDecorator } = form;

  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'BulkEditDocumentsLabelsForm.labels' })}>
        {getFieldDecorator<BulkEditDocumentsLabelsFormData>('documentsLabels', {
          initialValue: [],
          rules: [requiredRule('BulkEditDocumentsLabelsForm.labels.required')],
        })(<LabelsInput />)}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'BulkEditDocumentsLabelsForm.mode' })}>
        {getFieldDecorator<BulkEditDocumentsLabelsFormData>('setMode', {
          initialValue: DocumentLabelsSetModeEnum.addLabels,
          rules: [requiredRule('BulkEditDocumentsLabelsForm.mode.required')],
        })(
          <Radio.Group>
            <Radio value={DocumentLabelsSetModeEnum.addLabels}>
              <Fmt id="BulkEditDocumentsLabelsForm.mode.add" />
            </Radio>
            <Radio value={DocumentLabelsSetModeEnum.replaceLabels}>
              <Fmt id="BulkEditDocumentsLabelsForm.mode.replace" />
            </Radio>
            <Radio value={DocumentLabelsSetModeEnum.removeLabels}>
              <Fmt id="BulkEditDocumentsLabelsForm.mode.remove" />
            </Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="BulkEditDocumentsLabelsForm.impossibleChanges" />
    </Form>
  );
});

export default Form.create<Props>()(BulkEditDocumentsLabelsForm);
