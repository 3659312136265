import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Typography } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './DashboardTile.module.less';

export interface DashboardTileProps {
  menu?: MenuProps;
  title: ReactNode;
  style?: React.CSSProperties;
  containerClass?: string;
}

type Props = DashboardTileProps;

class DashboardTile extends React.Component<Props> {
  render() {
    const { menu, title, children, style, containerClass } = this.props;
    return (
      <div className={classNames(styles.dashboardTile, containerClass)} style={style}>
        <div className={styles.dashboardTileHeader}>
          <Typography.Text className={styles.dashboardTitle}>{title}</Typography.Text>
          {!!menu && (
            <Dropdown menu={menu} className={styles.dropdown}>
              <Button type="default" shape="circle">
                <EllipsisOutlined rotate={90} />
              </Button>
            </Dropdown>
          )}
        </div>
        <div className={styles.projectsTileContent}>{children}</div>
      </div>
    );
  }
}

export default DashboardTile;
