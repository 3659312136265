import { message } from 'antd';
import { api } from 'api';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { ConnectedDirectory, ConnectedDirectoryLink } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryLinkDiscardForm from './DirectoryLinkDiscardForm';

type Props = InjectedIntlProps &
  FormModalProps<void, ConnectedDirectory> & {
    directoryLink: ConnectedDirectoryLink;
  };

const DirectoryLinkDiscardFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, directoryLink, ...restProps } = props;

  const handleSubmit: FormSubmitHandler = async () => {
    const [err] = await api.project.directories.discardDirectoryLink(directoryLink.linkId);
    if (err) {
      processApiError(err, (error) => {
        message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      });
      onClose();
      return null;
    }
    onSubmit();
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onCancel={onClose}
      onClose={onClose}
      titleId="DiscardDirectoryLink.Modal.title"
      submitTextId="general.ok"
      visible={true}
      confirmClose={false}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <>
          <DirectoryLinkDiscardForm
            title={<Fmt id={'DiscardDirectoryLink.Modal.description'} />}
            wrappedComponentRef={formRef}
            intl={intl}
            directoryLink={directoryLink}
          />
        </>
      )}
    </FormModalWrapper>
  );
};

export default injectIntl(DirectoryLinkDiscardFormModal);
