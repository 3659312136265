import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { Component } from 'react';
import styles from './RestoreForm.module.less';

type Props = FormComponentProps &
  InjectedIntlProps & {
    message: string;
    errorTitleId: IntlMessageId;
    errors: JSX.Element[];
  };

class RestoreForm extends Component<Props> {
  render() {
    const { message, errorTitleId, errors } = this.props;

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className={styles.restoreDocumentsWrap}>
          <div className={styles.restoreDocumentsTitle}>{message}</div>
        </div>
        {errors.length > 0 && <DocumentMultipleActionError errors={errors} titleId={errorTitleId} />}
      </Form>
    );
  }
}

export default Form.create<Props>()(RestoreForm);
