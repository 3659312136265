import { api } from 'api';
import { AppUserDto, OrgUsersRequestDto } from 'api/completeApiInterfaces';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import AppUserAddForm, { AppUserAddFormData } from './OrganizationUserAddForm';

type Props = FormModalProps<OrgUsersRequestDto> & {
  toAddAppUsers: AppUserDto[];
  isAdminsAdding?: boolean;
  titleId: IntlMessageId;
  organizationId: Guid;
};
const OrganizationUsersAddFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, toAddAppUsers, isAdminsAdding, titleId, organizationId, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<AppUserAddFormData> = async (values) => {
    const data: OrgUsersRequestDto = {
      users: Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
        mail: mail,
        language: attributes.defaultLanguage,
        isAdmin: attributes.isAdmin,
        namesFromOrganization: undefined,
        firstname: undefined,
        lastname: undefined,
      })),
      organizationId,
    };

    const [err] = await api.master.organization.setorgusers(data);
    if (err) return err;
    onSubmit(data);
    return null;
  };

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId={titleId} submitTextId="general.add" width={700} {...restProps}>
      {({ intl, formRef }) => (
        <AppUserAddForm intl={intl} wrappedComponentRef={formRef} toAddAppUsers={toAddAppUsers} />
      )}
    </FormModalWrapper>
  );
};

export default OrganizationUsersAddFormModal;
