import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import { EstiCategoryEnum } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DirectoriesTreeSelect } from 'components/DirectoriesTreeSelect';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralSettingsItemsGroup from 'components/GeneralSettingsItemsGroup/GeneralSettingsItemsGroup';
import { AddIcon, DeleteIcon } from 'components/Icons/HubActionsIcons';
import { Margin } from 'components/Margin/Margin';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { EsticonSettingsAction, EsticonSettingsWithErrors } from './EsticonDetail';
import { EsticonMaskInput } from './EsticonMaskInput';

type Props = {
  currentSettings: EsticonSettingsWithErrors;
  dispatchCurrentSettings: React.Dispatch<EsticonSettingsAction>;
  directory: EstiCategoryEnum;
};

const EsticonDetailDirectoryComponent: FunctionComponent<Props> = ({
  currentSettings,
  dispatchCurrentSettings,
  directory,
}) => {
  const onChangeDirectory = useCallback(
    (value: Guid) => dispatchCurrentSettings({ type: 'setDirectory', directory, directoryId: value }),
    [dispatchCurrentSettings, directory]
  );

  const projectAppSettings = useStoreSelector((state) => state.appSettings.data);
  const directoryMaskTemplates = projectAppSettings.esticonDirectoryTemplateTagMap[directory];
  const fileMaskTemplates = projectAppSettings.esticonFileTemplateTagMap[directory];

  const onChangeDirectoryMask = (index: number, value: string) =>
    dispatchCurrentSettings({ type: 'setPathMask', directory, pathIndex: index, mask: value });

  const onSetDirectoryError = (index: number, error: string) =>
    dispatchCurrentSettings({ type: 'setPathError', directory, pathIndex: index, error });

  const removeDirectoryMask = (index: number) =>
    dispatchCurrentSettings({ type: 'removePathMask', directory, pathIndex: index });

  const addDirectoryMask = useCallback(() => dispatchCurrentSettings({ type: 'addPathMask', directory }), [
    dispatchCurrentSettings,
    directory,
  ]);

  const onChangeFileMask = useCallback(
    (value: string) => dispatchCurrentSettings({ type: 'setFileMask', directory, mask: value }),
    [dispatchCurrentSettings, directory]
  );

  const onSetFileError = useCallback(
    (error: string) => dispatchCurrentSettings({ type: 'setFileError', directory, error }),
    [dispatchCurrentSettings, directory]
  );

  return (
    <GeneralSettingsContainer title={<Fmt id={`ProjectSettingsPage.ExternApps.Esticon.folder.${directory}`} />}>
      <GeneralSettingsItem
        title={<Fmt id="EsticonDetailDirectory.targetPath.title" />}
        input={
          !currentSettings.esticonDirectories[directory] && (
            <Tag color="red">
              <Fmt id="general.notSet" />
            </Tag>
          )
        }
      >
        <Margin right left bottom>
          <DirectoriesTreeSelect
            value={currentSettings.esticonDirectories[directory]}
            onChange={onChangeDirectory}
            allowClear
          />
        </Margin>
      </GeneralSettingsItem>

      {currentSettings.esticonDirectoryMasks[directory] && (
        <GeneralSettingsItem
          title={<Fmt id="EsticonDetailDirectory.pathMask" />}
          description={currentSettings.esticonDirectoryMasks[directory]?.join('/')}
          input={
            !currentSettings.esticonDirectoryMasks[directory]?.length && (
              <Tag color="red">
                <Fmt id="general.notSet" />
              </Tag>
            )
          }
          additionalActions={
            <Button size="small" type="primary" icon={<AddIcon />} onClick={addDirectoryMask}>
              <Fmt id="EsticonDetailDirectory.addMaskDirectory" />
            </Button>
          }
        >
          <GeneralSettingsItemsGroup>
            {currentSettings.esticonDirectoryMasks[directory].map((pathMask, index) => (
              <GeneralSettingsItem
                key={index}
                title={
                  <Margin bottom>
                    <FlowLayout growFirst>
                      <EsticonMaskInput
                        value={pathMask}
                        onChange={(value) => onChangeDirectoryMask(index, value)}
                        error={currentSettings.esticonDirectoryErrors[directory][index]}
                        onError={(error) => onSetDirectoryError(index, error)}
                        templates={directoryMaskTemplates}
                        category={directory}
                      />
                      <Typography.Text strong>/</Typography.Text>
                    </FlowLayout>
                  </Margin>
                }
                additionalActions={
                  <Button
                    danger
                    size="small"
                    type="link"
                    icon={<DeleteIcon />}
                    onClick={() => removeDirectoryMask(index)}
                  />
                }
              />
            ))}
          </GeneralSettingsItemsGroup>
        </GeneralSettingsItem>
      )}

      <GeneralSettingsItem
        title={
          <FlowLayout>
            <Fmt id="EsticonDetailDirectory.fileMask" />
            <CommonHubTooltip
              title={
                directory === EstiCategoryEnum.Fakturace || directory === EstiCategoryEnum.FakturaSdruzeni ? (
                  <Fmt id="ProjectSettingsPage.ExternApps.Esticon.folderHelp.invoices" />
                ) : (
                  <Fmt id="ProjectSettingsPage.ExternApps.Esticon.folderHelp" />
                )
              }
            >
              <Button type="link" icon={<QuestionCircleOutlined />} />
            </CommonHubTooltip>
          </FlowLayout>
        }
      >
        <Margin right left bottom>
          <EsticonMaskInput
            value={currentSettings.esticonFileMasks[directory]}
            onChange={onChangeFileMask}
            error={currentSettings.esticonFileErrors[directory]}
            onError={onSetFileError}
            templates={fileMaskTemplates}
            category={directory}
          />
        </Margin>
      </GeneralSettingsItem>
    </GeneralSettingsContainer>
  );
};

export const EsticonDetailDirectory = React.memo(EsticonDetailDirectoryComponent);
