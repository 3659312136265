import { ClusterOutlined, NodeCollapseOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Alert, Menu, Modal, Typography, message } from 'antd';
import { api } from 'api';
import {
  AccessLevelEnum,
  DirectoryContentDto,
  DirectoryDownloadDto,
  DirectoryDto,
  DirectoryListDto,
  EntityTypesEnum,
  ServiceError,
} from 'api/completeApiInterfaces';
import { DirectoryDownloadDocumentErrorData, ServiceErrorEnum } from 'api/errors';
import Axios from 'axios';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { accessLevelMap } from 'components/DirectoryAccessLevel/DirectoryAccessLevel';
import { DOCUMENT_FILTERS, DOCUMENT_ORDER_OPTIONS } from 'components/DocumentCompleteList/DocumentCompleteList';
import { DocumentsHeader } from 'components/DocumentsHeader';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  CreateShortcutIcon,
  DeleteIcon,
  DirectoryAddIcon,
  DirectoryMoveIcon,
  DownloadZipDeepWithFilesIcon,
  DownloadZipDeepWithoutFilesIcon,
  DownloadZipIcon,
  DownloadZipThisDirOnlyIcon,
  EditIcon,
  ShareAppUsersIcon,
  ShareDownloadIcon,
  ShareIcon,
  UploadIcon,
} from 'components/Icons/HubActionsIcons';
import { ActivityIcon, ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import { Margin } from 'components/Margin/Margin';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import ToolbarSettingsButton from 'components/ToolbarSettingsButton/ToolbarSettingsButton';
import { FiltersContextProvider } from 'components/filters/FiltersContextProvider';
import {
  BooleanOption,
  createFrontendBooleanFilter,
} from 'components/filters/components/RadioFilter/variants/BooleanFilter/BooleanFilter';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { AppUserShareFormModal } from 'components/forms/AppUserShareForm';
import { DirectoryCreateFormModal } from 'components/forms/DirectoryCreateForm';
import DirectoryCreateLinkFormModal from 'components/forms/DirectoryCreateLinkForm/DirectoryCreateLinkFormModal';
import { DirectoryEditFormModal } from 'components/forms/DirectoryEditForm';
import DirectoryLinkDiscardFormModal from 'components/forms/DirectoryLinkDiscardForm/DirectoryLinkDiscardFormModal';
import DirectoryLinkEditFormModal from 'components/forms/DirectoryLinkEditForm/DirectoryLinkEditFormModal';
import DirectoryLinkMoveFormModal from 'components/forms/DirectoryLinkMoveForm/DirectoryLinkMoveFormModal';
import DirectoryMoveFormModal from 'components/forms/DirectoryMoveForm/DirectoryMoveFormModal';
import DirectorySettingsForm, {
  DirectorySettingsFormTabsEnum,
} from 'components/forms/DirectorySettingsForm/DirectorySettingsForm';
import { DirectoryDiscardFormModal } from 'components/forms/DiscardForm';
import { DocumentCreateMultipleFormModal } from 'components/forms/DocumentCreateForm';
import { DeepShareDownloadFormModal } from 'components/forms/ShareDownloadForm/DeepShareDownloadFormModal';
import { SIGNAL_R_DIRECTORY_CONNECTION, SIGNAL_R_REDUX_STORE_CONNECTION } from 'config';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import {
  useBoolean,
  useCancelToken,
  useCurrentProjectUser,
  useSameCallback,
  useStoreSelector,
  useVisibleState,
} from 'hooks';
import { useOnDropProvider } from 'hooks/useOnDropProvider';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import produce from 'immer';
import { Fmt, InjectedIntl, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { isEqual } from 'lodash';
import { SelectedItemsProvider } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import AllDocumentsPageLayout from 'pages/AllDocumentsPage/AllDocumentsPageLayout';
import { directoryContainsSubdirWithName, useCreateDirectory } from 'pages/AllDocumentsPage/hooks/useCreateDirectory';
import { useCreateLink } from 'pages/AllDocumentsPage/hooks/useCreateLink';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DirectoryRouteParams } from 'routes';
import { Dispatch } from 'store';
import {
  categoryMapSelector,
  directoryConnectedLinksSelector,
  directoryConnectedMapSelector,
  directoryRootSelector,
} from 'store/selectors';
import { projectUsersListSelector, projectUsersMapSelector } from 'store/selectors/projectUsersSelectors';
import { RedirectOption, checkDirectoryWriteAccess, redirectWithOption, strCompareCI } from 'utils';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import {
  ConnectedDirectory,
  ConnectedDirectoryLink,
  DirectoryNode,
  DirectoryNodeDirectoryLink,
  DirectoryNodeType,
  directoryNodeHelpers,
} from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';
import { useProjectUrlPaths } from 'utils/urlPaths';
import DirectoryToolbar from './AllDocumentsPage.DirectoryToolbar';
import DirectoryReportExportFormModal from './AllDocumentsPage.ExportFormModal';
import styles from './AllDocumentsPage.module.less';
import { AllDocumentsPageContent } from './AllDocumentsPageContent';
import AllDocumentsPageDirectoryTree from './AllDocumentsPageDirectoryTree';
import { CommonDirectoryContentDto } from './AllDocumentsPageDocumentsGrid';
import GrantingAccessModal, { ACCESS_REQUEST } from './GrantingAccessModal/GrantingAccessModal';
import { useDirectoryReportExport } from './useDirectoryReportExport';

const { Text } = Typography;

enum DownloadErrorEntityEnum {
  document = 'document',
  link = 'link',
}

interface DocumentsErrors {
  isDirectoryLinkWarning: boolean;
  isDocument: boolean;
  objectId: Guid;
  objectName: string;
  objectPath: string;
}
interface ErrorData {
  documentsErrors: DocumentsErrors[];
}

export const getNodeKeysDeep = (
  node: ConnectedDirectory,
  filterNode?: (directoryNode: ConnectedDirectory) => boolean
): DirectoryNodeKey[] => {
  return [
    directoryNodeHelpers.directoryKey(node.id),
    ...node.children
      .flatMap(directoryNodeHelpers.filterMapDirectory)
      .filter((node) => (filterNode ? filterNode(node) : true))
      .flatMap((value) => getNodeKeysDeep(value, filterNode)),
  ];
};

type Props = RouteComponentProps<DirectoryRouteParams> & InjectedIntlProps;

type State = {
  directoryEditFormModalVisible: boolean;
  moveDirectoryModalVisible: boolean;
  moveDirectoryLinkModalVisible: boolean;
  moveDirectoryDestinationId: Guid;
  moveDirectorySourceId: Guid;
  discardDirectoryModalVisible: boolean;
  multiCreateFormModalFiles: FileSystemTreeNode[];
  moveDirectoryLink: ConnectedDirectoryLink;
  directoryLinkEditFormModalVisible: boolean;
  editDirectoryLink: ConnectedDirectoryLink;
  discardDirectoryLinkModalVisible: boolean;
  discardDirectoryLink: ConnectedDirectoryLink;
};

enum DirectoryChangeTypeEnum {
  'DirectoryAdded' = 0,
  'DirectoryChanged' = 1,
  'DirectoryDiscarded' = 2,
  'DirectoryRestored' = 3,
  'DirectoryContentChanged' = 4,
}

export const isAccessLevelAtLeast = (userAccessLevel: AccessLevelEnum, neededAccessLevel: AccessLevelEnum) => {
  if (!userAccessLevel || !neededAccessLevel) {
    return false;
  }
  return accessLevelMap[userAccessLevel] >= accessLevelMap[neededAccessLevel];
};

export function canCreateDirectory(dir: DirectoryListDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

export function canEditDirectoryInfo(dir: DirectoryListDto | DirectoryDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

export function canWriteInDirectory(dir: DirectoryListDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

function directoryDiscardDisabled(dir: ConnectedDirectory, intl: InjectedIntl): DisabledWithReason {
  const discardEnabled = canDiscardDirectory(dir);

  return (
    !discardEnabled &&
    intl.formatMessage({
      id: 'AllDocumentsPage.docMenu.discard.disabled.byRights',
    })
  );
}

function canMoveDirectory(dir: ConnectedDirectory): boolean {
  return (
    isAccessLevelAtLeast(dir?.parent?.currentAccessLevel, AccessLevelEnum.write) &&
    isAccessLevelAtLeast(dir?.accessLevelIncludingChildren, AccessLevelEnum.write)
  );
}

export function canDiscardDirectory(dir: ConnectedDirectory): boolean {
  return (
    isAccessLevelAtLeast(dir?.parent?.currentAccessLevel, AccessLevelEnum.write) &&
    isAccessLevelAtLeast(dir?.accessLevelIncludingChildren, AccessLevelEnum.write)
  );
}

function inChildrenOrDirIsEsticonDir(dir: DirectoryNode): boolean {
  if (!dir || dir.type !== DirectoryNodeType.Directory) return false;
  return dir.directory.isEstiConDir || dir.directory.children.some(inChildrenOrDirIsEsticonDir);
}

export function directoryDeleteDisabledByEsticon(dir: ConnectedDirectory): boolean {
  return inChildrenOrDirIsEsticonDir(directoryNodeHelpers.directoryNode(dir));
}

/** Gets ID of this directory and all its parents */
export function directoryAncestryIds(directory: ConnectedDirectory) {
  const ids: Guid[] = [];
  while (directory) {
    ids.push(directory.id);
    directory = directory.parent;
  }
  return ids;
}
const directoryContainsDirectoryLinkWithName = (directory: ConnectedDirectory, name: string) =>
  directory.children
    .flatMap(directoryNodeHelpers.filterMapDirectoryLink)
    .some((dir) => strCompareCI(dir.linkName, name) === 0);

const FILTERS = [
  ...DOCUMENT_FILTERS,
  createFrontendBooleanFilter(
    'isModel',
    {
      label: <Fmt id="BooleanFilter.isModel" />,
      title: <Fmt id="BooleanFilter.isModel.description" />,
      trueLabel: <Fmt id="BooleanFilter.isModel.yes" />,
      falseLabel: <Fmt id="BooleanFilter.isModel.no" />,
    },
    (document: DirectoryContentDto | CommonDirectoryContentDto) =>
      document.isModel ? BooleanOption.True : BooleanOption.False
  ),
];

const AllDocumentsPageComponent: FunctionComponent<Props> = ({ intl, match }) => {
  const [state, setState] = useState<State>({
    directoryEditFormModalVisible: false,
    moveDirectoryModalVisible: false,
    moveDirectoryLinkModalVisible: false,
    moveDirectoryDestinationId: undefined,
    moveDirectorySourceId: undefined,
    discardDirectoryModalVisible: false,
    multiCreateFormModalFiles: null,
    moveDirectoryLink: undefined,
    directoryLinkEditFormModalVisible: false,
    editDirectoryLink: undefined,
    discardDirectoryLinkModalVisible: false,
    discardDirectoryLink: undefined,
  });
  const { projectId, directoryId, directoryLinkId } = match.params;

  const [auditLogDirectory, auditLogVisible, setAuditLogDirectory, hideAuditLog] = useVisibleState<DirectoryListDto>();
  const [shareDownloadModalVisible, showShareDownloadModal, hideShareDownloadModal] = useBoolean(false);
  const [shareAppUserModalVisible, showShareAppUserModal, hideShareAppUserModal] = useBoolean(false);
  const [directoryNotFound, setDirectoryNotFound] = useState<ServiceError>(undefined);
  const [isLastTargetDirectoryDeleted, setIsLastTargetDirectoryDeleted] = useState<boolean>(false);
  const [lastUsefulTargetDirectory, setLastUsefulTargetDirectory] = useState<ConnectedDirectory>(undefined);

  const unmountCleanup = useCancelToken('AllDocumentsPage: unmounting', []);
  const dispatch = useDispatch<Dispatch>();

  const ui = useStoreSelector((state) => state.allDocumentsPage);
  const currentUser = useCurrentProjectUser();
  const usersList = useStoreSelector(projectUsersListSelector);
  const usersMap = useStoreSelector(projectUsersMapSelector);
  const categoryMap = useStoreSelector(categoryMapSelector);
  const directoryTree = useStoreSelector(directoryConnectedMapSelector);
  const directoryLinks = useStoreSelector(directoryConnectedLinksSelector);
  const directoryRoot = useStoreSelector(directoryRootSelector);

  const { createDirectoryUrlPath, createDirectoryTreeUrlPath } = useProjectUrlPaths();

  const selectedDirectoryTreeKey = useMemo(() => {
    if (directoryId) {
      return directoryNodeHelpers.directoryKey(directoryId);
    }
    if (directoryLinkId) {
      return directoryNodeHelpers.directoryLinkKey(directoryLinkId);
    }
    return undefined;
  }, [directoryId, directoryLinkId]);

  useEffect(() => {
    SIGNAL_R_DIRECTORY_CONNECTION.start();

    return () => SIGNAL_R_DIRECTORY_CONNECTION.stop();
  }, [projectId]);

  useEffect(() => {
    const groupName = projectId + '-dc-' + directoryId;
    if (directoryId) {
      SIGNAL_R_DIRECTORY_CONNECTION.joinGroup(groupName);
    }

    return () => {
      if (directoryId) {
        SIGNAL_R_DIRECTORY_CONNECTION.leaveGroup(groupName);
      }
    };
  }, [directoryId, projectId]);

  useEffect(() => {
    if (selectedDirectoryTreeKey) {
      dispatch.allDocumentsPage.setLastDirectoryNodeKey({ directoryNodeKey: selectedDirectoryTreeKey });
    }
  }, [dispatch, selectedDirectoryTreeKey]);

  const nodeKeyToDirectoryNode = useCallback(
    (nodeKey: DirectoryNodeKey | undefined) => {
      return directoryNodeHelpers.directoryNodeFromKey(nodeKey, directoryTree, directoryLinks);
    },
    [directoryTree, directoryLinks]
  );

  const selectedDirectoryTreeNode = useMemo(() => nodeKeyToDirectoryNode(selectedDirectoryTreeKey), [
    selectedDirectoryTreeKey,
    nodeKeyToDirectoryNode,
  ]);

  const selectedTargetDirectory =
    selectedDirectoryTreeNode && directoryNodeHelpers.getTargetDirectory(selectedDirectoryTreeNode);

  // Expand directories up to selected node
  useEffect(() => {
    if (selectedDirectoryTreeNode) {
      const parentDirectory = directoryNodeHelpers.getParentDirectory(selectedDirectoryTreeNode);
      const toExpandIds = directoryAncestryIds(parentDirectory).map(directoryNodeHelpers.directoryKey);
      dispatch.allDocumentsPage.directoryTreeExpandWithSearched({ keys: toExpandIds });
    }
  }, [selectedDirectoryTreeNode, dispatch]);

  const isSelectedDirectory = useMemo(() => {
    return !!selectedDirectoryTreeNode && selectedDirectoryTreeNode.type === DirectoryNodeType.Directory;
  }, [selectedDirectoryTreeNode]);

  const [downloadErrorsModal, showDownloadErrorsModal, hideDownloadErrorsModal] = useBoolean(false);
  const [errorsModalContent, setErrorsModalContent] = useState<DirectoryDownloadDocumentErrorData[]>(undefined);
  const [downloadRequestContainsLinks, setDownloadRequestContainsLinks] = useState<boolean>(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [permissionsTab, setPermissionsTab] = useState(DirectorySettingsFormTabsEnum.general);
  const [permissionsVisible, setPermissionsVisible] = useState(false);

  useEffect(() => {
    dispatch.directoriesWithLinks.loadData({ reload: false });
    dispatch.projectUsers.loadData({ reload: false });
  }, [dispatch]);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );
  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  useDirtyStoreReload(
    (store) => store.directoriesWithLinks,
    (dispatch) => dispatch.directoriesWithLinks
  );

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  const replaceSelectedDirectory = useSameCallback((dirId: Guid) => {
    redirectWithOption(createDirectoryUrlPath(dirId), RedirectOption.Replace);
  });

  // Unknown dir parameter => show and select last visited directory or root directory if none is saved
  useEffect(() => {
    if (!selectedDirectoryTreeKey) {
      if (ui.lastSelectedDirectoryKey) {
        const asId = directoryNodeHelpers.directoryNodeKeyToId(ui.lastSelectedDirectoryKey);
        redirectWithOption(createDirectoryTreeUrlPath(asId), RedirectOption.Replace);
      } else if (directoryRoot) {
        replaceSelectedDirectory(directoryRoot.id);
      }
    }
  }, [
    selectedDirectoryTreeKey,
    ui.lastSelectedDirectoryKey,
    directoryRoot,
    createDirectoryTreeUrlPath,
    replaceSelectedDirectory,
  ]);

  const directoryExpandAll = useSameCallback((dirNode: ConnectedDirectory) => {
    if (dirNode) {
      const expandKeys = getNodeKeysDeep(dirNode);
      dispatch.allDocumentsPage.directoryTreeExpand({ keys: expandKeys });
    }
  });

  const directoryCollapseAll = useSameCallback((dirNode: ConnectedDirectory) => {
    if (dirNode) {
      const collapseKeys = getNodeKeysDeep(dirNode);
      dispatch.allDocumentsPage.directoryTreeCollapse({ keys: collapseKeys });
    }
  });

  const onGrantingAccessClose = useSameCallback(() => {
    replaceSelectedDirectory(directoryId);
  });

  const createDirectory = useCreateDirectory(dispatch, selectedTargetDirectory);
  const createLink = useCreateLink(dispatch, selectedTargetDirectory);

  const handleOpenMoveDirectoryModal = (moveDirectorySourceId?: Guid, moveDirectoryDestinationId?: Guid) => {
    setState(
      (state): State => ({
        ...state,
        moveDirectoryModalVisible: true,
        moveDirectorySourceId,
        moveDirectoryDestinationId,
      })
    );
  };

  const handleCloseMoveDirectoryModal = () => {
    setState((state): State => ({ ...state, moveDirectoryModalVisible: false }));
  };

  const handleMoveDirectoryFormSubmit = async (directoryList: DirectoryListDto[]) => {
    dispatch.directories.setData(directoryList); // TODO: direct store setter usage
    setState((state): State => ({ ...state, moveDirectoryModalVisible: false }));
  };

  const handleMoveDirectoryTreeDrop = (selectedNodeKey: DirectoryNodeKey, destinationNodeKey: DirectoryNodeKey) => {
    const selectedNode = nodeKeyToDirectoryNode(selectedNodeKey);
    const targetNode = nodeKeyToDirectoryNode(destinationNodeKey);

    if (!selectedNode || !targetNode) {
      return;
    }

    const targetDir = directoryNodeHelpers.getTargetDirectory(targetNode);
    if (!canWriteInDirectory(targetDir)) {
      message.error(intl.formatMessage({ id: 'serviceError.DestinationDirectoryForMoveForbiddenError' }));
      return;
    }

    if (selectedNode.type === DirectoryNodeType.Directory) {
      const selectedDir = selectedNode.directory;

      if (selectedDir.parentId === targetDir.id) {
        message.error(intl.formatMessage({ id: 'serviceError.DirectoryAlreadyExistsThereError' }));
        return;
      }
      if (!canMoveDirectory(selectedDir)) {
        message.error(intl.formatMessage({ id: 'serviceError.DirectoryMoveForbiddenError' }));
        return;
      }

      handleOpenMoveDirectoryModal(selectedDir.id, targetDir.id);
    } else {
      const selectedLink = selectedNode.directoryLink;
      handleOpenMoveDirectoryLinkModal(selectedLink, targetDir.id);
    }
  };

  const handleOpenDirectoryEditFormModal = () => {
    setState((state): State => ({ ...state, directoryEditFormModalVisible: true }));
  };

  const handleDirectoryDiscard = () => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: true }));
  };

  const handleDiscardDirectoryFormSubmit = async (_values: unknown, discardDirectory: ConnectedDirectory) => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: false }));
    await dispatch.directories.loadData({ reload: true });
    replaceSelectedDirectory(discardDirectory?.parentId);
  };

  const handleCloseDiscardDirectoryModal = () => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: false }));
  };

  const handleCloseDirectoryEditFormModal = () => {
    setState((state): State => ({ ...state, directoryEditFormModalVisible: false }));
  };

  const handleDirectoryEditFormSubmit = async () => {
    await dispatch.directories.loadData({ reload: true });
    setState((state): State => ({ ...state, directoryEditFormModalVisible: false }));
  };

  const handleDirectoryFavoriteChange = (directoryId: Guid, isFavorite: boolean) => {
    dispatch.directories.updateData((directories) =>
      produce(directories, (draft) => {
        const foundDir = draft.find((dir) => dir.id === directoryId);
        if (foundDir) {
          foundDir.isFavorite = isFavorite;
        }
      })
    );
  };

  const toolbarDirectoryLinkMove = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenMoveDirectoryLinkModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenMoveDirectoryLinkModal = (directoryLink: ConnectedDirectoryLink, defaultDestinationId?: Guid) => {
    setState(
      (state): State => ({
        ...state,
        moveDirectoryLinkModalVisible: true,
        moveDirectoryDestinationId: defaultDestinationId,
        moveDirectoryLink: directoryLink,
      })
    );
  };

  const handleCloseMoveDirectoryLinkModal = () => {
    setState((state): State => ({ ...state, moveDirectoryLinkModalVisible: false }));
  };

  const handleMoveDirectoryLinkFormSubmit = async () => {
    dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, moveDirectoryLinkModalVisible: false }));
  };

  const toolbarDirectoryLinkEdit = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenDirectoryLinkEditFormModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenDirectoryLinkEditFormModal = (directoryLink: ConnectedDirectoryLink) => {
    setState(
      (state): State => ({ ...state, directoryLinkEditFormModalVisible: true, editDirectoryLink: directoryLink })
    );
  };

  const handleCloseDirectoryLinkEditFormModal = () => {
    setState((state): State => ({ ...state, directoryLinkEditFormModalVisible: false }));
  };

  const handleDirectoryLinkEditFormSubmit = async () => {
    await dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, directoryLinkEditFormModalVisible: false }));
  };

  const toolbarDirectoryLinkDiscard = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenDiscardDirectoryLinkModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenDiscardDirectoryLinkModal = (directoryLink: ConnectedDirectoryLink) => {
    setState(
      (state): State => ({ ...state, discardDirectoryLinkModalVisible: true, discardDirectoryLink: directoryLink })
    );
  };

  const handleDiscardDirectoryLinkFormSubmit = async () => {
    await dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, discardDirectoryLinkModalVisible: false }));
    replaceSelectedDirectory(state.discardDirectoryLink.parentDirectoryId);
  };

  const handleCloseDiscardDirectoryLinkModal = () => {
    setState((state): State => ({ ...state, discardDirectoryLinkModalVisible: false }));
  };

  const checkUpdateDirectoryUniqueName = (name: string): boolean => {
    // name is equal to original name
    if (strCompareCI(name, selectedTargetDirectory.name) === 0) return true;
    return !directoryContainsSubdirWithName(selectedTargetDirectory.parent, name);
  };

  const checkUpdateDirectoryLinkUniqueName = (name: string): boolean => {
    const directoryLink = selectedDirectoryTreeNode as DirectoryNodeDirectoryLink;
    if (strCompareCI(name, state.editDirectoryLink?.linkName) === 0) return true;
    return !directoryContainsDirectoryLinkWithName(directoryLink.directoryLink.parentDirectory, name);
  };

  const hasWriteAccess = useMemo(() => checkDirectoryWriteAccess(selectedTargetDirectory?.currentAccessLevel), [
    selectedTargetDirectory,
  ]);

  const [onDropFiles, droppedFiles, onCloseFileUpload, fileUploadVisible, onShowFileUpload] = useOnDropProvider(
    hasWriteAccess
  );

  const handleUploadFilesSubmit = useCallback(() => {
    dispatch.directories.loadData({ reload: true });
    onCloseFileUpload();
  }, [dispatch, onCloseFileUpload]);

  const downloadDirectoryInZip = async (
    directoryId: Guid,
    directoryData: DirectoryDownloadDto,
    errorsArray: DirectoryDownloadDocumentErrorData[] = []
  ) => {
    if (downloadZipLoading) return;

    setDownloadZipLoading(true);
    const [err, res] = await api.project.directories.getDirectoryDownloadUrl(
      directoryId,
      directoryData,
      unmountCleanup
    );
    if (Axios.isCancel(err)) return;
    setDownloadZipLoading(false);

    if (err) {
      const incomingErrorsArray: DirectoryDownloadDocumentErrorData[] = [
        ...(err.response.data.errorData as ErrorData)?.documentsErrors,
      ];
      const actualDirectoryData: DirectoryDownloadDto = {
        ...directoryData,
        ignoredDocumentsIds: incomingErrorsArray.filter((d) => d.isDocument).map((d) => d.objectId),
        ignoredDocumentLinksIds: incomingErrorsArray
          .filter((d) => !d.isDocument && !d.isDirectoryLinkWarning)
          .map((d) => d.objectId),
        ignoredDirectoryLinksIds: incomingErrorsArray
          .filter((d) => !d.isDocument && d.isDirectoryLinkWarning)
          .map((d) => d.objectId),
      };
      if (!!actualDirectoryData.ignoredDirectoryLinksIds.length) {
        setDownloadRequestContainsLinks(true);
      }
      if (isEqual(directoryData, actualDirectoryData)) {
        message.error(intl.formatMessage({ id: 'general.docMenu.downloadInZip.downloadError' }));
        return;
      }

      downloadDirectoryInZip(
        directoryId,
        {
          ...actualDirectoryData,
          ignoreDirectoryLink: true,
          ignoredDocumentsIds: incomingErrorsArray.filter((d) => d.isDocument).map((d) => d.objectId),
          ignoredDocumentLinksIds: incomingErrorsArray
            .filter((d) => !d.isDocument && !d.isDirectoryLinkWarning)
            .map((d) => d.objectId),
          ignoredDirectoryLinksIds: incomingErrorsArray
            .filter((d) => !d.isDocument && d.isDirectoryLinkWarning)
            .map((d) => d.objectId),
        },
        incomingErrorsArray
      );
    }
    if (!err) {
      setErrorsModalContent(errorsArray);
      !!errorsArray.length && showDownloadErrorsModal();
      const { url } = res.data;
      window.open(url, '_blank');
    }
  };

  const errorsModalTitle = useMemo(() => {
    return !!errorsModalContent && !!errorsModalContent.filter((error) => !error.isDirectoryLinkWarning).length
      ? intl.formatMessage({ id: `serviceError.DocumentDeepDownloadForbiddenError` })
      : '';
  }, [errorsModalContent, intl]);

  const handleOkDownloadErrorsModal = () => {
    hideDownloadErrorsModal();
    setErrorsModalContent(undefined);
    setDownloadRequestContainsLinks(false);
  };

  const [onOpenExport, onSubmitExport, onCancelExport, exportFormVisible, exporting] = useDirectoryReportExport({
    id: selectedTargetDirectory?.id,
    name: selectedTargetDirectory?.name,
  });

  const renderDownloadErrorsModalContent = useCallback(
    (currentErrorEntity: DownloadErrorEntityEnum, intlMessageId: IntlMessageId) => {
      const isDocument = currentErrorEntity === DownloadErrorEntityEnum.document;
      const errorEntities = errorsModalContent?.filter((er) => er.isDocument == isDocument);
      return !!errorEntities?.length ? (
        <>
          <Margin bottom top>
            <Text strong>
              <Fmt id={intlMessageId} />
            </Text>
          </Margin>
          {errorEntities?.map((error) => (
            <Margin bottom left right key={error.objectId}>
              <CommonHubTooltip placement="topLeft" title={error.objectName}>
                <div className={styles.dirElipsis}>{error.objectName}</div>
              </CommonHubTooltip>
              <CommonHubTooltip placement="topLeft" title={error.objectPath}>
                <div className={styles.dirElipsis}>{error.objectPath}</div>
              </CommonHubTooltip>
            </Margin>
          ))}
        </>
      ) : (
        undefined
      );
    },
    [errorsModalContent]
  );

  const renderDirectoriesMenu = (node: DirectoryNode): ReactElement => {
    const dir = directoryNodeHelpers.getTargetDirectory(node);
    const createDisabled: boolean = !canCreateDirectory(dir);
    const modifyDisabled: boolean = !canEditDirectoryInfo(dir);
    const moveDisabled: boolean = !canMoveDirectory(dir);
    const discardDisabled: boolean = !canDiscardDirectory(dir);
    const linkDiscardDisabled: boolean = !canEditDirectoryInfo(directoryNodeHelpers.getParentDirectory(node));

    const dirExpandAll = () => {
      directoryExpandAll(dir);
    };
    const dirCollapseAll = () => {
      directoryCollapseAll(dir);
    };

    if (node.type === DirectoryNodeType.DirectoryLink) {
      const existLinkedDirectory = !!node.directoryLink.linkedDirectory;
      return (
        <Menu>
          <Menu.Item
            key="upload-multiple"
            disabled={!checkDirectoryWriteAccess(dir?.currentAccessLevel)}
            onClick={onShowFileUpload}
            icon={<UploadIcon />}
          >
            <Fmt id="AllDocumentsPage.docMenu.upload" />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="createDirectory"
            disabled={createDisabled}
            onClick={createDirectory.show}
            icon={<DirectoryAddIcon />}
          >
            <Fmt id="AllDocumentsPage.docMenu.createDirectory" />
          </Menu.Item>
          <Menu.Item
            key="move"
            disabled={moveDisabled}
            onClick={() => handleOpenMoveDirectoryLinkModal(node.directoryLink)}
            icon={<DirectoryMoveIcon />}
          >
            <Fmt id="general.move" />
          </Menu.Item>
          <Menu.Item
            key="edit"
            disabled={modifyDisabled}
            onClick={() => handleOpenDirectoryLinkEditFormModal(node.directoryLink)}
            icon={<EditIcon />}
          >
            <Fmt id="general.edit" />
          </Menu.Item>
          <Menu.Item
            key="delete"
            disabled={linkDiscardDisabled}
            onClick={() => handleOpenDiscardDirectoryLinkModal(node.directoryLink)}
            icon={<DeleteIcon />}
          >
            <Fmt id="AllDocumentsPage.docMenu.delete" />
          </Menu.Item>
          <Menu.SubMenu
            disabled={!existLinkedDirectory}
            title={
              <span>
                <DownloadZipIcon /> <Fmt id="general.docMenu.downloadInZip" />
              </span>
            }
          >
            <Menu.Item
              key="thisDirOnly"
              onClick={() =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: true,
                  withSubfolders: false,
                  ignoreDirectoryLink: false,
                })
              }
              icon={<DownloadZipThisDirOnlyIcon />}
            >
              <Fmt id="general.docMenu.downloadInZip.thisDirOnly" />
            </Menu.Item>
            <Menu.Item
              key="deepWithFiles"
              onClick={() =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: true,
                  withSubfolders: true,
                  ignoreDirectoryLink: false,
                })
              }
              icon={<DownloadZipDeepWithFilesIcon />}
            >
              <Fmt id="general.docMenu.downloadInZip.deepWithFiles" />
            </Menu.Item>
            <Menu.Item
              key="deepWithoutFiles"
              onClick={() =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: false,
                  withSubfolders: true,
                  ignoreDirectoryLink: false,
                })
              }
              icon={<DownloadZipDeepWithoutFilesIcon />}
            >
              <Fmt id="general.docMenu.downloadInZip.deepWithoutFiles" />
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            disabled={!existLinkedDirectory}
            title={
              <span>
                <ShareIcon /> <Fmt id="general.docMenu.share" />
              </span>
            }
          >
            <Menu.Item key="shareDownloadDownload" onClick={showShareDownloadModal} icon={<ShareDownloadIcon />}>
              <Fmt id="general.docMenu.shareDownload" />
            </Menu.Item>
            <Menu.Item key="shareAppUserDownload" onClick={showShareAppUserModal} icon={<ShareAppUsersIcon />}>
              <Fmt id="general.docMenu.shareAppUserDownload" />
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key="createDirectoryLink"
            onClick={createLink.show}
            disabled={!existLinkedDirectory}
            icon={<ShortcutIcon />}
          >
            <Fmt id="general.dirMenu.createDirectoryLink" />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="audit-log"
            onClick={() => setAuditLogDirectory(dir)}
            disabled={!existLinkedDirectory}
            icon={<ActivityIcon />}
          >
            <Fmt id="general.activity" />
          </Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu>
        <Menu.SubMenu
          title={
            <span className={styles.subMenu}>
              <ClusterOutlined /> <Fmt id="AllDocumentsPage.docMenu.displaySubfolders" />
            </span>
          }
        >
          <Menu.Item key="expand-all" onClick={dirExpandAll} icon={<NodeExpandOutlined />}>
            <Fmt id="AllDocumentsPage.docMenu.expandAll" />
          </Menu.Item>
          <Menu.Item key="collapse-all" onClick={dirCollapseAll} icon={<NodeCollapseOutlined />}>
            <Fmt id="AllDocumentsPage.docMenu.collapseAll" />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Divider />
        <Menu.Item
          key="upload-multiple"
          disabled={!checkDirectoryWriteAccess(dir.currentAccessLevel)}
          onClick={onShowFileUpload}
          icon={<UploadIcon />}
        >
          <Fmt id="AllDocumentsPage.docMenu.upload" />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="createDirectory"
          disabled={createDisabled}
          onClick={createDirectory.show}
          icon={<DirectoryAddIcon />}
        >
          <Fmt id="AllDocumentsPage.docMenu.createDirectory" />
        </Menu.Item>
        <Menu.Item
          key="move"
          disabled={moveDisabled}
          onClick={() => handleOpenMoveDirectoryModal(dir.id)}
          icon={<DirectoryMoveIcon />}
        >
          <Fmt id="general.move" />
        </Menu.Item>
        <Menu.Item key="edit" disabled={modifyDisabled} onClick={handleOpenDirectoryEditFormModal} icon={<EditIcon />}>
          <Fmt id="general.edit" />
        </Menu.Item>
        <Menu.Item key="delete" disabled={discardDisabled} onClick={handleDirectoryDiscard} icon={<DeleteIcon />}>
          <Fmt id="AllDocumentsPage.docMenu.discard" />
        </Menu.Item>
        <Menu.SubMenu
          title={
            <span>
              <DownloadZipIcon /> <Fmt id="general.docMenu.downloadInZip" />
            </span>
          }
        >
          <Menu.Item
            key="thisDirOnly"
            onClick={() =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: true,
                withSubfolders: false,
                ignoreDirectoryLink: false,
              })
            }
            icon={<DownloadZipThisDirOnlyIcon />}
          >
            <Fmt id="general.docMenu.downloadInZip.thisDirOnly" />
          </Menu.Item>
          <Menu.Item
            key="deepWithFiles"
            onClick={() =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: true,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              })
            }
            icon={<DownloadZipDeepWithFilesIcon />}
          >
            <Fmt id="general.docMenu.downloadInZip.deepWithFiles" />
          </Menu.Item>
          <Menu.Item
            key="deepWithoutFiles"
            onClick={() =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: false,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              })
            }
            icon={<DownloadZipDeepWithoutFilesIcon />}
          >
            <Fmt id="general.docMenu.downloadInZip.deepWithoutFiles" />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          title={
            <span>
              <ShareIcon /> <Fmt id="general.docMenu.share" />
            </span>
          }
        >
          <Menu.Item key="shareDownloadDownload" onClick={showShareDownloadModal} icon={<ShareIcon />}>
            <Fmt id="general.docMenu.shareDownload" />
          </Menu.Item>
          <Menu.Item key="shareAppUserDownload" onClick={showShareAppUserModal} icon={<ShareAppUsersIcon />}>
            <Fmt id="general.docMenu.shareAppUserDownload" />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="createDirectoryLink" onClick={createLink.show} icon={<CreateShortcutIcon />}>
          <Fmt id="general.dirMenu.createDirectoryLink" />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="audit-log" onClick={() => setAuditLogDirectory(dir)} icon={<ActivityIcon />}>
          <Fmt id="general.activity" />
        </Menu.Item>
      </Menu>
    );
  };

  const dir = selectedTargetDirectory;

  const createDisabled: boolean = !canCreateDirectory(dir);
  const modifyDisabled: boolean = !canEditDirectoryInfo(dir);
  const discardDisabled: DisabledWithReason = directoryDiscardDisabled(dir, intl);
  const moveDirectoryDisabled: boolean = !canMoveDirectory(dir);

  const canUploadDocuments = selectedTargetDirectory && hasWriteAccess;

  useEffect(() => {
    if (!!Object.keys(directoryTree).length && !Object.keys(directoryTree).includes(selectedTargetDirectory?.id)) {
      setDirectoryNotFound({
        statusCode: 404,
        referenceErrorCode: ServiceErrorEnum.DirectoryNotFoundError,
      });
    } else {
      setDirectoryNotFound(undefined);
    }
  }, [directoryTree, selectedTargetDirectory?.id]);

  const directoryNotFoundDescription = useMemo(() => {
    if (isLastTargetDirectoryDeleted) {
      return (
        <Alert
          showIcon
          type="error"
          description={
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage(
                    { id: 'AllDocumentPage.deletedTargetDirectory.error' },
                    { name: lastUsefulTargetDirectory?.name }
                  ),
                }}
              />
              {intl.formatMessage(
                { id: 'AllDocumentPage.deletedTargetDirectory.lastPath' },
                { path: lastUsefulTargetDirectory?.path }
              )}
            </>
          }
        />
      );
    }
    return null;
  }, [intl, isLastTargetDirectoryDeleted, lastUsefulTargetDirectory]);

  useEffect(() => {
    if (!!dir) setLastUsefulTargetDirectory(dir);
  }, [dir]);

  const checkDirectoryChangedSignalR = useCallback(
    (fromSignalDirectoryId: Guid, changedById: Guid, eventType: DirectoryChangeTypeEnum) => {
      if (eventType === DirectoryChangeTypeEnum.DirectoryDiscarded && directoryId === fromSignalDirectoryId) {
        setIsLastTargetDirectoryDeleted(true);
      }
    },
    [directoryId]
  );

  useEffect(() => {
    SIGNAL_R_REDUX_STORE_CONNECTION.on('DirectoriesWithLinksChanged', checkDirectoryChangedSignalR);
    return () => {
      SIGNAL_R_REDUX_STORE_CONNECTION.off('DirectoriesWithLinksChanged', checkDirectoryChangedSignalR);
    };
  }, [checkDirectoryChangedSignalR]);

  return (
    <>
      <AllDocumentsPageLayout
        directoryContent={
          <ContentGate
            loading={!selectedTargetDirectory}
            error={directoryNotFound}
            additionalErrorContent={directoryNotFoundDescription}
          >
            <SelectedItemsProvider>
              {permissionsVisible ? (
                <DirectorySettingsForm
                  directory={selectedTargetDirectory}
                  usersList={usersList}
                  usersMap={usersMap}
                  categoryMap={categoryMap}
                  onSubmit={handleDirectoryEditFormSubmit}
                  defaultTab={permissionsTab}
                  setDefaultTab={setPermissionsTab}
                  projectId={projectId}
                />
              ) : (
                <FiltersContextProvider
                  filtersOptions={FILTERS}
                  orderOptions={DOCUMENT_ORDER_OPTIONS}
                  persistentKey={FiltersPersistentKey.DocumentsAll}
                >
                  <AllDocumentsPageContent
                    directory={selectedTargetDirectory}
                    directoryToolbar={
                      <DirectoryToolbar
                        className={styles.directoryToolbar}
                        selectedDirectory={selectedTargetDirectory}
                        onMultipleUpload={onShowFileUpload}
                        onCreateDirectory={createDirectory.show}
                        onMoveDirectory={handleOpenMoveDirectoryModal}
                        onDirectoryEdit={handleOpenDirectoryEditFormModal}
                        onDirectoryDiscard={handleDirectoryDiscard}
                        onDownloadZip={downloadDirectoryInZip}
                        onFavoriteChange={handleDirectoryFavoriteChange}
                        onShareAppUser={showShareAppUserModal}
                        onShareDownload={showShareDownloadModal}
                        downloadZipLoading={downloadZipLoading}
                        createDisabled={createDisabled}
                        moveDisabled={moveDirectoryDisabled}
                        modifyDisabled={modifyDisabled}
                        discardDisabled={discardDisabled}
                        onCreateDirectoryLink={createLink.show}
                        onEditDirectoryLink={toolbarDirectoryLinkEdit}
                        onDiscardDirectoryLink={toolbarDirectoryLinkDiscard}
                        onMoveDirectoryLink={toolbarDirectoryLinkMove}
                        isSelectedDirectory={isSelectedDirectory}
                        onOpenExport={onOpenExport}
                        setAuditLogDirectory={setAuditLogDirectory}
                      />
                    }
                  />
                </FiltersContextProvider>
              )}
            </SelectedItemsProvider>
          </ContentGate>
        }
        documentsHeader={
          selectedTargetDirectory && (
            <DocumentsHeader
              projectId={projectId}
              directoryPath={selectedTargetDirectory.path}
              directoryId={selectedTargetDirectory.id}
              buttons={
                <>
                  <ToolbarSettingsButton
                    onDisplayDirectorySettings={setPermissionsVisible}
                    displayDirectorySettings={permissionsVisible}
                  />
                </>
              }
            />
          )
        }
        directoryTree={
          <AllDocumentsPageDirectoryTree
            contextMenu={renderDirectoriesMenu}
            onMoveDirectory={handleMoveDirectoryTreeDrop}
            projectId={projectId}
            selectedTreeKey={selectedDirectoryTreeKey}
          />
        }
        disableDropzone={!canUploadDocuments || fileUploadVisible}
        onDropFiles={onDropFiles}
      />

      {selectedTargetDirectory && (
        <>
          <ErrorBoundary>
            <DirectoryDiscardFormModal
              selectedDirectory={selectedTargetDirectory}
              visible={state.discardDirectoryModalVisible}
              onSubmit={handleDiscardDirectoryFormSubmit}
              onClose={handleCloseDiscardDirectoryModal}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <DirectoryCreateLinkFormModal {...createLink} />
          </ErrorBoundary>
          <ErrorBoundary>
            <DocumentCreateMultipleFormModal
              startingDirectoryId={selectedTargetDirectory?.id}
              visible={fileUploadVisible}
              onSubmit={handleUploadFilesSubmit}
              onClose={onCloseFileUpload}
              droppedFiles={droppedFiles}
              uploadDisabled={!canUploadDocuments}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <AuditLogEntityModal
              visible={auditLogVisible}
              label={<Fmt id="AuditLog.directory.label" values={{ path: auditLogDirectory?.path }} />}
              entityId={auditLogDirectory?.id}
              entityType={EntityTypesEnum.directory}
              onOk={hideAuditLog}
              deps={[auditLogDirectory]}
            />
          </ErrorBoundary>
          <AppUserShareFormModal
            directoryId={selectedTargetDirectory.id}
            shareDocument={false}
            visible={shareAppUserModalVisible}
            onSubmit={hideShareAppUserModal}
            onClose={hideShareAppUserModal}
          />
          <DeepShareDownloadFormModal
            directoryId={selectedTargetDirectory.id}
            visible={shareDownloadModalVisible}
            onSubmit={hideShareDownloadModal}
            onClose={hideShareDownloadModal}
          />
          <DirectoryCreateFormModal {...createDirectory} />
          <DirectoryEditFormModal
            directoryId={selectedTargetDirectory.id}
            name={selectedTargetDirectory.name}
            description={selectedTargetDirectory.description}
            visible={state.directoryEditFormModalVisible}
            onSubmit={handleDirectoryEditFormSubmit}
            onClose={handleCloseDirectoryEditFormModal}
            validateUniqueName={checkUpdateDirectoryUniqueName}
          />
          <DirectoryMoveFormModal
            selectedDirectory={
              state.moveDirectorySourceId ? directoryTree[state.moveDirectorySourceId] : selectedTargetDirectory
            }
            destinationDirectory={state.moveDirectoryDestinationId}
            visible={state.moveDirectoryModalVisible}
            onSubmit={handleMoveDirectoryFormSubmit}
            onClose={handleCloseMoveDirectoryModal}
            match={match}
          />
          <DirectoryLinkMoveFormModal
            directoryLink={state.moveDirectoryLink}
            defaultDestinationId={state.moveDirectoryDestinationId}
            visible={state.moveDirectoryLinkModalVisible}
            onSubmit={handleMoveDirectoryLinkFormSubmit}
            onClose={handleCloseMoveDirectoryLinkModal}
            match={match}
          />
          <DirectoryLinkEditFormModal
            directoryLinkId={state.editDirectoryLink?.linkId}
            name={state.editDirectoryLink?.linkName}
            visible={state.directoryLinkEditFormModalVisible}
            onSubmit={handleDirectoryLinkEditFormSubmit}
            onClose={handleCloseDirectoryLinkEditFormModal}
            validateUniqueName={checkUpdateDirectoryLinkUniqueName}
          />
          <DirectoryLinkDiscardFormModal
            directoryLink={state.discardDirectoryLink}
            visible={state.discardDirectoryLinkModalVisible}
            onSubmit={handleDiscardDirectoryLinkFormSubmit}
            onClose={handleCloseDiscardDirectoryLinkModal}
          />
          {match.params.action === ACCESS_REQUEST && (
            <GrantingAccessModal
              usersMap={usersMap}
              usersList={usersList}
              selectedDirectory={selectedTargetDirectory}
              currentUser={currentUser}
              action={match.params.action}
              projectId={projectId}
              onClose={onGrantingAccessClose}
            />
          )}
          <DirectoryReportExportFormModal
            onSubmit={onSubmitExport}
            onClose={onCancelExport}
            visible={exportFormVisible}
            loading={exporting}
          />
          <Modal
            title={errorsModalTitle}
            open={downloadErrorsModal}
            cancelButtonProps={HIDE_BUTTON_PROPS}
            onOk={handleOkDownloadErrorsModal}
            onCancel={handleOkDownloadErrorsModal}
            width={800}
          >
            {renderDownloadErrorsModalContent(
              DownloadErrorEntityEnum.document,
              'AllDocumentsPage.downloadErrorsModal.documents'
            )}
            {downloadRequestContainsLinks && (
              <Margin top>
                <Text strong underline>
                  <Fmt id="general.docMenu.downloadInZip.directoryLinkWarning" />
                </Text>
              </Margin>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default memoizeWithIntl(AllDocumentsPageComponent);
