const formatterCache: { [key: string]: (value: number | string) => string } = {};

const fromCache = (locale: string, currency: string, key: string, config: Intl.NumberFormatOptions) => {
  const id = `${locale}-${currency}-${key}`;
  if (!(id in formatterCache)) {
    const formatter = new Intl.NumberFormat([locale], config);
    formatterCache[id] = (value: number | string) => (value === undefined ? undefined : formatter.format(+value));
  }
  return formatterCache[id];
};

export const currencyShort = (locale: string, currency: string) =>
  fromCache(locale, currency, 'currencyShort', {
    style: 'currency',
    currency: currency,
    maximumSignificantDigits: 3,
    notation: 'compact',
  } as Intl.NumberFormatOptions);

export const currencyLong = (locale: string, currency: string) =>
  fromCache(locale, currency, 'currencyLong', {
    style: 'currency',
    currency: currency,
  });

export const percentWithPrecision = (locale: string, decimalPlaces: number) =>
  fromCache(locale, null, `percentWithPrecision-${decimalPlaces}`, {
    style: 'percent',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

export const percentFraction = (locale: string) =>
  fromCache(locale, null, 'percentFraction', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const percentWhole = (locale: string) =>
  fromCache(locale, null, 'percentWhole', {
    style: 'percent',
  });
