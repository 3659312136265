import { InputRef } from 'antd';
import { api } from 'api';
import { DocumentCategoryNodeDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentCategoryTreeNodeCreateForm, {
  DocumentCategoryTreeNodeCreateFormData,
} from './DocumentCategoryTreeNodeCreateForm';

type Props = FormModalProps<DocumentCategoryNodeDto> & {
  categoryId: Guid;
  parentId: Guid;
  validateUniqueName: (name: string) => boolean;
  defaults?: DocumentCategoryNodeDto;
};

const DocumentCategoryTreeNodeCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, categoryId, parentId, validateUniqueName, defaults, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DocumentCategoryTreeNodeCreateFormData> = async (values) => {
    let data: any;
    let err: ApiError;
    let resp: AxiosResponse<DocumentCategoryNodeDto>;

    if (defaults) {
      // update
      data = {
        name: values.name,
        description: values.description,
      };
      [err, resp] = await api.project.categoryTrees.updateCategoryNodeById(categoryId, defaults.id, data);
    } else {
      // create
      data = {
        name: values.name,
        description: values.description,
        parentId,
      };
      [err, resp] = await api.project.categoryTrees.createCategoryNode(categoryId, data);
    }

    if (err) return err;

    await onSubmit(resp.data);
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.visible);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={defaults ? defaults.name : null}
      titleId="DocumentCategoryTreeNodeCreateFormModal.title"
      {...restProps}
    >
      {({ intl, formRef }) => (
        <DocumentCategoryTreeNodeCreateForm
          intl={intl}
          wrappedComponentRef={formRef}
          validateUniqueName={validateUniqueName}
          defaults={defaults}
          setRef={setInputRef}
        />
      )}
    </FormModalWrapper>
  );
};

export default DocumentCategoryTreeNodeCreateFormModal;
