import React, { useCallback } from 'react';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { message } from 'antd';
import { useIntl } from 'hooks/useIntl';

export const useOnDropProvider = (hasWriteAccess: boolean) => {
  const [files, setFiles] = React.useState<FileSystemTreeNode[]>([]);
  const [visible, setVisible] = React.useState(false);
  const intl = useIntl();

  const clear = useCallback(() => {
    setFiles([]);
  }, []);

  const showInvalidAccessMessage = useCallback(() => {
    message.info(intl.formatMessage({ id: 'serviceError.UploadAcccessDeniedError' }));
  }, [intl]);

  const onDrop = useCallback(
    (result: FileSystemTreeNode[]) => {
      if (!hasWriteAccess) {
        showInvalidAccessMessage();
        return;
      }
      setVisible(true);
      setFiles(result);
    },
    [setFiles, hasWriteAccess, showInvalidAccessMessage]
  );

  const onClose = useCallback(() => {
    setVisible(false);
    clear();
  }, []);

  const onShow = useCallback(() => {
    if (!hasWriteAccess) {
      showInvalidAccessMessage();
      return;
    }
    clear();
    setVisible(true);
  }, [showInvalidAccessMessage, clear, hasWriteAccess]);

  return [onDrop, files, onClose, visible, onShow] as const;
};
