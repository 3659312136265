import classNames from 'classnames';
import React from 'react';
import styles from './DisplayName.module.less';

export interface DisplayNameProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  text?: string;
  title?: string;
  isWrap?: boolean;
  removeNewLines?: boolean;
  strong?: boolean;
}

class DisplayName extends React.Component<DisplayNameProps> {
  render() {
    const { children, text: _text, removeNewLines, style, isWrap, className, strong, ...restProps } = this.props;

    const text = removeNewLines
      ? _text !== undefined && _text !== null
        ? _text.replace(/(\r\n\t|\n|\r\t)/gm, ' ')
        : _text
      : _text;

    return (
      <span
        className={classNames(
          styles.displayName,
          isWrap ? styles.isWrap : styles.noWrap,
          strong && styles.strong,
          className
        )}
        {...restProps}
        style={{ ...style }}
      >
        {text}
        {children}
      </span>
    );
  }
}

export default DisplayName;
