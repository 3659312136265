import createLoadingPlugin from '@rematch/loading';
import createRematchPersist from '@rematch/persist';
// import createSelectPlugin from '@rematch/select';

const persistPlugin = createRematchPersist({
  whitelist: ['filterState'],
  throttle: 3000,
  version: 2,
});

export const plugins = [createLoadingPlugin({}), persistPlugin /* , createSelectPlugin() */];
