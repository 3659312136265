import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';

type Props = InputProps &
  InjectedIntlProps & {
    onSearch: (value: string) => void;
    placeholder?: string;
  };

const SearchInput: FunctionComponent<Props> = ({ intl, placeholder, onChange, onSearch, ...rest }) => (
  <Input
    prefix={<SearchOutlined />}
    allowClear
    onChange={(e) => {
      onChange && onChange(e);
      onSearch && onSearch(e.target.value);
    }}
    placeholder={placeholder || intl.formatMessage({ id: 'general.search' })}
    {...rest}
  />
);

export default injectIntl(SearchInput);
