import { Button } from 'antd';
import { BlobDerivateDto, BlobDerivateStatusEnum } from 'api/completeApiInterfaces';
import { viewerComponentsMap } from 'components/FileViewer/Viewers';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ViewerTypes } from './FileViewerInterfaces';

type Props = {
  viewer: ViewerTypes;
  getUrl: () => Promise<string>;
  refresh: () => void;
  contentType: string;
  blobToken: string;
  derivate: BlobDerivateDto;
  viewerVisible?: boolean;
  loading?: boolean;
  onClickGenerateOne: () => void;
};

const viewerStyle = { width: '100%', height: '100%' };

const FileViewer: FunctionComponent<Props> = ({
  viewer,
  getUrl,
  refresh,
  contentType,
  blobToken,
  derivate,
  viewerVisible,
  loading,
  onClickGenerateOne,
}) => {
  const [url, setUrl] = useState<string>(undefined);
  const [loaded, setLoaded] = useState(false);
  const [loadedUrl, setLoadedUrl] = useState(false);

  useEffect(() => {
    Promise.resolve(getUrl()).then((result) => {
      setUrl(result);
      setLoadedUrl(true);
    });
  }, [getUrl]);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const Viewer = viewerComponentsMap[viewer];

  const spinning = !loaded || !loadedUrl || loading;
  return (
    <>
      {derivate?.status !== BlobDerivateStatusEnum.Error && derivate?.status !== BlobDerivateStatusEnum.Processing && (
        <SpinBoxCenter spinning={spinning} delay={0}>
          <Viewer
            url={url}
            refresh={refresh}
            contentType={contentType}
            blobToken={blobToken}
            derivate={derivate}
            viewerVisible={viewerVisible}
            onLoad={handleLoad}
            style={viewerStyle}
          />
        </SpinBoxCenter>
      )}

      {derivate?.status === BlobDerivateStatusEnum.Error && (
        <StackPanel justifyContent="centerContent" centerItems stretch>
          <Button type="dashed" onClick={onClickGenerateOne}>
            <Fmt id="FileViewer.rerenderingDerivateButton" />
          </Button>
        </StackPanel>
      )}

      {derivate?.status === BlobDerivateStatusEnum.Processing && (
        <StackPanel vertical justifyContent="centerContent" centerItems stretch>
          <Fmt id="FileViewer.processing" />
          <Button type="dashed" onClick={refresh}>
            <Fmt id="FileViewer.reloadDerivateButton" />
          </Button>
        </StackPanel>
      )}
    </>
  );
};

export default FileViewer;
