import { api } from 'api';
import { LabelDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import DisplayName from 'components/DisplayName';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import Label from 'components/Label';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { useLabelListDeleteItems } from 'hooks/useLabelListDeleteItems';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import { smartFilter } from 'utils';

type LabelUsedInEntitiesErrorData = {
  labelError: {
    labelId: Guid;
    documentsCount: number;
    commentProceduresCount: number;
    workflowsCount: number;
  };
};

type Props = InjectedIntlProps & {
  listData: LabelDto[];
  search: string;
  onEdit: (label: Guid) => void;
  onDelete: (labelId: Guid) => void;
  onClearSearch?: () => void;
};

const LabelsList: FunctionComponent<Props> = ({ intl, listData, search, onEdit, onDelete, onClearSearch }) => {
  const [deletingItems, handleDelete] = useLabelListDeleteItems(
    listData,
    onDelete,
    api.project.labels.deleteLabelById,
    (name, errorData) => {
      const errors = (errorData as LabelUsedInEntitiesErrorData).labelError;
      return intl.formatMessage(
        { id: 'LabelsList.deleteConfirmModal.description' },
        {
          label: name,
          documentCount: errors.documentsCount,
          commentProcedureCount: errors.commentProceduresCount,
          workflowCount: errors.workflowsCount,
        }
      );
    }
  );

  return (
    <GeneralSettingsContainer>
      <List<LabelDto>
        data={listData}
        search={search}
        filterItem={(item) => smartFilter(item.name, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            title={<Label color={item.color}>{<DisplayName text={item.name} />}</Label>}
            description={item.description}
            wrap
            loading={deletingItems.has({ id: item.id })}
            additionalActions={
              <>
                <EditButton onClick={() => onEdit?.(item.id)} />
                <DeleteButtonConfirm onConfirm={() => handleDelete({ id: item.id })} />
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
    </GeneralSettingsContainer>
  );
};

export default injectIntl(LabelsList);
