import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Dropdown, Tree } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import DisplayName from 'components/DisplayName';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import PathDisplay from 'components/PathDisplay';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import {
  ConnectedDirectory,
  DirectoryNode,
  DirectoryNodeDirectory,
  DirectoryNodeDirectoryLink,
  directoryNodeHelpers,
  DirectoryNodeType,
} from 'utils/typeMappings/directories/directoryTypes';

import { ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import { AllowDrop } from 'rc-tree/lib/Tree';
import styles from './DirectoriesTree.module.less';

const TRUE_LAMBDA = () => true;
const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['contextMenu'];

export type DirectoriesTreeProps = {
  directoryRoot: ConnectedDirectory;
  expandedKeys: DirectoryNodeKey[];
  onExpand: (expandedKeys: DirectoryNodeKey[]) => void;
  selectedKeys: DirectoryNodeKey[];
  onSelect: (selectedKeys: DirectoryNodeKey[]) => void;
  contextMenu: (node: DirectoryNode) => ReactElement;
  onMoveDirectory?: (selectedDirectoryId: Guid, destinationDirectoryId: Guid) => void;
  tooltipRenderer?: (dir: ConnectedDirectory) => ReactNode;
  nameRenderer?: (dir: DirectoryNode, originalName: ReactNode) => ReactNode;
  isDirectoryVisible?: (directory: DirectoryNode) => boolean;
  searchPhrase?: string;
};

const allowDrop: AllowDrop = (options) =>
  !(
    options.dragNode.key === options.dropNode.key || // target is the moved node
    options.dropNode.disabled || // target is disabled
    // target is parent of the moved node
    options.dropNode.children?.some((child) => child.key === options.dragNode.key)
  );

export const DirectoriesTree: FunctionComponent<DirectoriesTreeProps> = (props) => {
  const {
    directoryRoot,
    onSelect,
    contextMenu,
    onExpand,
    onMoveDirectory,
    tooltipRenderer,
    nameRenderer,
    isDirectoryVisible = TRUE_LAMBDA,
    searchPhrase,
    expandedKeys,
    ...restProps
  } = props;

  const getNodeTooltip =
    tooltipRenderer ||
    ((dir: ConnectedDirectory) => {
      if (dir.itemsCount >= 0)
        return (
          <div>
            <div>
              <Fmt id={`DirectoriesTree.itemsCount`} />: {dir.itemsCount}
            </div>
            <div>
              <Fmt id={`DirectoriesTree.subItemsCount`} />: {dir.subItemsCount}
            </div>
          </div>
        );
      return <span>{!!dir.description ? dir.description : dir.name}</span>;
    });

  const defaultNameRenderer = (dir: DirectoryNode) => directoryNodeHelpers.getDirectoryNodeName(dir);
  const getNodeName = nameRenderer || defaultNameRenderer;

  const renderNodeTitle = (node: DirectoryNode, title: ReactNode, icon: ReactNode) => {
    return (
      <Dropdown overlay={contextMenu(node)} trigger={DROPDOWN_TRIGGER} className={styles.dropdown}>
        <div className={styles.title}>
          <div className={styles.titleLeft}>
            {icon} {title}
          </div>
        </div>
      </Dropdown>
    );
  };

  // TODO: hook this up to directoryAncestryIds instead of this mess?
  const visibleIds = useMemo(() => {
    const result = new Set<DirectoryNodeKey>([directoryNodeHelpers.directoryKey(directoryRoot.id)]);
    const processNode = (node: DirectoryNode): boolean => {
      let visible = isDirectoryVisible(node);
      switch (node.type) {
        case DirectoryNodeType.DirectoryLink:
          break;
        case DirectoryNodeType.Directory:
          // because "result" is mutated, must map all nodes and THEN search for some true result,
          // eslint-disable-next-line no-case-declarations
          const someChildVisible = node.directory.children.map(processNode).some((visible) => visible);
          visible = visible || someChildVisible;
          break;
      }
      if (visible) result.add(directoryNodeHelpers.directoryNodeKey(node));
      return visible;
    };
    processNode(directoryNodeHelpers.directoryNode(directoryRoot));
    return result;
  }, [directoryRoot, isDirectoryVisible]);

  const renderDirectoryNode = (
    treeNode: DirectoryNodeDirectory,
    parentExpanded: boolean,
    title: ReactElement
  ): ReactElement => {
    const directory = treeNode.directory;
    const key = directoryNodeHelpers.directoryKey(directory.id);
    const isExpanded = expandedKeys.indexOf(key) !== -1 || directory.id === directoryRoot.id;
    const theme = directory.itemsCount > 0 || directory.subItemsCount > 0 ? 'filled' : undefined;
    const icon =
      directory.parentId === null ? (
        <LegacyIcon type="hdd" theme={theme} />
      ) : (
        <LegacyIcon type={isExpanded ? 'folder-open' : 'folder'} theme={theme} />
      );
    const iconWithTooltip = (
      <NoClickTooltip placement="right" title={getNodeTooltip(directory)} mouseEnterDelay={0.5}>
        {icon}
      </NoClickTooltip>
    );
    return (
      <Tree.TreeNode key={key} title={renderNodeTitle(treeNode, title, iconWithTooltip)} disabled={!directory}>
        {!!parentExpanded && directory.children.map((child) => renderTreeNode(child, isExpanded))}
      </Tree.TreeNode>
    );
  };

  const renderDirectoryLink = (treeNode: DirectoryNodeDirectoryLink, title: ReactElement): ReactElement => {
    const directory = treeNode.directoryLink.linkedDirectory;
    const key = directoryNodeHelpers.directoryLinkKey(treeNode.directoryLink.linkId);

    // TODO: there must be a better way...
    const classNameFromLength =
      directory?.path.length < 40 || !directory
        ? styles.linkTooltipSmall
        : directory?.path.length < 70
        ? styles.linkTooltipMedium
        : styles.linkTooltipLarge;

    const iconWithTooltip = (
      <NoClickTooltip
        placement="right"
        title={
          !!directory ? (
            <div>
              <Fmt id="DirectoriesTree.linkedDirectoryPath" />
              <PathDisplay alignLeft path={directory?.path} />
            </div>
          ) : (
            <Fmt id="DirectoriesTree.linkedDirectoryNotFound" />
          )
        }
        overlayClassName={classNameFromLength}
        mouseEnterDelay={0.5}
      >
        <ShortcutIcon />
      </NoClickTooltip>
    );

    return <Tree.TreeNode key={key} title={renderNodeTitle(treeNode, title, iconWithTooltip)} disabled={!directory} />;
  };

  const previousSearchPhraseRef = useRef(searchPhrase);

  useEffect(() => {
    if (searchPhrase !== '' && previousSearchPhraseRef.current !== searchPhrase) onExpand([...visibleIds]);
    previousSearchPhraseRef.current = searchPhrase;
  }, [searchPhrase, onExpand, visibleIds]);

  const renderTreeNode = (treeNode: DirectoryNode, parentExpanded: boolean): ReactElement => {
    const key = directoryNodeHelpers.directoryNodeKey(treeNode);
    if (!visibleIds.has(key)) return null;

    const title = <DisplayName>{getNodeName(treeNode, defaultNameRenderer(treeNode))}</DisplayName>;

    switch (treeNode.type) {
      case DirectoryNodeType.Directory:
        return renderDirectoryNode(treeNode, parentExpanded, title);
      case DirectoryNodeType.DirectoryLink:
        return renderDirectoryLink(treeNode, title);
    }
  };

  const onDrop = useCallback(
    (params) => {
      const sourceNodeId = params.dragNode?.key as DirectoryNodeKey;
      const targetNodeId = params.node?.key as DirectoryNodeKey;
      if (!onMoveDirectory || !sourceNodeId || !targetNodeId) return;
      !!onMoveDirectory && onMoveDirectory(sourceNodeId, targetNodeId);
    },
    [onMoveDirectory]
  );

  const onDragEnter = useCallback(
    (info) => {
      onExpand(info.expandedKeys as DirectoryNodeKey[]);
    },
    [onExpand]
  );

  const onRightClick = useCallback(
    ({ node }) => {
      if (!node.disabled) onSelect([node.key as DirectoryNodeKey]);
    },
    [onSelect]
  );

  return (
    <Tree.DirectoryTree
      blockNode
      showIcon={false}
      draggable={!!onMoveDirectory}
      onDragEnter={onDragEnter}
      allowDrop={allowDrop}
      onDrop={onDrop}
      expandAction="doubleClick"
      className={styles.tree}
      onSelect={onSelect}
      onRightClick={onRightClick}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      {...restProps}
    >
      {renderTreeNode(directoryNodeHelpers.directoryNode(directoryRoot), true)}
    </Tree.DirectoryTree>
  );
};
