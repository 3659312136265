import { message } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { processApiError } from './processApiError';

export const messageError = (error: ApiError | ServiceError | IntlMessageId | null, intl: InjectedIntl) => {
  if (!error) {
    return;
  }
  if (error instanceof Error) {
    error = processApiError(error);
  }
  const messageId: IntlMessageId = typeof error === 'string' ? error : `serviceError.${error.referenceErrorCode}`;
  message.error(intl.formatMessage({ id: messageId }));
};
