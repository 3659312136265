import { Alert } from 'antd';
import { ExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { LabelFormModal } from 'components/forms/LabelForm';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { labelsMapSelector, labelsOrderedListSelector } from 'store/selectors/labelsSelectors';
import { strCompareCI } from 'utils';
import { userHasPermission } from 'utils/userHasPermission';
import Panel from '../Panel';
import LabelsList from './LabelsList';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentProjectUser.data,
  labelsList: labelsOrderedListSelector(state),
  labelsMap: labelsMapSelector(state),
  labelsMapLoading: state.labels.loading,
  labelsMapError: state.labels.error,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadLabels: dispatch.labels.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState & PropsFromDispatch & InjectedIntlProps;

const Labels: FunctionComponent<Props> = ({
  intl,
  loadLabels,
  labelsList,
  labelsMap,
  labelsMapLoading,
  labelsMapError,
  currentUser,
}) => {
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [formModalId, setFormModalId] = useState<string>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);

  const { url } = useRouteMatch();

  useDirtyStoreReload(
    (store) => store.labels,
    (dispatch) => dispatch.labels
  );

  useEffect(() => {
    loadLabels({ reload: true });
  }, []);

  const handleCreate = () => {
    loadLabels({ reload: true });
    closeModal();
  };

  const handleAdd = () => {
    setFormModalId(undefined);
    setFormVisible(true);
  };

  const showModal = (id?: Guid) => {
    setFormModalId(id);
    setFormVisible(true);
    setEditMode(true);
  };

  const closeModal = () => {
    setFormVisible(false);
    setFormModalId(undefined);
    setEditMode(false);
  };

  const checkLabelUniqueName = useCallback(
    (name: string) =>
      labelsList
        ? labelsList.findIndex(
            (l) => strCompareCI(l.name, name) === 0 && (!!formModalId ? l.id !== formModalId : true)
          ) === -1
        : true,
    [labelsList, formModalId]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const renderList = () => {
    if (labelsMapError) return <ServiceErrorBox error={labelsMapError} />;
    if (labelsMapLoading && !labelsList) return <SpinBox />;
    if (labelsList === null) return null;

    return (
      <LabelsList
        listData={labelsList}
        search={search}
        onEdit={showModal}
        onDelete={() => loadLabels({ reload: true })}
        onClearSearch={clearSearch}
      />
    );
  };

  const noPermission = !currentUser.isAdmin && !userHasPermission(currentUser, ExtendedPermissionEnum.labels);

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'general.labels' })}
      children={() => (
        <StackPanel vertical scrollable>
          {noPermission ? (
            <Margin top left right>
              <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
            </Margin>
          ) : (
            <Panel
              panelWidth="auto"
              noMargin
              onSearch={setSearch}
              searchValue={search}
              addButtonOnClick={handleAdd}
              addButtonText={<Fmt id="Panel.AddLabel.tooltip" />}
            >
              {renderList()}
              <LabelFormModal
                visible={formVisible}
                onSubmit={handleCreate}
                onClose={closeModal}
                validateUniqueName={checkLabelUniqueName}
                defaults={editMode ? labelsMap[formModalId] : null}
              />
            </Panel>
          )}
        </StackPanel>
      )}
    />
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(injectIntl(Labels));
