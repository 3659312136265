import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from '@aspnet/signalr';
import { DEBUG } from 'config/env';
import { delay } from 'utils';

export interface SignalRConfig {
  retryConnectDelay: number;
  logLevel: LogLevel;
}

export class SignalR {
  private config: SignalRConfig = null;
  private static configDefaults: SignalRConfig = {
    retryConnectDelay: 3000,
    logLevel: LogLevel.Information,
  };

  private accessToken: string = null;
  private readonly connection: HubConnection = null;
  private groups = new Set<string>();

  private _isConnected: boolean = false;
  private _isConnecting: boolean = false;
  private _isClosing: boolean = false;
  private _hubUrl: string = null;

  public onConnected: (connection: HubConnection) => void = null;

  constructor(hubUrl: string, cfg: Partial<SignalRConfig> = {}) {
    this._hubUrl = hubUrl;
    this.config = Object.assign(SignalR.configDefaults, cfg);

    const connectionOptions: IHttpConnectionOptions = {
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => this.accessToken,
      logMessageContent: false,
    };

    this.connection = new HubConnectionBuilder()
      .withUrl(hubUrl, connectionOptions)
      .configureLogging(this.config.logLevel)
      .build();

    this.connection.onclose(this.oncloseHandler);
  }

  public get isConnected() {
    return this._isConnected;
  }

  public toString() {
    return `SignalR: ${this._hubUrl}`;
  }

  public get hubUrl() {
    return this._hubUrl;
  }
  public get hubName() {
    return this._hubUrl.split('/').pop();
  }

  private oncloseHandler = (err?: Error) => {
    this._isConnected = false;
    if (err) {
      // in case of error - eg. connection is closed by network error, reconnect
      DEBUG && console.error('SignalR: connection closed with error', err.toString());
      this.start();
    }
  };

  public async start() {
    if (this._isConnected || this._isConnecting || this._isClosing) return;
    this._isConnecting = true;

    do {
      try {
        await this.connection.start();
        this._isConnected = true;
        this._isConnecting = false;
      } catch (err) {
        const delayMs = this.config.retryConnectDelay;
        DEBUG && console.error(`SignalR: start failed, try to reconnect in ${delayMs} ms...`, err.toString());
        await delay(delayMs);
      }
    } while (!this._isConnected);

    if (this.onConnected) this.onConnected(this.connection);
    await this.invokeJoinAllGroups();
  }

  public async stop() {
    if (this._isConnected && !this._isClosing) {
      this._isClosing = true;
      try {
        await this.connection.stop();
      } catch (err) {
        DEBUG && console.error('SignalR: stop failed', err.toString());
      } finally {
        this._isClosing = false;
      }
    }
  }

  public setAccessToken(accessToken: string) {
    this.accessToken = accessToken;

    if (this._isConnected || this._isConnecting) this.reconnect();
  }

  public async reconnect() {
    if (this._isConnected) {
      await this.stop();
    }
    await this.start();
  }

  public async joinGroup(groupName: Guid) {
    DEBUG && console.log('JOIN GROUP', groupName);
    // Do not join undefined group
    if (!groupName) return false;
    this.groups.add(groupName);

    if (this._isConnected && !this._isClosing) {
      try {
        await this.invoke('JoinGroup', groupName);
      } catch (err) {
        DEBUG && console.error('SignalR: joining group failed', err.toString());
        return false;
      }
    }
    return true;
  }

  public async leaveGroup(groupName: Guid) {
    DEBUG && console.log('LEAVE GROUP', groupName);
    this.groups.delete(groupName);

    if (this._isConnected && !this._isClosing) {
      try {
        await this.invoke('LeaveGroup', groupName);
      } catch (err) {
        DEBUG && console.error('SignalR: leaving group failed', err.toString());
        return false;
      }
    }
    return true;
  }

  private async invokeJoinAllGroups() {
    DEBUG && console.debug('SignalR: joining all groups', this.groups);
    for (const group of Array.from(this.groups)) {
      const res = await this.joinGroup(group);
      if (!res) return;
    }
  }

  public on(methodName: string, callback: (...args: any[]) => void) {
    this.connection.on(methodName, callback);
  }

  public off(methodName: string, callback: (...args: any[]) => void) {
    this.connection.off(methodName, callback);
  }

  public invoke<T>(methodName: string, ...args: any[]): Promise<T> {
    DEBUG && console.debug(`SignalR: invoking ${methodName}`, args);
    return this.connection.invoke<T>(methodName, ...args);
  }
}

export default SignalR;
