import { Col, Row, Typography } from 'antd';
import { Fmt } from 'locale';
import { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

type CommentProcedureNotificationInfo = {
  CommentProcedureNotificationType: number;
  CommentProcedureName: string;
  CommentProcedureCommentName: string;
  CommentProcedureDocumentName: string;
  CommentProcedureLink: string;
  CommentProcedureTeamName: string;
  CommentProcedureUserName: string;
  NewState: string;
  NoteText: string;
  OldState: string;
  UserName: string;
};

export type CommentProcedureMessageData = {
  Language: string;
  ProjectId: Guid;
  ProjectDescription: string;
  FinishTime: Moment;
  CommentProcedureNotificationInfo: CommentProcedureNotificationInfo;
};

export const CommentProcedureMessageViewer: MessageViewer = ({
  category,
  messageData,
  projectName,
  organizationName,
}) => {
  const data = messageData as CommentProcedureMessageData;

  const header = <Fmt id={'MessageCenterPage.message.detail.commentProcedure.title'} />;

  const content = (
    <>
      <Typography.Text>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>
      <MessageViewerDetailGrid>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.commentProcedure'} />
          </Col>
          <Col span={12}>
            <Link to={parse(data.CommentProcedureNotificationInfo.CommentProcedureLink || '').pathname}>
              <Typography.Link underline>{data.CommentProcedureNotificationInfo.CommentProcedureName}</Typography.Link>
            </Link>
          </Col>
        </Row>
        {data.CommentProcedureNotificationInfo.CommentProcedureCommentName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.commentName'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.CommentProcedureCommentName}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.CommentProcedureTeamName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.team'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.CommentProcedureTeamName}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.CommentProcedureUserName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.user'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.CommentProcedureUserName}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.CommentProcedureDocumentName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.document'} values={{ count: 1 }} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.CommentProcedureDocumentName}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.OldState && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.oldState'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.OldState}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.NewState && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.newState'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.NewState}</Col>
          </Row>
        )}
        {data.CommentProcedureNotificationInfo.NoteText && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.noteText'} />
            </Col>
            <Col span={12}>{data.CommentProcedureNotificationInfo.NoteText}</Col>
          </Row>
        )}
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
