import { Comparator, textComparer } from 'utils/comparators';
import { DiscussionAttachment } from '../DiscussionInput/DiscussionInput';

export type DiscussionInputAttachmentFile = {
  id: Guid;
  name: string;
  thumbnailUrl: string;
  primaryFileContentType: string;
  blobToken: string;
  isStaged: boolean;
  isMetadata: boolean;
};

export type StagedAttachment = {
  id: Guid;
  name: string;
  fileUrl: string;
  file: File;
};

export const limitNewAttachmentLinks = (attachmentList: Guid[], currentCount: number, maxCount: number) => {
  if (attachmentList.length + currentCount >= maxCount) {
    return attachmentList.slice(0, maxCount - currentCount);
  }
  return attachmentList;
};

export const attachmentMapper = (attachment: DiscussionAttachment): DiscussionInputAttachmentFile => ({
  id: attachment.id,
  name: attachment.name,
  thumbnailUrl: attachment.file.thumbnail,
  primaryFileContentType: attachment.file.contentType,
  blobToken: attachment.file.blobToken,
  isStaged: false,
  isMetadata: attachment.file.isMetadata,
});

const ATTACHMENT_COMPARATOR: Comparator<DiscussionAttachment> = textComparer.map((attachment) => attachment.name);

export const getLinkedAttachments = (
  availableAttachments: DiscussionAttachment[] | null,
  selectedAttachments: Guid[]
) =>
  availableAttachments
    ?.filter((attachment) => selectedAttachments.includes(attachment.id))
    .sort(ATTACHMENT_COMPARATOR) || [];

export const getUnlinkedAttachments = (
  availableAttachments: DiscussionAttachment[] | null,
  selectedAttachments: Guid[]
) =>
  availableAttachments
    ?.filter((attachment) => !selectedAttachments.includes(attachment.id))
    .sort(ATTACHMENT_COMPARATOR) || [];
