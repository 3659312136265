import { FileJsonOutlined, FileModelOutlined, FileXmlOutlined } from 'components/Icons';
import React, { FunctionComponent, ReactElement } from 'react';
import Icon, {
  FileExcelOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MailOutlined,
  SoundOutlined,
} from '@ant-design/icons';

type Props = {
  contentType: string;
  className?: string;
};

const getIcon = (mediaType: string, className?: string): ReactElement => {
  switch (mediaType) {
    case 'application/octet-stream':
      return <FileOutlined className={className} />;
    case 'application/pdf':
      return <FilePdfOutlined className={className} />;
    case 'text/plain':
      return <FileTextOutlined className={className} />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return <FileWordOutlined className={className} />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return <FileExcelOutlined className={className} />;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
      return <FilePptOutlined className={className} />;
    case 'image/jpeg':
    case 'image/png':
      return <FileJpgOutlined className={className} />;
    case 'application/xml':
    case 'text/xml':
      return <Icon component={FileXmlOutlined} className={className} />;
    case 'application/json':
      return <Icon component={FileJsonOutlined} className={className} />;
    case 'application/forge-viewer':
      return <Icon component={FileModelOutlined} className={className} />;
    case 'message/rfc822':
    case 'application/vnd.ms-outlook':
      return <MailOutlined className={className} />;
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/x-lzma':
    case 'application/x-tar':
      return <FileZipOutlined className={className} />;
    case 'audio/mpeg':
    case 'audio/vorbis':
    case 'audio/vnd.wave':
    case 'audio/mpa':
    case 'audio/mpa-robust':
    case 'application/ogg':
    case 'audio/ogg':
    case 'audio/wav':
    case 'audio/wave':
    case 'audio/x-wav':
    case 'audio/mp4':
      return <SoundOutlined className={className} />;
    default:
      return <FileUnknownOutlined className={className} />;
  }
};

const FileTypeIcon: FunctionComponent<Props> = ({ contentType, className }) => {
  const index = contentType?.indexOf(';');
  const mediaType = index < 0 ? contentType : contentType?.slice(0, index);

  return getIcon(mediaType, className);
};

export default React.memo(FileTypeIcon);
