import { to } from 'api/await-to';
import {
  MultiUploadCheckRequestDto,
  MultiUploadCheckResponseDto,
  MultiUploadPrepareDirectoriesRequestDto,
  MultiUploadPrepareDirectoriesResponseDto,
  UploadCreateDto,
  UploadDto,
  UploadPartCompleteRequestDto,
  UploadPartDto,
  UploadPartInitRequestDto,
  UploadPartInitResponseDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const uploadApi = {
  listUploads: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<UploadDto[]>(`/uploads`, { cancelToken })
    ),
  createUpload: (data: UploadCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<UploadDto>(`/uploads`, data, { cancelToken })
    ),
  getUploadById: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<UploadDto>(`/uploads/${uploadId}`, { cancelToken })
    ),
  completeUpload: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<UploadDto>(`/uploads/${uploadId}/complete`, { cancelToken })
    ),
  listUploadParts: (uploadId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<UploadPartDto[]>(`/uploads/${uploadId}/parts`, { cancelToken })
    ),
  initUploadPart: (uploadId: Guid, partId: Guid, data: UploadPartInitRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<UploadPartInitResponseDto>(`/uploads/${uploadId}/parts/${partId}/init`, data, { cancelToken })
    ),
  completeUploadPart: (uploadId: Guid, partId: Guid, data: UploadPartCompleteRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/uploads/${uploadId}/parts/${partId}/complete`, data, { cancelToken })
    ),
  checkMultiUpload: (data: MultiUploadCheckRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<MultiUploadCheckResponseDto>(`/uploads/checkmultiupload`, data, { cancelToken })
    ),
  prepareMultiUpload: (data: MultiUploadPrepareDirectoriesRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<MultiUploadPrepareDirectoriesResponseDto>(`/uploads/preparemultiupload`, data, {
        cancelToken,
      })
    ),
};
