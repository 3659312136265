import { Spin, Typography } from 'antd';
import { api } from 'api';
import { ProjectHubDto } from 'api/completeApiInterfaces';
import axios from 'axios';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { useBoolean, useCancelToken, useCurrentProjectUser, useIntl, useSelectorDispatch } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import {
  AvailableAppEnum,
  ExternAppListItem,
  ExternAppListItemProps,
  ExternalAppSourceTypeEnum,
} from '../../externAppsTypes';
import { EsticonCreate } from '../EsticonCreate/EsticonCreate';
import { EsticonIcon } from '../EsticonListItem/EsticonIcon';

export const EsticonListItem: FunctionComponent<ExternAppListItemProps & {
  type: ExternalAppSourceTypeEnum.Project;
}> = ({ project, onDelete, ...listItemProps }) => {
  const esticonProjectsState = useSelectorDispatch(
    (store) => store.esticonProjects,
    (dispatch) => dispatch.esticonProjects.loadData({ reload: true })
  );
  const cancelToken = useCancelToken('EsticonDeleteLinkButton: unmounting', []);
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const [isEditLinkedProjectVisible, showEditLinkedProject, hideEditLinkedProject] = useBoolean();
  const currentUser = useCurrentProjectUser();

  const esticonProject = useMemo(
    () => esticonProjectsState.data?.find((esticon) => esticon.id === project.esticonProjectId),
    [esticonProjectsState, project]
  );

  const handleDelete = useCallback(async () => {
    const [err] = await api.master.projects.deleteEsticonProjectLink(project.id, project.esticonProjectId, cancelToken);

    if (axios.isCancel(err)) {
      return;
    }

    if (err) {
      messageError(err, intl);
    } else {
      dispatch.activeProject.setActiveProject({ ...project, esticonFirmId: null, esticonProjectId: null });
      onDelete();
    }
  }, [project]);

  if (!project.esticonProjectId) {
    return null;
  }

  // TODO: use content gate?
  const content: ReactNode = esticonProjectsState.error ? (
    <Typography.Text type="danger">
      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.loadError" />
    </Typography.Text>
  ) : esticonProjectsState.loading ? (
    <Spin />
  ) : !esticonProject ? (
    !!esticonProjectsState.data ? (
      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectMissing" />
    ) : (
      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectNotSet" />
    )
  ) : (
    <>
      <Typography.Text>{`${esticonProject.name} (${esticonProject.sign})`}</Typography.Text>
    </>
  );

  return (
    <>
      <GeneralSettingsItem
        selected={listItemProps.selected}
        onClick={listItemProps.onClick}
        selectable
        icon={<EsticonIcon />}
        title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.listItemTitle" />}
        description={<div>{content}</div>}
        additionalActions={
          <>
            {listItemProps.additionalActions}
            {!!currentUser.isAdmin && <EditButton onClick={showEditLinkedProject} />}
            {!!currentUser.isAdmin && <DeleteButtonConfirm onConfirm={handleDelete} />}
          </>
        }
      />
      <EsticonCreate
        open={isEditLinkedProjectVisible}
        onAppAdded={hideEditLinkedProject}
        onCancel={hideEditLinkedProject}
      />
    </>
  );
};

export const loadEsticonListItems = async (project: ProjectHubDto): Promise<ExternAppListItem[]> => {
  if (!project.esticonProjectId) {
    return [];
  }

  return [
    {
      type: ExternalAppSourceTypeEnum.Project,
      appType: AvailableAppEnum.Esticon,
      project,
    },
  ];
};
