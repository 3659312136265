import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import { ActionButtonProps } from './ActionButton';

const DeleteButtonComponent: FunctionComponent<ActionButtonProps> = ({ children, onClick, disabled, ...restProps }) => {
  const intl = useIntl();
  return (
    <CommonHubTooltip title={intl.formatHTMLMessage({ id: 'general.delete' })}>
      <Button
        icon={<DeleteOutlined />}
        onClick={(event) => {
          event.stopPropagation();
          onClick?.();
        }}
        type="link"
        danger
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        {...restProps}
      >
        {children}
      </Button>
    </CommonHubTooltip>
  );
};

export const DeleteButton = React.memo(DeleteButtonComponent);
