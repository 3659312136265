import { FileImageOutlined } from '@ant-design/icons';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import ImageBox from 'components/ImageBox';
import React, { useCallback } from 'react';

export const ImageViewer: FileViewerComponent = ({ style, className, url, onLoad }) => {
  const handleOnLoad = useCallback(() => {
    onLoad && onLoad();
  }, [onLoad]);
  return (
    <ImageBox style={style} className={className}>
      <img onLoad={handleOnLoad} src={url} />
    </ImageBox>
  );
};

const supportedTypes = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'image/bmp',
  'image/vnd.microsoft.icon',
  'image/webp',
  'image/x-icon',
];

export const ImageViewerData: FileViewerProperties = {
  supportedTypes,
  icon: <FileImageOutlined />,
  titleTranslationId: 'ImageViewer.title',
  component: 'ImageViewer',
};

export default ImageViewer;
