import { Button, Col, Row, Typography } from 'antd';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LoginErrorPage.module.less';

const LoginErrorPage: FunctionComponent = () => {
  const history = useHistory();

  const handleRelogin = () => {
    history.replace(process.env.PUBLIC_URL);
  };

  return (
    <div className={styles.screen}>
      <Row justify="space-around">
        <Col>
          <h1>
            <Fmt id="LoginErrorPage.ErrorTitle" />
          </h1>
          <Typography.Paragraph>
            <Fmt id="LoginErrorPage.AccessDeniedMessage" />
          </Typography.Paragraph>
          <Button onClick={handleRelogin} type="primary">
            <Fmt id="LoginErrorPage.Button.ReturnToLogin" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LoginErrorPage;
