import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ActivityIcon } from 'components/Icons/HubEntitiesIcons';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  onClick: () => void;
};

const AuditLogOpenButtonComponent: FunctionComponent<Props> = ({ onClick }) => {
  return (
    <CommonHubTooltip title={<Fmt id="general.activity" />} placement="topRight">
      <Button type="link" icon={<ActivityIcon />} onClick={onClick} />
    </CommonHubTooltip>
  );
};

export const AuditLogOpenButton = React.memo(AuditLogOpenButtonComponent);
