import { to } from 'api/await-to';
import {
  AssignmentDto,
  AssignmentListForExportRequestDto,
  MsgCenterAssignmentListRequestDto,
  MsgCenterAssignmentResponseDto,
  MsgCenterDefaultSettingsDto,
  MsgCenterIntervalListRequestDto,
  MsgCenterIntervalResponseDto,
  MsgCenterListRequestDto,
  MsgCenterMessageDto,
  MsgCenterProjectSettingsDto,
  MsgCenterProjectSettingsPatchDto,
  MsgCenterResponseDto,
  MsgCenterSetStatusDto,
  MsgCenterSummaryDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseMasterApi } from '../baseMasterApi';

export const messageCenterApi = {
  getDefaultSettings: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<MsgCenterDefaultSettingsDto>(`/projects/msgcenter/getsettings`, { cancelToken })
    ),
  getProjectSettings: (projectId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<MsgCenterProjectSettingsDto>(`/projects/msgcenter/${projectId}/getsettings`, { cancelToken })
    ),
  getSummary: (cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<MsgCenterSummaryDto>(`/projects/msgcenter/getsummary`, { cancelToken })
    ),
  setMessageSettings: (settings: MsgCenterProjectSettingsPatchDto, cancelToken?: CancelToken) =>
    to(baseMasterApi.patch(`/projects/msgcenter/setsettings`, settings, { cancelToken })),
  getPagedMessages: (request: MsgCenterListRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<MsgCenterResponseDto>(`/projects/msgcenter`, request, { cancelToken })
    ),
  getIntervalMessage: (request: MsgCenterIntervalListRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<MsgCenterIntervalResponseDto>(`/projects/msgcenter/interval`, request, { cancelToken })
    ),
  setStatus: (settings: MsgCenterSetStatusDto, cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`/projects/msgcenter/setStatus`, settings, { cancelToken })),
  setAllStatusesAsRead: (cancelToken?: CancelToken) =>
    to(baseMasterApi.post(`/projects/msgcenter/setallasreaded`, { cancelToken })),
  listAssignments: (request: MsgCenterAssignmentListRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<MsgCenterAssignmentResponseDto>(`/projects/msgcenter/assignments`, request, { cancelToken })
    ),
  getAssignment: (projectId: Guid, assignmentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<AssignmentDto>(`/projects/${projectId}/assignments/${assignmentId}`, { cancelToken })
    ),
  getMessageDetail: (msgCenterMessageId: Guid, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.get<MsgCenterMessageDto>(`/projects/msgcenter/${msgCenterMessageId}`, { cancelToken })
    ),
  exportAssignments: (data: AssignmentListForExportRequestDto, cancelToken?: CancelToken) =>
    to(
      baseMasterApi.post<string>(`/projects/msgcenter/assignmentsreport`, data, { cancelToken, responseType: 'blob' })
    ),
};
