import { api } from 'api';
import { processApiError } from 'utils';
import { ServiceErrorEnum } from 'api/errors';
import { DirectoryForbiddenErrorData } from 'components/DirectoryForbiddenErrorBox/DirectoryForbiddenError';
import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from 'hooks';
import { DocumentDto, ServiceError } from 'api/completeApiInterfaces';

export const useLoadDocument = (documentId: Guid, setDirectory?: (directoryId: Guid) => void) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentDto | null>(null);
  const [error, setError] = useState<ServiceError | null>(null);

  const isMounted = useIsMounted();

  const loadDocument = useCallback(async (): Promise<DocumentDto> => {
    if (!documentId) {
      return Promise.reject();
    }

    const [err, res] = await api.project.documents.getDocumentById(documentId);
    if (!isMounted.current) {
      return Promise.reject();
    }

    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DirectoryInsufficientAccessLevelError) {
          const errorData = error.errorData as DirectoryForbiddenErrorData;
          const { directoryId } = errorData || {};
          if (!!directoryId) {
            setDirectory?.(directoryId);
          }
        }
        setDocument(null);
        setLoading(false);
        setError(error);
      });
      return Promise.reject(err);
    } else {
      setDocument(res.data);
      setLoading(false);
      setError(null);
      setDirectory?.(res.data.directoryId);
      return Promise.resolve(res.data);
    }
  }, [documentId, setDirectory]);

  const onSetDocument = useCallback((document: DocumentDto) => {
    if (isMounted.current) setDocument(document);
  }, []);

  useEffect(() => {
    void loadDocument();
  }, [documentId]);

  return {
    document,
    loadDocument,
    setDocument: onSetDocument,
    documentLoading: loading,
    documentError: error,
  } as const;
};
