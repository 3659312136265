import { mapValues } from 'lodash';

export type Mapper<T, U> = (item: T) => U;

export const identityMapper = <T>(item: T) => item;

export const arrayMapper = <T, U>(itemMapper: Mapper<T, U>): Mapper<T[], U[]> => (items) => items?.map(itemMapper);

type PropertyMapper<T extends object, U extends { [K in keyof T]: unknown }> = { [K in keyof T]: Mapper<T[K], U[K]> };
export const objectMapper = <T extends object, U extends { [K in keyof T]: unknown }>(
  propMapper: PropertyMapper<T, U>
): Mapper<T, U> => (obj) => mapValues(obj, (value: any, prop: keyof T) => propMapper[prop](value)) as any;
