import { message } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { masterApi } from 'api/completeApi';
import { DeleteButton } from 'components/ActionButtons';
import { useIntl, useIsMounted, useSameCallback } from 'hooks';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';

type Props = {
  disabled?: boolean;
  onSuccess?: () => void;
  buttonType?: ButtonType;
  organizationId: Guid;
  projectId: Guid;
};

export const CalendarSettingsDeleteButton: FunctionComponent<Props> = ({
  disabled,
  onSuccess,
  organizationId,
  projectId,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const isMounted = useIsMounted();
  const intl = useIntl();

  const onProjectCalendarDelete = useSameCallback(async () => {
    if (
      !(await modalConfirm({
        title: intl.formatMessage({ id: 'CalendarSettingsDeleteButton.confirmDelete.message' }),
      }))
    ) {
      return;
    }
    if (!isMounted.current) {
      return;
    }

    setLoading(true);

    const [err] = await masterApi.projects.admin.calendar.id.project.id.delete(organizationId, projectId);

    if (err) {
      messageError(err, intl);
    } else {
      onSuccess && onSuccess();
    }

    if (!isMounted.current) {
      return;
    }

    setLoading(false);

    message.success(intl.formatMessage({ id: 'general.deletedItemSuccessfully' }, { count: 1 }));
  });

  return <DeleteButton onClick={onProjectCalendarDelete} disabled={disabled} loading={loading} />;
};
