import { Modal } from 'antd';
import { EntityTypesEnum } from 'api/completeApiInterfaces';
import React, { ReactNode, useMemo } from 'react';
import AuditLogGridLoader, { createEntityAuditLogBuilder } from './AuditLogGridLoader';
import styles from './AuditLogModal.module.less';

const EMPTY_DEPS_LIST: React.DependencyList = [];

type Props = {
  visible: boolean;
  label: ReactNode;
  entityType: EntityTypesEnum;
  entityId: Guid;
  onOk: () => void;
  deps?: React.DependencyList; // TODO: make mandatory?
};

const AuditLogEntityModal: React.FunctionComponent<Props> = ({
  visible,
  label,
  entityType,
  entityId,
  onOk,
  deps = EMPTY_DEPS_LIST,
}) => {
  const requestBuilder = useMemo(() => createEntityAuditLogBuilder(entityType, entityId), [
    entityType,
    entityId,
    ...deps,
  ]);

  return (
    <Modal open={visible} title={label} centered footer={null} width={850} onCancel={onOk}>
      <div className={styles.gridBody}>
        <AuditLogGridLoader requestBuilder={requestBuilder} />
      </div>
    </Modal>
  );
};

export default AuditLogEntityModal;
