import React, { FunctionComponent } from 'react';

type Props = {
  className?: string;
};

const StopPropagationWrapper: FunctionComponent<Props> = ({ className, children }) => {
  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      {children}
    </div>
  );
};

export default StopPropagationWrapper;
