import React, { FunctionComponent } from 'react';
import styles from './FilterLabel.module.less';

type Props = {
  value: string;
};

const FilterLabelComponent: FunctionComponent<Props> = ({ value }) => {
  return value?.trim().length ? <div className={styles.label}>{value}</div> : null;
};

export const FilterLabel = React.memo(FilterLabelComponent);
