import { Layout } from 'antd';
import React, { FunctionComponent } from 'react';
import styles from './Footer.module.less';

const Footer: FunctionComponent = () => {
  const year = new Date().getFullYear();
  return <Layout.Footer className={styles.footer}>AspeHub® Copyright © {year} IBR Consulting, s.r.o.</Layout.Footer>;
};

export default Footer;
