import { projectApi } from 'api/completeApi';
import {
  AssignmentAttachmentCreateDto,
  AssignmentAttachmentCreateMultipleDto,
  AssignmentAttachmentDto,
  AssignmentDto,
} from 'api/completeApiInterfaces';
import { injectUploadIdToAttachments } from 'api/project/upload/uploadHelpers';
import { UploadData } from 'api/project/upload/uploadManager';
import { AttachmentList } from 'components/DiscussionAttachments/AttachmentList/AttachmentList';
import { DiscussionAttachmentCreateFormData } from 'components/forms/DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';

type Props = {
  assignmentAttachments: AssignmentAttachmentDto[];
  assignmentId: Guid;
  name: string;
  updateAssignment: React.Dispatch<React.SetStateAction<AssignmentDto>>; // (assignment: AssignmentDto) => void;
  canEdit?: boolean;
};

const AssignmentAttachments: FunctionComponent<Props> = ({
  assignmentAttachments,
  assignmentId,
  name,
  updateAssignment,
  canEdit,
}) => {
  const intl = useIntl();

  const createUploadData = (data: DiscussionAttachmentCreateFormData): UploadData<AssignmentDto> => {
    const attachments: AssignmentAttachmentCreateDto[] = [
      {
        name: data.name,
        uploadId: null,
      },
    ];
    const createDto: AssignmentAttachmentCreateMultipleDto = {
      attachments,
    };
    const onFinish = async (response: AssignmentDto) => updateAssignment(response);
    return {
      createSaveRequest: (data) =>
        projectApi.assignments.id.addattachments.post(assignmentId, injectUploadIdToAttachments(createDto, data)),
      onFinish,
    };
  };

  return (
    <AttachmentList<AssignmentDto>
      key={assignmentId}
      attachments={assignmentAttachments}
      getDiscardedAttachments={(ct) => projectApi.assignments.id.attachments.discarded.get(assignmentId, ct)}
      canAddAttachment={canEdit}
      onDiscardAttachment={(id) => projectApi.assignments.id.attachment.id.delete(assignmentId, id)}
      onRestoreAttachment={(id) => projectApi.assignments.id.attachment.id.restore.post(assignmentId, id)}
      onResponse={updateAssignment}
      getDownloadUrl={(id: Guid) => projectApi.assignments.id.attachment.id.download.get(assignmentId, id)}
      getDownloadSelected={(ids: Guid[]) =>
        projectApi.assignments.id.downloadattachments.post(assignmentId, {
          name: `${intl.formatMessage({ id: 'general.assignment' })}: ${name} - ${intl.formatMessage({
            id: 'general.attachments',
          })}`,
          ids,
        })
      }
      createUploadData={createUploadData}
      assignAttachment={(data) => projectApi.assignments.id.assignattachment.post(assignmentId, data)}
    />
  );
};

export default React.memo(AssignmentAttachments);
