import React, { FunctionComponent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import styles from './ResizerWidth.module.less';
import { MoreOutlined } from '@ant-design/icons';

type Props = {
  handleResize: (width: number) => void;
  sizesSteps?: number[];
  constrains: {
    minWidth: number;
    maxWidth: number;
  };
  contentRef?: React.RefObject<HTMLDivElement>;
};

const ResizerWidth: FunctionComponent<Props> = ({ handleResize, sizesSteps, contentRef, constrains }) => {
  const [mouseDown, setMouseDown] = useState(false);

  const distanceRef = React.useRef<number>(0);
  const startWidthRef = React.useRef<number>(0);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation();
      distanceRef.current += e.movementX;
      const widthIncrement = e.movementX;

      const width = contentRef.current?.offsetWidth;
      const newWidth = width + widthIncrement;
      const newWidthConstrained = Math.min(Math.max(newWidth, constrains.minWidth), constrains.maxWidth);
      const diff = startWidthRef.current + distanceRef.current;

      if (diff > constrains.minWidth && diff < constrains.maxWidth) {
        handleResize(newWidthConstrained);
      }
    };

    if (mouseDown) {
      window.addEventListener('pointermove', handleMouseMove);
    }

    return () => {
      if (mouseDown) {
        window.removeEventListener('pointermove', handleMouseMove);
      }
    };
  }, [mouseDown, handleResize]);

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      e.stopPropagation();
      distanceRef.current = 0;
      setMouseDown(false);
    };

    window.addEventListener('pointerup', handleMouseUp);

    return () => {
      window.removeEventListener('pointerup', handleMouseUp);
    };
  }, []);

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    distanceRef.current = 0;
    startWidthRef.current = contentRef.current?.offsetWidth || 0;
    setMouseDown(true);
  }, []);

  const [stepIndex, setStepIndex] = useState(0);

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    if (!!sizesSteps) {
      event.preventDefault();
      event.stopPropagation();
      setStepIndex((prevIndex) => (prevIndex + 1) % sizesSteps.length);
    }
  }, []);

  useEffect(() => {
    if (!!sizesSteps) {
      const newWidthIncrement = sizesSteps[stepIndex];
      handleResize(newWidthIncrement);
    }
  }, [stepIndex]);

  return (
    <>
      <div className={styles.resizer} onPointerDown={handleMouseDown} onDoubleClick={handleDoubleClick}>
        <MoreOutlined rotate={0} />
      </div>
    </>
  );
};

export default React.memo(ResizerWidth);
