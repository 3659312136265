import { CheckOutlined, ClockCircleOutlined, FieldTimeOutlined, TagOutlined } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { api } from 'api';
import {
  AssignmentDto,
  AssignmentPatchDto,
  AssignmentStateEnum,
  AssignmentTypeEnum,
  EntityTypesEnum,
} from 'api/completeApiInterfaces';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import { CommonHeader } from 'components/CommonHeader/CommonHeader';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { ActivityIcon, ProjectIcon } from 'components/Icons/HubEntitiesIcons';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import { SimpleOrderId } from 'components/SimpleOrderId/SimpleOrderId';
import StackPanel from 'components/StackPanel';
import { StyledBox } from 'components/StyledBox/StyledBox';
import { UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import produce from 'immer';
import { Fmt } from 'locale';
import { isEqual } from 'lodash';
import moment from 'moment';
import AssignmentAttachments from 'pages/MessageCenterPage/assignments/AssignmentDetail/AssignmentAttachments';
import { AssignmentDocumentsField } from 'pages/MessageCenterPage/assignments/AssignmentDetail/AssignmentFields/AssignmentDocumentsField';
import { useIsAfterDeadline } from 'pages/MessageCenterPage/assignments/useIsAfterDeadline';
import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import styles from './AssignmentDetail.module.less';
import { AssignmentAssociatesField } from './AssignmentFields/AssignmentAssociatesField';
import { AssignmentContentField } from './AssignmentFields/AssignmentContentField';
import { AssignmentDeadlineField } from './AssignmentFields/AssignmentDeadlineField';
import { AssignmentDiscussionField } from './AssignmentFields/AssignmentDiscussionField';
import { AssignmentNameField } from './AssignmentFields/AssignmentNameField';
import { AssignmentSolversField } from './AssignmentFields/AssignmentSolversField';
import { AssignmentStateField } from './AssignmentFields/AssignmentStateField';
import { AssignmentModelLink } from './AssignmentModelLink';

type Props = {
  assignment: AssignmentDto;
  onAssignmentUpdated: (assignment: AssignmentDto | null) => void;
  isCorrectProject: boolean;
  projectName: string;
  setAssignment: React.Dispatch<React.SetStateAction<AssignmentDto>>;
};

export type AssignmentData = {
  name: string;
  content: string;
  deadlineDate: IsoDateTime;
  taskGiverId: Guid;
};

const assignmentDtoToData = (assignment: AssignmentDto): AssignmentData => ({
  name: assignment.name,
  content: assignment.content,
  deadlineDate: assignment.deadlineDate,
  taskGiverId: assignment.taskGiver?.id,
});

const assignmentDataToDto = (assignment: AssignmentData): AssignmentPatchDto => ({
  name: assignment.name,
  type: undefined,
  content: assignment.content,
  deadlineDate: assignment.deadlineDate,
  taskGiverId: assignment.taskGiverId,
});

type ResetAction = {
  type: 'reset';
  value: AssignmentData;
};

type SetNameAction = {
  type: 'setName';
  name: string;
};

type SetContentAction = {
  type: 'setContent';
  content: string;
};

type SetDeadlineDateAction = {
  type: 'setDeadlineDate';
  deadlineDate: IsoDateTime;
};

export type AssignmentDataAction = ResetAction | SetNameAction | SetContentAction | SetDeadlineDateAction;

const assignmentDataReducer = (state: AssignmentData, action: AssignmentDataAction) => {
  switch (action.type) {
    case 'reset':
      return action.value;
    case 'setName':
      return produce(state, (draft) => {
        draft.name = action.name;
      });
    case 'setContent':
      return produce(state, (draft) => {
        draft.content = action.content;
      });
    case 'setDeadlineDate':
      return produce(state, (draft) => {
        draft.deadlineDate = action.deadlineDate;
      });
  }
};

export const AssignmentDetail: FunctionComponent<Props> = ({
  assignment,
  onAssignmentUpdated,
  isCorrectProject,
  projectName,
  setAssignment,
}) => {
  const originalAssignment = useMemo(() => assignmentDtoToData(assignment), [assignment]);
  const [editedAssignment, dispatchEditedAssignment] = useReducer(assignmentDataReducer, originalAssignment);
  const isDirty = useMemo(() => !isEqual(originalAssignment, editedAssignment), [originalAssignment, editedAssignment]);
  const [auditLogVisible, showAuditLog, hideAuditLog] = useBoolean();
  const assignments = useMemo(() => [assignment], [assignment]);

  // reset value on assignment change - otherwise old name could stay in new assignment for example
  useEffect(() => {
    dispatchEditedAssignment({ type: 'reset', value: originalAssignment });
  }, [assignment.id]);

  const currentProjectUserId = useCurrentProjectUser()?.id;
  const isAuthor =
    currentProjectUserId === assignment.createdBy.id ||
    assignment.associates.some((associate) => associate.id === currentProjectUserId) ||
    assignment.taskGiver?.id == currentProjectUserId;
  const isSolver = assignment.solvers.some((solver) => solver.id === currentProjectUserId);

  const isClosed = assignment.state === AssignmentStateEnum.closed || assignment.state === AssignmentStateEnum.solved;

  const intl = useIntl();
  const [isSaving, setSaving] = useState(false);

  const saveChanges = async () => {
    const data = assignmentDataToDto(editedAssignment);

    setSaving(true);
    const [err, resp] = await api.project.assignments.updateAssignment(assignment.id, data);
    setSaving(false);

    if (err) {
      messageError(err, intl);
    } else {
      onAssignmentUpdated(resp.data);
    }
  };

  const { url } = useRouteMatch();
  const isAfterDeadline = useIsAfterDeadline(assignment);
  const discardChanges = () => dispatchEditedAssignment({ type: 'reset', value: originalAssignment });

  return (
    <>
      <MasterComponent
        url={url}
        title={assignment?.name}
        maxWidth={null}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical scrollable className={styles.container}>
            <StackPanel vertical autoHeight>
              <GeneralSettingsContainer itemsLargeGap>
                <GeneralSettingsContainer>
                  <StyledBox>
                    <CommonHeader
                      padding="all"
                      title={
                        <>
                          <SimpleOrderId orderId={assignment.orderId} />
                          <div className={styles.assignmentName}>
                            <AssignmentNameField
                              canEdit={isAuthor}
                              assignment={editedAssignment}
                              dispatchAssignment={dispatchEditedAssignment}
                            />
                          </div>
                        </>
                      }
                      buttons={
                        <Space>
                          {assignment.type === AssignmentTypeEnum.bcfTask && !!assignment.documentAnnotation && (
                            <AssignmentModelLink documentAnnotation={assignment.documentAnnotation} />
                          )}
                          <AssignmentStateField
                            assignment={assignment}
                            updateAssignment={onAssignmentUpdated}
                            isAuthor={isAuthor}
                            isSolver={isSolver}
                          />
                          <SaveOrCancelButton
                            onSave={saveChanges}
                            onCancel={discardChanges}
                            disabled={!isDirty}
                            loading={isSaving}
                          />
                        </Space>
                      }
                    />

                    <FlowLayout wrap className={styles.projectName}>
                      <Typography.Text>
                        <ProjectIcon /> {projectName}
                      </Typography.Text>

                      <Tag>
                        <TagOutlined /> <Fmt id={`AssignmentTypeEnum.${assignment.type}`} />
                      </Tag>
                    </FlowLayout>
                  </StyledBox>
                  <div className={styles.itemsContainer}>
                    <GeneralSettingsItem
                      title={<Fmt id="AssignmentSourceEnum.taskGiver" />}
                      icon={<UserIcon user={assignment.taskGiver || assignment.createdBy} />}
                      description={
                        <Typography.Text ellipsis>
                          {assignment.taskGiver?.username || assignment.createdBy.username}
                        </Typography.Text>
                      }
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="MessageCenterAssignments.createdDate" />}
                      icon={<ClockCircleOutlined />}
                      description={moment(assignment.createdDate)
                        .locale(intl.locale)
                        .format('LL')}
                    />
                  </div>
                  <div className={styles.itemsContainer}>
                    <GeneralSettingsItem
                      title={
                        <Typography.Text strong type={isAfterDeadline ? 'danger' : undefined}>
                          <Fmt id="MessageCenterAssignments.deadlineDate" />:
                        </Typography.Text>
                      }
                      icon={<FieldTimeOutlined />}
                      description={
                        <AssignmentDeadlineField
                          isAfterDeadline={isAfterDeadline}
                          canEdit={isAuthor && !isClosed}
                          assignment={editedAssignment}
                          dispatchAssignment={dispatchEditedAssignment}
                        />
                      }
                    />
                    {assignment.resolveDate && (
                      <GeneralSettingsItem
                        title={<Fmt id="MessageCenterAssignments.resolvedDate" />}
                        icon={<CheckOutlined />}
                        description={moment(assignment.resolveDate)
                          .locale(intl.locale)
                          .format('LL')}
                      />
                    )}
                  </div>
                </GeneralSettingsContainer>

                <GeneralSettingsContainer title={<Fmt id="AssignmentForm.content" />}>
                  <AssignmentContentField
                    canEdit={isAuthor && !isClosed}
                    assignment={editedAssignment}
                    dispatchAssignment={dispatchEditedAssignment}
                  />
                </GeneralSettingsContainer>

                {(!isClosed || !!assignment.associates?.length) && (
                  <AssignmentAssociatesField
                    canEdit={isAuthor && !isClosed}
                    assignment={assignment}
                    updateAssignment={onAssignmentUpdated}
                  />
                )}

                <AssignmentSolversField
                  canEdit={(isAuthor || isSolver) && !isClosed}
                  canSetPrimarySolver={(isAuthor || isSolver) && !isClosed}
                  canSendUserNotification={isAuthor && !isClosed}
                  assignment={assignment}
                  updateAssignment={onAssignmentUpdated}
                />

                {isCorrectProject && (
                  <GeneralSettingsContainer>
                    <GeneralSettingsItem
                      title={
                        <>
                          <Fmt id="general.discussion" /> ({assignment?.assignmentNotes?.length})
                        </>
                      }
                      onClick={() => {
                        onClick('discussion');
                      }}
                      selected={selectedItemId === 'discussion'}
                      selectable
                    />
                    <GeneralSettingsItem
                      title={
                        <>
                          <Fmt id="general.documents" /> ({assignment?.documents?.length})
                        </>
                      }
                      onClick={() => {
                        /* redirect to discussion page */
                        onClick('documents');
                      }}
                      selected={selectedItemId === 'documents'}
                      selectable
                    />
                    <GeneralSettingsItem
                      title={
                        <>
                          <Fmt
                            id="general.attachmentsWithCount"
                            values={{ count: assignment.assignmentAttachments.length }}
                          />
                        </>
                      }
                      onClick={() => {
                        /* redirect to discussion page */
                        onClick('attachments');
                      }}
                      selected={selectedItemId === 'attachments'}
                      selectable
                    />
                  </GeneralSettingsContainer>
                )}
                <GeneralSettingsContainer>
                  <GeneralSettingsItem
                    title={<Fmt id="general.activity" />}
                    icon={<ActivityIcon />}
                    onClick={showAuditLog}
                    selectable
                  />
                </GeneralSettingsContainer>
              </GeneralSettingsContainer>

              <AuditLogEntityModal
                entityType={EntityTypesEnum.assignment}
                entityId={assignment.id}
                label={<Fmt id="AuditLog.assignment.label" values={{ name: assignment.name }} />}
                onOk={hideAuditLog}
                visible={auditLogVisible}
                deps={assignments}
              />
            </StackPanel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/discussion`}
          render={(props) => (
            <MasterComponent
              key={'discussion'}
              url={props.match.url}
              title={intl.formatMessage({ id: 'general.discussion' })}
              maxWidth={null}
              children={() => (
                <AssignmentDiscussionField
                  canEdit={(isAuthor || isSolver) && !isClosed}
                  assignment={assignment}
                  updateAssignment={onAssignmentUpdated}
                />
              )}
            />
          )}
        />
        <Route
          path={`${url}/documents`}
          render={(props) => (
            <MasterComponent
              key="documents"
              url={props.match.url}
              title={intl.formatMessage({ id: 'general.documents' })}
              maxWidth={null}
              children={() => (
                <AssignmentDocumentsField
                  canEdit={(isAuthor || isSolver) && !isClosed}
                  assignment={assignment}
                  updateAssignment={onAssignmentUpdated}
                />
              )}
            />
          )}
        />
        <Route
          path={`${url}/attachments`}
          render={(props) => (
            <MasterComponent
              key="attachments"
              url={props.match.url}
              title={intl.formatMessage({ id: 'general.attachments' })}
              maxWidth={null}
              children={() => (
                <AssignmentAttachments
                  assignmentAttachments={assignment.assignmentAttachments.map((a) => ({
                    ...a,
                    canUserEdit: a.canEdit,
                  }))}
                  assignmentId={assignment.id}
                  name={assignment.name}
                  updateAssignment={setAssignment}
                  canEdit={(isAuthor || isSolver) && !isClosed}
                />
              )}
            />
          )}
        />
      </Switch>
    </>
  );
};
