import { api } from 'api';
import { useSameCallback } from 'hooks';
import { useEffect, useState } from 'react';

export const useLoadCommentProcedure = (commentProcedureId: string) => {
  const [commentProcedure, setCommentProcedure] = useState(null);

  const loadCommentProcedure = useSameCallback(async () => {
    if (!commentProcedureId) return;

    const [err, res] = await api.project.commentProcedures.getCommentProceduresById(commentProcedureId);
    if (err) {
      return;
    }
    setCommentProcedure(res.data);

    return;
  });

  useEffect(() => {
    if (commentProcedureId) {
      void loadCommentProcedure();
    }
  }, [loadCommentProcedure, commentProcedureId]);

  return { commentProcedure, loadCommentProcedure };
};
