import { userManager } from 'config';
import LoadingPage from 'pages/LoadingPage';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

class OidcPopupCallbackPage extends Component<Props> {
  componentDidMount() {
    userManager.signinPopupCallback();
  }

  render() {
    return <LoadingPage />;
  }
}

export default OidcPopupCallbackPage;
