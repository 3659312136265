import { createFileTreeNode, onDropHandler } from 'components/PrimaryFileInput/CommonFilesInputUtils';
import { DropArea } from 'components/PrimaryFileInput/DropArea';
import { useSameCallback } from 'hooks';
import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { message } from 'antd';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';

type Props = {
  className?: string;
  onDrop?: (data: FileSystemTreeNode[]) => void;
  multiple?: any;
  disabled?: boolean;
  disabledTooltip?: string;
  disableTreeStructure?: boolean;
  title?: ReactNode;
};

const PageLevelDropzone: FunctionComponent<Props> = ({
  children,
  title,
  onDrop: onDropProp,
  multiple,
  className,
  disabled,
  disabledTooltip,
  ...rest
}) => {
  const disableTreeStructure = false;

  const onDrop = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (disabled) {
      if (disabledTooltip) {
        message.error(disabledTooltip);
      }
      return;
    }
    if (event.dataTransfer) {
      const [result, rootErrors] = await onDropHandler(event, disableTreeStructure);
      onDropProp?.(result);
    }
  };

  const onChangeInput = useSameCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.currentTarget?.files;
    if (files && files.length > 0) {
      const data = Object.values(files).map((f) => createFileTreeNode(f));
      onDropProp?.(data);
    }
  });

  return (
    <DropArea
      {...rest}
      onDrop={onDrop}
      inputRef={undefined}
      title={title}
      onChangeInput={onChangeInput}
      multiple={multiple}
      disabled={disabled}
      tooltip={disabledTooltip}
    >
      {children}
    </DropArea>
  );
};

export default PageLevelDropzone;
