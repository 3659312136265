import { message } from 'antd';
import { MsgCenterSummaryDto, ServiceError } from 'api/completeApiInterfaces';
import { SIGNAL_R_REDUX_STORE_CONNECTION } from 'config';
import { useCurrentAppUser, useIntl, useStoreSelector } from 'hooks';
import { useAggregateEvent } from 'hooks/useAggregateEvent';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { max } from 'lodash';
import React, { FunctionComponent, createContext, useCallback, useEffect, useMemo } from 'react';

type ValueType = {
  totalNewMessages: number;
  totalNewAssignments: number;
  totalInProgressAssignments: number;
  totalForApprovalAssignments: number;
  summary: MsgCenterSummaryDto;
  summaryLoading: boolean;
  summaryError: ServiceError;
};

type SummaryChanges = {
  authorId: Guid;
  messages: number;
  newAssignments: number;
  inProgressAssignments: number;
  forApprovalAssignments: number;
};

const MESSAGE_CENTER_UPDATE_DEBOUNCE_MS = 1000;

export const NotificationContext = createContext<ValueType>(undefined);

export const NotificationContextProvider: FunctionComponent = ({ children }) => {
  const intl = useIntl();
  const { data: summary, loading: summaryLoading, error: summaryError } = useStoreSelector(
    (store) => store.userSummary
  );
  useDirtyStoreReload(
    (state) => state.userSummary,
    (dispatch) => dispatch.userSummary
  );
  const currentAppUser = useCurrentAppUser();

  const handleNotificationMessage = useCallback(
    async (summaries: SummaryChanges[]) => {
      const filteredSummaries = summaries.filter((newSummary) => newSummary.authorId !== currentAppUser.id);
      const newMessagesCount = filteredSummaries.reduce(
        (maxValue, newSummary) => max([maxValue, newSummary.messages, 0]),
        0
      );
      const newAssignmentsCount = filteredSummaries.reduce(
        (maxValue, newSummary) => max([maxValue, newSummary.newAssignments, 0]),
        0
      );
      const inProgressAssignmentsCount = filteredSummaries.reduce(
        (maxValue, newSummary) => max([maxValue, newSummary.inProgressAssignments, 0]),
        0
      );
      const forApprovalAssignmentsCount = filteredSummaries.reduce(
        (maxValue, newSummary) => max([maxValue, newSummary.forApprovalAssignments, 0]),
        0
      );
      const hasUpdatedAssignments = inProgressAssignmentsCount > 0 || forApprovalAssignmentsCount > 0;

      const messages = [];

      if (newMessagesCount > 0) {
        messages.push(
          intl.formatMessage({ id: 'NotificationContext.updateMessage.messages' }, { count: newMessagesCount })
        );
      }
      if (newAssignmentsCount > 0) {
        messages.push(
          intl.formatMessage({ id: 'NotificationContext.updateMessage.newAssignments' }, { count: newAssignmentsCount })
        );
      }
      if (hasUpdatedAssignments) {
        messages.push(
          intl.formatMessage(
            { id: 'NotificationContext.updateMessage.updatedAssignments' },
            { count: inProgressAssignmentsCount + forApprovalAssignmentsCount }
          )
        );
      }

      if (messages.length > 0) {
        await message.info(
          intl.formatMessage(
            { id: 'NotificationContext.updateMessage.notification' },
            {
              count: messages.length,
              message: messages.splice(messages.length - 1, 1).join(','),
              lastMessage: messages[messages.length - 1],
            }
          )
        );
      }
    },
    [currentAppUser, intl]
  );

  const totalNewMessages = useMemo(
    () =>
      !summaryLoading && !!summary?.messages
        ? Object.values(summary.messages).reduce((acc, value) => acc + value, 0)
        : 0,
    [summary?.messages, summaryLoading]
  );

  const totalNewAssignments = useMemo(
    () =>
      !summaryLoading && !!summary?.assigments
        ? Object.values(summary.assigments).reduce((acc, value) => acc + value.new, 0)
        : 0,
    [summary?.assigments, summaryLoading]
  );

  const totalForApprovalAssignments = useMemo(
    () =>
      !summaryLoading && !!summary?.assigments
        ? Object.values(summary.assigments).reduce((acc, value) => acc + value.forApproval, 0)
        : 0,
    [summary?.assigments, summaryLoading]
  );

  const totalInProgressAssignments = useMemo(
    () =>
      !summaryLoading && !!summary?.assigments
        ? Object.values(summary.assigments).reduce((acc, value) => acc + value.inProgress, 0)
        : 0,
    [summary?.assigments, summaryLoading]
  );

  const mapNotificationData = useCallback(
    (userId: Guid, newSummary: MsgCenterSummaryDto) => {
      const newMessagesCount =
        Object.values(newSummary.messages).reduce((acc, value) => acc + value, 0) - totalNewMessages;
      const newAssignmentsCount =
        Object.values(newSummary.assigments).reduce((acc, value) => acc + value.new, 0) - totalNewAssignments;
      const inProgressAssignmentsCount =
        Object.values(newSummary.assigments).reduce((acc, value) => acc + value.inProgress, 0) -
        totalInProgressAssignments;
      const forApprovalAssignmentsCount =
        Object.values(newSummary.assigments).reduce((acc, value) => acc + value.forApproval, 0) -
        totalForApprovalAssignments;
      return {
        authorId: userId,
        messages: newMessagesCount,
        newAssignments: newAssignmentsCount,
        inProgressAssignments: inProgressAssignmentsCount,
        forApprovalAssignments: forApprovalAssignmentsCount,
      };
    },
    [totalForApprovalAssignments, totalInProgressAssignments, totalNewAssignments, totalNewMessages]
  );

  const MessageCentrumStatisticsChanged = useAggregateEvent(
    handleNotificationMessage,
    MESSAGE_CENTER_UPDATE_DEBOUNCE_MS,
    mapNotificationData
  );

  useEffect(() => {
    SIGNAL_R_REDUX_STORE_CONNECTION.on('MessageCentrumStatisticsChanged', MessageCentrumStatisticsChanged);
    return () => {
      SIGNAL_R_REDUX_STORE_CONNECTION.off('MessageCentrumStatisticsChanged', MessageCentrumStatisticsChanged);
    };
  }, [MessageCentrumStatisticsChanged]);

  const values: ValueType = useMemo(
    () => ({
      totalNewMessages,
      totalNewAssignments,
      totalInProgressAssignments,
      totalForApprovalAssignments,
      summary,
      summaryLoading,
      summaryError,
    }),
    [
      totalNewMessages,
      totalNewAssignments,
      totalInProgressAssignments,
      totalForApprovalAssignments,
      summary,
      summaryLoading,
      summaryError,
    ]
  );

  return <NotificationContext.Provider value={values}>{children}</NotificationContext.Provider>;
};
