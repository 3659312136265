import { AssignmentAnnotationSaveDto, AssignmentDto } from 'api/completeApiInterfaces';
import { BcfTopic } from './bcfTopic';

export enum BcfVersion {
  BVF_V1_0 = '1.0',
  BVF_V2_0 = '2.0',
  BVF_V2_1 = '2.1',
  BVF_V3_0 = '3.0',
}

export type BcfFile = {
  version: BcfVersion;
  topics: BcfTopic[];
};

export type BCFAssignmentWithTopic = AssignmentDto & {
  id?: Guid;
  topic: BcfTopic;
};

export type AssignmentAnnotationSaveWithTopic = AssignmentAnnotationSaveDto & {
  topic: BcfTopic;
}

export type BCFAssignmentData = {
  version: BcfVersion;
  assignments: BCFAssignmentWithTopic[];
}

export const newBcfFile = (): BCFAssignmentData => {
  return {
    version: BcfVersion.BVF_V3_0,
    assignments: [],
  };
};
