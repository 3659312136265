import { WarningOutlined } from '@ant-design/icons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { DEV_STATUS_TEXT, NODE_ENV } from 'config/env';
import React, { FunctionComponent } from 'react';

export const DevelopmentStatusText: FunctionComponent = () => {
  if (NODE_ENV === 'production') return null;
  if (!DEV_STATUS_TEXT?.length) return null;
  return (
    <FlowLayout>
      <WarningOutlined />
      <span>{DEV_STATUS_TEXT}</span>
    </FlowLayout>
  );
};
