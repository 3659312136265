import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import numeral from 'numeral';
import React, { FunctionComponent } from 'react';
import { FormattedRelative } from 'react-intl';
import styles from './DocumentRow.Date.module.less';

export type DocumentRowProps = {
  date?: string;
  tooltip?: string;
  documentTooltip?: string;
  documentLinkTooltip?: string;
  size?: number;
};

export const DocumentRowDate: FunctionComponent<DocumentRowProps> = ({
  date,
  tooltip,
  documentTooltip,
  documentLinkTooltip,
  size,
}) => {
  return (
    <div className={styles.parameters}>
      {date && (
        <CommonHubTooltip
          placement="leftTop"
          title={
            <>
              <div>{tooltip}</div>
              <div>{documentTooltip}</div>
              <div>{documentLinkTooltip}</div>
            </>
          }
        >
          <span>
            {/* span is needful for the tooltip */}
            <FormattedRelative value={date} />
          </span>
        </CommonHubTooltip>
      )}
      {size !== undefined && (
        <div>
          <span>{numeral(size).format('0.0 b')}</span>
        </div>
      )}
    </div>
  );
};

export default DocumentRowDate;
