export const fulfillVersion = (browserVersion: string, version: number): boolean => {
  if (!browserVersion) return true;
  const browserVersionSplited = browserVersion.split('.', 1);
  if (browserVersionSplited.length > 0) {
    const versionNumber = parseInt(browserVersionSplited[0]);
    if (isNaN(versionNumber)) {
      return true;
    }
    return versionNumber >= version;
  } else {
    return false;
  }
};
