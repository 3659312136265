import React, { createContext, FC } from 'react';
import { usePdfTronLicenseKey } from 'pages/DocumentDetailPage/features/usePdfTronLicenseKey';

type PdfTronLicenceKeyContextType = {
  licenseKey: string | undefined;
};

export const PdfTronLicenceKeyContext = createContext<PdfTronLicenceKeyContextType>({
  licenseKey: undefined,
});

export default PdfTronLicenceKeyContext;

export const usePdfTronLicenceKeyContext = () => {
  const context = React.useContext(PdfTronLicenceKeyContext);
  if (!context) {
    throw new Error('usePdfTronLicenceKeyContext must be used within a PdfTronLicenceKeyContextProvider');
  }
  return context;
};

export const PdfTronLicenceKeyContextProvider: FC = ({ children }) => {
  const [pdfTronLicenseKey] = usePdfTronLicenseKey();

  return (
    <PdfTronLicenceKeyContext.Provider value={{ licenseKey: pdfTronLicenseKey }}>
      {children}
    </PdfTronLicenceKeyContext.Provider>
  );
};
