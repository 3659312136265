export const commonPrefix = (text1: string, text2: string): string => {
  if (!text1 || !text2) return '';
  let i = 0;
  while (i < text1.length && i < text2.length && text1[i] == text2[i]) i++;
  return text1.slice(0, i);
};

export const commonPrefixAll = (texts: string[]): string => {
  if (!texts?.length) return '';
  return texts.reduce(commonPrefix);
};
