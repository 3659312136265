import { ContentGate } from 'components/ContentGate/ContentGate';
import { useStoreSelector } from 'hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

const ProjectModuleGate: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    // first call to backend must be finished before any other call can be executed to prevent concurency database update on BE
    dispatch.currentProjectUser.loadData({ reload: false });
  }, []);

  const currentProjectUserState = useStoreSelector((state) => state.currentProjectUser);

  const ready = !!currentProjectUserState.data;

  return (
    <ContentGate
      error={currentProjectUserState.error}
      loading={currentProjectUserState.loading}
      loadingAlternative="" //Cannot display loading text because translation language is not set up yet
      overlay
    >
      {ready && children}
    </ContentGate>
  );
};

export default ProjectModuleGate;
