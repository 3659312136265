import {
  AssignmentListForExportRequestDto,
  AssignmentMessageDto,
  LanguageEnum,
  ProjectListDto,
  ProjectListForExportRequestDto,
  ResultFileFormatEnum,
} from 'api/completeApiInterfaces';
import moment from 'moment';
import { InjectedIntl } from 'react-intl';

export enum ExportEntityTypeEnum {
  projects = 'projects',
  assignments = 'assignments',
}

export const assignmentsColumnNames = (intl: InjectedIntl) => {
  return {
    nameColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.name' }),
    projectNameColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.projectName' }),
    typeColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.type' }),
    stateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.state' }),
    createdDateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.createDate' }),
    taskGiverColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.taskGiver' }),
    associateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.representative' }),
    deadlineDateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.deadline' }),
    resolveDateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.resolvedDate' }),
    contentColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.content' }),
    solverColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.resolvers' }),
  };
};

export const projectsColumnNames = (intl: InjectedIntl) => {
  return {
    nameColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.name' }),
    descriptionColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.description' }),
    organizationNameColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.organization' }),
    createdDateColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.createDate' }),
    labelsColDesc: intl.formatMessage({ id: 'CommonExportFormModal.columnName.labels' }),
  };
};

export const MIME_TYPE_MAP: Record<ResultFileFormatEnum, string> = {
  [ResultFileFormatEnum.excel]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [ResultFileFormatEnum.Word]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [ResultFileFormatEnum.pdf]: 'application/pdf',
};

export const EXTENSIONS_MAP: Record<ResultFileFormatEnum, string> = {
  [ResultFileFormatEnum.excel]: 'xlsx',
  [ResultFileFormatEnum.Word]: 'docx',
  [ResultFileFormatEnum.pdf]: 'pdf',
};

export const mapAssignmentsDataToDto = (
  assignments: AssignmentMessageDto[],
  intl: InjectedIntl,
  exportFileType: ResultFileFormatEnum
): AssignmentListForExportRequestDto => {
  return {
    resultFileFormat: exportFileType,
    ...assignmentsColumnNames(intl),
    assignmentDatas: assignments.map((assignment) => ({
      id: assignment.id,
      projectId: assignment.projectId,
      typ: intl.formatMessage({ id: `AssignmentTypeEnum.${assignment.type}` }) || '',
      state: intl.formatMessage({ id: `AssignmentStateEnum.${assignment.state}` }) || '',
      createdDate: !!assignment.createdDate
        ? moment(assignment.createdDate)
            .locale(intl.locale)
            .format('LL')
        : '',
      deadlineDate: !!assignment.deadlineDate
        ? moment(assignment.deadlineDate)
            .locale(intl.locale)
            .format('LL')
        : '',
      resolveDate: !!assignment.resolveDate
        ? moment(assignment.resolveDate)
            .locale(intl.locale)
            .format('LL')
        : '',
    })),
    language: intl.locale as LanguageEnum,
  };
};

export const mapProjectsDataToDto = (
  projects: ProjectListDto[],
  intl: InjectedIntl,
  exportFileType: ResultFileFormatEnum
): ProjectListForExportRequestDto => {
  return {
    resultFileFormat: exportFileType,
    ...projectsColumnNames(intl),
    projectDatas: projects.map((project) => ({
      projectId: project.id,
      name: project.name,
      description: project.description || '',
      organizationName: project.organization.name,
      labels: project.labels || [],
      createdDate: !!project.createdDate
        ? moment(project.createdDate)
            .locale(intl.locale)
            .format('LL')
        : '',
    })),
    language: intl.locale as LanguageEnum,
  };
};
