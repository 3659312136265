import { to } from 'api/await-to';
import {
  DocumentCategoryNodeCreateDto,
  DocumentCategoryNodeDto,
  DocumentCategoryNodePatchDto,
  DocumentCategoryNodeRemoveStrategyEnum,
  DocumentCategoryTreeCreateDto,
  DocumentCategoryTreeDto,
  DocumentCategoryTreePatchDto,
  DocumentCategoryTreeRemoveStrategyEnum,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const categoryTreesApi = {
  listCategoryTrees: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocumentCategoryTreeDto[]>(`/documents/categorytrees`, { cancelToken })
    ),
  createCategoryTree: (data: DocumentCategoryTreeCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentCategoryTreeDto>(`/documents/categorytrees`, data, { cancelToken })
    ),
  updateCategoryTreeById: (
    documentCategoryTreeId: Guid,
    data: DocumentCategoryTreePatchDto,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.patch<DocumentCategoryTreeDto>(`/documents/categorytrees/${documentCategoryTreeId}`, data, {
        cancelToken,
      })
    ),
  deleteCategoryTreeById: (
    documentCategoryTreeId: Guid,
    removeStrategy: DocumentCategoryTreeRemoveStrategyEnum,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.delete(`/documents/categorytrees/${documentCategoryTreeId}`, {
        cancelToken,
        params: { removeStrategy },
      })
    ),
  listCategoryNodes: (documentCategoryTreeId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocumentCategoryNodeDto[]>(`/documents/categorytrees/${documentCategoryTreeId}/nodes`, {
        cancelToken,
      })
    ),
  createCategoryNode: (documentCategoryTreeId: Guid, data: DocumentCategoryNodeCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentCategoryNodeDto>(`/documents/categorytrees/${documentCategoryTreeId}/nodes`, data, {
        cancelToken,
      })
    ),
  updateCategoryNodeById: (
    documentCategoryTreeId: Guid,
    documentCategoryNodeId: Guid,
    data: DocumentCategoryNodePatchDto,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.patch<DocumentCategoryNodeDto>(
        `/documents/categorytrees/${documentCategoryTreeId}/nodes/${documentCategoryNodeId}`,
        data,
        { cancelToken }
      )
    ),
  deleteCategoryNodeById: (
    documentCategoryTreeId: Guid,
    documentCategoryNodeId: Guid,
    removeStrategy?: DocumentCategoryNodeRemoveStrategyEnum,
    removeChildren: boolean = false,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.delete(`/documents/categorytrees/${documentCategoryTreeId}/nodes/${documentCategoryNodeId}`, {
        params: { removeStrategy, removeChildren },
        cancelToken,
      })
    ),
};
