import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LinkButton } from 'components/LinkButton/LinkButton';
import React, { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
import styles from './PreviousNextButtons.module.less';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { useIntl } from 'hooks';

type Props = {
  total: number;
  currentIndex: number;
  onSetIndex: (newIndex: number, oldIndex: number) => void;
};

export const PreviousNextButtons: React.FunctionComponent<Props> = ({ currentIndex, total, children, onSetIndex }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const [editing, setEditing] = useState(false);
  const [newInputValue, setNewInputValue] = useState(() => currentIndex + 1);

  const onStartEdit = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!editing) {
      event.preventDefault();
      setNewInputValue(currentIndex + 1);
      setEditing(true);
    }
  };

  const onSubmit = () => {
    onSetIndex(getIndexInBounds(newInputValue - 1, total), currentIndex);
    setEditing(false);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSubmit();
    }
  };

  useCloseOnEscape(editing, () => setEditing(false));

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  return (
    <>
      <LinkButton
        icon={<LeftOutlined />}
        onClick={() => onSetIndex(getIndexInBounds(currentIndex - 1, total), currentIndex)}
        title={intl.formatMessage({ id: 'general.previous' }) + ' (Shift + <)'}
      />
      <div className={styles.content} onClick={onStartEdit}>
        {!editing ? (
          <div>
            {currentIndex + 1} / {total}
          </div>
        ) : (
          <InputNumber
            ref={inputRef}
            onPressEnter={onPressEnter}
            addonAfter={` / ${total}`}
            value={newInputValue}
            className={styles.input}
            onBlur={onSubmit}
            onChange={(value) => {
              setNewInputValue(+value);
            }}
          />
        )}
      </div>
      {children}
      <LinkButton
        icon={<RightOutlined />}
        onClick={() => onSetIndex(getIndexInBounds(currentIndex + 1, total), currentIndex)}
        title={intl.formatMessage({ id: 'general.next' }) + ' (Shift + >)'}
      />
    </>
  );
};

export function getIndexInBounds(index: number, max: number, min: number = 0) {
  return index - min >= 0 ? ((index - min) % (max - min)) + min : ((index - min + 1) % (max - min)) + max - 1;
}
