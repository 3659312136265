import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { SortOrder } from 'api/completeApiInterfaces';
import classnames from 'classnames';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { SpinButtonOption } from 'components/SpinButton/SpinButton';
import { OrderOption, OrderValue } from 'components/filters/orderTypes';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';

const DIRECTION_OPTIONS: SpinButtonOption<SortOrder>[] = [
  {
    title: (
      <>
        <SortAscendingOutlined /> <Fmt id="general.sort.asc" />
      </>
    ),
    value: SortOrder.asc,
  },
  {
    title: (
      <>
        <SortDescendingOutlined /> <Fmt id="general.sort.desc" />
      </>
    ),
    value: SortOrder.desc,
  },
];

type Props<T extends string> = {
  orderOptions: OrderOption<T>[];
  order: OrderValue<T> | null;
  setOrder: React.Dispatch<React.SetStateAction<OrderValue<T>>>;
  className?: string;
  showTooltip?: boolean;
};

const OrderSelectComponent: FunctionComponent<Props<string>> = ({
  orderOptions,
  order,
  setOrder,
  className,
  showTooltip = false,
}) => {
  const handleSortChange = useCallback((key: string) => setOrder((prev) => ({ ...prev, key })), [setOrder]);

  const handleDescendingChange = useCallback((direction: SortOrder) => setOrder((prev) => ({ ...prev, direction })), [
    setOrder,
  ]);

  const inputRef = useRef<HTMLDivElement>();

  const getPopupContainer = useCallback(() => inputRef.current, []);

  const orderMenu = useMemo(() => {
    return {
      selectable: true,
      selectedKeys: [order?.key, order?.direction],
      items: [
        {
          key: 'sortBy',
          label: <Fmt id="general.sortBy" />,
          type: 'group',
          children: orderOptions.map((option) => ({
            key: option.key,
            label: option.label,
            onClick: () => handleSortChange(option.key),
          })),
        },
        {
          key: 'sortDirection',
          label: <Fmt id="general.sortDirection" />,
          type: 'group',
          children: DIRECTION_OPTIONS.map((option) => ({
            key: option.value,
            label: option.title,
            onClick: () => handleDescendingChange(option.value),
          })),
        },
      ],
    };
  }, [handleSortChange, orderOptions, order]);

  const content = (
    <div ref={inputRef}>
      <Dropdown
        className={classnames(className)}
        trigger={DROPDOWN_TRIGGER_CLICK}
        menu={orderMenu}
        getPopupContainer={getPopupContainer}
      >
        <Button icon={order?.direction === SortOrder.asc ? <SortAscendingOutlined /> : <SortDescendingOutlined />} />
      </Dropdown>
    </div>
  );

  if (!showTooltip) return content;

  return (
    <NoClickTooltip title={<Fmt id={'general.sortBy'} />} placement="topRight">
      {content}
    </NoClickTooltip>
  );
};

export const OrderSelect: <T extends string>(props: Props<T>) => JSX.Element = React.memo(OrderSelectComponent);
