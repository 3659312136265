import { BlobDerivateTypeEnum } from 'api/completeApiInterfaces';

export const derivatesContentTypeMap: { [key in BlobDerivateTypeEnum]: string } = {
  [BlobDerivateTypeEnum.ContentPlainText]: 'text/plain',
  [BlobDerivateTypeEnum.ThumbnailSmall]: 'image/png',
  [BlobDerivateTypeEnum.ThumbnailMedium]: 'image/png',
  [BlobDerivateTypeEnum.ThumbnailLarge]: 'image/png',
  [BlobDerivateTypeEnum.Pdf]: 'application/pdf',
  [BlobDerivateTypeEnum.Forge]: 'application/forge-viewer',
  [BlobDerivateTypeEnum.Xml]: 'application/xml',
  [BlobDerivateTypeEnum.Signature]: 'application/pdf',
  [BlobDerivateTypeEnum.ProcessedXhtml]: 'application/json',
};
