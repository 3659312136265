import { Typography } from 'antd';
import { OrganizationsAdminReportDto, ProjectUserProfileListDto, ServiceError } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { DEFAULT_SETTINGS_PANEL_WIDTH } from 'config/constants';
import { useMasterDetailView } from 'hooks/useMasterDetailView';
import { Fmt, InjectedIntlProps } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { textComparer } from 'utils/comparators';
import OrganizationList from '../Organizations/OrganizationsList';
import AspeHubUsersListPanel from './AspeHubUsersListPanel';

type Props = InjectedIntlProps & {
  organizationsAdminReport: OrganizationsAdminReportDto;
  loadOrganizationsAdminReport: () => void;
  organizationsAdminReportLoading: boolean;
  organizationsAdminReportError: ServiceError;
};

const AspeHubUsers: FunctionComponent<Props> = (props) => {
  const {
    organizationsAdminReport,
    loadOrganizationsAdminReport,
    organizationsAdminReportLoading,
    organizationsAdminReportError,
  } = props;

  const [selectedAppUserId, setSelectedAppUserId] = useState<Guid>(null);
  const [search, setSearch] = useState<string>('');

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const onBack = useCallback(() => {
    setSelectedAppUserId(undefined);
  }, []);

  const selectedUserOrganizations = useMemo(
    () =>
      organizationsAdminReport?.organizations.filter((organization) =>
        organization.organizationUsers.find((user) => user.appUserProfileId === selectedAppUserId)
      ),
    [organizationsAdminReport?.organizations, selectedAppUserId]
  );

  const userOrganizationListComponent = useMemo(
    () =>
      !!selectedAppUserId ? (
        <Panel panelWidth={800} hideToolbar>
          <OrganizationList
            data={selectedUserOrganizations || []}
            search={search}
            onClearSearch={clearSearch}
            title={<Fmt id={'OrganizationList.organizationByUserTitle'} />}
          />
        </Panel>
      ) : (
        undefined
      ),
    [clearSearch, search, selectedUserOrganizations]
  );

  const remappedAppUsers: ProjectUserProfileListDto[] = useMemo(() => {
    return organizationsAdminReport?.appUsers.map((appUser) => ({
      id: appUser.id,
      username: appUser.username,
      firstname: appUser.firstname,
      lastname: appUser.lastname,
      status: undefined,
      isConfirmed: !!appUser.lastAccessDate,
    }));
  }, [organizationsAdminReport?.appUsers]);

  const selectedAppUser: ProjectUserProfileListDto = useMemo(() => {
    return remappedAppUsers?.find((appUser) => appUser.id === selectedAppUserId);
  }, [organizationsAdminReport?.appUsers, selectedAppUserId]);

  const userList = useMemo(() => {
    return (
      organizationsAdminReport?.appUsers
        .map((admin) => ({
          ...admin,
          id: admin.id,
          appUserId: admin.id,
          status: undefined,
          isConfirmed: !!admin.lastAccessDate,
        }))
        .sort(textComparer.map((user) => user.username)) || []
    );
  }, [organizationsAdminReport?.appUsers]);

  const { tableWrapRef, title, children } = useMasterDetailView(
    <AspeHubUsersListPanel
      intl={props.intl}
      appUsers={[]}
      loading={organizationsAdminReportLoading}
      error={organizationsAdminReportError}
      selectedId={selectedAppUserId}
      onSelect={setSelectedAppUserId}
      reloadUsers={loadOrganizationsAdminReport}
      panelWidth={DEFAULT_SETTINGS_PANEL_WIDTH}
      userList={userList}
    />,
    userOrganizationListComponent,
    props.intl.formatMessage({ id: 'general.users' }),
    !!selectedAppUserId && (
      <>
        <UserIcon user={selectedAppUser} size={UserAvatarSize.Large} />{' '}
        <Typography.Text>{selectedAppUser?.username}</Typography.Text>
      </>
    ),
    onBack
  );

  return (
    <>
      <StackPanel vertical divRef={tableWrapRef}>
        <PageContent title={title}>{children}</PageContent>
      </StackPanel>
    </>
  );
};

export default injectIntl(AspeHubUsers);
