import { ServiceErrorEnum } from 'api/errors';
import { AuditLogEntityData } from 'components/AuditLogsComponents/EntityData';
import { RevisionMetaData } from 'components/DocumentRevisionsGrid/DocumentRevisionsGridMetadataTable';
import { Dictionary } from 'lodash';
import {
  SubWorkFlowDisplayData,
  WorkFlowDisplayData,
  WorkflowNodeDisplayData,
  WorkflowNodeEdgeDisplayData,
  WorkflowNodePortDisplayData,
} from 'pages/WorkflowTemplatePage/interfaces';

export enum AccessLevelEnum {
  none = 'none',
  read = 'read',
  write = 'write',
  admin = 'admin',
}

export enum AssignmentOrderTypeEnum {
  createTime = 'createTime',
  deadlineTime = 'deadlineTime',
  resolvedTime = 'resolvedTime',
  status = 'status',
  name = 'name',
  type = 'type',
  project = 'project',
  orderNum = 'orderNum',
  priority = 'priority',
}

export enum AssignmentSourceEnum {
  createdByUser = 'createdByUser',
  assignedToUser = 'assignedToUser',
  primarySolver = 'primarySolver',
  associate = 'associate',
  taskGiver = 'taskGiver',
}

export enum AssignmentStateEnum {
  new = 'new',
  inProgress = 'inProgress',
  forApproval = 'forApproval',
  solved = 'solved',
  closed = 'closed',
}

export enum AssignmentTypeEnum {
  userTask = 'userTask',
  userDirectoryPermission = 'userDirectoryPermission',
  uploadDocuments = 'uploadDocuments',
  comment = 'comment',
  defectSolving = 'defectSolving',
  bcfTask = 'bcfTask',
}

export enum AuditLogSessionOrderTypeEnum {
  sessionStart = 'sessionStart',
  sessionEnd = 'sessionEnd',
}

export enum BackgroundDocumentStateEnum {
  missed = 'missed',
  ready = 'ready',
  accepted = 'accepted',
  refused = 'refused',
  forConfirm = 'forConfirm',
}

export enum BankIdSignStateEnum {
  Inicialized = 'Inicialized',
  UploadingDocument = 'UploadingDocument',
  WaitingForSigning = 'WaitingForSigning',
  DownloadingDocument = 'DownloadingDocument',
  Finished = 'Finished',
  Closed = 'Closed',
  Discarded = 'Discarded',
}

export enum BlobDerivateStatusEnum {
  Ok = 'Ok',
  Error = 'Error',
  Processing = 'Processing',
  OnDemand = 'OnDemand',
}

export enum BlobDerivateTypeEnum {
  ContentPlainText = 'ContentPlainText',
  ThumbnailSmall = 'ThumbnailSmall',
  ThumbnailMedium = 'ThumbnailMedium',
  ThumbnailLarge = 'ThumbnailLarge',
  Pdf = 'Pdf',
  Forge = 'Forge',
  Xml = 'Xml',
  Signature = 'Signature',
  ProcessedXhtml = 'ProcessedXhtml',
}

export enum CommentPermissionEnum {
  submitterRole = 'submitterRole',
  submitterTeamAdmin = 'submitterTeamAdmin',
  author = 'author',
  processorRole = 'processorRole',
  processorTeamAdmin = 'processorTeamAdmin',
  submitterTeam = 'submitterTeam',
  processorTeam = 'processorTeam',
}

export enum CommentProcedureCommentDiscussionRelationEnum {
  none = 'none',
  processor = 'processor',
  submitter = 'submitter',
  both = 'both',
}

export enum CommentProcedureCommentNoteNoteTypeEnum {
  generalMessage = 'generalMessage',
  internalSubmitter = 'internalSubmitter',
  internalProcessor = 'internalProcessor',
}

export enum CommentProcedureCommentStateEnum {
  Commenting = 'Commenting',
  InternallyDiscarded = 'InternallyDiscarded',
  ForDiscussion = 'ForDiscussion',
  ForSolution = 'ForSolution',
  Discarded = 'Discarded',
  ForIncorporation = 'ForIncorporation',
  Incorporated = 'Incorporated',
  Approved = 'Approved',
}

export enum CommentProcedureErrorStateEnum {
  ok = 'ok',
  finished = 'finished',
  criticalError = 'criticalError',
  error = 'error',
  warning = 'warning',
}

export enum CommentProcedureExportOrderEnum {
  id = 'id',
  state = 'state',
  author = 'author',
  category = 'category',
}

export enum CommentProcedurePermissionEnum {
  User = 'User',
  Admin = 'Admin',
}

export enum CommentProcedurePhaseEnum {
  Commenting = 'Commenting',
  InternalNegotiations = 'InternalNegotiations',
  Negotiations = 'Negotiations',
  Incorporation = 'Incorporation',
  Approval = 'Approval',
  Approved = 'Approved',
  Closed = 'Closed',
}

export enum CommentProcedureRoleEnum {
  Submitter = 'Submitter',
  Processor = 'Processor',
}

export enum CommentProcedureSetStateStrategyEnum {
  ignoreBadStateDocuments = 'ignoreBadStateDocuments',
}

export enum DemandMsgTypeEnum {
  WorkflowNodeResolveDemand = 'WorkflowNodeResolveDemand',
  AssignmentResolveDemand = 'AssignmentResolveDemand',
}

export enum DirectoryReportOrderTypeEnum {
  createTime = 'createTime',
  author = 'author',
  documentName = 'documentName',
  documentPath = 'documentPath',
}

export enum DirectorySubscriberEnumsWatchDirectoryEnum {
  nameChange = 'nameChange',
  descriptionChange = 'descriptionChange',
  categoryChange = 'categoryChange',
  permissionChange = 'permissionChange',
  directoryDiscard = 'directoryDiscard',
  directoryMove = 'directoryMove',
  addSubdirectory = 'addSubdirectory',
  removeSubdirectory = 'removeSubdirectory',
  addDocument = 'addDocument',
  removeDocument = 'removeDocument',
  revisionChange = 'revisionChange',
}

export enum DirectoryTypeEnum {
  folder = 'folder',
}

export enum DocumentCategoryNodeRemoveStrategyEnum {
  replaceParent = 'replaceParent',
  replaceRoot = 'replaceRoot',
}

export enum DocumentCategoryTreeRemoveStrategyEnum {
  remove = 'remove',
}

export enum DocumentLabelsSetModeEnum {
  addLabels = 'addLabels',
  replaceLabels = 'replaceLabels',
  removeLabels = 'removeLabels',
}

export enum DocumentLinkRemoveStrategyEnum {
  remove = 'remove',
}

export enum DocumentLinksEvaluationObstaclesEnum {
  NotFound = 'NotFound',
  DocumentIsDiscarded = 'DocumentIsDiscarded',
  LinkAccessDenied = 'LinkAccessDenied',
  DocumentAccessDenied = 'DocumentAccessDenied',
  PropertySet = 'PropertySet',
  PropertyUnset = 'PropertyUnset',
}

export enum DocumentLockPurposeEnum {
  editDocument = 'editDocument',
  annotation = 'annotation',
}

export enum DocumentRevisionStateEnum {
  Ok = 'Ok',
  AccessDenied = 'AccessDenied',
  InWorkflow = 'InWorkflow',
  InCommentProcedure = 'InCommentProcedure',
  Reserved = 'Reserved',
  HasOwner = 'HasOwner',
  EsticonDocument = 'EsticonDocument',
}

export enum DocumentsEvaluationObstaclesEnum {
  NotFound = 'NotFound',
  IsDiscarded = 'IsDiscarded',
  BadDirectory = 'BadDirectory',
  AccessDenied = 'AccessDenied',
  PropertySet = 'PropertySet',
  PropertyUnset = 'PropertyUnset',
}

export enum DocumentsMultipleMoveStrategyEnum {
  rename = 'rename',
}

export enum DocumentsMultipleRestoreStrategyEnum {
  rename = 'rename',
}

export enum DocumentSubscriberEnumsWatchDocumentEnum {
  download = 'download',
  documentShare = 'documentShare',
  linkShare = 'linkShare',
  linkShareDownload = 'linkShareDownload',
  revisionChange = 'revisionChange',
  nameChange = 'nameChange',
  descriptionChange = 'descriptionChange',
  stateChange = 'stateChange',
  labelChange = 'labelChange',
  categoryChange = 'categoryChange',
  ownerChange = 'ownerChange',
  documentMove = 'documentMove',
  documentDiscard = 'documentDiscard',
  documentSign = 'documentSign',
  annotationChange = 'annotationChange',
  attachmentChange = 'attachmentChange',
  cpChange = 'cpChange',
  wfChange = 'wfChange',
  assignmentChange = 'assignmentChange',
}

export enum EntityTypesEnum {
  directory = 'directory',
  document = 'document',
  label = 'label',
  project = 'project',
  projectUserProfile = 'projectUserProfile',
  group = 'group',
  documentCategoryNode = 'documentCategoryNode',
  documentCategoryTree = 'documentCategoryTree',
  role = 'role',
  head = 'head',
  link = 'link',
  shared = 'shared',
  linkedModelElement = 'linkedModelElement',
  workflowTemplate = 'workflowTemplate',
  workflow = 'workflow',
  commentProcedure = 'commentProcedure',
  esticonToDocumentLink = 'esticonToDocumentLink',
  assignment = 'assignment',
  documentAnnotation = 'documentAnnotation',
  organization = 'organization',
  extendedPermissionTemplate = 'extendedPermissionTemplate',
  projectTemplate = 'projectTemplate',
  orgLabel = 'orgLabel',
  projectNote = 'projectNote',
  projectSetting = 'projectSetting',
}

export enum ErrorCommentProcedureSubjectEnum {
  CommentProcedureRole = 'CommentProcedureRole',
  CommentProcedureUser = 'CommentProcedureUser',
}

export enum ErrorSeverityEnum {
  critical = 'critical',
  error = 'error',
  warning = 'warning',
}

export enum ErrorWorkflowSubjectEnum {
  workflowRole = 'workflowRole',
  nodeRole = 'nodeRole',
  backgroundDocumentRole = 'backgroundDocumentRole',
  nodeTerm = 'nodeTerm',
  backgroundDocumentTerm = 'backgroundDocumentTerm',
  backgroundDocumentDir = 'backgroundDocumentDir',
}

export enum EstiCategoriesAccountingFactsErrorsEnum {
  ok = 'ok',
  projectNotFound = 'projectNotFound',
  esticonBindingError = 'esticonBindingError',
  erpInitializationError = 'erpInitializationError',
}

export enum EstiCategoryEnum {
  Stavba = 'Stavba',
  Cerpani = 'Cerpani',
  Fakturace = 'Fakturace',
  ZBV = 'ZBV',
  Harmonogram = 'Harmonogram',
  FakturaSdruzeni = 'FakturaSdruzeni',
  CerpaniSdruzeni = 'CerpaniSdruzeni',
}

export enum EstiConCategoryModules {
  Projekt = 'Projekt',
  Rozpocet = 'Rozpocet',
  Realizace = 'Realizace',
  ZBV = 'ZBV',
  Fakturace = 'Fakturace',
  Harmonogram = 'Harmonogram',
  TM = 'TM',
  Model3D = 'Model3D',
  CenoveSoustavy = 'CenoveSoustavy',
  Nabidky = 'Nabidky',
  Ciselniky = 'Ciselniky',
  Layout = 'Layout',
  Admin = 'Admin',
  Pruzkumnik = 'Pruzkumnik',
}

export enum EstiConDataAnalyzeResult {
  Success = 'Success',
  FileReadError = 'FileReadError',
  IncorrectType = 'IncorrectType',
  InvalidData = 'InvalidData',
  PairingError = 'PairingError',
  Informative = 'Informative',
  NoData = 'NoData',
}

export enum EstiConDependencyType {
  FinishStart = 'FinishStart',
  StartStart = 'StartStart',
  FinishFinish = 'FinishFinish',
  StartFinish = 'StartFinish',
}

export enum EstiConDUZPDateSettings {
  Vystaveni = 'Vystaveni',
  PosledniDen = 'PosledniDen',
}

export enum EstiConErrorType {
  DuplicityInSource = 'DuplicityInSource',
  DuplicityInDestination = 'DuplicityInDestination',
  InvalidExcel = 'InvalidExcel',
  CannotOpen = 'CannotOpen',
  WrongContent = 'WrongContent',
  MissingPartnerSZ = 'MissingPartnerSZ',
  MissingUserRights = 'MissingUserRights',
}

export enum EstiConFidicType {
  None = 'None',
  Cerveny = 'Cerveny',
  Zeleny = 'Zeleny',
}

export enum EstiConFlagType {
  None = 'None',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  Blue = 'Blue',
  Pink = 'Pink',
  Orange = 'Orange',
  Purple = 'Purple',
  Azure = 'Azure',
}

export enum EstiConFrekvenceOpakovaniZalohy {
  KazdyDen = 'KazdyDen',
  KazdyDruhyDen = 'KazdyDruhyDen',
  KazdyTyden = 'KazdyTyden',
}

export enum EstiConHarmonogramNodeType {
  Cinnost = 'Cinnost',
  Milnik = 'Milnik',
}

export enum EstiConImportHmgDataType {
  Vse = 'Vse',
  PouzeHmg = 'PouzeHmg',
  PouzeFp = 'PouzeFp',
}

export enum EstiConImportValidationIssueType {
  Precerpani = 'Precerpani',
  MenepraceDoMinusu = 'MenepraceDoMinusu',
  ChybiZbvProPolozky = 'ChybiZbvProPolozky',
  ChybiZpProCerpani = 'ChybiZpProCerpani',
  ChybiZbvProZp = 'ChybiZbvProZp',
  ChybiZbvProZpSdruzeny = 'ChybiZbvProZpSdruzeny',
  ChybiFakturaProZp = 'ChybiFakturaProZp',
  ChybiZpSdruzenyProZp = 'ChybiZpSdruzenyProZp',
  ChybiFakturaSdruzenaProFaktura = 'ChybiFakturaSdruzenaProFaktura',
  ChybiFakturaProRozpisFaktury = 'ChybiFakturaProRozpisFaktury',
  ChybiObjektProPolozkaFaktury = 'ChybiObjektProPolozkaFaktury',
  ChybiSmlDodatekProZbv = 'ChybiSmlDodatekProZbv',
  ChybiPartnerZFakturyNaZP = 'ChybiPartnerZFakturyNaZP',
  ChybiClenoveNaSdruzeneStavbe = 'ChybiClenoveNaSdruzeneStavbe',
  MazaniZPPouziteNaFakture = 'MazaniZPPouziteNaFakture',
  MazaniZPPouziteNaSpolZP = 'MazaniZPPouziteNaSpolZP',
  MazaniFakturyPouziteNaSpolFakture = 'MazaniFakturyPouziteNaSpolFakture',
  RozdilneZaokrouhleniCena = 'RozdilneZaokrouhleniCena',
  RozdilneZaokrouhleniJednotkovaCena = 'RozdilneZaokrouhleniJednotkovaCena',
  RozdilneZaokrouhleniMnozstvi = 'RozdilneZaokrouhleniMnozstvi',
  ViceNezJednoCerpaniRozpisuStejnymZP = 'ViceNezJednoCerpaniRozpisuStejnymZP',
  RozdilneVypoctySDph = 'RozdilneVypoctySDph',
  VyssiPocetAktivit = 'VyssiPocetAktivit',
  NizsiPocetAktivit = 'NizsiPocetAktivit',
  DuplicateInSource = 'DuplicateInSource',
  DuplicateInDestination = 'DuplicateInDestination',
  ChybiVCili = 'ChybiVCili',
  RozdilnaStruktura = 'RozdilnaStruktura',
  DuplicitniPolozky = 'DuplicitniPolozky',
  SpatnyFormatSouboru = 'SpatnyFormatSouboru',
  NevalidniData = 'NevalidniData',
  KontrolaXC4 = 'KontrolaXC4',
  NeznamaChyba = 'NeznamaChyba',
  OdeslanoNaHub = 'OdeslanoNaHub',
  ZbvNeniOdeslanoNaHub = 'ZbvNeniOdeslanoNaHub',
  ChybiDodavatelClenskeFaktury = 'ChybiDodavatelClenskeFaktury',
  NelzeNaparovatDodavateleFaktury = 'NelzeNaparovatDodavateleFaktury',
  DuplicitniCsNazev = 'DuplicitniCsNazev',
  DuplicitniCsVarianta = 'DuplicitniCsVarianta',
  KalkulaceDoNekalkulace = 'KalkulaceDoNekalkulace',
  RozdilnaTvorbaStruktury = 'RozdilnaTvorbaStruktury',
  HmgRozdilnaStruktura = 'HmgRozdilnaStruktura',
  ExistingZpHasFaktura = 'ExistingZpHasFaktura',
  ZpIsApproved = 'ZpIsApproved',
  ExistingZbvHasZp = 'ExistingZbvHasZp',
  NeshodneValorizace = 'NeshodneValorizace',
  NeshodnyTypValorizace = 'NeshodnyTypValorizace',
  NeshodneObdobiValorizace = 'NeshodneObdobiValorizace',
  ChybiSkupinyZbv = 'ChybiSkupinyZbv',
  StavbaNepouzivaSkupinyZbv = 'StavbaNepouzivaSkupinyZbv',
  NeshodnaMena = 'NeshodnaMena',
  RozdilnaTvorbaStrukturySd = 'RozdilnaTvorbaStrukturySd',
  RozdilnaTvorbaStrukturyElement = 'RozdilnaTvorbaStrukturyElement',
  Duplicitni3DModel = 'Duplicitni3DModel',
  MenyChybiPrava = 'MenyChybiPrava',
  KvChybiPrava = 'KvChybiPrava',
  PartneriChybiPrava = 'PartneriChybiPrava',
  SkupinyZbvChybiPrava = 'SkupinyZbvChybiPrava',
  SubdodavkyChybiPrava = 'SubdodavkyChybiPrava',
  DuplicitniPotreby = 'DuplicitniPotreby',
  PkvChybiPrava = 'PkvChybiPrava',
  ChybnaStruktura = 'ChybnaStruktura',
  CelkoveZaporneMnozstvi = 'CelkoveZaporneMnozstvi',
  SchvaleneZbv = 'SchvaleneZbv',
  RozdilnaZpusobilostProjektu = 'RozdilnaZpusobilostProjektu',
  ProjektChybiPrava = 'ProjektChybiPrava',
}

export enum EstiConKalkulaceImportChoice {
  Puvodni = 'Puvodni',
  Vlastni = 'Vlastni',
}

export enum EstiConKalkulaceProjectSaveAction {
  NoChange = 'NoChange',
  AddedKV = 'AddedKV',
  ChangedKV = 'ChangedKV',
  ChangedCoefficient = 'ChangedCoefficient',
  DeletedKV = 'DeletedKV',
}

export enum EstiConLogType {
  NalezenaKorekcniPolozka = 'NalezenaKorekcniPolozka',
  NesparovaneSkupinyZbv = 'NesparovaneSkupinyZbv',
  NesparovaneHmgVazbyPolozky = 'NesparovaneHmgVazbyPolozky',
  NesparovaneHmgVazbyElementy = 'NesparovaneHmgVazbyElementy',
  VypnutiKalkulace = 'VypnutiKalkulace',
  NepovedenaAktualizacePolozkyCs = 'NepovedenaAktualizacePolozkyCs',
}

export enum EstiConObdobiValorizace {
  Rocni = 'Rocni',
  Pulrocni = 'Pulrocni',
  Ctvrtletni = 'Ctvrtletni',
  Mesicni = 'Mesicni',
}

export enum EstiConObjectState {
  New = 'New',
  Clean = 'Clean',
  Dirty = 'Dirty',
  Deleted = 'Deleted',
}

export enum EstiConPosloupnostUdrzbyPC {
  KodPolozky = 'KodPolozky',
  SDKodPolozky = 'SDKodPolozky',
  PoradiZadavani = 'PoradiZadavani',
}

export enum EstiConPrenosHub {
  Neposlano = 'Neposlano',
  Poslano = 'Poslano',
  Zruseno = 'Zruseno',
  ProbihaOdeslani = 'ProbihaOdeslani',
  ChybaOdeslani = 'ChybaOdeslani',
  Zamitnuto = 'Zamitnuto',
}

export enum EstiConProjectPhase {
  Priprava = 'Priprava',
  Nabidka = 'Nabidka',
  Realizace = 'Realizace',
  Dokonceno = 'Dokonceno',
  Neziskano = 'Neziskano',
}

export enum EstiConProjectStage {
  Zp = 'Zp',
  Dur = 'Dur',
  Dsp = 'Dsp',
  Dps = 'Dps',
}

export enum EstiConProjectStructureCreation {
  StavebniDily = 'StavebniDily',
  Elementy = 'Elementy',
}

export enum EstiConProjectVariantType {
  Klasicka = 'Klasicka',
}

export enum EstiConProjectZatrideniPolozek {
  Manual = 'Manual',
  FirstSignCharacter = 'FirstSignCharacter',
  MaxSignCharacters = 'MaxSignCharacters',
}

export enum EstiConRezimPrenosuDPH {
  NeuplatnujeSe = 'NeuplatnujeSe',
  ZaplatiPrijemce = 'ZaplatiPrijemce',
  PrenesenoOdPoskytovatele = 'PrenesenoOdPoskytovatele',
}

export enum EstiConRozStrukturaTypUzlu {
  Projekt = 'Projekt',
  StavebniObjekt = 'StavebniObjekt',
  StavebniDil = 'StavebniDil',
  KonstrukcniPrvek = 'KonstrukcniPrvek',
  Element = 'Element',
  HmgVerze = 'HmgVerze',
  Polozka = 'Polozka',
  PolozkaRozpis = 'PolozkaRozpis',
  ZBV = 'ZBV',
  ZjistovaciProtokol = 'ZjistovaciProtokol',
  ZjistovaciProtokolSdruzeny = 'ZjistovaciProtokolSdruzeny',
  Faktura = 'Faktura',
  FakturaSdruzena = 'FakturaSdruzena',
  FakturaRozpis = 'FakturaRozpis',
  SmluvniDodatekZbv = 'SmluvniDodatekZbv',
  HmgCinnost = 'HmgCinnost',
  HmgMilnik = 'HmgMilnik',
  KategoriePolozky = 'KategoriePolozky',
  KategorieDodavatelePolozky = 'KategorieDodavatelePolozky',
  VykazVymer = 'VykazVymer',
}

export enum EstiConSaveResult {
  Success = 'Success',
  DuplicateRecord = 'DuplicateRecord',
  PartialSuccess = 'PartialSuccess',
  NoData = 'NoData',
  DataNotSaveable = 'DataNotSaveable',
  DataNotValid = 'DataNotValid',
  DataReadonly = 'DataReadonly',
  SentToHub = 'SentToHub',
  SqlError = 'SqlError',
  UnspecifiedError = 'UnspecifiedError',
  IOException = 'IOException',
}

export enum EsticonTemplateEnum {
  ShortYear = 'ShortYear',
  Year = 'Year',
  ShortMonth = 'ShortMonth',
  Month = 'Month',
  Name = 'Name',
  Number = 'Number',
  ObjectNumber = 'ObjectNumber',
  Code = 'Code',
  ObjectPath = 'ObjectPath',
}

export enum EstiConTerminationType {
  Preruseni = 'Preruseni',
  TechnologickaPauza = 'TechnologickaPauza',
}

export enum EstiConTransferFormat {
  Native = 'Native',
  XC4 = 'XC4',
  XDC = 'XDC',
  A10 = 'A10',
  Excel = 'Excel',
  Smernice20Sz = 'Smernice20Sz',
  Zip = 'Zip',
  xlsx = 'xlsx',
  xls = 'xls',
  UNCEFACT = 'UNCEFACT',
  ISDOC = 'ISDOC',
  DATOVKY_A10 = 'DATOVKY_A10',
  MSPROJECT = 'MSPROJECT',
  UNIXML = 'UNIXML',
  XDCSZ = 'XDCSZ',
  XLSSZ = 'XLSSZ',
}

export enum EstiConTransferType {
  Projekt = 'Projekt',
  Rozpocet = 'Rozpocet',
  Harmonogram = 'Harmonogram',
  Nabidka = 'Nabidka',
  ZBV = 'ZBV',
  Cerpani = 'Cerpani',
  Faktury = 'Faktury',
  TechModel = 'TechModel',
  Model3D = 'Model3D',
  ExcelPril10 = 'ExcelPril10',
  ZipExcelSZZmenyExcel = 'ZipExcelSZZmenyExcel',
  CenovaSoustava = 'CenovaSoustava',
  ExcelNabidkyPosouzeni = 'ExcelNabidkyPosouzeni',
  ZipExcelProtokolVykazVymer = 'ZipExcelProtokolVykazVymer',
  ExcelZpetneOceneni = 'ExcelZpetneOceneni',
  UNIXML = 'UNIXML',
  RozdeleneMnozstvi = 'RozdeleneMnozstvi',
  Dokument = 'Dokument',
  ExcelRekapCerpaniPolZbv = 'ExcelRekapCerpaniPolZbv',
  TempFile = 'TempFile',
}

export enum EstiConTypCenyPolozky {
  PrimaCena = 'PrimaCena',
  Rozpad = 'Rozpad',
  Rozpis = 'Rozpis',
  PKV = 'PKV',
}

export enum EstiConTypCerpanehoMnozstvi {
  SOD = 'SOD',
  ZBV = 'ZBV',
  Oboji = 'Oboji',
}

export enum EstiConTypCerpano {
  PuvMnozstvi = 'PuvMnozstvi',
  ZeZBV = 'ZeZBV',
}

export enum EstiConTypDb {
  Klasicka = 'Klasicka',
  Archivni = 'Archivni',
  Kontrolni = 'Kontrolni',
}

export enum EstiConTypFaktury {
  Klasicka = 'Klasicka',
  Zalohova = 'Zalohova',
  Dobropis = 'Dobropis',
}

export enum EstiConTypKontrolniDb {
  RSD = 'RSD',
  SZ = 'SZ',
}

export enum EstiConTypMnozstviPotreby {
  Norma = 'Norma',
  Vykon = 'Vykon',
}

export enum EstiConTypologieSz {
  ZmenaGeologie = 'ZmenaGeologie',
  NalezSkrytychKonstrukci = 'NalezSkrytychKonstrukci',
  ArcheologickeNalezy = 'ArcheologickeNalezy',
  NovyPozadavek = 'NovyPozadavek',
  ChybaProjektoveDokumentace = 'ChybaProjektoveDokumentace',
  ZmenaStavebnichPostupu = 'ZmenaStavebnichPostupu',
  ExtremniKlimatickeVlivy = 'ExtremniKlimatickeVlivy',
  ProstojeZhotovitele = 'ProstojeZhotovitele',
  AkceleraceZhotovitele = 'AkceleraceZhotovitele',
  NovyObchodniPripad = 'NovyObchodniPripad',
  ZmenaObecnychZakonu = 'ZmenaObecnychZakonu',
  ZamenaVPolozkovemRozpoctu = 'ZamenaVPolozkovemRozpoctu',
  ZmenaVOdpadovemHospodarstvi = 'ZmenaVOdpadovemHospodarstvi',
  OstatniNezaraditelneZmeny = 'OstatniNezaraditelneZmeny',
}

export enum EstiConTypPartnera {
  Investor = 'Investor',
  Dodavatel = 'Dodavatel',
}

export enum EstiConTypPresunHmot {
  NeniPolozkou = 'NeniPolozkou',
  HSV = 'HSV',
  PSV = 'PSV',
  OdvozSuti = 'OdvozSuti',
}

export enum EstiConTypSkupinyZbv {
  RSD = 'RSD',
  SZ = 'SZ',
}

export enum EstiConTypSmluvniDodatek {
  Neurceno = 'Neurceno',
  ZLNaklady = 'ZLNaklady',
  ZLPokyn = 'ZLPokyn',
  ZLPredlozeno = 'ZLPredlozeno',
  ZLSchvaleno = 'ZLSchvaleno',
  ZLFinalni = 'ZLFinalni',
  ZLPodepsano = 'ZLPodepsano',
  Claim = 'Claim',
}

export enum EstiConTypUdrzbyPC {
  Neudrzovat = 'Neudrzovat',
  AutoPrecislovani = 'AutoPrecislovani',
}

export enum EstiConTypZadaniFakturace {
  PrevzataZeZP = 'PrevzataZeZP',
  RucneVytvorena = 'RucneVytvorena',
}

export enum EstiConValidationResultType {
  Unvalidated = 'Unvalidated',
  Failed = 'Failed',
  Success = 'Success',
  Warning = 'Warning',
}

export enum EstiConValidationSourceType {
  Imported = 'Imported',
  Native = 'Native',
}

export enum EstiConValorizaceTyp {
  ZaProjekty = 'ZaProjekty',
  PoObjektech = 'PoObjektech',
}

export enum EstiConZbvType {
  Investorsky = 'Investorsky',
  Navrh = 'Navrh',
  Interni = 'Interni',
}

export enum EstiConZjistovaciProtokolTyp {
  Klasicky = 'Klasicky',
  Storno = 'Storno',
  Valorizacni = 'Valorizacni',
}

export enum EstiDocumentUploadErrorEnum {
  ok = 'ok',
  notFoundProjectError = 'notFoundProjectError',
  accessDenied = 'accessDenied',
  documentIsUploading = 'documentIsUploading',
  unspecifiedError = 'unspecifiedError',
}

export enum EstiDokumentReturnForRevorkActionEnum {
  refused = 'refused',
  archived = 'archived',
  ignored = 'ignored',
}

export enum EstiDokumentReturnForRevorkErrorEnum {
  ok = 'ok',
  documentInWorkflowError = 'documentInWorkflowError',
  documentInCommentProcedureError = 'documentInCommentProcedureError',
  documentPublishedError = 'documentPublishedError',
  notFoundDocumentError = 'notFoundDocumentError',
}

export enum EstiReturnForRevorkErrorEnum {
  ok = 'ok',
  notFoundProjectError = 'notFoundProjectError',
  accessDenied = 'accessDenied',
  unspecifiedError = 'unspecifiedError',
  obstacle = 'obstacle',
}

export enum EventNamesEnum {
  initialised = 'initialised',
  patched = 'patched',
  created = 'created',
  currentRevisionChanged = 'currentRevisionChanged',
  invitationCreated = 'invitationCreated',
  invitationAccepted = 'invitationAccepted',
  statusChanged = 'statusChanged',
  requiredCategoryTreeAdded = 'requiredCategoryTreeAdded',
  requiredCategoryTreeRemoved = 'requiredCategoryTreeRemoved',
  requiredCategoryTreeChanged = 'requiredCategoryTreeChanged',
  groupAccessLevelHasBeenSet = 'groupAccessLevelHasBeenSet',
  groupAccessLevelHasBeenUnset = 'groupAccessLevelHasBeenUnset',
  userAccessLevelHasBeenSet = 'userAccessLevelHasBeenSet',
  userAccessLevelHasBeenUnset = 'userAccessLevelHasBeenUnset',
  permisssionInheritanceChanged = 'permisssionInheritanceChanged',
  move = 'move',
  labelAdded = 'labelAdded',
  labelRemoved = 'labelRemoved',
  linkAdded = 'linkAdded',
  revisionAdded = 'revisionAdded',
  categoryTreeNodeAdded = 'categoryTreeNodeAdded',
  categoryTreeNodeChanged = 'categoryTreeNodeChanged',
  categoryTreeNodeRemoved = 'categoryTreeNodeRemoved',
  suspended = 'suspended',
  activated = 'activated',
  discarded = 'discarded',
  requiredDefaultCategoryTreeNodechanged = 'requiredDefaultCategoryTreeNodechanged',
  moved = 'moved',
  documentsDiscarded = 'documentsDiscarded',
  documentsRestored = 'documentsRestored',
  documentsMoved = 'documentsMoved',
  directoriesDiscarded = 'directoriesDiscarded',
  directoriesRestored = 'directoriesRestored',
  restored = 'restored',
  used = 'used',
  documentShared = 'documentShared',
  sharedDocumentDownloaded = 'sharedDocumentDownloaded',
  shareSent = 'shareSent',
  documentDownloaded = 'documentDownloaded',
  documentsDownloaded = 'documentsDownloaded',
  secondaryFileAdded = 'secondaryFileAdded',
  documentRemovedFromModels = 'documentRemovedFromModels',
  documentAddedToModels = 'documentAddedToModels',
  secondaryFileDownloaded = 'secondaryFileDownloaded',
  linkRemoved = 'linkRemoved',
  addedToGroup = 'addedToGroup',
  removedFromGroup = 'removedFromGroup',
  usersInvited = 'usersInvited',
  workflowStateChanged = 'workflowStateChanged',
  reserved = 'reserved',
  reservationReleased = 'reservationReleased',
  userRoleAdded = 'userRoleAdded',
  userRoleDeleted = 'userRoleDeleted',
  ownerChanged = 'ownerChanged',
  roleAddedToWorkflow = 'roleAddedToWorkflow',
  roleAddedToNode = 'roleAddedToNode',
  roleRemovedFromNode = 'roleRemovedFromNode',
  roleAddedToBackgroundDocument = 'roleAddedToBackgroundDocument',
  finished = 'finished',
  approvalDocumentAssigned = 'approvalDocumentAssigned',
  backgroundDocumentAssigned = 'backgroundDocumentAssigned',
  backgroundDocumentAccepted = 'backgroundDocumentAccepted',
  backgroundDocumentRefused = 'backgroundDocumentRefused',
  backgroundDocumentRequestAdded = 'backgroundDocumentRequestAdded',
  backgroundDocumentRequestChanged = 'backgroundDocumentRequestChanged',
  backgroundDocumentRequestRemoved = 'backgroundDocumentRequestRemoved',
  workflowNodeStarted = 'workflowNodeStarted',
  workflowNodeRoleDelegation = 'workflowNodeRoleDelegation',
  workflowNodeVote = 'workflowNodeVote',
  workflowNodeFinish = 'workflowNodeFinish',
  documentAddedForApproval = 'documentAddedForApproval',
  documentAddedAsBackgroundDocument = 'documentAddedAsBackgroundDocument',
  documentRemovedFromBackgroundDocument = 'documentRemovedFromBackgroundDocument',
  workflowVote = 'workflowVote',
  roleRemovedFromBackgroundDocument = 'roleRemovedFromBackgroundDocument',
  documentRemovedFromComments = 'documentRemovedFromComments',
  documentAddedToComments = 'documentAddedToComments',
  roleAddedToComments = 'roleAddedToComments',
  roleRemovedFromComments = 'roleRemovedFromComments',
  userAddedToComments = 'userAddedToComments',
  userRemovedFromComments = 'userRemovedFromComments',
  commentsUserPatched = 'commentsUserPatched',
  teamAddedToComments = 'teamAddedToComments',
  teamRemovedFromComments = 'teamRemovedFromComments',
  commentsTeamPatched = 'commentsTeamPatched',
  CommentsSetState = 'CommentsSetState',
  commentsComentCreated = 'commentsComentCreated',
  commentsComentPatched = 'commentsComentPatched',
  commentsComentStateSet = 'commentsComentStateSet',
  commentsComentNoteAdded = 'commentsComentNoteAdded',
  documentAddedToCommentsComment = 'documentAddedToCommentsComment',
  documentRemovedFromCommentsComment = 'documentRemovedFromCommentsComment',
  attachmentAddedToCommentsComment = 'attachmentAddedToCommentsComment',
  commentsStateSet = 'commentsStateSet',
  commentsComentNoteEdited = 'commentsComentNoteEdited',
  commentsComentNoteDeleted = 'commentsComentNoteDeleted',
  commentsComentNoteRestored = 'commentsComentNoteRestored',
  attachmentDiscardedFromCommentsComment = 'attachmentDiscardedFromCommentsComment',
  exported = 'exported',
  documentApprovalFinished = 'documentApprovalFinished',
  closed = 'closed',
  documentRemovedFromCommentProcedure = 'documentRemovedFromCommentProcedure',
  documentAddedToCommentProcedure = 'documentAddedToCommentProcedure',
  approvalDocumentsAccepted = 'approvalDocumentsAccepted',
  approvalDocumentsRefused = 'approvalDocumentsRefused',
  CommentProcedureDocumentsAdded = 'CommentProcedureDocumentsAdded',
  commentProcedureRolesPatched = 'commentProcedureRolesPatched',
  usersAddedToComments = 'usersAddedToComments',
  commentProcedureCategoriesImported = 'commentProcedureCategoriesImported',
  commentProcedureCategoryAdded = 'commentProcedureCategoryAdded',
  commentProcedureCategoryPatched = 'commentProcedureCategoryPatched',
  commentProcedureCategoryDeleted = 'commentProcedureCategoryDeleted',
  commentProcedureAdded = 'commentProcedureAdded',
  commentProcedureRemoved = 'commentProcedureRemoved',
  commentsTeamPermissionPatched = 'commentsTeamPermissionPatched',
  documentDerivatesDownloaded = 'documentDerivatesDownloaded',
  documentSecondaryDerivatesDownloaded = 'documentSecondaryDerivatesDownloaded',
  attachmentRestoredInCommentsComment = 'attachmentRestoredInCommentsComment',
  groupUsersAdded = 'groupUsersAdded',
  revisionDiscarded = 'revisionDiscarded',
  revisionRestored = 'revisionRestored',
  startEsticonUpload = 'startEsticonUpload',
  startEsticonDocumentUpload = 'startEsticonDocumentUpload',
  esticonDocumentUploaded = 'esticonDocumentUploaded',
  extendedPermissionChanged = 'extendedPermissionChanged',
  projectOpened = 'projectOpened',
  projectClosed = 'projectClosed',
  reportCreated = 'reportCreated',
  mailLimitExceed = 'mailLimitExceed',
  workflowParallelInit = 'workflowParallelInit',
  esticonDocumentReturnForRework = 'esticonDocumentReturnForRework',
  userAddedToAssignment = 'userAddedToAssignment',
  userRemovedFromAssignment = 'userRemovedFromAssignment',
  documentAddedToAssignment = 'documentAddedToAssignment',
  documentRemovedFromAssignment = 'documentRemovedFromAssignment',
  primarySolverChanged = 'primarySolverChanged',
  approvalDocumentAddRevision = 'approvalDocumentAddRevision',
  assignmentNoteAdded = 'assignmentNoteAdded',
  assignmentNoteEdited = 'assignmentNoteEdited',
  assignmentNoteDeleted = 'assignmentNoteDeleted',
  assignmentNoteRestored = 'assignmentNoteRestored',
  attachmentAddedToAssignment = 'attachmentAddedToAssignment',
  attachmentDiscardedFromAssignment = 'attachmentDiscardedFromAssignment',
  attachmentRestoredInAssignment = 'attachmentRestoredInAssignment',
  approvalDocumentAddedToWorkflow = 'approvalDocumentAddedToWorkflow',
  approvalDocumentRevisionAddedInWorkflow = 'approvalDocumentRevisionAddedInWorkflow',
  approvalDocumentRemovedFromWorkflow = 'approvalDocumentRemovedFromWorkflow',
  workflowAssignApprovalDocuments = 'workflowAssignApprovalDocuments',
  directoryShared = 'directoryShared',
  workflowNodeNoteAdded = 'workflowNodeNoteAdded',
  workflowNodeNoteEdited = 'workflowNodeNoteEdited',
  workflowNodeNoteDeleted = 'workflowNodeNoteDeleted',
  workflowNodeNoteRestored = 'workflowNodeNoteRestored',
  attachmentAddedToWorkflowNode = 'attachmentAddedToWorkflowNode',
  attachmentDiscardedFromWorkflowNode = 'attachmentDiscardedFromWorkflowNode',
  attachmentRestoredInWorkflowNode = 'attachmentRestoredInWorkflowNode',
  commentProcedureDeadlineSet = 'commentProcedureDeadlineSet',
  documentLinkAdded = 'documentLinkAdded',
  documentLinkRemoved = 'documentLinkRemoved',
  documentLinkChanged = 'documentLinkChanged',
  documentLinksCreated = 'documentLinksCreated',
  documentEsticonMultiupload = 'documentEsticonMultiupload',
  documentAnnotationsCreated = 'documentAnnotationsCreated',
  documentAnnotationsChanged = 'documentAnnotationsChanged',
  annotationRemoved = 'annotationRemoved',
  documentAnnotationAddedToCommentsComment = 'documentAnnotationAddedToCommentsComment',
  directoryLinkAdded = 'directoryLinkAdded',
  directoryLinkRemoved = 'directoryLinkRemoved',
  directoryLinkChanged = 'directoryLinkChanged',
  esticonDocumentPublished = 'esticonDocumentPublished',
  bulkAction = 'bulkAction',
  bulkDocumentsChange = 'bulkDocumentsChange',
  directoryLinkMoved = 'directoryLinkMoved',
  labelReplaced = 'labelReplaced',
  attachmentDownloaded = 'attachmentDownloaded',
  attachmentsDownloaded = 'attachmentsDownloaded',
  unknown = 'unknown',
  assignmentStateChanged = 'assignmentStateChanged',
  esticonDocumentSetApprovalDate = 'esticonDocumentSetApprovalDate',
  transferToKD = 'transferToKD',
  attachmentVisibilityChangedInCommentsComment = 'attachmentVisibilityChangedInCommentsComment',
  docToDocLinksCreated = 'docToDocLinksCreated',
  organizationDeleted = 'organizationDeleted',
  addOrganizationUser = 'addOrganizationUser',
  patchOrganizationUser = 'patchOrganizationUser',
  usersAddedToProject = 'usersAddedToProject',
  projectUserSetAdmin = 'projectUserSetAdmin',
  projectUserChangeStatus = 'projectUserChangeStatus',
  addOrganizationProject = 'addOrganizationProject',
  harmonogramImported = 'harmonogramImported',
  removeOrganizationUser = 'removeOrganizationUser',
  projectUserRemoveInvited = 'projectUserRemoveInvited',
  organizationChanged = 'organizationChanged',
  directoryDownloaded = 'directoryDownloaded',
  associateUserAddedToAssignment = 'associateUserAddedToAssignment',
  associateUserRemovedFromAssignment = 'associateUserRemovedFromAssignment',
  bulkUpdateAnnotationsWithAssignment = 'bulkUpdateAnnotationsWithAssignment',
  documentDownloadedForDiff = 'documentDownloadedForDiff',
  workflowStateChangedFail = 'workflowStateChangedFail',
  approvalDocumentsShared = 'approvalDocumentsShared',
  approvalDocumentSignRevision = 'approvalDocumentSignRevision',
  addItem = 'addItem',
  patchItem = 'patchItem',
  deleteItem = 'deleteItem',
  documentStateChangedFail = 'documentStateChangedFail',
  merged = 'merged',
  documentReferenceAdded = 'documentReferenceAdded',
  documentReferenceMatrixAdded = 'documentReferenceMatrixAdded',
  documentReferenceRemoved = 'documentReferenceRemoved',
  userNotified = 'userNotified',
  userResendActivation = 'userResendActivation',
  settingsDeleted = 'settingsDeleted',
}

export enum ExtendedPermissionCategoryEnum {
  projectManager = 'projectManager',
  workflow = 'workflow',
  aspeEsticon = 'aspeEsticon',
  document = 'document',
}

export enum ExtendedPermissionEnum {
  userEditing = 'userEditing',
  labels = 'labels',
  category = 'category',
  externalApplications = 'externalApplications',
  addWorkflow = 'addWorkflow',
  workflowPreview = 'workflowPreview',
  stavba = 'stavba',
  importZP = 'importZP',
  importFaktura = 'importFaktura',
  importZBV = 'importZBV',
  importHarmonogram = 'importHarmonogram',
  prenosHub = 'prenosHub',
  setApprovalDate = 'setApprovalDate',
  transferKD = 'transferKD',
  returnForRework = 'returnForRework',
  addPartner = 'addPartner',
  forbidWorkInProgressState = 'forbidWorkInProgressState',
  forbidSharedState = 'forbidSharedState',
  forbidRejectedState = 'forbidRejectedState',
  forbidPublishedState = 'forbidPublishedState',
  forbidarchivedState = 'forbidarchivedState',
}

export enum HashAlgoEnum {
  MD5 = 'MD5',
  SHA256 = 'SHA256',
}

export enum InvoiceDocumentTypeEnum {
  accepted = 'accepted',
  released = 'released',
}

export enum LabelRemoveConflictStrategyEnum {
  remove = 'remove',
}

export enum LanguageEnum {
  cs = 'cs',
  sk = 'sk',
  en = 'en',
  de = 'de',
}

export enum MsgCategoryEnum {
  wfNodeStarted = 'wfNodeStarted',
  wfNodeLimitWillBeExceeded = 'wfNodeLimitWillBeExceeded',
  wfNodeLimitExceeded = 'wfNodeLimitExceeded',
  wfWorkflowFinished = 'wfWorkflowFinished',
  wfBackgroundDocumentLimitExceeded = 'wfBackgroundDocumentLimitExceeded',
  wfBackgroundDocumentLimitWillBeExceeded = 'wfBackgroundDocumentLimitWillBeExceeded',
  wfBackgroundDocumentRequestStarted = 'wfBackgroundDocumentRequestStarted',
  wfBackgroundDocumentAdded = 'wfBackgroundDocumentAdded',
  wfBackgroundDocumentRequestRemoved = 'wfBackgroundDocumentRequestRemoved',
  wfBackgroundDocumentRequestChanged = 'wfBackgroundDocumentRequestChanged',
  wfBackgroundDocumentAccepted = 'wfBackgroundDocumentAccepted',
  wfBackgroundDocumentRefused = 'wfBackgroundDocumentRefused',
  wfNodeFinished = 'wfNodeFinished',
  wfWorkflowClosed = 'wfWorkflowClosed',
  wfNotification = 'wfNotification',
  wfApprovalDocumentsAccepted = 'wfApprovalDocumentsAccepted',
  wfApprovalDocumentsRefused = 'wfApprovalDocumentsRefused',
  wfApprovalDocumentsRevisionAdded = 'wfApprovalDocumentsRevisionAdded',
  wfApprovalDocumentAdded = 'wfApprovalDocumentAdded',
  wfApprovalDocumentRemoved = 'wfApprovalDocumentRemoved',
  wfWorkflowNodeNoteAdded = 'wfWorkflowNodeNoteAdded',
  wfWorkflowNodeNoteUserNotified = 'wfWorkflowNodeNoteUserNotified',
  wfWorkflowNodeAttachmentAdded = 'wfWorkflowNodeAttachmentAdded',
  wfWorkflowNodeAttachmentRemoved = 'wfWorkflowNodeAttachmentRemoved',
  wfWorkflowNodeAttachmentRestored = 'wfWorkflowNodeAttachmentRestored',
  wfApprovalDocumentsShared = 'wfApprovalDocumentsShared',
  cpCommentAdded = 'cpCommentAdded',
  cpDocumentAdded = 'cpDocumentAdded',
  cpDocumentRemoved = 'cpDocumentRemoved',
  cpStateChanged = 'cpStateChanged',
  cpCommentStateChangded = 'cpCommentStateChangded',
  cpCommentSubmitterTeamChanged = 'cpCommentSubmitterTeamChanged',
  cpCommentProcessorTeamChanged = 'cpCommentProcessorTeamChanged',
  cpCommentNoteAdded = 'cpCommentNoteAdded',
  cpCommentTextChanged = 'cpCommentTextChanged',
  cpUserAdded = 'cpUserAdded',
  cpUserRemoved = 'cpUserRemoved',
  cpCommentDocumentAdded = 'cpCommentDocumentAdded',
  cpCommentDocumentRemoved = 'cpCommentDocumentRemoved',
  cpStarted = 'cpStarted',
  cpClosed = 'cpClosed',
  cpCommentAttachmentAdded = 'cpCommentAttachmentAdded',
  cpCommentAttachmentRemoved = 'cpCommentAttachmentRemoved',
  cpCommentNoteUserNotified = 'cpCommentNoteUserNotified',
  cpDeadlineWarning = 'cpDeadlineWarning',
  cpDeadlineExpired = 'cpDeadlineExpired',
  cpDeadlineChanged = 'cpDeadlineChanged',
  appFileShare = 'appFileShare',
  alertSign = 'alertSign',
  assignmentStarted = 'assignmentStarted',
  assignmentChanged = 'assignmentChanged',
  assignmentStateChanged = 'assignmentStateChanged',
  assignmentDocumentsChanged = 'assignmentDocumentsChanged',
  assignmentUsersChanged = 'assignmentUsersChanged',
  assignmentUserAdded = 'assignmentUserAdded',
  assignmentUserRemoved = 'assignmentUserRemoved',
  assignmentNoteAdded = 'assignmentNoteAdded',
  assignmentNoteUserNotified = 'assignmentNoteUserNotified',
  assignmentDeadlineWarning = 'assignmentDeadlineWarning',
  assignmentDeadlineExpired = 'assignmentDeadlineExpired',
  bulkActionReportNotification = 'bulkActionReportNotification',
  documentSubscriber = 'documentSubscriber',
  directorySubscriber = 'directorySubscriber',
  projectAccountStateChange = 'projectAccountStateChange',
  docReleaseReservationAlert = 'docReleaseReservationAlert',
  docReservationReleased = 'docReservationReleased',
  demWorkflowNodeResolveDemand = 'demWorkflowNodeResolveDemand',
  demAssignmentResolveDemand = 'demAssignmentResolveDemand',
}

export enum MsgCategoryTypeEnum {
  workflow = 'workflow',
  commentProcedure = 'commentProcedure',
  appFileShare = 'appFileShare',
  alert = 'alert',
  assignment = 'assignment',
  bulkAction = 'bulkAction',
  subscriber = 'subscriber',
  accountNotification = 'accountNotification',
  document = 'document',
  demandMsg = 'demandMsg',
}

export enum MsgOrderTypeEnum {
  createTime = 'createTime',
  finishTime = 'finishTime',
  status = 'status',
  category = 'category',
  project = 'project',
}

export enum MsgStatusEnum {
  new = 'new',
  read = 'read',
  solved = 'solved',
  finished = 'finished',
  ignored = 'ignored',
}

export enum MsgTypeEnum {
  message = 'message',
  task = 'task',
}

export enum NodeEdgeStateEnum {
  waiting = 'waiting',
  out = 'out',
  processed = 'processed',
  over = 'over',
  revert = 'revert',
  outOver = 'outOver',
}

export enum NodePortActionEventTypeEnum {
  no = 'no',
}

export enum NodePortActionTypeEnum {
  process = 'process',
  accept = 'accept',
  refuse = 'refuse',
}

export enum NodeStateEnum {
  waiting = 'waiting',
  current = 'current',
  out = 'out',
  processed = 'processed',
  accepted = 'accepted',
  refused = 'refused',
  over = 'over',
  init = 'init',
  outOver = 'outOver',
  closed = 'closed',
  closedIn = 'closedIn',
}

export enum NodeTimeoutActionEnum {
  wait = 'wait',
  selectDefault = 'selectDefault',
  closeWorkflow = 'closeWorkflow',
  evalLateAsAccept = 'evalLateAsAccept',
  evalLateAsRefuse = 'evalLateAsRefuse',
}

export enum NodeTypeEnum {
  start = 'start',
  userAction = 'userAction',
  notification = 'notification',
  refuse = 'refuse',
  accept = 'accept',
  stop = 'stop',
  parallel = 'parallel',
  addRevision = 'addRevision',
  changeDocState = 'changeDocState',
}

export enum OrgExtendedPermissionCategoryEnum {
  reports = 'reports',
}

export enum OrgExtendedPermissionEnum {
  financialOverviewByUnit = 'financialOverviewByUnit',
  projectsInRealisationOverview = 'projectsInRealisationOverview',
  projectsInRealisationOverviewAdmin = 'projectsInRealisationOverviewAdmin',
  projectsInRealisationShareWidget = 'projectsInRealisationShareWidget',
}

export enum OrgExtendedPermissionValueEnum {
  none = 'none',
  read = 'read',
  write = 'write',
}

export enum ProjectDataTypeEnum {
  string = 'string',
  number = 'number',
  numberArray = 'numberArray',
  stringArray = 'stringArray',
  bool = 'bool',
  externalAppData = 'externalAppData',
}

export enum ProjectReportTypeEnum {
  projectOverview = 'projectOverview',
  withdrawOverview = 'withdrawOverview',
  workflowTasks = 'workflowTasks',
  documentOverview = 'documentOverview',
  financialPlan = 'financialPlan',
  drawingActualOverview = 'drawingActualOverview',
}

export enum ProjectSettingExternalAppSettingsEnum {
  constructionJournal = 'constructionJournal',
  pageUrl = 'pageUrl',
  textPage = 'textPage',
  document = 'document',
}

export enum ProjectsInRealizationExportFormat {
  excel = 'excel',
  pdf = 'pdf',
}

export enum ProjectsInRealizationPropertyType {
  Type = 'Type',
  Unit = 'Unit',
  Subsidies = 'Subsidies',
  Name = 'Name',
  Sign = 'Sign',
  Supervisor = 'Supervisor',
  Supplier = 'Supplier',
  State = 'State',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  TotalCost = 'TotalCost',
  TotalCostI = 'TotalCostI',
  TotalCostO = 'TotalCostO',
  PerformedWorkTotal = 'PerformedWorkTotal',
  PerformedWorkTotalI = 'PerformedWorkTotalI',
  PerformedWorkTotalO = 'PerformedWorkTotalO',
  PerformedWorkYear = 'PerformedWorkYear',
  PerformedWorkYearI = 'PerformedWorkYearI',
  PerformedWorkYearO = 'PerformedWorkYearO',
  PlanYearICurrent = 'PlanYearICurrent',
  PlanYearOCurrent = 'PlanYearOCurrent',
  UnassignedYear = 'UnassignedYear',
  NoteAuthor = 'NoteAuthor',
  PlanYearI1 = 'PlanYearI1',
  PlanYearO1 = 'PlanYearO1',
  PlanYearI2 = 'PlanYearI2',
  PlanYearO2 = 'PlanYearO2',
  PlanYearI3 = 'PlanYearI3',
  PlanYearO3 = 'PlanYearO3',
  PlanYearI4 = 'PlanYearI4',
  PlanYearO4 = 'PlanYearO4',
  Note = 'Note',
}

export enum ProjectStatusEnum {
  creating = 'creating',
  error = 'error',
  ready = 'ready',
  suspended = 'suspended',
  archived = 'archived',
}

export enum ProjectUserProfileStatusEnum {
  system = 'system',
  active = 'active',
  invited = 'invited',
  suspended = 'suspended',
  expired = 'expired',
}

export enum ResultFileFormatEnum {
  excel = 'excel',
  Word = 'Word',
  pdf = 'pdf',
}

export enum RevisionLockPurposeEnum {
  sign = 'sign',
}

export enum RevisionMetaDataSourceEnum {
  AspeEsticon = 'AspeEsticon',
  OpenTree = 'OpenTree',
}

export enum SecondaryBlobTypeEnum {
  attachment = 'attachment',
  signed = 'signed',
  invalid = 'invalid',
}

export enum SharedSharedOutputTypeEnum {
  file = 'file',
  zip = 'zip',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum SubWorkflowStatusEnum {
  wait = 'wait',
  initialized = 'initialized',
  out = 'out',
  finished = 'finished',
}

export enum SuspendAllUserErrorEnum {
  NotFound = 'NotFound',
  IsLastAdmin = 'IsLastAdmin',
  InvalidOperation = 'InvalidOperation',
}

export enum UploadPartStatusEnum {
  Pending = 'Pending',
  InProgres = 'InProgres',
  Complete = 'Complete',
  Expired = 'Expired',
  Error = 'Error',
}

export enum UploadStatusEnum {
  Pending = 'Pending',
  InProgres = 'InProgres',
  Complete = 'Complete',
  Expired = 'Expired',
  Error = 'Error',
}

export enum UserRemoveStrategyEnum {
  remove = 'remove',
}

export enum WorkFlowApprovalDocumentStateEnum {
  used = 'used',
  removed = 'removed',
}

export enum WorkflowNodePermittedActionsEnum {
  removeDocument = 'removeDocument',
  addDocument = 'addDocument',
  signDocument = 'signDocument',
  addRevision = 'addRevision',
  stateFromNode = 'stateFromNode',
  stateWorkInProgress = 'stateWorkInProgress',
  stateShared = 'stateShared',
  statePublish = 'statePublish',
}

export enum WorkflowStateEnum {
  workinprogress = 'workinprogress',
  shared = 'shared',
  rejected = 'rejected',
  published = 'published',
  archived = 'archived',
}

export enum WorkflowStatusEnum {
  started = 'started',
  finished = 'finished',
  criticalError = 'criticalError',
  error = 'error',
  warning = 'warning',
  closed = 'closed',
}

export enum WorkflowTemplateErrorEnum {
  WorkflowNotReachableNodeError = 'WorkflowNotReachableNodeError',
  WorkflowCircularDependencyError = 'WorkflowCircularDependencyError',
  ReferencedWorkflowNodeNotExistError = 'ReferencedWorkflowNodeNotExistError',
  WorkflowPortShouldHaveOneEdgeError = 'WorkflowPortShouldHaveOneEdgeError',
  WorkflowTailMustHaveNoNoOutputPortError = 'WorkflowTailMustHaveNoNoOutputPortError',
  WorkflowNodeWithoutRoleError = 'WorkflowNodeWithoutRoleError',
  WorkflowInvalidDocumentAliasError = 'WorkflowInvalidDocumentAliasError',
  WorkflowInvalidRoleError = 'WorkflowInvalidRoleError',
  WorkflowNodeDuplicateRoleError = 'WorkflowNodeDuplicateRoleError',
  WorkflowHeadShouldHaveOneOutputPortError = 'WorkflowHeadShouldHaveOneOutputPortError',
  WorkflowTemplateShouldHaveOneHeadError = 'WorkflowTemplateShouldHaveOneHeadError',
  WorkflowTemplateDuplicateNameError = 'WorkflowTemplateDuplicateNameError',
  UserOrRoleCanBeSetTempError = 'UserOrRoleCanBeSetTempError',
  WorkflowInvalidTailNodeError = 'WorkflowInvalidTailNodeError',
  NestedSubWorkflowError = 'NestedSubWorkflowError',
  WorkflowInvalidRequiredVotersCount = 'WorkflowInvalidRequiredVotersCount',
  SubworkflowsCountError = 'SubworkflowsCountError',
  SubworkflowRequestedVoteCountError = 'SubworkflowRequestedVoteCountError',
  WorkflowInvalidUserError = 'WorkflowInvalidUserError',
  NotifyNodeShouldHaveMessageError = 'NotifyNodeShouldHaveMessageError',
  OnlyNotifyNodeCanHaveMessageError = 'OnlyNotifyNodeCanHaveMessageError',
  WorkflowNodeMessageDuplicateuserError = 'WorkflowNodeMessageDuplicateuserError',
  DocumentRequestsWithSameNameError = 'DocumentRequestsWithSameNameError',
  MessageSubjectEmptyError = 'MessageSubjectEmptyError',
  MessageMessageEmptyError = 'MessageMessageEmptyError',
  MessageNoRecipientsError = 'MessageNoRecipientsError',
  WorkflowBDRoleWithoutUserError = 'WorkflowBDRoleWithoutUserError',
  WorkflowNodeRoleWithoutUserError = 'WorkflowNodeRoleWithoutUserError',
  WorkflowNodeEmptyAddDocumentPermittedActionsError = 'WorkflowNodeEmptyAddDocumentPermittedActionsError',
  WorkflowNodeEmptyDestinationDocumentStateError = 'WorkflowNodeEmptyDestinationDocumentStateError',
  WorkflowNodeEmptyAddDocumentPermittedStatesError = 'WorkflowNodeEmptyAddDocumentPermittedStatesError',
  WorkflowEmptyAddDocumentPermittedStatesError = 'WorkflowEmptyAddDocumentPermittedStatesError',
}

export enum WorkflowTemplateErrorSeverityEnum {
  Error = 'Error',
  Warning = 'Warning',
}

export enum WorkflowTemplateStateEnum {
  valid = 'valid',
  invalid = 'invalid',
  validWithInvalid = 'validWithInvalid',
}

export interface AcceptBackgroundDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
}

export interface AddBackgroundDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
  name: string;
  description?: string;
  directoryId: Guid;
  uploadId: Guid;
  labels?: Guid[];
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  categoryTreeNodes?: Dictionary<Guid>;
  isModel: boolean;
  ownedById?: Guid;
}

export interface AppConflictedStructDto {
  directories?: Dictionary<ConflictedDataDto>;
  documents?: Dictionary<ConflictedDataDto>;
}

export interface AppConflictedStructRequestDto {
  directories?: Guid[];
  documents?: Guid[];
}

export interface AppDirectoriesDto {
  labels?: LabelDto[];
  rootIds?: Guid[];
  rootPaths?: string[];
  currentUser: ProjectUserProfileDto;
  directoryContents?: Dictionary<DirectoryContentDto[]>;
  directories?: DirectoryAppDto[];
  references?: DocumentReferenceDto[];
}

export interface AppDirectoriesRequestDto {
  rootIds?: Guid[];
}

export interface AppSettingsDto {
  defaultProjectLanguage: LanguageEnum;
  sharedDownloadExpiredDate: number;
  refreshListInterval: number;
  refreshListErrorInterval: number;
  idleTimeLogoutInterval: number;
  idleTimeWarningInterval: number;
  reservationTime: number;
  commentProcedureCommentStateMap?: Dictionary<Dictionary<Dictionary<CommentPermissionEnum[]>>>;
  esticonTemplateTags?: Dictionary<string>;
  esticonDirectoryTemplateTagMap?: Dictionary<EsticonTemplateEnum[]>;
  esticonFileTemplateTagMap?: Dictionary<EsticonTemplateEnum[]>;
  documentStateNumberMap?: Dictionary<number>;
  forgeSearchLocaltionsId?: ForgeSearchLocationSettingsDto[];
  forgeSearchLocaltionsName?: ForgeSearchLocationSettingsDto[];
}

export interface AppUserDto {
  id: Guid;
  username?: string;
  isAdmin: boolean;
  language: LanguageEnum;
  profilePicture?: string;
  firstname?: string;
  lastname?: string;
  lastAccessDate?: IsoDateTime;
}

export interface AppUserLangSetDto {
  language: LanguageEnum;
}

export interface AppUserProfileDto {
  id: Guid;
  aspeIdSrvId?: Guid;
  username?: string;
  isAdmin: boolean;
  locked: boolean;
  language: LanguageEnum;
  profilePicture?: string;
  data?: string;
  organizationUsers?: OrganizationUserInfoDto[];
  firstname?: string;
  lastname?: string;
  lastAccessDate?: IsoDateTime;
}

export interface AppUserReportItem {
  name: ProjectReportTypeEnum;
  position: number;
  big: boolean;
  visible: boolean;
}

export interface AppUserRequestDto {
  language: LanguageEnum;
  mail: string;
  isAdmin: boolean;
}

export interface AppUserShareDto {
  userIds: Guid[];
  message?: string;
  documentIds?: Guid[];
  documentLinksIds?: Guid[];
  directoryId?: Guid;
}

export interface AppUsersRequestDto {
  users: AppUserRequestDto[];
}

export interface AssigmentSummaryDto {
  new: number;
  inProgress: number;
  forApproval: number;
}

export interface AssignBackgroundDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
  revisionId: Guid;
}

export interface AssignmenListDto {
  assignments?: AssignmentDto[];
}

export interface AssignmentAnnotationListSaveDto {
  annotationAssignments?: AssignmentAnnotationSaveDto[];
  revisionId: Guid;
}

export interface AssignmentAnnotationSaveDto {
  name: string;
  content?: string;
  taskGiverId?: Guid;
  type: AssignmentTypeEnum;
  deadlineDate?: IsoDateTime;
  annotation: DocumentAnnotationSaveDto;
  newState: AssignmentStateEnum;
  solverIds?: Guid[];
  associateIds?: Guid[];
  primarySolverId?: Guid;
  id?: Guid;
}

export interface AssignmentAssociatesPatchDto {
  associateIds?: Guid[];
}

export interface AssignmentAttachmentAssignDto {
  name: string;
  documentId: Guid;
  revisionId: Guid;
}

export interface AssignmentAttachmentAssignListDto {
  assignedDocuments: AssignmentAttachmentAssignDto[];
}

export interface AssignmentAttachmentCreateDto {
  name: string;
  uploadId: Guid;
}

export interface AssignmentAttachmentCreateMultipleDto {
  attachments: AssignmentAttachmentCreateDto[];
  assignmentNoteId?: Guid;
}

export interface AssignmentAttachmentDto {
  name: string;
  createdDate: IsoDateTime;
  file: FileDto;
  id: Guid;
  canEdit: boolean;
}

export interface AssignmentAttachmentsDownloadDto {
  name: string;
  ids: Guid[];
}

export interface AssignmentCreateDto {
  name: string;
  content?: string;
  type: AssignmentTypeEnum;
  deadlineDate?: IsoDateTime;
  assignmentDocumentIds?: Guid[];
  solverIds: Guid[];
  taskGiverId?: Guid;
  associateIds?: Guid[];
}

export interface AssignmentDocumentDto {
  document: DocumentDto;
  currentRevision: RevisionDto;
  revision: RevisionDto;
}

export interface AssignmentDocumentsPatchDto {
  assignmentDocumentIds: Guid[];
}

export interface AssignmentDto {
  id: Guid;
  name: string;
  orderId: number;
  content?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedById?: Guid;
  modifiedDate?: IsoDateTime;
  type: AssignmentTypeEnum;
  state: AssignmentStateEnum;
  primarySolver: ProjectUserProfileListDto;
  deadlineDate?: IsoDateTime;
  resolveDate?: IsoDateTime;
  taskGiver: ProjectUserProfileListDto;
  solvers: ProjectUserProfileListDto[];
  associates: ProjectUserProfileListDto[];
  documents?: AssignmentDocumentDto[];
  assignmentAttachments: AssignmentAttachmentDto[];
  assignmentNotes: AssignmentNoteDto[];
  documentAnnotation: DocumentAnnotationDto;
}

export interface AssignmentForExportRequestDto {
  id: Guid;
  projectId: Guid;
  typ: string;
  state: string;
  createdDate: string;
  deadlineDate?: string;
  resolveDate?: string;
}

export interface AssignmentListForExportRequestDto {
  resultFileFormat: ResultFileFormatEnum;
  nameColDesc: string;
  projectNameColDesc: string;
  typeColDesc: string;
  stateColDesc: string;
  createdDateColDesc: string;
  taskGiverColDesc: string;
  associateColDesc: string;
  deadlineDateColDesc: string;
  resolveDateColDesc: string;
  contentColDesc: string;
  solverColDesc: string;
  assignmentDatas: AssignmentForExportRequestDto[];
  language: LanguageEnum;
}

export interface AssignmentMessageDto {
  id: Guid;
  name: string;
  data: string;
  createdBy: AppUserProfileDto;
  taskGiver: AppUserProfileDto;
  createdDate: IsoDateTime;
  projectId: Guid;
  assignmentId: Guid;
  orderId: number;
  deadlineDate?: IsoDateTime;
  resolveDate?: IsoDateTime;
  state: AssignmentStateEnum;
  type: AssignmentTypeEnum;
}

export interface AssignmentNoteCreateDto {
  title?: string;
  message: string;
  linkedAttachments: Guid[];
  userNotifications?: Guid[];
}

export interface AssignmentNoteDto {
  id: Guid;
  title: string;
  message?: string;
  state: AssignmentStateEnum;
  isDiscarded: boolean;
  createdDate: IsoDateTime;
  modifiedDate: IsoDateTime;
  discardedDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  modifiedBy: ProjectUserProfileListDto;
  discardedBy: ProjectUserProfileListDto;
  canUserEdit: boolean;
  assignmentNoteAttachments?: Guid[];
  notifiedUsers?: ProjectUserProfileListDto[];
}

export interface AssignmentNoteEditDto {
  title?: string;
  message: string;
  linkedAttachments: Guid[];
}

export interface AssignmentPatchDto {
  name: string;
  content?: string;
  taskGiverId?: Guid;
  type: AssignmentTypeEnum;
  deadlineDate?: IsoDateTime;
}

export interface AssignmentSearchSort {
  direction: SortOrder;
  property: AssignmentOrderTypeEnum;
}

export interface AssignmentSetStateDto {
  newState: AssignmentStateEnum;
}

export interface AssignmentSolversPatchDto {
  solverIds?: Guid[];
  primarySolverId?: Guid;
}

export interface AssignmentUserSearch {
  users?: Guid[];
  sources?: AssignmentSourceEnum[];
}

export interface AuditLogAdminRequestDto {
  eventNamesFilter?: EventNamesEnum[];
  timeFromFilter: IsoDateTime;
  timeToFilter: IsoDateTime;
  size: number;
  from: number;
  entityTypesFilter?: EntityTypesEnum[];
}

export interface AuditLogConnectionDto {
  id: number;
  ipAddress?: string;
  requestAddress?: string;
  method?: string;
}

export interface AuditLogDMSRequestDto {
  auditLogTranslationsDto: AuditLogTranslationsDto;
}

export interface AuditLogDto {
  id: number;
  entityType: EntityTypesEnum;
  createdDate: IsoDateTime;
  entityId: Guid;
  eventName: EventNamesEnum;
  createdBy: AuditLogUserProfileListDto;
  eventData?: Record<AuditLogEventData, string>;
  entityData?: AuditLogEntityData;
  parentId?: number;
  connectionId: number;
}

export interface AuditLogEntityRequestDto {
  eventNamesFilter?: EventNamesEnum[];
  timeFromFilter: IsoDateTime;
  timeToFilter: IsoDateTime;
  size: number;
  from: number;
  entityId: Guid;
  entityType: EntityTypesEnum;
}

export interface AuditLogListResponseDto {
  auditLogs?: AuditLogDto[];
  size: number;
  from: number;
  took: number;
  total: number;
}

export interface AuditLogResponseDto {
  auditLogs?: AuditLogDto[];
}

export interface AuditLogSessionDto {
  sessionHash?: string;
  ipAddress?: string;
  sessionStart: IsoDateTime;
  sessionEnd: IsoDateTime;
}

export interface AuditLogSessionListRequestDto {
  userId: Guid;
  from: IsoDateTime;
  to: IsoDateTime;
  order?: AuditLogSessionSearchSort[];
  pageSize: number;
  currentPage: number;
}

export interface AuditLogSessionResponseDto {
  sessions?: AuditLogSessionDto[];
  size: number;
  from: number;
  took: number;
  total: number;
}

export interface AuditLogSessionSearchSort {
  direction: SortOrder;
  property: AuditLogSessionOrderTypeEnum;
}

export interface AuditLogStavbaRequestDto {
  eventNamesFilter?: EventNamesEnum[];
  timeFromFilter: IsoDateTime;
  timeToFilter: IsoDateTime;
  size: number;
  from: number;
  esticonCategoryTypesFilter?: EstiCategoryEnum[];
}

export interface AuditLogTranslationsDto {
  language: LanguageEnum;
  localization?: Dictionary<string>;
}

export interface AuditLogUserActivityRequestDto {
  userId: Guid;
  sessionHash: string;
}

export interface AuditLogUserActivityResponseDto {
  auditLogs?: AuditLogDto[];
  connections?: AuditLogConnectionDto[];
}

export interface AuditLogUserProfileListDto {
  id: Guid;
  username?: string;
  firstname?: string;
  lastname?: string;
  status: ProjectUserProfileStatusEnum;
  isAppUser: boolean;
}

export interface AuditLogUsersMonthlyRequestDto {
  auditLogTranslationsDto: AuditLogTranslationsDto;
  startTime?: IsoDateTime;
  endTime?: IsoDateTime;
}

export interface AuthenticateWopiRequestDto {
  claims: WopiTokenTokenClaims;
}

export interface AuthenticateWopiResponseDto {
  aspeToken: string;
  projectId: Guid;
  documentId: Guid;
  documentName: string;
  user: string;
  version: string;
  newRevisionDesc?: string;
  newRevisionState: WorkflowStateEnum;
  fileName: string;
  fileExt: string;
  clientAddress: string;
}

export interface BackgroundDocumentAddRequestDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  name?: string;
  description?: string;
  insertionTerm: IsoDateTime;
  workflowDefaultDirectoryId: Guid;
  responsibleRoleId?: Guid;
  responsibleUserId?: Guid;
}

export interface BackgroundDocumentPatchRequestDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
  name?: string;
  description?: string;
  insertionTerm?: IsoDateTime;
  workflowDefaultDirectoryId?: Guid;
  responsibleRoleId?: Guid;
  responsibleUserId?: Guid;
}

export interface BackgroundDocumentRemoveRequestDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
}

export interface BlobDerivateDto {
  status: BlobDerivateStatusEnum;
  url?: string;
  expires: IsoDateTime;
  data?: unknown;
  contentType?: string;
}

export interface BlobDto {
  isMetadata: boolean;
  blobId: Guid;
  name?: string;
  ext?: string;
  size: number;
  contentType?: string;
  thumbnailUrl?: string;
  derivates?: Dictionary<BlobDerivateDto>;
}

export interface BlobsRequestDto {
  tokens?: Dictionary<string>;
}

export interface BlobsResponseDto {
  blobs?: Dictionary<BlobDto>;
}

export interface CalendarDto {
  id: Guid;
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  fr: boolean;
  sa: boolean;
  su: boolean;
  excludedDays?: ExcludedDayDto[];
}

export interface CalendarRequestDto {
  organizationId: Guid;
  projectId?: Guid;
}

export interface CalendarResponseDto {
  orgCalendar: CalendarDto;
  projectCalendar: CalendarDto;
}

export interface CalendarSetDto {
  organizationId: Guid;
  projectId?: Guid;
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  fr: boolean;
  sa: boolean;
  su: boolean;
  excludedDays?: ExcludedDaySetDto[];
}

export interface CalendarTemplateDto {
  id: Guid;
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  fr: boolean;
  sa: boolean;
  su: boolean;
  excludedDays?: ExcludedDayTemplateDto[];
}

export interface CommentProcedureCategoryCreateDto {
  name?: string;
  description?: string;
}

export interface CommentProcedureCategoryDto {
  id: Guid;
  name: string;
  description: string;
}

export interface CommentProcedureCategoryPatchDto {
  name?: string;
  description?: string;
}

export interface CommentProcedureCommentAttachmentAssignDto {
  name: string;
  documentId: Guid;
  revisionId: Guid;
}

export interface CommentProcedureCommentAttachmentAssignListDto {
  assignedDocuments: CommentProcedureCommentAttachmentAssignDto[];
  discussionRelation: CommentProcedureCommentDiscussionRelationEnum;
}

export interface CommentProcedureCommentAttachmentCreateDto {
  name: string;
  uploadId: Guid;
}

export interface CommentProcedureCommentAttachmentCreateMultipleDto {
  attachments: CommentProcedureCommentAttachmentCreateDto[];
  attachToCommentMessage: boolean;
  attachToCommentAddition: boolean;
  commentNoteId?: Guid;
  discussionRelation: CommentProcedureCommentDiscussionRelationEnum;
}

export interface CommentProcedureCommentAttachmentDto {
  name: string;
  createdDate: IsoDateTime;
  file: FileDto;
  id: Guid;
  canEdit: boolean;
  attachmentRelation: CommentProcedureCommentDiscussionRelationEnum;
}

export interface CommentProcedureCommentAttachmentsDownloadDto {
  name: string;
  ids: Guid[];
}

export interface CommentProcedureCommentCreateDto {
  title: string;
  message?: string;
  commentProcedureCommentDocuments: CommentProcedureCommentDocumentCreateDto[];
  commentProcedureSubmitterTeamIds?: Guid[];
  commentProcedureCategoryId?: Guid;
  newDocumentAnnotations: DocumentAnnotationsSaveDto;
}

export interface CommentProcedureCommentDocumentCreateDto {
  commentProcedureDocumentId: Guid;
  references?: unknown;
}

export interface CommentProcedureCommentDocumentDto {
  id: Guid;
  commentProcedureDocument: CommentProcedureDocumentDto;
  references?: unknown;
}

export interface CommentProcedureCommentDto {
  id: Guid;
  orderId: number;
  title: string;
  message?: string;
  discussionAddition?: string;
  createdBy: ProjectUserProfileListDto;
  state: CommentProcedureCommentStateEnum;
  commentProcedureCommentAttachments: CommentProcedureCommentAttachmentDto[];
  commentProcedureCommentDocuments: CommentProcedureCommentDocumentDto[];
  commentProcedureCommentNotes: CommentProcedureCommentNoteDto[];
  commentProcedureCommentInternalSubmitterNotes: CommentProcedureCommentNoteDto[];
  commentProcedureCommentInternalProcessorNotes: CommentProcedureCommentNoteDto[];
  commentProcedureCategory: CommentProcedureCategoryDto;
  commentProcedureSubmitterTeams?: CommentProcedureTeamDto[];
  commentProcedureProcessorTeams?: CommentProcedureTeamDto[];
  createdDate: IsoDateTime;
  commentProcedureCommentMessageAttachments?: Guid[];
  commentProcedureCommentDiscussionAdditionAttachments?: Guid[];
  documentAnnotations?: DocumentAnnotationDto[];
}

export interface CommentProcedureCommentListDto {
  id: Guid;
  commentProcedureId: Guid;
  orderId: number;
  title: string;
  createdBy: ProjectUserProfileListDto;
  state: CommentProcedureCommentStateEnum;
  createdDate: IsoDateTime;
  isMember: boolean;
  isSubmitter: boolean;
}

export interface CommentProcedureCommentNoteCreateDto {
  title?: string;
  message?: string;
  noteType: CommentProcedureCommentNoteNoteTypeEnum;
  linkedAttachments?: Guid[];
  userNotifications?: Guid[];
}

export interface CommentProcedureCommentNoteDto {
  id: Guid;
  title: string;
  message?: string;
  state: CommentProcedurePhaseEnum;
  isDiscarded: boolean;
  createdDate: IsoDateTime;
  modifiedDate: IsoDateTime;
  discardedDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  modifiedBy: ProjectUserProfileListDto;
  discardedBy: ProjectUserProfileListDto;
  authorRelation: CommentProcedureCommentDiscussionRelationEnum;
  canUserEdit: boolean;
  commentProcedureCommentNoteAttachments?: Guid[];
  notifiedUsers?: ProjectUserProfileListDto[];
}

export interface CommentProcedureCommentNoteEditDto {
  title?: string;
  message: string;
  linkedAttachments: Guid[];
}

export interface CommentProcedureCommentOverviewDto {
  id: Guid;
  orderId: number;
  title: string;
  createdBy: ProjectUserProfileListDto;
  state: CommentProcedureCommentStateEnum;
  createdDate: IsoDateTime;
  commentProcedureCommentDocumentIds: Guid[];
  commentProcedureCategoryId: Guid;
  commentProcedureSubmitterTeamIds?: Guid[];
  commentProcedureProcessorTeamIds?: Guid[];
  documentAnnotations?: DocumentAnnotationDto[];
}

export interface CommentProcedureCommentPatchDto {
  title?: string;
  message?: string;
  discussionAddition?: string;
  commentProcedureCategoryId?: Guid;
  commentProcedureSubmitterTeamIds?: Guid[];
  commentProcedureProcessorTeamIds?: Guid[];
  commentProcedureMessageAttachmentLinks?: Guid[];
  commentProcedureDiscusionAttachmentLinks?: Guid[];
}

export interface CommentProcedureCommentSetStateDto {
  state: CommentProcedureCommentStateEnum;
}

export interface CommentProcedureCommentStatePatchDto {
  state: CommentProcedureCommentStateEnum;
  commentProcedureComments: Guid[];
}

export interface CommentProcedureCreateDto {
  name: string;
  description?: string;
  commentProcedureDocumentIds?: Guid[];
  labels?: Guid[];
  submitterRoleIds: Guid[];
  processorRoleIds: Guid[];
  deadline?: IsoDate;
}

export interface CommentProcedureDirectoryDto {
  id: Guid;
  name?: string;
  path?: string;
  currentAccessLevel: AccessLevelEnum;
}

export interface CommentProcedureDocumentAssignDto {
  assignedDocumentIds?: Guid[];
}

export interface CommentProcedureDocumentCreateDto {
  name: string;
  description?: string;
  directoryId: Guid;
  uploadId: Guid;
  labels?: Guid[];
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  categoryTreeNodes?: Dictionary<Guid>;
  isModel: boolean;
  state: WorkflowStateEnum;
  ownedById?: Guid;
}

export interface CommentProcedureDocumentDetailDto {
  commentProcedures: CommentProcedureListDto[];
  commentProcedureComments: CommentProcedureCommentListDto[];
}

export interface CommentProcedureDocumentDto {
  id: Guid;
  document: DocumentDto;
  revisions?: RevisionDto[];
  isInComment: boolean;
  canAddRevision: boolean;
  canShowDetail: boolean;
}

export interface CommentProcedureDto {
  id: Guid;
  orderId: number;
  name: string;
  description?: string;
  version: number;
  labels?: Guid[];
  state: CommentProcedurePhaseEnum;
  errorState: CommentProcedureErrorStateEnum;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  deadline?: IsoDateTime;
  deadlineChangeReason?: string;
  commentProcedureDocuments: CommentProcedureDocumentDto[];
  commentProcedureComments: CommentProcedureCommentOverviewDto[];
  submitterRoles: CommentProcedureRoleDto[];
  processorRoles: CommentProcedureRoleDto[];
  submitterTeams: CommentProcedureTeamDto[];
  processorTeams: CommentProcedureTeamDto[];
  commentProcedureCategories: CommentProcedureCategoryDto[];
  workflow: WorkflowListDto;
  commentProcedureDocumentPaths: Dictionary<Guid[]>;
  errors?: CommentProcedureErrorDto[];
}

export interface CommentProcedureErrorDto {
  severity: ErrorSeverityEnum;
  subject: ErrorCommentProcedureSubjectEnum;
  roleOrUserId: Guid;
  roleOrUserName?: string;
  subjectId: Guid;
  subjectName?: string;
  commentProcedureRole: CommentProcedureRoleEnum;
}

export interface CommentProcedureExportSettingsDto {
  filename?: string;
  inline: boolean;
  orderBy: CommentProcedureExportOrderEnum;
  orderDescending: boolean;
  statesFilter?: CommentProcedureCommentStateEnum[];
  authorsFilter?: Guid[];
  submittersTeamFilter?: Guid[];
  processorsTeamFilter?: Guid[];
  categoryFilter?: Guid[];
}

export interface CommentProcedureChangeDeadlineDto {
  deadline?: IsoDate;
  reason: string;
}

export interface CommentProcedureListDto {
  id: Guid;
  orderId: number;
  name: string;
  description?: string;
  labels?: Guid[];
  state: CommentProcedurePhaseEnum;
  errorState: CommentProcedureErrorStateEnum;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  deadline?: IsoDateTime;
  isMember: boolean;
  isSubmitter: boolean;
  workflowId?: Guid;
  workflowName?: string;
  workflowOrderId?: number;
  workflowHasUserAccess: boolean;
  documentDirectories?: CommentProcedureDirectoryDto[];
  commentProcedureUsers?: ProjectUserProfileListDto[];
}

export interface CommentProcedurePatchDto {
  name?: string;
  description?: string;
  labels?: Guid[];
}

export interface CommentProcedureProjectSettingsDto {
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
}

export interface CommentProcedureProjectSettingsPatchDto {
  primarySubmitterCanEditProcessorTeams?: boolean;
  primaryProcessorCanEditSubmitterTeams?: boolean;
  useWorkDays?: boolean;
}

export interface CommentProcedureRoleDto {
  id: Guid;
  role: RoleDto;
}

export interface CommentProcedureRolePatchDto {
  submitterRoleIds?: Guid[];
  processorRoleIds?: Guid[];
}

export interface CommentProcedureSetStateDto {
  state: CommentProcedurePhaseEnum;
  approveDocuments?: boolean;
  deadline?: IsoDate;
  strategy: CommentProcedureSetStateStrategyEnum;
}

export interface CommentProcedureTeamCreateDto {
  name?: string;
  commentProcedureRole: CommentProcedureRoleEnum;
}

export interface CommentProcedureTeamDto {
  id: Guid;
  name: string;
  commentProcedureUsers: CommentProcedureUserDto[];
  commentProcedureRole: CommentProcedureRoleEnum;
  membersCanChangeState: boolean;
}

export interface CommentProcedureTeamPatchDto {
  name?: string;
  membersCanChangeState?: boolean;
}

export interface CommentProcedureUserCreateDto {
  userId: Guid;
  commentProcedurePermission: CommentProcedurePermissionEnum;
}

export interface CommentProcedureUserDto {
  id: Guid;
  user: ProjectUserProfileListDto;
  commentProcedurePermission: CommentProcedurePermissionEnum;
}

export interface CommentProcedureUserPatchDto {
  commentProcedurePermission: CommentProcedurePermissionEnum;
}

export interface ConfirmBackgroundDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
}

export interface ConflictedDataDto {
  path: string;
  isDiscarded: boolean;
  currentAccessLevel: AccessLevelEnum;
}

export interface CurrentRevisionDto {
  id: Guid;
  number: number;
  description?: string;
  createdDate: IsoDateTime;
  discardedBy: ProjectUserProfileListDto;
  discardedDate?: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  revisionMetaData?: Dictionary<Dictionary<string>>;
  revisionState: WorkflowStateEnum;
}

export interface DeepShareDownloadDto {
  emails: string[];
  shareMessage?: string;
  sendMails: boolean;
  requiredPin: boolean;
  includeSubdirectories: boolean;
  directoryId: Guid;
  ignoredDocumentsIds?: Guid[];
  ignoredDocumentLinksIds?: Guid[];
}

export interface DelegateWorkflowNodeDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowNodeRoleId: Guid;
  roleId: Guid;
  message?: string;
}

export interface DirectoryAccessInfoDto {
  users?: ProjectUserProfileListDto[];
  groups?: GroupListDto[];
}

export interface DirectoryAppDto {
  id: Guid;
  name: string;
  description?: string;
  parentId?: Guid;
  permissionInheritance: boolean;
  requiredCategoryTrees?: Dictionary<Guid>;
  currentAccessLevel: AccessLevelEnum;
  path?: string;
  idPath?: string;
}

export interface DirectoryContentDto {
  id: Guid;
  name: string;
  ext?: string;
  description?: string;
  directoryId: Guid;
  directoryPath: string;
  currentRevision: CurrentRevisionDto;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedById?: Guid;
  reservedDate?: IsoDateTime;
  reservedBy: ProjectUserProfileListDto;
  releaseReservedDate?: IsoDateTime;
  ownedBy: RoleDto;
  state: WorkflowStateEnum;
  modifiedDate?: IsoDateTime;
  labels?: Guid[];
  primaryFile: PrimaryFileDto;
  workflowId?: Guid;
  commentProcedureId?: Guid;
  esticonObjectLink: EsticonObjectLinkDto;
  isLink: boolean;
  isModel: boolean;
}

export interface DirectoryContentsExDto {
  documents?: DirectoryContentDto[];
  documentLinks?: DocumentLinkDto[];
}

export interface DirectoryCreateDto {
  name: string;
  description?: string;
  permissionInheritance: boolean;
  parentId: Guid;
  requiredCategoryTrees?: Dictionary<Guid>;
}

export interface DirectoryDetailDto {
  id: Guid;
  name: string;
  description?: string;
  parentId?: Guid;
  permissionInheritance: boolean;
  requiredCategoryTrees?: Dictionary<Guid>;
  currentAccessLevel: AccessLevelEnum;
  currentWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  inheritedWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  statistic: DirectoryStatisticDto;
}

export interface DirectoryDownloadDto {
  name: string;
  withFiles: boolean;
  withSubfolders: boolean;
  ignoreDirectoryLink: boolean;
  ignoredDocumentsIds?: Guid[];
  ignoredDocumentLinksIds?: Guid[];
  ignoredDirectoryLinksIds?: Guid[];
}

export interface DirectoryDto {
  id: Guid;
  name: string;
  description?: string;
  parentId?: Guid;
  permissionInheritance: boolean;
  requiredCategoryTrees?: Dictionary<Guid>;
  currentAccessLevel: AccessLevelEnum;
  currentWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  inheritedWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
}

export interface DirectoryGroupDto {
  groupId: Guid;
  name?: string;
  accessLevel: AccessLevelEnum;
  inheritedAccessLevel: AccessLevelEnum;
}

export interface DirectoryGroupPatchDto {
  groupId: Guid;
  accessLevel: AccessLevelEnum;
}

export interface DirectoryLinkCreateDto {
  name?: string;
  directoryId: Guid;
  linkedDirectoryId: Guid;
}

export interface DirectoryLinkDto {
  linkedDirectory: DirectoryDto;
  discardedLinkedDirectory: DiscardedDirectoryDto;
  directory: DirectoryDto;
  id: Guid;
  name?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedById?: Guid;
  modifiedDate?: IsoDateTime;
}

export interface DirectoryLinkListDto {
  linkedDirectoryId: Guid;
  directoryId: Guid;
  isDiscarded: boolean;
  id: Guid;
  name?: string;
}

export interface DirectoryLinkMoveDto {
  destination: Guid;
  name?: string;
}

export interface DirectoryLinkPatchDto {
  name?: string;
}

export interface DirectoryListDto {
  id: Guid;
  name?: string;
  description?: string;
  parentId?: Guid;
  itemsCount: number;
  subItemsCount: number;
  path?: string;
  isFavorite: boolean;
  currentAccessLevel: AccessLevelEnum;
  isEstiConDir: boolean;
  currentWatches?: DirectorySubscriberEnumsWatchDirectoryEnum[];
}

export interface DirectoryListsExDto {
  directories?: DirectoryListDto[];
  directoryLinks?: DirectoryLinkListDto[];
}

export interface DirectoryMoveDto {
  destination: Guid;
  name?: string;
  preserveAccessRights: boolean;
}

export interface DirectoryOverviewDto {
  id: Guid;
  name: string;
  requiredCategoryTrees?: Dictionary<Guid>;
}

export interface DirectoryPatchDto {
  name?: string;
  description?: string;
  requiredCategoryTrees?: Dictionary<Guid>;
}

export interface DirectoryPermissionDto {
  permissionInheritance: boolean;
  groups?: DirectoryGroupDto[];
  users?: DirectoryUserDto[];
}

export interface DirectoryPermissionPatchDto {
  permissionInheritance: boolean;
  groups?: DirectoryGroupPatchDto[];
  users?: DirectoryUserPatchDto[];
}

export interface DirectoryReportSettingsDto {
  inline: boolean;
  from?: IsoDateTime;
  to?: IsoDateTime;
  orderBy: DirectoryReportOrderTypeEnum;
  orderDescending: boolean;
  includeAllRevisions: boolean;
  includeSubdirectories: boolean;
  authorsFilter?: Guid[];
}

export interface DirectoryRestoreResultDto {
  newFilePaths?: RestoreFilePathItemDto[];
  newDirectoryPaths?: RestoreDirectoryPathItemDto[];
  newFileName?: RestoreFileNameItemDto[];
  categoryRemovedFrom?: string[];
  requireCategoryRemovedFrom?: string[];
  labelRemovedFrom?: string[];
  isAny: boolean;
  restoredDocuments: number;
  restoredDirectories: number;
}

export interface DirectorySelectionDto {
  directoryId: Guid;
  workflowStates?: WorkflowStateEnum[];
  withSubfolders: boolean;
}

export interface DirectorySelectionResultDto {
  documents?: DirectoryContentDto[];
  documentLinks?: DocumentLinkDto[];
}

export interface DirectoryStatisticDto {
  type: DirectoryTypeEnum;
  dirSize: number;
  dirFullSize: number;
  dirTreeSize: number;
  dirFullTreeSize: number;
  dirDocCount: number;
  dirTreeDocCount: number;
  dirDocLinkCount: number;
  dirTreeDocLinkCount: number;
  subDirCount: number;
  subDirTreeCount: number;
}

export interface DirectorySubscriberSetDto {
  watchTypes?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  interuptSubfolderInheritence: boolean;
}

export interface DirectoryUncheckDto {
  parentId?: Guid;
  permissionInheritance: boolean;
  requiredCategoryTrees?: Dictionary<Guid>;
  currentAccessLevel: AccessLevelEnum;
  currentWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  inheritedWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  id: Guid;
  name: string;
  path: string;
  description?: string;
  isDiscarded: boolean;
}

export interface DirectoryUserDto {
  userId: Guid;
  username?: string;
  accessLevel: AccessLevelEnum;
  inheritedAccessLevel: AccessLevelEnum;
}

export interface DirectoryUserPatchDto {
  userId: Guid;
  accessLevel: AccessLevelEnum;
}

export interface DiscardedDirectoryDto {
  id: Guid;
  name: string;
  description?: string;
  parentId?: Guid;
  permissionInheritance: boolean;
  requiredCategoryTrees?: Dictionary<Guid>;
  currentAccessLevel: AccessLevelEnum;
  currentWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  inheritedWatch?: DirectorySubscriberEnumsWatchDirectoryEnum[];
  path?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  discardedBy: ProjectUserProfileListDto;
  discardedDate?: IsoDateTime;
}

export interface DiscardedDocumentDto {
  id: Guid;
  name?: string;
  ext?: string;
  description?: string;
  directoryId: Guid;
  directoryPath?: string;
  primaryFile: PrimaryFileDto;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  discardedBy: ProjectUserProfileListDto;
  discardedDate?: IsoDateTime;
  state: WorkflowStateEnum;
  revisionCreatedBy: ProjectUserProfileListDto;
  revisionCreatedDate: IsoDateTime;
  revisionNumber: number;
}

export interface DMSDownloadDto {
  onlyCurrentRevisions: boolean;
}

export interface DMSMetadataRequestDto {
  language: LanguageEnum;
}

export interface DocToDocLinkCreateDto {
  linkingDocumentIds: Guid[];
  linkedDocumentId: Guid;
}

export interface DocToDocLinkCreateResultsDto {
  linkingDocumentIds: Guid[];
  errors: DocumentEvaluationErrorDto[];
}

export interface DocToDocLinkDeleteResultsDto {
  linkIds: Guid[];
  forbiddenLinkIds: Guid[];
}

export interface DocToDocLinkDto {
  oppositeDocument: OppositeDocumentDto;
  isLinkToElement: boolean;
  linkedDocumentRevisionId: Guid;
  linkedDocumentRevisionNumber: number;
  elementId?: string;
  name?: string;
  attributeName?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedDate?: IsoDateTime;
  modifiedById?: Guid;
}

export interface DocToDocLinksDto {
  linkIds: Guid[];
}

export interface DocToDocLinksListDto {
  links?: Dictionary<DocToDocLinkDto>;
  insideModelLinks?: Guid[];
  outerModelLinks?: Guid[];
  docToDocLinks?: Guid[];
}

export interface DocumentAnnotationDto {
  id: Guid;
  documentId: Guid;
  revisionId: Guid;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  content?: string;
  position?: unknown;
  annotationData?: unknown;
  file: FileDto;
}

export interface DocumentAnnotationSaveDto {
  content?: string;
  position?: unknown;
  id: Guid;
  annotationData?: unknown;
  blobId?: Guid;
}

export interface DocumentAnnotationsDto {
  annotations?: DocumentAnnotationDto[];
}

export interface DocumentAnnotationsSaveDto {
  revisionId: Guid;
  annotations?: DocumentAnnotationSaveDto[];
}

export interface DocumentBulkActionSetResultDto {
  updatedDocuments: number;
  updatedLinks: number;
  unchangedDocuments: number;
  unchangedLinks: number;
}

export interface DocumentBulkLabelsSetDto {
  selectedLabels?: Guid[];
  labelsSetMode: DocumentLabelsSetModeEnum;
  documentsIds?: Guid[];
  documentLinksIds?: Guid[];
  ignoredDocumentIds?: Guid[];
  ignoredDocumentLinksIds?: Guid[];
}

export interface DocumentBulkStateCheckDto {
  documentsIds?: Guid[];
  documentLinksIds?: Guid[];
}

export interface DocumentBulkStateCheckResultDto {
  availableStates?: WorkflowStateEnum[];
}

export interface DocumentBulkStateSetDto {
  state: WorkflowStateEnum;
  documentsIds?: Guid[];
  documentLinksIds?: Guid[];
  ignoredDocumentIds?: Guid[];
  ignoredDocumentLinksIds?: Guid[];
}

export interface DocumentBulkSubscribersSetDto {
  watchTypes?: DocumentSubscriberEnumsWatchDocumentEnum[];
  documentsIds?: Guid[];
  documentLinksIds?: Guid[];
  ignoredDocumentIds?: Guid[];
  ignoredDocumentLinksIds?: Guid[];
}

export interface DocumentCategoryNodeCreateDto {
  name: string;
  parentId: Guid;
  description?: string;
}

export interface DocumentCategoryNodeDto {
  id: Guid;
  name?: string;
  documentCategoryTreeId: Guid;
  parentId?: Guid;
  description?: string;
}

export interface DocumentCategoryNodeListDto {
  id: Guid;
  name?: string;
  parentId?: Guid;
  description?: string;
}

export interface DocumentCategoryNodePatchDto {
  name?: string;
  description?: string;
}

export interface DocumentCategoryTreeCreateDto {
  name: string;
  description?: string;
}

export interface DocumentCategoryTreeDto {
  id: Guid;
  name?: string;
  description?: string;
}

export interface DocumentCategoryTreeListDto {
  id: Guid;
  name?: string;
  description?: string;
}

export interface DocumentCategoryTreePatchDto {
  name?: string;
  description?: string;
}

export interface DocumentCreateDto {
  name: string;
  description?: string;
  directoryId: Guid;
  uploadId: Guid;
  labels?: Guid[];
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  categoryTreeNodes?: Dictionary<Guid>;
  isModel: boolean;
  state: WorkflowStateEnum;
  ownedById?: Guid;
  metaData: RevisionMetaDataCreateDto;
}

export interface DocumentDto {
  description?: string;
  id: Guid;
  name?: string;
  ext?: string;
  directoryId: Guid;
  directoryPath?: string;
  currentRevision: CurrentRevisionDto;
  createdBy: ProjectUserProfileListDto;
  reservedDate?: IsoDateTime;
  releaseReservedDate?: IsoDateTime;
  reservedBy: ProjectUserProfileListDto;
  reservationPurpose: DocumentLockPurposeEnum;
  ownedBy: RoleDto;
  createdDate: IsoDateTime;
  modifiedById?: Guid;
  modifiedDate?: IsoDateTime;
  labels?: Guid[];
  categoryTreeNodes?: Dictionary<Guid>;
  primaryFile: PrimaryFileDto;
  secondaryFiles?: SecondaryFileDto[];
  isFavorite: boolean;
  isModel: boolean;
  addRevisionState: DocumentRevisionStateEnum;
  currentWatch?: DocumentSubscriberEnumsWatchDocumentEnum[];
  workflowId?: Guid;
  commentProcedureId?: Guid;
  state: WorkflowStateEnum;
  esticonObjectLink: EsticonObjectLinkDto;
}

export interface DocumentEvaluationErrorDto {
  documentId: Guid;
  directoryPath?: string;
  documentName?: string;
  documentsObstacles: DocumentsEvaluationObstaclesEnum;
}

export interface DocumentLinkCreateDto {
  name?: string;
  directoryId: Guid;
  documentIds?: Guid[];
  documentLinksIds?: Guid[];
}

export interface DocumentLinkDto {
  linkedDocument: DirectoryContentDto;
  linkedDiscardedDocument: DiscardedDocumentDto;
  directory: DirectoryListDto;
  id: Guid;
  name?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedById?: Guid;
  modifiedDate?: IsoDateTime;
  documentAccessLevel: AccessLevelEnum;
}

export interface DocumentLinkEvaluationErrorDto {
  linkId: Guid;
  directoryPath?: string;
  linkName?: string;
  linksObstacles: DocumentLinksEvaluationObstaclesEnum;
}

export interface DocumentLinkPatchDto {
  name?: string;
}

export interface DocumentMoveDto {
  destinationDirectoryId: Guid;
  sourceDirectoryId: Guid;
  documentsIds: Guid[];
  documentLinksIds?: Guid[];
  strategy: DocumentsMultipleMoveStrategyEnum;
}

export interface DocumentMoveResultDto {
  movedDocuments: number;
  movedLinks: number;
  pathFrom?: string;
  pathTo?: string;
  renamedDocuments?: RenamedObjectMoveResultDto[];
  renamedDocumentLinks?: RenamedObjectMoveResultDto[];
}

export interface DocumentMultipleDownloadDto {
  documentsIds: Guid[];
  documentLinksIds?: Guid[];
  name: string;
  includingDirectories: boolean;
}

export interface DocumentOwnerSetDto {
  ownedById?: Guid;
}

export interface DocumentPatchDto {
  name?: string;
  description?: string;
  labels?: Guid[];
  categoryTreeNodes?: Dictionary<Guid>;
}

export interface DocumentReferenceCreateDto {
  referrerDocumentIds: Guid[];
  referencedDocumentIds: Guid[];
}

export interface DocumentReferenceDto {
  id: Guid;
  referrerDocument: DocumentDto;
  referencedDocument: DocumentDto;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedById: Guid;
  modifiedDate: IsoDateTime;
}

export interface DocumentReferencesDto {
  referrers: DocumentReferenceDto[];
  references: DocumentReferenceDto[];
}

export interface DocumentReservationDto {
  reservedDate?: IsoDateTime;
  releaseReservedDate?: IsoDateTime;
  reservedBy: ProjectUserProfileListDto;
}

export interface DocumentReservationLockRequestDto {
  lockId?: Guid;
  documentId: Guid;
  purpose: DocumentLockPurposeEnum;
}

export interface DocumentReservationLockResponseDto {
  lockId: Guid;
  revisionId: Guid;
  lastDocumentUpdate: IsoDateTime;
  lastRevisionUpdate: IsoDateTime;
  lastAnotationUpdate?: IsoDateTime;
  reNew: boolean;
}

export interface DocumentReturnForReworkDto {
  esticonDocumentId: Guid;
  unsetApprovalDate: boolean;
}

export interface DocumentRevisionDiffDownloadDto {
  firstRevisionUrl: DownloadUrl;
  secondRevisionUrl: DownloadUrl;
}

export interface DocumentsDiscardDto {
  documentsIds: Guid[];
  documentLinksIds?: Guid[];
  removeDocToDocLinks: boolean;
  removeReferences: boolean;
}

export interface DocumentSetApprovalDateDto {
  approvalDate?: IsoDateTime;
  esticonDocumentId: Guid;
}

export interface DocumentsRestoreDto {
  documentsIds?: Guid[];
  strategy: DocumentsMultipleRestoreStrategyEnum;
}

export interface DocumentsRestoreResultDto {
  newFilePaths?: RestoreFilePathItemDto[];
  newDirectoryPaths?: RestoreDirectoryPathItemDto[];
  newFileName?: RestoreFileNameItemDto[];
  categoryRemovedFrom?: string[];
  requireCategoryRemovedFrom?: string[];
  labelRemovedFrom?: string[];
  isAny: boolean;
  restoredDocuments: number;
  restoredDirectories: number;
}

export interface DocumentStateSetDto {
  state: WorkflowStateEnum;
}

export interface DocumentSubscriberSetDto {
  watchTypes?: DocumentSubscriberEnumsWatchDocumentEnum[];
}

export interface DownloadSharedUrl {
  url?: string;
  createdDate: IsoDateTime;
  expires: number;
  discardedFiles?: string[];
  forbidenFiles?: string[];
}

export interface DownloadUrl {
  url?: string;
  createdDate: IsoDateTime;
  expires: number;
}

export interface ERPCategoryDataDto {
  year: number;
  month: number;
  naklady?: ERPNakladyRowDto[];
  summary: ERPSummaryDto;
}

export interface ERPERPDataRowDto {
  vykon: number;
  extern: number;
}

export interface ERPNakladyRowDto {
  ico?: string;
  partner?: string;
  kategorie?: string;
  kategorieZn?: string;
  esticonRow: EstiConKategorieSubdodavkyErpDataDto;
  erpRow: ERPERPDataRowDto;
}

export interface ERPSummaryDto {
  nakladyOstatni: number;
  vynosyOstatni: number;
  vynosyRozpocet: number;
}

export interface EstiCategoriesAccountingFactsItemDto {
  invoiceDocument?: string;
  category?: string;
  personalIdentificationNumber?: string;
  price: number;
  period?: string;
}

export interface EstiCategoriesAccountingFactsRequestDto {
  projectId: Guid;
  firmId: Guid;
  period?: string;
  type: InvoiceDocumentTypeEnum;
}

export interface EstiCategoriesAccountingFactsResponseDto {
  items?: EstiCategoriesAccountingFactsItemDto[];
  error: EstiCategoriesAccountingFactsErrorsEnum;
}

export interface EstiConAdresaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  bankovniUcet: EstiConBankovniUcetDto;
  kontaktniOsoba: EstiConKontaktniOsobaDto;
  sign?: string;
  name: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  poznamka?: string;
  dorucovaciAdresa?: string;
  vychozi: boolean;
  prohlaseniPrenosDph?: string;
  isSdruzeni: boolean;
  kategorie?: string;
  favourite: EstiConAdresaFavouriteDto;
}

export interface EstiConAdresaFavouriteDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  uzivatelId: Guid;
  isFavourite: boolean;
}

export interface EstiConAdresaPartnerDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idPartnera: Guid;
  vychozi: boolean;
  isPlatceDph: boolean;
  dic?: string;
  nazev?: string;
  ico?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  znacka?: string;
  icp?: string;
  poBox?: string;
  prohlaseniPrenosDph?: string;
  osoba: boolean;
  obchodniRejstrik?: string;
  dorucovaciAdresa?: string;
  kodBanky?: string;
  bicSwift?: string;
  adresaBanka?: string;
  predcisli?: string;
  cisloUctu?: string;
  iban?: string;
  nazevBanky?: string;
  isSdruzeni: boolean;
  kategorie?: string;
  favourite: EstiConAdresaFavouriteDto;
  poznamka?: string;
  displayName?: string;
}

export interface EstiConAdresyPartneriDto {
  adresy?: EstiConAdresaPartnerDto[];
  clenoveSdruzeni?: EstiConProjectClenSdruzeniDto[];
}

export interface EsticonAnalyzeZBVRequestDto {
  pouzeSkupinyZbv: boolean;
}

export interface EstiConAppProjektyArchivniDbHistorieDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  actionTime: IsoDateTime;
}

export interface EstiConAppProjektyClenoveSdruzeniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisAdresy: Guid;
  poradi: number;
}

export interface EstiConAppProjektyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev: string;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  faze: number;
  idCisAdresyInvestor?: Guid;
  idCisAdresyProjektant?: Guid;
  idCisAdresyDodavatel?: Guid;
  dodavatelKontaktniOsoba?: string;
  investorKontaktniOsoba?: string;
  projektantKontaktniOsoba?: string;
  stupen: number;
  idAppUtvary: Guid;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  sKod?: string;
  evidencniCislo?: string;
  idPredch?: Guid;
  typVarianty: number;
  zatrideniProjektuZnackaUzivatelske?: string;
  zatrideniProjektuNazevUzivatelske?: string;
  rozStruktura?: EstiConRozStrukturaDataDto[];
  rozKvHlavicky: EstiConRozKvHlavickyDataDto;
  appProjektyArchivniDbHistorie?: EstiConAppProjektyArchivniDbHistorieDataDto[];
  appProjektyClenoveSdruzeni?: EstiConAppProjektyClenoveSdruzeniDataDto[];
  appProjektyDokumenty?: EstiConAppProjektyDokumentyDataDto[];
  appProjektyNastaveni: EstiConAppProjektyNastaveniDataDto;
  appProjektyPoznamky?: EstiConAppProjektyPoznamkyDataDto[];
  appProjektyTmoPkv?: EstiConAppProjektyTmoPkvDataDto[];
  fakFaktury?: EstiConFakFakturyDataDto[];
  fakFakturySdruzeni?: EstiConFakFakturySdruzeniDataDto[];
  hmgVerze?: EstiConHmgVerzeDataDto[];
  mdlModely?: EstiConMdlModelyDataDto[];
  nabNabidky?: EstiConNabNabidkyDataDto[];
  rozKategorie?: EstiConRozKategorieDataDto[];
  rozValorizace?: EstiConRozValorizaceDataDto[];
  tmoPolozkyCeny?: EstiConTmoPolozkyCenyDataDto[];
  tmoRozpisZbvEuroviaZl?: EstiConTmoRozpisZbvEuroviaZlDataDto[];
  tmoSppEurovia?: EstiConTmoSppEuroviaDataDto[];
  tmoVerze?: EstiConTmoVerzeDataDto[];
  zbvSmluvniDodatky?: EstiConZbvSmluvniDodatkyDataDto[];
}

export interface EstiConAppProjektyDokumentyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppDokumenty: Guid;
  rozPolozkyZpetneOceneni?: EstiConRozPolozkyZpetneOceneniDataDto[];
}

export interface EstiConAppProjektyNastaveniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  cerpatSod: boolean;
  dphObdobiMesic: number;
  dphObdobiRok: number;
  dphZemeZnacka: string;
  mena?: string;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
  tvorbaStruktury: number;
  zatrideniPolozek: number;
  zatrideniPolozekZbv: number;
  zaokrouhleniMnozstvi: number;
  idCisDphZeme: Guid;
  idCisDphSazby: Guid;
  dphSazba: number;
  zaokrouhleniCenaJednotkova: number;
  zaokrouhleniCena: number;
  pozastavka: number;
  zadrzne: number;
  vypoctySDph: boolean;
  rozlisovatZpusobile: boolean;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  okres?: string;
  mistoStavby?: string;
  smlouva?: string;
  datumSmlouvy?: IsoDateTime;
  hodnotaSmlouvy?: number;
  smlouvaObjednatel?: string;
  datumSmlouvyObjednatel?: IsoDateTime;
  zaZhotovitelePol?: string;
  zaZhotoviteleFcePol?: string;
  zaObjednatelePol?: string;
  zaObjednateleFcePol?: string;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  idCisAdresyFirma?: Guid;
  zarukaPredani?: IsoDateTime;
  zarukaDo?: IsoDateTime;
  zarukaMesice?: number;
  csTyp?: string;
  csVarianta?: string;
  cislovaniPolozekZbv?: number;
  skupinyZbv: boolean;
  vybranaSkupinaZbv: number;
  kontrolaSkupinRsd: boolean;
  mistoPodpisuFa?: string;
  zaZhotoviteleFa?: string;
  zaObjednateleFa?: string;
  spravceFaRsd?: string;
  ms2014?: string;
  isprofin?: string;
  prohlaseniZp?: string;
  prohlaseniFa?: string;
  slevaBezValorizaceFa: boolean;
  slevaProcento?: number;
  tmoCsTyp?: string;
  tmoCsVarianta?: string;
  sdruzeni: boolean;
  splatnostPocetDniFa?: number;
  zaZhotoviteleFaFce?: string;
  zaObjednateleFaFce?: string;
  zaZhotoviteleFaSdruzeni?: string;
  zaZhotoviteleFaSdruzeniFce?: string;
  zaObjednateleFaSdruzeni?: string;
  zaObjednateleFaSdruzeniFce?: string;
  mistoPodpisuFaSdruzeni?: string;
  spravceFaRsdSdruzeni?: string;
  prefixSdruzeniZp?: string;
  typFidic: number;
  financovaniCef: boolean;
  pocetAktivitCef: number;
  omezeniPrav: boolean;
  tmoZapocitavatSint: boolean;
  odpadySz: boolean;
  povolitPrecerpani: boolean;
  kontrolaDuplicitPr: boolean;
  typUdrzbyPc: number;
  posloupnostUdrzbyPc: number;
  kalkulace: boolean;
  typCeny: number;
  typMnozstvi: number;
  rezimPrenosuDphZp: number;
  rezimPrenosuDphFa: number;
  kontrolaDuplicitPc: boolean;
  omezeniPravObjektu: boolean;
  prefixSdruzeniFa?: string;
  stavbyvedouci?: string;
  cenoveProjednani?: string;
  autorskyDozor?: string;
  stavebniDozor?: string;
  spravceStavby?: string;
  opravnenaOsoba?: string;
  supervize?: string;
  zakladniDatum?: IsoDateTime;
  urcenoKeKontrole: boolean;
  valorizovat: boolean;
  typValorizace: number;
  typObdobiValorizace: number;
  valorizaceOdstup?: number;
  urcenoKArchivaci: boolean;
  docerpani: boolean;
  zhotovitelPriloha7?: string;
  mereniZhotovitel?: string;
  mereniAsistent?: string;
  alternativniMena: boolean;
  idCisMeny?: Guid;
  kurz: number;
  tmoOmezitVyberPkv: boolean;
  zpusobDoplneniDuzp: number;
  cisKv: EstiConCisKvDataDto;
}

export interface EstiConAppProjektyPoznamkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  poznamka: string;
  autor?: string;
  login?: string;
  datumZmeny?: IsoDateTime;
}

export interface EstiConAppProjektyTmoPkvDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisPkv: Guid;
}

export interface EstiConBankaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name: string;
  kodBanky: string;
  bicSwift?: string;
  adresa?: string;
  favourite: EstiConBankaFavouriteDto;
}

export interface EstiConBankaFavouriteDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  uzivatelId: Guid;
  isFavourite: boolean;
}

export interface EstiConBankovniUcetDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name?: string;
  cisloUctu: string;
  predcisli?: string;
  iban?: string;
  banka: EstiConBankaDto;
}

export interface EstiConBimModelStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  object3DUid?: string;
  attributes?: EstiConRozElementAtributDto[];
  model3DOrigin?: Guid;
}

export interface EstiConCenoveSoustavyPairingData {
  cenoveSoustavyPairingResults?: EstiConPairingResult<ValueTuple<string, string>>[];
}

export interface EstiConCerpaniPolozkyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zjistovaciProtokolId: Guid;
  cerpanoAmount: number;
  cerpanoCost: number;
  provedenoAmount: number;
  zpPrenosHub: EstiConPrenosHub;
  dphSazba: number;
  vykazVymer: EstiConVykazVymerDto;
  korekceCeny: boolean;
  odpady?: string;
  cerpaneSubdodavky?: EstiConCerpaniPolozkySubdodavkyDto[];
}

export interface EstiConCerpaniPolozkySubdodavkyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  cerpanoAmount: number;
  provedenoAmount: number;
  subdodavkaDto: EstiConRozPolozkaSubdodavkyDto;
  idCisPkv: Guid;
}

export interface EstiConCerpaniRozpocetStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  type: EstiConRozStrukturaTypUzlu;
  projectId: Guid;
  generated: boolean;
  isInvestorskaUroven: boolean;
  zalozenoRealizaci: boolean;
  stavebniObjekt: EstiConRozSODto;
  konstrukcniPrvek: EstiConRozKPDto;
  element: EstiConRozElementDto;
  kvVzorce: EstiConKVHeaderDto;
  figury?: EstiConVykazVymerFiguraRozpisDto[];
  displayName?: string;
  description?: string;
  polozkyBase?: EstiConPolozkaFullDtoBase[];
  children?: EstiConCerpaniRozpocetStructureDto[];
  polozky?: EstiConPolozkaCerpaniDto[];
  elementData: EstiConRozElementDto;
}

export interface EstiConCerpaniZjistovaciProtokolDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  zbvId?: Guid;
  zbvType: EstiConZbvType;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  schvaleni?: IsoDateTime;
  vystaveni: IsoDateTime;
  idDphObdobi?: Guid;
  idReaZjistovaciProtokolySdruzeni?: Guid;
  prenosHub: EstiConPrenosHub;
  idFaktury?: Guid;
  isZpusobile: boolean;
  valorizace?: number;
  pozastavka?: number;
  zadrzne?: number;
  valorizaceProcento?: number;
  pozastavkaProcento?: number;
  zadrzneProcento?: number;
  schvalenSdruzeneZP: boolean;
  isStornoZp: boolean;
  zjistovaciProtokolTyp: EstiConZjistovaciProtokolTyp;
  obdobiValorizace?: IsoDateTime;
  children?: EstiConCerpaniRozpocetStructureDto[];
}

export interface EstiConCisAdresyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  nazev: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  poznamka?: string;
  vychozi: boolean;
  prohlaseniPrenosDph?: string;
  sdruzeni: boolean;
  kategorieSubdodavky?: string;
  dorucovaciAdresa?: string;
}

export interface EstiConCisKategorieSubdodavkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
}

export interface EstiConCisKvDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  sablona: boolean;
  cisKvPolozky?: EstiConCisKvPolozkyDataDto[];
}

export interface EstiConCisKvPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisKv: Guid;
  vzorec?: string;
  poradi: number;
  typVypoctu: number;
  cisPkv: EstiConCisPkvDataDto;
  cisKvPolozky?: EstiConCisKvPolozkyDataDto[];
}

export interface EstiConCisPartneriDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  nazev?: string;
  ico: string;
  dic?: string;
  osoba: boolean;
  platceDph: boolean;
  obchodniRejstrik?: string;
  aktualizovatAres: boolean;
  cisAdresy?: EstiConCisAdresyDataDto[];
}

export interface EstiConCisPkvDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  typPotreby: number;
}

export interface EstiConCisSkupinyZbvDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  typSkupiny?: number;
  typZmeny?: number;
}

export interface EsticonDocumentDto {
  id: Guid;
  esticonObjectId: Guid;
  esticonCategory: EstiCategoryEnum;
  name?: string;
  ext?: string;
  directoryId: Guid;
  directoryPath?: string;
  currentRevision: CurrentRevisionDto;
  primaryFile: PrimaryFileDto;
}

export interface EstiConDphSazbyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  rok: number;
  mesic: number;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
}

export interface EstiConDphZemeDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  sazby?: EstiConDphSazbyDto[];
}

export interface EstiConDtoBase {}

export interface EstiConExportApiResult {
  errorMessage?: string;
  content?: string;
}

export interface EstiConExportCerpaniRequest {
  type: EstiConTransferType;
  format: EstiConTransferFormat;
  parameters: EstiConDtoBase;
  sourceId: Guid;
  zipFileName?: string;
}

export interface EstiConExportZBVRequest {
  type: EstiConTransferType;
  format: EstiConTransferFormat;
  parameters: EstiConDtoBase;
  sourceId: Guid;
  zipFileName?: string;
}

export interface EstiConFakDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  projectId: Guid;
  sign: string;
  poradoveCislo: number;
  obdobi?: IsoDateTime;
  vystaveni?: IsoDateTime;
  danovyDoklad?: string;
  splatnost?: IsoDateTime;
  zdanitelnePlneni?: IsoDateTime;
  uhrazeni?: IsoDateTime;
  typ: EstiConTypFaktury;
  typZadani: EstiConTypZadaniFakturace;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  slevaBezValorizace: boolean;
  dpH1?: number;
  dpH2?: number;
  dpH3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  zalohaCelkem?: number;
  cenaCelkem?: number;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
  dphSazbyStruktura: EstiConDphSazbyDto;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  isCerpano: boolean;
  cerpanoZnacka?: string;
  cerpanoId?: string;
  typCerpano: EstiConTypCerpano;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  spravceFaRSD?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  zalohaDphZaklad?: number;
  smlouvaZhotovitel?: string;
  smlouvaObjednatel?: string;
  datumSmlouvyZhotovitel?: IsoDateTime;
  datumSmlouvyObjednatel?: IsoDateTime;
  isZpusobila: boolean;
  variabilniSymbol?: string;
  hasZP: boolean;
  spolFakId?: Guid;
  typCerpanehoMnoztvi: EstiConTypCerpanehoMnozstvi;
  poznamkaSz?: string;
  zatrideniCzcpa?: string;
  fakRozpisZaloh?: EstiConFakRozpisZalohDto[];
  fakPolozky?: EstiConFakPolozkaDto[];
  partneri?: EstiConFakPartneriDto[];
  hubLog?: EstiConFakDetailHubLogDto[];
  zjistovaciProtokoly?: EstiConFakZjistovaciProtokolySimpleDto[];
}

export interface EstiConFakDetailHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConFakFakturyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  dphObdobiMesic?: number;
  dphObdobiRok?: number;
  znacka: string;
  poradoveCislo: number;
  danovyDoklad?: string;
  typFaktury: number;
  typZadani: number;
  isprofin?: string;
  faObdobi?: IsoDateTime;
  vystaveni?: IsoDateTime;
  splatnost?: IsoDateTime;
  zdanitelnePlneni?: IsoDateTime;
  uhrazeni?: IsoDateTime;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  slevaBezValorizace: boolean;
  dph1?: number;
  dph2?: number;
  dph3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  idCisDphSazby?: Guid;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
  prenosDph: number;
  zalohaCelkem?: number;
  zalohaDphZaklad?: number;
  cenaCelkem?: number;
  mistoPodpisu?: string;
  zaZhotovitele?: string;
  zaObjednatele?: string;
  spravceFaRsd?: string;
  idFakFakturySdruzeni?: Guid;
  smlouva?: string;
  kde?: string;
  zaZhotoviteleFce?: string;
  zaObjednateleFce?: string;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  datumSmlouvy?: IsoDateTime;
  datumSmlouvyObjednatel?: IsoDateTime;
  smlouvaObjednatel?: string;
  zpusobilost: boolean;
  typZdrojFak?: number;
  variabilniSymbol?: string;
  poznamkaSz?: string;
  zatrideniCzcpa?: string;
  fakFakturyHubLog?: EstiConFakFakturyHubLogDataDto[];
  fakFakturyPartneri?: EstiConFakFakturyPartneriDataDto[];
  fakFakturyPolozky?: EstiConFakFakturyPolozkyDataDto[];
  fakFakturyRozpisZaloh?: EstiConFakFakturyRozpisZalohDataDto[];
}

export interface EstiConFakFakturyHubLogDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: number;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConFakFakturyPartneriDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  typPartnera: number;
  idCisAdresy?: Guid;
  ico?: string;
  dic?: string;
  znacka?: string;
  nazev?: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  prohlaseniPrenosDph?: string;
  platceDph: boolean;
  osoba: boolean;
  obchodniRejstrik?: string;
  kodBanky?: string;
  bicSwift?: string;
  predcisli?: string;
  cisloUctu?: string;
  iban?: string;
  ks?: string;
  vs?: string;
  nazevBanky?: string;
  dorucovaciAdresa?: string;
  adresaBanky?: string;
}

export interface EstiConFakFakturyPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozStruktura: Guid;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  dph1?: number;
  dph2?: number;
  dph3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  cenaCelkem?: number;
}

export interface EstiConFakFakturyRozpisZalohDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idFakFakturyZaloha: Guid;
  cerpanoZaloha?: number;
}

export interface EstiConFakFakturySdruzeniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  dphObdobiMesic?: number;
  dphObdobiRok?: number;
  znacka: string;
  poradoveCislo: number;
  danovyDoklad?: string;
  typFaktury: number;
  typZadani: number;
  isprofin?: string;
  faObdobi?: IsoDateTime;
  vystaveni?: IsoDateTime;
  splatnost?: IsoDateTime;
  zdanitelnePlneni?: IsoDateTime;
  uhrazeni?: IsoDateTime;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  slevaBezValorizace: boolean;
  dph1?: number;
  dph2?: number;
  dph3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  idCisDphSazby?: Guid;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
  prenosDph: number;
  zalohaCelkem?: number;
  zalohaDphZaklad?: number;
  cenaCelkem?: number;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  spravceFaRsd?: string;
  smlouva?: string;
  kde?: string;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  datumSmlouvy?: IsoDateTime;
  datumSmlouvyObjednatel?: IsoDateTime;
  smlouvaObjednatel?: string;
  zpusobilost: boolean;
  variabilniSymbol?: string;
  fakFakturySdruzeniHubLog?: EstiConFakFakturySdruzeniHubLogDataDto[];
  fakFakturySdruzeniPartneri?: EstiConFakFakturySdruzeniPartneriDataDto[];
}

export interface EstiConFakFakturySdruzeniHubLogDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: number;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConFakFakturySdruzeniPartneriDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  typPartnera: number;
  idCisAdresy?: Guid;
  ico?: string;
  dic?: string;
  znacka?: string;
  nazev?: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  prohlaseniPrenosDph?: string;
  platceDph: boolean;
  osoba: boolean;
  obchodniRejstrik?: string;
  kodBanky?: string;
  bicSwift?: string;
  predcisli?: string;
  cisloUctu?: string;
  iban?: string;
  ks?: string;
  vs?: string;
  nazevBanky?: string;
  dorucovaciAdresa?: string;
  adresaBanky?: string;
}

export interface EstiConFakObjektDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  displayName?: string;
}

export interface EstiConFakPartneriDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  typ: EstiConTypPartnera;
  adresaId?: Guid;
  ico?: string;
  dic?: string;
  sign?: string;
  nazev?: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  prohlaseniPrenosDph?: string;
  platceDph: boolean;
  osoba: boolean;
  obchodniRejstrik?: string;
  dorucovaciAdresa?: string;
  kodBanky?: string;
  bicSwift?: string;
  adresaBanka?: string;
  predcisli?: string;
  cisloUctu?: string;
  iban?: string;
  ks?: string;
  vs?: string;
  nazevBanky?: string;
}

export interface EstiConFakPolozkaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  rozpocetStruktura: EstiConFakObjektDto;
  idZP?: Guid;
  znackaZP?: string;
  zbv?: string;
  vystaveni?: IsoDateTime;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  dpH1?: number;
  dpH2?: number;
  dpH3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  cenaCelkem?: number;
  zpIds?: Guid[];
  isZpusobila: boolean;
}

export interface EstiConFakProjektDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  faktury?: EstiConFakDetailDto[];
  hasZP: boolean;
  fakturySpol?: EstiConFakSpolDetailDto[];
}

export interface EstiConFakRozpisZalohDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  fakZalohaId: Guid;
  cerpanoZaloha?: number;
}

export interface EstiConFakSpolDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  projectId: Guid;
  sign: string;
  poradoveCislo: number;
  obdobi?: IsoDateTime;
  vystaveni?: IsoDateTime;
  danovyDoklad?: string;
  splatnost?: IsoDateTime;
  zdanitelnePlneni?: IsoDateTime;
  uhrazeni?: IsoDateTime;
  typ: EstiConTypFaktury;
  typZadani: EstiConTypZadaniFakturace;
  cerpanoZaklad1?: number;
  cerpanoZaklad2?: number;
  cerpanoZaklad3?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  poValorizaciZaklad1?: number;
  poValorizaciZaklad2?: number;
  poValorizaciZaklad3?: number;
  slevaProcento?: number;
  sleva?: number;
  poSleveZaklad1?: number;
  poSleveZaklad2?: number;
  poSleveZaklad3?: number;
  slevaBezValorizace: boolean;
  dpH1?: number;
  dpH2?: number;
  dpH3?: number;
  pozastavkaProcento?: number;
  pozastavka?: number;
  zadrzneProcento?: number;
  zadrzne?: number;
  zalohaCelkem?: number;
  cenaCelkem?: number;
  sazba1?: number;
  sazba2?: number;
  sazba3?: number;
  dphSazbyStruktura: EstiConDphSazbyDto;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  isCerpano: boolean;
  cerpanoZnacka?: string;
  cerpanoId?: string;
  typCerpano: EstiConTypCerpano;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  spravceFaRSD?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  zalohaDphZaklad?: number;
  smlouvaZhotovitel?: string;
  smlouvaObjednatel?: string;
  datumSmlouvyZhotovitel?: IsoDateTime;
  datumSmlouvyObjednatel?: IsoDateTime;
  isZpusobila: boolean;
  variabilniSymbol?: string;
  hasZP: boolean;
  clenskeFaktury?: EstiConFakDetailDto[];
  partneriSpol?: EstiConFakSpolPartneriDto[];
  hubLog?: EstiConFakSpolDetailHubLogDto[];
}

export interface EstiConFakSpolDetailHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConFakSpolPartneriDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  typ: EstiConTypPartnera;
  adresaId?: Guid;
  ico?: string;
  dic?: string;
  sign?: string;
  nazev?: string;
  icp?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  poBox?: string;
  prohlaseniPrenosDph?: string;
  platceDph: boolean;
  osoba: boolean;
  obchodniRejstrik?: string;
  dorucovaciAdresa?: string;
  kodBanky?: string;
  bicSwift?: string;
  adresaBanka?: string;
  predcisli?: string;
  cisloUctu?: string;
  iban?: string;
  ks?: string;
  vs?: string;
  nazevBanky?: string;
}

export interface EstiConFakturaDataDto {
  id: Guid;
  parentId?: Guid;
  znacka?: string;
  investor?: string;
  dodavatel?: string;
  dodavatelIco?: string;
  investorIco?: string;
  vystaveni?: IsoDateTime;
  obdobi?: IsoDateTime;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  typFaktury: EstiConTypFaktury;
  typUzlu: EstiConRozStrukturaTypUzlu;
  cena?: number;
  idFakFakturySdruzeni?: Guid;
  isSpolFaktura: boolean;
  hasExistingFakSpolId: boolean;
  polozky?: EstiConPolozkaFakturyDataDto[];
  dodavatelPrj?: string;
  dodavatelPrjIco?: string;
  dodavatelPrjNazev?: string;
  isVazbaToZPPartnerDodavatelIncorrect: boolean;
  isVazbaToZPPartnerInvestorIncorrect: boolean;
}

export interface EstiConFakturaSelectionResult {
  sourceId: Guid;
  destinationId?: Guid;
  isSelected: boolean;
  isExistingItem: boolean;
  canCreate: boolean;
  disabledWriteRight: boolean;
  isExistingZbvApproved: boolean;
  isParentLastLevel: boolean;
  isOdpadniObjekt: boolean;
  polozkySelectionResults?: EstiConSelectionResult[];
}

export interface EstiConFakturySelectionData {
  faktury?: EstiConFakturaDataDto[];
  selectionResults?: EstiConFakturaSelectionResult[];
  existingStruktura: EstiConAppProjektyDataDto;
  clenoveSdruzeni?: EstiConPartnerDto[];
  leaderIco?: string;
  faWithSentToHubList?: Guid[];
  existingFaHasSpolFaIdList?: Guid[];
}

export interface EstiConFakTvorbaFakturParamsDto {
  projectID: Guid;
  isCheckedRokMesic: boolean;
  datumOd: IsoDateTime;
  datumDo: IsoDateTime;
  rok: number;
  mesic: number;
  vystaveni: IsoDateTime;
  splatnost: IsoDateTime;
  duzp: IsoDateTime;
  proEtapy: boolean;
  podpisyZParamVar: boolean;
  rozlisDodOdPuv: boolean;
}

export interface EstiConFakZjistovaciProtokolySimpleDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idZjistovaciProtokolSdruzeni?: Guid;
  parentId: Guid;
  investor: EstiConAdresaPartnerDto;
  schvaleni?: IsoDateTime;
  prenosHub: EstiConPrenosHub;
  typMnozstvi?: string;
  sign: string;
  vystaveni: IsoDateTime;
  cerpano?: number;
  objektovaCesta?: string;
}

export interface EstiConFinancialOverviewByUnitDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  evidencniCislo?: string;
  sign?: string;
  name?: string;
  utvar?: string;
  investor?: string;
  phase: EstiConProjectPhase;
  begin?: IsoDateTime;
  end?: IsoDateTime;
  cena: number;
  fakturaceDosud?: number;
  fakturaceZbyva?: number;
  nakladyZbyva?: number;
  soD?: number;
  zbvFakturace?: number;
  zbvNavrhova?: number;
  vlastniSubdodavky?: number;
  subdodavky?: number;
  zbvInterni?: number;
}

export interface EstiConFinancialOverviewByUnitResponseDto {
  rows?: EstiConFinancialOverviewByUnitDto[];
}

export interface EstiConFirmDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name: string;
  description: string;
  server: string;
  isWindowsAuthenticated: boolean;
  userName?: string;
  password?: string;
  online: boolean;
  frekvenceOpakovani: EstiConFrekvenceOpakovaniZalohy;
  zalohovat: boolean;
  pocetZaloh: number;
  uloziste?: string;
  posledniZaloha?: IsoDateTime;
  planovanaZaloha: IsoDateTime;
  typDb: EstiConTypDb;
  idArchivniDb?: Guid;
  idKontrolniDb?: Guid;
  povolitPresunArchivniDb: boolean;
  povolitPresunKontrolniDb: boolean;
  typKontrolniDb: EstiConTypKontrolniDb;
}

export interface EstiConFirmRowDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  nazev: string;
  popis: string;
  server: string;
  online: boolean;
  canConnect: boolean;
  typDb: EstiConTypDb;
  idArchivniDb?: Guid;
  idKontrolniDb?: Guid;
  povolitPresunArchivniDb: boolean;
  povolitPresunKontrolniDb: boolean;
  typKontrolniDb: EstiConTypKontrolniDb;
  isChecked: boolean;
}

export interface EstiConFpNodeRozpisCerpaniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  cerpano: number;
  month: number;
  year: number;
  isBillingFromDependency: boolean;
}

export interface EstiConFpStructureRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  oc?: number;
  month: number;
  year: number;
}

export interface EstiConFpVersionRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  oc?: number;
  month: number;
  year: number;
}

export interface EstiConHarmonogramPreruseniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  start: IsoDateTime;
  finish: IsoDateTime;
  type: EstiConTerminationType;
  description?: string;
}

export interface EstiConHarmonogramProjektCalendarDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  day?: number;
  month?: number;
  start?: IsoDateTime;
  finish?: IsoDateTime;
  description: string;
  obdobi: boolean;
}

export interface EstiConHarmonogramProjektDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  type: EstiConHarmonogramNodeType;
  sign: string;
  name: string;
  cena?: number;
  version: EstiConHarmonogramVersionDto;
  children?: EstiConHarmonogramStructureDto[];
  cerpani?: EstiConFpNodeRozpisCerpaniDto[];
}

export interface EstiConHarmonogramProjektSettingsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  pondeli: boolean;
  utery: boolean;
  streda: boolean;
  ctvrtek: boolean;
  patek: boolean;
  sobota: boolean;
  nedele: boolean;
}

export interface EstiConHarmonogramStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  start?: IsoDateTime;
  finish?: IsoDateTime;
  poradi: number;
  wbs: string;
  percentComplete?: number;
  type: EstiConHarmonogramNodeType;
  structureNodeType: EstiConRozStrukturaTypUzlu;
  versionId: Guid;
  structureId?: Guid;
  sourceId?: Guid;
  cenaObjektu?: number;
  prevzatoZRozpoctu: boolean;
  children?: EstiConHarmonogramStructureDto[];
  vazby?: EstiConHarmonogramVazbyDto[];
  preruseni?: EstiConHarmonogramPreruseniDto[];
  rozpis?: EstiConFpStructureRozpisDto[];
  cerpani?: EstiConFpNodeRozpisCerpaniDto[];
  vazbyPolozek?: EstiConHarmonogramVazbaPolozkaElementDto[];
  element3DModelIdList?: EstiConBimModelStructureDto[];
}

export interface EstiConHarmonogramVazbaPolozkaElementDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  elementId?: Guid;
  polozkaId?: Guid;
}

export interface EstiConHarmonogramVazbyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  structureIdOd: Guid;
  structureIdDo: Guid;
  type: EstiConDependencyType;
  odstup: number;
}

export interface EstiConHarmonogramVersionDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  projectId: Guid;
  sign: string;
  name?: string;
  takeOverLevel: EstiConRozStrukturaTypUzlu;
  createDate: IsoDateTime;
  updateDate?: IsoDateTime;
  approveDate?: IsoDateTime;
  approveBy?: string;
  isActual: boolean;
  start: IsoDateTime;
  finish: IsoDateTime;
  billingPeriod?: IsoDateTime;
  setting: EstiConHarmonogramProjektSettingsDto;
  calendar?: EstiConHarmonogramProjektCalendarDto[];
  rozpis?: EstiConFpVersionRozpisDto[];
  getString?: string;
}

export interface EstiConHmgDataDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozStruktura?: Guid;
  znacka?: string;
  nazev?: string;
  zahajeni: IsoDateTime;
  dokonceni: IsoDateTime;
  poradi?: number;
  wbs?: string;
  procHotovo?: number;
  cena?: number;
  idPuv?: Guid;
  idHmgVerze: Guid;
  typUzlu: number;
  prevzatoRozpocet: boolean;
  hmgData?: EstiConHmgDataDataDto[];
  hmgFpRozpis?: EstiConHmgFpRozpisDataDto[];
  hmgPolozky?: EstiConHmgPolozkyDataDto[];
  hmgPreruseni?: EstiConHmgPreruseniDataDto[];
  hmgVazby?: EstiConHmgVazbyDataDto[];
}

export interface EstiConHmgFpRozpisDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mesic: number;
  rok: number;
  cenaPlan?: number;
}

export interface EstiConHmgFpRozpisProjektDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mesic: number;
  rok: number;
  cenaPlan?: number;
}

export interface EstiConHmgPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozky?: Guid;
  idRozStruktura?: Guid;
}

export interface EstiConHmgPreruseniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zahajeni: IsoDateTime;
  dokonceni: IsoDateTime;
  typ: number;
  popis?: string;
}

export interface EstiConHmgProjektyKalendarDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  den?: number;
  mesic?: number;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  popis?: string;
  obdobi: boolean;
}

export interface EstiConHmgProjektyNastaveniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  pondeli: boolean;
  utery: boolean;
  streda: boolean;
  ctvrtek: boolean;
  patek: boolean;
  sobota: boolean;
  nedele: boolean;
}

export interface EstiConHmgVazbyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idHmgDataDo: Guid;
  typ?: number;
  odstup?: number;
}

export interface EstiConHmgVerzeDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  prebiranaUroven: number;
  vytvoreni: IsoDateTime;
  datumAktualizace?: IsoDateTime;
  schvaleni?: IsoDateTime;
  schvalil?: string;
  aktualni: boolean;
  zahajeni: IsoDateTime;
  dokonceni: IsoDateTime;
  obdobi?: IsoDateTime;
  hmgData?: EstiConHmgDataDataDto[];
  hmgFpRozpisProjekt?: EstiConHmgFpRozpisProjektDataDto[];
  hmgProjektyKalendar?: EstiConHmgProjektyKalendarDataDto[];
  hmgProjektyNastaveni: EstiConHmgProjektyNastaveniDataDto;
}

export interface EstiConImportAnalyzeFakturyResult {
  result: EstiConDataAnalyzeResult;
  resolvedType: EstiConTransferType;
  modifiedOrCorruptedXml: boolean;
  validationResults?: ValidationResult[];
  pairingErrorType: EstiConErrorType;
  pairingErrorSource?: string;
  data: EstiConImportParamsFaktury;
  importValidationResults?: EstiConImportValidationResult[];
  transferFormat: EstiConTransferFormat;
  actionId: Guid;
}

export interface EstiConImportAnalyzeHarmonogramResult {
  result: EstiConDataAnalyzeResult;
  resolvedType: EstiConTransferType;
  modifiedOrCorruptedXml: boolean;
  validationResults?: ValidationResult[];
  pairingErrorType: EstiConErrorType;
  pairingErrorSource?: string;
  data: EstiConImportParamsHarmonogram;
  importValidationResults?: EstiConImportValidationResult[];
  transferFormat: EstiConTransferFormat;
  actionId: Guid;
}

export interface EstiConImportAnalyzeZBVResult {
  result: EstiConDataAnalyzeResult;
  resolvedType: EstiConTransferType;
  modifiedOrCorruptedXml: boolean;
  validationResults?: ValidationResult[];
  pairingErrorType: EstiConErrorType;
  pairingErrorSource?: string;
  data: EstiConImportParamsZBV;
  importValidationResults?: EstiConImportValidationResult[];
  transferFormat: EstiConTransferFormat;
  actionId: Guid;
}

export interface EstiConImportAnalyzeZPResult {
  result: EstiConDataAnalyzeResult;
  resolvedType: EstiConTransferType;
  modifiedOrCorruptedXml: boolean;
  validationResults?: ValidationResult[];
  pairingErrorType: EstiConErrorType;
  pairingErrorSource?: string;
  data: EstiConImportParamsCerpani;
  importValidationResults?: EstiConImportValidationResult[];
  transferFormat: EstiConTransferFormat;
  actionId: Guid;
}

export interface EstiConImportCerpaniRunDto {
  actionId: Guid;
  importParamsCerpani: EstiConImportParamsCerpani;
}

export interface EstiConImportedEntity {
  entityId: Guid;
  parentEntityId?: Guid;
  relatedEntityId?: Guid;
  entityType: EstiConRozStrukturaTypUzlu;
  isEntityCenovaSoustava: boolean;
  replacedEntityId?: Guid;
  metadata?: Dictionary<string>;
}

export interface EstiConImportFakturyRunDto {
  actionId: Guid;
  importParamsFaktury: EstiConImportParamsFaktury;
}

export interface EstiConImportHarmonogramRunDto {
  actionId: Guid;
  importParamsHarmonogram: EstiConImportParamsHarmonogram;
}

export interface EstiConImportHmgInvalidStruktura {
  struktura: EstiConRozStrukturaDataDto;
  path?: string;
}

export interface EstiConImportHmgMissingPolozkaVazba {
  polozka: EstiConRozPolozkyDataDto;
  path?: string;
}

export interface EstiConImportLogResult {
  path?: string;
  sign?: string;
  name?: string;
  variant?: string;
  typUzlu: EstiConRozStrukturaTypUzlu;
  logType: EstiConLogType;
}

export interface EstiConImportParamsCerpani {
  rightsCis?: unknown;
  canImportWithDuplicate: boolean;
  canCreateDuplicate: boolean;
  canReplace: boolean;
  cannotImportDuplicate: boolean;
  canUpdateActualContent: boolean;
  canUpdateTheSame: boolean;
  cannotUpdateTheSame: boolean;
  isSetBetweenDate: boolean;
  dateFrom?: IsoDateTime;
  dateTo?: IsoDateTime;
  isSetActualMonth: boolean;
  isSetAllDates: boolean;
  adresaDodavatelId?: Guid;
  adresaInvestorId?: Guid;
  dodavatelId?: Guid;
  investorId?: Guid;
  needDph: boolean;
  idProjectToUpdate: Guid;
  zpSelection: EstiConZpSelectionData;
  zpStructureData: EstiConZpStructureData;
  partneriPairing: EstiConPartneriPairingData;
  existingClenoveSdruzeni?: EstiConAppProjektyClenoveSdruzeniDataDto[];
  dodavatele?: EstiConZpPartneriPairing[];
  originalTransferFormat: EstiConTransferFormat;
  isFAseZP: boolean;
}

export interface EstiConImportParamsFaktury {
  rightsCis?: unknown;
  idProjectToUpdate: Guid;
  aktualizovatExistujici: boolean;
  prenestPlatbuDPH: boolean;
  fakturySelection: EstiConFakturySelectionData;
  seZP: boolean;
  showSeZP: boolean;
  overrideExistingZp: boolean;
  isHromadnaZmenaDodavatele: boolean;
  idCisAdresyDodavatel?: Guid;
  idCisPartneriDodavatel?: Guid;
  paramsCerpani: EstiConImportParamsCerpani;
  isProjektImportSdruzeni: boolean;
  invalidPartnerZpIds?: Guid[];
  invalidPartnerSpolZpIds?: Dictionary<Guid[]>;
}

export interface EstiConImportParamsHarmonogram {
  idProjectToUpdate: Guid;
  tvorbaStruktury: EstiConProjectStructureCreation;
  destinationTvorbaStruktury: EstiConProjectStructureCreation;
  importDataChoice: EstiConImportHmgDataType;
  versionSign?: string;
  versionName?: string;
  createNewVersion: boolean;
  actualizationVersion?: Guid;
  isMsProject: boolean;
  msProjectData?: EstiConMsProjectData[];
  canImportAllDataFromMsProject: boolean;
  hmgDataStrukturaPairingResults?: EstiConPairingResult<Guid>[];
  strukturaVazbyPairingResults?: EstiConPairingResult<Guid>[];
  missingVazby?: EstiConImportHmgMissingPolozkaVazba[];
  missingElementy?: EstiConImportHmgInvalidStruktura[];
}

export interface EstiConImportParamsZBV {
  rightsCis?: unknown;
  turnOnValorizace: boolean;
  differentValorizace: boolean;
  differentTypValorizace: boolean;
  differentTypObdobiValorizace: boolean;
  idProjectToUpdate: Guid;
  pouzeSkupinyZbv: boolean;
  partneriPairing: EstiConPartneriPairingData;
  valorizacePairing: EstiConValorizacePairingData;
  zbvSelection: EstiConZbvSelectionData;
  cenoveSoustavyPairing: EstiConCenoveSoustavyPairingData;
  skupinyZbvPairing: EstiConSkupinyZbvPairingData;
  pkvPairing: EstiConPkvPairingData;
  kvPairing: EstiConKvPairingData;
  subdodavkyKategoriePairing: EstiConKategorieSubdodavkyPairingData;
  originalTransferFormat: EstiConTransferFormat;
}

export interface EstiConImportResult {
  isSuccessful: boolean;
  importedEntities?: EstiConImportedEntity[];
  logResults?: EstiConImportLogResult[];
  isValid: boolean;
  validationResults?: EstiConImportValidationResult[];
  resultData?: EstiConImportValidationResult[];
  underlyingSaveResult: EstiConSaveResultDto;
  errorSource?: string;
  hasError: boolean;
  errorType: EstiConErrorType;
  utvarId?: Guid;
  importType: EstiConTransferType;
}

export interface EstiConImportValidationResult {
  path?: string;
  sign?: string;
  name?: string;
  variant?: string;
  id: Guid;
  validationResultType: EstiConValidationResultType;
  validationValues?: EstiConValidationValue[];
  underlyingResults?: EstiConImportValidationResult[];
  typUzlu: EstiConRozStrukturaTypUzlu;
  issueType: EstiConImportValidationIssueType;
}

export interface EstiConImportZBVRunDto {
  actionId: Guid;
  importParamsZBV: EstiConImportParamsZBV;
}

export interface EstiConIRozPolozkaRozpis {
  idRozStruktura?: Guid;
  idZbvZmenoveListy?: Guid;
}

export interface EstiConKalPotrebyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  kalPotrebyStruktura?: EstiConKalPotrebyStrukturaDataDto[];
}

export interface EstiConKalPotrebyStrukturaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idKalPotreby: Guid;
  idCisPkv?: Guid;
  typUzlu: number;
  typMnozstvi: number;
  znacka: string;
  nazev?: string;
  popis?: string;
  mj?: string;
  vykon: number;
  mnozstvi: number;
  faktor: number;
  jnc: number;
  csTyp?: string;
  csVarianta?: string;
  znackaCs?: string;
  fixniCena: boolean;
  idCisAdresyDodavatel?: Guid;
  popis2?: string;
  kategorieDodavatele?: string;
  zasmluvnenost: number;
  kod?: string;
  kalPotrebyStruktura?: EstiConKalPotrebyStrukturaDataDto[];
  vykHlavickyCena: EstiConVykHlavickyDataDto;
  vykHlavickyFaktor: EstiConVykHlavickyDataDto;
  vykHlavickyMnozstvi: EstiConVykHlavickyDataDto;
}

export interface EstiConKategorieSubdodavekDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  favourite: EstiConKategorieSubdodavekFavouriteDto;
}

export interface EstiConKategorieSubdodavekFavouriteDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  uzivatelId: Guid;
  isFavourite: boolean;
}

export interface EstiConKategorieSubdodavkyErpDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  ico?: string;
  partnerNazev?: string;
  kategorie?: string;
  cenaSoD: number;
  zbv: number;
  zbvInterni: number;
  cenaCelkem: number;
  cenaSubdodavek: number;
  fakturovano: number;
  cerpaniVlastniVykon: number;
  cerpaniSubdodavek: number;
  cerpanoNakladyCelkem: number;
  zbyvaFakturovano: number;
  zbyvaVlastniVykon: number;
  zbyvaSubdodavek: number;
  zbyvaNc: number;
}

export interface EstiConKategorieSubdodavkyPairingData {
  ksList?: EstiConCisKategorieSubdodavkyDataDto[];
  ksPairingResults?: EstiConPairingResult<Guid>[];
  availableKSList?: EstiConKategorieSubdodavekDto[];
}

export interface EstiConKontaktniOsobaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  jmeno?: string;
  prijmeni: string;
  telefon?: string;
  email?: string;
  titulPred?: string;
  titulZa?: string;
  mobil?: string;
  celeJmeno?: string;
}

export interface EstiConKVDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  nazev?: string;
  znacka: string;
  cisKvPolozky?: EstiConKVPolozkaDto[];
  sablona: boolean;
  vyskytyPocet: number;
  vychozi: boolean;
  vyskyty?: EstiConProjectSimpleDto[];
}

export interface EstiConKVHeaderDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  rozKvVzorce?: EstiConKVVzorecDto[];
}

export interface EstiConKvPairingData {
  importChoice: EstiConKalkulaceImportChoice;
  isStavbaKalkulovana: boolean;
  vytvoritNoveHlavicky: boolean;
  isKvSameRozZbv: boolean;
  vlastniKv: EstiConCisKvDataDto;
  puvodniKv: EstiConCisKvDataDto;
  importovaneKv: EstiConCisKvDataDto;
  existInCis: boolean;
  puvodniKvPairingResult: EstiConPairingResult<Guid>;
  puvodniKvPolozkyPairingResults?: EstiConPairingResult<Guid>[];
  puvodniKvHlavickyPairingResults?: EstiConPairingResult<Guid>[];
  vlastniKvPairingResult: EstiConPairingResult<Guid>;
  vlastniKvPolozkyPairingResults?: EstiConPairingResult<Guid>[];
  vlastniKvHlavickyPairingResults?: EstiConPairingResult<Guid>[];
}

export interface EstiConKVPolozkaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisKv: Guid;
  vzorec?: string;
  cisKvPolozky?: EstiConKVPolozkaDto[];
  cisPkv: EstiConPKVDto;
  poradi: number;
  typVypoctu: number;
}

export interface EstiConKVVzorecDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisKvPolozky: Guid;
  idCisPkvSubd?: Guid;
  idCisAdresy?: Guid;
  vzorec: string;
}

export interface EsticonLinkDto {
  esticonFirmId?: Guid;
  esticonProjectId: Guid;
}

export interface EstiConMdlModelyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  nazev: string;
  popis?: string;
  formatSouboru: string;
  aktualniVerze: Guid;
  mdlModelyVerze?: EstiConMdlModelyVerzeDataDto[];
}

export interface EstiConMdlModelyStrukturaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  objekt3dGuid?: string;
  zdrojovyModel?: Guid;
  rozElementyAtributy?: EstiConRozElementyAtributyDataDto[];
}

export interface EstiConMdlModelyVerzeDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  datumImportu?: IsoDateTime;
  popis?: string;
  soubor: string;
}

export interface EstiConMenyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  mnozstvi: number;
  symbol?: string;
  displayCurrency?: string;
}

export interface EstiConMsProjectData {
  name?: string;
  guid?: string;
  isChecked?: boolean;
  tasks?: EstiConMsProjectData[];
}

export interface EstiConNabNabidkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisAdresyDodavatel?: Guid;
  poradiSehrani: number;
  varianta: string;
  importLog?: string;
  popis?: string;
  kriteria?: string;
  nabStruktura?: EstiConNabStrukturaDataDto[];
}

export interface EstiConNabPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  nazev?: string;
  varianta?: string;
  popis?: string;
  specifikace?: string;
  mj?: string;
  joc: number;
  poradoveCislo: number;
  sazbaDph: number;
  csTyp?: string;
  csVarianta?: string;
  nabPolozkyRozpis?: EstiConNabPolozkyRozpisDataDto[];
}

export interface EstiConNabPolozkyRozpisDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idNabStruktura?: Guid;
  mnozstvi: number;
  jeZMikrorozpoctu: boolean;
  vykHlavicky: EstiConVykHlavickyDataDto;
}

export interface EstiConNabStrukturaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozStruktura: Guid;
  idNabNabidky: Guid;
  znacka?: string;
  nazev?: string;
  typUzlu: number;
  nabPolozky?: EstiConNabPolozkyDataDto[];
  nabStruktura?: EstiConNabStrukturaDataDto[];
}

export interface EsticonObjectLinkDto {
  esticonObjectId: Guid;
  category: EstiCategoryEnum;
  isDilci: boolean;
}

export interface EstiConPairingResult<S> {
  sourceKey: S;
  destinationId?: Guid;
  createNew: boolean;
  isPairedBySystem: boolean;
  setNull: boolean;
  canSetNull: boolean;
}

export interface EstiConParovaniKamenuKV {
  old: EstiConKVPolozkaDto;
  new: EstiConKVPolozkaDto;
}

export interface EstiConPartnerDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name?: string;
  ico: string;
  dic?: string;
  ulice?: string;
  mesto?: string;
  psc?: string;
  isSdruzeni: boolean;
  osoba: boolean;
  platceDph: boolean;
  obchodniRejstrik?: string;
  aktualizovatAres: boolean;
  znackaAdresy?: string;
  adresyPartnera?: EstiConAdresaDto[];
  bankovniUcty?: EstiConBankovniUcetDto[];
  kontaktniOsoby?: EstiConKontaktniOsobaDto[];
}

export interface EstiConPartneriPairingData {
  partneri?: EstiConCisPartneriDataDto[];
  availableAdresy?: EstiConPartnerDto[];
  partneriPairingResults?: EstiConPairingResult<Guid>[];
  adresyPairingResults?: EstiConPairingResult<Guid>[];
  invalidIcoPartneriPairingResults?: EstiConPairingResult<Guid>[];
  invalidIcoAdresyPairingResults?: EstiConPairingResult<Guid>[];
}

export interface EstiConPKVDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  typPotreby: number;
  znacka: string;
  nazev?: string;
  isPKVUsed: boolean;
}

export interface EstiConPkvPairingData {
  pkvList?: EstiConCisPkvDataDto[];
  pkvPairingResults?: EstiConPairingResult<Guid>[];
  availablePkvList?: EstiConPKVDto[];
}

export interface EstiConPolozkaCerpaniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  variant?: string;
  stavebniDilId: Guid;
  seq: number;
  unit?: string;
  jnc: number;
  joc: number;
  investicniMnozstvi: number;
  fixniOc: boolean;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  sazbaDph: number;
  poradoveCislo: number;
  createdBy?: string;
  createdId?: Guid;
  selectedFlags: EstiConFlagType;
  aktivita: number;
  odpadniPolozka: boolean;
  korekcniPolozka: boolean;
  specifikace: EstiConRozPolozkaSpecifikaceDto;
  hmotnost: number;
  sut: number;
  presunHmot: EstiConTypPresunHmot;
  pocetDni: number;
  denniVykon: number;
  prebiratVykon: boolean;
  valorizace: boolean;
  skupinaMereni?: string;
  historieCef?: EstiConRozPolozkaHistorieCefDto[];
  rozpisBase?: EstiConIRozPolozkaRozpis[];
  controlAmount: number;
  parentZpId: Guid;
  sazba?: number;
  rozpis?: EstiConPolozkaRozpisCerpaniDto[];
  kalkVerze?: EstiConRozPolozkaKalkVerzeDto[];
}

export interface EstiConPolozkaFakturyDataDto {
  id: Guid;
  objekt?: string;
  cerpano?: number;
  valorizaceProcento?: number;
  valorizace?: number;
  slevaProcento?: number;
  sleva?: number;
}

export interface EstiConPolozkaFullDtoBase {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  variant?: string;
  stavebniDilId: Guid;
  rozpisBase?: EstiConIRozPolozkaRozpis[];
  seq: number;
  unit?: string;
  jnc: number;
  joc: number;
  investicniMnozstvi: number;
  fixniOc: boolean;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  sazbaDph: number;
  poradoveCislo: number;
  createdBy?: string;
  createdId?: Guid;
  selectedFlags: EstiConFlagType;
  aktivita: number;
  odpadniPolozka: boolean;
  korekcniPolozka: boolean;
  specifikace: EstiConRozPolozkaSpecifikaceDto;
  hmotnost: number;
  sut: number;
  presunHmot: EstiConTypPresunHmot;
  pocetDni: number;
  denniVykon: number;
  prebiratVykon: boolean;
  valorizace: boolean;
  skupinaMereni?: string;
  historieCef?: EstiConRozPolozkaHistorieCefDto[];
}

export interface EstiConPolozkaRozpisCerpaniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mnozstvi: number;
  idRozStruktura?: Guid;
  cerpano?: number;
  cerpaneMnozstvi?: number;
  elementPath?: string;
  zbvVystaveno?: IsoDateTime;
  zbvZnacka?: string;
  idZbvZmenoveListy?: Guid;
  zbvId?: Guid;
  rozpocetStructureId?: Guid;
  zbvType: EstiConZbvType;
  isFromMikrorozpocet: boolean;
  vykazVymer: EstiConVykazVymerDto;
  zpusobilost: boolean;
  reklamace: boolean;
  isRozpisSchvalenyViaZbv: boolean;
  prenosHub: EstiConPrenosHub;
  souvislostSKladnouZmenou: boolean;
  cerpaniPolozky?: EstiConCerpaniPolozkyDto[];
  jeStejnyTypSeZp: boolean;
}

export interface EstiConPrehledStavebPohDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  phase: EstiConProjectPhase;
  uzivatelskeZatrideniProjektuZnacka?: string;
  uzivatelskeZatrideniProjektuNazev?: string;
  typ?: string;
  utvar?: string;
  tds?: string;
  nazevAkce?: string;
  dodavatel?: string;
  soD?: IsoDateTime;
  zahajeni: IsoDateTime;
  dokonceni: IsoDateTime;
  prostavenoAktRok?: number;
  prostavenoCelkem?: number;
  investiceCelkem?: number;
  investiceRok?: number;
  ostatniCelkem?: number;
  ostatniRok?: number;
  nezatrideno?: number;
  poznamka?: string;
  autorPosledniPoznamky?: string;
  datumPosledniPoznamky?: IsoDateTime;
  idUtvaru: Guid;
  aktualniCenaI?: number;
  aktualniCenaO?: number;
  cenaNezatrideno?: number;
  iCelkem?: number;
  iRok?: number;
  oCelkem?: number;
  oRok?: number;
  prostavenoAktRokO1?: number;
  prostavenoAktRokO2?: number;
  prostavenoAktRokO3?: number;
  prostavenoAktRokO4?: number;
  prostavenoAktRokO5?: number;
  prostavenoAktRokO6?: number;
  prostavenoAktRokO7?: number;
  prostavenoAktRokO8?: number;
  prostavenoAktRokO9?: number;
  prostavenoAktRokO10?: number;
  prostavenoAktRokO11?: number;
  prostavenoAktRokO12?: number;
  prostavenoAktRokI1?: number;
  prostavenoAktRokI2?: number;
  prostavenoAktRokI3?: number;
  prostavenoAktRokI4?: number;
  prostavenoAktRokI5?: number;
  prostavenoAktRokI6?: number;
  prostavenoAktRokI7?: number;
  prostavenoAktRokI8?: number;
  prostavenoAktRokI9?: number;
  prostavenoAktRokI10?: number;
  prostavenoAktRokI11?: number;
  prostavenoAktRokI12?: number;
  planAktRokI?: number;
  planAktRokO?: number;
  planAktRokI1?: number;
  planAktRokI2?: number;
  planAktRokI3?: number;
  planAktRokI4?: number;
  planAktRokI5?: number;
  planAktRokI6?: number;
  planAktRokI7?: number;
  planAktRokI8?: number;
  planAktRokI9?: number;
  planAktRokI10?: number;
  planAktRokI11?: number;
  planAktRokI12?: number;
  planAktRokO1?: number;
  planAktRokO2?: number;
  planAktRokO3?: number;
  planAktRokO4?: number;
  planAktRokO5?: number;
  planAktRokO6?: number;
  planAktRokO7?: number;
  planAktRokO8?: number;
  planAktRokO9?: number;
  planAktRokO10?: number;
  planAktRokO11?: number;
  planAktRokO12?: number;
  planAktRok1I?: number;
  planAktRok1O?: number;
  planAktRok2I?: number;
  planAktRok2O?: number;
  planAktRok3I?: number;
  planAktRok3O?: number;
  planAktRok4I?: number;
  planAktRok4O?: number;
  prebiranaUroven?: number;
  isFpRozepsanoVse: boolean;
}

export interface EstiConProjectClenSdruzeniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  firma: EstiConAdresaPartnerDto;
  order: number;
  isInZjistovak: boolean;
  isInFaktura: boolean;
}

export interface EstiConProjectDashboardDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name: string;
  phase: EstiConProjectPhase;
  stage: EstiConProjectStage;
  structureCreation: EstiConProjectStructureCreation;
  zatrideniPolozek: EstiConProjectZatrideniPolozek;
  begin?: IsoDateTime;
  end?: IsoDateTime;
  soD?: number;
  zbv?: number;
  buildCost?: number;
  utvarId: Guid;
  hasUtvarWriteRight: boolean;
  hasUtvarReadRight: boolean;
  hasProjectWriteRight: boolean;
  hasProjectReadRight: boolean;
  investor?: string;
  dodavatel?: string;
  projektant?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  settings: EstiConProjectSettingsDto;
  notes?: EstiConProjectNoteDto[];
  evidencniCislo?: string;
  zjistovaciProtokoly?: EstiConZjistovaciProtokolDto[];
  typVarianty: EstiConProjectVariantType;
  idPredch?: Guid;
}

export interface EstiConProjectDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name: string;
  phase: EstiConProjectPhase;
  stage: EstiConProjectStage;
  created: IsoDateTime;
  begin?: IsoDateTime;
  end?: IsoDateTime;
  cenaOdpady: number;
  hmgExists: boolean;
  settings: EstiConProjectSettingsDto;
  clenoveSdruzeni?: EstiConProjectClenSdruzeniDto[];
  rekapitulaceSkupinZbv?: EstiConProjectZbvSkupinyRekapitulaceDto[];
  investor: EstiConAdresaPartnerDto;
  projektant: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  kontaktInvestor?: string;
  kontaktProjektant?: string;
  kontaktDodavatel?: string;
  hasNabidky: boolean;
  hasValorizovaneZBV: boolean;
  hasCerpani: boolean;
  hasCerpaniSdruzeni: boolean;
  hasFaktury: boolean;
  hasFakturySdruzeni: boolean;
  hasZbv: boolean;
  hasZbvDodatky: boolean;
  hasHistorieAktivitCef: boolean;
  pocetSdkp: number;
  selectedUtvarId: Guid;
  soD?: number;
  zbv?: number;
  menePrace?: number;
  cerpanoSod?: number;
  cerpanoZbv?: number;
  zakladDphVSazbe1?: number;
  zakladDphVSazbe2?: number;
  zakladDphVSazbe3?: number;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  kvVzorce: EstiConKVHeaderDto;
  sKod?: string;
  evidencniCislo?: string;
  userSettings?: EstiConProjectUserSettingsDto[];
  userRights?: EstiConProjectRightsDto[];
  userSORights?: EstiConSORightsDto[];
  hubLog?: EstiConProjectHubLogDto[];
  kalkulaceProjectSaveAction: EstiConKalkulaceProjectSaveAction;
  sumFakCelkem?: number;
  sumFakCelkemSDPH?: number;
  sumFakPozastavka?: number;
  sumFakVyuzitiZaloh?: number;
  sumFakZadrzne?: number;
  sumFakValorizace?: number;
  sumZpusobileCelkem?: number;
  sumZpusobileSoD?: number;
  sumZpusobileZBV?: number;
  sumCerpano?: number;
  sumCerpanoZpusobile?: number;
  rozlisovatZpusobile: boolean;
  hasDocerpanePolozkySFa: boolean;
  hasDocerpanePolozky: boolean;
  zpCount: number;
  zbvCount: number;
  faCount: number;
  spolFaCount: number;
  spolZpCount: number;
  typVarianty: EstiConProjectVariantType;
  idPredch?: Guid;
  tmoPkv?: EstiConProjectTmoPkvDto[];
  notes?: EstiConProjectNoteDto[];
  zatrideniProjektuZnackaUzivatelske?: string;
  zatrideniProjektuNazevUzivatelske?: string;
  vytvoritVariantu: boolean;
}

export interface EstiConProjectFavouriteDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  uzivatelId: Guid;
  isFavourite: boolean;
}

export interface EstiConProjectHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConProjectNoteDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  projectId: Guid;
  note: string;
  autor?: string;
  login?: string;
  datumZmeny?: IsoDateTime;
}

export interface EstiConProjectRightsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppUzivatele: Guid;
  cteni: boolean;
  zapis: boolean;
  spravce: boolean;
  userFirstName?: string;
  userLastName?: string;
  login?: string;
}

export interface EsticonProjectRowDto {
  id: Guid;
  firmId: Guid;
  utvarId: Guid;
  settings: EstiConProjectSettingsDto;
  name?: string;
  sign?: string;
  phase: EstiConProjectPhase;
  stage: EstiConProjectStage;
  structureCreation: EstiConProjectStructureCreation;
  zatrideniPolozek: EstiConProjectZatrideniPolozek;
  begin?: IsoDateTime;
  end?: IsoDateTime;
  soD?: number;
  zbv?: number;
  buildCost?: number;
  zjistovaciProtokoly?: EsticonZjistovaciProtokolDto[];
  financniPlan: EstiConHarmonogramVersionDto;
}

export interface EstiConProjectRowDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  phase: EstiConProjectPhase;
  stage: EstiConProjectStage;
  structureCreation: EstiConProjectStructureCreation;
  zatrideniPolozek: EstiConProjectZatrideniPolozek;
  begin?: IsoDateTime;
  end?: IsoDateTime;
  cenaSod?: number;
  cenaZbv?: number;
  cenaCerpano?: number;
  zaokrouhleniCena: number;
  utvarId: Guid;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  sdruzeni: boolean;
  kalkulace: boolean;
  skod?: string;
  evidencniCislo?: string;
  zakladniDatum?: IsoDateTime;
  omezeniPrav: boolean;
  hasProjectWriteRight: boolean;
  hasProjectReadRight: boolean;
  investor: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  projektant: EstiConAdresaPartnerDto;
  provedeno?: number;
  cerpanoZbv?: number;
  cerpanoSod?: number;
  created: IsoDateTime;
  urcenoKeKontrole: boolean;
  urcenoKArchivaci: boolean;
  currency: EstiConMenyDto;
  favourite: EstiConProjectFavouriteDto;
  typVarianty: EstiConProjectVariantType;
  idPredch?: Guid;
}

export interface EstiConProjectSettingsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  tvorbaStruktury: EstiConProjectStructureCreation;
  zatrideniPolozek: EstiConProjectZatrideniPolozek;
  zatrideniPolozekZBV: EstiConProjectZatrideniPolozek;
  typUdrzbyPc: EstiConTypUdrzbyPC;
  posloupnostUdrzbyPc: EstiConPosloupnostUdrzbyPC;
  isVeSdruzeni: boolean;
  zaokrouhleniMnozstvi: number;
  zaokrouhleniCena: number;
  zaokrouhleniCenaJednotkova: number;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  idDphZeme: Guid;
  idDphObdobi: Guid;
  dphSazba: number;
  pozastavka: number;
  zadrzne: number;
  vypoctySDph: boolean;
  rozlisovatZpusobile: boolean;
  zaZhotovitele?: string;
  zaZhotoviteleFunkce?: string;
  zaObjednatele?: string;
  zaObjednateleFunkce?: string;
  mistoPodpisu?: string;
  mistoPodpisuOkres?: string;
  mistoStavby?: string;
  smlouva?: string;
  smlouvaDatum?: IsoDateTime;
  smlouvaHodnota?: number;
  smlouvaObjednatel?: string;
  smlouvaObjednatelDatum?: IsoDateTime;
  zaZhotovitelePolozky?: string;
  zaZhotovitelePolozkyFunkce?: string;
  zaObjednatelePolozky?: string;
  zaObjednatelePolozkyFunkce?: string;
  adresaFirmy: EstiConAdresaPartnerDto;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  zarukaPredani?: IsoDateTime;
  zarukaDo?: IsoDateTime;
  zarukaMesice?: number;
  cislovaniPolozekZBV?: number;
  skupinyZbv: boolean;
  kontrolaSkupinRsd: boolean;
  vybranaSkupinaZbv: EstiConTypSkupinyZbv;
  obsahujeZbvSkupinyRozpis: boolean;
  obsahujeSchvaleneZbv: boolean;
  obsahujeOdeslaneZBV: boolean;
  cenovaSoustavaTM?: string;
  cenovaSoustavaTypeTM?: string;
  isprofin?: string;
  ms2014?: string;
  slevaProcento?: number;
  slevaBezValorizaceFa: boolean;
  splatnostPocetDniFa?: number;
  zaZhotoviteleFa?: string;
  zaZhotoviteleFaFce?: string;
  zaObjednateleFa?: string;
  zaObjednateleFaFce?: string;
  mistoPodpisuFa?: string;
  spravceFaRsd?: string;
  zaZhotoviteleFaSdruzeni?: string;
  zaZhotoviteleFaSdruzeniFce?: string;
  zaObjednateleFaSdruzeni?: string;
  zaObjednateleFaSdruzeniFce?: string;
  mistoPodpisuFaSdruzeni?: string;
  spravceFaRsdSdruzeni?: string;
  prefixSdruzeniZp?: string;
  prefixSdruzeniFa?: string;
  typFidic: EstiConFidicType;
  financovaniCef: boolean;
  pocetAktivit: number;
  minPocetAktivit: number;
  omezeniPrav: boolean;
  omezeniPravObjektu: boolean;
  zapocitatSint: boolean;
  povolitPrecerpani: boolean;
  kontrolaDuplicitPr: boolean;
  odpadySZ: boolean;
  kv: EstiConKVDto;
  kalkulace: boolean;
  typCeny: EstiConTypCenyPolozky;
  typMnozstvi: EstiConTypMnozstviPotreby;
  hasZPSPozastavkou: boolean;
  kontrolaDuplicitPc: boolean;
  parovaneKameny?: EstiConParovaniKamenuKV[];
  rezimPrenosuDphZP: EstiConRezimPrenosuDPH;
  rezimPrenosuDphFA: EstiConRezimPrenosuDPH;
  prohlaseniFa?: string;
  prohlaseniZp?: string;
  stavbyvedouci?: string;
  cenoveProjednani?: string;
  autorskyDozor?: string;
  stavebniDozor?: string;
  spravceStavby?: string;
  opravnenaOsoba?: string;
  supervize?: string;
  zakladniDatum?: IsoDateTime;
  urcenoKeKontrole: boolean;
  valorizovat: boolean;
  typValorizace: EstiConValorizaceTyp;
  typObdobiValorizace: EstiConObdobiValorizace;
  odstupValorizace?: number;
  urcenoKArchivaci: boolean;
  docerpani: boolean;
  zhotovitelPriloha7?: string;
  mereniZhotovitel?: string;
  mereniAsistent?: string;
  alternativniMena: boolean;
  cisMena: EstiConMenyDto;
  kurz: number;
  vypnutiValidace: boolean;
  tmoOmezitVyberPkv: boolean;
  duzpDateSetting: EstiConDUZPDateSettings;
}

export interface EstiConProjectSimpleDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idKV?: Guid;
  projectSign?: string;
  projectName?: string;
  utvarSign?: string;
  utvarName?: string;
  phase: EstiConProjectPhase;
  stage: EstiConProjectStage;
  utvarId: Guid;
  created: IsoDateTime;
  utvarString?: string;
  isChecked: boolean;
}

export interface EstiConProjectsInRealisationReportPOHRequestDto {
  utcFrom: IsoDateTime;
  processedYear: number;
}

export interface EstiConProjectsInRealisationReportPOHResponseDto {
  rows?: EstiConPrehledStavebPohDto[];
  utcFrom: IsoDateTime;
  utcSetTime: IsoDateTime;
  processedYear: number;
}

export interface EstiConProjectTmoPkvDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  pkv: EstiConPKVDto;
}

export interface EstiConProjectUserSettingsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name: string;
  setting: string;
  category: EstiConCategoryModules;
  idAppProjekty: Guid;
}

export interface EstiConProjectZbvSkupinyRekapitulaceDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  typSkupiny: EstiConTypSkupinyZbv;
  cena?: number;
  menePrace?: number;
  vicePrace?: number;
  isChecked: boolean;
}

export interface EstiConReaCerpanePolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idReaZjistovaciProtokoly: Guid;
  cerpaneMnozstvi: number;
  cerpanoCena: number;
  dphSazba: number;
  provedeneMnozstvi: number;
  korekceCeny: boolean;
  odpady?: string;
  vykHlavicky: EstiConVykHlavickyDataDto;
  reaCerpanePolozkySubdodavky?: EstiConReaCerpanePolozkySubdodavkyDataDto[];
}

export interface EstiConReaCerpanePolozkySubdodavkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  cerpaneMnozstvi: number;
  provedeneMnozstvi: number;
  idRozPolozkySubdodavky: Guid;
}

export interface EstiConReaZjistovaciProtokolyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  dphObdobiMesic?: number;
  dphObdobiRok?: number;
  znacka: string;
  idZbvZmenoveListy?: Guid;
  idCisDphSazby?: Guid;
  idCisAdresyInvestor?: Guid;
  idCisAdresyDodavatel?: Guid;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  rezimPrenosuDph: number;
  mistoStavby?: string;
  okres?: string;
  smlouva?: string;
  smlouvaObjednatel?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePol?: string;
  zaZhotoviteleFcePol?: string;
  zaObjednatelePol?: string;
  zaObjednateleFcePol?: string;
  idCisAdresyFirma?: Guid;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  idFakFaktury?: Guid;
  valorizace?: number;
  pozastavka?: number;
  zadrzne?: number;
  valorizaceProcento?: number;
  pozastavkaProcento?: number;
  zadrzneProcento?: number;
  idReaZjistovaciProtokolySdruzeni?: Guid;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  zpusobilost: boolean;
  storno: boolean;
  typZp: number;
  obdobiValorizace?: IsoDateTime;
  reaZjistovaciProtokolyHubLog?: EstiConReaZjistovaciProtokolyHubLogDataDto[];
}

export interface EstiConReaZjistovaciProtokolyHubLogDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: number;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConReaZjistovaciProtokolySdruzeniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  idZbvZmenoveListy?: Guid;
  idCisAdresyInvestor?: Guid;
  idCisAdresyDodavatel?: Guid;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  mistoStavby?: string;
  okres?: string;
  smlouva?: string;
  smlouvaObjednatel?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePol?: string;
  zaZhotoviteleFcePol?: string;
  zaObjednatelePol?: string;
  zaObjednateleFcePol?: string;
  idCisAdresyFirma?: Guid;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  zpusobilost: boolean;
  reaZjistovaciProtokolySdruzeniHubLog?: EstiConReaZjistovaciProtokolySdruzeniHubLogDataDto[];
}

export interface EstiConReaZjistovaciProtokolySdruzeniHubLogDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: number;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConRozElementAtributDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  name: string;
  unit?: string;
  value?: string;
}

export interface EstiConRozElementDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  model3DStructure?: EstiConBimModelStructureDto[];
}

export interface EstiConRozElementyAtributyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  hodnota?: string;
  nazev: string;
  mj?: string;
}

export interface EstiConRozElementyDataDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mdlModelyStruktura?: EstiConMdlModelyStrukturaDataDto[];
  rozElementyAtributy?: EstiConRozElementyAtributyDataDto[];
}

export interface EstiConRozFakturyDodavateleDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  cenaCelkem: number;
  obdobi?: IsoDateTime;
}

export interface EstiConRozFiguryDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozkyRozpis?: Guid;
  znacka: string;
  popis?: string;
  hodnota?: number;
  vykHlavicky: EstiConVykHlavickyDataDto;
}

export interface EstiConRozKategorieDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisKategorieSubdodavky: Guid;
  planNc: number;
  cenaZasmluvneno: number;
  rozKategorieDodavatele?: EstiConRozKategorieDodavateleDataDto[];
}

export interface EstiConRozKategorieDodavateleDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisAdresy: Guid;
  cenaZasmluvneno: number;
  planNc: number;
  rozFakturyDodavatele?: EstiConRozFakturyDodavateleDataDto[];
}

export interface EstiConRozKpDataDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  kp?: string;
}

export interface EstiConRozKPDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  kp?: string;
}

export interface EstiConRozKvHlavickyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  rozKvVzorce?: EstiConRozKvVzorceDataDto[];
}

export interface EstiConRozKvVzorceDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisKvPolozky: Guid;
  idCisAdresy?: Guid;
  vzorec?: string;
  idCisPkvSubd?: Guid;
}

export interface EstiConRozPolozkaCenyPkvDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisPkv: Guid;
  idCisPkvSubd?: Guid;
  idCisAdresy?: Guid;
  jc: number;
  cena: number;
  fixniOC: boolean;
}

export interface EstiConRozPolozkaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  variant?: string;
  stavebniDilId: Guid;
  seq: number;
  unit?: string;
  jnc: number;
  joc: number;
  investicniMnozstvi: number;
  fixniOc: boolean;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  sazbaDph: number;
  poradoveCislo: number;
  createdBy?: string;
  createdId?: Guid;
  selectedFlags: EstiConFlagType;
  aktivita: number;
  odpadniPolozka: boolean;
  korekcniPolozka: boolean;
  specifikace: EstiConRozPolozkaSpecifikaceDto;
  hmotnost: number;
  sut: number;
  presunHmot: EstiConTypPresunHmot;
  pocetDni: number;
  denniVykon: number;
  prebiratVykon: boolean;
  valorizace: boolean;
  skupinaMereni?: string;
  historieCef?: EstiConRozPolozkaHistorieCefDto[];
  rozpisBase?: EstiConIRozPolozkaRozpis[];
  kalkVerze?: EstiConRozPolozkaKalkVerzeDto[];
  rozpis?: EstiConRozPolozkaRozpisDto[];
  dtoId: Guid;
  dtoType: EstiConRozStrukturaTypUzlu;
}

export interface EstiConRozPolozkaExtraDataDto {
  specifikace?: EstiConRozPolozkaSpecifikaceDto[];
  vykazyVymer?: EstiConVykazVymerDto[];
}

export interface EstiConRozPolozkaHistorieCefDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  platnostOd: IsoDateTime;
  zmena: IsoDateTime;
  vypracoval: string;
  aktivitaZ: number;
  aktivitaNa: number;
  zduvodneni: string;
}

export interface EstiConRozPolozkaKalkVerzeDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  aktualni: boolean;
  typCeny: number;
  rozPolozkyPotreby?: EstiConRozPolozkaPotrebaDto[];
  rozPolozkySubdodavky?: EstiConRozPolozkaSubdodavkyDto[];
  rozPolozkyCenyPkv?: EstiConRozPolozkaCenyPkvDto[];
}

export interface EstiConRozPolozkaPotrebaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozkyKalkVerze: Guid;
  idCisPkv?: Guid;
  idCisAdresy?: Guid;
  dodavatelNazev?: string;
  vykHlavickyMnozstvi: EstiConVykazVymerDto;
  vykHlavickyVykon: EstiConVykazVymerDto;
  vykHlavickyFaktor: EstiConVykazVymerDto;
  typUzlu: number;
  typMnozstvi: number;
  znacka: string;
  nazev?: string;
  popis?: string;
  mj?: string;
  vykon?: number;
  mnozstvi: number;
  mnozstviMjPr: number;
  faktor: number;
  jnc: number;
  jncPuv: number;
  csVarianta?: string;
  csTyp?: string;
  hmotnost: number;
  sut: number;
  idPredch?: Guid;
  rozPolozkyPotreby?: EstiConRozPolozkaPotrebaDto[];
}

export interface EstiConRozPolozkaRealizaceDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zpSign?: string;
  cerpanoMnozstvi: number;
  cerpanoCena: number;
  provedeneMnozstvi: number;
  vystaveno: IsoDateTime;
  zlSign?: string;
  fakSign?: string;
  jnc: number;
  korekceCeny: boolean;
}

export interface EstiConRozPolozkaRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mnozstvi: number;
  idRozStruktura?: Guid;
  cerpano?: number;
  cerpaneMnozstvi?: number;
  elementPath?: string;
  zbvVystaveno?: IsoDateTime;
  zbvZnacka?: string;
  idZbvZmenoveListy?: Guid;
  zbvId?: Guid;
  rozpocetStructureId?: Guid;
  zbvType: EstiConZbvType;
  isFromMikrorozpocet: boolean;
  vykazVymer: EstiConVykazVymerDto;
  zpusobilost: boolean;
  reklamace: boolean;
  isRozpisSchvalenyViaZbv: boolean;
  prenosHub: EstiConPrenosHub;
  souvislostSKladnouZmenou: boolean;
  cerpaniPolozky?: EstiConCerpaniPolozkyDto[];
}

export interface EstiConRozPolozkaSpecifikaceDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  description?: string;
  specification?: string;
  poznamka?: string;
  poznamkaKalkulant?: string;
}

export interface EstiConRozPolozkaSubdodavkyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisPkv: Guid;
  idCisAdresy?: Guid;
  dodavatelNazev?: string;
  idKategorieSubdodavky?: Guid;
  kategorieSubdodavkySign?: string;
  kategorieSubdodavkyName?: string;
  procento: number;
  mnozstvi: number;
  jc: number;
  subdodavkaType: number;
}

export interface EstiConRozPolozkyCenyPkvDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  jc: number;
  idCisPkv: Guid;
  idCisAdresy?: Guid;
  idCisPkvSubd?: Guid;
}

export interface EstiConRozPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  createdByZbv: boolean;
  znacka?: string;
  nazev?: string;
  varianta?: string;
  popis?: string;
  specifikace?: string;
  mj?: string;
  jnc: number;
  joc: number;
  invMnozstvi: number;
  idStavebniDil: Guid;
  poradoveCislo: number;
  fixniOc: boolean;
  sazbaDph: number;
  csTyp?: string;
  csVarianta?: string;
  kategorie: number;
  aktivitaCef: number;
  odpadniPolozka: boolean;
  korekcniPolozka: boolean;
  hmotnost: number;
  sut: number;
  presunHmot: number;
  denniVykon: number;
  pocetDni: number;
  prebiratVykon: boolean;
  valorizace: boolean;
  poznamka?: string;
  poznamkaKalkulant?: string;
  skupinaMereni?: string;
  rozPolozkyDokumenty?: EstiConRozPolozkyDokumentyDataDto[];
  rozPolozkyHistorieCef?: EstiConRozPolozkyHistorieCefDataDto[];
  rozPolozkyKalkVerze?: EstiConRozPolozkyKalkVerzeDataDto[];
  rozPolozkyRozpis?: EstiConRozPolozkyRozpisDataDto[];
  tmoPolozkyZatrideniSppEurovia: EstiConTmoPolozkyZatrideniSppEuroviaDataDto;
}

export interface EstiConRozPolozkyDokumentyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppDokumenty: Guid;
}

export interface EstiConRozPolozkyHistorieCefDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  datumPlatnostOd: IsoDateTime;
  datumZmeny: IsoDateTime;
  vypracoval: string;
  typZmenaZ: number;
  typZmenaNa: number;
  zduvodneni: string;
}

export interface EstiConRozPolozkyKalkVerzeDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  aktualni: boolean;
  typCeny: number;
  rozPolozkyPotreby?: EstiConRozPolozkyPotrebyDataDto[];
  rozPolozkyCenyPkv?: EstiConRozPolozkyCenyPkvDataDto[];
  rozPolozkySubdodavky?: EstiConRozPolozkySubdodavkyDataDto[];
}

export interface EstiConRozPolozkyPotrebyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozkyKalkVerze: Guid;
  idCisAdresy?: Guid;
  typUzlu: number;
  typMnozstvi: number;
  znacka: string;
  nazev?: string;
  popis?: string;
  mj?: string;
  vykon?: number;
  mnozstvi: number;
  faktor: number;
  jnc: number;
  csTyp?: string;
  csVarianta?: string;
  idCisPkv?: Guid;
  mnozstviMjPr: number;
  jncPuv: number;
  hmotnost: number;
  sut: number;
  rozPolozkyPotreby?: EstiConRozPolozkyPotrebyDataDto[];
  vykHlavickyFaktor: EstiConVykHlavickyDataDto;
  vykHlavickyMnozstvi: EstiConVykHlavickyDataDto;
  vykHlavickyVykon: EstiConVykHlavickyDataDto;
}

export interface EstiConRozPolozkyRozpisDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozStruktura?: Guid;
  mnozstvi: number;
  jeZMikrorozpoctu: boolean;
  idZbvZmenoveListy?: Guid;
  zpusobilost: boolean;
  reklamace: boolean;
  souvislostSVicepraci: boolean;
  reaCerpanePolozky?: EstiConReaCerpanePolozkyDataDto[];
  vykHlavicky: EstiConVykHlavickyDataDto;
  rozPolozkyRozpisSdruzeniMnozstvi?: EstiConRozPolozkyRozpisSdruzeniMnozstviDataDto[];
  zbvSkupinyRozpis?: EstiConZbvSkupinyRozpisDataDto[];
}

export interface EstiConRozPolozkyRozpisSdruzeniMnozstviDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mnozstvi: number;
  idCisAdresy: Guid;
}

export interface EstiConRozPolozkySubdodavkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mnozstvi: number;
  procento: number;
  jc: number;
  idCisPkv: Guid;
  idCisAdresy?: Guid;
  idCisKategorieSubdodavky?: Guid;
  subdodavkaType: number;
}

export interface EstiConRozPolozkyZpetneOceneniDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozky: Guid;
  reference: string;
}

export interface EstiConRozProjektDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  kvVzorce: EstiConKVHeaderDto;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  kontrolaDuplicitPr: boolean;
  children?: EstiConRozStrukturaDto[];
}

export interface EstiConRozSOCerpaniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFunkce?: string;
  zaObjednatele?: string;
  zaObjednateleFunkce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePolozky?: string;
  zaZhotovitelePolozkyFunkce?: string;
  zaObjednatelePolozky?: string;
  zaObjednatelePolozkyFunkce?: string;
  adresaFirmy: EstiConAdresaPartnerDto;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  zhotovitelMereniPol?: string;
  asistentMereniPol?: string;
}

export interface EstiConRozSoDataDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisAdresyInvestor?: Guid;
  idCisAdresyProjektant?: Guid;
  idCisAdresyDodavatel?: Guid;
  stavbyvedouci?: string;
  cenoveProjednani?: string;
  autorskyDozor?: string;
  stavebniDozor?: string;
  spravceStavby?: string;
  opravnenaOsoba?: string;
  supervize?: string;
  zadaniVypracoval?: string;
  datumZadani?: IsoDateTime;
  nabidkuVypracoval?: string;
  datumNabidky?: IsoDateTime;
  dphSazba: number;
  zaZhotovitele?: string;
  zaZhotoviteleFce?: string;
  zaObjednatele?: string;
  zaObjednateleFce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePol?: string;
  zaZhotoviteleFcePol?: string;
  zaObjednatelePol?: string;
  zaObjednateleFcePol?: string;
  idCisAdresyFirma?: Guid;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  csTyp?: string;
  csVarianta?: string;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  szZhotovitel?: string;
  szDozor?: string;
  szSpravce?: string;
  szZastupce?: string;
  szPravnik?: string;
  szIniciatorZmeny?: string;
  szOdpovednaOsoba?: string;
  szVedouci?: string;
  szReditel?: string;
  szNamestek?: string;
  szPredbeznySouhlas?: string;
  szDatumPredbeznySouhlas?: IsoDateTime;
  cenaKalkulaceOdpadu: boolean;
  typCeny: number;
  typMnozstvi: number;
  mereniZhotovitel?: string;
  mereniAsistent?: string;
  zhotovitelPriloha7?: string;
  zatrideniZnackaCzcpa?: string;
  zatrideniNazevCzcpa?: string;
  zatrideniZnackaCzcc?: string;
  zatrideniNazevCzcc?: string;
  zatrideniZnackaUzivatelske?: string;
  zatrideniNazevUzivatelske?: string;
  mnozstvi: number;
  mj?: string;
  cenaOdpady?: number;
  rozSoDataDokumenty?: EstiConRozSoDataDokumentyDataDto[];
}

export interface EstiConRozSoDataDokumentyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppDokumenty: Guid;
}

export interface EstiConRozSODto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  investor: EstiConAdresaPartnerDto;
  projektant: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  dphSazba: number;
  cenaOdpady?: number;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  cenaKalkulaceOdpadu: boolean;
  hmgExists: boolean;
  rozpocet: EstiConRozSORozpocetDto;
  cerpani: EstiConRozSOCerpaniDto;
  zatrideniZnackaCzcpa?: string;
  zatrideniNazevCzcpa?: string;
  zatrideniZnackaCzcc?: string;
  zatrideniNazevCzcc?: string;
  zatrideniZnackaUzivatelske?: string;
  zatrideniNazevUzivatelske?: string;
  mnozstvi: number;
  mj?: string;
}

export interface EstiConRozSOExtraDataDto {
  polSpecifikace?: EstiConRozPolozkaSpecifikaceDto[];
  polVykazyVymer?: EstiConVykazVymerDto[];
}

export interface EstiConRozSoPravaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppProjekty: Guid;
  idAppUzivatele: Guid;
  cteni: boolean;
  zapis: boolean;
}

export interface EstiConRozSORozpocetDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  stavbyvedouci?: string;
  cenoveProjednani?: string;
  autorskyDozor?: string;
  stavebniDozor?: string;
  spravceStavby?: string;
  opravnenaOsoba?: string;
  supervize?: string;
  zadaniVypracoval?: string;
  datumZadani?: IsoDateTime;
  nabidkuVypracoval?: string;
  datumNabidky?: IsoDateTime;
  szZhotovitel?: string;
  szDozor?: string;
  szSpravce?: string;
  szZastupce?: string;
  szPravnik?: string;
  szIniciatorZmeny?: string;
  szOdpovednaOsoba?: string;
  szVedouci?: string;
  szReditel?: string;
  szNamestek?: string;
  szPredbeznySouhlas?: string;
  szDatumPredbeznySouhlas?: IsoDateTime;
  typCeny: EstiConTypCenyPolozky;
  typMnozstvi: EstiConTypMnozstviPotreby;
  zhotovitelPriloha7?: string;
}

export interface EstiConRozStrukturaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppProjekty: Guid;
  znacka?: string;
  nazev?: string;
  uroven?: number;
  typUzlu: number;
  generovane: boolean;
  investorskaUroven: boolean;
  zalozenoRealizace: boolean;
  popis?: string;
  reaZjistovaciProtokoly?: EstiConReaZjistovaciProtokolyDataDto[];
  reaZjistovaciProtokolySdruzeni?: EstiConReaZjistovaciProtokolySdruzeniDataDto[];
  rozElementyData: EstiConRozElementyDataDataDto;
  rozFigury?: EstiConRozFiguryDataDto[];
  rozKpData: EstiConRozKpDataDataDto;
  rozPolozky?: EstiConRozPolozkyDataDto[];
  rozSoData: EstiConRozSoDataDataDto;
  rozSoPrava?: EstiConRozSoPravaDataDto[];
  rozStruktura?: EstiConRozStrukturaDataDto[];
  rozKvHlavicky: EstiConRozKvHlavickyDataDto;
  zbvZmenoveListy?: EstiConZbvZmenoveListyDataDto[];
}

export interface EstiConRozStrukturaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  type: EstiConRozStrukturaTypUzlu;
  projectId: Guid;
  generated: boolean;
  isInvestorskaUroven: boolean;
  zalozenoRealizaci: boolean;
  stavebniObjekt: EstiConRozSODto;
  konstrukcniPrvek: EstiConRozKPDto;
  element: EstiConRozElementDto;
  kvVzorce: EstiConKVHeaderDto;
  figury?: EstiConVykazVymerFiguraRozpisDto[];
  displayName?: string;
  description?: string;
  polozkyBase?: EstiConPolozkaFullDtoBase[];
  children?: EstiConRozStrukturaDto[];
  polozky?: EstiConRozPolozkaDto[];
  nabidkaParovani: boolean;
  dtoId: Guid;
  dtoType: EstiConRozStrukturaTypUzlu;
}

export interface EstiConRozValorizaceDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zacatek?: IsoDateTime;
  konec?: IsoDateTime;
  rozValorizaceRozpis?: EstiConRozValorizaceRozpisDataDto[];
}

export interface EstiConRozValorizaceRozpisDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mesic?: number;
  index: number;
}

export interface EstiConSaveResultDto {
  result: EstiConSaveResult;
  internalError?: string;
  underlyingRecordsSaveResults?: EstiConSaveResultDto[];
  timeUpload: number;
  timeSave: number;
  errorCode: number;
  informationList?: EstiConSaveResultInformation[];
  canDeleteFile: boolean;
  saveResultExtendedData: EstiConDtoBase;
}

export interface EstiConSaveResultInformation {
  idValue: Guid;
}

export interface EstiConSelectionResult {
  sourceId: Guid;
  destinationId?: Guid;
  isSelected: boolean;
  isExistingItem: boolean;
  canCreate: boolean;
  disabledWriteRight: boolean;
  isExistingZbvApproved: boolean;
  isParentLastLevel: boolean;
  isOdpadniObjekt: boolean;
}

export interface EsticonSettingsDto {
  projectId: Guid;
  projectName?: string;
  projectDescription?: string;
  esticonDirectories?: Dictionary<Guid>;
}

export interface EstiConSkupinaZbvDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  skupinaTyp: EstiConTypSkupinyZbv;
  zmenaTyp?: number;
}

export interface EstiConSkupinyZbvPairingData {
  skupinyZbv?: EstiConCisSkupinyZbvDataDto[];
  skupinyZbvPairingResults?: EstiConPairingResult<Guid>[];
  availableSkupinyZbv?: EstiConSkupinaZbvDto[];
}

export interface EstiConSORightsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppUzivatele: Guid;
  idSO: Guid;
  cteni: boolean;
  zapis: boolean;
}

export interface EstiConStrukturaDataDto {
  id: Guid;
  parentId?: Guid;
  sign?: string;
  name?: string;
  isLastLevel: boolean;
  typUzlu: EstiConRozStrukturaTypUzlu;
}

export interface EstiConTmoDataDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idTmoVerze: Guid;
  idRozStruktura?: Guid;
  typUzlu: number;
  znacka: string;
  nazev?: string;
  mj?: string;
  mnozstvi: number;
  mnozstviHotovo: number;
  ncSkutecnost: number;
  ncPotreby: number;
  ocFakturovano: number;
  ocZbyva: number;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  trvani?: number;
  dobaZbyva?: number;
  nevyfakturovatelnePolBezZl: number;
  fakturaceObdobi: number;
  popis?: string;
  poznamka?: string;
  datumPoznamky?: IsoDateTime;
  kalPotreby: EstiConKalPotrebyDataDto;
  tmoData?: EstiConTmoDataDataDto[];
  tmoPolozky?: EstiConTmoPolozkyDataDto[];
  tmoRozpisZbvEurovia?: EstiConTmoRozpisZbvEuroviaDataDto[];
  tmoSkutecnost?: EstiConTmoSkutecnostDataDto[];
}

export interface EstiConTmoPolozkyCenyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozky: Guid;
  ocFakturovano: number;
  ocZbyva: number;
  mnozstvi: number;
  mnozstviHotovo: number;
  zalozeno: string;
}

export interface EstiConTmoPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozPolozky: Guid;
  idRozStruktura?: Guid;
  ridiciMnozstvi: boolean;
}

export interface EstiConTmoPolozkyZatrideniSppEuroviaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idTmoSppEurovia: Guid;
}

export interface EstiConTmoRozpisZbvEuroviaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idRozStruktura?: Guid;
  soZnacka: string;
  soNazev: string;
  pravdepodobnostZiskani: number;
  viceprace: number;
  meneprace: number;
  zlVynosSb3: number;
  zlVynosPovolenoPredseda: number;
  zlSkutecnaFakturace: number;
  nakladyMeneprace: number;
  nakladyMenepraceSint: number;
  nakladyVicepraceNeschvaleneZbv: number;
  nakladyVicepraceSint: number;
  nakladyKheopsSb3: number;
  nakladyKheopsSb3Sint: number;
  nakladyProvedeneVicepraceKheops: number;
  nakladyProvedeneVicepraceKheopsSint: number;
  nakladyZbyva: number;
  nakladyZbyvaSint: number;
  smluvniDodatekZnacka: string;
  smluvniDodatekTypStav: number;
}

export interface EstiConTmoRozpisZbvEuroviaPolozkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idTmoPolozkyCeny: Guid;
  viceprace: number;
  meneprace: number;
}

export interface EstiConTmoRozpisZbvEuroviaZlDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  typStav: number;
  schvaleniPredseda: boolean;
  tmoRozpisZbvEuroviaPolozky?: EstiConTmoRozpisZbvEuroviaPolozkyDataDto[];
}

export interface EstiConTmoSkutecnostDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisPkv?: Guid;
  datum: IsoDateTime;
  cena: number;
}

export interface EstiConTmoSppEuroviaDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  nazev?: string;
}

export interface EstiConTmoVerzeDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  datumAktualizace: IsoDateTime;
  znacka: string;
  nazev?: string;
  aktualni: boolean;
  vytvoreni: IsoDateTime;
  schvaleni?: IsoDateTime;
  kurz?: number;
  aktualizaceRozpisu: boolean;
  poznamka?: string;
  datumPoznamky?: IsoDateTime;
  tmoData?: EstiConTmoDataDataDto[];
}

export interface EstiConUtvarRightsDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userId: Guid;
  read: boolean;
  write: boolean;
  userFirstName?: string;
  userLastName?: string;
  userLogin?: string;
}

export interface EstiConUtvaryStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  parentId?: Guid;
  rights?: EstiConUtvarRightsDto[];
  structure?: EstiConUtvaryStructureDto[];
}

export interface EstiConValidationValue {
  source: EstiConValidationSourceType;
  value?: unknown;
}

export interface EstiConValorizacePairingData {
  importedValorizace?: EstiConRozValorizaceDataDto[];
  existingValorizace?: EstiConRozValorizaceDataDto[];
  valorizacePairingResults?: EstiConPairingResult<Guid>[];
}

export interface EstiConVykazVymerDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  vykRadky?: EstiConVykazVymerRowDto[];
  zaokrouhleniVyk?: number;
}

export interface EstiConVykazVymerFiguraRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  description?: string;
  value?: number;
  vykazVymer: EstiConVykazVymerDto;
  rozPolozkaRozpisId?: Guid;
}

export interface EstiConVykazVymerRowDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  kod?: string;
  popis?: string;
  vzorec?: string;
  poradi: number;
  zapocitat: boolean;
  hodnota?: number;
}

export interface EstiConVykHlavickyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  zaokrouhleniVyk?: number;
  vykHlavickyDokumenty?: EstiConVykHlavickyDokumentyDataDto[];
  vykRadky?: EstiConVykRadkyDataDto[];
}

export interface EstiConVykHlavickyDokumentyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idAppDokumenty: Guid;
}

export interface EstiConVykRadkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  kod?: string;
  popis?: string;
  vzorec?: string;
  zapocitat: boolean;
  poradi: number;
  hodnota?: number;
}

export interface EstiConZBVDodatekProjectDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  sazbaDph: number;
  children?: EstiConZBVDodatekStructureDto[];
}

export interface EstiConZBVDodatekStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  children?: EstiConZBVDodatekStructureDto[];
  zmenoveListy?: EstiConZBVDodatekZmenovyListDto[];
}

export interface EstiConZBVDodatekZmenovyListDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  zbvType: EstiConZbvType;
  smluvniDodatkyId?: Guid;
  cena: number;
}

export interface EstiConZBVKontrolaSkupinDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka?: string;
  polozkaId: Guid;
  zbvId: Guid;
  vystaveni: IsoDateTime;
}

export interface EstiConZBVPolozkaDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  variant?: string;
  stavebniDilId: Guid;
  seq: number;
  unit?: string;
  jnc: number;
  joc: number;
  investicniMnozstvi: number;
  fixniOc: boolean;
  cenovaSoustava?: string;
  cenovaSoustavaType?: string;
  sazbaDph: number;
  poradoveCislo: number;
  createdBy?: string;
  createdId?: Guid;
  selectedFlags: EstiConFlagType;
  aktivita: number;
  odpadniPolozka: boolean;
  korekcniPolozka: boolean;
  specifikace: EstiConRozPolozkaSpecifikaceDto;
  hmotnost: number;
  sut: number;
  presunHmot: EstiConTypPresunHmot;
  pocetDni: number;
  denniVykon: number;
  prebiratVykon: boolean;
  valorizace: boolean;
  skupinaMereni?: string;
  historieCef?: EstiConRozPolozkaHistorieCefDto[];
  rozpisBase?: EstiConIRozPolozkaRozpis[];
  polozkyRozpis?: EstiConZBVPolozkaRozpisDto[];
  kontrolaSkupinList?: EstiConZBVKontrolaSkupinDataDto[];
  kalkVerze?: EstiConRozPolozkaKalkVerzeDto[];
}

export interface EstiConZBVPolozkaRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  mnozstvi: number;
  idRozStruktura?: Guid;
  cerpano?: number;
  cerpaneMnozstvi?: number;
  elementPath?: string;
  zbvVystaveno?: IsoDateTime;
  zbvZnacka?: string;
  idZbvZmenoveListy?: Guid;
  zbvId?: Guid;
  rozpocetStructureId?: Guid;
  zbvType: EstiConZbvType;
  isFromMikrorozpocet: boolean;
  vykazVymer: EstiConVykazVymerDto;
  zpusobilost: boolean;
  reklamace: boolean;
  isRozpisSchvalenyViaZbv: boolean;
  prenosHub: EstiConPrenosHub;
  souvislostSKladnouZmenou: boolean;
  cerpaniPolozky?: EstiConCerpaniPolozkyDto[];
  skupinyRozpis?: EstiConZBVSkupinaRozpisDto[];
}

export interface EstiConZBVPolozkaRozpisSkupinyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  amountZBV: number;
  displayName: string;
  zbvId: Guid;
  skupinyRozpis?: EstiConZBVSkupinaRozpisDto[];
  jednotkovaCena: number;
}

export interface EstiConZBVPolozkyStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  type: EstiConRozStrukturaTypUzlu;
  projectId: Guid;
  generated: boolean;
  isInvestorskaUroven: boolean;
  zalozenoRealizaci: boolean;
  stavebniObjekt: EstiConRozSODto;
  konstrukcniPrvek: EstiConRozKPDto;
  element: EstiConRozElementDto;
  kvVzorce: EstiConKVHeaderDto;
  figury?: EstiConVykazVymerFiguraRozpisDto[];
  displayName?: string;
  description?: string;
  polozkyBase?: EstiConPolozkaFullDtoBase[];
  children?: EstiConZBVPolozkyStructureDto[];
  polozky?: EstiConZBVPolozkaDto[];
}

export interface EstiConZBVPolozkyZmenovyListDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  zbvType: EstiConZbvType;
  approved?: IsoDateTime;
  exposed?: IsoDateTime;
  hasCerpani: boolean;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  kontrolaDuplicitPr: boolean;
  children?: EstiConZBVPolozkyStructureDto[];
  isZaverecneZbv: boolean;
}

export interface EstiConZBVProjectDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  kvVzorce: EstiConKVHeaderDto;
  skupinyZbv: boolean;
  children?: EstiConZBVStructureDto[];
}

export interface EstiConZbvSelectionData {
  struktura?: EstiConStrukturaDataDto[];
  selectionResults?: EstiConSelectionResult[];
  zbvWithCerpaniList?: Guid[];
  zbvWithSentToHubList?: Guid[];
  zbvIsApprovedList?: Guid[];
  zbvIsValorizacniList?: Guid[];
}

export interface EstiConZBVSkupinaRozpisDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  amount: number;
  idSkupina: Guid;
  znacka?: string;
}

export interface EstiConZbvSkupinyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisSkupinyZbv: Guid;
  znacka: string;
  popisZmen?: string;
  zduvodneni?: string;
}

export interface EstiConZbvSkupinyDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  popis?: string;
  zduvodneni?: string;
  idSkupinaZbv: Guid;
}

export interface EstiConZbvSkupinyRozpisDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idCisSkupinyZbv: Guid;
  mnozstvi: number;
}

export interface EstiConZBVSmluvniDodatekDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  stav: EstiConTypSmluvniDodatek;
  schvaleniPredseda: boolean;
  zduvodneni?: string;
  projekt: EstiConZBVDodatekProjectDto;
}

export interface EstiConZBVSmluvniDodatekSimpleDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  schvaleni?: IsoDateTime;
}

export interface EstiConZbvSmluvniDodatkyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  znacka: string;
  nazev?: string;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  typStav: number;
  schvaleniPredseda: boolean;
  zduvodneni?: string;
}

export interface EstiConZBVStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  type: EstiConRozStrukturaTypUzlu;
  projectId: Guid;
  generated: boolean;
  isInvestorskaUroven: boolean;
  zalozenoRealizaci: boolean;
  stavebniObjekt: EstiConRozSODto;
  konstrukcniPrvek: EstiConRozKPDto;
  element: EstiConRozElementDto;
  kvVzorce: EstiConKVHeaderDto;
  figury?: EstiConVykazVymerFiguraRozpisDto[];
  displayName?: string;
  description?: string;
  polozkyBase?: EstiConPolozkaFullDtoBase[];
  ocSod: number;
  ncSod: number;
  cerpano: number;
  cerpanoPrenosHub: number;
  provedeno: number;
  dphCelkem: number;
  maPolozky: boolean;
  maSd: boolean;
  maOdpadniPolozky: boolean;
  lzeZmenitOdpad: boolean;
  children?: EstiConZBVStructureDto[];
  zmenoveListy?: EstiConZBVZmenovyListDto[];
  prepocitatPolozky: boolean;
}

export interface EstiConZbvZmenoveListyDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  idPredch?: Guid;
  znacka: string;
  nazev?: string;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  typZbv: number;
  zduvodneni?: string;
  popisZmen?: string;
  zaDodavatele?: string;
  zaProjektanta?: string;
  dozor?: string;
  spravce?: string;
  supervize?: string;
  cenoveProjednani?: string;
  opravnenaOsoba?: string;
  idZbvSmluvniDodatky?: Guid;
  prenosHub: number;
  datumPrenosuHub?: IsoDateTime;
  szZhotovitel?: string;
  szDozor?: string;
  szSpravce?: string;
  szZastupce?: string;
  szPravnik?: string;
  szIniciatorZmeny?: string;
  szOdpovednaOsoba?: string;
  szVedouci?: string;
  szReditel?: string;
  szNamestek?: string;
  szPredbeznySouhlas?: string;
  szDatumPredbeznySouhlas?: IsoDateTime;
  typSzTypologie?: number;
  zaverecneZbv: boolean;
  idRozValorizace?: Guid;
  zhotovitelPriloha7?: string;
  zbvSkupiny?: EstiConZbvSkupinyDataDto[];
  zbvZmenoveListyHubLog?: EstiConZbvZmenoveListyHubLogDataDto[];
}

export interface EstiConZbvZmenoveListyHubLogDataDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: number;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConZBVZmenovyListDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  rozpocetId: Guid;
  smluvniDodatekId?: Guid;
  zbvType: EstiConZbvType;
  countZp: number;
  exposed: IsoDateTime;
  approved?: IsoDateTime;
  price: number;
  cena1: number;
  cena2: number;
  cena3: number;
  viceprace: number;
  meneprace: number;
  pocetNovychPolozek: number;
  pocetPolozekSSod: number;
  zduvodneni?: string;
  popisZmen?: string;
  zaDodavatele?: string;
  zaProjektanta?: string;
  dozor?: string;
  spravce?: string;
  supervize?: string;
  cenoveProjednani?: string;
  opravnenaOsoba?: string;
  szZhotovitel?: string;
  szDozor?: string;
  szSpravce?: string;
  szZastupce?: string;
  szPravnik?: string;
  szIniciatorZmeny?: string;
  szOdpovednaOsoba?: string;
  szVedouci?: string;
  szReditel?: string;
  szNamestek?: string;
  szPredbeznySouhlas?: string;
  szDatumPredbeznySouhlas?: IsoDateTime;
  szTypTypologie: EstiConTypologieSz;
  isDodatekSchvaleny: boolean;
  skupinyZbv?: EstiConZbvSkupinyDto[];
  rozpisPolozek?: EstiConZBVPolozkaRozpisSkupinyDto[];
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  hubLog?: EstiConZBVZmenovyListHubLogDto[];
  isZaverecneZBV: boolean;
  isValorizacni: boolean;
  zhotovitelPriloha7?: string;
}

export interface EstiConZBVZmenovyListDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name: string;
  exposed: IsoDateTime;
  approved?: IsoDateTime;
  zbvOc: number;
  zbvNc: number;
  cerpano: number;
  cerpanoPrenosHub: number;
  meneprace?: number;
  viceprace?: number;
  pocetZmenenych?: number;
  pocetNovych?: number;
  zbvType: EstiConZbvType;
  zduvodneni?: string;
  popisZmen?: string;
  zaDodavatele?: string;
  zaProjektanta?: string;
  dozor?: string;
  spravce?: string;
  supervize?: string;
  cenoveProjednani?: string;
  opravnenaOsoba?: string;
  szZhotovitel?: string;
  szDozor?: string;
  szSpravce?: string;
  szZastupce?: string;
  szPravnik?: string;
  szIniciatorZmeny?: string;
  szOdpovednaOsoba?: string;
  szVedouci?: string;
  szReditel?: string;
  szNamestek?: string;
  szPredbeznySouhlas?: string;
  szDatumPredbeznySouhlas?: IsoDateTime;
  szTypTypologie: EstiConTypologieSz;
  zjistovaciProtokolyPocet: number;
  smluvniDodatek?: string;
  provedeno: number;
  children?: EstiConZBVZmenovyListDto[];
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  isZaverecneZBV: boolean;
  isValorizacni: boolean;
  zhotovitelPriloha7?: string;
}

export interface EstiConZBVZmenovyListHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConZBVZmenovyListSimpleDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
}

export interface EsticonZjistovaciProtokolDto {
  id: Guid;
  sign?: string;
  vystaveni: IsoDateTime;
  cerpano?: number;
}

export interface EstiConZjistovaciProtokolDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  parentId: Guid;
  sign: string;
  idZbv?: Guid;
  investor: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  mistoStavby?: string;
  mistoPodpisuOkres?: string;
  smlouva?: string;
  smlouvaObjednatel?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFunkce?: string;
  zaObjednatele?: string;
  zaObjednateleFunkce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePolozky?: string;
  zaZhotovitelePolozkyFunkce?: string;
  zaObjednatelePolozky?: string;
  zaObjednatelePolozkyFunkce?: string;
  adresaFirmy: EstiConAdresaPartnerDto;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  typMnozstvi?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  isZpusobile: boolean;
  zjistovaciProtokolTyp: EstiConZjistovaciProtokolTyp;
  cenaZp: number;
  obdobiValorizace?: IsoDateTime;
  isStorno: boolean;
  idDphObdobi?: Guid;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  valorizace?: number;
  pozastavka?: number;
  zadrzne?: number;
  valorizaceProcento?: number;
  pozastavkaProcento?: number;
  zadrzneProcento?: number;
  idZjistovaciProtokolSdruzeni?: Guid;
  obsahujeCerpani: boolean;
  cerpano?: number;
  cerpanoNc?: number;
  cerpanoVDphSazbe1?: number;
  cerpanoVDphSazbe2?: number;
  cerpanoVDphSazbe3?: number;
  idFaktury?: Guid;
  provedeno?: number;
  provedenoOc?: number;
  korekceCeny: boolean;
  hubLog?: EstiConZjistovaciProtokolHubLogDto[];
}

export interface EstiConZjistovaciProtokolHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConZjistovaciProtokolSdruzeniDetailDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  parentId: Guid;
  sign: string;
  idZbv?: Guid;
  investor: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  mistoStavby?: string;
  mistoPodpisuOkres?: string;
  smlouva?: string;
  smlouvaObjednatel?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFunkce?: string;
  zaObjednatele?: string;
  zaObjednateleFunkce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePolozky?: string;
  zaZhotovitelePolozkyFunkce?: string;
  zaObjednatelePolozky?: string;
  zaObjednatelePolozkyFunkce?: string;
  adresaFirmy: EstiConAdresaPartnerDto;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  typMnozstvi?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  isZpusobile: boolean;
  cesta?: string;
  rezimPrenosuDPH: EstiConRezimPrenosuDPH;
  zjistovaciProtokoly?: EstiConZjistovaciProtokolDto[];
  hubLog?: EstiConZjistovaciProtokolSdruzeniHubLogDto[];
}

export interface EstiConZjistovaciProtokolSdruzeniDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  parentId: Guid;
  sign: string;
  idZbv?: Guid;
  investor: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  mistoStavby?: string;
  mistoPodpisuOkres?: string;
  smlouva?: string;
  smlouvaObjednatel?: string;
  zaZhotovitele?: string;
  zaZhotoviteleFunkce?: string;
  zaObjednatele?: string;
  zaObjednateleFunkce?: string;
  mistoPodpisu?: string;
  zaZhotovitelePolozky?: string;
  zaZhotovitelePolozkyFunkce?: string;
  zaObjednatelePolozky?: string;
  zaObjednatelePolozkyFunkce?: string;
  adresaFirmy: EstiConAdresaPartnerDto;
  popisFunkce?: string;
  jmeno?: string;
  funkce?: string;
  typMnozstvi?: string;
  prenosHub: EstiConPrenosHub;
  prenosHubDatum?: IsoDateTime;
  isZpusobile: boolean;
}

export interface EstiConZjistovaciProtokolSdruzeniHubLogDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  action: EstiConPrenosHub;
  actionTime: IsoDateTime;
  comment?: string;
}

export interface EstiConZjistovaciProtokolyListProjectDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign?: string;
  name?: string;
  zahajeni?: IsoDateTime;
  dokonceni?: IsoDateTime;
  investor: EstiConAdresaPartnerDto;
  dodavatel: EstiConAdresaPartnerDto;
  children?: EstiConZjistovaciProtokolyStructureDto[];
}

export interface EstiConZjistovaciProtokolyStructureDto {
  state: EstiConObjectState;
  id: Guid;
  stamp?: string;
  sign: string;
  name?: string;
  type: EstiConRozStrukturaTypUzlu;
  projectId: Guid;
  generated: boolean;
  isInvestorskaUroven: boolean;
  zalozenoRealizaci: boolean;
  stavebniObjekt: EstiConRozSODto;
  konstrukcniPrvek: EstiConRozKPDto;
  element: EstiConRozElementDto;
  kvVzorce: EstiConKVHeaderDto;
  figury?: EstiConVykazVymerFiguraRozpisDto[];
  displayName?: string;
  description?: string;
  polozkyBase?: EstiConPolozkaFullDtoBase[];
  cenaOcSod?: number;
  cenaNcSod?: number;
  cenaOcZbv?: number;
  cenaNcZbv?: number;
  cenaOcZbvPrenosHub?: number;
  cenaNcZbvPrenosHub?: number;
  dphCelkem?: number;
  maOdpadniPolozky: boolean;
  lzeZmenitOdpad: boolean;
  children?: EstiConZjistovaciProtokolyStructureDto[];
  zjistovaciProtokoly?: EstiConZjistovaciProtokolDto[];
  zjistovaciProtokolySdruzeni?: EstiConZjistovaciProtokolSdruzeniDto[];
}

export interface EstiConZPDataDto {
  idReaZjistovaciProtokolySdruzeni?: Guid;
  sourceId: Guid;
  destinationId?: Guid;
  znacka?: string;
  objektovaCesta?: string;
  destinationObjectId?: Guid;
  destinationObjectPath?: string;
  dodavatelId?: Guid;
  firmaId?: Guid;
  investorId?: Guid;
  zbvId?: Guid;
  zbv?: string;
  isExistingZbv: boolean;
  existingZbvHubState: EstiConPrenosHub;
  rezimDph?: number;
  vystaveno?: IsoDateTime;
  schvaleno?: IsoDateTime;
  existingZpIsApproved: boolean;
  existingZpHasFaktura: boolean;
  existingZpIsSentToHub: boolean;
  isSelected: boolean;
  canCreate: boolean;
  isExistingItem: boolean;
  canCreateInThisLevel: boolean;
  pathsUnderZp?: string[];
  isSdruzenyZp: boolean;
  fakturaId?: Guid;
}

export interface EstiConZpPartneriPairing {
  partner: EstiConCisPartneriDataDto;
  adresa: EstiConCisAdresyDataDto;
  destinationAdresaId?: Guid;
  isOnlyDodavatel: boolean;
}

export interface EstiConZpSelectionData {
  zjistovaciProtokoly?: EstiConZPDataDto[];
}

export interface EstiConZpStructureData {
  id: Guid;
  znacka?: string;
  nazev?: string;
  hasZp: boolean;
  canCreateZp: boolean;
  isRoot: boolean;
  path?: string;
  parent: EstiConZpStructureData;
  children?: EstiConZpStructureData[];
}

export interface EstiDocumentReturnForRevorkRequestDto {
  esticonObjects?: EstiReturnForRevorkObjectDto[];
  esticonFirmId: Guid;
  esticonProjectId: Guid;
  esticonUser?: string;
  esticonFirstName?: string;
  esticonLastName?: string;
}

export interface EstiDocumentReturnForRevorkResponseDto {
  results?: EstiReturnForRevorkObjectResultDto[];
  error: EstiReturnForRevorkErrorEnum;
}

export interface EstiDocumentsCreateDto {
  projectId: Guid;
  fakturas?: FakturaCreateDto[];
  zbVs?: ZBVCreateDto[];
  zjistovaks?: ZjistovakCreateDto[];
}

export interface EstiDocumentUploadRequestDto {
  esticonObjects: EstiObjectDto[];
  esticonFirmId: Guid;
  esticonProjectId: Guid;
}

export interface EstiDocumentUploadResponseDto {
  error: EstiDocumentUploadErrorEnum;
}

export interface EstiFirmDto {
  firm: EstiConFirmRowDto;
  projects?: EstiProjectDto[];
}

export interface EstiFirmsRequestDto {
  esticonFirmIds?: Guid[];
  organizationId: Guid;
}

export interface EstiFirmsResponseDto {
  esticonFirms?: EstiFirmDto[];
}

export interface EstiObjectDto {
  category: EstiCategoryEnum;
  origId?: Guid;
  id: Guid;
  metadata?: Dictionary<string>;
  parentId?: Guid;
  relatedId?: Guid;
}

export interface EstiProjectDto {
  estiProject: EstiConProjectRowDto;
  project: ProjectDto;
}

export interface EstiProjectNoteForHubCreateDto {
  organizationId: Guid;
  esticonFirmId: Guid;
  esticonProjectId: Guid;
  znacka: string;
  zahajeni: IsoDateTime;
  nazevAkce: string;
  projectNoteItems: EstiProjectNoteForHubItemSetDto[];
}

export interface EstiProjectNoteForHubDto {
  id: Guid;
  organizationId: Guid;
  esticonFirmId: Guid;
  esticonProjectId: Guid;
  znacka: string;
  zahajeni: IsoDateTime;
  nazevAkce: string;
  projectNoteItems: EstiProjectNoteForHubItemDto[];
  createdDate: IsoDateTime;
  createdBy: AppUserProfileDto;
  modifiedDate?: IsoDateTime;
  modifiedBy: AppUserProfileDto;
}

export interface EstiProjectNoteForHubItemDto {
  id: Guid;
  note: string;
  createdDate: IsoDateTime;
  createdBy: AppUserProfileDto;
  modifiedDate?: IsoDateTime;
  modifiedBy: AppUserProfileDto;
  orderId: number;
}

export interface EstiProjectNoteForHubItemSetDto {
  id: Guid;
  note: string;
}

export interface EstiProjectNoteForHubPatchDto {
  id: Guid;
  esticonFirmId?: Guid;
  esticonProjectId?: Guid;
  znacka?: string;
  zahajeni?: IsoDateTime;
  nazevAkce?: string;
  projectNoteItems?: EstiProjectNoteForHubItemSetDto[];
}

export interface EstiProjectSettingsBaseDto {
  esticonDirectories?: Dictionary<Guid>;
  esticonDirectoryMasks?: Dictionary<string[]>;
  esticonFileMasks?: Dictionary<string>;
}

export interface EstiProjectSettingsDto {
  esticonDirectories: Dictionary<DirectoryListDto>;
  esticonDirectoryMasks: Dictionary<string[]>;
  esticonFileMasks: Dictionary<string>;
}

export interface EstiReturnForRevorkObjectDto {
  approvalDateUnset: boolean;
  category: EstiCategoryEnum;
  id: Guid;
}

export interface EstiReturnForRevorkObjectResultDto {
  approvalDateUnset: boolean;
  category: EstiCategoryEnum;
  id: Guid;
  error: EstiDokumentReturnForRevorkErrorEnum;
  action: EstiDokumentReturnForRevorkActionEnum;
}

export interface ExcludedDayDto {
  id: Guid;
  day: number;
  month: number;
  year: number;
  name: string;
}

export interface ExcludedDaySetDto {
  id?: Guid;
  day: number;
  month: number;
  year?: number;
  name: string;
}

export interface ExcludedDayTemplateDto {
  id: Guid;
  day: number;
  month: number;
  year?: number;
  name: string;
}

export interface ExtendedPermissionDto {
  permissionType: ExtendedPermissionEnum;
  category: ExtendedPermissionCategoryEnum;
  permission: boolean;
}

export interface ExtendedPermissionPatchDto {
  permissionType: ExtendedPermissionEnum;
  permission: boolean;
}

export interface ExtendedPermissionsDto {
  settings: ExtendedPermissionDto[];
}

export interface ExtendedPermissionsPatchDto {
  settings: ExtendedPermissionPatchDto[];
}

export interface ExtendedPermissionTemplateCreateDto {
  id?: Guid;
  organizationId: Guid;
  name?: string;
  description?: string;
  settings: ExtendedPermissionPatchDto[];
}

export interface ExtendedPermissionTemplateDto {
  id: Guid;
  name?: string;
  description?: string;
  extendedPermissions: ExtendedPermissionsDto;
}

export interface ExtendedPermissionTemplatePatchDto {
  id: Guid;
  organizationId: Guid;
  name?: string;
  description?: string;
  settings?: ExtendedPermissionPatchDto[];
}

export interface ExternalApplicationSettingDto {
  id: Guid;
  name: string;
  menuOrder: number;
  type: ProjectSettingExternalAppSettingsEnum;
  data: { url: string } | { documentId: Guid } | { textPageId: Guid };
}

export interface ExternalApplicationSettingSetDto {
  id: Guid;
  name: string;
  menuOrder: number;
  type: ProjectSettingExternalAppSettingsEnum;
  data: Dictionary<JToken>;
  textPage: ExternalApplicationsProjectSettingsTextPagePatchDto;
}

export interface ExternalApplicationsProjectSettingsDto {
  id?: Guid;
  settings: ExternalApplicationSettingDto[];
}

export interface ExternalApplicationsProjectSettingsTextPageDto {
  id: Guid;
  title: string;
  content?: string;
}

export interface ExternalApplicationsProjectSettingsTextPagePatchDto {
  id?: Guid;
  title: string;
  content?: string;
}

export interface FakturaCreateDto {
  pdfUploadId: Guid;
  xmlUploadId?: Guid;
  xmlError?: string;
  esticonId: Guid;
  schvaleni?: IsoDateTime;
  obdobi?: IsoDateTime;
  vystaveni: IsoDateTime;
  sign: string;
  poradoveCislo: number;
  isSdruzene: boolean;
  spolFakId?: Guid;
}

export interface FavoriteDocumentsDto {
  documentsIds: Guid[];
  documentLinksIds?: Guid[];
}

export interface FavoriteDocumentsResultsDto {
  documentsIds: Guid[];
  documentErrors: DocumentEvaluationErrorDto[];
  documentLinkErrors?: DocumentLinkEvaluationErrorDto[];
}

export interface FavoriteProjectListDto {
  id: Guid;
  name?: string;
  description?: string;
  language: LanguageEnum;
  status: ProjectStatusEnum;
  createdDate: IsoDateTime;
  createdById?: Guid;
  organization: OrganizationDto;
  timeZone: ProjectTimezoneDto;
  storageArea?: string;
  labels?: Guid[];
  isFavorite: boolean;
  lastAccessDate: IsoDateTime;
  frequency: number;
  setPossition: number;
  esticonForbidden: boolean;
  esticonProjectData: EsticonProjectRowDto;
}

export interface FavoriteProjectsDto {
  projectsIds: Guid[];
}

export interface FavoriteProjectsResultsDto {
  projectsIds: Guid[];
  notFoundProjectsIds: Guid[];
}

export interface FileDto {
  id: Guid;
  size: number;
  contentType?: string;
  thumbnail?: string;
  blobToken?: string;
  isMetadata: boolean;
  hasPdfDerivate?: boolean;
}

export interface FinancialOverviewByUnitDataDto {
  esticonResponse: EstiConFinancialOverviewByUnitResponseDto;
  extendedDatas: Dictionary<FinancialOverviewByUnitExtendedDataDto>;
}

export interface FinancialOverviewByUnitExtendedDataDto {
  projectId?: Guid;
  erpNaklady?: number;
}

export interface FinancialOverviewByUnitRequestDto {
  esticonFirmId: Guid;
  organizationId: Guid;
}

export interface ForgeMonthlyReportRequestDto {
  organizationId: Guid;
  monthFrom: number;
  yearFrom: number;
  monthTo: number;
  yearTo: number;
  language: LanguageEnum;
}

export interface ForgeSearchLocationSettingsDto {
  category?: string;
  property: string;
}

export interface ForgeUploadOrganizationReportDto {
  organizationUsage: Dictionary<number>;
}

export interface GeneralSavedRecordDeleteRequestDto {
  id1: Guid;
  id2: Guid;
  iden: string;
  number: number;
}

export interface GeneralSavedRecordDto {
  id1: Guid;
  id2: Guid;
  iden: string;
  number: number;
  data: Dictionary<JToken>;
  organizationAuth?: Guid;
}

export interface GeneralSavedRecordRequestDto {
  id1: Guid;
  id2: Guid;
  iden: string;
  number: number;
  organizationAuth?: Guid;
}

export interface GetDocToDocLinksListsRequestDto {
  documentIds?: Guid[];
}

export interface GetDocToDocLinksListsResponseDto {
  linksLists?: Dictionary<DocToDocLinksListDto>;
}

export interface GroupCreateDto {
  name: string;
  description?: string;
  userIds?: Guid[];
}

export interface GroupDto {
  id: Guid;
  name?: string;
  description?: string;
  isAdminGroup: boolean;
}

export interface GroupListDto {
  id: Guid;
  name?: string;
  description?: string;
  isAdminGroup: boolean;
  userIds?: Guid[];
}

export interface GroupPatchDto {
  name?: string;
  description?: string;
  userIds?: Guid[];
}

export interface GroupUserListAddDto {
  groupId: Guid;
  users: Guid[];
}

export interface CheckEstiPathsDto {
  categories?: EstiCategoryEnum[];
}

export interface JToken {}

export interface LabelCreateDto {
  name: string;
  description?: string;
  color: string;
}

export interface LabelDto {
  id: Guid;
  name?: string;
  description?: string;
  color?: string;
  isDeprecated: boolean;
}

export interface LabelListDto {
  id: Guid;
  name?: string;
  description?: string;
  color?: string;
  isDeprecated: boolean;
}

export interface LabelPatchDto {
  name?: string;
  description?: string;
  color?: string;
}

export interface MessageListDto {
  id: Guid;
  projectId: Guid;
  type: MsgTypeEnum;
  categoryType: MsgCategoryTypeEnum;
  category: MsgCategoryEnum;
  status: MsgStatusEnum;
  createdDate: IsoDateTime;
  finishDate: IsoDateTime;
}

export interface ModelDocToDocLinkCreateDto {
  oppositeDocumentIds: Guid[];
  modelDocumentId: Guid;
  modelDocumentRevisionId: Guid;
  modelElements: ModelElementCreateDto[];
  ignoreDupliciteModels: boolean;
}

export interface ModelDocToDocLinkCreateResultsDto {
  duplicateElementIds: Dictionary<string[]>;
}

export interface ModelDocumentsAddToModelDto {
  documentsToAdd: Guid[];
}

export interface ModelDocumentsRemoveFromModelDto {
  documentsToRemove: Guid[];
}

export interface ModelElementCreateDto {
  elementId: string;
  name: string;
  attributeName?: string;
}

export interface ModelValidationError {
  field?: string;
  message?: string;
}

export interface MsgCenterAssignmentListRequestDto {
  assignmentNameFilter?: string;
  assignmentMsgStateEnumFilter?: AssignmentStateEnum[];
  assignmentMsgTypeEnumFilter?: AssignmentTypeEnum[];
  assignmentMsgSourceEnumFilter?: AssignmentSourceEnum[];
  assignmenAppUserFilter: AssignmentUserSearch;
  projectFilter?: Guid[];
  createTimeFromFilter?: IsoDateTime;
  createTimeToFilter?: IsoDateTime;
  deadlineFromFilter?: IsoDateTime;
  deadlineToFilter?: IsoDateTime;
  resolvedTimeFromFilter?: IsoDateTime;
  resolvedTimeToFilter?: IsoDateTime;
  order?: AssignmentSearchSort[];
  size: number;
  from: number;
}

export interface MsgCenterAssignmentResponseDto {
  size: number;
  from: number;
  took: number;
  total: number;
  assignments: AssignmentMessageDto[];
}

export interface MsgCenterDefaultSettingDto {
  category: MsgCategoryEnum;
  categoryType: MsgCategoryTypeEnum;
  sendEmail: boolean;
  sendReport: boolean;
  ignore: boolean;
}

export interface MsgCenterDefaultSettingsDto {
  settings: MsgCenterDefaultSettingDto[];
}

export interface MsgCenterIntervalListRequestDto {
  createTimeFromFilter: IsoDateTime;
  createTimeToFilter: IsoDateTime;
  requestedMessage: Guid;
  projectId: Guid;
  size: number;
  order?: MsgSearchSort[];
}

export interface MsgCenterIntervalResponseDto {
  defaultPageSize: number;
  pageCount: number;
  took: number;
  total: number;
  messages: MessageListDto[][];
  currentPage: number;
}

export interface MsgCenterListRequestDto {
  statusFilter?: MsgStatusEnum[];
  msgCategoryTypeEnumFilter?: MsgCategoryTypeEnum[];
  msgCategoryEnumFilter?: MsgCategoryEnum[];
  projectFilter?: Guid[];
  createTimeFromFilter?: IsoDateTime;
  createTimeToFilter?: IsoDateTime;
  finishTimeFromFilter?: IsoDateTime;
  finishTimeToFilter?: IsoDateTime;
  order?: MsgSearchSort[];
  size: number;
  from: number;
}

export interface MsgCenterMessageDto {
  id: Guid;
  projectId: Guid;
  type: MsgTypeEnum;
  categoryType: MsgCategoryTypeEnum;
  category: MsgCategoryEnum;
  status: MsgStatusEnum;
  createdDate: IsoDateTime;
  finishDate: IsoDateTime;
  data: unknown;
}

export interface MsgCenterProjectSettingsDto {
  settings: MsgCenterSettingDto[];
}

export interface MsgCenterProjectSettingsPatchDto {
  settings: Dictionary<MsgCenterSettingPatchDto>;
  projectId?: Guid;
}

export interface MsgCenterResponseDto {
  size: number;
  from: number;
  took: number;
  total: number;
  messages: MessageListDto[];
}

export interface MsgCenterSetStatusDto {
  status: MsgStatusEnum;
  messages: Guid[];
  strategy: CommentProcedureSetStateStrategyEnum;
}

export interface MsgCenterSettingDto {
  category: MsgCategoryEnum;
  categoryType: MsgCategoryTypeEnum;
  sendEmail?: boolean;
  sendReport?: boolean;
  ignore?: boolean;
  sendEmailDefault: boolean;
  sendReportDefault: boolean;
  ignoreDefault: boolean;
}

export interface MsgCenterSettingPatchDto {
  sendEmail: boolean;
  sendReport: boolean;
  ignore: boolean;
}

export interface MsgCenterSummaryDto {
  messages: Dictionary<number>;
  assigments: Dictionary<AssigmentSummaryDto>;
}

export interface MsgSearchSort {
  direction: SortOrder;
  property: MsgOrderTypeEnum;
}

export interface MultiUploadDirectoryDto {
  directory: DirectoryDto;
  path?: string;
  isCreated: boolean;
}

export interface MultiUploadFileDto {
  path?: string;
  name: string;
}

export interface MultiUploadFileResponseDto {
  requestedFile: MultiUploadFileDto;
  document: DocumentDto;
}

export interface MultiUploadCheckRequestDto {
  targetDirectoryId: Guid;
  files?: MultiUploadFileDto[];
  directories?: string[];
}

export interface MultiUploadCheckResponseDto {
  targetDirectory: DirectoryDto;
  duplicateDirectories?: Dictionary<DirectoryDto>;
  notWritableDirectories?: string[];
  invalidNameDirectories?: string[];
  invalidNameDocuments?: MultiUploadFileDto[];
  duplicateDocuments?: MultiUploadFileResponseDto[];
  notWritableDocuments?: MultiUploadFileDto[];
}

export interface MultiUploadPrepareDirectoriesRequestDto {
  targetDirectoryId: Guid;
  directories: string[];
  permissionInheritance: boolean;
}

export interface MultiUploadPrepareDirectoriesResponseDto {
  directories?: Dictionary<MultiUploadDirectoryDto>;
}

export interface OAuthToken {
  accessToken?: string;
  expires: IsoDateTime;
}

export interface OppositeDocumentDto {
  document: DirectoryContentDto;
  currentAccessLevel: AccessLevelEnum;
}

export interface OrganizationAdminReportDto {
  id: Guid;
  name: string;
  description?: string;
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId: string;
  defaultStorageArea?: string[];
  projectAdminCanInviteNewUser: boolean;
  esticonFirmIds?: Guid[];
  organizationUsers: OrgUserDto[];
  projects: ProjectBaseListDto[];
  isOrganizationAdmin: boolean;
}

export interface OrganizationCreateDto {
  name: string;
  description?: string;
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId: string;
  defaultStorageArea?: string[];
  projectAdminCanInviteNewUser: boolean;
  users?: OrgUserRequestDto[];
}

export interface OrganizationDto {
  id: Guid;
  name: string;
  description?: string;
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId: string;
  defaultStorageArea?: string[];
  projectAdminCanInviteNewUser: boolean;
  esticonFirmIds?: Guid[];
}

export interface OrganizationPatchDto {
  id: Guid;
  name?: string;
  description?: string;
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId?: string;
  defaultStorageArea?: string[];
  projectAdminCanInviteNewUser?: boolean;
}

export interface OrganizationPOHReportSettingDto {
  defaultReportDate: IsoDateTime;
  completeRealizationColor: string;
  invalidPricesColor?: string;
  unitSettings?: OrganizationPOHReportUnitSettingDto[];
}

export interface OrganizationPOHReportUnitSettingDto {
  color: string;
  sign: string;
  id: Guid;
}

export interface OrganizationsAdminReportDto {
  organizations: OrganizationAdminReportDto[];
  appUsers?: AppUserDto[];
  isAppAdmin: boolean;
}

export interface OrganizationUserInfoDto {
  organization: OrganizationDto;
  isAdmin: boolean;
  namesFromOrganization: boolean;
  firstname: string;
  lastname: string;
  extendedPermissions: OrgExtendedPermissionDto[];
}

export interface OrganizationUserProjectReportDto {
  project: ProjectBaseListDto;
  user: ProjectUserProfileListDto;
  isLastAdmin: boolean;
}

export interface OrganizationUserProjectsReportDto {
  projects: OrganizationUserProjectReportDto[];
  orgUser: OrgUserDto;
}

export interface OrgExtendedPermissionDto {
  permissionType: OrgExtendedPermissionEnum;
  category: OrgExtendedPermissionCategoryEnum;
  permission: OrgExtendedPermissionValueEnum;
  property: string;
}

export interface OrgExtendedPermissionPatchDto {
  permissionType: OrgExtendedPermissionEnum;
  permission: OrgExtendedPermissionValueEnum;
  property?: string;
}

export interface OrgExtendedPermissionsDto {
  settings: OrgExtendedPermissionDto[];
}

export interface OrgExtendedPermissionsPatchDto {
  settings: OrgExtendedPermissionPatchDto[];
}

export interface OrgLabelCreateDto {
  name: string;
  description?: string;
  color: string;
}

export interface OrgLabelDto {
  id: Guid;
  name?: string;
  description?: string;
  color?: string;
}

export interface OrgLabelListDto {
  id: Guid;
  name?: string;
  description?: string;
  color?: string;
  organizationId: Guid;
}

export interface OrgLabelPatchDto {
  name?: string;
  description?: string;
  color?: string;
}

export interface OrgUserDto {
  id: Guid;
  appUserProfileId: Guid;
  organizationId: Guid;
  locked: boolean;
  isAdmin: boolean;
  namesFromOrganization: boolean;
  firstname: string;
  lastname: string;
  appUserProfile: AppUserDto;
  inProject?: boolean;
}

export interface OrgUserRequestDto {
  language: LanguageEnum;
  mail: string;
  isAdmin: boolean;
  namesFromOrganization: boolean;
  firstname?: string;
  lastname?: string;
}

export interface OrgUsersRequestDto {
  users: OrgUserRequestDto[];
  organizationId: Guid;
}

export interface PrepareAddRevisionRequestDto {
  documentId: Guid;
  description?: string;
  state: WorkflowStateEnum;
}

export interface PrimaryFileDto {
  name?: string;
  id: Guid;
  size: number;
  contentType?: string;
  thumbnail?: string;
  blobToken?: string;
  isMetadata: boolean;
  hasPdfDerivate?: boolean;
}

export interface ProcessSharedDownloadDto {
  shareId: Guid;
  pin?: string;
  zipName?: string;
  excludeInvalid: boolean;
}

export interface ProjectBaseListDto {
  id: Guid;
  name?: string;
  description?: string;
  language: LanguageEnum;
  status: ProjectStatusEnum;
  createdDate: IsoDateTime;
  createdById?: Guid;
  organization: OrganizationDto;
  timeZone: ProjectTimezoneDto;
  storageArea?: string;
  labels?: Guid[];
}

export interface ProjectCreateDto {
  name: string;
  description?: string;
  language: LanguageEnum;
  orgLabels?: Guid[];
  organizationId: Guid;
  server?: string;
  port?: number;
  dbName?: string;
  usr?: string;
  pwd?: string;
  timeZoneId?: string;
  storageArea?: string;
  users?: ProjectUserRequestDto[];
}

export interface ProjectCreateFromTempDto {
  name: string;
  description?: string;
  organizationId: Guid;
  projectTemplateId: Guid;
  server?: string;
  port?: number;
  dbName?: string;
  usr?: string;
  pwd?: string;
  orgLabels?: Guid[];
  expandUsers?: ProjectUserRequestDto[];
}

export interface ProjectDataDto {
  frequency: number;
  users: number;
  todayUsers: number;
  data?: ProjectDataRowDto[];
  documentsStatistics: ProjectDocumentStatisticsDto;
  esticonProjectData: EsticonProjectRowDto;
  erpCategoryData: ERPCategoryDataDto;
  esticonForbidden: boolean;
}

export interface ProjectDataRowDto {
  name?: string;
  dataType: ProjectDataTypeEnum;
  lastUpdateDate: IsoDateTime;
  data?: unknown;
}

export interface ProjectDocumentStatisticsDto {
  countAll: number;
  createdByMeCount: number;
  discardedCount: number;
}

export interface ProjectDto {
  id: Guid;
  name?: string;
  description?: string;
  language: LanguageEnum;
  status: ProjectStatusEnum;
  createdDate: IsoDateTime;
  createdById?: Guid;
  organization: OrganizationDto;
  timeZone: ProjectTimezoneDto;
  storageArea?: string;
  labels?: Guid[];
  server?: string;
  port?: number;
  dbName?: string;
  isFavorite: boolean;
  esticonFirmId?: Guid;
  esticonProjectId?: Guid;
}

export interface ProjectForExportRequestDto {
  projectId: Guid;
  name: string;
  description?: string;
  organizationName: string;
  labels?: string[];
  createdDate: string;
}

export interface ProjectHubDto {
  id: Guid;
  name?: string;
  description?: string;
  language: LanguageEnum;
  status: ProjectStatusEnum;
  createdDate: IsoDateTime;
  createdById?: Guid;
  organization: OrganizationDto;
  timeZone: ProjectTimezoneDto;
  storageArea?: string;
  labels?: Guid[];
  server?: string;
  port?: number;
  dbName?: string;
  isFavorite: boolean;
  esticonFirmId?: Guid;
  esticonProjectId?: Guid;
}

export interface ProjectListDto {
  id: Guid;
  name?: string;
  description?: string;
  language: LanguageEnum;
  status: ProjectStatusEnum;
  createdDate: IsoDateTime;
  createdById?: Guid;
  organization: OrganizationDto;
  timeZone: ProjectTimezoneDto;
  storageArea?: string;
  labels?: Guid[];
  isFavorite: boolean;
  lastAccessDate: IsoDateTime;
  frequency: number;
  setPossition: number;
}

export interface ProjectListForExportRequestDto {
  resultFileFormat: ResultFileFormatEnum;
  nameColDesc: string;
  descriptionColDesc: string;
  organizationNameColDesc: string;
  labelsColDesc: string;
  createdDateColDesc: string;
  projectDatas: ProjectForExportRequestDto[];
  language: LanguageEnum;
}

export interface ProjectListResultDto {
  projects?: ProjectListDto[];
  creatingProjects: number;
}

export interface ProjectOrderDto {
  id: Guid;
  order: number;
}

export interface ProjectPatchDto {
  name?: string;
  description?: string;
  timeZoneId?: string;
  language?: LanguageEnum;
  orgLabels?: Guid[];
}

export interface ProjectsInRealisationReportColumnDto {
  sourceProperty: ProjectsInRealizationPropertyType;
  isHidden: boolean;
  isFolded: boolean;
  width?: number;
}

export interface ProjectsInRealisationReportExportRequestDto {
  utcFrom: IsoDateTime;
  processedYear: number;
  exportFormat: ProjectsInRealizationExportFormat;
  columnConfigurations?: ProjectsInRealisationReportColumnDto[];
  filteredRecords?: Guid[];
  reportTypeName?: string;
}

export interface ProjectsOrderDto {
  projectsOrder: ProjectOrderDto[];
}

export interface ProjectStorageAreasDto {
  fileStoragetype?: string;
  storageAreas?: string[];
}

export interface ProjectTemplateCategoryNodeDto {
  id: Guid;
  name: string;
  parentId?: Guid;
  templateCategoryTreeId: Guid;
  description?: string;
}

export interface ProjectTemplateCategoryTreeDto {
  id: Guid;
  name: string;
  description?: string;
}

export interface ProjectTemplateCreateDto {
  name: string;
  description?: string;
  language: LanguageEnum;
  organizationId: Guid;
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
  timeZoneId?: string;
  storageArea?: string;
  calendar: CalendarTemplateDto;
  projectTemplateUsers: ProjectTemplateUserCreateDto[];
  projectTemplateRoles: ProjectTemplateRoleDto[];
  projectTemplateGroups: ProjectTemplateGroupDto[];
  projectTemplateLabels: ProjectTemplateLabelDto[];
  projectTemplateCategoryTrees: ProjectTemplateCategoryTreeDto[];
  projectTemplateCategoryNodes: ProjectTemplateCategoryNodeDto[];
  projectTemplateDirectories: ProjectTemplateDirectoryDto[];
  userExtendedPermissionTempates: ExtendedPermissionTemplateCreateDto[];
}

export interface ProjectTemplateDirectoryDto {
  id: Guid;
  name: string;
  description?: string;
  parentId?: Guid;
  permissionInheritance: boolean;
  projectTemplateGroupPermMap: Dictionary<AccessLevelEnum>;
  projectTemplateDirectoryCategoryNodeIds: Guid[];
}

export interface ProjectTemplateDto {
  name: string;
  description?: string;
  language: LanguageEnum;
  organizationId: Guid;
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
  id: Guid;
  createdDate: IsoDateTime;
  createdById?: Guid;
  version: number;
  timeZoneId?: string;
  storageArea?: string;
  projectTemplateUsers?: ProjectTemplateUserDto[];
  projectTemplateGroups?: ProjectTemplateGroupDto[];
  calendar: CalendarTemplateDto;
  projectTemplateRoles: ProjectTemplateRoleDto[];
  projectTemplateLabels: ProjectTemplateLabelDto[];
  projectTemplateCategoryTrees: ProjectTemplateCategoryTreeDto[];
  projectTemplateCategoryNodes: ProjectTemplateCategoryNodeDto[];
  projectTemplateDirectories: ProjectTemplateDirectoryDto[];
  orgExtendedPermissionTempates: ExtendedPermissionTemplateDto[];
  userExtendedPermissionTempates: ExtendedPermissionTemplateDto[];
}

export interface ProjectTemplateGroupDto {
  id: Guid;
  name: string;
  description?: string;
  templateUserIds: Guid[];
  isAdminGroup: boolean;
}

export interface ProjectTemplateLabelDto {
  id: Guid;
  name: string;
  description?: string;
  color: string;
}

export interface ProjectTemplateListDto {
  name: string;
  description?: string;
  language: LanguageEnum;
  organizationId: Guid;
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
  id: Guid;
  createdDate: IsoDateTime;
  createdById?: Guid;
  version: number;
  timeZoneId?: string;
  storageArea?: string;
  projectTemplateUsers?: ProjectTemplateUserDto[];
  projectTemplateGroups?: ProjectTemplateGroupDto[];
}

export interface ProjectTemplatePatchDto {
  name: string;
  description?: string;
  language: LanguageEnum;
  organizationId: Guid;
  primarySubmitterCanEditProcessorTeams: boolean;
  primaryProcessorCanEditSubmitterTeams: boolean;
  useWorkDays: boolean;
  id: Guid;
  timeZoneId?: string;
  storageArea?: string;
  calendar: CalendarTemplateDto;
  projectTemplateUsers: ProjectTemplateUserPatchDto[];
  projectTemplateRoles: ProjectTemplateRoleDto[];
  projectTemplateGroups: ProjectTemplateGroupDto[];
  projectTemplateLabels: ProjectTemplateLabelDto[];
  projectTemplateCategoryTrees: ProjectTemplateCategoryTreeDto[];
  projectTemplateCategoryNodes: ProjectTemplateCategoryNodeDto[];
  projectTemplateDirectories: ProjectTemplateDirectoryDto[];
  userExtendedPermissionTempates: ExtendedPermissionTemplatePatchDto[];
}

export interface ProjectTemplateRoleDto {
  id: Guid;
  name: string;
  description?: string;
  templateUserId?: Guid;
}

export interface ProjectTemplateUserCreateDto {
  id: Guid;
  appUserOrganizationId: Guid;
  extendedPermissionId?: Guid;
}

export interface ProjectTemplateUserDto {
  id: Guid;
  appUserOrganizationId: Guid;
  extendedPermissionId?: Guid;
  appUserOrganization: OrgUserDto;
}

export interface ProjectTemplateUserPatchDto {
  id: Guid;
  appUserOrganizationId: Guid;
  extendedPermissionId?: Guid;
}

export interface ProjectTimezoneDto {
  id?: string;
  name?: string;
  timeOffset: TimeSpan;
}

export interface ProjectTimezoneListDto {
  timeZones?: ProjectTimezoneDto[];
  defaultTimeZoneId?: string;
}

export interface ProjectUserActivationResponseDto {
  activated: ProjectUserProfileDto;
  freeLicences: number;
  licences: number;
}

export interface ProjectUserInvitationDto {
  id: Guid;
  username?: string;
  projectName?: string;
  projectDescription?: string;
  invitationAcceptedDate?: IsoDateTime;
  firstname?: string;
  lastname?: string;
}

export interface ProjectUserProfileDto {
  id: Guid;
  username?: string;
  firstname?: string;
  lastname?: string;
  roles?: Guid[];
  isAdmin: boolean;
  status: ProjectUserProfileStatusEnum;
  groups?: Guid[];
  extendedPermissions?: ExtendedPermissionEnum[];
  isConfirmed?: boolean;
  language: LanguageEnum;
}

export interface ProjectUserProfileListDto {
  id: Guid;
  username?: string;
  firstname?: string;
  lastname?: string;
  isAdmin?: boolean;
  isConfirmed?: boolean;
  status: ProjectUserProfileStatusEnum;
}

export interface ProjectUserRequestDto {
  mail: string;
  addAsAdmin: boolean;
  groups?: Guid[];
  language: LanguageEnum;
}

export interface ProjectUserSetAdminDto {
  isAdmin: boolean;
}

export interface ProjectUsersLicenceInfoDto {
  freeLicences: number;
  licences: number;
  validTo: IsoDateTime;
}

export interface ProjectUsersRequestDto {
  users?: ProjectUserRequestDto[];
  organizationId: Guid;
  projectId: Guid;
}

export interface RefreshWopiResponseDto {
  token: string;
}

export interface RefuseBackgroundDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  backgroundDocumentId: Guid;
  reason?: string;
}

export interface ReinviteRequestDto {
  appUserId?: Guid;
  projectUserId?: Guid;
  projectId?: Guid;
  organizationtId?: Guid;
}

export interface RenamedObjectMoveResultDto {
  originName?: string;
  newName?: string;
}

export interface RepaireDerivateDto {
  blobId: Guid;
  derivateType: BlobDerivateTypeEnum;
}

export interface RepaireDerivatesDto {
  blobId: Guid;
}

export interface RestoreDirectoryPathItemDto {
  newPath?: string;
  oldPath?: string;
}

export interface RestoreFileNameItemDto {
  filePath?: string;
  newName?: string;
  oldName?: string;
}

export interface RestoreFilePathItemDto {
  fileName?: string;
  newPath?: string;
  oldPath?: string;
}

export interface RevisionCreateDto {
  description: string;
  uploadId: Guid;
  state: WorkflowStateEnum;
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  metaData: RevisionMetaDataCreateDto;
  annotations?: DocumentAnnotationSaveDto[];
}

export interface RevisionDto {
  id: Guid;
  number: number;
  description?: string;
  createdDate: IsoDateTime;
  discardedBy: ProjectUserProfileListDto;
  discardedDate?: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  revisionMetaData?: RevisionMetaData;
  revisionState: WorkflowStateEnum;
  primaryFile: PrimaryFileDto;
  secondaryFiles?: SecondaryFileDto[];
  annotations?: DocumentAnnotationDto[];
}

export interface RevisionLockRequestDto {
  lockId?: Guid;
  revisionId: Guid;
  purpose: RevisionLockPurposeEnum;
}

export interface RevisionLockResponseDto {
  lockId: Guid;
  revisionId: Guid;
  reNew: boolean;
  purpose: RevisionLockPurposeEnum;
}

export interface RevisionMetaDataCreateDto {
  source: RevisionMetaDataSourceEnum;
  metaData?: Dictionary<string>;
}

export interface RoleCreateDto {
  name: string;
  description?: string;
  userId?: Guid;
}

export interface RoleDto {
  id: Guid;
  name?: string;
  description?: string;
  user: ProjectUserProfileListDto;
}

export interface RolePatchDto {
  name?: string;
  description?: string;
  userId?: Guid;
  removeUser: boolean;
  strategy: UserRemoveStrategyEnum;
}

export interface SearchCategoryNodeDto {
  id: Guid;
  name?: string;
}

export interface SearchCategoryTreeDto {
  id: Guid;
  name?: string;
  node: SearchCategoryNodeDto;
}

export interface SearchDirectoryDto {
  id: Guid;
  path?: string;
}

export interface SearchHitDto {
  id: Guid;
  name?: string;
  description?: string;
  createdDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  modifiedDate: IsoDateTime;
  reservedDate?: IsoDateTime;
  releaseReservedDate?: IsoDateTime;
  reservedBy: ProjectUserProfileListDto;
  directory: SearchDirectoryDto;
  revision: SearchRevisionDto;
  labels?: SearchLabelDto[];
  categoryTrees?: SearchCategoryTreeDto[];
  highlights: SearchHitHighlighstDto;
  ownedBy: SearchOwnerDto;
  state: WorkflowStateEnum;
  isModel: boolean;
}

export interface SearchHitHighlighstDto {
  name?: string;
  documentDescription?: string[];
  revisionDescription?: string[];
  content?: string[];
  metadataValue?: string[];
  metadataKey?: string[];
}

export interface SearchLabelDto {
  id: Guid;
  name?: string;
}

export interface SearchMetadataRevisionDto {
  id: Guid;
  source?: string;
  key?: string;
  value?: string;
}

export interface SearchOwnerDto {
  name?: string;
  id: Guid;
}

export interface SearchPrimaryFileDto {
  ext?: string;
  contentType?: string;
  size: number;
  language?: string;
  thumbnailUrl?: string;
  blobToken?: string;
}

export interface SearchRequestDto {
  query?: string;
  sorting?: SearchSort[];
  size: number;
  from: number;
  highlightPreTag?: string;
  highlightPostTag?: string;
  highlightDescriptionSize: number;
  highlightContentSize: number;
  highlightMetadataSize: number;
  highlightDescriptionFragmentsCount: number;
  highlightContentFragmentsCount: number;
  highlightMetadataFragmentsCount: number;
  currentRevisionsOnly: boolean;
  fulltextInDocumentName: boolean;
  fulltextInDocumentDescription: boolean;
  fulltextInRevisionDescription: boolean;
  fulltextInContent: boolean;
  fulltextInLabel: boolean;
  fulltextInMetadataValue: boolean;
  fulltextInMetadataKey: boolean;
}

export interface SearchResponseDto {
  size: number;
  from: number;
  took: number;
  total: number;
  hits?: SearchHitDto[];
}

export interface SearchRevisionDto {
  id: Guid;
  number: number;
  description?: string;
  createdDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  primaryFile: SearchPrimaryFileDto;
  hasSecondaryFiles: boolean;
  isCurrent: boolean;
  metadata?: SearchMetadataRevisionDto[];
  isSigned: boolean;
  createdSignDate: IsoDateTime;
  createdSignBy: ProjectUserProfileListDto;
  hasAnnotation: boolean;
}

export interface SearchSort {
  direction: SortOrder;
  property?: string;
}

export interface SecondaryDocumentCreateDto {
  name: string;
  uploadId: Guid;
}

export interface SecondaryDocumentsAddDto {
  secondaryAttachedDocuments?: SecondaryDocumentCreateDto[];
  secondarySignedDocument: SecondaryDocumentCreateDto;
  workflowNodeId?: Guid;
}

export interface SecondaryFileDto {
  id: Guid;
  size: number;
  contentType?: string;
  thumbnail?: string;
  blobToken?: string;
  isMetadata: boolean;
  hasPdfDerivate?: boolean;
  name?: string;
  blobType: SecondaryBlobTypeEnum;
  createdDate: IsoDateTime;
  createdById: Guid;
}

export interface SendDemandMsgDto {
  message?: string;
  recipientId: Guid;
  demandMsgType: DemandMsgTypeEnum;
  objectId: Guid;
}

export interface ServiceError {
  apiVersion?: string;
  requestId?: string;
  statusCode: number;
  message?: string;
  referenceErrorCode?: ServiceErrorEnum;
  details?: string;
  validationErrors?: ModelValidationError[];
  errorData?: unknown;
}

export interface SharedDownloadDocumentDto {
  documentPath?: string;
  documentName?: string;
  revisionNumber: number;
}

export interface SharedDownloadDto {
  expiresDate: IsoDateTime;
  createdDate: IsoDateTime;
  userName?: string;
  firstName?: string;
  lastName?: string;
  requirePin: boolean;
  sharedType: SharedSharedOutputTypeEnum;
  data?: SharedDownloadDocumentDto[];
}

export interface ShareDownloadDto {
  emails: string[];
  shareMessage?: string;
  sendMails: boolean;
  requiredPin: boolean;
  shareDocumentRevisions?: Guid[];
  shareDocuments?: Guid[];
  sharedOutputType: SharedSharedOutputTypeEnum;
}

export interface ShareDownloadLinkDto {
  email?: string;
  pin?: string;
  link?: string;
}

export interface ShareDownloadResultDto {
  links?: ShareDownloadLinkDto[];
}

export interface SignDocumentAppInfoDto {
  returnAddress: string;
  state: BankIdSignStateEnum;
}

export interface SignDocumentDownloadInfoDto {
  documentName: string;
  state: BankIdSignStateEnum;
}

export interface SignDocumentInfoDto {
  downloadUrl: DownloadUrl;
  signObjectKey: string;
  signObjectValue: string;
  createDate: IsoDateTime;
  pageNumber: number;
  fieldName?: string;
  fieldPositionX1: number;
  fieldPositionY1: number;
  fieldPositionX2: number;
  fieldPositionY2: number;
  state: BankIdSignStateEnum;
}

export interface SignDocumentRequestDto {
  documentId: Guid;
  revisionId: Guid;
  signingDocumentBlobId?: Guid;
  returnAddress: string;
  signObjectKey: string;
  createDate: IsoDateTime;
  signObjectValue: string;
  pageNumber: number;
  fieldName?: string;
  fieldPositionX1: number;
  fieldPositionY1: number;
  fieldPositionX2: number;
  fieldPositionY2: number;
  revisionLockId: Guid;
  workflowNodeId?: Guid;
}

export interface SignDocumentResponseDto {
  bankIdSignServerUrl: string;
  id: Guid;
}

export interface StartWopiRequestDto {
  documentId: Guid;
  revisionId: Guid;
}

export interface StartWopiResponseDto {
  wopiServerUrl: string;
  wopiClientUrl: string;
  token: string;
}

export interface SubWorkflowDto {
  name: string;
  description?: string;
  displayData?: SubWorkFlowDisplayData;
  workflowNodes: WorkflowNodeDto[];
  workflowNodeEdges: WorkflowNodeEdgeDto[];
  id: Guid;
  currentStep: number;
  currentLayer: number;
  status: SubWorkflowStatusEnum;
}

export interface SubWorkflowOverviewDto {
  name: string;
  description?: string;
  workflowNodes: WorkflowNodeOverviewDto[];
  id: Guid;
  status: SubWorkflowStatusEnum;
}

export interface SubWorkflowTemplateCreateDto {
  name: string;
  description?: string;
  id: Guid;
  displayData?: SubWorkFlowDisplayData;
  workflowTemplateNodes: WorkflowTemplateNodeCreateDto[];
  workflowTemplateNodeEdges: WorkflowTemplateNodeEdgeDto[];
}

export interface SubWorkflowTemplateDto {
  name: string;
  description?: string;
  displayData?: SubWorkFlowDisplayData;
  workflowTemplateNodes: WorkflowTemplateNodeDto[];
  workflowTemplateNodeEdges: WorkflowTemplateNodeEdgeDto[];
  id: Guid;
}

export interface SubWorkflowTemplatePatchDto {
  name: string;
  description?: string;
  id: Guid;
  displayData?: SubWorkFlowDisplayData;
  workflowTemplateNodes: WorkflowTemplateNodePatchDto[];
  workflowTemplateNodeEdges: WorkflowTemplateNodeEdgeDto[];
}

export interface SuspendAllUsersErrorsDto {
  projectId: Guid;
  error: SuspendAllUserErrorEnum;
}

export interface SuspendAllUsersResponseDto {
  errors?: SuspendAllUsersErrorsDto[];
}

export interface TemplateFromProjectRequestDto {
  projectId: Guid;
  tryAssignExistingNamedExtendedPermission: boolean;
}

export interface TimeSpan {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  microseconds: number;
  nanoseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMicroseconds: number;
  totalNanoseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}

export interface UpdateErpDataDto {
  projectIds?: Guid[];
}

export interface UploadCreateDto {
  fileName: string;
  fileSize: number;
  clientFileId: string;
  partSize?: number;
  temporary: boolean;
}

export interface UploadDto {
  id: Guid;
  fileName?: string;
  fileSize: number;
  partSize: number;
  clientFileId?: string;
  hashAlgo: HashAlgoEnum;
  status: UploadStatusEnum;
  partEtagRequired: boolean;
  blobId: Guid;
}

export interface UploadPartCompleteRequestDto {
  eTag?: string;
}

export interface UploadPartDto {
  id: Guid;
  startBytes: number;
  endBytes: number;
  hash?: string;
  eTag?: string;
  status: UploadPartStatusEnum;
  initDate?: IsoDateTime;
  commitDate?: IsoDateTime;
}

export interface UploadPartInitRequestDto {
  hash: string;
}

export interface UploadPartInitResponseDto {
  id: Guid;
  startBytes: number;
  endBytes: number;
  hash?: string;
  eTag?: string;
  status: UploadPartStatusEnum;
  initDate?: IsoDateTime;
  commitDate?: IsoDateTime;
  uri?: string;
  headers?: Dictionary<string>;
}

export interface ValidationResult {
  memberNames?: string[];
  errorMessage?: string;
}

export interface ValueTuple<S, T> {}

export interface WopiTokenTokenClaims {
  userId: Guid;
  username?: string;
  projectId: Guid;
  revisionId: Guid;
}

export interface WorkflowApprovalDocumentAddedRevisionDto {
  revision: RevisionDto;
  workflowNodeId: Guid;
}

export interface WorkflowApprovalDocumentAddedSignDto {
  revisionId: Guid;
  secondaryBlobId: Guid;
  workflowNodeId: Guid;
  blobId: Guid;
}

export interface WorkflowApprovalDocumentDto {
  id: Guid;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  document: WorkflowDocumentDto;
  revision: RevisionDto;
  hasUserDocumentPermissions: boolean;
  addedRevisions?: WorkflowApprovalDocumentAddedRevisionDto[];
  addedSigns?: WorkflowApprovalDocumentAddedSignDto[];
  workflowNodeId?: Guid;
  state: WorkFlowApprovalDocumentStateEnum;
  reservationPurpose: DocumentLockPurposeEnum;
}

export interface WorkflowBackgroundDocumentDto {
  id: Guid;
  name: string;
  description?: string;
  duration: number;
  workflowDefaultDirectory: DirectoryUncheckDto;
  responsibleRole: RoleDto;
  responsibleUser: ProjectUserProfileListDto;
  document: WorkflowDocumentDto;
  revision: RevisionDto;
  hasUserDocumentPermissions: boolean;
  backgroundDocumentState: BackgroundDocumentStateEnum;
  rejectedById?: Guid;
  rejectedDate?: IsoDateTime;
  rejectedReason?: string;
  assignedDate?: IsoDateTime;
  acceptedById?: Guid;
  acceptedDate?: IsoDateTime;
}

export interface WorkflowBackgroundDocumentOverviewDto {
  responsibleRole: RoleDto;
  responsibleUser: ProjectUserProfileListDto;
  backgroundDocumentState: BackgroundDocumentStateEnum;
}

export interface WorkflowCommentProcedurePatchDto {
  commentProcedureIds: Guid[];
}

export interface WorkflowDefaultDirectoryDto {
  id: Guid;
  name: string;
  description?: string;
  directory: DirectoryUncheckDto;
}

export interface WorkflowDefaultDirectoryInstantiationDto {
  directoryId: Guid;
  workflowTemplateDirectoryAliasId: Guid;
}

export interface WorkflowDefaultDirectoryPatchDto {
  id: Guid;
  name: string;
  description?: string;
  directoryId: Guid;
}

export interface WorkflowDocumentDetailDto {
  workflowId: Guid;
  orderId: number;
  workflowName?: string;
  status: WorkflowStatusEnum;
  hasWorkflowAccess: boolean;
  nodeName?: string;
  nodeStartTime: IsoDateTime;
  nodeDuration: number;
  nodeOnlyWorkDays: boolean;
  nodeType: NodeTypeEnum;
  nodeState: NodeStateEnum;
  backgroundDocumentRequestName?: string;
  responsibleRole: RoleDto;
  documentRevision: RevisionDto;
}

export interface WorkflowDocumentDetailListDto {
  workflowApprovalDocuments?: WorkflowDocumentDetailDto[];
  workflowBackgroundDocuments?: WorkflowDocumentDetailDto[];
}

export interface WorkflowDocumentDto {
  description?: string;
  id: Guid;
  name?: string;
  ext?: string;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  directoryId: Guid;
  directoryPath?: string;
  ownedBy: RoleDto;
  labels?: Guid[];
  categoryTreeNodes?: Dictionary<Guid>;
  isModel: boolean;
  esticonObjectLink: EsticonObjectLinkDto;
}

export interface WorkflowDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkFlowDisplayData;
  version: number;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  workflowNodes?: WorkflowNodeDto[];
  workflowNodeEdges?: WorkflowNodeEdgeDto[];
  workflowApprovalDocuments?: WorkflowApprovalDocumentDto[];
  workflowDefaultDirectories?: WorkflowDefaultDirectoryDto[];
  workflowCommentProcedures?: CommentProcedureListDto[];
  workflowRoles?: RoleDto[];
  labels?: Guid[];
  orderId: number;
  createdBy: ProjectUserProfileListDto;
  createdDate: IsoDateTime;
  modifiedBy: ProjectUserProfileListDto;
  modifiedDate: IsoDateTime;
  status: WorkflowStatusEnum;
  instanceName?: string;
  instanceDescription?: string;
  finishData?: Dictionary<JToken>;
  startTime: IsoDateTime;
  finishTime: IsoDateTime;
  closedBy: ProjectUserProfileListDto;
  currentStep: number;
  currentLayer: number;
  errors?: WorkflowErrorDto[];
}

export interface WorkflowErrorDto {
  severity: ErrorSeverityEnum;
  subject: ErrorWorkflowSubjectEnum;
  roleId: Guid;
  roleName?: string;
  subjectId: Guid;
  subjectName?: string;
}

export interface WorkflowInitParallelNode {
  workflowId: Guid;
  workflowNodeId: Guid;
  selectedSubworkflowsId?: Guid[];
}

export interface WorkflowInstantiationDto {
  workflowTemplateId: Guid;
  instanceName: string;
  instanceDescription?: string;
  workflowApprovalDocumentIds?: Guid[];
  workflowDefaultDirectories?: WorkflowDefaultDirectoryInstantiationDto[];
  workflowRole: Guid;
  message?: string;
  labels?: Guid[];
}

export interface WorkflowListDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkFlowDisplayData;
  version: number;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  modifiedBy: ProjectUserProfileListDto;
  modifiedDate?: IsoDateTime;
  workflowRoles?: RoleDto[];
  instanceName?: string;
  instanceDescription?: string;
  status: WorkflowStatusEnum;
  orderId: number;
  workflowNodes?: WorkflowNodeOverviewDto[];
  labels?: Guid[];
  voteRequested: boolean;
  bacgroundDocumentRequested: boolean;
  isMyWorkflow: boolean;
  deadlineTime?: IsoDateTime;
  taskMembership: WorkflowTaskMembershipDto;
}

export interface WorkflowLockableRoleDto {
  id: Guid;
  name?: string;
  description?: string;
  user: ProjectUserProfileListDto;
  workflowNodeRoleId: Guid;
  lockIntact: boolean;
}

export interface WorkflowNodeAddDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  name: string;
  description?: string;
  directoryId: Guid;
  uploadId: Guid;
  labels?: Guid[];
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  categoryTreeNodes?: Dictionary<Guid>;
  isModel: boolean;
  ownedById?: Guid;
}

export interface WorkflowNodeAddRevisionDto {
  description: string;
  uploadId: Guid;
  state: WorkflowStateEnum;
  secondaryDocumentsAdd: SecondaryDocumentsAddDto;
  metaData: RevisionMetaDataCreateDto;
  annotations?: DocumentAnnotationSaveDto[];
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowApprovalDocumentId: Guid;
}

export interface WorkflowNodeAssignDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  documentIds: Guid[];
}

export interface WorkflowNodeAttachmentAssignDto {
  name: string;
  documentId: Guid;
  revisionId: Guid;
}

export interface WorkflowNodeAttachmentAssignListDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  assignedDocuments?: WorkflowNodeAttachmentAssignDto[];
}

export interface WorkflowNodeAttachmentCreateDto {
  name: string;
  uploadId: Guid;
}

export interface WorkflowNodeAttachmentCreateMultipleDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  attachments: WorkflowNodeAttachmentCreateDto[];
  workflowNodeNoteId?: Guid;
}

export interface WorkflowNodeAttachmentDiscardDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  attachmentId: Guid;
}

export interface WorkflowNodeAttachmentDownloadDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  attachmentId: Guid;
}

export interface WorkflowNodeAttachmentDto {
  name: string;
  createdDate: IsoDateTime;
  file: FileDto;
  id: Guid;
  canEdit: boolean;
}

export interface WorkflowNodeAttachmentRestoreDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  attachmentId: Guid;
}

export interface WorkflowNodeAttachmentsDownloadDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  name: string;
  ids: Guid[];
}

export interface WorkflowNodeDocumentChangeDto {
  id: Guid;
  destinationState: WorkflowStateEnum;
}

export interface WorkflowNodeDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkflowNodeDisplayData;
  duration: number;
  onlyWorkDays: boolean;
  nodeType: NodeTypeEnum;
  requestedVotes: number;
  requiredVotersCount: number;
  permittedActions?: WorkflowNodePermittedActionsEnum[];
  workflowBackgroundDocuments?: WorkflowBackgroundDocumentDto[];
  workflowNodePorts?: WorkflowNodePortDto[];
  workflowNodeRoles?: WorkflowLockableRoleDto[];
  subWorkflows?: SubWorkflowDto[];
  outStep: number;
  step: number;
  layer: number;
  overNodes?: WorkflowNodeDto[];
  state: NodeStateEnum;
  actionData?: Dictionary<JToken>;
  resultPortId?: Guid;
  startTime: IsoDateTime;
  maxExpectedTime?: IsoDateTime;
  maxContinuousTime?: IsoDateTime;
  initContinuousTime?: IsoDateTime;
  startContinuousTime?: IsoDateTime;
  termin?: IsoDateTime;
  finishTime: IsoDateTime;
  defaultPortId?: Guid;
  timeoutAction: NodeTimeoutActionEnum;
  isDummy: boolean;
  originDuration: number;
  workflowNodeMessage: WorkflowNodeMessageDto;
  workflowNodeDocumentChange: WorkflowNodeDocumentChangeDto;
  workflowNodeAttachments: WorkflowNodeAttachmentDto[];
  workflowNodeNotes: WorkflowNodeNoteDto[];
}

export interface WorkflowNodeEdgeDto {
  id: Guid;
  inPortId: Guid;
  inNodeId: Guid;
  outNodeId: Guid;
  displayData?: WorkflowNodeEdgeDisplayData;
  state: NodeEdgeStateEnum;
  outStep: number;
  step: number;
  layer: number;
  overEdges?: WorkflowNodeEdgeDto[];
}

export interface WorkflowNodeGetAttachmentsDto {
  workflowId: Guid;
  workflowNodeId: Guid;
}

export interface WorkflowNodeMessageDto {
  id: Guid;
  sendToUsers: ProjectUserProfileListDto[];
  subject?: string;
  message?: string;
}

export interface WorkflowNodeNoteCreateDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  title?: string;
  message: string;
  linkedAttachments: Guid[];
  userNotifications?: Guid[];
}

export interface WorkflowNodeNoteDiscardDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowNodeNoteId: Guid;
}

export interface WorkflowNodeNoteDto {
  id: Guid;
  title: string;
  message?: string;
  state: NodeStateEnum;
  isDiscarded: boolean;
  createdDate: IsoDateTime;
  modifiedDate: IsoDateTime;
  discardedDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  modifiedBy: ProjectUserProfileListDto;
  discardedBy: ProjectUserProfileListDto;
  canUserEdit: boolean;
  workflowNodeNoteAttachments?: Guid[];
  notifiedUsers?: ProjectUserProfileListDto[];
}

export interface WorkflowNodeNoteEditDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowNodeNoteId: Guid;
  title?: string;
  message: string;
  linkedAttachments: Guid[];
}

export interface WorkflowNodeNoteRestoreDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowNodeNoteId: Guid;
}

export interface WorkflowNodeOverviewDto {
  state: NodeStateEnum;
  startTime: IsoDateTime;
  finishTime: IsoDateTime;
  id: Guid;
  name?: string;
  description?: string;
  duration: number;
  nodeType: NodeTypeEnum;
  termin?: IsoDateTime;
  onlyWorkDays: boolean;
  workflowBackgroundDocuments?: WorkflowBackgroundDocumentOverviewDto[];
  workflowNodeRoles?: WorkflowLockableRoleDto[];
  workflowNodePorts?: WorkflowNodePortDto[];
  subWorkflows?: SubWorkflowOverviewDto[];
  isDummy: boolean;
  displayData?: Dictionary<JToken>;
  workflowNodeDocumentChange: WorkflowNodeDocumentChangeDto;
}

export interface WorkflowNodePortDto {
  id: Guid;
  name: string;
  description?: string;
  actionType: NodePortActionTypeEnum;
  actionEventType: NodePortActionEventTypeEnum;
  displayData?: WorkflowNodePortDisplayData;
  votes: number;
  messages?: WorkflowNodePortVoteMessageDto[];
}

export interface WorkflowNodePortVoteMessageDto {
  message?: string;
  voteBy: ProjectUserProfileListDto;
  voteDate: IsoDateTime;
  workflowNodeRoleId: Guid;
}

export interface WorkflowNodeRemoveDocumentDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  approvalDocumentId: Guid;
}

export interface WorkflowNodeUnlockConfirmationDto {
  workflowId: Guid;
  workflowNodeId: Guid;
}

export interface WorkflowNodeVoteDto {
  workflowId: Guid;
  workflowNodeId: Guid;
  workflowNodePortId: Guid;
  message?: string;
}

export interface WorkflowPatchDto {
  name?: string;
  description?: string;
  labels?: Guid[];
}

export interface WorkflowTaskMembershipDto {
  waiting: boolean;
  current: boolean;
  out: boolean;
  processed: boolean;
}

export interface WorkflowTemplateBackgroundDocumentCreateDto {
  id: Guid;
  name: string;
  description?: string;
  duration: number;
  workflowTemplateDefaultDirectoryAliasId: Guid;
  responsibleRoleId?: Guid;
  responsibleUserId?: Guid;
}

export interface WorkflowTemplateBackgroundDocumentDto {
  id: Guid;
  name: string;
  description?: string;
  duration: number;
  workflowTemplateDefaultDirectoryAlias: WorkflowTemplateDirectoryAliasDto;
  responsibleRole: RoleDto;
  responsibleUser: ProjectUserProfileListDto;
  workflowTemplateDefaultDirectoryAliasId: Guid;
  responsibleRoleId?: Guid;
  responsibleUserId?: Guid;
}

export interface WorkflowTemplateBackgroundDocumentPatchDto {
  id: Guid;
  name: string;
  description?: string;
  duration: number;
  workflowTemplateDefaultDirectoryAliasId: Guid;
  responsibleRoleId?: Guid;
  responsibleUserId?: Guid;
}

export interface WorkflowTemplateCreateDto {
  name: string;
  description?: string;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  displayData?: WorkFlowDisplayData;
  workflowTemplateNodes: WorkflowTemplateNodeCreateDto[];
  workflowTemplateNodeEdges: WorkflowTemplateNodeEdgeDto[];
  workflowTemplateDirectoryAliases: WorkflowTemplateDirectoryAliasDto[];
  workflowTemplateRoleIds: Guid[];
  labels?: Guid[];
}

export interface WorkflowTemplateDirectoryAliasDto {
  id: Guid;
  name: string;
  description?: string;
  directoryId?: Guid;
}

export interface WorkflowTemplateDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkFlowDisplayData;
  version: number;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  workflowTemplateNodes?: WorkflowTemplateNodeDto[];
  workflowTemplateNodeEdges?: WorkflowTemplateNodeEdgeDto[];
  workflowTemplateDirectoryAliases?: WorkflowTemplateDirectoryAliasDto[];
  workflowTemplateRoles?: RoleDto[];
  modifiedBy: ProjectUserProfileListDto;
  modifiedDate: IsoDateTime;
  valid: boolean;
  workflowTemplateErrors?: WorkflowTemplateErrorDto[];
  labels?: Guid[];
}

export interface WorkflowTemplateDuplicateDto {
  name: string;
  description?: string;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  originalTemplateId: Guid;
}

export interface WorkflowTemplateErrorDto {
  errorType: WorkflowTemplateErrorEnum;
  errorSeverity: WorkflowTemplateErrorSeverityEnum;
  workflowTemplateDirectoryAliasId?: Guid;
  workflowTemplateNodeId?: Guid;
  workflowTemplateNodePortId?: Guid;
  workflowTemplateEdgeId?: Guid;
  subWorkflowTemplateId?: Guid;
}

export interface WorkFlowTemplateCheckedBackgroundDirDto {
  origAliasId: Guid;
  origAliasName: string;
  foundDir: DirectoryDto;
}

export interface WorkFlowTemplateCheckedRoleDto {
  origId: Guid;
  origName: string;
  foundRole: RoleDto;
}

export interface WorkFlowTemplateCheckedTemplateDto {
  origId: Guid;
  origName: string;
  isNameUnique: boolean;
}

export interface WorkFlowTemplateCheckedUserDto {
  origId: Guid;
  origName: string;
  foundUser: ProjectUserProfileListDto;
}

export interface WorkFlowTemplateCheckRequestDto {
  workflowTemplates: WorkflowTemplateDto[];
}

export interface WorkFlowTemplateCheckResponseDto {
  templates: WorkFlowTemplateCheckedTemplateDto[];
  roles: WorkFlowTemplateCheckedRoleDto[];
  users: WorkFlowTemplateCheckedUserDto[];
  dirs: WorkFlowTemplateCheckedBackgroundDirDto[];
}

export interface WorkFlowTemplateImportBackgroundDirDto {
  origAliasId: Guid;
  dirId?: Guid;
}

export interface WorkFlowTemplateImportRequestDto {
  workflowTemplates: WorkflowTemplateDto[];
  workFlowTemplateImportTemplates: WorkFlowTemplateImportTemplateDto[];
  workFlowTemplateImportRoles: WorkFlowTemplateImportRoleDto[];
  workFlowTemplateImportUsers: WorkFlowTemplateImportUserDto[];
  workFlowTemplateImportBackgroundDir: WorkFlowTemplateImportBackgroundDirDto[];
}

export interface WorkFlowTemplateImportRoleDto {
  origRoleId: Guid;
  roleId: Guid;
}

export interface WorkFlowTemplateImportTemplateDto {
  origId: Guid;
  name: string;
}

export interface WorkFlowTemplateImportUserDto {
  origUserId: Guid;
  userId: Guid;
}

export interface WorkflowTemplateListDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkFlowDisplayData;
  version: number;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  modifiedBy: ProjectUserProfileListDto;
  modifiedDate?: IsoDateTime;
  workflowTemplateRoles: RoleDto[];
  workflowTemplateDirectoryAliases: WorkflowTemplateDirectoryAliasDto[];
  oldName?: string;
  oldDescription?: string;
  state: WorkflowTemplateStateEnum;
  labels?: Guid[];
}

export interface WorkflowTemplateNodeCreateDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkflowNodeDisplayData;
  duration: number;
  onlyWorkDays: boolean;
  nodeType: NodeTypeEnum;
  requestedVotes: number;
  requiredVotersCount: number;
  permittedActions?: WorkflowNodePermittedActionsEnum[];
  workflowTemplateBackgroundDocuments: WorkflowTemplateBackgroundDocumentCreateDto[];
  workflowTemplateNodePorts: WorkflowTemplateNodePortDto[];
  workflowTemplateNodeRoleIds: Guid[];
  subWorkflowTemplates?: SubWorkflowTemplateCreateDto[];
  templateDefaultPortId?: Guid;
  timeoutAction: NodeTimeoutActionEnum;
  workflowTemplateNodeMessage: WorkflowTemplateNodeMessageCreateDto;
  workflowTemplateNodeDocumentChange: WorkflowTemplateNodeDocumentChangeDto;
}

export interface WorkflowTemplateNodeDocumentChangeDto {
  id: Guid;
  destinationState: WorkflowStateEnum;
}

export interface WorkflowTemplateNodeDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkflowNodeDisplayData;
  duration: number;
  onlyWorkDays: boolean;
  nodeType: NodeTypeEnum;
  requestedVotes: number;
  requiredVotersCount: number;
  permittedActions?: WorkflowNodePermittedActionsEnum[];
  workflowTemplateBackgroundDocuments?: WorkflowTemplateBackgroundDocumentDto[];
  workflowTemplateNodePorts?: WorkflowTemplateNodePortDto[];
  workflowTemplateNodeRoles?: RoleDto[];
  templateDefaultPortId?: Guid;
  timeoutAction: NodeTimeoutActionEnum;
  subWorkflowTemplates?: SubWorkflowTemplateDto[];
  workflowTemplateNodeMessage: WorkflowNodeMessageDto;
  workflowTemplateNodeDocumentChange: WorkflowTemplateNodeDocumentChangeDto;
}

export interface WorkflowTemplateNodeEdgeDto {
  id: Guid;
  inPortId: Guid;
  inNodeId: Guid;
  outNodeId: Guid;
  displayData?: WorkflowNodeEdgeDisplayData;
}

export interface WorkflowTemplateNodeMessageCreateDto {
  id: Guid;
  sendToUserIds: Guid[];
  subject?: string;
  message?: string;
}

export interface WorkflowTemplateNodeMessagePatchDto {
  id: Guid;
  sendToUserIds: Guid[];
  subject?: string;
  message?: string;
}

export interface WorkflowTemplateNodePatchDto {
  id: Guid;
  name: string;
  description?: string;
  displayData?: WorkflowNodeDisplayData;
  duration: number;
  onlyWorkDays: boolean;
  nodeType: NodeTypeEnum;
  requestedVotes: number;
  requiredVotersCount: number;
  permittedActions?: WorkflowNodePermittedActionsEnum[];
  workflowTemplateBackgroundDocuments: WorkflowTemplateBackgroundDocumentPatchDto[];
  workflowTemplateNodePorts: WorkflowTemplateNodePortDto[];
  workflowTemplateNodeRoleIds: Guid[];
  templateDefaultPortId?: Guid;
  timeoutAction: NodeTimeoutActionEnum;
  subWorkflowTemplates?: SubWorkflowTemplatePatchDto[];
  workflowTemplateNodeMessage: WorkflowTemplateNodeMessagePatchDto;
  workflowTemplateNodeDocumentChange: WorkflowTemplateNodeDocumentChangeDto;
}

export interface WorkflowTemplateNodePortDto {
  id: Guid;
  name: string;
  description?: string;
  actionType: NodePortActionTypeEnum;
  actionEventType: NodePortActionEventTypeEnum;
  displayData?: WorkflowNodePortDisplayData;
}

export interface WorkflowTemplatePatchDto {
  name: string;
  description?: string;
  workInProgressPermitted: boolean;
  sharedPermitted: boolean;
  publishedPermitted: boolean;
  id: Guid;
  displayData?: WorkFlowDisplayData;
  workflowTemplateNodes: WorkflowTemplateNodePatchDto[];
  workflowTemplateNodeEdges: WorkflowTemplateNodeEdgeDto[];
  workflowTemplateDirectoryAliases: WorkflowTemplateDirectoryAliasDto[];
  workflowTemplateRoleIds: Guid[];
  labels?: Guid[];
}

export interface ZBVCreateDto {
  pdfUploadId: Guid;
  xmlUploadId?: Guid;
  xmlError?: string;
  esticonId: Guid;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  sign: string;
  objectSign: string;
  name?: string;
  objectPath?: string[];
}

export interface ZjistovakCreateDto {
  pdfUploadId: Guid;
  xmlUploadId?: Guid;
  xmlError?: string;
  esticonId: Guid;
  vystaveni: IsoDateTime;
  schvaleni?: IsoDateTime;
  sign: string;
  objectSign: string;
  objectPath?: string[];
  isSdruzene: boolean;
  idZjistovaciProtokolSdruzeni?: Guid;
}
