import { getPersistor } from '@rematch/persist';
import Main from 'Main';
import IdleTimerActivityCheck from 'components/IdleTimerActivityCheck/IdleTimerActivityCheck';
import LastLocationStartupRedirect from 'components/LastLocationStartupRedirect/LastLocationStartupRedirect';
import SignalRStoreMonitor from 'components/SignalRStoreMonitor/SignalRStoreMonitor';
import { userManager } from 'config';
import { configureApplicationInsights } from 'config/applicationInsights';
import { Locale } from 'locale/Locale';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import { PersistGate } from 'redux-persist/integration/react';
import { history, store } from 'store';
import UnsupportedBrowserCheck from './components/UnsupportedBrowserCheck/UnsupportedBrowserCheck';

const PERSISTOR = getPersistor();

const App = () => (
  <Provider store={store}>
    <Locale>
      <UnsupportedBrowserCheck>
        <LastLocationStartupRedirect />
        <IdleTimerActivityCheck history={history} />
        <SignalRStoreMonitor />
        <PersistGate persistor={PERSISTOR}>
          <OidcProvider store={store} userManager={userManager}>
            <Router history={history}>
              <Route path="/" component={Main} />
            </Router>
          </OidcProvider>
        </PersistGate>
      </UnsupportedBrowserCheck>
    </Locale>
  </Provider>
);

export default configureApplicationInsights(App);
