import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { useIntl } from 'hooks';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { PageSubmenu } from 'components/PageSubmenu/PageSubmenu';

type Props = {
  url: string;
  selectedKeys?: string[];
  onSelect?: (key: string) => void;
};

const MessageCenterMenu: FunctionComponent<Props> = ({ url, selectedKeys, onSelect }) => {
  const history = useHistory();
  const intl = useIntl();

  const messageCenterMenuItems: ItemType[] = useMemo(
    () => [
      {
        key: 'messages',
        label: intl.formatMessage({ id: 'MessageCenterPage.tabs.messages' }),
        onClick: () => {
          onSelect?.('messages');
        },
        icon: <MailOutlined />,
      },
      {
        key: 'assignments',
        label: intl.formatMessage({ id: 'MessageCenterPage.tabs.assignments' }),
        onClick: () => {
          onSelect?.('assignments');
        },
        icon: <CheckCircleOutlined />,
      },
      {
        key: 'settings',
        label: intl.formatMessage({ id: 'MessageCenterPage.tabs.settings' }),
        onClick: () => {
          onSelect?.('settings');
        },
        icon: <SettingOutlined />,
      },
    ],
    [url, history, intl]
  );

  return <PageSubmenu items={messageCenterMenuItems} selectedKeys={selectedKeys} />;
};

export default React.memo(MessageCenterMenu);
