import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import { ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransfer } from 'components/UserTransfer/UserTransfer';
import { USER_TRANSFER_MODAL_WIDTH } from 'config/constants';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

type Props = {
  onSubmit: (addedUserIds: Guid[]) => void;
  onClose: () => void;
  loading: boolean;
  visible: boolean;
  users?: ProjectUserProfileListDto[];
  allowEmpty?: boolean;
  defaultTargetKeys?: string[];
};

const GroupUserAddFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  loading,
  visible,
  allowEmpty,
  users,
  defaultTargetKeys,
}) => {
  const [targetKeys, setTargetKeys] = useState<Guid[]>(() => defaultTargetKeys || []);

  useEffect(() => {
    setTargetKeys(defaultTargetKeys || []);
  }, [users, defaultTargetKeys, visible]);

  const handleOnOk = () => {
    onSubmit(targetKeys);
  };

  const handleOnCancel = () => {
    onClose();
  };

  const okButtonProps = useMemo(() => {
    return { disabled: !allowEmpty && targetKeys.length === 0, loading: loading };
  }, [targetKeys]);

  return (
    <Modal
      title={<Fmt id="GroupUserAddFormModal.title" />}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={handleOnOk}
      width={USER_TRANSFER_MODAL_WIDTH}
      okButtonProps={okButtonProps}
    >
      <Form layout="vertical">
        <Form.Item>
          <UserTransfer users={users} value={targetKeys} onChange={setTargetKeys} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default GroupUserAddFormModal;
