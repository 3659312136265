import { message } from 'antd';
import {
  EstiCategoryEnum,
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { useAggregateEvent } from 'hooks/useAggregateEvent';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { useCallback } from 'react';
import { toGroups } from './useAggregateDocumentMessage';

type SignalEsticonGridDto = {
  projectId: Guid;
  user: string;
  esticonObjectId: Guid;
  category: EstiCategoryEnum;
  isImport?: boolean;
};

const MAP_ESTICON_SIGNAL_PARAMS_TO_OBJECT = (
  projectId: Guid,
  user: string,
  esticonObjectId: Guid,
  category: EstiCategoryEnum,
  isImport?: boolean
): SignalEsticonGridDto => ({
  projectId,
  user,
  esticonObjectId,
  category,
  isImport,
});

export function useAggregateEsticonGridMessage(
  action: () => void,
  messageId: IntlMessageId,
  intl: InjectedIntl,
  users: ProjectUserProfileListDto[],
  currentProjectUser: ProjectUserProfileDto,
  categories: EstiCategoryEnum[],
  wait: number = 300
) {
  const onDebounceFunc = useCallback(
    (values: SignalEsticonGridDto[]) => {
      const categoryValues = values.filter((value) => categories.some((category) => category === value.category));
      if (!categoryValues.length) return;
      showGroupedMessagesByUser(categoryValues, users, currentProjectUser, messageId, intl);
      action();
    },
    [action, users, intl]
  );
  return useAggregateEvent(onDebounceFunc, wait, MAP_ESTICON_SIGNAL_PARAMS_TO_OBJECT);
}

function showGroupedMessagesByUser(
  values: SignalEsticonGridDto[],
  users: ProjectUserProfileListDto[],
  currentProjectUser: ProjectUserProfileListDto,
  messageID: IntlMessageId,
  intl: InjectedIntl
) {
  toGroups(
    values,
    (value) => value.user,
    (values, key) => {
      const user = users?.find((u) => u.username === key);
      if (!user || user.status === ProjectUserProfileStatusEnum.system || user.id === currentProjectUser.id) return;
      message.info(
        intl.formatMessage(
          {
            id: messageID,
          },
          { user: user?.username, count: values.length }
        )
      );
    }
  );
}
