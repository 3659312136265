import { AccessLevelEnum, WorkflowTemplateDirectoryAliasDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelect } from 'components/DirectoriesTreeSelect';
import { InjectedIntl } from 'locale';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { ignoreRef } from 'utils';

type Props = {
  directoryAliases: WorkflowTemplateDirectoryAliasDto[];
  intl: InjectedIntl;
  initialFolders: Record<Guid, Guid>;
  onChange?: (folders: Record<Guid, Guid>) => void;
};

const WorkflowInstantiationDirectoryAssignComponent: React.FunctionComponent<Props> = ({
  directoryAliases,
  initialFolders,
  onChange,
  intl,
}) => {
  const [usedFolders, setUsedFolder] = useState<Record<Guid, Guid>>({});
  const [isDirectoryTreeOpen, setDirectoryTreeOpen] = useState<boolean>();

  const selectFolder = (aliasKey: Guid, value: Guid) => {
    setUsedFolder((folders) => ({ ...folders, [aliasKey]: value }));
  };

  const reservedFolders = useMemo(() => {
    return Object.keys(usedFolders).map((f: Guid) => usedFolders[f]);
  }, [usedFolders]);

  useEffect(() => {
    setUsedFolder(initialFolders);
  }, [initialFolders]);

  useDebounce(
    () => {
      if (isDirectoryTreeOpen || Object.values(usedFolders).filter((folderId) => folderId !== null).length === 0)
        return;

      onChange && onChange(usedFolders);
    },
    100,
    [usedFolders, isDirectoryTreeOpen]
  );

  return (
    <Fragment>
      {directoryAliases.map((alias) => (
        <Fragment key={alias.id}>
          <span>{alias.name}:</span>
          <DirectoriesTreeSelect
            showSearch
            placeholder={intl.formatMessage({
              id: 'WorkflowInstantiationForm.defaultDirectories.placeholder',
            })}
            isItemDisabled={(item) =>
              (item.currentAccessLevel !== AccessLevelEnum.admin &&
                item.currentAccessLevel !== AccessLevelEnum.write) ||
              (usedFolders[alias.id] !== item.id && reservedFolders?.find((f) => f === item.id) !== undefined)
            }
            defaultValue={alias.directoryId}
            value={usedFolders[alias.id]}
            onChange={(value) => selectFolder(alias.id, value)}
            onDropdownVisibleChange={setDirectoryTreeOpen}
            defaultExpandDepth={3}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};

export const WorkflowInstantiationDirectoryAssign = ignoreRef(
  React.memo(WorkflowInstantiationDirectoryAssignComponent)
);
