import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { RoleDto } from 'api/completeApiInterfaces';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import { InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { requiredRule } from 'utils/formHelpers';

export type RoleAssignFormData = {
  roleId: string;
};

type Props = FormComponentProps<RoleAssignFormData> &
  InjectedIntlProps & {
    userRolesSet: Set<Guid>;
  };

type State = {
  initialized: boolean;
};

class RoleAssignForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { initialized: false };
  }

  disabledRoles = (role: RoleDto) => {
    return this.props.userRolesSet?.has(role.id);
  };

  render() {
    const { intl, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.roleId.label' })}>
          {getFieldDecorator<RoleAssignFormData>('roleId', {
            rules: [requiredRule('forms.items.roleId.rules.required')],
          })(<RoleSelect hiddenRoles={this.disabledRoles} />)}
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create<Props>()(RoleAssignForm);
