import React, { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import SpinBox from 'components/SpinBox';
import { Fmt } from 'locale';
import { useIntl, useIsMounted } from 'hooks';
import { DEBUG } from 'config/env';
import { FORGE_VIEWER_VERSION } from 'Forge/ForgeViewerOptions';
import { loadCss } from 'utils/loadCss';
import { loadScript } from 'utils/loadScript';
import { ForgeDisplayMode, NamedForgeModel } from 'Forge/Forge';

type Options = Autodesk.Viewing.InitializerOptions;

type OptionsParams = keyof Options;

type RuntimeType = {
  options: Options;
  ready: Promise<void>;
};

const FORGE_VIEWER_SCRIPT_ID = 'forge-viewer-script';
const TRIGGER_OPTIONS: OptionsParams[] = ['accessToken', 'getAccessToken', 'env', 'api', 'language'];

const forgeRuntime: RuntimeType = {
  options: null,
  ready: null,
};

export function initializeViewerRuntime(options: Options) {
  if (!options) {
    return Promise.reject('No options provided.');
  }
  const Autodesk = window.Autodesk;
  if (!Autodesk) {
    return Promise.reject('Autodesk is not loaded.');
  }
  if (!forgeRuntime.ready) {
    forgeRuntime.options = { ...options };
    forgeRuntime.ready = new Promise((resolve) => Autodesk.Viewing.Initializer(forgeRuntime.options, resolve));
  } else {
    if (TRIGGER_OPTIONS.some((prop) => options[prop] !== forgeRuntime.options[prop])) {
      return Promise.reject('Cannot initialize another viewer runtime with different settings.');
    }
  }
  return forgeRuntime.ready;
}

export const loadForgeViewerScripts = (onLoad?: () => void) => {
  if (!window.Autodesk) {
    DEBUG && console.log('loadForgeViewerScripts', 'No Autodesk scripts found. Loading...');
    const loadScripts = async () => {
      await loadCss(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${FORGE_VIEWER_VERSION}/style.min.css`
      );
      await loadScript(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${FORGE_VIEWER_VERSION}/viewer3D.min.js`,
        FORGE_VIEWER_SCRIPT_ID
      );
      await import('./extensions/CustomPropertiesExtension');
      await import('./extensions/VolumeSurfaceExtension');
      DEBUG && console.log('loadForgeViewerScripts', 'Autodesk scripts loaded.');
      if (!!onLoad) onLoad();
    };

    try {
      void loadScripts();
    } catch (e) {
      DEBUG && console.error(e);
    }
  } else {
    DEBUG && console.log('loadForgeViewerScripts', 'Autodesk scripts already loaded.');
    if (!!onLoad) onLoad();
  }
};

export const useDocumentsLoadState = (onLoadingModelsDone?: () => void) => {
  const currentlyLoadedModel = useRef<NamedForgeModel | null>(null);
  const loadingQueue = useRef<NamedForgeModel[]>([]);
  // const [displayMode, setDisplayMode] = useState<ForgeDisplayMode>(ForgeDisplayMode.Mode3d);

  const loadDocumentsSequentially = useCallback(
    async (
      viewer: Autodesk.Viewing.GuiViewer3D,
      models: NamedForgeModel[],
      loadModel: (model: NamedForgeModel, index: number) => Promise<void>
    ) => {
      if (!viewer) {
        DEBUG && console.error('Forge viewer not loaded yet');
        return;
      }

      if (!models || models.length === 0) {
        DEBUG && console.error('No models to load');
        return;
      }

      if (currentlyLoadedModel.current !== null) {
        // already running
        return;
      }

      loadingQueue.current = [...models];

      let index = 0;

      while (loadingQueue.current.length > 0) {
        const [namedModel] = loadingQueue.current.splice(0, 1); // pop first element
        currentlyLoadedModel.current = namedModel;
        await loadModel(namedModel, index);
        index++;
        if (!!onLoadingModelsDone) onLoadingModelsDone();
      }

      currentlyLoadedModel.current = null;
      if (!!onLoadingModelsDone) onLoadingModelsDone();
    },
    [onLoadingModelsDone]
  );

  return [loadDocumentsSequentially] as const;
};

//#region Hook useForgeViewerScriptLoader
export const useForgeViewerScriptLoader = () => {
  const [isForgeViewerScriptsLoaded, setIsForgeViewerScriptsLoaded] = useState(false);
  const [autodesk, setAutodesk] = useState<typeof Autodesk>(undefined);
  const isMounted = useIsMounted();

  useEffect(() => {
    loadForgeViewerScripts(() => {
      if (!isMounted.current) return;
      setIsForgeViewerScriptsLoaded(true);
      setAutodesk(window.Autodesk);
    });
  }, []);

  return [isForgeViewerScriptsLoaded, autodesk] as const;
};
//#endregion

//#region Component ForgeLoader
type ForgeProps = {};

export const ForgeLoader: FunctionComponent<ForgeProps> = ({ children }) => {
  const [forgeScriptsLoaded] = useForgeViewerScriptLoader();
  const intl = useIntl();
  const [renderedLocale, setRenderedLocale] = React.useState(intl.locale);

  useEffect(() => {
    if (forgeScriptsLoaded) {
      const localize = async () => {
        if (!window.Autodesk) {
          DEBUG && console.error('localize Forge while Autodesk is not loaded');
          return;
        }
        const i18n = window.Autodesk.Viewing.i18n;
        console.log('localize Forge', i18n);
        await i18n.reloadResources([intl.locale]);
        await i18n.changeLanguage(intl.locale);
        await i18n.localize();
        setRenderedLocale(intl.locale);
      };
      void localize();
    }
  }, [intl, forgeScriptsLoaded]);

  if (!forgeScriptsLoaded) {
    return (
      <SpinBox fill spinning>
        <Fmt id="Forge.loading" />
      </SpinBox>
    );
  }

  return <Fragment key={renderedLocale}>{children}</Fragment>;
};

//#endregion

const getModelBubbleNodes = (
  viewerDocument: Autodesk.Viewing.Document
): { bubbleNodes2d: Autodesk.Viewing.BubbleNode[]; bubbleNodes3d: Autodesk.Viewing.BubbleNode[] } => {
  const bubbleNodes2d = viewerDocument?.getRoot()?.search({ role: '2d', type: 'geometry' }) || [];
  const bubbleNodes3d = viewerDocument?.getRoot()?.search({ role: '3d', type: 'geometry' }) || [];

  return { bubbleNodes2d, bubbleNodes3d };
};

const getSelectedBubbleNode = (
  viewerDocument: Autodesk.Viewing.Document,
  mode: ForgeDisplayMode,
  bubbleIndex: number
) => {
  const { bubbleNodes2d, bubbleNodes3d } = getModelBubbleNodes(viewerDocument);

  const availableModels = mode === ForgeDisplayMode.Mode2d ? bubbleNodes2d : bubbleNodes3d;

  return availableModels[bubbleIndex];
};

const LoadDocumentBubbleNodeToViewer = (
  viewer: Autodesk.Viewing.GuiViewer3D,
  viewerDocument: Autodesk.Viewing.Document,
  selectedBubbleNode: Autodesk.Viewing.BubbleNode,
  options: { [key: string]: any }
): Promise<Autodesk.Viewing.Model> => {
  return viewer.loadDocumentNode(viewerDocument, selectedBubbleNode, options);
};

export const loadDocument = (urn: string): Promise<Autodesk.Viewing.Document> =>
  new Promise<Autodesk.Viewing.Document>((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      `urn:${urn}`,
      (viewerDocument) => {
        resolve(viewerDocument);
      },
      (viewerErrorCode: Autodesk.Viewing.ErrorCodes) => {
        reject(viewerErrorCode);
      }
    );
  });

export const loadSingleModel = async (
  viewer: Autodesk.Viewing.GuiViewer3D,
  loadingModel: NamedForgeModel,
  mode: ForgeDisplayMode,
  options: { [key: string]: any }
) => {
  const document = await loadDocument(loadingModel.urn);
  if (!document) {
    DEBUG && console.error('Document not found');
    return Promise.reject();
  }

  const bubbleNodes = getModelBubbleNodes(document);
  const selectedBubbleNode = getSelectedBubbleNode(document, mode, 0);

  const model = await LoadDocumentBubbleNodeToViewer(viewer, document, selectedBubbleNode, options);

  if (!model) {
    DEBUG && console.error('Model not found');
    return Promise.reject();
  }

  return Promise.resolve({ document, bubbleNodes, model });
};

const changeDocumentBubbleNode = async (
  viewer: Autodesk.Viewing.GuiViewer3D,
  document: Autodesk.Viewing.Document,
  selectedBubbleNode: Autodesk.Viewing.BubbleNode,
  options: { [key: string]: any }
) => {
  return await LoadDocumentBubbleNodeToViewer(viewer, document, selectedBubbleNode, options);
};
