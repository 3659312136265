import { DEBUG } from 'config/env';
import isMobile from 'is-mobile';
import { useEffect } from 'react';

export const useSetOrientation = (orientation: 'landscape' | 'portrait' | null | undefined) => {
  useEffect(() => {
    if (!isMobile()) {
      return () => {}; // do not do anything - it would crash on desktop
    }
    if (orientation && !!screen?.orientation?.lock) {
      screen.orientation.lock(orientation).catch((error) => DEBUG && console.error(error));
    }
    return () => screen?.orientation?.unlock?.();
  }, [orientation]);
};
