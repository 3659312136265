import { LinkOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { CommentProcedureCommentDto, CommentProcedureDto, CommentProcedurePhaseEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DiscussionInputAttachments from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments';
import {
  StagedAttachment,
  attachmentMapper,
  limitNewAttachmentLinks,
} from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { DiscussionInputAttachmentNameFormModal } from 'components/forms/DiscussionInputAttachmentNameForm/DiscussionInputAttachmentNameFormModal';
import { DiscussionLinkAttachmentFormData } from 'components/forms/DiscussionLinkAttachmentForm/DiscussionLinkAttachmentForm';
import { DiscussionLinkAttachmentFormModal } from 'components/forms/DiscussionLinkAttachmentForm/DiscussionLinkAttachmentFormModal';
import { MAX_COMMENT_TEXT_ATTACHMENT_COUNT } from 'config/constants';
import { useBoolean, useSameCallback } from 'hooks';
import { useImagePaste } from 'hooks/useImagePaste';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { CommentDetailReducerActions } from 'pages/CommentProcedureDetailPage/tabs/Comments/CommentProcedureCommentsDetails';
import { createGetOriginalUrlForCommentAttachment } from 'pages/CommentProcedureDetailPage/tabs/Comments/Discussion/CommentProcedureCommentsNotes';
import React, { Dispatch, FunctionComponent, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import styles from './CommentText.module.less';

type AddCommentAttachmentCapability = {
  canPasteImage: boolean;
  canAddExistingAttachment: boolean;
  disabledMessage: IntlMessageId | null;
};

export const processAddCommentAttachmentCapability = (
  linkedAttachments: number,
  totalAttachments: number,
  availableAttachments: number
): AddCommentAttachmentCapability => {
  if (linkedAttachments >= MAX_COMMENT_TEXT_ATTACHMENT_COUNT) {
    return {
      canPasteImage: false,
      canAddExistingAttachment: false,
      disabledMessage: 'CommentProcedureLinkAttachment.tooltip.maxAttachmentsExceeded',
    };
  }
  if (totalAttachments === 0) {
    return {
      canPasteImage: true,
      canAddExistingAttachment: false,
      disabledMessage: 'CommentProcedureLinkAttachment.tooltip.noAttachments',
    };
  }
  if (availableAttachments === 0) {
    return {
      canPasteImage: true,
      canAddExistingAttachment: false,
      disabledMessage: 'CommentProcedureLinkAttachment.tooltip.noMoreAttachments',
    };
  }
  return {
    canPasteImage: true,
    canAddExistingAttachment: true,
    disabledMessage: null,
  };
};

type Props = InjectedIntlProps & {
  commentDetail: CommentProcedureCommentDto;
  commentProcedure: CommentProcedureDto;
  setCommentDetail?: Dispatch<CommentDetailReducerActions>;
  stagedAttachments: Set<StagedAttachment>;
  emptyPlaceholder?: string;
  canEditComment: boolean;
  canEditAddition: boolean;
  disabled: boolean;
};

const AUTOSIZE_OPTIONS = { minRows: 2, maxRows: 6 };
const ADDITION_AUTOSIZE_OPTIONS = { minRows: 1, maxRows: 4 };

export const CommentText: FunctionComponent<Props> = ({
  intl,
  commentProcedure,
  commentDetail,
  setCommentDetail,
  stagedAttachments,
  emptyPlaceholder,
  canEditComment,
  canEditAddition,
  disabled,
}) => {
  const [linkAttachmentModalVisible, showLinkAttachmentModal, hideLinkAttachmentModal] = useBoolean(false);
  const [namingModalVisible, showNamingModal, hideNamingModal] = useBoolean(false);
  const [pastedImageFile, onImagePasteHandler] = useImagePaste(showNamingModal);

  const isCommentingState = commentProcedure.state === CommentProcedurePhaseEnum.Commenting;
  const hideDiscussionAdition =
    commentProcedure.state === CommentProcedurePhaseEnum.InternalNegotiations && !canEditAddition;

  const onMessageChange = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      setCommentDetail({ type: 'setMessage', message: value });
    },
    [setCommentDetail]
  );

  const onDiscussionAdditionChange = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      setCommentDetail({ type: 'setDiscussionAddition', discussionAddition: value });
    },
    [setCommentDetail]
  );

  const handleAttachmentLinkRemove = useSameCallback((attachmentId: Guid) => {
    isCommentingState
      ? setCommentDetail({
          type: 'removeMessageAttachmentLink',
          removedAttachment: attachmentId,
        })
      : setCommentDetail({
          type: 'removeDiscussionAttachmentLink',
          removedAttachment: attachmentId,
        });
  });

  const unlinkedDocuments = useMemo(() => {
    return isCommentingState
      ? commentDetail.commentProcedureCommentAttachments.filter(
          (f) => !commentDetail.commentProcedureCommentMessageAttachments.includes(f.id)
        )
      : commentDetail.commentProcedureCommentAttachments.filter(
          (f) => !commentDetail.commentProcedureCommentDiscussionAdditionAttachments.includes(f.id)
        );
  }, [commentDetail]);

  const addAttachmentLink = useCallback(
    (newAttachments: Guid[]) => {
      isCommentingState
        ? setCommentDetail({
            type: 'addMessageAttachmentLink',
            newAttachments: limitNewAttachmentLinks(
              newAttachments,
              commentDetail.commentProcedureCommentMessageAttachments.length,
              MAX_COMMENT_TEXT_ATTACHMENT_COUNT
            ),
          })
        : setCommentDetail({
            type: 'addDiscussionAttachmentLink',
            newAttachments: limitNewAttachmentLinks(
              newAttachments,
              commentDetail.commentProcedureCommentDiscussionAdditionAttachments.length,
              MAX_COMMENT_TEXT_ATTACHMENT_COUNT
            ),
          });
    },
    [commentDetail, setCommentDetail]
  );

  const handleAttachmentLinkAdd = useCallback(
    (data: DiscussionLinkAttachmentFormData) => {
      const selectedAttachments = Array.from(data.linkedAttachments || []);
      selectedAttachments && addAttachmentLink(selectedAttachments);
      hideLinkAttachmentModal();
      return null;
    },
    [addAttachmentLink]
  );

  const linkedMessageDocuments = useMemo(() => {
    return commentDetail.commentProcedureCommentAttachments
      .filter((f) => commentDetail.commentProcedureCommentMessageAttachments.includes(f.id))
      .map(attachmentMapper)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [commentDetail]);

  const linkedDiscussionDocuments = useMemo(() => {
    return commentDetail.commentProcedureCommentAttachments
      .filter((f) => commentDetail.commentProcedureCommentDiscussionAdditionAttachments.includes(f.id))
      .map(attachmentMapper)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [commentDetail]);

  const handleNameSubmit = useCallback(
    (attachment: StagedAttachment) => {
      stagedAttachments.add(attachment);
      setCommentDetail({ type: 'setStagedAttachment', stagedAttachments: stagedAttachments });
      hideNamingModal();
    },
    [stagedAttachments]
  );

  const handleStagedAttachmentLinkRemove = useSameCallback((attachment: StagedAttachment) => {
    stagedAttachments.delete(attachment);
    setCommentDetail({ type: 'setStagedAttachment', stagedAttachments: stagedAttachments });
  });

  const {
    canPasteImage: canMessagePasteImage,
    canAddExistingAttachment: canMessageAddExistingAttachment,
    disabledMessage: addMessageAttachmentDisabledMessage,
  } = processAddCommentAttachmentCapability(
    linkedMessageDocuments.length + stagedAttachments.size,
    commentDetail.commentProcedureCommentAttachments.length,
    unlinkedDocuments.length
  );

  const {
    canPasteImage: canDiscussionPasteImage,
    canAddExistingAttachment: canDiscussionAddExistingAttachment,
    disabledMessage: addDiscussionAttachmentDisabledMessage,
  } = processAddCommentAttachmentCapability(
    linkedDiscussionDocuments.length + stagedAttachments.size,
    commentDetail.commentProcedureCommentAttachments.length,
    unlinkedDocuments.length
  );

  const getOriginalUrl = useMemo(
    () => createGetOriginalUrlForCommentAttachment(commentProcedure.id, commentDetail.id),
    [commentProcedure.id, commentDetail.id]
  );

  return (
    <div className={styles.comment}>
      {canEditComment ? (
        <>
          <div className={styles.attachmentHeader}>
            <Typography.Text className={styles.additionTitle}>
              <Fmt id="CommentText.messageTitle" />
            </Typography.Text>
            <CommonHubTooltip
              placement="right"
              title={
                canMessageAddExistingAttachment ? (
                  <Fmt id="CommentProcedureLinkAttachment.tooltip.attach" />
                ) : (
                  <Fmt id={addMessageAttachmentDisabledMessage} />
                )
              }
            >
              <Button
                icon={<LinkOutlined />}
                shape="circle"
                size="small"
                onClick={showLinkAttachmentModal}
                disabled={!canMessageAddExistingAttachment}
              />
            </CommonHubTooltip>
          </div>
          <Input.TextArea
            disabled={disabled}
            placeholder={emptyPlaceholder}
            value={commentDetail.message}
            onChange={onMessageChange}
            autoSize={AUTOSIZE_OPTIONS}
            className={classNames(styles.messageTextArea, styles.editable)}
            onPaste={onImagePasteHandler}
          />
        </>
      ) : (
        <Typography.Paragraph className={styles.messageTextArea}>{commentDetail.message}</Typography.Paragraph>
      )}
      <div className={styles.viewer}>
        <DiscussionInputAttachments
          attachments={linkedMessageDocuments}
          onAttach={canEditComment && showLinkAttachmentModal}
          onRemove={canEditComment && handleAttachmentLinkRemove}
          onStagedRemove={canEditComment && handleStagedAttachmentLinkRemove}
          stagedAttachments={canEditComment && stagedAttachments}
          disableNewLink={!canMessageAddExistingAttachment}
          disableNewLinkMessage={addMessageAttachmentDisabledMessage}
          getOriginalUrl={getOriginalUrl}
        />
      </div>
      {!isCommentingState && !hideDiscussionAdition && (
        <div className={styles.addition}>
          <div className={styles.attachmentHeader}>
            <Typography.Text strong className={styles.additionTitle}>
              <Fmt id="CommentText.additionalMessageTitle" />
            </Typography.Text>
            {canEditAddition && (
              <CommonHubTooltip
                placement="right"
                title={
                  canDiscussionAddExistingAttachment ? (
                    <Fmt id="CommentProcedureLinkAttachment.tooltip.attach" />
                  ) : (
                    <Fmt id={addDiscussionAttachmentDisabledMessage} />
                  )
                }
              >
                <Button
                  icon={<LinkOutlined />}
                  shape="circle"
                  size="small"
                  onClick={showLinkAttachmentModal}
                  disabled={!canDiscussionAddExistingAttachment}
                />
              </CommonHubTooltip>
            )}
          </div>
          {canEditAddition ? (
            <Input.TextArea
              disabled={disabled}
              placeholder={emptyPlaceholder}
              value={commentDetail.discussionAddition}
              onChange={onDiscussionAdditionChange}
              autoSize={ADDITION_AUTOSIZE_OPTIONS}
              className={classNames(styles.messageTextArea, styles.editable)}
              onPaste={onImagePasteHandler}
            />
          ) : (
            <Typography.Paragraph className={styles.messageTextArea}>
              {commentDetail.discussionAddition}
            </Typography.Paragraph>
          )}
          <div className={styles.viewer}>
            <DiscussionInputAttachments
              attachments={linkedDiscussionDocuments}
              onAttach={canEditAddition && showLinkAttachmentModal}
              onRemove={canEditAddition && handleAttachmentLinkRemove}
              onStagedRemove={handleStagedAttachmentLinkRemove}
              stagedAttachments={stagedAttachments}
              disableNewLink={!canDiscussionAddExistingAttachment}
              disableNewLinkMessage={addDiscussionAttachmentDisabledMessage}
              getOriginalUrl={getOriginalUrl}
            />
          </div>
        </div>
      )}

      <DiscussionInputAttachmentNameFormModal
        intl={intl}
        imageFile={pastedImageFile}
        visible={namingModalVisible}
        onSubmit={handleNameSubmit}
        onClose={hideNamingModal}
        disabled={(canEditComment && !canMessagePasteImage) || (canEditAddition && !canDiscussionPasteImage)}
      />

      <DiscussionLinkAttachmentFormModal
        visible={linkAttachmentModalVisible}
        availableAttachments={unlinkedDocuments}
        onSubmit={handleAttachmentLinkAdd}
        onClose={hideLinkAttachmentModal}
        getOriginalUrl={getOriginalUrl}
      />
    </div>
  );
};

export default injectIntl(CommentText);
