import { Tree } from 'antd';
import numeral from 'numeral';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import { ProcessedJsonData } from '../ProcessedJsonViewer';
import styles from './Processor.module.less';

type DirectoryContent = {
  subDirs: Record<string, DirectoryContent>;
  files: string[];
};

export type ArchiveProcessorData = {
  size: string;
  contentType: string;
} & DirectoryContent;

const renderArchiveNodes = (rootName: string, data: DirectoryContent) => {
  return (
    <Tree.TreeNode key={rootName} title={<div>{rootName}</div>} isLeaf={false}>
      {[
        ...(data.subDirs &&
          Object.keys(data.subDirs).map((itemKey) => {
            const item = data.subDirs[itemKey];
            return renderArchiveNodes(itemKey, item);
          })),
        ...data.files.map((item, index) => {
          return <Tree.TreeNode key={`${rootName}-${index}`} title={<div>{item}</div>} isLeaf={true} />;
        }),
      ]}
    </Tree.TreeNode>
  );
};

export const ArchiveProcessor = (data: ArchiveProcessorData, intl: InjectedIntl): ProcessedJsonData => {
  const header = [
    {
      key: 0,
      title: intl.formatMessage({ id: 'ArchiveViewer.headers.size' }),
      value: numeral(data.size).format('0.0 b'),
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'ArchiveViewer.headers.type' }),
      value: data.contentType,
    },
  ];
  return {
    headers: header.filter((h) => !!h.value && h.value.length > 0),
    body: (
      <div className={styles.content}>
        <div className={styles.scrollable}>
          <Tree showLine defaultExpandAll selectable={false}>
            {renderArchiveNodes('/', data)}
          </Tree>
        </div>
      </div>
    ),
  };
};
