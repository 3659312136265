import { api } from 'api';
import { messageError } from 'utils';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'hooks';

export const usePdfTronLicenseKey = () => {
  const intl = useIntl();
  const [pdfTronLicenseKey, setPdfTronLicenseKey] = useState<string>(undefined);

  const getPdfTronLicenceKey = useCallback(() => {
    api.master.pdfTron.getLicenceKey().then(([err, resp]) => {
      if (err) {
        messageError(err, intl);
      } else {
        setPdfTronLicenseKey(resp.data);
      }
    });
  }, []);

  useEffect(() => {
    getPdfTronLicenceKey();
  }, []);

  return [pdfTronLicenseKey, getPdfTronLicenceKey] as const;
};
