import { AuditLogDto } from 'api/completeApiInterfaces';
import { InjectedIntl, InjectedIntlProps } from 'locale';
import { isIntlMessageId } from 'locale/messages/cs';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import DoubleParamsTable, { DoubleParams } from './DoubleParamsTable';
import ParamsTable, { Params } from './ParamsTable';

export type AuditLogEntityData =
  | Dictionary<string>
  | {
      before: Dictionary<string>;
      after: Dictionary<string>;
    };

type TableParams = { type: 'two'; data: DoubleParams[] } | { type: 'one'; data: Params[] };

type Props = InjectedIntlProps & {
  log: AuditLogDto;
};

const getName = (key: string, intl: InjectedIntl): string => {
  const localeKey: string = 'AuditLog.EntityData.' + key;
  if (isIntlMessageId(localeKey)) {
    return intl.formatMessage({ id: localeKey });
  }
  return key;
};

const getValue = (value: any, intl: InjectedIntl): string => {
  if (value === 'unset') return intl.formatMessage({ id: 'AuditLog.EntityData.unset' });
  if (typeof value === 'boolean')
    return value
      ? intl.formatMessage({ id: 'AuditLog.EntityData.true' })
      : intl.formatMessage({ id: 'AuditLog.EntityData.false' });
  return value;
};

const EntityData: FunctionComponent<Props> = ({ log, intl }) => {
  const params: TableParams = useMemo(() => {
    if ('after' in log.entityData && 'before' in log.entityData) {
      return {
        type: 'two',
        data: Object.keys(log.entityData.before).map((key) => ({
          key: key,
          name: getName(key, intl),
          before: getValue((log.entityData.before as Dictionary<string>)[key], intl),
          after: getValue((log.entityData.after as Dictionary<string>)[key], intl),
        })),
      };
    }
    return {
      type: 'one',
      data: Object.keys(log.entityData).map((key) => ({
        key: key,
        name: getName(key, intl),
        value: getValue((log.entityData as Dictionary<string>)[key], intl),
      })),
    };
  }, [log, intl]);

  switch (params.type) {
    case 'one':
      return <ParamsTable params={params.data} isEntity entityName={log.entityType} />;
    case 'two':
      return <DoubleParamsTable params={params.data} entityName={log.entityType} />;
    default:
      return <></>;
  }
};

export default injectIntl(EntityData);
