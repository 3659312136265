import '@ant-design/compatible/assets/index.css';

export enum KpiRuleCondition {
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThan = '<',
  LessThanOrEqual = '<=',
}

export enum KpiRuleValidation {
  Ok = 0,
  Critical = 1,
}

export type KpiRule = {
  condition: KpiRuleCondition;
  refValue: number;
  validation: KpiRuleValidation;
};

export type KPIConfiguration = {
  id: Guid;
  title: string;
  rules: Record<string, KpiRule>;
};
