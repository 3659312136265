import { ServiceError } from 'api/completeApiInterfaces';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentCategoryTreeNodeDeleteForm, {
  DocumentCategoryTreeNodeDeleteFormData,
} from './DocumentCategoryTreeNodeDeleteForm';

type Props = FormModalProps<DocumentCategoryTreeNodeDeleteFormData> & {
  error: ServiceError;
  askRemoveStrategy: boolean;
  removeChildren?: boolean;
  defaults?: DocumentCategoryTreeNodeDeleteFormData;
  projectId: Guid;
};

const DocumentCategoryTreeNodeDeleteFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, error, defaults, askRemoveStrategy, removeChildren, projectId, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DocumentCategoryTreeNodeDeleteFormData> = async (values) => {
    await onSubmit(values);
    return null;
  };

  return (
    <FormModalWrapper
      confirmClose={false}
      onSubmit={handleSubmit}
      titleId={`DocumentCategoryTreeNodeDeleteModal.title`}
      submitTextId={'DocumentCategoryTreeNodeDeleteModal.ok'}
      {...restProps}
    >
      {({ intl, formRef }) => {
        return (
          <>
            <DocumentCategoryTreeNodeDeleteForm
              intl={intl}
              error={error}
              wrappedComponentRef={formRef}
              defaults={defaults}
              askRemoveStrategy={askRemoveStrategy}
              removeChildren={removeChildren}
              projectId={projectId}
            />
          </>
        );
      }}
    </FormModalWrapper>
  );
};

export default DocumentCategoryTreeNodeDeleteFormModal;
