import React, { FC } from 'react';
import styles from './StyledBox.module.less';
import classnames from 'classnames';

type Props = {
  className?: string;
  flex?: boolean;
  padding?: boolean;
};

export const StyledBox: FC<Props> = ({ children, flex, padding, className }) => {
  return (
    <div className={classnames(styles.styledBox, flex && styles.flex, padding && styles.padding, className)}>
      {children}
    </div>
  );
};
