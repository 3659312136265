import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import FileTypeIcon from 'components/FileTypeIcon';
import { Fmt } from 'locale';
import numeral from 'numeral';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styles from './PrimaryFileInput.module.less';

type PrimaryFileInputProps = {
  value?: File;
  onChange?: (value: File) => void;
};

type PrimaryFileInputState = { file: File };

class PrimaryFileInput extends Component<PrimaryFileInputProps, PrimaryFileInputState> {
  constructor(props: PrimaryFileInputProps) {
    super(props);
    this.state = { file: props.value };
  }

  triggerChange = (file: File) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(file);
    }
  };

  onDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
    const file = acceptedFiles.length ? acceptedFiles[0] : null;
    this.setState({ file });
    this.triggerChange(file);
  };

  removeFile = () => {
    this.setState({ file: null });
    this.triggerChange(null);
  };

  render() {
    const { file } = this.state;
    if (file) {
      return (
        <div className={styles.item}>
          <div className={styles.content}>
            <div className={styles.icon} title={file.type}>
              <FileTypeIcon contentType={file.type} />
            </div>
            <div className={styles.info}>
              <span className={styles.name} title={file.name}>
                {file.name}
              </span>
              <br />
              <span>{numeral(file.size).format('0.0 b')}</span>
            </div>
            <CloseOutlined onClick={this.removeFile} />
          </div>
        </div>
      );
    } else {
      return (
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={classNames('ant-upload', 'ant-upload-drag', { 'ant-upload-drag-hover': isDragActive })}
            >
              <input {...getInputProps()} />
              <span className="ant-upload ant-upload-btn" role="button">
                <div className="ant-upload-drag-container">
                  <p className="ant-upload-text">
                    <Fmt id="PrimaryFileInput.collapse.text" />
                  </p>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint">
                    <Fmt id="PrimaryFileInput.collapse.hint" />
                  </p>
                </div>
              </span>
            </div>
          )}
        </Dropzone>
      );
    }
  }
}

export default PrimaryFileInput;
