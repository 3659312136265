import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent, ReactNode } from 'react';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import DocumentCheckbox from 'components/DocumentCheckbox/DocumentCheckbox';
import { Fmt } from 'locale';

type Props = {
  disableSelect?: boolean;
  selectAllDocuments?: () => void;
  allFilesSelected?: boolean;
  isAnyItemSelected?: boolean;
  order?: ReactNode;
  filters?: ReactNode;
  noPadding?: boolean;
  select?: ReactNode;
};

const DocumentsGridHeader: FunctionComponent<Props> = ({
  selectAllDocuments,
  allFilesSelected,
  isAnyItemSelected,
  order,
  filters,
  disableSelect,
  noPadding,
}) => {
  if (disableSelect && !filters && !order) return null;

  return (
    <DocumentsGridHeaderStyled noPadding={noPadding}>
      <FlowLayout>
        {!disableSelect && !!selectAllDocuments && (
          <DocumentCheckbox
            onClickCheckbox={selectAllDocuments}
            selected={allFilesSelected}
            semiSelected={isAnyItemSelected}
            tooltip={
              allFilesSelected ? <Fmt id="DocumentsGridHeader.deselect" /> : <Fmt id="DocumentsGridHeader.select" />
            }
          />
        )}
        {order}
        {filters}
      </FlowLayout>
    </DocumentsGridHeaderStyled>
  );
};

export default React.memo(DocumentsGridHeader);
