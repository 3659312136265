import { to } from 'api/await-to';
import {
  ExtendedPermissionsDto,
  ExtendedPermissionsPatchDto,
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const projectUserApi = {
  getCurrentUser: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<ProjectUserProfileDto>(`/users/me`, { cancelToken })
    ),
  getUserExtendedPermissions: (userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<ExtendedPermissionsDto>(`/users/${userId}/extendedpermission`, { cancelToken })
    ),
  updateUserExtendedPermissions: (userId: Guid, data: ExtendedPermissionsPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<void>(`/users/${userId}/extendedpermission`, data, { cancelToken })
    ),
  listUsers: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<ProjectUserProfileListDto[]>(`/users`, { cancelToken })
    ),

  getUserById: (userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<ProjectUserProfileDto>(`/users/${userId}`, { cancelToken })
    ),

  suspendUser: (userId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/users/${userId}/suspend`, {
        cancelToken,
      })
    ),
  activateUser: (userId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.post(`/users/${userId}/activate`, { cancelToken })),

  addUserGroup: (userId: Guid, groupId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/users/${userId}/groups/${groupId}`, { cancelToken })
    ),
  removeUserGroup: (userId: Guid, groupId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/users/${userId}/groups/${groupId}`, { cancelToken })),

  deleteInvitedUser: (userId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/users/${userId}`, { cancelToken })),
};
