import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { api } from 'api';
import {
  AccessLevelEnum,
  DirectoryGroupDto,
  DirectoryGroupPatchDto,
  DirectoryPermissionPatchDto,
  DirectoryUserDto,
  DirectoryUserPatchDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { AccessLevelListDataSource } from 'components/AccessLevelList/AccessLevelList';
import {
  DirectoryPermissionMap,
  DirectorySettingsPermissionsActions,
} from 'components/DirectorySettingsPermissions/DirectorySettingsPermissions';
import SpinBox from 'components/SpinBox';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import React, { Component, Dispatch } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
import { messageError, smartFilter } from 'utils';
import DirectorySettingsPermissionsDrawer from './DirectorySettingsPermissionsDrawer';
import styles from './DirectorySettingsPermissionsForm.module.less';

const mapStateToProps = (state: RootState) => ({
  currentProjectUser: state.currentProjectUser.data,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

export type DirectorySettingsFormProps = PropsFromState & {
  directoryId: Guid;
  projectId: Guid;
  usersList: ProjectUserProfileListDto[];
  usersMap: Dictionary<ProjectUserProfileListDto>;
  defaultPermissionsMap: DirectoryPermissionMap;
  permissions: DirectoryPermissionPatchDto;
  dispatchPermissions: Dispatch<DirectorySettingsPermissionsActions>;
  isDirty: boolean;
  adminGroupIds: Guid[];
};

type Props = DirectorySettingsFormProps & InjectedIntlProps;

type State = {
  searchUsersValue: string;
  searchedUsers: ProjectUserProfileListDto[];
  availableUsersList: ProjectUserProfileListDto[];
  userAccessForDisplay: AccessLevelListDataSource[];
  groupsAccessForDisplay: AccessLevelListDataSource[];
};

class DirectorySettingsPermissionsForm extends Component<Props, State> {
  state: State = {
    searchUsersValue: '',
    searchedUsers: [],
    availableUsersList: [],
    userAccessForDisplay: [],
    groupsAccessForDisplay: [],
  };

  componentDidMount(): void {
    if (this.props.permissions) {
      this.initUsersList();
      this.initGroupsList();
    }
    if (this.props.adminGroupIds && this.props.usersList) {
      this.onUsersListUpdate();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (
      this.props.permissions &&
      (this.props.permissions.users !== prevProps.permissions.users ||
        this.props.permissions.permissionInheritance !== prevProps.permissions.permissionInheritance)
    ) {
      this.initUsersList();
    }
    if (
      this.props.permissions &&
      (this.props.permissions.groups !== prevProps.permissions.groups ||
        this.props.permissions.permissionInheritance !== prevProps.permissions.permissionInheritance)
    ) {
      this.initGroupsList();
    }

    if (
      (this.props.usersList !== prevProps.usersList || this.props.adminGroupIds !== prevProps.adminGroupIds) &&
      this.props.usersList &&
      this.props.adminGroupIds
    ) {
      this.onUsersListUpdate();
    }
  }

  onUsersListUpdate = async () => {
    const { adminGroupIds, usersList } = this.props;

    const results = await Promise.all(adminGroupIds.map((groupId) => api.project.groups.listGroupUsers(groupId)));
    const error = results.map((result) => result[0]).find((error) => !!error);
    if (!error) {
      const adminUsers = new Set(results.flatMap((result) => result[1].data));
      const availableUsersList = usersList.filter((u) => !adminUsers.has(u.id));
      this.setState({ availableUsersList }, () => this.handleUserSearch(this.state.searchUsersValue));
    } else {
      messageError(error, this.props.intl);
    }
  };

  initUsersList = () => {
    this.setState(
      {
        userAccessForDisplay: this.props.permissions.users.map(this.mapUserToDataItem).filter((u) => !u.hidden),
      },
      () => this.handleUserSearch(this.state.searchUsersValue)
    );
  };

  initGroupsList = () => {
    this.setState({
      groupsAccessForDisplay: this.props.permissions.groups.map(this.mapGroupToDataItem),
    });
  };

  handleAddUser = (userId: Guid) => {
    const payload: DirectoryUserPatchDto = {
      userId,
      accessLevel: AccessLevelEnum.none,
    };
    this.props.dispatchPermissions({ type: 'addUser', payload });
  };

  handleSetUser = (accessLevel: AccessLevelEnum, userId: Guid) => {
    const payload: DirectoryUserPatchDto = {
      userId,
      accessLevel,
    };
    this.props.dispatchPermissions({ type: 'setUser', payload });
  };

  handleRemoveUser = (userId: Guid) => {
    this.props.dispatchPermissions({ type: 'removeUser', payload: userId });
  };

  handleSetPermissionsInheritance = (inheritPermissions: boolean) => {
    this.props.dispatchPermissions({ type: 'setPermissionsInheritance', payload: inheritPermissions });
  };

  handleRemoveGroup = (groupId: Guid) => {
    this.props.dispatchPermissions({
      type: 'setGroup',
      payload: {
        groupId,
        accessLevel: null,
      },
    });
  };

  handleChangeGroupAccess = (accessLevel: AccessLevelEnum, groupId: Guid) => {
    this.props.dispatchPermissions({
      type: 'setGroup',
      payload: {
        groupId,
        accessLevel: accessLevel,
      },
    });
  };

  handleUserSearch = (value: string = '') => {
    const { currentProjectUser, permissions } = this.props;
    const { availableUsersList } = this.state;
    const used = new Set<Guid>(permissions.users.filter((u) => u.accessLevel !== null).map((u) => u.userId));
    const searchedUsers = availableUsersList
      .filter((available) => available.status !== ProjectUserProfileStatusEnum.suspended)
      .filter((user) => {
        return smartFilter(user.username, value) && !used.has(user.id) && user.id !== currentProjectUser.id;
      });
    this.setState({ searchUsersValue: value, searchedUsers });
  };

  mapUserToDataItem = (item: DirectoryUserPatchDto): AccessLevelListDataSource => {
    const { currentProjectUser, defaultPermissionsMap } = this.props;
    const originalItem = defaultPermissionsMap.users?.[item.userId];
    return {
      id: item.userId,
      key: item.userId + this.props.directoryId,
      inherited: !item.accessLevel && this.props.permissions.permissionInheritance,
      accessLevel: this.userAccess(item, originalItem),
      selfAccessLevel: item.accessLevel,
      label: this.props.usersMap[item.userId].username,
      edited:
        (!!item.accessLevel && !originalItem) ||
        (originalItem && !!item.accessLevel && originalItem.accessLevel !== item.accessLevel),
      disabled: item.userId === currentProjectUser.id,
      hidden:
        (this.props.permissions.permissionInheritance &&
          (!originalItem || originalItem.inheritedAccessLevel === null) &&
          item.accessLevel === null) ||
        (!this.props.permissions.permissionInheritance && item.accessLevel === null),
    };
  };

  userAccess = (newItem: DirectoryUserPatchDto, oldItem: DirectoryUserDto): AccessLevelEnum => {
    if (!!newItem.accessLevel) return newItem.accessLevel;
    if (this.props.permissions.permissionInheritance && oldItem?.inheritedAccessLevel)
      return oldItem.inheritedAccessLevel;
    return AccessLevelEnum.none;
  };

  mapGroupToDataItem = (item: DirectoryGroupPatchDto): AccessLevelListDataSource => {
    const originalItem = this.props.defaultPermissionsMap.groups[item.groupId];
    return {
      id: item.groupId,
      key: item.groupId + this.props.directoryId,
      inherited: !item.accessLevel && this.props.permissions.permissionInheritance,
      accessLevel: this.groupAccess(item, originalItem),
      selfAccessLevel: item.accessLevel,
      label: originalItem.name,
      disabled: this.props.adminGroupIds.includes(originalItem.groupId),
      edited:
        (item.accessLevel && item.accessLevel !== originalItem.accessLevel) ||
        (!item.accessLevel && !!originalItem.accessLevel),
    };
  };

  groupAccess = (newItem: DirectoryGroupPatchDto, oldItem: DirectoryGroupDto): AccessLevelEnum => {
    if (!!newItem.accessLevel) return newItem.accessLevel;
    if (this.props.permissions.permissionInheritance && oldItem.inheritedAccessLevel)
      return oldItem.inheritedAccessLevel;
    return AccessLevelEnum.none;
  };

  render() {
    const { searchUsersValue, searchedUsers, userAccessForDisplay, groupsAccessForDisplay } = this.state;
    const { isDirty, permissions, intl, currentProjectUser, projectId, usersList } = this.props;

    if (!permissions) return <SpinBox />;

    return (
      <>
        <DirectorySettingsPermissionsDrawer
          inheritPermissions={permissions.permissionInheritance}
          intl={intl}
          searchUsersValue={searchUsersValue}
          usersList={usersList}
          searchedUsers={searchedUsers}
          userAccessForDisplay={userAccessForDisplay}
          groupsAccessForDisplay={groupsAccessForDisplay}
          showIndividualUserAccessSettings
          handleSetPermissionsInheritance={this.handleSetPermissionsInheritance}
          directoryId={this.props.directoryId}
          handleAddUser={this.handleAddUser}
          handleChangeGroupAccess={this.handleChangeGroupAccess}
          handleRemoveGroup={this.handleRemoveGroup}
          handleRemoveUser={this.handleRemoveUser}
          handleSetUser={this.handleSetUser}
          handleUserSearch={this.handleUserSearch}
        />
        {!isDirty && currentProjectUser.isAdmin && (
          <div className={styles.centerButton}>
            <Link to={`/projects/${projectId}/settings/groups`}>
              <Button type="link" icon={<SettingOutlined />}>
                <Fmt id="DirectorySettingsForm.goToGroupsSettings" />
              </Button>
            </Link>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  injectIntl(DirectorySettingsPermissionsForm, { withRef: true })
);
