import { CloseOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FavoriteProjectListDto } from 'api/completeApiInterfaces';
import { estiConProjectPhaseTranslations } from 'api/project/projects/projectsApiConstants';
import classNames from 'classnames';
import DisplayName from 'components/DisplayName';
import { DragHook } from 'components/DragTile/DragTile';
import { MovableCard } from 'components/MovableCard/MovableCard';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { Fmt, InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { getProjectUrlPath } from 'utils/urlPaths';
import Charts, { ChartData } from './Charts';
import styles from './ProjectCard.module.less';

type Props = InjectedIntlProps & {
  project: FavoriteProjectListDto;
  onRemove: (projectId: Guid) => void;
  className?: string;
  dragRef: DragHook;
};

const ELLIPSIS = { rows: 3 };

const ProjectCard: FunctionComponent<Props> = ({ intl, onRemove, project, className, dragRef }) => {
  const chartData = useMemo<ChartData>(() => {
    if (!project.esticonProjectData) {
      return {
        aktCena: 0,
        cenaSOD: 0,
        realized: 0,
      };
    }
    return {
      aktCena: project.esticonProjectData.soD + project.esticonProjectData.zbv,
      cenaSOD: project.esticonProjectData.soD,
      realized: project.esticonProjectData.buildCost,
    };
  }, [project]);

  const removeProject = useCallback(
    async (e) => {
      e.preventDefault();
      onRemove(project.id);
    },
    [onRemove, project.id]
  );

  const title = (
    <NoClickTooltip mouseEnterDelay={0.5} title={project.name}>
      <div className={styles.name}>
        <DisplayName text={project.name} />
      </div>
    </NoClickTooltip>
  );

  const menu = (
    <NoClickTooltip title={<Fmt id="ProjectsListPage.Card.remove" />}>
      <Button icon={<CloseOutlined />} shape="circle" className={styles.closeButton} onClick={removeProject} />
    </NoClickTooltip>
  );

  const duration = useMemo(() => {
    if (!project.esticonProjectData || !project.esticonProjectData.begin) {
      return <Fmt id="ProjectsListPage.Card.duration.unknown" />;
    }
    const start = moment(project.esticonProjectData.begin)
      .locale(intl.locale)
      .format('L');
    const end = project.esticonProjectData.end ? (
      moment(project.esticonProjectData.end)
        .locale(intl.locale)
        .format('L')
    ) : (
      <Fmt id="ProjectsListPage.Card.duration.unset" />
    );
    return (
      <>
        {start} - {end}
      </>
    );
  }, [project, intl]);

  return (
    <MovableCard
      className={classNames(styles.card, className)}
      title={title}
      menu={menu}
      linkTo={getProjectUrlPath(project.id)}
      dragRef={dragRef}
    >
      <span className={styles.detail}>
        <NoClickTooltip
          overlayClassName={styles.tooltip}
          mouseEnterDelay={0.5}
          title={
            <>
              <div className={styles.projectNameTitle}>{project.name}</div>
              {project.description && <div>{project.description}</div>}
            </>
          }
        >
          {project.description && (
            <span>
              <Typography.Paragraph className={styles.description} ellipsis={ELLIPSIS}>
                {project.description}
              </Typography.Paragraph>
            </span>
          )}
        </NoClickTooltip>
        {!project.esticonForbidden && (
          <>
            <div className={styles.params}>
              <div>
                <b>{<Fmt id="ProjectsListPage.Card.duration" />}: </b>
                {duration}
              </div>
              <div>
                <b>{<Fmt id="ProjectsListPage.Card.phase" />}: </b>
                {project.esticonProjectData ? (
                  <Fmt id={estiConProjectPhaseTranslations[project.esticonProjectData.phase]} />
                ) : (
                  <Fmt id="ProjectsListPage.Card.status.unknown" />
                )}
              </div>
            </div>
            <Charts {...chartData} />
          </>
        )}
      </span>
    </MovableCard>
  );
};

export default injectIntl(ProjectCard);
