import { to } from 'api/await-to';
import { GroupCreateDto, GroupDto, GroupListDto, GroupPatchDto, GroupUserListAddDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';

export const groupsApi = {
  listGroups: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<GroupListDto[]>(`/groups`, { cancelToken })
    ),
  createGroup: (data: GroupCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<GroupDto>('/groups', data, { cancelToken })
    ),
  getGroupById: (groupId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<GroupDto>(`/groups/${groupId}`, { cancelToken })
    ),
  updateGroupById: (groupId: Guid, group: GroupPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<GroupDto>(`/groups/${groupId}`, group, { cancelToken })
    ),
  deleteGroupById: (groupId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/groups/${groupId}`, { cancelToken })),
  listGroupUsers: (groupId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<Guid[]>(`/groups/${groupId}/users`, { cancelToken })
    ),
  addGroupUsers: (groupUserListAddDto: GroupUserListAddDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<GroupDto>(`/groups/addUsers`, groupUserListAddDto, { cancelToken })
    ),
};
