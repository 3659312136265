import { Col, Row, Typography } from 'antd';
import { ProjectTimezoneDto } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerStyled from './MessageViewerStyled';

export enum AccountNotificationTypeEnum {
  userAddedToOrganization = 0,
  userAddedToProject = 1,
  userActivated = 2,
  userDeactivated = 3,
}

export type ProjectAccountMessageData = {
  ProjectName: string;
  ProjectDescription: string;
  Language: string;
  LinkUrl: string;
  IsRegistered: boolean;
  ToOrganization: boolean;
  OrganizationName: string;
  OriginatedBy: string;
  ProjectTimeZone: ProjectTimezoneDto;
  NotificationType: AccountNotificationTypeEnum;
};

export const ProjectAccountMessageViewer: MessageViewer = ({ messageData }) => {
  const data = messageData as ProjectAccountMessageData;

  const getNotificationTypeString = (notificationType: AccountNotificationTypeEnum) => {
    switch (notificationType) {
      case AccountNotificationTypeEnum.userAddedToProject:
        return 'userAddedToProject';
      case AccountNotificationTypeEnum.userActivated:
        return 'userActivated';
      case AccountNotificationTypeEnum.userDeactivated:
        return 'userDeactivated';
      case AccountNotificationTypeEnum.userAddedToOrganization:
        return 'userAddedToOrganization';
    }
  };

  const messageText = useMemo(() => {
    if (data.NotificationType === AccountNotificationTypeEnum.userAddedToOrganization) {
      return (
        <Fmt
          id={`MessageCenterPage.message.detail.projectAccount.userAddedToOrganization`}
          values={{ user: data.OriginatedBy, organization: data.OrganizationName }}
        />
      );
    }
    return (
      <Fmt
        id={`MessageCenterPage.message.detail.projectAccount.${getNotificationTypeString(data.NotificationType)}`}
        values={{ user: data.OriginatedBy, project: data.ProjectName }}
      />
    );
  }, [data]);

  const linkText = useMemo(() => {
    if (data.NotificationType === AccountNotificationTypeEnum.userAddedToOrganization) return data.OrganizationName;
    return data.ProjectName;
  }, [data]);

  const header = <Fmt id={'MessageCenterPage.message.detail.projectAccount.title'} />;

  const content = (
    <>
      <Typography.Text>{messageText}</Typography.Text>

      {data.NotificationType !== AccountNotificationTypeEnum.userDeactivated && (
        <Row>
          <Col span={12}>
            <Typography.Text>{<Fmt id={`general.goTo`} />}</Typography.Text>
            <Link to={parse(data.LinkUrl || '').pathname}>
              <Typography.Link underline>{linkText}</Typography.Link>
            </Link>
          </Col>
        </Row>
      )}
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
