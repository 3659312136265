import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { Badge, Button, Space } from 'antd';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';

type Props = {
  activeFiltersCount: number;
  onClick: () => void;
  isOn: boolean;
  showTooltip?: boolean;
  tooltipDelay?: number;
};

const FilterToggleButtonComponent: FunctionComponent<Props> = ({
  activeFiltersCount,
  isOn,
  onClick,
  showTooltip,
  tooltipDelay,
}) => {
  const content = (
    <Button type={!activeFiltersCount ? 'default' : 'primary'} onClick={onClick}>
      <Space>
        {isOn ? <FilterFilled /> : <FilterOutlined />}
        {!!activeFiltersCount && <Badge count={activeFiltersCount} />}
      </Space>
    </Button>
  );

  if (!showTooltip) {
    return content;
  }

  return (
    <NoClickTooltip
      title={<Fmt id={isOn ? 'general.turnFiltersOff' : 'general.turnFiltersOn'} />}
      placement="topRight"
      mouseEnterDelay={tooltipDelay}
    >
      {content}
    </NoClickTooltip>
  );
};

export const FilterToggleButton = React.memo(FilterToggleButtonComponent);
