import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  to: string | null | undefined;
};

export const Link: FunctionComponent<Props> = ({ to, children }) => {
  return to ? <RouterLink to={to}>{children}</RouterLink> : <>{children}</>;
};
