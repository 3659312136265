import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useStoreSelector } from 'hooks';
import { Moment } from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import { formatPickerHolidayHighlight } from './DatePickerWithHolidays';

const { RangePicker } = DatePicker;
type Props = {} & RangePickerProps;

export const RangePickerWithHolidays: FunctionComponent<Props> = ({ ...rangepickerProps }) => {
  const { activeCalendar, activeCalendarError, activeCalendarLoading } = useStoreSelector(
    (state) => state.activeCalendar
  );

  const calendar = activeCalendar?.projectCalendar || activeCalendar?.orgCalendar;

  const cellHighlight = useCallback((current: Moment) => formatPickerHolidayHighlight(current, calendar), [calendar]);

  return <RangePicker {...rangepickerProps} dateRender={cellHighlight} disabled={activeCalendarLoading} />;
};
