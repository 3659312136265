import { LoadedModel } from 'Forge/Forge';
import { Modal, Typography } from 'antd';
import { InjectedIntl } from 'locale';
import { groupBy } from 'lodash';
import React, { FunctionComponent } from 'react';
import styles from './ForgeConsistencyWarning.module.less';

type UnitType = 'meter' | 'millimeter' | 'other';

const groupModelsByUnit = (models: LoadedModel[]) => {
  const grouped = groupBy(
    models.map((model) => model.model).filter((model) => !!model),
    (model) => model.getUnitScale()
  );
  return Object.entries(grouped).map(([unit, objects]) => ({
    unit: (unit === '1' ? 'meter' : unit === '0.001' ? 'millimeter' : 'other') as UnitType,
    models: objects.map((model) => (model.getData().documentName || model.getData().documentId) as string),
  }));
};

const processBoundingBoxSync = (model: LoadedModel) => {
  if (!model.model) return null;

  const instanceTree = model.model.getInstanceTree();
  if (!instanceTree) return null;
  const fragList = instanceTree.fragList;

  const totalBB = new window.THREE.Box3();
  const fragBB = new window.THREE.Box3();

  for (let i = 0; i < fragList.fragments.length; i++) {
    (fragList as any).getWorldBounds(i, fragBB);
    totalBB.union(fragBB);
  }

  return totalBB;
};

export const groupModelsByDistance = (
  models: { model: Autodesk.Viewing.Model; center: THREE.Vector3 }[],
  threshold: number
): string[][] => {
  if (models.length === 0) return [];
  const point1 = models[0].center;
  const group = models
    .filter(({ center }) => center.distanceTo(point1) <= threshold)
    .map(({ model }) => model.getData().documentName);
  return [
    group,
    ...groupModelsByDistance(
      models.filter(({ center }) => center.distanceTo(point1) > threshold),
      threshold
    ),
  ];
};

type ModelsConsistencyWarningProps = {
  modelsByUnit: { models: string[]; unit: UnitType }[];
  intl: InjectedIntl;
};

const UnitsConsistencyWarning: FunctionComponent<ModelsConsistencyWarningProps> = ({ modelsByUnit, intl }) => {
  return (
    <>
      <div>{intl.formatMessage({ id: 'Forge.warning.multipleScales.message' })}</div>
      {modelsByUnit.map((unitModel, index) => (
        <div key={index}>
          <Typography.Title level={4} className={styles.unitTitle}>
            {intl.formatMessage({
              id: `Forge.warning.multipleScales.${unitModel.unit}`,
            })}
          </Typography.Title>
          {unitModel.models.map((model: any) => (
            <div>
              <Typography.Text strong>{model}</Typography.Text>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

type GroupsConsistencyWarningProps = {
  groups: string[][];
  intl: InjectedIntl;
  isUsingSharedCoodrdinates: boolean;
};

const GroupsConsistencyWarning: FunctionComponent<GroupsConsistencyWarningProps> = ({
  groups,
  intl,
  isUsingSharedCoodrdinates,
}) => {
  return (
    <>
      <div>{intl.formatMessage({ id: 'Forge.warning.longDistances.message' })}</div>
      {!isUsingSharedCoodrdinates && (
        <div>{intl.formatMessage({ id: 'Forge.warning.longDistances.sharedCoordinatesMissing' })}</div>
      )}
      {groups.map((group, index) => (
        <div key={index}>
          <Typography.Title level={4} className={styles.unitTitle}>
            {intl.formatMessage(
              {
                id: `Forge.warning.longDistances.group`,
              },
              {
                number: index + 1,
              }
            )}
          </Typography.Title>
          {group.map((group) => (
            <div key={group}>
              <Typography.Text strong>{group}</Typography.Text>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export const checkModelsConsistency = (
  models: LoadedModel[],
  intl: InjectedIntl,
  isUsingSharedCoodrdinates: boolean
) => {
  if (!models || models.length < 2) return;

  const modelsByUnit = groupModelsByUnit(models);

  if (modelsByUnit.length >= 2) {
    Modal.warning({
      title: intl.formatMessage({ id: 'Forge.warning.multipleScales.title' }),
      content: <UnitsConsistencyWarning modelsByUnit={modelsByUnit} intl={intl} />,
      width: 500,
    });
  } else {
    // models not matching / too far apart
    const notMatchingModels = models
      .map((model) => ({ model, boundingBox: processBoundingBoxSync(model) }))
      .filter((model) => !!model.model.model && !!model.boundingBox);

    // max size of any model
    const sphere = new window.THREE.Sphere();
    const maxSize = Math.max(...notMatchingModels.map((model) => model.boundingBox.getBoundingSphere(sphere).radius));

    const groups = groupModelsByDistance(
      notMatchingModels.map((model) => ({
        model: model.model.model,
        center: model.boundingBox.getBoundingSphere(sphere).center.clone(),
      })),
      maxSize * 100
    );

    if (groups.length >= 2) {
      Modal.warning({
        title: intl.formatMessage({ id: 'Forge.warning.longDistances.title' }),
        content: (
          <GroupsConsistencyWarning groups={groups} intl={intl} isUsingSharedCoodrdinates={isUsingSharedCoodrdinates} />
        ),
        width: 500,
      });
    }
  }
};
